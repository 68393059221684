import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserModel } from '../../../model/UserModel';
import { updatePassword } from '../../../services/updatePasswordService';
import { dispatch, isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { Title } from '../../reusableComponents/Title';
import './updatePassword.scss';
import { withRouter } from 'react-router-dom';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { actions } from 'react-redux-form';
import { IHistory } from '../../../interfaces';
import PropTypes from 'prop-types';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { ErrorMessageLabel } from '../../reusableComponents/ErrorMessageLabel/ErrorMessageLabel';

export interface IUpdatePasswordImplProps {
    instance: UserModel;
    history?: IHistory;
    userUID: string;
}

export interface IUpdatePasswordImplState {
    message: string;
    popOver: boolean;
    responseStatus: string;
}

export class UpdatePasswordImpl extends React.PureComponent<IUpdatePasswordImplProps, IUpdatePasswordImplState> {
    constructor(props: IUpdatePasswordImplProps | Readonly<IUpdatePasswordImplProps>) {
        super(props);
        this.state = {
            message: '', responseStatus: '', popOver: false
        };
    }

    componentWillMount() {
        dispatch(actions.reset('forms.updatePasswordForm'));
    }

    getChildContext() {
        return { formModel: 'forms.updatePasswordForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handlePasswordUpdate = ({ oldPassword, newPassword, confirmPassword }: any) => { 
        const { instance: { props: { userId, company } }, userUID, history } = this.props;
        const submittedValue: any = { 
            oldPassword, newPassword, confirmPassword,
            company,
            userUID
        };
        if (this.state.popOver) {
            showAlert('Please choose a strong password...!!!', 'update-password', 'danger');
            return;
        }
        if (isFormEmpty(submittedValue)) {
            showAlert(EMPTY_FORM_MESSAGE, 'update-password', 'danger');
            return;
        }
        if(newPassword!==confirmPassword) {
            showAlert('Confirm password must be same as new password', 'update-password', 'danger');
            return;
        }
        return updatePassword(submittedValue, userId, history);
    }

    handleSomething = (e: any) => {
        //eslint-disable-next-line
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
        if (e.target.value.length <= 0) {
            this.setState({ popOver: false });
            return;
        }
        if (!strongRegex.test(e.target.value)) {
            this.setState({ popOver: true });
            return;
        }
        this.setState({ popOver: false });

    }

    renderPasswordField = () => {   
        return(<React.Fragment>
        <Row className="show-grid">
            <Label required htmlFor="password">New Password:</Label>
            <Col sm={6} className="input">
                    <RRFInput
                        id="password"
                        type="password"
                        placeholder="Enter your new password..."
Î                        model=".newPassword"
                        onChange={(e: any) => this.handleSomething(e)}
                    />
                {/* {this.state.popOver && <h5 style={passwordLabel}>*Your Password is not strong</h5>}
                  */}
                <ErrorMessageLabel isActive={this.state.popOver} 
                errorMessage="* Password should have atleast one Uppercase Letter, 
                one Lowercase character , one numeric character, 
                one special symbol and minimum length of password should be 8 character"/>
            </Col>      
            
        </Row>
        
        </React.Fragment>);
    }

    render() {
        const { handlePasswordUpdate } = this;
        return (<BasePage>
            <Form model="forms.updatePasswordForm"
                loader={<SubmissionLoader />}
                className="update-password" onSubmit={handlePasswordUpdate}>
                <Title text="Update Password" />
                <Alert className="danger-alert" id="update-password" />
                <AlertModal id="update-password-modal" />
                <Row className="show-grid">
                    <Label required>Current Password:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            placeholder="Enter your current password..."
                            type="password"
                            model=".oldPassword"
                        />
                    </Col>
                </Row>
                {this.renderPasswordField()}
                <Row className="show-grid">
                    <Label required>Confirm Password</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            placeholder="Enter confirm password..."
                            type="password"
                            model=".confirmPassword"
                        />
                    </Col>
                </Row>
                <div className="form-button" style={{ marginTop: '50px' }}>
                    <Button style={{
                        color: '#5479AF',
                        border: '1px solid #5479AF',
                        backgroundColor: 'white',
                    }}
                        redirectTo="home" type="reset" onClick={() => this.props.history?.goBack() }
                    >
                        <i className="fa fa-ban" aria-hidden="true"></i>
                        &nbsp;
                            Cancel
                        </Button>
                    <Button style={{
                        color: '#FFFFFF',
                        border: 'none',
                        backgroundColor: '#26a65b'
                    }}
                    >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                        &nbsp;
                            Update
                        </Button>
                </div>
            </Form>
        </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userUID = state.login.get('userUID');
    const instance = UserModel.get(userId);
    return {
        instance,
        userUID
    };
}

export const UpdatePassword = withRouter(
    connect<IUpdatePasswordImplProps, any, any>(mapStateToProps)(UpdatePasswordImpl)
);
