import * as React from 'react';
import { PersonalSafetyModel } from '../../../model/PersonalSafetyModel';
import { getAllPersonalSafetyForms, /* getPersonalSafetyFormByUserUID */ } from '../../../services/personalSafetyFormService';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { BasePage } from '../../reusableComponents/BasePage';
import { Loader } from '../../reusableComponents/Loader';
import { ListPage } from '../../reusableComponents/ListPage';
import { ListItem } from './ListItem';
import { connect } from 'react-redux'; 
import { IImmutableObject, IHistory, IForms } from '../../../interfaces';
import { withRouter } from 'react-router-dom';
import { setLoading, setSuccess } from '../../../actions/loadingActions'; 
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { Pagination } from '../../reusableComponents/Pagination';
import { ExcelExport } from '../../reusableComponents/ExcelExport';
import { CommonFilter } from '../../reusableComponents/FilterComponents/CommonFilter';
import { dispatchCommonFilter } from '../../../utils/generalUtils';
declare var require: any;
const queryString = require('query-string');

export interface IPersonalSafetyFormListPageProps {
    companyName: string;
    userUID: string;
    history?: IHistory;
    id: string;
    currentUserInfo: IImmutableObject;
    userId: string;
    personalSafetyFormInstances: PersonalSafetyModel[];
    totalPageCount?: number;
    forms: IForms;
    axiosPreviousToken: any;
} 

export interface IPersonalSafetyFormListPageState {
    formData: Object;
}

export class PersonalSafetyFormListPageImpl extends
    React.Component<IPersonalSafetyFormListPageProps, IPersonalSafetyFormListPageState> {
    constructor(props: IPersonalSafetyFormListPageProps | Readonly<IPersonalSafetyFormListPageProps>) {
        super(props);
        this.state = { formData: '' }; 
    } 
    sortListing = { 0: 'date', 1: 'username', 2:'projectName', 3: 'groupName', 4: 'supervisorName', 5: 'followUp' };
    async componentDidUpdate(prevProps: any, prevState: any) {
        if(prevProps.location.pathname !== this.props.history?.location.pathname) { 
            /* setLoading('PersonalSafetyFormListPage');
            await this.promise();
            setSuccess('PersonalSafetyFormListPage'); */
            let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
            await this.getReportData(urlParams.sortingIndex);
        }
    } 
    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await dispatchCommonFilter(urlParams); 
        await this.getReportData();
        return;
    } 
    getReportData = async (sortIndex='false') => {
        const { id, forms } = this.props; 
        setLoading('reportList'); 
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        urlParams.sortIndexField = this.sortListing[sortIndex]; 
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        if(!id)
            await getAllPersonalSafetyForms( urlParams, forms, 'othersPersonalSafety');
        else 
            await getAllPersonalSafetyForms( urlParams, forms, 'ownPersonalSafety');
        setSuccess('reportList');
    }
    renderUserFormsList = () => { 
        const { personalSafetyFormInstances } = this.props; 
        return (
            <div className="jsa-list-wrapper">
                {<ExcelExport formType="personalSafety" fileName={`Own Personal Safety Report`} filterParams={ {formUserType: 'own' } } />}
                <ListPage
                    pageHeading="Own Personal Safety Forms"
                    instances={personalSafetyFormInstances}
                    rowHeadings={['Date','User Name','Team','Group','Supervisor','Status']}
                    listItemComponent={ListItem}
                    emptyInstancesMessage="No PSP forms submitted"
                    isSearchable={true}
                    searchableBy={['fullName', 'supervisorName', 'date', 'groupName', 'projectName']}
                    searchPlaceHolder="Enter project, group, supervisor to search..."
                    sm={[1,2, 2, 2, 2, 2]}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                {this.renderAddNewFormButton()}
            </div>
        );
    }

    renderOtherUsersList = () => { 
        const { personalSafetyFormInstances } = this.props; 
        return (
            <div className="jsa-list-wrapper">   
                <ExcelExport formType="personalSafety" fileName={`Others Personal Safety Report`} filterParams={ {formUserType: 'others' } } />
                <ListPage
                    pageHeading="Others Personal Safety Forms"
                    instances={personalSafetyFormInstances}
                    rowHeadings={['Date','User Name','Team','Group','Supervisor','Status']}
                    listItemComponent={ListItem}
                    emptyInstancesMessage="No PSP forms submitted"
                    isSearchable={true}
                    searchableBy={['fullName', 'supervisorName', 'date', 'groupName', 'projectName']}
                    searchPlaceHolder="Enter project, group, supervisor to search..."
                    sm={[1, 2, 2, 2, 2]}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                {this.renderAddNewFormButton()}
            </div>
        );
    }
    renderReportList = () => { 
        const { id, forms, history} = this.props; 
        return (
            <>
            <div>   
                <CommonFilter onApply={this.getReportData} forms={forms} history={history} />
                <> {id ? this.renderUserFormsList() : this.renderOtherUsersList()}
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.promise(pageNumber)}
                        totalCount={this.props.totalPageCount}
                        Model={PersonalSafetyModel as any} 
                        identifier="AllListPage"
                    /> </>
                {/* <Async
                    identifier="reportList"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={ <> {id ? this.renderUserFormsList() : this.renderOtherUsersList()}
                            <Pagination
                                history={this.props.history}
                                promise={(pageNumber) => this.promise(pageNumber)}
                                totalCount={this.props.totalPageCount}
                                Model={PersonalSafetyModel as any} 
                                identifier="AllListPage"
                            /> </>
                        }
                /> */}
                </div>
            </>
        );
    }

    renderAddNewFormButton = () => {
        return (<button className="add-new-report"
            onClick={() => { this.props.history?.push('/personal-safety/create'); }}
        >
            +
        </button>
        );
    }

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="PersonalSafetyFormListPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderReportList() }
                />
            </BasePage>
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const userUID = state.login.get('userUID');
    const userId = state.login.get('userId');
    const companyName = state.login.get('company');
    const currentUserInfo = state.login.get('currentUserInfo');
    const personalSafetyFormInstances = PersonalSafetyModel.list();
    return { state, userUID, userId, companyName, personalSafetyFormInstances,
        currentUserInfo, id: ownProps.match.params.id, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const PersonalSafetyFormListPage = withRouter(connect<IPersonalSafetyFormListPageProps, any, any>
    (mapStateToProps)(PersonalSafetyFormListPageImpl));
