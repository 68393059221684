import * as React from 'react';
import { getUserInfo } from '../../services/loginService';
import { Img } from '../reusableComponents/ImageComponents/Img';

export interface IPrintLogosProps {
}

export class PrintLogos extends React.PureComponent<IPrintLogosProps, {}> {
    constructor(props: IPrintLogosProps | Readonly<IPrintLogosProps>) {
        super(props);
    }

    render() {
        let companyLogo = getUserInfo().companyLogo; 
        return (
            <div className="print-logo row" style={{marginBottom:'10px'}}>
                <div className="col-sm-6">
                    {companyLogo && <Img 
                        key={'companyLogo'}
                        style={{ padding: '5px', textAlign: 'left' }}
                        width="150px"
                        height="75px" 
                        src={companyLogo}
                    /> } 
                </div>
                <div className="col-sm-6" style={{textAlign: 'right'}}> 
                    <Img 
                        key={'companyLogo'}
                        style={{ padding: '5px', textAlign: 'right' }}
                        width="150px"
                        height="75px"
                        src="https://safeconnect-beta.s3.us-west-2.amazonaws.com/static-files/safeConnectTransparent.png"
                    /> 
                </div>
            </div>
        );
    }
} 
