import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showModal } from '../../../actions/modalActions';
import { IHistory } from '../../../interfaces';
import { UserModel } from '../../../model/UserModel';
import { getAllReferences } from '../../../services/miscellaniousServices';
import { getCurrentUserCompanyName } from '../../../utils/generalUtils';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { Title } from '../../reusableComponents/Title';
import { ConfirmationModal } from './ConfirmationModal';
import { ImageCardContainer } from './ImageCardContainer';
import './referencePage.scss'; 
import oshaIcon from'../../../images/oshaIcon.png';
import spiLogo from'../../../images/spiLogo.png'; 

export interface IReferences {
    referenceId: string;
    referenceLink: string;
    referenceTitle: string;
    isDeletable?: boolean;
    referenceBanner?: string;
}

export interface IReferencePageProps {
    referenceFiles: IReferences[];
    history?: IHistory;
    instance: UserModel;
}

export interface IReferencePageState {
    references: IReferences[];
    addEditClass: boolean;
    index: number;
    selectedIds: string[];
    selectedIndexes: number[];
}

export class ReferencePageImpl extends React.Component<IReferencePageProps, IReferencePageState> {

    constructor(props: IReferencePageProps) {
        super(props);
        this.state = { selectedIndexes: [], selectedIds: [], index: 0, references: [], addEditClass: false };
    }

    componentWillReceiveProps(nextProps: IReferencePageProps) {
        this.setState({
            references: nextProps.referenceFiles
        });
    }

    promise = async () => {
        await getAllReferences(getCurrentUserCompanyName());
    }

    handleSingleFileDelete = (index: number) => {
        showModal('delete-reference', 'Confirm Delete File?', this.state.references[index].referenceId);
    }

    handleOnMouseOver = (index: number) => {
        if (UserModel.checkUserAccess('reference_delete') ) {
            this.setState({
                index,
                addEditClass: true
            });
        }
    }

    handleOnMouseLeave = (index: number) => {
        if (UserModel.checkUserAccess('reference_delete') ) {
            this.setState({
                index,
                addEditClass: false
            });
        }
    }

    handleFileSelect = (index: number) => {
        const referenceId = this.state.references[index].referenceId;
        const referenceIds = this.state.selectedIds;
        referenceId && referenceIds.push(referenceId);
        const selectedIndexes = this.state.selectedIndexes;
        selectedIndexes.push(index);
        this.setState({
            selectedIndexes,
            selectedIds: referenceIds
        });

    }

    handleFileDeselect = (index: number) => {
        const referenceId = this.state.references[index].referenceId;
        const referenceIds = this.state.selectedIds.filter(id => id !== referenceId);
        const selectedIndexes = this.state.selectedIndexes.filter(indexVal => indexVal !== index);
        this.setState({
            selectedIndexes,
            selectedIds: referenceIds
        });
    }

    handleMultipleReferenceDelete = () => {
        showModal('delete-reference', 'Confirm Delete File?', this.state.selectedIds);
        this.setState({ selectedIndexes: [] });
    }

    renderContent = () => {
        return <div style={{ textAlign: 'center' }} >
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                {(this.state.references || []).length > 0 ?
                    this.state.references.map((reference, index) => {
                        return <ImageCardContainer
                            key={index}
                            selectedIndexes={this.state.selectedIndexes}
                            index={index}
                            activeIndex={this.state.index}
                            href={reference.referenceLink || ''}
                            src={reference.isDeletable ? spiLogo : (reference.referenceBanner? reference.referenceBanner:  oshaIcon)}
                            onSingleFileDelete={this.handleSingleFileDelete}
                            text={reference.referenceTitle || ''}
                            addEditClass={this.state.addEditClass}
                            onMouseOver={!reference.isDeletable ? () => this.handleOnMouseOver(index) : null}
                            onFileSelect={this.handleFileSelect}
                            onFileDeselect={this.handleFileDeselect}
                            onMouseLeave={!reference.isDeletable ? () => this.handleOnMouseLeave(index) : null}
                            isDeletable={reference.isDeletable || false }
                        />;
                    }) :
                    <div className="no-reference">No Reference Document Uploaded
                    {UserModel.checkUserAccess('reference_add') ?
                            <button
                                onClick={() => this.props.history && this.props.history.push('/reference/edit')}
                            >
                                Upload One Now?
                             </button>
                            : ''}
                    </div>}
            </div>
            {this.state.selectedIndexes.length > 0 ?
                <button onClick={this.handleMultipleReferenceDelete} >Delete All Selected </button> :
                ''}
            {UserModel.checkUserAccess('reference_add') && this.props.referenceFiles && this.props.referenceFiles.length > 0 ?
                <button
                    title="Click to Add/Upload New Reference"
                    className="add-new-report"
                    onClick={() => this.props.history && this.props.history.push('/reference/edit')}
                >
                    +
                </button>
                : ''}
        </div>;
    }

    render() {
        return (
            <BasePage>
                <div className="reference" >
                    <ConfirmationModal id="delete-reference" />
                    <AlertModal id="reference-delete-success" className="success-alert" />
                    <Title text="Reference Documents" />
                    <Async
                        identifier="ReferencePage"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    let userId = state.login.get('userId');
    const instance = UserModel.get(userId);
    return {
        instance,
        referenceFiles: state.miscellaneous.get('referenceFiles')
    };
}

export const ReferencePage =
    withRouter(connect<IReferencePageProps, any, any>(mapStateToProps)(ReferencePageImpl as any));
