import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BasePage } from '../../reusableComponents/BasePage';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { Row, Col } from 'react-bootstrap';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../../interfaces';
import { submitFeedback } from '../../../services/miscellaniousServices';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Title } from '../../reusableComponents/Title';
import { Alert } from '../../Alert/index';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { UserModel } from '../../../model/UserModel';

export interface IFeedbackFormProps {
    userUID: string;
    history?: IHistory;
}

export class FeedbackFormImpl extends React.PureComponent<IFeedbackFormProps, {}> {

    constructor(props: IFeedbackFormProps | Readonly<IFeedbackFormProps>) {
        super(props);
    }

    formModel = 'forms.feedbackForm';

    deviceTypes = [
        { label: 'IPhone', value: 'iPhone' },
        { label: 'IPad', value: 'iPad' },
        { label: 'Android Phone', value: 'androidPhone' },
        { label: 'Android Tablet', value: 'androidTablet' },
        { label: 'Other', value: 'other' },
    ];

    getChildContext() {
        return { formModel: this.formModel };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleSubmit = ({ deviceType, deviceModel, deviceOs, problemDescription, problemRecreation }: any) => {
        const { userUID, history } = this.props;
        const data = { userId: userUID, deviceType, deviceModel, deviceOs, problemDescription, problemRecreation, company: UserModel.getCompanyName() };
        if (isFormEmpty({ deviceType, deviceModel, deviceOs, problemDescription, problemRecreation })) {
            showAlert(EMPTY_FORM_MESSAGE, 'feedback-failed', 'danger');
            return;
        }
        return history && submitFeedback(data, history);
    }

    render() {
        return (
            <BasePage className="user-list-page-container">
                <div style={{ padding: '20px' }}>
                    <Title text="Feedback Form" />
                    <Alert id="feedback-failed" className="danger-alert" />
                    <AlertModal id="feedback-success" />
                    <Form loader={<SubmissionLoader />} model={this.formModel} onSubmit={this.handleSubmit}>
                        <Row style={rowStyle} className="show-grid">
                            <Label required htmlFor="deviceType">Device Type:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="dropdown"
                                    menuItems={this.deviceTypes}
                                    model=".deviceType"
                                    id="deviceType"
                                />
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label required htmlFor="deviceModel">Device Model:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".deviceModel"
                                    id="deviceModel"
                                    placeholder="Enter device model..."
                                />
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label required htmlFor="deviceOs">Device Operating System:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".deviceOs"
                                    id="deviceOs"
                                    placeholder="Enter device operating system..."
                                />
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label required htmlFor="problemDescription">Description Of Problem:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".problemDescription"
                                    id="problemDescription"
                                    placeholder="Enter desription of the problem..."
                                />
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label required htmlFor="problemRecreation">How to recreate the problem:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".problemRecreation"
                                    id="problemRecreation"
                                    placeholder="Enter how to recreate the problem..."
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <div
                                className="form-button"
                                style={{ paddingBottom: '20px', marginTop: '50px', textAlign: 'center' }}
                            >
                                <Button style={{
                                    color: '#5479AF',
                                    border: '1px solid #5479AF',
                                    backgroundColor: 'white',
                                }}
                                    redirectTo="home" type="reset"
                                    onClick={() => this.props.history?.goBack() }
                                >
                                    <i className="fa fa-ban" aria-hidden="true"></i>
                                    &nbsp;
                                Cancel
                        </Button>
                                <Button style={{
                                    color: '#FFFFFF',
                                    border: 'none',
                                    backgroundColor: '#26a65b'
                                }}
                                >  <i className="fa fa-plus" aria-hidden="true"></i>
                                    &nbsp;
                                Submit Feedback
                        </Button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </BasePage>
        );
    }
}

const rowStyle: React.CSSProperties = {
    paddingBottom: '20px'
};

export function mapStateToProps(state: any) {
    const userUID = state.login.get('userUID');
    return {
        userUID
    };
}

export const FeedbackForm = withRouter(connect<IFeedbackFormProps, any, any>(mapStateToProps)(FeedbackFormImpl));
