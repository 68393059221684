import * as React from 'react';
import { connect } from 'react-redux'; 
import PropTypes from 'prop-types';
import './fileInput.scss';  
import { Col } from 'react-bootstrap';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { getGroupsOfUser } from '../../../services/userService';
import { IForms } from '../../../interfaces'; 

export interface IGroupInputProps {  
    userId?: string;
    model: string;
    id: string;
    defaultValue?: string;
    forms?: IForms;
    isDisabled?: boolean;
    onGroupChange?: (value: any) => void;
}

export interface IGroupInputState { 
}

export class GroupInputImpl extends React.PureComponent<IGroupInputProps, IGroupInputState> {
    constructor(props: IGroupInputProps | Readonly<IGroupInputProps>) {
        super(props); 
    }

    componentWillMount() { 
    } 
    static contextTypes = {
        formModel: PropTypes.string
    };
    handleInputChange = async (value: any) => { 
        const { props: { onGroupChange } } = this;
        if(onGroupChange)
            return await onGroupChange(value);
    }
    render() {
        const { context: { formModel }, props: { model, id, forms, defaultValue } } = this; 
        const formName = (formModel || '').split('.');
        const projectName = (forms as any)[`${formName[1]}`]['projectName'];   
        return (
            <div><Label required sm={12} htmlFor="project">Group:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id={id}
                        type="dropdown"
                        menuItems={this.props.userId && getGroupsOfUser(this.props.userId, projectName)}
                        defaultValue={defaultValue}
                        model={model}
                        isDisabled={this.props.isDisabled || false} 
                        onSelect={this.handleInputChange}
                    />
                </Col></div>
        );
    }
}

export function mapStateToProps( state: any ) { 
    const userId = state.login.get('userId');  
    return {
        forms: state.forms, userId
    };
}

export const GroupInput = connect<{}, {}, IGroupInputProps>(mapStateToProps)(GroupInputImpl);
 