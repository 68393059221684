import { fromJS } from 'immutable';

import {
    HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL,
    HIDE_DELETE_CONCERN_MODAL,
    HIDE_EDIT_RULES_FORM,
    HIDE_GOLDEN_RULES_MODAL,
    HIDE_MAP_INPUT_MODAL,
    HIDE_MODAL,
    HIDE_PRINT_PREVIEW_MODAL,
    SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL,
    SHOW_DELETE_CONCERN_MODAL,
    SHOW_EDIT_RULES_FORM,
    SHOW_GOLDEN_RULES_MODAL,
    SHOW_MAP_INPUT_MODAL,
    SHOW_MODAL,
    SHOW_PRINT_PREVIEW_MODAL,
    SHOW_FOLLOW_UP_FORM_MODAL,
    HIDE_FOLLOW_UP_FORM_MODAL,
    SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL,
    HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL,
    SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL,
    HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL, SHOW_COVID19_ASSESSMENT_MODAL, HIDE_COVID19_ASSESSMENT_MODAL, 
    SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL, SHOW_AUDIT_INPUT_MODAL, HIDE_AUDIT_INPUT_MODAL,SHOW_CHECKLIST_MODAL, HIDE_CHECKLIST_MODAL, SHOW_MICROPHONE_MODAL, HIDE_MICROPHONE_MODAL
} from '../constants/actions';

export function modalReducer(state: any = fromJS({}), action: { type: any; id: any; modalHeading: any; instance: any; componentType: any; concernName: any; identifier: any; show: any; markers: any; fullModelName: any; center: any; model: any; defaultValue: any; }) {
    switch (action.type) {
        case SHOW_MODAL:
            return state.set('showModal', true).set('modalID', action.id).set('modalHeading', action.modalHeading)
                .set('instance', action.instance).set('componentType', action.componentType);
        case HIDE_MODAL:
            return state.set('showModal', false).set('modalID', '');
        case SHOW_PRINT_PREVIEW_MODAL:
            return state.set('previewModalID', action.id);
        case HIDE_PRINT_PREVIEW_MODAL:
            return state.set('previewModalID', '');
        case SHOW_GOLDEN_RULES_MODAL:
            return state.set('showGoldenRules', true);
        case HIDE_GOLDEN_RULES_MODAL:
            return state.set('showGoldenRules', false);
        case SHOW_EDIT_RULES_FORM:
            return state.set('showEditRulesForm', true);
        case HIDE_EDIT_RULES_FORM:
            return state.set('showEditRulesForm', false);
        case SHOW_DELETE_CONCERN_MODAL:
            return state.set('formID', action.id).set('concernName', action.concernName).set('identifier', action.identifier);
        case HIDE_DELETE_CONCERN_MODAL:
            return state.set('formID', '').set('concernName', '');
        case SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL:
            return state.set('showCreateCustomNotificationModal', action.show);
        case HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL:
            return state.set('showCreateCustomNotificationModal', action.show);
        case SHOW_MAP_INPUT_MODAL:
            return state.set('mapInput', { id: action.id, markers: action.markers, fullModelName: action.fullModelName, center: action.center });
        case HIDE_MAP_INPUT_MODAL:
            return state.set('mapInput', null);
        case SHOW_FOLLOW_UP_FORM_MODAL:
            return state.set('followUpModal', true);
        case HIDE_FOLLOW_UP_FORM_MODAL:
            return state.set('followUpModal', false);
        case SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL:
            return state.set('showCreateCloseCallFollowupModal', { instance:action.instance} );
        case HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL:
            return state.set('showCreateCloseCallFollowupModal', action.show );
        case SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL:
            return state.set('showCloseCallFollowupListModal', { instance:action.instance} );
        case HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL:
            return state.set('showCloseCallFollowupListModal', action.show );
        case SHOW_COVID19_ASSESSMENT_MODAL:
            return state.set('showCOVID19AssessmentModal', true);
        case HIDE_COVID19_ASSESSMENT_MODAL:
            return state.set('showCOVID19AssessmentModal', false);
        case SHOW_CONFIRMATION_MODAL:
            return state.set('showConfirmationModal', true).set('confirmationInstance', action.instance);
        case HIDE_CONFIRMATION_MODAL:
            return state.set('showConfirmationModal', false).set('onConfirmationInstance', action.instance);
            /* if(action.instance) {
                state.set('onConfirmationInstance', action.instance)
            }
            return state; */
        case SHOW_AUDIT_INPUT_MODAL:
            return state.set('auditInput',
                {  fullModelName: action.fullModelName });
        case HIDE_AUDIT_INPUT_MODAL:
            return state.set('auditInput', null);
        case SHOW_CHECKLIST_MODAL:
            return state.set('showChecklistAttributeModal', true).set('modalID', action.id).set('instance', action.instance);
        case HIDE_CHECKLIST_MODAL: 
            return state.set('showChecklistAttributeModal', false) ;
        case SHOW_MICROPHONE_MODAL:
            return state.set('microphoneModal', { model: action.model, defaultValue: (action.defaultValue + ' ')  });
        case HIDE_MICROPHONE_MODAL:
            return state.set('microphoneModal', null);
        default:
            return state.set('showModal', false).set('modalID', '');
    }
}
