import { IHistory } from '../../interfaces';

import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';

import { IGroupModelProps } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { submitGroupForm } from '../../services/groupService';
import { dispatch, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { GroupForm } from './GroupForm';
import './addGroupForm.scss';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { format } from 'date-fns';

export interface ICreateFormProps {
    userId: string;
    instance?: UserModel[];
    history?: IHistory;
}

export interface ICreateFormState {
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, ICreateFormState> {

    constructor(props: ICreateFormProps | Readonly<ICreateFormProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.groupForm'));
    }

    handleSubmit = (submittedValues: IGroupModelProps) => {
        submittedValues.dateCreated = format(new Date(), 'yyyy-MM-dd');
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'add-group', 'danger');
            return;
        }
        return this.props.history && submitGroupForm(submittedValues, this.props.history);
    }

    render() {
        return (
            <BasePage>
                <Grid style={{ width: '100%' }} className="add-group">
                    <Title text="Add Group" />
                    <AlertModal id="group-modal" />
                    <Alert className="danger-alert" id="add-group" />
                    <GroupForm onSubmit={this.handleSubmit} />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        userId: state.login.get('userId'),
    };
}
export const CreateForm = withRouter(connect<ICreateFormProps, any, any>(mapStateToProps)(CreateFormImpl));
