import { fromJS } from 'immutable';
import {
    SAVE_ATTRIBUTE_DATA, DELETE_ATTRIBUTE_DATA,
    INITALIZE_ATTRIBUTE_DATA,
    EDIT_ATTRIBUTE_DATA,
    NAVIGATE_ADD_ATTRIBUTE,
    EMPTY_ATTRIBUTE_LIST 
} from '../constants/actions';
export function attributeReducers(state: any = fromJS({
    attributeList: [], isEditAttribute: false,
    formType: '', editingAttribute: {}
}), action: { type: any; attribute: any; attributeList: any; isEditAttribute: any; formType: any; }) {
    switch (action.type) {
        case SAVE_ATTRIBUTE_DATA:
            if (state.get('attributeList')) {
                return state.set('attributeList', [...state.get('attributeList'), action.attribute]);
            } else {
                return state.set('attributeList', [action.attribute]);
            } 
        case DELETE_ATTRIBUTE_DATA:
            return state.set('attributeList', action.attributeList);
        case INITALIZE_ATTRIBUTE_DATA:
            return state.set('attributeList', action.attributeList);
        case EDIT_ATTRIBUTE_DATA:
            state.set(
                'isEditAttribute', action.isEditAttribute
            );
            return state.set(
                'editingAttribute', action.attribute
            );
        case NAVIGATE_ADD_ATTRIBUTE:
            return state.set(
                'formType', action.formType
            );
        case EMPTY_ATTRIBUTE_LIST:
            return state.set(
                'attributeList', null
            );
        default:
            return state;
    }
}
