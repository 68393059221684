import './officeSafetyForm.scss'; 
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IForms, IHistory, ISelectOptions } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { IOfficeSafetyModelProps, OfficeSafetyModel } from '../../model/OfficeSafetyModel';
import { ProjectModel } from '../../model/ProjectModel';
import { getAllGroups } from '../../services/groupService';
import { submitOfficeSafetyForm } from '../../services/officeSafetyFormService';
import { getAllProjects } from '../../services/projectService';
import { constructAnswers, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { FileInput } from '../reusableComponents/FormComponents/FileInput'; 
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import { UserModel } from '../../model/UserModel';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IOfficeSafetyFormProps {
    history?: IHistory;
    forms: IForms;
    userUID: string;
    userId: string;
    company: string;
    userInstance?: UserModel;
    editInstance?: OfficeSafetyModel;
    editId?: string;
}

export interface IOfficeSafetyFormState {
    currentDate: string;
    currentTime: string;
    longitude: number;
    latitude: number;
    locationNameOptions: ISelectOptions[]; 
}

export class OfficeSafetyFormImpl extends React.PureComponent<IOfficeSafetyFormProps, IOfficeSafetyFormState> { 
    constructor(props: IOfficeSafetyFormProps | Readonly<IOfficeSafetyFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'),
            currentTime: format(new Date(), 'HH:mm'),
            longitude: 0, latitude: 0, locationNameOptions: [{ value: 'SPI 1029', label: 'SPI 1029' },
            { value: 'SPI 1033', label: 'SPI 1033' },
            { value: 'BNSF', label: 'BNSF' },
            { label: '7 Eleven', value: '7 Eleven' }], 
        }; 
    }

    getChildContext() {
        return { formModel: 'forms.officeSafetyForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    promise = async () => {
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        return null;
    }

    getLocation = (position: { coords: { latitude: number, longitude: number } }) => {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        return position;
    }

    handleSubmit = ({ observer, dateOfIncident, timeOfIncident, location, locationName, groupName, projectName,
        eyesOnPath, ascendingDescending, onTheMoveComments, toolOrEquipment, conditionUse,
        toolSelectionComments, liftingPoweringTwisting, pushingPulling, manualHandlingComments, ergonomicsWorkstation,
        ergonomicsComments, lineOfFire, pinchPoint, eyesOnTask, bodyPositionComments,
        foodSafety, cleanliness, healthHygieneComments, tailgating, personalProperty,
        hskAccessEgress, barricading, securityComments, hskStorageHousekeeping, mobileLocation,
        housekeepingComments, doubleSidePrinting, recycling, computerShutdown, envRecyclingComments
    } : IOfficeSafetyModelProps) => {
        const { userUID, history, forms, editId } = this.props; 
        if (isFormEmpty({ dateOfIncident,timeOfIncident, locationName, groupName, projectName, observer, })) {
            showAlert(EMPTY_FORM_MESSAGE, 'office-safety-form', 'danger');
            return;
        } 
        const answers = constructAnswers({
            reportDate: dateOfIncident, reportTime: timeOfIncident, location: mobileLocation,
            locationName, eyesOnPath, ascendingDescending, onTheMoveComments,
            toolOrEquipment, conditionUse, toolSelectionComments, liftingPoweringTwisting, pushingPulling,
            manualHandlingComments, ergonomicsWorkstation, ergonomicsComments, lineOfFire, pinchPoint, eyesOnTask,
            bodyPositionComments, foodSafety, cleanliness, healthHygieneComments, tailgating, personalProperty,
            barricading, securityComments, hskAccessEgress,
            hskStorageHousekeeping, housekeepingComments, doubleSidePrinting,
            recycling, computerShutdown, envRecyclingComments
        });
        return history && submitOfficeSafetyForm(answers, forms, history, userUID, groupName, projectName, dateOfIncident, editId);
    }

    renderContent = () => {
        const { state: { currentDate, currentTime }, props: { editInstance, editId, history, forms } } = this;
        if(editId && !editInstance) {
            history?.push('/office-safety-reports');
        }  
        return (
            <Grid className="office-safety-form" style={{ width: '100%' }}>
                <MapInputModal id="office-safety-form" />
                <Form loader={<SubmissionLoader />} model="forms.officeSafetyForm" onSubmit={this.handleSubmit}>
                    <Title titleColor="yellow" text="Office O&amp;I" noNeedBorder={true}/>
                    <AlertModal id="office-safety-modal" />
                    <fieldset>
                        <legend><span style={{ color: 'yellow' }}>Office Safety</span></legend>
                        <Row className="show-grid" >
                            <Col sm={4}>
                                <Label required sm={12} htmlFor="currentDate">Date of Observation:</Label>
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date"
                                        defaultValue={editInstance ? format(new Date(editInstance.props.dateReported), 'yyyy-MM-dd') : currentDate}
                                        model=".dateOfIncident"
                                        maxdate={editInstance? format(new Date(editInstance.props.date), 'yyyy-MM-dd'):currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={4}>
                                <Label sm={12} htmlFor="currentTime">Time of Observation:</Label>
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="currentTime"
                                        defaultValue={editInstance ? editInstance.props.timeOfIncident : currentTime}
                                        type="time"
                                        model=".timeOfIncident"
                                    />
                                </Col>
                            </Col>
                            <LocationInput 
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="office-safety-form"
                                navigator={navigator}
                                defaultValue={editInstance && editInstance.props.locationName}
                            />
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={editInstance ? editInstance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} />
                            </Col>
                            <Col sm={4} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={editInstance ? editInstance.props.groupName : this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]} />
                            </Col> 
                            <Col sm={4}>
                                <Label required sm={12}>Observer:</Label>
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="observer"
                                        type="text"
                                        defaultValue={editInstance ? editInstance.props.username : this.props.userInstance && this.props.userInstance.props.firstName}
                                        model=".observer"
                                        isDisabled={true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            On The Move:
                        </div>
                        <Row className="checkbox-row two-buttons">
                            <Col sm={6} className="button-container first">
                                <RadioButton labelSm={6} radioSm={6} id="eyesOnPath" defaultValue={editInstance && editInstance.props.eyesOnPath}>Eyes On Path:</RadioButton>
                            </Col>
                            <Col sm={6} className="button-container">
                                <RadioButton
                                    labelSm={6}
                                    radioSm={6}
                                    id="ascendingDescending" defaultValue={editInstance && editInstance.props.ascendingDescending}
                                >
                                    Ascending/Descending:
                            </RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="onTheMoveComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.onTheMoveComments} model="forms.officeSafetyForm.onTheMoveComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="onTheMoveComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".onTheMoveComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.onTheMoveComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Facility/Equipment/Tool:
                        </div>
                        <Row className="checkbox-row two-buttons">
                            <Col sm={6} className="button-container first">
                                <RadioButton
                                    labelSm={12}
                                    radioSm={12}
                                    id="toolOrEquipment" defaultValue={editInstance && editInstance.props.toolOrEquipment}
                                >
                                    Tool or Equipment Selection:
                            </RadioButton>
                            </Col>
                            <Col sm={6} className="button-container">
                                <RadioButton labelSm={6} radioSm={6} id="conditionUse" defaultValue={editInstance && editInstance.props.conditionUse}>Condition/Use:</RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}> 
                                <Label sm={12} htmlFor="toolSelectionComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.toolSelectionComments} model="forms.officeSafetyForm.toolSelectionComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="toolSelectionComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".toolSelectionComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.toolSelectionComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Manual Handling:
                        </div>
                        <Row className="checkbox-row two-buttons">
                            <Col sm={6} className="button-container first">
                                <RadioButton
                                    labelSm={6}
                                    radioSm={6}
                                    id="liftingPoweringTwisting" defaultValue={editInstance && editInstance.props.liftingPoweringTwisting}
                                >
                                    Lifting/Powering/Twisting:
                              </RadioButton>
                            </Col>
                            <Col sm={6} className="button-container">
                                <RadioButton labelSm={6} radioSm={6} id="pushingPulling" defaultValue={editInstance && editInstance.props.pushingPulling}>Pushing/Pulling:</RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="manualHandlingComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.manualHandlingComments} model="forms.officeSafetyForm.manualHandlingComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="manualHandlingComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".manualHandlingComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.manualHandlingComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Ergonomics:
                        </div>
                        <Row className="single-button">
                            <RadioButton
                                labelSm={6}
                                radioSm={6}
                                id="ergonomicsWorkstation" defaultValue={editInstance && editInstance.props.ergonomicsWorkstation}
                            >
                                Posture/Chair/Workstation:
                         </RadioButton>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}> 
                                <Label sm={12} htmlFor="ergonomicsComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.ergonomicsComments} model="forms.officeSafetyForm.ergonomicsComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="ergonomicsComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".ergonomicsComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.ergonomicsComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Body Position:
                        </div>
                        <Row className="checkbox-row three-buttons">
                            <Col sm={4}>
                                <RadioButton labelSm={12} radioSm={12} id="lineOfFire" defaultValue={editInstance && editInstance.props.lineOfFire}>Line Of Fire:</RadioButton>
                            </Col>
                            <Col sm={4} className="checkbox-center">
                                <RadioButton labelSm={12} radioSm={12} id="pinchPoint" defaultValue={editInstance && editInstance.props.pinchPoint}>Pinch Point:</RadioButton>
                            </Col>
                            <Col sm={4}>
                                <RadioButton labelSm={12} radioSm={12} id="eyesOnTask" defaultValue={editInstance && editInstance.props.eyesOnTask}>Eyes On Task:</RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}> 
                                <Label sm={12} htmlFor="bodyPositionComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.bodyPositionComments} model="forms.officeSafetyForm.bodyPositionComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="bodyPositionComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".bodyPositionComments"
                                        textAreaRows={3} 
                                        defaultValue={editInstance && editInstance.props.bodyPositionComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Health &amp; Hygiene:
                        </div>
                        <Row className="checkbox-row two-buttons">
                            <Col sm={6} className="button-container first">
                                <RadioButton labelSm={6} radioSm={6} id="foodSafety" defaultValue={editInstance && editInstance.props.foodSafety}>Food Safety:</RadioButton>
                            </Col>
                            <Col sm={6} className="button-container">
                                <RadioButton
                                    labelSm={6}
                                    radioSm={6}
                                    id="cleanliness" defaultValue={editInstance && editInstance.props.cleanliness}
                                >
                                    Cleanliness &amp; Spills:
                            </RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="healthHygieneComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.healthHygieneComments} model="forms.officeSafetyForm.healthHygieneComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="healthHygieneComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".healthHygieneComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.healthHygieneComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Security:
                    </div>
                        <Row className="checkbox-row three-buttons">
                            <Col sm={4}>
                                <RadioButton labelSm={12} radioSm={12} id="tailgating" defaultValue={editInstance && editInstance.props.tailgating}>Tailgating:</RadioButton>
                            </Col>
                            <Col sm={4} className="checkbox-center">
                                <RadioButton
                                    labelSm={12}
                                    radioSm={12}
                                    id="personalProperty" defaultValue={editInstance && editInstance.props.personalProperty}
                                >
                                    Personal Property:
                                </RadioButton>
                            </Col>
                            <Col sm={4}>
                                <RadioButton labelSm={12} radioSm={12} id="barricading" defaultValue={editInstance && editInstance.props.barricading}>Barricading:</RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="securityComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.securityComments} model="forms.officeSafetyForm.securityComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="securityComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".securityComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.securityComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Housekeeping:
                        </div>
                        <Row className="checkbox-row two-buttons">
                            <Col sm={6} className="button-container first">
                                <RadioButton labelSm={6} radioSm={6} id="hskAccessEgress" defaultValue={editInstance && editInstance.props.hskAccessEgress}>Access / Egress:</RadioButton>
                            </Col>
                            <Col sm={6} className="button-container">
                                <RadioButton
                                    labelSm={6}
                                    radioSm={6}
                                    id="hskStorageHousekeeping" defaultValue={editInstance && editInstance.props.hskStorageHousekeeping}
                                >
                                    Storage/Housekeeping:
                                </RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="housekeepingComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.housekeepingComments} model="forms.officeSafetyForm.housekeepingComments" /> 
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="housekeepingComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".housekeepingComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.housekeepingComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <div className="action-label" id="action">
                            Environment &amp; Recycling:
                        </div>

                        <Row className="checkbox-row three-buttons">
                            <Col sm={4}>
                                <RadioButton
                                    labelSm={12}
                                    radioSm={12}
                                    id="doubleSidePrinting" defaultValue={editInstance && editInstance.props.eyesOnPath}
                                >
                                    Double Sided Printing:
                                </RadioButton>
                            </Col>
                            <Col sm={4} className="checkbox-center">
                                <RadioButton labelSm={12} radioSm={12} id="recycling" defaultValue={editInstance && editInstance.props.recycling}>Recycling:</RadioButton>
                            </Col>
                            <Col sm={4}>
                                <RadioButton
                                    labelSm={12}
                                    radioSm={12}
                                    id="computerShutdown" defaultValue={editInstance && editInstance.props.computerShutdown}
                                >
                                    Computer Shutdown:
                        </RadioButton>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={{span: '6', offset:'3'}}>
                                <Label sm={12} htmlFor="envRecyclingComments">Comments:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.officeSafetyForm.envRecyclingComments} model="forms.officeSafetyForm.envRecyclingComments" />
                                <Col xs={12} sm={12} className="input">
                                    <RRFInput
                                        id="envRecyclingComments"
                                        type="textarea"
                                        placeholder="Comments..."
                                        model=".envRecyclingComments"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.envRecyclingComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={6} xs={12}>
                                <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                                <Col sm={12} className="input">
                                    <FileInput
                                        model=".officeSafetyImages"
                                        multiple={true}
                                        id="upload-images"
                                        defaultValue={editInstance && editInstance.props.imageUrls}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                                <Col sm={12} className="input">
                                    <VideoInput
                                        model=".officeSafetyVideos"
                                        multiple={true}
                                        id="upload-videos"
                                        defaultValue={editInstance && editInstance.props.videoUrls}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                    </fieldset>
                    <Row className="show-grid">
                        <div className="form-button" style={{ marginTop: '10px' }}> 
                            <Alert className="danger-alert" id="office-safety-form" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                                </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                &nbsp;
                                { editInstance ? 'Update' : 'Submit' }
                                    </Button>
                        </div>
                    </Row>
                </Form>
            </Grid >
        );
    }

    render() {
        return <BasePage className="safeconnect-form" >
            <Async
                identifier="GroupForm"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderContent()}
            />
        </BasePage >;
    }
}

const RadioButton = ({ labelSm, radioSm, id, defaultValue, children }: any) => { 
    return <div>
        <Label className="radio-label" required={false} sm={labelSm} htmlFor={`${id}`}>{children}</Label>
        <Col sm={radioSm} className="work-related">
            <div style={radioContainer} >
                <label htmlFor={`${id}Safe`}>Safe</label>
                <RRFInput
                    id={`${id}Safe`}
                    type="radio"
                    model={`.${id}`}
                    radioValue="Safe"
                    radioName={`${id}`}   
                    defaultValue={defaultValue && defaultValue==='Safe' ? defaultValue : null }
                /><label style={radioButton} htmlFor={`${id}Safe`}></label>
            </div>
            <div style={radioContainer} >
                <label htmlFor={`${id}AtRisk`}>At Risk</label>
                <RRFInput
                    id={`${id}AtRisk`}
                    type="radio"
                    model={`.${id}`}
                    radioValue="At Risk"
                    radioName={`${id}`} 
                    defaultValue={defaultValue && defaultValue==='At Risk' ? defaultValue : null }
                /> <label style={radioButton} htmlFor={`${id}AtRisk`}></label>
            </div>
        </Col>
    </div>;
};

const radioButton: React.CSSProperties = {
    verticalAlign: 'middle',
    margin: '0',
    marginLeft: '10px'
};

const radioContainer: React.CSSProperties = {
    display: 'inline-block',
    padding: '0 10px'
};

export function mapStateToProps(state: any, ownProps: any) {
    const userUID = state.login.get('userUID');
    const userId = state.login.get('userId');
    const company = state.login.get('company');
    let userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    const editInstance = OfficeSafetyModel.get(ownProps.match.params.id); 
    return {
        userInstance,
        userUID, userId, company,
        forms: state.forms, editId, editInstance
    };
}

export const OfficeSafetyForm = withRouter(connect<IOfficeSafetyFormProps, any, any>(mapStateToProps)(OfficeSafetyFormImpl));
