import * as React from 'react';
import { IHistory, IImmutableObject } from '../../../interfaces';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import { Row } from 'react-bootstrap';
import { Cell } from '../../reusableComponents/ChartComponents/Cell';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FeedbackModel } from '../../../model/FeedbackModel';

export interface IListItemProps {
    instance?: FeedbackModel;
    index?: number;
    currentUserInstance: IImmutableObject;
    style?: React.CSSProperties;
    history?: IHistory;
}

export class ListItemImpl extends React.Component<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderListItem = () => {
        const { instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row
                onClick={() => this.props.history?.push(`/submitted-feedback/details/${instance.props.id}`)}
                className="report-row" style={style}
            >
                <Cell sm={1}>{index || 0 + 1}</Cell>
                <Cell sm={4}>{instance.props.os || 'NA'}</Cell>
                <Cell sm={4}>{instance.props.device || 'NA'}</Cell>
                <Cell sm={3}>{instance.props.date || 'NA'}</Cell>
            </Row >
        );
    }

    render() {
        return this.renderListItem();
    }
}

export function mapStateToProps(state: any) {
    const currentUserInstance = state.login.get('currentUserInfo');
    return {
        currentUserInstance
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
