import { BaseModel } from './BaseModel'; 
import { UserModel } from './UserModel';

export interface IPersonalSafetyModelProps {
    uniqueId: string;
    userId: string;
    time: string;
    userUniqueId: string;
    name: string;
    company: string;
    credebilityHelp: string;
    credibilityDate: string;
    actionHelp: string;
    actionDate: string;
    resilienceHelp: string;
    resilienceDate: string;
    engagementHelp: string;
    engagementDate: string;
    considerationsHelp: string;
    considerationsDate: string;
    followUp: number;
    projectName: string;
    groupName: string;
    supervisor: string;
    supervisorName: string;
    fullName: string;
    id: string;
    date: string;
    dateCreated: string;
    imageUrls: string[];
    videoUrls: string[];
    credebilityComment: string;
    actionComment: string;
    resilienceComment: string;
    engagementComment: string;
    considerationsComment: string;
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string;
}

export class PersonalSafetyModel extends BaseModel<IPersonalSafetyModelProps> {
    constructor(props: IPersonalSafetyModelProps) {
        super(props, PersonalSafetyModel.resource);
    }

    static getCurrentUsersForms(userUID: string) {
        const instances = PersonalSafetyModel.list();
        if (instances.length <= 0) {
            return;
        }
        let usersForms: any[] = [];
        instances.map((instance: { props: { userUniqueId: string; }; }) => {
            if (instance.props.userUniqueId === userUID) {
                usersForms.push(instance);
            }
        });
        return usersForms;
    }

    static getOtherUsersForm(userUID: string) {
        const instances = PersonalSafetyModel.list();
        if (instances.length <= 0) {
            return;
        }
        let otherUsersForms: any[] = [];
        const userInstance: any = UserModel.getLoggedUserInstance(); 
        if(userInstance['projects']) {
            instances.map((instance: { props: { userUniqueId: string; projectName: any; }; }) => {  
                if (instance.props.userUniqueId !== userUID && userInstance['projects'].indexOf(instance.props.projectName)!==-1 ) { 
                    otherUsersForms.push(instance);
                }
            });
        }
        return otherUsersForms;
    }

    static resource = 'personal-safety';
}
