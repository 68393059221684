import * as React from 'react';
import { Col, Row, Container as Grid } from 'react-bootstrap'; 
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IHistory, ISelectOptions } from '../../interfaces'; 
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Label } from '../reusableComponents/FormComponents/Label'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general'; 
import { isFormEmpty, showAlert } from '../../utils/generalUtils';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Form } from '../reusableComponents/FormComponents/Form';  
import { submitChecklistName, getAllCheckListNames } from '../../services/checklistService';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { ChecklistNameModel } from '../../model/ChecklistModel';  

export interface ICreateAuditFormProps { 
    onSubmit?: (data: Object) => void;
    history?: IHistory;
    onCancel?: () => void;
    userUID: string;
    checklistName?: string;
    referenceForm?: string;
    checkListNameInstances: ChecklistNameModel[];
}

export interface ICreateAuditFormState { 
    formTypes: ISelectOptions[];
}

export class CreateAuditFormImpl extends React.PureComponent<ICreateAuditFormProps, ICreateAuditFormState> {

    constructor(props: ICreateAuditFormProps | Readonly<ICreateAuditFormProps>) {
        super(props);
        this.state = {formTypes: [{ value: 'OI', label: 'O&I' }, { value: 'Office OI', label: 'Office O&I' }, { value: 'JSA', label: 'JSA' }], };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    getChildContext() {
        return { formModel: 'forms.auditFormName' };
    }
    promise = async () => { 
        await getAllCheckListNames() 
        const { checkListNameInstances } = this.props;
        checkListNameInstances.forEach((item) => {  
            this.state.formTypes.push({value: item.props.id, label: item.props.checklistName })
        }) 
    }

    handleFormSubmit = async ({ checklistName, referenceForm}: ICreateAuditFormProps) => { 
        const submittedValues = { checklistName, referenceForm }; 
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'create-checklist', 'danger');
            return;
        } 
        return checklistName && submitChecklistName(submittedValues, this.props.history, 'auditChecklistForm',);  
    }

    renderContent = () => { 
        return (
            <Grid className="oi-form" style={{ width: '100%' }}>
            <div>
                <Row className="show-grid">
                    <Label required htmlFor="checklistName">Audit/Inspection Form Name:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="checklistName"
                            type="text"
                            placeholder="Enter audit name..."
                            model=".checklistName" 
                        />
                    </Col>
                </Row> 
                <Row className="show-grid">
                    <Label required htmlFor="referenceForm">Reference Form Type:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="referenceForm" 
                            model=".referenceForm"
                            type="dropdown" 
                            menuItems={this.state.formTypes}
                        />
                    </Col>
                </Row> 

                <Row className="show-grid">
                    <div className="form-button" style={{ marginTop: '50px' }} >
                        <Button
                            style={{
                                color: '#5479AF',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white'
                            }}
                            onClick={() => this.props.history?.goBack() }
                            redirectTo="home" type="reset" >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26a65b'
                            }} > 
                                <i className="fa fa-plus" aria-hidden="true"></i> 
                            &nbsp;
                            Add
                        </Button>
                    </div>
                </Row>
            </div>
            </Grid>
        );
    }
 
    /* render() {
        return (
            <BasePage className="min-wide-list-page">
                <Grid className="checklist-form" style={{ width: '100%', padding: '20px', }}>
                    <Title text="Add Audit & Inspection Form" /> 
                    <Form loader={<SubmissionLoader />} model="forms.auditFormName" onSubmit={this.handleFormSubmit}>
                        <AlertModal id="create-checklist-modal" />
                        <Alert id="create-checklist" className="danger-alert" /> 
                        {this.renderContent()}
                    </Form>
                </Grid>
            </BasePage>
        );
    } */
    renderFormContent() {
        return (<Grid className="checklist-form" style={{ width: '100%', padding: '20px', }}>
            <Title text="Add Audit & Inspection Form" /> 
            <Form loader={<SubmissionLoader />} model="forms.auditFormName" onSubmit={this.handleFormSubmit}>
                <AlertModal id="create-checklist-modal" />
                <Alert id="create-checklist" className="danger-alert" /> 
                {this.renderContent()}
            </Form>
        </Grid>)
    }
    render() {
        return (
            <BasePage className="min-wide-list-page"> 
                <Async
                    identifier="auditForm"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderFormContent()}
                />
            </BasePage>
        );
    }
}
 
export function mapStateToProps(state: any) {
    const userUID = state.login.get('userUID');
    const checkListNameInstances = ChecklistNameModel.list(); 
    return {
        userUID, checkListNameInstances
    };
}
export const CreateAuditForm = withRouter(connect<ICreateAuditFormProps, any, any>(mapStateToProps)(CreateAuditFormImpl)); 