import './autoGenerateSignature.scss'; 
import * as React from 'react';
import Select from 'react-select'; 
import { SelectOptionComponent } from './SelectOptionComponent'; 
import { Row, Col } from 'react-bootstrap'; 
declare var require: any;
const html2canvas = require('html2canvas');

interface IAutoSignature {
    name?: string;
    width?: string;
    height?: string;
    fontSize?: string;
    toDataURL: (base64: string) => void;
    className?: string;
}

interface IAutoSignatureState {
    selectedOption: string;
}

export class AutoGenerateSignature extends React.Component<IAutoSignature, IAutoSignatureState> {

    constructor(props: IAutoSignature | Readonly<IAutoSignature>) {
        super(props);
        this.state = { selectedOption: props.name || 'Font1' };
    }

    handleSave = () => {
        const self = this; 
        html2canvas(document.getElementById('signature')).then((canvas: { toDataURL: () => any; }) => {
            try { 
                const dataURL = canvas.toDataURL();  
                self.props.toDataURL(dataURL) ;
            } catch (error) {
                console.error(error);
            }
        });
    }

    handleChange = (obj: any ) => {
        this.setState({
            selectedOption: obj ? obj.value : ''
        });
    }

    render() {
        return (
            <div className={`${this.props.className} auto-e-signature-generator` }>
                <div
                    className="signature-container b"
                    id="signature"
                    style={{
                       /* width: `${this.props.width || '300px'}`,
                        height: `${this.props.height || '80px'}`, */
                        fontSize: `${this.props.fontSize || '30px'}`,
                        border: '1px solid #d3d3d3',
                        fontFamily: this.state.selectedOption
                    }}>
                    {this.props.name || 'Demo'}
                </div>
                <div className="footer-container" style={{
                    /* width: `${this.props.width || '300px'}`, */
                }} >
                    <Row>
                        <Col sm="6">
                            <button
                                type="button"
                                className="react-signature-button button"
                                onClick={this.handleSave}
                            >
                                Save
                            </button> 
                        </Col>
                        <Col sm="6">
                            <Select
                                name="form-field-name"
                                //inputValue={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={[
                                    { value: 'Font1', label: 'Style 1' /* (this.props.name || 'Demo') */ },
                                    { value: 'Font2', label: 'Style 2' },
                                    { value: 'Font3', label: 'Style 3' },
                                    { value: 'Font4', label: 'Style 4' },
                                    { value: 'Font5', label: 'Style 5' },
                                ]}
                                valueComponent={CustomOptionComponent}
                                optionComponent={SelectOptionComponent}
                                isClearable={false}
                            /> 
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }
}

const CustomOptionComponent = ({ value, option, children }: any) => {
    return (
        <div className="Select-value" style={{ paddingRight: '30px' }}>
            <span className="Select-value-label">
                <div style={{ fontFamily: value ? value.value : '' }}>
                    {children || 'NA'}
                </div>
            </span>
        </div >
    );
};
