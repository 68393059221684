import * as React from 'react';
import { ResponsiveContainer, Bar, Cell, Tooltip, BarChart as RBarChart, YAxis, CartesianGrid, XAxis } from 'recharts';

export interface IBarChartProps {
    data: Object[];
    isAnimationActive?: boolean;
    barColors: string[];
}

export class BarChart extends React.PureComponent<IBarChartProps, {}> {
    constructor(props: IBarChartProps | Readonly<IBarChartProps>) {
        super(props);
    }

    render() {
        const { data, barColors, isAnimationActive } = this.props;
        const keys = Object.keys(data[0]);
        return (
            <ResponsiveContainer>
                <RBarChart width={600} height={300} data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey={keys[0]} allowDecimals={false}/>
                    <YAxis allowDecimals={false}/>
                    <CartesianGrid strokeDasharray="3" />
                    <Tooltip />
                    <Bar isAnimationActive={isAnimationActive} dataKey={keys[1]} fill="#8884d8">
                        {barColors.map((color, index) => {
                            return <Cell key={index} fill={`#${color}`} />;
                        })}
                    </Bar>
                </RBarChart>
            </ResponsiveContainer>
        );
    }
}
