import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { IHistory } from '../../interfaces';
import { OIModel } from '../../model/OIModel'; 
import { convertTime, KeyValue } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import { Img } from '../reusableComponents/ImageComponents/Img';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay';
import { Button } from '../reusableComponents/FormComponents/Button'; 
import noImage from '../../images/noImage.png';

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    oiInstance: OIModel;
}
export interface IDetailsPageState {
    imageURLs: string[];
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''] };
    }

    promise = async () => {
        /* if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if (OIModel.list().length <= 0) {
            await getAllOIForms();
        } */
        return null;
    }

    renderImages = () => {
        const { oiInstance } = this.props;
        if (oiInstance && oiInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect"/>;
        }
        const { props: { imageUrls } } = oiInstance;

        return imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    }

    /* getObserverName = (userID: any) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    } */

    renderContent = () => {
        const { oiInstance } = this.props;
        if (!oiInstance) {
            return <Loader />;
        } 
        return (
            <div>
                <Title text={`O&I Report for ${oiInstance.props.dateReported}`}></Title>
                <KeyValue label="Time">{oiInstance.props.timeOfIncident ?
                    convertTime(oiInstance.props.timeOfIncident) : 'NA'}</KeyValue>
                <KeyValue label="Location Details">{oiInstance.props.location || 'NA'}</KeyValue>
                <KeyValue label="Location Name">{oiInstance.props.specifiedLocation || 'NA'}</KeyValue>
                {/* <KeyValue label="Observer">{this.getObserverName(oiInstance.props.observer) || 'NA'}</KeyValue> */}
                <KeyValue label="Observer">{oiInstance.props.username || 'NA'}</KeyValue>
                <KeyValue label="Team">{oiInstance.props.projectName || 'NA'}</KeyValue>
                <KeyValue label="Group">{oiInstance.props.groupName || 'NA'}</KeyValue>
                <KeyValue label="Position/Project Role">{oiInstance.props.observerPosition || 'NA'}</KeyValue>
                <KeyValue label="Observation Type">{oiInstance.props.observationType || 'NA'}</KeyValue>
                <KeyValue label="Concern Type">{oiInstance.props.concernType || 'NA'}</KeyValue>
                <KeyValue label="Activity">{oiInstance.props.activity || 'NA'}</KeyValue>
                <KeyValue label="Near Miss">
                    {oiInstance.props.nearMiss ? (oiInstance.props.nearMiss === '1' ? 'Yes' : 'No') : 'NA'}
                </KeyValue>
                <KeyValue label="Observation">{oiInstance.props.observation || 'NA'}</KeyValue>
                <KeyValue label="Consequence">{oiInstance.props.consequence || 'NA'}</KeyValue>
                <KeyValue label="Action Taken or Suggested">{oiInstance.props.actionTaken || 'NA'}</KeyValue>
                <KeyValue label="Images">{this.renderImages()}</KeyValue>
                <KeyValue label="Videos"><VideoPlay videoUrls={oiInstance.props.videoUrls}/></KeyValue>
            </div >
        );
    }

    renderFormDetails = () => { 
        return (
            <Async
                identifier="OIDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        let fileName = '';
        if (this.props.oiInstance) {
            const { oiInstance: { props: { dateOfIncident } } } = this.props;
            fileName = `O&I Report for ${dateOfIncident}`;
        } 
        return (
            <BasePage>
                <Gallery images={this.props.oiInstance ? this.props.oiInstance.props.imageUrls : []} />
                <DetailsPageWrapper pdfFileName={fileName} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const oiInstance = OIModel.get(ownProps.match.params.id);
    return {
        oiInstance
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
