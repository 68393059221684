import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SafetyWalkthroughReportChartData } from '../../model/SafetyWalkthroughModel';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { saveFilterData } from '../../actions/miscellaneousActions';
import { IChartValue, IForms } from '../../interfaces'; 
import { applyMonthlyFilterCloseCallSummary, applyYearlyFilterCloseCallSummary, getCloseCallReportSummaryData } from '../../services/attributeService';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { Card } from '../reusableComponents/Card'; 
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title'; 

export interface ICloseCallReportsDataProps {
    reportsData: SafetyWalkthroughReportChartData;
    getRef?: (value: any) => void;
    getFilterBy?: (value: any) => void;
    setFilterBy?: (value: any) => void;
    id: string;
    isPrint: boolean;
    filterBy: string;
    barChartData?: {
        positiveObservations: number;
        negativeObservations: number;
    };
    isAnimationActive?: boolean;
    forms?: IForms;
}
export interface ICloseCallReportsDataState {
    view: boolean;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
    filterBy: string;
}

export class CloseCallReportData extends React.Component<ICloseCallReportsDataProps,
    ICloseCallReportsDataState> {
    constructor(props: ICloseCallReportsDataProps) {
        super(props);
        this.state = {
            view: false,
            data: [],
            legends: [],
            totalFormsSubmitted: 0,
            pieChartData: {},
            showMonthlyFilter: false, showYearlyFilter: false,
            filterBy: 'monthly',
            isLoading: false,
        };
    } 
    ref: any;  
    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return (
            <YearlyFilter onApply={(year) => {
                setLoading('ReportsPage');
                saveFilterData('yearly', year);
                applyYearlyFilterCloseCallSummary(year);
            }} />
        );
    } 
    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        return <MonthlyFilter onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilterCloseCallSummary(year, month);
        }} />;
    } 
    handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({ showYearlyFilter: true, showMonthlyFilter: false, filterBy: 'yearly' });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'monthly') {
            this.setState({ showYearlyFilter: false, showMonthlyFilter: true, filterBy: 'monthly' });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'cumulative') {
            this.setState({ showYearlyFilter: false, showMonthlyFilter: false, filterBy: 'monthly' });
            this.props.getFilterBy && this.props.getFilterBy('monthly');
            //getCloseCallReportSummaryData('yearly', '0', '0'); 
        }
       
    }

    renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            <Alert style={{ height: '40px' }} id="oi-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' },{ value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        model=".filterType"
                        defaultValue="cumulative"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    } 
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getCloseCallReportSummaryData(this.props.forms); 
        setSuccess('ReportsPage');
    }
    renderCountLineChart = () => {
        const { reportCountData } = this.props.reportsData; 
        // const data = getOfficeSafetyLineChartData(this.props.filterBy || this.state.filterBy, officeData);
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Total Count of <span style={{ color: '#E6B0AA' }}>
                            Check List </span> / <span style={{ color: '#D7BDE2' }}>Mech
                         </span> / <span style={{ color: '#A9CCE3' }}>Trans
                         </span> / <span style={{ color: '#F9E79F' }}>Engg
                         </span> Forms
                    </h4> 
                    <Row className="show-grid line-chart closeCallSummary">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={reportCountData} legends={['CheckList', 'MechanicalForm','TransportationForm', 'EnginneringForm']}
                                strokeColors={['E6B0AA', 'D7BDE2','A9CCE3','F9E79F']} isAnimationActive={false}
                                dataKey={['CheckList', 'Mechanical','Transportation','Enginnering']} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
     

    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data" >
            <Title key="title" text="Close Call Report Summary"></Title>
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''}
                    {/* {this.renderFilters()} */}
                    <CommonFilter onApply={this.applyCommonFilter} forms={this.props.forms}/>
                    {this.renderCountLineChart()}  
                </div>
            </div>
        </div>;
    }

    render() {
        return [
            <Async
                key="content"
                identifier="ReportsPage"
                promise={() => setSuccess('ReportsPage')}
                loader={this.renderContent(true)}
                content={this.renderContent(false)}
                error={<ErrorPage />}
            />
        ];
    }
}
