import * as React from 'react';
import { IHistory } from '../../interfaces';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { IJSAModelProps, JSAModel } from '../../model/JSAModel';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { connect } from 'react-redux'; 
import { showDelConfirmModal } from '../../actions/modalActions'; 
import { UserModel } from '../../model/UserModel';
import { getPageItemNumber } from '../../utils/generalUtils'; 
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: JSAModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> { 
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderDropdown = () => {
        const { instance } = this.props;
        if (!instance) {
            return;
        }
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">
                { UserModel.checkUserAccess('jsa_edit') && <MenuItem eventKey="1"
                    onClick={() => this.props.history?.push(`/jsa-reports/${instance.props.id}/template`)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Use as Template</MenuItem> }
                { UserModel.checkUserAccess('jsa_view') && <MenuItem eventKey="1"
                    onClick={() => this.props.history?.push(`/jsa-reports/${instance.props.id}`)}
                    className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                { UserModel.checkUserAccess('jsa_edit') && <MenuItem eventKey="2"
                    onClick={() => this.onEditAttribute(instance.props)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                { UserModel.checkUserAccess('jsa_delete') && <MenuItem eventKey="3"
                    onClick={() => this.onDeleteAttribute()}
                    className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
            </DropdownButton>
        );
    }
    onDeleteAttribute() {
        const { instance } = this.props;  
        instance && showDelConfirmModal('delete-confirmation', 'JSA Form', instance.props);  
    }
    onEditAttribute(instance: IJSAModelProps & { id?: string | undefined; }) { 
        this.props.history?.push(`/jsa-reports/edit/${instance.key}`);
    }
    onViewAttribute(id: string) { 
        if(!UserModel.checkUserAccess('jsa_view') )
            return;
        this.props.history?.push(`/jsa-reports/${id}`)
    }
    renderFormList = () => {
        const { instance, index, style } = this.props; 
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}> 
                <Col sm={1}>
                    {( UserModel.checkUserAccess('jsa_view') || UserModel.checkUserAccess('jsa_edit') || UserModel.checkUserAccess('jsa_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
                </Col>
                <Cell onClick={() => this.onViewAttribute(instance.props.id) } sm={2} >
                    {instance.props.date || 'NA'}
                </Cell>
                <Cell onClick={() => this.onViewAttribute(instance.props.id) } sm={2} >
                    {instance.props.jsaNum || 'NA'}
                </Cell> 
                <Cell onClick={() => this.onViewAttribute(instance.props.id) } sm={3} >
                    {instance.props.workToPerform || 'NA'}
                </Cell>
                <Cell onClick={() => this.onViewAttribute(instance.props.id) } sm={2} >
                    {instance.props.projectName || 'NA'}
                </Cell> 
                <Col className="clickable-list-item" sm={2} onClick={() => this.onViewAttribute(instance.props.id) }> 
                    <span>{ `${instance?.props.totalFormAck || 0}/${(instance.props.appUsers.length + instance.props.nonAppUsers.length) || 0} `}</span><span style={{display: 'inline-block', marginLeft: '10px'}}>  { instance?.props.acknowledgement==='1'?<i title="Acknowledged" className="fa fa-check acknowledged" aria-hidden="true" ></i>:<i title="Unacknowledged" className="fa fa-times unacknowledged" aria-hidden="true" ></i> }</span> 
                </Col> 
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
        
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
