import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';  
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { GroupInput } from '../../reusableComponents/FormComponents/GroupInput';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { ProjectInput } from '../../reusableComponents/FormComponents/ProjectInput';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../../reusableComponents/Loader';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Title } from '../../reusableComponents/Title';
import { UserModel } from '../../../model/UserModel'; 
import { IForms, IHistory } from '../../../interfaces';
import { LocationInput } from '../../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../../reusableComponents/FormComponents/MapInputModal';
import '../index.scss';
import { dispatch, isFormEmpty, showAlert, convertToDateFormat } from '../../../utils/generalUtils';
import { actions } from 'react-redux-form'; 
import { IMocPermanentModelProps, MocPermanentModel} from '../../../model/MOCModel';
import { ManualSignature } from '../../reusableComponents/ManuallyGenerateSignature/ManualSignature';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general'; 
import { AttachementInput } from '../../reusableComponents/FormComponents/AttachementInput';
import {submitMOCForms, getFormData} from '../../../services/MocService'; 
import { getAllUsers } from '../../../services/userService';
import { VoiceRecognition } from '../../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IMOCPermanentDeviationFormProps { 
    instance?: any;
    userInstance: UserModel;
    history?: IHistory;
    forms: IForms;
    company: string;
    editId?: string;
    userUID?: string;
}

export interface IMOCPermanentDeviationFormState {  
    currentDate: string;
    currentTime: string; 
    safetySupervisorUsers: any[]; productionSupervisorUsers: any[]; safetyManagerUsers: any[]; operationsManagerUsers: any[]; productionManagerUsers: any[];
    originatorSignatureTray: boolean; originatorSignature: string; originatorSignatureURL: string;
    safetySupervisorSignatureTray: boolean; safetySupervisorSignature: string; safetySupervisorSignatureURL: string; 
    productionSupervisorSignatureTray: boolean; productionSupervisorSignature: string; productionSupervisorSignatureURL: string; 
    safetyManagerSignatureTray: boolean; safetyManagerSignature: string; safetyManagerSignatureURL: string;  
    operationsManagerSignatureTray: boolean; operationsManagerSignature: string; operationsManagerSignatureURL: string;
    productionManagerSignatureTray: boolean; productionManagerSignature: string; productionManagerSignatureURL: string;
    isDisableGeneralEdit: boolean;  
} 

export class MOCPermanentDeviationFormImpl extends React.PureComponent<IMOCPermanentDeviationFormProps, IMOCPermanentDeviationFormState> {
 
    constructor(props: IMOCPermanentDeviationFormProps | Readonly<IMOCPermanentDeviationFormProps>) {
        super(props); 
        this.state = { currentDate: format(new Date(), 'yyyy-MM-dd'), currentTime: format(new Date(), 'HH:mm'), safetySupervisorUsers: [], productionSupervisorUsers: [], safetyManagerUsers: [], operationsManagerUsers: [], productionManagerUsers: [], 
        originatorSignatureTray: true, originatorSignature: '', originatorSignatureURL: '', safetySupervisorSignatureTray: true, safetySupervisorSignature: '', safetySupervisorSignatureURL: '', productionSupervisorSignatureTray: true, productionSupervisorSignature: '', productionSupervisorSignatureURL: '', safetyManagerSignatureTray: true, safetyManagerSignature: '', safetyManagerSignatureURL: '', operationsManagerSignatureTray:true, operationsManagerSignature: '',  operationsManagerSignatureURL: '', productionManagerSignatureTray: true, productionManagerSignature: '', productionManagerSignatureURL: '', isDisableGeneralEdit: false }
    }

    promise = async () => { 
        const { props: { userUID, editId } } = this; 
        let instance = this.props.instance
        let answer: any = {};
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if(!editId) {
            this.setState({ safetySupervisorSignatureTray: false, productionSupervisorSignatureTray: false, safetyManagerSignatureTray: false, operationsManagerSignatureTray: false, productionManagerSignatureTray: false  })
        } 
        if(editId && !instance) {  
            await getFormData('mocPermanentDeviation', editId);
            instance =  MocPermanentModel.get(editId); 
        } 
        if (instance && instance.props && instance.props.answers) { 
            answer = instance.props.answers; 
            if(instance.props.userId!==userUID || answer.safetySupervisorApprovedDate) {
                this.setState({isDisableGeneralEdit: true})
            }  
            this.setState({safetySupervisorSignatureTray: false, productionSupervisorSignatureTray: false, safetyManagerSignatureTray: false, operationsManagerSignatureTray: false, productionManagerSignatureTray: false }) 

            this.getUsersOptions(answer.groupName);
            if(answer.originatorSignatureURL) {
                this.setState({originatorSignatureURL: answer.originatorSignatureURL, originatorSignatureTray: false })
            }
            if(answer.safetySupervisorSignatureURL) {
                this.setState({safetySupervisorSignatureURL: answer.safetySupervisorSignatureURL })
            }
            if(answer.productionSupervisorSignatureURL) {
                this.setState({productionSupervisorSignatureURL: answer.productionSupervisorSignatureURL })
            }
            if(answer.safetyManagerSignatureURL) {
                this.setState({safetyManagerSignatureURL: answer.safetyManagerSignatureURL })
            }
            if(answer.operationsManagerSignatureURL) {
                this.setState({operationsManagerSignatureURL: answer.operationsManagerSignatureURL })
            }
            if(answer.productionManagerSignatureURL) {
                this.setState({productionManagerSignatureURL: answer.productionManagerSignatureURL })
            }
        }
       return null;
    }
    componentWillMount() {  
    } 
    getChildContext() {
        return { formModel: 'forms.MOCPermanentDeviationForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    getUsersOptions = (group?: string) => { 
        dispatch(actions.change(`forms.MOCPermanentDeviationForm.emplyeesName`,''));
        const users = UserModel.list();
        this.setState({safetySupervisorUsers: [], productionSupervisorUsers: [], safetyManagerUsers: [], operationsManagerUsers: [], productionManagerUsers: []});
        let safetySupervisorUsers: any = []; 
        /* let productionSupervisorUsers: any = []; 
        let safetyManagerUsers: any = []; 
        let operationsManagerUsers: any = []; 
        let productionManagerUsers: any = []; 
        let firstNames: any[] = []; */
        (users || []).forEach( (user:any) => { 
            if(user.props.groups.includes(group)) { 
                let firstName = user.props.firstName + ' ' + user.props.middleName + ' ' + user.props.lastName;
                safetySupervisorUsers.push({ label: firstName, value: user.props.userUID.toString() });
                /* switch(user.props.jobTitle) {
                    case 'Safety Supervisor':
                        safetySupervisorUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Production Supervisor':
                        productionSupervisorUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Safety Manager':
                        safetyManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Operations Manager':
                        operationsManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Production Manager':
                        productionManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    default: break;
                } */
            }
        }); 
        /* this.setState({safetySupervisorUsers: safetySupervisorUsers, productionSupervisorUsers: productionSupervisorUsers, safetyManagerUsers: safetyManagerUsers, operationsManagerUsers: operationsManagerUsers, productionManagerUsers: productionManagerUsers }); */
        this.setState({safetySupervisorUsers: safetySupervisorUsers, productionSupervisorUsers: safetySupervisorUsers, safetyManagerUsers: safetySupervisorUsers, operationsManagerUsers: safetySupervisorUsers, productionManagerUsers: safetySupervisorUsers });
    } 
    handleFormSubmit = async (values: IMocPermanentModelProps) => {  
        const { props: { instance, editId, userUID, } } = this; 
        let answer: any = {}; 
        if(instance)
            answer = instance.props.answers; 
        let validateValues: any = { dateOfIncident: values.dateOfIncident, timeOfIncident: values.timeOfIncident, projectName: values.projectName, groupName: values.groupName, locationName: values.locationName, originator: values.originator, originatorSignature: values.originatorSignature || (answer && answer.originatorSignatureURL), changeRequestDetails: values.changeRequestDetails, reasonForChange: values.reasonForChange, targetDateForChange: values.targetDateForChange, safetySupervisor: values.safetySupervisor, productionSupervisor: values.productionSupervisor, safetyManager: values.safetyManager, operationsManager: values.operationsManager, productionManager: values.productionManager}  
        let submitValues = JSON.parse(JSON.stringify(values));
        submitValues['changeRequestDetails'] = values.changeRequestDetails;
        if(!editId || (instance && instance.props.userId === userUID && !values.safetySupervisorApprovedDate) ) {
            submitValues['mailTo'] = {'UUID': values.safetySupervisor, 'designation': 'safetySupervisor'}; 
        } else if (instance && instance.props && instance.props.answers) { 
            if(userUID===answer.safetySupervisor) { 
                let validateNew = {safetySupervisorApprovedDate: values.safetySupervisorApprovedDate, safetySupervisorSignature: values.safetySupervisorSignature || answer.safetySupervisorSignatureURL, safetySupervisorComments: values.safetySupervisorComments };
                validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.productionSupervisor, 'designation': 'productionSupervisor'}; 
            }
            if(userUID===answer.productionSupervisor) {
                let validateNew = {productionSupervisorApprovedDate: values.productionSupervisorApprovedDate, productionSupervisorSignature: values.productionSupervisorSignature || answer.productionSupervisorSignatureURL,productionSupervisorComments: values.productionSupervisorComments };
                if(answer.safetySupervisorApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.safetyManager, 'designation': 'safetyManager'};
            }
            if(userUID===answer.safetyManager) {
                let validateNew = {safetyManagerApprovedDate: values.safetyManagerApprovedDate, safetyManagerApproval: values.safetyManagerApproval,safetyManagerSignature: values.safetyManagerSignature || answer.safetyManagerSignatureURL, safetyManagerComments: values.safetyManagerComments }; 
                if(answer.productionSupervisorApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                if(values.safetyManagerApproval==='1') {
                    submitValues['mailTo'] = {'UUID': answer.operationsManager, 'designation': 'operationsManager'}; 
                } else {
                    submitValues['mailTo'] = {'UUID': instance.props.userId, 'designation': 'createdUser', 'type': 'safetyManagerDenies'}; 
                }
            }
            if(userUID===answer.operationsManager) { 
                let validateNew = {operationsManagerApprovedDate: values.operationsManagerApprovedDate, operationsManagerApproval: values.operationsManagerApproval,operationsManagerSignature: values.operationsManagerSignature || answer.operationsManagerSignatureURL, operationsManagerComments: values.operationsManagerComments }; 
                if(answer.safetyManagerApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                if(values.operationsManagerApproval==='1' && values.safetyManagerApproval==='1') {
                    submitValues['mailTo'] = {'UUID': answer.productionManager, 'designation': 'productionManager'}; 
                } else { 
                    submitValues['mailTo'] = {'UUID': instance.props.userId, 'designation': 'createdUser', 'type': 'operationsManagerDenies'}; 
                }
            }
            if(userUID===answer.productionManager) {
                let validateNew = {productionManagerApprovedDate: values.productionManagerApprovedDate, productionManagerApproval: values.productionManagerApproval,productionManagerSignature: values.productionManagerSignature || answer.productionManagerSignatureURL, productionManagerComments: values.productionManagerComments }; 
                if(answer.operationsManagerApprovedDate && values.operationsManagerApproval==='1')
                    validateValues = {...validateValues, ...validateNew}; 
                if(values.productionManagerApproval==='1') {
                    submitValues['mailTo'] = {'UUID': instance.props.userId, 'designation': 'createdUser'}; 
                } else {
                    submitValues['mailTo'] = {'UUID': instance.props.userId, 'designation': 'createdUser', 'type': 'productionManagerDenies'}; 
                }
            }
        }
        if (isFormEmpty(validateValues)) { 
            showAlert(EMPTY_FORM_MESSAGE, 'mocPermanentDeviation-failed', 'danger');
            return
        } 
        await submitMOCForms(submitValues, 'mocPermanentDeviation', this.props.history, this.props.forms, this.props.editId, answer);
    } 
    renderSignatureField = (SignatureURL: any ='', SignatureTray = '', isDisabled = false) => {
            return <><Row className="show-grid" style={{ paddingBottom: '20px' }} > 
                <Col sm={12} style={{ color: 'green' }} className="signature">
                    { SignatureURL ?<img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ SignatureURL } alt="SafeConnect"/> : 'No signature found'}
                    {!isDisabled && <div>
                        <button
                            className="button"
                            style={newSign}
                            onClick={() => {  
                                var obj  = {};
                                obj[SignatureTray] = true;
                                this.setState(obj)
                                } }>
                            Sign New
                        </button>
                    </div> }
                </Col>
            </ Row ></>;
    }
    saveSignature = (file: any, acceptorSignature: any, model: any) => { 
        switch(model) {
            case 'originatorSignature':
                this.setState({
                    originatorSignatureTray: false, originatorSignature: file, originatorSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.originatorSignature`, acceptorSignature));
                break;
            case 'safetySupervisorSignature':
                this.setState({
                    safetySupervisorSignatureTray: false, safetySupervisorSignature: file, safetySupervisorSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.safetySupervisorSignature`, acceptorSignature));
                break;
            case 'productionSupervisorSignature':
                this.setState({
                    productionSupervisorSignatureTray: false, productionSupervisorSignature: file, productionSupervisorSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.productionSupervisorSignature`, acceptorSignature));
                break;
            case 'safetyManagerSignature':
                this.setState({
                    safetyManagerSignatureTray: false,  safetyManagerSignature: file,  safetyManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.safetyManagerSignature`, acceptorSignature));
                break;
            case 'operationsManagerSignature':
                this.setState({
                    operationsManagerSignatureTray: false, operationsManagerSignature: file,  operationsManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.operationsManagerSignature`, acceptorSignature));
                break;
            case 'productionManagerSignature':
                this.setState({
                    productionManagerSignatureTray: false, productionManagerSignature: file,  productionManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.productionManagerSignature`, acceptorSignature));
                break;
        }
    } 
    saveGeneratedSignature = (file: any, base64: any, model: any) => { 
        switch(model) {
            case 'originatorSignature':
                this.setState({
                    originatorSignatureTray: false, originatorSignature: file, originatorSignatureURL: base64,
                }); 
                dispatch(actions.change(`forms.MOCPermanentDeviationForm.originatorSignature`, base64));
                break;
                case 'safetySupervisorSignature':
                    this.setState({
                        safetySupervisorSignatureTray: false, safetySupervisorSignature: file, safetySupervisorSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCPermanentDeviationForm.safetySupervisorSignature`, base64));
                    break;
                case 'productionSupervisorSignature':
                    this.setState({
                        productionSupervisorSignatureTray: false, productionSupervisorSignature: file, productionSupervisorSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCPermanentDeviationForm.productionSupervisorSignature`, base64));
                    break;
                case 'safetyManagerSignature':
                    this.setState({
                        safetyManagerSignatureTray: false,  safetyManagerSignature: file,  safetyManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCPermanentDeviationForm.safetyManagerSignature`, base64));
                    break;
                case 'operationsManagerSignature':
                    this.setState({
                        operationsManagerSignatureTray: false, operationsManagerSignature: file,  operationsManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCPermanentDeviationForm.operationsManagerSignature`, base64));
                    break;
                case 'productionManagerSignature':
                    this.setState({
                        productionManagerSignatureTray: false, productionManagerSignature: file,  productionManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCPermanentDeviationForm.productionManagerSignature`, base64));
                    break;
        }
    }
    renderUserName(userId: string) {
        let user: any = UserModel.getUserByUID(userId);
        if(user) {
            return `${user.props.firstName} ${user.props.lastName}` || '';
        }
        return ''; 
    }
    renderContent() {
        const { handleFormSubmit, state: { currentDate, currentTime }, props: { instance, forms, userUID } } = this; 
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        } 
        return (
            <Grid className="moc-form" style={{ width: '100%' }}>  
                <MapInputModal id="tool-box-meeting-form" />
                <Form loader={<SubmissionLoader />} model="forms.MOCPermanentDeviationForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="Permanent Deviation - Change Request Form" noNeedBorder={true}/>
                    <AlertModal id="mocPermanentDeviation-form-modal" />   
                    <fieldset> 
                        <Row className="show-grid" > 
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date"
                                        defaultValue={answer && answer.dateOfIncident? convertToDateFormat(answer.dateOfIncident, 'yyyy-MM-dd'): currentDate}  
                                        model=".dateOfIncident"
                                        maxdate={ currentDate} 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentTime">Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentTime"
                                        defaultValue={answer.timeOfIncident ? answer.timeOfIncident: currentTime}
                                        type="time"
                                        model=".timeOfIncident" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={answer ? answer.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} isDisabled={this.state.isDisableGeneralEdit} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={answer && answer.groupName} onGroupChange={this.getUsersOptions} isDisabled={this.state.isDisableGeneralEdit}  />
                            </Col> 
                            <LocationInput  
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="tsm-form"
                                navigator={navigator} 
                                defaultValue={answer && answer.locationName} 
                                isDisabled={this.state.isDisableGeneralEdit} 
                            />
                            <Col sm={{span: 3, offset: 1 }} xs={12}>
                                <Label required sm={12}>Originator:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="originator"
                                        type="text"
                                        defaultValue={(answer && answer.originator) || (this.props.userInstance && this.props.userInstance.props.firstName) || ''}
                                        model=".originator" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.originatorSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={`${this.props.userInstance && this.props.userInstance.props.firstName } ${this.props.userInstance && this.props.userInstance.props.lastName}`}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="originatorSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.originatorSignatureURL, 'originatorSignatureTray', this.state.isDisableGeneralEdit )}
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Details of Change Requested:</Label>
                                <Col sm={12} className="input"> 
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.changeRequestDetails} model={`forms.MOCPermanentDeviationForm.changeRequestDetails`} /> 
                                    <RRFInput
                                        id="changeRequestDetails"
                                        type="textarea"
                                        defaultValue={answer && answer.changeRequestDetails}
                                        model=".changeRequestDetails" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>  
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Reason for Change (explain rationale for change or improvement opportunity):</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.reasonForChange} model={`forms.MOCPermanentDeviationForm.reasonForChange`} /> 
                                    <RRFInput
                                        id="reasonForChange"
                                        type="textarea"
                                        defaultValue={answer && answer.reasonForChange}
                                        model=".reasonForChange" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>  
                            <Col sm={{span: 4, }}  xs={12}>
                                <Label required sm={12} htmlFor="targetDateForChange">Target date for change (if approved):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="targetDateForChange"
                                        type="date"
                                        defaultValue={answer && answer.targetDateForChange? convertToDateFormat(answer.targetDateForChange, 'yyyy-MM-dd'): null}  
                                        model=".targetDateForChange"
                                        mindate={ currentDate}
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={8} xs={12} style={{textAlign: 'center' }}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input"> 
                                    <AttachementInput
                                        model=".attachments"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={answer && answer.attachmentUrls}
                                        noNeedAttachButton={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12}><legend className="legend-header" >Approvals</legend></Col> 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Safety Supervisor</label></Col>
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.safetySupervisor`}
                                        type="dropdown"
                                        menuItems={this.state.safetySupervisorUsers} 
                                        model={`.safetySupervisor`} 
                                        defaultValue={answer && answer.safetySupervisor}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4}  xs={12}>
                                <Label required sm={12} htmlFor="safetySupervisorApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="safetySupervisorApprovedDate"
                                        type="date" 
                                        defaultValue={answer && answer.safetySupervisorApprovedDate? convertToDateFormat(answer.safetySupervisorApprovedDate, 'yyyy-MM-dd'): null} 
                                        model=".safetySupervisorApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && (userUID===answer.safetySupervisor && !answer.productionSupervisorApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.safetySupervisorSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCPermanentDeviationForm.safetySupervisor)} 
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model="safetySupervisorSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.safetySupervisorSignatureURL, 'safetySupervisorSignatureTray', answer && (userUID===answer.safetySupervisor && !answer.productionSupervisorApprovedDate)? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && (userUID===answer.safetySupervisor && !answer.productionSupervisorApprovedDate ) && <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.safetySupervisorComments} model={`forms.MOCPermanentDeviationForm.safetySupervisorComments`} /> }
                                    <RRFInput
                                        id="safetySupervisorComments"
                                        type="textarea"
                                        defaultValue={answer && answer.safetySupervisorComments}
                                        model=".safetySupervisorComments" 
                                        isDisabled={answer && (userUID===answer.safetySupervisor && !answer.productionSupervisorApprovedDate )? false: true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={12} xs={12} ><label className="sub-legend-header">Production Supervisor</label></Col>
                            { answer && (!answer.safetySupervisorApprovedDate && userUID===answer.productionSupervisor) &&  <Col sm={12} xs={12} className="moc-nonedit" >Safety Supervisor yet to approve</Col>}
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.productionSupervisor`}
                                        type="dropdown"
                                        menuItems={this.state.productionSupervisorUsers}  
                                        model={`.productionSupervisor`} 
                                        defaultValue={answer && answer.productionSupervisor}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="productionSupervisorApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="productionSupervisorApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.productionSupervisorApprovedDate ? convertToDateFormat(answer.productionSupervisorApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".productionSupervisorApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.productionSupervisor && answer.safetySupervisorApprovedDate && !answer.safetyManagerApprovedDate )? false: true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.productionSupervisorSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCPermanentDeviationForm.productionSupervisor)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'} 
                                        model="productionSupervisorSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.productionSupervisorSignatureURL, 'productionSupervisorSignatureTray', answer && ( userUID===answer.productionSupervisor && answer.safetySupervisorApprovedDate && !answer.safetyManagerApprovedDate)? false: true)}
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.productionSupervisor && answer.safetySupervisorApprovedDate && !answer.safetyManagerApprovedDate) && <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.productionSupervisorComments} model={`forms.MOCPermanentDeviationForm.productionSupervisorComments`} /> }
                                    <RRFInput
                                        id="productionSupervisorComments"
                                        type="textarea"
                                        defaultValue={answer && answer.productionSupervisorComments}
                                        model=".productionSupervisorComments" 
                                        isDisabled={answer && ( userUID===answer.productionSupervisor && answer.safetySupervisorApprovedDate && !answer.safetyManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col> 
                        </Row>

                        <Row className="show-grid" > 
                            <Col sm={12} xs={12}><legend className="legend-header" >Management Approvals</legend></Col> 
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Safety Manager</label></Col>
                            { answer && (!answer.productionSupervisorApprovedDate && userUID===answer.safetyManager) && <Col sm={12} xs={12} className="moc-nonedit" >Production Supervisor yet to approve</Col>}
                            <Col sm={3} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.safetyManager`}
                                        type="dropdown"
                                        menuItems={this.state.safetyManagerUsers}  
                                        model={`.safetyManager`} 
                                        defaultValue={answer && answer.safetyManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={2} xs={12}>
                                <Label required sm={12} htmlFor="safetyManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="safetyManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.safetyManagerApprovedDate? convertToDateFormat(answer.safetyManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".safetyManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.safetyManager && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             <Col sm={3} xs={12} className="input"> 
                                <Label required className="text-left" sm={12}>Approval?</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="safetyManagerApprovalYes"
                                        type="radio"
                                        model=".safetyManagerApproval"
                                        radioValue={'1'}
                                        radioName="safetyManagerApproval" 
                                        defaultValue={answer && answer.safetyManagerApproval}
                                        isDisabled={answer && ( userUID===answer.safetyManager && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate)? false: true}
                                    /> <label htmlFor="safetyManagerApprovalYes">Yes</label>
                                    <RRFInput
                                        id="safetyManagerApprovalNo"
                                        type="radio"
                                        model=".safetyManagerApproval"
                                        radioValue={'0'}
                                        radioName="safetyManagerApproval" 
                                        defaultValue={answer && answer.safetyManagerApproval}
                                        isDisabled={answer && ( userUID===answer.safetyManager && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate)? false: true}
                                    /> <label htmlFor="safetyManagerApprovalNo">No</label>
                                </Label>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.safetyManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCPermanentDeviationForm.safetyManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model="safetyManagerSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.safetyManagerSignatureURL, 'safetyManagerSignatureTray', answer && ( userUID===answer.safetyManager  && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate)? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.safetyManager && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate) && <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.safetyManagerComments} model={`forms.MOCPermanentDeviationForm.safetyManagerComments`} /> }
                                    <RRFInput
                                        id="safetyManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.safetyManagerComments}
                                        model=".safetyManagerComments" 
                                        isDisabled={answer && ( userUID===answer.safetyManager && answer.productionSupervisorApprovedDate && !answer.operationsManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Operations Manager</label></Col>
                            { answer && (!answer.safetyManagerApprovedDate && userUID===answer.operationsManager) && <Col sm={12} xs={12} className="moc-nonedit" >Safety Manager yet to approve</Col>}
                            <Col sm={3} className="input">
                                <Label required sm={12} htmlFor="operationsManager">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.operationsManager`}
                                        type="dropdown"
                                        menuItems={this.state.operationsManagerUsers}  
                                        model={`.operationsManager`} 
                                        defaultValue={answer && answer.operationsManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={2} xs={12}>
                                <Label required sm={12} htmlFor="operationsManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="operationsManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.operationsManagerApprovedDate? convertToDateFormat(answer.operationsManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".operationsManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1')? false: true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12} className="input"> 
                                <Label required className="text-left" sm={12}>Approval?</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="operationsManagerApprovalYes"
                                        type="radio"
                                        model=".operationsManagerApproval"
                                        radioValue={'1'}
                                        radioName="operationsManagerApproval" 
                                        defaultValue={answer && answer.operationsManagerApproval}
                                        isDisabled={answer && ( userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1') ? false: true}
                                    /> <label htmlFor="operationsManagerApprovalYes">Yes</label>
                                    <RRFInput
                                        id="operationsManagerApprovalNo"
                                        type="radio"
                                        model=".operationsManagerApproval"
                                        radioValue={'0'}
                                        radioName="operationsManagerApproval" 
                                        defaultValue={answer && answer.operationsManagerApproval}
                                        isDisabled={answer && (userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1')? false: true}
                                    /> <label htmlFor="operationsManagerApprovalNo">No</label>
                                </Label>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.operationsManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCPermanentDeviationForm.operationsManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model="operationsManagerSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.operationsManagerSignatureURL, 'operationsManagerSignatureTray', answer && ( userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1')? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1') && <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.operationsManagerComments} model={`forms.MOCPermanentDeviationForm.operationsManagerComments`} /> }
                                    <RRFInput
                                        id="operationsManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.operationsManagerComments}
                                        model=".operationsManagerComments" 
                                        isDisabled={answer && ( userUID===answer.operationsManager && !answer.productionManagerApprovedDate && answer.safetyManagerApproval==='1')? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Production Manager</label></Col>
                            { answer && (!answer.operationsManagerApprovedDate && userUID===answer.productionManager) && <Col sm={12} xs={12} className="moc-nonedit" >Operations Manager yet to approve</Col>}
                            <Col sm={3} className="input">
                                <Label required sm={12} htmlFor="productionManager">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.productionManager`}
                                        type="dropdown"
                                        menuItems={this.state.productionManagerUsers}  
                                        model={`.productionManager`} 
                                        defaultValue={answer && answer.productionManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={2} xs={12}>
                                <Label required sm={12} htmlFor="productionManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="productionManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.productionManagerApprovedDate? convertToDateFormat(answer.productionManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".productionManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApproval==='1') ? false: true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12} className="input"> 
                                <Label required className="text-left" sm={12}>Approval?</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="productionManagerApprovalYes"
                                        type="radio"
                                        model=".productionManagerApproval"
                                        radioValue={'1'}
                                        radioName="productionManagerApproval" 
                                        defaultValue={answer && answer.productionManagerApproval} 
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApproval==='1')? false: true}
                                    /> <label htmlFor="productionManagerApprovalYes">Yes</label>
                                    <RRFInput
                                        id="productionManagerApprovalNo"
                                        type="radio"
                                        model=".productionManagerApproval"
                                        radioValue={'0'}
                                        radioName="productionManagerApproval" 
                                        defaultValue={answer && answer.productionManagerApproval} 
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApproval==='1')? false: true}
                                    /> <label htmlFor="productionManagerApprovalNo">No</label>
                                </Label>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.productionManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCPermanentDeviationForm.productionManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model="productionManagerSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.productionManagerSignatureURL, 'productionManagerSignatureTray', answer && (userUID===answer.productionManager && answer.operationsManagerApproval==='1')? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.productionManager && answer.operationsManagerApproval==='1') && <VoiceRecognition labelText="" defaultValue={forms.MOCPermanentDeviationForm.productionManagerComments} model={`forms.MOCPermanentDeviationForm.productionManagerComments`} /> }
                                    <RRFInput
                                        id="productionManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.productionManagerComments}
                                        model=".productionManagerComments" 
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApproval==='1')? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="mocPermanentDeviation-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ instance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { instance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid> ); 
    } 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="MOCPermanentDeviationForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    } 
}

export function mapStateToProps(state: any, ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    let editInstance = '';
    if(editId) {
        editInstance = MocPermanentModel.get(editId)
    }
    return { instance: editInstance , userInstance, forms: state.forms, company, editId: editId, userUID };
} 
export const MOCPermanentDeviationForm = withRouter(connect<IMOCPermanentDeviationFormProps, any, any>(mapStateToProps)(MOCPermanentDeviationFormImpl));
const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};