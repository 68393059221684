import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
 
export interface IStackedAreaProps { 
    data: object[];
    dataKey: string[];
    strokeColors: string[];
}

export class StackedAreaChart extends React.PureComponent<IStackedAreaProps, {}> {
    constructor(props: IStackedAreaProps | Readonly<IStackedAreaProps>) {
        super(props);
    }
    
    renderlinearGradients = () => { 
        const { dataKey, strokeColors } = this.props;
        return strokeColors.map((key, index) => {  
            return <linearGradient id={`${dataKey[index]}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={`#${strokeColors[index]}`} stopOpacity={1}/>
            <stop offset="95%" stopColor={`#${strokeColors[index]}`} stopOpacity={1}/>
            </linearGradient>
        });
    }
    renderDatas  = () => {
        const { dataKey, strokeColors } = this.props;
        return dataKey.map((key, index) => { 
            return <Area name={`${dataKey[index]}`} type='monotone' dataKey={`${dataKey[index]}`} stackId="1" stroke={`#${strokeColors[index]}`} /* fill={`url(#${dataKey[index]})`} */ fill={`#${strokeColors[index]}`} />
        });
    }
    render() { 
        const { data } = this.props;  
        return (
            <div style={{ height: '100%' }}>
                <ResponsiveContainer>
                    <AreaChart width={600} height={400} data={data}
                            margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <CartesianGrid strokeDasharray="3 3"/> 
                        <defs> 
                            {this.renderlinearGradients()} 
                        </defs>
                        <XAxis dataKey="name" allowDecimals={false}/>
                        <YAxis allowDecimals={false}/>
                        <Tooltip/> 
                        <Legend verticalAlign="bottom" height={20}/>  
                        {this.renderDatas()} 
                    </AreaChart>
                </ResponsiveContainer>
            </div >
        );
    }
}
