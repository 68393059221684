import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { IHistory } from '../../interfaces';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { getIncidentForm } from '../../services/incidentNotificationFormService';
import { convertTime, KeyValue } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import { Img } from '../reusableComponents/ImageComponents/Img';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { checkInvestigationEditAccess } from '../../services/incidentReportService';
import noImage from '../../images/noImage.png';
import { DesignationModel } from '../../model/MiscellaneousModel';
import { getAllDesignations } from '../../services/miscellaniousServices';

export interface IDetailsPageProps {
    incidentInstance: IncidentNotificationModel;
    incidentId: string;
    match?: {
        params: {
            id: string;
        }
    };
    userId: string;
    history?: IHistory;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = async () => {
        /* if (IncidentNotificationModel.list().length <= 0) {
            return getAllIncidentNotificationForms();
        } */
        if (DesignationModel.list().length === 0) {
            await getAllDesignations();
        }
        await getIncidentForm(this.props.incidentId);
        return null;
    }
    async componentDidUpdate(prevProps: any) {   
        if (prevProps.incidentId !== this.props.incidentId) {   
            await getIncidentForm(this.props.incidentId);
        }
    } 

    renderImages = () => {
        const { incidentInstance } = this.props;
        if (incidentInstance.props.imageUrls && incidentInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect"/>;
        } 
        return (incidentInstance.props.imageUrls || []).map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });

    }

    renderContent = () => {
        const { incidentInstance } = this.props;
        if (!this.props.incidentInstance) {
            return <Loader />;
        }
        const { props: { timeOfIncident, location, specifiedLocation, injuredPartyWorkFunction, injuredPartyIdNo,
            injuryDiagnosis, injuryBodyPart, team, concernType, activity, activityBeingPerformed,
            injuryClassification, injurySummary } } = incidentInstance;
        const designationInstance = DesignationModel.get(team); 
        return (
            <div>
                <Title text={`Incident Report for ${incidentInstance.props.dateOfIncident}`} />
                <KeyValue label="Time"> {timeOfIncident ? convertTime(timeOfIncident) : 'NA'}</KeyValue>
                <KeyValue label="Location Details">{location}</KeyValue>
                <KeyValue label="Location Name">{specifiedLocation}</KeyValue>
                <KeyValue label="Injured Party's ID Number">{injuredPartyIdNo}</KeyValue>
                <KeyValue label="Designation">{designationInstance ? designationInstance.props.designation: ''}</KeyValue>
                <KeyValue label="Concern Type">{concernType}</KeyValue>
                <KeyValue label="Activity">{activity}</KeyValue>
                <KeyValue label="Injured Party's Work Function">{injuredPartyWorkFunction}</KeyValue>
                <KeyValue label="Activity Being Performed During Incident">{activityBeingPerformed}</KeyValue>
                <KeyValue label="Injury Diagnosis">{injuryDiagnosis}</KeyValue>
                <KeyValue label="Injury Summary">{injurySummary}</KeyValue>
                <KeyValue label="Injured Body Part">{injuryBodyPart}</KeyValue>
                <KeyValue label="Injury Classification">{injuryClassification}</KeyValue>
                <KeyValue label="Images">{this.renderImages()}</KeyValue>
                <KeyValue label="Videos"><VideoPlay videoUrls={incidentInstance.props.videoUrls}/></KeyValue>
            </div >
        );
    }

    renderIncidentDetail = () => {
        const { incidentInstance } = this.props; 
        if (!incidentInstance) {
            return <Loader />;
        }
        let fileName = '';
        if (incidentInstance) {
            const { incidentInstance: { props: { dateOfIncident } } } = this.props;
            fileName = `Incident Report for ${dateOfIncident}`;
        }
        return <DetailsPageWrapper
            pdfFileName={fileName}
            className="incident-report report-details"
            customButton={<p><Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                Close
                </Button> {this.investigationProcess()}</p>}
            /* customButton={incidentInstance.props.associatedFormsCompleted.length<1 ? <Button
                onClick={() => this.props.history.push
                    (`/incident-investigative-reports/create/${incidentInstance.props.submittedFormId}`)
                }
            >
                Add Investigation
            </Button> : <Button
                onClick={() => this.props.history.push
                    (`/incident-investigative-reports/edit/${incidentInstance.props.associatedFormsCompleted[0]}`)
                }
            >
                Edit Investigation
            </Button>} */
        >
            {this.renderContent()}
        </DetailsPageWrapper>;
    }
    investigationProcess() {
        const { incidentInstance } = this.props;
        if(incidentInstance.props.associatedFormsCompleted && incidentInstance.props.associatedFormsCompleted.length<1)
            return (<Button
                onClick={() => this.props.history?.push
                    (`/incident-investigative-reports/create/${incidentInstance.props.submittedFormId}`)
                }
            >
                Add Investigation
            </Button>);
        else  { 
            if(incidentInstance.props.associatedFormsCompleted) {
                let investigativeInstance = IncidentReportModel.get(incidentInstance.props.associatedFormsCompleted[0]); 
                return ( (investigativeInstance && ( investigativeInstance.props.finalReport===0 || (investigativeInstance.props.finalReport && checkInvestigationEditAccess(investigativeInstance.props.userAccessLevel)) ) ) && <Button
                onClick={() =>incidentInstance.props.associatedFormsCompleted &&  this.props.history?.push
                    (`/incident-investigative-reports/edit/${incidentInstance.props.associatedFormsCompleted[0]}`)
                }
            >
                Edit Investigation
            </Button>);
            }
        }
    }
    render() {
        const { incidentInstance } = this.props;
        return (
            <BasePage>
                <Gallery images={incidentInstance ? incidentInstance.props.imageUrls : []} />
                <Async
                    identifier="IncidentNotificationDetailsPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderIncidentDetail()}
                />;
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const incidentId = ownProps.match.params.id;
    const incidentInstance = IncidentNotificationModel.get(incidentId);
    const userId = state.login.get('userId'); 
    return { incidentId, incidentInstance, userId };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl)); 
