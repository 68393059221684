import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from '../../components/Alert';
import { Label } from '../../components/reusableComponents/FormComponents/Label';
import { getFormattedDate } from '../../utils/generalUtils';
import { IHistory } from '../../interfaces';
import { SafetyWalkthroughCommentListModel, SafetyWalkthroughCommentModel } from '../../model/SafetyWalkthroughModel';
import { getAllComments } from '../../services/attributeService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';
import './swFormList.scss';

export interface ISWCommentListPageProps {
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    swComments: SafetyWalkthroughCommentListModel;
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
}

export interface ISWCommentListPageState {
    showDetails: boolean;
    isStartSelected: boolean;
    formId: string;
    swManagerComments: SafetyWalkthroughCommentModel[];
    swSupervisorComments: SafetyWalkthroughCommentModel[];
    startDate: string;
    endDate: string;
    isLoading: boolean;

}

export class SWCommentListPageImpl extends React.PureComponent<ISWCommentListPageProps, ISWCommentListPageState> {
    constructor(props: ISWCommentListPageProps | Readonly<ISWCommentListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '', swManagerComments: [], isStartSelected: false,
        swSupervisorComments: [], startDate: getFormattedDate() , endDate: getFormattedDate(),
    isLoading: false};
    }

    promise = async () => {
        // return getAllAttribute('ManagerCheckList');
    }
    
    componentDidMount() {
        const {startDate, endDate} = this.state;
        this.getCommentListData(new Date(startDate), new Date(endDate));
    }
    
    getCommentListData(startDate: number | Date, endDate: number | Date) {
        this.setState({isLoading: true});
        getAllComments(startDate, endDate).then(() => {
            this.setState({ swManagerComments: this.props.swComments.swManagerComment ,
                swSupervisorComments: this.props.swComments.swSupervisorComment ,
            isLoading: false });
        });
    }
    
    renderManagerComment(commentList: any[]) {

        return (commentList || []).map((item: any, index) => {
            return <div key={`attributeRowParent${index}`}>
                <Row key={`attributeRow${index}`}
                    className="row-cell-height border-left
                     border-right margin-left-right-0 padding-bottom-0 color-white">
                    <Col className="row-cell-height border-bottom border-left color-white" sm={1} xs={12}>
                        <div className="width-height-100-textcenter">
                            {index + 1}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom border-left color-white" sm={2} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.name}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom border-left color-white" sm={7} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.comment}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom border-left color-white" sm={2} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.date}</div>
                    </Col>

                </Row>
            </div>;
        });
    }
    renderSupervisorComment(commentList: any[]) {

        return (commentList || []).map((item: any, index) => {
            return <div key={`attributeRowParent${index}`}>
                <Row key={`attributeRow${index}`} 
                className="border-bottom row-cell-height border-left 
                border-right margin-left-right-0 padding-bottom-0 color-white">
                    <Col className="row-cell-height border-bottom border-left color-white" sm={1} xs={12}>
                        <div className="width-height-100-textcenter">
                            {index + 1}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom border-left color-white" sm={2} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.name}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom  border-left color-white" sm={7} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.comment}</div>
                    </Col>
                    <Col className="row-cell-height border-bottom border-left color-white" sm={2} xs={12}>
                        <div className="width-height-100-textcenter">
                            {item.date}</div>
                    </Col>
                </Row>
            </div>;
        });
    }

    handleFiltering = () =>  {
        const {startDate, endDate} = this.state;
        this.getCommentListData(new Date(startDate), new Date(endDate));
    }

    renderContent() {
        const { swManagerComments, swSupervisorComments } = this.state;
        return (
            <div className="project-list-wrapper">
                {this.state.isLoading && <SubmissionLoader/>}
                <Grid style={{ width: '100%' }} className="safety-walkthrough-assesment">
                    <Title titleColor="yellow" text="Manager / Supervisor Comments" />
                    <Alert className="danger-alert" id="personal-form" />
                    <div className="filter-container" >
                        <div>Start Date: &nbsp; <input
                        className="form-control" 
                         max={getFormattedDate()}
                        onChange={e => this.setState({ isStartSelected: true,
                            startDate: getFormattedDate(new Date(e.target.value)) })} 
                        type="date"  /></div>
                        <div>End Date: &nbsp;  <input 
                        min={this.state.startDate}
                        max={getFormattedDate()}
                        disabled={!this.state.isStartSelected}
                        className="form-control" 
                        onChange={e => this.setState({ endDate: getFormattedDate(new Date(e.target.value)) })} 
                        type="date"  /></div>
                        <div><button className="filter-button" onClick={this.handleFiltering}>Filter</button> </div>
                    </div>
                    <Row className="show-grid margin-left-right-0">
                        <Col sm={4} xs={12}>

                            <Col sm={12} className="input">
                                <div style={{ padding: '5px 0px' }}>
                                    <strong>

                                    </strong>
                                </div>
                            </Col>
                        </Col>
                        <Col sm={8} xs={12}>
                            <Col sm={12} className="input">
                                <div style={{ padding: '5px 0px' }}>

                                </div>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col sm={12} xs={12}>
                            <Label sm={12}>Manager Comments</Label>
                        </Col>
                    </Row>
                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col className="border-bottom border-right" sm={1} xs={12}>
                            <Label sm={12}>SL No.</Label>
                        </Col>
                        <Col className="border-bottom" sm={2} xs={12}>
                            <Label sm={12}>Name</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={7} xs={12}>
                            <Label sm={12}>Comments</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={2} xs={12}>
                            <Label sm={12}>Date</Label>
                        </Col>
                    </Row>
                    {this.renderManagerComment(swManagerComments)}
                    <div style={{ height: '50px' }}></div>
                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col sm={12} xs={12}>
                            <Label sm={12}>Supervisor Comments</Label>
                        </Col>
                    </Row>

                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col className="border-bottom border-right" sm={1} xs={12}>
                            <Label sm={12}>SL No.</Label>
                        </Col>
                        <Col className="border-bottom" sm={2} xs={12}>
                            <Label sm={12}>Name</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={7} xs={12}>
                            <Label sm={12}>Comments</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={2} xs={12}>
                            <Label sm={12}>Date</Label>
                        </Col>
                    </Row>
                    {this.renderSupervisorComment(swSupervisorComments)}
                </Grid>
            </div>
        );
    }

    render() {

        return (
            <BasePage className="safeconnect-form" >
                <Async
                    identifier="SafetyWalkthroughAssesmentListing"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        swComments: state.report.get('commentList')
    };
}

export const SafetyWalkthroughCommentListing = withRouter(connect<ISWCommentListPageProps, any, any>
    (mapStateToProps)(SWCommentListPageImpl));
