import { Button } from '../reusableComponents/FormComponents/Button';
import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { JSAModel } from '../../model/JSAModel';
import { changeJSAReadStatus, getAllJSAForm, getFormData } from '../../services/jsaFormService';
import { KeyValue } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader'; 
import { shoCarousel } from '../../actions/miscellaneousActions';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { Img } from '../reusableComponents/ImageComponents/Img';  
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay'; 
import noImage from '../../images/noImage.png'; 
import { getAllUsers } from '../../services/userService';
import { UserModel } from '../../model/UserModel';
import { Label } from '../reusableComponents/FormComponents/Label';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import PropTypes from 'prop-types';

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    jsaId?: string;
    history?: IHistory;
    jsaInstance: JSAModel;
    userId?: string;
    associatedUserId?: string;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }
    getChildContext() {
        return { formModel: 'forms.jsaForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    promise = async () => { 
        if(this.props.associatedUserId)
            await changeJSAReadStatus(this.props.associatedUserId );
        if (this.props.jsaId) { 
            //await getAllJSAForm(); 
            await getFormData('jsa', this.props.jsaId);
        } 
        await getAllUsers();
        return null;
    }
    renderJobSafetySteps = () => {
        const { jsaInstance } = this.props;
        return (jsaInstance.props.answers || []).map((stepData, index) => {
            return (
                <div key={index} className="steps">
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Step Description:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.stepDesc}
                        </Col>
                    </Row >
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Hazards:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.hazards}
                        </Col>
                    </Row >
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Controls:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.controls}
                        </Col> 
                    </Row >
                </div>
            );
        });
    } 

    renderImages = () => {
        const { jsaInstance } = this.props;
        if (!jsaInstance.props.imageUrls || jsaInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        }
        return jsaInstance.props.imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });

    }
    renderUsersSteps = () => { 
        return <> 
            <Row className="step-row" style={{textAlign: 'center'}}>
                <Col sm={6} style={{ color: '#969696' }}>
                    <Row>
                        {this.renderAppUsers() } 
                    </Row>
                </Col> 
                <Col sm={6} style={{ color: '#969696' }}>
                    <Row>
                        {this.renderNonAppUsers() } 
                    </Row>
                </Col> 
            </Row > </>;
    }
    renderAppUsers = () => {
        const { jsaInstance } = this.props;
        let appUsers: any = jsaInstance.props.appUsers ;
        return (appUsers || []).map((item: any, index: any) => {
            return (
                <Col sm={12} className="user-data"  >{ (UserModel.getUserByUID(item) && UserModel.getUserByUID(item).props.firstName) || 'Unknown'}</Col>
            )
        })  
    }

    renderNonAppUsers = () => {
        const { jsaInstance } = this.props;
        let nonAppUsers: any = jsaInstance.props.nonAppUsers ;
        return (nonAppUsers || []).map((item: any, index: any) => {
            return (
                <Col sm={12} className="user-data"  >{item}</Col>
            )
        })  
    } 

    renderContent = () => {
        const { jsaInstance, } = this.props;
        if (!jsaInstance) {
            return <Loader />;
        }
        const { props: { groupName, jsaNum, typeOfWork, locationName, workToPerform, projectName, approval, date,videoUrls } } = jsaInstance; 
        return <div>
            <Title text={`JSA Report for ${date}`}></Title>
            <KeyValue label="Date">{date}</KeyValue>
            <KeyValue label="Title">{typeOfWork}</KeyValue> 
            <KeyValue label="JSA Num">{jsaNum}</KeyValue>
            <KeyValue label="Work To Perform">{workToPerform}</KeyValue>
            <KeyValue label="Location">{locationName}</KeyValue>
            <KeyValue label="Team Name">{projectName}</KeyValue>
            <KeyValue label="Group Name">{groupName}</KeyValue> 
            <KeyValue label="Approval">{approval || 'NA'}</KeyValue> 
            <Row className="show-grid table">
                <Col className="form-title yellow-center" sm={4}>Step Description:</Col>
                <Col className="form-title yellow-center" sm={4}>Hazards:</Col>
                <Col className="form-title yellow-center" sm={4}>Controls:</Col>
                <div className="table-rows">
                    {this.renderJobSafetySteps()}
                </div>
            </Row >
            <KeyValue label="Images">{this.renderImages()}</KeyValue >
            <KeyValue label="Videos"><VideoPlay videoUrls={videoUrls}/></KeyValue>
            <KeyValue label="Created User Signature">{this.renderCreatedUserSignature()}</KeyValue >
            <KeyValue label="Acknowledgement Attachments">
                <AttachementInput
                    model=".attachements"
                    multiple={true}
                    id="upload-images" 
                    noNeedAttachButton={true}
                    uploadedImages={jsaInstance.props.ackAttachUrls || []}
                />
            </KeyValue >
            {jsaInstance.props.isApproved==='1' &&
                <><div>
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}>
                        <Label required={false} sm={12} className="form-title yellow-center" >Created User Acknowledgement:</Label>
                        <div style={{color: 'white'}}> 
                        <RRFInput
                            type="checkbox"
                            id="acknowledgement"
                            model=".acknowledgement" 
                            isDisabled={true}
                            defaultValue={jsaInstance && jsaInstance.props.acknowledgement==='1'?true:false}
                        /> <span className="user-data" style={{paddingLeft: '10px'}}> I heareby, acknowledge that all participants mentioned above are aware of this Job Safety Analysis and I reviewed.</span></div>
                    </Col>
                </div>
              
                {/* <Col className="form-title yellow-center" sm={12}>
                    <Label sm={12} required={false} htmlFor="upload-images" style={{textAlign: 'center'}}  className="color-gray">Approved Manager Name: </Label> 
                    <label style={{color: 'white'}} className="color-black">({jsaInstance.props.approvedBy})</label>
                </Col> */}</>
            }

            <>{jsaInstance.props.isApproved !== '1' && ( jsaInstance.props.userId === UserModel.getUserUID() ) && this.renderUserListForAck()}</>
        </div >;
    }
    renderCreatedUserSignature = () => {
        const { jsaInstance } = this.props; 
        if(jsaInstance.props.signatureUrl){
            return <Img 
                key={'signatureUrl'}
                style={{ padding: '5px', backgroundColor: '#fff' }}
                width="250px"
                height="55px"
                src={jsaInstance.props.signatureUrl}
            />; 
        }
        return '';
    }
    renderUserListForAck = () => {

        const { jsaInstance } = this.props;
        return (
            <div className="pdf-pagebreak-before" style={{marginTop: '10px'}}>
            <Col className="form-title yellow-center " sm={12}>Acknowledgement Participants <span style={{fontSize: '18px', fontWeight:'bold'}}>({`${jsaInstance.props.totalFormAck}/${( (jsaInstance?.props.appUsers.length || 0) ) + ( (jsaInstance?.props.nonAppUsers.length) || 0)}`})</span></Col> 
            <Table responsive="sm" bordered>
                <thead className="form-title yellow-center">
                <tr>
                    <th>#</th>
                    <th style={{width: '30%'}}>Name</th>
                    <th style={{width: '30%'}}>Participants Type</th> 
                    <th>Acknowledgement</th> 
                </tr>
                </thead> 
                <tbody>
                    {this.renderAppUsersStepsForAck()}
                    {this.renderNonAppUsersStepsForAck()}
                </tbody>
            </Table></div>
            
        )
    }
    renderAppUsersStepsForAck = () => {
        const { jsaInstance } = this.props;
        let appUsersList: any = jsaInstance.props.appUsersList ; 
        return (appUsersList || []).map((item: any, index: any) => {
            return (
                <><tr className="user-data">
                    <td>{index + 1}</td>
                    <td>{item.userName || 'Unknown'}</td>
                    <td>App Participant</td>
                    <td style={{color: 'green'}}>{item.readStatus===1?'Acknowledged':''}</td> 
                </tr></> 
            )
        })  
    }
    renderNonAppUsersStepsForAck = () => {
        const { jsaInstance } = this.props;
        let appUsers: any = jsaInstance.props.appUsers ; 
        let nonAppUsersList: any = jsaInstance.props.nonAppUsersList ;
        let appUsersCount = appUsers.length;
        return (nonAppUsersList || []).map((item: any, index: any) => {
            return (
                <><tr className="user-data">
                    <td>{appUsersCount + index + 1}</td>
                    <td>{ item.userName || 'Unknown'}</td>
                    <td>Non App Participant</td>
                    <td style={{color: 'green'}}>{item.readStatus===1?'Acknowledged':''}</td> 
                </tr></> 
            )
        })  
    }
    renderFormDetails = () => {
        const { jsaInstance } = this.props;
        let fileName = ''; 
        if (jsaInstance) {
            const { jsaInstance: { props: { date } } } = this.props;
            fileName = `JSA Report for ${date}`;
        }
        return ( 
            <DetailsPageWrapper
                pdfFileName={fileName}
                className="jsa-report report-details"
                customButton={<p><Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
               Close
                </Button>
                    {/* <Button style={{
                        color: '#FFFFFF',
                        border: 'none',
                        backgroundColor: '#26a65b'
                    }}
                        onClick={() => this.props.history?.push(`/jsa-reports/${jsaInstance.props.id}/template`)}
                    >
                        <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                        Use as Template
                    </Button> */}
                </p>
                    }
                >
                {this.renderContent()}
            </DetailsPageWrapper>
        );
    }

    render() {
        const { jsaInstance } = this.props;
        return (
            <BasePage >
                <Gallery images={jsaInstance ? jsaInstance.props.imageUrls : []} />
                <Async
                    identifier="JSADetailsPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderFormDetails()}
                />
            </BasePage >
        );
    }
}

export function forEachStateToProps(state: any, ownProps: any) {
    const jsaId = ownProps.match.params.id;
    const jsaInstance = JSAModel.get(ownProps.match.params.id);
    const userId = UserModel.getUserUID();
    let associatedUserId = '';
    if(ownProps.match.params.associatedUserId)
        associatedUserId = ownProps.match.params.associatedUserId;
    return { jsaInstance, jsaId, userId, associatedUserId };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(forEachStateToProps)(DetailsPageImpl));
