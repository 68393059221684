import { BasePage } from './BasePage';
import * as React from 'react';

export interface IUnAuthorizedProps {
}

export class UnAuthorizedPage extends React.PureComponent<IUnAuthorizedProps, {}> {
    constructor(props: IUnAuthorizedProps | Readonly<IUnAuthorizedProps>) {
        super(props);
    }

    render() {
        return (
            <BasePage>
                <div className="un-authorized-content text-center error-page" style={errorPage}>
                    <h1 className="mt20">
                        <i className="fa fa-ban" style={faClass} aria-hidden="true"></i> Not Authorized
                    </h1>

                    <p> You are not authorized to access the page.<br></br> Please <a href="/" title="Home">click here</a> to Home or contact Administrator for details.</p>
                </div>
            </BasePage>
        );
    }
}
const errorPage: React.CSSProperties = {
    textAlign: 'center',
    padding: '40px'
}; 
const faClass: React.CSSProperties = {
    color: '#e17e23',
}
