import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { hideModal } from '../../actions/modalActions';
import { UserModel } from '../../model/UserModel';
import { updateUserPassword } from '../../services/userService';
import { Button } from '../reusableComponents/FormComponents/Button';
import { withRouter } from 'react-router-dom';  
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';

export interface IPasswordUpdationModalProps {
    id?: string;
    modalHeading: string;
    showModal: boolean;
    modalID: string;
    instance: UserModel;
    history?: IHistory;
}

export interface IPasswordUpdationModalState {
    showModal: boolean; isSubmit: boolean;
}

export class PasswordUpdationModalImpl extends 
React.PureComponent<IPasswordUpdationModalProps, IPasswordUpdationModalState> {
    constructor(props: IPasswordUpdationModalProps | Readonly<IPasswordUpdationModalProps>) {
        super(props);
        this.state = { showModal: true, isSubmit: false };
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    showModal = () => {
        const { props: { id, modalID, instance, history } } = this;
        if (modalID !== id) {
            return <div></div>;
        }
        return (
            <Modal className="confirmation-modal" show={this.state.showModal} onHide={() => hideModal()}>
                {this.state.isSubmit && <SubmissionLoader />}
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }}>Confirm Password Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Update the Password for user &nbsp;
                         {this.props.modalHeading} ?
                          
                    <div className="modal-buttons">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={async () => { this.setState({ isSubmit: true }); await instance && instance.props.userUID && updateUserPassword(instance.props.userUID, history); this.setState({ isSubmit: false }); } }
                        >
                            Yes
                        </Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => hideModal()}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return this.showModal();
    }
}

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('modalID'),
        showModal: state.modal.get('showModal'),
        modalHeading: state.modal.get('modalHeading'),
        instance: state.modal.get('instance')
    };
}

export const PasswordUpdationModal = withRouter(connect< IPasswordUpdationModalProps, any, any>(mapStateToProps)
    (PasswordUpdationModalImpl));
