import { BaseModel } from './BaseModel';

export interface IOfficeSafetyModelProps {
    id: string;
    observer?: string;
    date: string;
    time: string;
    dateOfIncident: string;
    timeOfIncident: string;
    mobileLocation: string;
    location?: string;
    locationName?: string;
    eyesOnPath?: string;
    ascendingDescending?: string;
    onTheMoveComments?: string;
    ergonomicsComments?: string;
    manualHandlingComments?: string;
    bodyPositionComments?: string;
    toolSelectionComments?: string;
    healthHygieneComments?: string;
    housekeepingComments?: string;
    toolOrEquipment?: string;
    conditionUse?: string;
    liftingPoweringTwisting?: string;
    pushingPulling?: string;
    ergonomicsWorkstation?: string;
    lineOfFire?: string;
    pinchPoint?: string;
    eyesOnTask?: string;
    foodSafety?: string;
    cleanliness?: string;
    hskAccessEgress?: string;
    hskStorageHousekeeping?: string;
    envAccessEgress?: string;
    tailgating?: string;
    personalProperty?: string;
    barricading?: string;
    securityComments?: string;
    envStorageHousekeeping?: string;
    doubleSidePrinting?: string;
    recycling: string;
    computerShutdown: string;
    envRecyclingComments: string;
    officeSafetyImages?: {
        name: string;
        image: string;
    }[];
    officeSafetyVideos?: {
        name: string;
        file: string;
    }[];
    groupName: string;
    projectName: string;
    userId?: string;
    username?: string;
    key?: string;
    dateReported: string;
    ans: any;
    imageUrls?: string[];
    videoUrls?: string[];
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string; 
    totalFollowUp?: number; 
    totalClosedFollowUp?: number;
}

export class OfficeSafetyModel extends BaseModel<IOfficeSafetyModelProps> {
    constructor(props: IOfficeSafetyModelProps) {
        super(props, OfficeSafetyModel.resource);
    }
    static resource = 'office-safety';
}
