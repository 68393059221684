import * as React from 'react'; 
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap'; 
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';  
import { IForms, ISubAttributeData } from '../../interfaces';  
import { dispatch, getSWPChecklistHints, showAlert } from '../../utils/generalUtils';
import { formatChecklistQuestions, deleteAttribute } from '../../services/attributeService'; 
import { IAttributeDataState } from '../../components/Micellaneous/SafetyWalkAssesment/AddAttribute';
import { UserModel } from '../../model/UserModel';
import { showChecklistProcessModal, showDelConfirmModal } from '../../actions/modalActions';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { ChecklistAttributeModal } from '../reusableComponents/ChecklistAttributeModal';
import { ChecklistAttributeModel } from '../../model/ChecklistModel';
import { actions } from 'react-redux-form';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';
const MenuItem = Dropdown.Item

export interface IChecklistFormProps { 
    currentStep?: number;
    company: string;
    forms: IForms; 
    checklistAttributes: ChecklistAttributeModel;
    editInstance: any;
    checklistComments: any
    onChange: (value: any, model?: string) => void;
}
export interface IChecklistFormState {    
    currentDate: string; 
    permitType: string; 
    attributeList: any;
    showLoader: boolean;
}
export class ChecklistForm extends React.PureComponent<IChecklistFormProps, IChecklistFormState> {
    constructor(props: IChecklistFormProps | Readonly<IChecklistFormProps>) {
        super(props); 
        let checklistAttributesProps = props.checklistAttributes?props.checklistAttributes: []; 
        this.state = {attributeList: checklistAttributesProps, currentDate: '', permitType: '', showLoader: false, }  
    }   
    checklistChange = async (checklistId) => { 
        this.getAttributeQuestions(checklistId);
    }
    updateChecklistAttribute = async () => { 
        this.getAttributeQuestions(this.props.forms.permitToWorkForm.SWPChecklistType); 
    }
    getAttributeQuestions = async (checklistId) => {  
        this.setState({showLoader: true});
        let checklistIds = (checklistId && checklistId.split(',') ) || [];  
        this.setState({attributeList: [] })
        if(checklistIds.length>0) { 
            for(let i = 0; i<checklistIds.length; i++) {
                let questions = await formatChecklistQuestions(checklistIds[i]); 
                questions = this.state.attributeList.concat(questions);
                this.setState({attributeList: questions }); 
            }
        } 
        this.setState({showLoader: false});
    }
    onDeleteAttribute(index: number, attributeItem: IAttributeDataState) {
        let attributeData = { index, attributeItem}
        showDelConfirmModal('delete-confirmation', 'Checklist Question', attributeData);  
    }
    onConfirmDeleteAttribute = (attributeData: any) => {  
        let index = attributeData.index;
        let attributeItem = attributeData.attributeItem; 
        let newAttributeArray = this.state.attributeList.filter(e => e !== this.state.attributeList[index]);
        if (newAttributeArray && newAttributeArray.length > 0) {
            newAttributeArray.map((attribute, index) => {
                attribute.attributeId = index + 1 + '';
            });
        }
       /*  let formType = this.props.forms.permitToWorkForm.SWPChecklistType; 
        dispatch(deleteAttributeAction(newAttributeArray, attributeItem, formType)); */
        let deletingAttribute = { formType: attributeItem.checklistId, mainAttr: attributeItem.attribute }; 
        deleteAttribute(deletingAttribute);
        this.setState({ attributeList: newAttributeArray });
        showAlert('Checklist updated successfully', 'Checklist-Modal', 'success' ); 
    }
    onEditAttribute(item: IAttributeDataState) {   
        item['checklistType'] = item.checklistId;
        item['totalSubAttribute'] = 2;
        showChecklistProcessModal('checklist-attribute-process', { instance: item }); 
    }
    processChecklistAttribute = (checklistId) => { 
        let item: any = {'checklistType': checklistId, subAttributeList: [], attribute: '', totalSubAttribute: 2, };
        showChecklistProcessModal('checklist-attribute-process', { instance: item });  
    }
    async onSubAttributeSelectionChange(event: React.ChangeEvent<HTMLInputElement>, item: ISubAttributeData, selection: string, checklistIdentifier: string) {
        const { forms } = this.props; 
        let subAttributeId = `${checklistIdentifier}${item.subAttributeId}`; 
        if(selection==='yes') {
            await dispatch(actions.push(`forms.permitToWorkForm.attributeYes`, subAttributeId )); 
            let attributeNA = forms.permitToWorkForm.attributeNA;
            if(attributeNA) { 
                let index = attributeNA.indexOf(subAttributeId); 
                if(index>-1) {
                    await dispatch(actions.remove(`forms.permitToWorkForm.attributeNA`,index )); 
                }
            } 
        } else if(selection==='na') { 
            await dispatch(actions.push(`forms.permitToWorkForm.attributeNA`, subAttributeId )); 
            let attributeYes = forms.permitToWorkForm.attributeYes;
            if(attributeYes) {
                let index = attributeYes.indexOf(subAttributeId);
                if(index>-1) {
                    await dispatch(actions.remove(`forms.permitToWorkForm.attributeYes`,index )); 
                }
            } 
        } 
    }
    renderSubAttribute(subAttributeData: ISubAttributeData[], row: number, attributeData: any) { 
        const { forms } = this.props; 
        let attributeYes = ( forms.permitToWorkForm.attributeYes?.length && forms.permitToWorkForm.attributeYes ) || [];
        let attributeNA = ( forms.permitToWorkForm.attributeNA?.length && forms.permitToWorkForm.attributeNA ) || [];
        let checklistIdentifier = attributeData.checklistId.replace(/ /g,''); 
        return subAttributeData.map((item, index) => {
            return <Row key={`subAttributeRow${checklistIdentifier}${item.subAttributeId}`}
             className="customized-grid row-cell-height border-bottom 
             border-left border-right margin-left-right-0 padding-bottom-0">
                <Col className="row-cell-height border-right" sm={1} xs={12}>

                </Col>
                <Col className="row-cell-height" sm={9} xs={12}>
                    <Label sm={12}>{item.name}</Label>
                </Col>
                <Col className="row-cell-height  border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter">
                        <input className="localcss radio-button-size" id={`.attributeYes${checklistIdentifier}${item.subAttributeId}`} type="radio" name={`attributeradio${checklistIdentifier}${item.subAttributeId}`} onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'yes', checklistIdentifier)} checked={attributeYes && attributeYes.indexOf(`${checklistIdentifier}${item.subAttributeId}`)>-1?true:false} /> 
                        <label htmlFor={`.attributeYes${checklistIdentifier}${item.subAttributeId}`}></label>
                    </div>
                </Col>
                
                <Col className="row-cell-height border-left" sm={1} xs={12}>
                    <div className="width-height-100-textcenter">
                        <input id={`.attributeNA${checklistIdentifier}${item.subAttributeId}`} type="radio" className="radio-button-size" name={`attributeradio${checklistIdentifier}${item.subAttributeId}`} onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'na', checklistIdentifier)} checked={attributeNA && attributeNA.indexOf(`${checklistIdentifier}${item.subAttributeId}`)>-1?true:false}/> 
                        <label htmlFor={`.attributeNA${checklistIdentifier}${item.subAttributeId}`}></label>
                    </div>
                </Col>
            </Row>;
        });
    }

    renderAttribute(attributeList: IAttributeDataState[], checklistId) { 
        return attributeList.map((item, index) => {
            return checklistId === item.checklistId && <div key={`attributeRowParent${checklistId}${index}`}>
                <Row key={`attributeRow${checklistId}${index}`} 
                className="border-bottom row-cell-height border-left 
                border-right margin-left-right-0 padding-bottom-0 color-white"> 
                    <Col className="row-cell-height border-right" sm={1} xs={12}>
                        { ( UserModel.checkUserAccess('safety_walkthrough_question_add') || UserModel.checkUserAccess('safety_walkthrough_question_delete') ) && 
                            <DropdownButton title="" id="bg-vertical-dropdown-3 " className="attribute-menu-dropdown">
                                { UserModel.checkUserAccess('safety_walkthrough_question_delete') && <MenuItem eventKey="2" onClick={() => this.onEditAttribute(item)}
                                    className="item">&nbsp;Edit</MenuItem> }
                                { UserModel.checkUserAccess('safety_walkthrough_question_delete') && <MenuItem eventKey="1" onClick={() => this.onDeleteAttribute(index, item)}
                                    className="item">&nbsp;Delete</MenuItem> } 
                            </DropdownButton> 
                        }
                        <div className="sequence-number-position">
                            {`${item.attributeId}.`}
                        </div>
                    </Col>
                    <Col className="row-cell-height" sm={9} xs={12}>
                        <div className="width-height-100-textcenter">{item.attribute}</div>
                    </Col>
                    
                    <Col className="row-cell-height color-white border-left" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height " sm={1} xs={12}>
                    </Col>
                </Row>
                {this.renderSubAttribute(item.subAttributeList, index, item)}
                {this.renderAttributeComments(item.subAttributeList, index, item) }
            </div>;
        });
    }
    renderAttributeComments = (subAttributeData: ISubAttributeData[], row: number, attributeData: any) => { 
        const { checklistComments, forms } = this.props; 
        let checklistIdentifier = attributeData.checklistId.replace(/ /g,'');  
        /* let comment = (forms.permitToWorkForm.checklistComments?.length && forms.permitToWorkForm.checklistComments[`${checklistIdentifier}~${row}`]) || '';  */
        let comment = checklistComments[`${checklistIdentifier}~${row}`] || ''; 
         
        return <Row key={`subAttributeRow${checklistIdentifier}${row}`} className="customized-grid row-cell-height border-bottom border-left border-right margin-left-right-0 padding-bottom-0 p10"> 
                <Col className="row-cell-height input" sm={2} xs={12}> 
                    <label>Comments: </label>
                </Col>
                <Col className="row-cell-height input" sm={9} xs={12}> 
                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.checklistComments[`${checklistIdentifier}~${row}`] || comment} model={`forms.permitToWorkForm.checklistComments~${checklistIdentifier}~${row}`}  /> 
                    <RRFInput
                        id="checklistComments"
                        type="text" 
                        model={`.checklistComments~${checklistIdentifier}~${row}`} 
                        onChange={this.getCommentsValue}
                        defaultValue={comment} 
                    />
                </Col> 
            </Row>
    }
    getCommentsValue = async (e, model) => { 
        let modelName = model.replace( /(^.*\[|\].*$)/g, '' ) ;  
        let models = modelName.split('~')
        if(this.props.onChange) {
            this.props.onChange(e.target.value, `${models[1]}~${models[2]}`)
        }
    }
    renderChecklistHTML() {
        const { forms } = this.props;  
        let checklistIds = (forms.permitToWorkForm.SWPChecklistType && forms.permitToWorkForm.SWPChecklistType.split(',')) || []; 
        checklistIds.sort();
        if(checklistIds.length>0) {
            return checklistIds.map((item: any, index: any) => { 
                return  <><div>
                    <Row className="show-grid">
                        <Label sm={12}><b style={{textTransform: 'capitalize'}}>{ (item) }</b> {getSWPChecklistHints(item)}</Label>
                    </Row>
                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col className="border-bottom border-right" sm={1} xs={12}>
                            <Label sm={12}>No.</Label>
                        </Col>
                        <Col className="border-bottom" sm={9} xs={12}>
                            <Label sm={12}>Attribute</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={1} xs={12}>
                            <Label sm={12}>Yes</Label>
                        </Col>
                        <Col className="border-bottom border-right border-left" sm={1} xs={12}>
                            <Label sm={12}>N/A</Label>
                        </Col> 
                    </Row> 
                    { (this.state.attributeList.length && this.renderAttribute(this.state.attributeList, item)) || null }
                    <Row className="show-grid" >
                        <div className="table-buttons">
                            <button type="button" className="add-row"  onClick={() => this.processChecklistAttribute(item)} >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Addribute
                            </button>
                        </div>
                    </Row>
                </div> 
                <div className='checklist-border'>

                </div></>
            });
        }
    }
    render() {
        let editInstance = this.props.editInstance;  
        const { forms } = this.props;  
        return (
             <div>
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}> 
                    <AlertModal id="Checklist-Modal" /> 
                    <ChecklistAttributeModal id="checklist-attribute-process" onUpdate={this.updateChecklistAttribute } />
                    <DeleteConfirmationModal id="delete-confirmation" onConfirm={this.onConfirmDeleteAttribute}/>
                    <label>Form {this.props.currentStep} - Permit</label> 
                    {this.state.showLoader && <SubmissionLoader/>}
                    <Row className="show-grid">
                        <Col sm={{span: 4, offset: 4}} xs={12}>
                            <Label required sm={12} htmlFor="observationType">SWP Checklist Type:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="SWPChecklistType" 
                                    model=".SWPChecklistType"
                                    type="dropdown"
                                    multi={true}
                                    defaultValue={forms.permitToWorkForm.SWPChecklistType || editInstance.SWPChecklistType }
                                    onSelect={this.checklistChange}
                                    menuItems={[ { label: 'Abrasive Blasting', value: 'abrasive blasting' }, { label: 'Breaking Containment', value: 'breaking containment' }, { label: 'Confined Space', value: 'confined space' }, { label: 'Critical Lift Operations', value: 'critical lift operations' }, { label: 'Diving Operations', value: 'diving operations' }, { label: 'Excavation Ground Disturbance', value: 'excavation ground disturbance' }, { label: 'High Risk Electical', value: 'high risk electical' }, { label: 'Hot Work', value: 'hot work' }, { label: 'Working at Heights', value: 'working at heights' }, ]} 
                                />
                            </Col>
                        </Col>
                    </Row>  
                    {this.renderChecklistHTML() }  
                </fieldset>
             </div>
        );
    }
}
