import * as React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
  } from 'recharts'; 
 
export interface IBarProps { 
    data: object[];
    dataKey: string[];
    strokeColors: string[];
    hideLegend?: boolean;
}

export class StackedBarChart extends React.PureComponent<IBarProps, {}> {
    constructor(props: IBarProps | Readonly<IBarProps>) {
        super(props);
    }
    
    renderlinearGradients = () => { 
        const { dataKey, strokeColors } = this.props;
        return strokeColors.map((key, index) => {  
            return <linearGradient id={`${dataKey[index]}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={`#${strokeColors[index]}`} stopOpacity={1}/>
            <stop offset="95%" stopColor={`#${strokeColors[index]}`} stopOpacity={1}/>
            </linearGradient> 
        });
    }
    renderDatas  = () => {
        const { dataKey, strokeColors } = this.props;
        return dataKey.map((key, index) => { 
            return <Bar name={`${dataKey[index]}`} dataKey={`${dataKey[index]}`} stackId="a" stroke={`#${strokeColors[index]}`} fill={`#${strokeColors[index]}`}/>
            /* return <Area name={`${dataKey[index]}`} type='monotone' dataKey={`${dataKey[index]}`} stackId="1" stroke={`#${strokeColors[index]}`}  fill={`#${strokeColors[index]}`} /> */
        });
    }
    render() { 
        const { data, hideLegend } = this.props;  
        return (
            <div style={{ height: '100%' }}>
            <ResponsiveContainer>
            <BarChart
              width={600}
              height={400}
              data={data}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0
              }}
            >
                <CartesianGrid strokeDasharray="2 2" />
                <defs> 
                    {this.renderlinearGradients()} 
                </defs>
                <XAxis dataKey="name" allowDecimals={false}/>
                <YAxis allowDecimals={false} />
                <Tooltip /* cursor={{fill: 'transparent'}} */ />
                { !hideLegend && <Legend verticalAlign="bottom" height={20}/> }
                { this.renderDatas() }  
            </BarChart> 
            </ResponsiveContainer>
            </div >
          ); 
    }
}
