import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { IHistory } from '../../interfaces';
import { FollowUpModel } from '../../model/FollowUpModel';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { INCIDENTINVESTIGATE_PREFIX } from '../../constants/general';

export interface IFollowUpListItemProps {
    history?: IHistory;
    index?: number;
    instance?: FollowUpModel;
    style?: React.CSSProperties;
    inputParam1?: string;
}

export class FollowUpListItemImpl extends React.PureComponent<IFollowUpListItemProps, {}> {
    constructor(props: IFollowUpListItemProps | Readonly<IFollowUpListItemProps>) {
        super(props);
    }

    render() {
        const { instance, style, history, inputParam1 } = this.props;
        if (!instance ) {
            return;
        }  
        return (
            <Row
                className="report-row"
                style={style}
                onClick={() => inputParam1?history?.push(`/incident-investigative-reports/follow-up-detail/${instance.props.id}/${inputParam1}`):history?.push(`/incident-investigative-reports/follow-up-detail/${instance.props.id}`)}
            >
                {/* <Cell sm={1}>{index + 1}</Cell> */}
                <Cell sm={1}>{`${INCIDENTINVESTIGATE_PREFIX}-${instance.props.FormSerialNo}` }</Cell>
                <Cell sm={4}>{instance.props.CreatedBy}</Cell>
                <Col className="clickable-list-item" sm={4}>{instance.props.Complete === 0 ?
                    <span>
                        <i style={{ fontSize: '20px', color: '#888888' }} className="fa  fa-clock-o"></i>
                        &nbsp;Pending
                    </span> :
                    <span>
                        <i style={{ fontSize: '20px', color: '#26a65b' }} className="fa fa-check-circle"></i>
                        &nbsp;Complete
                    </span>
                }</Col>
                <Cell sm={3}>{instance.props.TargetDate}</Cell>
            </Row >
        );
    }
}

export function mapStateToProps() { 
    return {
    };
}

export const FollowUpListItem =
    withRouter(connect< IFollowUpListItemProps, any, any>(mapStateToProps)(FollowUpListItemImpl));
