import { IIncidentSummaryPageProps } from '../components/IncidentNotificationForm/IncidentSummaryPage';
import {
    DELETE_CONCERN_TYPE,
    SAVE_CONCERN_TYPES,
    SAVE_INCIDENT_NOTIFICATION_SUMMARY,
    SAVE_OI_FORM_SUMMARY,
    SAVE_NOTIFICATIONS,
    SAVE_INCIDENT_INVESTIGATIVE_SUMMARY,
    SAVE_ACTIVITIES_MENU_ITEMS,
    SAVE_OFFICE_SAFETY_SUMMARY,
    GET_OFFICE_SAFETY_COMMENT, SAVE_PANDEMIC_SUMMARY, SAVE_PANDEMIC_SHORT_SUMMARY, SAVE_RISK_ASSESSMENT_SUMMARY, SAVE_HP_FORM_SUMMARY
} from '../constants/actions';
import { IIncidentInvestigativeSummary, INotification, IOIReportSummary, IRiskAssessmentSummary, ISelectOptions } from '../interfaces';
import { dispatch } from '../utils/generalUtils';
import { ICovid19ShortSummaryProps } from '../model/Covid19Model';

export function saveOIFormSummary(summaryData: IOIReportSummary) {
    dispatch({
        type: SAVE_OI_FORM_SUMMARY,
        summaryData
    });
}

export function saveIncidentInvestigativeSummary(summaryData: IIncidentInvestigativeSummary) {
    dispatch({
        type: SAVE_INCIDENT_INVESTIGATIVE_SUMMARY,
        summaryData
    });
}

export function saveIncidentNotificationSummary(summaryData: IIncidentSummaryPageProps) {
    dispatch({
        type: SAVE_INCIDENT_NOTIFICATION_SUMMARY,
        summaryData
    });
}

export function saveOfficeSafetyFormSummary(summaryData: any) {
    dispatch({
        type: SAVE_OFFICE_SAFETY_SUMMARY,
        summaryData
    });
}

export function getOfficeSafetyComment(commentList: any) {
    dispatch({
        type: GET_OFFICE_SAFETY_COMMENT,
        commentList
    });
}

export function saveConcernTypes(concernTypes: Object[]) {
    return dispatch({
        type: SAVE_CONCERN_TYPES,
        concernTypes
    });
}

export function deleteConcernType(concern: string) {
    return dispatch({
        type: DELETE_CONCERN_TYPE,
        concern
    });
}

export function saveNotifications(notifications: INotification[], totalFollowupOpen) {
    return dispatch({
        type: SAVE_NOTIFICATIONS,
        notifications, totalFollowupOpen
    });
}

export function saveActivitiesOptions(activityOptions: ISelectOptions[]) {
    return dispatch({
        type: SAVE_ACTIVITIES_MENU_ITEMS,
        activityOptions
    });
}
export function savePandemicSummary(summaryData: any) {
    dispatch({
        type: SAVE_PANDEMIC_SUMMARY,
        summaryData
    });
} 
export function savePandemicShortSummary(summaryData: ICovid19ShortSummaryProps) {
    dispatch({
        type: SAVE_PANDEMIC_SHORT_SUMMARY,
        summaryData
    });
} 
export function saveRiskAssessmentSummary(summaryData: IRiskAssessmentSummary) {
    dispatch({
        type: SAVE_RISK_ASSESSMENT_SUMMARY,
        summaryData
    });
}

export function saveHPFormSummary(summaryData: any) {
    dispatch({
        type: SAVE_HP_FORM_SUMMARY,
        summaryData
    });
} 
