import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';
import { dispatch } from '../../utils/generalUtils';
import { actions } from 'react-redux-form';
import { Button } from '../reusableComponents/FormComponents/Button';
import { getProjectsOfUser } from '../../services/userService';
import { store } from '../../store';
import { format } from 'date-fns';
import { IncidentReportModel } from '../../model/IncidentReportModel'; 

export interface IIncidentInvestigationSummaryProps {
    userId: string;
    editInstance?: IncidentReportModel;
}

export interface IIncidentInvestigationSummaryState {
    noOfActionItems: any[];
}

export class IncidentInvestigationSummaryForm extends React.PureComponent<IIncidentInvestigationSummaryProps
    , IIncidentInvestigationSummaryState> {
    constructor(props: IIncidentInvestigationSummaryProps | Readonly<IIncidentInvestigationSummaryProps>) { 
        super(props); 
        let noOfRows: Array<any> = [];
        if(props.editInstance) {
            noOfRows = props.editInstance.props.incidentInvestigation; 
            if(noOfRows.length<1) {
                noOfRows = [1];
            }
            this.state = { noOfActionItems: noOfRows }; 
        } else {
            noOfRows = store.getState().forms.incidentDetails.incidentInvestigation || [1];
            this.state = { noOfActionItems: noOfRows };
        }  
    }

    componentDidMount() { 
        // dispatch(actions.push(`forms.incidentDetails.incidentInvestigation`, {
        //     actionItems: '',
        //     partyResponsible: '',
        //     targetDate: format(new Date(), 'yyyy-MM-dd'),
        // })); 
        dispatch(actions.change(`forms.incidentDetails.incidentInvestigation[0].targetDate`, format(new Date(), 'yyyy-MM-dd')));
            
        if(this.props.editInstance) { 
            dispatch(actions.change('forms.incidentDetails.formSerialNo', this.props.editInstance.props.formSerialNo));
        }
    }

    renderTableRows = (index: any) => {
        const editInstance = this.props.editInstance;  
        let date:any = '';
        if(editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['TargetDate'] ) {
            date = format(new Date(editInstance.props.incidentInvestigation[index]['TargetDate']),'yyyy-MM-dd')
        } 
        dispatch(actions.change (`forms.incidentDetails.incidentInvestigation[${index}].UUID`, editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['UUID'] ) );
        return (
            <Row key={index} className="show-grid action-items">
                <Col sm={1}>
                    {index + 1}
                </Col>
                <Col sm={1}
                    className="trash"
                    style={{ cursor: 'pointer', textAlign: 'center' }} >
                    {this.state.noOfActionItems.length > 1 && ( ( editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['Complete']===0 ) || !editInstance || (editInstance && editInstance.props.incidentInvestigation.length<=index) )? <i onClick={() => this.decrementRows(index)}
                        className="fa fa-lg fa-trash"
                        aria-hidden="true"
                    >
                    </i> : null}
                </Col>
                <Col sm={5} className="input">
                    <RRFInput
                        id="actionItems"
                        type="textarea"
                        className="action-item"
                        placeholder="Enter action items(Must address / match root casual factor(s) identified above)..."
                        model={`.incidentInvestigation[${index}].actionItems`}
                        defaultValue={editInstance && editInstance.props.incidentInvestigation.length>index ? editInstance.props.incidentInvestigation[index]['actionItems']:''}
                        isDisabled={editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['Complete']===1?true:false}
                    />
                </Col>
                <Col sm={3} className="input">
                    <RRFInput
                        id="partyResponsible"
                        multi={false}
                        type="dropdown"
                        menuItems={getProjectsOfUser(this.props.userId)}
                        className="party-responsible"
                        placeholder="Select party responsible..."
                        model={`.incidentInvestigation[${index}].partyResponsible`}
                        defaultValue={editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['partyResponsible']}
                        isDisabled={editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['Complete']===1?true:false}
                    />
                </Col>
                <Col sm={3} className="input">
                    <RRFInput
                        id="targetDate" 
                        type="date"
                        className={this.tableRows > 1 ? 'target-date-trash' : 'target-date'}
                        model={`.incidentInvestigation[${index}].targetDate`}
                        defaultValue={date}
                        mindate={editInstance ?( editInstance.props.incidentInvestigation.length<=index?format(new Date(), 'yyyy-MM-dd'):format(new Date(editInstance.props.date), 'yyyy-MM-dd')) : format(new Date(), 'yyyy-MM-dd') }
                        isDisabled={editInstance && editInstance.props.incidentInvestigation.length>index && editInstance.props.incidentInvestigation[index]['Complete']===1 ?true:false} 
                    />
                </Col>
            </Row>
        );
    }

    tableRows = 1;

    incrementRows = (event: any) => {
        event.preventDefault();
        dispatch(actions.push(`forms.incidentDetails.incidentInvestigation`, {
            actionItems: '',
            partyResponsible: '',
            targetDate: format(new Date(), 'yyyy-MM-dd'),
        }));
        this.setState({
            noOfActionItems: [...this.state.noOfActionItems, ++this.tableRows]
        });
    }

    decrementRows = (indexVal: any) => {
        dispatch(actions.remove(`forms.incidentDetails.incidentInvestigation`, indexVal));
        --this.tableRows;
        this.setState({
            noOfActionItems: this.state.noOfActionItems.slice(0, - 1)
        }); 
    }

    renderFormTable = () => {
        return (
            <div className="root-actions">
                <Row className="show-grid heading">
                    {/* <Label required={false} sm={1}></Label> */}
                    <Label required sm={6} htmlFor="actionItems">
                        Action Items(Must match root casual factor(s)):
                    </Label>
                    <Label required sm={3} htmlFor="partyResponsible">Party Responsible:</Label>
                    <Label required sm={3} htmlFor="targetDate">Target Date:</Label>
                </Row >
                {this.state.noOfActionItems.map((a, index) => { 
                    return this.renderTableRows(index);
                })
                }
                <div className="table-buttons">
                    <button style={{
                            color: '#FFFFFF',
                            border: 'none',
                            backgroundColor: '#4B77BE'
                        }}
                        className="add-row"
                        onClick={this.incrementRows}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;Add Another Item
                        </button>
                </div>
            </div>
        );
    }

    render() { 
        let editInstance = this.props.editInstance; 
        return (
            <div>
                <div className="incident-investigation-summary">
                    <fieldset className="">
                        <legend><span style={{color: 'yellow'}}>
                        Incident / Near Miss Investigation Summary</span></legend>
                        <Row className="show-grid">
                            <Label
                                className="direct-cause"
                                sm={3}
                                htmlFor="directCause"
                            >
                                Direct Cause:
                         </Label>
                            <Col sm={8} className="input">
                                <RRFInput
                                    type="textarea"
                                    placeholder="Enter what are the direct causes to the incident..."
                                    model=".directCause"
                                    textAreaRows={3}
                                    defaultValue={editInstance && editInstance.props.directCause}                               />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="rootCause">
                                    Primary &amp; Other Significant Contributing Factor (Root Cause):
                    </Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        type="textarea"
                                        placeholder="Impact w/ Other Vehicle/Vessel/Dropped or Moving Object..."
                                        model=".rootCause"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.rootCause}      
                                    />
                                </Col>

                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="incidentActivity">
                                    Identify activities contributing to the incident:
                                </Label>
                                <Col sm={12} className="input">
                                    <br />
                                    <RRFInput
                                        id="incidentActivity"
                                        type="textarea"
                                        placeholder=
                                        "Identify activities contributing to the incident..."
                                        model=".incidentActivity"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.incidentActivity}      
                                    />
                                </Col>

                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="incidentConditions">
                                    Identify conditions contributing to the incident:
                    </Label>
                                <Col sm={12} className="input">
                                    <br />
                                    <RRFInput
                                        id="incidentConditions"
                                        type="textarea"
                                        placeholder="Identify conditions contributing to the incident..."
                                        model=".incidentConditions"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.incidentConditions}      
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="workDirection">
                                    How did work direction influencing the incident?:
                    </Label>
                                <Col sm={12} className="input">
                                    <br />
                                    <RRFInput
                                        id="workDirection"
                                        type="textarea"
                                        placeholder="How did work direction influencing the incident?..."
                                        model=".workDirection"
                                        textAreaRows={3}
                                        defaultValue={editInstance && editInstance.props.workDirection}      
                                    />
                                </Col>
                            </Col>
                        </Row>
                        {/* <Row className="show-grid project-oims">
                    <Row className="show-grid heading">
                        <Label
                            style={{ textAlign: 'center' }}
                            sm={8} htmlFor="projectOIMSSystem"
                        >
                            Project OIMS Systems:
                    </Label>
                        <Label sm={4}
                            style={{ textAlign: 'center' }}
                            htmlFor="projectOIMSSystem"
                        >
                            OIMS System Date:
                    </Label>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={1}>1</Col>
                        <Col sm={7} className="input">
                            <RRFInput
                                id="projectOIMSSystem"
                                type="textarea"
                                className="oims-system"
                                placeholder="Enter Project OIMS Systems..."
                                model=".projectOIMSDetails[0].projectOIMSSystem"
                            />
                        </Col>
                        <Col sm={4} className="input">
                             <RRFInput
                                id="OIMSSystemDate"
                                type="date"
                                className="date"
                                model=".projectOIMSDetails[0].OIMSSystemDate"
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={1}>2</Col>
                        <Col sm={7} className="input">
                            <RRFInput
                                id="projectOIMSSystem"
                                type="textarea"
                                className="oims-system"
                                placeholder="Enter Project OIMS Systems..."
                                model=".projectOIMSDetails[1].projectOIMSSystem"
                            />
                        </Col>
                        <Col sm={4} className="input">
                             <RRFInput
                                id="OIMSSystemDate"
                                type="date"
                                className="date"
                                model=".projectOIMSDetails[1].OIMSSystemDate"
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={1}>3</Col>
                        <Col sm={7} className="input">
                            <RRFInput
                                id="projectOIMSSystem"
                                type="textarea"
                                placeholder="Enter Project OIMS Systems..."
                                className="oims-system"
                                model=".projectOIMSDetails[2].projectOIMSSystem"
                            />
                        </Col>
                        <Col sm={4} className="input">
                             <RRFInput
                                id="OIMSSystemDate"
                                type="date"
                                className="date"
                                model=".projectOIMSDetails[2].OIMSSystemDate"
                            />
                        </Col>
                    </Row>
                </Row> */}
                        {this.renderFormTable()}
                    </fieldset>
                </div>
                <div className="form-button" style={{ marginTop: '50px' }}>
                    <Button style={{
                        color: 'rgb(84, 121, 175)',
                        border: '1px solid #5479AF',
                        backgroundColor: 'white',
                    }}
                        redirectTo="reset" type="button"  
                    >
                        <i className="fa fa-ban" aria-hidden="true"></i>
                        &nbsp;
                            Cancel
                        </Button>
                    <Button style={{
                        color: '#FFFFFF',
                        border: 'none',
                        backgroundColor: '#26A65B'
                    }}
                    >
                        <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                        &nbsp;
                            { editInstance ? 'Update' : 'Submit' }
                        </Button>
                </div>
            </div >
        );
    }
}
