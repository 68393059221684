import { BaseModel } from './BaseModel';

export interface ITransModelProps {
    id?: string;
    key?: string; 
    transImages?: { name: string; image: string; }[];
    imageUrls?: string[];
    transVideos?: { name: string; file: string; }[];
    videoUrls?: string[]; 
    eventtype?: string;
    eventlocation?: string;
    facility?: string;
    involvedWorkers?: string;
    milepost?: string;
    nearstation?: string;
    phone?: string;
    hours?: string;
    carrier?: string;
    state?: string; 
    name?: string;
    address?: string;
    city?: string;
    stateString?: string;
    zip?: string;
    reporter?: string; 
    reporterOther?: string; 
    railroadyears?: string;
    yearscraft?: string; 
    crewsize?: string; 
    shiftEvent?: string;
    otherShiftEvent?: string;
    reporterLocation?: string;
    otherReporterLocation?: string;   
    weather?: string;
    outdoors?: string;
    workarea?: string;
    reducedVisibility?: string; 
    reducedVisibilityDetail?: string;  
    typeOperation?: string;
    transportType?: string;
    totalhead?: string;
    locomotive?: string;
    remoteControl?: string; 
    cst?: string;
    helpers?: string;
    distributedPower?: string;
    noofcars?: string;
    carCabControlling?: string;
    noinservice?: string;
    loads?: string;
    empties?: string;
    tons?: string;
    length?: string;
    type?: string;
    otherType?: string;
    operatingRules?: string;
    operatingRulesOthers?: string; 
    jobCompleted?: string;
    trainLocation?: string;
    operation?: string;
    trainActivity?: string;
    trainLocationOther?: string;
    operationOther?: string;
    trainActivityOther?: string;
    date?: string;
    time?: string;
    descriveEventSituation?: string;  
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string;
    createdDate?: any;
} 

export class TransModel extends BaseModel<ITransModelProps> {
    constructor(props: ITransModelProps) {
        super(props, TransModel.resource);
    }
    static resource = 'trans-from';
}
