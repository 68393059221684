import { LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT } from '../constants/actions'; 
import { UserModel } from '../model/UserModel';
import { dispatch } from '../utils/generalUtils';

export function throwError(text: string) {
    return dispatch({
        type: LOGIN_ERROR,
        text
    });
}

export function login(userInfo: UserModel) {
    return dispatch({
        type: LOGIN_SUCCESS,
        userInfo
    });
}

export function logout() {
    return dispatch({
        type: LOGOUT,
    });
}
