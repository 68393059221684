import { fromJS } from 'immutable';
import {
        GET_CHECKLIST_REPORT_DATA, GET_MODALLIST_REPORT_DATA,
        GET_CHECKLIST_REPORT_DETAILS,
        GET_CHECKLIST_REPORTCHART_DATA,
        GET_SAFETY_COMMENT_LIST
} from '../constants/actions';

export function reportReducers(state = fromJS({
        reportDataList: [],modalReportDataList: [], 
        reportDetailData: {}, reportChartData: {}, commentList: {}
}), action: { type: any; reportDataList: any; modalReportDataList: any; reportDetailData: any; reportChartData: any; commentList: any; }) { 
        switch (action.type) {
                case GET_CHECKLIST_REPORT_DATA:
                        return state.set('reportDataList', action.reportDataList);
                case GET_MODALLIST_REPORT_DATA:
                        return state.set('modalReportDataList', action.modalReportDataList);
                case GET_CHECKLIST_REPORT_DETAILS:
                        return state.set('reportDetailData', action.reportDetailData);
                case GET_CHECKLIST_REPORTCHART_DATA:
                        return state.set('reportChartData', action.reportChartData);
                case GET_SAFETY_COMMENT_LIST:
                        return state.set('commentList', action.commentList);
                default:
                        return state;
        }
}
