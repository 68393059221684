import { Alert } from '../Alert';
import { IHistory } from '../../interfaces';
import { Loader } from '../reusableComponents/Loader';
import { ConfirmationModal } from './ConfirmationModal';
import { getAllProjects } from '../../services/projectService';
import * as React from 'react';
import { ProjectModel } from '../../model/ProjectModel'; 
import { Row, Col } from 'react-bootstrap';
import { Title } from '../reusableComponents/Title';
import { BasePage } from '../reusableComponents/BasePage';
import { connect } from 'react-redux';
import { Button } from '../reusableComponents/FormComponents/Button';
import { showModal } from '../../actions/modalActions';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';

export interface IDetailsPageProps {
    projectInstance: ProjectModel;
    match?: {
        params: {
            id: string;
        }
    }; 
    history?: IHistory;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = () => {
        if (ProjectModel.list().length <= 0) {
            return getAllProjects();
        }
        return null;
    }

    handleDelete = async (id: string) => {
        const projectInstance = ProjectModel.get(id);
        new ProjectModel(projectInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-project', projectInstance.props.projectName, projectInstance);
    }

    renderEditProjectButton = () => { 
        //const { props: { match: { params: { id } } } } = this;
        const id = this.props.match?.params.id;
        return <Row className="show-grid form-button" style={{ textAlign: 'center' }}>
            <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white', width: '160px', margin:'0px 10px', 
            }}
                
                onClick={() => this.props.history?.goBack() }
                type="reset"
            >
                <i className="fa fa-ban" aria-hidden="true"></i>
                &nbsp;
                Cancel
            </Button>
            <Button style={{
                color: '#FFFFFF',
                border: 'none',
                backgroundColor: '#26a65b', width: '160px', margin:'0px 10px', 
            }}
                onClick={() => this.props.history?.push(`/teams/${id}/edit`)}
            >
                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                Edit
            </Button>
            <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white', width: '160px', margin:'0px 10px', 
            }}
                onClick={() => id && this.handleDelete(id)}
            >
                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;
                Delete
            </Button>
            
        </Row>;
    }

    renderContent = () => {
        const { projectInstance } = this.props;
        if (!projectInstance) {
            return <Loader />;
        }
        const { props: { projectName, groups, projectDesc, dateCreated, dateUpdated } } = projectInstance; 
        return (
            <div className="project-details">
                <Title text={`Team Report for ${dateCreated}`}></Title>
                <Alert className="danger-alert" id="project-details" />
                <KeyValue label="Team Name">{projectName || 'NA'}</KeyValue>
                <KeyValue label="Team Description">{projectDesc || 'NA'}</KeyValue>
                <KeyValue label="Groups">{groups.toString() || 'NA'}</KeyValue>
                <KeyValue label="Date Updated">{dateUpdated || 'NA'}</KeyValue>
                {this.renderEditProjectButton()}
            </div>
        );
    }

    renderProjectDetails = () => {
        return (
            <Async
                identifier="ProjectDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        return (
            <BasePage>
                <ConfirmationModal id="delete-project" />
                {this.renderProjectDetails()}
            </BasePage>
        );
    }
}

const KeyValue = ({ label, children }: any) => {
    return <Row className="show-grid">
        <Col xs={6} style={{ textAlign: 'right', color: 'yellow' }} sm={6}>{label}:</Col>
        <Col sm={6} xs={6}>{children || 'NA'}</Col>
    </Row >;
};

export function mapStateToProps(state: any, ownProps: any) {
    const projectInstance = ProjectModel.get(ownProps.match.params.id);
    return {
        projectInstance,
    };
}

export const DetailsPage = connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl);
