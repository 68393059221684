import React, {Component} from "react";
import {BasePage} from "../../reusableComponents/BasePage";
import {Async} from "../../reusableComponents/Async";
import {ErrorPage} from "../../reusableComponents/ErrorPage";
import {Loader} from "../../reusableComponents/Loader";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {SubmissionLoader} from "../../reusableComponents/SubmissionLoader";
import {Form} from "../../reusableComponents/FormComponents/Form";
import {Title} from "../../reusableComponents/Title";
import {Col, Row} from "react-bootstrap";
import {Label} from "../../reusableComponents/FormComponents/Label";
import {RRFInput} from "../../reusableComponents/FormComponents/RRFInput";
import './preExcavationChecklist.scss'
import Select from "react-select";
import * as displayText from "./language.json";
import {Button} from "../../reusableComponents/FormComponents/Button";
import {IForms, IHistory} from "../../../interfaces";
import {IPreExcavationChecklistV2ModelProps, PreExcavationChecklistV2Model } from "../../../model/PreExcavationChecklistModel";
import PropTypes from "prop-types";
import { isFormEmpty, showAlert} from "../../../utils/generalUtils"; 
import {EMPTY_FORM_MESSAGE} from "../../../constants/general";
import {Alert} from "../../Alert";
import {AlertModal} from "../../reusableComponents/ModalComponents/AlertModal";
import {getFormData, submitTexas11Forms} from "../../../services/texas811Service";
import {format} from "date-fns";
import {AttachementInput} from "../../reusableComponents/FormComponents/AttachementInput";
import {PlantLocationMap} from "../../reusableComponents/GoogleMapsWithDrawing/GoogleMapsWithDrawing";
import { VoiceRecognition } from '../../reusableComponents/VoiceRecognition/VoiceRecognition';
 
interface IPreExcavationCheckListProps {
    editId: string;
    history?: IHistory;
    forms: IForms;
    editInstance: PreExcavationChecklistV2Model;
}

interface IPreExcavationCheckListState {
    displayText: Record<any, any>
    language: string;
    isLocationAddressChanged: boolean;
}   

export class PreExcavationCheckListFormImpl extends Component<IPreExcavationCheckListProps, IPreExcavationCheckListState> { 
    shapesList: Array<any> | undefined = [];
    outfallsList: Array<any> | undefined = [];
    coordinatesPointerList: Array<any> | undefined = [];
    coords: { lat: number, lang: number } | null = null;

    constructor(props: IPreExcavationCheckListProps) {
        super(props);
        this.state = {
            displayText: !props.editInstance ? displayText.eng : props.editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.editInstance ? 'eng' : props.editInstance.props.language === 'spa' ? 'spa' : 'eng', isLocationAddressChanged: false,
        }
    }

    getChildContext() {
        return {formModel: 'forms.preExcavationChecklistV2'};
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    applyDefaultValues = (props: any) => { 
        const {editInstance}: any = this.props;
        this.shapesList = this.props.editInstance.props.shapesList;
        this.outfallsList = this.props.editInstance.props.outfallsList;
        this.coordinatesPointerList = this.props.editInstance.props.coordinatesPointerList;
        this.coords = this.props.editInstance.props.coords;
        this.setState({
            displayText: !editInstance ? displayText.eng : editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !editInstance ? 'eng' : editInstance.props.language === 'spa' ? 'spa' : 'eng'
        });
    }

    promise = async () => {
        if (this.props.editId && !this.props.editInstance) {
            await getFormData('preExcavationChecklistV2', this.props.editId).then((res) => {
                this.applyDefaultValues(this.props)
            })
        } else if (this.props.editInstance) {
            setTimeout(() => {
                this.applyDefaultValues(this.props)
            }, 100);
        }
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    } 

    onFormSubmit = async (values: IPreExcavationChecklistV2ModelProps) => {
        let validateValues = { excavationDate: values.excavationDate, jobNo: values.jobNo, streetAddress: values.streetAddress,city: values.city,state: values.state, crewForeman: values.crewForeman, oneCallTicketNo: values.oneCallTicketNo, contactNo: values.contactNo, updatTicket: values.updatTicket, speicalInstructions: values.speicalInstructions, digupTicketNo: values.digupTicketNo, facilityOwner: values.facilityOwner}
        if (isFormEmpty(validateValues)) { 
            showAlert(EMPTY_FORM_MESSAGE, 'pre-excavation-checklist-form-v2', 'danger');
            return
        } 
        const mapObjs = {
            'coords': JSON.stringify(this.coords),
            'shapesList': JSON.stringify(this.shapesList),
            'outfallsList': JSON.stringify(this.outfallsList),
            'coordinatesPointerList': JSON.stringify(this.coordinatesPointerList),
        }

        await submitTexas11Forms(JSON.parse(JSON.stringify(values)), 'preExcavationChecklistV2', this.state.language, this.props.history, this.props.forms, this.props.editId, mapObjs);
    }
    setMapCoordsData = (coords) => {
        this.coords = coords;
    }

    updateOutfallsList = (outfallsList) => {
        this.outfallsList = outfallsList;
    }

    updateShapesList = (shapesList) => {
        this.shapesList = shapesList;
    }
    updateCoordinatesPointerList = (coordinatesPointerList) => {
        this.coordinatesPointerList = coordinatesPointerList;
    }

    updateAddressChangeStatus = (status) => {
        if (status !== this.state.isLocationAddressChanged)
            this.setState({
                isLocationAddressChanged: status
            });
    }

    renderContent = () => {
        const {displayText, language} = this.state;
        const {editInstance, forms, history} = this.props;
        let answer: any = {};
        if (editInstance && editInstance.props && editInstance.props.answers) {
            answer = editInstance.props.answers;
        }
        return <Form loader={<SubmissionLoader/>} model="forms.preExcavationChecklistV2" className="preExcavationChecklist" onSubmit={this.onFormSubmit}>
            <AlertModal id="preExcavationChecklistV2-form-modal"/>
            <Alert className="danger-alert" id="preExcavationChecklistV2-form-failed"/>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle2}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <fieldset>
                <Row className="show-grid">
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="excavationDate">{displayText.date}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="excavationDate"
                                type="date"
                                placeholder="HH:MM"
                                model=".excavationDate"
                                defaultValue={answer.excavationDate ? format(new Date(answer.excavationDate), 'yyyy-MM-dd') : ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="jobNo">{displayText.jobNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="jobNo"
                                type="text"
                                placeholder=""
                                model=".jobNo"
                                defaultValue={answer.jobNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="streetAddress">{displayText.streetAddress}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.streetAddress} model="forms.preExcavationChecklistV2.streetAddress" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="streetAddress"
                                type="text"
                                placeholder=""
                                model=".streetAddress"
                                onBlur={() => { this.updateAddressChangeStatus(true) }}
                                defaultValue={answer.streetAddress || null}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="city">{displayText.city}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.city} model="forms.preExcavationChecklistV2.city" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="city"
                                type="text"
                                placeholder=""
                                model=".city"
                                onBlur={() => { this.updateAddressChangeStatus(true) }}
                                defaultValue={answer.city || null}
                            />
                        </Col>
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="state">{displayText.state}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.state} model="forms.preExcavationChecklistV2.state" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="state"
                                type="text"
                                placeholder=""
                                model=".state"
                                onBlur={() => { this.updateAddressChangeStatus(true) }}
                                defaultValue={answer.state || null}
                            />
                        </Col>
                    </Col>

                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="crewForeman">{displayText.foreman}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.crewForeman} model="forms.preExcavationChecklistV2.crewForeman" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="crewForeman"
                                type="text"
                                placeholder=""
                                model=".crewForeman"
                                defaultValue={answer.crewForeman || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="oneCallTicketNo">{displayText.oneCallTicketNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="oneCallTicketNo"
                                type="text"
                                placeholder=""
                                model=".oneCallTicketNo"
                                defaultValue={answer.oneCallTicketNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="contactNo">{displayText.contactNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="contactNo"
                                type="text"
                                placeholder=""
                                model=".contactNo"
                                defaultValue={answer.contactNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}> 
                        <Label required sm={12} className="text-left">{displayText.updateTicketNo} </Label>
                        <Label sm={12} className="radioInput reporterLocationContent">
                            <RRFInput
                                id="updateTicketYes"
                                type="radio"
                                model=".updatTicket"
                                radioValue={'true'}
                                radioName="updatTicket" 
                                defaultValue={answer.updatTicket || ''}
                            /> <label htmlFor="updateTicketYes">{displayText.yes}</label>
                            <RRFInput
                                id="updateTicketNo"
                                type="radio"
                                model=".updatTicket"
                                radioValue={'false'}
                                radioName="updatTicket" 
                                defaultValue={answer.updatTicket || ''}
                            /> <label htmlFor="updateTicketNo">{displayText.no}</label>
                        </Label>
                    </Col>
                </Row>
                <Row className={'show-grid top-info'}>
                    <p>{displayText.doNotProceedLabel}</p> 
                </Row>
                <Row className={'show-grid'}>
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest1Part1}<a href="https://www.texas811.org" target="_blank" rel="noreferrer">www.texas811.org</a>{displayText.quest1Part2}</p>
                    <Col sm={12} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label">{displayText.quest1Ans1}</label> 
                            <label className="checkbox-label">{displayText.quest1Ans2}</label> 
                        </Col>
                    </Col> 
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest2}</p>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.quest2Hint}</label> 
                    </Col>
                    <Col sm={3} className="input">
                        {/* <label className="checkbox-label" htmlFor="isGasMarked">{displayText.gas}</label>
                        <RRFInput
                            id="isGasMarked"
                            type="checkbox"
                            model=".isGasMarked"
                            defaultValue={(answer.isGasMarked && answer.isGasMarked === '1') || false}
                        /> */}
                        <Label sm={12} htmlFor="contactNo">{displayText.gas}</Label> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="naturalGasContactNo"
                                type="text"
                                placeholder=""
                                model=".naturalGasContactNo"
                                defaultValue={answer.naturalGasContactNo || ''}
                            />
                        </Col> 
                    </Col>
                    <Col sm={3} className="input">
                        {/* <label className="checkbox-label" htmlFor="isPipelineMarked">{displayText.pipeline}</label>
                        <RRFInput
                            id="isPipelineMarked"
                            type="checkbox"
                            model=".isPipelineMarked"
                            defaultValue={(answer.isPipelineMarked && answer.isPipelineMarked === '1') || false}
                        /> */}
                        <Label sm={12} htmlFor="contactNo">{displayText.pipeline}</Label>  
                        <Col sm={12} className="input">
                            <RRFInput
                                id="pipelineContactNo"
                                type="text"
                                placeholder=""
                                model=".pipelineContactNo"
                                defaultValue={answer.pipelineContactNo || ''}
                            />
                        </Col> 
                    </Col>
                    <Col sm={3} className="input">
                        {/* <label className="checkbox-label" htmlFor="isElectricMarked">{displayText.electric}</label>
                        <RRFInput
                            id="isElectricMarked"
                            type="checkbox"
                            model=".isElectricMarked"
                            defaultValue={(answer.isElectricMarked && answer.isElectricMarked === '1') || false}
                        /> */}
                        <Label sm={12} htmlFor="contactNo">{displayText.electric}</Label> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="electricContactNo"
                                type="text"
                                placeholder=""
                                model=".electricContactNo"
                                defaultValue={answer.electricContactNo || ''}
                            />
                        </Col> 
                    </Col>
                    <Col sm={3} className="input">
                        {/* <label className="checkbox-label" htmlFor="isTelephoneMarked">{displayText.telephone}</label>
                        <RRFInput
                            id="isTelephoneMarked"
                            type="checkbox"
                            model=".isTelephoneMarked"
                            defaultValue={(answer.isTelephoneMarked && answer.isTelephoneMarked === '1') || false}
                        /> */}
                        <Label sm={12} htmlFor="contactNo">{displayText.telephone}</Label> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="telephoneContactNo"
                                type="text"
                                placeholder=""
                                model=".telephoneContactNo"
                                defaultValue={answer.telephoneContactNo || ''}
                            />
                        </Col> 
                    </Col>
                   
                    <Col sm={3} className="input">
                        {/* <label className="checkbox-label" htmlFor="isWaterMarked">{displayText.water}</label>
                        <RRFInput
                            id="isWaterMarked"
                            type="checkbox"
                            model=".isWaterMarked"
                            defaultValue={(answer.isWaterMarked && answer.isWaterMarked === '1') || false}
                        /> */}
                        <Label sm={12} htmlFor="contactNo">{displayText.water}</Label> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="waterContactNo"
                                type="text"
                                placeholder=""
                                model=".waterContactNo"
                                defaultValue={answer.waterContactNo || ''}
                            />
                        </Col> 
                    </Col>
                    <Col sm={3} className="input" style={{textAlign: 'center'}}>
                        {/* <label className="checkbox-label" htmlFor="isOtherUtilityMarked">{displayText.other}</label> */}
                        <Label sm={12} htmlFor="isOtherUtilityMarked">{displayText.other}</Label>
                        <RRFInput
                            id="isOtherUtilityMarked"
                            type="checkbox"
                            model=".isOtherUtilityMarked"
                            defaultValue={(answer.isOtherUtilityMarked && answer.isOtherUtilityMarked === '1') || false}
                        />
                    </Col>
                    { forms.preExcavationChecklistV2.isOtherUtilityMarked && <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="otherUtility">{displayText.specifyOtherUtility}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="otherUtility"
                                type="text"
                                placeholder=""
                                model=".otherUtility"
                                defaultValue={answer.otherUtility || ''}
                            />
                        </Col>
                    </Col> }
                    <Col sm={12} xs={12}>
                        <Label required sm={12} htmlFor="speicalInstructions">{displayText.speicalInstructions}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.speicalInstructions} model="forms.preExcavationChecklistV2.speicalInstructions" />  
                        <Col sm={12} className="input">
                            <RRFInput
                                id="speicalInstructions"
                                type="textarea"
                                placeholder=""
                                model=".speicalInstructions"
                                defaultValue={answer.speicalInstructions || ''}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest3}</p> 
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest3Ans1}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isScopeOfWork"
                            type="checkbox"
                            model=".isScopeOfWork"
                            defaultValue={(answer.isScopeOfWork && answer.isScopeOfWork === '1') || false}
                        />
                    </Col>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest3Ans2}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isWorkToBegin"
                            type="checkbox"
                            model=".isWorkToBegin"
                            defaultValue={(answer.isWorkToBegin && answer.isWorkToBegin === '1') || false}
                        />
                    </Col>
                </Row> 
                <Row key={3.2} className={'show-grid'}>
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest4}</p> 
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isGasUnmarked">{displayText.gasMarked }</label>
                        <RRFInput
                            id="isGasUnmarked"
                            type="checkbox"
                            model=".isGasUnmarked"
                            defaultValue={answer.isGasUnmarked && answer.isGasUnmarked === '1'}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label"
                                htmlFor="isElectricUnmarked">{displayText.electricMarked}</label>
                        <RRFInput
                            id="isElectricUnmarked"
                            type="checkbox"
                            model=".isElectricUnmarked"
                            defaultValue={answer.isElectricUnmarked && answer.isElectricUnmarked === '1'}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label"
                                htmlFor="isTelephoneUnMarked">{displayText.telephoneMarked}</label>
                        <RRFInput
                            id="isTelephoneUnMarked"
                            type="checkbox"
                            model=".isTelephoneUnMarked"
                            defaultValue={answer.isTelephoneUnMarked && answer.isTelephoneUnMarked === '1'}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isCableTVUnmarked">{displayText.cableTvMarked}</label>
                        <RRFInput
                            id="isCableTVUnmarked"
                            type="checkbox"
                            model=".isCableTVUnmarked"
                            defaultValue={answer.isCableTVUnmarked && answer.isCableTVUnmarked === '1'}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isWaterUnmarked">{displayText.waterMarked}</label>
                        <RRFInput
                            id="isWaterUnmarked"
                            type="checkbox"
                            model=".isWaterUnmarked"
                            defaultValue={answer.isWaterUnmarked && answer.isWaterUnmarked === '1'}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isSewerUnmarked">{displayText.sewerMarked}</label>
                        <RRFInput
                            id="isSewerUnmarked"
                            type="checkbox"
                            model=".isSewerUnmarked"
                            defaultValue={answer.isSewerUnmarked && answer.isSewerUnmarked === '1'}
                        />
                    </Col>
                    <Col sm={12} className="input" style={{marginTop: '15px', marginLeft: '20px'}}>
                        <label className="checkbox-label" >{displayText.quest4Hint1}</label>
                        <label className="checkbox-label" >{displayText.quest4Hint2}</label>
                    </Col>
                </Row> 
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest5}</p>
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest5Ans1}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isSitePhotographed"
                            type="checkbox"
                            model=".isSitePhotographed"
                            defaultValue={(answer.isSitePhotographed && answer.isSitePhotographed === '1') || false}
                        />
                    </Col> 
                    <AttachementInput
                        id={'excavationUploads'}
                        model={'.attachments'}
                        uploadedImages={editInstance && editInstance.props.attachmentUrls}
                        multiple={true}
                    />
                </Row>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest6}</p>
                 
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest6Ans1}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isExposedMarkedUtilities"
                            type="checkbox"
                            model=".isExposedMarkedUtilities"
                            defaultValue={(answer.isExposedMarkedUtilities && answer.isExposedMarkedUtilities === '1') || false}
                        />
                    </Col>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest6Ans2}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isMechanizedEquipmentUsed"
                            type="checkbox"
                            model=".isMechanizedEquipmentUsed"
                            defaultValue={(answer.isMechanizedEquipmentUsed && answer.isMechanizedEquipmentUsed === '1') || false}
                        />
                    </Col>
                </Row>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest7}</p>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest8}</p>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest9}</p>
                <Row className={'show-grid'}>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isGasDamaged">{displayText.gasRaw}</label>
                        <RRFInput
                            id="isGasDamaged"
                            type="checkbox"
                            model=".isGasDamaged"
                            defaultValue={(answer.isGasDamaged && answer.isGasDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isElectricDamaged">{displayText.electricRaw}</label>
                        <RRFInput
                            id="isElectricDamaged"
                            type="checkbox"
                            model=".isElectricDamaged"
                            defaultValue={(answer.isElectricDamaged && answer.isElectricDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isTelephoneDamaged">{displayText.telephoneRaw}</label>
                        <RRFInput
                            id="isTelephoneDamaged"
                            type="checkbox"
                            model=".isTelephoneDamaged"
                            defaultValue={(answer.isTelephoneDamaged && answer.isTelephoneDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isCableTvDamaged">{displayText.cableTvRaw}</label>
                        <RRFInput
                            id="isCableTvDamaged"
                            type="checkbox"
                            model=".isCableTvDamaged"
                            defaultValue={(answer.isCableTvDamaged && answer.isCableTvDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isWaterDamaged">{displayText.waterRaw}</label>
                        <RRFInput
                            id="isWaterDamaged"
                            type="checkbox"
                            model=".isWaterDamaged"
                            defaultValue={(answer.isWaterDamaged && answer.isWaterDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isSewerDamaged">{displayText.sewerRaw}</label>
                        <RRFInput
                            id="isSewerDamaged"
                            type="checkbox"
                            model=".isSewerDamaged"
                            defaultValue={(answer.isSewerDamaged && answer.isSewerDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isUnknownPipeDamaged">{displayText.unknownPipeRaw}</label>
                        <RRFInput
                            id="isUnknownPipeDamaged"
                            type="checkbox"
                            model=".isUnknownPipeDamaged"
                            defaultValue={(answer.isUnknownPipeDamaged && answer.isUnknownPipeDamaged === '1') || false}
                        />
                    </Col>
                    <Col sm={3} className="input">
                        <label className="checkbox-label" htmlFor="isUnknownCableDamaged">{displayText.unknownCableRaw}</label>
                        <RRFInput
                            id="isUnknownCableDamaged"
                            type="checkbox"
                            model=".isUnknownCableDamaged"
                            defaultValue={(answer.isUnknownCableDamaged && answer.isUnknownCableDamaged === '1') || false}
                        />
                    </Col>
                </Row> 
                <Row className={'show-grid'}>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} htmlFor="digupTicketNo">{displayText.digupTicketNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="digupTicketNo"
                                type="text"
                                placeholder=""
                                model=".digupTicketNo"
                                defaultValue={answer.digupTicketNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} htmlFor="facilityOwner">{displayText.facilityOwner}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.preExcavationChecklistV2.facilityOwner} model="forms.preExcavationChecklistV2.facilityOwner" />  
                        <Col sm={12} className="input">
                            <RRFInput
                                id="facilityOwner"
                                type="text"
                                placeholder=""
                                model=".facilityOwner"
                                defaultValue={answer.facilityOwner || ''}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <PlantLocationMap
                        locationAddress={forms.preExcavationChecklistV2.streetAddress  + ', ' + forms.preExcavationChecklistV2.city + ', ' + forms.preExcavationChecklistV2.state}
                        locationCoords={this.coords} shapesList={this.shapesList}
                        outfallsList={this.outfallsList} coordinatesPointerList={(this.props.editInstance && this.props.editInstance.props.coordinatesPointerList) || []} 
                        type={'edit'}
                        isLocationAddressChanged={this.state.isLocationAddressChanged}
                        updateCoords={this.setMapCoordsData}
                        updateShapesList={this.updateShapesList}
                        updateOutfallsList={this.updateOutfallsList}
                        updateCoordinatesPointerList={this.updateCoordinatesPointerList}
                        updateAddressChangeStatus={this.updateAddressChangeStatus}
                    />
                </Row>
            </fieldset>
            <Row className="show-grid">
                <div className="form-button" style={{marginTop: '50px', textAlign: 'center'}}>
                    <Button style={{color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white',}}
                            redirectTo="goBack" type="button" onClick={() => history?.goBack()}>
                        <i className="fa fa-ban" aria-hidden="true"/>  &nbsp; Cancel
                    </Button>
                    <Button style={{color: '#FFFFFF', border: 'none', backgroundColor: '#26A65B'}}>
                        <i className={editInstance ? "fa fa-refresh" : "fa fa-plus"} aria-hidden="true"/>
                        &nbsp;
                        {editInstance ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Row>
            <Alert id="pre-excavation-checklist-form-v2" className="danger-alert"/>
        </Form>
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
                <Async
                    identifier="utilityDamageReport"
                    promise={this.promise}
                    error={<ErrorPage/>}
                    loader={<Loader/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }

}

function mapStateToProps(state: any, ownProps: any) {
    const editId = ownProps.match.params.id;
    return {
        editId,
        forms: state.forms,
        editInstance: PreExcavationChecklistV2Model.get(ownProps.match.params.id)
    }
}

export const PreExcavationCheckListForm = withRouter(connect<IPreExcavationCheckListProps, any, any>(mapStateToProps)(PreExcavationCheckListFormImpl));
