import { BaseModel } from './BaseModel';

export interface SafetyWalkthroughModel {
    id?: string;
    company?: string;
    formType?: string;
    userName?: string;
    date?: string;
    time?: string;
    userId?: string;
    uniqueId?: string;
    formId?: string;
    totalCloseCallCount?: number;
    totalCloseCallOpenCount?: number;
    projectName?: string; groupName?: string;
    checklistTitle?: string; serialNo?: string;
}
export class WalkthroughModel extends BaseModel<SafetyWalkthroughModel> {
    constructor(props: SafetyWalkthroughModel) {
        super(props, WalkthroughModel.resource);
    }
    static resource = 'safety-walkthrough';
}

export interface SafetyWalkthroughReportDetail {
    projectName?: string;
    groupName?: string;
    company?: string;
    formType?: string;
    userName?: string;
    date?: string;
    subAttributeList?: Array<any>;
    imageUrls?: string[];
    videoUrls?: string[];
    totalScore: string;
    overAllComment: string;
    dateOfIncident?: string;
    checklistTitle?: string;
    serialNo?: string;
}

export interface SafetyWalkthroughReportChartData {
    yearlyCount?: any;
    yearlySum?: any;
    monthlyCount?: any;
    monthlySum?: any;
    managerCheckListPercentage?: any;
    supervisorCheckListPercentage?: any;
    reportCountData?: any;
    reportSumData?: any;
    averageReportData?: any;
}
export interface SafetyWalkthroughCommentModel {
    name?: string;
    date?: string;
    comments?: string;
}
export interface SafetyWalkthroughCommentListModel {
    swManagerComment: SafetyWalkthroughCommentModel[];
    swSupervisorComment: SafetyWalkthroughCommentModel[];
    swCloseCallComment: SafetyWalkthroughCommentModel[];
}
