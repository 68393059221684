import { fromJS } from 'immutable';
import { SAVE_FILTERED_GROUP_LIST, SAVE_ALL_GROUPS } from '../constants/actions';
export function groupReducer(state: any = fromJS({}), action: { type: any; groupList: any[]; groupNames: any; }) {
    switch (action.type) {
        case SAVE_FILTERED_GROUP_LIST:
            const allGroups: any[] =  [];
            action.groupList.forEach(groupName => {
                if (allGroups.indexOf(groupName) < 0) {
                    allGroups.push(groupName);
                }
            });

            return state.set('groupNames', allGroups);
        case SAVE_ALL_GROUPS:
            return state.set('allGroups', action.groupNames);

        default:
            return state;
    }

}
