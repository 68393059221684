import { OrderedMap } from 'immutable';

import { REMOVE_INSTANCE, SAVE_FILTERED_INSTANCE, SAVE_INSTANCE, UPDATE_INSTANCE, DELETE_INSTANCE } from '../constants/actions';

export function modelReducer(state = OrderedMap({}), action: { type: any; instance: any; key: string; }) {

    switch (action.type) {
        case SAVE_INSTANCE:
            let instance = action.instance;
            return state.set(action.key, instance);
        case SAVE_FILTERED_INSTANCE:
            let filteredInstance = action.instance;
            return state.set(action.key, filteredInstance);
        case REMOVE_INSTANCE:
            return state.delete(action.key);
        case DELETE_INSTANCE:
            return state.clear();
        case UPDATE_INSTANCE: 
            return state.update(action.key, (ins) => ins);
        default:
            return state;
    }
}
