import {IForms, IHistory} from "../interfaces";
import {UserModel} from "../model/UserModel";
import {format} from "date-fns";
import {constructAnswers, getSignatureData, removeByValue, showAlert, getPermanentDeviation, getTemporaryDeviationStatus } from "../utils/generalUtils"; 
import {FORM_SUBMISSION_ERROR} from "../constants/general";
import {getCustom, post} from "../utils/HTTP"; 
import {MocPermanentModel, MocTemporaryModel} from '../model/MOCModel'; 
import { saveListCount } from '../actions/miscellaneousActions';

function formattingAnswers(answers: Array<{ quesType: string, quesNum: number, answer: any }>) {
    const formattedAnswer: any = {}; 
    answers.forEach((answer: { quesType: string, quesNum: number, answer: any }) => {
        formattedAnswer[answer.quesType] = answer.answer
    });
    return formattedAnswer;
}

function marshallFormData(data: any) {
    const formattedData: any = {
        id: data.key,
        answers: formattingAnswers(data.answers)
    } 
    delete data['answers'];
    Object.assign(formattedData, data);
    return formattedData
}

export async function submitMOCForms(formValues: any, formName: string, history: IHistory | undefined, forms: IForms, editId?: string, instance?: any) {
    const company = UserModel.getCompanyName();
    const submittedDateTime = new Date()
    let deletedFiles: Array<any> = [];
    let files: Array<any> = [];
    let mailTo = formValues['mailTo'];
    if (formName === 'mocPermanentDeviation') {
        deletedFiles = forms.MOCPermanentDeviationForm.deletedFiles || []
        files = forms.MOCPermanentDeviationForm.attachments;
        formValues = removeByValue(formValues, ['attachments', 'id', 'editId', 'deletedFiles', 'originatorSignature', 'safetySupervisorSignature', 'productionSupervisorSignature', 'safetyManagerSignature', 'operationsManagerSignature', 'productionManagerSignature', 'mailTo' ]);
    }  else if(formName === 'mocTemporaryDeviation') {
        deletedFiles = forms.MOCTemporaryDeviationForm.deletedFiles || []
        files = forms.MOCTemporaryDeviationForm.attachments;
        formValues = removeByValue(formValues, ['attachments', 'id', 'editId', 'deletedFiles', 'originatorSignature', 'safetyManagerSignature', 'operationsManagerSignature', 'productionManagerSignature', 'generalManagerSignature', 'regionalVicePresidentSignature', 'globalOperationsManagerSignature', 'mailTo' ]);
    }
    let answers = constructAnswers(formValues);
    let signatureData: any = []; 
    let signatures: any = [];
    if (formName === 'mocPermanentDeviation') {
        signatures = ['originatorSignature', 'safetySupervisorSignature', 'productionSupervisorSignature', 'safetyManagerSignature', 'operationsManagerSignature', 'productionManagerSignature' ];
        signatures.forEach((item:string) => {
            if (forms.MOCPermanentDeviationForm[item]) {
                let signature = getSignatureData(forms.MOCPermanentDeviationForm[item], `${item}URL`);
                signatureData.push(signature); 
            } else if(instance && instance[`${item}URL`]) {
                answers.push({'answer': instance[`${item}URL`], 'quesNum': 0, 'quesType': `${item}URL`})
            }
        });
        const {completed, approvalStatus} = getPermanentDeviation(formValues);
        answers.push({'answer': completed.toString(), 'quesNum': 0, 'quesType': `completedMOCStage`});
        answers.push({'answer': approvalStatus.toString(), 'quesNum': 0, 'quesType': `MOCApprovalStatus`});

    } else if (formName === 'mocTemporaryDeviation') {
        signatures = ['originatorSignature', 'safetyManagerSignature', 'operationsManagerSignature', 'productionManagerSignature', 'generalManagerSignature', 'regionalVicePresidentSignature', 'globalOperationsManagerSignature'];
        signatures.forEach((item:string) => {
            if (forms.MOCTemporaryDeviationForm[item]) {
                let signature = getSignatureData(forms.MOCTemporaryDeviationForm[item], `${item}URL`);
                signatureData.push(signature);
            } else if(instance && instance[`${item}URL`]) {
                answers.push({'answer': instance[`${item}URL`], 'quesNum': 0, 'quesType': `${item}URL`})
            }
        });
        const {total, completed, approvalStatus} = getTemporaryDeviationStatus(formValues);
        answers.push({'answer': completed.toString(), 'quesNum': 0, 'quesType': `completedMOCStage`});
        answers.push({'answer': total.toString(), 'quesNum': 0, 'quesType': `totalMOCStage`});
        answers.push({'answer': approvalStatus.toString(), 'quesNum': 0, 'quesType': `MOCApprovalStatus`});
    } 
    const dataToSend = {
        groupName: formValues.groupName,
        userId: UserModel.getUserUID(),
        formId: formName,
        date: format(submittedDateTime, 'yyyy-MM-dd'),
        time: format(submittedDateTime, 'HH:mm'),
        projectName: formValues.projectName,
        associatedFormId: '',
        followUp: "0",
        editId: editId, 
        deletedFiles,
        deletedImages: [],
        deletedVideos: [],
        company,
        answers, 
        mailTo, changeRequestDetails: formValues.changeRequestDetails, 
        attachments: files || [],
        signatureData: signatureData || [],
    };   
    try {
        dataToSend.editId = editId || '';
        const response = editId ? await post('/EditForm', dataToSend) : await post('/SubmitForm', dataToSend);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, `'${formName}-form-failed`, 'danger');
            return;
        }
        showAlert(response.data.message, `${formName}-form-modal`, 'success', async () => {
            history?.push(editId ? `/home` : `/home`);
        });
        return response;
    } catch (e) {
        showAlert(FORM_SUBMISSION_ERROR, `${formName}-form-failed`, 'danger');
        throw e;
    } 
}

export async function getAllMOCForms( urlData: any, forms: IForms, formType: string) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType, pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        MocPermanentModel.deleteAll(); 
        MocTemporaryModel.deleteAll();
        if (response.data.status === 'valid' && response.data.data.length) {
            let formData: any = {};
            if (formType === 'mocPermanentDeviation') {
                response.data.data.forEach((data: any) => {
                    formData = marshallFormData(data)
                    new MocPermanentModel(formData).$save();
                });
            } else if (formType === 'mocTemporaryDeviation') {
                response.data.data.forEach((data: any) => {
                    formData = marshallFormData(data)
                    new MocTemporaryModel(formData).$save();
                });
            } 
            saveListCount(response.data.TotalPages);
        }
    } catch (e) {
        throw e;
    }
}

export async function getFormData(formType: string, formId: string) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`GetForms/${formType}/${company}/${formId}`);
        MocPermanentModel.deleteAll();
        MocTemporaryModel.deleteAll();
        if (response.data.status === 'valid' && response.data.data.length) {
            const formData = marshallFormData(response.data.data[0]);
            if (formType === 'mocPermanentDeviation') {
                new MocPermanentModel(formData).$save();
            } else {
                new MocTemporaryModel(formData).$save();
            } 
        }
    } catch (e) {
        throw e
    }  
}
