import { IChartValue, IHistory } from '../../interfaces';
import * as React from 'react';
import { Card } from '../reusableComponents/Card';
import { Row, Col } from 'react-bootstrap';
import { IForms } from '../../interfaces';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends';
import { Title } from '../reusableComponents/Title';
import { Cone } from '../reusableComponents/ChartComponents/Cone';
import { Async } from '../reusableComponents/Async';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader'; 
import { UserModel } from '../../model/UserModel';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import followUpIcon from '../../images/followUpIcon.png';
/* import { printLogos } from '../../utils/generalUtils'; */
import { PrintLogos } from '../reusableComponents/PrintLogos'; 
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { getIncidentInvestigativeSummaryData } from '../../services/incidentReportService';

export interface IIncidentInvestigativeSummaryDataProps {
    locationChartData: IChartValue[] | undefined;
    bodyPartChartData: IChartValue[] | undefined;
    isAnimationActive: boolean;
    getRef: (ref: any) => void;
    id: string;
    potentialSeverity: any;
    actualSeverity: any;
    history: IHistory;
    followUpsCount: number;
    forms?: IForms;
}

export class IncidentInvestigativeSummaryData extends React.PureComponent
    <IIncidentInvestigativeSummaryDataProps, {}> {
    constructor(props: IIncidentInvestigativeSummaryDataProps | Readonly<IIncidentInvestigativeSummaryDataProps>) {
        super(props);
    }

    ref: any;

    componentWillMount() {
        setSuccess('ReportsPage');
    }

    applyCommonFilter = async () => {
        setLoading('ReportsPage');  
        await getIncidentInvestigativeSummaryData(this.props.forms);
        setSuccess('ReportsPage');
    }
    injuryLocationChart = () => {
        const { locationChartData, isAnimationActive } = this.props;
        const legendsName = ['SPI 1029', 'SPI 1033', 'BNSF', '7 Eleven', 'Other'];
        if (UserModel.getCompanyName() === 'Demo-Exxon') {
            return <div></div>;
        }
        return (<Col sm={6} xs={12} style={{ padding: '20px' }}>
            <div id="pie-chart"
                style={{ height: '100%', width: '100%', margin: '20px auto', backgroundColor: 'white' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Location Of Injury
                    </h4>
                    <Row className="show-grid ">
                        <Col sm={7} className="pie circle" style={{ height: '253px' }}>
                            <PieChart
                                emptyDataMsg="No Locations Found"
                                isAnimationActive={isAnimationActive}
                                data={locationChartData}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1}
                            />
                        </Col>
                        <PieChartLegends
                            legendNames={legendsName}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                        />
                    </Row>
                </Card>
            </div>
        </Col>
        );
    }

    renderActualSeverity = () => {
        const { actualSeverity } = this.props;
        const colorArray = ['f35454', 'ff7979', 'ffeea8', 'd1e88d',
            '75c590', '57b7e0', '3596ff'];
        return <div id="cone" style={{ height: '400px', width: '100%', margin: '20px auto' }}>
            <Card width="100%" height="400px" className="cone-card">
                <h4
                    style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                >
                    Actual Severity
            </h4>
                <Cone
                    layerData={actualSeverity}
                    colorArray={colorArray}
                />
            </Card>
        </div>;
    }

    renderPotentialSeverity = () => {
        const { potentialSeverity } = this.props;
        const colorArray = ['f35454', 'ff7979', 'ffeea8', 'd1e88d',
            '75c590', '57b7e0', '3596ff'];
        return <div id="cone" style={{ height: '400px', width: '100%', margin: '20px auto' }}>
            <Card width="100%" height="400px" className="cone-card">
                <h4
                    style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                >
                    Potential Severity
            </h4>
                <Cone
                    layerData={potentialSeverity}
                    colorArray={colorArray}
                />
            </Card>
        </div>;
    }

    renderFollowUpTile = () => {
        return <Row className="show-grid ">
            <Col
                sm={12}
                xs={12}
                style={{ margin: 'auto' }}
                onClick={() => this.props.history.push('/incident-investigative-reports/follow-up')}
            >
                <Card className="oi-report-card clickable-report-card">
                    <img src={followUpIcon} alt="SafeConnect" />
                    <h4 style={{ margin: '10px 0px 0px 0px' }}>FollowUp <br /> Actions</h4>
                    <p style={{ paddingTop: '0px' }} >{this.props.followUpsCount}</p>
                </Card>
            </Col>
        </Row >;
    }

    bodyPartChart = () => {
        const { bodyPartChartData, isAnimationActive } = this.props;
        const legendsName = ['Hand', 'Head', 'Limb', 'Foot', 'Chest', 'Other'];
        return (
            <div id="pie-chart"
                style={{ height: '100%', width: '100%', margin: 'auto', backgroundColor: 'white' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Injured Body Part
                    </h4>
                    <Row className="show-grid ">
                        <Col sm={7} className="pie circle" style={{ height: '253px' }}>
                            <PieChart
                                emptyDataMsg="No Injured Body Parts Found"
                                isAnimationActive={isAnimationActive}
                                data={bodyPartChartData}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE ', '96C597', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E'].reverse()}
                                paddingAngle={1}
                            />
                        </Col>

                        <PieChartLegends
                            legendNames={legendsName}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE ', '96C597', 'F2BD91', 'C0F5FA',
                                'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E'].reverse()}
                        />
                    </Row>
                </Card>
            </div>
        );
    }

    renderContent = (isLoading: boolean) => {
        return <div
            className="summary-reports" id={this.props.id}
            ref={value => { this.props.getRef ? this.props.getRef(value) : this.ref = value; }}
        >
            {isLoading ? <SubmissionLoader /> : ''} 
            <PrintLogos/>
            <Title text="Incident Investigative Report Summary" /> 
            {this.renderFollowUpTile()} 
            <Row>
                <CommonFilter onApply={this.applyCommonFilter} forms={this.props.forms}/>
                <Col sm={6} xs={12} style={{ padding: '20px' }}>
                    {this.renderActualSeverity()}
                </Col>
                <Col sm={6} xs={12} style={{ padding: '20px' }}>
                    {this.renderPotentialSeverity()}
                </Col>
            </Row>
            <Row style={centerBodyPartChart} >
                {/* {this.injuryLocationChart()} */}
                <Col className="report-chart" sm={12} xs={12} style={{ padding: '20px' }}>
                    {this.bodyPartChart()}
                </Col>
            </Row>
        </div >;
    }

    render() {
        return <Async
            identifier="ReportsPage" 
            error={<div></div>}
            loader={this.renderContent(true)}
            content={this.renderContent(false)}
        />;
    }
}

const centerBodyPartChart: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center'
};
