import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
    CartesianGrid,
    Line,
    LineChart as RLineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis, Legend
} from 'recharts';

export interface ILineChartProps {
    data: Object[];
    legends: string[];
    isAnimationActive?: boolean;
    strokeColors: string[];
    dataKey: string[];
    refName?: string;
}

export class LineChart extends React.PureComponent<ILineChartProps, {}> {
    constructor(props: ILineChartProps | Readonly<ILineChartProps>) {
        super(props);
    }

    renderLegends = () => {
        const { legends, strokeColors } = this.props;
        return (
            <Row className="show-grid ">
                <Col sm={12} className="line-chart-legends">
                    {legends.map(((legendText, index) => {
                        return <div key={index} className="actual-legend">
                            <p
                                className="actual-legend-box"
                                style={{ backgroundColor: `#${strokeColors[index]}` }}
                            ></p>
                            <span>{legendText}</span>
                        </div>;
                    }))}
                </Col>
            </Row>
        );
    }

    renderLines = () => {
        const { dataKey, strokeColors } = this.props; 
        return dataKey.map((key, index) => {
            return <Line type="monotone" key={index} dataKey={key} stroke={`#${strokeColors[index]}`} />;
        });
    }   

    render() {
        const { data, refName } = this.props;
        return (
            <div style={{ height: refName==='customChecklist'?'100%':'85%' }}>
                <ResponsiveContainer>
                    <RLineChart
                        /* isAnimationActive={isAnimationActive} */
                        data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <XAxis dataKey="name" allowDecimals={false} />
                        <YAxis allowDecimals={false}/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {refName==='customChecklist' && <Legend verticalAlign="bottom" height={36}/>}
                        {this.renderLines()}
                    </RLineChart>
                </ResponsiveContainer>
                {refName!=='customChecklist' && this.renderLegends()}
            </div >
        );
    }
}
