import { fromJS } from 'immutable';
import { LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS, SAVE_COMPANY_NAMES } from '../constants/actions';

export function loginReducer(state: any = fromJS({}), action: { type: any; companyNames: any; text: any; userInfo: { userId: any; firstName: any; userUID: any; company: any; }; }) {
    switch (action.type) {
        case SAVE_COMPANY_NAMES:
            return state.set('companyNames', action.companyNames);
        case LOGIN_ERROR:
            return state.set('error', action.text);
        case LOGIN_SUCCESS:
            return state.merge({
                'userId': action.userInfo.userId, 'firstName': action.userInfo.firstName,
                'userUID': action.userInfo.userUID, 'company': action.userInfo.company,
                'currentUserInfo': action.userInfo, 'isLoggedIn': true
            });
        case LOGOUT:
            return state.delete('userId').delete('firstName').delete('company')
                .delete('currentUserInfo').set('isLoggedIn', false);
        default:
            return state;
    }
}
