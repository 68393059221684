export const DATA_FETCH_ERROR = 'Cannot fetch data please try again';
export const SAVE_INSTANCE = 'SAVE_INSTANCE';
export const REMOVE_INSTANCE = 'REMOVE_INSTANCE';
export const DELETE_INSTANCE = 'DELETE_INSTANCE';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const PUSH_ALERT = 'PUSH_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GET_TEAMS = 'GET_TEAMS';
export const SET_TIMEOUT_ID = 'SET_TIMEOUT_ID';
export const UPDATE_INSTANCE = 'UPDATE_INSTANCE';
export const SAVE_PROJECT_LIST = 'SAVE_PROJECT_LIST';
export const SAVE_FILTERED_GROUP_LIST = 'SAVE_FILTERED_GROUP_LIST';
export const SAVE_ALL_GROUPS = 'SAVE_ALL_GROUPS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SAVE_OI_FORM_SUMMARY = 'SAVE_OI_FORM_SUMMARY';
export const SAVE_INCIDENT_NOTIFICATION_SUMMARY = 'SAVE_INCIDENT_NOTIFICATION_SUMMARY';
export const SHOW_PRINT_PREVIEW_MODAL = 'SHOW_PRINT_PREVIEW_MODAL';
export const HIDE_PRINT_PREVIEW_MODAL = 'HIDE_PRINT_PREVIEW_MODAL';
export const SHOW_IMAGE = 'SHOW_IMAGE';
export const HIDE_IMAGE = 'HIDE_IMAGE';
export const IS_IMAGE_LOADING = 'IS_IMAGE_LOADING';
export const SET_FIELD_EMPTY = 'SET_FIELD_EMPTY';
export const RESET_FIELD_EMPTY = 'RESET_FIELD_EMPTY';
export const SAVE_GOLDEN_RULES = 'SAVE_GOLDEN_RULES';
export const SHOW_GOLDEN_RULES_MODAL = 'SHOW_GOLDEN_RULES_MODAL';
export const HIDE_GOLDEN_RULES_MODAL = 'HIDE_GOLDEN_RULES_MODAL';
export const SHOW_EDIT_RULES_FORM = 'SHOW_EDIT_RULES_FORM';
export const HIDE_EDIT_RULES_FORM = 'HIDE_EDIT_RULES_FORM';
export const DELETE_CONCERN_TYPE = 'DELETE_CONCERN_TYPE';
export const SHOW_DELETE_CONCERN_MODAL = 'SHOW_DELETE_CONCERN_MODAL';
export const HIDE_DELETE_CONCERN_MODAL = 'HIDE_DELETE_CONCERN_MODAL';
export const SAVE_CONCERN_TYPES = 'SAVE_CONCERN_TYPES';
export const SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';
export const SAVE_INCIDENT_INVESTIGATIVE_SUMMARY = 'SAVE_INCIDENT_INVESTIGATIVE_SUMMARY';
export const SAVE_COMPANY_NAMES = 'SAVE_COMPANY_NAMES';
export const SAVE_ACTIVITIES_MENU_ITEMS = 'SAVE_ACTIVITIES_MENU_ITEMS';
export const SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL = 'SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL';
export const HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL = 'HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL';
export const SAVE_CUSTOM_NOTIFICATION = 'SAVE_CUSTOM_NOTIFICATION';
export const SHOW_SUB_DROPDOWN_MENU = 'SHOW_SUB_DROPDOWN_MENU';
export const HIDE_SUB_DROPDOWN_MENU = 'HIDE_SUB_DROPDOWN_MENU';
export const SAVE_SUB_DROPDOWN_VALUE = 'SAVE_SUB_DROPDOWN_VALUE';
export const REMOVE_SUB_DROPDOWN_VALUE = 'REMOVE_SUB_DROPDOWN_VALUE';
export const SHOW_MAP_INPUT_MODAL = 'SHOW_MAP_INPUT_MODAL';
export const HIDE_MAP_INPUT_MODAL = 'HIDE_MAP_INPUT_MODAL';
export const SAVE_FILTERED_INSTANCE = 'SAVE_FILTERED_INSTANCE';
export const SAVE_FILTER_DATA = 'SAVE_FILTER_DATA';
export const SHOW_FOLLOW_UP_FORM_MODAL = 'SHOW_FOLLOW_UP_FORM_MODAL';
export const HIDE_FOLLOW_UP_FORM_MODAL = 'HIDE_FOLLOW_UP_FORM_MODAL';
export const SAVE_WEATHER_DETAILS = 'SAVE_WEATHER_DETAILS';
export const SHOW_WEATHER_MODAL = 'SHOW_WEATHER_MODAL';
export const HIDE_WEATHER_MODAL = 'HIDE_WEATHER_MODAL';
export const SAVE_OFFICE_SAFETY_SUMMARY = 'SAVE_OFFICE_SAFETY_SUMMARY';
export const GET_OFFICE_SAFETY_COMMENT = 'GET_OFFICE_SAFETY_COMMENT';
export const SAVE_REFERENCE_ITEMS = 'SAVE_REFERENCE_ITEMS';
export const SAVE_OI_FORM_COUNT = 'SAVE_OI_FORM_COUNT';
export const SAVE_OFFICE_SAFETY_FORM_COUNT = 'SAVE_OFFICE_SAFETY_FORM_COUNT';
export const SAVE_LIST_COUNT = 'SAVE_LIST_COUNT';

export const SHOW_MICROPHONE_MODAL = 'SHOW_MICROPHONE_MODAL';
export const HIDE_MICROPHONE_MODAL = 'HIDE_MICROPHONE_MODAL';

/* Attribute */
export const SAVE_ATTRIBUTE_DATA = 'SAVE_ATTRIBUTE_DATA';
export const DELETE_ATTRIBUTE_DATA = 'DELETE_ATTRIBUTE_DATA';
export const INITALIZE_ATTRIBUTE_DATA = 'INITALIZE_ATTRIBUTE_DATA';
export const EDIT_ATTRIBUTE_DATA = 'EDIT_ATTRIBUTE_DATA';
export const NAVIGATE_ADD_ATTRIBUTE = 'NAVIGATE_ADD_ATTRIBUTE';
export const EMPTY_ATTRIBUTE_LIST = 'EMPTY_ATTRIBUTE_LIST'; 

/* CheckList Report */
export const GET_CHECKLIST_REPORT_DATA = 'GET_CHECKLIST_REPORT_DATA';
export const GET_MODALLIST_REPORT_DATA = 'GET_MODALLIST_REPORT_DATA';
export const GET_CHECKLIST_REPORT_DETAILS = 'GET_CHECKLIST_REPORT_DETAILS';
export const GET_CHECKLIST_REPORTCHART_DATA = 'GET_CHECKLIST_REPORTCHART_DATA'; 

/* Safety Comment */
export const GET_SAFETY_COMMENT_LIST = 'GET_SAFETY_COMMENT_LIST';

export const SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL = 'SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL';
export const HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL = 'HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL';
export const SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL = 'SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL';
export const HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL = 'HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL';

/* Pandemic */ 
export const SAVE_PANDEMIC_SUMMARY = 'SAVE_PANDEMIC_SUMMARY';
export const SAVE_PANDEMIC_SHORT_SUMMARY = 'SAVE_PANDEMIC_SHORT_SUMMARY';
export const SHOW_COVID19_ASSESSMENT_MODAL = 'SHOW_COVID19_ASSESSMENT_MODAL';
export const HIDE_COVID19_ASSESSMENT_MODAL = 'HIDE_COVID19_ASSESSMENT_MODAL';

/* Foir Confirmation  */
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL';

export const SAVE_HOME_COUNTS = 'SAVE_HOME_COUNTS';
export const SAVE_RISK_ASSESSMENT_SUMMARY = 'SAVE_RISK_ASSESSMENT_SUMMARY';

export const SHOW_AUDIT_INPUT_MODAL = 'SHOW_AUDIT_INPUT_MODAL';
export const HIDE_AUDIT_INPUT_MODAL = 'HIDE_AUDIT_INPUT_MODAL'; 

export const SHOW_CHECKLIST_MODAL = 'SHOW_CHECKLIST_MODAL';
export const HIDE_CHECKLIST_MODAL = 'HIDE_CHECKLIST_MODAL';

export const SAVE_HP_FORM_SUMMARY = 'SAVE_HP_FORM_SUMMARY';
export const SAVE_AXIOS_PREVIOUS_TOKEN = 'SAVE_AXIOS_PREVIOUS_TOKEN';