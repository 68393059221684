import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from './FormComponents/Form'; 
import { SubmissionLoader } from './SubmissionLoader';
import { Row, Col, Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Label } from './FormComponents/Label';
import { RRFInput } from './FormComponents/RRFInput';
import { connect } from 'react-redux';
import { hideCreateCustomNotificationModal } from '../../actions/modalActions';
import { Button } from './FormComponents/Button';
import { showAlert, isFormEmpty } from '../../utils/generalUtils';
import { ICustomNotification, IForms } from '../../interfaces';
import { createCustomNotification } from '../../services/notificationService';
import { Async } from './Async';
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { AlertModal } from './ModalComponents/AlertModal';
import { Alert } from '../Alert/index';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';   

export interface ICustomNotificationFormModalProps {
    showModal: boolean;
    userId: string;
    userUID: string;
    forms: IForms;
}
export interface ICustomNotificationFormModalState {
    uploadedFiles: File[];
    uploadedFileNames: string[];
    editFileNameIndex: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[];
}

export class CustomNotificationFormModalImpl extends
    React.Component<ICustomNotificationFormModalProps, ICustomNotificationFormModalState> {
    constructor(props: any) {
        super(props);
        this.state = { editFileNameIndex: 0, uploadedFiles: [], uploadedFileNames: [] };
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return null;
    }

    renameInputRef: any;

    modelName = 'forms.customNotificationForm';

    getChildContext() {
        return { formModel: this.modelName };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleFileUpload = ({ target: { files } }: any) => {
        const { uploadedFiles, uploadedFileNames } = this.state;
        let filesData: File[] = [];
        let fileNames: Array<any> = [];
        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                filesData.push(uploadedFiles[i]);
                fileNames.push(uploadedFileNames[i]);
            }
        }
        for (let i = 0; i < files.length; i++) {
            if (uploadedFileNames.indexOf(files[i].name) < 0) {
                filesData.push(files[i]);
                fileNames.push(files[i].name);
            }
        }
        this.setState({
            uploadedFiles: filesData,
            uploadedFileNames: fileNames
        });
    }

    removeItem = (index: number) => {
        const allFileNames = this.state.uploadedFileNames;
        const allFiles: File[] = [];
        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            if (i !== index) {
                allFiles.push(this.state.uploadedFiles[i]);
            }
        }
        allFileNames.splice(index, 1);
        this.setState({
            uploadedFileNames: allFileNames,
            uploadedFiles: allFiles
        });
    }

    setFileNames = () => {
        let { uploadedFileNames, editFileName, editFileNameIndex } = this.state;
        let newFileNames = uploadedFileNames;
        const fileExtension = editFileNameIndex && this.state.uploadedFiles[editFileNameIndex].name.split('.')[1];
        uploadedFileNames.forEach((fileName, index) => {
            if (index === editFileNameIndex && editFileName) {
                newFileNames.splice(editFileNameIndex, 1, `${editFileName}.${fileExtension}`);
                this.setState({
                    uploadedFileNames: newFileNames,
                    editFileNameIndex: -1
                });
            } else {
                this.setState({
                    editFileNameIndex: -1
                });
            }
        });
    }

    handleSubmit = ({title, message, audience, distributeDate}: ICustomNotification) => {
        const { state: { uploadedFiles, uploadedFileNames }, props: { userId, userUID, forms } } = this;
        if (isFormEmpty({title, message, audience})) {
            showAlert(EMPTY_FORM_MESSAGE, 'custom-notification-failed', 'danger');
            return;
        }
        /* if (uploadedFiles.length <= 0) {
            showAlert('PLease Select file to upload.', 'custom-notification-failed', 'danger');
            return;
        } */
        let customNotificationForm = {title, message, audience, distributeDate}
       /*  if (editFileNameIndex >= 0) {
            showAlert('Please save changes first.', 'custom-notification-failed', 'danger');
            return;
        } */
        return createCustomNotification(customNotificationForm, userId, userUID, uploadedFiles, uploadedFileNames, forms);
    }

    editFile = (index: number, editFileName: string) => {
        const fileName = editFileName.split('.')[0];
        this.setState({
            editFileNameIndex: index,
            editFileName: fileName
        });
        setTimeout(() => {
            if (this.renameInputRef) {
                this.renameInputRef.focus();
            }
        }, 500);
    }

    handleRenameCancel = () => {
        this.setState({
            editFileNameIndex: -1
        });
    }

    handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>, fileIndex: number) => {
        this.setState({
            editFileName: e.target.value
        });
    }

    renderRenameFileInput = (index: number, fileName: string) => {
        const fileExtension = this.state.uploadedFiles[index].name.split('.')[1];
        return <ListGroupItem key={index} style={listItemStyle}>
            <input
                key={index}
                className="form-control"
                style={renameInput}
                type="text"
                ref={(ref) => this.renameInputRef = ref}
                onChange={(e) => this.handleFileNameChange(e, index)}
                value={this.state.editFileName}
            />
            <span>.{fileExtension}</span>
            <i className="fa fa-check rename-file" onClick={this.setFileNames} aria-hidden="true"></i>
            <span
                style={{
                    zIndex: 1000, width: '21px', right: '3px'
                }}
                className="delete-file"
                onClick={this.handleRenameCancel}
            >
                &times;
                </span>
        </ListGroupItem>;
    }

    renderListItem = (index: number, fileName: string) => {
        return <ListGroupItem key={index} style={listItemStyle}>
            <span onClick={() => this.editFile(index, fileName)} className="file-name" >
                {fileName}
            </span>
            <span
                className="delete-file"
                onClick={() => this.removeItem(index)}
            >
                &times;
            </span>
        </ListGroupItem>;
    }

    renderForm = () => {
        return <Form loader={< SubmissionLoader />} model={this.modelName} onSubmit={this.handleSubmit} >
            <Alert style={{ height: 'auto' }} id="custom-notification-failed" className="danger-alert" />
            <Row className="show-grid" >
                <CustomLabel htmlFor="title">Notification Title:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="title"
                        type="text"
                        placeholder="Enter notification title..."
                        model=".title"
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel htmlFor="title">Audience:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="audience"
                        type="dropdown"
                        menuItems={[
                        {
                            label: 'All',
                            value: 'All'
                        },
                        {
                            label: 'L1',
                            value: 'L1'
                        }, 
                        {
                            label: 'L2',
                            value: 'L2'
                        },
                        {
                            label: 'L3',
                            value: 'L3'
                        },
                        {
                            label: 'L4',
                            value: 'L4'
                        },
                        {
                            label: 'L5',
                            value: 'L5'
                        }]}
                        placeholder="Select audience..."
                        model=".audience" 
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel htmlFor="title">Message:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="message"
                        type="textarea"
                        placeholder="Enter your message..."
                        model=".message"
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel htmlFor="title">Files:</CustomLabel>
                <div className="file-upload col-sm-8" style={{ display: 'inline-block' }} >
                    <AttachementInput
                        model=".attachments"
                        multiple={false}
                        id="upload-images" 
                        uploadedImages={[]}
                    />
                </div> 
            </Row>
            {/* <Row className="show-grid" >
                <CustomLabel htmlFor="title">Files:</CustomLabel>
                <div className="file-upload col-sm-8" style={{ display: 'inline-block' }} >
                    <input
                        id="files"
                        type="file"
                        multiple
                        style={fileUpload}
                        onChange={this.handleFileUpload}
                    />
                    <label
                        htmlFor="files"
                        style={{ padding: '2px 15px', marginLeft: '15px', }}
                        className="upload-image">
                        Select File(s)
                    </label>
                </div>
            </Row> */}
            <Row className="show-grid" >
                <Col sm={{span: 6, offset: 4}}  xs={12} className="input">
                    {this.state.uploadedFileNames.length > 0 ? <div style={{ border: '1px solid #ddd' }}>
                        <PerfectScrollbar>
                            <ListGroup style={this.state.uploadedFileNames.length > 0 ? listStyle : {}}>
                                {this.state.uploadedFileNames.map((fileName, index) => {
                                    return this.state.editFileNameIndex === index ?
                                        this.renderRenameFileInput(index, fileName)
                                        : this.renderListItem(index, fileName);
                                })}
                            </ListGroup>
                        </PerfectScrollbar>
                    </div> : ''}
                </Col>
            </Row>
            <div className="modal-buttons row">
                <Button style={{
                    border: 'none',
                    backgroundColor: '#26a65b'
                }}
                    type="submit"
                >
                    Send
                    </Button>
                <Button style={{
                    color: '#5479AF',
                    border: '1px solid #5479AF',
                    backgroundColor: 'white'
                }}
                    onClick={() => hideCreateCustomNotificationModal()}
                    type="reset"
                >
                    Cancel
                    </Button>
            </div>
        </Form >;
    }

    clearState = () => {
        this.setState({
            uploadedFiles: [],
            uploadedFileNames: [],
            editFileNameIndex: -1,
            editFileName: '',
            editedFileNames: []
        });
    }

    render() {
        return (
            <div>
                <AlertModal id="custom-notification-success" />
                <Async
                    identifier="CustomNotification"
                    promise={this.promise}
                    loader={<div></div>}
                    error={<div></div>}
                    content={
                        <Modal className="create-custom-notification-modal"
                            show={this.props.showModal}
                            onExited={this.clearState}
                            onHide={() => hideCreateCustomNotificationModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Create Custom Notification</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.renderForm()}
                            </Modal.Body>
                        </Modal>
                    }
                />
            </div>
        );
    }
}

const CustomLabel = ({ children, htmlFor }: any) => {
    return <Label className="custom-notification-label" htmlFor={htmlFor}>{children}</Label>;
};

const CustomCol = ({ children }: any) => {
    return <Col sm={6} xs={12} className="input custom-notification-input">{children}</Col>;
};
 
const renameInput: React.CSSProperties = {
    border: 'none',
    fontSize: '12px',
    padding: 0,
    boxShadow: 'none',
    height: 'auto',
    width: '70%',
    display: 'inline-block',
    marginRight: '5px',
};

const listStyle: React.CSSProperties = {
    maxHeight: '143px',
    color: 'grey',
    fontSize: '12px',
    marginBottom: 0,
};

const listItemStyle: React.CSSProperties = {
    outline: '1px solid #ddd',
    border: 'none'
};
  
export function mapStateToProps(state: any) {
    return {
        showModal: state.modal.get('showCreateCustomNotificationModal'),
        userId: state.login.get('userId'),
        userUID: state.login.get('userUID'), forms: state.forms,
    };
}

export const CustomNotificationFormModal = connect<ICustomNotificationFormModalProps>(mapStateToProps)(CustomNotificationFormModalImpl);
