import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as React from 'react';
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'; 
import { showModal } from '../../actions/modalActions';
import { GroupModel } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { connect } from 'react-redux';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { IHistory } from '../../interfaces';
const MenuItem = Dropdown.Item;

export interface IListItemImplProps {
    history?: IHistory;
    index?: number;
    instance?: GroupModel;
    userId: string;
    style?: React.CSSProperties;
}

export interface IListItemState {
    displayOptions: boolean;
}

export class ListItemImpl extends React.PureComponent<IListItemImplProps, IListItemState> {

    constructor(props: IListItemImplProps | Readonly<IListItemImplProps>) {
        super(props);
        this.state = { displayOptions: false };
    }

    static defaultProps: IListItemImplProps;

    renderUserOptions = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            displayOptions: !this.state.displayOptions
        });
    }

    handleDelete = async (id: string) => {
        const groupInstance = GroupModel.get(id);
        new GroupModel(groupInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-group', groupInstance.props.groupName, groupInstance);
    }

    renderDropdown = () => {
        const { instance } = this.props;
        /* const userInstance = UserModel.get(userId);
        if (userInstance && userInstance.props.accessLevel !== 'L1') {
            return;
        } */
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">
                 { UserModel.checkUserAccess('group_edit') &&<MenuItem eventKey="1"
                    onClick={() => instance && this.props.history?.push(`/groups/${instance.props.id}/edit`)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit
                    </MenuItem> }
                { UserModel.checkUserAccess('group_delete') && <MenuItem eventKey="2"
                    onClick={() => instance && instance.props.id && this.handleDelete(instance.props.id)} className="item">
                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                    </MenuItem>}
            </DropdownButton>
        );
    }

    renderFormList = () => {
        const { instance, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        /* const userInstance = UserModel.getUserByUID(instance.props.groupCreator);
        let groupCreator = 'NA';
        if (userInstance) {
            groupCreator = userInstance.props.firstName;
        } */
        return (

            <Row className="report-row" style={style}>
                <Col sm={1}>
                    { ( UserModel.checkUserAccess('group_edit') ||  UserModel.checkUserAccess('group_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{ (this.props.index || 0) + 1}</span></Col>
                <Cell sm={4} onClick={() => this.props.history?.push(`/groups/${instance.props.id}`)}>
                    {instance.props.creatorName || 'NA'}</Cell>
                <Cell sm={4} onClick={() => this.props.history?.push(`/groups/${instance.props.id}`)}>
                    {instance.props.groupName || 'NA'}</Cell>
                <Cell sm={3} onClick={() => this.props.history?.push(`/groups/${instance.props.id}`)}>
                    {instance.props.dateCreated || 'NA'}</Cell>
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
        userId: state.login.get('userId')
    };
}

export const ListItem = withRouter(connect<IListItemImplProps, any, any>(mapStateToProps)(ListItemImpl));
