import * as React from 'react';
import { connect } from 'react-redux';
import { hideImage } from '../../../actions/miscellaneousActions';
import ImageGallery from 'react-image-gallery';
declare var require: any;
require('react-image-gallery/styles/scss/image-gallery.scss');

export interface IGalleryProps {
    imageURL?: string;
    images?: string[];
}

export class GalleryImpl extends React.PureComponent<IGalleryProps, {}> {
    constructor(props: IGalleryProps | Readonly<IGalleryProps>) {
        super(props);
    }

    handleImage = (e: { target: { className: string; }; }) => {
        if (e.target.className === 'image-outer') {
            return hideImage();
        }
        return;
    }

    renderImage = () => {
        const { imageURL, images } = this.props;
        if (!imageURL) {
            return <div ></div>;
        }

        const imagesData: { original: string; thumbnail: string; }[] = [];
        if(images) { 
            images.forEach(element => {  
                imagesData.push({
                    original: element,
                    thumbnail: element,
                });
            }); 
        }
        return (
            <div className="image-outer" onClick={() =>this.handleImage}>
                <span className="close-image" onClick={() => hideImage()}>&times;</span>
                <ImageGallery items={imagesData} showFullscreenButton={false} showPlayButton={false} lazyLoad={true} />
            </div>
        );
    }

    render() {
        return this.renderImage();
    }
}

export function mapStateToProps(state: any) {
    return {
        imageURL: state.miscellaneous.get('imageURL')
    };
}

export const Gallery = connect<{}, {}, IGalleryProps>(mapStateToProps)(GalleryImpl);
