import {Async} from '../reusableComponents/Async';
import * as React from 'react';
import {Title} from '../reusableComponents/Title';
import {BasePage} from '../reusableComponents/BasePage';
import '../HomePage/homePage.scss';
import {UserModel} from '../../model/UserModel';
import {Loader} from '../reusableComponents/Loader';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {Container as Grid, Row, Col} from 'react-bootstrap';
import oiReportsIcon from '../../images/observationIcon.png';
import officeSafetyIcon from '../../images/officeSafetyLogo.png';

export interface IMOCFormsProps {
    history: IHistory;
}

export class MOCFormsImpl extends React.PureComponent<IMOCFormsProps, {}> {
    constructor(props: IMOCFormsProps | Readonly<IMOCFormsProps>) {
        super(props);
    }

    promise = async () => {
        return null;
    }

    renderContent = () => {
        return (
            <Grid style={{width: '100%'}} className="homepage">
                <Title /* titleColor="white" */ text="Management Of Change Forms"/>
                <div className="links-container">
                    <Row className="show-grid">
                        <Col sm={6} xs={12}
                             className="top-row-left"
                             title="Click to fill out MOC-Permanent Deviation Form"
                             onClick={() => this.props.history.push('/moc-permanent-deviation/create')}
                        >
                            <img className="icon icon-1" src={oiReportsIcon} alt="SafeConnect"/>
                            <span className="text">Permanent Deviation</span>
                        </Col>
                        <Col sm={6} xs={12}
                             className="top-row-right"
                             title="Click to fill out MOC-Temporary Deviation Form"
                             onClick={() => this.props.history.push('/moc-temporary-deviation/create')}
                        >
                            <img className="icon" src={officeSafetyIcon} alt="SafeConnect"/>
                            <span className="text">Temporary Deviation</span>
                        </Col>
                    </Row>
                </div>
            </Grid>
        );
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="moc"
                    promise={this.promise}
                    loader={<Loader/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);

    return {
        userInstance,
        userId
    };
}

export const MOCForms = withRouter(connect<{}, {}, IMOCFormsProps>(mapStateToProps)(MOCFormsImpl));
