import { IHistory } from '../interfaces';
import { logoutUser, showAlert } from '../utils/generalUtils';
import { postCustom } from '../utils/HTTP';

export async function updatePassword(data: {
    oldPassword: string, newPassword: string,
    company: string, userUID: string
}, userId: string | undefined, history: IHistory | undefined) {
    const requestData = {
        userUID: data.userUID,
        oldPassword: data.oldPassword,
        newPassword: data.newPassword, company: data.company
    };
    try {
        const response = await postCustom('UpdatePassword', requestData); 
        showAlert(response.data.message , 'update-password', 'danger', ()=> {logoutUser('user', userId, () => history?.replace('/'));} );
        
    } catch (error) {
        showAlert('Incorrect Password', 'update-password', 'danger');
    }
}
