import * as React from 'react';
import QRCode from "react-qr-code";

export interface IUserQRCodeProps {
    className?: string; 
    user: string;
}

export class UserQRCode extends React.PureComponent<IUserQRCodeProps, {}> {
    constructor(props: IUserQRCodeProps | Readonly<IUserQRCodeProps>) {
        super(props);
    }
    printQR = () => {  
        /* let ref: any = document.getElementById('print-qr');
        if(ref) {  
			window.print();
        }
        return false; */

        const element = window.document.getElementById("print-qr"); 
        if (element !== null) {   
            let divContents: any = element.innerHTML;
            let a: any = window.open('', '', 'height=500, width=600');
            if (a !== null) {  
                a.document.write('<html><head><style type="text/css" media="print"> @page { size: auto; margin: 0mm; text-align: center; } </style> </head>');
                a.document.write('<body style=" text-align: center;"> <div style="text-align: center; height: 170px; width: 170px;"> ');
                a.document.write(divContents);
                a.document.write('</div> </body></html>');
                a.document.close();
                a.print(); 
            }
        } 
        return;
    }
    render() {
        const { user } = this.props;
        return (
            <div className='profile-qr'>
                <span id="print-qr" >
                    <QRCode 
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`${user}`}
                        viewBox={`0 0 256 256`}
                    />
                </span>
                <div className="qr-print non-print">
                    <button onClick={() => this.printQR() }>Print QR</button>
                </div>
            </div> 
        );
    }
} 
