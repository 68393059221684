import { 
     GET_CHECKLIST_REPORT_DATA,GET_MODALLIST_REPORT_DATA,
     GET_CHECKLIST_REPORT_DETAILS,
     GET_CHECKLIST_REPORTCHART_DATA } from '../constants/actions';
import { IAction } from '../interfaces';
import { SafetyWalkthroughReportDetail, SafetyWalkthroughReportChartData, } from '../model/SafetyWalkthroughModel';
import { closeCallDetailProps, closeCallSummaryProps, FollowUpParentDetails } from '../model/MechModel';
  
interface IReportGetDetailAction extends IAction {
    reportDetailData: SafetyWalkthroughReportDetail;
}
interface IClosecallReportGetDetailAction extends IAction {
    reportDetailData: closeCallDetailProps;
}

interface IReportGetDataAction extends IAction {
    reportDataList: any[];
}
interface IModalReportGetDataAction extends IAction {
    modalReportDataList: any[];
}
interface IReportGetChartDataAction extends IAction {
    reportChartData: SafetyWalkthroughReportChartData;
}
interface IReportGetCloseCallSummaryChartDataAction extends IAction {
    reportChartData: closeCallSummaryProps;
}
interface IFollowUpParentDetailAction extends IAction {
    reportDetailData: FollowUpParentDetails;
}

export function getReportDataList(reportDataList: any[]): IReportGetDataAction {
    return ({
        type: GET_CHECKLIST_REPORT_DATA,
        reportDataList
    });
}

export function getModalReportDataList(modalReportDataList: any[]): IModalReportGetDataAction {
    return ({
        type: GET_MODALLIST_REPORT_DATA,
        modalReportDataList
    });
}

export function getReportDetailData(reportDetailData: SafetyWalkthroughReportDetail): IReportGetDetailAction {
    return ({
        type: GET_CHECKLIST_REPORT_DETAILS,
        reportDetailData
    });
}
export function getReportChartData(reportChartData: SafetyWalkthroughReportChartData): IReportGetChartDataAction {
    return ({
        type: GET_CHECKLIST_REPORTCHART_DATA,
        reportChartData
    });
}
export function getClosecallReportDetailData(reportDetailData: closeCallDetailProps): IClosecallReportGetDetailAction {
    return ({
        type: GET_CHECKLIST_REPORT_DETAILS,
        reportDetailData
    });
}
export function getCloseCallSummaryChartData(reportChartData: closeCallSummaryProps): IReportGetCloseCallSummaryChartDataAction {
    return ({
        type: GET_CHECKLIST_REPORTCHART_DATA,
        reportChartData
    });
}

export function getFollowUpDetails(reportDetailData: FollowUpParentDetails): IFollowUpParentDetailAction {
    return ({
        type: GET_CHECKLIST_REPORT_DETAILS,
        reportDetailData
    });
} 