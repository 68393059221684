import './addUserForm.scss'; 
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { IUserModelProps, UserModel } from '../../model/UserModel';
import { getAllUsers, updateUser } from '../../services/userService';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { UserForm } from './UserForm';
import { isFormEmpty, showAlert, dispatch } from '../../utils/generalUtils';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { actions } from 'react-redux-form';
import { Container as Grid } from 'react-bootstrap';
import { GroupModel } from '../../model/GroupModel';
import { ProjectModel } from '../../model/ProjectModel';
import { getAllGroups } from '../../services/groupService';
import { getAllProjects } from '../../services/projectService';

export interface IUpdateFormProps {
    userInstance: UserModel;
    history?: IHistory;
    onCancel?: () => void;
    match?: {
        params: {
            id: string;
        }
    };
}

export class UpdateFormImpl extends React.Component<IUpdateFormProps, {}> {
    constructor(props: IUpdateFormProps | Readonly<IUpdateFormProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.userForm'));
    }

    promise = async () => {
        if (UserModel.list().length <= 0) {
            await getAllUsers();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        return null;
    }

    renderUpdateUserForm = () => {
        const { props: { userInstance, history }, handleSubmit } = this;
        if (!userInstance) {
            return <Loader />;
        }
        const { props: { userId, firstName, middleName, lastName, email,
            jobTitle, jobDesc, projects, groups, accessLevel, phoneNumber } } = userInstance;
        let groupIds: any = [];
        let projectIds: any = [];
        (groups || []).forEach(groupName => {
            const groupId = GroupModel.getIdByName(groupName);
            groupIds.push(groupId).toString();
        });
        (projects || []).forEach(projectName => {
            const projectId = ProjectModel.getIdByName(projectName);
            projectIds.push(projectId).toString();
        });
        return (
            <UserForm
                handleFormSubmit={handleSubmit}
                userInstance={userInstance}
                userId={userId}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                email={email}
                jobTitle={jobTitle}
                jobDesc={jobDesc}
                project={projectIds.toString()}
                projectName={projects}
                group={groupIds.toString()}
                groupNames={groups}
                accessLevel={accessLevel}
                phoneNumber={phoneNumber}
                hideFields={['password', 'userId', 'signature']}
                onClick={() => {
                    history?.push(`/users`);
                }}
                buttonName="Update User"
            />

        );
    }

    handleSubmit = ({ email, firstName, groups, jobDesc, jobTitle, lastName,
        middleName, phoneNumber, projects, userId, accessLevel }: IUserModelProps) => {
        const { history, userInstance: { props: { userUID } } } = this.props; 
        if (isFormEmpty({
            email, firstName, jobDesc, jobTitle, lastName, userId, accessLevel
        })) {
            showAlert(EMPTY_FORM_MESSAGE, 'update-user', 'danger');
            return;
        }
        return updateUser({
            email, firstName, groups, jobDesc, jobTitle, lastName, 
            middleName, phoneNumber, projects, userUID, accessLevel, originalEmail: this.props.userInstance.props.email
        }, history);
    }
    render() {
        return (
            <BasePage>
                <Grid style={{ width: '100%' }} className="update-profile">
                    <Title text={`Update User`} />
                    <AlertModal id="update-user-modal" />
                    <Alert className="danger-alert" id="update-user" />
                    <Async
                        identifier="UpdateUserForm"
                        promise={this.promise}
                        error={<ErrorPage />}
                        loader={<Loader />}
                        content={this.renderUpdateUserForm()}
                    />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, owpProps: any) { 
    const userId = owpProps.match.params.id;
    const userInstance = UserModel.get(userId);
    const groupInstances = GroupModel.list();
    const projectInstances = ProjectModel.list();
    return {
        userInstance, groupInstances,
        projectInstances
    };
}

export const UpdateForm = withRouter(connect< IUpdateFormProps, any, any>(mapStateToProps)(UpdateFormImpl));
