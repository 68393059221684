import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals'; 

import '../node_modules/react-bootstrap/dist/react-bootstrap'; 
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './generals.scss';
import {Provider} from 'react-redux';
import {HashRouter as Router, Switch, Route,} from 'react-router-dom';
import {store} from './store';


import {ProtectedRoute} from './components/reusableComponents/RouteComponents/ProtectedRoute';
import {LoginForm} from './components/Micellaneous/LoginForm';
import {HomePage} from './components/HomePage';

import {CreateForm as CreateGroupForm} from './components/Group/CreateForm';
import {DetailsPage as GroupDetailsPage} from './components/Group/DetailsPage';
import {GroupListPage} from './components/Group/GroupListPage';
import {UpdateForm as UpdateGroupForm} from './components/Group/UpdateForm';

import {CreateForm as CreateProjectForm} from './components/Project/CreateForm';
import {DetailsPage as ProjectDetailsPage} from './components/Project/DetailsPage';
import {ProjectListPage} from './components/Project/ProjectListPage';
import {UpdateForm as UpdateProjectForm} from './components/Project/UpdateForm';

import {LocationForm} from './components/Micellaneous/Locations';
import {LocationListPage} from './components/Micellaneous/Locations/LocationListPage';

import {DesignationForm} from './components/Micellaneous/Designation';
import {DesignationListPage} from './components/Micellaneous/Designation/DesignationListPage';

import {OIForm} from './components/OIForm';
import {DetailsPage as OIDetailsPage} from './components/OIForm/DetailsPage';
import {FilteredListPage} from './components/OIForm/FilteredListPage';
import {OIFormListPage} from './components/OIForm/OIFormListPage';
import {ReportSummaryPage} from './components/OIForm/ReportSummaryPage';

import {OfficeSafetyForm} from './components/OfficeSafetyForm';
import {DetailsPage as OfficeSafetyDetailsPage} from './components/OfficeSafetyForm/DetailsPage';
import {OfficeSafetyListPage} from './components/OfficeSafetyForm/OfficeSafetyListPage';
import {OfficeSafetyComments} from './components/OfficeSafetyForm/OfficeSafetyCommentPage';
import {OfficeSafetyReportSummaryPage} from './components/OfficeSafetyForm/OfficeSafetyReportsPage';

import {PersonalSafetyForm} from './components/Micellaneous/PersonalSafetyForm';
import {DetailsPage} from './components/Micellaneous/PersonalSafetyForm/DetailsPage';
import {PersonalSafetyFormListPage} from './components/Micellaneous/PersonalSafetyForm/PersonalSafetyFormListPage';

import {CreateForm as JSAForm} from './components/JSAForm/CreateForm';
import {DetailsPage as JSADetailsPage} from './components/JSAForm/DetailsPage';
import {NonLoginDetailsPage as JSANonLoginDetailsPage} from './components/JSAForm/NonLoginDetailsPage'; 
import {JSAFormListPage} from './components/JSAForm/JSAFormListPage';
import {TemplateForm as JSATemplateForm} from './components/JSAForm/TemplateForm';


import {RiskAssessmentForm} from './components/RiskAssessment';
import {DetailsPage as RiskAssessmentDetailsPage} from './components/RiskAssessment/DetailsPage';
import {RiskAssessmentFormListPage} from './components/RiskAssessment/RiskAssessmentFormListPage';
import {ReportSummaryPage as RiskReportSummaryPage} from './components/RiskAssessment/ReportSummaryPage';

import {IncidentNotificationForm} from './components/IncidentNotificationForm';
import {DetailsPage as IncidentDetailsPage} from './components/IncidentNotificationForm/DetailsPage';
import {IncidentNotificationListPage} from './components/IncidentNotificationForm/IncidentNotificationListPage';
import {IncidentSummaryPage} from './components/IncidentNotificationForm/IncidentSummaryPage';

import {CreateForm as IncidentReportForm} from './components/InvestigationForm/CreateForm';
import {DetailsPage as IncidentReportDetailsPage} from './components/InvestigationForm/DetailsPage';
import {IncidentReportFormListPage} from './components/InvestigationForm/IncidentReportFormListPage';
import {IncidentReportSummaryPage} from './components/InvestigationForm/IncidentReportSummaryPage';
import {FollowUpListPage} from './components/InvestigationForm/FollowUpListPage';
import {FollowUpPage} from './components/InvestigationForm/FollowUpPage';

import {SafetyWalkthroughReportSummaryPage} from './components/SafetyReport/SafetyWalkthroughReportPage';
import {SafetyWalkthroughCommentListing} from './components/SafetyReport/SWFormCommentsPage';
import {SWFormListPage} from './components/SafetyReport/SWFormListPage';
import {SWReportDetail} from './components/SafetyReport/SWReportDetail';
import {SafetyWalkthroughAssesmentListing} from './components/SafetyReport/SafetyWalkAssesment';
import {AddAttributePage} from './components/SafetyReport/SafetyWalkAssesment/AddAttribute';
import {SupervisorSafetyWalkthroughAssessmentListing} from './components/SafetyReport/SupervisorSafetyWalk';

import {Covid19SummaryPage} from './components/Covid19/Covid19SummaryPage';
import {Covid19ListPage} from './components/Covid19/Covid19ListPage';
import {DetailsPage as Covid19DetailsPage} from './components/Covid19/DetailsPage';

import {CloseCall} from './components/CloseCall';
import {CloseCallCheckList} from './components/CloseCall/CheckList';
import {MechForm} from './components/CloseCall/MechForm';
import {TransForm} from './components/CloseCall/TransForm';
import {EngineerForm} from './components/CloseCall/EngineerForm'; 
import {CloseCallListPage} from './components/CloseCall/CloseCallListPage';
import {CloseCallListDetail} from './components/CloseCall/CloseCallListDetail';
import {CloseCallCheckListPage} from './components/CloseCall/CheckList/CloseCallCheckListPage';
import {CloseCallCheckListDetail} from './components/CloseCall/CheckList/CloseCallCheckListDetail';
import {CloseCallSummaryPage} from './components/CloseCall/CloseCallSummaryPage';

import {ChecklistHome} from './components/Checklist/index';
import {CreateChecklist} from './components/Checklist/CreateChecklist';
import {ChecklistForm} from './components/Checklist/ChecklistForm';
import {ChecklistAttributeForm} from './components/Checklist/ChecklistAttributeForm';
import {ChecklistSummaryPage} from './components/Checklist/ChecklistSummaryPage';

import {AuditlistHome} from './components/Audit/index';
import {AuditForm} from './components/Audit/AuditForm';
import {ChecklistAttributeForm as AuditChecklistAttributeForm} from './components/Audit/ChecklistAttributeForm';
import {AuditFormListPage} from './components/Audit/AuditFormListPage';
import {CreateAuditForm} from './components/Audit/CreateAuditForm';
import {AuditFormDetail} from './components/Audit/AuditFormDetail';

import {CreateForm as CreateUserForm} from './components/User/CreateForm';
import {DetailsPage as UserDetailsPage} from './components/User/DetailsPage';
import {FormListPage} from './components/User/FormsListPage';
import {UpdateForm as UpdateUserForm} from './components/User/UpdateForm';
import {UserListPage} from './components/User/UserListPage';

import {FeedbackForm} from './components/Micellaneous/FeedbackForm';
import {FeedbackDetailsPage} from './components/Micellaneous/FeedbackForm/DetailsPage';
import {FeedbackListPage} from './components/Micellaneous/FeedbackForm/FeedbackListPage';

import {ReferenceDropZone} from './components/Micellaneous/ReferencePage/ReferenceDropZone';
import {ReferencePage} from './components/Micellaneous/ReferencePage/ReferencePage';
import {GoldenRulesPage} from './components/Micellaneous/GoldenRules/GoldenRulesPage';

import {CustomNotificationDetailsPage} from './components/reusableComponents/CustomNotificationDetails';
import {Profile} from './components/DashBoard/Profile';
import {UpdateProfile} from './components/DashBoard/UpdateProfile';
import {UpdatePassword} from './components/User/UpdatePassword';
import {ForgotPasswordForm} from './components/Micellaneous/ForgotPasswordForm';
import {UnAuthorizedPage} from './components/reusableComponents/UnAuthorizedPage';
import {NotFoundPage} from './components/reusableComponents/NotFoundPage';
import {FollowUpPage as FollowUpsPage} from './components/Micellaneous/FollowUps/FollowUpPage';

import {Texas811Forms} from "./components/Texas811";
import {PreExcavationCheckListForm} from "./components/Texas811/PreExcavationCheckListForm";
import {UtilityDamageReportForm} from "./components/Texas811/UtilityDamageReportForm";
import {PreExcavationChecklistList} from "./components/Texas811/preExcavationChecklistListPage";
import {UtilityDamageReportsList} from "./components/Texas811/utilityDamageReportListPage";
import {PreExcavationDetailsPage} from "./components/Texas811/preExcavationChecklistDetailPage";
import {UtilityDamageReportDetailPage} from "./components/Texas811/utilityDamageReportDetail"; 

import {PreExcavationCheckListForm as PreExcavationCheckListFormV2} from "./components/Texas811/PreExcavationCheckListForm-v2";
import {PreExcavationChecklistList as PreExcavationChecklistListV2} from "./components/Texas811/PreExcavationCheckListForm-v2/preExcavationChecklistListPage";
import {PreExcavationDetailsPage as PreExcavationDetailsPageV2} from "./components/Texas811/PreExcavationCheckListForm-v2/preExcavationChecklistDetailPage";

import { ToolBoxMeetingForm} from './components/ToolBoxMeeting';
import { TSMFormListPage } from './components/ToolBoxMeeting/TSMFormListPage';
import { DetailsPage as TSMDetailsPage } from './components/ToolBoxMeeting/DetailsPage';

import {MOCForms} from "./components/ManagementOfChange";
import {MOCPermanentDeviationForm} from './components/ManagementOfChange/Permanent/MOCPermanentDeviation';
import {MOCPermanentDeviationList} from './components/ManagementOfChange/Permanent/MOCPermanentDeviationListPage';
import { DetailsPage as MOCPermanentDeviationDetails } from './components/ManagementOfChange/Permanent/MOCPermanentDeviationDetailsPage';
import {MOCTemporaryDeviationForm} from './components/ManagementOfChange/Temporary/MOCTemporaryDeviation';
import {MOCTemporaryDeviationList} from './components/ManagementOfChange/Temporary/MOCTemporaryDeviationListPage';
import { DetailsPage as MOCTemporaryDeviationDetails } from './components/ManagementOfChange/Temporary/MOCTemporaryDeviationDetailsPage';

import { WorkToPermitForm } from './components/PermitToWork';
import { PTWList } from './components/PermitToWork/PTWListPage'; 
import { DetailsPage as PTWDetailsPage } from './components/PermitToWork/DetailsPage'; 

import { HPForm } from './components/HumanPerformance';
import { HPFormListPage } from './components/HumanPerformance/HPFormListPage';
import { HPReportSummaryPage } from './components/HumanPerformance/HPReportSummaryPage';
import { DetailsPage as HPDetailsPage } from './components/HumanPerformance/DetailsPage'; 

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                {/* Login Route */}
                <Route exact path="/" component={LoginForm}/>
                <Route exact path="/login/:company" component={LoginForm}/>
                <Route exact path="/forgot-password" component={ForgotPasswordForm}/>
                <ProtectedRoute path="/home" component={HomePage} userAccess=""/>

                {/* Group Frm */}
                <ProtectedRoute path="/groups/create" component={CreateGroupForm} userAccess="group_add"/>
                <ProtectedRoute exact path="/groups/:id" component={GroupDetailsPage} userAccess="group_view"/>
                <ProtectedRoute path="/groups/:id/edit" component={UpdateGroupForm} userAccess="group_edit"/>
                <ProtectedRoute path="/groups" component={GroupListPage} userAccess="groups_list"/>

                {/* Project Routes */}
                <ProtectedRoute path="/teams/create" component={CreateProjectForm} userAccess="project_add"/>
                <ProtectedRoute path="/teams/:id/edit" component={UpdateProjectForm} userAccess="project_edit"/>
                <ProtectedRoute exact path="/teams/:id" component={ProjectDetailsPage} userAccess="project_view"/>
                <ProtectedRoute path="/teams" component={ProjectListPage} userAccess="projects_list"/>
                
                {/* Location Routes */}
                <ProtectedRoute path="/location/create" component={LocationForm} userAccess="project_add"/>
                <ProtectedRoute path="/locations" component={LocationListPage} userAccess="projects_list"/>
                <ProtectedRoute path="/location/edit/:id" component={LocationForm} userAccess="projects_list"/>

                {/* Designation Routes */}
                <ProtectedRoute path="/designation/create" component={DesignationForm} userAccess="project_add"/>
                <ProtectedRoute path="/designations" component={DesignationListPage} userAccess="projects_list"/>
                <ProtectedRoute path="/designation/edit/:id" component={DesignationForm} userAccess="projects_list"/>

                {/* OI Forms Route */}
                <ProtectedRoute path="/oi-reports/create" component={OIForm} userAccess="o&i_add"/>
                <ProtectedRoute path="/oi-reports/edit/:id" component={OIForm} userAccess="o&i_edit"/>
                <ProtectedRoute exact path="/oi-reports/filter/:filterType/:year/:month/:id"
                                component={FilteredListPage} userAccess="o&i_report"/>
                <ProtectedRoute exact path="/oi-reports/filter/:filterType/:year/:month/:id"
                                component={FilteredListPage} userAccess="o&i_report"/>
                <ProtectedRoute path="/oi-reports/summary" component={ReportSummaryPage}
                                userAccess="o&i_report_summary"/>
                <ProtectedRoute path="/oi-reports/:id" component={OIDetailsPage} userAccess="o&i_view"/>
                <ProtectedRoute path="/oi-reports" component={OIFormListPage} userAccess="o&i_report"/>

                {/* Office Safety Form Routes */}
                <ProtectedRoute path="/office-safety-reports/comments" component={OfficeSafetyComments}
                                userAccess="office_safety_comments"/>
                <ProtectedRoute path="/office-safety-reports/summary" component={OfficeSafetyReportSummaryPage}
                                userAccess="office_safety_report_summary"/>
                <ProtectedRoute path="/office-safety-reports/create" component={OfficeSafetyForm}
                                userAccess="office_safety_add"/>
                <ProtectedRoute path="/office-safety-reports/edit/:id" component={OfficeSafetyForm}
                                userAccess="office_safety_edit"/>
                <ProtectedRoute path="/office-safety-reports/:id" component={OfficeSafetyDetailsPage}
                                userAccess="office_safety_view"/>
                <ProtectedRoute path="/office-safety-reports" component={OfficeSafetyListPage}
                                userAccess="office_safety_report"/>

                {/* Personal Safety Form Routes */}
                <ProtectedRoute path="/personal-safety/create" component={PersonalSafetyForm}
                                userAccess="personal_safty_add"/>
                <ProtectedRoute path="/personal-safety/all" component={PersonalSafetyFormListPage}
                                userAccess="others_personal_safty_report"/>
                <ProtectedRoute exact path="/personal-safety/edit/:editid" component={PersonalSafetyForm}
                                userAccess="personal_safty_edit"/>
                <ProtectedRoute exact path="/personal-safety/addComments/:id" component={PersonalSafetyForm}
                                userAccess="personal_safty_edit"/>
                <ProtectedRoute path="/personal-safety/myforms/:id" component={PersonalSafetyFormListPage}
                                userAccess="personal_safty_report"/>
                <ProtectedRoute exact path="/personal-safety/:id" component={DetailsPage}
                                userAccess="personal_safty_view"/>

                {/* JSA Route */}
                <ProtectedRoute path="/jsa-reports/create" component={JSAForm} userAccess="jsa_add" />
                <ProtectedRoute path="/jsa-reports/edit/:id" component={JSAForm} userAccess="jsa_edit" />
                <ProtectedRoute path="/jsa-reports/:templateId/template" component={JSATemplateForm} userAccess="jsa_edit" />
                {/* <ProtectedRoute path="/jsa-reports/:id/:associatedUserId" component={JSADetailsPage} userAccess="jsa_view" /> */} 
                <Route exact path="/jsa-reports/:id/:associatedUserId/:company/:userEmail" component={JSANonLoginDetailsPage} userAccess=""/>
                <ProtectedRoute path="/jsa-reports/:id" component={JSADetailsPage} userAccess="jsa_view" /> 
                <ProtectedRoute path="/jsa-reports" component={JSAFormListPage} userAccess="jsa_report" /> 

                {/* RISK Assessment Route */}
                <ProtectedRoute path="/risk-assessment/create" component={RiskAssessmentForm} userAccess="risk_add"/>
                <ProtectedRoute path="/risk-assessment/summary" component={RiskReportSummaryPage}
                                userAccess="risk_summary"/>
                <ProtectedRoute path="/risk-assessment-follow-up/:associatedId" component={RiskAssessmentForm}
                                userAccess="risk_add"/>
                <ProtectedRoute path="/risk-assessment/edit/:id" component={RiskAssessmentForm} userAccess="risk_edit"/>
                <ProtectedRoute path="/risk-assessment-reports/:assessmentType" component={RiskAssessmentFormListPage}
                                userAccess="risk_report"/>
                <ProtectedRoute path="/risk-assessment-reports/:assessmentType" component={RiskAssessmentFormListPage}
                                userAccess="risk_report"/>
                <ProtectedRoute path="/risk-assessment/:id" component={RiskAssessmentDetailsPage}
                                userAccess="risk_view"/>

                {/* Incident Form Routes */}
                <ProtectedRoute path="/incident-reports/create" component={IncidentNotificationForm}
                                userAccess="incident_notification_add"/>
                <ProtectedRoute path="/incident-reports/edit/:id" component={IncidentNotificationForm}
                                userAccess="incident_notification_edit"/>
                <ProtectedRoute path="/incident-reports/summary" component={IncidentSummaryPage}
                                userAccess="incident_notification_report_summary"/>
                <ProtectedRoute path="/incident-reports/:id" component={IncidentDetailsPage}
                                userAccess="incident_notification_view"/>
                <ProtectedRoute path="/incident-reports" component={IncidentNotificationListPage}
                                userAccess="incident_notification_report"/>

                {/* Investigation Form Rutes*/}
                <ProtectedRoute exact path="/incident-investigative-reports/follow-up" component={FollowUpListPage}
                                userAccess="incident_investigative_add"/>
                <ProtectedRoute exact path="/incident-investigative-reports/follow-up/:investigativeId"
                                component={FollowUpListPage} userAccess="incident_investigative_add"/>
                <ProtectedRoute exact path="/incident-investigative-reports/follow-up-detail/:id"
                                component={FollowUpPage} userAccess="incident_investigative_add"/>
                <ProtectedRoute exact path="/incident-investigative-reports/follow-up-detail/:id/:investigativeId"
                                component={FollowUpPage} userAccess="incident_investigative_add"/>
                <ProtectedRoute exact path="/incident-investigative-reports/create" component={IncidentReportForm}
                                userAccess="incident_investigative_add"/>
                <ProtectedRoute exact path="/incident-investigative-reports/edit/:id" component={IncidentReportForm}
                                userAccess="incident_investigative_edit"/>
                <ProtectedRoute path="/incident-investigative-reports/create/:incidentId" component={IncidentReportForm}
                                userAccess="incident_investigative_add"/>
                <ProtectedRoute path="/incident-investigative-reports/view/:incidentId"
                                component={IncidentReportDetailsPage} userAccess="incident_investigative_view"/>
                <ProtectedRoute path="/incident-investigative-reports/summary" component={IncidentReportSummaryPage}
                                userAccess="incident_investigative_report_summary"/>
                <ProtectedRoute path="/incident-investigative-reports/:incidentType"
                                component={IncidentReportFormListPage} userAccess="incident_investigative_report"/>

                {/* Safety Walkthrough Route */}
                <ProtectedRoute path="/sw-reports" component={SWFormListPage} userAccess="safety_walkthrough_report"/>
                <ProtectedRoute path="/sw-comments" component={SafetyWalkthroughCommentListing}
                                userAccess="safety_walkthrough_comments"/>
                <ProtectedRoute exact path="/sw-report-detail/:formId/:formType" component={SWReportDetail}
                                userAccess="safety_walkthrough_view"/>
                <ProtectedRoute exact path="/safety-walkthrough-reports/summary"
                                component={SafetyWalkthroughReportSummaryPage}
                                userAccess="safety_walkthrough_report_summary"/>
                <ProtectedRoute path="/safety-walkthroug/all" component={SafetyWalkthroughAssesmentListing}
                                userAccess="safety_walkthrough_add"/>
                <ProtectedRoute path="/safety-walkthrough/edit/:id" component={SafetyWalkthroughAssesmentListing}
                                userAccess="safety_walkthrough_edit"/>
                <ProtectedRoute path="/safety-walkthroug/attribute" component={AddAttributePage}
                                userAccess="safety_walkthrough_add"/>
                <ProtectedRoute path="/supervisor-safetywalkthrough/all"
                                component={SupervisorSafetyWalkthroughAssessmentListing}
                                userAccess="safety_walkthrough_add"/>
                <ProtectedRoute path="/supervisor-safetywalkthrough/edit/:id"
                                component={SupervisorSafetyWalkthroughAssessmentListing}
                                userAccess="safety_walkthrough_edit"/>

                {/* Checklist Route */}
                <ProtectedRoute path="/checklist" component={ChecklistHome} userAccess="checklist_add"/>
                <ProtectedRoute path="/create/checklist" component={CreateChecklist} userAccess="checklist_name_add"/>
                <ProtectedRoute path="/checklist-form/:checklistId" component={ChecklistForm}
                                userAccess="checklist_add"/>
                <ProtectedRoute path="/checklist-edit-form/:checklistId/:editId" component={ChecklistForm}
                                userAccess="checklist_edit"/>
                <ProtectedRoute path="/checklist-attribute/:checklistId" component={ChecklistAttributeForm}
                                userAccess="checklist_add"/>
                <ProtectedRoute path="/checklist-reports/:checklistType" component={SWFormListPage}
                                userAccess="checklist_report"/>
                <ProtectedRoute path="/checklist-summary" component={ChecklistSummaryPage}
                                userAccess="checklist_summary"/>

                {/** Audit Route */}
                <ProtectedRoute path="/audit" component={AuditlistHome} userAccess="audit_inspection_add"/>
                <ProtectedRoute path="/create/audit" component={CreateAuditForm} userAccess="audit_inspection_add"/>
                <ProtectedRoute path="/audit-form/:checklistId" component={AuditForm} userAccess="audit_inspection_add"/>
                <ProtectedRoute path="/audit-form-edit/:checklistId/:editId" component={AuditForm}
                                userAccess="audit_inspection_edit"/>
                <ProtectedRoute path="/audit-form-view/:checklistId/:viewId" component={AuditFormDetail}
                                userAccess="audit_inspection_view"/>
                <ProtectedRoute path="/audit-checklist-attribute/:checklistId" component={AuditChecklistAttributeForm} userAccess="audit_inspection_add"/>
                <ProtectedRoute path="/audit-reports" component={AuditFormListPage} userAccess="audit_inspection_report"/>

                {/* FRA Route */}
                <ProtectedRoute path="/closecall" component={CloseCall} userAccess=""/>
                <ProtectedRoute path="/mech-reports/create" component={MechForm} userAccess="closecall_add"/>
                <ProtectedRoute path="/mech-reports/edit/:id" component={MechForm} userAccess="closecall_edit"/>
                <ProtectedRoute path="/trans-reports/create" component={TransForm} userAccess="closecall_add"/>
                <ProtectedRoute path="/trans-reports/edit/:id" component={TransForm} userAccess="closecall_edit"/>
                <ProtectedRoute path="/engineer-reports/create" component={EngineerForm} userAccess="closecall_add"/>
                <ProtectedRoute path="/engineer-reports/edit/:id" component={EngineerForm} userAccess="closecall_edit"/>
                <ProtectedRoute path="/close-call-reports" component={CloseCallListPage} userAccess="closecall_report"/>
                <ProtectedRoute path="/close-call-report-detail/:formId/:formType" component={CloseCallListDetail}
                                userAccess="closecall_view"/>

                {/* CloseCall Checklist Route */}
                <ProtectedRoute path="/close-call-summary" component={CloseCallSummaryPage}
                                userAccess="closecall_report_summary"/>
                <ProtectedRoute path="/close-call-checklist" component={CloseCallCheckList}
                                userAccess="closecall_checklist_add"/>
                <ProtectedRoute path="/closecall-checklist/edit/:id" component={CloseCallCheckList}
                                userAccess="closecall_checklist_edit"/>
                <ProtectedRoute path="/close-call-checklist-reports" component={CloseCallCheckListPage}
                                userAccess="closecall_checklist_report"/>
                <ProtectedRoute path="/closecall-checklist-detail/:formId/:formType"
                                component={CloseCallCheckListDetail} userAccess="closecall_checklist_view"/>
        
                {/* COVID19 Frm */}
                <ProtectedRoute path="/covid19-reports" component={Covid19ListPage} userAccess="covid19_report"/>
                <ProtectedRoute path="/covid19-report/:id" component={Covid19DetailsPage} userAccess="covid19_view"/>
                <ProtectedRoute path="/covid19-summary" component={Covid19SummaryPage}
                                userAccess="covid19_report_summary"/>

                {/* User Form Routes */}
                <ProtectedRoute path="/users/create" component={CreateUserForm} userAccess="user_add"/>
                <ProtectedRoute exact path="/user-forms/:id" component={FormListPage} userAccess="user_edit"/>
                <ProtectedRoute exact path="/users/:id" component={UserDetailsPage} userAccess="user_view"/>
                <ProtectedRoute path="/users/:id/edit" component={UpdateUserForm} userAccess="user_edit"/>
                <ProtectedRoute path="/users" component={UserListPage} userAccess="users_list"/>
 
                {/* Texas 811 Routes */}
                <ProtectedRoute path="/utility-forms" component={Texas811Forms} userAccess="tsm_add"/>
                <ProtectedRoute path="/texas811" component={Texas811Forms} userAccess="texas811_forms"/>
                <ProtectedRoute path="/preExcavationChecklist/reports" component= {PreExcavationChecklistList} userAccess="pre_excavation_checklist_report"/>
                <ProtectedRoute path="/preExcavationChecklist/edit/:id" component={PreExcavationCheckListForm} userAccess="pre_excavation_checklist_edit"/>
                <ProtectedRoute path={"/preExcavationChecklist/detail/:id"} component={PreExcavationDetailsPage} userAccess="pre_excavation_checklist_view"/>
                <ProtectedRoute path="/preExcavationChecklist/create" component={PreExcavationCheckListForm} userAccess="pre_excavation_checklist_add"/>
                
                <ProtectedRoute path="/preExcavationChecklistV2/reports" component= {PreExcavationChecklistListV2} userAccess="pre_excavation_checklist_report"/>
                <ProtectedRoute path="/preExcavationChecklistV2/edit/:id" component={PreExcavationCheckListFormV2} userAccess="pre_excavation_checklist_edit"/>
                <ProtectedRoute path={"/preExcavationChecklistV2/detail/:id"} component={PreExcavationDetailsPageV2} userAccess="pre_excavation_checklist_view"/>
                <ProtectedRoute path="/preExcavationChecklist-v2/create" component={PreExcavationCheckListFormV2} userAccess="pre_excavation_checklist_add"/>

                <ProtectedRoute path="/utilityDamageReport/reports" component={UtilityDamageReportsList} userAccess="utility_damage_report"/>
                <ProtectedRoute path="/utilityDamageReport/edit/:id" component={UtilityDamageReportForm} userAccess="utility_damage_edit"/>
                <ProtectedRoute path="/UtilityDamageReportDetail/:id" component={UtilityDamageReportDetailPage}
                                userAccess="utility_damage_view"/>
                <ProtectedRoute path="/utilityDamageReport/create" component={UtilityDamageReportForm} userAccess="utility_damage_add"/>

                {/* Tool Box Meeting Routes */} 
                <ProtectedRoute path="/tool-box-meeting/create" component={ToolBoxMeetingForm} userAccess="tsm_add"/> 
                <ProtectedRoute path="/tool-box-meeting/reports" component={TSMFormListPage} userAccess="tsm_report"/>
                <ProtectedRoute path="/tool-box-meeting/edit/:id" component={ToolBoxMeetingForm} userAccess="tsm_edit"/>
                <ProtectedRoute path="/tool-box-meeting/view/:id" component={TSMDetailsPage} userAccess="tsm_view"/>

                {/* MOC Routes */} 
                <ProtectedRoute path="/management-of-change/create" component={MOCForms} userAccess=""/> 
                <ProtectedRoute path="/moc-permanent-deviation/create" component={MOCPermanentDeviationForm} userAccess=""/>
                <ProtectedRoute path="/moc-temporary-deviation/create" component={MOCTemporaryDeviationForm} userAccess=""/>
                <ProtectedRoute path="/management-of-change/permanent-deviation-reports" component={MOCPermanentDeviationList} userAccess=""/> 
                <ProtectedRoute path="/management-of-change/temporary-deviation-reports" component={MOCTemporaryDeviationList} userAccess=""/>
                <ProtectedRoute path="/moc-permanent-deviation/edit/:id" component={MOCPermanentDeviationForm} userAccess=""/>
                <ProtectedRoute path="/moc-permanent-deviation/detail/:id" component={MOCPermanentDeviationDetails} userAccess=""/>
                <ProtectedRoute path="/moc-temporary-deviation/edit/:id" component={MOCTemporaryDeviationForm} userAccess=""/>
                <ProtectedRoute path="/moc-temporary-deviation/detail/:id" component={MOCTemporaryDeviationDetails} userAccess=""/>

                {/* WTP Forms Route */}
                <ProtectedRoute path="/permit-to-work/create" component={WorkToPermitForm} userAccess=""/> 
                <ProtectedRoute path="/permit-to-work/detail/:id" component={PTWDetailsPage} userAccess=""/>
                <ProtectedRoute path="/permit-to-work/edit/:id" component={WorkToPermitForm} userAccess=""/>
                <ProtectedRoute path="/permit-to-work/:formStage" component={PTWList} userAccess=""/>  

                {/* HP Forms Route */}
                <ProtectedRoute path="/human-performance/create" component={HPForm} userAccess=""/> 
                <ProtectedRoute path="/human-performance-reports" component={HPFormListPage} userAccess=""/>  
                <ProtectedRoute path="/human-performance/edit/:id" component={HPForm} userAccess=""/>
                <ProtectedRoute path="/human-performance/view/:id" component={HPDetailsPage} userAccess=""/>
                <ProtectedRoute path="/human-performance-summary" component={HPReportSummaryPage} userAccess=""/> 
                
                {/* References */}
                <ProtectedRoute path="/reference/edit" component={ReferenceDropZone} userAccess="reference_add"/>
                <ProtectedRoute path="/reference/show" component={ReferencePage} userAccess="references_list"/>
                <ProtectedRoute path="/golden-rules" component={GoldenRulesPage} userAccess="golden_rules_list"/>

                {/* Others */}
                <ProtectedRoute path="/feedback-form" component={FeedbackForm} userAccess=""/>
                <ProtectedRoute path="/submitted-feedback/list" component={FeedbackListPage} userAccess=""/>
                <ProtectedRoute path="/submitted-feedback/details/:id" component={FeedbackDetailsPage} userAccess=""/>
                <ProtectedRoute path="/custom-notification/:id" component={CustomNotificationDetailsPage}
                                userAccess=""/>
                <ProtectedRoute exact path="/profile/:id" component={Profile} userAccess=""/>
                <ProtectedRoute path="/profile/:id/edit" component={UpdateProfile} userAccess=""/>
                <ProtectedRoute path="/update-password/:id" component={UpdatePassword} userAccess=""/>
                <ProtectedRoute path="/follow-ups/:type" component={FollowUpsPage} userAccess=""/>

                <ProtectedRoute exact path="*" component={NotFoundPage} userAccess=""/>
                <ProtectedRoute exact path="/not-authorized" component={UnAuthorizedPage} userAccess=""/>

            </Switch>
        </Router>
    </Provider>,
    document.getElementById('safeconnect')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
