import { BaseModel } from './BaseModel'; 

export interface ICovid19ModelProps {
    id?: string; 
    userId?: string;
    userName?: string;
    formResult?: string;
    date?: string;
    time?: string; 
    q1?: string;
    q2?: string;
    q3?: string;
    q4?: string;
    q5?: string;
    q6?: string;
    q7?: string;
    q8?: string;
    q9?: string;
    q10?: string;
    q11?: string;
    q12?: string;
    q13?: string; 
}
export interface ICovid19ShortSummaryProps {
    total?: string;
    negative?: string;
    positive?: string;
    postivePercentage?: string;
    negativePercentage?: string;
    userCount?: string;
}

export class Covid19Model extends BaseModel<ICovid19ModelProps> {
    constructor(props: ICovid19ModelProps) {
        super(props, Covid19Model.resource);
    }
    static resource = 'covid19-form';
}
