import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../FormComponents/Button';
import { Form, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { RRFInput } from '../FormComponents/RRFInput';
import { dispatch } from '../../../utils/generalUtils';

export interface IYearlyFilterProps {
    onApply: (year: any) => void;
    defaultValue?: number;
    
}

export class YearlyFilter extends React.PureComponent<IYearlyFilterProps, {}> {
    constructor(props: IYearlyFilterProps | Readonly<IYearlyFilterProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.yearlyFilter'));
    }

    getChildContext() {
        return { formModel: 'forms.yearlyFilter' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    applyYearlyFilter = ({ year }: any) => {
        const { onApply } = this.props;
        if (onApply) {
            return onApply(year);
        }
    }

    renderYearlyFilter = () => {
        const { defaultValue } = this.props;
        let yearOptions:Array<any> = [];
        let startYear = new Date().getFullYear();
        let currentYear = new Date().getFullYear();
        let endYear = startYear - 100;
        for (let i = startYear; i >= endYear; i--) {
            yearOptions.push({
                value: startYear.toString(), label: startYear
            });
            startYear--;
        }
        return (
            <Form model="forms.yearlyFilter" onSubmit={this.applyYearlyFilter}>
                <Row className="show-grid filter" style={{ padding: '10px 0px' }}>
                    <Col sm={9} className="input month-select">
                        <RRFInput
                            type="dropdown"
                            menuItems={yearOptions}
                            model=".year"
                            defaultValue={ (defaultValue || currentYear).toString()}
                        />
                    </Col>
                    <Col sm={3} className="input done">
                        <Button
                            type="submit"
                            style={{
                                width: '100%',
                                color: '#5479AF',
                                border: '1px solid #5479AF', backgroundColor: 'white',
                                marginLeft: '20px', marginRight: '20px'
                            }}
                        >
                            Done
                    </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
    render() {
        return this.renderYearlyFilter();
    }
}
