
import * as React from 'react';  
import { Col, Row } from 'react-bootstrap'; 
import { Card } from '../reusableComponents/Card';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends'; 
import { Title } from '../reusableComponents/Title'; 

import { geHPFormSummaryData } from '../../services/hpFormService'; 
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { showPrintPreviewModal } from '../../actions/modalActions'; 
import { IHistory, IForms } from '../../interfaces'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Async } from '../reusableComponents/Async';
import { setSuccess, setLoading } from '../../actions/loadingActions';  
import { forms } from '../../reducers/formReducer';
import { StackedAreaChart } from '../reusableComponents/ChartComponents/StackedAreaChart'; 
import { StackedBarChart } from '../reusableComponents/ChartComponents/StackedBarChart';
import './hpForm.scss';

export interface IHPReportsDataProps {  
    getRef?: (value: any) => void;
    showCheckBox?: boolean;
    id?: string; 
    history?: IHistory;
    forms?: IForms;
    reportsData: any;
}

export interface IHPReportsDataState { 
    isLoading: boolean;
    chartType: boolean;
}

export class HPReportsData extends React.PureComponent<IHPReportsDataProps, IHPReportsDataState> {
    constructor(props: IHPReportsDataProps | Readonly<IHPReportsDataProps>) {
        super(props);
        this.state = { isLoading: false, chartType: false, };
    }

    componentWillMount() {
        setSuccess('ReportsPage');
    }

    ref: any; 
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await geHPFormSummaryData(this.props.forms); 
        setSuccess('ReportsPage');
    }
    renderPrintPreviewModal = () => {
        showPrintPreviewModal('oi-report');
        return;
    }
    renderFilters = () => {
        return;
    }
    
    renderChart = () => { 
        //let strokeColors = ['82ca9d', 'ffc658']; 
        let strokeColors = ['008000', 'FF0000'];
        //let strokeColors = ['228B22', 'ff4c00']; 
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <div  style={{ display: 'flex'}}>
                        <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px', flex: '1 0 auto', marginLeft: '20%' }}>
                            Total Count of <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label> / <label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> Human Performance
                        </h4> 
                    </div> 
                    <Row className="show-grid line-chart closeCallSummary">
                        <Col sm={12} style={{ height: '300px' }}> 
                            {this.state.chartType? <StackedAreaChart data={this.props.reportsData.summary} dataKey={['Ok', 'Not Ok']} strokeColors={strokeColors}/> : <StackedBarChart data={this.props.reportsData.summary} dataKey={['Ok', 'Not Ok']} strokeColors={strokeColors} hideLegend={true}/> } 
                        </Col>
                    </Row>
                </Card>
                <Card width="100%" className="chart sad-details-chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        <label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> - Details
                    </h4>
                    <Row className="show-grid" >
                        <Col sm={5} id="oi-pie-chart" className="pie circle" style={{ height: '253px' }}>
                             
                            <PieChart
                                data={this.props.reportsData.sadSummary}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1} 
                                customTootip={true}
                                isAnimationActive={true}
                                tooltipHint={' '}
                            />
                        </Col>
                        <PieChartLegends
                            sm={7}
                            legendNames={this.props.reportsData.sadSummaryLegends}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA',
                            'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                        />
                    </Row> 
                </Card>
            </div>
        );
    }
    handleChartType = async (value: boolean) => { 
        this.setState({chartType: value});//Sad-Details
    } 
    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data hp-summary" >
            <Title key="title" text="Human Performance Report Summary" style={{paddingTop: '30px'}}></Title> 
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''} 
                    <CommonFilter onApply={this.applyCommonFilter} forms={forms}/> 
                    {this.renderChart()}  
                </div>
            </div>
        </div>;
    }
 
    render() {
        return <Async
            identifier="ReportsPage" 
            error={<div></div>}
            loader={this.renderContent(true)}
            content={this.renderContent(false)}
        />;
    }
}
