import * as React from 'react';
import { Col } from 'react-bootstrap';
import './legends.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
export interface IPieChartLegendsProps {
    legendNames: string[];
    legendColors: string[];
    style?: React.CSSProperties;
    sm?: number;
    xs?: number;
}

export class PieChartLegends extends React.PureComponent<IPieChartLegendsProps, {}> {
    constructor(props: IPieChartLegendsProps | Readonly<IPieChartLegendsProps>) {
        super(props);
    }

    renderLegends = () => {
        const { legendColors, legendNames } = this.props;
        return legendNames.map((legend, index) => {
            return (
                <div key={index} className="legend">
                    <p style={{
                        margin: '5px auto',
                        width: '50px',
                        height: '20px',
                        display: 'inline-block',
                        backgroundColor: `#${legendColors[index]}`
                    }}></p><span className="legend-text">{legend}</span>
                </div >
            );
        });
    }

    render() {
        const { style, xs, sm } = this.props;
        return (
            <Col style={style} sm={sm || 5} xs={xs || 12} className="legends">
                <PerfectScrollbar>
                    {this.renderLegends()}
                </PerfectScrollbar>
            </Col>
        );
    }
}
