import { delCustom, post, postCustom } from '../utils/HTTP';   
import { format } from 'date-fns';  
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { UserModel } from '../model/UserModel';
import { showAlert, convertToDateFormat } from '../utils/generalUtils'; 
import { IAnswer, IForms, IHistory } from '../interfaces'; 
import { ChecklistNameModel } from '../model/ChecklistModel'; 
import { saveListCount } from '../actions/miscellaneousActions';
import { Answers } from '../utils/Answers';
import { AuditModel } from '../model/AuditModel'; 
 
export async function verifyJSANo(param: any) {
    const company = UserModel.getCompanyName(); 
    try {
        param.company = company; 
        const response = await postCustom(`/VerifyJSANo`, param);
        if (response.data.status === 'valid') {
             
        }
        return response.data; 

    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'create-checklist', 'danger');
        throw error;
    } 
} 
export async function submitAuditForm(formData: any, answers: IAnswer[], history: IHistory, forms: IForms, editId?: string) { 
    const submittedValues: any = {
        groupName: formData.groupName,
        userId: formData.userUID,
        formId: formData.checklistId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        answers,
        projectName: formData.projectName,
        associatedFormId: '',
        followUp: '0', 
        deletedImages: [],
        deletedVideos: [],
        editId: formData.editId || '',
        deletedFiles: forms.auditForm.deletedFiles || [], 
        attachments: forms.auditForm.attachements || [],
    };  
    try { 
        const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'checklist-form-failed', 'danger');
            return;
        } 
        showAlert(response.data.message, 'checklist-form-modal', 'success', async () => { history.push(`/audit-reports`);  
        });
        return response; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'checklist-form-failed', 'danger');
        throw error;
    } 
}

export async function getAllAuditForms(urlData?: any, forms?: IForms ) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType: 'auditForms', pageNumber, sortIndexField, sortingOrder, searchItem, filterParam1: '' }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        if (response.data.status === 'valid') {
            AuditModel.deleteAll();
            response
                .data
                .data
                .forEach((FormData: any) => {
                    const FormInstance: any = marshallFormInstance(FormData); 
                    new AuditModel(FormInstance).$save();
                });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export function marshallFormInstance(data: { id: any; key: any; totalClosedFollowUp: number; totalFollowUp: number; totalOpenFollowUp: number; answers: any; date: number | Date; time: any; userId: any; }) {
    data.id = data.key; 
    data.totalClosedFollowUp = data.totalFollowUp - data.totalOpenFollowUp; 
    const answerData = new Answers(data.answers);  
    let obj: any = {
        dateReported: format(new Date(data.date), 'MM/dd/yyyy'),
        timeReported: data.time,
        dateOfIncident: convertToDateFormat(answerData.get(1) ),
        timeOfIncident: answerData.get(2),
        createdUserName: answerData.get(3),
        serialNo: answerData.get(4),
        referenceNo: answerData.get(5),
        overallComments: answerData.get(6),
        totalScore: answerData.get(7),
        overallPercent: answerData.get(8),
        totalChecklist: answerData.get(9),  
    };
    let effect: any = [];
    let comments: any = []; 
    let checklistQuestions: any = []; 
    let eventIndex: number = 10;
    for (let index = 0; index < answerData.get(9); index++) { 
        let ques = data.answers[eventIndex-1]['quesType'].split('~');
        checklistQuestions.push(ques[1]);
        effect.push(answerData.get(eventIndex++));
        comments.push(answerData.get(eventIndex++));
        
    }  
    obj.effect = effect;
    obj.comments = comments; 
    obj.checklistQuestions = checklistQuestions; 

    Object.assign(data, obj);
    delete data.answers;
    return data;  
} 

export async function deleteChecklistAttr(param: any) { 
    try {
        await delCustom(`/DelAuditQuestions`, param);
    } catch (error) {
        throw error;
    }
}


export async function getAllCheckListNamesOptions() {
    let checklists = ChecklistNameModel.list();
    let options: any = [];
    checklists.forEach((el: { props: { key: any; checklistName: any; }; }) => { 
        options.push({ value: el.props.key, label: el.props.checklistName })
    }); 
    return options;
}
