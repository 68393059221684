import { Async } from '../reusableComponents/Async';
import { IFormMonths, IHistory, IForms, } from '../../interfaces';
import { ListPage } from '../reusableComponents/ListPage';
import { getAllIncidentNotificationForms, getIncidentNotificationFormSummaryData, } from '../../services/incidentNotificationFormService';
import * as React from 'react';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { BasePage } from '../reusableComponents/BasePage';
import { Row, Col, Container as Grid } from 'react-bootstrap';
import { Title } from '../reusableComponents/Title';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import { ListItem } from './ListItem';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { UserModel } from '../../model/UserModel'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { getAllIncidentReportForms } from '../../services/incidentReportService';
import { Pagination } from '../reusableComponents/Pagination';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
declare var require: any;
const queryString = require('query-string');

export interface IIncidentNotificationListPageProps {
    incidentNotificationInstances: IncidentNotificationModel[];
    history?: IHistory;
    formMonths?: IFormMonths;
    total?: number;
    formSummaryData: any;
    bodyParts?: {
        Head: number;
        Hand: number;
        Foot: number;
        Chest: number;
        Other: number;
        Limb: number;
    };
    totalPageCount: number;
    forms: IForms;
    axiosPreviousToken: any;
}

export interface IIncidentNotificationListPageState {
    showDetails: boolean;
    formId: string;
}

export class IncidentNotificationListPageImpl extends React.PureComponent<IIncidentNotificationListPageProps,
    IIncidentNotificationListPageState> {
    constructor(props: IIncidentNotificationListPageProps | Readonly<IIncidentNotificationListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    
    sortListing = { 0: 'date', 1: 'username', 2:'concernType', 3: 'injuryClassification', 4: 'injuryBodyPart' };
    promise = async (pageNumber = (this.props.history && queryString.parse(this.props.history.location['search']).pageNumber) || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        if (IncidentReportModel.list().length <= 0) {
            getAllIncidentReportForms(urlParams);
        } 
        return null;
    }
    getReportData = async (sortIndex='false') => {  
        setLoading('AllListPage');  
        //let pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1; 
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        if(sortIndex==='false') {
            await getIncidentNotificationFormSummaryData(this.props.forms);
        } else {
            urlParams.sortIndexField = this.sortListing[sortIndex]; 
        }
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllIncidentNotificationForms(urlParams, this.props.forms); 
        setSuccess('AllListPage');
    } 

    renderAddNewFormButton = () => { 
        return (
            <button className="add-new-report"
                onClick={() => { this.props.history?.push('/incident-reports/create'); }}
            > + </button> );
    }

    renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                onClick={() => { this.props.history?.push('/incident-reports/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    }

    renderFormList = () => {
        const { incidentNotificationInstances } = this.props; 
        return <div className="incident-list-wrapper"> 
            {<ExcelExport formType="incidentReport" fileName="Incident Report" style={{paddingTop: '17px'}}/>}
            <ListPage
                style={{ minHeight: '50vh', padding: '20px 0px 0px', }}
                titleStyle={{ padding: '0px 70px' }}
                pageHeading="Incident Report List"
                instances={incidentNotificationInstances}
                rowHeadings={['Date of Submit','Date of Incident', 'Concern Type', 'Injury Classification', 'Body Part']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={['projectName','groupName', 'injuryBodyPart', 'date', 'concernType','dateOfIncident', 'specifiedLocation', 'injuryClassification']}
                searchPlaceHolder="Enter project, group, injury classification, injury diagnosis, injury summary, concern type to search..."
                emptyInstancesMessage="No Incident Notification reports found."
                sm={[2, 2, 2, 3, 2]}
                currentPage={ (this.props.history && queryString.parse(this.props.history.location['search']).pageNumber) || 1}
                rowSortings={this.sortListing} 
                promise={(sortIndex) => this.getReportData(sortIndex)}
                forms={this.props.forms}
                history={this.props.history }
            />
            { UserModel.checkUserAccess('incident_notification_add') && this.renderAddNewFormButton()}
            { UserModel.checkUserAccess('incident_notification_report_summary') && this.renderSummaryPageButton()}
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={IncidentNotificationModel as any}
                identifier="AllListPage"
            />
        </div>;
    } 

    renderPieChart = () => {
        if (!this.props.formSummaryData) {
            return;
        }
        const { Hand, Head, Limb, Chest, Foot, Other   } = this.props.formSummaryData.bodyParts;
        const data = [{ name: 'Hand', value: Hand },
        { name: 'Head', value: Head }, { name: 'Limb', value: Limb },
        { name: 'Foot', value: Foot }, { name: 'Chest', value: Chest }, { name: 'Other', value: Other }];
        return (
            <PieChart
                data={data}
                innerRadius={60}
                colorArray={['FFBECE', 'A9DEDC', 'B0D9FF', 'FFF6BE', 'E7BFFF', 'FFDDBE']}
                paddingAngle={1}
            />
        );
    }

    renderContent = () => {
        return (
            <Grid style={{ width: '100%', padding: '20px' }} className="incident-notification-list">
                <Row className="show-grid" style={{ margin: 'auto' }}>
                    <Title text="Incident Notification"></Title>
                    <h4 style={{color: 'white', textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Injured Body Parts
                </h4>
                    <div className="chart list-page-chart">
                        <Col sm={7} className="pie circle" style={{ height: '253px', width: '300px', margin: 'auto' }}>
                            {this.renderPieChart()}
                        </Col>
                        <PieChartLegends
                            style={{ padding: '33px 0 10px 33px' }}
                            legendNames={['Hand', 'Head', 'Limb', 'Foot', 'Chest', 'Other']}
                            legendColors={['FFBECE', 'A9DEDC', 'B0D9FF',
                                'FFF6BE', 'E7BFFF', 'FFDDBE']}
                        />
                    </div>
                </Row > 
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} /> 
                {this.renderFormList()}
            </Grid>
        );
    }

    render() {
        return (
            <BasePage className="wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="IncidentReportSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const incidentNotificationInstances = IncidentNotificationModel.list(state);
    /* if (!state.formSummary.get('incidentFormSummary')) {
        return {
            incidentNotificationInstances
        };
    } */
    const { formSummary } = state;
    const formSummaryData = formSummary.get('incidentFormSummary');
    return {
       /*  formMonths: formSummary.get('incidentFormSummary').formMonths,
        bodyParts: formSummary.get('incidentFormSummary').bodyParts, */
        formSummaryData,
        totalPageCount: state.miscellaneous.get('listCount'),
        incidentNotificationInstances, forms: state.forms, 
        axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const IncidentNotificationListPage = withRouter(connect<IIncidentNotificationListPageProps, any, any>    (mapStateToProps)(IncidentNotificationListPageImpl)); 
