import * as React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; 
import { Form } from '../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Row, Col, Modal, ListGroupItem } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { connect } from 'react-redux';
import { hideCreateCloseCallFollowUpModal,showCloseCallFollowUpListModal } from '../../actions/modalActions';
import { Button } from '../reusableComponents/FormComponents/Button';
import { showAlert, isFormEmpty, uuid } from '../../utils/generalUtils'; 
import { ICloseCallFollowUp, IHistory, IForms } from '../../interfaces';
import { Async } from '../reusableComponents/Async'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Alert } from '../Alert/index'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { format } from 'date-fns'; 
import { KeyValue } from '../../utils/generalUtils'; 
import { S3_URL } from '../../constants/urls';
import noImage from '../../images/noImage.png';
import textThumb from '../../images/text-thumb.png';
import videoThumb from '../../images/video-thumb.png';
import excelThumb from '../../images/excel-thumb.jpg';
import wordThumb from '../../images/word-thumb.png';
import pdfThumb from '../../images/pdf-thumb.png';   
import { getUsersByGroup } from '../../services/userService';
import { closeCallFollowUpProcess, getAllFollowUpForms } from '../../services/closeCallService';
import { Loader } from '../reusableComponents/Loader';  
import { RiskAssessmentModel} from '../../model/RiskAssessmentModel';
import { followUpListProps, FollowUpModel } from '../../model/MechModel'; 
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
/* const queryString = require('query-string');  */

export interface ICloseCallFollowUpFormModalProps {
    showModal: any;
    userId: string;
    userUID: string;  
    followUpParentDetails: any;
    history?: IHistory;
    followUpInstances: followUpListProps[]; 
    forms: IForms;
}
export interface ICloseCallFollowUpFormModalState {
    uploadedFiles: File[];
    uploadedFileNames: string[];
    oldFileNames: string[];
    deletedFileNames: string[];
    editFileNameIndex?: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[];   
    isActualCompletionDisabled: boolean; 
    actualCompletionDate: string; 
} 
export class CloseCallFollowUpFormModalImpl extends
    React.Component<ICloseCallFollowUpFormModalProps, ICloseCallFollowUpFormModalState> {
    constructor(props: ICloseCallFollowUpFormModalProps | Readonly<ICloseCallFollowUpFormModalProps>) {
        super(props);
        this.state = { uploadedFiles: [], uploadedFileNames: [], oldFileNames: [], deletedFileNames: [], isActualCompletionDisabled: true, actualCompletionDate: 'null'  };
    } 
    usersList: any = []; 
    invalidFile: number = 0;
    promise = async () => {  
        let instance: any = this.props.showModal;  
        if(!instance['instance']['reqFrom'] || instance['instance']['reqFrom']!=="ownFollowUps") {
            let usersList = await getUsersByGroup(instance['instance']['followUpFor']['groupName'], 'includeSelf', 'closecall-followup-failed');
            this.usersList = usersList; 
        }
        this.setState({ isActualCompletionDisabled: true, actualCompletionDate: 'null' });
        if(instance['process']==='edit' ) { 
            //this.setState({actualCompletionDate: format(instance['instance']['actualCompletion'], 'yyyy-MM-dd')});
        } 
        let fileInfo = instance['instance']['imageUrls'] || [];
        let fileNames: Array<any> = [];
        if (fileInfo.length > 0) {
            for (let i = 0; i < fileInfo.length; i++) {  
                let file: Array<any> | any = fileInfo[i].split('/');
                fileNames.push(file[file.length-1]);
            }
        }
        this.setState({ 
            uploadedFileNames: fileNames, oldFileNames:fileNames
        });   
        return;
        /* if (UserModel.list().length <= 1) {
            await getAllUsers('includeSelf');
        }   
        if(this.usersList.length < 1) {
            UserModel.list().forEach(user => {
                this.usersList.push({ value: user.props.userUID, label: user.props.firstName + ' ' + user.props.lastName });
            });
            this.usersList = await sortBy(this.usersList,'alpha');
        }  */
    } 
    loadGroup = async () => { 
        let instance: any = this.props.showModal;    
        this.usersList = await getUsersByGroup(instance['instance']['followUpFor']['groupName'], 'includeSelf') || []; 
        return;
    }
    renameInputRef: any; 
    getChildContext() {
        return { formModel: 'forms.closeCallFollowUpForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    
    followUpFileData: any = []; 
    handleFileUpload = async ({ target: { files } }: any) => {
        const { uploadedFiles, oldFileNames, deletedFileNames } = this.state;  
        this.invalidFile = 0;
        let filesData: File[] = [];
        let fileNames: any = [];
        if (oldFileNames.length > 0) { 
            for (let i = 0; i < oldFileNames.length; i++) { 
                let file = oldFileNames[i].split('/'); 
                if(deletedFileNames.indexOf(file[file.length-1])===-1 ) { 
                    fileNames.push(file[file.length-1]);
                }
            }
        }
        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) { 
                filesData.push(uploadedFiles[i]);
                let fileDet = uploadedFiles[i].name.split('.');  
                const fileExtension = fileDet[fileDet.length - 1];
                //fileNames.push(uploadedFileNames[i]);
                fileNames.push(uploadedFiles[i].name.split('.')[0] + '~~' + uploadedFiles[i].lastModified + '.' + fileExtension); 
            }
        }
        /* for (let i = 0; i < files.length; i++) {
            if (uploadedFileNames.indexOf(files[i].name) < 0) {
                let fileDet = files[i].name.split('.'); 
                const fileExtension = fileDet[fileDet.length - 1]; 
                filesData.push(files[i]); 
                fileNames.push(files[i].name.split('.')[0] + '~~' + files[i].lastModified + '.' + fileExtension);
            }
        } */ 
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const fileReader = new FileReader();
                const name = uuid(); 
                filesData.push(files[i]);
                let filename = files[i]['name'].split('.');
                let followUpFileName = `${name}.${filename[filename.length-1].toLowerCase()}`; 
                let thiz = this;
                fileReader.onload =  function (e: any) {
                    thiz.followUpFileData.push({file: e.target.result, name: followUpFileName});
                }
                fileReader.readAsDataURL(files[i]); 
            }
        } 
        this.setState({
            uploadedFiles: filesData,
            uploadedFileNames: fileNames
        });
    } 
    removeItem = (index: number) => {
        const allFileNames = this.state.uploadedFileNames; 
        const oldFileNames = this.state.oldFileNames; 
        let deletedFileNames = this.state.deletedFileNames; 
        if(oldFileNames.indexOf(allFileNames[index])!==-1) {
            deletedFileNames.push(allFileNames[index]);
            allFileNames.splice(index, 1);
            this.setState({
                uploadedFileNames: allFileNames,
                deletedFileNames: deletedFileNames
            });
            return;
        }
        const allFiles: File[] = [];  
        let start = (oldFileNames.length-deletedFileNames.length); 
        let filesRemove = (index - start);  
        for (let i = 0; i < this.state.uploadedFiles.length; i++) { 
            if (i !== filesRemove) {  
                allFiles.push(this.state.uploadedFiles[i]);
            }
        }  
        allFileNames.splice(index, 1);  
        this.setState({
            uploadedFileNames: allFileNames,
            uploadedFiles: allFiles,
            deletedFileNames: deletedFileNames
        });
    } 
    setFileNames = () => {
        let { uploadedFileNames, editFileName, editFileNameIndex } = this.state;
        let newFileNames = uploadedFileNames;
        const fileExtension = editFileNameIndex && this.state.uploadedFiles[editFileNameIndex].name.split('.')[1];
        uploadedFileNames.forEach((fileName, index) => {
            if (index === editFileNameIndex && editFileName) {
                newFileNames.splice(editFileNameIndex, 1, `${editFileName}.${fileExtension}`);
                this.setState({
                    uploadedFileNames: newFileNames,
                    editFileNameIndex: -1
                });
            } else {
                this.setState({
                    editFileNameIndex: -1
                });
            }
        });
    } 
    handleSubmit = async ( { description,action, targetCompletionDate, actualCompletionDate,assignedTo,comments, status }: ICloseCallFollowUp) => {
        let instance: any = this.props.showModal; 
        let followUpParentDetails = this.props.followUpParentDetails;  
        const { state: { uploadedFiles, uploadedFileNames, editFileNameIndex }, props: { userId, userUID, forms } } = this; 
        targetCompletionDate = targetCompletionDate==='null'?'':targetCompletionDate;
        actualCompletionDate = actualCompletionDate==='null'?'':actualCompletionDate;
        const submittedValues: any = { description, action, targetCompletionDate,assignedTo : instance['instance']['followUpId'] ? instance['instance']['assignedTo'] : assignedTo }; 
        const answerValues: any = {description,action, targetDate:targetCompletionDate, actualDate:actualCompletionDate,assignedTo : instance['instance']['followUpId'] ? instance['instance']['assignedTo'] : assignedTo,comments: comments || '', status}; 
        answerValues['formType'] = instance['instance']['closeCallId']; 
        answerValues['followUpType'] = instance['instance']['followUpType'] || 'main'; 
        if(status==='Closed')
            submittedValues['actualCompletionDate'] = actualCompletionDate; 
        answerValues['followUpId'] = instance['instance']['followUpId'] || false;   
       
        if(instance['instance']['followUpId']) {
            answerValues['createdDate'] = instance['instance']['createdDate']; 
            answerValues['createdBy'] = instance['instance']['createdBy'];
        } else {
            answerValues['createdBy'] = userUID;
        }  
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'closecall-followup-failed', 'danger');
            return;
        }
        if (editFileNameIndex && editFileNameIndex >= 0) {
            showAlert('Please save changes first.', 'closecall-followup-failed', 'danger');
            return;
        }  
        await closeCallFollowUpProcess(answerValues, userId, userUID, uploadedFiles, uploadedFileNames,followUpParentDetails['requestFrom'], forms );
        this.resetField();
       
        showAlert(instance['instance']['followUpId']?'Follow-up details updated':'Follow-up details added', 'closecall-followup-success', 'success', () => { this.closeFollowUpFormModal(status) }); 
        //getAllFollowUpForms(instance['instance']['closeCallId']);
        /* switch(this.props.followUpParentDetails.requestFrom || '') {
            case 'o&iReport':  
            getAllOIForms(); break; 
            case 'officeSafetyReport': getAllOfficeSafetyForm(); break;
            case 'saftyWalkthrough': getAllCheckListForms('SafetyChecklist'); break;
            case 'closeCall': getAllCloseCallForms(); break; 
            default: break;
        } */
    }
    
    editFile = (index: number, editFileName: string) => {
        const fileName = editFileName.split('.')[0];
        this.setState({
            editFileNameIndex: index,
            editFileName: fileName
        });
        setTimeout(() => {
            if (this.renameInputRef) {
                this.renameInputRef.focus();
            }
        }, 500);
    }  
    handleRenameCancel = () => {
        this.setState({
            editFileNameIndex: -1
        });
    } 
    handleFileNameChange = (e: any, fileIndex: number) => {
        this.setState({
            editFileName: e.target.value
        });
    } 
    renderRenameFileInput = (index: number, fileName: string) => {  
        const fileExtension = this.state.uploadedFiles[index].name.split('.')[1];
        return <ListGroupItem key={index} style={listItemStyle}>
            <input
                key={index}
                className="form-control"
                style={renameInput}
                type="text"
                ref={(ref) => this.renameInputRef = ref}
                onChange={(e) => this.handleFileNameChange(e, index)}
                value={this.state.editFileName}
            />
            <span>.{fileExtension}</span>
            <i className="fa fa-check rename-file" onClick={this.setFileNames} aria-hidden="true"></i>
            <span style={{ zIndex: 1000, width: '21px', right: '3px' }} className="delete-file" onClick={this.handleRenameCancel}
            > &times; </span>
        </ListGroupItem>;
    } 
    renderListFiles = (index: number, fileName: string) => {  
        const { oldFileNames, uploadedFiles, deletedFileNames } = this.state; 
        const fileSplit = fileName.split('.'); 
        let imageThumb = '';
        let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
        switch(fileSplit[fileSplit.length-1].toLowerCase()) {
            case 'pdf': imageThumb = pdfThumb; break;
            case 'doc': case 'docx': imageThumb = wordThumb; break; 
            case 'xlx': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
            case 'txt': imageThumb = textThumb; break;
            case 'mp4': imageThumb = videoThumb; break;
            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ 
                if(oldFileNames.indexOf(fileName)!==-1) {
                    imageThumb = S3_URL +'/follow_ups/' + fileName;  
                } else {
                    let totalIndex = (oldFileNames.length-deletedFileNames.length);  
                    let currentImageIndex = index - totalIndex; 
                    if(uploadedFiles[currentImageIndex]) {
                        imageThumb = URL.createObjectURL(uploadedFiles[currentImageIndex]);
                    }
                }
                break;
            default: imageThumb = ''; this.invalidFile++; break; 
        } 
       /*  if(this.invalidFile>0) {
            showAlert(`Sorry, Some file(s) CANNOT be upload.`, 'file-danger-alert-invalidexe', 'danger',null,true,4500);   
            return;
        } */
       /*  return imageThumb && <span className="file-upload col-sm-2"><span className="images"><div key={index} style={displayImage} className="image-wrapper"> 
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.removeItem(index) }
                /> 
        <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={tooltip}> <strong>{tooltip}</strong> </Tooltip> } >
            <img
                height="75px"
                width="90px"
                style={{ padding: '5px' }}
                className="thumbnail-image" 
                src={imageThumb} alt="SafeConnect"
            />
        </OverlayTrigger>
        </div></span></span>;    */
        return <div key={index} style={displayImage} className="image-wrapper"> 
            <input
                type="button"
                value="&times;"
                className="delete-image"
                onClick={() => this.removeItem(index) }
            />
            <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={tooltip}> <strong>{tooltip}</strong> </Tooltip> } >
                <img
                    height="75px"
                    width="90px"
                    style={{ padding: '5px' }}
                    className="thumbnail-image" 
                    src={imageThumb} alt="SafeConnect" 
                />
            </OverlayTrigger>
        </div>  
    } 
    renderFiles() {
        let instance: any = this.props.showModal; 
        if(!instance) return null;  
        let imageData = instance['instance']['imageUrls'] || []; 
        if(imageData.length > 0) {
            return imageData.map((imageObj: string, index: any) => {
                let imageThumb = imageObj;  
                const fileSplit = imageObj.split('/')[imageObj.split('/').length - 1]; 
                switch( fileSplit.split('.')[fileSplit.split('.').length - 1] ) { 
                    case 'pdf': imageThumb = pdfThumb; break;
                    case 'doc': case 'docx': imageThumb = wordThumb; break; 
                    case 'xlx': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                    case 'txt': imageThumb = textThumb; break;
                    case 'mp4': imageThumb = videoThumb; break; 
                } 
                let file = imageObj.split('/')[imageObj.split('/').length-1].split('~~')[0];
                let fileExtension = imageObj.split('.')[imageObj.split('.').length-1]; 
                return <span className="file-upload linker" onClick={() => this.openFile(imageObj)}><span className="images"><div key={index} style={displayImage} className="image-wrapper"> 
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={file + '.' + fileExtension}> <strong>{file + '.' + fileExtension}</strong> </Tooltip> } >
                    <img
                        height="75px"
                        width="90px"
                        style={{ padding: '5px' }}
                        className="thumbnail-image" 
                        src={imageThumb} alt="SafeConnect"
                    />
                </OverlayTrigger>
                </div></span></span>; 
            });
        } else {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;  
        }
    }
    openFile = (imageObj: string ) => {
        window.open(imageObj, "_blank");
    } 
    handleFollowUpStatus = (value: string) => {
        if (value === 'Closed') { 
            this.setState({
                isActualCompletionDisabled: false,
            }); 
            let todayDate =  new Date();  
            let todayDateFormat = todayDate.getFullYear() + '-' +  (todayDate.getMonth()+1) + '-' + todayDate.getDate(); 
            this.setState({actualCompletionDate: format( new Date(todayDateFormat), 'yyyy-MM-dd') }); 
        } else { 
            this.resetField();
        }
    } 
    resetField() { 
        this.setState({ isActualCompletionDisabled: true, }); 
    }
    renderForm = () => {
        let instance: any = this.props.showModal; 
        if(!instance) return <div></div>;  
        const { handleFollowUpStatus, 
            state: { isActualCompletionDisabled },  } = this; 
        //instance['instance']['createdDate'] = isActualCompletionDisabled ? 'None' : instance['instance']['createdDate']; 
        return (instance['instance']['status'] && instance['instance']['status']==='Closed') || ( instance['instance']['createdBy'] !== this.props.userUID && instance['instance']['assignedTo'] !== this.props.userUID && instance['instance']['process']!=='add')
        ? <div>
            <KeyValue label="Description">{instance['instance']['description'] && instance['instance']['description'] }</KeyValue>
            <KeyValue label="Action">{instance['instance']['action'] && instance['instance']['action'] }</KeyValue>
            <KeyValue label="Target Completion">{ instance['instance']['targetCompletion']  ? format(new Date(instance['instance']['targetCompletion']), 'MM/dd/yyyy'):"" } </KeyValue>
            <KeyValue label="Actual Completion">{ instance['instance']['actualCompletion'] && instance['instance']['status']==='Closed'? format(new Date(instance['instance']['actualCompletion']), 'MM/dd/yyyy'):"" }</KeyValue>
            <KeyValue label="Assigned to">{instance['instance']['assignedToName'] && instance['instance']['assignedToName'] }</KeyValue>
            <KeyValue label="Comments">{instance['instance']['comments'] && instance['instance']['comments'] }</KeyValue>
            <KeyValue label="Status">{instance['instance']['status'] && instance['instance']['status'] }</KeyValue>
            <KeyValue label="Files">{this.renderFiles()}</KeyValue> 
          </div>
        : <Form loader={<SubmissionLoader />} model="forms.closeCallFollowUpForm" onSubmit={this.handleSubmit} >
            <AlertModal id="closecall-followup-success" />
            <Row className="show-grid" >
                <CustomLabel required="required" htmlFor="title">Description:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="description"
                        type="text"
                        placeholder="Enter description..."
                        model=".description"
                        defaultValue={instance['instance']['description'] && instance['instance']['description'] }
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel required="required" htmlFor="title">Action:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="action"
                        type="text"
                        placeholder="Enter action..."
                        model=".action"
                        defaultValue={instance['instance']['action'] && instance['instance']['action'] }
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel required="required" htmlFor="title">Target Completion:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="targetCompletionDate"
                        type="date"
                        placeholder="Choose target completion..."
                        model=".targetCompletionDate"
                        isDisabled={instance['instance']['targetCompletion'] ? true: false}
                        mindate={ instance['instance']['targetCompletion'] ? format(new Date(instance['instance']['targetCompletion']), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd') }
                        defaultValue={ instance['instance']['targetCompletion'] ? format(new Date(instance['instance']['targetCompletion']), 'yyyy-MM-dd') :"null" } 
                    />
                </CustomCol>
            </Row>
            { !isActualCompletionDisabled && <Row className="show-grid" >
                <CustomLabel required={ instance['instance']['targetCompletion'] && !isActualCompletionDisabled && "null"} htmlFor="title">Actual Completion:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="actualCompletionDate"
                        type="date"
                        placeholder="Choose actual completion..."
                        model=".actualCompletionDate"  
                        isDisabled={isActualCompletionDisabled}
                        defaultValue={this.state.actualCompletionDate || "null"} 
                        mindate={ instance['instance']['createdDate'] && instance['instance']['createdDate']!=='None'? format(new Date(instance['instance']['createdDate']), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd') }
                        maxdate={ format(new Date(), 'yyyy-MM-dd') }   
                    />
                </CustomCol>
            </Row> } 
            <Row className="show-grid" >
                <CustomLabel required="required" htmlFor="title">Assigned to : </CustomLabel>
                <CustomCol>
                    <div>  
                        { instance['instance']['assignedToName'] ? <Label htmlFor="assignedName" sm={12} style={{color:'white !important'}}>{instance['instance']['assignedToName']}</Label>  
                        :
                        <RRFInput
                            id="assignedTo"
                            type="dropdown"
                            menuItems={this.usersList}
                            placeholder="Select assigned to..."
                            model=".assignedTo" 
                            defaultValue={instance['instance']['assignedTo'] && instance['instance']['assignedTo']}
                        /> }
                    </div> 
                </CustomCol>
            </Row>  
            <Row className="show-grid" >
                <CustomLabel required="" htmlFor="title">Comments:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="comments"
                        type="textarea"
                        placeholder="Enter comments..."
                        model=".comments"
                        defaultValue={ (instance['instance']['comments'] && instance['instance']['comments']) || null}
                    />
                </CustomCol>
            </Row>
            <Row className="show-grid" >
                <CustomLabel required="" htmlFor="title">Status:</CustomLabel>
                <CustomCol>
                    <RRFInput
                        id="status"
                        type="dropdown"
                        onSelect={handleFollowUpStatus}
                        menuItems={instance['instance']['followUpId']? [ { label: 'Open', value: 'Open' }, { label: 'Closed', value: 'Closed' } ]:[ { label: 'Open', value: 'Open' } ]}
                        isDisabled={instance['instance']['status'] ?false:true}
                        placeholder="Select status..."
                        model=".status"
                        defaultValue={instance['instance']['status'] ? instance['instance']['status']:'Open' }
                    />
                </CustomCol>
            </Row>  
            <Row className="show-grid">
                <Col sm={12} xs={12} style={{textAlign: 'center' }}>
                    <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                    <Col sm={12} className="input"> 
                        <AttachementInput
                            model=".attachements"
                            multiple={true}
                            id="upload-images" 
                            uploadedImages={instance['instance'] && instance['instance']['imageUrls'] }
                        />
                    </Col>
                </Col> 
            </Row>
            {/* <Row className="show-grid">
                <CustomLabel required="" htmlFor="title">Files:</CustomLabel> 
                <div className="file-upload col-sm-3" style={{ display: 'inline-block' }} >
                    <input
                        id="files"
                        type="file"
                        multiple
                        style={fileUpload}
                        onChange={this.handleFileUpload} 
                        accept={FILESALLOWEDEXE.join(', ')} 
                    />
                    <label
                        htmlFor="files"
                        style={{ padding: '2px 15px', marginLeft: '15px', }}
                        className="upload-image">
                        Select File(s)
                    </label>
                </div> 
                <div className="col-sm-3">
                    <span className="imageDangerAlert"> <Alert className="danger-alert" id="file-danger-alert-invalidexe" /> </span>
                </div> 
            </Row> 
            { this.state.uploadedFileNames.length > 0 ? <Row className="show-grid file-upload " > <div className='images'> 
                { this.state.uploadedFileNames.map((fileName, index) => { 
                    return this.renderListFiles(index, fileName); 
                })
                }
            </div></Row> : '' }  */}

            
           {/* { this.state.uploadedFileNames.length > 0 ? <Row className="show-grid" > 
                <Col sm={12} xs={ 12 } className="input">
                    {this.state.uploadedFileNames.length > 0 ? <div style={{ border: '1px solid #ddd',borderRadius: '15px' }}>
                        <PerfectScrollbar>
                            <ListGroup className="row" style={this.state.uploadedFileNames.length > 0 ? listStyle : {}}>
                                { this.state.uploadedFileNames.map((fileName, index) => { 
                                    return this.renderListFiles(index, fileName); 
                                })
                                }
                            </ListGroup>
                        </PerfectScrollbar>
                    </div> : ''}
                </Col>
            </Row> : '' }   */}
            <Row className="modal-buttons text-center">
                <Col sm={12} xs={ 12 }  >
                    <Button style={{ border: 'none', backgroundColor: '#26a65b' }} type="submit" >
                        Submit
                    </Button>
                    <Button style={{ color: '#5479AF', border: '1px solid #5479AF', backgroundColor: 'white' }} onClick={() => this.closeFollowUpFormModal()} type="reset" >
                        Cancel
                    </Button>
                </Col>
            </Row>
            <Alert style={{ height: 'auto' }} id="closecall-followup-failed" className="danger-alert" /> 
        </Form >;
    }
    clearState = () => {
        this.followUpFileData = [];
        this.setState({
            uploadedFiles: [],
            uploadedFileNames: [],
            editFileNameIndex: -1,
            editFileName: '',
            editedFileNames: [],
            deletedFileNames: [],
            oldFileNames: [],

        });
    } 
    /* showCloseCallFollowUpList() { 
        this.clearState();
        hideCreateCloseCallFollowUpModal(); 
        showCloseCallFollowUpListModal(''); 
    } */
    
    async closeFollowUpFormModal(status: string = '') {
        this.clearState();
        let instance: any = this.props.showModal; 
        /* let followUpInstance = instance['instance'];
        followUpInstance['closeCallId'] = instance['instance']['closeCallId']; */  
        hideCreateCloseCallFollowUpModal();
        if( (instance['instance']['reqFrom'] && instance['instance']['reqFrom']==="ownFollowUps") ) { 
            if( status==='Closed' ) {
                let editInstance = instance['instance']; 
                editInstance.status = 'Closed'; 
                new FollowUpModel(editInstance).$save();
                //new FollowUpModel(editInstance).$update(editInstance.key); 
            }
            return;
        }
        if(instance['instance']['followUpType']!=='temp')
            await getAllFollowUpForms(instance['instance']['closeCallId']); 
        else 
            await getAllFollowUpForms(instance['instance']['closeCallId'], instance['instance']['followUpType']); 
    
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['instance']['closeCallId'];
        modalInstance['followUpFor'] = instance['instance']['followUpFor'];  
        let followUpInstance = this.props.followUpInstances;
        let open = 0; let closed = 0;
        followUpInstance.forEach((res) => { 
            if(res.status==='Open') {
                open++;
            } else {
                closed++;
            }
        });
        let parantId = modalInstance['followUpFor']['key'];
        switch(modalInstance['followUpFor']['formId']) {
            case 'riskAssessmentForm':  
                if(instance['instance']['followUpType']!=='temp') {
                    let riskInstance = RiskAssessmentModel.get(parantId).props;
                    riskInstance['totalClosedFollowUp'] = closed;
                    riskInstance['totalFollowUp'] = followUpInstance.length;
                    riskInstance['totalOpenFollowUp'] = open;
                    new RiskAssessmentModel(riskInstance).$save();
                } 
                break;
        }
        if(modalInstance['followUpFor']['formId']!=='riskAssessmentForm')
            showCloseCallFollowUpListModal(modalInstance);
    }
    render_Old() {  
        let instance = this.props.showModal; 
        if(!instance) return null; 
        return (
            <div>
               {/* <Gallery images={imageData ? imageData : []} /> */}
               <Async
                    identifier="CloseCallFollowUp"
                    promise={this.promise}
                    loader={<div></div>}
                    error={<div></div>}
                    content={
                        <Modal className="create-custom-notification-modal"
                            show={instance ? true : false}
                            onExited={this.clearState}
                            onHide={() => this.closeFollowUpFormModal()}>
                            <Modal.Header closeButton>  
                                <Modal.Title>{ (instance['instance']['status'] && instance['instance']['status'] === 'Closed') || ( instance['instance']['createdBy'] !== this.props.userUID && instance['instance']['assignedTo'] !== this.props.userId && instance['instance']['process']!== 'add') ?'View': ( instance['instance']['status'] === 'Open'? 'Edit' : 'Create' )  } {/* followUpTitle(instance['instance']['formType']) */} Follow-up</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.renderForm()}
                            </Modal.Body>
                        </Modal>
                    }
                />
            </div>
        );
    }
    render() { 
        let instance = this.props.showModal; 
        if(!instance) return null; 
        return (
            <div>
                <Modal className="create-custom-notification-modal " size="lg"  keyboard={false}
                    show={instance ? true : false} 
                    onExited={this.clearState}
                    onHide={() => this.closeFollowUpFormModal()}>
                    <Modal.Header closeButton>  
                        <Modal.Title>{ (instance['instance']['status'] && instance['instance']['status'] === 'Closed' ) || ( instance['instance']['createdBy'] !== this.props.userUID && instance['instance']['assignedTo'] !== this.props.userId && instance['instance']['process']!=='add') ?'View': ( instance['instance']['status'] === 'Open'? 'Edit' : 'Create' )  } {/* followUpTitle(instance['instance']['formType']) */} Follow-up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>   
                        <AlertModal id="covid19-assessment-modal" />
                        <Async
                            identifier="COVID19Assessment"
                            promise={this.promise}
                            loader={<Loader />} 
                            error={<div></div>}
                            content={ this.renderForm()} 
                        />
                    </Modal.Body>
                </Modal>
            </div>
        ); 
    }
}

const CustomLabel = ({ children, htmlFor, required }: any) => {
    return <Label className="custom-notification-label" htmlFor={htmlFor} required={required}>{children}</Label>;
};

const CustomCol = ({ children }: any) => {
    return <Col sm={6} xs={12} className="input custom-notification-input">{children}</Col>;
};

/* const fileUpload: React.CSSProperties = {
    height: '38px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
}; */

const renameInput: React.CSSProperties = {
    border: 'none',
    fontSize: '12px',
    padding: 0,
    boxShadow: 'none',
    height: 'auto',
    width: '70%',
    display: 'inline-block',
    marginRight: '5px',
};

/* const listStyle: React.CSSProperties = {
    maxHeight: '143px',
    color: 'grey',
    fontSize: '12px',
    marginBottom: 0,
}; */

const listItemStyle: React.CSSProperties = {
    outline: '1px solid #ddd',
    border: 'none', /* borderRadius: '10px' */ margin: '17px'
}; 
const displayImage: React.CSSProperties = {
    width: '90px',
    height: '75px',
    margin: '0 10px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)'
};

export function mapStateToProps(state: any) {
    return {
        showModal: state.modal.get('showCreateCloseCallFollowupModal'),
        userId: state.login.get('userId'),
        userUID: state.login.get('userUID'), 
        followUpParentDetails: state.report.get('reportDetailData'),
        followUpInstances: state.report.get('modalReportDataList'), 
        forms: state.forms, 
    };
}

export const CloseCallFollowUpFormModal = connect<ICloseCallFollowUpFormModalProps, any, any>(mapStateToProps)(CloseCallFollowUpFormModalImpl);
