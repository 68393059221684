import * as React from 'react';
import { Col, Row, Container as Grid } from 'react-bootstrap'; 
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IHistory } from '../../interfaces'; 
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Label } from '../reusableComponents/FormComponents/Label'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 

import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general'; 
import { isFormEmpty, showAlert } from '../../utils/generalUtils';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Form } from '../reusableComponents/FormComponents/Form'; 
import { submitChecklistName } from '../../services/checklistService';

export interface ICreateChecklistProps { 
    onSubmit?: (data: Object) => void;
    history?: IHistory;
    onCancel?: () => void;
    checklistName?: string;
    userUID: string;
}

export interface ICreateChecklistState { 
}

export class CreateChecklistImpl extends React.PureComponent<ICreateChecklistProps, ICreateChecklistState> {

    constructor(props: ICreateChecklistProps | Readonly<ICreateChecklistProps>) {
        super(props);
        this.state = { };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };

    getChildContext() {
        return { formModel: 'forms.checklistFormName' };
    }
    handleFormSubmit = async ({ checklistName }: ICreateChecklistProps) => { 
        const submittedValues = { checklistName }; 
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'create-checklist', 'danger');
            return;
        } 
        return checklistName && submitChecklistName(submittedValues, this.props.history); 
        
    }

    renderContent = () => { 
        return (
            <Grid className="oi-form" style={{ width: '100%' }}>
            <div>
                <Row className="show-grid">
                    <Label required htmlFor="checklistName">Checklist Name:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="checklistName"
                            type="text"
                            placeholder="Enter checklist name..."
                            model=".checklistName" 
                        />
                    </Col>
                </Row> 

                <Row className="show-grid">
                    <div className="form-button" style={{ marginTop: '50px' }} >
                        <Button
                            style={{
                                color: '#5479AF',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white'
                            }}
                            onClick={() => this.props.history?.goBack() }
                            redirectTo="home" type="reset" >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26a65b'
                            }} > 
                                <i className="fa fa-plus" aria-hidden="true"></i> 
                            &nbsp;
                            Add
                        </Button>
                    </div>
                </Row>
            </div>
            </Grid>
        );
    }
 
    render() {
        return (
            <BasePage>
                <Grid className="checklist-form" style={{ width: '100%', padding: '20px', }}>
                    <Title text="Add Checklist" /> 
                    <Form loader={<SubmissionLoader />} model="forms.checklistFormName" onSubmit={this.handleFormSubmit}>
                        <AlertModal id="create-checklist-modal" />
                        <Alert id="create-checklist" className="danger-alert" /> 
                        {this.renderContent()}
                    </Form>
                </Grid>
            </BasePage>
        );
    }
}
 
export function mapStateToProps(state: any) {
    const userUID = state.login.get('userUID');
    return {
        userUID
    };
}
export const CreateChecklist = withRouter(connect<ICreateChecklistProps, any, any>(mapStateToProps)(CreateChecklistImpl)); 