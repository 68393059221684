import { Alert } from '../../Alert';
import { actions } from 'react-redux-form';
import { dispatch } from '../../../utils/generalUtils';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Title } from '../../reusableComponents/Title';
import { Form } from '../../reusableComponents/FormComponents/Form'; 
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { getGoldenRules, submitGoldenRules } from '../../../services/miscellaniousServices';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { BasePage } from '../../reusableComponents/BasePage'; 
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { IHistory, IRules } from '../../../interfaces';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './goldenRules.scss';
import { withRouter } from 'react-router-dom'; 
import { UserModel } from '../../../model/UserModel'; 
 
export interface IGoldenRulesProps {
    show?: boolean;
    showModal?: boolean;
    history?: IHistory;
    showEditForm?: boolean;
    instance: any; 
    referenceFiles: any;
}

export interface IGoldenRulesState {
    showModal: boolean;
    editRules: boolean;
    totalRules: IRules[];
    newRules: IRules[];
}

export class GoldenRulesImpl extends React.Component<IGoldenRulesProps, IGoldenRulesState> {

    constructor(props: IGoldenRulesProps) {
        super(props);
        this.state = { showModal: props.show || false, editRules: false, totalRules: [], newRules: [] };
    } 

    promise = async () => {
        const goldenRules = await getGoldenRules();
        goldenRules.forEach((ruleData, index) => {  
            dispatch(actions.change(`forms.goldenRules.rule[${index}]`, ruleData.rule));
            dispatch(actions.change(`forms.goldenRules.priority[${index}]`, +ruleData.priority));
        });
        this.setState({
            totalRules: goldenRules,
            newRules: goldenRules,
            editRules: false
        });
        return null;
    }
    getChildContext() {
        return { formModel: 'forms.goldenRules' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    toggleRulesForm = () => {
        const { editRules, newRules, totalRules } = this.state;
        if (editRules) {
            this.setState({
                totalRules: newRules
            });
        }
        this.setState({
            editRules: !editRules
        });
        totalRules.forEach((ruleData, index) => {
            dispatch(actions.change(`forms.goldenRules.rule[${index}]`, ruleData.rule));
            dispatch(actions.change(`forms.goldenRules.priority[${index}]`, +ruleData.priority));
        });
    }

    toggleDropdownVisibility = () => {
        this.setState({
            showModal: false
        });
    }

    incrementRows = () => {
        const ruleNum = this.state.totalRules.length;
        this.setState({
            totalRules: [...this.state.totalRules, { ruleNum: ruleNum, rule: '', priority: '' }]
        });
    }

    decrementRow = (index: number) => {
        const { totalRules } = this.state;
        dispatch(actions.remove(`forms.goldenRules.rule`, index));
        dispatch(actions.remove(`forms.goldenRules.priority`, index));
        let newArray = totalRules.filter(e => e !== totalRules[index]);
        this.setState({
            totalRules: newArray
        });
    }
    /* isNumeric = (data: number) => {
        let count = this.countDecimals(e.target.value);
        if (count === 3) {
          e.preventDefault();
        }
      }
 */
    renderDynamicTable = () => {
        const { totalRules } = this.state; 
        if(totalRules.length>0) {
            return [totalRules.map((ruleData, index) => {
                return <Row key={index} className="show-grid">
                    <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={9} sm={9} className="input">
                        <RRFInput
                            type="text"
                            model={`.rule[${index}]`}
                            /* onChange={this.isNumeric} */
                        />
                    </Col>
                    <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                        <RRFInput
                            type="number"
                            model={`.priority[${index}]`} pattern="[0-9]*"
                        />
                    </Col>
                    <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={1} sm={1} className="input">
                        <i className="fa fa-trash-o" onClick={() => this.decrementRow(index)} aria-hidden="true"></i>
                    </Col>
                </Row>;
            }),
            <Row key={totalRules.length} className="show-grid">
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={9} sm={9} className="input">
                    <RRFInput
                        type="text"
                        model={`.rule[${totalRules.length}]`}
                    />
                </Col> 
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                    <RRFInput
                        type="number"
                        model={`.priority[${totalRules.length}]`} pattern="[0-9]*"
                    />
                </Col>
            </Row>];
        }
        return <Row key={0} className="show-grid">
            <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={9} sm={9} className="input">
                <RRFInput
                    type="text"
                    model={`.rule[${0}]`}
                />
            </Col> 
            <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                <RRFInput
                    type="number"
                    model={`.priority[${0}]`}
                />
            </Col>
        </Row>;
    }

    handleSubmit = async (goldenRules: any) => {
        const { history } = this.props; 
        const { response, updatedRules } = await submitGoldenRules(goldenRules, history);
        if(response) {
            this.setState({
                totalRules: updatedRules || [],
                newRules: updatedRules || [],
                editRules: false
            }); 
            return response;
        }
    }
 

    renderContent = () => { 
        const { totalRules, editRules, newRules } = this.state;
        if (!totalRules) {
            return <div></div>;
        }
        if (editRules) {
            return <div> 
                <Form loader={<SubmissionLoader />} onSubmit={this.handleSubmit} model="forms.goldenRules"> 
                    <Row key={-1} className="show-grid">
                        <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px' }} xs={9} sm={9} className="input">
                            Rules
                        </Col>
                        <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px' }} xs={2} sm={2} className="input">
                            Rank
                        </Col>
                        <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', fontSize: '16px' }} xs={1} sm={1} className="input">
                           Action
                        </Col>
                    </Row>
                    {this.renderDynamicTable()}
                    <Alert id="golden-rules-failed" className="danger-alert" />
                    <div className="table-buttons">
                        <button
                            className="add-row"
                            type="button"
                            onClick={this.incrementRows}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            &nbsp;Add Another Rule
                        </button>
                    </div>
                    <div style={{ textAlign: 'center' }} className="edit-button form-button"> 
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white',
                        }} onClick={async () => {
                            this.toggleRulesForm();
                        }} type="reset">Cancel</Button>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
            </div>
        }
        
        return <div className="rules-list">
            {newRules.length <= 0 ? 'No Rules Found' : newRules.map(
                (ruleData, index) => {
                    return <p key={index} >{index+1}. {ruleData.rule} </p>;
                }
            )} 
            <Alert id="golden-rules-success" className="success-alert" />
            <div className="table-buttons"> 
                <button
                    className="add-row"
                    type="button"
                    onClick={() => this.toggleRulesForm()}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    &nbsp;Add Another Rule
                </button>
            </div>
        </div> 
    }

    render() {
        return (
            <BasePage>
                <div className="golden-rules" >  
                    <Title text="Golden Rules" />
                    <Async
                        identifier="GoldenRules"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </div>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    let userId = state.login.get('userId');
    const instance = UserModel.get(userId);
    return {
        instance,
        referenceFiles: state.miscellaneous.get('referenceFiles')
    };
}

export const GoldenRulesPage =
    withRouter(connect<IGoldenRulesProps, any, any>(mapStateToProps)(GoldenRulesImpl as any));
