import { Async } from '../reusableComponents/Async';
import * as React from 'react';
import { Title } from '../reusableComponents/Title';
import { BasePage } from '../reusableComponents/BasePage';
import '../HomePage/homePage.scss'; 
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { Container as Grid, Row, Col} from 'react-bootstrap'; 
import { deleteChecklistName, getAllCheckListNames } from '../../services/checklistService';
import managerSafetyIcon from '../../images/ManagerSafetyWlk.png';
import supervisorSafetyIcon from '../../images/SupervisorSafetWalk.png'; 
import { ChecklistNameModel } from '../../model/ChecklistModel'; 
import './Auditlist.scss'; 
import { UserModel } from '../../model/UserModel';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Alert } from '../Alert';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Modal } from 'react-bootstrap';
import { Button } from '../reusableComponents/FormComponents/Button';

export interface IAuditlistHomeProps {
    history?: IHistory; 
    checkListNameInstances: ChecklistNameModel[];
}

export class AuditListHomeImpl extends React.PureComponent<IAuditlistHomeProps, {isSubmitting: boolean, deleteChecklistId: any}> {
    constructor(props: IAuditlistHomeProps | Readonly<IAuditlistHomeProps>) {
        super(props); 
        this.state = {isSubmitting: false, deleteChecklistId: false}
    }

    promise = async () => {
        await getAllCheckListNames('auditChecklistForm');
        return null;
    }

    renderContent = () => {
            const { checkListNameInstances } = this.props;
            let insLength = checkListNameInstances.length ;
            let gridCol = insLength<5?6:insLength%3===0?4:insLength%4===0?3:4; 
            return (
            <Grid style={{ width: '100%' }} className="homepage checklistHome">
                <Title text="Audit & Inspections"></Title> 
                {this.state.isSubmitting && <SubmissionLoader />} 
                {this.deleteConfirmation()}
                {  <button className="add-new-report"
                        onClick={() => { this.props.history?.push('/create/audit'); }}
                    >
                        +
                </button> }
                <div className="links-container">
                    <Row className="show-grid">  
                        { checkListNameInstances && this.getInstance(gridCol) } 
                    </Row>  
                    <AlertModal id="checklist-modal" />
                    <Alert id="checklist-alert" className="danger-alert" /> 
                </div>
            </Grid >
        );
    }
    deleteConfirmation = () => {  
        return (
            <Modal className="confirmation-modal" show={this.state.deleteChecklistId} onHide={() => this.setState({deleteChecklistId: false})}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }}>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this checklist?
                <div className="modal-buttons row">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={() => this.state.deleteChecklistId && this.deleteChecklistName(this.state.deleteChecklistId)}
                        >Yes</Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => this.setState({deleteChecklistId: false})}
                        >No</Button>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }
    deleteChecklistName = async (id: string) => {
        this.setState({deleteChecklistId: false}); this.setState({isSubmitting: true}); await deleteChecklistName(id); await getAllCheckListNames('auditChecklistForm'); this.setState({isSubmitting: false}); 
    }
    getInstance(gridCol: any) {
        const { checkListNameInstances } = this.props;
        let supervisorIcons = gridCol===6?[0,3,4, 7,8, 11,12, 15,16 ]:gridCol===3?[1,2,4,6,8,10,12,14,16,18,20,22,24]:[1,3,5,7,9,11,13,15,17,19]; 
        if(checkListNameInstances.length>0) {
            return checkListNameInstances.map((instance, index) => {  
                return (<Col sm={gridCol} xs={12} key={index}
                    className=" checklist-menu"
                    title={`Click to fill out ${instance.props.checklistName}`}
                     >
                        <div onClick={() => this.props.history?.push(`/audit-form/${instance.props.id}`)}>
                            <img className="icon" src={supervisorIcons.indexOf(index)!==-1?managerSafetyIcon:supervisorSafetyIcon} alt="SafeConnect" />
                            <span className="text"> { instance.props.checklistName}</span>
                        </div>
                        { UserModel.checkUserAccess('checklist_name_delete') && <div className="checklist-delete" onClick={async() => { this.setState({deleteChecklistId: instance.props.id}); } }><i className="fa fa-trash" aria-hidden="true"></i></div> }
                </Col>);
            })
        } else {
            return (<Col sm={12} xs={12} key={22} style={{textAlign: 'center'}}>
                <label> No checklist available. </label>
            </Col>);
        }
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="checklist"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) { 
    const checkListNameInstances = ChecklistNameModel.list(); 
    return { 
        checkListNameInstances
    };
}

export const AuditlistHome = withRouter(connect<IAuditlistHomeProps, any, any>(mapStateToProps)(AuditListHomeImpl));
