import { Loader } from '../reusableComponents/Loader';
import { BasePage } from '../reusableComponents/BasePage';
import { ProjectModel, IProjectModelProps } from '../../model/ProjectModel';
import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { getAllProjects, updateProjectForm } from '../../services/projectService';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Title } from '../reusableComponents/Title';
import { ProjectForm } from './ProjectForm';
import { connect } from 'react-redux';
import { IHistory } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { isFormEmpty, showAlert } from '../../utils/generalUtils';
import { format } from 'date-fns';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { GroupModel } from '../../model/GroupModel';
import { getAllGroups } from '../../services/groupService';

export interface IUpdateFormProps {
    instance: ProjectModel;
    match: {
        params: {
            id: string;
        }
    };
    history: IHistory;
}

export class UpdateFormImpl extends React.PureComponent<IUpdateFormProps, {}> {
    constructor(props: IUpdateFormProps | Readonly<IUpdateFormProps>) {
        super(props);
    }

    promise = async () => {
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        return null;
    }

    renderUpdateProjectForm = () => {
        const { instance } = this.props;
        if (!instance) {
            return <div></div>;
        }
        const { props: { projectName, projectDesc, groupId } } = instance;
        return (
            <ProjectForm
                onCancel={() => this.props.history.push('/home')}
                projectName={projectName}
                projectDesc={projectDesc}
                groups={groupId.toString()}
                instance={instance}
                onSubmit={this.handleSubmit}
            />
        );
    }

    handleSubmit = (submittedValues: IProjectModelProps) => {
        const { history, instance: { props: { projectId } } } = this.props;
        submittedValues.projectId = projectId;
        submittedValues.dateUpdated = format(new Date(), 'yyyy-MM-dd');
        if (isFormEmpty({
            projectName: submittedValues.projectName,
            projectDesc: submittedValues.projectDesc
        })) {
            showAlert(EMPTY_FORM_MESSAGE, 'update-project', 'danger');
            return;
        }
        return updateProjectForm(submittedValues, history, projectId);
    }

    render() {
        return (
            <BasePage>
                <Grid className="update-project" style={{ width: '100%' }}>
                    <Title text="Update Team" />
                    <AlertModal id="update-project-modal" />
                    <Alert className="danger-alert" id="update-project" />
                    <Async
                        identifier="UpdateProjectForm"
                        promise={this.promise}
                        error={<ErrorPage />}
                        loader={<Loader />}
                        content={this.renderUpdateProjectForm()}
                    />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const instance = ProjectModel.get(ownProps.match.params.id, state);
    return {
        instance,
    };
}
export const UpdateForm = connect<{}, {}, IUpdateFormProps>(mapStateToProps)(UpdateFormImpl);
