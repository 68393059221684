import * as React from 'react';
import { Modal } from 'react-bootstrap'; 
import { connect } from 'react-redux';
import './printPreviewModal.scss';
import { hidePrintPreviewModal } from '../../../actions/modalActions'; 
import { SubmissionLoader } from '../SubmissionLoader';
import { Async } from '../Async';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import html2canvas from 'html2canvas';
declare var require: any;
const pdfmake = require('pdfmake/build/pdfmake.js');

export interface IPrintPreviewModalProps {
    id?: string;
    modalID?: string;
    onClickYes?: (ref: any) => void;
    ref?: string;
    className?: string;
    style?: React.CSSProperties;
    isImageLoading?: boolean;
    isDetailsPage?: boolean;
    fileName?: string;
    children: any;
}

export interface IPrintPreviewModalState {
    isPreviewLoading: boolean;
}

export class PrintPreviewModalImpl extends React.Component<IPrintPreviewModalProps, IPrintPreviewModalState> {
    constructor(props: IPrintPreviewModalProps | Readonly<IPrintPreviewModalProps>) {
        super(props);
    }

    static defaultProps = {
        isDetailsPage: false
    };

    /**
     * TODO: Remove this DOM manipulation if any other way to assign the scaled child's height to it's parent.
     */
    componentDidUpdate() {
        const { isDetailsPage } = this.props;
        const height = document.getElementById('print');
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            let printOuter = document.getElementById('print-outer');
            if (isDetailsPage && height && printOuter) {
                printOuter.style.height =
                    (height.getBoundingClientRect().height) + 'px';
            }
            if (!isDetailsPage && height && printOuter) {
                printOuter.style.height =
                    (height.getBoundingClientRect().height) + 250 + 'px';
            }
        }
    }

    refVal: any;

    printOuter: any;

    static Filter: any;

    getFileName = () => {
        const { fileName } = this.props;
        if (window.navigator.userAgent.indexOf('Edge') > -1) {
            return `${fileName}.pdf`;
        }
        return fileName;
    }

    handlePrint = () => {
        const { getFileName } = this;
        //const ref = document.getElementById('print');
        let ref: any = document.querySelector('#print');
        if(!ref) { 
            setSuccess('PrintPreviewLoader');
            hidePrintPreviewModal();
            return;
        }
       /*  html2canvas(ref).then(canvas => {
            document.body.appendChild(canvas)
        }); */
        //ref.style.backgroundColor = 'white';
        setLoading('PrintPreviewLoader'); 
        html2canvas(ref, {
            allowTaint: false
        }).then(function (canvas) {  
            const dataURL = canvas.toDataURL();
            const docDefinition = {
                pageSize: { width: ref.clientWidth, height: ref.clientHeight },
                content: [ 
                    {
                        image: dataURL,
                        fit: [ref.clientWidth - (ref.clientWidth/10) , ref.clientHeight - (ref.clientWidth/10)]
                        //fit: [480, (ref.clientHeight - 200)], 
                    }
                ],
                /* pageBreakBefore: function(currentNode: any) {
                    console.log(currentNode, currentNode.style)
                    return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
                },    */
            };
            
            pdfmake.createPdf(docDefinition).download(getFileName());
            setSuccess('PrintPreviewLoader');
            hidePrintPreviewModal();
        }).catch(function (error) {
            console.error(error);
        });
        // this.props.onClickYes(node);
    }

    renderModal = (showLoader: boolean) => {
        const { id, isImageLoading, modalID, className, style } = this.props;
        if (modalID !== id) {
            return <div ref="print"></div>;
        }
        const newStyle = Object.assign({
            margin: 'auto',
            padding: '0px 10px'
        }, (style ? style : ''));
        return (
            <div>
                <Modal size="lg"
                    className="print-preview-outer" show={true} onHide={() => hidePrintPreviewModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: 'yellow' }}>Print Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            className={`print-preview ${className}`}
                            style={newStyle}
                            id="print-outer"
                        >
                            {this.props.children}
                        </div>
                        <div className="print-button">
                            <button
                                disabled={isImageLoading}
                                className={`${isImageLoading ? 'button-disabled' : 'button'}`}
                                onClick={this.handlePrint}
                            >
                                Export
                   </button>
                        </div>
                    </Modal.Body>
                    {showLoader ? <SubmissionLoader /> : ''}
                </Modal >
            </div>
        );
    }

    render() {
        return <Async
            identifier="PrintPreviewLoader"
            promise={() => setSuccess('PrintPreviewLoader')}
            error={< div ></div>}
            loader={this.renderModal(true)}
            content={this.renderModal(false)}
        />;
    }
}

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('previewModalID'),
        isImageLoading: state.miscellaneous.get('isImageLoading')
    };
}

export const PrintPreviewModal = connect<{}, {}, IPrintPreviewModalProps>(mapStateToProps)(PrintPreviewModalImpl);
