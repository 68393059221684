import React, {Component} from "react";
import {BasePage} from "../../reusableComponents/BasePage";
import {Async} from "../../reusableComponents/Async";
import {ErrorPage} from "../../reusableComponents/ErrorPage";
import {Loader} from "../../reusableComponents/Loader";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {SubmissionLoader} from "../../reusableComponents/SubmissionLoader";
import {Form} from "../../reusableComponents/FormComponents/Form";
import {Title} from "../../reusableComponents/Title";
import {Col, Row} from "react-bootstrap";
import {Label} from "../../reusableComponents/FormComponents/Label";
import {RRFInput} from "../../reusableComponents/FormComponents/RRFInput";
import './preExcavationChecklist.scss'
import Select from "react-select";
import * as displayText from "./language.json";
import {Button} from "../../reusableComponents/FormComponents/Button";
import {IForms, IHistory} from "../../../interfaces";
import {
    IPreExcavationChecklistModelProps, PreExcavationChecklistModel
} from "../../../model/PreExcavationChecklistModel";
import PropTypes from "prop-types";
import {dispatch, isFormEmpty, showAlert} from "../../../utils/generalUtils";
import {actions} from "react-redux-form";
import {EMPTY_FORM_MESSAGE} from "../../../constants/general";
import {Alert} from "../../Alert";
import {AlertModal} from "../../reusableComponents/ModalComponents/AlertModal";
import {getFormData, submitTexas11Forms} from "../../../services/texas811Service";
import {format} from "date-fns";


interface IPreExcavationCheckListProps {
    editId: string;
    history?: IHistory;
    forms: IForms;
    editInstance: PreExcavationChecklistModel
}

interface IPreExcavationCheckListState {
    displayText: Record<any, any>
    language: string;
}

export class PreExcavationCheckListFormImpl extends Component<IPreExcavationCheckListProps, IPreExcavationCheckListState> {
    constructor(props: IPreExcavationCheckListProps) {
        super(props);
        this.state = {
            displayText: !props.editInstance ? displayText.eng : props.editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.editInstance ? 'eng' : props.editInstance.props.language === 'spa' ? 'spa' : 'eng'
        }
    }

    getChildContext() {
        return {formModel: 'forms.preExcavationChecklist'};
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    applyDefaultValues = (props: any) => {
        const {
            editInstance: {
                props: {
                    answers: {
                        isGasUnmarked,
                        isElectricUnmarked,
                        isTelephoneUnMarked,
                        isCableTVUnmarked,
                        isWaterUnmarked,
                        isSewerUnmarked,
                        isOneCallNotifiedOnUnmarked
                    }
                }
            }
        } = props
        dispatch(actions.change(`forms.preExcavationChecklist.isGasUnmarked`, isGasUnmarked === "1"));
        dispatch(actions.change(`forms.preExcavationChecklist.isElectricUnmarked`, isElectricUnmarked === '1'));
        dispatch(actions.change(`forms.preExcavationChecklist.isTelephoneUnMarked`, isTelephoneUnMarked === '1'));
        dispatch(actions.change(`forms.preExcavationChecklist.isCableTVUnmarked`, isCableTVUnmarked === '1'));
        dispatch(actions.change(`forms.preExcavationChecklist.isWaterUnmarked`, isWaterUnmarked === '1'));
        dispatch(actions.change(`forms.preExcavationChecklist.isSewerUnmarked`, isSewerUnmarked === '1'));
        dispatch(actions.change(`forms.preExcavationChecklist.isOneCallNotifiedOnUnmarked`, isOneCallNotifiedOnUnmarked === '1'));
        const {editInstance}: any = this.props;
        this.setState({
            displayText: !editInstance ? displayText.eng : editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !editInstance ? 'eng' : editInstance.props.language === 'spa' ? 'spa' : 'eng'
        });
    }

    promise = async () => {
        if (this.props.editId && !this.props.editInstance) {
            await getFormData('preExcavationChecklist', this.props.editId).then((res) => {
                this.applyDefaultValues(this.props)
            })
        } else if (this.props.editInstance) {
            setTimeout(() => {
                this.applyDefaultValues(this.props)
            }, 100);
        }
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    }

    onChangeUnmarkedUtilities = (value: any) => {
        dispatch(actions.change(`forms.preExcavationChecklist.isGasUnmarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isElectricUnmarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isTelephoneUnMarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isCableTVUnmarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isWaterUnmarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isSewerUnmarked`, false));
        dispatch(actions.change(`forms.preExcavationChecklist.isOneCallNotifiedOnUnmarked`, false));
    }

    onFormSubmit = async (values: IPreExcavationChecklistModelProps) => {
        if (isFormEmpty(values, ['attachments', 'id', 'editId', 'deletedFiles', 'isGasUnmarked', 'isElectricUnmarked', 'isTelephoneUnMarked', 'isCableTVUnmarked', 'isWaterUnmarked', 'isSewerUnmarked', 'isOneCallNotifiedOnUnmarked'])) {
            showAlert(EMPTY_FORM_MESSAGE, 'pre-excavation-checklist-form', 'danger');
            return
        }

        await submitTexas11Forms(JSON.parse(JSON.stringify(values)), 'preExcavationChecklist', this.state.language, this.props.history, this.props.forms, this.props.editId);
    }

    renderContent = () => {
        const {displayText, language} = this.state;
        const {editInstance, forms, history} = this.props;
        let answer: any = {};
        if (editInstance && editInstance.props && editInstance.props.answers) {
            answer = editInstance.props.answers;
        }
        return <Form loader={<SubmissionLoader/>} model="forms.preExcavationChecklist" className="preExcavationChecklist" onSubmit={this.onFormSubmit}>
            <AlertModal id="preExcavationChecklist-form-modal"/>
            <Alert className="danger-alert" id="preExcavationChecklist-form-failed"/>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <fieldset>
                <Row className="show-grid">
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="crewForeman">{displayText.cfName}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="crewForeman"
                                type="text"
                                placeholder=""
                                model=".crewForeman"
                                defaultValue={answer.crewForeman || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="oneCallTicketNo">{displayText.oneCallTicketNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="oneCallTicketNo"
                                type="text"
                                placeholder=""
                                model=".oneCallTicketNo"
                                defaultValue={answer.oneCallTicketNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="contactNo">{displayText.contactNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="contactNo"
                                type="text"
                                placeholder=""
                                model=".contactNo"
                                defaultValue={answer.contactNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="excavationDate">{displayText.date}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="excavationDate"
                                type="date"
                                placeholder="HH:MM"
                                model=".excavationDate"
                                defaultValue={answer.excavationDate ? format(new Date(answer.excavationDate), 'yyyy-MM-dd') : ''}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid top-info'}>
                    <p>{displayText.boxedInfoPara1}</p>
                    <p className={'centered-text'}>{displayText.boxedInfoPara2}</p>
                </Row>
                <Row className={'show-grid'}>
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest1OneCallTicketCovers}</p>
                    <Col sm={12} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isVerifiedWorkScope">{displayText.sowVerified}</label>
                            <RRFInput
                                id="isVerifiedWorkScope"
                                type="checkbox"
                                isDisabled={false}
                                model=".isVerifiedWorkScope"
                                defaultValue={(answer.isVerifiedWorkScope && answer.isVerifiedWorkScope === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={12} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isVerifiedWorkBeginDay">{displayText.wtbVerified}</label>
                            <RRFInput
                                id="isVerifiedWorkBeginDay"
                                type="checkbox"
                                model=".isVerifiedWorkBeginDay"
                                defaultValue={(answer.isVerifiedWorkBeginDay && answer.isVerifiedWorkBeginDay === '1') || false}
                            />
                        </Col>
                    </Col>
                    <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest2UtilitiesObserved}</p>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isGasMarked">{displayText.gas}</label>
                        <RRFInput
                            id="isGasMarked"
                            type="checkbox"
                            model=".isGasMarked"
                            defaultValue={(answer.isGasMarked && answer.isGasMarked === '1') || false}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isElectricMarked">{displayText.electric}</label>
                        <RRFInput
                            id="isElectricMarked"
                            type="checkbox"
                            model=".isElectricMarked"
                            defaultValue={(answer.isElectricMarked && answer.isElectricMarked === '1') || false}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isTelephoneMarked">{displayText.telephone}</label>
                        <RRFInput
                            id="isTelephoneMarked"
                            type="checkbox"
                            model=".isTelephoneMarked"
                            defaultValue={(answer.isTelephoneMarked && answer.isTelephoneMarked === '1') || false}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isCableTVMarked">{displayText.ctv}</label>
                        <RRFInput
                            id="isCableTVMarked"
                            type="checkbox"
                            model=".isCableTVMarked"
                            defaultValue={(answer.isCableTVMarked && answer.isCableTVMarked === '1') || false}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isWaterMarked">{displayText.water}</label>
                        <RRFInput
                            id="isWaterMarked"
                            type="checkbox"
                            model=".isWaterMarked"
                            defaultValue={(answer.isWaterMarked && answer.isWaterMarked === '1') || false}
                        />
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isSewerMarked">{displayText.sewer}</label>
                        <RRFInput
                            id="isSewerMarked"
                            type="checkbox"
                            model=".isSewerMarked"
                            defaultValue={(answer.isSewerMarked && answer.isSewerMarked === '1') || false}
                        />
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Col sm={12} className="input">
                        <label style={{color: "yellow", marginTop: '15px', paddingLeft: 0}} className="checkbox-label"
                               htmlFor="isUnmarkedUtilities">{displayText.quest3UnmarkedUtilities}</label>
                        <RRFInput
                            id="isUnmarkedUtilities"
                            type="checkbox"
                            model=".isUnmarkedUtilities"
                            onChange={this.onChangeUnmarkedUtilities}
                            defaultValue={(answer.isUnmarkedUtilities && answer.isUnmarkedUtilities === '1') || false}
                        />
                    </Col>
                </Row>
                {(forms.preExcavationChecklist.isUnmarkedUtilities) && [<p key={3.1}
                                                                           style={{
                                                                               color: "white",
                                                                               marginLeft: '30px'
                                                                           }}>{displayText.questPleaseIdentify}</p>,
                    <Row key={3.2} className={'show-grid'}>
                        <Col sm={4} className="input">
                            <label className="checkbox-label" htmlFor="isGasUnmarked">{displayText.gas}</label>
                            <RRFInput
                                id="isGasUnmarked"
                                type="checkbox"
                                model=".isGasUnmarked"
                                defaultValue={forms.preExcavationChecklist.isGasUnmarked}
                            />
                        </Col>
                        <Col sm={4} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isElectricUnmarked">{displayText.electric}</label>
                            <RRFInput
                                id="isElectricUnmarked"
                                type="checkbox"
                                model=".isElectricUnmarked"
                                defaultValue={forms.preExcavationChecklist.isElectricUnmarked}
                            />
                        </Col>
                        <Col sm={4} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isTelephoneUnMarked">{displayText.telephone}</label>
                            <RRFInput
                                id="isTelephoneUnMarked"
                                type="checkbox"
                                model=".isTelephoneUnMarked"
                                defaultValue={forms.preExcavationChecklist.isTelephoneUnMarked}
                            />
                        </Col>
                        <Col sm={4} className="input">
                            <label className="checkbox-label" htmlFor="isCableTVUnmarked">{displayText.ctv}</label>
                            <RRFInput
                                id="isCableTVUnmarked"
                                type="checkbox"
                                model=".isCableTVUnmarked"
                                defaultValue={forms.preExcavationChecklist.isCableTVUnmarked}
                            />
                        </Col>
                        <Col sm={4} className="input">
                            <label className="checkbox-label" htmlFor="isWaterUnmarked">{displayText.water}</label>
                            <RRFInput
                                id="isWaterUnmarked"
                                type="checkbox"
                                model=".isWaterUnmarked"
                                defaultValue={forms.preExcavationChecklist.isWaterUnmarked}
                            />
                        </Col>
                        <Col sm={4} className="input">
                            <label className="checkbox-label" htmlFor="isSewerUnmarked">{displayText.sewer}</label>
                            <RRFInput
                                id="isSewerUnmarked"
                                type="checkbox"
                                model=".isSewerUnmarked"
                                defaultValue={forms.preExcavationChecklist.isSewerUnmarked}
                            />
                        </Col>
                    </Row>,
                    <Row key={3.3} className={'show-grid'}>
                        <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.questNotifiedUnmarkedUtility}</span>&nbsp;&nbsp;
                            <RRFInput
                                id="isOneCallNotifiedOnUnmarked"
                                type="checkbox"
                                model=".isOneCallNotifiedOnUnmarked"
                                defaultValue={forms.preExcavationChecklist.isOneCallNotifiedOnUnmarked}
                            />
                        </Col>
                    </Row>]}
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest4IsPhotographed}</p>
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.questPhotoGraphedPriorExcavation}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isSitePhotographed"
                            type="checkbox"
                            model=".isSitePhotographed"
                            defaultValue={(answer.isSitePhotographed && answer.isSitePhotographed === '1') || false}
                        />
                    </Col>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.questPhotoGraphedMarkings}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isMarkingsPhotographed"
                            type="checkbox"
                            model=".isMarkingsPhotographed"
                            defaultValue={(answer.isMarkingsPhotographed && answer.isMarkingsPhotographed === '1') || false}
                        />
                    </Col>
                </Row>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest5CrewMemberAdvised}</p>
                <h6 style={{color: "red", textAlign: 'center'}}>{displayText.respectTheMarks}</h6>
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.crewAdvised}</span>&nbsp;&nbsp;
                        <RRFInput
                            id="isAdvised18InchHandDigRule"
                            type="checkbox"
                            model=".isAdvised18InchHandDigRule"
                            defaultValue={(answer.isAdvised18InchHandDigRule && answer.isAdvised18InchHandDigRule === '1') || false}
                        />
                    </Col>
                </Row>
                <p style={{color: "yellow", marginTop: '15px'}}>{displayText.quest6TestHolesEvery80T060Feet}</p>
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span style={{
                            color: "white",
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: '30px'
                        }}>{displayText.quest6Option}</span>&nbsp;&nbsp;
                        <RRFInput
                            id=".isAdvisedToTestDugHoles"
                            type="checkbox"
                            model=".isAdvisedToTestDugHoles"
                            defaultValue={(answer.isAdvisedToTestDugHoles && answer.isAdvisedToTestDugHoles === '1') || false}
                        />
                    </Col>
                </Row>
                <hr/>
                <span style={{color: "white"}}>
                      <h5 style={{textAlign: 'center'}}>{displayText.advise1Title}</h5>
                    <ul>
                        <li>{displayText.advise11}</li>
                        <li>{displayText.advise12}</li>
                        <li>{displayText.advise13}</li>
                    </ul>
                </span>
                <span style={{color: "white"}}>
                      <h5 style={{textAlign: 'center'}}>{displayText.advise2Title}</h5>
                    <ul>
                        <li>{displayText.advise21}</li>
                        <li>{displayText.advise22}</li>
                        <li>{displayText.advise23}</li>
                        <li>{displayText.advise24}</li>
                        <li>{displayText.advise25}</li>
                        <li>{displayText.advise26}</li>
                        {displayText.advise27 && <li>{displayText.advise27}</li>}
                        {displayText.advise28 && <li>{displayText.advise28}</li>}
                    </ul>
                </span>
            </fieldset>
            <Row className="show-grid">
                <div className="form-button" style={{marginTop: '50px', textAlign: 'center'}}>
                    <Button style={{color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white',}}
                            redirectTo="goBack" type="button" onClick={() => history?.goBack()}>
                        <i className="fa fa-ban" aria-hidden="true"/>  &nbsp; Cancel
                    </Button>
                    <Button style={{color: '#FFFFFF', border: 'none', backgroundColor: '#26A65B'}}>
                        <i className={editInstance ? "fa fa-refresh" : "fa fa-plus"} aria-hidden="true"/>
                        &nbsp;
                        {editInstance ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Row>
            <Alert id="pre-excavation-checklist-form" className="danger-alert"/>
        </Form>
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
                <Async
                    identifier="utilityDamageReport"
                    promise={this.promise}
                    error={<ErrorPage/>}
                    loader={<Loader/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }

}

function mapStateToProps(state: any, ownProps: any) {
    const editId = ownProps.match.params.id;
    return {
        editId,
        forms: state.forms,
        editInstance: PreExcavationChecklistModel.get(ownProps.match.params.id)
    }
}

export const PreExcavationCheckListForm = withRouter(connect<IPreExcavationCheckListProps, any, any>(mapStateToProps)(PreExcavationCheckListFormImpl));
