import { IAnswer } from '../interfaces';
import { BaseModel } from './BaseModel';

export interface IIncidentNotificationModelProps {
    id: string;
    date: string;
    time: string;
    timeOfIncident: string;
    location: string;
    locationName: string;
    mobileLocation: string;
    specifiedLocation: string;
    injuredPartyIdNo: string;
    injuredPartyWorkFunction: string;
    activityBeingPerformed: string;
    team: string;
    concernType: string;
    activity?: string;
    injurySummary: string;
    injuryDiagnosis: string;
    injuryBodyPart: string;
    injuryClassification: string;
    dateOfIncident: string;
    followUp: boolean;
    groupName: string;
    projectName: string;
    userId: string;
    latitude: string;
    longitude: string;
    formId: string;
    key: string;
    incidentNotificationImages?: {
        name: string;
        image: string;
    }[];
    imageUrls?: string[];
    incidentNotificationVideos?: {
        name: string;
        file: string;
    }[];
    videoUrls?: string[];
    submittedFormId?: string;
    answers?: IAnswer;
    followUpNeeded: boolean; 
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string;
    changedConcernType?: string;
    associatedFormsCompleted?: string[]
}

export class IncidentNotificationModel extends BaseModel<IIncidentNotificationModelProps> {
    constructor(props: IIncidentNotificationModelProps) {
        super(props, IncidentNotificationModel.resource);
    }
    static resource = 'incident-notification';
}
