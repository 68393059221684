import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../../interfaces';
import { SafetyWalkthroughReportDetail } from '../../../model/SafetyWalkthroughModel';
import { BasePage } from '../../reusableComponents/BasePage';
import { Title } from '../../reusableComponents/Title'; 
import { shoCarousel } from '../../../actions/miscellaneousActions';
import { Img } from '../../reusableComponents/ImageComponents/Img';
import { getSWReportDetail } from '../../../services/attributeService';
import { DetailsPageWrapper } from '../../reusableComponents/DetailsPageWrapper';
import { KeyValue } from '../../../utils/generalUtils';
import { Gallery } from '../../reusableComponents/ImageComponents/Gallery'; 
import { VideoPlay } from '../../reusableComponents/FormComponents/VideoPlay';  
import { Button } from '../../reusableComponents/FormComponents/Button';
import noImage from '../../../images/noImage.png';

export interface ICloseCallCheckListDetailProps {
    checklistReportDetails: SafetyWalkthroughReportDetail;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    history?: IHistory;
    formId: string;
    formType: string;
    match?: {
        params: {
            id: string;
        }
    };
}

export interface ICloseCallCheckListDetailState {
    checklistReportDetails: SafetyWalkthroughReportDetail;
    showDetails: boolean;
    formId: string;
}

export class CloseCallCheckListDetailImpl extends React.PureComponent<ICloseCallCheckListDetailProps, ICloseCallCheckListDetailState> {
    constructor(props: ICloseCallCheckListDetailProps | Readonly<ICloseCallCheckListDetailProps>) {
        super(props);
        let reportDetail: SafetyWalkthroughReportDetail = {
            company: '', subAttributeList: [],
            formType: '', date: '', overAllComment: '', totalScore: '', userName: ''
        };
        this.state = { showDetails: false, formId: '', checklistReportDetails: reportDetail };
    }

    promise = async () => { 
        // return getAllJSAForm(); 
        //        return null;
    }

    componentDidMount() {
        const { formId, formType } = this.props;
        getSWReportDetail(formId, formType);
    }

    renderAnswerList = () => {
        const { checklistReportDetails } = this.props;
        if (checklistReportDetails.subAttributeList) {
            return checklistReportDetails.subAttributeList.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="sub-heading" >{item.attributeName}</div>
                        <div>{this.renderAnswerInnerList(item.answerList)}</div>
                        <div className="table-rows">
                        </div>
                    </div>
                );
            });
        } else {
            return (<span></span>);
        }
    }
    renderAnswerInnerList = (list: any[]) => {
        if(list) {     
            return list.map((item, index) => {
                return (
                    <span key={index} >
                        <KeyValue label={item.key}><span>{item.value}</span></KeyValue>
                    </span>
                );
            });
        }
    }

    renderImages = () => {
        const { checklistReportDetails } = this.props;
        if ((checklistReportDetails.imageUrls || []).length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect"/>;
        }
        const { imageUrls } = checklistReportDetails;

        return (imageUrls || []).map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    }

    renderDetailList = () => {
        const { checklistReportDetails } = this.props;
        const { props: { titleStyle } } = this; 
        return (
            <div>
                <Title style={titleStyle || {}} text="Close Call Checklist Details"></Title>
                <KeyValue label="Date"><span>{checklistReportDetails.date}</span></KeyValue>
                <KeyValue label="Company Name">{checklistReportDetails.company}</KeyValue>
                <KeyValue label="Project Name">{checklistReportDetails.projectName}</KeyValue>
                <KeyValue label="Group Name">{checklistReportDetails.groupName}</KeyValue>
                <KeyValue label="Form Type">{checklistReportDetails.formType}</KeyValue>
                {this.renderAnswerList()}
                <span className="border"></span>
               {/*  <KeyValue label="Total Score:">{checklistReportDetails.totalScore}</KeyValue> */}
                <KeyValue label="Overall Comments:">{checklistReportDetails.overAllComment}</KeyValue >
                <KeyValue label="Images">{this.renderImages()}</KeyValue>
                <KeyValue label="Videos"><VideoPlay videoUrls={checklistReportDetails.videoUrls}/></KeyValue> 
            </div>
        );
    }

    render() {
        return (
            <BasePage className="jsa-report" >
                <Gallery images={this.props.checklistReportDetails ? this.props.checklistReportDetails.imageUrls : []} />
                <DetailsPageWrapper pdfFileName={'SafetyWalk'} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close </Button> }
                >
                    {this.renderDetailList()}
                </DetailsPageWrapper>

            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        formId: ownProps.match.params.formId,
        formType: ownProps.match.params.formType,
        checklistReportDetails: state.report.get('reportDetailData')
    };
}

export const CloseCallCheckListDetail = withRouter(connect<ICloseCallCheckListDetailProps, any, any>(mapStateToProps)(CloseCallCheckListDetailImpl));
