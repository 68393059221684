import { getAllUsers } from '../../services/userService';
import { BasePage } from '../reusableComponents/BasePage';
import { updateUserProfile } from '../../services/profileService';
import * as React from 'react';
import { IUserModelProps, UserModel } from '../../model/UserModel';
import { connect } from 'react-redux';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Title } from '../reusableComponents/Title';
import { UserForm } from '../User/UserForm';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import '../User/addUserForm.scss';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { IHistory } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { ProjectModel } from '../../model/ProjectModel';
import { getAllGroups } from '../../services/groupService';
import { getAllProjects } from '../../services/projectService';
import { isFormEmpty, showAlert } from '../../utils/generalUtils';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';

export interface IUpdateProfileProps {
    userInstance: UserModel;
    history?: IHistory;
    onCancel?: () => void;
}

export class UpdateProfileImpl extends React.PureComponent<IUpdateProfileProps, {}> {
    constructor(props: IUpdateProfileProps | Readonly<IUpdateProfileProps>) {
        super(props);
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        return null;
    }

    renderUpdateUserForm = () => {
        const { userInstance } = this.props;
        if (!userInstance) {
            return <Loader />;
        }
        const { props: { userId, firstName, middleName, lastName, email,
            jobTitle, jobDesc, projects, groups, accessLevel, phoneNumber } } = userInstance;
        let groupIds: any[] = [];
        let projectIds: any[] = [];
        (groups || []).forEach(groupName => {
            const groupId = GroupModel.getIdByName(groupName);
            groupIds.push(groupId).toString();
        });
        (projects || []).forEach(projectName => {
            const projectId = ProjectModel.getIdByName(projectName);
            projectIds.push(projectId).toString();
        });
        return (
            <Async
                identifier="UpdateProfileForm"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={
                    <UserForm
                        id="UpdateProfile"
                        handleFormSubmit={this.handleSubmit}
                        userId={userId}
                        firstName={firstName}
                        middleName={middleName}
                        lastName={lastName}
                        email={email}
                        jobTitle={jobTitle}
                        jobDesc={jobDesc}
                        phoneNumber={phoneNumber}
                        project={projectIds.toString()}
                        group={groupIds.toString()}
                        accessLevel={accessLevel}
                        userInstance={this.props.userInstance}
                        hideFields={['password']}
                        onCancel={() => {
                            this.props.history?.push(`/profile/${userInstance.props.userId}`);
                        }}
                        buttonName="Update Profile"
                    />
                } />
        );
    }

    handleSubmit = ({ dateUpdated, userId, firstName, middleName, lastName,
        email, accessLevel, originalEmail, jobTitle, jobDesc, groups: groupNames, phoneNumber, signatureUrl }: IUserModelProps, signature: string | File) => {
        const { userInstance: { props: { userUID } }, history } = this.props;
        const groups = groupNames;
        const submittedValues: any = {
            dateUpdated: format(new Date(), 'yyyy-MM-dd'), userId: userUID, firstName, middleName, lastName,
            email, originalEmail, accessLevel, jobTitle, jobDesc, groups, signatureUrl
        };
        if (isFormEmpty({ email, firstName, jobDesc, jobTitle, lastName, userId, accessLevel })) {
            showAlert(EMPTY_FORM_MESSAGE, 'profile', 'danger');
            return;
        }
        return history && updateUserProfile(submittedValues, history, phoneNumber, signature);
    }
    render() {
        return (
            <BasePage>
                <div className="update-profile">
                    <Title text={`Update Profile`} />
                    <AlertModal id="profile-modal" />
                    <Alert className="danger-alert" id="profile" />
                    {this.renderUpdateUserForm()}
                </div>
            </BasePage>
        ); 
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId, state);
    const groupInstances = GroupModel.list();
    const projectInstances = ProjectModel.list();
    return {
        userInstance, groupInstances,
        projectInstances
    };
}

export const UpdateProfile = withRouter(connect< IUpdateProfileProps, any, any>(mapStateToProps)(UpdateProfileImpl));
