import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { IIncidentSummary } from '../../../interfaces';

export interface IConeProps {
    colorArray: string[];
    layerData: IIncidentSummary;
}

export class Cone extends React.PureComponent<IConeProps, {}> {
    constructor(props: IConeProps | Readonly<IConeProps>) {
        super(props);
    }

    calculateColorIndex = (layerIndex: number): string => {
        const { colorArray } = this.props;
        return colorArray[layerIndex % colorArray.length];
    }

    renderLabels = () => {
        const { layerData } = this.props; 
        return (
            <div className="cone-labels">
                <div className="layer-label label-1" >&nbsp;FAT:<br />{layerData.fat}
                    <span className="label-1-tooltip">FAT: {layerData.fat}</span>
                </div>
                <div className="layer-label label-2" >LTI: {layerData.lti}
                    <span className="label-2-tooltip">LTI: {layerData.lti}</span>
                </div>
                <div className="layer-label label-3" >MTI: {layerData.mti}
                    <span className="label-3-tooltip">MTI: {layerData.mti}</span>
                </div>
                <div className="layer-label label-4" >SNM: {layerData.snm}
                    <span className="label-4-tooltip">SNM: {layerData.snm}</span>
                </div>
                <div className="layer-label label-5" >FA: {layerData.fa}
                    <span className="label-5-tooltip">FA: {layerData.fa}</span>
                </div>
                <div className="layer-label label-6" >NT: {layerData.nt}
                    <span className="label-6-tooltip">NT: {layerData.nt}</span>
                </div>
                <div className="layer-label label-7" >NM: {layerData.nm}
                    <span className="label-7-tooltip">NM: {layerData.nm}</span>
                </div>
            </div>
        );
    }

    render() {
        {/*tslint:disable */ }
        return (
            <Grid className="cone" style={{ position: 'relative', padding: '20px', width: '100%' }}>
                {this.renderLabels()}
                <svg
                    width="100"
                    height="100"
                    className="cone"
                    viewBox="-25 0 1175 1419"
                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Canvas"
                        transform="translate(19600 8843)">
                        <g id="Group 5">
                            <g id="Group 6">
                                <g id="Group 2">
                                    <g id="Vector 3">
                                        <path
                                            d="M 0 156L 68 0C 87 13.4232 146.8 39.7539 234 37.6915C 239.313 37.4229 244.55 37.1274 249.612 36.8168C 268.747 35.1047 298.188 30.5832 328 20.6537C 342.848 15.2729 357.886 8.61654 373 0.5L 442 156.5C 297 244 94.5 213 0 156Z"
                                            className="mti"
                                            transform="translate(-19255 -8518.5)"
                                            fill={`#` + this.calculateColorIndex(2)}
                                        />
                                    </g>
                                </g>
                                <g id="Group 2">
                                    <g id="Vector">
                                        <path
                                            d="M 0 195.5L 92.5 0L 186.5 195.212C 114 225.5 62.5 221.288 0 195.5Z"
                                            xlinkHref="#path2_fill"
                                            transform="translate(-19126.5 -8843)"
                                            fill={`#` + this.calculateColorIndex(0)}
                                        />
                                    </g>
                                </g>
                                <g id="Group">
                                    <g id="Vector 2">
                                        <path
                                            d="M 0 131.762L 60.5 0.5C 90 16.3333 168.4 38.4 246 0L 305.087 131.762C 205 188 71.5 175.5 0 131.762Z"
                                            xlinkHref="#path4_fill"
                                            transform="translate(-19187 -8650)"
                                            fill={`#` + this.calculateColorIndex(1)}
                                        />
                                    </g>
                                </g>
                                <g id="Group 2">
                                    <g id="Vector 3.1">
                                        <path
                                            d="M 0 164L 73 0C 117.5 33 335 106 515 1L 587.5 166C 400.5 286 96 238.5 0 164Z"
                                            xlinkHref="#path6_fill"
                                            transform="translate(-19328 -8363)"
                                            fill={`#` + this.calculateColorIndex(3)}
                                        />
                                    </g>
                                </g>
                                <g id="Group 3">
                                    <g id="Vector 3.2">
                                        <path
                                            d="M 0 207L 95.5 0C 144.5 48.5 451 137 683 1L 774.5 207C 519 372.5 128.5 316.5 0 207Z"
                                            xlinkHref="#path8_fill"
                                            transform="translate(-19423 -8199.5)"
                                            fill={`#` + this.calculateColorIndex(4)}
                                        />
                                    </g>
                                </g>
                                <g id="Group 5">
                                    <g id="Vector 3.4">
                                        <path
                                            d="M 0 208L 85 0C 230.833 82 623.9 196.8 1047.5 0L 1138 208C 842 441.5 137 369.5 0 208Z"
                                            xlinkHref="#path10_fill"
                                            transform="translate(-19604.5 -7783)"
                                            fill={`#` + this.calculateColorIndex(6)}
                                        />
                                    </g>
                                </g>
                                <g id="Group 4">
                                    <g id="Vector 3.3">
                                        <path
                                            d="M 0 213L 97 0C 185 80 554.9 189.6 870.5 0L 963.5 215.5C 627.5 370 251.5 344.5 0 213Z"
                                            xlinkHref="#path12_fill"
                                            transform="translate(-19520 -7994)"
                                            fill={`#` + this.calculateColorIndex(5)}
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    {/*tslint:enable */}
                </svg>
            </Grid >
        );
    }
}
