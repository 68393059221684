import { UserModel } from '../model/UserModel';
import { getCustom, postCustom } from '../utils/HTTP';
import { showAlert } from '../utils/generalUtils';
import { SERVER_URL } from '../constants/urls';
import { hideCreateCustomNotificationModal } from '../actions/modalActions';
import { FORM_SUBMISSION_ERROR } from '../constants/general';
import { getNotifications } from './profileService';
import { saveCustomNotification } from '../actions/miscellaneousActions';
import { IFileType, IForms } from '../interfaces'; 
declare var require: any;
const AWS = require('aws-sdk');

export async function createCustomNotification(customNotificationData: { audience: string; title: any; message: any; distributeDate: any; }, userId: string, userUID: string, files: File[], fileNames: string[], forms: IForms) { 
    const companyName = UserModel.getCompanyName(); 
    let fileInfo:Array<any> = []; 
    /* if(files.length) {
        try {
            // const url = 'https://jayce-test-bucket.s3.eu-west-1.amazonaws.com/notificationFiles/';
            const url = 'https://s3-us-west-2.amazonaws.com/safeconnect-beta/notification-files/';
            for (let i = 0; i < files.length; i++) {
                const upload = await uploadFile(files[i], name, 'notification-files');
                if (upload) {
                    uploadStatus = true;
                } else {
                    uploadStatus = false;
                    break;
                }
            }
            if (uploadStatus) {
                for (let i = 0; i < files.length; i++) {
                    const fileExtension = files[i].name.split('.')[1];
                    fileInfo.push({
                        fileName: fileNames[i],
                        mimeType: files[i].type,
                        fileSize: files[i].size,
                        fileLink: `${url}${name}.${fileExtension}`
                    });
                }
            }
        } catch (error) {
            showAlert(FORM_SUBMISSION_ERROR, 'custom-notification-failed', 'danger');
            throw error;
        }
    } */
    try {
        const userInstance = UserModel.getLoggedUserInstance();  
        const response = await postCustom(`${SERVER_URL}/CustomNotification`, {
            userId: userUID,
            audience: customNotificationData ? customNotificationData.audience.split(',') : '',
            firstLast: (`${userInstance.firstName} ${userInstance.lastName}`),
            company: companyName,
            title: (customNotificationData ? customNotificationData.title : ''),
            message: (customNotificationData ? customNotificationData.message : ''),
            distributeDate: (customNotificationData ? customNotificationData.distributeDate : ''),
            fileInfo, attachments: forms.customNotificationForm.attachments, 
        });
        if (JSON.stringify(response.data.message).toLowerCase().indexOf('error') > -1) {
            showAlert(FORM_SUBMISSION_ERROR, 'custom-notification-failed', 'danger');
            return;
        }
        await getNotifications(userUID, companyName);
        showAlert(response.data.message, 'custom-notification-success', 'success', () => { hideCreateCustomNotificationModal(); }); 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'custom-notification-failed', 'danger');
        throw error;
    }
}

export async function uploadFile(file: File, name: string, key: string) {
    AWS.config.update({
        'accessKeyId': 'AKIA47N26QZ4LRASUVVG',
        'secretAccessKey': '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',
        /* 'accessKeyId': 'AKIAJBGQTQPHFGMJHBYQ',
        'secretAccessKey': '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', */
        
        /* AKIA47N26QZ4FZMVBMMY
        U6CFOOnqDxN91/uZapp5OIvF2WsDoxjrgjLY0HyW */

        /* NEW
        AKIA47N26QZ4AGS5U4OD
        7X4kBzVO0m/7yFcLgsoBuhP/6ffllnGpvh90SR8v */  
    }); 
    const fileExtension = file.name.split('.')[file.name.split('.').length-1];

    AWS.config.region = 'us-west-2';
    const s3 = new AWS.S3();

    const myBucketName = 'safeconnect-beta';
    const params = {
        Bucket: myBucketName,
        // Key: `notificationFiles/${name}.${fileExtension}`,
        Key: `${key}/${name}.${fileExtension}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };
    return s3.putObject(params, function (err: any, data: any) {
        if (err) {
            throw err;
        } else {
            return true;
        }
    });
}

export async function uploadReferenceFile(file: IFileType, name: any, key: string) {
    AWS.config.update({
        'accessKeyId': 'AKIAJBGQTQPHFGMJHBYQ',
        'secretAccessKey': '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',
    });
    /* const fileExtension = file.name.split('.')[1]; */

    AWS.config.region = 'us-west-2';
    const s3 = new AWS.S3();

    const myBucketName = 'safeconnect-beta';
    const params = {
        Bucket: myBucketName,
        // Key: `notificationFiles/${name}.${fileExtension}`,
        Key: `${key}/${name}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };
    return s3.putObject(params, function (err: any, data: any) {
        if (err) {
            throw err;
        } else {
            return true;
        }
    });
}

export async function getCustomNotificationDetails(notificationId: string, userUID: string) {
    try {
        const company = UserModel.getCompanyName();
        const response = await getCustom(`/CustomNotification/${company}/${notificationId}`);
        if (JSON.stringify(response.data).toString().toLowerCase().indexOf('error') > -1) {
            throw new Error(JSON.stringify(response.data).toString());
        }
        saveCustomNotification(response.data.data);
        await getNotifications(userUID, UserModel.getCompanyName());
        return response.data;
    } catch (error) {
        throw error;
    }
}
