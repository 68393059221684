import * as React from 'react'; 
import { connect } from 'react-redux';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom'; 
import { format } from 'date-fns'; 
import PropTypes from 'prop-types';
import { IForms, IHistory } from '../../../interfaces'; 
import { constructAnswers, dispatch, isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage'; 
import './designation.scss';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { Title } from '../../reusableComponents/Title';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { IDesignationModelProps, DesignationModel } from '../../../model/MiscellaneousModel';
import { submitDesignationForm } from '../../../services/miscellaniousServices'; 

export interface IDesignationFormProps { 
    history?: IHistory;
    forms: IForms;
    editId?: string;
    instance?: DesignationModel;
}

export interface IDesignationFormState {
}

export class DesignationFormImpl extends React.PureComponent<IDesignationFormProps, IDesignationFormState> {

    constructor(props: IDesignationFormProps | Readonly<IDesignationFormProps>) {
        super(props);  
    } 
    
    promise = async () => {

    }
    getChildContext() {
        return { formModel: 'forms.designationForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleFormSubmit = async ({ designation, status, priority }: IDesignationModelProps) => { 
        const { props: { forms, history, editId } } = this;  
        const answerData: any = { designation, status: status?'1':'2', priority }; 
        if (isFormEmpty(answerData)) {
            showAlert(EMPTY_FORM_MESSAGE, 'designation-form-failed', 'danger');
            return;
        }  
        answerData.editId = editId || ''; 
        console.log(answerData)
        return history && submitDesignationForm(answerData, history, forms); 
    } 

    renderContent = () => {
        const { editId, instance } = this.props;
        return (
            <Grid className="designation-form" style={{ width: '100%' }}> 
                <Form loader={<SubmissionLoader />} model="forms.designationForm" onSubmit={this.handleFormSubmit}>
                    <Title titleColor="yellow" text="Designation Form" noNeedBorder={true}/>
                    <AlertModal id="designation-modal" /> 
                    <fieldset> 
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" required htmlFor="designation">Designation:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".designation"
                                    id="designation" 
                                    defaultValue={instance?instance.props.designation:''}
                                /> 
                            </Col>
                        </Row> 
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" required htmlFor="priority">Priority:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".priority"
                                    id="priority" 
                                    defaultValue={instance?instance.props.priority:''}
                                /> 
                            </Col>
                        </Row> 
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" htmlFor="status">Status:</Label>
                            <Col sm={6} className="input"> 
                                <RRFInput
                                    id="status"
                                    model=".status"
                                    type="checkbox" 
                                    defaultValue={instance && instance.props.status===1?true:null} 
                                />
                                <label htmlFor="status"></label>
                            </Col>
                        </Row> 
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="designation-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ !instance? "fa fa-plus": 'fa fa-refresh' }  aria-hidden="true"></i>
                            &nbsp;
                            { !instance? 'Submit': 'Update' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    }
 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="DesignationForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}


const rowStyle: React.CSSProperties = {
    paddingBottom: '20px'
};

export function mapStateToProps(state: any, ownProps: any) { 
    const editId = ownProps.match.params.id;
    const instance = DesignationModel.get(editId);
    return { editId, instance, forms: state.forms, };
}
export const DesignationForm = withRouter(connect<IDesignationFormProps, any, any>(mapStateToProps)(DesignationFormImpl));
