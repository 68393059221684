
import * as React from 'react'; 
import { ActivityChart } from '../reusableComponents/ChartComponents/ActivityChart';
import { Card } from '../reusableComponents/Card';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends'; 
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { Title } from '../reusableComponents/Title';  
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Col, Row, Container as Grid } from 'react-bootstrap'; 
import { IChartValue, IFormMonths, IPieChartObject, IHistory, IForms, } from '../../interfaces'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Async } from '../reusableComponents/Async';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import totalReportsAvatar from '../../images/totalReports.png';
import positiveObservationAvatar from '../../images/positiveObservations.png';
import negativeObservationAvatar from '../../images/negativeObservation.png'; 
/* import { printLogos } from '../../utils/generalUtils'; */
import { PrintLogos } from '../reusableComponents/PrintLogos';  
import { getRiskAssessmentData } from '../../services/riskAssessmentService';

export interface IOIReportsDataProps {
    detailedPie?: {
        name?: string;
        value?: number;
    }[];
    activityData: any;
    combinedOthers?: boolean;
    lineChartData: { formMonths?: IFormMonths }; 
    summaryCardsData: { 
        total: number;  finalCount: number, preliminaryCount: number
    }; 
    getRef?: (value: any) => void;
    showCheckBox?: boolean;
    id?: string;
    isAnimationActive?: boolean;
    filterData: {
        year: string;
        filterType: string;
        month: string;
    };
    history?: IHistory;
    forms?: IForms;
}

export interface IOIReportsDataState {
    showPieChart: string;
    showLineChart: string;
    showBarChart: string;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    view: boolean;
    activityName: string;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
}

export class RiskReportsData extends React.PureComponent<IOIReportsDataProps, IOIReportsDataState> {
    constructor(props: IOIReportsDataProps | Readonly<IOIReportsDataProps>) {
        super(props);
        this.state = {
            showMonthlyFilter: false, showYearlyFilter: false,
            showPieChart: 'show', showLineChart: 'show',
            showBarChart: 'show', view: false, activityName: '', legends: [],
            data: [], totalFormsSubmitted: 0, isLoading: false
        };
    }

    componentWillMount() {
        setSuccess('ReportsPage');
    }

    ref: any;
 
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getRiskAssessmentData(this.props.forms); 
        setSuccess('ReportsPage');
    }
    renderPrintPreviewModal = () => {
        showPrintPreviewModal('oi-report');
        return;
    }

    renderActivityPopover = (obj: IPieChartObject) => {
        const { activityData, detailedPie } = this.props;
        let legends: Array<any> = [];
        let data: Array<any> = [];

        let totalFormsSubmitted: any = '';
        (detailedPie || []).forEach(concernData => {
            if (concernData.name === obj.name) {
                totalFormsSubmitted = concernData.value;
            }
        });
        const activities = obj.name !== undefined ? activityData[obj.name]:'';
        for (let key in activities) {
            legends.push(key);
        }
        for (let key in activities) {
            data.push({
                name: key, value: activities[key]
            });
        }
        this.setState({
            view: true,
            pieChartData: obj,
            legends,
            data,
            totalFormsSubmitted
        });
    }

    renderPieChart = () => {
        const { detailedPie, isAnimationActive } = this.props;
        const legendsName: any = [];
        (detailedPie || []).forEach(data => {
            legendsName.push(data.name);
        });
        return (
            <div id="pie-chart"
                style={{ /* height: '100%', */ width: '100%', display: 'inline-block', margin: '20px auto', backgroundColor: 'white' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Risk Assessments
                    </h4>
                    <Row className="show-grid" >
                        <Col sm={7} id="oi-pie-chart" className="pie circle" style={{ height: '300px' }}>
                            <ActivityChart
                                visibility={this.state.view}
                                data={this.state.data}
                                legends={this.state.legends}
                                totalFormsSubmitted={this.state.totalFormsSubmitted}
                                obj={this.state.pieChartData}
                                chartType="pie"
                            />
                            <PieChart
                                data={detailedPie}
                                innerRadius={60}
                                colorArray={[ 'B0D9FF', 'B3D9AD', 'FFF6BE', 'F2BD91','FFBECE', 'C0F5FA',
                                'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1}
                                /* onClick={this.renderActivityPopover} */
                                customTootip={false}
                                isAnimationActive={isAnimationActive}
                            />
                        </Col>
                        <PieChartLegends
                            sm={5}
                            legendNames={legendsName}
                            legendColors={[ 'B0D9FF', 'B3D9AD', 'FFF6BE', 'F2BD91','FFBECE', 'C0F5FA',
                            'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                        />
                    </Row> 
                </Card>
            </div>
        );
    }
 

    renderLineChart = () => {
        const { lineChartData: { formMonths } } = this.props;
        if (!formMonths) {
            return;
        }
        const { January, February, March, April, May, June, July, August,
            September, October, November, December } = formMonths;
        const data = [
            { name: 'Jan', count: January  }, { name: 'Feb', count: February },
            { name: 'Mar', count: March }, { name: 'Apr', count: April},
            { name: 'May', count: May }, { name: 'Jun', count: June },
            { name: 'July', count: July }, { name: 'Aug', count: August },
            { name: 'Sep', count: September }, { name: 'Oct', count: October },
            { name: 'Nov', count: November }, { name: 'Dec', count: December },
        ];
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }} >
                        No of Risk Assessments
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={data} legends={['Count' ]}
                                strokeColors={['B0D9FF' ]}
                                dataKey={['count' ]} />
                            {/* <LineChart data={data} legends={['Actual']}
                                strokeColors={['B0D9FF', 'FFBECE']}
                                dataKey={['actual']} /> */}
                        </Col>
                    </Row>  
                </Card>
            </div>
        );
    }

    

    renderSummaryCards = () => {
        const { total, finalCount, preliminaryCount } = this.props.summaryCardsData; 
        return (
            <Grid style={{ width: '100%' }}>
                <Row className="show-grid " sm={12}> 
                    <Col sm={4} className="card50">
                        <Card className="oi-report-card clickable-report-card">
                            <img src={totalReportsAvatar} alt="SafeConnect" />
                            <h4 style={{ margin: '15px 0px 0px 0px' }}>Total Reports</h4>
                            <p style={{ paddingTop: '0px' }} >{total || 0}</p>
                        </Card>
                    </Col>
                    <Col sm={4} className="card50" >
                        <Card className="oi-report-card clickable-report-card">
                            <img src={negativeObservationAvatar} alt="SafeConnect" />
                            <h4 style={{ margin: '2px 0px 0px 0px' }}>Preliminary Reports</h4>
                            <p style={{ paddingTop: '0px' }}>{preliminaryCount || 0}</p>
                        </Card>
                    </Col> 
                
                    <Col sm={4} className="card50">
                        <Card className="oi-report-card clickable-report-card">
                            <img src={positiveObservationAvatar} alt="SafeConnect" />
                            <h4 style={{ margin: '15px 0px 0px 0px' }}>Final Reports</h4>
                            <p style={{ paddingTop: '0px' }}>{finalCount || 0}</p>
                        </Card>
                    </Col> 
                </Row>
            </Grid >
        );
    }

    handleLineChart = (value: any) => {
        this.setState({
            showLineChart: value
        });
    }

    handlePieChart = (value: any) => {
        this.setState({
            showPieChart: value
        });
    }

    handleBarChart = (value: any) => {
        this.setState({
            showBarChart: value
        });
    }

    renderCheckBox = () => { 
        return <div className="print-filter-wrapper">
            <div className="filter-title">Please select charts to export:</div>
            <div className="print-data-filter">
                <div className="chart-filter">
                    <label
                        className="checkbox-label"
                        htmlFor="oiLineChart"
                    >
                        &nbsp;Types Of Observations(Line Chart)
                    </label>
                    <RRFInput
                        type="checkbox"
                        model=".oiLineChart"
                        id="oiLineChart"
                        onChange={this.handleLineChart}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <label
                        className="checkbox-label"
                        htmlFor="oiPieChart"
                    >
                        &nbsp;Types Of Observations(Pie Chart)
                    </label>
                    <RRFInput
                        type="checkbox"
                        id="oiPieChart"
                        model=".oiPieChart"
                        onChange={this.handlePieChart}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <label className="checkbox-label" htmlFor="oiBarChart">&nbsp;Observation Influence</label>
                    <RRFInput
                        type="checkbox"
                        id="oiBarChart"
                        model=".oiBarChart"
                        onChange={this.handleBarChart}
                        defaultValue="checked"
                    />
                </div>
            </div>
        </div >;
    }

    renderContent = (isLoading: boolean) => {
        const { props: { showCheckBox, getRef, forms },
            state: { showLineChart, showPieChart } } = this;
        return (
            <div id="oi-reports" className="oi-report-summary">
                {showCheckBox ? this.renderCheckBox() : null}
                {isLoading ? <SubmissionLoader /> : ''}
                <div
                    className="summary-reports"
                    id={this.props.id} ref={value => { getRef ? getRef(value) : this.ref = value; }}> 
                    <PrintLogos/>
                    <Title text="Risk Assessment Report Summary" />
                    {this.renderSummaryCards()} 
                    <CommonFilter onApply={this.applyCommonFilter} forms={forms}/>
                    {showLineChart ? this.renderLineChart() : null}
                    <Row>
                        <Col sm={12} xs={12} className="report-chart" style={{ padding: '0 20px' }}>
                            {showPieChart ? this.renderPieChart() : null}
                        </Col> 
                    </Row>
                </div>
            </div >
        );
    }
    render() {
        return <Async
            identifier="ReportsPage" 
            error={<div></div>}
            loader={this.renderContent(true)}
            content={this.renderContent(false)}
        />;
    }
}
