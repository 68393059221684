import * as React from 'react';
import { Button } from './FormComponents/Button';
import { Col, Row } from 'react-bootstrap';  
import ReactExport from "react-export-excel"; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubmissionLoader } from './SubmissionLoader';
import { getExportData } from '../../services/miscellaniousServices';
import { IForms, IHistory } from '../../interfaces';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
declare var require: any;
const queryString = require('query-string');

export interface IExcelExportProps {
    formType?: string;
    fileName?: string;
    style?: React.CSSProperties;
    filterParams?: any;
    userId: string;
    forms?: IForms;
    history?: IHistory;
} 

export interface IExcelExportState {
    excelData: any;
    showDownload: boolean;
    loading: boolean;
} 

export class ExcelExportImpl extends React.PureComponent<IExcelExportProps, IExcelExportState> {
    constructor(props: IExcelExportProps | Readonly<IExcelExportProps>) {
        super(props); 
        this.state = { excelData: [], showDownload: false, loading: false, };
    }
    getData = async () => { 
        const {formType, filterParams, forms } = this.props;
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        if(formType) {
            this.setState({loading: true}) 
            let filterParam = {userId: this.props.userId, formType: formType, searchItem: urlParams.searchItem, ...filterParams, ...forms?.commonFilterForm}
            const response = await getExportData(filterParam);
            if(response.data.status) {
                await this.setState({ excelData: response.data.data, showDownload: true });  
                document.getElementById('excelExport')?.click(); 
            } else {
                alert(response.data.message)
            }
            this.setState({loading: false, showDownload: false});
        }
    }
    exportData() {  
        return (
            <>
            {this.state.loading && <SubmissionLoader/>}
            
            {!this.state.showDownload && <div> <Row className="show-grid floatR" style={this.props.style}>
            <Col sm={12}> <Button type="button" onClick={this.getData} className="excel-export-button"> <i className="fa fa-file-excel-o" aria-hidden="true"></i> Export as Excel</Button> </Col></Row></div> }

            {this.state.showDownload && <ExcelFile filename={this.props.fileName} element={<div className="excel-export-page"> 
                    <Row className="show-grid floatR" style={this.props.style}>
                        <Col sm={12}>
                            <Button type="button" id="excelExport" className="excel-export-button"> <i className="fa fa-file-excel-o" aria-hidden="true"></i> Export as Excel</Button>
                        </Col>
                    </Row>
                </div>}>
                <ExcelSheet dataSet={[this.state.excelData]} name={this.props.fileName} />
            </ExcelFile>}
            </>
        );
    } 
    render() {
        return (this.exportData()); 
    }
}

export function mapStateToProps(state: any) {  
    const userId = state.login.get('userUID');
    return {userId, forms: state.forms, };
}

export const ExcelExport = withRouter(connect<IExcelExportProps, any, any>(mapStateToProps)(ExcelExportImpl));
