import * as React from 'react';
import { connect } from 'react-redux'; 
import './alert.scss';
import { withRouter } from 'react-router';

// TODO: Add a replaceable prop that will decide whether to replace the previous alert or push it to the array. 
export interface IAlertProps {
    alert?: string;
    className?: string;
    alertType?: 'success' | 'danger' | 'success-alert' | 'danger-alert';
    style?: React.CSSProperties;
    id?: string;
    alertID?: string;
    view?: (text: any, alertType: any) => JSX.Element | undefined;
}

export class AlertImpl extends React.Component<IAlertProps, {}> {
    /**
     * Renders the alert if the value of alert is set in the state.
     */
    renderAlert = () => {
        const { alert, alertID, alertType, id, view } = this.props;
        let alertMsg; 
        if (!alert) {
            return;
        }
        if (alertID !== id) {
            return;
        }
        if (alertType === 'danger' || alertType === 'success-alert') {
            return (
                <p><i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    &nbsp;{alertMsg || alert}</p>
            );
        }
        return (view ? view(alert, alertType) : null);
    }
    render() {
        const { className, style } = this.props;
        return (
            <div style={style || {}} className={`${className ? className : ''} alert-field`}>
                {this.renderAlert()}
            </div >
        );
    }
}

export function mapStateToProps(state: any) { 
    return {
        alert: state.alert.get('alert'),
        alertID: state.alert.get('alertID'),
        alertType: state.alert.get('type'), 
    };
}

//export const Alert = connect<{}, {}, IAlertProps>(mapStateToProps)();

export const Alert = withRouter(connect<IAlertProps, any, any>(mapStateToProps)(AlertImpl));
