// URL for Beta/Production Server
export const SERVER_URL = 'https://api.safe-connect.us/'; 
export const ELMSURL = 'https://spitraining.net/#'; 

// URL for the development server
/* export const SERVER_URL = 'https://stage-api.safe-connect.us/'; 
export const ELMSURL = 'https://stage.spitraining.net/#'; */
//export const ELMSURL = 'http://localhost:3001/#'


// URL for the AWS S3 server
export const S3_URL = "https://safeconnect-beta.s3.us-west-2.amazonaws.com";  