import * as React from 'react';

export interface IFATickProps {
    className?: string;
    style?: React.CSSProperties;
    title?: string;
}

export class FATick extends React.PureComponent<IFATickProps, {}> {
    constructor(props: IFATickProps | Readonly<IFATickProps>) {
        super(props);
    }

    render() {
        const styles = Object.assign({}, this.props.style || {}, tickIcon);
        return <i title={this.props.title}
            style={styles}
            className={`fa ${this.props.className || `fa-check`}`}
            aria-hidden="true"
        ></i>;
    }
}

let tickIcon = {
    width: '25px',
    height: '25px',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    color: 'white',
};
