import * as React from 'react';
import { Col, DropdownButton, Container as Grid, Dropdown, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteAttributeAction, editAttribute, navigateAddAttribute, emptyAttributeList } from '../../../actions/attributeAction';
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { Loader } from '../../reusableComponents/Loader';
import { Title } from '../../reusableComponents/Title';
import { IHistory, ISubAttributeData, IForms } from '../../../interfaces';
import { UserModel } from '../../../model/UserModel';
import { dispatch } from '../../../utils/generalUtils';
import { getAllAttribute, submitCheckList } from '../../../services/attributeService';
import { BasePage } from '../../reusableComponents/BasePage';
import { IAttributeDataState } from '../../SafetyReport/SafetyWalkAssesment/AddAttribute';
import '../../SafetyReport/SupervisorSafetyWalk/supervisorSafetyWalk.scss';
import { format } from 'date-fns';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal'; 
import PropTypes from 'prop-types';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { FileInput } from '../../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../../reusableComponents/FormComponents/VideoInput';
import { closeCallDetailProps } from '../../../model/MechModel';
import { showDelConfirmModal } from '../../../actions/modalActions';
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal';  
import { showAlert } from '../../../utils/generalUtils'; 
import { ProjectInput } from '../../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../../reusableComponents/FormComponents/GroupInput';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { ProjectModel } from '../../../model/ProjectModel';
import { GroupModel } from '../../../model/GroupModel';
import { getAllGroups } from '../../../services/groupService'; 
import { getAllProjects } from '../../../services/projectService';
import { isFormEmpty } from '../../../utils/generalUtils';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
const MenuItem = Dropdown.Item

export interface ICheckList{
    userUID: string;
    userId: string;
    userInstance: UserModel;
    history?: IHistory;
    managerName: string;
    id: string; 
}
export interface ICheckListProps {
    history?: IHistory;
    attributeList: IAttributeDataState[];
    firstName: string;
    userId: string; 
    forms: IForms;
    editId: string;
    userInstance: UserModel;
    editInstance: closeCallDetailProps; 
}

export interface ICheckListState {
    userUID: string;
    userId: string;
    managerName: string;
    id: string;
    currentDate: string;
    attributeList: IAttributeDataState[];
    comment: string;
    submitDate: Date;
    isSubmit: boolean;
}

export class CheckList extends
    React.Component<ICheckListProps, ICheckListState> {
    constructor(props: ICheckListProps | Readonly<ICheckListProps>) {
        super(props);
        let date = new Date();
        let currentDate = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        this.state = {
            userUID: '', userId: '', managerName: '', id: '', currentDate: currentDate,
            attributeList: [], comment: ( props.editInstance && props.editInstance.overAllComment ) || '', submitDate: ( props.editInstance && props.editInstance.date && new Date(props.editInstance.date) ) || new Date(), isSubmit: false,
        }; 
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        //this.onSubmitCheckList = this.onSubmitCheckList.bind(this);
    }

    promise = async () => {
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } 
        return;
    }

    componentWillUnmount() {
        emptyAttributeList('CloseCallCheckList');
        this.setState({
            attributeList: []
        });
    }
    
    getChildContext() {
        return { formModel: 'forms.assessmentForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    dynamicSort(property: any) {
        let sortOrder = 1; 
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        } 
        return function (a: any, b: any) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        };
    }

    componentDidMount() {
        const { history, editId } = this.props; 
        if(editId && !this.props.editInstance )
            history?.push('/close-call-checklist-reports');
        this.getCheckListData();
    }

    getCheckListData = async () => {
        const { editInstance } = this.props; 
        getAllAttribute('CloseCallCheckList').then((responseList: any) => { 
            if (responseList && responseList['data']!=='No data found') {
                let attributeArray: IAttributeDataState[] = [];
                responseList.sort(this.dynamicSort('attribute'));
                responseList.map((item: any, parentIndex: any) => {
                    let subAttributeArray: ISubAttributeData[] = [];
                    let editIAttr: any = [];
                    if(editInstance) { 
                        editIAttr = editInstance.subAttributeList && editInstance.subAttributeList.filter(p => p.attributeName === item.attribute)
                    }
                    if (item.name && item.name.length > 0) {
                        item.name.map((subattributeName: any, index: string) => {
                            let editISubAttr: any = [];
                            if(editIAttr.length) {
                                editISubAttr = editIAttr[0].answerList.filter((p: any) => p.key === subattributeName)
                            }
                            let subAttributeItem: ISubAttributeData = {
                                subAttributeId: index + '' + parentIndex,
                                isAttributeNA: ( editISubAttr.length && editISubAttr[0].value==='NA' && true ) || false,
                                isAttributeNo: ( editISubAttr.length && editISubAttr[0].value==='No' && true ) || false,
                                isAttributeYes: ( editISubAttr.length && editISubAttr[0].value==='Yes' && true ) || false,
                                name: subattributeName
                            };
                            subAttributeArray.push(subAttributeItem);
                        });
                    } else if (item.subAttributeList && item.subAttributeList.length > 0) {
                        item.subAttributeList.map((subattributeItem: { name: any; }, index: string) => {
                            let subAttributeItem: ISubAttributeData = {
                                subAttributeId: index + '' + parentIndex,
                                isAttributeNA: false,
                                isAttributeNo: false,
                                isAttributeYes: false,
                                name: subattributeItem.name
                            };
                            subAttributeArray.push(subAttributeItem);
                        });
                    }
                    let attributeItem: IAttributeDataState = {
                        attributeId: parentIndex + 1 + '',
                        attribute: item.attribute,
                        editAttribute: false,
                        inputValue: '',
                        isSubmitting: false,
                        name: [],
                        newAttributes: [],
                        subAttributes: [],
                        subAttributeList: subAttributeArray,
                        formType: ''
                    };
                    attributeArray.push(attributeItem);
                });
                this.setState({ attributeList: attributeArray });
            }
        });
    }
    onCommentChange(event: { target: { value: any; }; }) {
        this.setState({ comment: event.target.value });
    }
    async onDateChange(event: { target: { value: string | number | Date; }; }) {
        let date = new Date(event.target.value);
        //date.setDate(date.getDate() + 1);
        date.setDate(date.getDate() );
        await this.setState({ submitDate: date });
    }

    onSubAttributeSelectionChange(event: React.ChangeEvent<HTMLInputElement>, item: ISubAttributeData, selection: string) {
        let currentAttrubuteList = this.state.attributeList;
        if (currentAttrubuteList) {
            currentAttrubuteList.map((attribute) => {
                attribute.subAttributeList.map((subAttribute) => {
                    if (subAttribute.subAttributeId === item.subAttributeId) {
                        if (selection === 'yes') {
                            subAttribute.isAttributeYes = true;
                            subAttribute.isAttributeNo = false;
                            subAttribute.isAttributeNA = false;
                        } else if (selection === 'no') {
                            subAttribute.isAttributeNo = true;
                            subAttribute.isAttributeNA = false;
                            subAttribute.isAttributeYes = false;
                        } else {
                            subAttribute.isAttributeNA = true;
                            subAttribute.isAttributeNo = false;
                            subAttribute.isAttributeYes = false;
                        }
                    }
                });
            });
            this.setState({ attributeList: currentAttrubuteList });
        }
    }

    onAddNewAttribute = () => {
        dispatch(navigateAddAttribute('CloseCallCheckList'));
        this.props.history?.push(`/safety-walkthroug/attribute`);
    }
    onSubAttributeChange = (event: { preventDefault: () => void; }) => {
        // event.setSta
        event.preventDefault();
    }
    onDeleteAttribute(index: number, attributeItem: IAttributeDataState) {
        let attributeData = { index, attributeItem}
        showDelConfirmModal('delete-confirmation', 'Closecall Checklist Question', attributeData);  
    } 
    onConfirmDeleteAttribute = (attributeData: any) => { 
        let index = attributeData.index;
        let attributeItem = attributeData.attributeItem; 
        let newAttributeArray = this.state.attributeList.filter(e => e !== this.state.attributeList[index]); 
        if (newAttributeArray && newAttributeArray.length > 0) {
            newAttributeArray.map((attribute, index) => {
                attribute.attributeId = index + 1 + '';
            });
        }
        // attributeItem 
        let formType="CloseCallCheckList";
        dispatch(deleteAttributeAction(newAttributeArray, attributeItem, formType)); 
        this.setState({ attributeList: newAttributeArray }); 
        showAlert('Checklist updated successfully', 'check-list-form', 'success' ); 
    }
    onEditAttribute(item: IAttributeDataState) {
        dispatch(navigateAddAttribute('CloseCallCheckList'));
        dispatch(editAttribute(true, item));
        this.props.history?.push(`/safety-walkthroug/attribute`);
    } 
        //onSubmitCheckList = async () => {
    handleFormSubmit = async ({ projectName, groupName }: closeCallDetailProps) => {  
        const { editId } = this.props; 
        const submittedValues = {projectName, groupName};
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'assessment-form-failed', 'danger');
            return;
        }
        let currentAttrubuteList = this.state.attributeList;
        let time = new Date().toLocaleTimeString();
        let answers: any = [];
        //setLoading('SafetyWalkthroughAssesmentListing');
        this.setState({ isSubmit: true });
        currentAttrubuteList.map(item => {
            if (item.subAttributeList) {
                item.subAttributeList.map(i => {
                    let submitObject: any = {
                        mainAttr: item.attribute,
                        subAttr: i.name,
                        answer: ''
                    };
                    if (i.isAttributeNo) {
                        submitObject.answer = 'No';
                    } else if (i.isAttributeYes) {
                        submitObject.answer = 'Yes';
                    } else if (i.isAttributeNA) {
                        submitObject.answer = 'NA';
                    } else {
                        submitObject.answer = null;
                    }
                    answers.push(submitObject);
                });
            }
        });
        
        let submitData: any = {
            formType: 'CloseCallCheckList',
            userId: UserModel.getUserUID(),
            company: UserModel.getCompanyName(),
            date: format(this.state.submitDate, 'MM/dd/yyyy'),
            time: time,
            comment: this.state.comment,
            answers: answers
        }; 
        await submitCheckList(submitData, this.props.history, this.props.forms, 'closecallImages', 'closecallVideos', editId );
        this.setState({ isSubmit: false });
        this.setState({ comment: '' });
        this.setState({ submitDate: new Date() });
        await this.getCheckListData();
    }
    renderSubAttribute(subAttributeData: ISubAttributeData[], row: number) {

        return subAttributeData.map((item, index) => {
            return <Row key={`subAttributeRow${index}${row}`}
                className="customized-grid row-cell-height border-bottom
                 border-left border-right margin-left-right-0 padding-bottom-0">
                <Col className="row-cell-height border-right" sm={1} xs={12}>

                </Col>
                <Col className="row-cell-height" sm={8} xs={12}>
                    <Label sm={12}>{item.name}</Label>
                </Col>
                <Col className="row-cell-height  border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter">
                        <input className="localcss radio-button-size"
                            id={`.attributeYes${index}${row}`}
                            type="radio"
                            onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'yes')}
                            checked={item.isAttributeYes}
                            name={`attributeradio${index}${row}`}

                        />
                        <label htmlFor={`.attributeYes${index}${row}`}></label></div>
                </Col>
                <Col className="row-cell-height border-right border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter"><input
                        id={`.attributeNo${index}${row}`}
                        type="radio" className="radio-button-size"
                        onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'no')}
                        checked={item.isAttributeNo}
                        name={`attributeradio${index}${row}`}

                    /><label htmlFor={`.attributeNo${index}${row}`}></label></div>
                </Col>
                <Col className="row-cell-height " sm={1} xs={12}>
                    <div className="width-height-100-textcenter"><input
                        id={`.attributeNA${index}${row}`}
                        type="radio" className="radio-button-size"
                        onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'na')}
                        checked={item.isAttributeNA}
                        name={`attributeradio${index}${row}`}
                    /><label htmlFor={`.attributeNA${index}${row}`}></label></div>
                </Col>
            </Row>;
        });
    }

    renderAttribute(attributeList: IAttributeDataState[]) { 
        return attributeList.map((item, index) => {
            return <div key={`attributeRowParent${index}`}>
                <Row key={`attributeRow${index}`} 
                className="border-bottom row-cell-height border-left 
                border-right margin-left-right-0 padding-bottom-0 color-white">
                    <Col className="row-cell-height border-right" sm={1} xs={12}>
                        { ( UserModel.checkUserAccess('closecall_checklist_question_add') || UserModel.checkUserAccess('closecall_checklist_question_delete') ) && 
                                <DropdownButton title="" id="bg-vertical-dropdown-3">
                                    { UserModel.checkUserAccess('closecall_checklist_question_delete') && <MenuItem eventKey="2"
                                        onClick={() => this.onEditAttribute(item)}
                                        className="item">&nbsp;Edit</MenuItem> }
                                    { UserModel.checkUserAccess('closecall_checklist_question_add') && <MenuItem eventKey="1"
                                        onClick={() => this.onDeleteAttribute(index, item)}
                                        className="item">&nbsp;Delete</MenuItem> }
                                </DropdownButton>
                        }
                        <div className="sequence-number-position">
                            {`${item.attributeId}.`}
                        </div>
                    </Col>
                    <Col className="row-cell-height" sm={8} xs={12}>
                        <div className="width-height-100-textcenter">{item.attribute}</div>
                    </Col>
                    <Col className="row-cell-height border-left color-white" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height color-white" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height " sm={1} xs={12}>
                    </Col>
                </Row>
                {this.renderSubAttribute(item.subAttributeList, index)}
            </div>;
        });
    }

    renderContent() {
        const { attributeList } = this.state;
        const { firstName, editInstance } = this.props;
        /* if(!this.props.editInstance)
            history.push('/close-call-checklist-reports'); */
        return (
            <div className="project-list-wrapper"> 
                <Grid style={{ width: '100%' }} className="safety-walkthrough-assesment">
                    <Title titleColor="yellow" text="Close Call Checklist" noNeedBorder={true} />
                    <fieldset> 
                    {this.state.isSubmit && <SubmissionLoader />}
                    <Form loader={<SubmissionLoader />} model="forms.assessmentForm" onSubmit={this.handleFormSubmit}>
                        <Row className="show-grid margin-left-right-0">
                        <Col sm={3} xs={12} className="input">
                                <Label sm={12} htmlFor="currentDate">Manager:</Label>
                                <Col sm={12}  >{firstName}</Col>
                            </Col>
                            <Col sm={3} xs={12} className="input">
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <RRFInput
                                    id="currentDate"
                                    type="date"
                                    defaultValue={format(this.state.submitDate, 'yyyy-MM-dd')}
                                    model=".dateOfIncident"
                                    maxdate={format(new Date(), 'yyyy-MM-dd')} onChange={this.onDateChange}
                                /> 
                            </Col>
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={ (this.props.editInstance && this.props.editInstance.projectName ) || ( this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0] )} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={ (this.props.editInstance && this.props.editInstance.groupName) || (this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0])} />
                            </Col>
                        </Row>
                        <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                            <Col className="border-bottom border-right" sm={1} xs={12}>
                                <Label sm={12}>No.</Label>
                            </Col>
                            <Col className="border-bottom" sm={8} xs={12}>
                                <Label sm={12}>Attribute</Label>
                            </Col>
                            <Col className="border-bottom border-left" sm={1} xs={12}>
                                <Label sm={12}>Yes</Label>
                            </Col>
                            <Col className="border-bottom border-right border-left" sm={1} xs={12}>
                                <Label sm={12}>No</Label>
                            </Col>
                            <Col className="border-bottom" sm={1} xs={12}>
                                <Label sm={12}>N/A</Label>
                            </Col>
                        </Row>
                        {this.renderAttribute(attributeList)}

                        

                        <Row className="show-grid border-left border-right border-bottom margin-left-right-0">
                            <Col sm={2} xs={12}>
                                <div className="width-height-100-textleft"> Overall Comments:</div>
                            </Col>
                            <Col sm={10} xs={12}>
                                <div className="total-score-input">
                                    <input className="comment-input"
                                        id="observer"
                                        type="text"
                                        value={this.state.comment ||''}
                                        onChange={this.onCommentChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col /* smOffset={4} */ sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Images:</Label>
                                <Col sm={12} className="input">
                                    <FileInput
                                        model=".closecallImages"
                                        multiple={true}
                                        id="upload-images"
                                        defaultValue={editInstance && editInstance.imageUrls}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                                <Col sm={12} className="input">
                                    <VideoInput
                                        model=".closecallVideos"
                                        multiple={true}
                                        id="upload-videos"
                                        defaultValue={editInstance && editInstance.videoUrls}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="row-addrow-section" style={{}} >
                            <Col  >
                            
                                <Alert className="danger-alert" id="check-list-form-alert" />
                                <div className="table-buttons form-button"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '0'
                                    }}
                                >
                                    <Button style={{ color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white', height: '34px', width: '190px',}} redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }> <i className="fa fa-ban" aria-hidden="true"></i> &nbsp;  Cancel </Button>
                                    <Button type="submit" /* onClick={this.onSubmitCheckList} */ style={{
                                        color: '#FFFFFF',
                                        border: 'none',
                                        backgroundColor: '#26A65B',
                                        fontSize: '18px',
                                        height: '34px',
                                        borderRadius: '17px',
                                        outline: 'none',
                                        width: '190px',
                                        marginRight: '5px'
                                    }}
                                    >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                    &nbsp;
                                    { editInstance ? 'Update' : 'Submit' }
                                    </Button>
                                    { !editInstance ? <button
                                        className="add-row"
                                        type="button"
                                        onClick={this.onAddNewAttribute}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Attribute
                                    </button> : null }  
                                </div>
                            </Col>
                        </Row>
                        </Form>
                    </fieldset>
                </Grid> 
            </div>
        );
    }

    render() {
        
        return (
            <BasePage className="safeconnect-form" >
                    <AlertModal id="check-list-form" /> 
                    <DeleteConfirmationModal id="delete-confirmation" onConfirm={this.onConfirmDeleteAttribute}/>
                <Async
                    identifier="SafetyWalkthroughAssesmentListing"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const { login } = state;
    let firstName = login.get('firstName');
    let userId = login.get('userUID');  
    const editId = ownProps.match.params.id;
    const editInstance = editId && state.report.get('reportDetailData');  
    const userInstance = UserModel.get(userId);
    return {
        firstName,
        forms: state.forms,
        userId,
        attributeList: state.attribute.get('attributeList'),editId, editInstance, userInstance
    };
}

export const CloseCallCheckList = withRouter(connect<ICheckListProps, any, any>(mapStateToProps)(CheckList));
