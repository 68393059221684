import '../../forms.scss';   
import './style.scss';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';  
import { Col, Container as Grid, Row } from 'react-bootstrap';  
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom'; 
import { IForms, IHistory } from '../../interfaces';
import { UserModel } from '../../model/UserModel';
import { GetAllJSAForms } from '../../services/jsaFormService';
import { dispatch, renderATCSEHints, showAlert, convertToDateFormat, isArrayEmpty, isFormEmpty } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';  
import { ManualSignature } from '../reusableComponents/ManuallyGenerateSignature/ManualSignature'; 
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';   
import { ChecklistForm } from './ChecklistForm'; 
import { submitPermitToWorkForms, getFormData } from '../../services/permitToWorkService';
import { PermitToWorkModel ,IPermitToWorkModelProps } from '../../model/PermitToWorkModel';
import { ChecklistAttributeModel } from '../../model/ChecklistModel';
import { JSAModel } from '../../model/JSAModel';
import * as _ from 'lodash';
import { formatChecklistQuestions } from '../../services/attributeService'; 
/* import { PermitTypeForm } from './PermitTypeForm'; */  
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IWorkToPermitFormProps {
    userInstance: UserModel;
    userUID: string;
    userId: string; 
    company: string;
    history?: IHistory; 
    forms: IForms; 
    editId?: string;
    checklistAttributes?: any;
    jsaInstance: JSAModel;
    editInstance: PermitToWorkModel;
}

export interface IWorkToPermitFormState {
    currentDate: string;
    currentTime: string;
    formMinDate: string; 
    signatureTray: any; 
    signature: any;
    signatureURL: any;
    currentStep: number; navigateItems: any; 
    permitShiftHandoverStep: number[]; riskMitigationStep: number[]; isolationDetailStep: number[]; blindRegisterIsolationMethodStep: number[]; atmospericCSEStep: number[]; signOffStep: number[]; workTeamDeclerationStep: number[]; detecterCalibrationStep: number[];
    isolationControlPointsHint: boolean; shiftChangeMechanicalHint: boolean; shiftChangeSafetyHint: boolean;
    jsaTitle?: string; submitLoader: boolean; signOffDetails: any; 
    checklistComments: {};
}

export class WorkToPermitFormImpl extends React.PureComponent<IWorkToPermitFormProps, IWorkToPermitFormState> { 
    constructor(props: IWorkToPermitFormProps | Readonly<IWorkToPermitFormProps>) {
        super(props);
        this.state = {
            currentDate: convertToDateFormat(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd'),
            currentTime: format(new Date(), 'HH:mm'),
            formMinDate: convertToDateFormat(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd'), 
            signatureTray: {PIReviewer: '', OIReviewer: '', AGT: '', AtmospericAGT: '', isolationPIPlanVerification: '', isolationPIIsolationVerification: '', isolationOIIsolationVerification: '', deisolationPIIsolationVerification: '', isolationTemporaryDefeatPI: '', isolationTemporaryDefeatDefeatedBy: '',isolationTemporaryDefeatServiceReturnBy: '', isolationLongTermDefeatRequestedBy: '', isolationLongTermDefeatAuthorizationPI: '', isolationLongTermDefeatAuthorizationOI: '',permitCloseOutPI: '', permitCloseOutOI: '', permitCloseOutFI: '', }, 
            signature: {PIReviewer: '', OIReviewer: '', AGT: '', AtmospericAGT: '', isolationPIPlanVerification: '', isolationPIIsolationVerification: '',isolationOIIsolationVerification: '',  deisolationPIIsolationVerification: '', isolationTemporaryDefeatPI: '', isolationTemporaryDefeatDefeatedBy: '',isolationTemporaryDefeatServiceReturnBy: '',isolationLongTermDefeatRequestedBy: '', isolationLongTermDefeatAuthorizationPI: '', isolationLongTermDefeatAuthorizationOI: '',permitCloseOutPI: '', permitCloseOutOI: '', permitCloseOutFI: '', }, 
            signatureURL: {PIReviewer: '', OIReviewer: '', AGT:'', AtmospericAGT: '', isolationPIPlanVerification: '', isolationPIIsolationVerification: '',isolationOIIsolationVerification: '', deisolationPIIsolationVerification: '', isolationTemporaryDefeatPI: '', isolationTemporaryDefeatDefeatedBy: '',isolationTemporaryDefeatServiceReturnBy: '', isolationLongTermDefeatRequestedBy: '', isolationLongTermDefeatAuthorizationPI: '', isolationLongTermDefeatAuthorizationOI: '', permitCloseOutPI: '', permitCloseOutOI: '', permitCloseOutFI: '', }, 

            currentStep: 1, navigateItems: [{orderBy: 9,packName:'permitCloseOut'}],
            permitShiftHandoverStep: [1], riskMitigationStep: [1], isolationDetailStep: [1],blindRegisterIsolationMethodStep:[1], atmospericCSEStep:[1], signOffStep: [1], workTeamDeclerationStep: [1], detecterCalibrationStep:[1], 
            isolationControlPointsHint: false, shiftChangeMechanicalHint: false, shiftChangeSafetyHint: false, submitLoader: false, signOffDetails: {},  checklistComments: {}
        };   
    } 
    promise = async () => { 
        const { props: { editId, editInstance } } = this; 
        if(editId && !editInstance) {
            await getFormData(editId);
        }
        let answers = ( editInstance && editInstance.props.answers ) || ''; 
        if(JSAModel.list().length===0) {
            await GetAllJSAForms();
        }
        this.prepareJSANumDropdown();
        if(answers) { 
            //console.log(Object.keys(answers), Object.values(answers) )
            this.setState({signatureURL: {PIReviewer: answers.PIReviewerSignatureURL, OIReviewer: answers.OIReviewerSignatureURL, AGT: answers.AGTSignatureURL, AtmospericAGT: answers.AtmospericAGTSignatureURL, isolationPIPlanVerification: answers.isolationPIPlanVerificationSignatureURL, isolationPIIsolationVerification: answers.isolationPIIsolationVerificationSignatureURL,isolationOIIsolationVerification: answers.isolationOIIsolationVerificationSignatureURL, deisolationPIIsolationVerification: answers.deisolationPIIsolationVerificationSignatureURL, isolationTemporaryDefeatPI: answers.isolationTemporaryDefeatPISignatureURL, isolationTemporaryDefeatDefeatedBy: answers.isolationTemporaryDefeatDefeatedBySignatureURL,isolationTemporaryDefeatServiceReturnBy: answers.isolationTemporaryDefeatServiceReturnBySignatureURL, isolationLongTermDefeatRequestedBy: answers.isolationLongTermDefeatRequestedBySignatureURL, isolationLongTermDefeatAuthorizationPI: answers.isolationLongTermDefeatAuthorizationPISignatureURL, isolationLongTermDefeatAuthorizationOI: answers.isolationLongTermDefeatAuthorizationOISignatureURL, permitCloseOutPI: answers.permitCloseOutPISignatureURL, permitCloseOutOI: answers.permitCloseOutOISignatureURL, permitCloseOutFI: answers.permitCloseOutFISignatureURL, }, formMinDate: answers.plannedDateOfWork || this.state.currentDate}) 
            for (const [key, value] of Object.entries(answers)) { 
                switch(key) {
                    case 'PCOOIIsolationsRemovedNo': case 'PCOOIIsolationsRemovedYes': case 'pcoWorkCompletedYes': case 'pcoWorkCompletedNo': case 'closePermitCloseOut': case 'closeWorkDecleration': case 'isolationElectrical': case 'isolationMechanical': case 'isolationInstrumentationProcess': case 'isolationOtherSafety': case 'isolation2ICC': case 'isolation2RFIC': case 'isolation2Electrical': case 'isolation2Mechanical': case 'isolation2Instrumentation': case 'preEstablishedIsolation': case 'preEstablishedBlindList': case 'testFrequencyPrierToWorkYes': case 'testFrequencyPrierToWorkNo': case 'continuousGasTestYes': case 'continuousGasTestNo': case 'testFrequencyEveryHoursYes': case 'testFrequencyEveryHoursNo': case 'testFrequencyO2': case 'testFrequencyLEL': case 'testFrequencyH2S': case 'testFrequencyCO': case 'testFrequencyBenzene': case 'testFrequencySO2': case 'testFrequencyCO2': case 'testFrequencyOther': case 'abrasiveBlasting': case 'breakingContainment': case 'criticalLift': case 'drivingOperations': case 'excavationGroundDisturbance': case 'highRiskElectrical': case 'overTheSideRoad': case 'sparkPotential': case 'workAtHeights': case 'workingOnPressurizedSystems': case 'workingInExposedLocations': case 'otherCWP': case 'iccIsolations': case 'rficIsolations': case 'personnalIsolations': case 'noneIsolations': case 'admosphericTestRecords': case 'isolationCertificate': case 'jsatha': case 'swpChecklist':  case 'temporaryDefeat': case 'liftingPlan': case 'msds': case 'pidDrawing': case 'procedure': case 'rescuePlan': case 'simopDeviation': case 'workAid':
                        await dispatch(actions.change(`forms.permitToWorkForm.${key}`, (value==='1' || value==='true' || value===true?true:false )  )); 
                        break;
                    case 'checklistComments':
                        break;
                    default:
                        await dispatch(actions.change(`forms.permitToWorkForm.${key}`, value)); 
                }
            } 
            if(answers.signOffName && answers.signOffName.length>0) { 
                for (let i = 1; i < answers.signOffName.length; i++) { 
                    this.setState({signOffStep: [...this.state.signOffStep, i]}) 
                } 
            } 
            if(answers.pshDate && answers.pshDate.length>0) {  
                for (let i = 1; i < answers.pshDate.length; i++) { 
                    this.setState({permitShiftHandoverStep: [...this.state.permitShiftHandoverStep, i]}) 
                } 
            }
            if(answers.AT1CSEO2 && answers.AT1CSEO2.length>0) {  
                for (let i = 1; i < answers.AT1CSEO2.length; i++) { 
                    this.setState({atmospericCSEStep: [...this.state.atmospericCSEStep, i]}) 
                } 
            }
            if(answers.riskMitigationHazard && answers.riskMitigationHazard.length>0) {  
                for (let i = 1; i < answers.riskMitigationHazard.length; i++) { 
                    this.setState({riskMitigationStep: [...this.state.riskMitigationStep, i]}) 
                } 
            } 
            if(answers.isolationBlindRegisterDescription && answers.isolationBlindRegisterDescription.length>0) {  
                for (let i = 1; i < answers.isolationBlindRegisterDescription.length; i++) { 
                    this.setState({blindRegisterIsolationMethodStep: [...this.state.blindRegisterIsolationMethodStep, i]}) 
                } 
            }
           
            if(answers.detecterUsed && answers.detecterUsed.length>0) {  
                for (let i = 1; i < answers.detecterUsed.length; i++) { 
                    this.setState({detecterCalibrationStep: [...this.state.detecterCalibrationStep, i]}) 
                } 
            }
            if(answers.workTeamDeclerationDate && answers.workTeamDeclerationDate.length>0) {  
                for (let i = 1; i < answers.workTeamDeclerationDate.length; i++) { 
                    this.setState({workTeamDeclerationStep: [...this.state.workTeamDeclerationStep, i]}) 
                } 
            }
            let checklistIds = (answers.SWPChecklistType && answers.SWPChecklistType.split(',') ) || [];
            if(checklistIds.length>0) { 
                for(let i = 0; i<checklistIds.length; i++) {
                    await formatChecklistQuestions(checklistIds[i]);  
                }
                dispatch(actions.change(`forms.permitToWorkForm.attributeYes`, answers.attributeYes));
                dispatch(actions.change(`forms.permitToWorkForm.attributeNA`, answers.attributeNA));
            }

            if(answers.formJsaNo)
                await this.jsaNumChange(answers.formJsaNo);
            setTimeout(() => { this.onChangePermitPack() }, 500);
            let attachementFields = ['generalPermitLiftingPlanAttachments', 'generalPermitMSDSAttachments', 'generalPermitPIDAttachments', 'generalPermitProcedureAttachments', 'generalPermitRescuePlanAttachments', 'generalPermitSIMOPAttachments', /* 'generalPermitTemporaryDefeatAttachments', */ 'generalPermitWorkAidAttachments'];
            for (let i = 0; i < attachementFields.length; i++) {
                let signatureFieldURL = attachementFields[i]+'URL';
                dispatch(actions.change(`forms.permitToWorkForm.${signatureFieldURL}`, answers[signatureFieldURL])); 
            }
            await dispatch(actions.change(`forms.permitToWorkForm.permitType`, answers.permitType )); 
        }
        return;
    } 
    getChildContext() {
        return { formModel: 'forms.permitToWorkForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    handleFormSubmit  = async (values: IPermitToWorkModelProps) => { 
        const { props: { history } } = this; 
        let response = await this.submitPermitToWorkForms(JSON.parse(JSON.stringify(values)));
        if(response) { 
            showAlert(response.data.message, `PTW-modal`, 'success', async () => { 
                history?.push( `/home` ); 
            }); 
        } 
       // return response; 
    }
    submitPermitToWorkForms = async (formValues) => {
        const { props: {editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        let response = await submitPermitToWorkForms(formValues, 'permitToWork', this.props.history, this.props.forms, this.state.checklistComments, this.props.editId, answers);
        return response;
    } 
    renderSteps = () => {
        const { currentStep } = this.state;
        switch(currentStep) { 
            case 1: return this.renderStep1(); 
            case 2: return this.renderInternalSteps(0); 
            case 3: return this.renderInternalSteps(1); 
            case 4: return this.renderInternalSteps(2); 
            case 5: return this.renderInternalSteps(3); 
            case 6: return this.renderInternalSteps(4); 
            case 7: return this.renderInternalSteps(5); 
            case 8: return this.renderInternalSteps(6); 
            case 9: return this.renderInternalSteps(7); 
            /* case 8: return this.renderInternalSteps(8); 
            case 9: return this.renderInternalSteps(9); */ 
        }
    }
    renderInternalSteps = (step) => {
        const { currentStep, navigateItems, checklistComments } = this.state; 
        const { props: {editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        let checklistAttributes:any = [];
        (this.props.checklistAttributes || []).forEach(element => { 
            checklistAttributes.push(element.props)
        }); 
        switch(navigateItems[step]['packName']) {
            case 'admosphericTestRecords': return this.renderStep2(); 
            case 'isolationCertificate': return this.renderStep3();
            case 'jsatha': return this.renderStep4();
            //case 'swpChecklist': return this.renderStep5();
            case 'swpChecklist': return <><ChecklistForm currentStep={currentStep} company={this.props.company} forms={this.props.forms} checklistAttributes={checklistAttributes} editInstance={answers} checklistComments={checklistComments} onChange={this.updateChecklistComments} /></>; 
            case 'temporaryDefeat': return this.renderStep6();
            case 'generalForms': return this.renderStep7();
            case 'signOffForms': return this.renderStep8();
            case 'workTeamDeclaration': return this.renderStep9();
            case 'permitCloseOut': return this.renderStep10();
        }
    } 
    updateChecklistComments = async (value, model) => { 
        let checklistComments: any = {...this.state.checklistComments}; 
        checklistComments[model] = value;
        await this.setState({checklistComments}) 
    }
    onChangePermitPack = () => { 
        const {forms} = this.props;
        let navigateItems: any = []; 
        navigateItems.push({orderBy: 8,packName:'permitCloseOut'})
        navigateItems.push({orderBy: 7, packName:'signOffForms'})
        setTimeout(() => {  
            if(forms.permitToWorkForm.admosphericTestRecords) {
                navigateItems.push({orderBy: 1,packName:'admosphericTestRecords'})
            }
            if(forms.permitToWorkForm.isolationCertificate) {
                navigateItems.push({orderBy: 2,packName:'isolationCertificate'})
            }
            if(forms.permitToWorkForm.jsatha) {
                navigateItems.push({orderBy: 3,packName:'jsatha'})
            }  
            if(forms.permitToWorkForm.swpChecklist) {
                navigateItems.push({orderBy: 4,packName:'swpChecklist'})
            }
            if(forms.permitToWorkForm.temporaryDefeat) {
                navigateItems.push({orderBy: 5,packName:'temporaryDefeat'})
            }
            /* if(forms.permitToWorkForm.permitType==='Confined Space' ) {
                navigateItems.push({orderBy: 8, packName:'workTeamDeclaration'})
            } */
            if(navigateItems.indexOf("generalForms") <0 && ( forms.permitToWorkForm.liftingPlan || forms.permitToWorkForm.msds || forms.permitToWorkForm.pidDrawing || forms.permitToWorkForm.procedure || forms.permitToWorkForm.rescuePlan || forms.permitToWorkForm.simopDeviation || forms.permitToWorkForm.workAid ) ) {
                navigateItems.push({orderBy: 6, packName:'generalForms'}) 
            }
            navigateItems = _.sortBy( navigateItems, 'orderBy' ); 
            this.setState({navigateItems: navigateItems }) 
        }, 500);
    }  
    previousStep = async (type?) => {
        const { props: {forms, editId, history } } = this;  
        this.setState({submitLoader: true});
        if(type) {
            let response = await this.submitPermitToWorkForms(JSON.parse(JSON.stringify(forms.permitToWorkForm)));
            if(response) { 
                if(!editId) { 
                    showAlert(response.data.message, `PTW-modal`, 'success', async () => {
                    });  
                    history?.push( `/permit-to-work/edit/${response?.data.submittedFormId}` );
                }
            }
        } 
        this.setState({currentStep: this.state.currentStep-1, submitLoader: false }) 
    }
    nextStep = async (type?) => {  
        const { props: {forms, editId, history } } = this; 
        this.setState({submitLoader: true});
        if(type) {
            let response = await this.submitPermitToWorkForms(JSON.parse(JSON.stringify(forms.permitToWorkForm)));
            if(response) { 
                if(!editId) { 
                    showAlert(response.data.message, `PTW-modal`, 'success', async () => {
                    });  
                    history?.push( `/permit-to-work/edit/${response?.data.submittedFormId}` );
                }
            }
        } 
        this.setState({currentStep: this.state.currentStep+1, submitLoader: false })
    }
    navigateWithoutSave = async (type) => {
        if(type===1) {
            this.previousStep();
        } else {
            this.nextStep(); 
        }
    }
    handlePermitType = (value: string) => {
        //this.setState({permitType: value})
        this.onChangePermitPack();
    } 
    saveSignature = (file: any, acceptorSignature: any, model: any) => { 
        let signatureTray = this.state.signatureTray;
        let signature = this.state.signature;
        let signatureURL = this.state.signatureURL;
        model = model.split('.')[1]; 
        switch(model) {
            case 'PIReviewerSignature':
                signatureTray.PIReviewer =  false; signature.PIReviewer =  file; signatureURL.PIReviewer =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.PIReviewerSignature`, acceptorSignature));
                break;
            case 'OIReviewerSignature':
                signatureTray.OIReviewer =  false; signature.OIReviewer =  file; signatureURL.OIReviewer =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.OIReviewerSignature`, acceptorSignature));
                break;
            case 'AtmospericAGTSignature':
                signatureTray.AtmospericAGT =  false; signature.AtmospericAGT =  file; signatureURL.AtmospericAGT =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.AtmospericAGTSignature`, acceptorSignature));
                break;
            case 'isolationPIPlanVerificationSignature':
                signatureTray.isolationPIPlanVerification =  false; signature.isolationPIPlanVerification =  file; signatureURL.isolationPIPlanVerification =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationPIPlanVerificationSignature`, acceptorSignature));
                break;
            case 'isolationPIIsolationVerificationSignature':
                signatureTray.isolationPIIsolationVerification =  false; signature.isolationPIIsolationVerification =  file; signatureURL.isolationPIIsolationVerification =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationPIIsolationVerificationSignature`, acceptorSignature));
                break;
            case 'isolationOIIsolationVerificationSignature':
                signatureTray.isolationOIIsolationVerification =  false; signature.isolationOIIsolationVerification =  file; signatureURL.isolationOIIsolationVerification =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationOIIsolationVerificationSignature`, acceptorSignature));
                break;
            case 'deisolationPIIsolationVerificationSignature':
                signatureTray.deisolationPIIsolationVerification =  false; signature.deisolationPIIsolationVerification =  file; signatureURL.deisolationPIIsolationVerification =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.deisolationPIIsolationVerificationSignature`, acceptorSignature));
                break;
            case 'isolationTemporaryDefeatPISignature':
                signatureTray.isolationTemporaryDefeatPI =  false; signature.isolationTemporaryDefeatPI =  file; signatureURL.isolationTemporaryDefeatPI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatPISignature`, acceptorSignature));
                break;
            case 'isolationTemporaryDefeatDefeatedBySignature':
                signatureTray.isolationTemporaryDefeatDefeatedBy =  false; signature.isolationTemporaryDefeatDefeatedBy = file; signatureURL.isolationTemporaryDefeatDefeatedBy =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatDefeatedBySignature`, acceptorSignature));
                break;
            case 'isolationTemporaryDefeatServiceReturnBySignature':
                signatureTray.isolationTemporaryDefeatServiceReturnBy =  false; signature.isolationTemporaryDefeatServiceReturnBy = file; signatureURL.isolationTemporaryDefeatServiceReturnBy =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatServiceReturnBySignature`, acceptorSignature));
                break;
            case 'isolationLongTermDefeatRequestedBySignature':
                signatureTray.isolationLongTermDefeatRequestedBy =  false; signature.isolationLongTermDefeatRequestedBy = file; signatureURL.isolationLongTermDefeatRequestedBy =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatRequestedBySignature`, acceptorSignature));
                break;
            case 'isolationLongTermDefeatAuthorizationPISignature':
                signatureTray.isolationLongTermDefeatAuthorizationPI =  false; signature.isolationLongTermDefeatAuthorizationPI = file; signatureURL.isolationLongTermDefeatAuthorizationPI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatAuthorizationPISignature`, acceptorSignature));
                break;
            case 'isolationLongTermDefeatAuthorizationOISignature':
                signatureTray.isolationLongTermDefeatAuthorizationOI =  false; signature.isolationLongTermDefeatAuthorizationOI = file; signatureURL.isolationLongTermDefeatAuthorizationOI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatAuthorizationOISignature`, acceptorSignature));
                break;
            case 'permitCloseOutPISignature':
                signatureTray.permitCloseOutPI =  false; signature.permitCloseOutPI = file; signatureURL.permitCloseOutPI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.permitCloseOutPISignature`, acceptorSignature));
                break;
            case 'permitCloseOutOISignature':
                signatureTray.permitCloseOutOI =  false; signature.permitCloseOutOI = file; signatureURL.permitCloseOutOI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.permitCloseOutOISignature`, acceptorSignature));
                break;
            case 'permitCloseOutFISignature':
                signatureTray.permitCloseOutFI =  false; signature.permitCloseOutFI = file; signatureURL.permitCloseOutFI =  acceptorSignature ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.permitCloseOutFISignature`, acceptorSignature));
                break;
        }
    }
    saveGeneratedSignature = (file: any, base64: any, model: any) => { 
        let signatureTray = this.state.signatureTray;
        let signature = this.state.signature;
        let signatureURL = this.state.signatureURL;
        model = model.split('.')[1]; 
        switch(model) {
            case 'PIReviewerSignature':
                signatureTray.PIReviewer =  false; signature.PIReviewer =  file; signatureURL.PIReviewer =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });   
                dispatch(actions.change(`forms.permitToWorkForm.PIReviewerSignature`, base64));
                break;
            case 'OIReviewerSignature': 
                signatureTray.OIReviewer =  false; signature.OIReviewer =  file; signatureURL.OIReviewer =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });   
                dispatch(actions.change(`forms.permitToWorkForm.OIReviewerSignature`, base64));
                break;
            case 'AtmospericAGTSignature':
                signatureTray.AtmospericAGT =  false; signature.AtmospericAGT =  file; signatureURL.AtmospericAGT =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.AtmospericAGTSignature`, base64));
                break;
            case 'isolationPIPlanVerificationSignature':
                signatureTray.isolationPIPlanVerification =  false; signature.isolationPIPlanVerification =  file; signatureURL.isolationPIPlanVerification =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationPIPlanVerificationSignature`, base64));
                break;
            case 'isolationPIIsolationVerificationSignature':
                signatureTray.isolationPIIsolationVerification =  false; signature.isolationPIIsolationVerification =  file; signatureURL.isolationPIIsolationVerification =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationPIIsolationVerificationSignature`, base64));
                break;
            case 'isolationOIIsolationVerificationSignature':
                signatureTray.isolationOIIsolationVerification =  false; signature.isolationOIIsolationVerification =  file; signatureURL.isolationOIIsolationVerification =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationOIIsolationVerificationSignature`, base64));
                break;
            case 'deisolationPIIsolationVerificationSignature':
                signatureTray.deisolationPIIsolationVerification =  false; signature.deisolationPIIsolationVerification =  file; signatureURL.deisolationPIIsolationVerification =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.deisolationPIIsolationVerificationSignature`, base64));
                break;
            case 'isolationTemporaryDefeatPISignature':
                signatureTray.isolationTemporaryDefeatPI =  false; signature.isolationTemporaryDefeatPI =  file; signatureURL.isolationTemporaryDefeatPI =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatPISignature`, base64));
                break;
            case 'isolationTemporaryDefeatDefeatedBySignature':
                signatureTray.isolationTemporaryDefeatDefeatedBy =  false; signature.isolationTemporaryDefeatDefeatedBy = file; signatureURL.isolationTemporaryDefeatDefeatedBy =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatDefeatedBySignature`, base64));
                break;
            case 'isolationTemporaryDefeatServiceReturnBySignature':
                signatureTray.isolationTemporaryDefeatServiceReturnBy =  false; signature.isolationTemporaryDefeatServiceReturnBy = file; signatureURL.isolationTemporaryDefeatServiceReturnBy = base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationTemporaryDefeatServiceReturnBySignature`, base64));
                break;
            case 'isolationLongTermDefeatRequestedBySignature':
                signatureTray.isolationLongTermDefeatRequestedBy =  false; signature.isolationLongTermDefeatRequestedBy = file; signatureURL.isolationLongTermDefeatRequestedBy =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatRequestedBySignature`, base64));
                break;
            case 'isolationLongTermDefeatAuthorizationPISignature':
                signatureTray.isolationLongTermDefeatAuthorizationPI =  false; signature.isolationLongTermDefeatAuthorizationPI = file; signatureURL.isolationLongTermDefeatAuthorizationPI = base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatAuthorizationPISignature`, base64 ));
                break; 
            case 'isolationLongTermDefeatAuthorizationOISignature':
                signatureTray.isolationLongTermDefeatAuthorizationOI =  false; signature.isolationLongTermDefeatAuthorizationOI = file; signatureURL.isolationLongTermDefeatAuthorizationOI =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.isolationLongTermDefeatAuthorizationOISignature`, base64));
                break;
            case 'permitCloseOutOISignature':
                signatureTray.permitCloseOutOI =  false; signature.permitCloseOutOI = file; signatureURL.permitCloseOutOI =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.permitCloseOutOISignature`, base64));
                break;
            case 'permitCloseOutFISignature':
                signatureTray.permitCloseOutFI =  false; signature.permitCloseOutFI = file; signatureURL.permitCloseOutFI =  base64 ; 
                this.setState({ signatureTray: signatureTray, signature: signature, signatureURL: signatureURL, });  
                dispatch(actions.change(`forms.permitToWorkForm.permitCloseOutFISignature`, base64));
                break;
        }
    } 
    renderSignatureField = (SignatureURL: any ='', SignatureTrayParam = '', isDisabled = false) => {
        return <><Row className="show-grid" style={{ paddingBottom: '20px' }} > 
            <Col sm={12} style={{ color: 'green' }} className="signature">
                { SignatureURL ?<img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ SignatureURL } alt="SafeConnect" /> : 'No signature found'}
                {!isDisabled && <div>
                    <button type="button"
                        className="button"
                        style={newSign}
                        onClick={() => { this.changeSignatureTray(SignatureTrayParam) } }>
                        Sign New
                    </button>
                </div> }
            </Col>
        </ Row ></>;
    }
    changeSignatureTray = (SignatureTrayParam: string) => {
        let signatureTray = this.state.signatureTray;
        signatureTray[SignatureTrayParam] = true;
        this.setState({signatureTray: signatureTray});
        dispatch(actions.change(`forms.permitToWorkForm.${SignatureTrayParam}Signature`, '')); 
    }
    accordianProcess = (type: string) => {
        switch(type) {
            case '1': this.setState({isolationControlPointsHint: !this.state.isolationControlPointsHint}); break;
            case 'shiftChangeMechanicalHint': this.setState({shiftChangeMechanicalHint: !this.state.shiftChangeMechanicalHint }); break;
            case 'shiftChangeSafetyHint': this.setState({shiftChangeSafetyHint: !this.state.shiftChangeSafetyHint }); break;
        }
    }  
    renderContent = () => {
        const { forms } = this.props;
        const { handleFormSubmit, } = this; 
        return (
            <Grid className="oi-form permit-to-work-form" style={{ width: '100%' }}>
                <MapInputModal id="oi-form" />
                <Form loader={<SubmissionLoader />} model="forms.permitToWorkForm" onSubmit={handleFormSubmit}>
                    {this.state.submitLoader && <SubmissionLoader />}
                    <Title titleColor="yellow" text="Permit to Work" noNeedBorder={true}/>
                    <AlertModal id="PTW-modal" /> 
                    <div className="form-header">
                        {forms.permitToWorkForm.permitType &&<>
                            <div className="backward-button">
                                {this.state.currentStep >1 ?<span className="navigatePreviousButton" onClick={ this.previousStep}>Save & Previous</span> : ''}
                                {this.state.currentStep >1 ?<span className="navigatePreviousButton previousBtnOnly" onClick={() => this.navigateWithoutSave(1)}>Previous</span> : ''}
                            </div> 
                            <div className="forward-button">
                                {this.state.currentStep <this.state.navigateItems.length+1 ?<span className="navigateNextButton nextBtnOnly" onClick={() => this.navigateWithoutSave(2)}>Next</span> : '' }
                                {this.state.currentStep <this.state.navigateItems.length+1 ?<span className="navigateNextButton" onClick={ this.nextStep }>Save & Next</span> : '' }
                            </div></> 
                        }
                        {this.renderSteps()} 
                    </div>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="PTW-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ "fa fa-refresh" }  aria-hidden="true"></i>
                            &nbsp;
                            { this.state.currentStep === this.state.navigateItems.length+1?'Submit':'Save' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid> 
        );
    } 
    jsaNumDropdown: any = [];
    prepareJSANumDropdown = () => { 
        this.jsaNumDropdown.push({'label':  'External JSA' , 'value': 'Other JSA', 'title': 'External JSA',  }); 
        if(JSAModel.list().length>0) {
            (JSAModel.list() ).forEach(instance => {
                this.jsaNumDropdown.push({'label': instance.props.jsaNum + ' ('+instance.props.title+')' , 'value': instance.props.id, 'title': instance.props.title,  }); 
            });
        }   
        return this.jsaNumDropdown;
    }
    jsaNumChange = (value: string) => {
        const jsa = this.jsaNumDropdown.find(item => item.value === value); 
        if(jsa) {
            this.setState({jsaTitle: jsa.title})
        }  
        return;
    }
    renderStep10 = () => {
    const { props: {forms, editInstance }, state: { currentDate } } = this; 
    let answers = (editInstance && editInstance.props.answers) || '';
    return (
        <div> 
            <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Permit Close-Out</label>
            <Row className="show-grid" >
                <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'18px'}}> Permit Close-Out</b> </p>
            </Row>
            <Row className="show-grid" >
                <Col sm={{span: 4, offset: 4}} xs={12}>
                    <Label required sm={12}  >Permit No:</Label>
                    {/* <Col sm={12} className="input">
                        <RRFInput
                            id="permitNo"
                            type="text" 
                            model=".permitNo"  
                            defaultValue={forms.permitToWorkForm.permitNo}
                        />
                    </Col> */} 
                    <Label style={{color: 'white'}} sm={12} htmlFor="permitNoVal">{forms.permitToWorkForm.permitNo}</Label>
                </Col>
            </Row>
            <Row className="show-grid" >
                <p style={{color: "yellow" }}> Work associated with this permit has been completed? </p>
                <Col sm={{span: 1, offset: 5}} className="input">
                    <label className="checkbox-label" htmlFor="pcoWorkCompletedYes"> Yes</label>
                    <RRFInput
                        id="pcoWorkCompletedYes"
                        type="checkbox"
                        model=".pcoWorkCompletedYes" 
                        onChange={this.onChangeQuestionYesNo} 
                    />
                </Col>
                <Col sm={1} className="input">
                    <label className="checkbox-label" htmlFor="pcoWorkCompletedNo">No</label>
                    <RRFInput
                        id="pcoWorkCompletedNo"
                        type="checkbox"
                        model=".pcoWorkCompletedNo" 
                        onChange={this.onChangeQuestionYesNo} 
                    />
                </Col>
            </Row>
            <Row className="show-grid" >
                <Alert className="danger-alert" id="PTW-form-closeout-1-failed" />
            </Row>
            {forms.permitToWorkForm.pcoWorkCompletedYes && 
            <><Row className="show-grid" >
                <Col sm={4}>
                    <span style={{ color: 'yellow' }} >Name</span>
                    <span style={{ color: 'red' }} >*</span>
                </Col> 
                <Col sm={2}>
                    <span style={{ color: 'yellow' }} >Sign Off</span>
                    <span style={{ color: 'red' }} >*</span>
                </Col>
                <Col sm={2}>
                    <span style={{ color: 'yellow' }} >Time In</span>
                    <span style={{ color: 'red' }} >*</span>
                </Col>
                <Col sm={2}>
                    <span style={{ color: 'yellow' }} >Time Out</span>
                    <span style={{ color: 'red' }} >*</span>
                </Col>
                <Col sm={2}>
                    <span style={{ color: 'yellow' }} ></span> 
                </Col>
            </Row>
            {this.renderSignOffs()} 
            </> }
            
            <Row className="show-grid" >
                <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'16px'}}> Permit Holder:</b> I am satisified that the worksite has been restored to a safe and tidy condition. All isolations relevant to this permit must follow the de-isolation procedure prior to reenergizing, or a long term isolation (LTI) must be applied.</p>
            </Row> 
            <Row className="show-grid" >
                <Col sm={3} className="input">
                    <p style={{color: "yellow" }}> Have isolations been removed? </p>
                </Col>
               
                <Col sm={2} className="input">
                    <label className="checkbox-label" htmlFor="PCOOIIsolationsRemovedYes"> Yes</label>
                    <RRFInput
                        id="PCOOIIsolationsRemovedYes"
                        type="checkbox"
                        model=".PCOOIIsolationsRemovedYes" 
                        onChange={this.onChangeQuestionYesNo}
                        //defaultValue={answers && answers.PCOOIIsolationsRemovedYes}
                    />
                </Col>
                <Col sm={2} className="input">
                    <label className="checkbox-label" htmlFor="PCOOIIsolationsRemovedNo"> No</label>
                    <RRFInput
                        id="PCOOIIsolationsRemovedNo"
                        type="checkbox"
                        model=".PCOOIIsolationsRemovedNo" 
                        onChange={this.onChangeQuestionYesNo}
                        //defaultValue={answers && answers.PCOOIIsolationsRemovedNo && (answers.PCOOIIsolationsRemovedNo==='1'?true:false)} 
                    />
                </Col>
            </Row> 
            <Row className="show-grid" >
                <Col sm={12} xs={12}>
                    <Label sm={12}>Work Status Comments:</Label>                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.PCOPIWorkStatusComments} model="forms.permitToWorkForm.PCOPIWorkStatusComments" /> 
                    <Col sm={12} className="input">
                        <RRFInput 
                            id="PCOPIWorkStatusComments"
                            type="textarea" 
                            model=".PCOPIWorkStatusComments" 
                            defaultValue={answers && answers.PCOPIWorkStatusComments}
                        />
                    </Col>
                </Col>
            </Row>
            <Row className="show-grid" >
                <Col sm={3} xs={12}>
                    <Label sm={12}>Print Name:</Label>
                    <Col sm={12} className="input">
                        <RRFInput
                            id="PCOOIName"
                            type="text" 
                            model=".PCOOIName" 
                            defaultValue={answers && answers.PCOOIName}
                        />
                    </Col>
                </Col>   
                {/* <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                    <Label required sm={12}>Sign:</Label> 
                    {this.state.signatureTray.permitCloseOutOI ? 
                        <ManualSignature manualSignsm={12}
                            autoSignTitle={ forms.permitToWorkForm.PCOOIName || 'OI'}
                            saveSignature={this.saveSignature}
                            saveGeneratedSignature={this.saveGeneratedSignature}
                            model=".permitCloseOutOISignature"
                            className={'signature'}
                            autoGenerateProps={ { className: 'jsa-signature-generator' } }
                        /> : this.renderSignatureField(this.state.signatureURL.permitCloseOutOI, 'permitCloseOutOI')}
                </Col> */}  
                <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                    <Label required sm={12}>Sign:</Label> 
                    {this.state.signatureTray.permitCloseOutFI ? 
                        <ManualSignature manualSignsm={12}
                            autoSignTitle={ forms.permitToWorkForm.PCOOIName || 'FI'}
                            saveSignature={this.saveSignature}
                            saveGeneratedSignature={this.saveGeneratedSignature}
                            model=".permitCloseOutFISignature"
                            className={'signature'}
                            autoGenerateProps={ { className: 'jsa-signature-generator' } }
                        /> : this.renderSignatureField(this.state.signatureURL.permitCloseOutFI, 'permitCloseOutFI')}
                </Col> 
                <Col sm={3} xs={12}>
                    <Label sm={12}>Date:</Label>
                    <Col sm={12} className="input">
                        <RRFInput 
                            id="PCOOIDate"
                            type="date" 
                            model=".PCOOIDate" 
                            defaultValue={(forms.permitToWorkForm.PCOOIDate && format(new Date(forms.permitToWorkForm.PCOOIDate), 'yyyy-MM-dd') ) || null }
                            maxdate={currentDate} 
                        />
                    </Col>
                </Col> 
                <Col sm={2} xs={12}>
                    <Label sm={12}>Time:</Label>
                    <Col sm={12} className="input">
                        <RRFInput 
                            id="PCOOITime"
                            type="time" 
                            model=".PCOOITime" 
                            defaultValue={answers && answers.PCOOITime}
                        />
                    </Col>
                </Col> 
            </Row> 
            <Row className="show-grid" >
                <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'16px'}}> FI:</b> I confirm that all personnel have been accounted for and the worksite has been restored to a safe and tidy working condition.</p>
            </Row> 
            <Row className="show-grid" >
                <Col sm={12} className="input text-center">
                    <label className="checkbox-label" htmlFor="closePermitCloseOut">Agree to close this permit to work?</label>
                    <RRFInput
                        id="closePermitCloseOut"
                        type="checkbox"
                        model=".closePermitCloseOut"  
                        onChange={this.onChangeclosePermitCloseOut} 
                        //defaultValue={answers && answers.closePermitCloseOut}
                    />
                </Col>
            </Row>
            {forms.permitToWorkForm.closePermitCloseOut && 
                <>
                <Row className="show-grid" >
                    <Col sm={3} xs={12}>
                        <Label sm={12}>Print Name:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="PCOPIName"
                                type="text" 
                                model=".PCOPIName" 
                                defaultValue={answers && answers.PCOPIName}
                            />
                        </Col>
                    </Col> 
                    <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                        <Label required sm={12}>Sign:</Label> 
                        {this.state.signatureTray.permitCloseOutPI ? 
                            <ManualSignature manualSignsm={12}
                                autoSignTitle={ forms.permitToWorkForm.PCOPIName || 'FI'}
                                saveSignature={this.saveSignature}
                                saveGeneratedSignature={this.saveGeneratedSignature}
                                model=".permitCloseOutPISignature"
                                className={'signature'}
                                autoGenerateProps={ { className: 'jsa-signature-generator' } }
                            /> : this.renderSignatureField(this.state.signatureURL.permitCloseOutPI, 'permitCloseOutPI')}
                    </Col>  
                    <Col sm={3} xs={12}>
                        <Label sm={12}>Date:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="PCOPIDate"
                                type="date" 
                                model=".PCOPIDate" 
                                defaultValue={(forms.permitToWorkForm.PCOPIDate && format(new Date(forms.permitToWorkForm.PCOPIDate), 'yyyy-MM-dd') ) || null }
                                maxdate={currentDate} 
                            />
                        </Col>
                    </Col>
                    <Col sm={2} xs={12}>
                        <Label sm={12}>Time:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="PCOPITime"
                                type="time" 
                                model=".PCOPITime" 
                                defaultValue={answers && answers.PCOPITime}
                            />
                        </Col>
                    </Col>
                    
                </Row> 
            </> }
            
            
        </fieldset>
        </div>);
    }
    renderStep9 = () => { 
        const { props: { forms } } = this;
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Work Team Declaration</label>
                    <p style={{color: 'yellow'}}> Work team members must sign before commencing work for the first time to indicate that they have reviewed the Permit Pack with the Permit Holder. Each person understands the scope of work, their responsibilities and is competent to execute their part of the Permit Pack (including JSA, Procedures, and Checklists) to complete the work safely.

                    If any person observes an unsafe situation developing they must immediately stop work, inform their co-workers, make the worksite safe, and inform the Permit Holder and the Area Operator.
                    Note: Workers reassigned, crew changing, or no longer working on this permit while the permit is valid must sign off. </p>
                    <Row className="show-grid" >
                        <Col sm={2}> <span style={{ color: 'yellow' }} >Date</span> <span style={{ color: 'red' }} >*</span> </Col> 
                        <Col sm={3}>
                            <span style={{ color: 'yellow' }} >Name</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col> 
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Date (if different) </span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Sign On</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Sign Off</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={1}>
                            <span style={{ color: 'yellow' }} ></span> 
                        </Col>
                    </Row>
                    {this.renderWorkTeamDecleration} 
                    <Row className="show-grid" onClick={()=> this.incrementWorkTeamDeclerationRows}>
                        <div className="table-buttons">
                            <button className="add-row" type="button">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Another Step
                            </button>
                        </div>
                    </Row>

                </fieldset>
            </div>);
    }
    renderStep8= () => { 
        const { props: {forms } } = this;  
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Work Declaration</label>
                    {/* <Row className="show-grid">
                        <label>Confined Space Entry - Personnel Log-In/Log-Out Sheet (Supplement)</label>
                    </Row> */}
                    <Row className="show-grid">
                        <label style={{color: '#fff', textAlign:'left'}}><p style={{textIndent: '20px'}}>Work team members must sign before commencing work for the first time to indicate that they have reviewed the Permit Pack with the Permit Holder. Each person understands the scope of work, their responsibilities and is competent to execute their part of the Permit Pack (including JSA, Procedures, and Checklists) to complete the work safely.</p>
                        <p style={{textIndent: '20px'}}>If any person observes an unsafe situation developing they must immediately stop work, inform their co-workers, make the worksite safe, and inform the Permit Holder and the Area Operator.</p>
                        <p style={{textIndent: '20px'}}>Note: Workers reassigned, crew changing, or no longer working on this permit while the permit is valid must sign off.</p> </label>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={{span: 4, offset: 4}} xs={12}>
                            <Label required sm={12}  >Permit No:</Label>
                            {/* <Col sm={12} className="input">
                                <RRFInput
                                    id="permitNo"
                                    type="text" 
                                    model=".permitNo"  
                                    defaultValue={forms.permitToWorkForm.permitNo}
                                />
                            </Col> */} 
                            <Label style={{color: 'white'}} sm={12} htmlFor="permitNoVal">{forms.permitToWorkForm.permitNo}</Label>
                        </Col>
                        {/* {forms.permitToWorkForm.permitType=='Confined Space' && 
                            <Col sm={12} xs={12}>
                                <Label required sm={12} htmlFor="signOffReasonForEntry">Reason for Entry:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="signOffReasonForEntry"
                                        type="textarea" 
                                        model=".signOffReasonForEntry" 
                                        defaultValue={answers && answers.signOffReasonForEntry}
                                    />
                                </Col>
                            </Col>
                        } */}
                    </Row>
                    <Row className="show-grid" >
                        <Col sm={4}>
                            <span style={{ color: 'yellow' }} >Name</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col> 
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Sign Off</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Time In</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} >Time Out</span>
                            <span style={{ color: 'red' }} >*</span>
                        </Col>
                        <Col sm={2}>
                            <span style={{ color: 'yellow' }} ></span> 
                        </Col>
                    </Row>
                    {this.renderSignOffs()} 
                    <Row className="show-grid">
                        <div className="table-buttons">
                            <button className="add-row" type="button" onClick={()=>this.incrementSignOffRows}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Another Step
                            </button>
                        </div>
                    </Row>
                    {/* {forms.permitToWorkForm.permitType=='Confined Space' &&
                        <Row className="show-grid" > 
                            <Col sm={4} xs={12}> 
                                <Label sm={12}>Confined Space Entry Attendant</Label>
                            </Col>
                            <Col sm={3} xs={12}> 
                                <Label required sm={12}>Time In print name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="signOffsTimeInEntryAttendantName"
                                        type="text" 
                                        model=".signOffsTimeInEntryAttendantName" 
                                        defaultValue={answers && answers.signOffsTimeInEntryAttendantName} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>  
                                <Label required sm={12}>Time Out print name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="signOffsTimeOutEntryAttendantName"
                                        type="text" 
                                        model=".signOffsTimeOutEntryAttendantName"
                                        defaultValue={answers && answers.signOffsTimeOutEntryAttendantName} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                    } */}
                    <Row className="show-grid" >
                        <Col sm={12} className="input text-center">
                            <label className="checkbox-label" htmlFor="closeWorkDecleration">Confirm- Finalize this Permit to Work?</label>
                            <RRFInput
                                id="closeWorkDecleration"
                                type="checkbox"
                                model=".closeWorkDecleration" 
                                //defaultValue={answers && answers.closeWorkDecleration}
                            />
                        </Col>
                    </Row>

                </fieldset>
            </div>
        );
    }
    renderStep7= () => {
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}> Form {this.state.currentStep} - General Permit Pack Documents</label>
                    {/* {forms.permitToWorkForm.temporaryDefeat && 
                    <div>
                    <label> Temporary Defeat</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitTemporaryDefeatRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitTemporaryDefeatRefNo"
                                type="text"
                                model=".generalPermitTemporaryDefeatRefNo" 
                                defaultValue={answers && answers.generalPermitTemporaryDefeatRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitTemporaryDefeatAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitTemporaryDefeatAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> } */}
                    {forms.permitToWorkForm.liftingPlan && 
                    <div>
                    <label> Lifting Plan</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitLiftingPlanRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitLiftingPlanRefNo"
                                type="text"
                                model=".generalPermitLiftingPlanRefNo" 
                                defaultValue={answers && answers.generalPermitLiftingPlanRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitLiftingPlanAttachments"
                                    multiple={true}
                                    id="upload-images"  
                                    uploadedImages={forms.permitToWorkForm.generalPermitLiftingPlanAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.msds && 
                    <div>
                    <label> MSDS</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitMSDSRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitMSDSRefNo"
                                type="text"
                                model=".generalPermitMSDSRefNo" 
                                defaultValue={answers && answers.generalPermitMSDSRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitMSDSAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitMSDSAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.pidDrawing && 
                    <div>
                    <label> P&ID/Drawing</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitPIDRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitPIDRefNo"
                                type="text"
                                model=".generalPermitPIDRefNo" 
                                defaultValue={answers && answers.generalPermitPIDRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitPIDAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitPIDAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.procedure && 
                    <div>
                    <label> Procedure</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitProcedureRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitProcedureRefNo"
                                type="text"
                                model=".generalPermitProcedureRefNo" 
                                defaultValue={answers && answers.generalPermitProcedureRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitProcedureAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitProcedureAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.rescuePlan && 
                    <div>
                    <label> Rescue Plan</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitRescuePlanRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitRescuePlanRefNo"
                                type="text"
                                model=".generalPermitRescuePlanRefNo" 
                                defaultValue={answers && answers.generalPermitRescuePlanRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitRescuePlanAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitRescuePlanAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.simopDeviation && 
                    <div>
                    <label> SIMOP Deviation</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitSIMOPRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitSIMOPRefNo"
                                type="text"
                                model=".generalPermitSIMOPRefNo" 
                                defaultValue={answers && answers.generalPermitSIMOPRefNo}
                            />
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitSIMOPAttachments"
                                    multiple={true}
                                    id="upload-images"  
                                    uploadedImages={forms.permitToWorkForm.generalPermitSIMOPAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                    {forms.permitToWorkForm.workAid && 
                    <div>
                    <label> Work Aid</label>
                    <Row className="show-grid">
                        <Col sm={{span:'3', offset: '2'}} className="input">
                            <label className="checkbox-label" htmlFor="generalPermitWorkAidRefNo">Reference No</label>
                            <RRFInput
                                id="generalPermitWorkAidRefNo"
                                type="text"
                                model=".generalPermitWorkAidRefNo" 
                                defaultValue={answers && answers.generalPermitWorkAidRefNo}
                            />  
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                            <Col sm={12} className="input">
                                <AttachementInput
                                    model=".generalPermitWorkAidAttachments"
                                    multiple={true}
                                    id="upload-images" 
                                    uploadedImages={forms.permitToWorkForm.generalPermitWorkAidAttachmentsURL || []}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    </div> }
                </fieldset>
            </div>
        );
    }
    renderStep6= () => {
        const { props: {forms, editInstance }, state: {currentDate} } = this; 
        let answers = (editInstance && editInstance.props.answers) || ''; 
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Temporary Defeat</label> 
                    {/* {forms.permitToWorkForm.formIsolationType==='1' &&  */}
                        <div style={{marginTop: '15px'}}> 
                            <Row className="show-grid"> 
                                <Col sm={2} className="input">
                                    <label className="checkbox-label" htmlFor="isolationElectrical">Electrical</label>
                                    <RRFInput
                                        id="isolationElectrical"
                                        type="checkbox"
                                        model=".isolationElectrical" 
                                        //defaultValue={answers && answers.isolationElectrical}
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolationMechanical">Mechanical</label>
                                    <RRFInput
                                        id="isolationMechanical"
                                        type="checkbox"
                                        model=".isolationMechanical" 
                                        //defaultValue={answers && answers.isolationMechanical}
                                    />
                                </Col>
                                <Col sm={4} className="input">
                                    <label className="checkbox-label" htmlFor="isolationInstrumentationProcess">Instrumentation/Process</label>
                                    <RRFInput
                                        id="isolationInstrumentationProcess"
                                        type="checkbox"
                                        model=".isolationInstrumentationProcess" 
                                        //defaultValue={answers && answers.isolationInstrumentationProcess}
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolationOtherSafety">Other Safety</label>
                                    <RRFInput
                                        id="isolationOtherSafety"
                                        type="checkbox"
                                        model=".isolationOtherSafety" 
                                        //defaultValue={answers && answers.isolationOtherSafety}
                                    />
                                </Col>
                            </Row>
                            <p style={{color: "yellow", fontSize:'18px'}}>Temporary Defeat Information:</p>
                            <Row className="show-grid" >
                                <Col sm={12} xs={12}>
                                    <Label sm={12}>Description of specified device/System and tag number being defeated:</Label> 
                                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.temporaryDefeatInformation} model="forms.permitToWorkForm.temporaryDefeatInformation" /> 
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="temporaryDefeatInformation"
                                            type="textarea" 
                                            model=".temporaryDefeatInformation" 
                                            defaultValue={answers && answers.temporaryDefeatInformation}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="show-grid" >
                                {/* <Col sm={6} className="input">
                                    <label className="checkbox-label" htmlFor="isApprovedBypassAttached">Approved Device Bypass Procedure Attached</label>
                                    <RRFInput
                                        id="isApprovedBypassAttached"
                                        type="checkbox"
                                        model=".isApprovedBypassAttached" 
                                        defaultValue={answers && answers.isApprovedBypassAttached}
                                    />
                                </Col>
                                <Col sm={6} className="input">
                                    <label className="checkbox-label" htmlFor="isRiskAssessmentRequired">Risk Assessment Required?</label>
                                    <RRFInput
                                        id="isRiskAssessmentRequired"
                                        type="checkbox"
                                        model=".isRiskAssessmentRequired" 
                                        defaultValue={answers && answers.isRiskAssessmentRequired}
                                    />
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Label sm={12}>Procedure Title</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="procedureTitle"
                                            type="text" 
                                            model=".procedureTitle" 
                                            defaultValue={answers && answers.procedureTitle}
                                        />
                                    </Col>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <Label sm={12}>Work Permit Number</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="workPermitNumber"
                                            type="text" 
                                            model=".workPermitNumber" 
                                            defaultValue={answers && answers.workPermitNumber}
                                        />
                                    </Col>
                                </Col> */}
                                <Col sm={12} xs={12}>
                                    <Label sm={12}>Reason for the defeating the device/system:</Label>
                                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.reasonDefectSystem} model="forms.permitToWorkForm.reasonDefectSystem" /> 
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="reasonDefectSystem"
                                            type="textarea" 
                                            model=".reasonDefectSystem" 
                                            defaultValue={answers && answers.reasonDefectSystem}
                                        />
                                    </Col>
                                </Col> 
                            </Row>
                            {/* <Row className="show-grid">
                                <Col sm={12} xs={12}>
                                    <Label required sm={12} >Does the defeat create a Critical Operating parameter(COP)?</Label>
                                </Col>
                                <Col sm={{span: 2, offset: 4}} className="input">
                                    <label className="checkbox-label" htmlFor="COPDefeatYes">Yes</label>
                                    <RRFInput
                                        id="COPDefeatYes"
                                        type="checkbox"
                                        model=".COPDefeatYes" 
                                        onChange={this.onChangeQuestionYesNo} 
                                        defaultValue={answers && answers.COPDefeatYes}
                                    />
                                </Col>
                                <Col sm={2} className="input">
                                    <label className="checkbox-label" htmlFor="COPDefeatNo">No</label>
                                    <RRFInput
                                        id="COPDefeatNo"
                                        type="checkbox"
                                        model=".COPDefeatNo" 
                                        onChange={this.onChangeQuestionYesNo} 
                                        defaultValue={answers && answers.COPDefeatNo}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={12} xs={12}>
                                    <Label required sm={12}>COP Approved?</Label>
                                </Col>
                                <Col sm={{span: 2, offset: 4}} className="input">
                                    <label className="checkbox-label" htmlFor="COPApprovedYes">Yes</label>
                                    <RRFInput
                                        id="COPApprovedYes"
                                        type="checkbox"
                                        model=".COPApprovedYes" 
                                        onChange={this.onChangeQuestionYesNo} 
                                        defaultValue={answers && answers.COPApprovedYes}
                                    />
                                </Col>
                                <Col sm={2} className="input">
                                    <label className="checkbox-label" htmlFor="COPApprovedNo">No</label>
                                    <RRFInput
                                        id="COPApprovedNo"
                                        type="checkbox"
                                        model=".COPApprovedNo" 
                                        onChange={this.onChangeQuestionYesNo} 
                                        defaultValue={answers && answers.COPApprovedNo}
                                    />
                                </Col>
                            </Row> */}
                            {/* <Row className="show-grid">
                                <Col sm={12} xs={12}>
                                    <Label required sm={12}>Define risk mitigation controls in place during the bypass that provide adequate protection:</Label>
                                </Col>
                            </Row> */}
                            <Row className="show-grid" >
                                <Col sm={6}>
                                    <span style={{ color: 'yellow' }} >Hazards</span>
                                    <span style={{ color: 'red' }} >*</span>
                                </Col> 
                                <Col sm={6}>
                                    <span style={{ color: 'yellow' }} >Controls</span>
                                    <span style={{ color: 'red' }} >*</span>
                                </Col>
                            </Row>
                            {this.renderRiskMitigation()}
                            
                        <Row className="show-grid" >
                            <div className="table-buttons" onClick={()=>this.incrementRiskMitigationRows()}>  
                                <button className="add-row" type="button">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    &nbsp;Hazard
                                </button>
                            </div>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px'}}>Authorization for Temporary Defeat - up to 12 hours</Label>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={2} xs={12}>
                                <Label required sm={12}>Duration of Defeat:</Label>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>From(date):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="defeatDurationFromDate"
                                        type="date" 
                                        model=".defeatDurationFromDate" 
                                        defaultValue={(forms.permitToWorkForm.defeatDurationFromDate && format(new Date(forms.permitToWorkForm.defeatDurationFromDate), 'yyyy-MM-dd') ) || null }
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="defeatDurationFromTime"
                                        type="time" 
                                        model=".defeatDurationFromTime" 
                                        defaultValue={answers && answers.defeatDurationFromTime}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>To(date):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="defeatDurationToDate"
                                        type="date" 
                                        model=".defeatDurationToDate" 
                                        defaultValue={(forms.permitToWorkForm.defeatDurationToDate && format(new Date(forms.permitToWorkForm.defeatDurationToDate), 'yyyy-MM-dd') ) || null }
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="defeatDurationToTime"
                                        type="time" 
                                        model=".defeatDurationToTime" 
                                        defaultValue={answers && answers.defeatDurationToTime}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}>
                                <Label sm={12}>Permit Holder/Requestor(print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationPermitHolderName"
                                        type="text" 
                                        model=".isolationPermitHolderName" 
                                        defaultValue={answers && answers.isolationPermitHolderName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={4} xs={12}>
                                <Label sm={12}>Facility Incharge(print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationPIName"
                                        type="text" 
                                        model=".isolationPIName" 
                                        defaultValue={answers && answers.isolationPIName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.isolationTemporaryDefeatPI ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.isolationPIName || 'FI'}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".isolationTemporaryDefeatPISignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.isolationTemporaryDefeatPI, 'isolationTemporaryDefeatPI')}
                            </Col> 
                        </Row>
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px'}}>Execution of Temporary Defeat:</Label>
                            </Col>
                        </Row>
                        <Row className="show-grid"> 
                            <Col sm={2} xs={12}>
                                <Label required sm={12}>Defeated by:</Label>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Name(print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationDedeatedByName"
                                        type="text" 
                                        model=".isolationDedeatedByName" 
                                        defaultValue={answers && answers.isolationDedeatedByName}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.isolationTemporaryDefeatDefeatedBy ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.isolationDedeatedByName || ' '}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".isolationTemporaryDefeatDefeatedBySignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.isolationTemporaryDefeatDefeatedBy, 'isolationTemporaryDefeatDefeatedBy')}
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationDedeatedDate"
                                        type="date" 
                                        model=".isolationDedeatedDate" 
                                        defaultValue={(forms.permitToWorkForm.isolationDedeatedDate && format(new Date(forms.permitToWorkForm.isolationDedeatedDate), 'yyyy-MM-dd') ) || null}
                                        maxdate={currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationDedeatedTime"
                                        type="time" 
                                        model=".isolationDedeatedTime" 
                                        defaultValue={answers && answers.isolationDedeatedTime}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px'}}>Long Term Defeats:</Label>
                            </Col>
                        </Row>
                        <Row className="show-grid"> 
                            <Col sm={2} xs={12}>
                                <Label required sm={12}>Requested by:</Label>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Name(print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="longTermDefeatsRequestedByName"
                                        type="text" 
                                        model=".longTermDefeatsRequestedByName" 
                                        defaultValue={answers && answers.longTermDefeatsRequestedByName}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.isolationLongTermDefeatRequestedBy ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.longTermDefeatsRequestedByName || ' '}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".isolationLongTermDefeatRequestedBySignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.isolationLongTermDefeatRequestedBy, 'isolationLongTermDefeatRequestedBy')}
                            </Col> 
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="longTermDefeatsRequestedDate"
                                        type="date" 
                                        model=".longTermDefeatsRequestedDate" 
                                        defaultValue={(forms.permitToWorkForm.longTermDefeatsRequestedDate && format(new Date(forms.permitToWorkForm.longTermDefeatsRequestedDate), 'yyyy-MM-dd') ) || null }
                                        maxdate={currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="longTermDefeatsRequestedTime"
                                        type="time" 
                                        model=".longTermDefeatsRequestedTime" 
                                        defaultValue={answers && answers.longTermDefeatsRequestedTime}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Reason for Long Term Defeat:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.longTermDefeatReason} model="forms.permitToWorkForm.longTermDefeatReason" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="longTermDefeatReason"
                                        type="textarea" 
                                        model=".longTermDefeatReason" 
                                        defaultValue={answers && answers.longTermDefeatReason}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px'}}>Long Term Defeat Authorization:</Label>
                            </Col>
                        </Row>
                        <Row className="show-grid">  
                            <Col sm={2} xs={12}>
                                <Label sm={12}>FI (print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCPIName"
                                        type="text" 
                                        model=".ITDCPIName" 
                                        defaultValue={answers && answers.ITDCPIName}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.isolationLongTermDefeatAuthorizationPI ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.ITDCPIName || ' '}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".isolationLongTermDefeatAuthorizationPISignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.isolationLongTermDefeatAuthorizationPI, 'isolationLongTermDefeatAuthorizationPI')}
                            </Col> 
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCPIDate"
                                        type="date" 
                                        model=".ITDCPIDate" 
                                        defaultValue={(forms.permitToWorkForm.ITDCPIDate && format(new Date(forms.permitToWorkForm.ITDCPIDate), 'yyyy-MM-dd') ) || null}
                                        maxdate={currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Expiry Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCPIExpiryDate"
                                        type="date" 
                                        model=".ITDCPIExpiryDate" 
                                        defaultValue={(forms.permitToWorkForm.ITDCPIExpiryDate && format(new Date(forms.permitToWorkForm.ITDCPIExpiryDate), 'yyyy-MM-dd') ) || null}
                                       // mindate={forms.permitToWorkForm.plannedDateOfWork || currentDate}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">  
                            <Col sm={2} xs={12}>
                                <Label sm={12}>OI (print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCOIName"
                                        type="text" 
                                        model=".ITDCOIName" 
                                        defaultValue={answers && answers.ITDCOIName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.isolationLongTermDefeatAuthorizationOI ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.ITDCOIName || ' '}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".isolationLongTermDefeatAuthorizationOISignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.isolationLongTermDefeatAuthorizationOI, 'isolationLongTermDefeatAuthorizationOI')}
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCOIDate"
                                        type="date" 
                                        model=".ITDCOIDate" 
                                        defaultValue={(forms.permitToWorkForm.ITDCOIDate && format(new Date(forms.permitToWorkForm.ITDCOIDate), 'yyyy-MM-dd') ) || null }
                                        maxdate={currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Expiry Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ITDCOIExpiryDate"
                                        type="date" 
                                        model=".ITDCOIExpiryDate" 
                                        defaultValue={(forms.permitToWorkForm.ITDCOIExpiryDate && format(new Date(forms.permitToWorkForm.ITDCOIExpiryDate), 'yyyy-MM-dd') ) || null}
                                        //mindate={forms.permitToWorkForm.plannedDateOfWork || currentDate}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                        {/* <Row className="show-grid"> 
                            <Col sm={12} xs={12}>
                                <Label required sm={12}> {`For Defeats over 60 days, an MOC must be generated and both the MOC and Temporary Defeat Certificate must be signed.`}</Label>
                            </Col>
                        </Row>
                        <Row className="show-grid">  
                            <Col sm={3} xs={12}>
                                <Label sm={12}>MOC Number:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationTemporaryMOCNumber"
                                        type="text" 
                                        model=".isolationTemporaryMOCNumber" 
                                        defaultValue={answers && answers.isolationTemporaryMOCNumber}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label required sm={12}> MOC Assigned to:</Label>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Name(print):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationTemporaryMOCAssignedTo"
                                        type="text" 
                                        model=".isolationTemporaryMOCAssignedTo" 
                                        defaultValue={answers && answers.isolationTemporaryMOCAssignedTo}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="isolationTemporaryMOCAssignedDate"
                                        type="date" 
                                        model=".isolationTemporaryMOCAssignedDate" 
                                        defaultValue={(forms.permitToWorkForm.isolationTemporaryMOCAssignedDate && format(new Date(forms.permitToWorkForm.isolationTemporaryMOCAssignedDate), 'yyyy-MM-dd') ) || null }
                                        maxdate={currentDate} 
                                    />
                                </Col>
                            </Col> 
                        </Row> */}
                        {/* <Row className="show-grid"> 
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px'}}> Shift Change</Label>
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}> Open Temporary Defeat Certificates must be reviewed during each shift change and recorded in the shift handover log/document.</Label>
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>A copy of any open Defeat must be posted at the Control Room or Central Control Point. A copy must also be attached to any permit pack (where applicable).</Label>
                            </Col>
                        </Row>
                        <Row className="assessment-hint">
                            <Accordion  >
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} eventKey="0" className="risk-assessment-toggle" onClick={() => this.accordianProcess('shiftChangeMechanicalHint')}>
                                        {this.state.shiftChangeMechanicalHint ?  <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 

                                        </Accordion.Toggle>
                                        Mechanical Hazards and Controls — Protection to be defeated or not functioning
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Row className="show-grid"> 
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Vibration Monitoring</Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="vibrationMonitoringSE">Shutdown equipment</label>
                                                <RRFInput
                                                    id="vibrationMonitoringSE"
                                                    type="checkbox"
                                                    model=".vibrationMonitoringSE" 
                                                    defaultValue={answers && answers.vibrationMonitoringSE}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="vibrationMonitoringPPME">Provide portable monitoring equipment</label>
                                                <RRFInput
                                                    id="vibrationMonitoringPPME"
                                                    type="checkbox"
                                                    model=".vibrationMonitoringPPME" 
                                                    defaultValue={answers && answers.vibrationMonitoringPPME}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="vibrationMonitoringCFS">Conduct frequent surveillance</label>
                                                <RRFInput
                                                    id="vibrationMonitoringCFS"
                                                    type="checkbox"
                                                    model=".vibrationMonitoringCFS" 
                                                    defaultValue={answers && answers.vibrationMonitoringCFS}
                                                />
                                            </Col>
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="vibrationMonitoringOther">Other</label>
                                                <RRFInput
                                                    id="vibrationMonitoringOther"
                                                    type="checkbox"
                                                    model=".vibrationMonitoringOther" 
                                                    defaultValue={answers && answers.vibrationMonitoringOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.vibrationMonitoringOther &&  <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="vibrationMonitoringMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="vibrationMonitoringMentionOther"
                                                    type="text"
                                                    model=".vibrationMonitoringMentionOther" 
                                                    defaultValue={answers && answers.vibrationMonitoringMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">   
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Seal Failure System Isolated or Inoperable</Label>
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="sealFailureSPCC">Shutdown pump or compressor</label>
                                                <RRFInput
                                                    id="sealFailureSPCC"
                                                    type="checkbox"
                                                    model=".sealFailureSPCC" 
                                                    defaultValue={answers && answers.sealFailureSPCC}
                                                />
                                            </Col>
                                            <Col sm={5} className="input">
                                                <label className="checkbox-label" htmlFor="sealFailureCSPSMI">Continuous seal pressure surveillance and manual intervention</label>
                                                <RRFInput
                                                    id="sealFailureCSPSMI"
                                                    type="checkbox"
                                                    model=".sealFailureCSPSMI" 
                                                    defaultValue={answers && answers.sealFailureCSPSMI}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="sealFailureOther">Other</label>
                                                <RRFInput
                                                    id="sealFailureOther"
                                                    type="checkbox"
                                                    model=".sealFailureOther" 
                                                    defaultValue={answers && answers.sealFailureOther}
                                                />
                                            </Col>
                                            {forms.permitToWorkForm.sealFailureOther && 
                                                <Col sm={3} className="input">
                                                    <label className="checkbox-label" htmlFor="sealFailureMentionOther">Mention</label>
                                                    <RRFInput
                                                        id="sealFailureMentionOther"
                                                        type="text"
                                                        model=".sealFailureMentionOther" 
                                                        defaultValue={answers && answers.sealFailureMentionOther}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                        <Row className="show-grid">   
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Engine Overspeed Trip Isolated or Inoperable</Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="engineOverspeedTripSDE">Shut down engine</label>
                                                <RRFInput
                                                    id="engineOverspeedTripSDE"
                                                    type="checkbox"
                                                    model=".engineOverspeedTripSDE" 
                                                    defaultValue={answers && answers.engineOverspeedTripSDE}
                                                />
                                            </Col>
                                            <Col sm={6} className="input">
                                                <label className="checkbox-label" htmlFor="engineOverspeedTripCSSMI">Continuous speed surveillance and manual intervention</label>
                                                <RRFInput
                                                    id="engineOverspeedTripCSSMI"
                                                    type="checkbox"
                                                    model=".engineOverspeedTripCSSMI" 
                                                    defaultValue={answers && answers.engineOverspeedTripCSSMI}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="engineOverspeedTripOther">Other</label>
                                                <RRFInput
                                                    id="engineOverspeedTripOther"
                                                    type="checkbox"
                                                    model=".engineOverspeedTripOther" 
                                                    defaultValue={answers && answers.engineOverspeedTripOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.engineOverspeedTripOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="engineOverspeedTripMentionOther">Mention</label>
                                                <RRFInput
                                                    id="engineOverspeedTripMentionOther"
                                                    type="text"
                                                    model=".engineOverspeedTripMentionOther" 
                                                    defaultValue={answers && answers.engineOverspeedTripMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">   
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Limit Switches Isolated or Inoperable </Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="limitSwitchedIsolatedDO">Drilling operations to cease</label>
                                                <RRFInput
                                                    id="limitSwitchedIsolatedDO"
                                                    type="checkbox"
                                                    model=".limitSwitchedIsolatedDO" 
                                                    defaultValue={answers && answers.limitSwitchedIsolatedDO}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="limitSwitchedIsolatedLP">Lift Plan in place</label>
                                                <RRFInput
                                                    id="limitSwitchedIsolatedLP"
                                                    type="checkbox"
                                                    model=".limitSwitchedIsolatedLP" 
                                                    defaultValue={answers && answers.limitSwitchedIsolatedLP}
                                                />
                                            </Col> 
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="limitSwitchedIsolatedCNLS">Do not operate crane near limits Stop loading ship and disconnect </label>
                                                <RRFInput
                                                    id="limitSwitchedIsolatedCNLS"
                                                    type="checkbox"
                                                    model=".limitSwitchedIsolatedCNLS" 
                                                    defaultValue={answers && answers.limitSwitchedIsolatedCNLS}
                                                />
                                            </Col>
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="limitSwitchedIsolatedOther">Other</label>
                                                <RRFInput
                                                    id="limitSwitchedIsolatedOther"
                                                    type="checkbox"
                                                    model=".limitSwitchedIsolatedOther" 
                                                    defaultValue={answers && answers.limitSwitchedIsolatedOther}
                                                />
                                            </Col>
                                            {forms.permitToWorkForm.limitSwitchedIsolatedOther && 
                                                <Col sm={3} className="input">
                                                    <label className="checkbox-label" htmlFor="limitSwitchedIsolatedMentionOther">Mention</label>
                                                    <RRFInput
                                                        id="limitSwitchedIsolatedMentionOther"
                                                        type="text"
                                                        model=".limitSwitchedIsolatedMentionOther" 
                                                        defaultValue={answers && answers.limitSwitchedIsolatedMentionOther}
                                                    />
                                                </Col> }
                                        </Row>

                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>
                        </Row>
                        <Row className="assessment-hint">
                            <Accordion >
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} eventKey="0" className="risk-assessment-toggle" onClick={() => this.accordianProcess('shiftChangeSafetyHint')}>
                                        {this.state.shiftChangeSafetyHint ?  <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 

                                        </Accordion.Toggle> 
                                        Safety Hazards and Controls — Protection to be defeated or not functioning 
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Fire Protection System Inoperable or Impaired </Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireProductionSDF">Shut down facility</label>
                                                <RRFInput
                                                    id="fireProductionSDF"
                                                    type="checkbox"
                                                    model=".fireProductionSDF" 
                                                    defaultValue={answers && answers.fireProductionSDF}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="fireProductionSHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="fireProductionSHCW"
                                                    type="checkbox"
                                                    model=".fireProductionSHCW" 
                                                    defaultValue={answers && answers.fireProductionSHCW}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireProductionCAP">Communicate to all affected personnel</label>
                                                <RRFInput
                                                    id="fireProductionCAP"
                                                    type="checkbox"
                                                    model=".fireProductionCAP" 
                                                    defaultValue={answers && answers.fireProductionCAP}
                                                />
                                            </Col>
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="fireProductionOther">Other</label>
                                                <RRFInput
                                                    id="fireProductionOther"
                                                    type="checkbox"
                                                    model=".fireProductionOther" 
                                                    defaultValue={answers && answers.fireProductionOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.fireProductionOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireProductionMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="fireProductionMentionOther"
                                                    type="text"
                                                    model=".fireProductionMentionOther" 
                                                    defaultValue={answers && answers.fireProductionMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Fire Pump Inoperable or impaired </Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpSDF">Shut down facility</label>
                                                <RRFInput
                                                    id="firePumpSDF"
                                                    type="checkbox"
                                                    model=".firePumpSDF" 
                                                    defaultValue={answers && answers.firePumpSDF}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpSHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="firePumpSHCW"
                                                    type="checkbox"
                                                    model=".firePumpSHCW" 
                                                    defaultValue={answers && answers.firePumpSHCW}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpBackup">Back-up fire pump available</label>
                                                <RRFInput
                                                    id="firePumpBackup"
                                                    type="checkbox"
                                                    model=".firePumpBackup" 
                                                    defaultValue={answers && answers.firePumpBackup}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpCommunicate">Communicate to all affected personnel</label>
                                                <RRFInput
                                                    id="firePumpCommunicate"
                                                    type="checkbox"
                                                    model=".firePumpCommunicate" 
                                                    defaultValue={answers && answers.firePumpCommunicate}
                                                />
                                            </Col>
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpOther">Other</label>
                                                <RRFInput
                                                    id="firePumpOther"
                                                    type="checkbox"
                                                    model=".firePumpOther" 
                                                    defaultValue={answers && answers.firePumpOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.firePumpOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="firePumpMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="firePumpMentionOther"
                                                    type="text"
                                                    model=".firePumpMentionOther" 
                                                    defaultValue={answers && answers.firePumpMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Make-up to Fire Water System Inoperable or impaired </Label>
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireWaterShutDown">Shut down process if water levels approach minimum allowable</label>
                                                <RRFInput
                                                    id="fireWaterShutDown"
                                                    type="checkbox"
                                                    model=".fireWaterShutDown" 
                                                    defaultValue={answers && answers.fireWaterShutDown}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="fireWaterSHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="fireWaterSHCW"
                                                    type="checkbox"
                                                    model=".fireWaterSHCW" 
                                                    defaultValue={answers && answers.fireWaterSHCW}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireWaterAlternative">Alternative sources to be provided</label>
                                                <RRFInput
                                                    id="fireWaterAlternative"
                                                    type="checkbox"
                                                    model=".fireWaterAlternative" 
                                                    defaultValue={answers && answers.fireWaterAlternative}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="fireWaterOther">Other</label>
                                                <RRFInput
                                                    id="fireWaterOther"
                                                    type="checkbox"
                                                    model=".fireWaterOther" 
                                                    defaultValue={answers && answers.fireWaterOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.fireWaterOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireWaterMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="fireWaterMentionOther"
                                                    type="text"
                                                    model=".fireWaterMentionOther" 
                                                    defaultValue={answers && answers.fireWaterMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Emergency! Fire Alarm Inoperable or Impaired </Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="fireEmergencySHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="fireEmergencySHCW"
                                                    type="checkbox"
                                                    model=".fireEmergencySHCW" 
                                                    defaultValue={answers && answers.fireEmergencySHCW}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireEmergencyAlternative">Alternate notification method implemented</label>
                                                <RRFInput
                                                    id="fireEmergencyAlternative"
                                                    type="checkbox"
                                                    model=".fireEmergencyAlternative" 
                                                    defaultValue={answers && answers.fireEmergencyAlternative}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireEmergencyPortableAir">Portable air horns provided</label>
                                                <RRFInput
                                                    id="fireEmergencyPortableAir"
                                                    type="checkbox"
                                                    model=".fireEmergencyPortableAir" 
                                                    defaultValue={answers && answers.fireEmergencyPortableAir}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="fireEmergencyOther">Other</label>
                                                <RRFInput
                                                    id="fireEmergencyOther"
                                                    type="checkbox"
                                                    model=".fireEmergencyOther" 
                                                    defaultValue={answers && answers.fireEmergencyOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.fireEmergencyOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireEmergencyMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="fireEmergencyMentionOther"
                                                    type="text"
                                                    model=".fireEmergencyMentionOther" 
                                                    defaultValue={answers && answers.fireEmergencyMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Fire Detection Inoperable or Impaired </Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="fireDetectionSHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="fireDetectionSHCW"
                                                    type="checkbox"
                                                    model=".fireDetectionSHCW" 
                                                    defaultValue={answers && answers.fireDetectionSHCW}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireDetectionCSMI">Continuous surveillance and manual intervention</label>
                                                <RRFInput
                                                    id="fireDetectionCSMI"
                                                    type="checkbox"
                                                    model=".fireDetectionCSMI" 
                                                    defaultValue={answers && answers.fireDetectionCSMI}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireDetectionCAP">Communicate to all affected personnel</label>
                                                <RRFInput
                                                    id="fireDetectionCAP"
                                                    type="checkbox"
                                                    model=".fireDetectionCAP" 
                                                    defaultValue={answers && answers.fireDetectionCAP}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="fireDetectionOther">Other</label>
                                                <RRFInput
                                                    id="fireDetectionOther"
                                                    type="checkbox"
                                                    model=".fireDetectionOther" 
                                                    defaultValue={answers && answers.fireDetectionOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.fireDetectionOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="fireDetectionMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="fireDetectionMentionOther"
                                                    type="text"
                                                    model=".fireDetectionMentionOther" 
                                                    defaultValue={answers && answers.fireDetectionMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Gas detection Inoperable or Impaired </Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionSHCW">Stop all high consequence work </label>
                                                <RRFInput
                                                    id="gasDetectionSHCW"
                                                    type="checkbox"
                                                    model=".gasDetectionSHCW" 
                                                    defaultValue={answers && answers.gasDetectionSHCW}
                                                />
                                            </Col>
                                            <Col sm={5} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionCRGD">Communicate to all affected personnel Adequate coverage by remaining gas detectors</label>
                                                <RRFInput
                                                    id="gasDetectionCRGD"
                                                    type="checkbox"
                                                    model=".gasDetectionCRGD" 
                                                    defaultValue={answers && answers.gasDetectionCRGD}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionPGD">Deploy portable gas detection devices </label>
                                                <RRFInput
                                                    id="gasDetectionPGD"
                                                    type="checkbox"
                                                    model=".gasDetectionPGD" 
                                                    defaultValue={answers && answers.gasDetectionPGD}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionRS">Regular Surveillance </label>
                                                <RRFInput
                                                    id="gasDetectionRS"
                                                    type="checkbox"
                                                    model=".gasDetectionRS" 
                                                    defaultValue={answers && answers.gasDetectionRS}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionCS">Continuous Surveillance </label>
                                                <RRFInput
                                                    id="gasDetectionCS"
                                                    type="checkbox"
                                                    model=".gasDetectionCS" 
                                                    defaultValue={answers && answers.gasDetectionCS}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionOther">Other</label>
                                                <RRFInput
                                                    id="gasDetectionOther"
                                                    type="checkbox"
                                                    model=".gasDetectionOther" 
                                                    defaultValue={answers && answers.gasDetectionOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.gasDetectionOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="gasDetectionMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="gasDetectionMentionOther"
                                                    type="text"
                                                    model=".gasDetectionMentionOther" 
                                                    defaultValue={answers && answers.gasDetectionMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">ESD System Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="esdShutDown">	Shut down process </label>
                                                <RRFInput
                                                    id="esdShutDown"
                                                    type="checkbox"
                                                    model=".esdShutDown" 
                                                    defaultValue={answers && answers.esdShutDown}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="esdStopHighConsequence">	Stop all high consequence work </label>
                                                <RRFInput
                                                    id="esdStopHighConsequence"
                                                    type="checkbox"
                                                    model=".esdStopHighConsequence" 
                                                    defaultValue={answers && answers.esdStopHighConsequence}
                                                />
                                            </Col> 
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="esdAdditionalSurveillance">Additional surveillance </label>
                                                <RRFInput
                                                    id="esdAdditionalSurveillance"
                                                    type="checkbox"
                                                    model=".esdAdditionalSurveillance" 
                                                    defaultValue={answers && answers.esdAdditionalSurveillance}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="esdOther">Other</label>
                                                <RRFInput
                                                    id="esdOther"
                                                    type="checkbox"
                                                    model=".esdOther" 
                                                    defaultValue={answers && answers.esdOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.esdOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="esdMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="esdMentionOther"
                                                    type="text"
                                                    model=".esdMentionOther" 
                                                    defaultValue={answers && answers.esdMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Public address System Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={5} className="input">
                                                <label className="checkbox-label" htmlFor="publicAddressSysAlternativeComm"> Alternative communication & notification system identified for emergencies</label>
                                                <RRFInput
                                                    id="publicAddressSysAlternativeComm"
                                                    type="checkbox"
                                                    model=".publicAddressSysAlternativeComm" 
                                                    defaultValue={answers && answers.publicAddressSysAlternativeComm}
                                                />
                                            </Col>
                                            <Col sm={5} className="input">
                                                <label className="checkbox-label" htmlFor="publicAddressSysCommAffected"> Communicated to affected personnel </label>
                                                <RRFInput
                                                    id="publicAddressSysCommAffected"
                                                    type="checkbox"
                                                    model=".publicAddressSysCommAffected" 
                                                    defaultValue={answers && answers.publicAddressSysCommAffected}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="publicAddressSysOther">Other</label>
                                                <RRFInput
                                                    id="publicAddressSysOther"
                                                    type="checkbox"
                                                    model=".publicAddressSysOther" 
                                                    defaultValue={answers && answers.publicAddressSysOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.publicAddressSysOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="publicAddressSysMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="publicAddressSysMentionOther"
                                                    type="text"
                                                    model=".publicAddressSysMentionOther" 
                                                    defaultValue={answers && answers.publicAddressSysMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Smoke Detection Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="smokeDetectionStopHighConsequence">	Stop all high consequence work </label>
                                                <RRFInput
                                                    id="smokeDetectionStopHighConsequence"
                                                    type="checkbox"
                                                    model=".smokeDetectionStopHighConsequence" 
                                                    defaultValue={answers && answers.smokeDetectionStopHighConsequence}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="smokeDetectionAdddtionalSurvillance"> Additional surveillance </label>
                                                <RRFInput
                                                    id="smokeDetectionAdddtionalSurvillance"
                                                    type="checkbox"
                                                    model=".smokeDetectionAdddtionalSurvillance" 
                                                    defaultValue={answers && answers.smokeDetectionAdddtionalSurvillance}
                                                />
                                            </Col>  
                                            <Col sm={5} className="input">
                                                <label className="checkbox-label" htmlFor="smokeDetectionCommunicateAffected">	Communicate to all affected personnel </label>
                                                <RRFInput
                                                    id="smokeDetectionCommunicateAffected"
                                                    type="checkbox"
                                                    model=".smokeDetectionCommunicateAffected" 
                                                    defaultValue={answers && answers.smokeDetectionCommunicateAffected}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="smokeDetectionOther">Other</label>
                                                <RRFInput
                                                    id="smokeDetectionOther"
                                                    type="checkbox"
                                                    model=".smokeDetectionOther" 
                                                    defaultValue={answers && answers.smokeDetectionOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.smokeDetectionOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="smokeDetectionMentionnOther">Mention:</label>
                                                <RRFInput
                                                    id="smokeDetectionMentionnOther"
                                                    type="text"
                                                    model=".smokeDetectionMentionnOther" 
                                                    defaultValue={answers && answers.smokeDetectionMentionnOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Radar Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="radarInoperableProvideCoverage">	Provide coverage from other facilities </label>
                                                <RRFInput
                                                    id="radarInoperableProvideCoverage"
                                                    type="checkbox"
                                                    model=".radarInoperableProvideCoverage" 
                                                    defaultValue={answers && answers.radarInoperableProvideCoverage}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="radarInoperablePortableRadar"> Provide portable radar unit </label>
                                                <RRFInput
                                                    id="radarInoperablePortableRadar"
                                                    type="checkbox"
                                                    model=".radarInoperablePortableRadar" 
                                                    defaultValue={answers && answers.radarInoperablePortableRadar}
                                                />
                                            </Col>  
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="radarInoperablePortableCoverageVessel"> Provide coverage from vessel / ship </label>
                                                <RRFInput
                                                    id="radarInoperablePortableCoverageVessel"
                                                    type="checkbox"
                                                    model=".radarInoperablePortableCoverageVessel" 
                                                    defaultValue={answers && answers.radarInoperablePortableCoverageVessel}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="radarInoperableOther">Other</label>
                                                <RRFInput
                                                    id="radarInoperableOther"
                                                    type="checkbox"
                                                    model=".radarInoperableOther" 
                                                    defaultValue={answers && answers.radarInoperableOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.radarInoperableOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="radarInoperableMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="radarInoperableMentionOther"
                                                    type="text"
                                                    model=".radarInoperableMentionOther" 
                                                    defaultValue={answers && answers.radarInoperableMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Transport systems</Label>
                                            </Col>
                                             
                                            <Col sm={6} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemNotifyAffectedParties"> Notify affected parties and Government Authorities </label>
                                                <RRFInput
                                                    id="transportSystemNotifyAffectedParties"
                                                    type="checkbox"
                                                    model=".transportSystemNotifyAffectedParties" 
                                                    defaultValue={answers && answers.transportSystemNotifyAffectedParties}
                                                />
                                            </Col>
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemNoNightFlights"> No night flights</label>
                                                <RRFInput
                                                    id="transportSystemNoNightFlights"
                                                    type="checkbox"
                                                    model=".transportSystemNoNightFlights" 
                                                    defaultValue={answers && answers.transportSystemNoNightFlights}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemProvideAlternateLighting"> Provide alternate lighting </label>
                                                <RRFInput
                                                    id="transportSystemProvideAlternateLighting"
                                                    type="checkbox"
                                                    model=".transportSystemProvideAlternateLighting" 
                                                    defaultValue={answers && answers.transportSystemProvideAlternateLighting}
                                                />
                                            </Col>  
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemSurveillance"> Surveillance </label>
                                                <RRFInput
                                                    id="transportSystemSurveillance"
                                                    type="checkbox"
                                                    model=".transportSystemSurveillance" 
                                                    defaultValue={answers && answers.transportSystemSurveillance}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemOther">Other</label>
                                                <RRFInput
                                                    id="transportSystemOther"
                                                    type="checkbox"
                                                    model=".transportSystemOther" 
                                                    defaultValue={answers && answers.transportSystemOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.transportSystemOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="transportSystemMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="transportSystemMentionOther"
                                                    type="text"
                                                    model=".transportSystemMentionOther" 
                                                    defaultValue={answers && answers.transportSystemMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Escape Craft or Lifeboat not functional</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftShutDownSafe"> Shut down and make facility safe </label>
                                                <RRFInput
                                                    id="escapeCraftShutDownSafe"
                                                    type="checkbox"
                                                    model=".escapeCraftShutDownSafe" 
                                                    defaultValue={answers && answers.escapeCraftShutDownSafe}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftStopHighConsequence">	Stop all high consequence work </label>
                                                <RRFInput
                                                    id="escapeCraftStopHighConsequence"
                                                    type="checkbox"
                                                    model=".escapeCraftStopHighConsequence" 
                                                    defaultValue={answers && answers.escapeCraftStopHighConsequence}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftHelicopter">	Helicopter to be on location </label>
                                                <RRFInput
                                                    id="escapeCraftHelicopter"
                                                    type="checkbox"
                                                    model=".escapeCraftHelicopter" 
                                                    defaultValue={answers && answers.escapeCraftHelicopter}
                                                />
                                            </Col>  
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftPOB">	POB's reduced to available capacity </label>
                                                <RRFInput
                                                    id="escapeCraftPOB"
                                                    type="checkbox"
                                                    model=".escapeCraftPOB" 
                                                    defaultValue={answers && answers.escapeCraftPOB}
                                                />
                                            </Col>  
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftVessalStandby"> Vessel on standby</label>
                                                <RRFInput
                                                    id="escapeCraftVessalStandby"
                                                    type="checkbox"
                                                    model=".escapeCraftVessalStandby" 
                                                    defaultValue={answers && answers.escapeCraftVessalStandby}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftOther">Other</label>
                                                <RRFInput
                                                    id="escapeCraftOther"
                                                    type="checkbox"
                                                    model=".escapeCraftOther" 
                                                    defaultValue={answers && answers.escapeCraftOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.escapeCraftOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="escapeCraftMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="escapeCraftMentionOther"
                                                    type="text"
                                                    model=".escapeCraftMentionOther" 
                                                    defaultValue={answers && answers.escapeCraftMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Emergency Lighting Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyLightingAlternativeLighting"> Provide Alternative Lighting </label>
                                                <RRFInput
                                                    id="EmergencyLightingAlternativeLighting"
                                                    type="checkbox"
                                                    model=".EmergencyLightingAlternativeLighting" 
                                                    defaultValue={answers && answers.EmergencyLightingAlternativeLighting}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyLightingCommunicateAffectedPersonnel">	Communicate to affected personnel </label>
                                                <RRFInput
                                                    id="EmergencyLightingCommunicateAffectedPersonnel"
                                                    type="checkbox"
                                                    model=".EmergencyLightingCommunicateAffectedPersonnel" 
                                                    defaultValue={answers && answers.EmergencyLightingCommunicateAffectedPersonnel}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyLightingRestrictHighConsequence"> Restrict high consequence work </label>
                                                <RRFInput
                                                    id="EmergencyLightingRestrictHighConsequence"
                                                    type="checkbox"
                                                    model=".EmergencyLightingRestrictHighConsequence" 
                                                    defaultValue={answers && answers.EmergencyLightingRestrictHighConsequence}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyLightingOther">Other</label>
                                                <RRFInput
                                                    id="EmergencyLightingOther"
                                                    type="checkbox"
                                                    model=".EmergencyLightingOther" 
                                                    defaultValue={answers && answers.EmergencyLightingOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.EmergencyLightingOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyLightingMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="EmergencyLightingMentionOther"
                                                    type="text"
                                                    model=".EmergencyLightingMentionOther" 
                                                    defaultValue={answers && answers.EmergencyLightingMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Emergency Eye Wash or Shower Inoperable</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyEyeWashCommunicateAffected"> Communicate to all affected personnel</label>
                                                <RRFInput
                                                    id="EmergencyEyeWashCommunicateAffected"
                                                    type="checkbox"
                                                    model=".EmergencyEyeWashCommunicateAffected" 
                                                    defaultValue={answers && answers.EmergencyEyeWashCommunicateAffected}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyEyeWashNoChemical">	No chemical handling in vicinity El Provide portable eye wash / shower</label>
                                                <RRFInput
                                                    id="EmergencyEyeWashNoChemical"
                                                    type="checkbox"
                                                    model=".EmergencyEyeWashNoChemical" 
                                                    defaultValue={answers && answers.EmergencyEyeWashNoChemical}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyEyeWashProvidePortableShower"> Provide portable shower </label>
                                                <RRFInput
                                                    id="EmergencyEyeWashProvidePortableShower"
                                                    type="checkbox"
                                                    model=".EmergencyEyeWashProvidePortableShower" 
                                                    defaultValue={answers && answers.EmergencyEyeWashProvidePortableShower}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyEyeWashOther">Other</label>
                                                <RRFInput
                                                    id="EmergencyEyeWashOther"
                                                    type="checkbox"
                                                    model=".EmergencyEyeWashOther" 
                                                    defaultValue={answers && answers.EmergencyEyeWashOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.EmergencyEyeWashOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="EmergencyEyeWashMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="EmergencyEyeWashMentionOther"
                                                    type="text"
                                                    model=".EmergencyEyeWashMentionOther" 
                                                    defaultValue={answers && answers.EmergencyEyeWashMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Communication System Shut Down or Faulty</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="communicationSysShutdownRedundancyEquipment">	Redundancy in available frequencies or equipment </label>
                                                <RRFInput
                                                    id="communicationSysShutdownRedundancyEquipment"
                                                    type="checkbox"
                                                    model=".communicationSysShutdownRedundancyEquipment" 
                                                    defaultValue={answers && answers.communicationSysShutdownRedundancyEquipment}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="communicationSysShutdownPortableDevices"> Portable devices provided</label>
                                                <RRFInput
                                                    id="communicationSysShutdownPortableDevices"
                                                    type="checkbox"
                                                    model=".communicationSysShutdownPortableDevices" 
                                                    defaultValue={answers && answers.communicationSysShutdownPortableDevices}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="communicationSysShutdownCommunicateAffected">	Communicate to affected personnel </label>
                                                <RRFInput
                                                    id="communicationSysShutdownCommunicateAffected"
                                                    type="checkbox"
                                                    model=".communicationSysShutdownCommunicateAffected" 
                                                    defaultValue={answers && answers.communicationSysShutdownCommunicateAffected}
                                                />
                                            </Col>  
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="communicationSysShutdownOther">Other</label>
                                                <RRFInput
                                                    id="communicationSysShutdownOther"
                                                    type="checkbox"
                                                    model=".communicationSysShutdownOther" 
                                                    defaultValue={answers && answers.communicationSysShutdownOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.communicationSysShutdownOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="communicationSysShutdownMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="communicationSysShutdownMentionOther"
                                                    type="text"
                                                    model=".communicationSysShutdownMentionOther" 
                                                    defaultValue={answers && answers.communicationSysShutdownMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">HVAC / Pressurization Inoperable or Impaired</Label>
                                            </Col>
                                             
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="HVACShutdownFacility"> Shut down facility on gas or fire detect near quarters or buildings</label>
                                                <RRFInput
                                                    id="HVACShutdownFacility"
                                                    type="checkbox"
                                                    model=".HVACShutdownFacility" 
                                                    defaultValue={answers && answers.HVACShutdownFacility}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="HVACShutdownCommunicatAffected"> Communicate to all affected personnel</label>
                                                <RRFInput
                                                    id="HVACShutdownCommunicatAffected"
                                                    type="checkbox"
                                                    model=".HVACShutdownCommunicatAffected" 
                                                    defaultValue={answers && answers.HVACShutdownCommunicatAffected}
                                                />
                                            </Col>
                                            <Col sm={4} className="input">
                                                <label className="checkbox-label" htmlFor="HVACShutdownCommunicatRestrictHighConsequence"> Restrict high consequence work </label>
                                                <RRFInput
                                                    id="HVACShutdownCommunicatRestrictHighConsequence"
                                                    type="checkbox"
                                                    model=".HVACShutdownCommunicatRestrictHighConsequence" 
                                                    defaultValue={answers && answers.HVACShutdownCommunicatRestrictHighConsequence}
                                                />
                                            </Col> 
                                        </Row>
                                        
                                        <Row className="show-grid">
                                            <Col sm={12} xs={12}>
                                                <Label sm={12} className="form-section-title">Tank Bunding / Containment to be compromised or Breached</Label>
                                            </Col>
                                             
                                            <Col sm={6} className="input">
                                                <label className="checkbox-label" htmlFor="tankBundingCapacity"> Tank(s) level(s) to be reduced to remaining bund capacity </label>
                                                <RRFInput
                                                    id="tankBundingCapacity"
                                                    type="checkbox"
                                                    model=".tankBundingCapacity" 
                                                    defaultValue={answers && answers.tankBundingCapacity}
                                                />
                                            </Col>
                                            <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="tankBundingTankEmpty"> Tank(s) to be emptied</label>
                                                <RRFInput
                                                    id="tankBundingTankEmpty"
                                                    type="checkbox"
                                                    model=".tankBundingTankEmpty" 
                                                    defaultValue={answers && answers.tankBundingTankEmpty}
                                                />
                                            </Col> 
                                            <Col sm={2} className="input">
                                                <label className="checkbox-label" htmlFor="tankBundingOther">Other</label>
                                                <RRFInput
                                                    id="tankBundingOther"
                                                    type="checkbox"
                                                    model=".tankBundingOther" 
                                                    defaultValue={answers && answers.tankBundingOther}
                                                />
                                            </Col>
                                            { forms.permitToWorkForm.tankBundingOther && <Col sm={3} className="input">
                                                <label className="checkbox-label" htmlFor="tankBundingMentionOther">Mention:</label>
                                                <RRFInput
                                                    id="tankBundingMentionOther"
                                                    type="text"
                                                    model=".tankBundingMentionOther" 
                                                    defaultValue={answers && answers.tankBundingMentionOther}
                                                />
                                            </Col> }
                                        </Row>
                                        
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>
                        </Row>  */}
                        </div>
                    {/* } */} 
                </fieldset>
            </div>
        );
    }
    renderStep4 = () => {
        const { props: {forms } } = this;  
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - JSA</label>
                    <Row className="show-grid">
                        <Col sm={{span:'4', offset:'2'}} xs={12}>
                            <Label required sm={12} htmlFor="formJsaNo">JSA N°:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="formJsaNo" 
                                    model=".formJsaNo"
                                    type="dropdown" 
                                    searchable={true}
                                    menuItems={this.jsaNumDropdown }
                                    onSelect={this.jsaNumChange} 
                                    defaultValue={forms.permitToWorkForm.formJsaNo}
                                /> 
                            </Col>
                        </Col> 
                        {forms.permitToWorkForm.formJsaNo && forms.permitToWorkForm.formJsaNo!== 'Other JSA' && 
                            <Col sm={4} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input"> 
                                    <div style={{padding: '10px', color: 'white'}}>{this.state.jsaTitle || 'JSA Report'}
                                         <br/>
                                        <span className="file-upload col-sm-2"><span className="images"><div key={'JSAdefault'} style={displayImage} className="image-wrapper">   
                                            <a href={`#/jsa-reports/${forms.permitToWorkForm.formJsaNo }`} title={`Open JSA`} target="_BLANK" rel="noreferrer"><span  ><i className="fa fa-file-o" style={{fontSize: '75px'}} aria-hidden="true"></i> </span>  </a> 
                                        </div></span></span>
                                    </div>  
                                </Col>
                            </Col> 
                        }
                        {forms.permitToWorkForm.formJsaNo && forms.permitToWorkForm.formJsaNo=== 'Other JSA' &&
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input">
                                    <AttachementInput
                                        model=".otherJSAAttachments"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={forms.permitToWorkForm.otherJSAAttachmentsURL || []}
                                    />
                                </Col>
                            </Col> 
                        }
                    </Row>
                </fieldset>
            </div>
        );
    } 
    renderStep3 = () => {
        const { props: {forms, editInstance }, state: {currentDate} } = this; 
        let answers = (editInstance && editInstance.props.answers) || ''; 
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Isolation</label>
                    {/* <Row className="show-grid">
                        <Col sm={{span: 12 }} xs={12}>
                            <Label required sm={12} htmlFor="formIsolationType">Isolation Type:</Label>
                            <Col sm={12} className="input mt20">  
                                <input className="radio-input   mr10" id="formIsolationType1" type="radio" name={`formIsolationType`} onChange={(event) => this.formIsolationTypeChange(event,'1')} checked={forms.permitToWorkForm.formIsolationType==='1'?true:false} /> 
                                <label htmlFor={`formIsolationType1`}> Temporary Defeat Certificate</label>
                                
                                <input className="radio-input mr10 ml10" id="formIsolationType2" type="radio" name={`formIsolationType`} onChange={(event) => this.formIsolationTypeChange(event,'2')} checked={forms.permitToWorkForm.formIsolationType==='2'?true:false} /> 
                                <label htmlFor={`formIsolationType2`}> Isolation Blind Register</label> 
                            </Col>
                        </Col>
                    </Row> */} 
                    <Row className="show-grid">
                        <Col sm={{span: 4, offset: 4}} xs={12}>
                            <Label required sm={12}>Permit No:</Label>
                            {/* <Col sm={12} className="input">
                                <RRFInput
                                    id="permitNo"
                                    type="text" 
                                    model=".permitNo" 
                                    defaultValue={forms.permitToWorkForm.permitNo}
                                />
                            </Col> */}
                            
                            <Label style={{color: 'white'}} sm={12} htmlFor="permitNoVal">{forms.permitToWorkForm.permitNo}</Label>
                        </Col>
                    </Row>
                    
                    {/* {forms.permitToWorkForm.formIsolationType==='2' &&  */}
                        <div>
                            <Row className="show-grid"> 
                                <Col sm={{span: 2, offset: 4}} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2ICC">ICC</label>
                                    <RRFInput
                                        id="isolation2ICC"
                                        type="checkbox"
                                        model=".isolation2ICC" 
                                        //defaultValue={answers && answers.isolation2ICC} 
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2RFIC">RFIC</label>
                                    <RRFInput
                                        id="isolation2RFIC"
                                        type="checkbox"
                                        model=".isolation2RFIC" 
                                        //defaultValue={answers && answers.isolation2RFIC} 
                                    />
                                </Col>
                            </Row> 
                            <Row className="show-grid"> 
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2Electrical">Electrical</label>
                                    <RRFInput
                                        id="isolation2Electrical"
                                        type="checkbox"
                                        model=".isolation2Electrical" 
                                        //defaultValue={answers && answers.isolation2Electrical} 
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2Mechanical">Mechanical</label>
                                    <RRFInput
                                        id="isolation2Mechanical"
                                        type="checkbox"
                                        model=".isolation2Mechanical" 
                                        //defaultValue={answers && answers.isolation2Mechanical} 
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2Instrumentation">Instrumentation</label>
                                    <RRFInput
                                        id="isolation2Instrumentation"
                                        type="checkbox"
                                        model=".isolation2Instrumentation" 
                                        //defaultValue={answers && answers.isolation2Instrumentation} 
                                    />
                                </Col>
                                <Col sm={3} className="input">
                                    <label className="checkbox-label" htmlFor="isolation2IsolationCertificateNo">Isolation Certificate No</label>
                                    <RRFInput
                                        id="isolation2IsolationCertificateNo"
                                        type="text"
                                        model=".isolation2IsolationCertificateNo" 
                                        defaultValue={answers && answers.isolation2IsolationCertificateNo} 
                                    />
                                </Col>
                            </Row> 
                            <Row className="show-grid"> 
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2Title">Title</label>
                                    <RRFInput
                                        id="isolation2Title"
                                        type="text"
                                        model=".isolation2Title" 
                                        defaultValue={answers && answers.isolation2Title} 
                                    />
                                </Col>
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2Facility">Facility</label>
                                    <RRFInput
                                        id="isolation2Facility"
                                        type="text"
                                        model=".isolation2Facility" 
                                        defaultValue={answers && answers.isolation2Facility} 
                                    />
                                </Col>
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2CreatedBy">Created By</label>
                                    <RRFInput
                                        id="isolation2CreatedBy"
                                        type="text"
                                        model=".isolation2CreatedBy" 
                                        defaultValue={answers && answers.isolation2CreatedBy} 
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={12} xs={12}>
                                    <Label required sm={12} style={{fontSize:'18px'}} >Isolation Details</Label>
                                </Col> 
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2Date">Date</label>
                                    <RRFInput
                                        id="isolation2Date"
                                        type="date"
                                        model=".isolation2Date"
                                        defaultValue={(forms.permitToWorkForm.isolation2Date && format(new Date(forms.permitToWorkForm.isolation2Date), 'yyyy-MM-dd') ) || null } 
                                    />
                                </Col>
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2Time">Time</label>
                                    <RRFInput
                                        id="isolation2Time"
                                        type="time"
                                        model=".isolation2Time" 
                                        defaultValue={answers && answers.isolation2Time} 
                                    />
                                </Col>
                                <Col sm={4} className="input">
                                    <label htmlFor="isolation2DetailsFacility">Facility</label>
                                    <RRFInput
                                        id="isolation2DetailsFacility"
                                        type="text"
                                        model=".isolation2DetailsFacility" 
                                        defaultValue={answers && answers.isolation2DetailsFacility} 
                                    />
                                </Col> 
                            </Row>
                            <Row className="show-grid">
                                <Col sm={12} className="input">
                                    <label htmlFor="isolatedSystemDescription">Number and/or description of equipment/system to be isolated:</label>
                                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.isolatedSystemDescription} model="forms.permitToWorkForm.isolatedSystemDescription" /> 
                                    <RRFInput
                                        id="isolatedSystemDescription"
                                        type="textarea"
                                        model=".isolatedSystemDescription" 
                                        defaultValue={answers && answers.isolatedSystemDescription} 
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={12} className="input">
                                    <label htmlFor="resonForIsolation">Reason for Isolation:</label>
                                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.resonForIsolation} model="forms.permitToWorkForm.resonForIsolation" /> 
                                    <RRFInput
                                        id="resonForIsolation"
                                        type="textarea"
                                        model=".resonForIsolation" 
                                        defaultValue={answers && answers.resonForIsolation} 
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={12} className="input">
                                    <label htmlFor="zeroEnergyDemonstrationMethod">Zero Energy Demonstration Method:</label>
                                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.zeroEnergyDemonstrationMethod} model="forms.permitToWorkForm.zeroEnergyDemonstrationMethod" /> 
                                    <RRFInput
                                        id="zeroEnergyDemonstrationMethod"
                                        type="textarea"
                                        model=".zeroEnergyDemonstrationMethod" 
                                        defaultValue={answers && answers.zeroEnergyDemonstrationMethod} 
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={6} className="input">
                                    <label className="checkbox-label" htmlFor="preEstablishedIsolation">Pre-established Isolation List Attached</label>
                                    <RRFInput
                                        id="preEstablishedIsolation"
                                        type="checkbox"
                                        model=".preEstablishedIsolation" 
                                        //defaultValue={answers && answers.preEstablishedIsolation} 
                                    />
                                </Col>
                                <Col sm={6} className="input">
                                    <label className="checkbox-label" htmlFor="preEstablishedBlindList">Pre-established Blind List Attached</label>
                                    <RRFInput
                                        id="preEstablishedBlindList"
                                        type="checkbox"
                                        model=".preEstablishedBlindList" 
                                        //defaultValue={answers && answers.preEstablishedBlindList} 
                                    />
                                </Col>
                            </Row>
                            {/* <Row className="assessment-hint">
                                <Accordion >
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} eventKey="0" className="risk-assessment-toggle" onClick={() => this.accordianProcess('1')}>
                                            {this.state.isolationControlPointsHint ?  <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 

                                            </Accordion.Toggle>
                                            Isolation Control Points
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <Row className="show-grid">
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} ><b>Isolating Type:</b></Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >E - Electrical</Label>
                                                <Label sm={12} >M - Mechanical</Label>
                                                <Label sm={12} >I - Instrument</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} ><b>Isolating Method:</b></Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >L - Locked</Label>
                                                <Label sm={12} >T - Tagged</Label>
                                                <Label sm={12} >P - Plugged</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >C - Capped</Label>
                                                <Label sm={12} >D - Disconnect</Label>
                                                <Label sm={12} >B - Blind</Label>
                                                <Label sm={12} >S - Spade</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >G - Grounded</Label>
                                                <Label sm={12} >RK - Racked</Label>
                                                <Label sm={12} >RMV - Removed</Label>
                                                <Label sm={12} >INST - Installed</Label> 
                                            </Col> 
                                        </Row>
                                        <Row className="show-grid">
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} ><b>Isolating State:</b></Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >NO - Normally Open</Label>
                                                <Label sm={12} >NC - Normally Closed</Label>
                                                <Label sm={12} >CSO - Car Sealed Open</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >CSC - Car Sealed Closed</Label>
                                                <Label sm={12} >PLUG - Plug in Place</Label>
                                                <Label sm={12} >DCT - Disconnected</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >CON - Connected</Label>
                                                <Label sm={12} >REC - Reconnected</Label>
                                                <Label sm={12} >APP - Applied</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >RMV - Removed</Label>
                                                <Label sm={12} >REP - Replaced</Label>
                                                <Label sm={12} >LO - Locked Open</Label>
                                            </Col>
                                            <Col sm={2} xs={12}>
                                                <Label sm={12} >LC - Locked Closed</Label>
                                                <Label sm={12} >IN - In</Label>
                                                <Label sm={12} >OUT - Out</Label>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> 
                                </Accordion>
                            </Row>  */}
                            
                            {/* <Row className="show-grid isolation-details"> 
                                <Table striped hover responsive="lg">
                                    <thead>
                                        <tr>
                                        <th colSpan={6}>Isolation Detail</th>
                                        <th colSpan={3}>Point Position</th>
                                        <th colSpan={2}>Installed</th>
                                        <th colSpan={2}>Removed</th>
                                        </tr>
                                        <tr>
                                        <th style={{width: '60px'}}>S#</th>
                                        <th style={{width: '200px'}}>Equipment ID:</th>
                                        <th style={{width: '200px'}}>Description:</th>
                                        <th>Isolation Type:</th>
                                        <th>Isolation Method:</th>
                                        <th>Comment:</th>
                                        <th style={{width: '200px'}}>Normal</th>
                                        <th>Isolated Type</th> 
                                        <th>IA Assigned</th>
                                        <th>Initial as In place</th>
                                        <th>IA Assigned</th>
                                        <th>Initial as removed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderIsolationDetail()} 
                                    </tbody> 
                                        
                                </Table>
                            </Row>
                            <Row className="show-grid" onClick={this.incrementIsolationDetailRows} >
                                <div className="table-buttons">
                                    <button className="add-row" >
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Another Step
                                    </button>
                                </div> 
                            </Row> 
                            <Row className="show-grid isolation-details"> 
                                <Col sm={12} xs={12}>
                                    <Label sm={12} ><b>Blind Register</b></Label>
                                </Col>
                                <Table striped hover responsive="lg">
                                    <thead>
                                        <tr>
                                        <th> </th>
                                        <th colSpan={3}>Isolation Method</th>
                                        <th colSpan={3}>Installed</th>
                                        <th colSpan={3}>Removed</th>
                                        </tr>
                                        <tr>
                                        <th style={{width: '60px'}}>Blind No.</th>
                                        <th>Description/Location</th>
                                        <th>Rating</th>
                                        <th>Size</th>
                                        <th>Type(S or B)</th>
                                        <th>Date</th>
                                        <th>IA Initial</th>
                                        <th>Verifier Initial</th>
                                        <th>Date</th>
                                        <th>IA Initial</th>
                                        <th>Verifier Initial</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {this.renderBlindRegisterIsolationMethod()}  
                                    </tbody> 
                                        
                                </Table>
                            </Row>
                            <Row className="show-grid" onClick={this.incrementBlindRegisterIsolationMethodRows} >
                                <div className="table-buttons">
                                    <button className="add-row" >
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Another Step
                                    </button>
                                </div> 
                            </Row> */}
                            <Row className="show-grid "> 
                                <Col sm={12} xs={12}>
                                    <Label sm={12} ><b>Plan Verification</b></Label>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <Label sm={12} >The isolation plan is correct for the proposed work.</Label>
                                </Col>
                                <Col sm={4} xs={12}>
                                    <Label sm={12} >Verified by FI:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationPIPlanVerificationName"
                                            type="text" 
                                            model=".isolationPIPlanVerificationName" 
                                            defaultValue={answers && answers.isolationPIPlanVerificationName} 
                                        />
                                    </Col>
                                </Col>
                                <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                    <Label required sm={12}>Sign:</Label> 
                                    {this.state.signatureTray.isolationPIPlanVerification ? 
                                        <ManualSignature manualSignsm={12}
                                            autoSignTitle={ forms.permitToWorkForm.isolationPIPlanVerificationName || 'FI'}
                                            saveSignature={this.saveSignature}
                                            saveGeneratedSignature={this.saveGeneratedSignature}
                                            model=".isolationPIPlanVerificationSignature"
                                            className={'signature'}
                                            autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                        /> : this.renderSignatureField(this.state.signatureURL.isolationPIPlanVerification, 'isolationPIPlanVerification')}
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Date:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationPIPlanVerificationDate"
                                            type="date" 
                                            model=".isolationPIPlanVerificationDate" 
                                            defaultValue={(forms.permitToWorkForm.isolationPIPlanVerificationDate && format(new Date(forms.permitToWorkForm.isolationPIPlanVerificationDate), 'yyyy-MM-dd') ) || null }
                                            maxdate={currentDate}
                                        />
                                    </Col>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Time:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationPIPlanVerificationTime"
                                            type="time" 
                                            model=".isolationPIPlanVerificationTime" 
                                            defaultValue={answers && answers.isolationPIPlanVerificationTime}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="show-grid "> 
                                <Col sm={12} xs={12}>
                                    <Label sm={12} ><b>Isolation Verification</b><br></br>Note: A second independent competent person must verify each isolation point and sign below.</Label>
                                </Col>
                                <Col sm={12} xs={12}>
                                    <Label sm={12} >The isolations have been identified and performed so that the equipment is properly isolated. </Label>
                                </Col>
                                <Col sm={4} xs={12}>
                                    <Label sm={12}>FI Verification:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationPIVerifyName"
                                            type="text" 
                                            model=".isolationVerificationPIVerifyName" 
                                            defaultValue={answers && answers.isolationVerificationPIVerifyName}
                                        />
                                        </Col>
                                </Col>
                                <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                    <Label required sm={12}>Sign:</Label> 
                                    {this.state.signatureTray.isolationPIIsolationVerification ? 
                                        <ManualSignature manualSignsm={12}
                                            autoSignTitle={ forms.permitToWorkForm.isolationVerificationPIVerifyName || 'FI'}
                                            saveSignature={this.saveSignature}
                                            saveGeneratedSignature={this.saveGeneratedSignature}
                                            model=".isolationPIIsolationVerificationSignature"
                                            className={'signature'}
                                            autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                        /> : this.renderSignatureField(this.state.signatureURL.isolationPIIsolationVerification, 'isolationPIIsolationVerification')}
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Date:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationPIVerifyDate"
                                            type="date" 
                                            model=".isolationVerificationPIVerifyDate" 
                                            defaultValue={(forms.permitToWorkForm.isolationVerificationPIVerifyDate && format(new Date(forms.permitToWorkForm.isolationVerificationPIVerifyDate), 'yyyy-MM-dd') ) || null } 
                                            maxdate={currentDate}
                                        />
                                    </Col>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Time:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationPIVerifyTime"
                                            type="time" 
                                            model=".isolationVerificationPIVerifyTime" 
                                            defaultValue={answers && answers.isolationVerificationPIVerifyTime}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col sm={4} xs={12}>
                                    <Label sm={12}>OI Verification:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationOIVerifyName"
                                            type="text" 
                                            model=".isolationVerificationOIVerifyName" 
                                            defaultValue={answers && answers.isolationVerificationOIVerifyName}
                                        />
                                        </Col>
                                </Col>
                                <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                    <Label required sm={12}>Sign:</Label> 
                                    {this.state.signatureTray.isolationOIIsolationVerification ? 
                                        <ManualSignature manualSignsm={12}
                                            autoSignTitle={ forms.permitToWorkForm.isolationVerificationOIVerifyName || 'FI'}
                                            saveSignature={this.saveSignature}
                                            saveGeneratedSignature={this.saveGeneratedSignature}
                                            model=".isolationOIIsolationVerificationSignature"
                                            className={'signature'}
                                            autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                        /> : this.renderSignatureField(this.state.signatureURL.isolationOIIsolationVerification, 'isolationOIIsolationVerification')}
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Date:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationOIVerifyDate"
                                            type="date" 
                                            model=".isolationVerificationOIVerifyDate" 
                                            defaultValue={(forms.permitToWorkForm.isolationVerificationOIVerifyDate && format(new Date(forms.permitToWorkForm.isolationVerificationOIVerifyDate), 'yyyy-MM-dd') ) || null } 
                                            maxdate={currentDate}
                                        />
                                    </Col>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Time:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="isolationVerificationOIVerifyTime"
                                            type="time" 
                                            model=".isolationVerificationOIVerifyTime" 
                                            defaultValue={answers && answers.isolationVerificationOIVerifyTime}
                                        />
                                    </Col>
                                </Col>
                            </Row> 
                        </div>
                    {/* } */}
                </fieldset>
            </div>
        );
    } 
    renderStep2 = () => {
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <div> 
                <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}>
                    <label style={{fontSize:'18px'}}>Form {this.state.currentStep} - Atmospheric Test</label>
                    {/* <Row className="show-grid">
                        <Col sm={{span: 4, offset: 4}} xs={12}>
                            <Label required sm={12} htmlFor="atmosphericTestType">Atmospheric Test Type:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="atmosphericTestType" 
                                    model=".atmosphericTestType"
                                    type="dropdown" 
                                    menuItems={[
                                        { label: 'Work Management System Atmospheric Test Record', value: 'Work Management System Atmospheric Test Record' }, { label: 'Work Management System Atmospheric Test Record (Supplement)', value: 'Work Management System Atmospheric Test Record (Supplement)' },
                                    ]}
                                />
                            </Col>
                        </Col>
                    </Row> */} 
                    {/* {forms.permitToWorkForm.atmosphericTestType==='Work Management System Atmospheric Test Record' &&  */}
                    <div>
                        <Row className="show-grid">
                            <Col sm={{span: 4, offset: 4}} xs={12}>
                                <Label required sm={12} htmlFor="permitNo">Permit No:</Label>
                                <Label style={{color: 'white'}} sm={12} htmlFor="permitNoVal">{forms.permitToWorkForm.permitNo}</Label>
                                {/* <Col sm={12} className="input">
                                    <RRFInput
                                        id="permitNo"
                                        type="text" 
                                        model=".permitNo" 
                                        defaultValue={forms.permitToWorkForm.permitNo}
                                    />
                                </Col> */}
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} htmlFor="areaToBeTested">Area to be Tested:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.areaToBeTested} model="forms.permitToWorkForm.areaToBeTested" />
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="areaToBeTested"
                                        type="text" 
                                        model=".areaToBeTested" 
                                        defaultValue={answers && answers.areaToBeTested}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                        <Row className="show-grid" >
                            <Col sm={3} xs={12}>
                                <label>Prior to work commencing:</label>
                            </Col>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="testFrequencyPrierToWorkYes">Yes</label>
                                <RRFInput
                                    id="testFrequencyPrierToWorkYes"
                                    type="checkbox"
                                    model=".testFrequencyPrierToWorkYes" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.testFrequencyPrierToWorkYes}
                                />
                            </Col>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="testFrequencyPrierToWorkNo">No</label>
                                <RRFInput
                                    id="testFrequencyPrierToWorkNo"
                                    type="checkbox"
                                    model=".testFrequencyPrierToWorkNo" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.testFrequencyPrierToWorkNo}
                                />
                            </Col>  
                            <Col sm={3} xs={12}>
                                <label>Continuous gas testing required:</label>
                            </Col>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="continuousGasTestYes">Yes</label>
                                <RRFInput
                                    id="continuousGasTestYes"
                                    type="checkbox"
                                    model=".continuousGasTestYes" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.continuousGasTestYes}
                                />
                            </Col>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="continuousGasTestNo">No</label>
                                <RRFInput
                                    id="continuousGasTestNo"
                                    type="checkbox"
                                    model=".continuousGasTestNo" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.continuousGasTestNo}
                                />
                            </Col> 
                        </Row>
                        <p style={{color: "yellow", marginTop: '18px', textAlign: 'left'}}>Test Frequency:</p>
                        <Row>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="testFrequencyEveryHoursYes">Yes</label>
                                <RRFInput
                                    id="testFrequencyEveryHoursYes"
                                    type="checkbox"
                                    model=".testFrequencyEveryHoursYes"  
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.testFrequencyEveryHoursYes}
                                />
                            </Col>
                            <Col sm={1} className="input">
                                <label className="checkbox-label" htmlFor="testFrequencyEveryHoursNo">No</label>
                                <RRFInput
                                    id="testFrequencyEveryHoursNo"
                                    type="checkbox"
                                    model=".testFrequencyEveryHoursNo" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.testFrequencyEveryHoursNo}
                                />
                            </Col>
                            {forms.permitToWorkForm.testFrequencyEveryHoursYes && 
                                <Col sm={2} xs={12}>
                                    <Label sm={12}>Every</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="testFrequencyEveryHours"
                                            type="text" 
                                            model=".testFrequencyEveryHours" 
                                            defaultValue={answers && answers.testFrequencyEveryHours}
                                        />
                                    </Col>
                                    <Label sm={12}>Hours:</Label>
                                </Col>
                            }
                        </Row> 
                        <p style={{color: "yellow", marginTop: '15px'}}>Required Test:</p>
                        <Row className="show-grid" >
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyO2">O2</label>
                                <RRFInput
                                    id="testFrequencyO2"
                                    type="checkbox"
                                    model=".testFrequencyO2" 
                                    //defaultValue={answers && answers.testFrequencyO2}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyLEL">LEL</label>
                                <RRFInput
                                    id="testFrequencyLEL"
                                    type="checkbox"
                                    model=".testFrequencyLEL" 
                                    //defaultValue={answers && answers.testFrequencyLEL}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyH2S">H2S</label>
                                <RRFInput
                                    id="testFrequencyH2S"
                                    type="checkbox"
                                    model=".testFrequencyH2S" 
                                    //defaultValue={answers && answers.testFrequencyH2S}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyCO">CO</label>
                                <RRFInput
                                    id="testFrequencyCO"
                                    type="checkbox"
                                    model=".testFrequencyCO" 
                                    //defaultValue={answers && answers.testFrequencyCO}
                                />
                            </Col> 
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyBenzene">Benzene</label>
                                <RRFInput
                                    id="testFrequencyBenzene"
                                    type="checkbox"
                                    model=".testFrequencyBenzene" 
                                    //defaultValue={answers && answers.testFrequencyBenzene}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencySO2">SO2</label>
                                <RRFInput
                                    id="testFrequencySO2"
                                    type="checkbox"
                                    model=".testFrequencySO2" 
                                    //defaultValue={answers && answers.testFrequencySO2}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyCO2">CO2</label>
                                <RRFInput
                                    id="testFrequencyCO2"
                                    type="checkbox"
                                    model=".testFrequencyCO2" 
                                    //defaultValue={answers && answers.testFrequencyCO2}
                                />
                            </Col>
                            <Col sm={2} className="input ta-right">
                                <label className="checkbox-label" htmlFor="testFrequencyOther">Others</label>
                                <RRFInput
                                    id="testFrequencyOther"
                                    type="checkbox"
                                    model=".testFrequencyOther" 
                                    //defaultValue={answers && answers.testFrequencyOther}
                                />
                            </Col>
                            { forms.permitToWorkForm.testFrequencyOther &&  <Col sm={3} xs={12}>
                                <Label sm={12}>Describe:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="testFrequencyOtherDescribe"
                                        type="text" 
                                        model=".testFrequencyOtherDescribe" 
                                        defaultValue={answers && answers.testFrequencyOtherDescribe}
                                    />
                                </Col>
                            </Col> }
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={{span:5, offset: 1}}>
                                <span style={{ color: 'yellow' }} >Detecter used </span> 
                            </Col> 
                            <Col sm={5}>
                                <span style={{ color: 'yellow' }} >Calibiration date</span> 
                            </Col> 
                            <Col sm={1}>
                                <span style={{ color: 'yellow' }} ></span> 
                            </Col> 
                        </Row>
                        {this.renderDetecterCalibiration()}
                        
                        <Row className="show-grid" >
                            <div className="table-buttons" onClick={()=>this.incrementDetecterCalibirationRows}>
                                <button className="add-row" type="button" >
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    &nbsp;Add Another Step
                                </button>
                            </div>
                        </Row>

                        
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Comments/Special Precautions</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.commentsSpecialPracautions } model="forms.permitToWorkForm.commentsSpecialPracautions" />
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="commentsSpecialPracautions"
                                        type="textarea" 
                                        model=".commentsSpecialPracautions" 
                                        defaultValue={answers && answers.commentsSpecialPracautions}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Results (Pre-Start + Work Period Tests)</Label>
                            </Col>
                        </Row>
                        {this.renderCSEOptions()}
                        {/* <Row className="show-grid" >
                            <Col sm={{span: 4 }}> <span style={{ color: 'yellow' }} >Authorized Gas Tester (AGT) Initials: </span> </Col> 
                            <Col sm={2} xs={12}> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="ATAGT"
                                        type="text" 
                                        model=".ATAGT" 
                                        defaultValue={answers && answers.ATAGT}
                                    />
                                </Col>
                            </Col> 
                        </Row> */} 
                        {forms.permitToWorkForm.CSEClassLimit && <><Row className="show-grid">
                            <p style={{color: 'white'}}> O2 - Oxygen, LEL - Lower Explosive Limit Flammability — General Work Flammability — Hot Work/Critical Work — Spark Potential, THC - Total Hydrocarbon Content, H2S - Hydrogen Sulphide, CO - Carbon Monoxide, SO2 - Sulphur Dioxide </p>
                        </Row> </>}
                    </div>
                    {/*  } */}
                    {/* {forms.permitToWorkForm.atmosphericTestType==='Work Management System Atmospheric Test Record (Supplement)' && 
                    <div> 
                        {this.renderCSEOptions()}   
                        <Row className="show-grid" >
                            <Col sm={{span: 4, offset: 6}}> <span style={{ color: 'yellow' }} >Authorized Gas Tester (AGT) Initials: </span> </Col> 
                            <Col sm={2} xs={12}> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="AGTInitials"
                                        type="text" 
                                        model=".AGTInitials" 
                                    />
                                </Col>
                            </Col> 
                        </Row>  */}
                        {/* {forms.permitToWorkForm.CSEClassLimit && <><Row className="show-grid">
                            <p style={{color: 'white'}}> O2 - Oxygen, LEL - Lower Explosive Limit Flammability — General Work Flammability — Hot Work/Critical Work — Spark Potential, VOC - Volatile Organic Compounds, H2S - Hydrogen Sulphide, CO - Carbon Monoxide, SO2 - Sulphur Dioxide </p>
                        </Row> </>} */}
                        {/* <Row className="show-grid" >
                            <Col sm={6} xs={12}>
                                <Label sm={12}>Print name of the Authorized Gas Tester (AGT):</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="AGTName"
                                        type="text" 
                                        model=".AGTName" 
                                        defaultValue={answers && answers.AGTName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.AtmospericAGT ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.AGTName || 'AGT'}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".AtmospericAGTSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.AtmospericAGT, 'AtmospericAGT')}
                            </Col> 
                        </Row> */}
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Authorized Gas Tester(AGT) Comments/Special Precautions:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.AGTComments} model="forms.permitToWorkForm.AGTComments" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="AGTComments"
                                        type="textarea" 
                                        model=".AGTComments" 
                                        defaultValue={answers && answers.AGTComments}
                                    />
                                </Col>
                            </Col> 
                        </Row> 
                    {/* </div>
                    } */}
                </fieldset>
            </div>
        );  
    }
    renderStep1 = () => { 
        const { handlePermitType, state: { currentDate, }, props: {forms } } = this;  
        let answers = forms.permitToWorkForm || ''; 
        console.log(answers.permitType)
        return (
                <div> 
                    <fieldset className={`${(forms.permitToWorkForm.permitType || '').replace(/ /g, "-")}permitForm`}> 
                        <label style={{fontSize:'18px'}}>Form I - Permit</label>
                        <Row className="show-grid">
                            <Col sm={{span: 4, offset: 4}} xs={12}>
                                <Label required sm={12} htmlFor="observationType">Permit Type:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="permitType"
                                        onSelect={handlePermitType}
                                        model=".permitType"
                                        type="dropdown" 
                                        menuItems={[{ label: 'Hot work', value: 'Hot work' },
                                            { label: 'Confined Space', value: 'Confined Space' },
                                            { label: 'Critical Works', value: 'Critical Works' },
                                            { label: 'General', value: 'General' }
                                        ]} 
                                        defaultValue={answers && answers.permitType}
                                        //isDisabled={answers && answers.permitType?true:false}
                                    />
                                </Col>
                            </Col>
                        </Row> 
                        {forms.permitToWorkForm.permitType && 
                        <div>
                        <Row className="show-grid">
                            <Col sm={{span: 4, offset: 4}} xs={12}>
                                <Label required sm={12}>Permit No:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="permitNo"
                                        type="text" 
                                        model=".permitNo" 
                                        defaultValue={forms.permitToWorkForm.permitNo}
                                    />
                                </Col>  
                            </Col>
                        </Row>
                        {forms.permitToWorkForm.permitType==='Critical Works' && <Row className="show-grid">
                            <Col sm={12} xs={12}>
                                <Label required sm={12} style={{fontSize:'18px', marginBottom: '10px'}}>Critical Work Permit Type</Label>
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="abrasiveBlasting">Abrasive Blasting</label>
                                <RRFInput
                                    id="abrasiveBlasting"
                                    type="checkbox"
                                    model=".abrasiveBlasting" 
                                    //defaultValue={answers && answers.abrasiveBlasting==='1'}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="breakingContainment">Breaking Containment</label>
                                <RRFInput
                                    id="breakingContainment"
                                    type="checkbox"
                                    model=".breakingContainment" 
                                    //defaultValue={answers && answers.breakingContainment}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="criticalLift">Critical Lift </label>
                                <RRFInput
                                    id="criticalLift"
                                    type="checkbox"
                                    model=".criticalLift" 
                                    //defaultValue={answers && answers.criticalLift}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="drivingOperations">Diving Operations </label>
                                <RRFInput
                                    id="drivingOperations"
                                    type="checkbox"
                                    model=".drivingOperations" 
                                    //defaultValue={answers && answers.drivingOperations}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="excavationGroundDisturbance">Exe/Ground Disturbance </label>
                                <RRFInput
                                    id="excavationGroundDisturbance"
                                    type="checkbox"
                                    model=".excavationGroundDisturbance" 
                                    //defaultValue={answers && answers.excavationGroundDisturbance}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="highRiskElectrical">High Risk Electrical</label>
                                <RRFInput
                                    id="highRiskElectrical"
                                    type="checkbox"
                                    model=".highRiskElectrical" 
                                    //defaultValue={answers && answers.highRiskElectrical}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="overTheSideRoad">Over the Side Road</label>
                                <RRFInput
                                    id="overTheSideRoad"
                                    type="checkbox"
                                    model=".overTheSideRoad" 
                                    //defaultValue={answers && answers.overTheSideRoad}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="sparkPotential">Spark Potential</label>
                                <RRFInput
                                    id="sparkPotential"
                                    type="checkbox"
                                    model=".sparkPotential" 
                                    //defaultValue={answers && answers.sparkPotential}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="workAtHeights">Work at Heights</label>
                                <RRFInput
                                    id="workAtHeights"
                                    type="checkbox"
                                    model=".workAtHeights" 
                                    //defaultValue={answers && answers.workAtHeights}
                                />
                            </Col>
                            <Col sm={4} className="input ta-right">
                                <label className="checkbox-label" htmlFor="workingOnPressurizedSystems">Working on Pressurized Systems</label>
                                <RRFInput
                                    id="workingOnPressurizedSystems"
                                    type="checkbox"
                                    model=".workingOnPressurizedSystems" 
                                    //defaultValue={answers && answers.workingOnPressurizedSystems}
                                />
                            </Col>
                            <Col sm={4} className="input ta-right">
                                <label className="checkbox-label" htmlFor="workingInExposedLocations">Working in Exposed Locations</label>
                                <RRFInput
                                    id="workingInExposedLocations"
                                    type="checkbox"
                                    model=".workingInExposedLocations" 
                                    //defaultValue={answers && answers.workingInExposedLocations}
                                />
                            </Col> 
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="otherCWP">Other</label>
                                <RRFInput
                                    id="otherCWP"
                                    type="checkbox"
                                    model=".otherCWP" 
                                    //defaultValue={answers && answers.otherCWP}
                                />
                            </Col>
                            {forms.permitToWorkForm.otherCWP && <Col sm={3} xs={12}>
                                <Label sm={12}>Mention other CWP:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="mentionOtherCWP"
                                        type="text" 
                                        model=".mentionOtherCWP" 
                                        defaultValue={answers && answers.mentionOtherCWP}
                                    />
                                </Col>
                            </Col> }
                        </Row> }
                         
                        <Row className="show-grid">
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="plannedDateOfWork">Planned Date of Work:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="plannedDateOfWork"
                                        type="date" 
                                        model=".plannedDateOfWork"
                                        defaultValue={ (forms.permitToWorkForm.plannedDateOfWork && format(new Date(forms.permitToWorkForm.plannedDateOfWork), 'yyyy-MM-dd')) || null}
                                        mindate={this.state.formMinDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="plannedTimeOfWork">Planned Start Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="plannedTimeOfWork" 
                                        type="time"
                                        model=".plannedTimeOfWork" 
                                        defaultValue={answers && answers.plannedTimeOfWork}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Validity Period:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="validityPeriod"
                                        type="text" 
                                        model=".validityPeriod" 
                                        defaultValue={answers && answers.validityPeriod}
                                    />
                                </Col>
                            </Col>
                            <LocationInput  
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="oi-form" sm={3}
                                navigator={navigator} 
                                defaultValue={answers && answers.locationName}
                            />
                            <Col sm={12} xs={12}>
                                <Label sm={12} htmlFor="taskDescription">Task Description:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.taskDescription} model="forms.permitToWorkForm.taskDescription" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="taskDescription" 
                                        type="textarea"
                                        model=".taskDescription"
                                        className="non-resizable" 
                                        defaultValue={answers && answers.taskDescription}
                                    />
                                </Col>
                            </Col>
                            <Col sm={{offset: 3, span: 3}} xs={12}>
                                <Label sm={12}>Task Area:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.taskArea} model="forms.permitToWorkForm.taskArea" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="taskArea"
                                        type="text" 
                                        model=".taskArea" 
                                        defaultValue={answers && answers.taskArea}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Equipment ID/Tag Number:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="equipmentId"
                                        type="text" 
                                        model=".equipmentId" 
                                        defaultValue={answers && answers.equipmentId}
                                    />
                                </Col>
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label sm={12} htmlFor="equipmentDescription">Equipment Description:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.equipmentDescription} model="forms.permitToWorkForm.equipmentDescription" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="equipmentDescription" 
                                        type="textarea"
                                        model=".equipmentDescription"
                                        className="non-resizable" 
                                        defaultValue={answers && answers.equipmentDescription}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Permit Holder:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="leadDiscipline"
                                        type="text" 
                                        model=".leadDiscipline" 
                                        defaultValue={answers && answers.leadDiscipline}
                                    />
                                </Col>
                            </Col>
                            <Col sm={9} xs={12}>
                                <Label sm={12} style={{textAlign: 'left'}}>People:</Label> 
                                <Row className="show-grid"> {this.renderNoOfPeople()} 
                                    <Col sm={1} xs={12} onClick={ this.incrementSignOffRows}> 
                                        <button className="add-row signOffAddBtn" type="button" >
                                            <i className="fa fa-plus" aria-hidden="true"></i> 
                                        </button> 
                                    </Col>
                                </Row>
                                {/* <Label sm={12}>People:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="noOfPeople"
                                        type="textarea" 
                                        model=".noOfPeople" 
                                        defaultValue={answers && answers.noOfPeople} 
                                    />
                                </Col>
                                <label style={{color: '#ffe2e2', fontSize: '14px' }} >*Enter the multiple people name with seperate by comma(,) </label> */}
                            </Col>
                            <p style={{color: "yellow", marginTop: '15px', fontSize: '18px'}}>Isolations Required:</p>
                            <Col sm={3} className="input">
                                <label className="checkbox-label" htmlFor="iccIsolations">ICC</label>
                                <RRFInput
                                    id="iccIsolations"
                                    type="checkbox"
                                    model=".iccIsolations"  
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.iccIsolations}
                                />
                            </Col>
                            <Col sm={3} className="input">
                                <label className="checkbox-label" htmlFor="rficIsolations">RFIC</label>
                                <RRFInput
                                    id="rficIsolations"
                                    type="checkbox"
                                    model=".rficIsolations" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.rficIsolations}
                                />
                            </Col>
                            <Col sm={3} className="input">
                                <label className="checkbox-label" htmlFor="personnalIsolations">Personnel</label>
                                <RRFInput
                                    id="personnalIsolations"
                                    type="checkbox"
                                    model=".personnalIsolations" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.personnalIsolations}
                                />
                            </Col>
                            <Col sm={3} className="input">
                                <label className="checkbox-label" htmlFor="noneIsolations">None</label>
                                <RRFInput
                                    id="noneIsolations"
                                    type="checkbox"
                                    model=".noneIsolations" 
                                    onChange={this.onChangeQuestionYesNo} 
                                    //defaultValue={answers && answers.noneIsolations}
                                />
                            </Col>
                            <Col sm={{span: 3, offset: 2}} xs={12}>
                                <Label sm={12}>Work Order Number:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="workOrderNumber"
                                        type="text" 
                                        model=".workOrderNumber" 
                                        defaultValue={answers && answers.workOrderNumber}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Requested By:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="requestedBy"
                                        type="text" 
                                        model=".requestedBy" 
                                        defaultValue={answers && answers.requestedBy}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Company:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="company"
                                        type="text" 
                                        model=".company" 
                                        defaultValue={answers && answers.company}
                                    />
                                </Col>
                            </Col>
                        </Row> 
                        <p style={{color: "yellow", marginTop: '15px', fontSize: '18px'}}>Permit Pack Required Documents:</p>
                        <Row className="show-grid" >
                            <Col sm={3} className="input ta-right pl0">
                                <label className="checkbox-label" htmlFor="admosphericTestRecords">Admospheric Test Record</label>
                                <RRFInput
                                    id="admosphericTestRecords"
                                    type="checkbox"
                                    model=".admosphericTestRecords" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.admosphericTestRecords==='1'?'1':null}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="isolationCertificate">Isolation Certificate</label>
                                <RRFInput
                                    id="isolationCertificate"
                                    type="checkbox"
                                    model=".isolationCertificate" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.isolationCertificate}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="jsatha">JSA</label>
                                <RRFInput
                                    id="jsatha"
                                    type="checkbox"
                                    model=".jsatha" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.jsatha}
                                />
                            </Col> 
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="swpChecklist">SWP Checklist</label>
                                <RRFInput
                                    id="swpChecklist"
                                    type="checkbox"
                                    model=".swpChecklist" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={null} 
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="temporaryDefeat">Temporary Defeat</label>
                                <RRFInput
                                    id="temporaryDefeat"
                                    type="checkbox"
                                    model=".temporaryDefeat" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.temporaryDefeat}
                                />
                            </Col>
                            
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="liftingPlan">Lifting Plan</label>
                                <RRFInput
                                    id="liftingPlan"
                                    type="checkbox"
                                    model=".liftingPlan" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.liftingPlan}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="msds">MSDS</label>
                                <RRFInput
                                    id="msds"
                                    type="checkbox"
                                    model=".msds" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.msds}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="pidDrawing">P&ID/Drawing</label>
                                <RRFInput
                                    id="pidDrawing"
                                    type="checkbox"
                                    model=".pidDrawing" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.pidDrawing}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="procedure">Procedure</label>
                                <RRFInput
                                    id="procedure"
                                    type="checkbox"
                                    model=".procedure" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.procedure}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="rescuePlan">Rescue Plan</label>
                                <RRFInput
                                    id="rescuePlan"
                                    type="checkbox"
                                    model=".rescuePlan" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.rescuePlan}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="simopDeviation">SIMOP Deviation</label>
                                <RRFInput
                                    id="simopDeviation"
                                    type="checkbox"
                                    model=".simopDeviation" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.simopDeviation}
                                />
                            </Col>
                            <Col sm={3} className="input ta-right">
                                <label className="checkbox-label" htmlFor="workAid">Work Aid</label>
                                <RRFInput
                                    id="workAid"
                                    type="checkbox"
                                    model=".workAid" 
                                    onChange={this.onChangePermitPack} 
                                    //defaultValue={answers && answers.workAid}
                                />
                            </Col>
                             
                        </Row>
                        <Row className="show-grid" >
                            <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'18px'}}> Facility Incharge (FI):</b>  </p>
                            <Col sm={12} xs={12} style={{color: "yellow" }}>
                                <p>1. The task description accurately details the permit work activity. The required permit pack documentation has been identified.</p>
                                <p>2. I have endorsed this work and it will not conflict with other activities. It can commence when the permit conditions are fulfiled.</p>
                                <p>3. I have demonstrated zero energy to the Permit Holder (when required and not delegated). </p>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Print Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="PIReviewerName"
                                        type="text" 
                                        model=".PIReviewerName" 
                                        defaultValue={answers && answers.PIReviewerName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.PIReviewer ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={ forms.permitToWorkForm.PIReviewerName || 'FI'}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".PIReviewerSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.PIReviewer, 'PIReviewer')}
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="PIReviewerDate"
                                        type="date" 
                                        model=".PIReviewerDate" 
                                        defaultValue={ (forms.permitToWorkForm.PIReviewerDate && format(new Date(forms.permitToWorkForm.PIReviewerDate), 'yyyy-MM-dd') ) || null }
                                        //mindate={forms.permitToWorkForm.plannedDateOfWork || currentDate}
                                        maxdate={currentDate}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="PIReviewerTime"
                                        type="time" 
                                        model=".PIReviewerTime" 
                                        defaultValue={answers && answers.PIReviewerTime}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                        <Row className="show-grid" >
                            <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'18px'}}> Overall Incharge (OI):</b>  </p> 
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Print Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="OIReviewerName"
                                        type="text" 
                                        model=".OIReviewerName" 
                                        defaultValue={answers && answers.OIReviewerName}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.signatureTray.OIReviewer ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={forms.permitToWorkForm.OIReviewerName || 'AO'}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        model=".OIReviewerSignature"
                                        className={'signature'}
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.signatureURL.OIReviewer, 'OIReviewer')}
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="OIReviewerDate"
                                        type="date" 
                                        model=".OIReviewerDate"
                                        defaultValue={ (forms.permitToWorkForm.OIReviewerDate && format(new Date(forms.permitToWorkForm.OIReviewerDate), 'yyyy-MM-dd') ) || null }
                                        maxdate={currentDate}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Label sm={12}>Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="OIReviewerTime"
                                        type="time" 
                                        model=".OIReviewerTime" 
                                        defaultValue={answers && answers.OIReviewerTime}
                                    />
                                </Col>
                            </Col>
                        </Row> 
                        <Row className="show-grid" >
                            <p style={{color: "yellow", marginTop: '15px', fontSize: '18px'}}>Permit Shift Handover/Extension/Reissue</p>
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={2}>
                                <span style={{ color: 'yellow' }} >Date </span>
                                <span style={{ color: 'red' }} >*</span>
                            </Col> 
                            <Col sm={3}>
                                <span style={{ color: 'yellow' }} >Permit Shift Type</span>
                                <span style={{ color: 'red' }} >*</span>
                            </Col>
                            <Col sm={3}>
                                <span style={{ color: 'yellow' }} >Facility Incharge</span>
                                <span style={{ color: 'red' }} >*</span>
                            </Col>
                            <Col sm={3}>
                                <span style={{ color: 'yellow' }} >Overall Incharge</span>
                                <span style={{ color: 'red' }} >*</span>
                            </Col> 
                            <Col sm={1}>
                                <span style={{ color: 'yellow' }} ></span> 
                            </Col> 
                        </Row>
                        {this.renderPermitShiftHandover()}
                        
                        <Row className="show-grid">
                            <div className="table-buttons">
                                <button className="add-row" type="button" onClick={ this.incrementPermitShiftHandoverRows}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    &nbsp;Add Another Step
                                </button>
                            </div>
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={1} xs={12}> 
                                <p style={{color: 'red', float: 'right'}}>*</p>
                            </Col>
                            <Col sm={11} xs={12}> 
                                <p style={{color: 'yellow', float: 'left', textAlign: 'left'}}>
                                    1. Any changes of FI, OI personnel on the permit must sign accepting their responsibility.<br></br> 
                                </p>
                            </Col>
                        </Row>
                        
                        </div>
                         
                        }  
                    </fieldset>
                    
                </div>
        );
    } 
    onChangeclosePermitCloseOut = async () => {
        const { forms } = this.props;
        let ptwForm = forms.permitToWorkForm;  
        if(isArrayEmpty(ptwForm.signOffName) || isArrayEmpty(ptwForm.signOffDate) || isArrayEmpty(ptwForm.signOffInTime) || isArrayEmpty(ptwForm.signOffOutTime) ) { 
            showAlert('Please fill employees all details', `PTW-form-failed`, 'danger'); 
            await dispatch(actions.change(`forms.permitToWorkForm.closePermitCloseOut`, ''));
            return false;
        }
        if(isFormEmpty({PCOOIIsolationsRemovedYes: ptwForm.PCOOIIsolationsRemovedYes, PCOOIName: ptwForm.PCOOIName, PCOOIDate: ptwForm.PCOOIDate, PCOOITime: ptwForm.PCOOITime})) {
            showAlert('Please fill permit holder details', `PTW-form-failed`, 'danger'); 
            await dispatch(actions.change(`forms.permitToWorkForm.closePermitCloseOut`, ''));
            return false;
        }
        /* if(isFormEmpty({PCOPIName: ptwForm.PCOPIName, PCOPIDate: ptwForm.PCOPIDate, PCOPITime: ptwForm.PCOPITime})) {
            showAlert('Please fill facility incharge details', `PTW-form-failed`, 'danger'); 
            await dispatch(actions.change(`forms.permitToWorkForm.closePermitCloseOut`, ''));
            return false; 
        } */
        return true;
    }
    formIsolationTypeChange = async (event, value: string) => { 
        await dispatch(actions.change(`forms.permitToWorkForm.formIsolationType`, value));
    } 
    onChangeQuestionYesNo = async (res, model) => {
        const { forms } = this.props;
        const { testFrequencyPrierToWorkYes, testFrequencyPrierToWorkNo, testFrequencyEveryHoursYes,testFrequencyEveryHoursNo, continuousGasTestYes, continuousGasTestNo, /* COPDefeatYes, COPDefeatNo, COPApprovedYes, COPApprovedNo, */ pcoWorkCompletedYes, pcoWorkCompletedNo, PCOOIIsolationsRemovedYes, PCOOIIsolationsRemovedNo, noneIsolations } = this.props.forms.permitToWorkForm; 
        if(model==='iccIsolations' || model==='rficIsolations' || model==='personnalIsolations') {
            await dispatch(actions.change(`forms.permitToWorkForm.noneIsolations`, false));
        }
        if(model==='noneIsolations') {  
            if(noneIsolations) {
                await dispatch(actions.change(`forms.permitToWorkForm.iccIsolations`, false));
                await dispatch(actions.change(`forms.permitToWorkForm.rficIsolations`, false));
                await dispatch(actions.change(`forms.permitToWorkForm.personnalIsolations`, false));
            }
        }
        if(model==='testFrequencyPrierToWorkYes') { 
            if(testFrequencyPrierToWorkYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.testFrequencyPrierToWorkNo`, false));
            }
        }
        if(model==='testFrequencyPrierToWorkNo') { 
            if(testFrequencyPrierToWorkNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.testFrequencyPrierToWorkYes`, false));
            }
        }
        if(model==='testFrequencyEveryHoursYes') {
            if(testFrequencyEveryHoursYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.testFrequencyEveryHoursNo`, false));
            }
        } 
        if(model==='testFrequencyEveryHoursNo') { 
            if(testFrequencyEveryHoursNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.testFrequencyEveryHoursYes`, false));
            }
        } 
        if(model==='continuousGasTestYes') {
            if(continuousGasTestYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.continuousGasTestNo`, false));
            }
        } 
        if(model==='continuousGasTestNo') { 
            if(continuousGasTestNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.continuousGasTestYes`, false));
            }
        }
        /* if(model==='COPDefeatYes') {
            if(COPDefeatYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.COPDefeatNo`, false));
            }
        } 
        if(model==='COPDefeatNo') { 
            if(COPDefeatNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.COPDefeatYes`, false));
            }
        }
        if(model==='COPApprovedYes') {
            if(COPApprovedYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.COPApprovedNo`, false));
            }
        } 
        if(model==='COPApprovedNo') { 
            if(COPApprovedNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.COPApprovedYes`, false));
            }
        } */
        if(model==='pcoWorkCompletedYes') {
            if(pcoWorkCompletedYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.pcoWorkCompletedNo`, false));
                if(!forms.permitToWorkForm.closeWorkDecleration) {
                    await dispatch(actions.change(`forms.permitToWorkForm.pcoWorkCompletedYes`, false)); 
                    showAlert('Please tick Finalize this Permit to Work(in prvious form)', `PTW-form-closeout-1-failed`, 'danger'); 
                }
            }
        } 
        if(model==='pcoWorkCompletedNo') { 
            if(pcoWorkCompletedNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.pcoWorkCompletedYes`, false));
            }
        }
        if(model==='PCOOIIsolationsRemovedYes') {
            if(PCOOIIsolationsRemovedYes) {
                await dispatch(actions.change(`forms.permitToWorkForm.PCOOIIsolationsRemovedNo`, false));
            }
        } 
        if(model==='PCOOIIsolationsRemovedNo') { 
            if(PCOOIIsolationsRemovedNo) {
                await dispatch(actions.change(`forms.permitToWorkForm.PCOOIIsolationsRemovedYes`, false));
            }
        } 
    }
    renderCSEOptions = () => {
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <><Row className="show-grid">
                <Col sm={{span: 2, offset: 5 }} className="input">
                    <label className="checkbox-label" htmlFor="CSEClassLimit">CSE Class Limit</label>
                    <RRFInput
                        id="CSEClassLimit" 
                        model=".CSEClassLimit"
                        type="dropdown" 
                        menuItems={[ { label: 'Class 1', value: 'Class 1' }, { label: 'Class 2', value: 'Class 2' }, ]}
                        defaultValue={answers && answers.CSEClassLimit}
                    />
                </Col>
                {this.AT1CSERows<3 && 
                    <Col sm={{offset:1, span: 4}}> 
                        <div className="table-buttons" onClick={()=>this.incrementAT1CSERows}>
                            <button className="add-row" type="button" >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Step
                            </button>
                        </div> 
                    </Col>
                }
            </Row>
            <Row className="show-grid"> 
                <div style={{width: '200px'}}> </div>
                { forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} > Pre-Start Test Result </span> </div> }
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} > Result </span> </div> 
            </Row>
            <Row className="show-grid">
                {this.renderAtmospericCSE()}  
            </Row> </>
        );
    } 
    ATDateResult = (atmospericCSEStep) => {  
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSEDate"
                            type="date" 
                            model={`.AT1CSEDate[${index}]`} 
                            defaultValue={ (forms.permitToWorkForm.AT1CSEDate && forms.permitToWorkForm.AT1CSEDate[index] && format(new Date(forms.permitToWorkForm.AT1CSEDate[index]), 'yyyy-MM-dd')) ||(answers && answers.AT1CSEDate && answers.AT1CSEDate.length>index && format(new Date(answers.AT1CSEDate[index]), 'yyyy-MM-dd')) || null }  
                        />
                    </Col>
                </div>
            ); 
        }); 
    }
    ATTimeResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSETime"
                            type="time" 
                            model={`.AT1CSETime[${index}]`}
                            defaultValue={answers && answers.AT1CSETime && answers.AT1CSETime[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    ATO2Result = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSEO2"
                            type="text" 
                            model={`.AT1CSEO2[${index}]`}
                            defaultValue={answers && answers.AT1CSEO2 && answers.AT1CSEO2[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    }
    ATLELGWResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSELELGW"
                            type="text" 
                            model={`.AT1CSELELGW[${index}]`}
                            defaultValue={answers && answers.AT1CSELELGW && answers.AT1CSELELGW[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    ATLELFResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSELELF"
                            type="text" 
                            model={`.AT1CSELELF[${index}]`}
                            defaultValue={answers && answers.AT1CSELELF &&  answers.AT1CSELELF[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    }  
    ATCOResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSECO"
                            type="text" 
                            model={`.AT1CSECO[${index}]`}
                            defaultValue={answers && answers.AT1CSECO && answers.AT1CSECO[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    }  
    ATBenzeneResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSEBenzene"
                            type="text" 
                            model={`.AT1CSEBenzene[${index}]`}
                            defaultValue={answers && answers.AT1CSEBenzene && answers.AT1CSEBenzene[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    ATSO2Result = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSESO2"
                            type="text" 
                            model={`.AT1CSESO2[${index}]`}
                            defaultValue={answers && answers.AT1CSESO2 && answers.AT1CSESO2[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    ATAGTInitialsResult = (atmospericCSEStep) => {
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSEAGTInitials"
                            type="text" 
                            model={`.AT1CSEAGTInitials[${index}]`}
                            defaultValue={answers && answers.AT1CSEAGTInitials && answers.AT1CSEAGTInitials[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    }
    ATVOCResult = (atmospericCSEStep) => {
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT2CSEVOC"
                            type="text" 
                            model={`.AT2CSEVOC[${index}]`}
                            defaultValue={answers && answers.AT2CSEVOC && answers.AT2CSEVOC[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    }
    ATH2SResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSEH2S"
                            type="text" 
                            model={`.AT1CSEH2S[${index}]`}
                            defaultValue={answers && answers.AT1CSEH2S && answers.AT1CSEH2S[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    ATTHCResult = (atmospericCSEStep) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return atmospericCSEStep.map((a, index) => {  
            return (
                <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="AT1CSETHC"
                            type="text" 
                            model={`.AT1CSETHC[${index}]`}
                            defaultValue={answers && answers.AT1CSETHC && answers.AT1CSETHC[index]}
                        />
                    </Col>
                </div>
            ); 
        }); 
    } 
    workTeamDeclerationRows = 1; 
    incrementWorkTeamDeclerationRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationDate`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationName`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationSignOn`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationDiffDate`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationSignOff`, ''));  
        this.setState({ workTeamDeclerationStep: [...this.state.workTeamDeclerationStep, ++this.workTeamDeclerationRows] }); 
    }
    decrementWorkTeamDeclerationRows = async (indexVal: number) => { 
        --this.workTeamDeclerationRows; 
        await dispatch(actions.remove(`forms.permitToWorkForm.workTeamDeclerationDate`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.workTeamDeclerationName`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.workTeamDeclerationSignOn`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.workTeamDeclerationDiffDate`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.workTeamDeclerationSignOff`, indexVal));
        await dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationDate`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationName`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationSignOn`, ''));  
        await dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationDiffDate`, ''));  
        await dispatch(actions.push(`forms.permitToWorkForm.workTeamDeclerationSignOff`, ''));  
        this.setState({ workTeamDeclerationStep: this.state.workTeamDeclerationStep.slice(0, - 1) }); 
    }
    renderWorkTeamDecleration = () => {
        let workTeamDeclerationStep = this.state.workTeamDeclerationStep;  
        return workTeamDeclerationStep.map((a, index) => {  
            return this.renderWorkTeamDeclerationHtml(index); 
        });
    } 
    renderWorkTeamDeclerationHtml = (index: number = 1 ) => { 
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <Row className="show-grid" > 
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="workTeamDeclerationDate"
                            type="date" 
                            model={`.workTeamDeclerationDate[${index}]`} 
                            defaultValue={ (forms.permitToWorkForm.workTeamDeclerationDate && forms.permitToWorkForm.workTeamDeclerationDate[index] && format(new Date(forms.permitToWorkForm.workTeamDeclerationDate[index]), 'yyyy-MM-dd')) ||(answers && answers.workTeamDeclerationDate && answers.workTeamDeclerationDate.length>index && format(new Date(answers.workTeamDeclerationDate[index]), 'yyyy-MM-dd')) || null } 
                        />
                    </Col>
                </Col>
                <Col sm={3} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="workTeamDeclerationName"
                            type="text" 
                            model={`.workTeamDeclerationName[${index}]`}
                            defaultValue={answers && answers.workTeamDeclerationName[index]} 
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="workTeamDeclerationSignOn"
                            type="text" 
                            model={`.workTeamDeclerationSignOn[${index}]`}
                            defaultValue={answers && answers.workTeamDeclerationSignOn[index]} 
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="workTeamDeclerationDiffDate"
                            type="date" 
                            model={`.workTeamDeclerationDiffDate[${index}]`}
                            defaultValue={ (forms.permitToWorkForm.workTeamDeclerationDiffDate && forms.permitToWorkForm.workTeamDeclerationDiffDate[index] && format(new Date(forms.permitToWorkForm.workTeamDeclerationDiffDate[index]), 'yyyy-MM-dd')) || (answers && answers.workTeamDeclerationDiffDate && answers.workTeamDeclerationDiffDate.length>index && format(new Date(answers.workTeamDeclerationDiffDate[index]), 'yyyy-MM-dd')) || null }
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="workTeamDeclerationSignOff"
                            type="text" 
                            model={`.workTeamDeclerationSignOff[${index}]`}
                            defaultValue={answers && answers.workTeamDeclerationSignOff[index]} 
                        />
                    </Col>
                </Col>
                <Col sm={1} xs={12}> 
                    <Col sm={12} className="input"> 
                        <span className="trash">
                            <i className="fa fa-lg fa-trash" onClick={() => this.decrementWorkTeamDeclerationRows(index)}
                            aria-hidden="true" > </i>  
                        </span >
                    </Col>  
                </Col>
            </Row>
        );
    } 
    signOffRows = 1;
    renderNoOfPeople = () => {
        let signOffStep = this.state.signOffStep;  
        return signOffStep.map((a, index) => {  
            return this.renderNoOfPeopleHtml(index); 
        });
    }
    renderNoOfPeopleHtml = (index = 1) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return ( <><Col sm={3} className="input mb10">
                <RRFInput
                    id="signOffName"
                    type="text" 
                    model={`.signOffName[${index}]`} 
                    defaultValue={answers && answers.signOffName && answers.signOffName[index]}
                />
            </Col>
            <Col sm={1} xs={12} className="mb10"> 
                <Col sm={12} className="input"> 
                    <span className="trash">
                        <i className="fa fa-lg fa-trash" onClick={() => this.decrementSignOffRows(index)}
                        aria-hidden="true" > </i>  
                    </span >
                </Col>  
            </Col>
        </>)
    } 
    incrementSignOffRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.signOffName`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.signOffDate`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.signOffInTime`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.signOffOutTime`, ''));  
        this.setState({ signOffStep: [...this.state.signOffStep, ++this.signOffRows] }); 
    }
    decrementSignOffRows = async (indexVal: number) => { 
        --this.signOffRows; 
        await dispatch(actions.remove(`forms.permitToWorkForm.signOffName`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.signOffDate`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.signOffInTime`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.signOffOutTime`, indexVal));
        await dispatch(actions.push(`forms.permitToWorkForm.signOffName`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.signOffDate`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.signOffInTime`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.signOffOutTime`, ''));  
        this.setState({ signOffStep: this.state.signOffStep.slice(0, - 1) }); 
    }
    renderSignOffs = () => { 
        let signOffStep = this.state.signOffStep;  
        return signOffStep.map((a, index) => {  
            return this.renderSignOffsHtml(index); 
        });
    } 
    renderSignOffsHtml = (index: number = 1 ) => {
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <Row className="show-grid" > 
                <Col sm={4} xs={12}> 
                    <Col sm={12} className="input"> 
                        <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.signOffName[index]} model={`forms.permitToWorkForm.signOffName[${index}]`} /> 
                        <RRFInput
                            id="signOffName"
                            type="text" 
                            model={`.signOffName[${index}]`}
                            defaultValue={answers && answers.signOffName && answers.signOffName[index]} 
                           //defaultValue={signOffDetails.length>0 && signOffDetails[index] && signOffDetails[index]['signOffName']}
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="signOffDate"
                            type="date" 
                            model={`.signOffDate[${index}]`}
                            defaultValue={ (forms.permitToWorkForm.signOffDate && forms.permitToWorkForm.signOffDate[index] && format(new Date(forms.permitToWorkForm.signOffDate[index]), 'yyyy-MM-dd')) || null }
                            //defaultValue={signOffDetails.length>0 && signOffDetails[index] && signOffDetails[index]['signOffDate']}
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="signOffInTime"
                            type="time" 
                            model={`.signOffInTime[${index}]`}
                            defaultValue={answers && answers.signOffInTime && answers.signOffInTime[index]} 
                            //defaultValue={signOffDetails.length>0 && signOffDetails[index] && signOffDetails[index]['signOffInTime']}
                        />
                    </Col>
                </Col>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="signOffOutTime"
                            type="time" 
                            model={`.signOffOutTime[${index}]`}
                            defaultValue={answers && answers.signOffOutTime && answers.signOffOutTime[index]} 
                            //defaultValue={signOffDetails.length>0 && signOffDetails[index] && signOffDetails[index]['signOffOutTime']}
                        />
                    </Col>
                </Col>
                <Col sm={1} xs={12}> 
                    <Col sm={12} className="input"> 
                        <span className="trash">
                            <i className="fa fa-lg fa-trash" onClick={() => this.decrementSignOffRows(index)}
                            aria-hidden="true" > </i>  
                        </span >
                    </Col>  
                </Col>
            </Row>
        );
    } 
    AT1CSERows = 1;
    incrementAT1CSERows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEDate`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSETime`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEO2`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSELELGW`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSELELF`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSETHC`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEH2S`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSECO`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEBenzene`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSESO2`, '')); 
        this.setState({ atmospericCSEStep: [...this.state.atmospericCSEStep, ++this.AT1CSERows] }); 
    }
    decrementAT1CSERows = async (indexVal: number) => { 
        --this.AT1CSERows;  
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEDate`, indexVal));
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSETime`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEO2`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSELELGW`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSELELF`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSETHC`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEH2S`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSECO`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSEBenzene`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.AT1CSESO2`, indexVal)); 
        this.setState({ atmospericCSEStep: this.state.atmospericCSEStep.slice(0, - 1) }); 
    } 
    renderAtmospericCSE = () => { 
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        let atmospericCSEStep = this.state.atmospericCSEStep; 
        return (
            <><Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >Date</span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultDate"
                            type="date" 
                            model={`.ATCSEPreStartResultDate`}
                            defaultValue={ (forms.permitToWorkForm.ATCSEPreStartResultDate && format(new Date(forms.permitToWorkForm.ATCSEPreStartResultDate), 'yyyy-MM-dd') ) || null }  
                        />
                    </Col>
                </div>}
                {this.ATDateResult(atmospericCSEStep)} 
            </Row>
            
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >Time</span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultTime"
                            type="time" 
                            model={`.ATCSEPreStartResultTime`}
                            defaultValue={answers && answers.ATCSEPreStartResultTime}
                        />
                    </Col>
                </div> }
                {this.ATTimeResult(atmospericCSEStep)}
            </Row>
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >O2</span>  <div className="ATCSEHints">{renderATCSEHints('O2', forms.permitToWorkForm)}</div></div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultO2"
                            type="text" 
                            model={`.ATCSEPreStartResultO2`}
                            defaultValue={answers && answers.ATCSEPreStartResultO2}
                        />
                    </Col>
                </div> }
                {this.ATO2Result(atmospericCSEStep)}
            </Row>
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >LEL (General Work)</span>
                    <div className="ATCSEHints">{renderATCSEHints('LELGW', forms.permitToWorkForm)}</div>
                </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultLELGeneralWork"
                            type="text" 
                            model={`.ATCSEPreStartResultLELGeneralWork`}
                            defaultValue={answers && answers.ATCSEPreStartResultLELGeneralWork}
                        />
                    </Col>
                </div>}
                {this.ATLELGWResult(atmospericCSEStep)}
            </Row>
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >LEL (Flammability )<div className="ATCSEHints">{renderATCSEHints('LELF', forms.permitToWorkForm)}</div></span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultLELFlammability"
                            type="text" 
                            model={`.ATCSEPreStartResultLELFlammability`}
                            defaultValue={answers && answers.ATCSEPreStartResultLELFlammability}
                        />
                    </Col>
                </div> }
                {this.ATLELFResult(atmospericCSEStep)}
            </Row>
            {/* {forms.permitToWorkForm.atmosphericTestType==='Work Management System Atmospheric Test Record' && 
                <Row className="show-grid" >
                    <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >THC<div className="ATCSEHints">{renderATCSEHints('THC', forms.permitToWorkForm)}</div></span> </div> 
                    {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="ATCSEPreStartResultTHC"
                                type="text" 
                                model={`.ATCSEPreStartResultTHC`}
                                defaultValue={answers && answers.ATCSEPreStartResultTHC}
                            />
                        </Col>
                    </div> }
                    {this.ATTHCResult(atmospericCSEStep)}
                </Row>
            } */}
            {/* {forms.permitToWorkForm.atmosphericTestType==='Work Management System Atmospheric Test Record (Supplement)' &&
                <> <Row className="show-grid" >
                    <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >VOC<div className="ATCSEHints">{renderATCSEHints('VOC', forms.permitToWorkForm)}</div></span> </div> 
                    {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="ATCSEPreStartResultVOC"
                                type="text" 
                                model={`.ATCSEPreStartResultVOC`}
                                defaultValue={answers && answers.ATCSEPreStartResultVOC}
                            />
                        </Col>
                    </div> }
                    {this.ATVOCResult(atmospericCSEStep)}
                </Row>
                <Row className="show-grid" >
                    <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >H2S<div className="ATCSEHints">{renderATCSEHints('H2S', forms.permitToWorkForm)}</div></span> </div> 
                    {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="ATCSEPreStartResultH2S"
                                type="text" 
                                model={`.ATCSEPreStartResultH2S`}
                                defaultValue={answers && answers.ATCSEPreStartResultH2S}
                            />
                        </Col>
                    </div> }
                    {this.ATH2SResult(atmospericCSEStep)}
                </Row></>
            } */}
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >CO<div className="ATCSEHints">{renderATCSEHints('CO', forms.permitToWorkForm)}</div></span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultCO"
                            type="text" 
                            model={`.ATCSEPreStartResultCO`}
                            defaultValue={answers && answers.ATCSEPreStartResultCO}
                        />
                    </Col>
                </div> }
                {this.ATCOResult(atmospericCSEStep)}
            </Row>
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >Benzene<div  className="ATCSEHints">{renderATCSEHints('Benzene', forms.permitToWorkForm)}</div></span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultBenzene"
                            type="text" 
                            model={`.ATCSEPreStartResultBenzene`}
                            defaultValue={answers && answers.ATCSEPreStartResultBenzene}
                        />
                    </Col>
                </div>}
                {this.ATBenzeneResult(atmospericCSEStep)}
            </Row>
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >SO2<div className="ATCSEHints">{renderATCSEHints('SO2', forms.permitToWorkForm)}</div></span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultSO2"
                            type="text" 
                            model={`.ATCSEPreStartResultSO2`}
                            defaultValue={answers && answers.ATCSEPreStartResultSO2}
                        />
                    </Col>
                </div>}
                {this.ATSO2Result(atmospericCSEStep)}
            </Row> 
            <Row className="show-grid" >
                <div style={{width: '200px'}}> <span style={{ color: 'yellow' }} >AGT Initials </span> </div> 
                {forms.permitToWorkForm.testFrequencyPrierToWorkYes && <div style={{width: '200px'}} > 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="ATCSEPreStartResultAGTInitials"
                            type="text" 
                            model={`.ATCSEPreStartResultAGTInitials`}
                            defaultValue={answers && answers.ATCSEPreStartResultAGTInitials}
                        />
                    </Col>
                </div>}
                {this.ATAGTInitialsResult(atmospericCSEStep)}
            </Row>

            </>
        );
    }
    detecterCalibrationRows = 1;
    incrementDetecterCalibirationRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.detecterUsed`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.colibirationDate`, ''));  
        this.setState({ detecterCalibrationStep: [...this.state.detecterCalibrationStep, ++this.detecterCalibrationRows] });  
    }
    decrementDetecterCalibirationRows = async (indexVal: number) => { 
        --this.detecterCalibrationRows; 
        await dispatch(actions.remove(`forms.permitToWorkForm.detecterUsed`, indexVal)); 
        await dispatch(actions.remove(`forms.permitToWorkForm.colibirationDate`, indexVal)); 
        this.setState({ detecterCalibrationStep: this.state.detecterCalibrationStep.slice(0, - 1) }); 
    }
    renderDetecterCalibiration = () => {
        let detecterCalibrationStep = this.state.detecterCalibrationStep; 
        return detecterCalibrationStep.map((a, index) => {  
            return this.DetecterCalibirationHtml(index); 
        });
    } 
    DetecterCalibirationHtml = (index: number = 1) => { 
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || ''; 
        return (
            <Row className="show-grid" >
                <Col sm={{span:5, offset: 1}} xs={12}> 
                    <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.detecterUsed && forms.permitToWorkForm.detecterUsed[index]} model={`forms.permitToWorkForm.detecterUsed[${index}]`} /> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="detecterUsed"
                            type="text" 
                            model={`.detecterUsed[${index}]`}
                            defaultValue={answers && answers.detecterUsed && answers.detecterUsed[index]}
                        />
                    </Col>
                </Col>
                <Col sm={5} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="colibirationDate"
                            type="date" 
                            model={`.colibirationDate[${index}]`}
                            defaultValue={(forms.permitToWorkForm.colibirationDate && forms.permitToWorkForm.colibirationDate[index] && format(new Date(forms.permitToWorkForm.colibirationDate[index]), 'yyyy-MM-dd')) || null } 
                        />
                    </Col>
                </Col>
                <Col sm={1} xs={12}>
                    <Col sm={12} className="input"> 
                        <span className="trash">
                            <i className="fa fa-lg fa-trash" onClick={() => this.decrementDetecterCalibirationRows(index)}
                            aria-hidden="true" > </i>  
                        </span >
                    </Col>  
                </Col>
            </Row>
        );
    } 
    permitShiftHandoverRows = 1;
    incrementPermitShiftHandoverRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.pshDate`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.isPSH`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.pshOI`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.pshPI`, ''));  
        this.setState({ permitShiftHandoverStep: [...this.state.permitShiftHandoverStep, ++this.permitShiftHandoverRows] });  
    }
    decrementPermitShiftHandoverRows = async (indexVal: number) => { 
        --this.permitShiftHandoverRows; 
        await dispatch(actions.remove(`forms.permitToWorkForm.pshDate`, indexVal));  
        await dispatch(actions.remove(`forms.permitToWorkForm.isPSH`, indexVal));  
        await dispatch(actions.remove(`forms.permitToWorkForm.pshOI`, indexVal)); 
        await dispatch(actions.remove(`forms.permitToWorkForm.pshPI`, indexVal));  
        this.setState({ permitShiftHandoverStep: this.state.permitShiftHandoverStep.slice(0, - 1) }); 
    }
    renderPermitShiftHandover = () => {
        let permitShiftHandoverStep = this.state.permitShiftHandoverStep; 
        return permitShiftHandoverStep.map((a, index) => {  
            return this.PermitShiftHandoverHtml(index); 
        });
    } 
    PermitShiftHandoverHtml = (index: number = 1) => { 
        const { props: {forms, editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || ''; 
        return (
            <Row className="show-grid" key={index}>
                <Col sm={2} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="pshDate"
                            type="date" 
                            model={`.pshDate[${index}]`}
                            defaultValue={ (forms.permitToWorkForm.pshDate && forms.permitToWorkForm.pshDate[index] && format(new Date(forms.permitToWorkForm.pshDate[index]), 'yyyy-MM-dd')) || null }
                            //maxdate={currentDate}  
                        />
                    </Col>
                </Col>
                <Col sm={3} xs={12}> 
                    <Col sm={12} className="input"> 
                        <RRFInput
                            id="isPSH" 
                            model={`.isPSH[${index}]`}
                            type="dropdown" 
                            menuItems={[{ label: 'Handover', value: 'Handover' }, { label: 'Extension', value: 'Extension' }, { label: 'Reissue', value: 'Reissue' }, 
                            ]}
                            defaultValue={answers && answers.isPSH && answers.isPSH[index]}
                        /> 
                    </Col>
                </Col>
                <Col sm={3} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="pshPI"
                            type="text" 
                            model={`.pshPI[${index}]`}
                            defaultValue={answers && answers.pshPI && answers.pshPI[index]}
                        />
                    </Col>
                </Col>
                <Col sm={3} xs={12}> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="pshOI"
                            type="text" 
                            model={`.pshOI[${index}]`}
                            defaultValue={answers && answers.pshOI && answers.pshOI[index]}
                        />
                    </Col>
                </Col>
                
                <Col sm={1} xs={12}> 
                    <Col sm={12} className="input"> 
                        <span className="trash">
                            <i className="fa fa-lg fa-trash" onClick={() => this.decrementPermitShiftHandoverRows(index)}
                            aria-hidden="true" > </i>  
                        </span >
                    </Col> 
                </Col> 
            </Row>
        )
    }
    riskMitigationRows = 1;
    
    incrementRiskMitigationRows = (/* event: { preventDefault: () => void; } */) => {  
        //event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.riskMitigationHazard`, ''));
        dispatch(actions.push(`forms.permitToWorkForm.riskMitigationControl`, ''));  
        this.setState({
            riskMitigationStep: [...this.state.riskMitigationStep, ++this.riskMitigationRows]
        }); 
    }
    decrementRiskMitigationRows = async (indexVal: number) => { 
        --this.riskMitigationRows;  
        await dispatch(actions.remove(`forms.permitToWorkForm.riskMitigationHazard`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.riskMitigationControl`, indexVal));
        await dispatch(actions.push(`forms.permitToWorkForm.riskMitigationHazard`, '')); 
        await dispatch(actions.push(`forms.permitToWorkForm.riskMitigationControl`, ''));  
        this.setState({
            riskMitigationStep: this.state.riskMitigationStep.slice(0, - 1)
        }); 
    }
    renderRiskMitigation = () => {
        let riskMitigationStep = this.state.riskMitigationStep; 
        return riskMitigationStep.map((a, index) => {  
            return this.RiskMitigationHtml(index); 
        });
    } 
    RiskMitigationHtml = (index: number = 1) => {
        const { props: {editInstance, forms } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <Row className="show-grid" >
                <Col sm={1} xs={12}> 
                    <Label>{index+1}</Label>
                </Col>
                <Col sm={5} xs={12}> 
                    <Col sm={12} className="input"> 
                        <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.riskMitigationHazard[index]} model={`forms.permitToWorkForm.riskMitigationHazard[${index}]`} /> 
                        <RRFInput
                            id="riskMitigationHazard"
                            type="text" 
                            model={`.riskMitigationHazard[${index}]`} 
                            defaultValue={answers && answers.riskMitigationHazard && answers.riskMitigationHazard[index]}
                        />
                    </Col>
                </Col>
                <Col sm={5} xs={12}> 
                    <Col sm={12} className="input">
                        <VoiceRecognition labelText="" defaultValue={forms.permitToWorkForm.riskMitigationControl[index]} model={`forms.permitToWorkForm.riskMitigationControl[${index}]`} /> 
                        <RRFInput
                            id="riskMitigationControl"
                            type="text" 
                            model={`.riskMitigationControl[${index}]`} 
                            defaultValue={answers && answers.riskMitigationControl && answers.riskMitigationControl[index]}
                        />
                    </Col>
                </Col>
                <Col sm={1} xs={12}> 
                    <Col sm={12} className="input"> 
                        <span className="trash">
                            <i className="fa fa-lg fa-trash" onClick={() => this.decrementRiskMitigationRows(index)}
                            aria-hidden="true" > </i>  
                        </span >
                    </Col>  
                </Col> 
            </Row>
        );
    }
    /* isolationDetailRows = 1;
    incrementIsolationDetailRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailEquipmentID`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailDescription`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailIsolationType`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailIsolationMethod`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailComment`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionNormal`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionIsolated`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionDeIsolated`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailInstalledIAAssigned`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailInstalledIIP`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailRemovedIAAssigned`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailRemovedIAR`, ''));  
        this.setState({ isolationDetailStep: [...this.state.isolationDetailStep, ++this.isolationDetailRows] }); 
    }
    decrementIsolationDetailRows = async (indexVal: number) => { 
        --this.isolationDetailRows;  
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailEquipmentID`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailDescription`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailIsolationType`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailIsolationMethod`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailPointPositionNormal`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailPointPositionIsolated`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailPointPositionDeIsolated`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailInstalledIAAssigned`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailInstalledIIP`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailRemovedIAAssigned`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationDetailRemovedIAR`, indexVal));
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailEquipmentID`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailDescription`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailIsolationType`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailIsolationMethod`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailComment`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionNormal`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionIsolated`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailPointPositionDeIsolated`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailInstalledIAAssigned`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailInstalledIIP`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailRemovedIAAssigned`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationDetailRemovedIAR`, ''));  
        this.setState({ isolationDetailStep: this.state.isolationDetailStep.slice(0, - 1) }); 
    }
    renderIsolationDetail = () => {
        let isolationDetailStep = this.state.isolationDetailStep;   
        return isolationDetailStep.map((a, index) => {  
            return this.IsolationDetailHtml(index); 
        });
    } 
    IsolationDetailHtml = (index: number = 1) => { 
        const { props: { editInstance } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <tr>
                <td><span className="trash" style={{paddingRight: '10px'}}>
                    <i className="fa fa-lg fa-trash" onClick={() => this.decrementIsolationDetailRows(index)}
                    aria-hidden="true" > </i>  
                </span >{index+1}</td>
                <td>
                    <RRFInput
                        id="isolationDetailEquipmentID"
                        type="text"
                        model={`.isolationDetailEquipmentID[${index}]`}
                        defaultValue={answers && answers.isolationDetailEquipmentID && answers.isolationDetailEquipmentID[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailDescription"
                        type="text"
                        model={`.isolationDetailDescription[${index}]`}
                        defaultValue={answers && answers.isolationDetailDescription && answers.isolationDetailDescription[index]}
                    />
                </td> 
                <td> 
                    <RRFInput
                        id="isolationDetailIsolationType" 
                        model={`.isolationDetailIsolationType[${index}]`}
                        type="dropdown" 
                        menuItems={[{ label: 'Electrical', value: 'Electrical' }, { label: 'Mechanical', value: 'Mechanical' }, { label: 'Instrument', value: 'Instrument' }, ]}
                        defaultValue={answers && answers.isolationDetailIsolationType && answers.isolationDetailIsolationType[index]}
                    />
                </td>
                <td> 
                    <RRFInput
                        id="isolationDetailIsolationMethod" 
                        model={`.isolationDetailIsolationMethod[${index}]`}
                        type="dropdown" 
                        menuItems={[{ label: 'Locked', value: 'Locked' }, { label: 'Tagged', value: 'Tagged' }, { label: 'Plugged', value: 'Plugged' }, { label: 'Capped', value: 'Capped' }, { label: 'Disconnect', value: 'Disconnect' }, { label: 'Blind', value: 'Blind' }, { label: 'Spade', value: 'Spade' }, { label: 'Grounded', value: 'Grounded' }, { label: 'Racked', value: 'Racked' }, { label: 'Removed', value: 'Removed' }, { label: 'Installed', value: 'Installed' }, ]}
                        defaultValue={answers && answers.isolationDetailIsolationMethod && answers.isolationDetailIsolationMethod[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailComment"
                        type="text"
                        model={`.isolationDetailComment[${index}]`}
                        defaultValue={answers && answers.isolationDetailCommen && answers.isolationDetailComment[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailPointPositionNormal"
                        type="dropdown"
                        model={`.isolationDetailPointPositionNormal[${index}]`}
                        menuItems={ this.getIsolationDetailPointPositionNormal(index)}
                        defaultValue={answers && answers.isolationDetailPointPositionNormal && answers.isolationDetailPointPositionNormal[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailPointPositionIsolatedType" 
                        model={`.isolationDetailPointPositionIsolatedType[${index}]`}
                        type="dropdown" 
                        menuItems={[{ label: 'Isolated', value: 'Isolated' }, { label: 'De-Isolated', value: 'De-Isolated' } ]}
                        defaultValue={answers && answers.isolationDetailPointPositionIsolatedType && answers.isolationDetailPointPositionIsolatedType[index]}
                    /> 
                </td> 
                <td>
                    <RRFInput
                        id="isolationDetailInstalledIAAssigned"
                        type="text"
                        model={`.isolationDetailInstalledIAAssigned[${index}]`}
                        defaultValue={answers && answers.isolationDetailPointPositionIsolatedType && answers.isolationDetailPointPositionIsolatedType[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailInstalledIIP"
                        type="text"
                        model={`.isolationDetailInstalledIIP[${index}]`}
                        defaultValue={answers && answers.isolationDetailInstalledIIP && answers.isolationDetailInstalledIIP[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailRemovedIAAssigned"
                        type="text"
                        model={`.isolationDetailRemovedIAAssigned[${index}]`}
                        defaultValue={answers && answers.isolationDetailRemovedIAAssigned && answers.isolationDetailRemovedIAAssigned[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationDetailRemovedIAR"
                        type="text"
                        model={`.isolationDetailRemovedIAR[${index}]`}
                        defaultValue={answers && answers.isolationDetailRemovedIAR && answers.isolationDetailRemovedIAR[index]}
                    />
                </td>
            </tr>  
        );
    } */
    /* getIsolationDetailPointPositionNormal = (index) => {
        const { forms } = this.props;
        let isolationDetailIsolationType = (forms.permitToWorkForm.isolationDetailIsolationType && forms.permitToWorkForm.isolationDetailIsolationType[index]) || '';
        switch(isolationDetailIsolationType) {
           case 'Electrical':
               return [{ label: 'Normally Open', value: 'Normally Open' }, { label: 'Normally Closed', value: 'Normally Closed' }, { label: 'Car Sealed Open', value: 'Car Sealed Open' }, { label: 'Car Sealed Closed', value: 'Car Sealed Closed' } ]; 
            case 'Mechanical':
                return [{ label: 'Plug in Place', value: 'Plug in Place' }, { label: 'Disconnected', value: 'Disconnected' }, { label: 'Connected', value: 'Connected' }, { label: 'Reconnected', value: 'Reconnected' } ];
            case 'Instrument':
                return [{ label: 'Applied', value: 'Applied' }, { label: 'Removed', value: 'Removed' }, { label: 'Replaced', value: 'Replaced' }, { label: 'Locked Open', value: 'Locked Open' }, { label: 'Locked Closed', value: 'Locked Closed' }, { label: 'In', value: 'In' }, { label: 'Out', value: 'Out' } ]; 
            default:
                return [{ label: '', value: '' }];
        } 
    } */
    /* blindRegisterIsolationMethodRows = 1;
    incrementBlindRegisterIsolationMethodRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterDescription`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRating`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterSize`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterType`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterDate`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterIAInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterVerifierInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedDate`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedIAInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedVerifierInitial`, ''));
        this.setState({ blindRegisterIsolationMethodStep: [...this.state.blindRegisterIsolationMethodStep, ++this.blindRegisterIsolationMethodRows] }); 
    }
    decrementBlindRegisterIsolationMethodRows = async (indexVal: number) => { 
        --this.blindRegisterIsolationMethodRows;  
        this.setState({ blindRegisterIsolationMethodStep: this.state.blindRegisterIsolationMethodStep.slice(0, - 1) }); 
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterDescription`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterRating`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterSize`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterType`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterDate`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterIAInitial`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterVerifierInitial`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterRemovedDate`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterRemovedIAInitial`, indexVal));
        await dispatch(actions.remove(`forms.permitToWorkForm.isolationBlindRegisterRemovedVerifierInitial`, indexVal)); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterDescription`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRating`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterSize`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterType`, '')); 
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterDate`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterIAInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterVerifierInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedDate`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedIAInitial`, ''));  
        dispatch(actions.push(`forms.permitToWorkForm.isolationBlindRegisterRemovedVerifierInitial`, '')); 
    }
    renderBlindRegisterIsolationMethod = () => {
        let blindRegisterIsolationMethodStep = this.state.blindRegisterIsolationMethodStep;   
        return blindRegisterIsolationMethodStep.map((a, index) => {  
            return this.BlindRegisterIsolationMethodHtml(index); 
        });
    } 
    BlindRegisterIsolationMethodHtml = (index: number = 1) => { 
        const { props: {forms, editInstance }, state: { currentDate } } = this; 
        let answers = (editInstance && editInstance.props.answers) || '';
        return (
            <tr>
                <td><span className="trash" style={{paddingRight: '10px'}}>
                    <i className="fa fa-lg fa-trash" onClick={() => this.decrementBlindRegisterIsolationMethodRows(index)}
                    aria-hidden="true" > </i>  
                </span >{index+1}</td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterDescription"
                        type="text"
                        model={`.isolationBlindRegisterDescription[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterDescription && answers.isolationBlindRegisterDescription[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterRating"
                        type="text"
                        model={`.isolationBlindRegisterRating[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterRating && answers.isolationBlindRegisterRating[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterSize"
                        type="text"
                        model={`.isolationBlindRegisterSize[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterSize && answers.isolationBlindRegisterSize[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterType"
                        type="text"
                        model={`.isolationBlindRegisterType[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterType && answers.isolationBlindRegisterType[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterDate"
                        type="date"
                        model={`.isolationBlindRegisterDate[${index}]`} 
                        //defaultValue={ (forms.permitToWorkForm.isolationBlindRegisterDate && forms.permitToWorkForm.isolationBlindRegisterDate[index] && format(new Date(forms.permitToWorkForm.isolationBlindRegisterDate[index]), 'yyyy-MM-dd') ) || null } 
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterIAInitial"
                        type="text"
                        model={`.isolationBlindRegisterIAInitial[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterIAInitial && answers.isolationBlindRegisterIAInitial[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterVerifierInitial"
                        type="text"
                        model={`.isolationBlindRegisterVerifierInitial[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterVerifierInitial && answers.isolationBlindRegisterVerifierInitial[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterRemovedDate"
                        type="date"
                        model={`.isolationBlindRegisterRemovedDate[${index}]`} 
                        //defaultValue={(forms.permitToWorkForm.isolationBlindRegisterRemovedDate && forms.permitToWorkForm.isolationBlindRegisterRemovedDate[index] && format(new Date(forms.permitToWorkForm.isolationBlindRegisterRemovedDate[index]), 'yyyy-MM-dd') ) || null } 
                    /> 
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterRemovedIAInitial"
                        type="text"
                        model={`.isolationBlindRegisterRemovedIAInitial[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterRemovedIAInitial && answers.isolationBlindRegisterRemovedIAInitial[index]}
                    />
                </td>
                <td>
                    <RRFInput
                        id="isolationBlindRegisterRemovedVerifierInitial"
                        type="text"
                        model={`.isolationBlindRegisterRemovedVerifierInitial[${index}]`}
                        defaultValue={answers && answers.isolationBlindRegisterRemovedVerifierInitial && answers.isolationBlindRegisterRemovedVerifierInitial[index]}
                    />
                </td> 
            </tr>  
        );
    } */
    render() {
        return (
            <BasePage className="safeconnect-form very-extra-wide-list-page"> 
                <Async
                    promise={this.promise}
                    identifier="PTWForm" 
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any,ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    const checklistAttributes = ( ChecklistAttributeModel.list().length>0 && ChecklistAttributeModel.list() ) || '';
    const editInstance = PermitToWorkModel.get(ownProps.match.params.id); 
    const jsaInstance = JSAModel.list(); 
    return { userId, userInstance, userUID, company, forms: state.forms, editId, checklistAttributes, jsaInstance, editInstance }; 
}
const displayImage: React.CSSProperties = {
    width: '90px',
    height: '75px',
    margin: '0 10px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)'
};
export const WorkToPermitForm = withRouter(connect<IWorkToPermitFormProps, any, any>(mapStateToProps)(WorkToPermitFormImpl));

const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};