import * as React from 'react';

export interface ICardProps {
    className: string;
    width?: string;
    height?: string;
    onClick?: Function;
}

export class Card extends React.PureComponent<ICardProps, {}> {
    constructor(props: ICardProps | Readonly<ICardProps>) {
        super(props);
    }
    render() {
        const { className, onClick, width, height } = this.props;
        return (
            <div
                onClick={onClick ? () => onClick() : undefined }
                className={`${className} mui-card`} style={{ width, height }} >
                {this.props.children}
            </div>
        );
    }
} 
