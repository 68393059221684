import { BaseModel } from './BaseModel';
import { IAnswer } from '../interfaces';
import {IFollowUpModelProps} from './FollowUpModel';

export interface IIncidentInvestigation {
    actionItems: string;
    partyResponsible: string;
    targetDate: string;
}

export interface IIncidentReportModelProps {
    id: string;
    time: string;
    dateOfIncident: Date;
    timeOfIncident: string;
    timeReported: string;
    dateReportedToAgency: string;
    endorsementDate: string;
    approvalDate: string;
    photosIncluded: number;
    preliminaryReport: number;
    report: string;
    finalReport: number;
    country: string;
    groupId: string;
    generalContractor: string;
    concernType: string;
    subContractor: string;
    workRelated: string;
    offShore: number;
    onShore: number;
    incidentSummary: string;
    incidentDetailedDesc: string;
    immediateCorrectiveAction: string;
    jobTask: string;
    location: string;
    specifiedLocation: string;
    locationName: string;
    equipInvolved: string;
    weather: string;
    lighting: string;
    wind: string;
    seas: string;
    properPPE: string;
    selfSupervised: string;
    incidentWitnessed: string;
    name: string;
    employee: number;
    contractor: number;
    businessPartner: string;
    drugTestConducted: string;
    sufferEmployeeName: string;
    company: string;
    jobPosition: string;
    timeOnJob: string;
    natureOfInjury: string;
    bodyPart: string;
    medicalResponseTaken: string;
    statusUpdate: string;
    highestMedicalResponder: string;
    eventLeadingToInjury: string;
    actualDaysLost: string;
    firstAidTreatment: string;
    estimatedDaysLost: string;
    actualSeverity: string;
    potentialSeverity: string;
    securityCategory: string;
    methodOfBreach: string;
    agencyNotified: number;
    agencyName: string;
    reportedToAgencyBy: string;
    notifiedAtAgency: string;
    costIncurred: string;
    totalCost: string;
    emOwnershipPercentage: string;
    costType: string;
    costCategory: string;
    askWhy5Times: string;
    tapRoot: string;
    directCause: string;
    rootCause: string;
    projectOIMSDetails: string;
    //incidentInvestigation: IIncidentInvestigation[];
    incidentInvestigation: IFollowUpModelProps[];
    incidentInvestigationFollowps: IFollowUpModelProps[]; 
    CompleteActions: IFollowUpModelProps[];
    InCompleteActions: IFollowUpModelProps[];
    incidentActivity: string;
    incidentConditions: string;
    workDirection: string;
    supervisorName: string;
    supervisorSignature: string;
    groupManagerName: string;
    groupManagerSignature: string;
    acceptorName: string;
    acceptorSignature: string;
    dateAccepted: string;
    mngmtReportRequired?: string;
    ['8010Required']: string;
    groupName: string;
    projectName: string;
    date: string;
    dateReported: string;
    dateReturnedToWork: string;
    category: string;
    answers: IAnswer[];
    key?: string;  
    associatedFormId?: string;
    associatedForms?: string[];
    incidentReportImages?: {
        name: string;
        image: string;
    }[];
    imageUrls: string[];
    incidentReportVideos?: {
        name: string;
        file: string;
    }[];
    videoUrls: string[]; 
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string; 
    totalFollowUp?: number; 
    totalClosedFollowUp?: number;
    totalOpenFollowUp?: number;
    userId?: string;
    userAccessLevel?: string;
    formSerialNo?: string;
    isFinalSubmit?: string;
}

export class IncidentReportModel extends BaseModel<IIncidentReportModelProps> {
    constructor(props: IIncidentReportModelProps) {
        super(props, IncidentReportModel.resource);
    }

    static resource = 'incident-report';

}
