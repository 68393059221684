import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { JSAModel } from '../../model/JSAModel';
import { changeJSAReadStatus, getFormData } from '../../services/jsaFormService';
import { KeyValue } from '../../utils/generalUtils';
import { Title } from '../reusableComponents/Title'; 
import { Loader } from '../reusableComponents/Loader'; 
import { shoCarousel } from '../../actions/miscellaneousActions';
import { Img } from '../reusableComponents/ImageComponents/Img';  
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay'; 
import noImage from '../../images/noImage.png'; 
import PropTypes from 'prop-types';
import './nonAppForm.scss';
import { IHistory } from '../../interfaces';
import { UserModel } from '../../model/UserModel';

export interface INonLoginDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    jsaId: string; 
    jsaInstance: JSAModel; 
    associatedUserId?: string;
    company: string;
    history?: IHistory;
    userEmail?: string;
}

export class NonLoginDetailsPageImpl extends React.PureComponent<INonLoginDetailsPageProps, {}> {
    constructor(props: INonLoginDetailsPageProps | Readonly<INonLoginDetailsPageProps>) {
        super(props); 
        let user = UserModel.isLogged(); 
        if(this.props.associatedUserId && this.props.company)
            changeJSAReadStatus(this.props.associatedUserId, this.props.company );
        if(user) { 
            let userInstance = UserModel.localSession(); 
            if(userInstance && (userInstance.email===this.props.userEmail) ) { 
                this.props.history?.push(`/jsa-reports/${this.props.jsaId}`);
            }
        } 
        if (this.props.associatedUserId && this.props.company ) {
            getFormData('jsa', this.props.jsaId, this.props.company);
        } 
    }
    getChildContext() {
        return { formModel: 'forms.jsaForm' };
    } 

    static childContextTypes = {
        formModel: PropTypes.string
    };
 
    renderJobSafetySteps = () => {
        const { jsaInstance } = this.props;
        return (jsaInstance.props.answers || []).map((stepData, index) => {
            return (
                <div key={index} className="steps">
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Step Description:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.stepDesc}
                        </Col>
                    </Row >
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Hazards:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.hazards}
                        </Col>
                    </Row >
                    <Row className="step-row">
                        <Col sm={8} style={{ color: '#969696' }}>
                            Controls:
                        </Col>
                        <Col xs={12} className="step-data">
                            {stepData.controls}
                        </Col> 
                    </Row >
                </div>
            );
        });
    } 

    renderImages = () => {
        const { jsaInstance } = this.props;
        if (!jsaInstance.props.imageUrls || jsaInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect"/>;
        }
        return jsaInstance.props.imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        }); 
    } 

    renderContent = () => {
        const { jsaInstance, } = this.props;
        if (!jsaInstance) {
            return <Loader />;
        }
        const { props: { groupName, jsaNum, typeOfWork, locationName, workToPerform, projectName, date, companyName, videoUrls } } = jsaInstance; 
        return <div>
            <Title text={`JSA Report for ${date}`}></Title>
            <KeyValue label="Date">{date}</KeyValue>
            <KeyValue label="Company Name">{companyName}</KeyValue>
            <KeyValue label="JSA Num">{jsaNum}</KeyValue>
            <KeyValue label="Location">{locationName}</KeyValue>
            <KeyValue label="Type of Work">{typeOfWork}</KeyValue>
            <KeyValue label="Work To Perform">{workToPerform}</KeyValue>
            <KeyValue label="Team Name">{projectName}</KeyValue>
            <KeyValue label="Group Name">{groupName}</KeyValue> 
            <Row className="show-grid table">
                <Col className="form-title yellow-center" sm={4}>Step Description:</Col>
                <Col className="form-title yellow-center" sm={4}>Hazards:</Col>
                <Col className="form-title yellow-center" sm={4}>Controls:</Col>
                <div className="table-rows">
                    {this.renderJobSafetySteps()}
                </div>
            </Row >
            <KeyValue label="Images">{this.renderImages()}</KeyValue >
            <KeyValue label="Videos"><VideoPlay videoUrls={videoUrls}/></KeyValue>
            <KeyValue label="Created User Signature">{this.renderCreatedUserSignature()}</KeyValue > 
            <div className="acknowledge-message">
                You have successfully acknowledged!!
            </div>
        </div >;
    }
    renderCreatedUserSignature = () => {
        const { jsaInstance } = this.props; 
        if(jsaInstance.props.signatureUrl){
            return <Img 
                key={'signatureUrl'}
                style={{ padding: '5px', backgroundColor: '#fff' }}
                width="250px"
                height="55px"
                src={jsaInstance.props.signatureUrl}
            />; 
        }
        return '';
    } 
    render() {
        const { company } = this.props;
        return (<div className="background nonLoginDetails">  
            <div id="basepage" className="basepage undefined">
                <div className="jsa-report report-details">
                    <div className="container non-app-logos"><span className="navbar-brand"><img className="logo img-responsive app-logo" src="/static/media/SafeLogo.a6ce855d.png" alt="SafeConnect"></img></span><span className="navbar-brand"><img className="logo img-responsive company-logo" src={`https://safeconnect-beta.s3-us-west-2.amazonaws.com/company-logos/${company.toLowerCase()}.png`} alt="SafeConnect"></img></span> </div>
                    {this.renderContent()}
                </div>
            </div>
        </div>); 
    }
}

export function forEachStateToProps(state: any, ownProps: any) {
    const jsaId = ownProps.match.params.id;
    const jsaInstance = JSAModel.get(ownProps.match.params.id); 
    let associatedUserId = ''; let userEmail = '';
    if(ownProps.match.params.associatedUserId)
        associatedUserId = ownProps.match.params.associatedUserId;
    if(ownProps.match.params.userEmail)
        userEmail = ownProps.match.params.userEmail;
    let company= ownProps.match.params.company;
    return { jsaInstance, jsaId, associatedUserId, company, userEmail };
}

export const NonLoginDetailsPage = withRouter(connect<INonLoginDetailsPageProps, any, any>(forEachStateToProps)(NonLoginDetailsPageImpl));
