import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { UserModel } from '../../model/UserModel';
import { getAllUsers, addBulkUser } from '../../services/userService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Pagination } from '../reusableComponents/Pagination';
import { ConfirmationModal } from './ConfirmationModal';
import { ListItem } from './ListItem';
import { Row, Col } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';  
import CSVReader from 'react-csv-reader';
import { setSuccess, setLoading } from '../../actions/loadingActions';   
declare var require: any;
const queryString = require('query-string');

export interface IListPageProps {
    userInstances: UserModel[];
    history?: IHistory;
}

export class ListPageImpl extends React.Component<IListPageProps, {
    pageNumber?: number,
    userInstances: any;
    isTouched: boolean;
    bulkUploadMessage: string; 
}> {
    constructor(props: IListPageProps | Readonly<IListPageProps>) {
        super(props);
        this.state = {
            userInstances: [],
            isTouched: false,
            pageNumber: queryString.parse(this.props.history?.location['search']).pageNumber || 1,
            bulkUploadMessage:'' 
        };
    }
    
    sortListing = { 0: 'userId', 1: 'firstName', 2: 'accessLevel', 3: 'jobTitle' };
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.userInstances.length > 0) {
            this.setState({
                userInstances: UserModel.getNextPage(this.state.pageNumber || 1, nextProps.userInstances)  
            });
        }
    }

    promise = () => {
        //if (UserModel.list().length <= 1) {
            return getAllUsers();
        //}
        //return null;
    }

    renderAddNewUserButton = () => { 
        return <button className="add-new-report"
            onClick={() => { this.props.history?.push('/users/create'); }}
        >
            +
        </button>;
    }

    getUserList = (pageNumber: any) => {
        this.setState({
            pageNumber
        });
    }
    handleChange = (files: Blob[]) => {
        var reader = new FileReader();
        reader.onload = function(e) {
            // Use reader.result
            alert(reader.result)
        }
        reader.readAsText(files[0]);
    }
    userBulkUpload = async (data: string | any[], file: any ) => {  
        setLoading('UserListPage'); 
        let fileNameDet = file.name.split('.');
        let fileExe = fileNameDet[fileNameDet.length -1 ];
        if(fileExe!== 'csv') {
            setSuccess('UserListPage');  
            this.setState({'bulkUploadMessage': 'Only .csv file accepted. Please download sample template, fill the data and upload'}); 
            //showAlert('Only .csv file accepted. ', 'bulk-upload-red-alert', 'danger'); 
        } else if(data.length<1) {
            setSuccess('UserListPage');  
            this.setState({'bulkUploadMessage': 'File can\'t be empty'}); 
            //showAlert('File can\'t be empty ', 'bulk-upload-red-alert', 'danger'); 
        } else {
            const response = await addBulkUser(data); 
            //showAlert(response, 'bulk-upload-red-alert', 'danger'); 
            this.setState({'bulkUploadMessage': response}); 
            await getAllUsers();
        }  
    } 
    papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_")
    };
    renderUserList = () => {
        const { userInstances } = this.props;
        let totalCount = UserModel.getUserListTotalPages(userInstances);
        return <Async
            identifier="UserListPage"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={ 
                <div className="user-list-wrapper"> 
                    <div className="user-bulk-upload-grid">  
                        <Row className="show-grid">
                            <Col sm={12} xs={12} className="row">
                                <Col sm={2}></Col>
                                <Label sm={3} required={false} htmlFor="upload-images">Bulk Upload</Label>
                                <Col sm={2} className="input">
                                    <div className="file-upload">
                                        <CSVReader
                                            cssClass="bulk-file-upload-style"
                                            cssInputClass="bulk-file-upload"
                                            label="Choose File"
                                            onFileLoaded={this.userBulkUpload}
                                            parserOptions={this.papaparseOptions}
                                        /> 
                                    </div> 
                                </Col> 
                                <Col sm={5} className="mt5">  
                                    <a download="" className="download-sample" href="https://safeconnect-beta.s3-us-west-2.amazonaws.com/User-Template.csv"> Download Sample Template</a> 
                                </Col> 
                            </Col>  
                            <Col sm={12} xs={12}> 
                                {  this.state.bulkUploadMessage && 
                                    <div style={{color:'red', textAlign:'center',fontSize:'14px',paddingTop:'20px'}}>
                                        {this.state.bulkUploadMessage}
                                    </div>
                                }
                            </Col> 
                        </Row> 
                        
                    </div>
                    <ListPage
                        pageHeading="Users"
                        instances={this.state.userInstances}
                        rowHeadings={['User Name', 'Access Level', 'Job Title']}
                        listItemComponent={ListItem}
                        isSearchable={true}
                        searchableBy={['userId', 'firstName', 'middleName', 'lastName']}
                        searchPlaceHolder="Enter user id or username to search..."
                        emptyInstancesMessage="No Users found."
                        sm={[5, 2, 3]}
                        searchFrom={this.props.userInstances}
                        rowSortings={this.sortListing} 
                        promise={(sortIndex) => getAllUsers()} 
                        history={this.props.history }
                    />
                    {UserModel.checkUserAccess('user_add') && this.renderAddNewUserButton()}
                    <Pagination
                        history={this.props.history}
                        promise={this.getUserList}
                        totalCount={((Number(totalCount) / 20).toString().split('.')[0] === '0') ?
                            1 : Number(totalCount) / 20}
                        Model={undefined}
                        identifier="UserListPage"
                    />
                </div>
            }
        />;
    }

    render() {
        return (
            <BasePage className="user-list-page-container" >
                <div className="users-list"> 
                    <AlertModal path="/users" id="delete-user-success" /> 
                    <AlertModal path="/users" id="password-update-user-success" style={{ color: 'white' }} />
                    <ConfirmationModal id="delete-user" />
                    {this.renderUserList()} 
                </div>
            </BasePage >
        );
    }
} 

export function mapStateToProps(state: any) {
    const userInstances = UserModel.list(state);
    return {
        userInstances
    };
}

export const UserListPage = withRouter(connect<IListPageProps, any, any>(mapStateToProps)(ListPageImpl));
