import * as React from 'react';
import { Col, DropdownButton, Container as Grid, Dropdown, Row  } from 'react-bootstrap'; 
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IHistory, ISubAttributeData, IForms } from '../../interfaces'; 
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Label } from '../reusableComponents/FormComponents/Label'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';  
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general'; 
import { isFormEmpty, showAlert, dispatch } from '../../utils/generalUtils';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Form } from '../reusableComponents/FormComponents/Form';  
import '../OIForm/oiForm.scss';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { format } from 'date-fns';
import { UserModel } from '../../model/UserModel';
import { FileInput } from '../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { TOTALSCOREERROR } from '../../constants/general'; 
import { Async } from '../reusableComponents/Async'; 
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';  
import { getAllAttribute, submitCheckList } from '../../services/attributeService';
import { ChecklistNameModel } from '../../model/ChecklistModel';
import { getAllCheckListNames } from '../../services/checklistService';
import { IAttributeDataState } from '../SafetyReport/SafetyWalkAssesment/AddAttribute'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { deleteAttributeAction, editAttribute, navigateAddAttribute } from '../../actions/attributeAction';
import { showDelConfirmModal } from '../../actions/modalActions';
const MenuItem = Dropdown.Item

export interface IChecklistFormProps {
    history?: IHistory;
    attributeList: IAttributeDataState[];
    firstName: string;
    company?: string;
    forms: IForms;
    userId: string;
    editId: string;
    editInstance: any;
    userInstance: UserModel;
    checklistId: string;
    checklistNameInstance: ChecklistNameModel;
}
 
export interface IChecklistFormState {
    userUID: string;
    userId: string;
    managerName: string;
    id: string;
    currentDate: string;
    attributeList: IAttributeDataState[];
    totalScore: number;
    comment: string;
    hasError: boolean;
    submitDate: Date;
    isSubmit: boolean;
}

export class ChecklistFormImpl extends
    React.Component<IChecklistFormProps, IChecklistFormState> {
    constructor(props: IChecklistFormProps | Readonly<IChecklistFormProps>) {
        super(props); 
        let date = new Date();
        let currentDate = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        this.state = {
            userUID: '', userId: '', managerName: '', id: '', currentDate: currentDate,
            attributeList: [], totalScore: (props.editInstance && props.editInstance.totalScore) || 0, comment: (props.editInstance && props.editInstance.overAllComment ) || '', submitDate: ( props.editInstance && new Date(props.editInstance.date) ) || new Date(), hasError: false, isSubmit: false,
        };
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onScoreChange = this.onScoreChange.bind(this); 
        this.onDateChange = this.onDateChange.bind(this);
    }

    promise = async () => {
        
        return;
    } 
    getChildContext() {
        return { formModel: 'forms.assessmentForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    dynamicSort(property: string) {
        let sortOrder = 1;

        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a: any, b: any) { 
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        };
    }
    async componentDidMount() {  
        if(ChecklistNameModel.list().length<1) {
            await getAllCheckListNames();
        }
        /* const { history, editId } = this.props; 
        if(editId && !this.props.editInstance ) 
            history.push('/sw-reports'); */
        this.getCheckListData(); 
    }
    getCheckListData() {
        const { editInstance,checklistId } = this.props; 
        getAllAttribute(checklistId).then((responseList) => { 
            if (responseList) {
                let attributeArray: IAttributeDataState[] = [];
                responseList.sort(this.dynamicSort('attribute')); 
                let attrList: any = []; 
                responseList.map((item: any, parentIndex) => {
                    if(item['attribute'].toLowerCase() === 'general' ) {
                        attrList.push(item);   
                        delete responseList[parentIndex];  
                    } 
                }); 
                attrList = attrList.concat(responseList); 
                attrList = attrList.filter(Boolean); 
                attrList.map((item: { attribute: any; name: { length: number; map: (arg0: (subattributeName: any, index: string) => void) => void; }; subAttributeList: { length: number; map: (arg0: (subattributeItem: { name: any; }, index: string) => void) => void; }; }, parentIndex: any) => { 
                    let editIAttr: any = [];
                    if(editInstance) { 
                        editIAttr = editInstance.subAttributeList.filter((p: { attributeName: any; }) => p.attributeName === item.attribute)
                    } 
                    let subAttributeArray: ISubAttributeData[] = [];
                    if (item.name && item.name.length > 0) {
                        item.name.map((subattributeName: any, index: string) => {
                            let editISubAttr: any = [];
                            if(editIAttr.length) {
                                editISubAttr = editIAttr[0].answerList.filter((p: { key: any; }) => p.key === subattributeName)
                            } 
                            let subAttributeItem: ISubAttributeData = {
                                subAttributeId: index + '' + parentIndex,
                                isAttributeNA: (editISubAttr.length && editISubAttr[0].value==='NA' && true) || false,
                                isAttributeNo: (editISubAttr.length && editISubAttr[0].value==='No' && true) || false,
                                isAttributeYes: (editISubAttr.length && editISubAttr[0].value==='Yes' && true) || false,
                                name: subattributeName
                            };
                            subAttributeArray.push(subAttributeItem);
                        });
                    } else if (item.subAttributeList && item.subAttributeList.length > 0) {
                        item.subAttributeList.map((subattributeItem: { name: any; }, index: string) => {
                            let subAttributeItem: ISubAttributeData = {
                                subAttributeId: index + '' + parentIndex,
                                isAttributeNA: false,
                                isAttributeNo: false,
                                isAttributeYes: false,
                                name: subattributeItem.name
                            };
                            subAttributeArray.push(subAttributeItem);
                        });
                    }
                    let attrId: any =  (parseInt(parentIndex) + 1).toString()
                    let attributeItem: IAttributeDataState = {
                        attributeId:  attrId,
                        attribute: item.attribute,
                        editAttribute: false,
                        inputValue: '',
                        isSubmitting: false,
                        name: [],
                        newAttributes: [],
                        subAttributes: [],
                        subAttributeList: subAttributeArray,
                        formType: ''
                    };
                    attributeArray.push(attributeItem);
                });
                this.setState({ attributeList: attributeArray });
            }
        });
    } 
    handleFormSubmit = async ({ projectName, groupName, serialNo, checklistTitle }: any) => {  
        const { userId, editId, checklistId } = this.props;  
        const submittedValues = {projectName, groupName, serialNo, checklistTitle};
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'checklist-form-failed', 'danger');
            return;
        }
        let currentAttrubuteList = this.state.attributeList;
        let time = new Date().toLocaleTimeString();
        let answers: any = []; 
        this.setState({isSubmit: true});
        currentAttrubuteList.map(item => {
            if (item.subAttributeList) {
                item.subAttributeList.map(i => {
                    let submitObject: any = {
                        mainAttr: item.attribute,
                        subAttr: i.name,
                        answer: ''
                    };
                    if (i.isAttributeNo) {
                        submitObject.answer = 'No';
                    } else if (i.isAttributeYes) {
                        submitObject.answer = 'Yes';
                    } else if (i.isAttributeNA) {
                        submitObject.answer = 'NA';
                    } else {
                        submitObject.answer = null;
                    }
                    answers.push(submitObject);
                });
            }
        });
        if (this.state.hasError) {
            this.setState({isSubmit: false});
            showAlert(TOTALSCOREERROR, 'check-list-form-alert', 'danger');
            return;
        }
        let submitData = {
            formType: checklistId,
            userId: userId,
            company: UserModel.getCompanyName(),
            date: format(this.state.submitDate, 'MM/dd/yyyy'),
            time: time,
            comment: this.state.comment,
            total: this.state.totalScore,
            answers: answers 
        };  
        await submitCheckList(submitData, this.props.history, this.props.forms, 'checklistImages', 'checklistVideos', editId );
        this.setState({isSubmit: false});
        this.setState({ comment: '' });
        this.setState({ submitDate: new Date() });
        this.setState({ totalScore: 0 });
        this.getCheckListData();
    }
    onSubAttributeSelectionChange(event: React.ChangeEvent<HTMLInputElement>, item: ISubAttributeData, selection: string) {
        let currentAttrubuteList = this.state.attributeList;
        if (currentAttrubuteList) {
            currentAttrubuteList.map((attribute) => {
                attribute.subAttributeList.map((subAttribute) => {
                    if (subAttribute.subAttributeId === item.subAttributeId) {
                        if (selection === 'yes') {
                            subAttribute.isAttributeYes = true;
                            subAttribute.isAttributeNo = false;
                            subAttribute.isAttributeNA = false;
                        } else if (selection === 'no') {
                            subAttribute.isAttributeNo = true;
                            subAttribute.isAttributeNA = false;
                            subAttribute.isAttributeYes = false;
                        } else {
                            subAttribute.isAttributeNA = true;
                            subAttribute.isAttributeNo = false;
                            subAttribute.isAttributeYes = false;
                        }
                    }
                });
            });
            this.setState({ attributeList: currentAttrubuteList });
        }
    }

    onAddNewAttribute = () => {
        //dispatch(navigateAddAttribute('ManagerCheckList'));
        this.props.history?.push(`/checklist-attribute/${this.props.checklistId}`);
    }
    onSubAttributeChange = (event: { preventDefault: () => void; }) => {
        // event.setSta
        event.preventDefault();
    }
    onDeleteAttribute(index: number, attributeItem: IAttributeDataState) {
        let attributeData = { index, attributeItem}
        showDelConfirmModal('delete-confirmation', 'Checklist Question', attributeData);  
    }
    onConfirmDeleteAttribute = (attributeData: any) => { 
        let index = attributeData.index;
        let attributeItem = attributeData.attributeItem; 
        let newAttributeArray = this.state.attributeList.filter(e => e !== this.state.attributeList[index]);
        if (newAttributeArray && newAttributeArray.length > 0) {
            newAttributeArray.map((attribute, index) => {
                attribute.attributeId = index + 1 + '';
            });
        }
        let formType = this.props.checklistId;
        // attributeItem
        dispatch(deleteAttributeAction(newAttributeArray, attributeItem, formType));
        this.setState({ attributeList: newAttributeArray });
        showAlert('Checklist updated successfully', 'check-list-form', 'success' ); 

    }
    onEditAttribute(item: IAttributeDataState) { 
        dispatch(navigateAddAttribute(this.props.checklistId));
        item.formType = this.props.checklistId;
        dispatch(editAttribute(true, item));
        this.props.history?.push(`/checklist-attribute/${this.props.checklistId}`);
    }
    onScoreChange(event: { target: { value: any; }; }) {
        if (event.target.value !== '' && (event.target.value < 1 || event.target.value > 100)) {
            this.setState({
                hasError: true,
                totalScore: 0
            });
            return;
        }
        this.setState({
            hasError: false, totalScore: event.target.value
        });
    }
    onCommentChange(event: { target: { value: any; }; }) {
        this.setState({ comment: event.target.value });
    }
    async onDateChange(value: string | number | Date) {  
        let date = new Date(value); 
        date.setDate(date.getDate() ); 
        await this.setState({ submitDate: date });  
    }
    renderSubAttribute(subAttributeData: ISubAttributeData[], row: number) { 
        return subAttributeData.map((item, index) => {
            return <Row key={`subAttributeRow${index}${row}`}
             className="customized-grid row-cell-height border-bottom 
             border-left border-right margin-left-right-0 padding-bottom-0">
                <Col className="row-cell-height border-right" sm={1} xs={12}>

                </Col>
                <Col className="row-cell-height" sm={8} xs={12}>
                    <Label sm={12}>{item.name}</Label>
                </Col>
                <Col className="row-cell-height  border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter"><input className="localcss radio-button-size"
                        id={`.attributeYes${index}${row}`}
                        type="radio"
                        onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'yes')}
                        checked={item.isAttributeYes}
                        name={`attributeradio${index}${row}`}

                    /><label htmlFor={`.attributeYes${index}${row}`}></label></div>
                </Col>
                <Col className="row-cell-height border-right border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter"><input
                        id={`.attributeNo${index}${row}`}
                        type="radio" className="radio-button-size"
                        onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'no')}
                        checked={item.isAttributeNo}
                        name={`attributeradio${index}${row}`}

                    /><label htmlFor={`.attributeNo${index}${row}`}></label></div>
                </Col>
                <Col className="row-cell-height " sm={1} xs={12}>
                    <div className="width-height-100-textcenter"><input
                        id={`.attributeNA${index}${row}`}
                        type="radio" className="radio-button-size"
                        onChange={(event) => this.onSubAttributeSelectionChange(event, item, 'na')}
                        checked={item.isAttributeNA}
                        name={`attributeradio${index}${row}`}
                    /><label htmlFor={`.attributeNA${index}${row}`}></label></div>
                </Col>
            </Row>;
        });
    }

    renderAttribute(attributeList: IAttributeDataState[]) {  
        return attributeList.map((item, index) => {
            return <div key={`attributeRowParent${index}`}>
                <Row key={`attributeRow${index}`} 
                className="border-bottom row-cell-height border-left 
                border-right margin-left-right-0 padding-bottom-0 color-white"> 
                    <Col className="row-cell-height border-right" sm={1} xs={12}>
                        { ( UserModel.checkUserAccess('safety_walkthrough_question_add') || UserModel.checkUserAccess('safety_walkthrough_question_delete') ) && 
                            <DropdownButton title="" id="bg-vertical-dropdown-3">
                                { UserModel.checkUserAccess('safety_walkthrough_question_delete') && <MenuItem eventKey="2"
                                    onClick={() => this.onEditAttribute(item)}
                                    className="item">&nbsp;Edit</MenuItem> }
                                { UserModel.checkUserAccess('safety_walkthrough_question_delete') && <MenuItem eventKey="1"
                                    onClick={() => this.onDeleteAttribute(index, item)}
                                    className="item">&nbsp;Delete</MenuItem> }
                               
                            </DropdownButton> 
                        }
                        <div className="sequence-number-position">
                            {`${item.attributeId}.`}
                        </div>
                    </Col>
                    <Col className="row-cell-height" sm={8} xs={12}>
                        <div className="width-height-100-textcenter">{item.attribute}</div>
                    </Col>
                    <Col className="row-cell-height border-left color-white" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height color-white" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height " sm={1} xs={12}>
                    </Col>
                </Row>
                {this.renderSubAttribute(item.subAttributeList, index)}
            </div>;
        });
    }

    renderContent() {
        const { attributeList } = this.state;
        const { firstName, editInstance, checklistNameInstance } = this.props;  
        return (
            <div className="project-list-wrapper">
                <Grid style={{ width: '100%' }} className="safety-walkthrough-assesment">
                    <Title titleColor="yellow" text={`${checklistNameInstance ? checklistNameInstance.props.checklistName:'Checklist'} Form`} noNeedBorder={true}/>
                    <Alert className="danger-alert" id="check-list-form-alert" />
                    <fieldset>
                    {/* {this.state.isSubmit && <SubmissionLoader />} */}
                    <Form loader={<SubmissionLoader />} model="forms.assessmentForm" onSubmit={this.handleFormSubmit}>
                       <Row className="show-grid margin-left-right-0"> 
                            <Col sm={4} xs={12} className="input"> 
                                <Label sm={12}>Checklist Title:</Label>
                                <RRFInput
                                    id="checklistTitle"
                                    type="text"
                                    defaultValue={editInstance? editInstance.checklistTitle: '' }
                                    model=".checklistTitle" 
                                /> 
                            </Col>
                            <Col sm={{offset: 4, span: 4}} xs={12} className="input"> 
                                <Label sm={12}>Serial No:</Label>
                                <RRFInput
                                    id="serialNo"
                                    type="text"
                                    defaultValue={editInstance? editInstance.serialNo: '' }
                                    model=".serialNo" 
                                /> 
                            </Col>
                            <Col sm={3} xs={12} className="input">
                                <Label sm={12} htmlFor="currentDate">User:</Label>
                                <Col sm={12}  >{firstName}</Col>
                            </Col>
                            <Col sm={3} xs={12} className="input">
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <RRFInput
                                    id="currentDate"
                                    type="date"
                                    defaultValue={editInstance?format(new Date(editInstance.dateOfIncident), 'yyyy-MM-dd'): format(this.state.submitDate, 'yyyy-MM-dd')}
                                    model=".dateOfIncident"
                                    maxdate={format(new Date(), 'yyyy-MM-dd')} onChange={this.onDateChange}
                                /> 
                            </Col>
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={ (this.props.editInstance && this.props.editInstance.projectName) || (this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0] )} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={ (this.props.editInstance && this.props.editInstance.groupName ) || ( this.props.userInstance  && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]) } />
                            </Col>
                        </Row>
                        <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                            <Col className="border-bottom border-right" sm={1} xs={12}>
                                <Label sm={12}>No.</Label>
                            </Col>
                            <Col className="border-bottom" sm={8} xs={12}>
                                <Label sm={12}>Attribute</Label>
                            </Col>
                            <Col className="border-bottom border-left" sm={1} xs={12}>
                                <Label sm={12}>Yes</Label>
                            </Col>
                            <Col className="border-bottom border-right border-left" sm={1} xs={12}>
                                <Label sm={12}>No</Label>
                            </Col>
                            <Col className="border-bottom" sm={1} xs={12}>
                                <Label sm={12}>N/A</Label>
                            </Col>
                        </Row>
                        {this.renderAttribute(attributeList)}

                        {/* Shows's today's score with 2 column and one row */}
                        <Row className="show-grid border-left border-right border-bottom margin-left-right-0">
                            <Col sm={2} xs={12}>
                                <div className="width-height-100-textleft">Total Score:</div>
                            </Col>
                            <Col sm={10} xs={12}>
                                <div className="total-score-input">
                                    <input className="comment-input"
                                        id="observer"
                                        type="number"
                                        value={this.state.totalScore}
                                        onChange={this.onScoreChange}
                                    />
                                    {this.state.hasError &&
                                        <span style={{ color: 'red', fontWeight: 100, }} >{TOTALSCOREERROR}</span>}
                                </div>
                            </Col>
                        </Row> 

                        <Row className="show-grid border-left border-right border-bottom margin-left-right-0">
                            <Col sm={2} xs={12}>
                                <div className="width-height-100-textleft"> Overall Comments:</div>
                            </Col>
                            <Col sm={10} xs={12}>
                                <div className="total-score-input">
                                    <input className="comment-input"
                                        id="observer"
                                        type="text"
                                        value={this.state.comment}
                                        onChange={this.onCommentChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Images:</Label>
                                <Col sm={12} className="input">
                                    <FileInput
                                        model=".checklistImages"
                                        multiple={true}
                                        id="upload-images"
                                        defaultValue={editInstance && editInstance.imageUrls}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                                <Col sm={12} className="input">
                                    <VideoInput
                                        model=".checklistVideos"
                                        multiple={true}
                                        id="upload-videos"
                                        defaultValue={editInstance && editInstance.videoUrls}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="row-addrow-section">
                            <Alert className="danger-alert" id="checklist-form-failed" />
                            <Col>
                                <div className="table-buttons form-button"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '0'
                                    }}>
                                    <Button className="closeButton" redirectTo="goBack" type="button" style={{width: '20%', height:' 34px'}} onClick={() => this.props.history?.goBack() }>
                                        <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                                    Cancel </Button>   
                                    { !editInstance ? <button style={{width: 'unset'}}
                                        className="add-row"
                                        type="button"
                                        onClick={this.onAddNewAttribute}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Attribute
                                    </button> : null }
                                    
                                    <Button type="submit" style={{
                                        color: '#FFFFFF',
                                        border: 'none',
                                        backgroundColor: '#26A65B',
                                        fontSize: '18px',
                                        height: '34px',
                                        borderRadius: '17px',
                                        outline: 'none',
                                        width: '190px',
                                        marginRight: '5px'
                                        }}
                                        >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                        &nbsp;
                                        { editInstance ? 'Update' : 'Submit' }
                                    </Button> 
                                </div>
                            </Col>
                        </Row>
                       </Form>
                    </fieldset>
                </Grid>
            </div>
        );
    }

    render() {
        const {editInstance, editId, history } = this.props;
        if(editId && !editInstance)
            history?.push('/checklist-reports/other-checklists');
        return (
            <BasePage className="safeconnect-form" >
                <AlertModal id="check-list-form" /> 
                <DeleteConfirmationModal id="delete-confirmation" onConfirm={this.onConfirmDeleteAttribute}/>
                <Async
                    identifier="CheklistForm"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
} 
 
export function mapStateToProps(state: any, ownProps: any) { 
    const { login } = state;
    const checklistId = ownProps.match.params.checklistId;
    const checklistNameInstance = ChecklistNameModel.get(checklistId); 
    const editId = ownProps.match.params.editId; 
    let firstName = login.get('firstName');
    let userId = login.get('userUID');
    const editInstance = editId && state.report.get('reportDetailData');  
    const userInstance = UserModel.get(userId);
    return {
        firstName, userId, editInstance, userInstance, checklistId, checklistNameInstance, forms: state.forms, editId,  attributeList: state.attribute.get('attributeList'), 
    };
}
export const ChecklistForm = withRouter(connect<IChecklistFormProps, any, any>(mapStateToProps)(ChecklistFormImpl)); 
