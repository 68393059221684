import {BaseModel} from "./BaseModel";

export interface IUtilityDamageReportModelProps {
    id?: string;
    editId?: string;
    dateOfDamage?: Date;
    streetAddress?: string;
    city?: string;
    state?: string;
    nearestIntersection?: string;
    isElectricAffected?: boolean;
    isNGasAffected?: boolean;
    isSewerAffected?: boolean;
    isWaterAffected?: boolean;
    isTelePhoneAffected?: boolean;
    isCableTVAffected?: boolean;
    isServiceOrDrop?: boolean;
    isMain?: boolean;
    isFiberOptic?: boolean;
    damageDepth?: boolean;
    isOneCallCenterNotified?: string
    locateTicketNumber?: string;
    isFacilityMarkVisible?: string;
    isFacilityMarkAccurate?: string;
    facilityMarkerType?: string
    isPhotosTaken?: string;
    typeOfPaintedLocateMarks?: string
    locateMarksDistance?: string;
    isTrackHoe?: boolean;
    isBoring?: boolean;
    isAuger?: boolean;
    isTrencher?: boolean;
    isDirectionDrill?: boolean;
    isDrilling?: boolean;
    isHandTools?: boolean;
    isProbingDevice?: boolean;
    isGasPipelineInstalled?: boolean;
    isElectricCable?: boolean;
    isJointTrench?: boolean;
    isTelephoneInstallation?: boolean;
    isCableTVInstallation?: boolean;
    isPolesInstallation?: boolean;
    isAnchorsInstallation?: boolean;
    isOtherWork?: any;
    otherWorkType?: string;
    isLocationPrivateProp?: boolean;
    isLocationUtilityEasement?: boolean;
    isLocationRoad?: boolean;
    incidentDesc?: string;
    attachments?: any;
    deletedFiles?: any;
    generalForemanName?: string;
    generalForemanPhone?: string;
    crewForemanName?: string;
    crewForemanPhone?: string;
    jobNo?: string;
    crewNo?: string;
    answers?: any;
    attachmentUrls?: any;
    shapesList?: any;
    outfallsList?: any;
    coords?: any;
    language?: string;
    coordinatesPointerList?: any;
}

export class UtilityDamageReportModel extends BaseModel<IUtilityDamageReportModelProps> {
    static resource = 'utility-damage-report';

    constructor(props: IUtilityDamageReportModelProps) {
        super(props, UtilityDamageReportModel.resource);
    }
}
