import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './preExcavationChecklistListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import {PreExcavationChecklistModel} from "../../model/PreExcavationChecklistModel";
declare var require: any;
const queryString = require('query-string');

export interface IPreExcavationChecklistListPageProps {
    instances: PreExcavationChecklistModel[];
    history?: IHistory;
    totalPageCount: number;
}

export class PreExcavationChecklistListImpl extends React.PureComponent<IPreExcavationChecklistListPageProps, {}> {
    constructor(props: IPreExcavationChecklistListPageProps | Readonly<IPreExcavationChecklistListPageProps>) {
        super(props);
    }

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {
        //await getAllTexas811Forms('preExcavationChecklist', pageNumber);
        return null;
    }

    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report"
                    onClick={() => { history?.push('/preExcavationChecklist/create'); }}
            >
                +
            </button>
        );
    }

   /* renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                    onClick={() => { this.props.history?.push('/office-safety-reports/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"/>
            </button>
        );
    }*/

    renderContent = () => {
        const { instances } = this.props;
        return <div className="office-safety-wrapper">
            <ListPage
                pageHeading="Pre-Excavation Checklist List"
                instances={instances}
                rowHeadings={['Date Submitted','Observer', 'Crew Foreman', 'OneCall Ticket #' ]}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={[ 'date', 'username']}
                searchPlaceHolder="Enter project, group, location, observer or date created to search..."
                emptyInstancesMessage="No pre-excavation checklist report found."
                sm={[2, 3, 2, 2]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
            />
            {this.renderAddNewFormButton()}
          {/*  { UserModel.checkUserAccess('office_safety_report_summary') && this.renderSummaryPageButton()}*/}
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={PreExcavationChecklistModel as any}
                identifier="PreExcavationChecklistReports"
            />
        </div>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="PreExcavationChecklistReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const instances = PreExcavationChecklistModel.list();
    return {
        instances,
        totalPageCount: state.miscellaneous.get('officeSafetyFormCount'),
    };
}

export const PreExcavationChecklistList = withRouter(connect<IPreExcavationChecklistListPageProps, any, any>
(mapStateToProps)(PreExcavationChecklistListImpl));
