import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';  
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { Label } from '../reusableComponents/FormComponents/Label';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';
import { UserModel } from '../../model/UserModel'; 
import { IForms, IHistory } from '../../interfaces';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import './index.scss';
import { dispatch, isFormEmpty, showAlert, convertToDateFormat } from '../../utils/generalUtils';
import { actions } from 'react-redux-form';
import { IWorkDetails, IContractEmployeesDetails, IToolBoxMeetingModelProps, ToolBoxMeetingModel } from '../../model/ToolBoxMeetingModel';
import { ManualSignature } from '../reusableComponents/ManuallyGenerateSignature/ManualSignature';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { submitTSMForm, getFormData } from '../../services/tsmService';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IToolBoxMeetingFormProps { 
    instance?: any;
    userInstance: UserModel;
    history?: IHistory;
    forms: IForms;
    company: string;
    editId?: string;
}

export interface IToolBoxMeetingFormState {  
    currentDate: string;
    currentTime: string;
    worksDetails: IWorkDetails[]; 
    contractEmployeesDetails: IContractEmployeesDetails[]; 
    groupMembers: any[];
    tsmMemberSign: boolean;
    isTsmSignatureUploaded: boolean;
    tsmSignature: string;
    tsmSignatureURL: string; 
}

 
export class ToolBoxMeetingFormImpl extends React.PureComponent<IToolBoxMeetingFormProps, IToolBoxMeetingFormState> {
 
    constructor(props: IToolBoxMeetingFormProps | Readonly<IToolBoxMeetingFormProps>) {
        super(props); 
        this.state = { currentDate: format(new Date(), 'yyyy-MM-dd'), currentTime: format(new Date(), 'HH:mm'), worksDetails: [{ worksDetail: '' }], contractEmployeesDetails: [{name: ''}], groupMembers: [], tsmMemberSign: true, isTsmSignatureUploaded: false, tsmSignature: '', tsmSignatureURL: '',  }
    }

    promise = async () => { 
        const { editId, instance } = this.props;
        if(editId && !instance ) {
            await getFormData(editId);
        }
        
        if(instance) {
            this.getUsersOptions(instance.props.groupName); 
            this.setState({tsmMemberSign: false, tsmSignatureURL: instance.props.TSMLeaderSignatureURL}) 
            if(instance.props.worksDetail ) {
                let workDetails: any = [];
                instance.props.worksDetail.forEach((data: any) => {  
                    workDetails.push({ worksDetail: data })
                })
                this.setState({worksDetails: workDetails})
            }
            if(instance.props.contractEmplyeesName ) {
                let contractEmplyeesName: any = [];
                instance.props.contractEmplyeesName.forEach((data: any) => {  
                    contractEmplyeesName.push({ name: data })
                })
                this.setState({contractEmployeesDetails: contractEmplyeesName})
            }
        }
    }
    componentWillMount() {  
    }

    getChildContext() {
        return { formModel: 'forms.toolBoxMeetingForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    handleFormSubmit = async (values: IToolBoxMeetingModelProps) => { 
        const { instance } = this.props; 
        let submittedValues: any = { dateOfIncident: values.dateOfIncident, timeOfIncident: values.timeOfIncident, locationName: values.locationName, taskToPerform: values.taskToPerform, projectName: values.projectName, groupName: values.groupName, isExternalPersonNeed: values.isExternalPersonNeed }; 
        if(values.isExternalPersonNeed==='1') 
            submittedValues.isExternalPersonAffected = values.isExternalPersonAffected;
        if(values.isExternalPersonAffected==='1') 
            submittedValues.isExternalPersonBriefed = values.isExternalPersonBriefed; 
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'tsm-form-failed', 'danger');
            return;
        }
        await submitTSMForm(JSON.parse(JSON.stringify(values)), 'TSMForm', this.props.history, this.props.forms, this.props.editId, instance);
        return; 
    } 
    getUsersOptions = (group?: string) => { 
        dispatch(actions.change(`forms.toolBoxMeetingForm.emplyeesName`,''));
        const users = UserModel.list();
        this.setState({groupMembers: []});
        let groupMembers: any = [];
        let firstNames: any[] = [];
        (users || []).forEach( (user:any) => { 
            if(user.props.groups.includes(group)) {
                let firstName = user.props.firstName;
                firstNames.push(firstName);
                if (firstNames.indexOf(firstName) > -1) {
                    firstName = user.props.firstName + ' ' + user.props.middleName + ' ' + user.props.lastName;
                }
                groupMembers.push({ label: firstName, value: user.props.userUID.toString() });
            }
        }); 
        this.setState({groupMembers: groupMembers});
    }  
    addMoreWorkDetails = () => { 
        this.setState({
            worksDetails: [...this.state.worksDetails, { worksDetail: '' }]
        });
    }
    decrementWorkDetails = (index) => {
        const { worksDetails } = this.state;
        dispatch(actions.remove(`forms.toolBoxMeetingForm.worksDetail`, index));
        let newArray = worksDetails.filter(e => e !== worksDetails[index]);
        this.setState({
            worksDetails: newArray
        });
    }
    renderWorkDetails = () => {
        const { worksDetails } = this.state; 
        let { forms } = this.props;
        return [worksDetails.map((ruleData, index) => {
            return <><Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={6} sm={{offset: 3, span: 6}} className="input">
                
                <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.worksDetail[index]} model={`forms.toolBoxMeetingForm.worksDetail[${index}]`} />  
            <RRFInput
                type="textarea"
                model={`.worksDetail[${index}]`} 
                defaultValue={ruleData.worksDetail}
            />
        </Col> 
        <Col style={{ padding: '10px 15px', display: 'inline-block', color: 'white', cursor: 'pointer' }} xs={1} sm={1} className="input" onClick={() => this.decrementWorkDetails(index)} >
            <i className="fa fa-trash-o" aria-hidden="true"></i>
        </Col></> }) ]
    }
    renderContent = () => {  
        const { handleFormSubmit, state: { currentDate, currentTime }, props: { instance, forms } } = this;

        return (
            <Grid className="tbm-form" style={{ width: '100%' }}>  
                <MapInputModal id="tool-box-meeting-form" />
                <Form loader={<SubmissionLoader />} model="forms.toolBoxMeetingForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="Toolbox Safety Meeting (TSM) Form" noNeedBorder={true}/>
                    <AlertModal id="tsm-modal" />  
                    <Alert className="danger-alert" id="tsm-form-failed"/>
                    <fieldset> 
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}><legend className="legend-header" >Section 1: General Information </legend></Col>
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date"
                                        defaultValue={instance? convertToDateFormat(instance.props.dateOfIncident, 'yyyy-MM-dd'): currentDate}  
                                        model=".dateOfIncident"
                                        maxdate={ currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentTime">Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentTime"
                                        defaultValue={instance ? instance.props.timeOfIncident: currentTime}
                                        type="time"
                                        model=".timeOfIncident" 
                                    />
                                </Col>
                            </Col>  
                            <LocationInput 
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="tsm-form"
                                navigator={navigator} 
                                defaultValue={instance && instance.props.locationName}
                            />
                            <Col sm={3} xs={12}>
                                <Label required sm={12}>Task:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.taskToPerform} model={`forms.toolBoxMeetingForm.taskToPerform`} />  
                                    <RRFInput
                                        id="taskToPerform"
                                        type="text"
                                        defaultValue={instance && instance.props.taskToPerform}
                                        model=".taskToPerform" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={instance ? instance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={instance && instance.props.groupName} onGroupChange={this.getUsersOptions} />
                            </Col>  
                            <Col sm={3} xs={12}>
                                <Label required sm={12}>TSM Coordinater:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.tsmCoordinater} model={`forms.toolBoxMeetingForm.tsmCoordinater`} />  
                                    <RRFInput
                                        id="tsmCoordinater"
                                        type="text"
                                        defaultValue={instance && instance.props.tsmCoordinater}
                                        model=".tsmCoordinater" 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Primary Assembly Point:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.primaryAssemblyPoint} model={`forms.toolBoxMeetingForm.primaryAssemblyPoint`} />  
                                    <RRFInput
                                        id="primaryAssemblyPoint"
                                        type="text"
                                        defaultValue={instance && instance.props.primaryAssemblyPoint}
                                        model=".primaryAssemblyPoint" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Secondary Assembly Point:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.secondaryAssemblyPoint} model={`forms.toolBoxMeetingForm.secondaryAssemblyPoint`} />  
                                    <RRFInput
                                        id="secondaryAssemblyPoint"
                                        type="text"
                                        defaultValue={instance && instance.props.secondaryAssemblyPoint}
                                        model=".secondaryAssemblyPoint" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Nearest Medical Facility:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.nearestMedicalFacility} model={`forms.toolBoxMeetingForm.nearestMedicalFacility`} />  
                                    <RRFInput
                                        id="nearestMedicalFacility"
                                        type="text"
                                        defaultValue={instance && instance.props.nearestMedicalFacility}
                                        model=".nearestMedicalFacility" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Nearest Fire Extinguisher:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.nearestFireExtinguisher} model={`forms.toolBoxMeetingForm.nearestFireExtinguisher`} />  
                                    <RRFInput
                                        id="nearestFireExtinguisher"
                                        type="text"
                                        defaultValue={instance && instance.props.nearestFireExtinguisher}
                                        model=".nearestFireExtinguisher" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Nearest First Aid Kit:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.nearestFirstAidKit} model={`forms.toolBoxMeetingForm.nearestFirstAidKit`} />  
                                    <RRFInput
                                        id="nearestFirstAidKit"
                                        type="text"
                                        defaultValue={instance && instance.props.nearestFirstAidKit}
                                        model=".nearestFirstAidKit" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Nearest Eye Wash:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.nearestEyeWash} model={`forms.toolBoxMeetingForm.nearestEyeWash`} />  
                                    <RRFInput
                                        id="nearestEyeWash"
                                        type="text"
                                        defaultValue={instance && instance.props.nearestEyeWash}
                                        model=".nearestEyeWash" 
                                    />
                                </Col>
                            </Col>
                            <Col sm={{span: '3' }} xs={12}>
                                <Label sm={12}>Cell Phone Usage:</Label>
                                <Col sm={12} className="input text-center">
                                    <RRFInput
                                        id="cellPhoneUsage"
                                        model=".cellPhoneUsage"
                                        type="checkbox" 
                                        defaultValue={instance && instance.props.cellPhoneUsage } 
                                    />
                                    <label htmlFor="cellPhoneUsage"></label>
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}> 
                                <Label required className="text-left" sm={12}>Are there other personnel not required for this Task present during execution?</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="needExternalPersonsYes"
                                        type="radio"
                                        model=".isExternalPersonNeed"
                                        radioValue={'1'}
                                        radioName="isExternalPersonNeed" 
                                        defaultValue={instance && instance.props.isExternalPersonNeed}
                                    /> <label htmlFor="needExternalPersonsYes">Yes</label>
                                    <RRFInput
                                        id="needExternalPersonsNo"
                                        type="radio"
                                        model=".isExternalPersonNeed"
                                        radioValue={'0'}
                                        radioName="isExternalPersonNeed" 
                                        defaultValue={instance && instance.props.isExternalPersonNeed}
                                    /> <label htmlFor="needExternalPersonsNo">No</label>
                                </Label>
                            </Col>
                            { forms.toolBoxMeetingForm.isExternalPersonNeed==='1' && 
                            <Col sm={6} xs={12}> 
                                <Label required className="text-left" sm={12}><span className="color-red">if External personnel needed</span>, Will those personnel be affected by this Task</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="externalPersonAffectedYes"
                                        type="radio"
                                        model=".isExternalPersonAffected"
                                        radioValue={'1'}
                                        defaultValue={instance && instance.props.isExternalPersonAffected}
                                        radioName="isExternalPersonAffected" 
                                    /> <label htmlFor="externalPersonAffectedYes">Yes</label>
                                    <RRFInput
                                        id="externalPersonAffectedNo"
                                        type="radio"
                                        model=".isExternalPersonAffected"
                                        radioValue={'0'}
                                        defaultValue={instance && instance.props.isExternalPersonAffected}
                                        radioName="isExternalPersonAffected" 
                                    /> <label htmlFor="externalPersonAffectedNo">No</label>
                                </Label>
                            </Col> }
                            
                            { forms.toolBoxMeetingForm.isExternalPersonNeed==='1' && forms.toolBoxMeetingForm.isExternalPersonAffected==='1' && 
                            <Col sm={6} xs={12}> 
                               <Label required className="text-left" sm={12}><span className="color-red">if External personnel affected</span>, Those personnel are briefed about the Task and present in TSM</Label>
                                <Label sm={12} className="radioInput">
                                    <RRFInput
                                        id="externalPersonBriefedYes"
                                        type="radio"
                                        model=".isExternalPersonBriefed"
                                        radioValue={'1'}
                                        defaultValue={instance && instance.props.isExternalPersonBriefed}
                                        radioName="isExternalPersonBriefed" 
                                    /> <label htmlFor="externalPersonBriefedYes">Yes</label>
                                    <RRFInput
                                        id="externalPersonBriefedNo"
                                        type="radio"
                                        model=".isExternalPersonBriefed"
                                        radioValue={'0'}
                                        defaultValue={instance && instance.props.isExternalPersonBriefed}
                                        radioName="isExternalPersonBriefed" 
                                    /> <label htmlFor="externalPersonBriefedNo">No</label>
                                </Label>
                            </Col> }
                        </Row>  
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}><legend className="legend-header" >Section 2: Work Details </legend></Col>
                            <Col sm={12} xs={12} style={{marginTop: 'unset'}}>
                                <Label sm={12}>Description of Tasks to be performed</Label> 
                            </Col> 
                        </Row>
                        <Row className="show-grid">
                            {this.renderWorkDetails()}
                            
                        </Row>
                        <div className="table-buttons">
                            <button onClick={() => this.addMoreWorkDetails() }
                                className="add-row"
                                type="button" >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Task
                            </button>
                        </div>
                        <Row>
                            <Col sm={12} xs={12} className="singleLine">
                                <p className="yellow-color-title">Are employees working within hearing distance from each other?</p>
                                <Label className="radioInput contentSingleLine">
                                    <RRFInput
                                        id="employeesWithinDistanceYes"
                                        type="radio"
                                        model=".employeesWithinDistance"
                                        radioValue={'1'}
                                        radioName="employeesWithinDistance" 
                                        defaultValue={instance && instance.props.employeesWithinDistance}
                                    /> <label htmlFor="employeesWithinDistanceYes">Yes</label>
                                    <RRFInput
                                        id="employeesWithinDistanceNo"
                                        type="radio"
                                        model=".employeesWithinDistance"
                                        radioValue={'0'}
                                        radioName="employeesWithinDistance" 
                                        defaultValue={instance && instance.props.employeesWithinDistance}
                                    /> <label htmlFor="employeesWithinDistanceNo">No</label>
                                </Label>
                            </Col> 
                        </Row>  
                        { forms.toolBoxMeetingForm.employeesWithinDistance==='0' && 
                        <Row className={'show-grid'}>
                            <p className="yellow-color-title"><span className="color-red">if no,</span> Communication is through?</p>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="throughCellPhone">Cell Phone</label>
                                    <RRFInput
                                        id="throughCellPhone"
                                        type="checkbox"
                                        model=".throughCellPhone"
                                        defaultValue={instance && instance.props.throughCellPhone}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="throughLandLine">Land Line</label>
                                    <RRFInput
                                        id="throughLandLine"
                                        type="checkbox"
                                        model=".throughLandLine"
                                        defaultValue={instance && instance.props.throughLandLine}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="through2WayWalkie">2 Way Walkie Talkie</label>
                                    <RRFInput
                                        id="through2WayWalkie"
                                        type="checkbox"
                                        model=".through2WayWalkie"
                                        defaultValue={instance && instance.props.through2WayWalkie}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="throughOtherCommunication">Others</label>
                                    <RRFInput
                                        id="throughOtherCommunication"
                                        type="checkbox"
                                        model=".throughOtherCommunication"
                                        defaultValue={instance && instance.props.throughOtherCommunication}
                                    />
                                </Col>
                            </Col>
                            { forms.toolBoxMeetingForm.throughOtherCommunication==='1' && 
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherCommunication">Specify</label>
                                    <RRFInput
                                        id="specifyOtherCommunication"
                                        type="text"
                                        model=".specifyOtherCommunication"
                                        defaultValue={instance && instance.props.specifyOtherCommunication}
                                    />
                                </Col>
                            </Col> }
                        </Row> }
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}><legend className="legend-header" >Section 3: Hazard Identification & Control </legend></Col> 
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">Type of work: </p>
                            </Col>
                            <Col sm={2} xs={12} className="text-center">
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="hotWork">Hot Work</label><br/>
                                    <RRFInput
                                        id="hotWork"
                                        type="checkbox"
                                        model=".hotWork"
                                        defaultValue={instance && instance.props.hotWork}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12} className="text-center">
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="lockoutTagout">Lockout/Tagout</label>
                                    <RRFInput
                                        id="lockoutTagout"
                                        type="checkbox"
                                        model=".lockoutTagout"
                                        defaultValue={instance && instance.props.lockoutTagout}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12} className="text-center">
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="excavation">Excavation</label>
                                    <RRFInput
                                        id="excavation"
                                        type="checkbox"
                                        model=".excavation"
                                        defaultValue={instance && instance.props.excavation}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={2} xs={12} className="text-center">
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="confinedSpace">Confined Space</label>
                                    <RRFInput
                                        id="confinedSpace"
                                        type="checkbox"
                                        model=".confinedSpace"
                                        defaultValue={instance && instance.props.confinedSpace}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={2} xs={12} className="text-center">
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherTypeOfWork">Other</label><br/>
                                    <RRFInput
                                        id="otherTypeOfWork"
                                        type="checkbox"
                                        model=".otherTypeOfWork"
                                        defaultValue={instance && instance.props.otherTypeOfWork}
                                    />
                                </Col>
                            </Col>  
                            { forms.toolBoxMeetingForm.otherTypeOfWork==='1' && 
                            <Col sm={12} xs={12} className="singleLine">
                                <p className="yellow-color-title">Confirm availability of additional permit/forms</p>
                                <Label className="radioInput contentSingleLine">
                                    <RRFInput
                                        id="additionalPermitAvailablityYes"
                                        type="radio"
                                        model=".additionalPermitAvailablity"
                                        radioValue={'1'}
                                        radioName="additionalPermitAvailablity" 
                                        defaultValue={instance && instance.props.additionalPermitAvailablity}
                                    /> <label htmlFor="additionalPermitAvailablityYes">Yes</label>
                                    <RRFInput
                                        id="additionalPermitAvailablityNo"
                                        type="radio"
                                        model=".additionalPermitAvailablity"
                                        radioValue={'0'}
                                        radioName="additionalPermitAvailablity" 
                                        defaultValue={instance && instance.props.additionalPermitAvailablity}
                                    /> <label htmlFor="additionalPermitAvailablityNo">No</label>
                                </Label>
                            </Col> }
                        </Row>
                        <Row className={'show-grid'}>
                            <p className="yellow-color-title">Tick applicable hazards for this Task</p>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="hazardousAtmosphere">Hazardous Atmosphere</label>
                                    <RRFInput
                                        id="hazardousAtmosphere"
                                        type="checkbox"
                                        model=".hazardousAtmosphere"
                                        defaultValue={instance && instance.props.hazardousAtmosphere}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="overheadHazards">Overhead Hazards</label>
                                    <RRFInput
                                        id="overheadHazards"
                                        type="checkbox"
                                        model=".overheadHazards"
                                        defaultValue={instance && instance.props.overheadHazards}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="temperatureExtremesHazard">Temperature extremes (hot/cold)</label>
                                    <RRFInput
                                        id="temperatureExtremesHazard"
                                        type="checkbox"
                                        model=".temperatureExtremesHazard"
                                        defaultValue={instance && instance.props.temperatureExtremesHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="chemicalExposureHazard">Chemical Exposure</label>
                                    <RRFInput
                                        id="chemicalExposureHazard"
                                        type="checkbox"
                                        model=".chemicalExposureHazard"
                                        defaultValue={instance && instance.props.chemicalExposureHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="safetySystemsHazard">Safety Systems Bypasses/disabled</label>
                                    <RRFInput
                                        id="safetySystemsHazard"
                                        type="checkbox"
                                        model=".safetySystemsHazard"
                                        defaultValue={instance && instance.props.safetySystemsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="slipsHazard">Slips/ Trips/ Falls/ Alternate Route</label>
                                    <RRFInput
                                        id="slipsHazard"
                                        type="checkbox"
                                        model=".slipsHazard"
                                        defaultValue={instance && instance.props.slipsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="dustyHazard">Dusty environment</label>
                                    <RRFInput
                                        id="dustyHazard"
                                        type="checkbox"
                                        model=".dustyHazard"
                                        defaultValue={instance && instance.props.dustyHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="weatherHazard">Weather Hazards</label>
                                    <RRFInput
                                        id="weatherHazard"
                                        type="checkbox"
                                        model=".weatherHazard"
                                        defaultValue={instance && instance.props.weatherHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="roadwayHazard">Roadway Work (traffic control)</label>
                                    <RRFInput
                                        id="roadwayHazard"
                                        type="checkbox"
                                        model=".roadwayHazard"
                                        defaultValue={instance && instance.props.roadwayHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="fallFromHeightHazard">Fall From Heights</label>
                                    <RRFInput
                                        id="fallFromHeightHazard"
                                        type="checkbox"
                                        model=".fallFromHeightHazard"
                                        defaultValue={instance && instance.props.fallFromHeightHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="heavyLoadsHazard">Heavy Loads</label>
                                    <RRFInput
                                        id="heavyLoadsHazard"
                                        type="checkbox"
                                        model=".heavyLoadsHazard"
                                        defaultValue={instance && instance.props.heavyLoadsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="excavationCollapseHazard">Excavation Collapse</label>
                                    <RRFInput
                                        id="excavationCollapseHazard"
                                        type="checkbox"
                                        model=".excavationCollapseHazard"
                                        defaultValue={instance && instance.props.excavationCollapseHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="movingMachinaryHazard">Moving Machinary</label>
                                    <RRFInput
                                        id="movingMachinaryHazard"
                                        type="checkbox"
                                        model=".movingMachinaryHazard"
                                        defaultValue={instance && instance.props.movingMachinaryHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="noiseHazard">Noise</label><br/>
                                    <RRFInput
                                        id="noiseHazard"
                                        type="checkbox"
                                        model=".noiseHazard"
                                        defaultValue={instance && instance.props.noiseHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="adjacentOperationHazard">Adjacent Operations</label>
                                    <RRFInput
                                        id="adjacentOperationHazard"
                                        type="checkbox"
                                        model=".adjacentOperationHazard"
                                        defaultValue={instance && instance.props.adjacentOperationHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="wildLifeHazard">Wild Life (reptiles, bears, etc)</label>
                                    <RRFInput
                                        id="wildLifeHazard"
                                        type="checkbox"
                                        model=".wildLifeHazard"
                                        defaultValue={instance && instance.props.wildLifeHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="suspendedLoadsHazard">Suspended Loads/Rigging</label>
                                    <RRFInput
                                        id="suspendedLoadsHazard"
                                        type="checkbox"
                                        model=".suspendedLoadsHazard"
                                        defaultValue={instance && instance.props.suspendedLoadsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="electricalHazard">Electrical</label>
                                    <RRFInput
                                        id="electricalHazard"
                                        type="checkbox"
                                        model=".electricalHazard"
                                        defaultValue={instance && instance.props.electricalHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="mobileEquipmentsHazard">Mobile Equipments</label>
                                    <RRFInput
                                        id="mobileEquipmentsHazard"
                                        type="checkbox"
                                        model=".mobileEquipmentsHazard"
                                        defaultValue={instance && instance.props.mobileEquipmentsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="insectsHazard">Insects (bees, ticks etc)</label>
                                    <RRFInput
                                        id="insectsHazard"
                                        type="checkbox"
                                        model=".insectsHazard"
                                        defaultValue={instance && instance.props.insectsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="ignitionSouresHazard">Ignition Sources</label>
                                    <RRFInput
                                        id="ignitionSouresHazard"
                                        type="checkbox"
                                        model=".ignitionSouresHazard"
                                        defaultValue={instance && instance.props.ignitionSouresHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="pinchPointsHazard">Pinch Points</label>
                                    <RRFInput
                                        id="pinchPointsHazard"
                                        type="checkbox"
                                        model=".pinchPointsHazard"
                                        defaultValue={instance && instance.props.pinchPointsHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="overExertionHazard">Over Exertion</label>
                                    <RRFInput
                                        id="overExertionHazard"
                                        type="checkbox"
                                        model=".overExertionHazard"
                                        defaultValue={instance && instance.props.overExertionHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="poisonHazard">Poison Oak, Ivy, Summac etc</label>
                                    <RRFInput
                                        id="poisonHazard"
                                        type="checkbox"
                                        model=".poisonHazard"
                                        defaultValue={instance && instance.props.poisonHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="loneWorkerHazard">Lone Worker</label>
                                    <RRFInput
                                        id="loneWorkerHazard"
                                        type="checkbox"
                                        model=".loneWorkerHazard"
                                        defaultValue={instance && instance.props.loneWorkerHazard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherHazard">Other Hazard</label>
                                    <RRFInput
                                        id="otherHazard"
                                        type="checkbox"
                                        model=".otherHazard"
                                        defaultValue={instance && instance.props.otherHazard}
                                    />
                                </Col>
                            </Col>
                            { forms.toolBoxMeetingForm.otherHazard==='1' && 
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherHazard">Specify</label>
                                    <RRFInput
                                        id="specifyOtherHazard"
                                        type="text"
                                        model=".specifyOtherHazard"
                                        defaultValue={instance && instance.props.specifyOtherHazard}
                                    />
                                </Col>
                            </Col> }
                            <Col sm={12} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="drivingHazard">Indicate driving hazards inside (weel heads, barriers, low hanging tree branches, tanks etc)</label>
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.drivingHazard} model={`forms.toolBoxMeetingForm.drivingHazard`} />  
                                    <RRFInput
                                        id="drivingHazard"
                                        type="text"
                                        model=".drivingHazard"
                                        defaultValue={instance && instance.props.drivingHazard}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}><legend className="legend-header" >Section 4: Additional Personal Protection </legend></Col> 
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={12} xs={12}>
                                <p className="yellow-color-title">Tick all required PPE ( Hard Hat, Safety Eye Glasses and Hard Toe Boots always required): </p>
                            </Col>
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">General PPE </p>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="faceShield">Face Shield</label>
                                    <RRFInput
                                        id="faceShield"
                                        type="checkbox"
                                        model=".faceShield"
                                        defaultValue={instance && instance.props.faceShield}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="frClothing">FR Clothing</label>
                                    <RRFInput
                                        id="frClothing"
                                        type="checkbox"
                                        model=".frClothing"
                                        defaultValue={instance && instance.props.frClothing}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="hearingProtection">Hearing Protection</label>
                                    <RRFInput
                                        id="hearingProtection"
                                        type="checkbox"
                                        model=".hearingProtection"
                                        defaultValue={instance && instance.props.hearingProtection}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="chainsawChaps">Chainsaw Chaps</label>
                                    <RRFInput
                                        id="chainsawChaps"
                                        type="checkbox"
                                        model=".chainsawChaps"
                                        defaultValue={instance && instance.props.chainsawChaps}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherGeneralPPE">Others</label>
                                    <RRFInput
                                        id="otherGeneralPPE"
                                        type="checkbox"
                                        model=".otherGeneralPPE"
                                        defaultValue={instance && instance.props.otherGeneralPPE}
                                    />
                                </Col>
                            </Col>
                            { forms.toolBoxMeetingForm.otherGeneralPPE==='1' && 
                            <Col sm={{span: '2', offset: '10' }} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherGeneralPPE">Specify:</label>
                                    <RRFInput
                                        id="specifyOtherGeneralPPE"
                                        type="text"
                                        model=".specifyOtherGeneralPPE"
                                        defaultValue={instance && instance.props.specifyOtherGeneralPPE}
                                    />
                                </Col>
                            </Col> }
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">Gloves </p>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="generalPurposeGloves">General Purpose</label>
                                    <RRFInput
                                        id="generalPurposeGloves"
                                        type="checkbox"
                                        model=".generalPurposeGloves"
                                        defaultValue={instance && instance.props.generalPurposeGloves}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="chemicalResistant">Chemical Resistant</label>
                                    <RRFInput
                                        id="chemicalResistant"
                                        type="checkbox"
                                        model=".chemicalResistant"
                                        defaultValue={instance && instance.props.chemicalResistant}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="heatResistant">Heat Resistant</label>
                                    <RRFInput
                                        id="heatResistant"
                                        type="checkbox"
                                        model=".heatResistant"
                                        defaultValue={instance && instance.props.heatResistant}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherGloves">Other</label>
                                    <RRFInput
                                        id="otherGloves"
                                        type="checkbox"
                                        model=".otherGloves"
                                        defaultValue={instance && instance.props.otherGloves}
                                    />
                                </Col>
                            </Col>
                            { forms.toolBoxMeetingForm.otherGloves==='1' && 
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherGlovesPPE">Specify:</label>
                                    <RRFInput
                                        id="specifyOtherGlovesPPE"
                                        type="text"
                                        model=".specifyOtherGlovesPPE"
                                        defaultValue={instance && instance.props.specifyOtherGlovesPPE}
                                    />
                                </Col>
                            </Col> }
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">Fall Arrrest</p>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="harness">Harness</label>
                                    <RRFInput
                                        id="harness"
                                        type="checkbox"
                                        model=".harness"
                                        defaultValue={instance && instance.props.harness}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="lanyard">Lanyard</label>
                                    <RRFInput
                                        id="lanyard"
                                        type="checkbox"
                                        model=".lanyard"
                                        defaultValue={instance && instance.props.lanyard}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="retrievalLine">Retrieval Line</label>
                                    <RRFInput
                                        id="retrievalLine"
                                        type="checkbox"
                                        model=".retrievalLine"
                                        defaultValue={instance && instance.props.retrievalLine}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherFallArrestPPE">Other</label>
                                    <RRFInput
                                        id="otherFallArrestPPE"
                                        type="checkbox"
                                        model=".otherFallArrestPPE"
                                        defaultValue={instance && instance.props.otherFallArrestPPE}
                                    />
                                </Col>
                            </Col>
                            { forms.toolBoxMeetingForm.otherFallArrestPPE==='1' && 
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherFallArrestPPE">Specify:</label>
                                    <RRFInput
                                        id="specifyOtherFallArrestPPE"
                                        type="text"
                                        model=".specifyOtherFallArrestPPE"
                                        defaultValue={instance && instance.props.specifyOtherFallArrestPPE}
                                    />
                                </Col>
                            </Col> }
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">Personal Monitors</p>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="fourGasMonitor">4-Gas monitor</label>
                                    <RRFInput
                                        id="fourGasMonitor"
                                        type="checkbox"
                                        model=".fourGasMonitor"
                                        defaultValue={instance && instance.props.fourGasMonitor}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="h2s">H2S</label>
                                    <RRFInput
                                        id="h2s"
                                        type="checkbox"
                                        model=".h2s"
                                        defaultValue={instance && instance.props.h2s}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="o2">O2</label>
                                    <RRFInput
                                        id="o2"
                                        type="checkbox"
                                        model=".o2"
                                        defaultValue={instance && instance.props.o2}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="lel">LEL</label>
                                    <RRFInput
                                        id="lel"
                                        type="checkbox"
                                        model=".lel"
                                        defaultValue={instance && instance.props.lel}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">Respirator Type</p>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="dustMask">Dust mask</label>
                                    <RRFInput
                                        id="dustMask"
                                        type="checkbox"
                                        model=".dustMask"
                                        defaultValue={instance && instance.props.dustMask}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="halfMaskAPR">1/2 mask APR</label>
                                    <RRFInput
                                        id="halfMaskAPR"
                                        type="checkbox"
                                        model=".halfMaskAPR"
                                        defaultValue={instance && instance.props.halfMaskAPR}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="scba">SCBA</label>
                                    <RRFInput
                                        id="scba"
                                        type="checkbox"
                                        model=".scba"
                                        defaultValue={instance && instance.props.scba}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="otherRespiratorType">Others</label>
                                    <RRFInput
                                        id="otherRespiratorType"
                                        type="checkbox"
                                        model=".otherRespiratorType"
                                        defaultValue={instance && instance.props.otherRespiratorType}
                                    />
                                </Col>
                            </Col> 
                            { forms.toolBoxMeetingForm.otherRespiratorType==='1' && 
                            <Col sm={2} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="specifyOtherRespiratorType">Specify</label>
                                    <RRFInput
                                        id="specifyOtherRespiratorType"
                                        type="text"
                                        model=".specifyOtherRespiratorType"
                                        defaultValue={instance && instance.props.specifyOtherRespiratorType}
                                    />
                                </Col>
                            </Col> }
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}><legend className="legend-header" >Section 5: TSM Completion </legend></Col> 
                        </Row>
                        <Row className={'show-grid'}>
                            <Col sm={2} xs={12}>
                                <p className="yellow-color-title">TSM Leader</p>
                            </Col>
                            <Col sm={4} xs={12} className='text-center'>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="TSMLeaderName">Name</label>
                                    <RRFInput
                                        id="TSMLeaderName"
                                        type="text"
                                        model=".TSMLeaderName"
                                        defaultValue={(instance && instance.props.TSMLeaderName) || (this.props.userInstance && this.props.userInstance.props.firstName) || ''}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12} className='text-center'>  
                                <label className="checkbox-label" htmlFor="TSMLeaderName">Signature </label>
                                <Col sm={12} xs={12} style={{ marginTop: 'unset'}} className="e-signature tsm-form"> 
                                    <Col sm={12} className="input">
                                        {this.state.tsmMemberSign ? this.props.userInstance &&
                                            <ManualSignature manualSignsm={12}
                                                autoSignTitle={`${this.props.userInstance && this.props.userInstance.props.firstName} ${this.props.userInstance && this.props.userInstance.props.lastName}`}
                                                saveSignature={this.saveSignature}
                                                saveGeneratedSignature={this.saveGeneratedSignature}
                                                className={'signature'}
                                                autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                            /> : this.renderSignatureField()}
                                    </Col> 
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}>
                                <Label sm={12}>List Employees conducting the task and participating in the TSM</Label> 
                            </Col> 
                        </Row>
                        <Row className="show-grid"> 
                            <Col sm={{span: '4', offset: '4'}} className="input">
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.emplyeesName`}
                                        type="dropdown"
                                        menuItems={this.state.groupMembers} 
                                        multi={true}
                                        model={`.emplyeesName`} 
                                        defaultValue={instance && instance.props.emplyeesName}
                                    />
                                </Col>
                            </Col> 
                        </Row> 
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: 'unset'}}>
                                <Label sm={12}>List of Contract employees conducting the task</Label> 
                            </Col> 
                        </Row>
                        <Row className="show-grid"> 
                            {this.renderContractEmployees()} 
                        </Row>
                        <div className="table-buttons">
                            <button onClick={() => this.addMoreContractEmployees() }
                                className="add-row w25"
                                type="button" >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Contract Employee
                            </button>
                        </div>
                        <Row className="show-grid">
                            <Col sm={12} xs={12} style={{textAlign: 'center' }}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input"> 
                                    <AttachementInput
                                        model=".attachements"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={instance && instance.props.attachmentUrls}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                        <Row>
                            <Col sm={12} xs={12}>
                                <Col sm={12} className="input">
                                    <label className="checkbox-label" htmlFor="overallComments">Overall Comments</label>
                                    <VoiceRecognition labelText="" defaultValue={forms.toolBoxMeetingForm.overallComments} model={`forms.toolBoxMeetingForm.overallComments`} />  
                                    <RRFInput
                                        id="overallComments"
                                        type="text"
                                        model=".overallComments"
                                        defaultValue={instance && instance.props.overallComments}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="tsm-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ instance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { instance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    }
    addMoreContractEmployees = () => { 
        this.setState({
            contractEmployeesDetails: [...this.state.contractEmployeesDetails, { name: '' }]
        });
    }
    decrementContractEmployees = (index) => {
        const { contractEmployeesDetails } = this.state;
        dispatch(actions.remove(`forms.toolBoxMeetingForm.contractEmplyeesName`, index));
        let newArray = contractEmployeesDetails.filter(e => e !== contractEmployeesDetails[index]);
        this.setState({
            contractEmployeesDetails: newArray
        });
    }
    renderContractEmployees = () => {
        const { contractEmployeesDetails } = this.state;
        return [contractEmployeesDetails.map((ruleData, index) => {
            return <> 
                <Col style={{ display: 'inline-block' }} xs={6} sm={{offset: 4,span: 4}} className="input">
                    <RRFInput
                        type="text"
                        model={`.contractEmplyeesName[${index}]`} 
                        defaultValue={ruleData.name}
                    />
                </Col>
                <Col style={{ padding: '10px 15px', marginTop: '15px', display: 'inline-block', color: 'white', cursor: 'pointer' }} xs={1} sm={1} className="input" onClick={() => this.decrementContractEmployees(index)} >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                </Col></> }) ]
    }
    saveSignature = (file: any, acceptorSignature: any) => { 
        this.setState({
            tsmMemberSign: false, isTsmSignatureUploaded: true, tsmSignature: file, tsmSignatureURL: acceptorSignature, 
        });  
        dispatch(actions.change(`forms.toolBoxMeetingForm.TSMLeaderSignature`, acceptorSignature)); 
    }

    saveGeneratedSignature = (file: any, base64: any) => { 
        this.setState({
            tsmMemberSign: false, isTsmSignatureUploaded: true, tsmSignature: file, tsmSignatureURL: base64,
        }); 
        dispatch(actions.change(`forms.toolBoxMeetingForm.TSMLeaderSignature`, base64));
    }
    renderSignatureField = () => {
        if (this.props.userInstance ) {
            return <Row className="show-grid" style={{ paddingBottom: '20px' }} > 
                <Col sm={12} style={{ color: 'green' }} className="signature">
                    <img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }}
                        src={this.state.tsmSignatureURL /* || (this.props.userInstance && this.props.userInstance.props.signatureUrl) */ } alt="SafeConnect"/>
                    <div>
                        <button
                            className="button"
                            style={newSign}
                            onClick={() => this.setState({ tsmMemberSign: true })}>
                            Sign New
                  </button>
                    </div>
                </Col>
            </ Row >;
        } 
        return '';
    }
   
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="ToolBoxMeetingForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) { 
    const userId = state.login.get('userId');  
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    let editInstance = '';
    if(editId) {
        editInstance = ToolBoxMeetingModel.get(editId)
    }
    return { instance: editInstance , userInstance, forms: state.forms, company, editId: editId };
} 
export const ToolBoxMeetingForm = withRouter(connect<IToolBoxMeetingFormProps, any, any>(mapStateToProps)(ToolBoxMeetingFormImpl));
const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};
