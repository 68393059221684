import * as React from 'react';
import { Async } from '../Async';
import { getBase64, compressBase64Image } from '../../../utils/generalUtils';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import { isImageLoading } from '../../../actions/miscellaneousActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import errorImage from '../../../images/errorImage.png'; 

export interface IImgProps {
    className?: string;
    src: string;
    width: string;
    height: string;
    style?: React.CSSProperties;
    onClick?: (imageURL: string) => void;
    tooltip?: string;
    key?: any;
}

export interface IImgState {
    imageSrc: string;
}

export class Img extends React.PureComponent<IImgProps, IImgState> {
    constructor(props: IImgProps | Readonly<IImgProps>) {
        super(props);
        this.state = { imageSrc: '' };
    }

    promise = async () => { 
        const imgData = await getBase64(this.props.src);
        const imageBase64 = 'data:image/png;base64,' + imgData;
        compressBase64Image(imageBase64, (compressedImage) => {
            isImageLoading(false);
            this.setState({
                imageSrc: compressedImage
            });
        });
        return null;
    }

    render() {
        const { props: { className, height, width, src, style, tooltip, key }, state: { imageSrc } } = this; 
        return <Async
            identifier="IMG"
            promise={this.promise}
            error={<img
                width={width}
                height={height}
                style={{ boxShadow: 'none', pointerEvents: 'none' }}
                src={errorImage} alt="SafeConnect"
            />}
            loader={<div
                style={{ width, height, textAlign: 'center', position: 'relative', top: '22%' }
                }>
                <MuiThemeProvider>
                    <CircularProgress color="#88A4CE" />
                </MuiThemeProvider>
            </div>}
            content={
                <div key={key || 'image'}
                    onClick={() => this.props.onClick && this.props.onClick(src)}
                    className="display-image-wrapper"
                >  
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={tooltip || 'tooltipImage'}> <strong>{tooltip}</strong> </Tooltip> } >
                    <img width={width} style={style} height={height} className={className} src={imageSrc} alt={tooltip} />
                </OverlayTrigger>
                </div>
            }
        />;
    }
}
