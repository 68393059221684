import * as React from 'react';
import { connect } from 'react-redux'; 
import './fileInput.scss';  
import { Col } from 'react-bootstrap';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { getProjectsOfUser } from '../../../services/userService';
import { IForms } from '../../../interfaces'; 
import { UserModel } from '../../../model/UserModel';

export interface IProjectInputProps { 
    userId: string;
    model?: string;
    id?: string;
    defaultValue?: string;
    forms: IForms;
    isDisabled?: boolean;
    userInstance?: any;
    onTeamChange?: (value: any) => void;
}

export interface IProjectInputState { 
}

export class ProjectInputImpl extends React.PureComponent<IProjectInputProps, IProjectInputState> {
    constructor(props: IProjectInputProps | Readonly<IProjectInputProps>) {
        super(props); 
    }

    componentWillMount() { 
    } 

    handleInputChange = async (value: any) => { 
        const { props: { onTeamChange } } = this;
        if(onTeamChange)
            return await onTeamChange(value);
    }
    render() {
        const { model, id, defaultValue, userInstance } = this.props; 
        return (
            <div><Label required sm={12} htmlFor="team">Team:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id={id}
                        type="dropdown"
                        menuItems={getProjectsOfUser(this.props.userId, userInstance)}
                        defaultValue={defaultValue}
                        model={model}
                        isDisabled={this.props.isDisabled || false} 
                        onSelect={this.handleInputChange}
                    />
                </Col></div>
        );
    }
}

export function mapStateToProps( state: any ) { 
    const userId = state.login.get('userId'); 
    return { forms: state.forms, userId, userInstance: UserModel.get(userId) || UserModel.getUserByUID(userId) };
}

export const ProjectInput = connect<IProjectInputProps, any, any>(mapStateToProps)(ProjectInputImpl);
 