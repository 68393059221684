import * as React from 'react';
import { Col } from 'react-bootstrap';

export interface IImageCardContainerProps {
    href: string;
    src: string;
    index: number;
    activeIndex: number;
    text: string;
    onMouseOver: any;
    onMouseLeave: any;
    addEditClass: boolean;
    onSingleFileDelete: Function;
    selectedIndexes: number[];
    onFileSelect: Function;
    onFileDeselect: Function;
    isDeletable: boolean;
}

export class ImageCardContainer extends React.PureComponent<IImageCardContainerProps, {}> {
    constructor(props: IImageCardContainerProps) {
        super(props);
    }

    render() {
        const { href, src, index, activeIndex, text, onMouseOver,
            onMouseLeave, addEditClass, onSingleFileDelete, selectedIndexes,
            onFileSelect, onFileDeselect, isDeletable
        } = this.props;

        return <div
            className="card-container col-sm-6"
            onMouseOver={onMouseOver as any}
            onTouchStart={onMouseOver}
            onTouchEnd={onMouseLeave}
            onMouseLeave={onMouseLeave as any}
        >
            <Col className="card" sm={11} xs={11}>
                {(addEditClass && index === activeIndex) || selectedIndexes.length > 0 ?
                    <div className="show-edit-options">
                        {selectedIndexes.length <= 0 ?
                            [<a
                                key={index + 11}
                                href={href}
                                target="_blank"
                                title="Click to view this file"
                                className="fa fa-file"
                                aria-hidden="true" rel="noreferrer"
                            >
                                <span>View File</span>
                            </a>,
                            <a href="javascript:;"
                                key={index + 121}
                                onClick={() => onSingleFileDelete(index)}
                                title="Click to delete this file" className="fa fa-trash" aria-hidden="true"></a>] : ''}
                        <br />
                        {!isDeletable? selectedIndexes.length > 0 && selectedIndexes.indexOf(index) >= 0 ?
                            <i
                                title="Click to remove this file from selected items."
                                onClick={() => onFileDeselect(index)}
                                className="fa fa-check-square-o"
                                aria-hidden="true"></i> :
                            <i
                                title="Click to select Multiple Files"
                                onClick={() => onFileSelect(index)}
                                className="fa fa-square-o"
                                aria-hidden="true"
                            ></i> : ''
                        }
                    </div> : ''}

                <a href={href} target="_blank" rel="noreferrer">
                    <div>
                        <img src={src} alt="SafeConnect"/>
                    </div>
                    <div className="reference-text" >
                        {text}
                    </div>
                </a>
            </Col>
        </div>;
    }

}
