import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as React from 'react';

export interface ISubmissionLoaderProps {
    loaderSize?: number;
}

export class SubmissionLoader extends React.PureComponent<ISubmissionLoaderProps, {}> {
    constructor(props: ISubmissionLoaderProps | Readonly<ISubmissionLoaderProps>) {
        super(props);
    }

    static defaultProps = {
        loaderSize: 100
    };

    render() {
        return (
            <MuiThemeProvider>
                <div style={loaderStyle}>
                    <CircularProgress
                        style={{ top: '50%', transform: 'translateY(-75%)' }}
                        size={this.props.loaderSize}
                        color="#88A4CE"
                    />
                </div>
            </MuiThemeProvider>
        );
    }
}

const loaderStyle: React.CSSProperties = {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 100,
    top: '0',
    left: '0',
    background: 'rgba(255, 255, 255, 0.7)',
};
