import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import { IHistory, ISelectOptions } from '../../../interfaces';
import { dispatch, isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import './forgotPasswordForm.scss';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { actions, Form } from 'react-redux-form';
import PropTypes from 'prop-types';
import { Loader } from '../../reusableComponents/Loader'; 
import { EMPTY_FORM_MESSAGE } from '../../../constants/general'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../../model/UserModel';
import { resetPassword } from '../../../services/loginService';
import { ErrorMessageLabel } from '../../reusableComponents/ErrorMessageLabel/ErrorMessageLabel';
import logoURL from '../../../images/SafeLogo.png';

export interface IForgotPasswordFormImplProps {
    history: IHistory;
    isLoggedIn: boolean;
    alertType: string;
    pathName: string;
}

export interface IForgotPasswordFormImplState {
    isSignUpPage: boolean;
    errorMessage: string;
    email: string;
    isValidUser: boolean;
    menuItems?: ISelectOptions[];
    popOver: boolean; 
}

export class ForgotPasswordFormImpl extends React.PureComponent<IForgotPasswordFormImplProps, IForgotPasswordFormImplState> {

    forgotPasswordIdentifier: string = 'forgot-password';  
    constructor(props: IForgotPasswordFormImplProps | Readonly<IForgotPasswordFormImplProps>) {
        super(props);
        this.state = { email: '', isValidUser: false, errorMessage: '', isSignUpPage: false, popOver: false };
    }

    promise = async () => {
        return null;
    }

    /**
     * To set the initial state of the loader in the login button.
     */
    componentWillMount() { 
        setSuccess(this.forgotPasswordIdentifier); 
        if (!this.props.isLoggedIn) {
            dispatch(actions.reset('forms.forgotPasswordForm'));
            return;
        }
        this.props.history.push('/home');
    }
    handleStrongPassword = (e: any) => {  
        //eslint-disable-next-line
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
        if (e.target.value.length <= 0) {
            this.setState({ popOver: false });
            return;
        }
        if (!strongRegex.test(e.target.value)) {
            this.setState({ popOver: true });
            return;
        }
        this.setState({ popOver: false });

    }
    renderForgotPasswordForm = () => {
        const { handleForgotPassword } = this;
        return (
            <Form model="forms.forgotPasswordForm" className="login" onSubmit={handleForgotPassword}>
                <div className="title">
                    <img src={logoURL} style={{ width: '45%', height: '100%' }} alt="SafeConnect"/>
                </div>
                <div className="page-title"> 
                    Reset Password
                </div>
                <Alert className={`forgot-password-alert`} id="forgot-password-error" />
                <AlertModal id="forgot-password-form-modal" />
                <div className="input-field">
                    <InputField className="tempPassword"
                        placeholder="Temporary Password" 
                        type="password"
                        model=".tempPassword"
                    />
                    <InputField className="newPassword"
                        placeholder="New Password" 
                        type="password"
                        model=".newPassword"
                        onChange={(e: any) => this.handleStrongPassword(e)}
                    />
                    <ErrorMessageLabel isActive={this.state.popOver} 
                       errorMessage="* New Password should have atleast one Uppercase, 
                       one Lowercase, one numeral, 
                       one special symbol and minimum 8 characters"/>
                    <InputField className="confirmPassword"
                        placeholder="Confirm Password" 
                        type="password"
                        model=".confirmPassword"
                    />
                </div>
                <Async 
                    loader={<span className="button-loading">
                        <MuiThemeProvider>
                            <CircularProgress color="#FFFFFF" size={30} />
                        </MuiThemeProvider>
                    </span>}
                    identifier={this.forgotPasswordIdentifier}
                    content={<button style={
                        !this.state.isValidUser ? {
                            width: '150px',
                            height: '40px',
                        } : {}
                    } className="button" 
                    disabled={this.state.popOver}>Update</button>}
                /> 
            </Form>
        );
    }  
    getChildContext() { 
        return { formModel: 'forms.forgotPasswordForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    /**
     * Submits the form to the sever. Collects the data from the username field and password field.
     */
    handleForgotPassword = async ({ tempPassword, newPassword, confirmPassword }: any) => { 
        const esubmittedValues = { tempPassword, newPassword, confirmPassword}
        if (isFormEmpty(esubmittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'forgot-password-error', 'danger');
            return;
        } 
        if(newPassword!==confirmPassword) {
            showAlert('Confirm password not matched.', 'forgot-password-error', 'danger');
            return;
        }
        setLoading(this.forgotPasswordIdentifier); 
        const { props: { history } } = this;
        const requestData = {
            oldPassword: tempPassword, 
            newPassword: newPassword, 
            userUID: UserModel.getUserUID(),
            company: UserModel.getCompanyName(),
        };
        
        await resetPassword(requestData, history); 
    } 
    
    renderContent = () => { 
        const { props: { history } } = this;
        if(!UserModel) {
            history.push('login');
        }

        return (
            <div className={`login-form`}>
                {
                    this.renderForgotPasswordForm()
                }
            </div>
        );
    }

    render() { 
        return <div>
            <Async
                identifier="ForgotPasswordForm"
                promise={this.promise}
                error={<div></div>}
                loader={<Loader size={50} />}
                content={this.renderContent()}
            />
        </div>;
    }
}

/**
 * To be used for fetching the login state of the user.
 * @param login A Map of the immutable Object.
 */
export function mapStateToProps(state: any, ownProps: any) {
    let pathName = '/home';
    if (ownProps.location.search) {
        pathName = ownProps.location.search.split('=')[1];
    }
    return {
        isLoggedIn: state.login.get('isLoggedIn'),
        alertType: state.alert.get('type'),
        pathName
    };
}
export const ForgotPasswordForm = withRouter(connect(mapStateToProps)(ForgotPasswordFormImpl));

export const error: React.CSSProperties = {
    position: 'relative',
    top: '39%',
    width: '100%',
    textAlign: 'center',
    color: 'red'
};

const InputField = ({ className, placeholder, type, model, ...props }: any) => {
    return <RRFInput
        className={className}
        placeholder={placeholder}
        onChange={props.onChange}
        type={type}
        model={model}
    />;
};
