import '../../forms.scss'; 
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Accordion, Col, Container as Grid, Row, Card, Table, DropdownButton, Dropdown } from 'react-bootstrap'; 
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';

import './riskAssessment.scss';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IForms, IHistory } from '../../interfaces';  
import { UserModel } from '../../model/UserModel';   
import { constructAnswers, dispatch, isFormEmpty, showAlert, isArrayEmpty, backToTop, getEventCategory, getProbabilityCategory, ssriResultTable, uuid, eventCategorization, probabilityCategorization, convertToDateFormat } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title'; 

import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { ProjectModel } from '../../model/ProjectModel';
import { getAllProjects } from '../../services/projectService';
import { GroupModel } from '../../model/GroupModel';
import { getAllGroups } from '../../services/groupService';
import { hideCloseCallFollowUpListModal,showCreateCloseCallFollowUpModal } from '../../actions/modalActions';
import { RiskAssessmentModel, IRiskAssessmentModelProps} from '../../model/RiskAssessmentModel';
import { submitRiskAssessmentForm, getRiskAssessmentForm } from '../../services/riskAssessmentService';
import { getAllFollowUpForms } from '../../services/closeCallService'; 
import { followUpListProps } from '../../model/MechModel';  
import { showDelConfirmModal } from '../../actions/modalActions';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';  
import { getModalReportDataList } from '../../actions/reportActions';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';
const MenuItem = Dropdown.Item;

export interface IRiskAssessmentFormProps {
    userInstance: UserModel;
    userUID: string;
    userId: string; 
    company: string;
    history?: IHistory; 
    forms: IForms; 
    instance?: RiskAssessmentModel;
    editId?: string;
    editInstance?: RiskAssessmentModel; 
    followUpInstances?: followUpListProps[]; 
    associatedId?: string;
    assosiatedIdInstance?: RiskAssessmentModel; 
}

export interface IRiskAssessmentFormState {
    currentDate: string;
    currentTime: string; 
    hint1AccordianState: boolean;  
    hint2AccordianState: boolean;  
    hint3AccordianState: boolean; 
    followupAccordianState: boolean; riskAssessmentFollowupAccordianState: boolean;
    eventStep: number[];
    
    averageEventCategory: number; averageProbabilityCategory: number;
    SSRI: number;
    isApproved?: boolean; isAttested?: boolean;
    reassessmentOption?: string; finalAssessment?: any;
    followUpTempUUID: string;
 
}

export class RiskAssessmentFormImpl extends React.PureComponent<IRiskAssessmentFormProps, IRiskAssessmentFormState> {
 
    constructor(props: IRiskAssessmentFormProps | Readonly<IRiskAssessmentFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'), currentTime: format(new Date(), 'HH:mm'), hint1AccordianState: false, hint2AccordianState: false, hint3AccordianState: false, followupAccordianState: false, riskAssessmentFollowupAccordianState: false, eventStep: [1], SSRI: 0,  isApproved: false, isAttested: false, reassessmentOption: '',
            averageEventCategory: 0, averageProbabilityCategory: 0, followUpTempUUID: uuid()
        };
    }
    componentWillMount() { 
        let reportDataList = [null];
        dispatch(getModalReportDataList(reportDataList));  
        //this.renderScores();
    } 
    promise = async () => { 
        const { editId, editInstance } = this.props;
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } 
        if(editId && !editInstance) {
            await getRiskAssessmentForm(editId);
            let editingData = RiskAssessmentModel.get(editId);
            this.renderEditData(editingData);
        }
        if(editInstance) { 
            this.renderEditData(editInstance); 
        } 
        setTimeout(() =>{ this.renderScores(); }, 1000); 
        return;
    } 
    async componentWillReceiveProps(nextProps: any) { 
        const { editInstance } = this.props; 
        if(this.props.associatedId !== nextProps.associatedId  ) { //For add follow_up risk re Assessment 
            backToTop();
            this.resetState();
            await getAllFollowUpForms(nextProps.associatedId);
            let assosiatedIdInstance = RiskAssessmentModel.get(nextProps.associatedId);  
            if(assosiatedIdInstance) {
                this.eventTableRows = assosiatedIdInstance.props.eventDescription.length; 
                let eventStep: Array<any> = [];
                for(let i = 0; i<assosiatedIdInstance.props.eventDescription.length; i++) {
                    eventStep.push(i+1);
                    dispatch(actions.change(`forms.riskAssessmentForm.eventScore[${i}]`, '')); 
                    dispatch(actions.change(`forms.riskAssessmentForm.eventCategory[${i}]`, '')); 
                    dispatch(actions.change(`forms.riskAssessmentForm.probabilityCategory[${i}]`, '')); 
                }
                await this.renderEventAssessment(); 
                dispatch(actions.change(`forms.riskAssessmentForm.reassessmentOption`, ''));  
                this.setState({ eventStep: eventStep }); 
            } 
        }
        if(this.props.editId !== nextProps.editId  ) {  //For edit follow_up risk re Assessment form  
            setTimeout(() =>{ this.renderScores(); }, 1000); 
            backToTop(); 
            if(editInstance) { 
                let editingData = RiskAssessmentModel.get(nextProps.editId);
                this.renderEditData(editingData);
                setTimeout(() =>{ this.renderScores(); }, 1000); 
            }
        } 
    }
    renderEditData = async (editInstance: RiskAssessmentModel) => { 
        if(editInstance) {
            this.resetState();
            await getAllFollowUpForms(editInstance.props.id);
            this.eventTableRows = editInstance.props.eventDescription.length; 
            let eventStep: Array<any> = [];
            for(let i = 0; i<editInstance.props.eventDescription.length; i++) {
                eventStep.push(i+1);
            }
            await this.renderEventAssessment(); 
            this.setState({ eventStep: eventStep, });  
            if( editInstance.props.associatedChildForms && editInstance.props.associatedChildForms.length>0 )  {
                let finalAssessment = editInstance.props.associatedChildForms[editInstance.props.associatedChildForms.length-1];
                this.setState({finalAssessment: finalAssessment}); 
            }    
            this.setState({ isApproved: editInstance.props.isApproved?true:false, isAttested: editInstance.props.isAttested?true:false, reassessmentOption: editInstance.props.reassessmentOption }); 
        }
    }
    getChildContext() {
        return { formModel: 'forms.riskAssessmentForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    handleFormSubmit = async ({dateOfIncident, timeOfIncident, dateOfAssessment, typeOfWork, workToPerform, location, locationName, projectName, groupName, eventDescription, eventScore, reassessmentOption, isApproved, isAttested, finalAuthorityComments }: IRiskAssessmentModelProps) => { 
        const {  averageEventCategory, averageProbabilityCategory, SSRI} = this.state;
        const { forms, editId, editInstance, history, userUID, associatedId } = this.props; 
        let validateData: any = {
            dateOfAssessment, typeOfWork, workToPerform, locationName, projectName, groupName, userUID, dateOfIncident, timeOfIncident
        };
        if (isFormEmpty(validateData)) {
            showAlert(EMPTY_FORM_MESSAGE, 'risk-assessment-form-failed', 'danger');
            return;
        }
        if(associatedId) {  
            validateData['associatedFormId'] = associatedId;
        } 
        if(editInstance && editInstance.props.associatedForms && editInstance.props.associatedForms.length>0) {
            validateData['associatedFormId'] = editInstance.props.associatedForms[0];
        }  
        validateData['followUpTempUUID'] = this.state.followUpTempUUID; 
        if(isArrayEmpty(forms.riskAssessmentForm.eventDescription)) {
            showAlert('Please fill all event description', 'risk-assessment-form-failed', 'danger');
            return; 
        }
        if(isArrayEmpty(forms.riskAssessmentForm.eventCategory)) {
            showAlert('Please fill all event category', 'risk-assessment-form-failed', 'danger');
            return; 
        } 
        if(isArrayEmpty(forms.riskAssessmentForm.probabilityCategory)) {
            showAlert('Please fill all probability category', 'risk-assessment-form-failed', 'danger');
            return; 
        } 
        if(!reassessmentOption) {
            showAlert('Please choose reassessment detail', 'risk-assessment-form-failed', 'danger');
            return;
        } 
        let answerData: any = { reportDate: dateOfAssessment, reportTime: timeOfIncident, location, locationName, typeOfWork, workToPerform };
       
        let answers = constructAnswers(answerData); 

        answers.push({
            'quesType': 'ssri',
            'quesNum': answers.length + 1,
            'answer': SSRI.toString(),
        }); 
        answers.push({
            'quesType': 'eventSteps',
            'quesNum': answers.length+1,
            'answer': this.eventTableRows.toString()
        });
        answers.push({
            'quesType': 'averageEventCategory',
            'quesNum': answers.length+1,
            'answer': averageEventCategory.toString()
        });
        answers.push({
            'quesType': 'averageProbabilityCategory',
            'quesNum': answers.length+1,
            'answer': averageProbabilityCategory.toString()
        });
        
        for (let i = 0; i < this.eventTableRows; i++) { 
            answers.push({
                'quesType': 'eventDescription',
                'quesNum': answers.length + 1,
                'answer': forms.riskAssessmentForm.eventDescription[i] || '',
            });
            answers.push({
                'quesType': 'eventCategory',
                'quesNum': answers.length + 1,
                'answer': forms.riskAssessmentForm.eventCategory[i] || '',
            });
            answers.push({
                'quesType': 'probabilityCategory',
                'quesNum': answers.length + 1,
                'answer': forms.riskAssessmentForm.probabilityCategory[i] || '',
            });
        }  
        answers.push({
            'quesType': 'reassessmentOption',
            'quesNum': answers.length + 1,
            'answer': forms.riskAssessmentForm.reassessmentOption || 'continueWork',
        }); 
        answers.push({
            'quesType': 'createdById',
            'quesNum': answers.length + 1,
            'answer':  editInstance?.props.createdById || userUID,
        }); 
        answers.push({
            'quesType': 'isApproved',
            'quesNum': answers.length + 1,
            'answer': editInstance?.props.isApproved || (isApproved?'1':'0'),
        });  
        let approvedBy = '';
        if(this.state.isApproved) { 
            approvedBy = editInstance?.props.approvedById || userUID;
        } 
        answers.push({
            'quesType': 'approvedById',
            'quesNum': answers.length + 1,
            'answer': approvedBy,
        }); 
        answers.push({
            'quesType': 'isAttested',
            'quesNum': answers.length + 1,
            'answer': editInstance?.props.isAttested || (isAttested?'1':'0'),
        });  
        let attestedBy = '';
        if(this.state.isAttested) { 
            attestedBy = editInstance?.props.attestedById || userUID; 
        } 
        answers.push({
            'quesType': 'attestedById',
            'quesNum': answers.length + 1,
            'answer': attestedBy,
        }); 
        answers.push({
            'quesType': 'finalAuthorityComments',
            'quesNum': answers.length + 1,
            'answer': finalAuthorityComments || '', 
        }); 
        let finalResult: number = 0;
        let finalSSRIResult: number = SSRI;
        /* 
            3/4, Continu, 2nd sign -> 1  ``
            1/2, Reassessment(3/4), 2nd sign -> 2 ``  
            1/2, Reassessment(1/2), 3rd sign ->3 ``
            1/2, Continu, 3rd sign -> 4 ``
        */
       let finalAssessment = this.state.finalAssessment || false; 
        if( (SSRI===3 || SSRI===4) && reassessmentOption==='continueWork' && isApproved) {
            finalResult = 1; 
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && ( finalAssessment && ( finalAssessment.ssri===3 || finalAssessment.ssri===4 ) && isApproved) ) {
            finalResult = 2; finalSSRIResult = finalAssessment.ssri;
        } else if( (SSRI===1 || SSRI===2) && (finalAssessment && ( finalAssessment.ssri===1 || finalAssessment.ssri===2 ) &&  isAttested ) ) {
            finalResult = 3; finalSSRIResult = finalAssessment.ssri;
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && isAttested) {
            finalResult = 4;
        } 
        //return false;
        answers.push({
            'quesType': 'finalResult',
            'quesNum': answers.length + 1,
            'answer': finalResult.toString(), 
        }); 
        validateData['finalResult'] = finalResult; 
        let mailTo = '';
        let reportType = 'preliminary'; 
        if( (SSRI===3 || SSRI===4) && reassessmentOption==='continueWork' && !isApproved) {
            mailTo = 'manager';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && ( finalAssessment && ( finalAssessment.ssri===3 || finalAssessment.ssri===4 ) && !isApproved) ) {
            mailTo = 'manager';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && (finalAssessment && ( finalAssessment.ssri===1 || finalAssessment.ssri===2 ) && !isApproved ) ) {
            mailTo = 'manager';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && (finalAssessment && ( finalAssessment.ssri===1 || finalAssessment.ssri===2 ) && !isAttested ) ) {
            mailTo = 'finalAuthority';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && (finalAssessment && ( finalAssessment.ssri===3 || finalAssessment.ssri===4 ) && !isApproved ) ) {
            mailTo = 'manager';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && !finalAssessment && !isApproved ) {
            mailTo = 'manager';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && !finalAssessment && !isAttested ) {
            mailTo = 'finalAuthority';
            reportType = 'approval';
        } else if( (SSRI===1 || SSRI===2) && reassessmentOption==='continueWork' && (finalAssessment &&  ( isApproved ) ) ) {
            mailTo = 'createdUser';
            reportType = 'final';
        } else if( (SSRI===1 || SSRI===2) && isApproved && isAttested) {
            mailTo = 'createdUser';
            reportType = 'final';
        } else if((SSRI===3 || SSRI===4) && isApproved) {
            mailTo = 'createdUser';
            reportType = 'final';
        }
        validateData['mailTo'] = mailTo;
        validateData['dateOfAssessment'] = dateOfAssessment && convertToDateFormat(dateOfAssessment);
        validateData['workToPerform'] = workToPerform; 
        validateData['finalSSRIResult'] = finalSSRIResult;
        let averageEventCategoryRes = getEventCategory( (averageEventCategory))[1];
        let averageProbabilityCategoryRes = getProbabilityCategory( (averageProbabilityCategory))[2];
        answers.push({ 'quesType': 'averageEventCategoryResult', 'quesNum': answers.length + 1, 'answer': averageEventCategoryRes.toString(), }); 
        answers.push({ 'quesType': 'averageProbabilityCategoryResult', 'quesNum': answers.length + 1, 'answer': averageProbabilityCategoryRes.toString(), }); 
        answers.push({ 'quesType': 'reportType', 'quesNum': answers.length + 1, 'answer': reportType, }); 
        return history && submitRiskAssessmentForm(validateData, answers, history, forms, editId);  
    } 
    accordianProcess = (type: string) => {
        switch(type) {
            case '1': this.setState({hint1AccordianState: !this.state.hint1AccordianState}); break;
            case '2': this.setState({hint2AccordianState: !this.state.hint2AccordianState}); break;
            case '3': this.setState({hint3AccordianState: !this.state.hint3AccordianState}); break;
            case '4': this.setState({followupAccordianState: !this.state.followupAccordianState}); break;
            case '5': this.setState({riskAssessmentFollowupAccordianState: !this.state.riskAssessmentFollowupAccordianState}); break;
        }
    }
    eventTableRows = 1;
    incrementEventRows = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        dispatch(actions.push(`forms.riskAssessmentForm.eventDescription`, ''));
        dispatch(actions.push(`forms.riskAssessmentForm.eventCategory`, '')); 
        dispatch(actions.push(`forms.riskAssessmentForm.probabilityCategory`, '')); 
        this.setState({
            eventStep: [...this.state.eventStep, ++this.eventTableRows]
        }); 
    }
    decrementEventRows = async (indexVal: number) => { 
        --this.eventTableRows; 
        await dispatch(actions.remove(`forms.riskAssessmentForm.eventDescription`, indexVal));
        dispatch(actions.push(`forms.riskAssessmentForm.eventCategory`, indexVal)); 
        dispatch(actions.push(`forms.riskAssessmentForm.probabilityCategory`, indexVal)); 
        this.setState({
            eventStep: this.state.eventStep.slice(0, - 1)
        });
        await this.renderScores();
    }
    changeCategory = (e: any) => {  
        this.renderScores();  
        //setTimeout(() =>{ this.renderScores(); }, 200); 
    }
    
    eventAssessmentHtml = (index: number = 1, answer?: any ) => {
        const { editInstance, assosiatedIdInstance, forms } = this.props;  
        let defaultValue = ( forms.riskAssessmentForm.eventDescription[index] ) || ( editInstance && editInstance.props.eventDescription[index] ) || ( assosiatedIdInstance && assosiatedIdInstance.props.eventDescription[index] )  || '';
        return (<>
            <Row className="show-grid" key={index}>
                <Col sm={1} className="trash">
                    <i className="fa fa-lg fa-trash" onClick={() => this.decrementEventRows(index)}
                    aria-hidden="true" > </i>  
                </Col > 
                <Col sm={1}> 
                    <span style={{ textAlign: 'center',color: '#fff' }}>{index+1}</span>
                </Col>
                <Col sm={6} > 
                    <VoiceRecognition labelText="" defaultValue={defaultValue} model={`forms.riskAssessmentForm.eventDescription[${index}]`} />
                    <RRFInput
                        type="text"
                        className="form-control hazards-risk"
                        placeholder="Enter event assessment description"
                        model={`.eventDescription[${index}]`} 
                        defaultValue={defaultValue }
                    />
                </Col>
                <Col sm={2}> 
                    {this.renderEventCategory(index)} 
                </Col>
                <Col sm={2}> 
                    {this.renderProbabilityCategory(index)} 
                </Col> 
            </Row> 
             </>
        )
    }
    renderEventCategory = (index: any) => { 
        const { editInstance } = this.props;  
        return (
            <RRFInput
                type="dropdown" 
                model={`.eventCategory[${index}]`} 
                menuItems={[{ value: '1', label: 'I' },{ value: '2', label: 'II' },
                { value: '3', label: 'III' }, { value: '4', label: 'IV' }]}
                onSelect={this.changeCategory} 
                defaultValue={editInstance && editInstance.props.eventCategory[index]}
            />
        );
    } 
    renderProbabilityCategory = (index: any) => { 
        const { editInstance } = this.props;  
        return (
            <RRFInput
                type="dropdown" 
                model={`.probabilityCategory[${index}]`} 
                menuItems={[{ value: 'A', label: 'A' },{ value: 'B', label: 'B' },
                { value: 'C', label: 'C' }, { value: 'D', label: 'D' }, { value: 'E', label: 'E' }]}
                onSelect={this.changeCategory} 
                defaultValue={editInstance && editInstance.props.probabilityCategory[index]}
            />
        );
    } 
    renderEventAssessment = () => {
        let eventStep = this.state.eventStep; 
        return eventStep.map((a, index) => {  
            return this.eventAssessmentHtml(index); 
        });
    } 
    renderScores = () => {  
        const { riskAssessmentForm } = this.props.forms; 
        let eventCategories = riskAssessmentForm.eventCategory;
        let probabilityCategories = riskAssessmentForm.probabilityCategory;
        let totalEventCategory: number = 0; 
        let totalProbabilityCategory: number = 0; 
        for(let i = 0; i<eventCategories.length;i++) { 
            let eventCategory = parseInt(eventCategories[i]) || 0; 
            let probabilityCategory = probabilityCategories[i] ? getProbabilityCategory(probabilityCategories[i])[0]: 0; 
            //let eventCategory: string = renderEventScoreCategory(eventScore);   
            totalEventCategory += eventCategory; 
            totalProbabilityCategory += probabilityCategory; 
        }
        let eventRes = totalEventCategory>0? Math.round(totalEventCategory/eventCategories.length):0;
        let probabilityRes = totalProbabilityCategory>0? Math.round(totalProbabilityCategory/eventCategories.length):0;

        let ssri = 0;
        if( (eventRes>0 && eventRes<5 ) || (probabilityRes>0 && probabilityRes<6) ) {
            if( (eventRes===1 && (probabilityRes===1 || probabilityRes===2 || probabilityRes===3 )) || (eventRes===2 && (probabilityRes===1 || probabilityRes===2) ) || (eventRes===3 && probabilityRes===1)  ) {
                ssri = 1;
            } else if( (eventRes===1 && probabilityRes===4 ) || (eventRes===2 && (probabilityRes===3 || probabilityRes===4) ) || (eventRes===3 && (probabilityRes===2 || probabilityRes===3) )  ) {
                ssri = 2;
            } else if( (eventRes===1 && probabilityRes===5) || (eventRes===2 && probabilityRes===5) || (eventRes===3 && (probabilityRes===4 || probabilityRes===5) ) || (eventRes===4 && (probabilityRes===2 || probabilityRes===1)  )  ) {
                ssri = 3;
            } else if((eventRes===4 && (probabilityRes===3 || probabilityRes===4 || probabilityRes===5 ))) {
                ssri = 4;
            }
            this.setState({averageEventCategory: eventRes, averageProbabilityCategory: probabilityRes, SSRI: ssri});
        } else {
            this.setState({averageEventCategory: 0, averageProbabilityCategory: 0, SSRI: 0});
        } 
    }  
    reassessmentOptionChange = (item: any) => {  
        const { editInstance } = this.props; 
        this.setState({reassessmentOption: item}); 
        if(editInstance && editInstance.props.associatedChildForms && editInstance.props.associatedChildForms.length>0)  {
            let finalAssessment = editInstance.props.associatedChildForms[editInstance.props.associatedChildForms.length-1];
            this.setState({finalAssessment: finalAssessment}); 
        } 
        /* if(item=='continueWork') { 
            if( (editInstance?.props.totalFollowUp || 0) - (editInstance?.props.totalClosedFollowUp || 0)>0 ) { 
                showAlert('Please close all follow_ups for continue to approval', 'reassessment-option-form-failed', 'danger'); 
                dispatch(actions.push(`forms.riskAssessmentForm.reassessmentOption`, '')); 
                return;
            }
        } */
    }
    renderEventCategorizationHint = () => {
        let eventCategory = eventCategorization();  
        return  Object.entries(eventCategory).map(([key,value],i) => <><tr> <td>{eventCategory[key][0]}</td> <td>{eventCategory[key][1]}</td> </tr> </>) 
    }
    renderProbabilityCategorizationHint = () => {
        let probabilityCategory = probabilityCategorization();  
        return  Object.entries(probabilityCategory).map(([key,value],i) => <><tr> <td>{probabilityCategory[key][0]}</td> <td>{probabilityCategory[key][1]}</td> </tr> </>) 
    }
    backToParantAssessment = async (associatedForm: any) => {  
        const { history } = this.props;
        await getRiskAssessmentForm(associatedForm);
        history?.push(`/risk-assessment/edit/${associatedForm}`)
        return;
    }
    renderContent = () => { 
        const { editId, history, forms, associatedId, assosiatedIdInstance } = this.props;
        const { handleFormSubmit, 
            state: { currentDate, },
            props: { editInstance } } = this;  
        if( ( (editId && !editInstance) || (associatedId && !assosiatedIdInstance) ) && history) {
            //history.push('/risk-assessment-reports'); 
            return <> </>;
        }
        let associatedForm = editInstance?.props.associatedForms && editInstance?.props.associatedForms.length>0 ?editInstance.props.associatedForms[0]:null; 
        let typeOfWork = forms.riskAssessmentForm.typeOfWork || (editInstance && editInstance.props.typeOfWork) || (assosiatedIdInstance && assosiatedIdInstance.props.typeOfWork) || '';
        let workToPerform = forms.riskAssessmentForm.workToPerform || (editInstance && editInstance.props.workToPerform) || (assosiatedIdInstance && assosiatedIdInstance.props.workToPerform) || '';
        return (
            <Grid className="oi-form risk-assessment" style={{ width: '100%' }}> 
                <Form loader={<SubmissionLoader />} model="forms.riskAssessmentForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="Risk Assessment Form" noNeedBorder={true}/>
                    <AlertModal id="risk-assessment-modal" /> 
                    <fieldset> 
                        {(associatedForm || associatedId) && <Row>
                            <div className="parant-asessment">
                                <Button className="" type="button" /* onClick={ () => history?.push(`/risk-assessment/edit/${associatedForm}`) } */ onClick={ () => this.backToParantAssessment(associatedForm) }> <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to parent assessment</Button> 
                            </div>
                        </Row> }
                        <Row className="show-grid" >
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="currentDate">Date of Risk Assessment:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date" 
                                        model=".dateOfAssessment"
                                        maxdate={currentDate} 
                                        defaultValue={editInstance?.props.dateOfIncident? format(new Date(editInstance?.props.dateOfIncident), 'yyyy-MM-dd'):( assosiatedIdInstance?.props.dateOfIncident?format(new Date(assosiatedIdInstance.props.dateOfIncident), 'yyyy-MM-dd'):currentDate)}
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="typeOfWork">Type of Work:</Label>
                                <VoiceRecognition labelText="" defaultValue={typeOfWork} model="forms.riskAssessmentForm.typeOfWork" required /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="typeOfWork"
                                        type="text"
                                        placeholder="Enter type of work..."
                                        model=".typeOfWork" 
                                        defaultValue={ typeOfWork }
                                    />
                                </Col>
                            </Col> 
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="workToPerform">Work Description:</Label>
                                <VoiceRecognition labelText="" defaultValue={workToPerform} model="forms.riskAssessmentForm.workToPerform" required={true} /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="workToPerform"
                                        type="text"
                                        placeholder="Enter work to be performed..."
                                        model=".workToPerform" 
                                        defaultValue={workToPerform}
                                    />
                                </Col>
                            </Col>
                            <LocationInput     
                                companyName={this.props.company}
                                model=".location"
                                id="risk-assessment-form"
                                navigator={navigator} 
                                defaultValue={editInstance?.props.locationName || assosiatedIdInstance?.props.locationName}
                            /> 
                             <Col sm={4} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={editInstance?.props.projectName || assosiatedIdInstance?.props.projectName} />
                            </Col>
                            <Col sm={4} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={editInstance?.props.groupName || assosiatedIdInstance?.props.groupName } />
                            </Col> 
                        </Row>
                        <legend>Risk Assessment</legend>
                        <Row className="assessment-hint">
                            <Accordion /* defaultActiveKey="0" */>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} eventKey="0" className="risk-assessment-toggle" onClick={() => this.accordianProcess('1')}>
                                        {this.state.hint1AccordianState ?  <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 

                                        </Accordion.Toggle>
                                        Event Categorization
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Table responsive bordered hover size="sm" className="event-categorization">
                                            <thead>
                                                <tr>
                                                    <th>Severity</th>
                                                    <th>Characteristics</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderEventCategorizationHint()}    
                                            </tbody>
                                        </Table> 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>
                        </Row>
                        <Row className="probability-hint">
                            <Accordion  >
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} eventKey="1" className="risk-assessment-toggle" onClick={() => this.accordianProcess('2')}>
                                        {this.state.hint2AccordianState ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 

                                        </Accordion.Toggle>
                                        Probability Categorization
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <Table responsive bordered hover size="sm" className="probability-categories">
                                            <thead>
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Definition</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderProbabilityCategorizationHint()}  
                                            </tbody>
                                        </Table> 
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card> 
                            </Accordion>
                        </Row>
                        <Row className="event-assessment-content">
                            <Grid style={{ width: '100%' }}>  
                                <Row className="show-grid  ">
                                    <Col sm={1}>
                                        <span style={{ color: 'yellow' }} >Action</span>
                                    </Col > 
                                    <Col sm={1}> 
                                        <span style={{ textAlign: 'center',color: 'yellow' }} >#</span>
                                    </Col>
                                    <Col sm={6} style={{textAlign: 'center', }}> 
                                        <span style={{ color: 'yellow' }} >Description</span>
                                    </Col>
                                    <Col sm={2} style={{textAlign: 'center', }}> 
                                        <span style={{ color: 'yellow' }} >Event <br/>Category </span>
                                    </Col>
                                    <Col sm={2} style={{textAlign: 'center', }}> 
                                        <span style={{ color: 'yellow' }} >Probability Category</span>
                                    </Col>
                                </Row>
                                {this.renderEventAssessment()}
                                <Row>
                                    <Col sm={8} style={{textAlign: 'right',}}> 
                                       <Label style={{textAlign:'right'}}>Average</Label>
                                    </Col>
                                    <Col sm={2} style={{textAlign: 'center',}}> 
                                        <label style={{ background: '#817f84', color: 'white', padding: '3px 25px 3px 25px', borderRadius: '7px', fontSize: '18px', fontWeight: 600,}}>{ getEventCategory(this.state.averageEventCategory)[1]}</label>
                                    </Col>
                                    <Col sm={2} style={{textAlign: 'center',}}> 
                                        <label style={{ background: '#817f84', color: 'white', padding: '3px 25px 3px 25px', borderRadius: '7px', fontSize: '18px', fontWeight: 600,}}>{getProbabilityCategory(this.state.averageProbabilityCategory)[2]}</label>
                                    </Col>
                                </Row>
                                <div className="table-buttons">
                                    <button className="add-row" onClick={this.incrementEventRows}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Another Step
                                        </button>
                                </div>
                            </Grid> 
                        </Row> 
                        {this.renderAssessmentResult()}
                        { !editInstance && this.renderReassessmentOptions() }
                        { (editId || this.state.reassessmentOption==='reasssessment') && forms.riskAssessmentForm.groupName && this.renderFollowUp() }
                        { editId && !associatedForm && this.renderRiskAssessmentFollowUp() }
                        { editId && this.renderReassessmentOptions() }
                        <Row className="show-grid">
                            <Col sm={12} xs={12} style={{textAlign: 'center' }}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input"> 
                                    <AttachementInput
                                        model=".attachements"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={editInstance && editInstance.props.attachmentUrls}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                        <div style={{marginTop: '10px'}}>
                            <legend >Approvals</legend> 
                            <Row className="show-grid"> 
                                { this.createdByDecleration() }
                                { this.state.reassessmentOption==='continueWork' && (UserModel.getAccessLevel()==='L2' || UserModel.getAccessLevel()==='L1') && this.approvedByDecleration() }
                                { this.state.reassessmentOption==='continueWork' && (this.state.SSRI===1 || this.state.SSRI===2 ) && (!this.state.finalAssessment || ( this.state.finalAssessment.ssri===1 || this.state.finalAssessment.ssri===2 ) ) && (UserModel.getAccessLevel()==='L2' || UserModel.getAccessLevel()==='L1') && this.state.isApproved && this.attestedByDecleration() }

                            </Row>
                        </div>
                       </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="risk-assessment-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }} type="submit"
                            >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { editInstance ? 'Update' : 'Submit' }
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    } 

    renderReassessmentOptions = () => {
        const {editInstance, } = this.props;
        return (
            <>
                <Row className="checkbox-row ">
                    <Col sm={12}>
                        <div style={{textAlign: 'center'}}> 
                        <Label className="radio-label" required={false} sm={12} htmlFor={`reassessmentTitle`} style={{fontSize: '20px'}}> Reassessment details</Label>
                            <Col sm={12 } className="work-related" style={{marginTop: '10px'}}>
                                <div style={radioContainer} >
                                    <label htmlFor={`reasssessment`} style={{color:'white'}}>Reassessment</label>
                                    <RRFInput
                                        id={`reasssessment`}
                                        type="radio"
                                        model={`.reassessmentOption`}
                                        radioValue="reasssessment"
                                        radioName={`reassessmentOption`}  
                                        defaultValue={editInstance?.props.reassessmentOption==='reasssessment'?'reasssessment':null}
                                        onChange={this.reassessmentOptionChange}
                                        isDisabled={this.state.SSRI===3 || this.state.SSRI===4?true:false }
                                    /><label style={radioButton} htmlFor={`reasssessment`}></label>
                                </div>
                                <div style={radioContainer} >
                                    <label htmlFor={`continueWork`} style={{color:'white'}}>Continue to approval</label>
                                    <RRFInput
                                        id={`continueWork`}
                                        type="radio"
                                        model={`.reassessmentOption`}
                                        radioValue="continueWork"
                                        radioName={`reassessmentOption`} 
                                        defaultValue={editInstance?.props.reassessmentOption==='continueWork'?'continueWork':null}
                                        onChange={this.reassessmentOptionChange} 
                                        //isDisabled={ (editInstance && (editInstance?.props.totalFollowUp || 0) - (editInstance?.props.totalClosedFollowUp || 0) >0) ?true:false }
                                    /> <label style={radioButton} htmlFor={`continueWork`}></label>
                                </div>
                            </Col>
                        </div> 
                        <Alert className="danger-alert" id="reassessment-option-form-failed" />
                    </Col>
                </Row>
            </>
        );
    }
    renderAssessmentResult = () => {
        const { averageEventCategory, averageProbabilityCategory, SSRI } = this.state; 
        return (
            <>
                <legend>Assessment Result</legend>    
                <Row className="analysis-result-content">
                    {ssriResultTable(averageEventCategory, averageProbabilityCategory)}
                    <Row className="analysis-result-hint">
                        <Accordion  >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} eventKey="3" className="risk-assessment-toggle" onClick={() => this.accordianProcess('3')}>
                                        {this.state.hint3AccordianState ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 
                                    </Accordion.Toggle>
                                    Risk Matrix Approved Criteria
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <Table responsive bordered hover size="sm" className="probability-categories">
                                        <thead>
                                            <tr>
                                                <th>Result</th>
                                                <th>Leading Factors</th> 
                                                <th>Counter Measures</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className={SSRI===1 ? 'res1':''}>
                                                <td>1</td>
                                                <td>IA,IB, IC, IIA, IIB & IIIA</td> 
                                                <td>Implement Countermeasures that Reduce Risk to an SSRI of a level 2, at a minimum</td>
                                            </tr>
                                            <tr className={SSRI===2 ? 'res2':''}>
                                                <td>2</td>
                                                <td>ID, IIC, IID, IIIB & IIIC</td>
                                                <td>Not Acceptable Without Management Re-evaluation</td> 
                                            </tr>
                                            <tr className={SSRI===3 ? 'res3':''}>
                                                <td >3</td>
                                                <td >IE, IIE, IIID, IIIE, IVA & IVB</td>
                                                <td >Acceptable with Review by Management</td> 
                                            </tr>
                                            <tr className={SSRI===4 ? 'res4':''}>
                                                <td>4</td>
                                                <td>IVC, IVD & IVE</td> 
                                                <td>Acceptable Without Review</td>
                                            </tr>  
                                        </tbody>
                                    </Table> 
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card> 
                        </Accordion>
                    </Row>
                    <Row className="analysis-result ">
                        <Row className="show-grid">
                            <Col sm={12} xs={12} style={{marginBottom: '10px'}}>
                                <label className="result-label" style={{color: 'yellow'}}>Safeguards & Security Risk Result</label>
                            </Col>
                            <Col sm={{offset:'2', span:'4'}}>
                                <label className="result-label">Risk Matrix Result:</label> <label className={`result-no res${SSRI}`}>{SSRI}</label>
                            </Col>
                            <Col sm={{  span:'5'}}>
                            <label className="result-label">Risk Matrix Category:</label> <label className={`result-no res${SSRI}`}>{getEventCategory(this.state.averageEventCategory)[1]}{getProbabilityCategory(averageProbabilityCategory)[2]}</label>
                            </Col>
                        </Row> 
                    </Row>
                    {this.state.averageEventCategory!==0 && this.state.averageProbabilityCategory!==0 && 
                        <Row className="analysis-result ssriResultHint mb20">
                            <Table responsive bordered hover size="sm" className=" "> 
                                <tbody>
                                    <tr >
                                        <td width="7%">{getEventCategory(this.state.averageEventCategory)[1]}</td>
                                        <td>{eventCategorization()[this.state.averageEventCategory][1]}</td></tr>
                                    <tr>
                                        <td>{getProbabilityCategory(averageProbabilityCategory)[2]}</td>
                                        <td>{probabilityCategorization()[this.state.averageProbabilityCategory][1]}</td></tr>
                                </tbody>
                            </Table> 
                        </Row> }
                    
                    <div className="form-button hide" > 
                        <Button style={{
                            color: '#FFFFFF',
                            border: 'none',
                            backgroundColor: '#26A65B'
                        }}
                        >  <i className={ "fa fa-bar-chart" }  aria-hidden="true"></i>
                        &nbsp;
                        { 'Analysis' }</Button>
                        <Alert className="danger-alert" id="risk-assessment-analysis-failed" />
                    </div> 
                </Row> 
            </>
        )
    }
    renderFollowUp = () => { 
        return (
            <>
            <legend>Follow-up</legend>    
            <Row className="followup-content"> 
                <Accordion  >
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} eventKey="4" className="risk-assessment-toggle" onClick={() => this.accordianProcess('4')}>
                                {this.state.followupAccordianState ?  <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 
                            </Accordion.Toggle>
                            Follow-up Status {/* editInstance && `(<span>${editInstance?.props.totalClosedFollowUp || 0 }</span>/<span>${editInstance?.props.totalFollowUp || 0}</span>)` */ }
                            
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                <div className="sw-list-wsrapper followup-list-modal  ">
                                    <div style={{ padding: '0px'}} className="list-page"> 
                                        <div className="reports-table">
                                            <div>
                                                <Row className="heading report-row">
                                                    <Col sm={1}>#</Col>
                                                    <Col sm={3}>Action</Col>
                                                    <Col sm={3}>Assigned To</Col>
                                                    <Col sm={3}>Target Date</Col> 
                                                    <Col sm={2}>Status</Col>
                                                </Row >
                                            </div>
                                            <div style={{ textAlign: 'center', fontSize: '15px', color: 'White' }}>
                                                {this.renderFollowUpListItems()}
                                            </div>
                                            { this.state.reassessmentOption==='reasssessment' && <div className="followup-button">
                                                <button type="button" className="followup-add" onClick={() => this.renderAddNewFormButton()} > <i className={ "fa fa-plus" } aria-hidden="true"></i> &nbsp; Add Follow-up </button>
                                            </div>}
                                        </div>
                                    </div > 
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> 
                </Accordion> 
            </Row>   
            </>
        )
    }
    renderFollowUpListItems = () => {
        const { followUpInstances } = this.props; 
        if(followUpInstances && followUpInstances.length>0 && followUpInstances[0]!==null ) { 
            return followUpInstances.map((data, index) => {  
                return (
                    <Row className="report-row">
                        <Col className="clickable-list-item" sm={1} > 
                            { UserModel.checkUserAccess('follow_up_delete') && <DropdownButton title="" style={{width: '20px'}} id="bg-vertical-dropdown-3"> 
                                <MenuItem eventKey="3" onClick={() => this.onDeleteAttribute(data)}
                                    className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem>
                            </DropdownButton> } <span className="serial-no">{ ++index}</span>
                        </Col>
                        <Col className="clickable-list-item" onClick={() => this.followUpEditFormModal(data)}
                            sm={3}
                        >
                            { data['action'] || ''}
                        </Col>
                        <Col className="clickable-list-item" onClick={() => this.followUpEditFormModal(data)}
                            sm={3}
                        >
                            { data['assignedToName']}
                        </Col>
                        <Col className="clickable-list-item" onClick={() => this.followUpEditFormModal(data)}
                            sm={3}
                        >
                            { data['targetCompletion'] && format(new Date(data['targetCompletion']), 'MM/dd/yyyy') }
                        </Col> 
                        <Col className="clickable-list-item closeCall" sm={2} onClick={() => this.followUpEditFormModal(data)}
                        > 
                            { data['status']==='Open'?<Button className="close-call-followup-open" type="button" style={followUpOpen}>Open</Button>: <Button className="close-call-followup-close"  type="button" style={followUpClosed}>Closed</Button>} 
                        </Col> 
                    </Row>
                ); 
            });
        } else {
            return (<div style={{textAlign: 'center'}}>No follow-up record found..</div>)
        } 
    }
    renderAddNewFormButton = () => {   
        const { editInstance, forms } = this.props;
        let modalInstance: any = []; 
        if(editInstance) {
            modalInstance['closeCallId'] = editInstance?.props.id 
            modalInstance['process'] = 'add';
            modalInstance['followUpFor'] = editInstance?.props; 
        } else if(forms.riskAssessmentForm.groupName) { 
            modalInstance['closeCallId'] = this.state.followUpTempUUID;
            modalInstance['followUpType'] = 'temp';
            modalInstance['process'] = 'add';
            modalInstance['followUpFor'] = [];
            modalInstance['followUpFor']['groupName'] = forms.riskAssessmentForm.groupName; 
            modalInstance['followUpFor']['formId'] = 'riskAssessmentForm'; 
        }
        showCreateCloseCallFollowUpModal(modalInstance);
    }
    followUpEditFormModal = (instance: any) => {  
        const { editInstance, forms } = this.props;
        let modalInstance: any = []; 
        modalInstance = instance; 
        if(editInstance) {
            modalInstance['closeCallId'] = instance['closeCallId']; 
            modalInstance['process'] = 'edit';
            modalInstance['followUpFor'] = this.props.editInstance?.props; 
        } else if(forms.riskAssessmentForm.groupName) { 
            modalInstance['closeCallId'] = this.state.followUpTempUUID;
            modalInstance['followUpType'] = 'temp';
            modalInstance['process'] = 'edit';
            modalInstance['followUpFor'] = [];
            modalInstance['followUpFor']['groupName'] = forms.riskAssessmentForm.groupName; 
            modalInstance['followUpFor']['formId'] = 'riskAssessmentForm'; 
        }
        this.followUpFormModal(modalInstance)
    } 
    followUpFormModal = (instance: any) => {  
        hideCloseCallFollowUpListModal();
        showCreateCloseCallFollowUpModal(instance);
    } 
    onDeleteAttribute(instance: any) { 
        const { props: { editInstance, forms } } = this;  
        instance['delFormType'] = 'follow_up' 
        if(editInstance) {
            instance['followUpFor'] = editInstance?.props;
        } else {
            instance['followUpFor'] = [];
            instance['followUpType'] = 'temp';
            instance['followUpFor']['groupName'] = forms.riskAssessmentForm.groupName; 
            instance['followUpFor']['formId'] = 'riskAssessmentForm'; 
        }
        showDelConfirmModal('delete-confirmation', 'Follow_up Form', instance);  
    } 

    renderRiskAssessmentFollowUp = () => {
        const { editInstance } = this.props;
        return (
            <>
            {/* <legend>Follow_up Risk Assessment</legend> */}
            <Row className="followup-risk-assessment-content"> 
                <Accordion  >
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} eventKey="5" className="risk-assessment-toggle" onClick={() => this.accordianProcess('5')}>
                                {this.state.riskAssessmentFollowupAccordianState ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} 
                            </Accordion.Toggle>
                            Follow-up Risk Assessment ({ (editInstance?.props.associatedChildForms && editInstance.props.associatedChildForms.length ) || 0}) 
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                                <div className="sw-list-wsrapper followup-list-modal  ">
                                    <div style={{ padding: '0px'}} className="list-page"> 
                                        <div className="reports-table">
                                            <div>
                                                <Row className="heading report-row">
                                                    <Col sm={1}>#</Col>
                                                    <Col sm={3}>Date</Col>
                                                    <Col sm={3}>Risk Matrix No</Col>
                                                    <Col sm={3}>Follow-up Status</Col>  
                                                </Row >
                                            </div>
                                            <div style={{ textAlign: 'center', fontSize: '15px', color: 'White' }}>
                                                {this.renderRiskAssessmentFollowUpListItems()}
                                            </div>
                                            {this.state.reassessmentOption==='reasssessment' && <div className="followup-button">
                                                <button className="risk-assessment-followup-add" type="button" onClick={() => this.addRiskAssessmentFollowUp()} > <i className={ "fa fa-plus"} aria-hidden="true"></i> &nbsp; Follow-up Risk Assessment </button>
                                            </div>} 
                                            <Alert className="danger-alert" id="risk-reassessment-form-failed" /> 
                                        </div>
                                    </div > 
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> 
                </Accordion> 
            </Row>
                    
            </>
        )
    } 
    addRiskAssessmentFollowUp = () => {
        const { history, editId, editInstance } = this.props; 
        if(editId) { 
            if(editInstance?.props.totalFollowUp===editInstance?.props.totalClosedFollowUp) {
                this.resetState();
                history?.push(`/risk-assessment-follow-up/${editId}`);
            } else {
                showAlert('Please close all follow-ups for risk reassessment', 'risk-reassessment-form-failed', 'danger');
                return;
            }
        }
    }
    resetState = () => {
        this.setState({
            currentDate: format(new Date(), 'yyyy-MM-dd'), 
            currentTime: format(new Date(), 'HH:mm'), hint1AccordianState: false, hint2AccordianState: false, hint3AccordianState: false, followupAccordianState: false, riskAssessmentFollowupAccordianState: false, eventStep: [1], SSRI: 0, isApproved: false, isAttested: false, 
            averageEventCategory: 0, averageProbabilityCategory: 0,  
            reassessmentOption: '', finalAssessment: 0,
            followUpTempUUID: ''
        });
    }
    renderRiskAssessmentFollowUpListItems = () => { 
        const { editInstance } = this.props;
        let associatedChildForms = editInstance?.props.associatedChildForms || []; 
        if(associatedChildForms.length>0 ) { 
            return associatedChildForms.map((data, index) => {  
                return (<><div>
                    <Row className="report-row">
                        <Col className="clickable-list-item" sm={1} > 
                            { UserModel.checkUserAccess('follow_up_delete') && <DropdownButton title="" style={{width: '20px'}} id="bg-vertical-dropdown-3"> 
                                <MenuItem eventKey="3" onClick={()=>this.editRiskAssessmentForm(data.key)} 
                                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem>
                            </DropdownButton> } <span className="serial-no">{index+1}</span>
                        </Col>
                        <Col className="clickable-list-item" onClick={()=>this.viewRiskAssessmentForm(data.key)} sm={3}
                        >
                            { format(new Date(data.date), 'MM/dd/yyyy') }
                        </Col>
                        <Col className="clickable-list-item" onClick={()=>this.viewRiskAssessmentForm(data.key)} sm={3}
                        >
                            <span className={`result-no res${data.ssri}`}>{ data.ssri}</span>
                        </Col>
                        <Col className="clickable-list-item" onClick={()=>this.viewRiskAssessmentForm(data.key)} sm={3}
                        >
                            {  <Button type="button" className="assessment-followup-button" > {( data.totalFollowUp - data.totalOpenFollowUp )}/{data.totalFollowUp || 0}</Button>  }
                        </Col> 
                        
                    </Row>
                </div>
            </>)
            })
        } else {
            return (<div style={{textAlign: 'center'}}> 
                No data available for risk reassessment 
            </div>)
        }
    }
    viewRiskAssessmentForm = async (key: any) => { 
        await getRiskAssessmentForm(key);
        this.props.history?.push(`/risk-assessment/${key}`)
    }
    editRiskAssessmentForm = async (key: any) => { 
        await getRiskAssessmentForm(key);
        this.props.history?.push(`/risk-assessment/edit/${key}`)
    }

    createdByDecleration = () => { 
        const { editInstance, userInstance } = this.props;  
        return (
            <><Col sm={4} xs={4}>
                <Label sm={12} required={false} htmlFor="upload-images">Submitted By: </Label> 
                <Col sm={12} className="input" style={{textAlign:'center', color:'white'}}>  
                    <Label style={{textAlign: 'center', color:'white'}}>({editInstance?.props.createdBy || userInstance?.props.firstName})</Label> 
                </Col> 
            </Col> </>
        );
    } 
    approvedByDecleration = () => {
        const { isApproved } = this.state; 
        const { editInstance, userInstance } = this.props;
        return ( 
            <Col sm={4} xs={4}>
                <Label sm={12}>Manager</Label>
                <Col sm={12} xs={12} style={{textAlign: 'center'}}>
                    <label className="checkbox-label" htmlFor="isApproved">Approval</label>
                    <RRFInput
                        id="isApproved"
                        model=".isApproved"
                        type="checkbox" 
                        defaultValue={isApproved} onChange={this.changeApproved}
                    />
                    <label htmlFor="isApproved"></label>
                </Col>
                <Col sm={12} className="input" style={{textAlign:'center'}}>  
                    <label style={{textAlign: 'center', color:'white' }}>({editInstance?.props.approvedBy || userInstance?.props.firstName})</label> 
                </Col> 
            </Col> 
        );
    }
    changeApproved = (status: any) => {
        this.setState({isApproved: status}); 
    } 
    attestedByDecleration = () => {
        const { isAttested } = this.state; 
        const { editInstance } = this.props;
        return ( 
            <><Col sm={4} xs={4}>
                <Label sm={12}>Final Authority</Label>
                <Col sm={12} xs={12} style={{textAlign: 'center'}}>
                    <label className="checkbox-label" htmlFor="isAttested">Approval</label>
                    <RRFInput
                        id="isAttested"
                        model=".isAttested"
                        type="checkbox"  
                        defaultValue={isAttested} onChange={this.changeAttested}
                    />
                    <label htmlFor="isAttested"></label>
                </Col>
                <Col sm={12} className="input" style={{textAlign:'center', color:'white'}}>  
                    <label style={{textAlign: 'center', color: 'white'}}>({editInstance?.props.attestedBy || this.props.userInstance?.props.firstName})</label> 
                </Col> 
            </Col> 
            {isAttested &&  <Col sm={12} xs={12} style={{marginTop: '20px'}}>
                <Label sm={12} htmlFor="finalAuthorityComments">Final Authority Comments:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="finalAuthorityComments"
                        type="textarea"
                        placeholder="Enter final authority comments..."
                        model=".finalAuthorityComments" 
                        textAreaRows={4}
                        defaultValue={editInstance?.props.finalAuthorityComments}
                    />
                </Col>
            </Col> }
            </>
        );
    }
    changeAttested = (status: any) => {
        this.setState({isAttested: status});  
    } 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <MapInputModal id="risk-assessment-form" /> 
                <DeleteConfirmationModal id="delete-confirmation" />
                <Async
                    promise={this.promise}
                    identifier="RiskAssessmentForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any,ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    const editInstance = RiskAssessmentModel.get(editId);  
    const instance = RiskAssessmentModel.get(userId);
    let followUpInstances: any;
    //if(editInstance) { 
        followUpInstances = state.report.get('modalReportDataList');  
    //} 
    const associatedId = ownProps.match.params.associatedId;
    let assosiatedIdInstance: any;
    if(associatedId) { 
        assosiatedIdInstance = RiskAssessmentModel.get(associatedId); 
    } 
    return { userId, userInstance, userUID, company, forms: state.forms, editId, editInstance, instance, followUpInstances, associatedId, assosiatedIdInstance
    };
}
export const RiskAssessmentForm = withRouter(connect<IRiskAssessmentFormProps, any, any>(mapStateToProps)(RiskAssessmentFormImpl));
 
const followUpOpen: React.CSSProperties = {
    color:'white',
    borderColor: 'red',
    backgroundColor: 'red'
 }; 
 const followUpClosed: React.CSSProperties = {
    color:'white',
    borderColor: 'green',
    backgroundColor: 'green'
 }; 
 const radioButton: React.CSSProperties = {
    verticalAlign: 'middle',
    margin: '0',
    marginLeft: '10px'
}; 
const radioContainer: React.CSSProperties = {
    display: 'inline-block',
    padding: '0 10px'
}; 
