import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { showDelConfirmModal } from '../../actions/modalActions'; 
import { RiskAssessmentModel, IRiskAssessmentModelProps } from '../../model/RiskAssessmentModel';
import { UserModel } from '../../model/UserModel';
import { connect } from 'react-redux'; 
import { getPageItemNumber, getEventCategory, getProbabilityCategory } from '../../utils/generalUtils'; 
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';   
import { setLoading } from '../../actions/loadingActions'; 
import { getAllFollowUpForms } from '../../services/closeCallService';  
const MenuItem = Dropdown.Item;
export interface IListItemProps {
    history?: {
        push: IHistory['push']
    };
    index?: number;
    instance?: RiskAssessmentModel;
    style?: React.CSSProperties; 
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> { 
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    onDeleteAttribute() {
        const { instance } = this.props;   
        instance &&  showDelConfirmModal('delete-confirmation', 'Risk Assessment form', instance.props);  
    }
    onEditAttribute(key?: string) {  
        if(!key) {
            this.props.history?.push(`risk-assessment-reports`);
        }
        this.props.history?.push(`/risk-assessment/edit/${key}`);
    }
    onViewAttribute(instance: RiskAssessmentModel) { 
        if(!UserModel.checkUserAccess('risk_view') )
            return;
        this.props.history?.push(`/risk-assessment/${instance.props.id}`);
    }
    renderFormList = () => {
        const { instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        } 
        //let riskResultCat: any = calcRiskAssessmentSSRI(parseInt(instance.props.eventResult),parseInt(instance.props.probabilityResult) ); 
        let averageEventCategory = getEventCategory(parseInt(instance.props.averageEventCategory))[1];
        let averageProbabilityCategory = getProbabilityCategory(parseInt(instance.props.averageProbabilityCategory))[2];
        let isApproved = instance.props.isApproved; 
        let userLevel = UserModel.getAccessLevel();
        return (
            <Row className="report-row" style={style}
            >
                <Col sm={1}>
                    { ( UserModel.checkUserAccess('risk_view') || UserModel.checkUserAccess('risk_edit') || UserModel.checkUserAccess('risk_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                        { UserModel.checkUserAccess('risk_view') && <MenuItem eventKey="1"
                            onClick={() => this.props.history?.push(`/risk-assessment/${instance.props.id}`)}
                            className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                        { UserModel.checkUserAccess('risk_edit') && ( ( (userLevel==='L1' || userLevel==='L2') && isApproved) || !isApproved)/* && ( ( (SSRI == '3' || SSRI == '4') && !isApproved) || ( (SSRI == '1' || SSRI == '2') && !isAttested) ) */ && <MenuItem eventKey="2"
                            onClick={() => this.onEditAttribute(instance.props.key)}
                            className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                        { UserModel.checkUserAccess('risk_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute()}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                    </DropdownButton> }
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
                </Col>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.dateOfIncident || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.projectName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.groupName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.locationName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{`${instance.props.SSRI}/${averageEventCategory}${averageProbabilityCategory}`}</Cell>  
                <Col className="clickable-list-item text-center" sm={1} onClick={() => this.getFollowUpList(instance.props)} > 
                    <Button className="closecall-followup-button" > {instance.props.totalClosedFollowUp || '0'}/{instance.props.totalFollowUp || '0'}</Button> 
                </Col> 
            </Row >
        );
    }
    getFollowUpList(instance: IRiskAssessmentModelProps & { id?: string | undefined; }) { 
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
         
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {  
    return {
         
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
