import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces'; 
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { Pagination } from '../reusableComponents/Pagination'; 
import { ListItem } from './ListItem'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { getAllRiskAssessmentForms } from '../../services/riskAssessmentService';
import { RiskAssessmentModel } from '../../model/RiskAssessmentModel';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
declare var require: any;
const queryString = require('query-string');

export interface IRiskAssessmentFormListPageProps {
    riskAssessmentFormInstances: RiskAssessmentModel[];
    history?: IHistory; 
    totalPageCount?: number;
    userId: string; 
    assessmentType: string;
    forms: IForms;
    axiosPreviousToken: any;
}  
export interface IRiskAssessmentFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class RiskAssessmentFomListPageImpl extends React.PureComponent<IRiskAssessmentFormListPageProps, IRiskAssessmentFormListPageState> {
    constructor(props: IRiskAssessmentFormListPageProps | Readonly<IRiskAssessmentFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'projectName', 2:'groupName', 3: 'locationName', 4: 'ssri'  };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {  
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }
    async componentDidUpdate(prevProps: any) {   
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];
        if (prevProps.assessmentType !== this.props.assessmentType) {  
            await this.getReportData(urlParams.sortingIndex);
        }
    }  
    getReportData = async (sortIndex='false') => {
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; if(sortIndex!=='false') {
            urlParams.sortIndexField = this.sortListing[sortIndex];
        }
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllRiskAssessmentForms(urlParams, this.props.forms, this.props.assessmentType);
        setSuccess('AllListPage'); 
    }
    renderFormList = () => {
        const { riskAssessmentFormInstances, assessmentType } = this.props; 
        return <div className="oi-list-wrapper" > 
            {<ExcelExport formType="riskAssessmentForm" fileName={`${assessmentType==='final'? 'Final':'Preliminary'} Risk Assessment Report`} filterParams={ {assessmentType} } />}
            <ListPage
                style={{ minHeight: '50vh', padding: '20px 0px 0px 0px' }}
                titleStyle={{ padding: '0px 70px' }}
                pageHeading={`${assessmentType==='final'? 'Final':'Preliminary'} Risk Assessment Report List`}
                instances={riskAssessmentFormInstances}
                rowHeadings={['Date Submitted', 'Team', 'Group', 'Location', 'Risk Matrix Result/Category','Follow-up Status']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={[ 'username', 'date', 'groupName', 'projectName','specifiedLocation']}
                searchPlaceHolder="Enter project, group, type of work, work to perform to search..."
                emptyInstancesMessage="No Risk Assessment reports found."
                sm={[2, 2, 2, 2, 2, 1]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                rowSortings={this.sortListing} 
                promise={(sortIndex) => this.getReportData(sortIndex)}
                forms={this.props.forms}
                history={this.props.history }
            />
            { UserModel.checkUserAccess('risk_add') && this.renderAddNewFormButton()}
            { UserModel.checkUserAccess('risk_summary') && this.renderSummaryPageButton()}
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={RiskAssessmentModel as any} 
                identifier="AllListPage"
            />
        </div>;
    }

    renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                onClick={() => { this.props.history?.push('/risk-assessment/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    }

    renderAddNewFormButton = () => { 
        return (
            <button className="add-new-report"
                onClick={() => { this.props.history?.push('/risk-assessment/create'); }}
            >
                +
            </button>
        );
    } 

    renderContent = () => { 
        return (
            <Grid className="oi-report-list" style={{ width: '100%', padding: '20px' }}>  
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
                {this.renderFormList()}
            </Grid>
        );
    }
    render() {
        return (
            <BasePage className="extra-wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="RiskAssessmentFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const riskAssessmentFormInstances = RiskAssessmentModel.list(state);
    const userId = state.login.get('userId'); 
    const assessmentType = ownProps.match.params.assessmentType;  
    return { 
        totalPageCount: state.miscellaneous.get('listCount'),
        riskAssessmentFormInstances, 
        userId, assessmentType, forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const RiskAssessmentFormListPage = withRouter(connect<IRiskAssessmentFormListPageProps, any, any>(mapStateToProps)(RiskAssessmentFomListPageImpl));
