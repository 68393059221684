import { BaseModel } from './BaseModel'; 

export interface IAuditFormsProps {
    id?: string;
    auditName?: string; 
    referenceNo?: string;
}

export class AuditFormsModel extends BaseModel<IAuditFormsProps> {
    constructor(props: IAuditFormsProps) {
        super(props, AuditFormsModel.resource);
    }
    static resource = 'auditName-form';
}

export interface IAuditModelProps {
    id: string;
    key?: string; 
    dateOfIncident: string;
    timeOfIncident: string;
    groupName: string;
    projectName: string;
    companyName: string;
    company: string; 
    effect: string[];
    comments: string[];
    checklistQuestions: string[];
    attachements: any[];
    attachmentUrls: any[];
    deletedFiles: any[];
    overallComments: string;
    totalChecklist: string;
    createdUserName: string;
    totalClosedFollowUp: string;
    totalFollowUp: string;
    totalScore: string;
    overallPercent: string;
    formId: string; 
    fileUrls?: string[]; 
    referenceNo?: string;
    serialNo?: string;
} 
export class AuditModel extends BaseModel<IAuditModelProps> {
    constructor(props: IAuditModelProps) {
        super(props, AuditModel.resource);
    }
    static resource = 'audit-form';
}

