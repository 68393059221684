import {BaseModel} from "./BaseModel";

export interface IPreExcavationChecklistV2ModelProps {
    id?: string;
    editId?: string;
    crewForeman?: string;
    oneCallTicketNo?: string;
    contactNo?: string;
    excavationDate?: Date;
    jobNo?: string;
    streetAddress?: string;
    city?: string;
    state?: string;
    updatTicket?: string; 
    isGasMarked?: boolean;
    isPipelineMarked?: boolean;
    isElectricMarked?: boolean;
    isTelephoneMarked?: boolean; 
    isWaterMarked?: boolean;
    isOtherUtilityMarked?: boolean;
    otherUtility?: any;
    naturalGasContactNo?: string;
    pipelineContactNo?: string; 
    electricContactNo?: string;
    telephoneContactNo?: string;
    waterContactNo?: string;

    speicalInstructions?: any;
    isScopeOfWork?: boolean;
    isWorkToBegin?: boolean;
    isGasUnmarked?: boolean; 
    isElectricUnmarked?: any;
    isTelephoneUnMarked?: any;
    isCableTVUnmarked?: any;
    isWaterUnmarked?: any;
    isSewerUnmarked?: any; 
    isSitePhotographed?: boolean;  
    attachments?: any;
    deletedFiles?: any;
    isExposedMarkedUtilities?: boolean;
    isMechanizedEquipmentUsed?: boolean;
    isGasDamaged?: boolean; 
    isElectricDamaged?: boolean;
    isTelephoneDamaged?: boolean;
    isCableTvDamaged?: boolean;
    isWaterDamaged?: boolean;
    isSewerDamaged?: boolean;
    isUnknownPipeDamaged?: boolean;
    isUnknownCableDamaged?: boolean;
    digupTicketNo?: any;
    facilityOwner?: any; 
    answers?: Array<{ quesType: string, quesNum: number, answer: any }>
    attachmentUrls?: any;
    shapesList?: any;
    outfallsList?: any;
    coordinatesPointerList?: any;
    coords?: any;
    language?: string;
}
 
export class PreExcavationChecklistV2Model extends BaseModel<IPreExcavationChecklistV2ModelProps> {
    static resource = 'pre-excavation-checklist-v2';

    constructor(props: IPreExcavationChecklistV2ModelProps) {
        super(props, PreExcavationChecklistV2Model.resource);
    }
} 

export interface IPreExcavationChecklistModelProps {
    id?: string;
    editId?: string;
    crewForeman?: string;
    oneCallTicketNo?: string;
    contactNo?: string;
    excavationDate?: Date;
    isVerifiedWorkScope?: boolean;
    isVerifiedWorkBeginDay?: boolean;
    isGasMarked?: boolean;
    isElectricMarked?: boolean;
    isTelephoneMarked?: boolean;
    isCableTVMarked?: boolean;
    isWaterMarked?: boolean;
    isSewerMarked?: boolean;
    isUnmarkedUtilities?: boolean;
    isGasUnmarked?: any;
    isElectricUnmarked?: any;
    isTelephoneUnMarked?: any;
    isCableTVUnmarked?: any;
    isWaterUnmarked?: any;
    isSewerUnmarked?: any;
    isOneCallNotifiedOnUnmarked?: any;
    isSitePhotographed?: boolean;
    isMarkingsPhotographed?: boolean;
    isAdvised18InchHandDigRule?: boolean;
    isAdvisedToTestDugHoles?: boolean;
    attachments?: any;
    deletedFiles?: any;
    language?: string;
    answers?: Array<{ quesType: string, quesNum: number, answer: any }>
    updateTicket?: any;
    coordinatesPointerList?: Array<any>;  
}

export class PreExcavationChecklistModel extends BaseModel<IPreExcavationChecklistModelProps> {
    static resource = 'pre-excavation-checklist';

    constructor(props: IPreExcavationChecklistModelProps) {
        super(props, PreExcavationChecklistModel.resource);
    }
}
