import * as React from 'react';
import { IChartValue, IPieChartObject } from '../../../interfaces';
import { Row, Col, Modal } from 'react-bootstrap';
import { PieChart } from './PieChart';
import { PieChartLegends } from './PieChartLegends';
import './activityChart.scss';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

export interface IActivityChartProps {
    data: IChartValue[];
    legends: string[];
    visibility: boolean;
    obj: IPieChartObject | undefined;
    totalFormsSubmitted?: number;
    chartType: string;
}

export interface IActivityChartState {
    visibility: boolean;
}

export class ActivityChart extends React.PureComponent<IActivityChartProps, IActivityChartState> {

    constructor(props: IActivityChartProps) {
        super(props);
        this.state = { visibility: false };
    }

    dy = 0;
    dx = 0;

    isMobileDevice = () => {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(nextProps: IActivityChartProps) {
        if (nextProps) {
            this.setState({
                visibility: nextProps.visibility
            });
        }
    }

    toggleModalVisibility = () => {
        this.setState({
            visibility: false
        });
    }

    calculateCoordinates = () => {
        const { props: { obj } } = this;
        if(!obj)
            return {};
        let pieContainer = document.getElementById('oi-pie-chart');
        let { dy, dx } = this;
        const { middleRadius, midAngle } = obj;
        if (obj && midAngle && middleRadius) {
            const calcRadians = (angle: number) => (angle * 3.1459) / 180;
            if (pieContainer) {
                dy = pieContainer.clientHeight / 2;
                dx = pieContainer.clientWidth / 2;
            }
            if (midAngle >= 0 && midAngle <= 90) {
                dy = dy - (Math.sin(calcRadians(midAngle)) * middleRadius);
                dx = dx + (Math.cos(calcRadians(midAngle)) * middleRadius);
            }
            if (midAngle >= 90 && midAngle <= 180) {
                dy = dy - (Math.sin(calcRadians(180 - midAngle)) * middleRadius);
                dx = (dx - (Math.cos((calcRadians(180 - midAngle)) * middleRadius))) - 450;
            }
            if (midAngle >= 180 && midAngle <= 270) {
                dy = dy + (Math.sin(calcRadians(midAngle - 180)) * middleRadius);
                dx = (dx - (Math.cos(calcRadians(midAngle - 180)) * middleRadius)) - 450;
            }
            if (midAngle >= 270 && midAngle <= 360) {
                dy = dy + (Math.sin(calcRadians(180 - 90 - (midAngle - 270))) * middleRadius);
                dx = dx + (Math.cos(calcRadians(180 - 90 - (midAngle - 270))) * middleRadius);
            }
        }
        //return { left: dx, top: dy };
        return { left: '23', top: 'auto'}
    }

    renderActivityChartModal = () => {
        const { state: { visibility }, props: { obj } } = this;
        if (visibility) {
            return <Modal show={this.state.visibility} onHide={this.toggleModalVisibility}>
                <Modal.Header closeButton>
                    <Modal.Title>Activities in {obj ? obj.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderContent()}
                </Modal.Body>
            </Modal >;
        }
        return;
    }

    renderContent = () => {
        const colorArray = ['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
            'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E'];
        const { legends, data, totalFormsSubmitted } = this.props;
        if (this.props.chartType === 'pie') {
            return (<Row className="show-grid">
                <Col sm={6} xs={6} className="pie circle" style={{ height: '168px' }}>
                    {data.length <= 0 ? '' : <div style={totalActity}>
                        <span style={activityText}>
                            {totalFormsSubmitted}
                        </span>
                        <span style={activityCount}>Total Report(s)</span>
                    </div>}
                    <PieChart
                        data={data}
                        innerRadius={40}
                        colorArray={colorArray}
                        paddingAngle={1}
                    />
                </Col>
                <PieChartLegends
                    xs={6}
                    style={{ padding: 0, width: '50%', height: '128px', textAlign: 'left', margin: '20px 0' }}
                    legendNames={legends}
                    legendColors={colorArray}
                />
            </Row>);
        } else {
            return (<Row className="show-grid">
                <Col sm={12} xs={12} style={{ height: '168px' }}>
                    {data.length <= 0 ? '' : <div>
                        <BarChart width={400} height={168} data={data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" /* interval={0} */ />
                            <XAxis dataKey="name" interval={0} allowDecimals={false}/>
                            <YAxis allowDecimals={false}/>
                            <Tooltip />
                            <Legend />
                            <Bar dataKey={legends[0]} fill="#82ca9d" />
                            <Bar dataKey={legends[1]} fill="#b1b1b1" />
                        </BarChart>
                    </div>}
                </Col>
            </Row>);
        }
    }

    renderActivitPopover = () => {
        const { left, top } = this.calculateCoordinates();
        const { obj } = this.props;
        return <div className="activity-charts" id="popover" style={{
            top: `${top}px`, left: `${left}px`, height: '25%', width: '46%', /* height: 'auto',
            width: '450px', */ position: 'absolute', zIndex: 100,
            background: 'white', border: '2px solid #dedede'
        }}
        >
            <span onClick={this.toggleModalVisibility} className="close-button">&times;</span>
            <h4 style={{ fontSize: '16px', textAlign: 'center' }}>Activities in {obj ? obj.name : ''}</h4>
            {this.renderContent()}
        </div >;
    }

    render() {
        const { state: { visibility }, isMobileDevice, calculateCoordinates } = this;
        calculateCoordinates();
        if (visibility && !isMobileDevice()) {
            return this.renderActivitPopover();
        }
        if (visibility && isMobileDevice()) {
            return this.renderActivityChartModal();
        }
        return <div></div>;
    }
}

const totalActity: React.CSSProperties = {
    /* position: 'absolute', top: '67px', */
    width: '100%',
    textAlign: 'center',
    left: 0,
    lineHeight: '100%',
};

const activityText: React.CSSProperties = {
    display: 'block',
    fontSize: '18px',
    textAlign: 'center',
    height: '15px',
    lineHeight: 1
};

const activityCount: React.CSSProperties = {
    fontSize: '10px'
};
