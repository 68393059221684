import { BaseModel } from './BaseModel';

export interface IOIModelProps {
    id?: string;
    timeOfIncident: string;
    mobileLocation: string;
    dateReported: string;
    location?: string;
    locationName?: string;
    specifiedLocation?: string;
    observer?: string;
    observerPosition?: string;
    observationType?: string;
    concernType?: string;
    activity?: string;
    nearMiss?: string;
    observation?: string;
    consequence?: string;
    actionTaken?: string;
    dateOfIncident: string;
    followUp?: boolean;
    groupName?: string;
    projectName?: string;
    userId: string;
    formId: string;
    oiImages?: {
        name: string;
        image: string;
    }[];
    oiVideos?: {
        name: string;
        file: string;
    }[];
    imageUrls: string[];
    videoUrls: string[];
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string;
    changedConcernType?: string;
    username?: string;
    totalFollowUp?: number; 
    totalClosedFollowUp?: number;
    timeReported: string;  
}

export class OIModel extends BaseModel<IOIModelProps> {
    constructor(props: IOIModelProps) {
        super(props, OIModel.resource);
    }
    static resource = 'oi-form';
}
