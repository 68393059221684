import { BaseModel } from './BaseModel';

export interface IJSAModelProps {
    id: string;
    key?: string;
    jsaNum: string;
    location: string;
    locationName: string;
    typeOfWork: string;
    groupName: string;
    projectName: string;
    approval?: string;
    followUp?: number;
    companyName: string;
    date: string;
    dateOfAnalysis?: string;
    workToPerform: string;
    controls?: string[];
    hazardsRisk?: string[];
    step?: number[];
    stepDescription?: string[];
    answers?: IJobSafetyStep[];
    stepValues: IJobSafetyStep[];
    jsaImages?: {
        name: string;
        image: string;
    }[];
    imageUrls?: string[]; 
    jsaVideos?: {
        name: string;
        file: string;
    }[];
    videoUrls?: string[];  
    deletedImages?: string[];
    deletedVideos?: string[];
    /* jsaSignature?: {
        name: string;
        file: string;
    }[]; */
    jsaSignature?: string;
    signatureUrl?: string; 
    editId?: string;
    userId?: string;
    appUsersCount?: string;
    appUsers: string;
    nonAppUsersCount?: string;
    nonAppUsers: string[]; 
    nonAppUsersEmail: string[];
    nonAppUsersEditId: string[];
    appUsersList: {
        uniqueId: string;
        user: string;
        readStatus: string;
        userEmail: string;
        userName: string;
    }[]; 
    nonAppUsersList: {
        uniqueId: string;
        user: string;
        readStatus: string;
        userEmail: string;
        userName: string;
    }[];
    acknowledgement: string;
    ackAttach: any[];
    ackAttachUrls: any[];
    deletedFiles: any[];
    isApproved?: string; 
    approvedById?: string;
    approvedBy?: string;
    totalFormAck?: number;
    deletedNonAppUsers?: string[];
} 

export interface IJobSafetyStep {
    controls?: string;
    hazards?: string;
    step?: number;
    stepDesc?: string;
}

export class JSAModel extends BaseModel<IJSAModelProps> {
    constructor(props: IJSAModelProps) {
        super(props, JSAModel.resource);
    }
    static resource = 'jsa-from';
}
