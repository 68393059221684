import * as React from 'react';
import {connect} from 'react-redux';
import {IHistory} from '../../interfaces';
import {UserModel} from '../../model/UserModel';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {DetailsPageWrapper} from '../reusableComponents/DetailsPageWrapper';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Gallery} from '../reusableComponents/ImageComponents/Gallery';
import {Loader} from '../reusableComponents/Loader';
import {Title} from '../reusableComponents/Title';
import {Button} from '../reusableComponents/FormComponents/Button';
import {getFormData} from "../../services/texas811Service";
import Select from "react-select";
import {Col, Row} from "react-bootstrap";
import {format} from "date-fns";
import * as displayText from "./UtilityDamageReportForm/language.json";
import {UtilityDamageReportModel} from "../../model/UtilityDamageReportModel";
import {AttachementInput} from "../reusableComponents/FormComponents/AttachementInput";
import {PlantLocationMap} from "../reusableComponents/GoogleMapsWithDrawing/GoogleMapsWithDrawing";

export interface IUtilityDamageReportDetailProps {
    id: string;
    instance: UtilityDamageReportModel;
    history: IHistory;
}

export interface IUtilityDamageReportDetailState {
    displayText: Record<any, any>
    language: string;
}

export class UtilityDamageReportDetailImpl extends React.Component<IUtilityDamageReportDetailProps, IUtilityDamageReportDetailState> {
    constructor(props: IUtilityDamageReportDetailProps | Readonly<IUtilityDamageReportDetailProps>) {
        super(props);
        this.state = {
            displayText: !props.instance ? displayText.eng : props.instance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.instance ? 'eng' : props.instance.props.language === 'spa' ? 'spa' : 'eng'
        }
    }

    promise = async () => {
        if (!this.props.instance) {
            await getFormData('utilityDamageReport', this.props.id)
            setTimeout(() => {
                const {instance}: any = this.props;
                this.setState({
                    displayText: !instance ? displayText.eng : instance.props.language === 'spa' ? displayText.spa : displayText.eng,
                    language: !instance ? 'eng' : instance.props.language === 'spa' ? 'spa' : 'eng'
                });
            }, 100);
        }
    }

    getObserverName = (userID: string) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    }

    renderContent = () => {
        const {instance} = this.props;
        if (!instance) {
            return <Loader/>;
        }

        const {displayText, language} = this.state;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }

        const {props: {outfallsList, shapesList, coordinatesPointerList, coords}} = instance;

        return <div>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <legend><span className="yellow-color-title">{displayText.partATitle}</span></legend>
            <Row className="show-grid">
                <Col sm={5} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.dod}&nbsp;&nbsp;&nbsp;
                            <span>{answer.dateOfDamage ? format(new Date(answer.dateOfDamage), 'MM/dd/yyyy') : ''}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.streetAddr}&nbsp;&nbsp;&nbsp;
                            <span>{answer.streetAddress || null}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={5} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.city}&nbsp;&nbsp;&nbsp;
                            <span>{answer.city || null}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.state}&nbsp;&nbsp;&nbsp;
                            <span>{answer.state || null}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.nearestIntersection}&nbsp;&nbsp;&nbsp;
                            <span>{answer.nearestIntersection || null}</span>
                        </label>
                    </Col>
                </Col>
            </Row>
            <hr/>
            <legend><span className="yellow-color-title">{displayText.partBTitle}</span></legend>
            <Row className='show-grid'>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.electric} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isElectricAffected && answer.isElectricAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.nGas} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isNGasAffected && answer.isNGasAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.sewer} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isSewerAffected && answer.isSewerAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.water} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isWaterAffected && answer.isWaterAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
            </Row>
            <Row className='show-grid'>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.telephone} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isTelePhoneAffected && answer.isTelePhoneAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <label className="checkbox-label">{displayText.cableTV} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isCableTVAffected && answer.isCableTVAffected === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
            </Row>
            <Row className='show-grid'>
                <p className="yellow-color-title">{displayText.typeOfService}</p>
                <Col sm={4} xs={12}>
                    <label className="checkbox-label">{displayText.serviceDrop} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isServiceOrDrop && answer.isServiceOrDrop === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} xs={12}>
                    <label className="checkbox-label">{displayText.main} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isMain && answer.isMain === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} xs={12}>
                    <label className="checkbox-label">{displayText.fo} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isFiberOptic && answer.isFiberOptic === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={10} xs={12}>
                    <label className="checkbox-label key-data">{displayText.dodf}&nbsp;&nbsp;&nbsp;
                        <span>{answer.damageDepth || ''}</span>
                    </label>
                </Col>
            </Row>
            <hr/>
            <legend><span className="yellow-color-title">{displayText.partCTitle}</span></legend>
            <Row className='show-grid'>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.oneCallCenterNotified} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isOneCallCenterNotified && answer.isOneCallCenterNotified === 'true') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                {(answer.isOneCallCenterNotified === 'true') && <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.provideTheTicketNo} &nbsp;&nbsp;&nbsp;
                        <span>{answer.locateTicketNumber || ''}</span>
                    </label>
                </Col>}
                <Col sm={12} xs={12}>
                    <label
                        className="checkbox-label key-data">{displayText.questFacilityMarksVisible} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isFacilityMarkVisible && answer.isFacilityMarkVisible === 'true') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={12} xs={12}>
                    <label
                        className="checkbox-label key-data">{displayText.questFacilityMarkAccurate} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isFacilityMarkAccurate && answer.isFacilityMarkAccurate === 'true') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.questFacilityMarkerType} &nbsp;&nbsp;&nbsp;
                        <span>{answer.facilityMarkerType || ''}</span>
                    </label>
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.QuestIsPhotosTaken} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isPhotosTaken && answer.isPhotosTaken === 'true') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={12} xs={12}>
                    <label
                        className="checkbox-label key-data">{displayText.questTypeOfPaintLocateMarksPresent} &nbsp;&nbsp;&nbsp;
                        <span>{answer.typeOfPaintedLocateMarks || ''}</span>
                    </label>
                </Col>
                <Col sm={12} xs={12}>
                    <label
                        className="checkbox-label key-data">{displayText.QuestMarksDistance} &nbsp;&nbsp;&nbsp;
                        <span>{answer.locateMarksDistance || ''}</span>
                    </label>
                </Col>
            </Row>
            <hr/>
            <legend><span className="yellow-color-title">{displayText.partDTitle}</span>
            </legend>
            <Row className={'show-grid'}>
                <p className="yellow-color-title">{displayText.questTypeofExcavationEquipment}</p>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.backhoe} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isTrackHoe && answer.isTrackHoe === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.boring} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isBoring && answer.isBoring === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.auger} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isAuger && answer.isAuger === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.trencher} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isTrencher && answer.isTrencher === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.directionDrill} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isDirectionDrill && answer.isDirectionDrill === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.drilling} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isDrilling && answer.isDrilling === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.handTools} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isHandTools && answer.isHandTools === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.probingDevice} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isProbingDevice && answer.isProbingDevice === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
            </Row>
            <Row className={'show-grid'}>
                <p className="yellow-color-title">{displayText.questTypeOfWorkPerformed}</p>
                <Col sm={6} xs={12}>
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label">{displayText.installingGP} &nbsp;&nbsp;&nbsp;
                            <span> {(answer.isGasPipelineInstalled && answer.isGasPipelineInstalled === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.installingEC} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isElectricCable && answer.isElectricCable === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.jointTrench} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isJointTrench && answer.isJointTrench === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.installingTele} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isTelephoneInstallation && answer.isTelephoneInstallation === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.installingCTV} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isCableTVInstallation && answer.isCableTVInstallation === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.installingPoles} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isPolesInstallation && answer.isPolesInstallation === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.installingAnchors} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isAnchorsInstallation && answer.isAnchorsInstallation === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.otherWork} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isOtherWork && answer.isOtherWork === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                {answer.isOtherWork === '1' && <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.otherWorkType} &nbsp;&nbsp;&nbsp;
                        <span>{answer.otherWorkType || ''}</span>
                    </label>
                </Col>}
            </Row>
            <Row className={'show-grid'}>
                <p className="yellow-color-title">{displayText.questLocationOfDigSite}</p>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.privateProp} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isLocationPrivateProp && answer.isLocationPrivateProp === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.utilityEasement} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isLocationUtilityEasement && answer.isLocationUtilityEasement === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label">{displayText.roadRow} &nbsp;&nbsp;&nbsp;
                        <span> {(answer.isLocationRoad && answer.isLocationRoad === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
            </Row>
            <hr/>
            <legend><span
                className="yellow-color-title">{displayText.partETitle}</span>
            </legend>
            <Row className={'show-grid'}>
                <Col sm={12} xs={12}>
                    <span className="checkbox-label">
                          <span>{answer.incidentDesc || 'NA'}</span>
                    </span>
                </Col>
            </Row>
            <hr/>
            <div className="pagebreak"> </div> 
            <legend><span className="yellow-color-title">{displayText.partFTitle}</span></legend>
            <Row className={'show-grid'}>
                <PlantLocationMap
                    locationAddress={answer.streetAddress + ', ' + answer.city + ', ' + answer.state}
                    locationCoords={coords} shapesList={shapesList}
                    outfallsList={outfallsList} coordinatesPointerList={coordinatesPointerList}
                    type={'nonEdit'}
                />
            </Row>
            <Row className={'show-grid attachement'}>
                <AttachementInput
                    id={'excavationUploads'}
                    noNeedAttachButton={true}
                    model={'.attachments'}
                    uploadedImages={instance && instance.props.attachmentUrls}
                    multiple={true}
                />
            </Row>
            <hr/>
            <Row className={'show-grid'}>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label key-data">{displayText.GFN} &nbsp;&nbsp;&nbsp;
                        <span>{answer.generalForemanName || ''}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label key-data">{displayText.GFPNo} &nbsp;&nbsp;&nbsp;
                        <span>{answer.generalForemanPhone || ''}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label key-data">{displayText.CFN} &nbsp;&nbsp;&nbsp;
                        <span>{answer.crewForemanName || ''}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label key-data">{displayText.CFPNo} &nbsp;&nbsp;&nbsp;
                        <span>{answer.crewForemanPhone || ''}</span>
                    </label>
                </Col>
                <Col sm={6} xs={12}>
                    <label className="checkbox-label key-data">{displayText.jobNo} &nbsp;&nbsp;&nbsp;
                        <span>{answer.jobNo || ''}</span>
                    </label>
                </Col>
                <Col sm={4} xs={12}>
                    <label className="checkbox-label key-data">{displayText.crewNo} &nbsp;&nbsp;&nbsp;
                        <span>{answer.crewNo || ''}</span>
                    </label>
                </Col>
            </Row>
        </div>
    }

    renderFormDetails = () => {
        return <Async
            identifier="UtilityDamageReportDetail"
            promise={this.promise}
            error={<ErrorPage/>}
            loader={<Loader/>}
            content={this.renderContent()}
        />;
    }

    render() {
        let fileName = '';
        const {instance} = this.props;
        if (instance) {
            const {instance: {props: {dateOfDamage}}} = this.props;
            fileName = `Utility Damage Report ${dateOfDamage}`;
        }
        return (
            <BasePage>
                <Gallery images={instance ? instance.props.attachments : []}/>
                <DetailsPageWrapper pdfFileName={fileName} className="office-safety-report report-details utilityDamageReport"
                                    customButton={<Button className="closeButton" redirectTo="goBack" type="button"
                                                          onClick={() => this.props.history?.goBack()}>
                                        <i className="fa fa-ban" aria-hidden="true"/>&nbsp;
                                        Close
                                    </Button>
                                    }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const id = ownProps.match.params.id;
    const instance = UtilityDamageReportModel.get(id);
    return {
        id,
        instance
    };
}

export const UtilityDamageReportDetailPage = connect<{}, {}, IUtilityDamageReportDetailProps>(mapStateToProps)(UtilityDamageReportDetailImpl);
