import * as React from 'react'; 
import { getAllGroups } from '../../../services/groupService';
import { GroupModel } from '../../../model/GroupModel';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Async } from '../../reusableComponents/Async';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage';
import { Row, Col } from 'react-bootstrap';
import { Title } from '../../reusableComponents/Title';
import { Alert } from '../../Alert';
import '../MechForm/mechForm.scss'; 
import { Button } from '../../reusableComponents/FormComponents/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { IHistory, IAnswer, ISelectOptions, IForms } from '../../../interfaces';
import { Loader } from '../../reusableComponents/Loader';
import { UserModel } from '../../../model/UserModel';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput'; 
import PropTypes from 'prop-types';
import { FileInput } from '../../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../../reusableComponents/FormComponents/VideoInput';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader'; 
import { white } from 'material-ui/styles/colors';  
import { TransModel, ITransModelProps } from '../../../model/TransModel';
import {  isFormEmpty, showAlert,constructAnswers } from '../../../utils/generalUtils';  
import { submitTransForm } from '../../../services/closeCallService';
import { format } from 'date-fns';

export interface ITransFormProps {
    userUID: string;
    userId: string;
    id?: string; 
    location?: string;
    typeOfWork?: string;
    groupName?: string;
    projectName?: string;
    followUp?: string;
    companyName?: string;
    date?: string;
    workToPerform?: string; 
    history?: IHistory;
    instance?: TransModel;
    onSubmit?: (userId: string, date: string, answerData: IAnswer[],
        group: string, project: string) => void;
    userInstance?: UserModel; 
    forms?: IForms;
    editId?: string;
    editInstance?: TransModel;
}

export interface ITransFormState {
    noOfRows: number[];
    signature: File | string;
    signatureURL: string;
    isSignatureUploaded: boolean;
    signNew: boolean;
    reporterOptions?: ISelectOptions[];
    stateOptions?: ISelectOptions[];
    activityOptions?: ISelectOptions[];
    weatherOptions?: ISelectOptions[];
    outdoorOptions?: ISelectOptions[];
    lightingOptions?: ISelectOptions[];
    locationOptions?: ISelectOptions[];
    typeOptions?: ISelectOptions[];
    operationOptions?: ISelectOptions[];
    trainActivityOptions?: ISelectOptions[];
    reporterValue?: string;
    shiftEventValue?: string;
    reporterLocationValue?: string;
    reducedVisibilityValue?: boolean;
    operatingRuleValue?: string;
    trainLocationValue?: string;
    operationValue?: string;
    trainActivityValue?: string;
    typeValue?: string; 
}

export class TransFormImpl extends React.PureComponent<ITransFormProps, ITransFormState> {

    constructor(props: ITransFormProps | Readonly<ITransFormProps>) {
        super(props);
        const value = [1];    
        this.state = {
            noOfRows: value,
            signature: '',
            signatureURL: '', isSignatureUploaded: false,
            signNew: false,
            reporterOptions: [{ value: 'Boiler Maker', label: 'Boiler Maker' }, { value: 'Foreman', label: 'Foreman' }, { value: 'Machinist', label: 'Machinist' },
            { value: 'Trainee', label: 'Trainee' }, { value: 'Carman', label: 'Carman' }, { value: 'Hostler', label: 'Hostler' },
            { value: 'Manager', label: 'Manager' }, { value: 'Electrician', label: 'Electrician' }, { value: 'Laborer', label: 'Laborer' },
            { value: 'Pipe Fitter', label: 'Pipe Fitter' }, { value: 'Other', label: 'Other' }], 
            activityOptions: [{ value: 'Blocking', label: 'Blocking/Jacking/Rerailing' }, { value: 'Installation', label: 'Installation' }, { value: 'Scheduled Maintenance', label: 'Scheduled Maintenance' },
            { value: 'Documentation', label: 'Documentation' }, { value: 'Operating Vehicle', label: 'Operating Vehicle/Equipment' }, { value: 'Testing', label: 'Testing' },
            { value: 'Inspection', label: 'Inspection' }, { value: 'Repair', label: 'Repair/Replace' }, { value: 'Other', label: 'Other' },], 
            weatherOptions: [{ value: 'Clear', label: 'Clear' }, { value: 'Ice', label: 'Ice' }, { value: 'Wind', label: 'Wind' },
            { value: 'Fog', label: 'Fog' }, { value: 'Rain', label: 'Rain' }, { value: 'Haze', label: 'Haze/Smoke' },
            { value: 'Hail', label: 'Hail' }, { value: 'Snow', label: 'Snow' }, { value: 'Thunderstorm', label: 'Thunderstorm/Lightning' },], 
            outdoorOptions: [{ value: 'Dawn', label: 'Dawn' }, { value: 'Daylight', label: 'Daylight' },
            { value: 'Dusk', label: 'Dusk' },{ value: 'Night', label: 'Night' }],  
            lightingOptions: [{ value: 'High', label: 'High' }, { value: 'Medium', label: 'Medium' },
            { value: 'Low', label: 'Low' }, { value: 'Off', label: 'Off' }],  
            locationOptions: [{ value: 'Main Track', label: 'Main Track' }, { value: 'Passenger Station', label: 'Passenger Station' },
            { value: 'Repair Facility', label: 'Repair Facility' }, { value: 'Yard', label: 'Yard' }, { value: 'Facility', label: 'Facility' },
            { value: 'Other', label: 'Other' }],  
            stateOptions: [{value:'Texas',  label:'Texas'}, {value:'Arkansas',  label:'Arkansas'},{value:'Okhlohoma',  label:'Okhlohoma'},
            {value:'Ohio',  label:'Ohio'}, {value:'Louisiana',  label:'Louisiana'}], 
            typeOptions: [{value:'Crane', label:'Crane'}, {value:'Off Track Equipment', label:'Off Track Equipment'},{value:'Portable', label:'Portable/Hand Tools'},
            {value:'Motor Vehicle', label:'Motor Vehicle'}, {value:'On Track Equipment', label:'On Track Equipment'}, {value:'Other', label:'Other'}],  
            operationOptions: [{value:'Centralized Traffic Control', label:'Centralized Traffic Control'}, {value:'Other than main track rules', label:'Other than main track rules'},
            {value:'Automatic Train Stop', label:'Automatic Train Stop'},{value:'Interlocking', label:'Interlocking'}, {value:'Positive Train Control', label:'Positive Train Control'},
             {value:'Camp Car Protection', label:'Camp Car Protection'}, {value:'Track Warrant Control', label:'Track Warrant Control'}, {value:'Dark Territory', label:'Dark Territory'},
             {value:'Roadway Worker Protection', label:'Roadway Worker Protection'},{value:'Direct Traffic Control', label:'Direct Traffic Control'},{value:'Automatic Block Signals', label:'Automatic Block Signals'},
             {value:'Yard Limits', label:'Yard Limits'},  {value:'Automatic Cab Signals', label:'Automatic Cab Signals'},  {value:'Other', label:'Other'}], 
             trainActivityOptions: [{value:'Predeparture',label:'Predeparture'}, {value:'Arrival',label:'Arrival'}, {value:'Passenger Boarding',label:'Passenger Boarding'},
             {value:'Departure',label:'Departure'}, {value:'Switching in Yard',label:'Switching in Yard'}, {value:'Freight',label:'Freight Loading/ Unloading'},
             {value:'Enroute',label:'Enroute'}, {value:'Hold',label:'Hold'}, {value:'Other',label:'Other'},],
            /*  reporterValue: editInstance && editInstance.props.reporter || '', shiftEventValue: editInstance && editInstance.props.shiftEvent || '', reporterLocationValue: editInstance && editInstance.props.reporterLocation || '',  reducedVisibilityValue: editInstance && editInstance.props.reducedVisibility? true : false, operatingRuleValue: editInstance && editInstance.props.operatingRules || '', trainLocationValue: editInstance && editInstance.props.trainLocation || '', operationValue: editInstance && editInstance.props.operation || '',trainActivityValue: editInstance && editInstance.props.trainActivity || '', typeValue:  editInstance && editInstance.props.type || '',   */
            reporterValue: '', shiftEventValue: '', reporterLocationValue: '', reducedVisibilityValue: false, operatingRuleValue:  '', trainLocationValue: '', operationValue: '',trainActivityValue: '', typeValue: '',
            
        };
    }  
    getChildContext() {
        return { formModel: 'forms.transForm' };
    }  
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    promise = async () => {
        if (GroupModel.list().length === 0) {
            await getAllGroups();
        }
        return null;
    }

    handleSubmit = ({ eventtype, eventlocation,facility, involvedWorkers, milepost, nearstation, phone, hours, carrier, state, name, date, time, address, city,stateString, zip, reporter, reporterOther,  railroadyears, yearscraft,crewsize, shiftEvent, otherShiftEvent, reporterLocation, otherReporterLocation, weather, outdoors, workarea, reducedVisibility,reducedVisibilityDetail,typeOperation,transportType, totalhead, locomotive, remoteControl, cst, helpers, distributedPower, noofcars, noinservice, carCabControlling, loads, empties, tons, length, type,otherType, operatingRules,operatingRulesOthers, jobCompleted, trainLocation, operation, trainActivity, trainLocationOther, operationOther, trainActivityOther, descriveEventSituation }: ITransModelProps) => { 
        const { userUID, forms, history, editId } = this.props; 
        const answerData = {
            'Type of Event':eventtype, 'Event Location':eventlocation,'Facility':facility,'Involved Co-Workers': involvedWorkers,'Milepost':milepost, 'Nearest Station':nearstation, 'Primary':phone, 'Hours': hours, 'Carrier/Railroad':carrier, 'Event State':state, 'Name':name, 'Date of Occurence':date, 'Local Time (12 HR Clock)':time, 'Address':address, 'City':city,'State':stateString, 'ZIP':zip, 'Reporter':reporter, 'Reporter Other':reporterOther,  'Railroad Years':railroadyears, 'Years in Craft':yearscraft,'Crew Size':crewsize,  'Shift During Event':shiftEvent,  'Shift During Event Other':otherShiftEvent, 'Reporter Location':reporterLocation, 'Reporter Location Other':otherReporterLocation, 'Weather':weather, 'Outdoors':outdoors, 'Work Area Lightning':workarea, 'Reduced Visibility':reducedVisibility,'Reduced Visibility Detail':reducedVisibilityDetail,' Type of Operation':typeOperation,' Transport Type':transportType, 'Total Head End':totalhead, 'Locomotive Make/Model':locomotive, 'Remote Control':remoteControl, 'Control Stand Type': cst, ' # of Helpers':helpers,'Distributed Power': distributedPower, 'No of Cars':noofcars, 'No in Service':noinservice,  'Cab Car Controlling':carCabControlling, 'Loads':loads, 'Empties':empties, 'Tons':tons, 'Length':length, 'Type':type,'Other Type':otherType, 'Operating Rules':operatingRules,'Operating Rules Other':operatingRulesOthers, 'Were job/safety briefings completed?':jobCompleted, 'Train Location':trainLocation, 'Methods of Operation':operation, 'Train Activity':trainActivity, 'Train Location Other':trainLocationOther, 'Other Methods of Operation':operationOther, 'Other Train Activity':trainActivityOther,' Describe Event / Situation':descriveEventSituation
        };  
        const submittedValues = {
            eventtype, eventlocation,facility, involvedWorkers, milepost, nearstation/* , phone, hours, carrier, state, name, date, time, address, city,stateString, zip, reporter, railroadyears, yearscraft,crewsize, shiftEvent, reporterLocation, weather, outdoors, workarea,  typeOperation,transportType, totalhead, locomotive, remoteControl, cst, helpers, distributedPower, noofcars, noinservice, carCabControlling, loads, empties, tons, length, type, operatingRules, jobCompleted, trainLocation, operation, trainActivity */ 
        };    
       /*  if(this.state.reporterValue==='Other')
            submittedValues['reporterOther'] = reporterOther; 
        if(this.state.shiftEventValue==='other')
            submittedValues['otherShiftEvent'] = otherShiftEvent; 
        if(this.state.reporterLocationValue==='other')
            submittedValues['otherReporterLocation'] = otherReporterLocation; 
        if(this.state.reducedVisibilityValue) {
            submittedValues['reducedVisibility'] = reducedVisibility; 
            submittedValues['reducedVisibilityDetail'] = reducedVisibilityDetail; 
        }
        if(this.state.operatingRuleValue==='Other')
            submittedValues['operatingRulesOthers'] = operatingRulesOthers; 
        if(this.state.trainLocationValue==='Other')
            submittedValues['trainLocationOther'] = trainLocationOther;
        if(this.state.operationValue==='Other')
            submittedValues['operationOther'] = operationOther; 
        if(this.state.trainActivityValue==='Other')
            submittedValues['trainActivityOther'] = trainActivityOther; 
        if(this.state.typeValue==='Other')
            submittedValues['otherType'] = otherType; */ 
 
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'trans-form', 'danger');
            return;
        } 
        let answers = constructAnswers(answerData);  
        return submitTransForm(userUID, answers, history, forms, editId); 
    }
    
    renderFormContent() {
        const {handleSubmit, state:{ reporterOptions, weatherOptions,
            outdoorOptions, lightingOptions, trainActivityOptions,locationOptions, stateOptions, typeOptions, operationOptions} } = this;
        const { editInstance, editId, history } = this.props;  
        if( (editId && ( !editInstance || editInstance.props===undefined ) ) ) {
            history?.push('/close-call-reports');
            return <SubmissionLoader/>
        } else if(editInstance && editInstance.props) { 
            this.setState({ reporterValue: editInstance.props.reporter || '', shiftEventValue: editInstance.props.shiftEvent || '', reporterLocationValue: editInstance.props.reporterLocation || '',  reducedVisibilityValue: editInstance.props.reducedVisibility? true : false, operatingRuleValue: editInstance.props.operatingRules || '', trainLocationValue: editInstance.props.trainLocation || '', operationValue: editInstance.props.operation || '',trainActivityValue: editInstance.props.trainActivity || '', typeValue: editInstance.props.type || '',},); 
        }
        return (
            <Form loader={<SubmissionLoader />} model="forms.transForm" onSubmit={handleSubmit}>
                <Title titleColor="yellow" text="C3RS Transportation Form" noNeedBorder={true} />
                {/* <Alert id="trans-form" className="danger-alert" /> */}
                <AlertModal id="trans-form-modal" />
                <fieldset>
                    <legend><span style={{ color: 'yellow' }}>Transportation Form</span></legend>
                    <Row className="show-grid">
                        <Col sm={5} xs={12}>
                            <Label required sm={12} htmlFor="eventtype">Type of Event:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="eventtype"
                                    type="text"
                                    placeholder="Enter event name..."
                                    model=".eventtype"
                                    defaultValue={editInstance && editInstance.props.eventtype}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="eventlocation">Event Location:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="eventlocation"
                                    type="text"
                                    placeholder="Enter location..."
                                    model=".eventlocation"
                                    defaultValue={editInstance && editInstance.props.eventlocation}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="facility">Facility:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="facility"
                                    type="text"
                                    placeholder="Enter name of Facility.."
                                    model=".facility"
                                    defaultValue={editInstance && editInstance.props.facility}
                                   />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{ marginTop: '15px' }}>
                        <Col sm={5} xs={12}>
                            <Label required sm={12} htmlFor="involvedWorkers">Involved Co-Workers:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="involvedWorkers"
                                    type="text"
                                    placeholder="Enter name of coworkers."
                                    model=".involvedWorkers"
                                    defaultValue={editInstance && editInstance.props.involvedWorkers}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="milepost">Milepost:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="milepost"
                                    type="text"
                                    placeholder="Enter milepost..."
                                    model=".milepost"
                                    defaultValue={editInstance && editInstance.props.milepost}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="nearstation">Nearest Station</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="nearstation"
                                    type="text"
                                    placeholder="Enter nearest station..."
                                    model=".nearstation"
                                    defaultValue={editInstance && editInstance.props.nearstation}
                                />
                            </Col>
                        </Col>
                    </Row>
                        
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <p style={{color:white}}>Telephone Number where we may reach you for further details</p>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="phone">Primary</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="phone"
                                    type="text"
                                    placeholder="XXX-XXX-XXXX"
                                    model=".phone"
                                    defaultValue={editInstance && editInstance.props.phone}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="hours">Hours</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="hours"
                                    type="time"
                                    model=".hours"
                                    defaultValue={editInstance && editInstance.props.hours}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="carrier">Carrier/Railroad</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="carrier"
                                    type="text"
                                    placeholder="Enter carrier name..."
                                    model=".carrier"
                                    defaultValue={editInstance && editInstance.props.carrier}
                                />
                            </Col>
                        </Col>

                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="state">State</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="state"
                                    type="dropdown"
                                    placeholder="Select State."
                                    menuItems={stateOptions}
                                    model=".state"
                                    defaultValue={editInstance && editInstance.props.state}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={5} xs={12}>
                            <Label sm={12} htmlFor="name">Name</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    model=".name" 
                                    defaultValue={editInstance && editInstance.props.name}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="date">Date of Occurence</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="date"
                                    type="date"
                                    placeholder="HH:MM"
                                    model=".date"
                                    defaultValue={editInstance ? (editInstance.props.date? format(new Date(editInstance.props.date), 'yyyy-MM-dd')
                                    : format(new Date(editInstance.props.createdDate), 'yyyy-MM-dd')):'' }
                                    maxdate={ (editInstance && format(new Date(editInstance.props.createdDate), 'yyyy-MM-dd') ) || format(new Date(), 'yyyy-MM-dd')}
                                />
                            </Col>
                        </Col>

                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="time">Local Time (12 HR Clock)</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="time"
                                    type="time"
                                    model=".time"
                                    defaultValue={editInstance && editInstance.props.time}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={5} xs={12}>
                            <Label sm={12} htmlFor="address">Address</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="address"
                                    type="text"
                                    placeholder="Enter Address"
                                    model=".address"
                                    defaultValue={editInstance && editInstance.props.address}                                    
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="city">City</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="city"
                                    type="text"
                                    placeholder="Enter City Name"
                                    model=".city"
                                    defaultValue={editInstance && editInstance.props.city}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="state">State</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="stateString"
                                    type="dropdown"
                                    placeholder="Select State"
                                    menuItems={stateOptions}
                                    model=".stateString"
                                    defaultValue={editInstance && editInstance.props.stateString}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="zip">ZIP</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="zip"
                                    type="text"
                                    placeholder="Enter Zipcode"
                                    model=".zip"
                                    defaultValue={editInstance && editInstance.props.zip}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>          
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="reporter">Reporter</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="reporter"
                                    type="dropdown"
                                    menuItems={reporterOptions}
                                    model=".reporter"
                                    onSelect={this.handleReporterDropdown}
                                    defaultValue={editInstance && editInstance.props.reporter}
                                />
                            </Col>
                            { this.state.reporterValue === 'Other' ?
                                <Col sm={12} id="reporterOtherContent" >
                                <Label sm={10} style={{right:'2px'}}>
                                    other: <RRFInput
                                        id="reporterOther"
                                        type="text" 
                                        model=".reporterOther"
                                        defaultValue={editInstance && editInstance.props.reporterOther}
                                    /> 
                                </Label> 
                                </Col> 
                                : null
                            }
                        </Col> 
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="certification">Reporter Experience</Label>
                            <Label sm={12} htmlFor="railroadyears">Railroad Years</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="railroadyears"
                                    type="text"
                                    placeholder="Enter No of Years"
                                    model=".railroadyears"
                                    defaultValue={editInstance && editInstance.props.railroadyears}
                                />
                            </Col>

                            <Label sm={12} htmlFor="yearscraft">Years in Craft</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="yearscraft"
                                    type="text"
                                    placeholder="Enter No of Years"
                                    model=".yearscraft"
                                    defaultValue={editInstance && editInstance.props.yearscraft}
                                />
                            </Col>

                            <Label sm={12} htmlFor="crewsize">Crew Size</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="crewsize"
                                    type="text" 
                                    model=".crewsize"
                                    defaultValue={editInstance && editInstance.props.crewsize}
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} >Shift During Event</Label> 
                            <Label sm={12} >At time of incident, were you on:</Label>   
                            <Label sm={12} className="radioInput" >
                                <RRFInput
                                    id="shiftEventOD"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="Overtime Duty"
                                    radioName="shiftEvent"
                                    onChange={this.handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent}
                                /><label htmlFor="shiftEventOD">Overtime Duty</label> 
                            </Label> 
                            <Label sm={12} className="radioInput">
                                <RRFInput
                                    id="shiftEventED"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="Emergency Duty"
                                    radioName="shiftEvent"
                                    onChange={this.handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent}
                                /><label htmlFor="shiftEventED">Emergency Duty</label> 
                            </Label> 
                            <Label sm={12} className="radioInput">
                                <RRFInput
                                    id="shiftEventOther"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="other" 
                                    radioName="shiftEvent" 
                                    onChange={this.handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent}
                                /><label htmlFor="shiftEventOther">Other</label>
                                { this.state.shiftEventValue === 'other' ?
                                    <RRFInput
                                        id="otherShiftEvent"
                                        type="text" 
                                        model=".otherShiftEvent"
                                        defaultValue={editInstance && editInstance.props.otherShiftEvent}
                                    />
                                : null }
                            </Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} >Reporter Location</Label> 
                            <Label sm={12} className="radioInput reporterLocationContent">
                                <RRFInput
                                    id="reporterLocationYard"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="no"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationYard">Yard</label> 
                            
                                <RRFInput
                                    id="reporterLocationShop"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Shop"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationShop">Shop</label> 
                             
                                <RRFInput
                                    id="reporterLocationOT"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Other Track"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationOT">Other Track</label> 
                             
                                <RRFInput
                                    id="reporterLocationAdjacent"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Adjacent to track/on ground"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationAdjacent">Adjacent to track/on ground </label> 
                             
                                <RRFInput
                                    id="reporterLocationCF"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Office/ Crew Facility"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationCF">Office/ Crew Facility</label>  
                             
                                <RRFInput
                                    id="reporterLocationMP"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue=" On/ under/ between Motive Power"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationMP"> On/ under/ between Motive Power</label> 
                             
                                <RRFInput
                                    id="reporterLocationRE"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="On/ under/ between Rolling Equipment"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationRE">On/ under/ between Rolling Equipment</label> 
                             
                                <RRFInput
                                    id="reporterLocationStationPlatform"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Station Platform"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationStationPlatform">Station Platform</label> 
                             
                                <RRFInput
                                    id="reporterLocationother"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="other"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationother">other</label> 
                                { this.state.reporterLocationValue === 'other' ?<RRFInput
                                    id="otherReporterLocation"
                                    type="text" 
                                    model=".otherReporterLocation"
                                    defaultValue={editInstance && editInstance.props.otherReporterLocation}
                                />
                                : null }
                            </Label> 
                        </Col>
                    </Row>
                     
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="weather">Weather</Label> 
                            <Col sm={12} className="input">
                                <RRFInput 
                                    id="weather"
                                    type="dropdown"
                                    menuItems={weatherOptions}
                                    model=".weather"
                                    defaultValue={editInstance && editInstance.props.weather}
                                />
                            </Col>
                        </Col>

                        <Col sm={9} xs={12}>
                            <Label sm={12} >Light/Visibility</Label> 
                            <Row className="show-grid">
                                <Label sm={2} htmlFor="outdoors">Outdoors</Label>
                                <Col sm={3} className="input">
                                    <RRFInput 
                                        id="outdoors"
                                        type="dropdown"
                                        menuItems={outdoorOptions}
                                        model=".outdoors"
                                        defaultValue={editInstance && editInstance.props.outdoors}
                                    />
                                </Col> 
                                <Label sm={3} htmlFor="workarea">Work Area Lighting</Label>
                                <Col sm={3} className="input">
                                    <RRFInput 
                                        id="workarea"
                                        type="dropdown"
                                        menuItems={lightingOptions}
                                        model=".workarea"
                                        defaultValue={editInstance && editInstance.props.workarea}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Label sm={6}>
                                    <label className="checkbox-label" htmlFor="followUp">Reduced Visibility:</label>
                                    <RRFInput
                                        id="reducedVisibility"
                                        type="checkbox"
                                        model=".reducedVisibility"
                                        onChange={this.handleReducedVisibility}
                                        defaultValue={editInstance && editInstance.props.reducedVisibility}
                                    /> 
                                    { this.state.reducedVisibilityValue ?
                                    <RRFInput
                                        id="reducedVisibilityDetail"
                                        type="text" 
                                        model=".reducedVisibilityDetail"
                                        defaultValue={editInstance && editInstance.props.reducedVisibilityDetail}
                                    />
                                    : null }
                                </Label> 
                            </Row>
                        </Col>
                    </Row> 
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Row className="show-grid" >
                            <Col sm={2} ><Label sm={12}>Train</Label></Col>
                            <Col sm={5} xs={12}>
                                <Label sm={12} htmlFor="typeOperation">Type of Operation</Label> 
                                <Col sm={12} className="input">
                                    <RRFInput multi
                                        id="typeOperation"
                                        type="dropdown"
                                        menuItems={typeOptions}
                                        model=".typeOperation"
                                        defaultValue={editInstance && editInstance.props.typeOperation}
                                    />
                                </Col>
                            </Col> 
                            <Col sm={5} xs={12}>
                                <Label sm={12} htmlFor="typeOperation">Transport Type</Label> 
                                <Label sm={12} className="radioInput reporterLocationContent">
                                    <RRFInput
                                        id="transportTypeShoving"
                                        type="radio"
                                        model=".transportType"
                                        radioValue="Shoving"
                                        radioName="transportTypeShoving"
                                        defaultValue={editInstance && editInstance.props.transportType}
                                    /><label htmlFor="transportType">Shoving</label> 
                                
                                    <RRFInput
                                        id="transportTypePulling"
                                        type="radio"
                                        model=".transportType"
                                        radioValue="Pulling"
                                        radioName="transportType" 
                                        defaultValue={editInstance && editInstance.props.transportType}
                                    /><label htmlFor="transportTypePulling">Pulling</label> 
                                
                                    <RRFInput
                                        id="transportTypePush"
                                        type="radio"
                                        model=".transportType"
                                        radioValue="Push/ Pull (passenger)"
                                        radioName="transportType"
                                        defaultValue={editInstance && editInstance.props.transportType}
                                    /><label htmlFor="transportTypePush">Push/ Pull (passenger)</label> 
                                </Label>
                            </Col> 
                        </Row>
                    </Row>

                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Label sm={12} htmlFor="equipment">Equipment</Label>
                        <Row className="show-grid" >
                            <Col sm={2} ><Label sm={12}>Locomotives</Label></Col>
                            <Col sm={5} >
                                <Label sm={8} htmlFor="totalhead">Total Head End</Label>
                                <Col sm={8} className="input">
                                    <RRFInput
                                        id="totalhead"
                                        type="text" 
                                        model=".totalhead"
                                        defaultValue={editInstance && editInstance.props.totalhead}
                                    />
                                </Col>
                                <Label sm={8} htmlFor="locomotive">Locomotive Make/Model</Label>
                                <Col sm={8} className="input">
                                    <RRFInput
                                        id="locomotive"
                                        type="text" 
                                        model=".locomotive"
                                        defaultValue={editInstance && editInstance.props.locomotive}
                                    />
                                </Col> 
                                    <Label sm={9} >Remote Control</Label> 
                                    <Label sm={5}>
                                        <RRFInput
                                            id="remoteControlYes"
                                            type="radio"
                                            model=".remoteControl"
                                            radioValue="yes"
                                            radioName="remoteControl"
                                            defaultValue={editInstance && editInstance.props.remoteControl}
                                        /><label htmlFor="remoteControlYes">Yes</label>  
                                    </Label>
                                    <Label sm={3}>
                                        <RRFInput
                                            id="remoteControlNo"
                                            type="radio"
                                            model=".remoteControl"
                                            radioValue="no"
                                            radioName="remoteControl"
                                            defaultValue={editInstance && editInstance.props.remoteControl}
                                        /><label htmlFor="remoteControlNo">No</label> 
                                    </Label> 
                            </Col>
                            <Col sm={5} >                            
                                <Row className="show-grid">
                                    <Label sm={8} htmlFor="cst">Control Stand Type</Label>
                                    <Col sm={8} className="input">
                                        <RRFInput
                                            id="cst"
                                            type="text" 
                                            model=".cst"
                                            defaultValue={editInstance && editInstance.props.cst}
                                        />
                                    </Col>
                                </Row>
                                <Row className="show-grid" >
                                    <Label sm={8} htmlFor="helpers"># of Helpers</Label>
                                    <Col sm={8} className="input">
                                        <RRFInput
                                            id="helpers"
                                            type="text" 
                                            model=".helpers"
                                            defaultValue={editInstance && editInstance.props.helpers}
                                        />
                                    </Col>
                                </Row>    
                                <Label sm={9} >Distributed Power</Label> 
                                    <Label sm={5}>
                                        <RRFInput
                                            id="distributedPowerYes"
                                            type="radio"
                                            model=".distributedPower"
                                            radioValue="yes"
                                            radioName="distributedPower"
                                            defaultValue={editInstance && editInstance.props.distributedPower}
                                        /><label htmlFor="distributedPowerYes">Yes</label> 
                                    </Label>
                                <Label sm={3}>
                                    <RRFInput
                                        id="distributedPowerNo"
                                        type="radio"
                                        model=".distributedPower"
                                        radioValue="no"
                                        radioName="distributedPower"
                                        defaultValue={editInstance && editInstance.props.distributedPower}
                                    /><label htmlFor="distributedPowerNo">No</label>  
                                </Label>
                                                   
                            </Col>
                        </Row>
                    </Row>

                    <Row className="show-grid" style={{marginTop: '15px'}} >
                        <Col sm={2} ><Label sm={12}>Passenger</Label></Col>
                        <Col sm={5} >
                            <Label sm={8} htmlFor="noofcars">No of Cars</Label>
                            <Col sm={8} className="input">
                                <RRFInput
                                    id="noofcars"
                                    type="text" 
                                    model=".noofcars"
                                    defaultValue={editInstance && editInstance.props.noofcars}
                                />
                            </Col>
                            <Label sm={9} >Cab Car Controlling</Label> 
                            <Label sm={5}>
                                <RRFInput
                                    id="carCabControllingYes"
                                    type="radio"
                                    model=".carCabControlling"
                                    radioValue="yes"
                                    radioName="carCabControlling"
                                    defaultValue={editInstance && editInstance.props.carCabControlling}
                                /><label  htmlFor="carCabControllingYes">Yes</label> 
                            </Label>
                            <Label sm={3}>
                                <RRFInput
                                    id="carCabControllingNo"
                                    type="radio"
                                    model=".carCabControlling"
                                    radioValue="no"
                                    radioName="carCabControlling"
                                    defaultValue={editInstance && editInstance.props.carCabControlling}
                                /><label htmlFor="carCabControllingNo">No</label>
                            </Label>
                        </Col>
                        <Col sm={5} >
                            <Row className="show-grid" >
                                <Label sm={8} htmlFor="noinservice">No in Service</Label>
                                <Col sm={8} className="input">
                                    <RRFInput
                                        id="noinservice"
                                        type="text" 
                                        model=".noinservice"
                                        defaultValue={editInstance && editInstance.props.noinservice}
                                    />
                                </Col>                        
                            </Row>                        
                        </Col>
                    </Row> 
                    <Row className="show-grid" style={{marginTop: '15px'}} >
                        <Col sm={2} ><Label sm={12}>Freight</Label></Col>
                        <Col sm={2} >
                            <Label sm={12} htmlFor="loads">Loads</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="loads"
                                    type="text"
                                    model=".loads"
                                    defaultValue={editInstance && editInstance.props.loads}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} >
                            <Label sm={12} htmlFor="empties">Empties</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="empties"
                                    type="text"
                                    model=".empties"
                                    defaultValue={editInstance && editInstance.props.empties}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} >
                            <Label sm={12} htmlFor="tons">Tons</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="tons"
                                    type="text"
                                    model=".tons"
                                    defaultValue={editInstance && editInstance.props.tons}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} >
                            <Label sm={12} htmlFor="length">Length</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="length"
                                    type="text"
                                    model=".length"
                                    defaultValue={editInstance && editInstance.props.length}
                                />
                            </Col>
                        </Col>
                    </Row> 
                    <Col sm={12} >
                        <Label sm={2}></Label> 
                        <Label sm={8} className="radioInput" style={{marginTop: '15px'}}>
                            <RRFInput
                                id="typeUT"
                                type="radio"
                                model=".type"
                                radioValue="unittrain"
                                radioName="type" 
                                onChange={this.handleType}
                                defaultValue={editInstance && editInstance.props.type}
                            /><label htmlFor="typeUT">Unit Train </label>
                            <RRFInput
                                id="typeMF"
                                type="radio"
                                model=".type"
                                radioValue="mixedfreight"
                                radioName="type"
                                onChange={this.handleType}
                                defaultValue={editInstance && editInstance.props.type}
                            /><label htmlFor="typeMF">Mixed Freight</label>
                            <RRFInput
                                id="typeIT"
                                type="radio"
                                model=".type"
                                radioValue="intermodal"
                                radioName="type"
                                onChange={this.handleType}
                                defaultValue={editInstance && editInstance.props.type}
                            /><label htmlFor="typeIT">Intermodal Train</label>
                            <RRFInput
                                id="typeOther"
                                type="radio"
                                model=".type"
                                radioValue="other"
                                radioName="type"
                                onChange={this.handleType}
                                defaultValue={editInstance && editInstance.props.type}
                            /><label htmlFor="typeOther">Other</label>   
                            { this.state.typeValue==='other'?
                                <RRFInput
                                    id="otherType"
                                    type="text"
                                    model=".otherType"
                                    defaultValue={editInstance && editInstance.props.otherType}
                                />
                            : null }
                        </Label>
                    </Col> 
                    <Row className="show-grid" style={{marginTop: '25px'}} >
                        <Col sm={2} ><Label sm={12}>Operating Rules</Label></Col>                             
                        <Col sm={10} style={{marginTop: '25px'}} >
                            <Row className="show-grid">
                                <Label sm={4}>
                                    <RRFInput
                                        id="operatingRulesGCOR"
                                        type="radio"
                                        model=".operatingRules"
                                        radioValue="GCOR"
                                        radioName="operatingRules" 
                                        onChange={this.handleOperatingRules}
                                        defaultValue={editInstance && editInstance.props.operatingRules}
                                    /><label htmlFor="operatingRulesGCOR">GCOR </label>  
                                </Label>
                                <Label sm={4}>
                                    <RRFInput
                                        id="typeoperatingRules"
                                        type="radio"
                                        model=".operatingRules"
                                        radioValue="NORAC"
                                        radioName="operatingRules" 
                                        onChange={this.handleOperatingRules}
                                        defaultValue={editInstance && editInstance.props.operatingRules}
                                    /><label htmlFor="typeoperatingRules">NORAC </label>  
                                </Label>
                                <Label sm={4}>
                                    <RRFInput
                                        id="operatingRulesOther"
                                        type="radio"
                                        model=".operatingRules"
                                        radioValue="Other"
                                        radioName="operatingRules"
                                        onChange={this.handleOperatingRules}
                                        defaultValue={editInstance && editInstance.props.operatingRules}
                                    /><label htmlFor="operatingRulesOther">Other </label>
                                    { this.state.operatingRuleValue==='Other'?
                                        <RRFInput
                                            id="operatingRulesOthers"
                                            type="text" 
                                            model=".operatingRulesOthers"
                                            defaultValue={editInstance && editInstance.props.operatingRulesOthers}
                                        />
                                    : null }
                                </Label> 
                            </Row>
                        </Col>
                        <Col sm={12} >
                            <Label sm={2}></Label>
                            <Label sm={4} >Were job/safety briefings completed?</Label> 
                            <Label sm={6} className="radioInput reporterLocationContent" style={{marginTop: '5px'}}>
                                <RRFInput
                                    id="jobCompletedYes"
                                    type="radio"
                                    model=".jobCompleted"
                                    radioValue="yes"
                                    radioName="jobCompleted" 
                                    defaultValue={editInstance && editInstance.props.jobCompleted}
                                /><label htmlFor="jobCompletedYes">Yes </label> 
                                <RRFInput
                                    id="jobCompletedNo"
                                    type="radio"
                                    model=".jobCompleted"
                                    radioValue="no"
                                    radioName="jobCompleted" 
                                    defaultValue={editInstance && editInstance.props.jobCompleted}
                                /><label htmlFor="jobCompletedNo">No </label>
                            </Label>
                        </Col>
                    </Row> 
                    <Row className="show-grid" style={{marginTop: '15px'}}>          
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="trainLocation">Train Location</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="trainLocation"
                                    type="dropdown"
                                    menuItems={locationOptions}
                                    model=".trainLocation"
                                    onSelect={this.handleTrainLocationDropdown} 
                                    defaultValue={editInstance && editInstance.props.trainLocation}
                                />
                            </Col>
                            { this.state.trainLocationValue==='Other'?
                                <Col sm={12} style={{marginTop:'10px'}} >
                                    <Label sm={10} style={{right:'2px'}}>
                                        other: <RRFInput
                                        id="trainLocationOther"
                                        type="text"
                                        model=".trainLocationOther"
                                        defaultValue={editInstance && editInstance.props.trainLocationOther}
                                    />
                                    </Label> 
                                </Col>
                            : null }
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="operation">Methods of Operation</Label>
                            <Col sm={12} className="input">
                                <RRFInput 
                                    id="operation"
                                    type="dropdown"
                                    menuItems={operationOptions}
                                    model=".operation"
                                    onSelect={this.handleOperationOptionsDropdown} 
                                    defaultValue={editInstance && editInstance.props.operation}
                                />
                            </Col>
                            { this.state.operationValue==='Other'?
                                <Col sm={12} style={{marginTop:'10px'}}>
                                    <Label sm={10} style={{right:'2px'}}>
                                        other: <RRFInput
                                            id="operationOther"
                                            type="text"
                                            model=".operationOther"
                                            defaultValue={editInstance && editInstance.props.operationOther}
                                        />
                                    </Label> 
                                </Col>
                            : null }
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="trainActivity">Train Activity</Label>
                            <Col sm={12} className="input">
                                <RRFInput 
                                    id="trainActivity"
                                    type="dropdown"
                                    menuItems={trainActivityOptions}
                                    model=".trainActivity"
                                    onSelect={this.handleTrainActivityDropdown}
                                    defaultValue={editInstance && editInstance.props.trainActivity}
                                />
                            </Col>
                            { this.state.trainActivityValue==='Other'?
                                <Col sm={12} style={{marginTop:'10px'}}>
                                    <Label sm={10} style={{right:'2px'}}>
                                        other: <RRFInput
                                            id="trainActivityOther"
                                            type="text"
                                            model=".trainActivityOther"
                                            defaultValue={editInstance && editInstance.props.trainActivityOther}
                                        />
                                    </Label> 
                                </Col>
                            : null }
                        </Col>
                    </Row>

                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={12} ><Label sm={12}>Describe Event / Situation</Label>
                            <Label sm={12}>
                            <RRFInput
                                id="descriveEventSituation"
                                type="textarea"
                                //placeholder="Enter No of Years"
                                model=".descriveEventSituation"
                                defaultValue={editInstance && editInstance.props.descriveEventSituation}
                            />           
                            </Label>  </Col>
                    </Row> 
                    <Row className="show-grid">
                        <Col /* smOffset={4} */ sm={6} xs={12}>
                            <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                            <Col sm={12} className="input">
                                <FileInput
                                    model=".transImages"
                                    multiple={true}
                                    id="upload-images"
                                    defaultValue={editInstance && editInstance.props.imageUrls}
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                            <Col sm={12} className="input">
                                <VideoInput
                                    model=".transVideos"
                                    multiple={true}
                                    id="upload-videos"
                                    defaultValue={editInstance && editInstance.props.videoUrls}
                                />
                            </Col>
                        </Col>
                    </Row> 
            </fieldset>
            <Row className="show-grid">
                <div className="form-button" style={{ marginTop: '50px', textAlign: 'center' }} >
                    <Button style={{ color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white', }} redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                        <i className="fa fa-ban" aria-hidden="true"></i> &nbsp; Cancel
                    </Button>
                    <Button style={{ color: '#FFFFFF', border: 'none', backgroundColor: '#26A65B' }} >
                        <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                &nbsp;
                            { editInstance ? 'Update' : 'Submit' }
                    </Button>
                </div>
            </Row>
            <Alert id="trans-form" className="danger-alert" />
        </Form>
        );
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
            
            <Async
                identifier="JSAForm"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderFormContent()}
            />
            </BasePage>
        );
    }
    handleReporterDropdown = (value: any) => {  
        this.setState({ reporterValue: value });
    }
    handleShiftEvent = (value: any) => { 
        this.setState({ shiftEventValue: value });
    } 
    handleReporterLocation = (value: any) => {
        this.setState({ reporterLocationValue: value });
    } 
    handleReducedVisibility = (value: any) => {
        this.setState({ reducedVisibilityValue: value });
    } 
    handleOperatingRules = (value: any) => {
        this.setState({ operatingRuleValue: value });
    }
    handleTrainLocationDropdown = (value: any)=> {
        this.setState({ trainLocationValue: value });
    }
    handleOperationOptionsDropdown = (value: any)=> {
        this.setState({ operationValue: value });
    }
    handleTrainActivityDropdown = (value: any)=> {
        this.setState({ trainActivityValue: value });
    }
    handleType = (value: any)=> {
        this.setState({ typeValue: value });
    }

}

export function mapStateToProps(state: any, ownProps: { match: { params: { id: any; }; }; }) {
    const groupInstances = GroupModel.list(state);
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);  
    const editId = ownProps.match.params.id;
    //const editInstance = TransModel.get(ownProps.match.params.id);   
    const editInstance = editId && state.report.get('reportDetailData'); 
    // const userInstance = state.models.get(`user${userId}`)

    return {
        groupInstances,
        userInstance,
        userUID: state.login.get('userUID'),
        userId, forms: state.forms, editId, editInstance
    };
}

export const TransForm = withRouter(connect<ITransFormProps, any, any>(mapStateToProps)(TransFormImpl));