import * as React from 'react'; 
import { getAllGroups } from '../../../services/groupService';
import { GroupModel } from '../../../model/GroupModel';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Async } from '../../reusableComponents/Async';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage';
import { Row, Col } from 'react-bootstrap';
import { Title } from '../../reusableComponents/Title';
import { Alert } from '../../Alert';
import './mechForm.scss'; 
import { Button } from '../../reusableComponents/FormComponents/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { IHistory, ISelectOptions, IForms } from '../../../interfaces';
import { Loader } from '../../reusableComponents/Loader';
import { UserModel } from '../../../model/UserModel';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput'; 
import PropTypes from 'prop-types';
import { FileInput } from '../../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../../reusableComponents/FormComponents/VideoInput';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';  
import { white } from 'material-ui/styles/colors';  
import { MechModel, IMechModelProps } from '../../../model/MechModel';
import { isFormEmpty, showAlert,constructAnswers } from '../../../utils/generalUtils';  
import { submitMechForm } from '../../../services/closeCallService';
import { format } from 'date-fns';

export interface IMechFormProps {  
    history?: IHistory;
    forms?: IForms;
    userUID: string;
    userId: string;
    company?: string;
    userInstance?: UserModel; 
    instance?: MechModel;
    editInstance?: MechModel;
    editId?: string; 
    closeCallReportDetails?: any;
} 
export interface IMechFormState { 
    signature: File | string;
    signatureURL: string;
    isSignatureUploaded: boolean;
    signNew: boolean;
    stateOptions?: ISelectOptions[];
    reporterOptions?: ISelectOptions[];
    certificationOptions?: ISelectOptions[];
    activityOptions?: ISelectOptions[];
    weatherOptions?: ISelectOptions[];
    outdoorOptions?: ISelectOptions[];
    lightingOptions?: ISelectOptions[];
    locationOptions?: ISelectOptions[];
    reporterValue?: string;
    activityValue?: string;
    shiftEventValue?: string;
    reporterLocationValue?: string;
    reducedVisibilityValue?: boolean;
    typeValue?: string;
    operatingRuleValue?: string;
}

export class MechFormImpl extends React.PureComponent<IMechFormProps, IMechFormState> { 
    constructor(props: IMechFormProps | Readonly<IMechFormProps>) { 
        super(props);  
        this.state = { 
            signature: '',
            signatureURL: '', isSignatureUploaded: false, 
            signNew: false,
            reporterOptions: [{ value: 'Boiler Maker', label: 'Boiler Maker' }, { value: 'Foreman', label: 'Foreman' }, { value: 'Machinist', label: 'Machinist' },
            { value: 'Trainee', label: 'Trainee' }, { value: 'Carman', label: 'Carman' }, { value: 'Hostler', label: 'Hostler' },
            { value: 'Manager', label: 'Manager' }, { value: 'Electrician', label: 'Electrician' }, { value: 'Laborer', label: 'Laborer' },
            { value: 'Pipe Fitter', label: 'Pipe Fitter' }, { value: 'Other', label: 'Other' }], 
            certificationOptions: [{ value: 'Air Brake Inspections', label: 'Air Brake Inspections' }, { value: 'Freight Car Inspection', label: 'Freight Car Inspection' },
            { value: 'Rear End Marker', label: 'Rear End Marker / EOT' },{ value: 'Blue Signal Protection', label: 'Blue Signal Protection' },{ value: 'Locomotive Engineer Certification', label: 'Locomotive Engineer Certification' },
            { value: 'Safety Appliances', label: 'Safety Appliances' },{ value: 'Conductor Certification', label: 'Conductor Certification' },{ value: 'Locomotive Inspection', label: 'Locomotive Inspection' },
            { value: 'FRA Glazing', label: 'FRA Glazing' },{ value: 'Passenger Car Inspection', label: 'Passenger Car Inspection' },{ value: 'Other', label: 'Other' },], 
            activityOptions: [{ value: 'Blocking', label: 'Blocking/Jacking Rerailing' }, { value: 'Installation', label: 'Installation' }, { value: 'Scheduled Maintenance', label: 'Scheduled Maintenance' },
            { value: 'Documentation', label: 'Documentation' }, { value: 'Operating Vehicle', label: 'Operating Vehicle/Equipment' }, { value: 'Testing', label: 'Testing' },
            { value: 'Inspection', label: 'Inspection' }, { value: 'Repair', label: 'Repair/Replace' }, { value: 'Other', label: 'Other' },],

            weatherOptions: [{ value: 'Clear', label: 'Clear' }, { value: 'Ice', label: 'Ice' }, { value: 'Wind', label: 'Wind' },
            { value: 'Fog', label: 'Fog' }, { value: 'Rain', label: 'Rain' }, { value: 'Haze', label: 'Haze/Smoke' },
            { value: 'Hail', label: 'Hail' }, { value: 'Snow', label: 'Snow' }, { value: 'Thunderstorm', label: 'Thunderstorm/Lightning' },],
        
            outdoorOptions: [{ value: 'Dawn', label: 'Dawn' }, { value: 'Daylight', label: 'Daylight' },
            { value: 'Dusk', label: 'Dusk' },{ value: 'Night', label: 'Night' }], 
            
            lightingOptions: [{ value: 'High', label: 'High' }, { value: 'Medium', label: 'Medium' },
            { value: 'Low', label: 'Low' }, { value: 'Off', label: 'Off' }], 

            locationOptions: [{ value: 'Main Track', label: 'Main Track' }, { value: 'Passenger Station', label: 'Passenger Station' },
            { value: 'Repair Facility', label: 'Repair Facility' }, { value: 'Yard', label: 'Yard' }, { value: 'Facility', label: 'Facility' },
            { value: 'Other', label: 'Other' }], 

            stateOptions: [{value:'Texas',  label:'Texas'}, {value:'Arkansas',  label:'Arkansas'},{value:'Okhlohoma',  label:'Okhlohoma'},
            {value:'Ohio',  label:'Ohio'}, {value:'Louisiana',  label:'Louisiana'}],
            /* reporterValue: editInstance && editInstance.props.reporter || '', activityValue: editInstance && editInstance.props.activity || '', reporterLocationValue: editInstance && editInstance.props.reporterLocation || '', shiftEventValue: editInstance && editInstance.props.shiftEvent || '', reducedVisibilityValue: editInstance && editInstance.props.reducedVisibility? true : false, typeValue:  editInstance && editInstance.props.type || '', operatingRuleValue: editInstance && editInstance.props.operatingRules || '', */
            reporterValue: '', activityValue: '', reporterLocationValue: '', shiftEventValue: '', reducedVisibilityValue: false, typeValue: '', operatingRuleValue: '',
        };
    }  
   /*  componentDidMount() {
        const { instance } = this.props;
    }  */
    getChildContext() {
        return { formModel: 'forms.mechForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    };  

    promise = async () => {
        if (GroupModel.list().length === 0) {
            await getAllGroups();
        }
        return null;
    } 
    handleSubmit = ({ eventtype, eventlocation,facility, involvedWorkers, milepost, nearstation, phone, hours, carrier, state, Name, date, time, address, city,stateString, zip, reporter, reporterOther, certification,  activity, activityOther, railroadyears, yearscraft, groupsize, shiftEvent, otherShiftEvent, reporterLocation, otherReporterLocation, weather, outdoors, workarea, reducedVisibility,reducedVisibilityDetail, totalhead, locomotive, remoteControl, distributedPower,  positionInTrain, noofcars, noinservice, carCabControlling, loads, empties, tons, length, recordsComplete, serviceReleased, requiredDocuments, movingForRepair, maintenceDeferred,type, otherType, involvedcar,location, operatingRules, operatingRulesOthers,descriveEventSituation }: IMechModelProps) => { 
        const { userUID, forms, history, editId } = this.props;  
        const answerData = {'Type of Event':eventtype, 'Event Location':eventlocation,'Facility':facility,'Involved Co-Workers': involvedWorkers, 'Milepost':milepost, 'Nearest Station':nearstation, 'Primary':phone,'Hours': hours, 'Carrier/Railroad':carrier, 'Event State':state, 'Name':Name, 'Date of Occurence':date, 'Local Time (12 HR Clock)':time, 'Address':address, 'City':city,'State':stateString, 'ZIP':zip, 'Reporter':reporter, 'Reporter Other':reporterOther, 'Certification':certification, 'Activity':activity, 'Activity Other':activityOther, 'Railroad Years':railroadyears, 'Years in Craft':yearscraft, 'Work Group Size':groupsize, 'Shift During Event':shiftEvent, 'Shift During Event Other':otherShiftEvent, 'Reporter Location':reporterLocation, 'Reporter Location Other':otherReporterLocation, 'Weather':weather, 'Outdoors':outdoors, 'Work Area Lightning':workarea, 'Reduced Visibility':reducedVisibility,'Reduced Visibility Detail':reducedVisibilityDetail, 'Total Head End':totalhead, 'Locomotive Make/Model':locomotive, 'Remote Control':remoteControl, 'Distributed Power':distributedPower,  'Position in Train':positionInTrain, 'No of Cars':noofcars, 'No in Service':noinservice, 'Cab Car Controlling':carCabControlling, 'Loads':loads, 'Empties':empties, 'Tons':tons, 'Length':length, 'Records Complete':recordsComplete, 'Released for Service':serviceReleased, 'Required / correct documents on board':requiredDocuments, 'Moving for repair':movingForRepair, 'Maintenance Deferred':maintenceDeferred,'Type':type, 'Other Type':otherType, 'Involved Car Kind':involvedcar,'Location':location, 'Operating Rules':operatingRules, 'Operating Rules Other':operatingRulesOthers,'Describe Event / Situation':descriveEventSituation};
        const submittedValues = {
             eventtype, eventlocation, facility, involvedWorkers, milepost, nearstation/* , phone, hours, carrier, state, Name, date, time, address, city,stateString, zip, reporter, certification,  activity, railroadyears, yearscraft, groupsize, shiftEvent,reporterLocation, weather, outdoors, workarea, totalhead, locomotive, remoteControl, distributedPower,  positionInTrain, noofcars, noinservice, carCabControlling, loads, empties, tons, length, recordsComplete, serviceReleased, requiredDocuments, movingForRepair, maintenceDeferred,type, involvedcar, operatingRules, descriveEventSituation */
        };  
        /* if(this.state.reporterValue==='Other')
            submittedValues['reporterOther'] = reporterOther; 
        if(this.state.activityValue==='Other')
            submittedValues['activityOther'] = activityOther; 
        if(this.state.reporterLocationValue==='other')
            submittedValues['otherReporterLocation'] = otherReporterLocation;
        if(this.state.shiftEventValue==='other')
            submittedValues['otherShiftEvent'] = otherShiftEvent;   
        if(this.state.reducedVisibilityValue) {
            submittedValues['reducedVisibility'] = reducedVisibility; 
            submittedValues['reducedVisibilityDetail'] = reducedVisibilityDetail; 
        }
        if(this.state.typeValue==='Other' )
            submittedValues['otherType'] = otherType; 
        if(this.state.operatingRuleValue==='Other' )
            submittedValues['operatingRulesOthers'] = operatingRulesOthers;   */ 
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'mech-form', 'danger');
            return;
        } 
        let answers = constructAnswers(answerData);
        return submitMechForm(userUID, answers, history, forms, editId); 
    }
    
    renderFormContent() {
        const {handleReporterDropdown,handleShiftEvent, handleActivityDropdown, state:{reporterOptions, certificationOptions, activityOptions, weatherOptions, outdoorOptions, lightingOptions, locationOptions, stateOptions} } = this; 
        let { editInstance, editId, history } = this.props; 
        if( (editId && ( !editInstance || editInstance.props===undefined ) ) ) {
            history?.push('/close-call-reports');
            return <SubmissionLoader/>
        } else if(editId && editInstance && editInstance.props) { // this.setState({ activityValue: value }); 
            this.setState({reporterValue: editInstance.props.reporter || '',activityValue: editInstance.props.activity || '', reporterLocationValue: editInstance.props.reporterLocation || '', shiftEventValue: editInstance.props.shiftEvent || '', reducedVisibilityValue: editInstance.props.reducedVisibility? true : false, typeValue: editInstance.props.type || '', operatingRuleValue: editInstance.props.operatingRules || ''},); 
        } 
        return (
            <Form loader={<SubmissionLoader />} model="forms.mechForm" onSubmit={this.handleSubmit}>
                <Title titleColor="yellow" text="C3RS Mechanical Form" noNeedBorder={true} />
                {/* <Alert id="mech-form" className="danger-alert" />  */}
                {/* <Alert className="success-alert" id="mech-form-success" /> */}
                <AlertModal id="mech-form-modal" />
                <fieldset>
                    <legend><span style={{ color: 'yellow' }}>Mechanical Form</span></legend> 
                    <Row className="show-grid">
                        
                        <Col sm={5} xs={12}>
                            <Label required sm={12} htmlFor="eventtype">Type of Event:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="eventtype"
                                    type="text"
                                    placeholder="Enter event name..."
                                    model=".eventtype" 
                                    defaultValue={editInstance && editInstance.props.eventtype}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="eventlocation">Event Location:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="eventlocation"
                                    type="text"
                                    placeholder="Enter location..."
                                    model=".eventlocation" 
                                    defaultValue={editInstance && editInstance.props.eventlocation}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="facility">Facility:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="facility"
                                    type="text"
                                    placeholder="Enter name of Facility.."
                                    model=".facility"
                                    defaultValue={editInstance && editInstance.props.facility}
                                   />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{ marginTop: '15px' }}>
                        <Col sm={5} xs={12}>
                            <Label required sm={12} htmlFor="involvedWorkers">Involved Co-Workers:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="involvedWorkers"
                                    type="text"
                                    placeholder="Enter name of coworkers."
                                    model=".involvedWorkers"
                                    defaultValue={editInstance && editInstance.props.involvedWorkers}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="milepost">Milepost:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="milepost"
                                    type="text"
                                    placeholder="Enter milepost..."
                                    model=".milepost"
                                    defaultValue={editInstance && editInstance.props.milepost}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <Label required sm={12} htmlFor="nearstation">Nearest Station</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="nearstation"
                                    type="text"
                                    placeholder="Enter nearest station..."
                                    model=".nearstation"
                                    defaultValue={editInstance && editInstance.props.nearstation}
                                />
                            </Col>
                        </Col>
                    </Row> 
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <p style={{color:white}}>Telephone Number where we may reach you for further details</p>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="phone">Primary</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="phone"
                                    type="text"
                                    placeholder="XXX-XXX-XXXX"
                                    model=".phone"
                                    defaultValue={editInstance && editInstance.props.phone}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="hours">Hours</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="hours"
                                    type="time"
                                    model=".hours"
                                    defaultValue={editInstance && editInstance.props.hours}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}/>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="carrier">Carrier/Railroad</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="carrier"
                                    type="text"
                                    placeholder="Enter carrier name..."
                                    model=".carrier"
                                    defaultValue={editInstance && editInstance.props.carrier}
                                />
                            </Col>
                        </Col> 
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="carrier">State</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="state"
                                    type="dropdown"
                                    placeholder="Select State"
                                    menuItems={stateOptions}
                                    model=".state"    
                                    defaultValue={editInstance && editInstance.props.state}                                
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={5} xs={12}>
                            <Label sm={12} htmlFor="name">Name</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    model=".Name"
                                    defaultValue={editInstance && editInstance.props.Name}
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}/>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="date">Date of Occurence</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="date"
                                    type="date"
                                    placeholder="HH:MM"
                                    model=".date" 
                                    defaultValue={editInstance ? (editInstance.props.date? format(new Date(editInstance.props.date), 'yyyy-MM-dd')
                                    : format(new Date(editInstance.props.createdDate), 'yyyy-MM-dd')):'' }
                                    maxdate={ ( editInstance && format(new Date(editInstance.props.createdDate), 'yyyy-MM-dd') ) || format(new Date(), 'yyyy-MM-dd')}
                                />
                            </Col>
                        </Col> 
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="time">Local Time (12 HR Clock)</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="time"
                                    type="time"
                                    model=".time"
                                    defaultValue={editInstance && editInstance.props.time}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={5} xs={12}>
                            <Label sm={12} htmlFor="address">Address</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="address"
                                    type="text"
                                    placeholder="Enter Address"
                                    model=".address"
                                    defaultValue={editInstance && editInstance.props.address}                                    
                                />
                            </Col>
                        </Col>
                        <Col sm={1} xs={12}></Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="city">City</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="city"
                                    type="text"
                                    placeholder="Enter City Name"
                                    model=".city" 
                                    defaultValue={editInstance && editInstance.props.city}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="carrier">State</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="stateString"
                                    type="dropdown"
                                    placeholder="Select State"
                                    menuItems={stateOptions}
                                    model=".stateString"
                                    defaultValue={editInstance && editInstance.props.stateString}
                                />
                            </Col>
                        </Col>
                        <Col sm={2} xs={12}>
                            <Label sm={12} htmlFor="zip">ZIP</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="zip"
                                    type="text"
                                    placeholder="Enter Zipcode"
                                    model=".zip"
                                    defaultValue={editInstance && editInstance.props.zip}
                                />
                            </Col>
                        </Col> 
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>          
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="reporter">Reporter</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="reporter"
                                    type="dropdown"
                                    menuItems={reporterOptions}
                                    model=".reporter"
                                    onSelect={handleReporterDropdown}
                                    defaultValue={editInstance && editInstance.props.reporter}
                                />
                            </Col>
                            { this.state.reporterValue === 'Other' ?
                                <Col sm={12} id="reporterOtherContent" >
                                    <Label sm={10} style={{right:'2px'}}>
                                        other: <RRFInput
                                            id="reporterOther"
                                            type="text" 
                                            model=".reporterOther"
                                            defaultValue={editInstance && editInstance.props.reporterOther}
                                        /> 
                                    </Label> 
                                </Col> 
                            : null }
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="certification">Certification</Label>
                            <Col sm={12} className="input">
                                <RRFInput multi
                                    id="certification"
                                    type="dropdown"
                                    menuItems={certificationOptions}
                                    model=".certification"
                                    defaultValue={editInstance && editInstance.props.certification}
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="activity">Activity</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="activity"
                                    type="dropdown"
                                    menuItems={activityOptions}
                                    model=".activity"
                                    onSelect={handleActivityDropdown}
                                    defaultValue={editInstance && editInstance.props.activity}
                                />
                            </Col>
                            { this.state.activityValue === 'Other' ? 
                                <Col sm={12}>
                                <Label sm={10} style={{right:'2px'}}  >
                                    other: <RRFInput
                                        id="activityOther"
                                        type="text" 
                                        model=".activityOther"
                                        defaultValue={editInstance && editInstance.props.activityOther}
                                    />
                                </Label> 
                                </Col> 
                            : null }
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={4} xs={12}>
                            <Label sm={12} htmlFor="certification">Reporter Experience</Label>
                            <Label sm={12} htmlFor="railroadyears">Railroad Years</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="railroadyears"
                                    type="text"
                                    placeholder="Enter No of Years"
                                    model=".railroadyears"
                                    defaultValue={editInstance && editInstance.props.railroadyears}
                                />
                            </Col> 
                            <Label sm={12} htmlFor="yearscraft">Years in Craft</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="yearscraft"
                                    type="text"
                                    placeholder="Enter No of Years"
                                    model=".yearscraft"
                                    defaultValue={editInstance && editInstance.props.yearscraft}
                                />
                            </Col> 
                            <Label sm={12} htmlFor="groupsize">Work Group Size</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="groupsize"
                                    type="text" 
                                    model=".groupsize"
                                    defaultValue={editInstance && editInstance.props.groupsize}
                                />
                            </Col>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} >Shift During Event</Label> 
                            <Label sm={12} >At time of incident, were you on:</Label>  
                            <Label sm={12} className="radioInput" >
                                <RRFInput
                                    id="shiftEventOD"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="Overtime Duty"
                                    radioName="shiftEvent"
                                    onChange={handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent}
                                /><label htmlFor="shiftEventOD">Overtime Duty</label> 
                            </Label> 
                            <Label sm={12} className="radioInput">
                                <RRFInput
                                    id="shiftEventED"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="Emergency Duty"
                                    radioName="shiftEvent"
                                    onChange={handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent }
                                /><label htmlFor="shiftEventED">Emergency Duty</label> 
                            </Label> 
                            <Label sm={12} className="radioInput">
                                <RRFInput
                                    id="shiftEventOther"
                                    type="radio"
                                    model=".shiftEvent"
                                    radioValue="other" 
                                    radioName="shiftEvent" 
                                    onChange={handleShiftEvent}
                                    defaultValue={editInstance && editInstance.props.shiftEvent }
                                /><label htmlFor="shiftEventOther">Other</label>
                                { this.state.shiftEventValue === 'other' ?
                                    <RRFInput
                                        id="otherShiftEvent"
                                        type="text" 
                                        model=".otherShiftEvent"
                                        defaultValue={editInstance && editInstance.props.otherShiftEvent}
                                    />
                                : null }
                            </Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} >Reporter Location</Label> 
                            <Label sm={12} className="radioInput reporterLocationContent">
                                <RRFInput
                                    id="reporterLocationYard"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="no"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationYard">Yard</label> 
                            
                                <RRFInput
                                    id="reporterLocationShop"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Shop"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationShop">Shop</label> 
                             
                                <RRFInput
                                    id="reporterLocationOT"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Other Track"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationOT">Other Track</label> 
                             
                                <RRFInput
                                    id="reporterLocationAdjacent"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Adjacent to track/on ground"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationAdjacent">Adjacent to track/on ground </label> 
                             
                                <RRFInput
                                    id="reporterLocationCF"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Office/ Crew Facility"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationCF">Office/ Crew Facility</label>  
                             
                                <RRFInput
                                    id="reporterLocationMP"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue=" On/ under/ between Motive Power"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationMP"> On/ under/ between Motive Power</label> 
                             
                                <RRFInput
                                    id="reporterLocationRE"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="On/ under/ between Rolling Equipment"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationRE">On/ under/ between Rolling Equipment</label> 
                             
                                <RRFInput
                                    id="reporterLocationStationPlatform"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="Station Platform"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationStationPlatform">Station Platform</label> 
                             
                                <RRFInput
                                    id="reporterLocationother"
                                    type="radio"
                                    model=".reporterLocation"
                                    radioValue="other"
                                    radioName="reporterLocation"
                                    onChange={this.handleReporterLocation}
                                    defaultValue={editInstance && editInstance.props.reporterLocation}
                                /><label htmlFor="reporterLocationother">other</label> 
                                { this.state.reporterLocationValue === 'other' ?<RRFInput
                                    id="otherReporterLocation"
                                    type="text" 
                                    model=".otherReporterLocation"
                                    defaultValue={editInstance && editInstance.props.otherReporterLocation}
                                />
                                : null }
                            </Label> 
                        </Col>
                    </Row> 
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Col sm={3} xs={12}>
                            <Label sm={12} htmlFor="weather">Weather</Label> 
                            <Col sm={12} className="input">
                                <RRFInput 
                                    id="weather"
                                    type="dropdown"
                                    menuItems={weatherOptions}
                                    model=".weather"
                                    defaultValue={editInstance && editInstance.props.weather}
                                />
                            </Col>  
                        </Col> 
                        <Col sm={9} xs={12}>
                            <Label sm={12} >Light/Visibility</Label> 
                            <Row className="show-grid">
                                <Label sm={2} htmlFor="outdoors">Outdoors</Label>
                                <Col sm={3} className="input">
                                    <RRFInput 
                                        id="outdoors"
                                        type="dropdown"
                                        menuItems={outdoorOptions}
                                        model=".outdoors"
                                        defaultValue={editInstance && editInstance.props.outdoors}
                                    />
                                </Col> 
                                <Label sm={3} htmlFor="workarea">Work Area Lightning</Label>
                                <Col sm={3} className="input">
                                    <RRFInput 
                                        id="workarea"
                                        type="dropdown"
                                        menuItems={lightingOptions}
                                        model=".workarea"
                                        defaultValue={editInstance && editInstance.props.workarea}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Label sm={6}>
                                    <label className="checkbox-label" htmlFor="followUp">Reduced Visibility:</label>
                                    <RRFInput
                                        id="reducedVisibility"
                                        type="checkbox"
                                        model=".reducedVisibility"
                                        onChange={this.handleReducedVisibility}
                                        defaultValue={editInstance && editInstance.props.reducedVisibility==='1'?true:null}
                                    /> 
                                    { this.state.reducedVisibilityValue ?
                                    <RRFInput
                                        id="reducedVisibilityDetail"
                                        type="text" 
                                        model=".reducedVisibilityDetail"
                                        defaultValue={editInstance && editInstance.props.reducedVisibilityDetail}
                                    />
                                    : null }
                                </Label> 
                            </Row>
                        </Col>
                    </Row>
                    <Row className="show-grid" style={{marginTop: '15px'}}>
                        <Label sm={12} htmlFor="equipment">Equipment</Label>
                        <Row className="show-grid" >
                            <Col sm={2} ><Label sm={12}>Locomotives</Label></Col>
                            <Col sm={3} >
                                <Label sm={12} htmlFor="totalhead">Total Head End</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="totalhead"
                                        type="text" 
                                        model=".totalhead"
                                        defaultValue={editInstance && editInstance.props.totalhead}
                                    />
                                </Col>
                                <Label sm={12} htmlFor="locomotive">Locomotive Make/Model</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="locomotive"
                                        type="text" 
                                        model=".locomotive"
                                        defaultValue={editInstance && editInstance.props.locomotive}
                                    />
                                </Col>
                            </Col>
                            <Col sm={7} >
                                <Row className="show-grid" >
                                    <Label sm={4} >Remote Control</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="remoteControlYes"
                                            type="radio"
                                            model=".remoteControl"
                                            radioValue="yes"
                                            radioName="remoteControl"
                                            defaultValue={editInstance && editInstance.props.remoteControl}
                                        /><label htmlFor="remoteControlYes">Yes</label> 
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="remoteControlNo"
                                            type="radio"
                                            model=".remoteControl"
                                            radioValue="no"
                                            radioName="remoteControl"
                                            defaultValue={editInstance && editInstance.props.remoteControl}
                                        /><label htmlFor="remoteControlNo">No</label> 
                                    </Label>
                                </Row> 
                                <Row className="show-grid" >
                                    <Label sm={4} >Distributed Power</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="distributedPowerYes"
                                            type="radio"
                                            model=".distributedPower"
                                            radioValue="yes"
                                            radioName="distributedPower"
                                            defaultValue={editInstance && editInstance.props.distributedPower}
                                        /><label htmlFor="distributedPowerYes">Yes</label> 
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="distributedPowerNo"
                                            type="radio"
                                            model=".distributedPower"
                                            radioValue="no"
                                            radioName="distributedPower"
                                            defaultValue={editInstance && editInstance.props.distributedPower}
                                        /><label htmlFor="distributedPowerNo">No</label> 
                                    </Label>
                                </Row>
                                <Row className="show-grid">
                                    <Label sm={4} htmlFor="locomotive">Position in Train</Label>
                                    <Col sm={4} className="input">
                                        <RRFInput
                                            id="positionInTrain"
                                            type="text" 
                                            model=".positionInTrain"
                                            defaultValue={editInstance && editInstance.props.positionInTrain}
                                        />
                                    </Col>
                                </Row>                            
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Passenger</Label></Col>
                            <Col sm={3} >
                                <Label sm={12} htmlFor="noofcars">No of Cars</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="noofcars"
                                        type="text" 
                                        model=".noofcars"
                                        defaultValue={editInstance && editInstance.props.noofcars}
                                    />
                                </Col>
                                <Label sm={12} htmlFor="noinservice">No in Service</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="noinservice"
                                        type="text" 
                                        model=".noinservice"
                                        defaultValue={editInstance && editInstance.props.noinservice}
                                    />
                                </Col>
                            </Col>
                            <Col sm={7} >
                                <Row className="show-grid" >
                                    <Label sm={4} >Cab Car Controlling</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="carCabControllingYes"
                                            type="radio"
                                            model=".carCabControlling"
                                            radioValue="yes"
                                            radioName="carCabControlling"
                                            defaultValue={editInstance && editInstance.props.carCabControlling}
                                        /><label  htmlFor="carCabControllingYes">Yes</label> 
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="carCabControllingNo"
                                            type="radio"
                                            model=".carCabControlling"
                                            radioValue="no"
                                            radioName="carCabControlling"
                                            defaultValue={editInstance && editInstance.props.carCabControlling}
                                        /><label htmlFor="carCabControllingNo">No</label> 
                                    </Label>
                                </Row>                        
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Freight</Label></Col>
                            <Col sm={2} >
                                <Label sm={12} htmlFor="loads">Loads</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="loads"
                                        type="text"
                                        model=".loads"
                                        defaultValue={editInstance && editInstance.props.loads}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} >
                                <Label sm={12} htmlFor="empties">Empties</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="empties"
                                        type="text"
                                        model=".empties"
                                        defaultValue={editInstance && editInstance.props.empties}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} >
                                <Label sm={12} htmlFor="tons">Tons</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="tons"
                                        type="text"
                                        model=".tons"
                                        defaultValue={editInstance && editInstance.props.tons}
                                    />
                                </Col>
                            </Col>
                            <Col sm={2} >
                                <Label sm={12} htmlFor="length">Length</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="length"
                                        type="text"
                                        model=".length"
                                        defaultValue={editInstance && editInstance.props.length}
                                    />
                                </Col>
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Status</Label></Col>
                            <Col sm={7}>
                                <Row className="show-grid" >
                                    <Label sm={6} >Records Complete</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="recordsCompleteYes"
                                            type="radio"
                                            model=".recordsComplete"
                                            radioValue="yes"
                                            radioName="recordsComplete"
                                            defaultValue={editInstance && editInstance.props.recordsComplete}
                                        /><label htmlFor="recordsCompleteYes">Yes</label>  
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="recordsCompleteNo"
                                            type="radio"
                                            model=".recordsComplete"
                                            radioValue="No"
                                            radioName="recordsComplete"
                                            defaultValue={editInstance && editInstance.props.recordsComplete}
                                        /><label htmlFor="recordsCompleteNo">No</label> 
                                    </Label>
                                </Row> 
                                <Row className="show-grid" >
                                    <Label sm={6} >Released for Service</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="serviceReleasedYes"
                                            type="radio"
                                            model=".serviceReleased"
                                            radioValue="Yes"
                                            radioName="serviceReleased"
                                            defaultValue={editInstance && editInstance.props.serviceReleased}
                                        /><label htmlFor="serviceReleasedYes">Yes</label>  
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="serviceReleasedNo"
                                            type="radio"
                                            model=".serviceReleased"
                                            radioValue="No"
                                            radioName="serviceReleased"
                                            defaultValue={editInstance && editInstance.props.serviceReleased}
                                        /><label htmlFor="serviceReleasedNo">No</label> 
                                    </Label>
                                </Row> 
                                <Row className="show-grid" >
                                    <Label sm={6} >Required / correct documents on board</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="requiredDocumentsYes"
                                            type="radio"
                                            model=".requiredDocuments"
                                            radioValue="Yes"
                                            radioName="requiredDocuments"
                                            defaultValue={editInstance && editInstance.props.requiredDocuments}
                                        /><label htmlFor="requiredDocumentsYes">Yes</label>  
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="requiredDocumentsNo"
                                            type="radio"
                                            model=".requiredDocuments"
                                            radioValue="No"
                                            radioName="requiredDocuments"
                                            defaultValue={editInstance && editInstance.props.requiredDocuments}
                                        /><label htmlFor="requiredDocumentsNo">No</label> 
                                    </Label>
                                </Row> 
                                <Row className="show-grid" >
                                    <Label sm={6} >Moving for repair</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="movingForRepairYes"
                                            type="radio"
                                            model=".movingForRepair"
                                            radioValue="Yes"
                                            radioName="movingForRepair"
                                            defaultValue={editInstance && editInstance.props.movingForRepair}
                                        /><label htmlFor="movingForRepairYes">Yes</label>   
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="movingForRepairNo"
                                            type="radio"
                                            model=".movingForRepair"
                                            radioValue="No"
                                            radioName="movingForRepair"
                                            defaultValue={editInstance && editInstance.props.movingForRepair}
                                        /><label htmlFor="movingForRepairNo">No</label>  
                                    </Label>
                                </Row> 
                                <Row className="show-grid" >
                                    <Label sm={6} >Maintenance Deferred</Label> 
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="maintenceDeferredYes"
                                            type="radio"
                                            model=".maintenceDeferred"
                                            radioValue="Yes"
                                            radioName="maintenceDeferred"
                                            defaultValue={editInstance && editInstance.props.maintenceDeferred}
                                        /><label htmlFor="maintenceDeferredYes">Yes</label> 
                                    </Label>
                                    <Label sm={3} className="mt15">
                                        <RRFInput
                                            id="maintenceDeferredNo"
                                            type="radio"
                                            model=".maintenceDeferred"
                                            radioValue="No"
                                            radioName="maintenceDeferred"
                                            defaultValue={editInstance && editInstance.props.maintenceDeferred}
                                        /><label htmlFor="maintenceDeferredNo">No</label> 
                                    </Label>
                                </Row>
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Type</Label></Col>                             
                            <Col sm={10} >
                                <Row className="show-grid" style={{marginTop: '15px'}}>
                                    <Label sm={4}>
                                        <RRFInput
                                            id="typePC"
                                            type="radio"
                                            model=".type"
                                            radioValue="Passenger/Commuter"
                                            radioName="type"
                                            onChange={this.handleType}
                                            defaultValue={editInstance && editInstance.props.type}
                                        /><label htmlFor="typePC">Passenger/Commuter </label>  
                                    </Label>
                                    <Label sm={3}>
                                        <RRFInput
                                            id="typeFreight"
                                            type="radio"
                                            model=".type"
                                            radioValue="Freight"
                                            radioName="type"
                                            onChange={this.handleType}
                                            defaultValue={editInstance && editInstance.props.type}
                                        /><label htmlFor="typeFreight">Freight </label> 
                                    </Label>
                                    <Label sm={5}>
                                        <RRFInput
                                            id="typeOther"
                                            type="radio"
                                            model=".type"
                                            radioValue="Other"
                                            radioName="type" 
                                            onChange={this.handleType}
                                            defaultValue={editInstance && editInstance.props.type}
                                        />
                                        <label htmlFor="typeOther">Other </label>
                                        { this.state.typeValue==='Other' ? 
                                        <RRFInput
                                            id="otherType"
                                            type="text" 
                                            model=".otherType"
                                            defaultValue={editInstance && editInstance.props.otherType}
                                        />
                                        : null }
                                        
                                    </Label> 
                                </Row>                             
                                <Row className="show-grid">
                                    <Label sm={3} htmlFor="involvedcar">Involved Car Kind</Label>
                                    <Col sm={2} className="input">
                                        <RRFInput
                                            id="involvedcar"
                                            type="text" 
                                            model=".involvedcar"
                                            defaultValue={editInstance && editInstance.props.involvedcar}
                                        />
                                    </Col>
                                </Row>                
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Location</Label></Col>
                            <Col sm={3} className="input">
                                <RRFInput multi
                                    id="location"
                                    type="dropdown"
                                    menuItems={locationOptions}
                                    model=".location"
                                    defaultValue={editInstance && editInstance.props.location}
                                />
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}} >
                            <Col sm={2} ><Label sm={12}>Operating Rules</Label></Col>                             
                            <Col sm={10} >
                                <Row className="show-grid" style={{marginTop: '15px'}}>
                                    <Label sm={4}>
                                        <RRFInput
                                            id="operatingRulesGCOR"
                                            type="radio"
                                            model=".operatingRules"
                                            radioValue="GCOR"
                                            radioName="operatingRules" 
                                            onChange={this.handleOperatingRules}
                                            defaultValue={editInstance && editInstance.props.operatingRules}
                                        /><label htmlFor="operatingRulesGCOR">GCOR </label>  
                                    </Label>
                                    <Label sm={4}>
                                        <RRFInput
                                            id="typeoperatingRules"
                                            type="radio"
                                            model=".operatingRules"
                                            radioValue="NORAC"
                                            radioName="operatingRules" 
                                            onChange={this.handleOperatingRules}
                                            defaultValue={editInstance && editInstance.props.operatingRules}
                                        /><label htmlFor="typeoperatingRules">NORAC </label>  
                                    </Label>
                                    <Label sm={4}>
                                        <RRFInput
                                            id="operatingRulesOther"
                                            type="radio"
                                            model=".operatingRules"
                                            radioValue="Other"
                                            radioName="operatingRules"
                                            onChange={this.handleOperatingRules}
                                            defaultValue={editInstance && editInstance.props.operatingRules}
                                        /><label htmlFor="operatingRulesOther">Other </label>
                                        { this.state.operatingRuleValue==='Other' ?
                                            <RRFInput
                                                id="operatingRulesOthers"
                                                type="text" 
                                                model=".operatingRulesOthers"
                                                defaultValue={editInstance && editInstance.props.operatingRulesOthers}
                                            />
                                        : null }
                                    </Label> 
                                </Row>
                            </Col>
                        </Row> 
                        <Row className="show-grid" style={{marginTop: '15px'}}>
                            <Col sm={12} ><Label sm={12}>Describe Event / Situation</Label>
                                <Label sm={12}>
                                    <RRFInput
                                        id="descriveEventSituation"
                                        type="textarea" 
                                        model=".descriveEventSituation"
                                        defaultValue={editInstance && editInstance.props.descriveEventSituation}
                                    /> 
                                </Label>
                            </Col>
                        </Row> 
                    </Row> 
                    <Row className="show-grid">
                        <Col /* smOffset={4} */ sm={6} xs={12}>
                            <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                            <Col sm={12} className="input">
                                <FileInput
                                    model=".mechImages"
                                    multiple={true}
                                    id="upload-images"
                                    defaultValue={editInstance && editInstance.props.imageUrls}
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                            <Col sm={12} className="input">
                                <VideoInput
                                    model=".mechVideos"
                                    multiple={true}
                                    id="upload-videos"
                                    defaultValue={editInstance && editInstance.props.videoUrls}
                                />
                            </Col>
                        </Col>
                    </Row>
                </fieldset>
                <Row className="show-grid">
                    <div className="form-button" style={{ marginTop: '50px', textAlign: 'center' }} >
                        <Button style={{ color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white', }} redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                            <i className="fa fa-ban" aria-hidden="true"></i>  &nbsp; Cancel
                        </Button>
                        <Button style={{ color: '#FFFFFF', border: 'none', backgroundColor: '#26A65B' }} >
                        <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                &nbsp;
                            { editInstance ? 'Update' : 'Submit' }
                        </Button>
                    </div>
                </Row>
                <Alert id="mech-form" className="danger-alert" /> 
            </Form>
        );
    } 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
            <Async
                identifier="mechForm"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderFormContent()}
            />
            </BasePage>
        );
    } 
    handleReporterDropdown = (value: any) => {  
        this.setState({ reporterValue: value });
    }
    handleActivityDropdown = (value: any) => { 
        this.setState({ activityValue: value });
    }
    handleShiftEvent = (value: any) => { 
        this.setState({ shiftEventValue: value });
    }
    handleReporterLocation = (value: any) => {
        this.setState({ reporterLocationValue: value });
    }
    handleReducedVisibility = (value: any) => {
        this.setState({ reducedVisibilityValue: value });
    }
    handleType = (value: any) => {
        this.setState({ typeValue: value });
    }
    handleOperatingRules = (value: any) => {
        this.setState({ operatingRuleValue: value });
    }
} 
export function mapStateToProps(state: any, ownProps: { match: { params: { id: any; }; }; }) { 
    const groupInstances = GroupModel.list(state);
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);  
    const editId = ownProps.match.params.id;  
    //const editInstance = MechModel.get(ownProps.match.params.id);   
    const editInstance = editId && state.report.get('reportDetailData');
    //const editInstance = edit.findIndex(x => x.uniqueId ===editId); 
    return {
        groupInstances,
        userInstance,
        userUID: state.login.get('userUID'),
        userId, forms: state.forms, editId, editInstance ,
        closeCallReportDetails: state.report.get('reportDetailData')
    };
}

export const MechForm = withRouter(connect<IMechFormProps, any, any>(mapStateToProps)(MechFormImpl));
