import { BaseModel } from './BaseModel';

export interface IMocPermanentModelProps {
    id?: string;
    date: string;
    time: string;
    dateReported: string;
    timeReported: string;
    dateOfIncident: string;
    timeOfIncident: string; 
    location?: string;
    locationName?: string;
    specifiedLocation?: string;
    groupName: string;
    projectName: string; 
    company: string;
    changeRequestDetails: string;
    originator: string;
    originatorSignature: string;
    originatorSignatureURL: string;
    reasonForChange: string;
    targetDateForChange: string;
    safetySupervisor: string;
    safetySupervisorApprovedDate: string;
    safetySupervisorSignature: string;
    safetySupervisorSignatureURL: string;
    safetySupervisorComments: string;
    productionSupervisor: string;
    productionSupervisorApprovedDate: string;
    productionSupervisorSignature: string;
    productionSupervisorSignatureURL: string;
    productionSupervisorComments: string;
    safetyManager: string;
    safetyManagerApprovedDate: string;
    safetyManagerApproval: string;
    safetyManagerSignature: string;
    safetyManagerSignatureURL: string;
    safetyManagerComments: string;
    operationsManager: string;
    operationsManagerApprovedDate: string; 
    operationsManagerSignature: string;
    operationsManagerSignatureURL: string;
    operationsManagerApproval: string;
    operationsManagerComments: string;
    productionManager: string;
    productionManagerApprovedDate: string;
    productionManagerSignature: string;
    productionManagerSignatureURL: string;
    productionManagerApproval: string;
    productionManagerComments: string;
    attachments: any[];
    attachmentUrls: any[];
    deletedFiles: any[]; 
    userId: string;
    answers: any[];
}

export class MocPermanentModel extends BaseModel<IMocPermanentModelProps> {
    constructor(props: IMocPermanentModelProps) {
        super(props, MocPermanentModel.resource);
    }
    static resource = ' moc-permanent-form';
}


export interface IMocTemporaryModelProps {
    id?: string;
    date: string;
    time: string;
    timeOfIncident: string; 
    dateReported: string;
    dateOfIncident: string;
    location?: string;
    locationName?: string;
    specifiedLocation?: string;
    groupName: string;
    projectName: string; 
    company: string; 
    originator: string;
    originatorSignature: string;
    originatorSignatureURL: string;
    changeRequestDuration: string;
    changeRequestSection: string;
    changeRequestDetails: string;
    reasonForChange: string;
    neededPrecautions: string;
    safetyManager: string;
    safetyManagerApprovedDate: string;
    safetyManagerSignature: string;
    safetyManagerSignatureURL: string;
    safetyManagerComments: string;
    operationsManager: string;
    operationsManagerApprovedDate: string;
    operationsManagerSignature:string; 
    operationsManagerSignatureURL:string; 
    operationsManagerComments: string;
    productionManager: string;
    productionManagerApprovedDate: string;
    productionManagerSignature: string;
    productionManagerSignatureURL: string; 
    productionManagerComments: string; 
    generalManager: string;
    generalManagerApprovedDate: string;
    generalManagerSignature: string;
    generalManagerSignatureURL: string;
    regionalVicePresident: string;
    regionalVicePresidentApprovedDate: string;
    regionalVicePresidentSignature: string;
    regionalVicePresidentSignatureURL: string;
    globalOperationsManager: string;
    globalOperationsManagerApprovedDate: string;
    globalOperationsManagerSignature: string;
    globalOperationsManagerSignatureURL: string;
    overallComments: string;
    
    attachments: any[];
    attachmentUrls: any[];
    deletedFiles: any[];  
    userId: string; 
    answers: any[];
}
export class MocTemporaryModel extends BaseModel<IMocTemporaryModelProps> {
    constructor(props: IMocTemporaryModelProps) {
        super(props, MocTemporaryModel.resource);
    }
    static resource = ' moc-temporary-form';
}
