import {getCustom, post, postCustom, put} from '../utils/HTTP';
import {showAlert, dispatch } from '../utils/generalUtils';
import {IAnswer, IHistory, IForms} from '../interfaces';
import {UserModel} from '../model/UserModel';
import {FORM_SUBMISSION_ERROR} from '../constants/general'; 
import {format} from 'date-fns';
import { closeCallListProps, closeCallDetailProps, followUpListProps, CloseCallCheckListModel, FollowUpModel } from '../model/MechModel';
import {getReportDataList, getClosecallReportDetailData, getModalReportDataList} from '../actions/reportActions';
import {saveListCount} from '../actions/miscellaneousActions'; 

export async function submitMechForm(userId: string, answers: IAnswer[], history?: IHistory, forms?: IForms, editId?: string) {
    const data: any = {
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        groupName: null,
        projectName: null,
        formId: 'mechanicalForm',
        followUp: '',
        associatedFormId: '',
        answers, 
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: forms?.mechForm.mechImages || [],
        videoData: forms?.mechForm.mechVideos || []
    }; 
    try {
        data.deletedImages = forms?.mechForm.deletedImages || [];
        data.deletedVideos = forms?.mechForm.deletedVideos || [];
        data.editId = editId;
        const response = data.editId ? await post('/EditForm', data) : await post('/SubmitForm', data); 
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'mech-form', 'danger');
            return;
        }
        showAlert(response.data.message, 'mech-form-modal', 'success', () => {
            history?.push('/closecall');
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function submitTransForm(userId: string, answers: IAnswer[], history?: IHistory, forms?: IForms, editId?: string) {
    const data: any = {
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        groupName: null,
        projectName: null,
        formId: 'transportationForm',
        followUp: '',
        associatedFormId: '',
        answers, 
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: forms?.transForm.transImages || [],
        videoData: forms?.transForm.transVideos || []
    }; 
    try {
        data.deletedImages = forms?.transForm.deletedImages || [];
        data.deletedVideos = forms?.transForm.deletedVideos || [];
        data.editId = editId;
        const response = data.editId ? await post('/EditForm', data) : await post('/SubmitForm', data);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'trans-form', 'danger');
            return;
        }
        showAlert(response.data.message, 'trans-form-modal', 'success', () => {
            history?.push('/closecall');
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function submitEnggForm(userId: string, answers: IAnswer[], history?: IHistory, forms?: IForms, editId?: string) {
    const data: any = {
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        groupName: null,
        projectName: null,
        formId: 'engineeringForm',
        followUp: '',
        associatedFormId: '',
        answers, 
        deletedImages: [],
        deletedVideos: [],
        editId: '',
        imageData: forms?.enggForm.enggImages || [],
        videoData: forms?.enggForm.enggVideos || []
    }; 
    try {
        data.deletedImages = forms?.enggForm.deletedImages || [];
        data.deletedVideos = forms?.enggForm.deletedVideos || [];
        data.editId = editId;
        const response = data.editId ? await post('/EditForm', data) : await post('/SubmitForm', data);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'engg-form', 'danger');
            return;
        }
        showAlert(response.data.message, 'engg-form-modal', 'success', () => {
            history?.push('/closecall');
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getAllCloseCallForms(pageNumber: number = 1) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`/SubmitForm/CloseCallForms/${company}/summary/na/na/${pageNumber}`);
        if (response.data.data != null) {
            let reportDataList = new Array<closeCallListProps>();
            //response.data.data.map(item => { 
            response.data.data.forEach((item: any) => {
                let formName;
                switch (item.formId) {
                    case 'mechanicalForm':
                        item.formId = formName = 'Mechanical Form';
                        /*    let mechInstance = marshallMechFormInstance(item);
                           new MechModel(mechInstance).$save(); */
                        break;
                    case 'transportationForm':
                        item.formId = formName = 'Transportation Form';
                        /*  let transInstance = marshallTransFormInstance(item);
                         new TransModel(transInstance).$save(); */
                        break;
                    case 'engineeringForm':
                        item.formId = formName = 'Engineering Form';
                        /*   let enggInstance = marshallEnggFormInstance(item);
                          new EnggModel(enggInstance).$save(); */
                        break;
                }
                let reportItem: closeCallListProps = {};
                reportItem.userName = item.username;
                reportItem.userId = item.userId;
                reportItem.company = item.company;
                reportItem.createdDate = format(new Date(item.date), 'MM/dd/yyyy');
                reportItem.time = item.time;
                reportItem.formId = formName;
                reportItem.uniqueId = item.key;
                reportItem.subAttributeList = item.answers;
                reportItem.imageUrls = item.imageUrls;
                reportItem.videoUrls = item.videoUrls;
                reportItem.totalFollowUp = item.totalFollowUp;
                reportItem.totalOpenFollowUp = item.totalOpenFollowUp;
                reportItem.totalClosedFollowUp = item.totalFollowUp - item.totalOpenFollowUp;
                reportDataList.push(reportItem);
            });
            dispatch(getReportDataList(reportDataList));
            saveListCount(response.data.TotalPages);
        }
    } catch (error) {
        throw error;
    }
}

export function marshallMechFormInstance(data: { id: any; key: any; answers: { answer: any; }[]; }) {
    //data.id = data.uniqueId;  
    data.id = data.key;
    //const answerData = new Answers(data.answers);
    //data.answers = data.subAttributeList; 
    const obj = {
        eventtype: data.answers[0].answer,
        eventlocation: data.answers[1].answer,
        facility: data.answers[2].answer,
        involvedWorkers: data.answers[3].answer,
        milepost: data.answers[4].answer,
        nearstation: data.answers[5].answer,
        phone: data.answers[6].answer,
        hours: data.answers[7].answer,
        carrier: data.answers[8].answer,
        state: data.answers[9].answer,
        Name: data.answers[10].answer,
        date: data.answers[11].answer && format(new Date(data.answers[11].answer), 'MM/dd/yyyy'),
        time: data.answers[12].answer,
        address: data.answers[13].answer,
        city: data.answers[14].answer,
        stateString: data.answers[15].answer,
        zip: data.answers[16].answer,
        reporter: data.answers[17].answer,
        reporterOther: data.answers[18].answer,
        certification: data.answers[19].answer,
        activity: data.answers[20].answer,
        activityOther: data.answers[21].answer,
        railroadyears: data.answers[22].answer,
        yearscraft: data.answers[23].answer,
        groupsize: data.answers[24].answer,
        shiftEvent: data.answers[25].answer,
        otherShiftEvent: data.answers[26].answer,
        reporterLocation: data.answers[27].answer,
        otherReporterLocation: data.answers[28].answer,
        weather: data.answers[29].answer,
        outdoors: data.answers[30].answer,
        workarea: data.answers[31].answer,
        reducedVisibility: data.answers[32].answer,
        reducedVisibilityDetail: data.answers[33].answer,
        totalhead: data.answers[34].answer,
        locomotive: data.answers[35].answer,
        remoteControl: data.answers[36].answer,
        distributedPower: data.answers[37].answer,
        positionInTrain: data.answers[38].answer,
        noofcars: data.answers[39].answer,
        noinservice: data.answers[40].answer,
        carCabControlling: data.answers[41].answer,
        loads: data.answers[42].answer,
        empties: data.answers[43].answer,
        tons: data.answers[44].answer,
        length: data.answers[45].answer,
        recordsComplete: data.answers[46].answer,
        serviceReleased: data.answers[47].answer,
        requiredDocuments: data.answers[48].answer,
        movingForRepair: data.answers[49].answer,
        maintenceDeferred: data.answers[50].answer,
        type: data.answers[51].answer,
        otherType: data.answers[52].answer,
        involvedcar: data.answers[53].answer,
        location: data.answers[54].answer,
        operatingRules: data.answers[55].answer,
        operatingRulesOthers: data.answers[56].answer,
        descriveEventSituation: data.answers[57].answer,
    };
    Object.assign(data, obj);
    //delete data.answers;  
    return data;
}

export function marshallTransFormInstance(data: { id: any; key: any; answers: { answer: any; }[]; }) {
    //data.id = data.uniqueId;  
    data.id = data.key;
    const obj = {
        eventtype: data.answers[0].answer,
        eventlocation: data.answers[1].answer,
        facility: data.answers[2].answer,
        involvedWorkers: data.answers[3].answer,
        milepost: data.answers[4].answer,
        nearstation: data.answers[5].answer,
        phone: data.answers[6].answer,
        hours: data.answers[7].answer,
        carrier: data.answers[8].answer,
        state: data.answers[9].answer,
        Name: data.answers[10].answer,
        date: data.answers[11].answer && format(new Date(data.answers[11].answer), 'MM/dd/yyyy'),
        time: data.answers[12].answer,
        address: data.answers[13].answer,
        city: data.answers[14].answer,
        stateString: data.answers[15].answer,
        zip: data.answers[16].answer,
        reporter: data.answers[17].answer,
        reporterOther: data.answers[18].answer,
        railroadyears: data.answers[19].answer,
        yearscraft: data.answers[20].answer,
        crewsize: data.answers[21].answer,
        shiftEvent: data.answers[22].answer,
        otherShiftEvent: data.answers[23].answer,
        reporterLocation: data.answers[24].answer,
        otherReporterLocation: data.answers[25].answer,
        weather: data.answers[26].answer,
        outdoors: data.answers[27].answer,
        workarea: data.answers[28].answer,
        reducedVisibility: data.answers[29].answer,
        reducedVisibilityDetail: data.answers[30].answer,
        typeOperation: data.answers[31].answer,
        transportType: data.answers[32].answer,
        totalhead: data.answers[33].answer,
        locomotive: data.answers[34].answer,
        remoteControl: data.answers[35].answer,
        cst: data.answers[36].answer,
        helpers: data.answers[37].answer,
        distributedPower: data.answers[38].answer,
        noofcars: data.answers[39].answer,
        carCabControlling: data.answers[40].answer,
        noinservice: data.answers[41].answer,
        loads: data.answers[42].answer,
        empties: data.answers[43].answer,
        tons: data.answers[44].answer,
        length: data.answers[45].answer,
        type: data.answers[46].answer,
        otherType: data.answers[47].answer,
        operatingRules: data.answers[48].answer,
        operatingRulesOthers: data.answers[49].answer,
        jobCompleted: data.answers[50].answer,
        trainLocation: data.answers[51].answer,
        operation: data.answers[52].answer,
        trainActivity: data.answers[53].answer,
        trainLocationOther: data.answers[54].answer,
        operationOther: data.answers[55].answer,
        trainActivityOther: data.answers[56].answer,
        descriveEventSituation: data.answers[57].answer,
    };
    Object.assign(data, obj);
    //delete data.answers;  
    return data;
}

export function marshallEnggFormInstance(data: { id: any; key: any; answers: { answer: any; }[]; }) {
    data.id = data.key;
    //data.id = data.uniqueId;  
    const obj = {
        eventtype: data.answers[0].answer,
        eventlocation: data.answers[1].answer,
        facility: data.answers[2].answer,
        involvedWorkers: data.answers[3].answer,
        milepost: data.answers[4].answer,
        nearstation: data.answers[5].answer,
        phone: data.answers[6].answer,
        hours: data.answers[7].answer,
        carrier: data.answers[8].answer,
        state: data.answers[9].answer,
        Name: data.answers[10].answer,
        date: data.answers[11].answer && format(new Date(data.answers[11].answer), 'MM/dd/yyyy'),
        time: data.answers[12].answer,
        address: data.answers[13].answer,
        city: data.answers[14].answer,
        stateString: data.answers[15].answer,
        zip: data.answers[16].answer,
        reporter: data.answers[17].answer,
        reporterOther: data.answers[18].answer,
        activity: data.answers[19].answer,
        activityOther: data.answers[20].answer,
        railroadyears: data.answers[21].answer,
        yearscraft: data.answers[22].answer,
        workgroup: data.answers[23].answer,
        shiftEvent: data.answers[24].answer,
        otherShiftEvent: data.answers[25].answer,
        reporterLocation: data.answers[26].answer,
        otherReporterLocation: data.answers[27].answer,
        weather: data.answers[28].answer,
        outdoors: data.answers[29].answer,
        workarea: data.answers[30].answer,
        reducedVisibility: data.answers[31].answer,
        reducedVisibilityDetail: data.answers[32].answer,
        operatingRules: data.answers[33].answer,
        operatingRulesOthers: data.answers[34].answer,
        jobCompleted: data.answers[35].answer,
        type: data.answers[36].answer,
        typeOther: data.answers[37].answer,
        operation: data.answers[38].answer,
        operationOther: data.answers[39].answer,
        location: data.answers[40].answer,
        LocationOther: data.answers[41].answer,
        descriveEventSituation: data.answers[42].answer,
    };
    Object.assign(data, obj);
    //delete data.answers;  
    return data;
}

export async function getCloseCallEditDetail(item: any) {
    item.answers = item.subAttributeList;
    switch (item.formId) {
        case 'Mechanical Form':
            let mechInstance: any = marshallMechFormInstance(item);
            mechInstance.props = mechInstance;
            dispatch(getClosecallReportDetailData(mechInstance));
            break;
        case 'Transportation Form':
            let transInstance: any = marshallTransFormInstance(item);
            transInstance.props = transInstance;
            dispatch(getClosecallReportDetailData(transInstance));
            break;
        case 'Engineering Form':
            let enggInstance: any = marshallEnggFormInstance(item);
            enggInstance.props = enggInstance;
            dispatch(getClosecallReportDetailData(enggInstance));
            break;
    }
}

export async function getCloseCallReportDetail(item: { company: any; date: any; formId: any; userName: any; imageUrls: any; videoUrls: any; subAttributeList: any; }) {
    let reportDetail: closeCallDetailProps = {
        company: item.company,
        date: item.date,
        formType: item.formId,
        userName: item.userName,
        imageUrls: item.imageUrls,
        videoUrls: item.videoUrls,
        subAttributeList: item.subAttributeList
    };
    dispatch(getClosecallReportDetailData(reportDetail));
}

export async function getAllCloseCallCheckListForm(params: any) {
    const company = UserModel.getCompanyName();
    try {
        //const response = await getCustom(`/GetAllCheckListForms/${company}/CloseCallChecklist/${pageNumber}`);
        params['company'] = company;
        const response = await postCustom(`/GetAllCheckListForms`, params);
        if (response.data.status === 'valid') {
            CloseCallCheckListModel.deleteAll();
            response.data.data.forEach((data: any) => {
                const checkListInstance = marshallCheckListInstances(data); 
                new CloseCallCheckListModel(checkListInstance).$save(); 
            });
            saveListCount(response.data.TotalPages);
        }
    } catch (error) {
        throw error;
    }
}

export function marshallCheckListInstances(instance: any) {
    instance.id = instance.UniqueId;
    //let answerData = new Answers(instance.answers); 
    let formType = 'NA';
    switch (instance.FormType) {
        case 'SupervisorCheckList':
            formType = 'Supervisor Checklist';
            break;
        case 'ManagerCheckList':
            formType = 'Manager Checklist';
            break;
        case 'CloseCallCheckList':
            formType = 'Close Call Checklist';
            break;
    }
    const checkListInstance = {
        Company: instance.Company,
        Date: format(new Date(instance.Date), 'MM/dd/yyyy'),
        FormType: formType,
        Time: instance.Time,
        UniqueId: instance.UniqueId,
        UserId: instance.UserId,
        UserName: instance.UserName,
        id: instance.id,

    };
    Object.assign(instance, checkListInstance);
    delete instance.answers;
    return instance;
}

export async function closeCallFollowUpProcess(followUpData: any, userId: string, userUID: string, files: File[], closeCallFollowUpProcess: string[], formType: any, forms: IForms) { 
    let fileInfo: Array<any> = [];  
    try { 
        followUpData['attachments'] = forms.closeCallFollowUpForm.attachements || [];
        followUpData['deletedFiles'] = forms.closeCallFollowUpForm.deletedFiles || [];
        followUpData['imageUrls'] = fileInfo;
        followUpData['userId'] = userUID;
        followUpData['formReference'] = 'Follow_up';
        switch (formType || '') {
            case 'o&iReport':
                followUpData['formReference'] = 'O&I Report';
                break;
            case 'officeSafetyReport':
                followUpData['formReference'] = 'Office Safety O&I';
                break;
            case 'saftyWalkthrough':
                followUpData['formReference'] = 'Safety Walkthrough';
                break;
            case 'closeCall':
                followUpData['formReference'] = 'CloseCall';
                break;
            case 'closeCallChecklist':
                followUpData['formReference'] = 'CloseCall Checklist';
                break;
            default:
                break;
        }
        let response;
        if (followUpData['followUpId']) {
            followUpData['updatedDate'] = format(new Date(), 'yyyy-MM-dd');
            followUpData['updatedTime'] = format(new Date(), 'HH:mm');
            response = await put('/CloseCallFollowUp', followUpData);
        } else {
            followUpData['createdDate'] = format(new Date(), 'yyyy-MM-dd');
            followUpData['createdTime'] = format(new Date(), 'HH:mm');
            response = await post('/CloseCallFollowUp', followUpData);
        }
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'closecall-followup-failed', 'danger');
            return;
        }
        /* switch(formType) {
            case 'closeCallReport': await getAllCloseCallForms(); break;
            case 'closeCallChecklist': await getAllCloseCallCheckListForm(); break;
            case 'saftyWalkthrough': await getAllCheckListForms('SafetyChecklist'); break; 
        }  */
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'closecall-followup-failed', 'danger');
        throw error;
    }
}

export async function getAllFollowUpForms(closeCallId: any, followUpType: string = 'main', userUID: string = '', reqFrom: string = 'all', pageNumber: string = '1') {
    const company = UserModel.getCompanyName();
    let reportDataList = [null];
    dispatch(getModalReportDataList(reportDataList)); 
    FollowUpModel.deleteAll();
    try {
        //const response = await getCustom(`/GetCloseCallFollowUp/${company}/${closeCallId}/${followUpType}`); 
        let params = {company, formId: closeCallId, followUpType, userUID, reqFrom, pageNumber};
        const response = await postCustom(`/GetCloseCallFollowUp`, params); 
        if (response.data.data != null) {
            let reportDataList = new Array<followUpListProps>();
            response.data.data.map((item: any) => { 
                let reportItem: followUpListProps = {};
                reportItem.key = item.followUpId;
                reportItem.id = item.followUpId;
                reportItem.action = item.actions;
                reportItem.actualCompletion = item.actualDate;
                reportItem.assignedTo = item.assignedTo;
                reportItem.assignedToName = item.name;
                reportItem.closeCallId = item.checklistFormType;
                reportItem.comments = item.comments;
                reportItem.company = item.company;
                reportItem.description = item.description;
                reportItem.followUpId = item.followUpId;
                reportItem.targetCompletion = item.targetDate;
                reportItem.status = item.status;
                reportItem.imageUrls = item.imageUrls;
                reportItem.createdDate = format(new Date(item.createdDate), 'MM/dd/yyyy');
                reportItem.createdBy = item.createdBy;
                reportItem.assignedByName = item.assignedByName;
                reportDataList.push(reportItem); 
                new FollowUpModel(reportItem).$save();
            });
            dispatch(getModalReportDataList(reportDataList));
            saveListCount(response.data.TotalPages);
        } else {
            let reportDataList: any[] = [];
            dispatch(getModalReportDataList(reportDataList));
        }
    } catch (error) {
        throw error;
    }
} 
