import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { UserModel } from '../../model/UserModel';
import { getAllIncidentReportForms } from '../../services/incidentReportService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './ListItem';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Pagination } from '../reusableComponents/Pagination';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { ExcelExport } from '../reusableComponents/ExcelExport';
declare var require: any;
const queryString = require('query-string');

export interface IIncidentReportFormListPageProps {
    incidentReportInstance: IncidentReportModel[];
    history?: IHistory;
    totalPageCount?: number;
    incidentType?: string;
    forms: IForms;
    axiosPreviousToken: any;
}

export interface IIncidentFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class IncidentReportFormListPageImpl extends React.PureComponent<IIncidentReportFormListPageProps, IIncidentFormListPageState> {
    constructor(props: IIncidentReportFormListPageProps | Readonly<IIncidentReportFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'locationName', 2:'actualSeverity', 3: 'potentialSeverity' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {  
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        //await getAllIncidentReportForms(pageNumber, this.props.incidentType); 
    }
    async componentDidUpdate(prevProps: any) {   
        if (prevProps.incidentType !== this.props.incidentType) { 
            /* setLoading('AllListPage');
            await getAllIncidentReportForms(1, this.props.incidentType);
            setSuccess('AllListPage'); */
            let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
            await this.getReportData(urlParams.sortingIndex);
        }
    } 
    getReportData = async (sortIndex='false') => {  
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        //let pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1 
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllIncidentReportForms(urlParams, this.props.forms, this.props.incidentType);
        setSuccess('AllListPage');
    } 
    renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                onClick={() => { this.props.history?.push('/incident-investigative-reports/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    }
    renderFormList = () => {
        const { incidentReportInstance, incidentType } = this.props;  
        return (
            <>
                <ListPage
                    pageHeading={`${incidentType==='final'?'Final':'Preliminary'} Incident Investigative Reports`}
                    instances={incidentReportInstance}
                    rowHeadings={['Date Submitted', 'Location', 'Actual Severity', 'Potential Severity', 'Follow-up Status']}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={['actualSeverity', 'potentialSeverity', 'username', 'date', 'groupName', 'projectName','location']}
                    searchPlaceHolder="Enter project, group, severity to search..."
                    emptyInstancesMessage="No Incident Investigative reports found."
                    sm={[2, 2, 2, 3, 2]}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                { UserModel.checkUserAccess('incident_investigative_add') && this.renderAddNewFormButton()}
                { UserModel.checkUserAccess('incident_investigative_report_summary') && this.renderSummaryPageButton()}
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={IncidentReportModel as any} 
                    identifier="AllListPage"
                />
            </>
        );
    }
    renderContent = () => {
        const { incidentType } = this.props;  
        return <div className="investigative-list-wrapper">  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
            {<ExcelExport formType="incidentDetailedReport" fileName={`${incidentType==='final'? 'Final':'Preliminary'} Incident Investigative Report`} filterParams={ {incidentType} } />}
            {this.renderFormList()} 
        </div>  
    }

    renderAddNewFormButton = () => { 
        return <button className="add-new-report"
            onClick={() => { this.props.history?.push('/incident-investigative-reports/create'); }}
        >
            +
        </button>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" /> 
                <AlertModal id="delete-success" />
                <Async
                    identifier="IncidentReportListPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={ this.renderContent()}
                />;
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const incidentReportInstance = IncidentReportModel.list(state); 
    const incidentType = ownProps.match.params.incidentType; 
    return { incidentReportInstance, totalPageCount: state.miscellaneous.get('listCount'), incidentType: incidentType, forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const IncidentReportFormListPage =
    withRouter(connect<IIncidentReportFormListPageProps, any, any>(mapStateToProps)(IncidentReportFormListPageImpl));
