import { BaseModel } from './BaseModel';

export interface IEnggModelProps {
    id?: string;
    key?: string; 
    enggImages?: { name: string; image: string; }[];
    imageUrls?: string[];
    enggVideos?: { name: string; file: string; }[];
    videoUrls?: string[];
    eventtype?: string;
    eventlocation?: string;
    facility?: string;
    involvedWorkers?: string;
    milepost?: string;
    nearstation?: string;
    phone?: string;
    hours?: string;
    carrier?: string;
    state?: string;
    name?: string;
    date?: string;
    time?: string; 
    address?: string;
    city?: string;
    stateString?: string;
    zip?: string;
    reporter?: string; 
    reporterOther?: string; 
    activity?: string;
    activityOther?: string; 
    railroadyears?: string;
    yearscraft?: string;  
    workgroup?: string;
    shiftEvent?: string;
    otherShiftEvent?: string;
    reporterLocation?: string;
    otherReporterLocation?: string;
    weather?: string;
    outdoors?: string;
    workarea?: string;
    reducedVisibility?: string; 
    reducedVisibilityDetail?: string;
    operatingRules?: string;
    operatingRulesOthers?: string;
    jobCompleted?: string;
    type?: string;
    typeOther?: string;
    operation?: string;
    operationOther?: string;
    location?: string;
    LocationOther?: string;
    descriveEventSituation?: string; 
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string;
    createdDate?: any; 
} 

export class EnggModel extends BaseModel<IEnggModelProps> {
    constructor(props: IEnggModelProps) {
        super(props, EnggModel.resource);
    }
    static resource = 'engg-from';
}
