import * as React from 'react';
import { Col, Row } from 'react-bootstrap'; 
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { saveFilterData } from '../../actions/miscellaneousActions';
import { IChartValue, IForms } from '../../interfaces'; 
import { getChecklistSummary, applyMonthlyFilterChecklist, applyYearlyFilterForChecklist } from '../../services/checklistService'; 
import { Async } from '../reusableComponents/Async';
import { Card } from '../reusableComponents/Card'; 
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';   
import { ChecklistSummaryDataProps } from '../../model/ChecklistModel'; 
import { PrintLogos } from '../reusableComponents/PrintLogos';  

export interface IChecklistSummaryProps {
    reportsData: ChecklistSummaryDataProps;
    getRef?: (value: any) => void;
    getFilterBy?: (value: any) => void;
    setFilterBy?: (value: any) => void;
    id: string;
    isPrint: boolean;
    filterBy: string; 
    isAnimationActive?: boolean;
    forms?: IForms;
}
export interface IChecklistSummaryDataState {
    view: boolean;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
    filterBy: string;
}

export class ChecklistSummaryData extends React.Component<IChecklistSummaryProps,
IChecklistSummaryDataState> {
    constructor(props: IChecklistSummaryProps) {
        super(props);
        this.state = {
            view: false,
            data: [],
            legends: [],
            totalFormsSubmitted: 0,
            pieChartData: {},
            showMonthlyFilter: false, showYearlyFilter: false,
            filterBy: 'monthly',
            isLoading: false,
        };
    }

    ref: any;
 
    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return (
            <YearlyFilter defaultValue={new Date().getFullYear()} onApply={(year) => {
                setLoading('ReportsPage');
                saveFilterData('yearly', year);
                applyYearlyFilterForChecklist(year);
            }} />
        );
    }

    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        const defaultYear = new Date().getFullYear().toString();  
        const defaultMonth = ("0" + (new Date().getMonth() + 1)).slice(-2).toString(); 
        return <MonthlyFilter defaultValue={[defaultYear,defaultMonth]} onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilterChecklist(year, month);
        }} />;
    }

    handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({
                showYearlyFilter: true,
                showMonthlyFilter: false,
                filterBy: 'yearly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'monthly') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: true,
                filterBy: 'monthly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'cumulative') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: false,
                filterBy: 'monthly'
            });  
            let params: any = {};  
            const defaultYear = new Date().getFullYear().toString();  
            params['filterType'] = 'cumulative';
            params['year'] = defaultYear; 
            //getChecklistSummary(params);
            this.props.getFilterBy && this.props.getFilterBy(value);
        } 
    }

    renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            {/* <Alert style={{ height: '40px' }} id="checklist-summary" className="danger-alert" /> */}
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' }, { value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        model=".filterType"
                        defaultValue="cumulative"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    }
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getChecklistSummary(this.props.forms); 
        setSuccess('ReportsPage');
    }
    renderCountLineChart = () => {
        const { reportCountData, checklistNames } = this.props.reportsData;
        let strokeColorsSet = ['B0D9FF', 'FFBECE','F7DC6F', '73C6B6', 'D2B4DE', 'AED6F1', 'F9E79F', 'CCD1D1', '943126', '73C6B6', 'E6B0AA']; 
        let strokeColors: any = [] 
        checklistNames.forEach((key: any, index: any) => {
            strokeColors.push(strokeColorsSet[index])
        }); 
        //['Electrician Checklist', 'Driver\'s Checklist', 'Garden Checklist']
        // const data = getOfficeSafetyLineChartData(this.props.filterBy || this.state.filterBy, officeData);
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Total Count of Checklist Forms
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '250px' }}>
                            <LineChart data={reportCountData} legends={checklistNames? checklistNames: []}
                                strokeColors={strokeColors} isAnimationActive={false}
                                dataKey={checklistNames? checklistNames: []} refName="customChecklist" />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    } 

    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data" > 
            <PrintLogos/>
            <Title key="title" text="Checklist Report Summary" style={{paddingTop: '20px'}}></Title>
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''}
                    {/* {this.renderFilters()} */}
                    <CommonFilter onApply={this.applyCommonFilter} forms={this.props.forms}/>
                    {this.renderCountLineChart()} 
                </div>
            </div>
        </div>;
    }

    render() {
        return [
            <Async
                key="content"
                identifier="ReportsPage"
                promise={() => setSuccess('ReportsPage')}
                loader={this.renderContent(true)}
                content={this.renderContent(false)}
                error={<ErrorPage />}
            />
        ];
    }
}
