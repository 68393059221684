import { setSuccess } from '../actions/loadingActions'; 
import { savePandemicSummary, savePandemicShortSummary  } from '../actions/summaryActions'; 
import { UserModel } from '../model/UserModel'; 
import { Covid19Model } from '../model/Covid19Model';
import { showAlert, checkValidDate } from '../utils/generalUtils';
import { getCustom, post } from '../utils/HTTP';  
import { saveListCount } from '../actions/miscellaneousActions';
import { Answers } from '../utils/Answers'; 
import { format } from 'date-fns';  
import { FORM_SUBMISSION_ERROR } from '../constants/general';
import { getUserInfo } from './loginService';
import { IAnswer } from '../interfaces';

export async function getCovid19SummaryData(freq: string = 'monthly', param1: string = 'na', param2: string = 'na') {
    const company = UserModel.getCompanyName();
    const assessLevel = UserModel.getAccessLevel(); 
    const userUID = UserModel.getUserUID();
    try {
        const response = await getCustom(`/GetCovid19Summary/${company}/${assessLevel}/${userUID}/${freq}/${param1}/${param2}`); 
        savePandemicSummary(response.data.data);
    } catch (error) {
        throw error;
    }
}
export async function applyMonthlyFilter(year: string | undefined, month: string | undefined) { 
    if (!month) {
        setSuccess('ReportsPage');
        showAlert('Please select month.', 'covid19-report-summary', 'danger');
        return;
    } else if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'covid19-report-summary', 'danger');
        return;
    }
    await getCovid19SummaryData('monthly', year, month);
    setSuccess('ReportsPage');
}
export async function applyDateRangeFilter(startDate: string | undefined, endDate: string | undefined) {
    if (!checkValidDate(startDate) ) {
        setSuccess('ReportsPage');
        showAlert('Please select start date.', 'covid19-report-summary', 'danger');
        return;
    } else if (!checkValidDate(endDate)) {
        setSuccess('ReportsPage');
        showAlert('Please select end date.', 'covid19-report-summary', 'danger');
        return;
    }
    await getCovid19SummaryData('dateRange', startDate, endDate);
    setSuccess('ReportsPage');
} 
export async function applyYearlyFilter(year: string | undefined) {
    if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'covid19-report-summary', 'danger');
        return;
    } 
    await getCovid19SummaryData('yearly', year);
    setSuccess('ReportsPage'); 
}


export async function getCovidShortSummary(date: string) {
    const company = UserModel.getCompanyName();
    const assessLevel = UserModel.getAccessLevel();
    try {
        const response = await getCustom(`/GetCovidShortSummary/${company}/${assessLevel}/${date}`); 
        savePandemicShortSummary(response.data.data);
    } catch (error) {
        throw error;
    }
}

export async function getCovid19Report(pageNumber: number, filter: { reportFilterForm: { fromDate: string; toDate: string; resultType: string; userName: string; }; }) { 
    let fromDate = filter.reportFilterForm.fromDate || 'all';
    let toDate = filter.reportFilterForm.toDate || 'all';
    let resultType = filter.reportFilterForm.resultType || 'all';
    let userName = filter.reportFilterForm.userName || 'all'; 
    
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`/GetPandemicReport/covid19Form/${company}/${pageNumber}/${fromDate}/${toDate}/${resultType}/${userName}`);
        if (response.data.status === 'valid') {
            Covid19Model.deleteAll();
            (response.data.data || []).forEach((covid19FormData: any) => {
                    const covid19FormInstance = marshallCovid19FormInstance(covid19FormData); 
                    new Covid19Model(covid19FormInstance).$save();
                });
            saveListCount(response.data.TotalPages || '');
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}
export function marshallCovid19FormInstance(data: { id: any; key: any; answers: any; }) {
    data.id = data.key;    
    const answerData = new Answers(data.answers); 
    const obj = {   
        q1: answerData.get(1), 
        q2: answerData.get(2), 
        q3: answerData.get(3), 
        q4: answerData.get(4), 
        q5: answerData.get(5), 
        q6: answerData.get(6), 
        q7: answerData.get(7), 
        q8: answerData.get(8), 
        q9: answerData.get(9), 
        q10: answerData.get(10), 
        q11: answerData.get(11), 
        q12: answerData.get(12), 
        q13: answerData.get(13), 
    };
    Object.assign(data, obj);
    delete data.answers;
    return data;
} 

export async function submitCOVID19AssessmentForm(answers: IAnswer[]) {
    const company = UserModel.getCompanyName();
    const userId = UserModel.getUserUID();
    const submittedValues = { 
        formId: 'covid19Form', language: getUserInfo().language,
        answers,
        company, userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'), currentDateTime: format(new Date(), 'MM/dd/yyyy hh:mm a')
    }; 
    try {
        const response =  await post('/SubmitPandemicForm', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'covid19-step2-form-failed', 'danger');
            return;
        } 
        return response.data; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'covid19-step2-form-failed', 'danger');
        throw error;
    } 
}

export async function getCovid19AssessmentStatus() {
    const company = UserModel.getCompanyName();
    const userUID = UserModel.getUserUID();
    const submittedValues = {  
        company, userUID,
        currentDate: format(new Date(), 'yyyy-MM-dd'), 
    }; 
    try {
        const response =  await post('/GetCovid19Status', submittedValues);
        return response.data; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'covid19-step2-form-failed', 'danger');
        throw error;
    } 
}