import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../FormComponents/Button';
import { Form, actions } from 'react-redux-form';
import { RRFInput } from '../FormComponents/RRFInput';
import PropTypes from 'prop-types';
import { dispatch } from '../../../utils/generalUtils';

export interface IMonthlyFilterProps {
    onApply: (month: any, year: any) => void;
    defaultValue?: string[];
}

export interface IMonthlyFilterState {

}

export class MonthlyFilter extends React.PureComponent<IMonthlyFilterProps, {}> {
    constructor(props: IMonthlyFilterProps | Readonly<IMonthlyFilterProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.monthlyFilter'));
    }

    applyMonthlyFilter = ({ month, year }: any) => {
        const { onApply } = this.props;
        if (onApply) {
            return onApply(month, year);
        }
    }

    getChildContext() {
        return { formModel: 'forms.monthlyFilter' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    renderMonthlyFilter = () => { 
        const menuItems = [
            { value: '01', label: 'January' },
            { value: '02', label: 'February' },
            { value: '03', label: 'March' },
            { value: '04', label: 'April' },
            { value: '05', label: 'May' },
            { value: '06', label: 'June' },
            { value: '07', label: 'July' },
            { value: '08', label: 'August' },
            { value: '09', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ];
        let yearOptions:Array<any> = [];
        let startYear = new Date().getFullYear();
        let currentYear = new Date().getFullYear(); 
        let currentMonth: string | number = new Date().getMonth() + 1;
        currentMonth =  currentMonth < 9 ? ("0" + currentMonth) : currentMonth;
        
        let endYear = startYear - 10;
        for (let i = startYear; i >= endYear; i--) {
            yearOptions.push({
                value: startYear.toString(), label: startYear
            });
            startYear--;
        }
        const{ defaultValue } = this.props;  
        return (
            <Form model="forms.monthlyFilter" onSubmit={this.applyMonthlyFilter}>
                <Row className="show-grid filter" style={{ padding: '10px 0px' }}>
                    <Col sm={5} className="input month-select">
                        <RRFInput
                            type="dropdown"
                            menuItems={menuItems.reverse()}
                            model=".month"
                            defaultValue={ ( (defaultValue && defaultValue[1] ) || currentMonth).toString()}
                        />
                    </Col>
                    <Col sm={5} className="input year-select">
                        <RRFInput
                            type="dropdown"
                            menuItems={yearOptions}
                            model=".year"
                            defaultValue={  ( (defaultValue && +defaultValue[0]) || currentYear).toString() }
                        />
                    </Col>
                    <Col sm={2} className="input done">
                        <Button
                            type="submit"
                            style={{
                                width: '100%',
                                color: '#5479AF',
                                border: '1px solid #5479AF', backgroundColor: 'white',
                                marginLeft: '20px', marginRight: '20px'
                            }}
                        >
                            Done
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    render() {
        return this.renderMonthlyFilter();
    }
}
