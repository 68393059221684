import * as React from 'react';
import { FormControl } from 'react-bootstrap'; 
import { Editable } from './Editable';

export interface ITitleProps {
    text: string;
    title?: string;
    style?: React.CSSProperties;
    titleColor?: string;
    onClick?: (e: any) => void;
    isEditable?: boolean;
    getRef?: (ref: any) => void;
    noNeedBorder?: boolean;
}

export interface ITitleState {
    pageTitle: string;
    isEditable: any;
    isTitleChange: boolean;
}

export class Title extends React.PureComponent<ITitleProps, ITitleState> {
    constructor(props: ITitleProps) {
        super(props);
        this.state = { isEditable: props.isEditable, pageTitle: '', isTitleChange: false };
    }

    componentWillReceiveProps(nextProps: ITitleProps) {
        this.setState({
            isEditable: nextProps.isEditable
        });
    }

    refVal: any;

    handleTitleChange = (e: any, saveChanges?: boolean) => {
        const keyCode = e.which || e.keyCode;
        if ((e.ctrlKey && keyCode === 13) || !this.state.isTitleChange || saveChanges) {
            this.setState({
                isTitleChange: !this.state.isTitleChange
            });
        }
    }

    get value() {
        return this.refs.titleValue.value;
    }

    handleInputChange = (e: { target: { value: any; }; }) => {
        this.setState({
            pageTitle: e.target.value
        });
    }

    isDesktop = () => {
        if (navigator.userAgent.match(/Mobile/i)) {
            return false;
        }
        return true;
    }

    refs!: {
        titleValue: Editable;
    };

    static defaultProps = {
        title: ''
    };

    renderEditTitle = () => {
        return <div className="title-input" style={{ textAlign: 'center' }}>
            <FormControl
                type="text"
                onKeyPress={() =>this.handleTitleChange}
                onKeyDown={() =>this.handleTitleChange}
                value={this.state.pageTitle}
                onChange={this.handleInputChange}
            />
            <p style={{ fontSize: '10px', color: '#9c9c9c' }}>
                {this.isDesktop() ?
                    'Press CTRL + Enter to Save' :
                    <i
                        onClick={(e) => this.handleTitleChange(e, true)}
                        style={{ fontSize: '20px', color: '#26a65b' }}
                        className="fa fa-check-circle">
                    </i>}
            </p>
        </div>;
    }

    render() {
        const { props: { titleColor, style, text, isEditable, noNeedBorder }, state: { isTitleChange, pageTitle } } = this;
        return (
            <div className="title-container" style={{marginBottom: '30px'}}>
                {isTitleChange && isEditable ? this.renderEditTitle() :
                    <div
                        title={pageTitle}
                        onClick={() =>this.props.isEditable ? this.handleTitleChange : null}
                        className="page-title"
                        style={style}
                    >
                        <div
                            style={{ color: titleColor }}
                            className="form-title">{pageTitle.length > 0 ? pageTitle : text}

                            {/* <button className="add-new-repodrt" style={{ position: 'absolute', right: '40px', backgroundColor: '#c0af34', width: '40px', height: '30px', borderRadius: '114px' , fontSize: '22px', border: 'none',  color: 'white',  boxShadow: '2px 1px 7px rgb(192, 175, 52)', zIndex: 1, top:'16px' }} ><i className="fa fa-filter" aria-hidden="true"></i> </button>
                            <button className="add-new-repodrt" style={{ position: 'absolute', right: '90px', backgroundColor: 'rgb(243, 34, 34)', width: '40px', height: '30px', borderRadius: '114px' , fontSize: '22px', border: 'none',  color: 'white',  boxShadow: '2px 1px 7px rgb(243, 34, 34)', zIndex: 1, top:'16px' }} ><i className="fa fa-times" aria-hidden="true"></i> </button> */}

                        </div>
                        {!noNeedBorder? <span className="border"></span>:''}
                    </div>
                }
            </div>
        );
    }
}
