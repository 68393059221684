import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { IHistory } from '../../interfaces'; 
import { convertTime, convertToDateFormat, getSWPChecklistHints, renderATCSEHints } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage'; 
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title'; 
import { Button } from '../reusableComponents/FormComponents/Button';  
import format from 'date-fns/format';
import {Col, Row} from "react-bootstrap";
import {getFormData} from '../../services/permitToWorkService'; 
import { PermitToWorkModel } from '../../model/PermitToWorkModel';  
import { Label } from '../reusableComponents/FormComponents/Label';
import './style.scss';
import { getFormData as JSAGetFormData } from '../../services/jsaFormService';
import { JSAModel } from '../../model/JSAModel'; 
import { ChecklistAttributeModel } from '../../model/ChecklistModel';
import { formatChecklistQuestions } from '../../services/attributeService'; 

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    instance: PermitToWorkModel;
    jsaInstance: JSAModel;
    viewId: string;
    checklistAttributes: ChecklistAttributeModel;
}
export interface IDetailsPageState {
    imageURLs: string[];
    answer: any;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''], answer: '' };
    } 
    promise = async () => {
        const { instance, viewId } = this.props;
         
        if( !instance) {
            await getFormData(viewId);
        } 
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
            await this.setState({answer: answer});
        } 
        if( answer.formJsaNo && answer.formJsaNo!=='Other JSA') {
            await JSAGetFormData('jsa', answer.formJsaNo);
        } 
        let checklistIds = (answer.SWPChecklistType && answer.SWPChecklistType.split(',')) || [];   
        if(checklistIds.length>0) { 
            for(let i = 0; i<checklistIds.length; i++) {
                await formatChecklistQuestions(checklistIds[i]);  
            } 
        }
        return null;
    } 
    renderDetailsAttribute = (ques, ans, sm=4, xs=12) => {
        return <Col sm={sm} xs={xs}> <Col xs={12} className="details-label" sm={12}> {ques}:</Col> 
        <Col sm={12} xs={12} id="detailsChild" className={`details-value ${ans==='Yes'?'ansYes':(ans==='No'?'ansNo':'') }`} >{ans || 'NA'}</Col> </Col>
    }
    renderPermitCloseOut = () => {
        const { answer } = this.state; 
        return (<>
            {answer.closePermitCloseOut!=='1' && <div className="pagebreak"> </div> }
            <Row className={'show-grid'}> 
                <Col sm={12} xs={12} className="form-page-title"> Form - Permit Close-Out</Col>  
                
                {this.renderDetailsAttribute('Permit No',answer.permitNo ) }  
                {this.renderDetailsAttribute('Work associated with this permit has been completed',answer.pcoWorkCompletedYes?'Yes': answer.pcoWorkCompletedNo?'No':'', 12 ) } 
                {answer.closePermitCloseOut==='1' && this.renderSignOffContent()}

                <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'16px'}}> Permit Holder:</b> I am satisified that the worksite has been restored to a safe and tidy condition. All isolations relevant to this permit must follow the de-isolation procedure prior to reenergizing, or a long term isolation (LTI) must be applied.</p> 
                {this.renderDetailsAttribute('Have isolations been removed',answer.PCOOIIsolationsRemovedYes?'Yes': answer.PCOOIIsolationsRemovedNo?'No':'', 12 ) } 
                {this.renderDetailsAttribute('Work Status Comments',answer.PCOPIWorkStatusComments, 12 ) } 
                {this.renderDetailsAttribute('Print Name',answer.PCOOIName, 3 ) }
                {this.renderDetailsAttribute('Sign',( (answer.permitCloseOutOISignatureURL && <img className="signature-img" height="56px" width="100%" alt="SafeConnect" src={ answer.permitCloseOutOISignatureURL } />)  || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.PCOOIDate ? convertToDateFormat(answer.PCOOIDate) : 'NA', 3 ) } 
                {this.renderDetailsAttribute('Time',answer.PCOOITime ? convertTime(answer.PCOOITime) : 'NA', 3 ) }  

                <p style={{color: "yellow", marginTop: '15px'}}><b style={{fontSize:'16px'}}> FI:</b> I confirm that all personnel have been accounted for and the worksite has been restored to a safe and tidy working condition.</p>
                {this.renderDetailsAttribute('Print Name',answer.PCOPIName, 3 ) }
                {this.renderDetailsAttribute('Sign',( (answer.permitCloseOutPISignatureURL && <img className="signature-img" height="56px" width="100%" alt="SafeConnect" src={ answer.permitCloseOutPISignatureURL } /> ) || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.PCOPIDate ? convertToDateFormat(answer.PCOPIDate) : 'NA', 3 ) } 
                {this.renderDetailsAttribute('Time',answer.PCOPITime ? convertTime(answer.PCOPITime) : 'NA', 3 ) } 
                 
            </Row>
            </>
        );
    }
    renderWorkDeclerations = () => { 
        const { answer } = this.state; 
        return (<>
            <div className="pagebreak"> </div> 
            <Row className={'show-grid'}> 
                <Col sm={12} xs={12} className="form-page-title"> Form - Work Declaration</Col> 
            </Row>
            <Row className="show-grid">
                <label style={{color: '#fff', textAlign:'left'}}><p style={{textIndent: '20px'}}>Work team members must sign before commencing work for the first time to indicate that they have reviewed the Permit Pack with the Permit Holder. Each person understands the scope of work, their responsibilities and is competent to execute their part of the Permit Pack (including JSA, Procedures, and Checklists) to complete the work safely.</p>
                <p style={{textIndent: '20px'}}>If any person observes an unsafe situation developing they must immediately stop work, inform their co-workers, make the worksite safe, and inform the Permit Holder and the Area Operator.</p>
                <p style={{textIndent: '20px'}}>Note: Workers reassigned, crew changing, or no longer working on this permit while the permit is valid must sign off.</p> </label>
            </Row>
            {/* {answer.permitType=='Confined Space' && 
                <Row className={'show-grid'}> 
                    {this.renderDetailsAttribute('Reason for Entry',answer.signOffReasonForEntry ) } 
                </Row>
            } */}
            {this.renderDetailsAttribute('Confirm- Finalize this Permit to Work', answer.closeWorkDecleration?'Yes':'No', 12 ) } 
            {this.renderSignOffContent()}
            {/* {answer.permitType=='Confined Space' && 
                <Row className={'show-grid'}>  
                    <Col sm={12} xs={12} className="form-section-title"> <div>Confined Space Entry Attendant</div></Col> 
                    {this.renderDetailsAttribute('Time In print name',answer.signOffsTimeInEntryAttendantName, 6 ) } 
                    {this.renderDetailsAttribute('Time Out print name',answer.signOffsTimeOutEntryAttendantName, 6 ) }  
                </Row>
            } */}
        </>);
    }
    renderSignOffContent = () => {
        const { answer } = this.state; 
        return (
            <><Row className="show-grid" >
                <Col sm={{span: 2, offset: 2}}> <span style={{ color: 'yellow' }} >Name</span> </Col> 
                <Col sm={2}> <span style={{ color: 'yellow' }} >Sign Off Date</span> </Col>
                <Col sm={2}> <span style={{ color: 'yellow' }} >Time In</span> </Col>
                <Col sm={2}> <span style={{ color: 'yellow' }} >Time Out</span> </Col> 
            </Row>
            <Row className="show-grid" >
                { answer.signOffName?.length && answer.signOffInTime?.length && answer.signOffOutTime?.length ? this.renderSignOffs() : <><Col sm={12} className="text-center">No records found..</Col> </> }
            </Row></>
        )
    }
    renderSignOffs = () => {
        const { answer } = this.state; 
        return (answer.signOffName || []).map((item, index) => {
            return (
            <Row className="show-grid" key={index}> 
                <Col sm={{span: 2, offset: 2}} style={{textAlign: 'left'}}> {answer.signOffName && answer.signOffName[index]} </Col>
                <Col sm={2} style={{textAlign: 'left'}}> { answer.signOffDate && answer.signOffDate[index] ? convertToDateFormat(answer.signOffDate[index]) : 'NA' } </Col>  
                <Col sm={2} style={{textAlign: 'left'}}> {answer.signOffDate && answer.signOffInTime[index] ? convertTime(answer.signOffInTime[index]) : 'NA' } </Col>  
                <Col sm={2} style={{textAlign: 'left'}}> {answer.signOffDate && answer.signOffOutTime[index] ? convertTime(answer.signOffOutTime[index]) : 'NA' } </Col>  
            </Row> 
            )
        });
    }
    renderGeneralPermitDocuments = () => { 
        const { answer } = this.state; 
       
        return (<>
            { ( answer.liftingPlan || answer.msds || answer.pidDrawing || answer.procedure || answer.rescuePlan || answer.simopDeviation || answer.workAid) && 
                <><div className="pagebreak"> </div> 
                    <Row className={'show-grid'}> 
                        <Col sm={12} xs={12} className="form-page-title"> Form - General Permit Pack Documents </Col> 
                    </Row> 
                    { answer.liftingPlan && this.renderGeneralDocumentsHTML('Lifting Plan', 'generalPermitLiftingPlanRefNo' ,'generalPermitLiftingPlanAttachmentsURL') }
                    { answer.msds && this.renderGeneralDocumentsHTML('MSDS', 'generalPermitMSDSRefNo' ,'generalPermitMSDSAttachmentsURL') }
                    { answer.pidDrawing && this.renderGeneralDocumentsHTML('MSDS', 'generalPermitPIDRefNo' ,'generalPermitPIDAttachmentsURL') }
                    { answer.procedure && this.renderGeneralDocumentsHTML('Procedure', 'generalPermitProcedureRefNo' ,'generalPermitProcedureAttachmentsURL') }
                    { answer.rescuePlan && this.renderGeneralDocumentsHTML('Rescue Plan', 'generalPermitRescuePlanRefNo' ,'generalPermitRescuePlanAttachmentsURL') }
                    { answer.simopDeviation && this.renderGeneralDocumentsHTML('SIMOP Deviation', 'generalPermitSIMOPRefNo' ,'generalPermitSIMOPAttachmentsURL') }
                    { answer.workAid && this.renderGeneralDocumentsHTML('Work Aid', 'generalPermitWorkAidRefNo' ,'generalPermitWorkAidAttachmentsURLCount') } 
                </>
            } 
        </>)
    }
    renderGeneralDocumentsHTML = (title, refNo, attachURLS ) => {
        const { answer } = this.state; 
        return (
            <><Row className={'show-grid'}>
                <Col sm={12} xs={12} className="form-section-title"><div>{title} </div> </Col>
                <Col sm={{  offset: 2, span: 1}} ></Col>
                {this.renderDetailsAttribute('Reference No',answer[refNo], 4 ) }
                {this.renderDetailsAttribute(' Attachments',`( ${answer[attachURLS] ? answer[attachURLS].length: 0} ) Attached` )}  
            </Row>
            </>
        );
    }
    renderTemperaryDefect = () => {
        const { answer } = this.state; 
        if(!answer.temporaryDefeat) {
            return;
        }
        return (<>
            <div className="pagebreak"> </div> 
            <Row className={'show-grid'}>
                <Col sm={12} xs={12} className="form-page-title"> Form - Temporary Defeat Certificate </Col> 
                {/* {answer.formIsolationType==='1' && 
                <Row className={'show-grid'}>  */}
                {this.renderDetailsAttribute('Electrical',answer.isolationElectrical?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Mechanical',answer.isolationMechanical?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Instrumentation/Process',answer.isolationInstrumentationProcess?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other Safety',answer.isolationOtherSafety?'Yes': 'No', 3 ) }
                <Col sm={12} xs={12} className="form-section-title"><span>Temporary Defeat Information</span> </Col>
                {this.renderDetailsAttribute('Description of specified device/System and tag number being defeated',answer.temporaryDefeatInformation, 12 ) }
                {/* {this.renderDetailsAttribute('Approved Device Bypass Procedure Attached',answer.isApprovedBypassAttached?'Yes': 'No', 6 ) }
                {this.renderDetailsAttribute('Risk Assessment Required?',answer.isRiskAssessmentRequired?'Yes': 'No', 6 ) }
                {this.renderDetailsAttribute('Procedure Title',answer.procedureTitle, 6 ) } 
                {this.renderDetailsAttribute('Work Permit Number',answer.workPermitNumber, 6 ) }  */}
                {this.renderDetailsAttribute('Reason for the defeating the device/system',answer.reasonDefectSystem, 12 ) }
                {/* {this.renderDetailsAttribute('Does the defeat create a Critical Operating parameter(COP)',answer.COPDefeatYes?'Yes': answer.COPDefeatNo?'No':'', 6 ) }
                {this.renderDetailsAttribute('COP Approved?',answer.COPApprovedYes?'Yes': answer.COPApprovedNo?'No':'', 6 ) }
                <Col sm={12} xs={12} style={{ color: 'yellow' }}>Define risk mitigation controls in place during the bypass that provide adequate protection:</Col>  */}
                <Row className="show-grid" >
                    <Col sm={6} style={{ color: 'yellow', textAlign: 'center' }}> Hazards </Col> 
                    <Col sm={6} style={{ color: 'yellow', textAlign: 'center' }}> Controls </Col>
                </Row>
                <Row className="show-grid" >
                    { answer.riskMitigationHazard ? this.renderIsolationHazard() : <><Col sm={12}>No records found..</Col> </> }
                </Row>
                <Col sm={12} xs={12} className="form-section-title"><span>Authorization for Temporary Defeat - up to 12 hours</span><div>Duration of Defeat</div> </Col>
                {this.renderDetailsAttribute('From(date)',answer.defeatDurationFromDate ? convertToDateFormat(answer.defeatDurationFromDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Time',answer.defeatDurationFromTime ? convertTime(answer.defeatDurationFromTime) : 'NA' ) }
                {this.renderDetailsAttribute('To(date)',answer.defeatDurationToDate ? convertToDateFormat(answer.defeatDurationToDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Time',answer.defeatDurationToTime ? convertTime(answer.defeatDurationToTime) : 'NA' ) }
                {this.renderDetailsAttribute('Permit Holder/Requestor(print)',answer.isolationPermitHolderName ) }  
                {this.renderDetailsAttribute('Facility Incharge(print)',answer.isolationPIName ) }  
                {this.renderDetailsAttribute('Sign',( (answer.isolationTemporaryDefeatPISignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationTemporaryDefeatPISignatureURL } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
                <Col sm={12} xs={12} className="form-section-title"><span>Execution of Temporary Defeat</span><div>Defeated by</div> </Col>
                {this.renderDetailsAttribute('Name(print)',answer.isolationDedeatedByName, 3 ) }  
                {this.renderDetailsAttribute('Sign',( (answer.isolationTemporaryDefeatDefeatedBySignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationTemporaryDefeatDefeatedBySignatureURL } alt="SafeConnect"/>) || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.isolationDedeatedDate ? convertToDateFormat(answer.isolationDedeatedDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Time',answer.isolationDedeatedTime ? convertTime(answer.isolationDedeatedTime) : 'NA', 3 ) }
                
                <Col sm={12} xs={12} className="form-section-title"><span>Long Term Defeats</span><div>Requested by</div> </Col>
                {this.renderDetailsAttribute('Name(print)',answer.longTermDefeatsRequestedByName, 3 ) }  
                {this.renderDetailsAttribute('Sign',( (answer.isolationLongTermDefeatRequestedBySignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationLongTermDefeatRequestedBySignatureURL } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.longTermDefeatsRequestedDate ? convertToDateFormat(answer.longTermDefeatsRequestedDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Time',answer.longTermDefeatsRequestedTime ? convertTime(answer.longTermDefeatsRequestedTime) : 'NA', 3  ) }
                {this.renderDetailsAttribute('Reason for Long Term Defeat',answer.longTermDefeatReason, 12 ) }  
                <Col sm={12} xs={12} className="form-section-title"><span>Long Term Defeat Authorization</span> </Col>
                {this.renderDetailsAttribute('FI (print)',answer.ITDCPIName, 3 ) }  
                {this.renderDetailsAttribute('Sign',( (answer.isolationLongTermDefeatAuthorizationPISignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationLongTermDefeatAuthorizationPISignatureURL } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.ITDCPIDate ? convertToDateFormat(answer.ITDCPIDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Expiry Date',answer.ITDCPIExpiryDate ? convertToDateFormat(answer.ITDCPIExpiryDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('OI (print)',answer.ITDCOIName, 3 ) }  
                {this.renderDetailsAttribute('Sign',( (answer.isolationLongTermDefeatAuthorizationOISignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationLongTermDefeatAuthorizationOISignatureURL } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
                {this.renderDetailsAttribute('Date',answer.ITDCOIDate ? convertToDateFormat(answer.ITDCOIDate) : 'NA', 3 ) }
                {this.renderDetailsAttribute('Expiry Date',answer.ITDCOIExpiryDate ? convertToDateFormat(answer.ITDCOIExpiryDate) : 'NA', 3 ) }  
                {/* <Col sm={12} xs={12} style={{ color: 'yellow' }}>For Defeats over 60 days, an MOC must be generated and both the MOC and Temporary Defeat Certificate must be signed.</Col> 
                <Col sm={12} xs={12} className="form-section-title"><div>MOC Assigned</div> </Col>
                {this.renderDetailsAttribute('MOC Number',answer.isolationTemporaryMOCNumber, 3 ) } 
                {this.renderDetailsAttribute('Name(print)',answer.isolationTemporaryMOCAssignedTo, 3 ) }  
                {this.renderDetailsAttribute('Date',answer.isolationTemporaryMOCAssignedDate ? convertToDateFormat(answer.isolationTemporaryMOCAssignedDate) : 'NA', 3 ) } */}
                <div>
                {/* <Col sm={12} xs={12} className="form-section-title"><span>Shift Change</span> </Col>
                <Col sm={12} xs={12} style={{color: "yellow" }}> 
                    <p>Open Temporary Defeat Certificates must be reviewed during each shift change and recorded in the shift handover log/document.</p>
                    <p>A copy of any open Defeat must be posted at the Control Room or Central Control Point. A copy must also be attached to any permit pack (where applicable).</p>
                </Col>  
                <Col sm={12} xs={12} className="form-section-title"><span>Mechanical Hazards and Controls — Protection to be defeated or not functioning</span><div>Vibration Monitoring</div> </Col>
                {this.renderDetailsAttribute('Shutdown equipment',answer.vibrationMonitoringSE?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Provide portable monitoring equipment',answer.vibrationMonitoringPPME?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Conduct frequent surveillance',answer.vibrationMonitoringCFS?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.vibrationMonitoringOther?`Yes ${answer.vibrationMonitoringMentionOther?' - '+answer.vibrationMonitoringMentionOther:''} `: 'No', 3 ) }
                <Col sm={12} xs={12} className="form-section-title"> <div>Seal Failure System Isolated or Inoperable</div> </Col>
                {this.renderDetailsAttribute('Shutdown pump or compressor',answer.sealFailureSPCC?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Continuous seal pressure surveillance and manual intervention',answer.sealFailureCSPSMI?'Yes': 'No', 4) } 
                {this.renderDetailsAttribute('Other',answer.sealFailureOther?`Yes ${answer.sealFailureMentionOther?' - '+answer.sealFailureMentionOther:''} `: 'No', 3 ) }
                <Col sm={12} xs={12} className="form-section-title"> <div>Engine Overspeed Trip Isolated or Inoperable</div> </Col>
                {this.renderDetailsAttribute('Shut down engine',answer.engineOverspeedTripSDE?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Continuous speed surveillance and manual intervention',answer.engineOverspeedTripCSSMI?'Yes': 'No', 4) } 
                {this.renderDetailsAttribute('Other',answer.engineOverspeedTripOther?`Yes ${answer.engineOverspeedTripMentionOther?' - '+answer.engineOverspeedTripMentionOther:''} `: 'No', 3 ) }
                <Col sm={12} xs={12} className="form-section-title"> <div>Limit Switches Isolated or Inoperable</div> </Col>
                {this.renderDetailsAttribute('Drilling operations to cease',answer.limitSwitchedIsolatedDO?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Lift Plan in place',answer.limitSwitchedIsolatedLP?'Yes': 'No', 3) } 
                {this.renderDetailsAttribute('Do not operate crane near limits Stop loading ship and disconnect',answer.limitSwitchedIsolatedCNLS?'Yes': 'No', 4) } 
                {this.renderDetailsAttribute('Other',answer.limitSwitchedIsolatedOther?`Yes ${answer.limitSwitchedIsolatedOther?' - '+answer.limitSwitchedIsolatedOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"><span>Safety Hazards and Controls — Protection to be defeated or not functioning</span><div>Fire Protection System Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Shut down facility',answer.fireProductionSDF?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Stop all high consequence work',answer.fireProductionSHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.fireProductionCAP?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.fireProductionOther?`Yes ${answer.fireProductionMentionOther?' - '+answer.fireProductionMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Fire Pump Inoperable or impaired</div> </Col>
                {this.renderDetailsAttribute('Shut down facility',answer.firePumpSDF?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Stop all high consequence work',answer.firePumpSHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Back-up fire pump available',answer.firePumpBackup?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.firePumpCommunicate?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.firePumpOther?`Yes ${answer.firePumpMentionOther?' - '+answer.firePumpMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Make-up to Fire Water System Inoperable or impaired </div> </Col>
                {this.renderDetailsAttribute('Shut down process if water levels approach minimum allowable',answer.fireWaterShutDown?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Stop all high consequence work',answer.fireWaterSHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Alternative sources to be provided',answer.fireWaterAlternative?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.fireWaterOther?`Yes ${answer.fireWaterMentionOther?' - '+answer.fireWaterMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Emergency! Fire Alarm Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Stop all high consequence work',answer.fireEmergencySHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Alternate notification method implemented',answer.fireEmergencyAlternative?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Portable air horns provided',answer.fireEmergencyPortableAir?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.fireEmergencyOther?`Yes ${answer.fireEmergencyMentionOther?' - '+answer.fireEmergencyMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Fire Detection Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Stop all high consequence work',answer.fireDetectionSHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Continuous surveillance and manual intervention',answer.fireDetectionCSMI?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.fireDetectionCAP?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.fireDetectionOther?`Yes ${answer.fireDetectionMentionOther?' - '+answer.fireDetectionMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Gas detection Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Stop all high consequence work',answer.gasDetectionSHCW?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel Adequate coverage by remaining gas detectors',answer.gasDetectionCRGD?'Yes': 'No', 5 ) }
                {this.renderDetailsAttribute('Deploy portable gas detection devices',answer.gasDetectionPGD?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Regular Surveillance',answer.gasDetectionRS?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Continuous Surveillance',answer.gasDetectionCS?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.gasDetectionOther?`Yes ${answer.gasDetectionMentionOther?' - '+answer.gasDetectionMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>ESD System Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Shut down process',answer.esdShutDown?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Stop all high consequence work',answer.esdStopHighConsequence?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Additional surveillance',answer.esdAdditionalSurveillance?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.esdOther?`Yes ${answer.esdMentionOther?' - '+answer.esdMentionOther:''} `: 'No', 2 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Public address System Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Alternative communication & notification system identified for emergencies',answer.publicAddressSysAlternativeComm?'Yes': 'No', 5 ) }
                {this.renderDetailsAttribute('Communicated to affected personnel',answer.publicAddressSysCommAffected?'Yes': 'No', 5 ) } 
                {this.renderDetailsAttribute('Other',answer.publicAddressSysOther?`Yes ${answer.publicAddressSysMentionOther?' - '+answer.publicAddressSysMentionOther:''} `: 'No', 2 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Smoke Detection Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Stop all high consequence work',answer.smokeDetectionStopHighConsequence?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Additional surveillance',answer.smokeDetectionAdddtionalSurvillance?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.smokeDetectionCommunicateAffected?'Yes': 'No', 5 ) }
                {this.renderDetailsAttribute('Other',answer.smokeDetectionOther?`Yes ${answer.smokeDetectionOther?' - '+answer.smokeDetectionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Public address System Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Alternative communication & notification system identified for emergencies',answer.publicAddressSysAlternativeComm?'Yes': 'No', 5 ) }
                {this.renderDetailsAttribute('Communicated to affected personnel',answer.publicAddressSysCommAffected?'Yes': 'No', 5 ) } 
                {this.renderDetailsAttribute('Other',answer.publicAddressSysOther?`Yes ${answer.publicAddressSysMentionOther?' - '+answer.publicAddressSysMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Radar Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Provide coverage from other facilities',answer.radarInoperableProvideCoverage?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Provide portable radar unit',answer.radarInoperablePortableRadar?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Provide coverage from vessel / ship',answer.radarInoperablePortableCoverageVessel?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Other',answer.radarInoperableOther?`Yes ${answer.radarInoperableMentionOther?' - '+answer.radarInoperableMentionOther:''} `: 'No', 3 ) }
                
                <Col sm={12} xs={12} className="form-section-title"> <div>Transport systems</div> </Col>
                {this.renderDetailsAttribute('Notify affected parties and Government Authorities',answer.transportSystemNotifyAffectedParties?'Yes': 'No', 6 ) }
                {this.renderDetailsAttribute('No night flights',answer.transportSystemNoNightFlights?'Yes': 'No', 2 ) }
                {this.renderDetailsAttribute('Provide alternate lighting',answer.transportSystemProvideAlternateLighting?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Surveillance',answer.transportSystemSurveillance?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.transportSystemOther?`Yes ${answer.transportSystemMentionOther?' - '+answer.transportSystemMentionOther:''} `: 'No', 3 ) } 

                <Col sm={12} xs={12} className="form-section-title"> <div>Escape Craft or Lifeboat not functional</div> </Col>
                {this.renderDetailsAttribute('Shut down and make facility safe',answer.escapeCraftShutDownSafe?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Stop all high consequence work',answer.escapeCraftStopHighConsequence?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Helicopter to be on location',answer.escapeCraftHelicopter?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('POB\'s reduced to available capacity',answer.escapeCraftPOB?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Vessel on standby',answer.escapeCraftVessalStandby?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Other',answer.escapeCraftOther?`Yes ${answer.escapeCraftMentionOther?' - '+answer.escapeCraftMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Emergency Lighting Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Provide Alternative Lighting',answer.EmergencyLightingAlternativeLighting?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Communicate to affected personnel',answer.EmergencyLightingCommunicateAffectedPersonnel?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Restrict high consequence work',answer.EmergencyLightingRestrictHighConsequence?'Yes': 'No', 4 ) } 
                {this.renderDetailsAttribute('Other',answer.EmergencyLightingOther?`Yes ${answer.EmergencyLightingMentionOther?' - '+answer.EmergencyLightingMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Emergency Eye Wash or Shower Inoperable</div> </Col>
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.EmergencyEyeWashCommunicateAffected?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('No chemical handling in vicinity El Provide portable eye wash / shower',answer.EmergencyEyeWashNoChemical?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Provide portable shower',answer.EmergencyEyeWashProvidePortableShower?'Yes': 'No', 3 ) } 
                {this.renderDetailsAttribute('Other',answer.EmergencyEyeWashOther?`Yes ${answer.EmergencyEyeWashMentionOther?' - '+answer.EmergencyEyeWashMentionOther:''} `: 'No', 3 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>Communication System Shut Down or Faulty</div> </Col>
                {this.renderDetailsAttribute('Redundancy in available frequencies or equipment',answer.communicationSysShutdownRedundancyEquipment?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Portable devices provided',answer.communicationSysShutdownPortableDevices?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Communicate to affected personnel',answer.communicationSysShutdownCommunicateAffected?'Yes': 'No', 3 ) } 
                {this.renderDetailsAttribute('Other',answer.communicationSysShutdownOther?`Yes ${answer.communicationSysShutdownMentionOther?' - '+answer.communicationSysShutdownMentionOther:''} `: 'No', 2 ) }

                <Col sm={12} xs={12} className="form-section-title"> <div>HVAC / Pressurization Inoperable or Impaired</div> </Col>
                {this.renderDetailsAttribute('Shut down facility on gas or fire detect near quarters or buildings',answer.HVACShutdownFacility?'Yes': 'No', 4 ) }
                {this.renderDetailsAttribute('Communicate to all affected personnel',answer.HVACShutdownCommunicatAffected?'Yes': 'No', 3 ) }
                {this.renderDetailsAttribute('Restrict high consequence work',answer.HVACShutdownCommunicatRestrictHighConsequence?'Yes': 'No', 4 ) }  

                <Col sm={12} xs={12} className="form-section-title"> <div>Tank Bunding / Containment to be compromised or Breached</div> </Col>
                {this.renderDetailsAttribute('Tank(s) level(s) to be reduced to remaining bund capacity ',answer.tankBundingCapacity?'Yes': 'No', 6 ) }
                {this.renderDetailsAttribute('Tank(s) to be emptied',answer.tankBundingTankEmpty?'Yes': 'No', 3 ) } 
                {this.renderDetailsAttribute('Other',answer.tankBundingOther?`Yes ${answer.tankBundingMentionOther?' - '+answer.tankBundingMentionOther:''} `: 'No', 3 ) } */}
                </div>
            {/* </Row> } */}
            </Row></>);
    }
    renderChecklist() { 
        const { answer } = this.state; 
        if(!answer.SWPChecklistType ) {
            return;
        }
        return (<><div className="pagebreak"> </div> 
        <Row className={'show-grid checklist-form'}>  
            <Col sm={12} xs={12} className="form-page-title"> Form - SWP Checklist </Col> 
            {this.renderDetailsAttribute('SWP Checklist Type',answer.SWPChecklistType, 12 ) } 
            {this.renderChecklistHTML()}
        </Row></>);
    }
    renderChecklistHTML() {
        const { answer } = this.state; 
        let SWPChecklistType = answer.SWPChecklistType.split(',');
        SWPChecklistType.sort();
        if(SWPChecklistType.length>0) {
            return SWPChecklistType.map((item: any, index: any) => { 
                return  <><div>
                    <Row className="show-grid">
                        <Label sm={12}><b style={{textTransform: 'capitalize'}}>{ (item) }</b> {getSWPChecklistHints(item)}</Label>
                    </Row>
                    <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                        <Col className="border-bottom border-right" sm={1} xs={12}>
                            <Label sm={12} className="text-center">No.</Label>
                        </Col>
                        <Col className="border-bottom" sm={9} xs={12}>
                            <Label sm={12} className="text-center">Attribute</Label>
                        </Col>
                        <Col className="border-bottom border-left" sm={1} xs={12}>
                            <Label sm={12} className="text-center">Yes</Label>
                        </Col>
                        <Col className="border-bottom border-right border-left" sm={1} xs={12}>
                            <Label sm={12} className="text-center">N/A</Label>
                        </Col> 
                    </Row> 
                    { (this.props.checklistAttributes && this.renderChecklistAttribute(this.props.checklistAttributes, item) ) || null } 
                </div>  
                <div className="checklist-border"></div></>
            });
        }
    } 
    renderChecklistAttribute = (attributeList, checklistId) => { 
        const { answer } = this.state;  
        return attributeList.map((item, index) => {
            return checklistId === item.props.checklistId && <div key={`attributeRowParent${index}`}>
                <Row key={`attributeRow${index}`} 
                className="border-bottom row-cell-height border-left 
                border-right margin-left-right-0 padding-bottom-0 color-white"> 
                    <Col className="row-cell-height border-right" sm={1} xs={12}> 
                        <div className="sequence-number-position">
                            {`${item.props.attributeId}.`}
                        </div>
                    </Col>
                    <Col className="row-cell-height" sm={9} xs={12}>
                        <div className="width-height-100-textcenter">{item.props.attribute}</div>
                    </Col>
                    
                    <Col className="row-cell-height color-white border-left" sm={1} xs={12}>

                    </Col>
                    <Col className="row-cell-height " sm={1} xs={12}>
                    </Col>
                </Row>
                {this.renderChecklistSubAttribute(item.props.subAttributeList, index, item.props)}
                <Row key={`subAttributeRow${item.props.attributeId}`} className="customized-grid row-cell-height border-bottom border-left border-right margin-left-right-0 padding-bottom-0 p10"> 
                    <Col className="row-cell-height input" sm={2} xs={12}> 
                        <label>Comments: </label>
                    </Col>
                    <Col className="row-cell-height input" sm={9} xs={12}>
                        { answer.checklistComments[`${item.props.checklistId.replace(/ /g,'')}${index}`] }
                    </Col> 
                </Row>
            </div>;
        }); 
    }
    renderChecklistSubAttribute = (subAttributeData, row, attributeData) => {
        const { answer } = this.state; 
        let attributeYes = ( answer.attributeYes?.length && answer.attributeYes ) || [];
        let attributeNA = ( answer.attributeNA?.length && answer.attributeNA ) || [];
        let checklistIdentifier = attributeData.checklistId.replace(/ /g,'');  
        return subAttributeData.map((item, index) => {
            return <Row key={`subAttributeRow${index}${row}`}
             className="customized-grid row-cell-height border-bottom 
             border-left border-right margin-left-right-0 padding-bottom-0">
                <Col className="row-cell-height border-right" sm={1} xs={12}>

                </Col>
                <Col className="row-cell-height " sm={9} xs={12}>
                    <Label sm={12} className="text-center">{item.name}</Label>
                </Col>
                <Col className="row-cell-height border-left color-white" sm={1} xs={12}>
                    <div className="width-height-100-textcenter">
                        {attributeYes && attributeYes.indexOf(`${checklistIdentifier}${item.subAttributeId}`)>-1? <i className="fa fa-check" aria-hidden="true"></i>:false}
                    </div>
                </Col>
                
                <Col className="row-cell-height border-left" sm={1} xs={12}>
                    <div className="width-height-100-textcenter">
                        {attributeNA && attributeNA.indexOf(`${checklistIdentifier}${item.subAttributeId}`)>-1?<i className="fa fa-check" aria-hidden="true"></i>:false}
                    </div>
                </Col>
            </Row>;
        });
    }
    renderJSA() { 
        const { jsaInstance } = this.props;
        const { answer } = this.state; 
        if(!answer.formJsaNo || !jsaInstance) {
            return;
        }
        return (<><div className="pagebreak"> </div> 
        <Row className={'show-grid'}> 
            <Col sm={12} xs={12} className="form-page-title"> Form - JSA </Col> 
            {this.renderDetailsAttribute('JSA N°',jsaInstance.props.jsaNum ) } 
            {this.renderDetailsAttribute('Title',jsaInstance.props.typeOfWork ) } 
            {this.renderDetailsAttribute('Date',jsaInstance.props.date ) } 
            {this.renderDetailsAttribute('Work To Perform',jsaInstance.props.workToPerform ) } 
            {this.renderDetailsAttribute('Location',jsaInstance.props.locationName ) } 
            {this.renderDetailsAttribute('Team Name',jsaInstance.props.projectName ) } 
            {this.renderDetailsAttribute('Group Name',jsaInstance.props.groupName ) } 
            <Row className="show-grid" >
                <Col sm={4} style={{ color: 'yellow', textAlign: 'center' }}> Step Description </Col> 
                <Col sm={4} style={{ color: 'yellow', textAlign: 'center' }}> Hazards </Col>
                <Col sm={4} style={{ color: 'yellow', textAlign: 'center' }}> Controls </Col>
            </Row>
            <Row className="show-grid" >
                { jsaInstance.props.answers?.length ? this.renderJSAHazard() : <><Col sm={12}>No records found..</Col> </> }
            </Row>
            {this.renderDetailsAttribute('Images',`(${jsaInstance.props.imageUrls?.length} ) Attached` ) } 
            {this.renderDetailsAttribute('Videos',`(${jsaInstance.props.videoUrls?.length} ) Attached` ) } 
            {this.renderDetailsAttribute('Sign',( (jsaInstance.props.signatureUrl && <img className="signature-img" height="56px" width="100%" src={ jsaInstance.props.signatureUrl } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
        </Row></>
        )
    }
    renderJSAHazard = () => {
        const { jsaInstance } = this.props;
        return (jsaInstance.props.answers || []).map((stepData, index) => {
            return (
            <Row className="show-grid" > 
                <Col sm={4} style={{textAlign: 'center'}}> {stepData.stepDesc} </Col>
                <Col sm={4} style={{textAlign: 'center'}}> {stepData.hazards} </Col>  
                <Col sm={4} style={{textAlign: 'center'}}> {stepData.controls} </Col>  
            </Row> 
            )
        });
    }
    renderIsolationHazard  = (index: number = 1) => { 
        const { answer } = this.state;
        return (answer.riskMitigationHazard || []).map((stepData, index) => {
            return <>
                <Row className="show-grid" > 
                    <Col sm={6} style={{textAlign: 'center'}}> {answer.riskMitigationHazard[index]} </Col>
                    <Col sm={6} style={{textAlign: 'center'}}> {answer.riskMitigationControl[index]} </Col>  
                </Row></>
        });
    }
    renderIsolationForm = () => {
        const { answer } = this.state;
        if(!answer.isolationCertificate) {
            return;
        }
        return (<>
        <div className="pagebreak"> </div> 
        <Row className={'show-grid'}>
            <Col sm={12} xs={12} className="form-page-title"> Form - Isolation Blind Register </Col> 
            {/* {this.renderDetailsAttribute('Isolation Type',(answer.formIsolationType===1?'Temporary Defeat Certificate':'Isolation Blind Register'), 12 ) }  */}
        </Row>
       
        {/* {answer.formIsolationType==='2' &&  */}
        <Row className={'show-grid'}> 
            {this.renderDetailsAttribute('ICC',answer.isolation2ICC?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('RFIC',answer.isolation2RFIC?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Electrical',answer.isolation2Electrical?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Mechanical',answer.isolation2Mechanical?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Instrumentation',answer.isolation2Instrumentation?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Isolation Certificate No',answer.isolation2IsolationCertificateNo, 3 ) }
            {this.renderDetailsAttribute('Title',answer.isolation2Title, 3 ) }
            {this.renderDetailsAttribute('Facility',answer.isolation2Facility, 3 ) }
            {this.renderDetailsAttribute('Created By',answer.isolation2CreatedBy, 3 ) }
            <Col sm={12} xs={12} className="form-section-title"> <span>Isolation Details</span> </Col>
            {this.renderDetailsAttribute('Date',answer.isolation2Date ? convertToDateFormat(answer.isolation2Date) : 'NA' ) } 
            {this.renderDetailsAttribute('Time',answer.isolation2Time ? convertTime(answer.isolation2Time) : 'NA' ) } 
            {this.renderDetailsAttribute('Facility',answer.isolation2DetailsFacility ) }
            {this.renderDetailsAttribute('Number and/or description of equipment/system to be isolated',answer.isolatedSystemDescription ) }
            {this.renderDetailsAttribute('Reason for Isolation',answer.resonForIsolation) }
            {this.renderDetailsAttribute('Zero Energy Demonstration Method',answer.zeroEnergyDemonstrationMethod ) } 
            {this.renderDetailsAttribute('Pre-established Isolation List Attached',answer.preEstablishedIsolation?'Yes': 'No', 6 ) }
            {this.renderDetailsAttribute('Pre-established Blind List Attached',answer.preEstablishedBlindList?'Yes': 'No', 6 ) }
            <Col sm={12} xs={12} className="form-section-title"> <span> Plan Verification</span> </Col> 
            <Col sm={12} xs={12}>
                <p>The isolation plan is correct for the proposed work.</p>
            </Col>
            {this.renderDetailsAttribute('Verified by FI',answer.isolationPIPlanVerificationName, 3 ) }
            {this.renderDetailsAttribute('Sign',( (answer.isolationPIPlanVerificationSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationPIPlanVerificationSignatureURL }  alt="SafeConnect" /> ) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.isolationPIPlanVerificationDate ? convertToDateFormat(answer.isolationPIPlanVerificationDate) : 'NA', 3 ) } 
            {this.renderDetailsAttribute('Time',answer.isolationPIPlanVerificationTime ? convertTime(answer.isolationPIPlanVerificationTime) : 'NA', 3 ) } 
            <Col sm={12} xs={12} className="form-section-title"> <span> Isolation Verification</span> </Col> 
            <Col sm={12} xs={12}>
                <p> Note: A second independent competent person must verify each isolation point and sign below.</p>
                <p>The isolations have been identified and performed so that the equipment is properly isolated.</p>
            </Col>
            {this.renderDetailsAttribute('FI Verification',answer.isolationVerificationPIVerifyName, 3 ) }
            {this.renderDetailsAttribute('Sign',( (answer.isolationPIIsolationVerificationSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationPIIsolationVerificationSignatureURL } alt="SafeConnect" />) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.isolationVerificationPIVerifyDate ? convertToDateFormat(answer.isolationVerificationPIVerifyDate) : 'NA', 3 ) } 
            {this.renderDetailsAttribute('Time',answer.isolationVerificationPIVerifyTime ? convertTime(answer.isolationVerificationPIVerifyTime) : 'NA', 3 ) } 
            {this.renderDetailsAttribute('OI Verification',answer.isolationVerificationOIVerifyName, 3 ) }
            {this.renderDetailsAttribute('Sign',( (answer.isolationOIIsolationVerificationSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.isolationOIIsolationVerificationSignatureURL } alt="SafeConnect"/> ) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.isolationVerificationOIVerifyDate ? convertToDateFormat(answer.isolationVerificationOIVerifyDate) : 'NA', 3 ) } 
            {this.renderDetailsAttribute('Time',answer.isolationVerificationOIVerifyTime ? convertTime(answer.isolationVerificationOIVerifyTime) : 'NA', 3 ) } 
            <Col sm={12} xs={12} className="form-section-title"> <span> De-isolation Verification</span> </Col> 
            <Col sm={12} xs={12}>
                <p> All isolations have been removed and equipment/system can be reinstated.</p>
            </Col>
            {this.renderDetailsAttribute('FI Verification',answer.deisolationVerificationPIVerifyName, 3 ) }
            {this.renderDetailsAttribute('Sign',( (answer.deisolationPIIsolationVerificationSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.deisolationPIIsolationVerificationSignatureURL } alt="SafeConnect" /> ) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.deisolationVerificationPIVerifyDate ? convertToDateFormat(answer.deisolationVerificationPIVerifyDate) : 'NA', 3 ) } 
            {this.renderDetailsAttribute('Time',answer.deisolationVerificationPIVerifyTime ? convertTime(answer.deisolationVerificationPIVerifyTime) : 'NA', 3 ) } 
        </Row> 
        {/* } */}
        </>);
    }
    renderAdmosphericForm = () => {
        const { answer } = this.state; 
        if(!answer.admosphericTestRecords) {
            return;
        } 
        return (<>
        <div className="pagebreak"> </div> 
        <Row className={'show-grid'}>
            <Col sm={12} xs={12} className="form-page-title"> Form - Atmospheric Test </Col> 
            {this.renderDetailsAttribute('Area to be Tested',answer.areaToBeTested, 12 ) }  
            {this.renderDetailsAttribute('Prior to work commencing',answer.testFrequencyPrierToWorkYes?'Yes': 'No' ) }
            {this.renderDetailsAttribute('Continuous gas testing required',answer.continuousGasTestYes?'Yes': 'No' ) }
            {this.renderDetailsAttribute('Test Frequency',answer.testFrequencyEveryHoursYes?`Yes - Every  ${answer.testFrequencyEveryHours} Hours`: 'No' ) } 
            <Col sm={12} xs={12} className="form-section-title"> <span>Required Test </span></Col>
            {this.renderDetailsAttribute('O2',answer.testFrequencyO2?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('LEL',answer.testFrequencyLEL?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('H2S',answer.testFrequencyH2S?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('CO',answer.testFrequencyCO?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('Benzene',answer.testFrequencyBenzene?'Yes': 'No', 2 ) }
            {this.renderDetailsAttribute('SO2',answer.testFrequencySO2?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('CO2',answer.testFrequencyCO2?'Yes': 'No', 2 ) } 
            {this.renderDetailsAttribute('Others',answer.testFrequencyOther?'Yes': 'No', 2 ) } 
            {answer.testFrequencyOther && this.renderDetailsAttribute('Describe',answer.testFrequencyOtherDescribe, 3) } 
            {/* {this.renderDetailsAttribute('Detecter used',answer.detecterUsed ) }
            {this.renderDetailsAttribute('Calibiration date',answer.colibirationDate ? convertToDateFormat(answer.colibirationDate) : 'NA' ) } */}
            {this.renderDetailsAttribute('Comments/Special Precautions',answer.commentsSpecialPracautions ) }
            <Col sm={12} xs={12} className="form-section-title"><span>Results (Pre-Start + Work Period Tests)</span> </Col>
            {this.renderDetailsAttribute('CSE Class Limit',answer.CSEClassLimit ) }
            <Row className="show-grid" > 
                {answer.testFrequencyPrierToWorkYes && <Col sm={{span: 2, offset: 3}}> <span style={{ color: 'yellow' }} >Pre-Start Test Result</span> </Col> }
                <Col sm={2}> <span style={{ color: 'yellow' }} >Result</span> </Col>   
            </Row>
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> Date </Col> 
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultDate ? convertToDateFormat(answer.ATCSEPreStartResultDate) : 'NA'} </Col> }
                { answer.pshDate ? this.renderCSEClassLimit('AT1CSEDate'): '' }
            </Row> 
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> Time </Col> 
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultTime ? convertTime(answer.ATCSEPreStartResultTime) : 'NA' } </Col> }
                { answer.AT1CSETime ? this.renderCSEClassLimit('AT1CSETime'): '' }
            </Row> 
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> O2 <div className="ATCSEHints">{renderATCSEHints('O2', answer)}</div></Col>  
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultO2} </Col>  }
                { answer.AT1CSEO2 ? this.renderCSEClassLimit('AT1CSEO2'): '' }
            </Row> 
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> LEL (General Work) <div className="ATCSEHints">{renderATCSEHints('LELGW', answer)}</div> </Col>  
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultLELGeneralWork} </Col> }
                { answer.AT1CSELELGW ? this.renderCSEClassLimit('AT1CSELELGW'): '' }
            </Row> 
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> LEL (Flammability )<div className="ATCSEHints">{renderATCSEHints('LELF', answer)}</div> </Col>  
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultLELFlammability} </Col> }
                { answer.AT1CSELELF ? this.renderCSEClassLimit('AT1CSELELF'): '' }
            </Row>
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> CO <div className="ATCSEHints">{renderATCSEHints('CO', answer)}</div></Col>  
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultCO} </Col> }
                { answer.AT1CSECO ? this.renderCSEClassLimit('AT1CSECO'): '' }
            </Row>
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> Benzene <div className="ATCSEHints">{renderATCSEHints('Benzene', answer)}</div></Col>  
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultBenzene} </Col>  }
                { answer.AT1CSEBenzene ? this.renderCSEClassLimit('AT1CSEBenzene'): '' }
            </Row>
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> SO2 <div className="ATCSEHints">{renderATCSEHints('SO2', answer)}</div></Col> 
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultSO2} </Col> }
                { answer.AT1CSESO2 ? this.renderCSEClassLimit('AT1CSESO2'): '' }
            </Row>
            <Row className="show-grid" > 
                <Col sm={3} className="details-label"> AG Tester Initials</Col> 
                {answer.testFrequencyPrierToWorkYes && <Col sm={2}> {answer.ATCSEPreStartResultAGTInitials} </Col> }
                { answer.AT1CSEAGTInitials ? this.renderCSEClassLimit('AT1CSEAGTInitials'): '' }
            </Row>
            {/* {this.renderDetailsAttribute('Authorized Gas Tester (AGT) Initials', answer.ATAGT, 6) }
            {this.renderDetailsAttribute('Print name of the Authorized Gas Tester (AGT)', answer.AGTName, 6) }
            {this.renderDetailsAttribute('Sign',(answer.AtmospericAGTSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.AtmospericAGTSignatureURL } /> || 'Nill'), 6 ) } */}
            {this.renderDetailsAttribute('AGT Comments/Special Precautions', answer.AGTComments, 6) }  
        </Row></>);
    }
    renderCSEClassLimit = (field) => {
        const { answer } = this.state;  
        return (answer[field] || []).map((a, index) => {  
            return this.CSEClassLimitHtml(field==='AT1CSEDate'?convertToDateFormat(answer[field][index]):field==='AT1CSETime' ?convertTime(answer[field][index]) :answer[field][index]); 
        });
    }
    CSEClassLimitHtml = (ans) => { 
        return <Col sm={2}> {ans} </Col> 
    }
    renderPermitForm = () => {
        const { answer } = this.state; 
        return (<Row className={'show-grid'}>
            <Col sm={12} xs={12} className="form-page-title"> Form - Permit </Col> 
            {this.renderDetailsAttribute('Permit No',answer.permitNo ) }  
            {this.renderDetailsAttribute('Permit Type',answer.permitType ) } 
            {this.renderDetailsAttribute('Planned Date of Work',answer.plannedDateOfWork ? convertToDateFormat(answer.plannedDateOfWork) : 'NA' ) } 
            {this.renderDetailsAttribute('Time',answer.plannedTimeOfWork ? convertTime(answer.plannedTimeOfWork) : 'NA' ) }
            {this.renderDetailsAttribute('Validity Period',answer.validityPeriod ) }
            {this.renderDetailsAttribute('Location',answer.locationName ) }
            {this.renderDetailsAttribute('Task Description',answer.taskDescription ) }
            {this.renderDetailsAttribute('Task Area',answer.taskArea ) }
            {this.renderDetailsAttribute('Equipment ID/Tag Number',answer.equipmentId ) }
            {this.renderDetailsAttribute('Equipment Description',answer.equipmentDescription ) }
            {this.renderDetailsAttribute('Permit Holder',answer.leadDiscipline ) }
            {this.renderDetailsAttribute('People', (answer.signOffName && answer.signOffName.length>0?answer.signOffName.join(', '):'N/A') ) }
            <Col sm={12} xs={12} className="form-section-title"> <span>Isolations Required</span></Col>
            {this.renderDetailsAttribute('ICC',answer.iccIsolations?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('RFIC',answer.rficIsolations?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Personnel',answer.personnalIsolations?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('None',answer.noneIsolations?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Work Order Number',answer.workOrderNumber ) }
            {this.renderDetailsAttribute('Requested By',answer.requestedBy ) } 
            {this.renderDetailsAttribute('Company',answer.company ) } 
            <Col sm={12} xs={12} className="form-section-title"> <span>Permit Pack Required Documents</span></Col>
            {this.renderDetailsAttribute('Admospheric Test Record',answer.admosphericTestRecords?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Isolation Certificate',answer.isolationCertificate?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('JSA',answer.jsatha?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('SWP Checklist',answer.swpChecklist?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Temporary Defeat',answer.temporaryDefeat?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Lifting Plan',answer.liftingPlan?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('MSDS',answer.msds?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('P&ID/Drawing',answer.pidDrawing?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Procedure',answer.procedure?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Rescue Plan',answer.rescuePlan?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('SIMOP Deviation',answer.simopDeviation?'Yes': 'No', 3 ) }
            {this.renderDetailsAttribute('Work Aid',answer.workAid?'Yes': 'No', 3 ) }
            <Col sm={12} xs={12} style={{color: "yellow" }}> 
                <p className="form-section-title"><span> Facility Incharge (FI): </span></p>
                <p>1. The task description accurately details the permit work activity. The required permit pack documentation has been identified.</p>
                <p>2. I have endorsed this work and it will not conflict with other activities. It can commence when the permit conditions are fulfiled.</p>
                <p>3. I have demonstrated zero energy to the Permit Holder (when required and not delegated). </p>
            </Col>
            {this.renderDetailsAttribute('Print Name',answer.PIReviewerName, 3 ) }
            {this.renderDetailsAttribute('Sign',( (answer.PIReviewerSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.PIReviewerSignatureURL } alt="SafeConnect" />) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.AAReviewerDate ? convertToDateFormat(answer.AAReviewerDate) : 'NA', 3 ) }
            {this.renderDetailsAttribute('Time',answer.AAReviewerTime ? convertTime(answer.AAReviewerTime) : 'NA', 3 ) }
            <Col sm={12} xs={12} className="form-section-title"><span> Overall Incharge (OI):</span> </Col>
            {this.renderDetailsAttribute('Print Name',answer.OIReviewerName, 3 ) }
            {this.renderDetailsAttribute('Sign',( ( answer.OIReviewerSignatureURL && <img className="signature-img" height="56px" width="100%" src={ answer.OIReviewerSignatureURL }  alt="SafeConnect" />) || 'Nill'), 3 ) }
            {this.renderDetailsAttribute('Date',answer.OIReviewerDate ? convertToDateFormat(answer.OIReviewerDate) : 'NA', 3 ) }
            {this.renderDetailsAttribute('Time',answer.OIReviewerTime ? convertTime(answer.OIReviewerTime) : 'NA', 3 ) }
            <Col sm={12} xs={12} className="form-section-title"><span> Permit Shift Handover/Extension/Reissue </span></Col>
            <Row className="show-grid" >
                <Col sm={3}> <span style={{ color: 'yellow' }} >Date </span> </Col> 
                <Col sm={3}> <span style={{ color: 'yellow' }} >Permit Shift Type</span> </Col>
                <Col sm={3}> <span style={{ color: 'yellow' }} >Facility Incharge</span> </Col>
                <Col sm={3}> <span style={{ color: 'yellow' }} >Overall Incharge</span> </Col>  
            </Row>
            <Row className="show-grid" >
                {answer.pshDate ? this.renderPermitShiftHandover() : <><Col sm={12}>No records found..</Col> </> }
            </Row>
            <Col sm={12} xs={12} style={{color: "yellow" }}> 
                1. Any changes of FI, OI personnel on the permit must sign accepting their responsibility.
            </Col>  
        </Row>);
    } 
    renderPermitShiftHandover = () => { 
        const { answer } = this.state;
        return (answer.pshDate || []).map((a, index) => {  
            return this.PermitShiftHandoverHtml(index); 
        });
    } 
    PermitShiftHandoverHtml = (index: number = 1) => { 
        const { answer } = this.state;
        return <>
            <Row className="show-grid" >
                <Col sm={3}> {answer.pshDate[index] ? convertToDateFormat(answer.pshDate[index]) : 'NA'} </Col> 
                <Col sm={3}> {answer.isPSH[index]} </Col>
                <Col sm={3}> {answer.pshPI[index]} </Col>
                <Col sm={3}> {answer.pshOI[index]} </Col>  
            </Row></>
    }
    renderContent = () => { 
        const { answer } = this.state; 
        if (!answer) {
            return <Loader />;
        } 
        /* let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }  */
        return (
            <div className=" "> 
                <Title text={`PTW Report for ${format(new Date(answer.plannedDateOfWork), 'MM/dd/yyyy') }`}></Title>
                <fieldset className={`${(answer.permitType || '').replace(/ /g, "-")}permitForm`}> 
                    {answer.closePermitCloseOut==='1' && this.renderPermitCloseOut()}
                    {this.renderPermitForm() } 
                    {this.renderAdmosphericForm()}  
                    {this.renderIsolationForm()} 
                    {this.renderJSA() } 
                    {this.renderChecklist() }
                    {this.renderTemperaryDefect()} 
                    {this.renderGeneralPermitDocuments() }
                    {this.renderWorkDeclerations() }
                    {answer.closePermitCloseOut!=='1' && this.renderPermitCloseOut()} 
                </fieldset>
            </div>
        );
    }
    renderFormDetails = () => { 
        return (
            <Async
                identifier="OIDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    } 
    render() {
        let fileName = '';
        if (this.props.instance) {
            const { instance: { props: { answers } } } = this.props;
            fileName = `PTW Report for ${format(new Date(answers.plannedDateOfWork), 'MM/dd/yyyy')}`;
        }
        return (
            <BasePage> 
                <DetailsPageWrapper pdfFileName={fileName} className="oi-report report-details ptwDetails" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    } 
}

export function mapStateToProps(state: any, ownProps: any) {
    const viewId = ownProps.match.params.id;
    const instance = PermitToWorkModel.get(viewId); 
    let jsaInstance;
    if(instance && instance.props.answers.formJsaNo)
        jsaInstance = JSAModel.get(instance.props.answers.formJsaNo);
    const checklistAttributes = ChecklistAttributeModel.list();  
    return { instance, viewId, jsaInstance, checklistAttributes };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
