import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'; 
import { IHistory } from '../../interfaces';
import { OIModel } from '../../model/OIModel';
import { getFilteredOIFormList } from '../../services/oiformService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { Card } from '../reusableComponents/Card';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './ListItem';
import positiveObservationAvatar from '../../images/positiveObservations.png';
import negativeObservationAvatar from '../../images/negativeObservation.png';
import nearMissAvatar from '../../images/nearMiss.png'; 

export interface IFilteredListPageProps {
    oiFormInstances: OIModel[];
    filterBy: string;
    filterType: string;
    year: string;
    month: string;
    history?: IHistory;
    userId?: string;
} 
export class FilteredListPageImpl extends React.PureComponent<IFilteredListPageProps, {}> {
    constructor(props: IFilteredListPageProps | Readonly<IFilteredListPageProps>) {
        super(props);
    }

    promise = async () => {
        const { year, month, filterBy, filterType } = this.props;
        await getFilteredOIFormList(filterBy, filterType, year, month);
    }

    renderSummaryCards = () => {
        const { filterBy } = this.props;
        const instances = OIModel.getFiltered(filterBy); 
        let count = 0;
        instances.forEach((instanceData: { props: { nearMiss: string; observationType: string; }; }) => {
            if (filterBy === 'nearmiss' && instanceData.props.nearMiss && instanceData.props.nearMiss === '1') {
                count++;
            } else if (filterBy === 'positive' && instanceData.props && instanceData.props.observationType === 'Positive Observation') {
                count++;
            } else if (filterBy === 'negative' && instanceData.props && instanceData.props.observationType === 'Negative Observation') {
                count++;
            }
        });
        return (
            <Grid style={{
                width: '100%'
            }}>
                {filterBy === 'nearmiss'
                    ? <Row className="show-grid ">
                        <Col sm={12}>
                            <Card className="oi-report-card">
                                <img src={nearMissAvatar} alt="SafeConnect"/>
                                <h4
                                    style={{
                                        margin: '15px 0px 0px 0px'
                                    }}>Near Miss</h4>
                                <p
                                    style={{
                                        paddingTop: '0px'
                                    }}>{count || 0}</p>
                            </Card>
                        </Col>
                    </Row>
                    : ''}

                {filterBy === 'negative'
                    ? <Row className="show-grid ">
                        <Col sm={12}>
                            <Card className="oi-report-card">
                                <img src={negativeObservationAvatar} alt="SafeConnect" />
                                <h4>Negative Observations</h4>
                                <p>{count}</p>
                            </Card>
                        </Col>
                    </Row>
                    : ''}

                {filterBy === 'positive'
                    ? <Row className="show-grid ">
                        <Col sm={12}>
                            <Card className="oi-report-card">
                                <img src={positiveObservationAvatar} alt="SafeConnect" />
                                <h4>Positive Observations</h4>
                                <p>{count}</p>
                            </Card>
                        </Col>
                    </Row>
                    : ''}
            </Grid>
        );
    }

    renderList = () => {
        const { filterBy } = this.props;
        const instances = OIModel.getFiltered(filterBy); 
        return <Grid
            className="oi-report-list"
            style={{
                width: '100%',
                padding: '20px'
            }}>
            {this.renderSummaryCards()}
            <ListPage
                style={{
                    minHeight: '50vh',
                    padding: '20px 0px 0px 0px'
                }}
                pageHeading={`Filtered By ${filterBy}`}
                instances={instances}
                rowHeadings={['Date Submitted', 'Observer', 'Observation', 'Action Taken', 'Near Miss','Follow-up Status']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={['actionTaken', 'observation', 'date']}
                searchPlaceHolder="Enter action taken, observation or date created to search..."
                emptyInstancesMessage={`No forms found for ${filterBy}`}
                sm={[2, 2, 2, 2, 2, 1]}
            />
        </Grid>;

    }

    render() {
        return (
            <BasePage className="wide-list-page" >
                <Async
                    identifier="FilteredOIList"
                    promise={this.promise}
                    loader={< Loader />}
                    error={< ErrorPage />}
                    content={this.renderList()} />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const oiFormInstances = OIModel.list(state);
    const filterBy = ownProps.match.params.id;
    const filterType = ownProps.match.params.filterType;
    const year = ownProps.match.params.year;
    const month = ownProps.match.params.month;
    const userId = state
        .login
        .get('userId'); 
    return { oiFormInstances, userId, filterBy, filterType, year, month };
}

export const FilteredListPage =
    withRouter(connect<IFilteredListPageProps, any, any>(mapStateToProps)(FilteredListPageImpl));
