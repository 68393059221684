import * as React from 'react';
import PropTypes from 'prop-types'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IForms, IHistory } from '../../interfaces';
import { SafetyWalkthroughModel, WalkthroughModel } from '../../model/SafetyWalkthroughModel';
import { getAllCheckListForms } from '../../services/attributeService';
import { BasePage } from '../reusableComponents/BasePage';  
import './swFormList.scss'; 
import { setLoading, setSuccess} from '../../actions/loadingActions'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
  
import { Async } from '../reusableComponents/Async';
import { ListPage } from '../reusableComponents/ListPage';
import { ListItem } from './ListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { Loader } from '../reusableComponents/Loader'; 
import { ChecklistNameModel } from '../../model/ChecklistModel';
import { getAllCheckListNames, /* getAllCheckListNamesOptions */ } from '../../services/checklistService'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader'; 
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { dispatch } from '../../utils/generalUtils';
import { actions } from 'react-redux-form';
declare var require: any;
const queryString = require('query-string'); 

export interface ISWFormListPageProps { 
    forms: IForms;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    swInstances: SafetyWalkthroughModel[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
    totalPageCount: number;
    checklistType: string;
    checklistNames: ChecklistNameModel[];
    axiosPreviousToken: any;
} 
export interface ISWFormListPageState {
    showDetails: boolean;
    formId: string;
    swInstances: SafetyWalkthroughModel[]; 
    isLoading: boolean;
}

export class SWFormListPageImpl extends React.Component<ISWFormListPageProps, ISWFormListPageState> {
    constructor(props: ISWFormListPageProps | Readonly<ISWFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '', swInstances: [], isLoading: false, };
    }
    sortListing = {}
    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {
        if(this.props.checklistType === 'other-checklists') {
            this.sortListing = { 0: 'date', 1: 'username', 2:'FormType', 3: 'checklistTitle', 4: 'serialNo' };
        } else { 
            this.sortListing = { 0: 'date', 1: 'username', 2:'FormType' };
        }
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await getAllCheckListNames();  
        await this.getReportData(urlParams.sortingIndex);
    }
    async componentDidUpdate(prevProps: any) {
        const { checklistType } = this.props;
        if (prevProps.checklistType !== checklistType) { 
            await dispatch(actions.reset('forms.commonFilterForm')); 
            await this.getReportData();
        }
    }
    getReportData = async (sortIndex='false') => {
        const { checklistType } = this.props;   
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {};
        urlParams.sortIndexField = this.sortListing[sortIndex];  
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        let params = {...urlParams, checklistType, ...this.props.forms.commonFilterForm}
        await getAllCheckListForms( params );
        setSuccess('AllListPage'); 
    }
    getChildContext() {
        return { formModel: 'forms.reportFilterForm' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };

    identifier = 'swFormList';
      
    /* submitFilters = async (type: any) => { 
        const { forms, checklistType } = this.props; 
        if(type==='filter') {
            let checklistName = (forms as any)['reportFilterForm']['checklist']!=='all'?(forms as any)['reportFilterForm']['checklist']:'';
            let params = {checklistType, pageNumber: 1, checklistName: checklistName }; 
            this.setState({isLoading: true});
            await getAllCheckListForms(params); 
            this.setState({isLoading: false});
        } else { 
            dispatch(actions.change('forms.reportFilterForm.checklist', 'all')); 
            let params = { checklistType, pageNumber: 1 }; 
            this.setState({isLoading: true});
            await getAllCheckListForms(params); 
            this.setState({isLoading: false});
        }
    }  */
    onConfirmDeleteAttribute = (attributeData: any) => { 
        //this.submitFilters('filter');
    }
    /* getAllCheckListNamesOptions = async () => { 
        //if(ChecklistNameModel.list().length<1) {
        //    await getAllCheckListNames(); 
        //} 
        //let checklists = ChecklistNameModel.list(); 
        let checklists = this.props.checklistNames;
        let options: any = [];
        checklists.forEach(el => { 
            options.push({ 'value': el.props.id, 'label': el.props.checklistName })
        });  
        return options;
    } */
    
    /* renderFilterOptions = () => {
        if(this.props.checklistType==='safety-walkthrough')
            return; 
        let checklists = this.props.checklistNames;
        let options: Array<any> = [];
        options.push({ 'value': 'all', 'label': 'All' })
        checklists.forEach(el => { 
            options.push({ 'value': el.props.id, 'label': el.props.checklistName })
        }); 
        return (
            <div className="filter-container p0" >
                <Form loader={<SubmissionLoader />} model="forms.reportFilterForm" onSubmit={undefined}>
                    <Row>
                        <Col sm={{span:6, offset:1}} md={{span:6, offset: 1}} className="input">Checklist Type: &nbsp;  
                            <RRFInput
                            id="checklist" 
                            model=".checklist"
                            type="dropdown" 
                            menuItems={options}  
                        /></Col>
                        <Col sm={2} md={2} className="input process-buttons"><button className="filter-button" onClick={() => this.submitFilters('filter')}  >Filter</button> </Col>
                        <Col sm={2} md={2} className="input process-buttons"><button className="filter-reset-button"  onClick={() => this.submitFilters('reset')} >Reset</button> </Col>
                    </Row>
                </Form>
            </div> 
        )
    } */

    renderFormList = () => {
        const { swInstances, checklistType } = this.props;  
        let rowHeadings: any = [];
        let rowSm: any = []; 
        let exportFileName: string = '';
        let additionalFilters: any = [];
        if(checklistType === 'other-checklists') {
            rowHeadings = ['Date Submitted','User Name', 'Form Type', 'Checklist Title', 'Serial No.',  'Follow-up Status'];
            rowSm = [ 2, 2, 2, 2, 2, 1];
            exportFileName = 'Checklist Report';
            additionalFilters = ['checklistNames']
        } else {
            rowHeadings = ['Date Submitted','User Name', 'Form Type',  'Follow-up Status'];
            rowSm = [ 2, 3, 3, 3];
            exportFileName = 'Safety Walkthrough Report';
        }
        

        return <div className="office-safety-wrapper checklist-wrapper"> 
            {this.state.isLoading && <SubmissionLoader/>}  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} additionalFilters={additionalFilters} />
            {<ExcelExport formType={`${checklistType}`} fileName={`${exportFileName}`} />}
            <><ListPage
                pageHeading={`${checklistType==='safety-walkthrough'?'Safety Walkthrough Report':'Checklist Report'}`} 
                instances={swInstances}
                rowHeadings={rowHeadings}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={['userName', 'formType', 'date']}
                searchPlaceHolder="Enter team, group, form type to search..." 
                emptyInstancesMessage="No report found."
                sm={rowSm}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                /* filterComponent={this.renderFilterOptions()} */
                rowSortings={this.sortListing} 
                promise={(sortIndex) => this.getReportData(sortIndex)}
                forms={this.props.forms}
                history={this.props.history }
            />  
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={WalkthroughModel as any}
                identifier={'AllListPage'}
            />
        </>
        </div>;
    } 

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" onConfirm={this.onConfirmDeleteAttribute}/> 
                <AlertModal id="delete-success" />
                <Async
                    identifier={this.identifier}
                    promise={this.promise}
                    loader={<Loader />}
                    error={<div></div>}
                    content={this.renderFormList()}
                /> 
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const checklistType = ownProps.match.params.checklistType;
    const checklistNames = ChecklistNameModel.list(state);
    return {
        swInstances: state.report.get('reportDataList'), totalPageCount: state.miscellaneous.get('listCount'), checklistType, forms: state.forms, checklistNames, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const SWFormListPage = withRouter(connect<ISWFormListPageProps, any, any>(mapStateToProps)(SWFormListPageImpl));
