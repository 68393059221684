import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';  
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { Pagination } from '../reusableComponents/Pagination'; 
import { ListItem } from './ListItem'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { dispatchCommonFilter } from '../../utils/generalUtils';
import { HPModel } from '../../model/HPModel';
import { getAllHPForms } from '../../services/hpFormService';
declare var require: any;
const queryString = require('query-string');

export interface IHPFormListPageProps {
    hpFormInstances: HPModel[];
    history?: IHistory; 
    totalPageCount?: number;
    userId: string; 
    forms: IForms;
    axiosPreviousToken: any;
}  
export interface IHPFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class HPFomListPageImpl extends React.PureComponent<IHPFormListPageProps, IHPFormListPageState> {
    constructor(props: IHPFormListPageProps | Readonly<IHPFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'username', 2:'projectName', 3: 'groupName', 4: 'overallResult' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await dispatchCommonFilter(urlParams);
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }

    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        urlParams.sortIndexField = this.sortListing[sortIndex];
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllHPForms(urlParams, this.props.forms);  
        setSuccess('AllListPage');
    } 

    renderFormList = () => {
        const { hpFormInstances } = this.props; 
        return <div className="oi-list-wrapper hp-report" >
            <ListPage
                style={{ minHeight: '50vh', padding: '0px 0px 0px 0px' }}
                titleStyle={{ padding: '0px 70px' }}
                pageHeading="Human Performance Report List"
                instances={hpFormInstances}
                rowHeadings={['Date Submitted', 'Name', 'Team', 'Group', 'Checklist Result' ]}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={[ 'username', 'observation', 'date', 'groupName', 'projectName','specifiedLocation']}
                searchPlaceHolder="Enter project, group to search..."
                emptyInstancesMessage="No HP report found."
                sm={[2, 2, 2, 2, 3]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1} 
                rowSortings={this.sortListing} 
                promise={(sortIndex) => this.getReportData(sortIndex)}
                forms={this.props.forms}
                history={this.props.history }
            />
            { UserModel.checkUserAccess('o&i_add') && this.renderAddNewFormButton()} 
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={HPModel as any} 
                identifier="AllListPage"
            />
        </div>;
    }
 
    renderAddNewFormButton = () => { 
        return (
            <button className="add-new-report"
                onClick={() => { this.props.history?.push('/human-performance/create'); }}
            >
                +
            </button>
        );
    } 
    renderContent = () => { 
        return (
            <Grid className="oi-report-list" style={{ width: '100%', padding: '20px' }}> 
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
                <ExcelExport formType="hpForm" fileName="Human Performance Report" />
                {this.renderFormList()} 
            </Grid>
        );
    }
    render() {
        return (
            <BasePage className="extra-wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="HPFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const hpFormInstances = HPModel.list(state);
    const userId = state.login.get('userId'); 
    return { 
        totalPageCount: state.miscellaneous.get('listCount'), hpFormInstances, userId, forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const HPFormListPage = withRouter(connect<IHPFormListPageProps, any, any>(mapStateToProps)(HPFomListPageImpl));
