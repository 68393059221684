import { showModal } from '../../actions/modalActions';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { UserModel } from '../../model/UserModel';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { IHistory } from '../../interfaces';
const MenuItem = Dropdown.Item;

export interface IListItemImplProps {
    history?: IHistory;
    index?: number;
    instance?: UserModel;
    userId: string;
    style?: React.CSSProperties;
}

export interface IListItemState {
    displayOptions: boolean;
}

export class ListItemImpl extends React.PureComponent<IListItemImplProps, IListItemState> {

    constructor(props: IListItemImplProps | Readonly<IListItemImplProps>) {
        super(props);
        this.state = { displayOptions: false };
    }

    static defaultProps: IListItemImplProps;

    renderUserOptions = (e: any) => {
        this.setState({
            displayOptions: !this.state.displayOptions
        });
    }

    handleDelete = (id: any) => {
        const userInstance = UserModel.get(id);
        new UserModel(userInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-user', userInstance.props.firstName, userInstance);
    }

    renderDropdown = () => {
        const { instance } = this.props; 
        if(!instance)
            return;
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">
                { UserModel.checkUserAccess('user_edit') && <MenuItem 
                    onClick={() => this.props.history?.push(`/users/${instance.props.id}/edit`)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</MenuItem> }
                { UserModel.checkUserAccess('user_delete') && <MenuItem 
                    onClick={() => this.handleDelete(instance.props.id)} className="item">
                    <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</MenuItem> }
            </DropdownButton>
        );
    }

    renderUserList = () => {
        const { instance, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        const { props: { firstName, middleName, lastName } } = instance; 
        if(UserModel.getUserUID()===instance.props.userUID)
            return '';
        return (
            <Row className="report-row" style={style}>
                <Col sm={1}>
                    { (UserModel.checkUserAccess('user_edit') || UserModel.checkUserAccess('user_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{instance.props.id}</span>
                </Col>
                <Cell
                    onClick={() => this.props.history?.push(`/users/${instance.props.id}`)}
                    sm={5}>
                    {`${firstName} ${middleName || ''} ${lastName}` || 'NA'}
                </Cell>
                <Cell
                    onClick={() => this.props.history?.push(`/users/${instance.props.id}`)}
                    sm={2}>
                    {instance.props.accessLevel || 'NA'}
                </Cell>
                <Cell
                    onClick={() => this.props.history?.push(`/users/${instance.props.id}`)}
                    sm={3}>
                    {instance.props.jobTitle || 'NA'}
                </Cell>
            </Row >
        );
    }

    render() {
        return this.renderUserList();
    }
}

export function mapStateToProps(state: any) {
    return {
        userId: state.login.get('userId')
    };
}

export const ListItem = withRouter(connect<IListItemImplProps, any, any>(mapStateToProps)(ListItemImpl));
