import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { Row, Col } from 'react-bootstrap';
import { IHistory } from '../../interfaces'; 
import { HPModel } from '../../model/HPModel'; 
import { convertToDateFormat, KeyValue } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage'; 
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title'; 
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { getFormData } from '../../services/hpFormService';
import './hpForm.scss'; 

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    instance: HPModel;
    viewId: string;
}
export interface IDetailsPageState {
    imageURLs: string[];
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''] };
    }

    promise = async () => { 
        if(!this.props.instance) {
            await getFormData(this.props.viewId)
        }
        return null;
    }
 
    renderContent = () => {
        const { instance } = this.props;
        if (!instance) {
            return <Loader />;
        } 
        let answer = instance.props.answers;
        return (
            <div>
                <Title text={`Human Performance Report for ${convertToDateFormat(answer.dateOfIncident ) }`}></Title> 
                <KeyValue label="Observer">{instance.props.username || 'NA'}</KeyValue>
                <KeyValue label="Team">{instance.props.projectName || 'NA'}</KeyValue>
                <KeyValue label="Group">{instance.props.groupName || 'NA'}</KeyValue>
                <KeyValue label="Location Details">{answer.locationDetails || 'NA'}</KeyValue>
                <KeyValue label="Location Name">{answer.specifiedLocation || 'NA'}</KeyValue> 
                <Row className="show-grid hp-checklist details">
                    <Row className="show-grid heading">
                        <Col sm={1} xs={12}>S.No</Col>
                        <Col sm={8} xs={12}>Checklist</Col>
                        <Col sm={3} xs={12}>Options</Col>
                    </Row>
                    <Row className="show-grid checklists">
                        <Col sm={1} xs={12} className="sno">1</Col>
                        <Col sm={8} xs={12}>I feel fit and able for today’s work</Col>
                        <Col sm={3} xs={12} className="options">
                            { (answer.fitandable==='2'? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}
                        </Col>
                    </Row>
                    <Row className="show-grid checklists">
                        <Col sm={1} xs={12} className="sno">2</Col>
                        <Col sm={8} xs={12}>I know my job and confident to safely plan & execute</Col>
                        <Col sm={3} xs={12} className="options">
                            { (answer.planandexecute==='2'? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}
                        </Col>
                    </Row>
                    <Row className="show-grid checklists">
                        <Col sm={1} xs={12} className="sno">3</Col>
                        <Col sm={8} xs={12}>I know my team and they are capable for the job</Col>
                        <Col sm={3} xs={12} className="options">
                            { (answer.teamcapable==='2'? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}
                        </Col>
                    </Row>
                    <Row className="show-grid checklists">
                        <Col sm={1} xs={12} className="sno">4</Col>
                        <Col sm={8} xs={12}>I have no external distractions and am able to work</Col>
                        <Col sm={3} xs={12} className="options">
                            { (answer.noexternaldistractions==='2'? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}
                        </Col>
                    </Row>
                    <Row className="show-grid checklists">
                        <Col sm={9} xs={12} className="overallscorelabel">Overall Score</Col>
                        <Col sm={3} xs={12} className="overallscore">{ (answer.overallResult==='safe'? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}</Col> 
                    </Row>
                </Row>
                <Row className="show-grid" >
                    <KeyValue label="Comment">{answer.comments || 'NA'}</KeyValue> 
                </Row>

            </div>
        );
    }

    renderFormDetails = () => { 
        return (
            <Async
                identifier="HPDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        let fileName = '';
        if (this.props.instance) {
            const { instance: { props: { dateOfIncident } } } = this.props;
            fileName = `Human Performance Report for ${dateOfIncident}`;
        } 
        return (
            <BasePage> 
                <DetailsPageWrapper pdfFileName={fileName} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const viewId = ownProps.match.params.id;
    const instance = HPModel.get(viewId); 
    return {
        instance, viewId
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
