import { dispatch } from '../../../utils/generalUtils';
import * as React from 'react';
import { actions, Form as RRFForm } from 'react-redux-form';

export interface IFormProps {
    model: string;
    loader?: JSX.Element;
    onSubmit?: (value: any) => void;
    className?: string;
}

export interface IFormState {
    showSubmissionLoader: boolean;
}

export class Form extends React.PureComponent<IFormProps, IFormState> {
    constructor(props: IFormProps | Readonly<IFormProps>) {
        super(props);
        this.state = { showSubmissionLoader: false };
    }

    componentWillUnmount() {
        const { model } = this.props;
        dispatch(actions.reset(model));
    }

    handleSubmit = async (value: any) => {
        const { onSubmit } = this.props;
        if (!onSubmit) {
            return;
        }
        this.setState({
            showSubmissionLoader: true
        });
        try {
            await onSubmit(value);
            this.setState({
                showSubmissionLoader: false
            });
        } catch (error) {
            this.setState({
                showSubmissionLoader: false
            });
            throw error;
        }
    }

    static defaultProps = {
        className: ''
    };

    render() {
        const { props: { loader, className, model },
            state: { showSubmissionLoader } } = this;
        return <RRFForm className={`rrf-form ${className}`} model={model} onSubmit={this.handleSubmit}>
            {this.props.children}
            {showSubmissionLoader ? loader : null}
        </RRFForm>;
    }
}
