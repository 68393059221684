import * as React from 'react'; 
import { actions } from 'react-redux-form';
import { showMapInputModal } from '../../../actions/modalActions';
import { ISelectOptions } from '../../../interfaces';
import { dispatch } from '../../../utils/generalUtils';

export interface IOptions {
    value?: string;
    label?: string;
    date?: string;
    model?: string;
}

export interface ILocationOptionComponentProps {
    className?: string;
    isDisabled?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    onFocus?: Function;
    onSelect?: Function;
    option: ISelectOptions;
}

export class LocationOptionComponent extends React.PureComponent<ILocationOptionComponentProps, {}> {
    constructor(props: ILocationOptionComponentProps | Readonly<ILocationOptionComponentProps>) {
        super(props);
    }

    handleMouseDown = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const { option } = this.props; 
       
        this.props.onSelect && this.props.onSelect(this.props.option, event);
        if (option.value === 'Other') {
            option.model && dispatch(actions.change(option.model, `Other`));
            return;
        } 
        showMapInputModal('id', option.locationDetails.locations, option.model,
            option.locationDetails.center);
    }

    handleMouseEnter = (event: any) => {
        this.props.onFocus && this.props.onFocus(this.props.option, event);
    }

    handleMouseMove = (event: any) => {
        if (this.props.isFocused) {
            this.props.onFocus && this.props.onFocus(this.props.option, event);
        }
    }
 
    render() {  
        /* if (this.demoOptions.indexOf(this.props.children.toString()) > -1) {
            return null;
        } */
        /* if(this.props.option['locationDetails']===undefined)
            return null; */
        let optionHide = this.props.option['locationDetails']===undefined?'hide':null;
        return (
            <div className={`${this.props.className} ${optionHide}` }
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
            >
                {this.props.children || 'NA'}
            </div>
        );
    }
}
