import { BaseModel } from './BaseModel';

export interface IProjectModelProps {
    id: string;
    projectCreator: string;
    creatorName?: string;
    projectId: string;
    projectName: string;
    projectDesc: string;
    groups: string[];
    groupId: string[];
    dateUpdated: string;
    dateCreated: string;
    projectImages?: string[];
    imageUrls?: string[];
}

export class ProjectModel extends BaseModel<IProjectModelProps> {
    constructor(props: IProjectModelProps) {
        super(props, ProjectModel.resource);
    }
    static resource = 'project';

    static getIdByName(projectName: string) {
        const projectList = ProjectModel.list();
        let projectId = '';
        if (projectList.length <= 0) {
            return;
        }
        projectList.forEach((projectInstance: { props: { projectName: string; projectId: string; }; }) => {
            if (projectInstance.props.projectName === projectName) {
                projectId = projectInstance.props.projectId;
            }
        });
        return projectId;
    }
}
