import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { IForms } from '../../interfaces';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { printDetails } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { ChecklistSummaryData } from './ChecklistSummaryData';
import { getChecklistSummary } from '../../services/checklistService';
import { Loader } from '../reusableComponents/Loader';  
import { ChecklistSummaryDataProps } from '../../model/ChecklistModel';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage'; 

export interface IChecklistSummaryProps {
    reportsData: ChecklistSummaryDataProps;
    userId: string;
    forms?: IForms;
}

export interface IChecklistSummaryState {
    mountPrintPreviewModal: boolean; 
    filterBy: string;
}

export class ChecklistSummaryPageImpl extends React.Component<IChecklistSummaryProps,
IChecklistSummaryState> {
    constructor(props: IChecklistSummaryProps) {
        super(props);
        /* let reportData: ChecklistSummaryDataProps = { reportCountData: {} }; */
        this.state = { mountPrintPreviewModal: false, filterBy: '', /* reportsDataValue: reportData */ };
    }

    promise = async () => {
        /* let params: any = {}; 
        const defaultYear = new Date().getFullYear().toString();  
        params['filterType'] = 'cumulative';
        params['year'] = defaultYear; */ 
        await getChecklistSummary();
    }
    async componentDidMount() { 
        /* let params: any = {}; 
        const defaultYear = new Date().getFullYear().toString();  
        params['filterType'] = 'cumulative';
        params['year'] = defaultYear; 
        await getChecklistSummary(); */
    }

    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any;

    renderContent = (id?: any) => {
        if(!this.props.reportsData || !this.props.reportsData.checklistNames) {
            return <Loader></Loader> 
        }
        return <div id="summary-reports">
            <ChecklistSummaryData
                reportsData={this.props.reportsData}
                getRef={value => this.ref = value}
                id={id}
                isPrint={id ? true : false}
                getFilterBy={filterBy => { this.setState({ filterBy }); }}
                filterBy={this.state.filterBy}
                forms={this.props.forms}
            /> 
             <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
            </div>
        </div>;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'SafetyWalkthroughReports');
    }

    renderPrintPreviewModal = () => {
        showPrintPreviewModal('checklist-summary-report'); 
        return;
    }

    render() {
        return (
            <BasePage className="reports-page">
                <PrintPreviewModal
                    id="checklist-summary-report"
                    ref="printPreview"
                    className="oi-report-data"
                    fileName="Checklist Summary"
                    onClickYes={this.exportCharts}
                    style={{ boxShadow: 'none' }}
                >
                    {this.renderContent(true )}
                </PrintPreviewModal>
                <Async
                    identifier="OIFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent(false)}
                />
            </BasePage >
        );
    }

    renderq() {
        return <BasePage className="reports-page checklist-summary" >
            {/* <PrintPreviewModal
                id="checklist-summary-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="ChecklistSummaryReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent(true)}
            </PrintPreviewModal>  */}
            {/* {this.state.mountPrintPreviewModal ? <PrintPreviewModal
                id="office-safety-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="OfficeSafetyReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent('print')}
            </PrintPreviewModal> : ''}
            {this.renderContent()}  */}
             {this.renderContent(true)} 
        </BasePage>;
        /* return <BasePage className="reports-page checklist-summary" > 
                <Async
                    identifier="covidSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent(true ) }
                /> 
            </BasePage>; */
    }
}

export function mapStateToProps(state: any) { 
    let userId = state.login.get('userUID');
    return { reportsData: state.report.get('reportChartData'), userId, forms: state.forms, };
}

export const ChecklistSummaryPage =
    connect<{}, {},
    IChecklistSummaryProps>(mapStateToProps)(ChecklistSummaryPageImpl);
