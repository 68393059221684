import { format } from 'date-fns'; 
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { IAnswer, IForms, IHistory } from '../interfaces'; 
import { showAlert} from '../utils/generalUtils';
import { getCustom, post } from '../utils/HTTP';
import { HPModel } from '../model/HPModel';
import { saveListCount } from '../actions/miscellaneousActions';
import { saveHPFormSummary } from '../actions/summaryActions';
import { UserModel } from '../model/UserModel';

export async function submitHPForm(userId: string, date: string, answers: IAnswer[],
    history: IHistory, groupName: string, projectName: string, forms: IForms, overallResult: string, editId?: string) {  
    const submittedValues: any = {
        groupName,
        userId,
        formId: 'hpForm',
        date: editId ? answers[0].answer : format(new Date(), 'yyyy-MM-dd'),
        time: editId ? answers[1].answer : format(new Date(), 'HH:mm'),
        answers,
        projectName,
        associatedFormId: '',
        followUp: '0',
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: [],
        videoData: [],
        overallResult,
    }; 

    try {  
        submittedValues.deletedImages = [];
        submittedValues.deletedVideos = [];
        submittedValues.editId = editId;  
        const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'hp-form', 'danger');
            return;
        } 
        showAlert(response.data.message, 'hp-modal', 'success', async () => {
            history.push('/home');  
        });
        return response; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'hp-form', 'danger');
        throw error;
    } 
}  
function formattingAnswers(answers: Array<{ quesType: string, quesNum: number, answer: any }>) {
    const formattedAnswer: any = {}; 
    answers.forEach((answer: { quesType: string, quesNum: number, answer: any }) => {
        formattedAnswer[answer.quesType] = answer.answer
    }) 
    return formattedAnswer;
}
function marshallFormData(data: any) {
    const formattedData: any = {
        id: data.key,
        answers: formattingAnswers(data.answers)
    } 
    delete data['answers'];
    Object.assign(formattedData, data);
    return formattedData
}
export async function getAllHPForms(urlData?: any, forms?: IForms) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        let data = { ...forms?.commonFilterForm, formType: 'hpForm', pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken);  
        if (response.data.status === 'valid') {
            HPModel.deleteAll();
            response
                .data
                .data
                .forEach((data: any) => {
                    let formData = marshallFormData(data)
                    new HPModel(formData).$save();
                });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function geHPFormSummaryData(forms?: IForms ) { 
    try { 
        const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '', month: forms?.commonFilterForm.month || '', }; 
        const response = await post('/GetHPReportSummary', data);  
        saveHPFormSummary(response.data.data); 
    } catch (error) {
        throw error;
    }
}

export async function getFormData(formId: string) {
    const company = UserModel.getCompanyName();
    //let requestData = {company, formId, formType: 'hpForm' }
    try {
        //const response = await post(`GetForms`, requestData);   
        const response = await getCustom(`GetForms/hpForm/${company}/${formId}`);
        HPModel.deleteAll(); 
        if (response.data.status === 'valid' && response.data.data.length) {
            const formData = marshallFormData(response.data.data[0]);
            new HPModel(formData).$save();
        }
    } catch (e) {
        throw e
    }  
}
