import * as React from 'react';
import { connect } from 'react-redux';

export interface IValues {
    value?: string;
    label?: string;
    date?: string;
}

export interface ILocationValueComponentProps {
    placeholder?: string;
    value?: IValues;
    subValue?: string; 
    locationDetails: any;
}

export class LocationValueComponentImpl extends React.PureComponent<ILocationValueComponentProps, {}> {
    constructor(props: ILocationValueComponentProps | Readonly<ILocationValueComponentProps>) {
        super(props);
    }

    render() {
        return (
            <div
                title={this.props.subValue + '' + `(${this.props.children})`}
                className="Select-value" style={{ paddingRight: '30px' }}
            >
                <span className="Select-value-label">
                    {this.props.children}
                </span>
            </div>
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        locationDetails: state.forms.reactSelect
    };
}

export const LocationValueComponent =
    connect<{}, {}, ILocationValueComponentProps>(mapStateToProps)(LocationValueComponentImpl);
