import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../../interfaces'; 
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { ListPage } from '../../reusableComponents/ListPage';
import { Loader } from '../../reusableComponents/Loader';
import { ListItem } from './ListItem';
import { Pagination } from '../../reusableComponents/Pagination';
import { getAllCloseCallCheckListForm } from '../../../services/closeCallService';
import { CloseCallCheckListModel } from '../../../model/MechModel';  
import { UserModel } from '../../../model/UserModel';
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
declare var require: any;
const queryString = require('query-string'); 

export interface ICloseCallCheckListPageProps {
    closeCallChecklistInstances: CloseCallCheckListModel[];
    history?: IHistory;
    totalPageCount: number;
    forms: IForms;
}

export class CloseCallCheckListPageImpl extends React.PureComponent<ICloseCallCheckListPageProps, {}> {
    constructor(props: ICloseCallCheckListPageProps | Readonly<ICloseCallCheckListPageProps>) {
        super(props);
    } 
    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        /* let params = {checklistType:'CloseCallChecklist', pageNumber}
        await getAllCloseCallCheckListForm(params); */
        await this.getReportData();
        return null;
    } 
    getReportData = async () => {
        setLoading('reportList');  
        let pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1 
        let params = {pageNumber, checklistType:'CloseCallChecklist', ...this.props.forms.commonFilterForm}
        await getAllCloseCallCheckListForm( params );
        setSuccess('reportList'); 
    }

    renderAddNewFormButton = () => { 
        const { history } = this.props;
        return (
            <button className="add-new-report" onClick={() => { history?.push('/close-call-checklist'); }} > + </button>
        );
    } 
    renderSummaryPageButton = () => {
        return (
            <button className="summary-page" onClick={() => { this.props.history?.push('/close-call-summary'); }} >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    } 
    renderContent = () => {
        const { closeCallChecklistInstances } = this.props; 
        return <div className="office-safety-wrapper">
            <Async
                identifier="reportList" 
                loader={<Loader />}
                error={<ErrorPage />}
                content={ 
                    <><ListPage
                        pageHeading="Close Call Checklist Report"
                        instances={closeCallChecklistInstances}
                        rowHeadings={['Date Submitted','User Name', 'Form Type',  'Follow-up Status']}
                        listItemComponent={ListItem}
                        isSearchable={true}
                        searchableBy={['UserName', 'FormType', 'Date']}
                        searchPlaceHolder="Enter user name or date created to search..."
                        emptyInstancesMessage="No Close Call Checklist report found."
                        sm={[ 2, 3, 3, 3]}
                        currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    />
                    { UserModel.checkUserAccess('incident_investigative_add') && this.renderAddNewFormButton()}
                    { UserModel.checkUserAccess('incident_investigative_add') && this.renderSummaryPageButton()}
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.promise(pageNumber)}
                        totalCount={this.props.totalPageCount}
                        Model={CloseCallCheckListModel as any}
                        identifier="AllListPage"
                    />
                </> }
            />
        </div>;
    } 
    render() {
        return (
            <BasePage className="wide-list-page"> 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="OfficeSafetyFormReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
} 
export function mapStateToProps(state: any) {
    const closeCallChecklistInstances = CloseCallCheckListModel.list(); 
    return { closeCallChecklistInstances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, };
} 
export const CloseCallCheckListPage = withRouter(connect<ICloseCallCheckListPageProps, any, any>
    (mapStateToProps)(CloseCallCheckListPageImpl)); 
