import { setSuccess } from '../actions/loadingActions';  
import { getCustom, post } from '../utils/HTTP';   
import { format } from 'date-fns';  
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { UserModel } from '../model/UserModel';
import { showAlert, dispatch } from '../utils/generalUtils'; 
import { IForms, IHistory } from '../interfaces'; 
import { ChecklistNameModel, ChecklistSummaryDataProps } from '../model/ChecklistModel';
import { getReportChartData } from '../actions/reportActions';

export async function submitChecklistName(values: any, history?: IHistory, checklistType?: string) {
    const company = UserModel.getCompanyName();
    const userId = UserModel.getUserUID();
    let submittedValues = { 
        formType: checklistType || 'checklistForm', 
        checklistName: values.checklistName,
        referenceForm: values.referenceForm || '',
        company, userId,
        createdDate: format(new Date(), 'yyyy-MM-dd'),
        createdTime: format(new Date(), 'HH:mm:ss')
    };  
    try {
        const response =  await post('/SubmitChecklistName', submittedValues);
        if(response.data.message==='duplicate') {
            showAlert('This checklist name already exists!! Try another.', 'create-checklist', 'danger');
            return;
        }
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'create-checklist', 'danger');
            return;
        }  
        showAlert(response.data.message, 'create-checklist-modal', 'success', () => { history?.push(!checklistType ?'/checklist':'/audit'); });
        //showAlert(response.data.message, 'create-checklist-modal', 'success', () => { history?.push(checklistType=='auditChecklistForm'?'/auditChecklistForm':'/checklistForm'); });
        return response.data; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'create-checklist', 'danger');
        throw error;
    } 
} 
export async function getAllCheckListNames(formType?: string) {
    const company = UserModel.getCompanyName();
    try {
        const response =  await getCustom(`/GetChecklistName/${company}/${formType || 'checklistForm'}`);
        if (response.data.status === 'valid') {
            ChecklistNameModel.deleteAll();
            response
                .data
                .data
                .forEach((Data: any) => { 
                    Data.id = Data.key; 
                    new ChecklistNameModel(Data).$save();
                }); 
        }
        return response.data; 

    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'create-checklist', 'danger');
        throw error;
    } 
} 
export async function getAllCheckListNamesOptions() {
    let checklists = ChecklistNameModel.list();
    let options: any = [];
    checklists.forEach((el: { props: { key: any; checklistName: any; }; }) => { 
        options.push({ value: el.props.key, label: el.props.checklistName })
    }); 
    return options;
}

/* export async function getChecklistSummary(params: any) { */
export async function getChecklistSummary(forms?: IForms ) {  
    const userId = UserModel.getUserUID();
    try {
        /* params['company'] = company;
        params['userId'] = userId;
        const response =  await post('/getChecklistSummary', params); */ 
        const defaultYear = new Date().getFullYear().toString();  
        const data = { userId, teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || defaultYear, month: forms?.commonFilterForm.month || '', }; 
        const response = await post('/getChecklistSummary', data); 

        if (response.data != null) {
            let summaryData: ChecklistSummaryDataProps = {};
            summaryData.reportCountData = response.data.data
            summaryData.checklistNames = response.data.checklistNames;
            dispatch(getReportChartData(summaryData));
        }
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'create-checklist', 'danger');
        throw error;
    } 
}

export async function deleteChecklistName(checklistId: string) {
    const userId = UserModel.getUserUID();
    try {
        let submittedValues = {userId, checklistId }
        const response =  await post('/DeleteChecklistName', submittedValues);  
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'checklist-alert', 'danger');
            return;
        }  
        showAlert(response.data.message, 'checklist-modal', 'success'); 
        return response.data;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'checklist-alert', 'danger');
        throw error;
    } 
}

export async function applyMonthlyFilterChecklist(year: any, month: any) {
    if (!month) {
      setSuccess('ReportsPage');
      showAlert('Please select month.', 'checklist-summary', 'danger');
      return;
    } else if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'checklist-summary', 'danger');
        return;
    }
    try {
        let params: any = {}; 
        params['filterType'] = 'monthly';
        params['year'] = year;
        params['month'] = month; 
      await getChecklistSummary(params); 
      setSuccess('ReportsPage');
    } catch (error) { 
      setSuccess('ReportsPage');
      throw error;
    }
  }
  export async function applyYearlyFilterForChecklist(year: any) {
    if (!year) {
      setSuccess('ReportsPage');
      showAlert('Please select year.', 'checklist-summary', 'danger');
      return;
    }
    try {
        let params: any = {}; 
        params['filterType'] = 'yearly';
        params['year'] = year;  
        await getChecklistSummary(params); 
        setSuccess('ReportsPage');
    } catch (error) { 
      setSuccess('ReportsPage');
      throw error;
    }
}