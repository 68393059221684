import { IHistory, IForms } from '../../../interfaces';
import { ListPage } from '../../reusableComponents/ListPage'; 
import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';  
import { getAllFollowUpForms } from '../../../services/closeCallService';
import { BasePage } from '../../reusableComponents/BasePage'; 
import { ListItem } from './ListItem';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader'; 
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal'; 
import { Pagination } from '../../reusableComponents/Pagination'; 
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import { followUpListProps, FollowUpModel } from '../../../model/MechModel';
declare var require: any;
const queryString = require('query-string');

export interface IFollowUpProps {
    followUpInstances: followUpListProps[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
    totalPageCount?: number;
    forms: IForms;
    userUID: string;
}

export interface IFollowUpState {
    showDetails: boolean;
    formId: string;
}

export class FollowUpPageImpl extends React.PureComponent<IFollowUpProps, IFollowUpState> {
    constructor(props: IFollowUpProps | Readonly<IFollowUpProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        await this.getReportData();
    }

    getReportData = async () => {
        setLoading('AllListPage');  
        let pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1 
        await getAllFollowUpForms('', 'main', this.props.userUID, 'userList', pageNumber );
        setSuccess('AllListPage'); 
    }
    renderFormList = () => {
        const { followUpInstances } = this.props; 
        return (
            <>  
                <div className="jsa-list-wrapper"> 
                    <ListPage
                        pageHeading={'Follow-Ups'}
                        instances={followUpInstances}
                        rowHeadings={['Action','Assigned By', 'Target Date', 'Status']}
                        listItemComponent={ListItem}
                        emptyInstancesMessage="No follow-ups found."
                        isSearchable={true}
                        searchableBy={['action', 'assignedByName' , 'targetCompletion' ]}
                        searchPlaceHolder="Enter action, assigned name, target date to search..."
                        sm={[4, 2, 2, 3]}
                        currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    /> 
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.promise(pageNumber)}
                        totalCount={this.props.totalPageCount} 
                        identifier="AllListPage"
                    />
                </div> 
            </>
        );
    } 

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="FollowUpPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={ this.renderFormList()}
                />; 
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) { 
    //const followUpInstances = state.report.get('modalReportDataList');
    const followUpInstances =  FollowUpModel.list();
    let userUID = state.login.get('userUID');
    return { followUpInstances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, userUID, };
}

export const FollowUpPage = withRouter(connect< IFollowUpProps, any, any>(mapStateToProps)(FollowUpPageImpl));
