import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { IForms } from '../../../interfaces';
import { dispatch, showAlert } from '../../../utils/generalUtils'; 
import { Alert } from '../../Alert';
import './fileInput.scss'; 
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; 
import { VIDEOMAXSIZE, VIDEOALLOWEDEXE } from '../../../constants/general'; 
import videoThumb from '../../../images/video-thumb.png'; 

export interface IVideoInputProps {
    id?: string;
    multiple?: boolean;
    style?: React.CSSProperties;
    accept?: string;
    model?: string;
    onChange?: (event: EventTarget) => void;
    imagesArray?: string[];
    forms?: IForms;
    displayUploadedFiles?: boolean;  
    defaultValue?: string[];
}

export interface IVideoInputState {
    imagesArray: string[];
    oldUploads: string[];
    uploadedVideo?: any;
}

export class VideoInputImpl extends React.PureComponent<IVideoInputProps, IVideoInputState> {
    constructor(props: IVideoInputProps | Readonly<IVideoInputProps>) {
        super(props);
        const { defaultValue } = this.props;
        this.state = { imagesArray: [], oldUploads:defaultValue || [] };
    }

    componentWillMount() {
        const { props: { model }, context: { formModel } } = this;
        if ((formModel || '').split('.')[1] === 'incidentDetails') {
            return;
        }
        dispatch(actions.change(`${formModel}${model}`, ''));
    }

    static defaultProps = {
        displayUploadedFiles: true
    };

    static contextTypes = {
        formModel: PropTypes.string
    };

    handleImageDelete = (index: number) => {
        const { props: { model }, context: { formModel } } = this;
        dispatch(actions.remove(`${formModel}${model}`, index));
    }

    renderUploadedFiles = () => {
        const { props: { model, forms }, context: { formModel } } = this; 
        if(!model)
            return;
        const formName = (formModel || '').split('.');
        const modelName = model.split('.');
        const fileData = (forms as any)[`${formName[1]}`][`${modelName[1]}`]; 
        if (!fileData) {
            return <div></div>;
        }
        return fileData.map((fileObj: { name: string; file: any; }, index: number) => { 
            let fileName  = fileObj.name.split('~~')[0] + '.' + fileObj.name.split('~~')[1].split('.')[1];
            return <div key={index} style={displayImage} className="image-wrapper">
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.handleImageDelete(index)}
                />
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={fileName}> <strong>{fileName}</strong> </Tooltip> } > 
                    <img onClick={() => this.openFile(fileObj.file)} 
                        height="100px"
                        width="125px"
                        style={{ padding: '5px' }}
                        className="thumbnail-image"
                        src={videoThumb} alt="SafeConnect"
                    />
                    {/* <video height="125px" width="150px" style={{ padding: '5px' }} controls className="thumbnail-image video-container video-container-overlay"  >
                        <source src={ fileObj.file} type="video/mp4" />
                    </video> */}
                </OverlayTrigger> 
            </div>;
        });
    }
    renderOldVideos = () => { 
        return this.state.oldUploads.map((videoObj, index) => {
            let file = videoObj.split('/')[videoObj.split('/').length-1].split('~~')[0];
            let fileExtension = videoObj.split('.')[videoObj.split('.').length-1]; 
            return <div key={index} style={displayImage} className="image-wrapper" onClick={() => this.openFile(videoObj)}>  
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.removeOldUpload(index, videoObj) }
                />
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={file + '.' + fileExtension}> <strong>{file + '.' + fileExtension}</strong> </Tooltip> } >
                    <img
                        height="100px"
                        width="125px"
                        style={{ padding: '5px' }}
                        className="thumbnail-image" 
                        src={videoThumb} alt="SafeConnect"
                    />
                </OverlayTrigger>
            </div> ; 
        });  
    } 
    openFile = async (fileObj: string) => { 
        //await base64ToFile(fileObj, 'video').then(file => { return file; }); 
    }
    removeOldUpload = (index: number, video: string) => {
        const { context: { formModel } } = this; 
        var array = [...this.state.oldUploads]; // make a separate copy of the array
        var arrayIndex = array.indexOf(video) 
        if (arrayIndex !== -1) {
          array.splice(index, 1);
          this.setState({oldUploads: array}); 
        }  
        dispatch(actions.push(`${formModel}.deletedVideos`, video ));
    }
    handleChange = (event: any) => { 
        let invalidSizeFile: Array<any> = [];
        let invalidExeFile: Array<any> = [];
        if(event.target.files.length>0) {
            /* const { context: { formModel }, props: { model, forms } } = this; */  
            const files = event.target.files;   
            for (let i = 0; i < files.length; i++) { 
                const file = files[i];
                let exe = file['name'].split('.')[file['name'].split('.').length-1];  
                if(file['size'] > VIDEOMAXSIZE) {
                    invalidSizeFile.push(file['name']);  
                    //delete files[i];  
                } else if(VIDEOALLOWEDEXE.indexOf(exe)===-1) {
                    invalidExeFile.push(file['name']); 
                    //delete files[i];  
                }
            /* } 
            for (let i = 0; i < files.length; i++) {  
                const file = files[i]; */
                else {
                    let fileName = file['name'];
                    if(invalidSizeFile.indexOf(fileName)===-1 && invalidExeFile.indexOf(fileName)===-1 ) {
                        const fileReader = new FileReader();
                        fileReader.onload = this.readFile;
                        this.setState({uploadedVideo: fileName})
                        /* function readFile(e) { 
                            const timeStamp = new Date().getTime().toString();
                            let name = fileName.split('.')[0] + '~~' + timeStamp + '.' + fileName.split('.')[fileName.split('.').length-1];
                            dispatch(actions.push(`${formModel}${model}`, { name: name, file: e.target.result }));
                        } */
                        fileReader.readAsDataURL(file);
                    }
                }
            }
            let errMsg = '';
            if(invalidSizeFile.length>0) {
                errMsg = 'File size should be below 50 MB, ' + invalidSizeFile.length + ' file' + (invalidSizeFile.length>1?'s':'') + ' CANNOT be upload.'; 
            }
            if(invalidExeFile.length>0) {
                errMsg += 'Only mp4 files allowed, ' + invalidExeFile.length + ' file' + (invalidExeFile.length>1?'s':'') + ' CANNOT be upload.';
            }
            if(errMsg) {
                showAlert(errMsg, 'danger-alert-invalidexe', 'danger',undefined,true,3000); 
            }
        }
    }
    readFile = (e: any) => {   
        const { context: { formModel }, props: { model } } = this; 
        const timeStamp = new Date().getTime().toString();
        let fileName = this.state.uploadedVideo;
        let name = fileName.split('.')[0] + '~~' + timeStamp + '.' + fileName.split('.')[fileName.split('.').length-1];
        dispatch(actions.push(`${formModel}${model}`, { name: name, file: e.target.result }));
    }

    render() {
        const { multiple, displayUploadedFiles } = this.props;
        return (
            <div className="file-upload"> 
                <input
                    type="file"
                    multiple={multiple}
                    style={fileUpload} 
                    accept="video/mp4,video/x-m4v"
                    onChange={this.handleChange}
                />
                <span className="upload-image">Choose Video(s)</span>  
                <div className="images">
                { this.state.oldUploads ? this.renderOldVideos() : '' }
                { displayUploadedFiles ? this.renderUploadedFiles() : '' }
                </div> 
                <span className="videoDangerAlert"> <Alert className="danger-alert" id="danger-alert-invalidexe" /> </span>
            </div>
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms
    };
}

export const VideoInput = connect<{}, {}, IVideoInputProps>(mapStateToProps)(VideoInputImpl);

const fileUpload: React.CSSProperties = {
    height: '30px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    /* left: '35%', */
};

const displayImage: React.CSSProperties = {
    width: '125px',
    height: '100px',
    margin: '0 5px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)'
};
