import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showModal } from '../../actions/modalActions';
import { IHistory } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { getAllGroups } from '../../services/groupService';
import { getAllUsers } from '../../services/userService';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { ConfirmationModal } from './ConfirmationModal';

export interface IDetailsPageProps {
    groupInstance: GroupModel;
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    userInstance: UserModel;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = async () => {
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return null;
    }

    handleDelete = async (id: string) => {
        const groupInstance = GroupModel.get(id);
        new GroupModel(groupInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-group', groupInstance.props.groupName, groupInstance);
    }

    renderEditGroupButton = () => { 
        let id = this.props.match && this.props.match?.params.id;
        return <div className="show-grid form-button" style={{ textAlign: 'center' }}>
            <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white', width: '160px', margin:'0px 10px', 
            }}
                
                onClick={() => this.props.history?.goBack() }
                type="reset"
            >
                <i className="fa fa-ban" aria-hidden="true"></i>
                &nbsp;
                Cancel
            </Button>
            <Button style={{
                color: '#FFFFFF',
                border: 'none',
                backgroundColor: '#26a65b', width: '160px', margin:'0px 10px', 
            }}
                onClick={() => id && this.props.history?.push(`/groups/${id}/edit`)}
            >
                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                Edit
            </Button> 
            <Button style={{
                color: '#5479AF',
                border: '1px solid #5479AF',
                backgroundColor: 'white', width: '160px', margin:'0px 10px', 
            }}
                onClick={() => id && this.handleDelete(id)}
            >
                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;
                Delete
            </Button> 
        </div>;
    }

    renderFormContent = () => {
        //const { props: { match: { params: { id } } } } = this;
        let id = this.props.match && this.props.match?.params.id;
        const formInstance = id && GroupModel.get(id);
        if (!formInstance) {
            return <Loader />;
        }
        const { props: { groupName, groupDesc, dateCreated, dateUpdated,
            creatorName, members } } = formInstance;
        //const groupCreatorInstance = UserModel.getUserByUID(groupCreator);
        const groupMembersArray: any[] = [];
        members.forEach((memberId: string) => {
            const userInstance = UserModel.get(memberId);
            if (!userInstance) {
                return;
            }
            groupMembersArray.push(userInstance.props.firstName);
        });
        return (
            <div>
                <Title text={`Group Report for ${dateCreated}`}></Title>
                <Alert id="group-details" className="danger-alert" />
                <KeyValue label="Group Name">{groupName}</KeyValue>
                <KeyValue label="Group Description">{groupDesc}</KeyValue>
                <KeyValue label="Group Creator">{
                    creatorName || 'NA'}
                </KeyValue>
                <KeyValue label="Group Members">
                    {groupMembersArray.length > 0 ? groupMembersArray.join(', ') : 'NA'}
                </KeyValue>
                {/* <KeyValue label="Group Form">{groupForms.length > 0 ? groupForms : 'NA'}</KeyValue> */}
                <KeyValue label="Date Updated">{dateUpdated}</KeyValue>
                {this.renderEditGroupButton()}
            </div >
        );
    }

    renderDetails = () => {
        return (
            <Async
                identifier="GroupDetailsPage"
                error={<ErrorPage />}
                promise={this.promise}
                loader={<Loader />}
                content={this.renderFormContent()}
            />
        );
    }

    render() {
        return (
            <BasePage>
                <ConfirmationModal id="delete-group" />
                <div className="group-details">
                    {this.renderDetails()}
                </div>
            </BasePage >
        );
    }
}

const KeyValue = ({ label, children }: any) => {
    return <Row className="show-grid">
        <Col xs={6} style={{ textAlign: 'right', color: 'yellow' }} sm={6}>{label}:</Col>
        <Col sm={6} xs={6}>{children || 'NA'}</Col>
    </Row >;
};

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const groupInstance = GroupModel.list(state);
    return {
        groupInstance,
        userInstance: state.models.get(`user${userId}`)
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
