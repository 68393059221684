import * as React from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IForms, IHistory } from '../../interfaces';
import { constructLongIncidentAnswers, dispatch, isFormEmpty, showAlert, constructActionItems, isArrayOfObjEmpty } from '../../utils/generalUtils';
import { GeneralInformationForm } from './GeneralInformationForm';
import { IncidentDetailsForm } from './IncidentDetailsForm';
import { IncidentInvestigationSummaryForm } from './IncidentInvestigationSummary';
import { InjuryForm } from './InjuryForm';
import { InjurySeverityForm } from './InjurySevityForm';
import PropTypes from 'prop-types';
import './incidentReport.scss';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Form } from '../reusableComponents/FormComponents/Form';
import { IIncidentReportModelProps, IncidentReportModel } from '../../model/IncidentReportModel';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { submitDetailedIncidentReport, checkInvestigationEditAccess, getInvestigativeForm } from '../../services/incidentReportService';
import { Async } from '../reusableComponents/Async';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { GetIncidentFormsForInvestigate } from '../../services/incidentNotificationFormService';
import { UserModel } from '../../model/UserModel'; 
import {  setEmptyField, resetEmptyField } from '../../actions/miscellaneousActions';  

export interface IIncidentReportFormProps {
    userUID: string;
    userId: string;
    history?: IHistory;
    forms: IForms;
    associatedFormId: string;
    incidentDetails: IIncidentReportModelProps;
    match?: {
        params: {
            id: string;
        }
    };
    userInstance: UserModel;
    editInstance?: IncidentReportModel;
    editId?: string;
    incidentNotificationInstance?: IncidentNotificationModel; 
}   

export interface IIncidentReportFormState {
    stepNo: number;
    formLabel?: string;
    incidentNotificationInstance?: any;
    isLoader: boolean;
}

export class IncidentReportFormImpl extends React.PureComponent<IIncidentReportFormProps, IIncidentReportFormState> {
    constructor(props: IIncidentReportFormProps | Readonly<IIncidentReportFormProps>) {
        super(props);
        this.step1 = this.step1.bind(this);
        this.state = { stepNo: 1, formLabel: 'General Information', isLoader: false };
    }

    model = 'forms.incidentDetails';

    promise = async () => { 
        this.setState({stepNo: 1,});
        if(this.props.editId && !this.props.editInstance) 
            await getInvestigativeForm(this.props.editId); 

        const { editId, editInstance, history } = this.props 
        if(editId && editInstance) {
            if( ( editInstance.props.finalReport===1 && !checkInvestigationEditAccess(editInstance.props.userAccessLevel || 'L3')) ) {
                //await getAllIncidentReportForms();
                showAlert('This investigation is already submitted the final report and you can not be edit', 'incident-report-modal', 'success', async () => { history?.push('/incident-reports'); }, false);  
            } 
        }
        await GetIncidentFormsForInvestigate(editInstance ? editInstance.props.id:'preliminary'); 
        if (this.props.match?.params.id) {
            this.populateValues(this.props.match.params.id);
        }
        if(!this.props.associatedFormId ) {
            setEmptyField('associatedFormId');
        } else {
            resetEmptyField();
        }
        return;
    }


    populateValues__ = async (formId: string) => {  
        if(this.props.editInstance) { 
            let instanceProps ; 
            instanceProps = {name:'', employee:'', contractor:'',drugTestConducted:'', company:'', jobPosition:'', timeOnJob:'', natureOfInjury:'', bodyPart:'', medicalResponseTaken:'', statusUpdate:'', eventLeadingToInjury:'', actualDaysLost:'', firstAidTreatment:'', estimatedDaysLost:'', actualSeverity:'', potentialSeverity:'', securityCategory:'', methodOfBreach:'', agencyNotified:'', agencyName:'', reportedToAgencyBy:'', notifiedAtAgency:'', costIncurred:'', totalCost:'', emOwnershipPercentage:'', costType:'', costCategory:'', '8010Required':'', directCause:'', rootCause:'', incidentActivity:'', incidentConditions:'', workDirection:'', concernType:'', isFinalSubmit:'', mobileLocation :'', formSerialNo:'' } 
            for (const property in this.props.editInstance.props) { 
                if(instanceProps[property] !== undefined ) {
                    let value = (this.props.editInstance.props as any)[property]
                    //let value = `${this.props.editInstance.props}.${property}`
                    dispatch(actions.change(`${this.model}.${property}`, value==='0' ? false : value==='1' && property !== 'formSerialNo' ? true : value ));
                }
            } 
            if(this.props.editInstance.props.imageUrls.length)
                dispatch(actions.change(`${this.model}.imageUrls`,this.props.editInstance.props.imageUrls ));
            
            if(this.props.editInstance.props.videoUrls.length)
                dispatch(actions.change(`${this.model}.videoUrls`,this.props.editInstance.props.videoUrls ));
        }
    }
    preparePopulateValues = async (formId: string) => {
        /* this.props.history?.push(`/incident-investigative-reports/edit/${incidentNotificationInstance.props.associatedFormsCompleted[0]}`);  */
    }  
    populateValues = async (formId: string) => { 
        if(this.props.editInstance) return;
        const { history } = this.props;
        if(formId==='No Report') {  
            this.setState({incidentNotificationInstance: ''})
            dispatch(actions.change(`${this.model}.jobPosition`, ''));
            dispatch(actions.change(`${this.model}.incidentSummary`, ''));
            dispatch(actions.change(`${this.model}.bodyPart`, ''));
            //dispatch(actions.change(`${this.model}.dateOfIncident`, '25-05-2020'));
            //dispatch(actions.change(`${this.model}.timeOfIncident`, ''));
            //dispatch(actions.change(`${this.model}.dateReported`, '25-05-2020'));
            //dispatch(actions.change(`${this.model}.timeReported`, ''));
            dispatch(actions.change(`${this.model}.groupName`, ''));
            dispatch(actions.change(`${this.model}.projectName`, ''));
            dispatch(actions.change(`${this.model}.locationName`, ''));
            dispatch(actions.change(`${this.model}.concernType`, ''));
            dispatch(actions.change(`${this.model}.actualSeverity`, ''));
            dispatch(actions.change(`${this.model}.potentialSeverity`, ''));
            return;
        } 
        let incidentNotificationInstance = IncidentNotificationModel.get(formId);  
          
        if(incidentNotificationInstance.props.associatedFormsCompleted.length>0) {  
            this.setState({isLoader: true});
            await getInvestigativeForm(incidentNotificationInstance.props.associatedFormsCompleted[0]); 
            let editInstance = IncidentReportModel.get(incidentNotificationInstance.props.associatedFormsCompleted[0]);
            let instanceProps ; 
            instanceProps = ['name', 'employee', 'contractor','drugTestConducted', 'company', 'jobPosition', 'timeOnJob', 'natureOfInjury', 'bodyPart', 'medicalResponseTaken', 'statusUpdate', 'eventLeadingToInjury', 'actualDaysLost', 'firstAidTreatment', 'estimatedDaysLost', 'actualSeverity', 'potentialSeverity', 'securityCategory', 'methodOfBreach', 'agencyNotified', 'agencyName', 'reportedToAgencyBy', 'notifiedAtAgency', 'costIncurred', 'totalCost', 'emOwnershipPercentage', 'costType', 'costCategory', '8010Required', 'directCause', 'rootCause', 'incidentActivity', 'incidentConditions', 'workDirection', 'concernType', 'isFinalSubmit', 'mobileLocation' , 'formSerialNo' ];
            instanceProps = {name:'', employee:'', contractor:'',drugTestConducted:'', company:'', jobPosition:'', timeOnJob:'', natureOfInjury:'', bodyPart:'', medicalResponseTaken:'', statusUpdate:'', eventLeadingToInjury:'', actualDaysLost:'', firstAidTreatment:'', estimatedDaysLost:'', actualSeverity:'', potentialSeverity:'', securityCategory:'', methodOfBreach:'', agencyNotified:'', agencyName:'', reportedToAgencyBy:'', notifiedAtAgency:'', costIncurred:'', totalCost:'', emOwnershipPercentage:'', costType:'', costCategory:'', '8010Required':'', directCause:'', rootCause:'', incidentActivity:'', incidentConditions:'', workDirection:'', concernType:'', isFinalSubmit:'', mobileLocation :'', formSerialNo:'' } 
            if(editInstance) {
                for (const property in editInstance.props) { 
                    if(instanceProps[property] !== undefined ) {
                        let value = editInstance.props[property]
                        dispatch(actions.change(`${this.model}.${property}`, value==='0' ? false : value==='1' && property !== 'formSerialNo' ? true : value ));
                    }
                } 
                if(editInstance.props.imageUrls.length)
                    dispatch(actions.change(`${this.model}.imageUrls`,editInstance.props.imageUrls ));
                
                if(editInstance.props.videoUrls.length)
                    dispatch(actions.change(`${this.model}.videoUrls`,editInstance.props.videoUrls ));
            } 
            /* let investigations = editInstance.props.incidentInvestigation; 
            if(investigations) {
                let investigationArray = [];
                investigations.map((element, index) => {
                    let investigationArrayTemp = [];
                    investigationArrayTemp['action'] = element['Action'];
                    investigationArrayTemp['responsible'] = element['AssignedToProject'].join(',');
                    investigationArrayTemp['target'] = element['TargetDate'];
                    investigationArrayTemp['followUpId'] = element['FormId'];
                    investigationArrayTemp['actionItems'] = element['Action'];
                    investigationArrayTemp['partyResponsible'] = element['AssignedToProject'].join(',');
                    investigationArrayTemp['UUID'] = element['UUID'];
                    investigationArrayTemp['targetDate'] = element['TargetDate'];
                    investigationArray.push(investigationArrayTemp);
                }); 
                dispatch(actions.change(`${this.model}.incidentInvestigation`, investigationArray));
            } */
            this.setState({isLoader: false});
            history?.push(`/incident-investigative-reports/edit/${incidentNotificationInstance.props.associatedFormsCompleted[0]}`); 
            return; 
        }  
        console.log('Calling Populate :',incidentNotificationInstance); 
        this.setState({incidentNotificationInstance: incidentNotificationInstance})
        //const { incidentNotificationInstance } = this.props; 
        if (incidentNotificationInstance) { 
            const { groupName, projectName, injuryBodyPart, injuredPartyWorkFunction, injurySummary,concernType,specifiedLocation, injuryClassification } = incidentNotificationInstance.props;
            dispatch(actions.change(`${this.model}.jobPosition`, injuredPartyWorkFunction));
            dispatch(actions.change(`${this.model}.incidentSummary`, injurySummary));
            dispatch(actions.change(`${this.model}.bodyPart`, injuryBodyPart));
           /*  dispatch(actions.change(`${this.model}.dateOfIncident`, format(dateOfIncident, 'YYYY-MM-DD'));
            dispatch(actions.change(`${this.model}.timeOfIncident`, timeOfIncident));
            dispatch(actions.change(`${this.model}.dateReported`, format(date, 'YYYY-MM-DD')));
            dispatch(actions.change(`${this.model}.timeReported`, time)); */
            dispatch(actions.change(`${this.model}.groupName`, groupName));
            dispatch(actions.change(`${this.model}.projectName`, projectName));
            dispatch(actions.change(`${this.model}.locationName`, specifiedLocation));
            dispatch(actions.change(`${this.model}.concernType`, concernType)); 
            dispatch(actions.change(`${this.model}.actualSeverity`, injuryClassification)); 
            dispatch(actions.change(`${this.model}.potentialSeverity`, injuryClassification)); 
        }
    } 
    getChildContext() {
        return { formModel: this.model };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    formTitle = ['', 'General/Incident Information', 'Injury/Injury Severity Details',
        'Near Miss Investigation Details', 'Injury Severity Scale',
        'Security', 'Incident / Near Miss Investigation Summary', 'Endorsement', 'Approval',
        'Corrective Actions Completed'];

    nextStep = () => {
        if (this.state.stepNo >= 3) {
            return;
        }  
        let process = 'inc';
        switch(this.state.stepNo) {
            case 1: 
                if(!this.validateStep1(process)) return;
                break;
            case 2: 
                if(!this.validateStep2(process)) return;
                break;
            case 3: 
                if(!this.validateStep3(process)) return;
                break;
            default: break;
        } 
    }
    incrementStep = () => {
        this.setState({
            stepNo: this.state.stepNo + 1
        });
    }
    previousStep = () => {
        if (this.state.stepNo <= 1) {
            return;
        }
        let process = 'dec';
        switch(this.state.stepNo) {
            case 1: 
                if(!this.validateStep1(process)) return;
                break;
            case 2: 
                if(!this.validateStep2(process)) return;
                break;
            case 3: 
                if(!this.validateStep3(process)) return;
                break;
            default: break;
        } 
    }
    decrementStep = () => {
        this.setState({
            stepNo: this.state.stepNo - 1
        });
    }
 
    createNew = async () => {
        const { history } = this.props;  
        this.setState({isLoader: true});
        await GetIncidentFormsForInvestigate('preliminary');
        this.setState({isLoader: false});
        history?.push(`/incident-investigative-reports/create`);   
    }

    renderSteps = () => {
        const { editInstance } = this.props;  
       /*  return <>
            <div className={this.state.stepNo!=1?' hide':''}> <GeneralInformationForm
                associatedFormId={this.props.associatedFormId}
                userId={this.props.userId}
                populateValues={this.populateValues}
                incidentNotificationInstance={this.state.incidentNotificationInstance}
                // instance={this.props.userInstance} 
                editInstance={editInstance}  
                forms={this.props.forms}
            />
                <IncidentDetailsForm editInstance={editInstance} company={UserModel.getCompanyName()} forms={this.props.forms}/>
            </div>
            <div className={this.state.stepNo!=2?' hide':''}>
                <InjuryForm editInstance={editInstance} incidentNotificationInstance={this.state.incidentNotificationInstance} forms={this.props.forms.incidentDetails}/>
                <InjurySeverityForm editInstance={editInstance}/>
            </div>
            <div className={this.state.stepNo!=3?' hide':''}>
                <IncidentInvestigationSummaryForm editInstance={editInstance} userId={this.props.userId} />
            </div>
        </>; */
        switch (this.state.stepNo) {
            case 1:
                return <div> <GeneralInformationForm
                    associatedFormId={this.props.associatedFormId}
                    userId={this.props.userId}
                    populateValues={this.populateValues}
                    incidentNotificationInstance={this.state.incidentNotificationInstance}
                    // instance={this.props.userInstance} 
                    editInstance={editInstance}  
                    forms={this.props.forms}
                />
                    <IncidentDetailsForm editInstance={editInstance} company={UserModel.getCompanyName()} forms={this.props.forms}/>
                </div>;
            case 2:
                return <div>
                    <InjuryForm editInstance={editInstance} incidentNotificationInstance={this.state.incidentNotificationInstance} forms={this.props.forms.incidentDetails}/>
                    <InjurySeverityForm editInstance={editInstance}/>
                </div>; 
            case 3:
                return <div>
                    <IncidentInvestigationSummaryForm editInstance={editInstance} userId={this.props.userId} />
                </div>;  
        }
    } 

    step1 = () => {
        this.setState({
            formLabel: 'Incident Details'
        });
    } 
    stepNo = 1;
    async validateStep1(process: string){ 
        const { props: { forms } } = this; 
        const incidentDetails = forms.incidentDetails;/* 
        dispatch(actions.change(`forms.incidentDetails.isFinalSubmit`, editInstance && editInstance.props.isFinalSubmit=='1' ?'1':'0' ));  */
        let submittedValues = {
            associatedFormId: incidentDetails.associatedFormId 
        };
        if (isFormEmpty(submittedValues) ) {
            showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
            return false; 
        } 
        if(incidentDetails.finalReport) {
            let submittedValues = {
                associatedFormId: incidentDetails.associatedFormId,
                dateOfIncident: incidentDetails.dateOfIncident,
                timeOfIncident: incidentDetails.timeOfIncident,
                dateReported: incidentDetails.dateReported,
                timeReported: incidentDetails.timeReported,
                concernType: incidentDetails.concernType,
                projectName: incidentDetails.projectName,
                groupName: incidentDetails.groupName,
                locationName: incidentDetails.locationName, 
            };
            if (isFormEmpty(submittedValues) ) {
                showAlert('This report seems FINAL Report. '+EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
                return false; 
            }  
        } 
        this.setState({isLoader: true});
        await this.submitForm('step1');
        process==='inc'?this.incrementStep():this.decrementStep();
        this.setState({isLoader: false});
        return true;
    }
    async validateStep2(process: string) {
        const { props: { forms, editInstance } } = this; 
        const incidentDetails = forms.incidentDetails;
        dispatch(actions.change(`forms.incidentDetails.isFinalSubmit`, editInstance && editInstance.props.isFinalSubmit==='1' ?'1':'0' ));  
        if(incidentDetails.finalReport) {
            let submittedValues = {
                bodyPart: incidentDetails.bodyPart,
                actualSeverity: incidentDetails.actualSeverity,
                potentialSeverity: incidentDetails.potentialSeverity,
            }
            if (isFormEmpty(submittedValues) ) {
                showAlert('This report seems FINAL Report. '+EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
                return false; 
            }  
            if(!this.validateDaysLost()) return false;
        }
        this.setState({isLoader: true});
        await this.submitForm('step2'); 
        process==='inc'?this.incrementStep():this.decrementStep();
        this.setState({isLoader: false});
        return true;
    }
    validateDaysLost() { 
        const { props: { forms } } = this; 
        const incidentDetails = forms.incidentDetails;
        if(parseInt(incidentDetails.actualDaysLost)<0) {  
            showAlert('Invalid actual days', 'actual-days-lost-alert', 'danger');
            //dispatch(actions.change('forms.incidentDetails.actualDaysLost', ''));
            return false;  
        }
        if(parseInt(incidentDetails.estimatedDaysLost)<0) {  
            showAlert('Invalid estimated days', 'estimated-days-lost-alert', 'danger');
            //dispatch(actions.change('forms.incidentDetails.estimatedDaysLost', ''));
            return false; 
        } 
        return true;
    }
    async validateStep3(process: string) { 
        const { props: { forms } } = this; 
        const incidentDetails = forms.incidentDetails
        if(incidentDetails.finalReport) {
            if(isArrayOfObjEmpty('incidentInvestigation', incidentDetails.incidentInvestigation)) {
                showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
                return; 
            }
        } 
        this.setState({isLoader: true});
        if(process==='inc'){ 
            await this.submitForm('final'); 
        } else {
            await this.submitForm('step3'); 
            this.decrementStep();
        }
        this.setState({isLoader: false});
        return true;
    }

    handleSubmit = async (incidentDetails: IIncidentReportModelProps) => { 
        /* if (isFormEmpty(submittedValues) || 
        isArrayOfObjEmpty('incidentInvestigation', submittedValues.incidentInvestigation)) {
            showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
            return; 
        } 
        if(!this.validateDaysLost()) {
            showAlert('Invalid ', 'incident-report', 'danger');
            return; 
        } */
        const submittedStep1Values = {
            associatedFormId: incidentDetails.associatedFormId,
            dateOfIncident: incidentDetails.dateOfIncident,
            timeOfIncident: incidentDetails.timeOfIncident,
            dateReported: incidentDetails.dateReported,
            timeReported: incidentDetails.timeReported,
            concernType: incidentDetails.concernType,
            projectName: incidentDetails.projectName,
            groupName: incidentDetails.groupName,
            locationName: incidentDetails.locationName, 
        };
        if (isFormEmpty(submittedStep1Values) ) {
            showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
            this.setState({ stepNo: 1 });
            return false; 
        }
        const submittedStep2Values = {
            bodyPart: incidentDetails.bodyPart,
            actualSeverity: incidentDetails.actualSeverity,
            potentialSeverity: incidentDetails.potentialSeverity,
        };
        if (isFormEmpty(submittedStep2Values) || !this.validateDaysLost() ) {
            showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
            this.setState({ stepNo: 2 });
            return false; 
        }
        if(isArrayOfObjEmpty('incidentInvestigation', incidentDetails.incidentInvestigation)) {
            showAlert(EMPTY_FORM_MESSAGE, 'incident-report', 'danger');
            this.setState({ stepNo: 3 });
            return; 
        }
        this.setState({isLoader: true});
        await this.submitForm('final'); 
        this.setState({isLoader: false}); 
    }
    async submitForm(step: string ='') { 
        const {  props: { userUID, history, forms, associatedFormId, editId, editInstance } } = this; 
        if(editInstance) {
            //dispatch(actions.change(`forms.incidentDetails.formSerialNo`, editInstance.props.formSerialNo));   
        }
        let incidentDetails = forms.incidentDetails; 
        const project = incidentDetails.projectName;
        const group = incidentDetails.groupName;
        let answers = constructLongIncidentAnswers(incidentDetails);   
        let isFinalSubIndex: any = '';  
        let formSerialNoKey;
        answers.map((element: any, index: any) => {
            let key = Object.keys(element).find(key => element[key] === 'isFinalSubmit');
            formSerialNoKey = Object.keys(element).find(key => element[key] === 'formSerialNo');
            if(key) {
                isFinalSubIndex = index; 
            } 
        }); 

        if(editId && !formSerialNoKey && editInstance) {
            answers.push( { 'quesType': 'formSerialNo', 'quesNum': answers.length+1, 'answer': editInstance.props.formSerialNo || ''} );
        }  
        if(isFinalSubIndex) {
            if(!editInstance || !editInstance.props.isFinalSubmit || (step !== 'final' && editInstance.props.isFinalSubmit==='0') ) {  
                answers[isFinalSubIndex]['answer'] = '0';
            } else {
                answers[isFinalSubIndex]['answer'] = '1'; 
            }
        } 
        let investigations = editInstance && editInstance.props.incidentInvestigation; 
        let followups: any = [];
        if(step!=='final') {
            if(investigations) {
                investigations.map((element, index) => { 
                    let investigationArrayTemp: any = []; 
                    investigationArrayTemp['actionItems'] = element['Action'];
                    investigationArrayTemp['partyResponsible'] = element['partyResponsible'];
                    investigationArrayTemp['UUID'] = element['UUID'];
                    investigationArrayTemp['targetDate'] = element['TargetDate'];
                    followups.push(investigationArrayTemp);
                });
                followups = constructActionItems(followups);
            } 
            if(step==='step3') {
                followups = constructActionItems(incidentDetails.incidentInvestigation);
            }
        } else { 
            followups = constructActionItems(incidentDetails.incidentInvestigation);
        } 
        const associatedFormID = associatedFormId || incidentDetails.associatedFormId;  
        let response = history && await submitDetailedIncidentReport(history, userUID, answers, group, project, forms, followups, associatedFormID || '', editId, step); 
        if(step === 'step1') {
            response && history?.push(`/incident-investigative-reports/edit/${response.data.submittedFormId}`); 
        }
    }

    render() { 
        /* if(editId && !editInstance) { 
           history.goBack();
        }  */
        return (
            <Async
                identifier="IncidentInvestigativeForm"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={<Form loader={<SubmissionLoader />} onSubmit={this.handleSubmit} model={this.model}>
                    {this.state.isLoader&& <SubmissionLoader />}
                    <div className="form-header">
                        <div className="backward-button">
                            {this.state.stepNo === 1 ? null : <span className="navigatePreviousButton" onClick={this.previousStep}>Save & Previous</span> }   
                            {/* <i
                                    className="fa fa-chevron-circle-left"
                                    onClick={this.previousStep} aria-hidden="true"
                                ></i> */}
                        </div>
                        <div className="step-label">
                            {`${this.state.stepNo}/3 Steps - ${this.formTitle[this.state.stepNo]}`}
                        </div>
                        <div className="forward-button">
                            {this.state.stepNo === 3 ? null : <span className="navigateNextButton" onClick={this.nextStep}>Save & Next</span> }
                            {/* <i
                                className="fa fa-chevron-circle-right"
                                onClick={this.nextStep}
                                aria-hidden="true"
                            ></i> */}
                        </div>
                    </div>
                    {this.renderSteps()}
                    <div className="form-header" style={{ paddingTop: '30px' }} >
                        <div className="backward-button">
                            {this.state.stepNo === 1 ? null : <span className="navigatePreviousButton" onClick={this.previousStep}>Save & Previous</span> }   
                            {/* <i
                                    className="fa fa-chevron-circle-left"
                                    onClick={this.previousStep} aria-hidden="true"
                                ></i> */} 
                        </div>
                        <div className="step-label">
                            {`${this.state.stepNo}/3 Steps - ${this.formTitle[this.state.stepNo]}`}
                        </div>
                        <div className="forward-button">
                            {this.state.stepNo === 3 ? null :<span className="navigateNextButton" onClick={this.nextStep}>Save & Next</span> }
                            {/* <i
                                className="fa fa-chevron-circle-right"
                                onClick={this.nextStep}
                                aria-hidden="true"
                            ></i> */}
                        </div>
                    </div>
                </Form >}
            />
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    let associatedFormId = '';
   /*  if (ownProps.match.params.id) {
        associatedFormId = ownProps.match.params.id;
    } */ 
    let incidentNotificationInstance;
    if (ownProps.match.params.incidentId) {
        associatedFormId = ownProps.match.params.incidentId; 
        incidentNotificationInstance = IncidentNotificationModel.get(associatedFormId); 
    }
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);
    
    /* const editId = ownProps.match.params.id;
    const editInstance = IncidentReportModel.get(ownProps.match.params.id); 
    if(editInstance) {
        associatedFormId = editInstance.props.associatedForms[0]
    }  */ 
    const editId = ownProps.match.params.id;
    let editInstance;
    if(editId) {
        editInstance = IncidentReportModel.get(ownProps.match.params.id);  
        if(editInstance)
            associatedFormId = editInstance.props.associatedForms[0]
    } 
    return {
        forms: state.forms,
        incidentDetails: state.forms.incidentDetails,
        associatedFormId,
        userUID: state.login.get('userUID'), 
        userId,
        userInstance, editId, editInstance, incidentNotificationInstance
    };
}

export const IncidentReportForm =
    withRouter(connect<IIncidentReportFormProps, any, any>(mapStateToProps)(IncidentReportFormImpl));
