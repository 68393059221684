import {IForms, IHistory} from "../interfaces";
import {UserModel} from "../model/UserModel";
import {format} from "date-fns";
import {constructAnswers, getSignatureData, removeByValue, showAlert} from "../utils/generalUtils"; 
import {FORM_SUBMISSION_ERROR} from "../constants/general";
import {getCustom, post} from "../utils/HTTP";
import { ToolBoxMeetingModel } from '../model/ToolBoxMeetingModel';  
 
export async function submitTSMForm(formValues: any, formName: string, history: IHistory | undefined, forms: IForms, editId?: string, instance?: any ) {
    const company = UserModel.getCompanyName();
    const submittedDateTime = new Date()
    let deletedFiles: Array<any> = []; 
    deletedFiles = forms.toolBoxMeetingForm.deletedFiles || [];
    formValues = removeByValue(formValues, ['TSMLeaderSignature','emplyeesName', 'contractEmplyeesName', 'worksDetail', 'attachements', 'deletedFiles']);
    let answers = constructAnswers(formValues);
    let emplyeesName: any = forms.toolBoxMeetingForm.emplyeesName || ''; 
    if(!Array.isArray(emplyeesName)) {
        emplyeesName = emplyeesName.split(',') 
    }  
    answers.push({'answer': emplyeesName.length.toString(), 'quesNum': 0, 'quesType': 'emplyeesCount' }); 
    for(let i=0; i<emplyeesName.length;i++) {
        answers.push({'answer': emplyeesName[i], 'quesNum': i, 'quesType': 'emplyeesName' });
    }
    answers.push({'answer': forms.toolBoxMeetingForm.contractEmplyeesName.length.toString(), 'quesNum': 0, 'quesType': 'contractEmplyeesCount' }); 
    for(let i=0; i<forms.toolBoxMeetingForm.contractEmplyeesName.length;i++) {
        answers.push({'answer': forms.toolBoxMeetingForm.contractEmplyeesName[i], 'quesNum': i, 'quesType': 'contractEmplyeesName' }); 
    }
    answers.push({'answer': forms.toolBoxMeetingForm.worksDetail.length.toString(), 'quesNum': 0, 'quesType': 'worksDetailCount' }); 
    for(let i=0; i<forms.toolBoxMeetingForm.worksDetail.length;i++) {
        answers.push({'answer': forms.toolBoxMeetingForm.worksDetail[i], 'quesNum': i, 'quesType': 'worksDetail' });
    }  
    let signatureData: any = []; 
    if(forms.toolBoxMeetingForm.TSMLeaderSignature) {
        let signature = getSignatureData(forms.toolBoxMeetingForm.TSMLeaderSignature, 'TSMLeaderSignatureURL')
        signatureData.push(signature);
    } else if(instance && instance.props.TSMLeaderSignatureURL) {
        answers.push({'answer': instance.props.TSMLeaderSignatureURL, 'quesNum': 0, 'quesType': 'TSMLeaderSignatureURL'})
    } 
    const dataToSend = {
        groupName: forms.toolBoxMeetingForm.groupName,
        userId: UserModel.getUserUID(),
        formId: formName,
        date: format(submittedDateTime, 'yyyy-MM-dd'),
        time: format(submittedDateTime, 'HH:mm'),
        projectName: forms.toolBoxMeetingForm.projectName,
        associatedFormId: '',
        followUp: "0",
        editId: editId, 
        deletedFiles,
        deletedImages: [],
        deletedVideos: [],
        company,
        answers, 
        attachments: forms.toolBoxMeetingForm.attachements || [],
        signatureData: signatureData || [],
    };  
    try {
        dataToSend.editId = editId || '';
        const response = editId ? await post('/EditForm', dataToSend) : await post('/SubmitForm', dataToSend);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, `tsm-form-failed`, 'danger');
            return;
        }
        showAlert(response.data.message, `tsm-modal`, 'success', async () => {
            history?.push(`/home`);
        });
        return response;
    } catch (e) {
        showAlert(FORM_SUBMISSION_ERROR, `tsm-form-failed`, 'danger');
        throw e;
    } 
}
export async function getAllTSMForms(urlData: any, forms: IForms) { 
    try {  
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType: 'tsmForm', pageNumber, sortIndexField, sortingOrder, searchItem}; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        ToolBoxMeetingModel.deleteAll(); 
        if (response.data.status === 'valid' && response.data.data.length) { 
            let instance: any = {}; 
            response.data.data.forEach((data: any) => {
                instance = marshallTSMFormData(data)
                new ToolBoxMeetingModel(instance).$save();
            }); 
        }
    } catch (e) {
        throw e;
    }
}

function formattingAnswers(answers: Array<{ quesType: string, quesNum: number, answer: any }>) {
    const formattedAnswer: any = {}; 
    let workDetails: any = [];
    let emplyeesName: any = [];
    let contractEmplyeesName: any = [];
    answers.forEach((answer: { quesType: string, quesNum: number, answer: any }) => {
        switch(answer.quesType) {
            case 'worksDetail': 
                workDetails.push(answer.answer)
                break;
            case 'emplyeesName':
                emplyeesName.push(answer.answer);
                break;
            case 'contractEmplyeesName':
                contractEmplyeesName.push(answer.answer);
                break;
            default: 
                formattedAnswer[answer.quesType] = answer.answer
        }
    }) 
    formattedAnswer['worksDetail'] = workDetails;
    formattedAnswer['emplyeesName'] = emplyeesName;
    formattedAnswer['contractEmplyeesName'] = contractEmplyeesName;
    return formattedAnswer;
}

function marshallTSMFormData(data: any) {   
    const answers = formattingAnswers(data.answers);  
    Object.assign(data, answers); 
    delete data.answers;
    data.id = data.key;
    return data;
} 

export async function getFormData( formId: string) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`GetForms/tsmForm/${company}/${formId}`);
        ToolBoxMeetingModel.deleteAll(); 
        if (response.data.status === 'valid' && response.data.data.length) {
            const texas811FormData = marshallTSMFormData(response.data.data[0]); 
            new ToolBoxMeetingModel(texas811FormData).$save(); 
        }
    } catch (e) {
        throw e
    }

}
