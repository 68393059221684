import { fromJS } from 'immutable';
import { PUSH_ALERT, REMOVE_ALERT, SET_TIMEOUT_ID } from '../constants/actions';

export function alertReducer(state = fromJS({ alert: [], alertID: [], type: [], timeOutID: '' }), action: { type: any; text: any; id: any; alertType: any; timeOutID: any; }) {
    switch (action.type) {
        case PUSH_ALERT:
            return state.set('alert', action.text)
                .set('alertID', action.id)
                .set('type', action.alertType);

        case REMOVE_ALERT:
            return state.set('alert', '').set('alertID', '').set('timeOutID', '').set('type', '');

        case SET_TIMEOUT_ID:
            return state.set('timeOutID', action.timeOutID);

        default:
            return state;
    }
}
