import PropTypes from 'prop-types';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { showMapInputModal } from '../../../actions/modalActions';
import { ISelectOptions } from '../../../interfaces';
import { dispatch } from '../../../utils/generalUtils';
import { LocationOptionComponent } from '../ReactSelectComponents/LocationOptionComponent';
import { LocationValueComponent } from '../ReactSelectComponents/LocationValueComponent';
import { Label } from './Label';
import { RRFInput } from './RRFInput';
import { UserModel } from '../../../model/UserModel';  
import { getCustom } from '../../../utils/HTTP';

export interface ILocationInputProps {
    id: string;
    menuItems?: ISelectOptions[];
    navigator?: Navigator;
    locationName?: string;
    getLongitude?: (longitude: number) => void;
    isCustom?: boolean;
    getLatitude?: (latitude: number) => void;
    model: string;
    sm?: number;
    companyName: string;
    defaultValue: any;
    location?: string;
    specifiedLocation?: any;
    isDisabled?: boolean;
}

export interface ILocationInputState {
    longitude: number;
    latitude: number;
    selectedValue: string;
    locations: any; 
    regions: any; 
    allLocations: any;
    regLength: any;
    menuItem: any;
    selectedRegion: string;
    showRegionOrLocation: string;
}

export class LocationInputImpl extends React.PureComponent<ILocationInputProps,
    ILocationInputState> {
    constructor(props: ILocationInputProps | Readonly<ILocationInputProps>) {
        super(props); 
        this.state = {
            longitude: 0,
            latitude: 0,
            selectedValue: '', locations:null, regions: null, allLocations: null, regLength:null,
            menuItem: [], selectedRegion: '', showRegionOrLocation: props.defaultValue?'location':'region',
        };
    }

    static defaultProps = { isCustom: false }; 
    static contextTypes = { formModel: PropTypes.string };

    componentDidMount() { 
        this.getLocations(UserModel.getCompanyName());
        dispatch(actions.change(`${this.context.formModel}.mobileLocation`, `0,0`));
    }  
    
    async getLocations(companyName: string) { 
        const { model, defaultValue } = this.props;  
        try { 
            const response = await getCustom(`/Locations/${companyName}`);  
            //let placesArray: IGoogleMaps[] = [];
            //let regionsArray: IMapRegion[] = []; 
            let placesArray: any[] = [];
            let regionsArray: any[] = []; 
            let regLength = 0;
            for (let item in response.data.data) { 
                regLength++;
                regionsArray.push({ label: item, value: item, model: `${this.context.formModel}${model}` });  
                //regionsArray.push({'loc':item});
                if( regLength===1 ) {
                    for (let item1 in response.data.data[item]) {  
                        placesArray.push({'lat': response.data.data[item][item1]['latitude'],'lng': response.data.data[item][item1]['longitude'],'name':item1, label: item1, value: item1,});  
                    }
                }
                if(defaultValue) {
                    for (let item1 in response.data.data[item]) {   
                        regionsArray.push({ label: item1, value: item1, model: `${this.context.formModel}${model}` }); 
                    }
                }
            }  
            this.setState({ allLocations:response.data.data, locations: placesArray, regions: regionsArray, regLength:regLength, menuItem: regionsArray, showRegionOrLocation: defaultValue?'location':'region' }); 
        } catch (error) {
            throw error;
        }
    }
    handleClick = () => {  
        const { id, model, isDisabled } = this.props; 
        const {locations} = this.state;  
        let center = this.getCenterPosition(locations);
        dispatch(actions.change(`${this.context.formModel}.mobileLocation`,
            `${this.state.longitude}, ${this.state.longitude}`)); 
        !isDisabled && showMapInputModal(id, locations, `${this.context.formModel}${model}`, center);
    }

    handleChangeRegion = (item) => { 
        if(!item) return;
        const { id, model, isDisabled } = this.props;  
        const { allLocations } = this.state;
        let locations: Array<any> = [];  
        if(!allLocations[item]) return;
        for (let item1 in allLocations[item]) {  
            locations.push({'lat': allLocations[item][item1]['latitude'],'lng': allLocations[item][item1]['longitude'],'name':item1, 'label': item1, 'value': item1 }); 
        }
        let center = this.getCenterPosition(locations);
        dispatch(actions.change(`${this.context.formModel}.mobileLocation`,
            `${this.state.longitude}, ${this.state.longitude}`)); 
        this.setState({menuItem: locations, selectedRegion: item, showRegionOrLocation: 'location'}) 
        !isDisabled && showMapInputModal(id, locations, `${this.context.formModel}${model}`, center);
    }

    handleClickRegionDropdown = async ()=> {  
        const { showRegionOrLocation, allLocations} = this.state; 
        const { model } = this.props;  
        if(showRegionOrLocation==='location') { 
            this.setState({menuItem: []});
            let regionsArray: any[] = []; 
            for (let item in allLocations) {  
                regionsArray.push({ label: item, value: item, model: `${this.context.formModel}${model}` });
            }  
            this.setState({menuItem: regionsArray}); 
            await dispatch(actions.change(`${this.context.formModel}.locationName`, '')); 
        } 
        return;
    }
    getLocation = (position: {
        coords: {
            longitude: number,
            latitude: number
        }
    }) => {
        this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        return position;
    }

    isCustom = () => {
        return this.props.companyName === 'Demo-Exxon' || this.props.companyName === 'UP';
    }

    getProps = () => {
        const { defaultValue, location, id } = this.props; 
        /* const {allLocations} = this.state;
        let menuItems: Array<any> = [];  */
        /* for(let item of Object.keys(allLocations).sort((a, b) => a.localeCompare(b))) {
            let locations: Array<any> = [];
            let exLoc: any = [];
            for (let item1 in allLocations[item]) {  
                locations.push({'lat': allLocations[item][item1]['latitude'],'lng': allLocations[item][item1]['longitude'],'name':item1});
            }
            let center = this.getCenterPosition(locations);
            exLoc['locations'] = locations;
            exLoc['center'] = center;
            menuItems.push({ label: item, value: item, model: `${this.context.formModel}${model}`, locationDetails: exLoc });  
        }  */
        /* for(let item of Object.keys(allLocations)) { 
            for (let item1 in allLocations[item]) {  
                menuItems.push({ label: item1,  value: item1, model: `${this.context.formModel}${model}` }); 
            }
        } */ 
        //this.setState({menuItem: menuItems})
        //dispatch(actions.change(`forms.oiForm.locationName`, defaultValue));   
        if(id==='jsa-form')
            dispatch(actions.change(`forms.jsaForm.location`, location)); 
        return { 
            id: 'specifiedLocation',
            type: 'dropdown', 
            model: '.locationName',
            //menuItems: menuItems,
            optionComponent: LocationOptionComponent,
            valueComponent: LocationValueComponent, 
            defaultValue: defaultValue,
            onSelect: this.handleChangeRegion, 
           /*  placeholder: 'loading...' */
        };
    }
    renderLocation = () => { 
        const {regLength} = this.state; 
        const { defaultValue,location, id } = this.props; 
        if(id==='jsa-form')
            dispatch(actions.change(`forms.jsaForm.location`, location));
        if (this.props.navigator && this.props.navigator.userAgent.match(/Mobile/i) && !this.props.navigator.userAgent.match(/iPad/i)) {
            if (navigator.geolocation) {
                navigator
                    .geolocation
                    .getCurrentPosition(this.getLocation);
                return (
                    <div className="location-details">
                        <div>
                            <Label htmlFor="latitude">Latitude:</Label>
                            <Col sm={6} className="input">
                                <input
                                    id="latitude"
                                    disabled
                                    type="number"
                                    value={this.state.latitude}
                                    className="form-control" />
                            </Col>
                        </div>
                        <div>
                            <Label htmlFor="longitude">Longitude:</Label>
                            <Col sm={6} className="input">
                                <input
                                    id="longitude"
                                    disabled
                                    type="number"
                                    value={this.state.longitude}
                                    className="form-control" />
                            </Col>
                        </div>
                        {this.props.isCustom
                            ? <Col sm={this.props.sm || 4} xs={12}>
                                <Label sm={12} htmlFor="specifiedLocation">Location:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput {...this.getProps()} />
                                </Col>
                            </Col>
                            : <div>
                                <Label htmlFor="latitude">Location:</Label>
                                <Col sm={4} className="input" onClick={this.handleClick}>
                                    <RRFInput
                                        id="locationName"
                                        className="location-input"
                                        placeholder="Click to select location"
                                        type="text"
                                        defaultValue={defaultValue}
                                        model=".locationName"
                                        isDisabled={true}
                                    />
                                    {/* <Control.text
                                        type="text"
                                        placeholder="Click to select location"
                                        model=".locationName"
                                        className="form-control location-input" 
                                        defaultValue={defaultValue}
                                        disabled /> */}
                                </Col>
                            </div>}
                    </div>
                );
            }
        }
        return (
            <>
                {regLength && regLength>1
                    ? <><Col sm={this.props.sm || 4} xs={12} >
                        <Label required sm={12} htmlFor="specifiedLocation">Location:</Label>
                        <Col sm={12} className="input" onClick={this.handleClickRegionDropdown}>
                            <RRFInput {...this.getProps()} menuItems={this.state.menuItem}   />
                        </Col>
                    </Col></>
                    : <Col sm={this.props.sm || 4} onClick={this.handleClick}>
                        <Label required sm={12} htmlFor="latitude">Location:</Label>
                        <RRFInput
                            id="locationName"
                            className="location-input"
                            placeholder="Click to select location"
                            type="text"
                            defaultValue={defaultValue && defaultValue.toString()}
                            model=".locationName" 
                            isDisabled={true}
                        />
                        {/* <Control.text
                            type="text"
                            placeholder="Click to select location"
                            model=".locationName"
                            className="form-control location-input"
                            defaultValue={defaultValue}
                            disabled /> */}
                    </Col>}
            </>
        );
    }

    render() {
        return this.renderLocation();
    }
    getCenterPosition(tempdata: any[]){  
        if(!tempdata) return;
        let latitudearray: any[] = [];
        let longitudearray: any[] = []; 
        tempdata.forEach(item=> {  
          latitudearray.push(item.lat);
          longitudearray.push(item.lng); 
        });
        latitudearray.sort(function (a, b) { return a-b; });
        longitudearray.sort(function (a, b) { return a-b; });
        let latdifferenece = latitudearray[latitudearray.length-1] - latitudearray[0];
        let temp = (latdifferenece / 2).toFixed(4) ;
        let latitudeMid = parseFloat(latitudearray[0]) + parseFloat(temp);
        let longidifferenece = longitudearray[longitudearray.length-1] - longitudearray[0];
        temp = (longidifferenece / 2).toFixed(4) ;
        let longitudeMid = parseFloat(longitudearray[0]) + parseFloat(temp);
        let maxdifference = (latdifferenece > longidifferenece)? latdifferenece : longidifferenece;
        let zoomvalue;	
        if(maxdifference >= 0 && maxdifference <= 0.0037)  //zoom 17
          zoomvalue=17;
        else if(maxdifference > 0.0037 && maxdifference <= 0.0070)  //zoom 16
          zoomvalue=16;
        else if(maxdifference > 0.0070 && maxdifference <= 0.0130)  //zoom 15
          zoomvalue=15;
        else if(maxdifference > 0.0130 && maxdifference <= 0.0290)  //zoom 14
          zoomvalue=14;
        else if(maxdifference > 0.0290 && maxdifference <= 0.0550)  //zoom 13
          zoomvalue=13;
        else if(maxdifference > 0.0550 && maxdifference <= 0.1200)  //zoom 12
          zoomvalue=12;
        else if(maxdifference > 0.1200 && maxdifference <= 0.4640)  //zoom 10
          zoomvalue=10;
        else if(maxdifference > 0.4640 && maxdifference <= 1.8580)  //zoom 8
          zoomvalue=8;
        else if(maxdifference > 1.8580 && maxdifference <= 3.5310)  //zoom 7
          zoomvalue=7;
        else if(maxdifference > 3.5310 && maxdifference <= 7.3367)  //zoom 6
          zoomvalue=6;
        else if(maxdifference > 7.3367 && maxdifference <= 14.222)  //zoom 5
          zoomvalue=5;
        else if(maxdifference > 14.222 && maxdifference <= 28.000)  //zoom 4
          zoomvalue=4;
        else if(maxdifference > 28.000 && maxdifference <= 58.000)  //zoom 3
          zoomvalue=3;
        else
          zoomvalue=1;  
        let data = {'centerLat':latitudeMid,'centerLong':longitudeMid,'zoom':zoomvalue};
        return data; 
    }
}

export function mapStateToProps(state: any) {
    return {
        locationName: state
            .miscellaneous
            .get('subDropdownValue')
    };
}

export const LocationInput = connect<{}, {}, ILocationInputProps>(mapStateToProps)(LocationInputImpl);
