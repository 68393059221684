import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './utilityDamageReportListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import {getAllTexas811Forms} from "../../services/texas811Service";
import {UtilityDamageReportModel} from "../../model/UtilityDamageReportModel";
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess} from '../../actions/loadingActions'; 

declare var require: any;
const queryString = require('query-string');

export interface IUtilityDamageReportListPageProps {
    instances: UtilityDamageReportModel[];
    history?: IHistory;
    totalPageCount: number;
    forms: IForms;
    axiosPreviousToken: any;
}

export class UtilityDamageReportsListImpl extends React.PureComponent<IUtilityDamageReportListPageProps, {}> {
    constructor(props: IUtilityDamageReportListPageProps | Readonly<IUtilityDamageReportListPageProps>) {
        super(props);
    }
    sortListing = { 0: 'date', 1: 'username', 2:'dateOfDamage', 3: 'jobNo' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || []; 
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }
    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        urlParams.sortIndexField = this.sortListing[sortIndex];
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllTexas811Forms( urlParams, this.props.forms, 'utilityDamageReport'  );
        setSuccess('AllListPage'); 
    }

    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report"
                    onClick={() => { history?.push('/utilityDamageReport/create'); }}
            >
                +
            </button>
        );
    } 

    renderContent = () => {
        const { instances } = this.props;
        return <div className="office-safety-wrapper">  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history}/>
            {<ExcelExport formType="utilityDamageReport" fileName="Utility Damage Reports" />}
            <> <ListPage
                    pageHeading="Utility Damage Reports List"
                    instances={instances}
                    rowHeadings={['Date Submitted','Observer', 'Date Of Damage', "Job #" ]}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={[ 'date', 'username']}
                    searchPlaceHolder="Enter job no to search..."
                    emptyInstancesMessage="No utility damage report found."
                    sm={[2, 3, 2, 2]}
                    currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                {this.renderAddNewFormButton()} 
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={UtilityDamageReportModel as any}
                    identifier="UtilityDamageReportsList"
                />
            </> 
        </div>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="UtilityDamageReportsList"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const instances = UtilityDamageReportModel.list();
    return { instances, totalPageCount: state.miscellaneous.get('officeSafetyFormCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const UtilityDamageReportsList = withRouter(connect<IUtilityDamageReportListPageProps, any, any>
(mapStateToProps)(UtilityDamageReportsListImpl));
