import './navBar.scss';

import * as React from 'react';
import {Button, Dropdown, Nav, Navbar, NavDropdown, OverlayTrigger, Popover, Container} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import { withRouter} from 'react-router-dom'; 
import {logout} from '../../../actions/loginActions';
import {showCreateCustomNotificationModal, showCOVID19AssessmentModal} from '../../../actions/modalActions';
import {removeInstance} from '../../../actions/modelActions';
import {IHistory, INotification, IWeatherDetails} from '../../../interfaces';
import {UserModel} from '../../../model/UserModel';
import {removeUserInfo, getUserInfo} from '../../../services/loginService';
import {getNotifications, setNotificationsSeen} from '../../../services/profileService';
import {getUser} from '../../../services/userService';
import {Async} from '../Async';
/* import {getWeatherDetails} from '../../../services/miscellaniousServices'; */
import {GeolocatedProps, geolocated} from 'react-geolocated';
import {substring, convertText} from '../../../utils/generalUtils';
import logoURL from '../../../images/SafeLogo.png';
import { ELMSURL } from '../../../constants/urls';

const MenuItem = NavDropdown.Item;

export interface INavBarProps {
    userId?: string;
    userUID: string;
    firstName?: string;
    instance?: UserModel;
    formNotifications: INotification[];
    companyName?: string;
    id?: string;
    history?: IHistory;
    weatherDetails: IWeatherDetails;
    companyLogo?: string;
    language: string;
    totalFollowupOpen?: any;
    userInfo?: any;
}

export interface INavBarState {
    dropdown: number;
    isMobileDevice: boolean;
}

export class NavBarImpl extends React.Component<INavBarProps & GeolocatedProps, INavBarState> {
    constructor(props: (INavBarProps & GeolocatedProps) | Readonly<INavBarProps & GeolocatedProps>) {
        super(props);
        //this.logout();
        this.state = {
            isMobileDevice: false, dropdown: 0
        };
    }

    promise = async () => {
        const {weatherDetails, instance, formNotifications} = this.props;
        if (!instance) {
            await getUser(this.props.userUID);
        }
        if (!formNotifications) {
            await getNotifications(this.props.userUID, UserModel.getCompanyName());
        }
        if (!weatherDetails && this.props.coords) {
            // this.fetchLongitudeLatitude();, -97.066542
            //await getWeatherDetails(32.997821, -97.066542);
        }
        return;
    }

    /* fetchLongitudeLatitude = () => {
        if (navigator.geolocation) {
            navigator
                .geolocation
                .getCurrentPosition(this.getLocation);
        }
    }

    getLocation = async (position) => {
    
    } */

    componentWillMount() {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            this.setState({
                isMobileDevice: true
            });
        }
    }

    logout = async () => {
        //const { props: { instance: { resource, props: { userId } } } } = this; 
        const company = UserModel.getCompanyName()
        await logout();
        await removeUserInfo();
        await removeInstance(`${UserModel.resource + this.props.userId}`);
        //UserModel.deleteAll(); 
        this.props.history?.push(`/login/${company}`);
        window.location.reload();
    } 

    isSamePath = (path: string) => {
        //const { history: { location: { pathname } } } = this.props; 
        let pathname = this.props.history && this.props.history.location['pathname'];
        if (path === pathname) {
            return true;
        }
        return false;
    }

    addClass = (isOpen: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
        if (index === this.state.dropdown) {
            this.setState({
                dropdown: 0
            });
            return;
        }
        this.setState({
            dropdown: index
        });
    }

    removeClassFromAll = (isOpen: boolean, e: React.SyntheticEvent<Dropdown, Event>, source: { source: any; }, identifier: number) => {
        if (source.source === 'select' || (source.source === 'rootClose' && identifier === this.state.dropdown)) {
            this.setState({
                dropdown: 0
            });
        }
    }

    adminModules = () => { 
        const {dropdown} = this.state; 
        if (UserModel.checkUserAccess('users_list') || UserModel.checkUserAccess('projects_list') || UserModel.checkUserAccess('groups_list')) {
            return (
                <NavDropdown
                    onClick={(e) => this.addClass(e, 1)}
                    onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                    className={`nav-dropdown ${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                    title="Admin"
                    id="admin-dropdown"
                >
                    {UserModel.checkUserAccess('users_list') &&
                    <LinkContainer replace={this.isSamePath('/users')} to="/users">
                        <MenuItem>Users</MenuItem>
                    </LinkContainer>}

                    {UserModel.checkUserAccess('projects_list') &&
                    <LinkContainer replace={this.isSamePath('/teams')} to="/teams">
                        <MenuItem>Teams</MenuItem>
                    </LinkContainer>}
                    
                    {UserModel.checkUserAccess('groups_list') &&
                    <LinkContainer replace={this.isSamePath('/groups')} to="/groups">
                        <MenuItem>Groups</MenuItem>
                    </LinkContainer> }
                    
                    {UserModel.checkUserAccess('groups_list') &&
                    <LinkContainer replace={this.isSamePath('/locations')} to="/locations">
                        <MenuItem>Locations</MenuItem>
                    </LinkContainer>}
                    {UserModel.checkUserAccess('groups_list') &&
                    <LinkContainer replace={this.isSamePath('/designations')} to="/designations">
                        <MenuItem>Designations</MenuItem>
                    </LinkContainer>}
                </NavDropdown>);
        }
        return;
    } 
    renderOIDropdown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="O&amp;I"
                id="oi-dropdown"
            >
                {UserModel.checkUserAccess('o&i_report') &&
                <LinkContainer replace={this.isSamePath('/oi-reports')} to="/oi-reports">
                    <MenuItem>O&amp;I Report</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('o&i_report_summary') &&
                <LinkContainer replace={this.isSamePath('/oi-reports/summary')} to="/oi-reports/summary">
                    <MenuItem>O&amp;I Report Summary</MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    } 
    renderOfficeSafetyDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 2)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 2)}
                className={`nav-dropdown open sub-menu /*${dropdown === 2 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Office Safety"
                id="office-safety-dropdown"
            >
                {UserModel.checkUserAccess('office_safety_report') &&
                <LinkContainer replace={this.isSamePath('/office-safety-reports')} to="/office-safety-reports">
                    <MenuItem>Office Safety Report
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('office_safety_report_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/office-safety-reports/summary')}
                    to="/office-safety-reports/summary"
                >
                    <MenuItem>Office Safety Report Summary
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('office_safety_comments') &&
                <LinkContainer
                    replace={this.isSamePath('/office-safety-reports/comments')}
                    to="/office-safety-reports/comments"
                >
                    <MenuItem>Office Safety Comments
                    </MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    }
    renderRiskAssessmentDropdown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Risk Assessment"
                id="risk-assessment-dropdown"
            >
                {/* { UserModel.checkUserAccess('o&i_report') && 
                    <LinkContainer replace={this.isSamePath('/risk-assessment-reports')} to="/risk-assessment-reports">
                        <MenuItem >Risk Assessment Report</MenuItem>
                    </LinkContainer>
                }  */}
                {UserModel.checkUserAccess('o&i_report') &&
                <NavDropdown
                    onClick={(e) => this.addClass(e, 4)}
                    onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 4)}
                    className={`nav-dropdown open second-sub-menu sub-menu /*${dropdown === 4 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                    title="Risk Assessment Report"
                    id="risk-assessment-dropdown"
                >
                    {UserModel.checkUserAccess('o&i_report') &&
                    <LinkContainer replace={this.isSamePath('/risk-assessment-reports/preliminary')}
                                   to="/risk-assessment-reports/preliminary">
                        <MenuItem>Preliminary Risk Assessment Report
                        </MenuItem>
                    </LinkContainer>
                    }
                    {UserModel.checkUserAccess('o&i_report') &&
                    <LinkContainer replace={this.isSamePath('/risk-assessment-reports/approval')}
                                   to="/risk-assessment-reports/approval">
                        <MenuItem>Risk Assessment (Approvals) Report
                        </MenuItem>
                    </LinkContainer>
                    }
                    {UserModel.checkUserAccess('o&i_report') &&
                    <LinkContainer replace={this.isSamePath('/risk-assessment-reports/final')}
                                   to="/risk-assessment-reports/final">
                        <MenuItem>Final Risk Assessment Report
                        </MenuItem>
                    </LinkContainer>
                    }
                </NavDropdown>
                }
                {UserModel.checkUserAccess('office_safety_report_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/risk-assessment/summary')}
                    to="/risk-assessment/summary"
                >
                    <MenuItem>Risk Assessment Summary
                    </MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    }
    renderAuditInspectionsDropdown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Audit/Inspection"
                id="audit-dropdown"
            >
                {UserModel.checkUserAccess('o&i_report') &&
                <LinkContainer replace={this.isSamePath('/audit-reports')} to="/audit-reports">
                    <MenuItem>Audit Report</MenuItem>
                </LinkContainer>
                }

            </NavDropdown>
        );
    }
    renderSafetyWalkthroughDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 2)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 2)}
                className={`nav-dropdown open sub-menu /*${dropdown === 2 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Safety Walkthrough"
                id="safety-walkthrough-dropdown"
            >
                {UserModel.checkUserAccess('safety_walkthrough_report') &&
                <LinkContainer replace={this.isSamePath('/sw-reports')} to="/sw-reports">
                    <MenuItem>Safety Walkthrough Report
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('safety_walkthrough_report_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/safety-walkthrough-reports/summary')}
                    to="/safety-walkthrough-reports/summary"
                >
                    <MenuItem>Safety Walkthrough Report Summary
                    </MenuItem>
                </LinkContainer>
                }
                {/* <LinkContainer replace={this.isSamePath('/sw-comments')} to="/sw-comments">
                    <MenuItem
                        eventKey={5.1}>Safety Walkthrough Comments
                    </MenuItem>
                </LinkContainer> */}

            </NavDropdown>
        );
    }
    renderChecklistDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 2)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 2)}
                className={`nav-dropdown open sub-menu /*${dropdown === 2 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Safety Walkthroughs & Checklists"
                id="checklists-dropdown"
            >
                {UserModel.checkUserAccess('safety_walkthrough_report') &&
                <LinkContainer replace={this.isSamePath('/checklist-reports/safety-walkthrough')}
                               to="/checklist-reports/safety-walkthrough">
                    <MenuItem>Safety Walkthrough Report
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('safety_walkthrough_report_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/safety-walkthrough-reports/summary')}
                    to="/safety-walkthrough-reports/summary"
                >
                    <MenuItem>Safety Walkthrough Report Summary
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('checklist_report') &&
                <LinkContainer replace={this.isSamePath('/checklist-reports/other-checklists')}
                               to="/checklist-reports/other-checklists">
                    <MenuItem>Checklist Report
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('checklist_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/checklist-summary')}
                    to="/checklist-summary"
                >
                    <MenuItem>Checklist Report Summary
                    </MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    } 
    renderCloseCallDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Close Call"
                id="closecall-dropdown"
            >
                {UserModel.checkUserAccess('closecall_report') &&
                <LinkContainer replace={this.isSamePath('/close-call-reports')} to="/close-call-reports">
                    <MenuItem>Close Call Report</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('closecall_report_summary') &&
                <LinkContainer replace={this.isSamePath('/close-call-summary')} to="/close-call-summary">
                    <MenuItem>Close Call Report Summary</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('closecall_checklist_report') &&
                <LinkContainer replace={this.isSamePath('/close-call-checklist-reports')}
                               to="/close-call-checklist-reports">
                    <MenuItem>Close Call Checklist Report</MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    } 
    renderTexas811DropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Utility Reports"
                id="closecall-dropdown"
            >
                {/* {
                    <LinkContainer replace={this.isSamePath('/preExcavationChecklist/reports')}
                                   to="/preExcavationChecklist/reports">
                        <MenuItem>Pre-excavation Checklist Report</MenuItem>
                    </LinkContainer>
                } */}
                {
                    <LinkContainer replace={this.isSamePath('/preExcavationChecklistV2/reports')}
                                   to="/preExcavationChecklistV2/reports">
                        <MenuItem>Pre-excavation Checklist Report</MenuItem>
                    </LinkContainer>
                }
                {
                    <LinkContainer replace={this.isSamePath('/utilityDamageReport/reports')}
                                   to="/utilityDamageReport/reports">
                        <MenuItem>Utility Damage Report</MenuItem>
                    </LinkContainer>
                }
            </NavDropdown>
        );
    } 
    renderTSMDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Toolbox Safety Meeting"
                id="closecall-dropdown"
            >
                {
                    <LinkContainer replace={this.isSamePath('/tool-box-meeting/reports')}
                                   to="/tool-box-meeting/reports">
                        <MenuItem>TSM Report</MenuItem>
                    </LinkContainer>
                } 
            </NavDropdown>
        );
    }
    renderMOCDropdown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu /*${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Management Of Change"
                id="closecall-dropdown"
            > 
                <LinkContainer replace={this.isSamePath('/management-of-change/permanent-deviation-reports')} to="/management-of-change/permanent-deviation-reports">
                    <MenuItem>Permanent Deviation Report</MenuItem>
                </LinkContainer>
                <LinkContainer replace={this.isSamePath('/management-of-change/temporary-deviation-reports')} to="/management-of-change/temporary-deviation-reports">
                    <MenuItem>Temporary Deviation Report</MenuItem>
                </LinkContainer> 
            </NavDropdown>
        );
    }
    renderPTWDropdown = () => { 
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown open sub-menu `}
                title="Permit to Work"
                id="closecall-dropdown"
            > 
                <LinkContainer replace={this.isSamePath('/permit-to-work/preliminary-reports')} to="/permit-to-work/preliminary-reports">
                    <MenuItem>Preliminary PTW Report</MenuItem>
                </LinkContainer>
                <LinkContainer replace={this.isSamePath('/permit-to-work/temporary-reports')} to="/permit-to-work/temporary-reports">
                    <MenuItem>Final PTW Report</MenuItem>
                </LinkContainer>
                <LinkContainer replace={this.isSamePath('/permit-to-work/closeout-reports')} to="/permit-to-work/closeout-reports">
                    <MenuItem>Close Out PTW Report</MenuItem>
                </LinkContainer>
            </NavDropdown>
        );
    }
    renderCovid19DropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown ${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                /* title={this.titleWithLabelNew('new', 'label-new')} */
                title="COVID-19"
                id="covid19-dropdown"
            >
                {UserModel.checkUserAccess('covid19_add') &&
                <MenuItem onClick={() => showCOVID19AssessmentModal()}>{convertText('selfAssessment')}</MenuItem>}
                {UserModel.checkUserAccess('covid19_report') &&
                <LinkContainer replace={this.isSamePath('/covid19-reports')} to="/covid19-reports">
                    <MenuItem>Report</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('covid19_report_summary') &&
                <LinkContainer replace={this.isSamePath('/covid19-summary')} to="/covid19-summary">
                    <MenuItem>Report Summary</MenuItem>
                </LinkContainer>
                }

            </NavDropdown>
        );
    }
    titleWithLabelNew = (title: string, label: {} | null | undefined, className: string) => {
        return (<span>{title}<span className={` ${className}`}>{label}</span> </span>)
    }
    renderHPDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 1)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 1)}
                className={`nav-dropdown ${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                title={this.titleWithLabelNew('HP', 'new', 'label-new')}  
                id="hp-dropdown"
            >
                {UserModel.checkUserAccess('covid19_report') &&
                <LinkContainer replace={this.isSamePath('/human-performance/create')} to="/human-performance/create">
                    <MenuItem>Human Performance</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('covid19_report') &&
                <LinkContainer replace={this.isSamePath('/human-performance-reports')} to="/human-performance-reports">
                    <MenuItem>Report</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('covid19_report_summary') &&
                <LinkContainer replace={this.isSamePath('/human-performance-summary')} to="/human-performance-summary">
                    <MenuItem>Report Summary</MenuItem>
                </LinkContainer>
                } 
            </NavDropdown>
        );
    }
    renderPersonalSafetyDropdown = () => {
        const {state: {dropdown}, props: { userUID}} = this;
        //if (instance && instance.props.accessLevel === 'L1') {
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 3)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 3)}
                className={`nav-dropdown ${dropdown === 3 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                title="Personal Safety"
                id="office-safety-dropdown"
            >
                {UserModel.checkUserAccess('personal_safty_report') &&
                <LinkContainer
                    replace={this.isSamePath(`/personal-safety/myforms/${userUID}`)}
                    to={`/personal-safety/myforms/${userUID}`}
                >
                    <MenuItem>Personal Safety Form
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('others_personal_safty_report') &&
                <LinkContainer replace={this.isSamePath('/personal-safety/all')} to="/personal-safety/all">
                    <MenuItem>Other Users Forms
                    </MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        ); 
    } 
    renderIncidentNotificationDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 4)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 4)}
                className={`nav-dropdown open sub-menu /*${dropdown === 4 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                title="Incident"
                id="incident-dropdown"
            >
                {UserModel.checkUserAccess('incident_notification_report') &&
                <LinkContainer replace={this.isSamePath('/incident-reports')} to="/incident-reports">
                    <MenuItem>Incident Notification Report
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('incident_notification_report_summary') &&
                <LinkContainer replace={this.isSamePath('/incident-reports/summary')} to="/incident-reports/summary">
                    <MenuItem>Incident Notification Report Summary
                    </MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('incident_investigative_report') &&
                <NavDropdown
                    onClick={(e) => this.addClass(e, 4)}
                    onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 4)}
                    className={`nav-dropdown open second-sub-menu sub-menu /*${dropdown === 4 ? 'sub-menu' : 'sub-menu-dropdown'}*/`}
                    title="Incident Investigative Report"
                    id="incident-dropdown"
                >
                    {UserModel.checkUserAccess('incident_notification_report') &&
                    <LinkContainer replace={this.isSamePath('/incident-investigative-reports/preliminary')}
                                   to="/incident-investigative-reports/preliminary">
                        <MenuItem>Preliminary Investigative Report
                        </MenuItem>
                    </LinkContainer>
                    }
                    {UserModel.checkUserAccess('incident_notification_report') &&
                    <LinkContainer replace={this.isSamePath('/incident-investigative-reports/final')}
                                   to="/incident-investigative-reports/final">
                        <MenuItem>Final Investigative Report
                        </MenuItem>
                    </LinkContainer>
                    }
                </NavDropdown>
                }
                {UserModel.checkUserAccess('incident_investigative_report_summary') &&
                <LinkContainer
                    replace={this.isSamePath('/incident-investigative-reports/summary')}
                    to="/incident-investigative-reports/summary"
                >
                    <MenuItem>Incident Investigative Report Summary
                    </MenuItem>
                </LinkContainer>
                } 
            </NavDropdown> 
        );
    } 
    renderReferenceGuideLines = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 5)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 6)}
                className={`nav-dropdown ${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                title="References"
                id="oi-dropdown"
            >
                {UserModel.checkUserAccess('golden_rules_list') &&
                <LinkContainer replace={this.isSamePath('/golden-rules')} to="/golden-rules">
                    <MenuItem>Golden Rules</MenuItem>
                </LinkContainer>
                }
                {UserModel.checkUserAccess('references_list') &&
                <LinkContainer replace={this.isSamePath('/reference/show')} to="/reference/show">
                    <MenuItem> Reference Documents</MenuItem>
                </LinkContainer>
                }
            </NavDropdown>
        );
    } 
    renderCovidDropDown = () => {
        const {dropdown} = this.state;
        return (
            <NavDropdown
                onClick={(e) => this.addClass(e, 5)}
                onToggle={(open, e, source) => this.removeClassFromAll(open, e, source, 6)}
                className={`nav-dropdown ${dropdown === 1 ? 'sub-menu' : 'sub-menu-dropdown'}`}
                title="COVID - 19"
                id="covid-dropdown"
            >

            </NavDropdown>
        );
    } 
    handleNotificationClick = (uniqueId: string) => {
        const {userUID} = this.props;
        document.body.click();
        setNotificationsSeen(uniqueId, userUID, UserModel.getCompanyName());
    } 
    getLink = (notification: any) => {
        if (notification.customNotificationId) {
            return `/#/custom-notification/${notification.customNotificationId}`;
        }
        const link = notification.referralLink.split('#')[1];
        return `/#${link}`;
    } 
    popOver = () => {
        const {formNotifications} = this.props;
        if (!formNotifications) {
            return <div></div>;
        }
        return <Popover
            className="notification-panel"
            id="popover-trigger-click"
            onClick={() => document.body.click()}
        >
            <PerfectScrollbar>
                {(formNotifications.length > 0 && (formNotifications instanceof Array))
                    ? formNotifications.map((notification, index) => {
                        return <a
                            href={this.getLink(notification)}
                            className="notification-link"
                            style={notification.seen > 0 ? {opacity: 0.7} : {opacity: 1}}
                            key={index}
                            onClick={() => this.handleNotificationClick(notification.uniqueId)}
                        >
                            {notification.message}
                        </a>;
                    }) : <a href="javascript:;" className="notification-link" >No Notifications Found</a>}
            </PerfectScrollbar>
        </Popover>;
    } 
    handleNotificationButtonClick = () => {
        if (this.state.isMobileDevice) {
            this.props.history?.push('/notifications');
            return;
        }
        return;
    } 
    renderProfileDropdown = () => {
        /**
         * Using instance and useInfo both since on reload the firstName and the userId
         * was not being fetched.
         */
        const {instance, formNotifications, firstName, userId, totalFollowupOpen, userInfo} = this.props;
        let notificationCount = 0;
        if (formNotifications && (formNotifications instanceof Array)) {
            formNotifications.forEach(notification => {
                if (notification.seen === 0) {
                    notificationCount++;
                }
            });
        }
        return (
            <NavDropdown
                /*  noCaret={notificationCount > 0 ? true : false}
                 eventKey={4} */
                title={
                    <div style={{display: 'inline-block'}}>
                        {substring(instance && instance.props.firstName ? instance.props.firstName : firstName ? firstName : '', 8, false)}
                        <span className="notification-badge user">
                            {notificationCount > 0 ? notificationCount : ''}
                        </span>
                    </div>
                }
                id="dropdown"
            > 
                <LinkContainer replace={this.isSamePath((`/profile/${instance ? instance.props.userId : userId}`))} to={`/profile/${instance ? instance.props.userId : userId}`} >
                    <MenuItem>Profile</MenuItem>
                </LinkContainer>
                { userInfo && userInfo.elmsFlag && <a href={`${ELMSURL}/login/${userInfo.elmsToken}`} className="dropdown-item" target="_blank"> Training</a> }
                <LinkContainer replace={this.isSamePath(`/follow-ups/self`)}
                    to={`/follow-ups/self`} >
                    <MenuItem>Follow-ups <span className="notification-badge">
                            {totalFollowupOpen > 0 ? totalFollowupOpen : ''}
                        </span></MenuItem>
                </LinkContainer>
                <LinkContainer replace={this.isSamePath(`/update-password/${userId}`)}
                    to={`/update-password/${userId}`} >
                    <MenuItem>Update Password</MenuItem>
                </LinkContainer>
                {(UserModel.checkUserAccess('personal_safty_report') || UserModel.checkUserAccess('others_personal_safty_report')) && this.renderPersonalSafetyDropdown()}
                 
                <MenuItem onClick={() => showCreateCustomNotificationModal()} >
                    Create Custom Notification
                </MenuItem>
                <OverlayTrigger trigger={['click']} /* id="overlay" */ rootClose placement="left"
                                overlay={this.popOver()}>
                    <Button
                        className="notification"
                        onClick={this.handleNotificationButtonClick}
                        id="notification"
                        style={notificationButton}
                    >
                        Notifications
                        <span className="notification-badge">{notificationCount > 0 ? notificationCount : ''}</span>
                    </Button>
                </OverlayTrigger>
                <LinkContainer replace={this.isSamePath('/feedback-form')} to="/feedback-form">
                    <MenuItem>Feedback</MenuItem>
                </LinkContainer>
                <LinkContainer replace={this.isSamePath('/')} to="/">
                    <MenuItem onClick={this.logout}>Logout</MenuItem>
                </LinkContainer>
            </NavDropdown>
        );
    } 
    render() {
        const { formNotifications } = this.props;
        let notificationCount = 0;
        if ((formNotifications instanceof Array) && formNotifications.length > 0) {
            formNotifications.forEach(notification => {
                if (notification.seen === 0) {
                    notificationCount++;
                }
            });
        }
        let companyLogo1 = getUserInfo().companyLogo;
        return (
            <Navbar style={{ /* padding: '0px' */}} /* inverse */ collapseOnSelect><Container>
                {/* <Navbar.Header> */}<Container>
                <Navbar.Brand>
                    <img
                        onClick={() => !this.isSamePath('/home') ? this.props.history?.push('/home') : ''}
                        className="logo img-responsive app-logo"
                        src={logoURL} alt="SafeConnect"
                    />
                </Navbar.Brand>
                {companyLogo1 &&
                <Navbar.Brand>
                    <img
                        onClick={() => !this.isSamePath('/home') ? this.props.history?.push('/home') : ''}
                        className="logo img-responsive company-logo" alt="SafeConnect"
                        src={companyLogo1}
                    />
                </Navbar.Brand>
                }
                <Navbar.Toggle/>

            </Container>
                <Container>
                    <Navbar.Collapse>
                        <Nav>
                            <LinkContainer replace={this.isSamePath('/home')} to="/home" className="directLink">
                                <MenuItem>Home</MenuItem>
                            </LinkContainer> 
                            {this.adminModules()} 
                            {(UserModel.checkUserAccess('golden_rules_list') && UserModel.checkUserAccess('references_list')) && this.renderReferenceGuideLines()}
                            {(UserModel.checkUserAccess('o&i_report') || UserModel.checkUserAccess('o&i_report_summary') || UserModel.checkUserAccess('office_safety_report') || UserModel.checkUserAccess('office_safety_report_summary') || UserModel.checkUserAccess('incident_notification_report') || UserModel.checkUserAccess('incident_notification_report_summary') || UserModel.checkUserAccess('incident_investigative_report') || UserModel.checkUserAccess('incident_investigative_report_summary') || UserModel.checkUserAccess('jsa_report') || UserModel.checkUserAccess('safety_walkthrough_report') || UserModel.checkUserAccess('safety_walkthrough_report_summary') || UserModel.checkUserAccess('closecall_report') || UserModel.checkUserAccess('closecall_report_summary') || UserModel.checkUserAccess('closecall_checklist_report')) &&
                            <NavDropdown className="nav-dropdown reports-dropdown" title="Reports"
                                         id="reports-dropdown">
                                {(UserModel.checkUserAccess('o&i_report') || UserModel.checkUserAccess('o&i_summary')) && this.renderOIDropdown()}
                                {(UserModel.checkUserAccess('office_safety_report') || UserModel.checkUserAccess('office_safety_report_summary')) && this.renderOfficeSafetyDropDown()}
                                {(UserModel.checkUserAccess('incident_notification_report') || UserModel.checkUserAccess('incident_notification_report_summary') || UserModel.checkUserAccess('incident_investigative_report') || UserModel.checkUserAccess('incident_investigative_report_summary')) && this.renderIncidentNotificationDropDown()}
                                {UserModel.checkUserAccess('jsa_report') &&
                                <LinkContainer className="menu-b-bottom" replace={this.isSamePath('/jsa-reports')} to="/jsa-reports">
                                    <MenuItem>JSA Report</MenuItem>
                                </LinkContainer>
                                }
                                {UserModel.checkUserAccess('risk_report') && this.renderRiskAssessmentDropdown()}
                                {UserModel.checkUserAccess('audit_inspection_report') && this.renderAuditInspectionsDropdown()}
                                {/* { ( UserModel.checkUserAccess('safety_walkthrough_report') || UserModel.checkUserAccess('safety_walkthrough_report_summary') ) && this.renderSafetyWalkthroughDropDown()} */}

                                {(UserModel.checkUserAccess('safety_walkthrough_report') || UserModel.checkUserAccess('safety_walkthrough_report_summary')) && this.renderChecklistDropDown()}

                                {(UserModel.checkUserAccess('closecall_report') || UserModel.checkUserAccess('closecall_report_summary') || UserModel.checkUserAccess('closecall_checklist_report')) && this.renderCloseCallDropDown()}
                                {(UserModel.checkUserAccess('utility_damage_report') || UserModel.checkUserAccess('pre_excavation_checklist_report')) && this.renderTexas811DropDown()} 
                                {(UserModel.checkUserAccess('tsm_report') ) && this.renderTSMDropDown()} 
                                {UserModel.checkUserAccess('jsa_report') && this.renderMOCDropdown()}
                                {UserModel.checkUserAccess('jsa_report') && this.renderPTWDropdown()}
                            </NavDropdown>
                            }
                            {/* {this.renderCovid19DropDown()} */}
                            {UserModel.checkUserAccess('covid19_report') && this.renderHPDropDown()} 
                            <Async
                                className="user-dropdown"
                                identifier="user"
                                promise={this.promise}
                                loader={<div className="loading">...</div>}
                                content={ this.renderProfileDropdown() }
                                error={<div className="error">Error</div>}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Container>
            </Navbar>
        );
    }
}

export function mapStateToProps(state: any) {
    const {login} = state;
    let firstName = login.get('firstName');
    let userUID = login.get('userUID');
    //let companyLogo = login.get('currentUserInfo') && login.get('currentUserInfo').toJS().companyLogo;
    let companyLogo = getUserInfo().companyLogo; 
    let userId = login.get('userId');
    const instance = UserModel.get(userId);  
    let userInfo = getUserInfo(); 
    return {
        firstName,
        userId,
        userUID, companyLogo,
        instance: instance ? instance : '',
        formNotifications: state.formSummary.get('formNotifications'), totalFollowupOpen: state.formSummary.get('totalFollowupOpen'),
        weatherDetails: state.miscellaneous.get('weatherDetails'),
        language: getUserInfo().language, userInfo
    };
}

export const NavBar = geolocated(
    {
        positionOptions: {
            enableHighAccuracy: false,
        }
    })(withRouter(connect<INavBarProps, any, any>(mapStateToProps)(NavBarImpl)));

const notificationButton: React.CSSProperties = {
    border: 'none',
    backgroundColor: 'black',
    color: 'white',
    padding: '5px 5px 5px 10px',
    outline: 'none',
    width: '100%'
};

/* const seenNotification: React.CSSProperties = {
    padding: '5px',
    color: 'white',
    textDecoration: 'none',
    display: 'block'
};

const unSeenNotification: React.CSSProperties = {
    padding: '5px',
    opacity: 0.7,
    color: 'white',
    textDecoration: 'none',
    display: 'block'
}; */
