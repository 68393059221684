import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ISubmittedFormData } from '../../model/UserModel';
import { connect } from 'react-redux';

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: ISubmittedFormData;
    style?: React.CSSProperties;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderFormList = () => {
        const { instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}
                onClick={() => this.props.history?.push(`${instance.formURL}`)}
            >
                <Cell sm={1}>{index || 0 + 1}</Cell>
                <Cell sm={4}>{instance.formName || 'NA'}</Cell>
                <Cell sm={4}>{instance.projectName || 'NA'}</Cell>
                <Cell sm={3}>{instance.date || 'NA'}</Cell>
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
         
    };
}

export const FormListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
