import { format } from 'date-fns'; 
import { hideFollowUpFormModal } from '../actions/modalActions'; 
import { removeInstance, deleteAllInstances} from '../actions/modelActions';
import { saveIncidentInvestigativeSummary } from '../actions/summaryActions';
import { bodyParts, FORM_SUBMISSION_ERROR, locations, USERLEVELHIERARCHY } from '../constants/general';
import { IAnswer, IForms, IHistory } from '../interfaces';
import { FollowUpModel } from '../model/FollowUpModel';
import { IIncidentReportModelProps, IncidentReportModel } from '../model/IncidentReportModel';
import { UserModel } from '../model/UserModel';
import { Answers } from '../utils/Answers';
import { dispatch, getSignatureData, showAlert } from '../utils/generalUtils';
import { getCustom, post, putCustom } from '../utils/HTTP';
import { getAllIncidentNotificationForms } from './incidentNotificationFormService'; 
import { saveListCount } from '../actions/miscellaneousActions'; 
import { actions, } from 'react-redux-form'; 

export async function submitDetailedIncidentReport(history: IHistory, userId: string, answers: IAnswer[],
    group: string, project: string, forms: IForms, followups: any, associatedFormId: string, editId?: string, step?: string) { 
    let data: any = {
        groupName: group,
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        answers,
        followups,
        projectName: project,
        formId: 'incidentDetailedReport',
        followUp: 0,
        associatedFormId, 
        deletedImages: [],
        deletedVideos: [],
        editId: ''
    }; 
    if(step==='step1') {
        data.imageData = forms.incidentDetails.incidentReportImages || [];
        data.videoData = forms.incidentDetails.incidentReportVideos || []; 
    } 
    try {  
        data.deletedImages = forms.incidentDetails.deletedImages || [];
        data.deletedVideos = forms.incidentDetails.deletedVideos || [];
        data.editId = editId;  
        const response = data.editId ? await post('/EditForm', data) : await post('/SubmitForm', data); 
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'incident-report', 'danger');
            return;
        } 
        if(step==='final') {
            showAlert(response.data.message, 'incident-report-modal', 'success', async () => { 
                history.push('/home');
                /* await getAllIncidentReportForms(); await getIncidentInvestigativeSummaryData(userId); */
            });
        } else if(step==='step1')  { 
            dispatch(actions.change('forms.incidentDetails.incidentReportImages', ''));
            dispatch(actions.change('forms.incidentDetails.incidentReportVideos', ''));
            if(response.data.currentInstance && response.data.currentInstance.length>0) {
                IncidentReportModel.deleteAll();
                const marshalledInstance = await marshallIncidentReportInstance(response.data.currentInstance[0]);   
                new IncidentReportModel(marshalledInstance).$save();
            }
        }  
        return response;   
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'incident-report', 'danger');
        throw error;
    }
}
export async function validateAssociatedFormId(associatedFormId: string) {
    const incidentNotificationInstances = await getAllIncidentNotificationForms();
    let flag = false;
    incidentNotificationInstances.data.forEach((data: { key: string; }) => {
        if (data.key === associatedFormId) {
            flag = true;
        }
    });
    return flag;
}

export function checkInvestigationEditAccess(accessLevel: string) {
    const userInstance = UserModel.getLoggedUserInstance();
    let loggedUserPos = USERLEVELHIERARCHY.indexOf(userInstance['accessLevel']); 
    let formUserPos = USERLEVELHIERARCHY.indexOf(accessLevel);  
    if(loggedUserPos===0 || loggedUserPos===1 )
        return true;
    else if(formUserPos<=loggedUserPos) 
        return false;
    else return true; 
}
 
export async function getIncidentInvestigativeSummaryData(forms?: IForms) {
    const userUID = UserModel.getUserUID();
    const data = { userId: userUID, teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '', month: forms?.commonFilterForm.month || '', }; 
    const response = await post('/GetInvestigativeSummary', data); 
    const respData = response.data.data;
    if (!respData) {
        return;
    }
    let summaryData: any = {
        bodyParts: [],
        locations: [],
        potentialSeverity: {},
        actualSeverity: {},
        followUpsCount: 0,
    };

    const getBodyPart = (index: number) =>
        summaryData.bodyParts[index] = { name: bodyParts[index], value: respData.bodyParts[bodyParts[index]] || 0 };

    const getLocationName = (index: number) =>
        summaryData.locations[index] = { name: locations[index], value: respData.locations[locations[index]] || 0 };

    for (let i = 0; i < bodyParts.length; i++) {
        getBodyPart(i);
    }

    for (let i = 0; i < locations.length; i++) {
        getLocationName(i);
    }
    const potentialSeverity = {
        fat: respData.potentialSeverity['Fatality (FAT)'],
        lti: respData.potentialSeverity['Lost Time (LTI)'],
        mti: respData.potentialSeverity['Medical Treatment (MTI)'],
        snm: respData.potentialSeverity['Serious Near Miss (SNM)'],
        fa: respData.potentialSeverity['First Aid (FA)'],
        nt: respData.potentialSeverity['No Treat (NT)'],
        nm: respData.potentialSeverity['Near Miss (NM)'],
    };

    const actualSeverity = {
        fat: respData.actualSeverity['Fatality (FAT)'],
        lti: respData.actualSeverity['Lost Time (LTI)'],
        mti: respData.actualSeverity['Medical Treatment (MTI)'],
        snm: respData.actualSeverity['Serious Near Miss (SNM)'],
        fa: respData.actualSeverity['First Aid (FA)'],
        nt: respData.actualSeverity['No Treat (NT)'],
        nm: respData.actualSeverity['Near Miss (NM)'],
    };

    summaryData.actualSeverity = actualSeverity;
    summaryData.potentialSeverity = potentialSeverity;

    const otherBodyParts = Object.keys(respData.bodyParts);
    const otherLocations = Object.keys(respData.locations);

    const getOthers = (array: Array<string>, key: string) => {
        let others = 0;
        array.forEach(element => {
            if (bodyParts.indexOf(element) <= -1) {
                others = others + respData.bodyParts[element];
                summaryData[key]['Other'] = { name: 'Other', value: others };
            }
        });
    };
    summaryData.followUpsCount = respData.inCompleteFollowUpsCount;
    getOthers(otherBodyParts, 'bodyParts');
    getOthers(otherLocations, 'locations');
    saveIncidentInvestigativeSummary(summaryData);
}
 
export async function getAllIncidentReportForms(urlData?: any, forms?: IForms, incidentType: string = 'primary') {  
    try {  
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType: 'incidentDetailedReport', pageNumber, sortIndexField, sortingOrder, filterParam1: incidentType, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        if (response.data.status === 'valid') {
            IncidentReportModel.deleteAll();
            response.data.data.forEach(async (incidentReportInstance: IIncidentReportModelProps) => {
                const marshalledInstance = await marshallIncidentReportInstance(incidentReportInstance);   
                new IncidentReportModel(marshalledInstance).$save();
            }); 
            saveListCount(response.data.TotalPages);
        }
    } catch (error) {
        throw error;
    }
}
export async function getInvestigativeForm(uuid: any) { 
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`/GetForms/incidentDetailedReport/${company}/${uuid}`); 
        if (response.data.status === 'valid' && response.data.data !== 'No Data Found') { 
            response.data.data.forEach(async (incidentReportInstance: any) => {
                const marshalledInstance = await marshallIncidentReportInstance(incidentReportInstance);  
                new IncidentReportModel(marshalledInstance).$save();
            });  
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export async function marshallIncidentReportInstance(data: any): Promise<IIncidentReportModelProps> {
    data.id = data.key;  
    //const GetFollowUp = await getCustom(`/GetFollowUp/${UserModel.getCompanyName()}/${data.userId}/incidentDetailedReport/${data.id}`);  
    const follow_ups = data.CompleteActions.concat(data.InCompleteActions);  
    data.totalClosedFollowUp = data.totalFollowUp - data.totalOpenFollowUp; 
    const answerData = new Answers(data.answers); 
    const incidentInvestigation: any[] = []; 
    /* let arrayStart = 62;  
    let arrayEnd = 65; */
    let arrayEnd = 62;
    /* for (let i = 0; i < 100; i++) {
        incidentInvestigation.push({'ActionItem': answerData.get(arrayStart+1), "PartyResponsible": answerData.get(arrayStart+2), "TargetDate": answerData.get(arrayStart+3)});  
        if(data.answers[arrayStart+3]['quesType']=='incidentInvestigationActionItem') {
            arrayStart = arrayStart+3;
            arrayEnd = arrayEnd + 3;
        } else {
            break;
        }
    }  */
    follow_ups.forEach((data: { actionItems: any; Action: any; partyResponsible: any; AssignedToProject: { toString: () => any; }; }) => {
        data.actionItems = data.Action;
        data.partyResponsible = data.AssignedToProject.toString(); 
        incidentInvestigation.push(data);
        //arrayStart = arrayStart+3;
        arrayEnd = arrayEnd + 3;
    }); 
     
    const answers = {
        dateOfIncident: format(new Date(answerData.get(1)), 'MM/dd/yyyy'),
        timeOfIncident: answerData.get(2),
        //dateReported: answerData.get(3),
        dateReported: format(new Date(answerData.get(3)), 'MM/dd/yyyy'),
        timeReported: answerData.get(4),
        dateReturnedToWork: answerData.get(5),
        //dateReportedToAgency: answerData.get(6),
        dateReportedToAgency: format(new Date(answerData.get(6)), 'MM/dd/yyyy'),
        //endorsementDate: answerData.get(7),
        endorsementDate: format(new Date(answerData.get(7)), 'MM/dd/yyyy'),
        //approvalDate: answerData.get(8),
        approvalDate: format(new Date(answerData.get(8)), 'MM/dd/yyyy'),
        //dateAccepted: answerData.get(9),
        dateAccepted: format(new Date(answerData.get(9)), 'MM/dd/yyyy'),
        photosIncluded: answerData.get(10),
        preliminaryReport: answerData.get(11),
        finalReport: answerData.get(12),
        generalContractor: answerData.get(13),
        subContractor: answerData.get(14),
        workRelated: answerData.get(15),
        category: answerData.get(16),
        offShore: answerData.get(17),
        onShore: answerData.get(18),
        incidentSummary: answerData.get(19),
        incidentDetailedDesc: answerData.get(20),
        immediateCorrectiveAction: answerData.get(21),
        jobTask: answerData.get(22),
        location: answerData.get(23),
        equipInvolved: answerData.get(24),
        weather: answerData.get(25),
        lighting: answerData.get(26),
        wind: answerData.get(27),
        seas: answerData.get(28),
        properPPE: answerData.get(29),
        selfSupervised: answerData.get(30),
        incidentWitnessed: answerData.get(31), 
        name: answerData.get(32),
        employee: answerData.get(33),
        contractor: answerData.get(34),
        drugTestConducted: answerData.get(35),
        company: answerData.get(36),
        jobPosition: answerData.get(37),
        timeOnJob: answerData.get(38),
        natureOfInjury: answerData.get(39),
        bodyPart: answerData.get(40),
        medicalResponseTaken: answerData.get(41),
        statusUpdate: answerData.get(42),
        eventLeadingToInjury: answerData.get(43),
        actualDaysLost: answerData.get(44),
        firstAidTreatment: answerData.get(45),
        estimatedDaysLost: answerData.get(46),
        actualSeverity: answerData.get(47),
        potentialSeverity: answerData.get(48),
        securityCategory: answerData.get(49),
        methodOfBreach: answerData.get(50),
        agencyNotified: answerData.get(51),
        agencyName: answerData.get(52),
        reportedToAgencyBy: answerData.get(53),
        notifiedAtAgency: answerData.get(54),
        costIncurred: answerData.get(55),
        totalCost: answerData.get(56),
        emOwnershipPercentage: answerData.get(57),
        costType: answerData.get(58),
        costCategory: answerData.get(59),
        mngmtReportRequired: answerData.get(60),
        directCause: answerData.get(61),
        rootCause: answerData.get(62),
        incidentActivity: answerData.get(63),
        incidentConditions: answerData.get(64),
        workDirection: answerData.get(65),
        concernType: answerData.get(66),
        isFinalSubmit: answerData.get(67),
        formSerialNo: answerData.get(data.answers.length), 
       /*  isFinalSubmit: answerData.get(data.answers.length), */
       /*   Final Change 
       incidentActivity: answerData.get(arrayEnd + 1),
        incidentConditions: answerData.get(arrayEnd + 2),
        workDirection: answerData.get(arrayEnd + 3),
        concernType: answerData.get(arrayEnd + 5 ),
        formSerialNo: answerData.get(data.answers.length ), */

       /*  incidentActivity: answerData.get(data.answers.length - 4),
        incidentConditions: answerData.get(data.answers.length - 3),
        workDirection: answerData.get(data.answers.length - 2),
        concernType: answerData.get(data.answers.length ), */
        incidentInvestigation: incidentInvestigation,
        incidentInvestigationFollowps: follow_ups
    }; 
    Object.assign(data, answers);
    delete data.answers;
    return data;
}

export function getInvestigativeFormObjects(incidentDetails: IIncidentReportModelProps, index: number) {
    const { endorsementDate, generalContractor, groupName, projectName, subContractor, category, incidentSummary, incidentDetailedDesc, dateOfIncident, timeOfIncident, dateReported, timeReported, immediateCorrectiveAction, jobTask, location, equipInvolved, weather, lighting, wind, seas, name, company, jobPosition, timeOnJob, natureOfInjury, bodyPart, medicalResponseTaken, statusUpdate, eventLeadingToInjury, actualDaysLost, firstAidTreatment, estimatedDaysLost, actualSeverity, potentialSeverity, securityCategory, methodOfBreach, agencyName, reportedToAgencyBy, notifiedAtAgency, costType, costCategory, directCause, rootCause, incidentInvestigation, incidentActivity, incidentConditions, workDirection, supervisorName, groupManagerName, acceptorName,  associatedFormId, approvalDate, dateAccepted } = incidentDetails;

    const generalInformationForm = {
        dateOfIncident, timeOfIncident, dateReported, timeReported,
        associatedFormId, generalContractor, subContractor, category, projectName, groupName,
    };

    const incidentDetail = {
        eventLeadingToInjury, incidentSummary, incidentDetailedDesc, immediateCorrectiveAction,
        jobTask, location, equipInvolved, weather, lighting, wind, seas,
    };

    const injuryOrIllnessForm = {
        name, company, jobPosition, timeOnJob, natureOfInjury, bodyPart,
        medicalResponseTaken, statusUpdate, actualDaysLost, firstAidTreatment, estimatedDaysLost,
    };

    const injurySeverity = {
        actualSeverity,
        potentialSeverity
    };

    const security = {
        securityCategory, methodOfBreach, agencyName, reportedToAgencyBy, notifiedAtAgency, costType, costCategory
    };

    const investigativeSummary = {
        directCause, rootCause,
        [`incidentInvestigation[0].actionItems`]:
            incidentInvestigation.map((data, index) => data.actionItems),
        [`incidentInvestigation[0].partyResponsible`]: incidentInvestigation.map(data => data.partyResponsible),
        [`incidentInvestigation[0].targetDate`]: incidentInvestigation.map(data => data.targetDate),
        incidentActivity, incidentConditions, workDirection
    };

    const endorsement = {
        supervisorName,
        endorsementDate
    };

    const approval = {
        groupManagerName,
        approvalDate
    };
    const completed = {
        acceptorName,
        dateAccepted
    };

    const arrayObj = [generalInformationForm, incidentDetail, injuryOrIllnessForm, injurySeverity,
        security, investigativeSummary, endorsement, approval, completed];
    return arrayObj[index];
}

export async function getAllFollowUpForm(userUID: string, investigativeId?: string) {
    try { 
        if(!investigativeId)
            await deleteAllInstances();  
        const response = await getCustom(`/GetFollowUp/${UserModel.getCompanyName()}/${userUID}/incidentDetailedReport/${investigativeId || 'All'}`); 
        (response.data.data ? response.data.data.InCompleteActions : []).forEach((element: any) => {
            element.id = element.UUID; 
            const targetDate = format(new Date(element.TargetDate), 'MM/dd/yyyy');
            element.TargetDate = targetDate;
            element.ActualCompletedDate = element.ActualCompleteDate && format(new Date(element.ActualCompleteDate), 'MM/dd/yyyy');
            new FollowUpModel(element).$saveInCompleteFollowUp('incident-investigative');
        });
        (response.data.data ? response.data.data.CompleteActions : []).forEach((element: any) => {
            const targetDate = format(new Date(element.TargetDate), 'MM/dd/yyyy');
            const submitDate = format(new Date(element.DateSubmitted), 'MM/dd/yyyy');
            element.ActualCompletedDate = format(new Date(element.ActualCompleteDate), 'MM/dd/yyyy');
            element.TargetDate = targetDate;
            element.DateSubmitted = submitDate;
            element.id = element.UUID;
            new FollowUpModel(element).$saveCompleteFollowUp('incident-investigative');
        });
    } catch (error) {
        throw error;
    }
}
export async function updateFollowUpAdditionalDet(data: Object | undefined) {
    try { 
        const response = await putCustom('/updateFollowUpAdditionalDet', data);
        if (response.data.error) {
            showAlert(FORM_SUBMISSION_ERROR, 'follow-up-failed', 'danger');
            return true;
        }
        showAlert('Follow_up updated', 'follow-up-success', 'success' ); 
        return true;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'follow-up-failed', 'danger');
        throw error; 
    }

}
export async function updateFollowUp(data: { printedName: any; actualDate: any; comments: any; }, file: string | File, userId: string, formId: string, history: IHistory, files: File[], fileNames: string[], investigativeId: string, forms: IForms) { 
    let signatureData: any = []; 
    if(file) {
        let signature = getSignatureData(file, 'signatureUrl')
        signatureData.push(signature);
    }
   /*  const url = 'https://s3-us-west-2.amazonaws.com/safeconnect-beta/signatures/';
    let signatureUrl = '';
    let fileInfo:Array<any> = [];
    try {
        if (file instanceof File) {
            const name = new Date().getTime().toString();
            const fileExtension = file.name.split('.')[1];
            await uploadFile(file, name, 'signatures');
            signatureUrl = `${url}${name}.${fileExtension}`;
        } else {
            signatureUrl = file;
        }
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'follow-up-failed', 'danger');
        throw error;
    } */
    /* try { 
        if(files.length>0) {
            for (let i = 0; i < files.length; i++) {
                let name = files[i].name.split('.')[0] + '~~' + files[i].lastModified;
                await uploadFile(files[i], name, 'follow_ups'); 
            } 
        } 
        if(fileNames.length>0) {
            for (let i = 0; i < fileNames.length; i++) {
                fileInfo.push( `${S3_URL}/follow_ups/${fileNames[i]}` ); 
            }
        } 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'closecall-followup-failed', 'danger');
        throw error;
    } */
    try { 
        const company = UserModel.getCompanyName();
        const body = {
            followUpId: formId,
            userId,
            printedName: data.printedName,
            actualDate: data.actualDate,
            comments: data.comments,
            signatureData, 
            /* files: fileInfo, */
            company: company, attachments: forms.followUpForm.attachments
        }; 
        const response = await putCustom('/UpdateFollowUp', body);
        if (response.data.error) {
            showAlert(FORM_SUBMISSION_ERROR, 'follow-up-failed', 'danger');
            return;
        }
        showAlert('Follow-up completed', 'follow-up-success', 'success', () => { });
        removeInstance(`incident-investigative-incomplete-${formId}`);
        //await getAllFollowUpForm(userId);
        hideFollowUpFormModal();
        investigativeId? history.push(`/incident-investigative-reports/follow-up/${investigativeId}`): history.push('/incident-investigative-reports/follow-up');
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'follow-up-failed', 'danger');
        throw error;
    }
}

/* export function base64ToFile(dataURL: RequestInfo, fileName: string) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    return (fetch(dataURL)
        .then(function (result) {
            return result.arrayBuffer();
        })).then(function (buffer) {
            return new File([buffer], fileName, { type: mime });
        });
} */
