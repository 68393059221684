import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { showDelConfirmModal } from '../../actions/modalActions';
import { UserModel } from '../../model/UserModel';
import { connect } from 'react-redux'; 
import { getPageItemNumber, renderCovid19Result } from '../../utils/generalUtils';    
import { Covid19Model } from '../../model/Covid19Model';
const MenuItem = Dropdown.Item;

export interface IListItemProps { 
    history?: IHistory;
    index?: number;
    instance?: Covid19Model;
    style?: React.CSSProperties; 
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    onDeleteAttribute() {
        const { instance } = this.props;  
        instance && showDelConfirmModal('delete-confirmation', 'covid19 form', instance.props);  
    } 
    onViewAttribute(instance: Covid19Model) { 
        if(!UserModel.checkUserAccess('covid19_view') )
            return; 
        this.props.history?.push(`/covid19-report/${instance.props.id}`);
    }
    renderFormList = () => {
        const { instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}
            >
                <Col sm={1}>
                        { ( UserModel.checkUserAccess('covid19_view') || UserModel.checkUserAccess('covid19_edit') || UserModel.checkUserAccess('covid19_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                            { UserModel.checkUserAccess('covid19_view') && <MenuItem eventKey="1"
                                onClick={() => this.onViewAttribute(instance)}
                                className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                             
                            { UserModel.checkUserAccess('covid19_delete__dev') && <MenuItem eventKey="3"
                                onClick={() => this.onDeleteAttribute()}
                                className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                        </DropdownButton> }
                    {getPageItemNumber(index || 0, this.props.currentPage) }
                </Col>
                <Cell sm={4} onClick={() => this.onViewAttribute(instance) }>{instance.props.date || 'NA'}</Cell>
                <Cell sm={4} onClick={() => this.onViewAttribute(instance) }>{instance.props.userName || 'NA'}</Cell> 
                <Col style={{textAlign:'center'}} className="clickable-list-item" sm={3} onClick={() => this.onViewAttribute(instance) }>{renderCovid19Result(instance.props.formResult ) || 'NA'}
                </Col> 
                
            </Row >
        );
    } 
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {  
    return { 
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
