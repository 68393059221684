import { actions } from 'react-redux-form';
import { JSAForm } from './JSAForm';
import { dispatch } from '../../utils/generalUtils';
import { } from 'material-ui/Table';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { Title } from '../reusableComponents/Title';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { submitJSAForm } from '../../services/jsaFormService';
import { withRouter } from 'react-router-dom';
import { IHistory, IAnswer, IForms } from '../../interfaces';
import { JSAModel } from '../../model/JSAModel';
import './jsaForm.scss';

export interface ICreateFormProps {
    userId: string;
    history?: IHistory;
    forms: IForms;
    editId: string;
    editInstance: any;
}

export interface ICreateFormState {
    currentDate: string;
    noOfRows: number[];
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, ICreateFormState> {
    constructor(props: ICreateFormProps | Readonly<ICreateFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'),
            noOfRows: [1]
        };
    }

    componentWillMount() {
        dispatch(actions.reset('forms.jsaForm'));
    }

    handleSubmit = (userId: string, date: string, answerData: IAnswer[],
        group: string, project: string) => {
        const { history, forms, editId, editInstance } = this.props;
        return history && submitJSAForm(userId, date, answerData, history, group, project, forms, editId, editInstance);
    }

    render() {
        const { history, editId, editInstance } = this.props;
        if(editId && !editInstance) {
            history?.push('/jsa-reports');
        }
        return (
            <BasePage className="safeconnect-form">
                <Grid style={{ width: '100%', padding: '20px' }} className="jsa-form" >
                    <Title titleColor="yellow" text="JSA Form" noNeedBorder={true}/>
                    <AlertModal id="jsa-form-modal" />
                    <JSAForm
                        onSubmit={this.handleSubmit} forms
                    />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        forms: state.forms,
        userId: state.login.get('userId'),
        editId: ownProps.match.params.id,
        editInstance: JSAModel.get(ownProps.match.params.id)
    };
}

export const CreateForm = withRouter(connect<ICreateFormProps, any, any>(mapStateToProps)(CreateFormImpl));
