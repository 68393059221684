import { BaseModel } from './BaseModel';

export interface IHPModelProps {
    id?: string; 
    name?: string;
    dateOfIncident?: string;
    date: string;
    mobileLocation: string; 
    location?: string;
    locationName?: string;
    specifiedLocation?: string; 
    groupName?: string;
    projectName?: string;
    userId: string;
    formId: string; 
    editId?: string; 
    username?: string; 
    comments?: string;
    fitandable: string;
    planandexecute: string;
    teamcapable: string;
    noexternaldistractions: string;
    totalScore?: string;
    overallResult?: string;
    answers?: any;
}

export class HPModel extends BaseModel<IHPModelProps> {
    constructor(props: IHPModelProps) {
        super(props, HPModel.resource);
    }
    static resource = 'hp-form';
}
