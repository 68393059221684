import { getCustom, post, postCustom } from '../utils/HTTP';
import { convertToDateFormat, showAlert } from '../utils/generalUtils';
import { JSAModel, IJSAModelProps } from '../model/JSAModel';
import { IAnswer, IHistory, IForms } from '../interfaces';
import { UserModel } from '../model/UserModel';
import { FORM_SUBMISSION_ERROR } from '../constants/general';  
import { getSignatureData } from '../utils/generalUtils';
import { format } from 'date-fns';
import { saveListCount } from '../actions/miscellaneousActions'; 
import { getNotifications } from './profileService';

export async function submitJSAForm(userId: string, date: string, answers: IAnswer[], history: IHistory, group: string, project: string, forms: IForms, editId?: string, editInstance?: any) {  
    let appUsers: any = forms.jsaForm.appUsers || [];
    let signatureData: any = []; 
    if(forms.jsaForm.jsaSignature) {
        let signature = getSignatureData(forms.jsaForm.jsaSignature, 'signatureUrl')
        signatureData.push(signature);
    }
    let data: any = {
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        groupName: group,
        projectName: project,
        formId: 'jsa',
        followUp: 0,
        actionType: 'submit',
        associatedFormId: '',
        answers,
        /* imageUrls: [],
        videoUrls: [], */
        signatureUrl: '', 
        deletedImages: [],
        deletedVideos: [],
        editId: '',
        appUsers: [],
        deletedAppUsers: [],
        //appUsers: appUsers && (Array.isArray(appUsers)?appUsers: (appUsers.split(',') || []) ) ,
        /* nonAppUsers: forms.jsaForm.nonAppUsers || [], 
        nonAppUsersEmail: forms.jsaForm.nonAppUsersEmail || [], */
        nonAppUsers: forms.jsaForm.nonAppUsers,
        deletedNonAppUsers: forms.jsaForm.deletedNonAppUsers,
        acknowledgement: forms.jsaForm.acknowledgement, 
        imageData: forms.jsaForm.jsaImages || [],
        videoData: forms.jsaForm.jsaVideos || [],
        attachments: forms.jsaForm.ackAttach || [],
        signatureData: signatureData || [],
        approval: forms.jsaForm.approval || '', 
    };
    if(appUsers) {
        if(!Array.isArray(appUsers)) {
            appUsers = appUsers.split(',') 
        } 
        for (let i = 0; i < appUsers.length; i++) { 
           if(!editInstance || !editInstance.props.appUsers.includes(appUsers[i])) { 
               data.appUsers.push(appUsers[i]);
           }
        } 
    } 
    if(editInstance) {
        for (let i = 0; i < editInstance.props.appUsers.length; i++) { 
            if(appUsers.length<1 || !appUsers.includes(editInstance.props.appUsers[i])) { 
                data.deletedAppUsers.push(editInstance.props.appUsers[i]);
            }
        }
    }  
    try { 
        data.deletedImages = forms.jsaForm.deletedImages || [];
        data.deletedVideos = forms.jsaForm.deletedVideos || []; 
        data.deletedFiles = forms.jsaForm.deletedFiles || [];
        data.editId = editId;  
        data.dateOfAssessment = data.date && convertToDateFormat(data.date);
        const response = data.editId ? await post('/EditForm', data) : await post('/SubmitForm', data); 
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'jsa-form', 'danger');
            return;
        }  
        getNotifications(UserModel.getUserUID(), UserModel.getCompanyName());
        showAlert(response.data.message, 'jsa-form-modal', 'success', async () => { 
            history.push('/home');
            //await getAllJSAForm(); 
        });
        return response; 

    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'jsa-form', 'danger');
        throw error;
    }
}

export async function getAllJSAForm(urlData?: any, forms?: IForms) { 
    try {
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType: 'jsa', pageNumber, sortIndexField, sortingOrder, searchItem, }; 
        const response = await post('/GetForms', data, axiosPreviousToken);
        if (response.data.status === 'valid') { 
            JSAModel.deleteAll();
            response.data.data.forEach((instance: any) => { 
                const jsaInstance: any = marshallJSAFormInstance(instance);  
                new JSAModel(jsaInstance).$save();
            });
            saveListCount(response.data.TotalPages);
        }
    } catch (error) {
        throw error;
    }
}

export function marshallJSAFormInstance(data: any) {
    data.id = data.key;    
    const jobStep: any = [];
    const jobSafetyStepData:Array<any> = [];
    const appUsers: any = [];
    const nonAppUsers: any = []; 
    let appUsersList: any = [];
    let nonAppUsersList: any = [];
    data.answers.forEach((answerData: any, index: any) => {
        const key = Object.keys(answerData);
        if (answerData[key[0]] > 0 && (answerData[key[1]] === 'step' || answerData[key[1]] === 'stepDesc' || answerData[key[1]] === 'hazards' || answerData[key[1]] === 'controls')) {
            jobStep.push({
                [answerData[key[1]]]: answerData[key[2]]
            });
            /* if (answerData[key[1]] === answerData[key[1]]) {
                jobStep.push({
                    [answerData[key[1]]]: answerData[key[2]]
                });
            } */
        } /* else if(answerData[key[1]]=='appUser') {
            appUsers.push(answerData[key[2]])
        } else if(answerData[key[1]]=='nonAppUser') {
            nonAppUsers.push(answerData[key[2]])
        } */ 
        else {
            data[answerData[key[1]]] = answerData[key[2]];
        } 
    });  
    for (let i = 0; i < jobStep.length; i = i + 4) {
        jobSafetyStepData.push({
            controls: jobStep[i].controls,
            hazards: jobStep[i + 1].hazards,
            step: jobStep[i + 2].step,
            stepDesc: jobStep[i + 3].stepDesc
        });
    }
    data.appUsers.forEach((ans: any, index: any) => {
        appUsers.push(ans.User);
        appUsersList.push({
            uniqueId: ans.UniqueId,
            user: ans.User,
            readStatus: ans.readStatus,
            userEmail: '',
            userName: ans.userName
        });
    });
    data.nonAppUsers.forEach((ans: any, index: any) => {
        nonAppUsers.push(ans.User);
        nonAppUsersList.push({
            uniqueId: ans.UniqueId,
            user: ans.User,
            readStatus: ans.readStatus,
            userEmail: ans.userEmail,
            userName: ans.userName
        });
    });
    data.appUsers = appUsers;
    data.nonAppUsers = nonAppUsers; 
    data.appUsersList = appUsersList;
    data.nonAppUsersList = nonAppUsersList;
    data.answers = jobSafetyStepData;
    data.ackAttachUrls = data.attachmentUrls;
    return data;
}

export function marshallJSAFormInstance__OLD(data: any) {
    data.id = data.key;
    const jobStep: any = [];
    const jobSafetyStepData:Array<any> = [];
    /* data.answers.forEach((answerData: any, index: any) => {
        const key = Object.keys(answerData);
        if (answerData[key[1]] > 0) {
            if (answerData[key[1]] === answerData[key[1]]) {
                jobStep.push({
                    [answerData[key[2]]]: answerData[key[0]]
                });
            }
        } else {
            data[answerData[key[2]]] = answerData[key[0]];
        }
    }); */
    for (let i = 0; i < jobStep.length; i = i + 4) {
        jobSafetyStepData.push({
            controls: jobStep[i].controls,
            hazards: jobStep[i + 1].hazards,
            step: jobStep[i + 2].step,
            stepDesc: jobStep[i + 3].stepDesc
        });
    }
    data.answers = jobSafetyStepData;
    return data;
}

export function getJSAFormValues(values: IJSAModelProps) {
    /* const { companyName, typeOfWork, location, workToPerform, date, jsaNum, projectName, groupName, stepValues } = values; */
    return {
        companyName: values.companyName,
        typeOfWork: values.typeOfWork,
        locationName: values.location,
        workToPerform: values.workToPerform,
        date: values.date,
        jsaNum: values.jsaNum,
        projectName: values.projectName,
        groupName: values.groupName/* ,
        [`stepDescription[0]`]: stepValues.map((data, index) => data.stepDesc),
        [`hazardsRisk[0]`]: stepValues.map((data, index) => data.hazards),
        [`controls[0]`]: stepValues.map((data, index) => data.controls), */
    };
}
export async function changeJSAReadStatus(formId: string, company: string = '') {
    company = company || UserModel.getCompanyName();  
    const data: any = {formId, company}
    const response = await postCustom('/ChangeJSAReadStatus', data);
    return response;
}   

export async function getFormData(formType: string, formId: string, company: string = '') { 
    company = company || UserModel.getCompanyName(); 
    try {
        const response = await getCustom(`GetForms/${formType}/${company}/${formId}`); 
        if (response.data.status === 'valid' && response.data.data.length) {
            response.data.data.forEach((instance: any) => { 
                const jsaInstance: any = marshallJSAFormInstance(instance);  
                new JSAModel(jsaInstance).$save();
            });
        }
    } catch (e) {
        throw e
    } 
}

export async function GetAllJSAForms() {
    let company = UserModel.getCompanyName(); 
    try {
        const response = await getCustom(`GetAllJSAForms/${company}`);  
        if (response.data.status === 'valid' && response.data.data.length) {
            response.data.data.forEach((instance: any) => { 
                let data: any = {key: '', id: '', jsaNum: ''};
                data.key = instance.key;
                data.id = instance.key;
                data.jsaNum = instance.jsaNum; 
                data.title = instance.title; 
                new JSAModel(data).$save();
            });
        }
    } catch (e) {
        throw e
    } 
}
