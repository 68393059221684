import { FORM_SUBMISSION_ERROR } from '../constants/general';
import { IHistory } from '../interfaces';
import { UserModel } from '../model/UserModel';
import { getSignatureData, showAlert } from '../utils/generalUtils';
import { put, putCustom, getCustom } from '../utils/HTTP';
import { updateUserData } from './userService';
import { saveNotifications } from '../actions/summaryActions';
import { format } from 'date-fns'; 

export async function updateUserProfile(submittedValues: { groups: string; email: any; firstName: any; password: any; jobDesc: any; jobTitle: any; lastName: any; middleName: any; userId: string; accessLevel: any; signatureUrl: any, originalEmail: any }, history: IHistory, phoneNumber: any, signature: string | File) {
    const groupNames = submittedValues.groups ? submittedValues.groups.split(',') : [];
    const data = {
        email: submittedValues.email,
        originalEmail: submittedValues.originalEmail,
        firstName: submittedValues.firstName,
        password: submittedValues.password,
        groups: (groupNames.length > 0 ? groupNames : []),
        jobDesc: submittedValues.jobDesc,
        jobTitle: submittedValues.jobTitle,
        lastName: submittedValues.lastName,
        middleName: submittedValues.middleName || '',
        userId: submittedValues.userId,
        phoneNumber: phoneNumber || '',
        accessLevel: submittedValues.accessLevel,
        signatureUrl: submittedValues.signatureUrl || '',
        dateCreated: format(new Date(), 'yyyy-MM-dd'),
        company: UserModel.getCompanyName(), signatureData: (signature && getSignatureData(signature, 'signatures') ) || null,
    };
    try { 
        const response = await putCustom('/CreateUser', data);  
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'profile', 'danger');
            return;
        }
        updateUserData(submittedValues.userId);
        showAlert(response.data.message, 'profile-modal', 'success',
            () => { history.push('/home'); });
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'profile', 'danger');
        throw error;
    }
}

export async function getNotifications(userId: string, companyName: string) {
    try {
        const response = await getCustom(`/Notifications/${UserModel.getCompanyName()}/${userId}/long`);
        saveNotifications(response.data.data, response.data.totalFollowupOpen);
    } catch (error) {
        throw error;
    }
}

export async function setNotificationsSeen(uniqueId: string, userUID: string, companyName: string) {
    try {
        await put('/Notifications', {
            notifications: [{ 
                uniqueId: uniqueId,
                seen: true
            }]
        });
        await getNotifications(userUID, companyName);
    } catch (error) {
        throw error;
    }
}
