import * as React from 'react';
import PropTypes from 'prop-types'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader'; 
import { connect } from 'react-redux';
import { Col, Row, DropdownButton, Dropdown, Modal } from 'react-bootstrap'; 
import { hideCloseCallFollowUpListModal,showCreateCloseCallFollowUpModal } from '../../actions/modalActions';
import { IHistory } from '../../interfaces';
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Async } from '../reusableComponents/Async';
import { Loader } from '../reusableComponents/Loader'; 
import { UserModel } from '../../model/UserModel';
import { format } from 'date-fns';  
import { followUpListProps } from '../../model/MechModel';  
import { showDelConfirmModal } from '../../actions/modalActions';
import { substring } from '../../utils/generalUtils';
const MenuItem = Dropdown.Item;

export interface ICloseCallFollowUpListModalProps {
    history?: IHistory;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    showModal: any;
    userId: string;
    userUID: string;  
    followUpInstances: followUpListProps[]; 
    followUpParentDetails?: any;
    userInstance?: any;
    
} 

export interface ICloseCallFollowUpListModalState {
    uploadedFiles: File[];
    uploadedFileNames: string[];
    editFileNameIndex?: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[]; 
    showLoader?: boolean;
     
} 
export class CloseCallFollowUpListModalImpl extends
    React.Component<ICloseCallFollowUpListModalProps, ICloseCallFollowUpListModalState> {
    constructor(props: ICloseCallFollowUpListModalProps | Readonly<ICloseCallFollowUpListModalProps>) {
        super(props);
        this.state = { uploadedFiles: [], uploadedFileNames: [], showLoader: true  };
    }  
    renameInputRef: any;  

    getChildContext() {
        return { formModel: 'forms.closeCallFollowUpForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    promise = async () => {
        return;
    }
    renderFormList = () => {  
        return (
            <div className="sw-list-wrapper">
                <div style={{ padding: '0px'}} className="list-page">
                    {/* <Title style={titleStyle || {}} text="Close Call Reports"></Title> */}
                    <div className="reports-table">
                        <div style={{ /* margin: '10px 100px 0px 110px' */ }}>
                            <Row className="heading report-row">
                                <Col sm={1}>#</Col>
                                <Col sm={3}>Action</Col>
                                <Col sm={3}>Assigned To</Col>
                                <Col sm={3}>Target Date</Col> 
                                <Col sm={2}>Status</Col>
                            </Row >
                        </div>
                        <div style={{ textAlign: 'center', fontSize: '15px', /* margin: '10px 100px 0px 110px', */ color: 'White' }}>
                            {this.renderListItems()}
                        </div>
                    </div>
                </div > 
            </div>
        );
    }
    renderAddNewFormButton = () => {  
        let instance = this.props.showModal; 
        /* instance['closeCallId'] = instance['instance']['closeCallId']; 
        instance['process'] = 'add';  */
        
        let modalInstance: any = []; 
        modalInstance['closeCallId'] = instance['instance']['closeCallId']; 
        modalInstance['process'] = 'add';
        modalInstance['followUpFor'] = instance['instance']['followUpFor']
        return (<button className="add-new-report" onClick={() =>  this.followUpFormModal(modalInstance)} > + </button> );
    }
    followUpEditFormModal = (followUpInstance: any) => { 
        let instance = this.props.showModal;  
        let modalInstance = followUpInstance; 
        modalInstance['closeCallId'] = instance['instance']['closeCallId']; 
        modalInstance['process'] = 'edit';
        modalInstance['followUpFor'] = instance['instance']['followUpFor'];
        this.followUpFormModal(modalInstance)
    }
    followUpFormModal = (instance: any) => {  
        hideCloseCallFollowUpListModal();
        showCreateCloseCallFollowUpModal(instance);
    }  
    onDeleteAttribute(instance: any) {  
        instance['delFormType'] = 'follow_up'; 
        instance['followUpFor'] = this.props.showModal['instance']['followUpFor'];
        showDelConfirmModal('delete-confirmation', 'Follow_up Form', instance);  
    }
    renderListItems = () => {
        const { followUpInstances } = this.props;  
        if(followUpInstances[0]===null) { 
            return  ( <SubmissionLoader /> );
        }
        if (!followUpInstances || followUpInstances.length <= 0) {
            return (
                <div style={{ textAlign: 'center', margin: '26px', color: 'white' }}>No records found.</div>
            );
        }  
       
        let style: React.CSSProperties = {
            borderBottom: 'none'
        };  
        let actions: React.CSSProperties = {
            width: '20px'
        };  
        return followUpInstances.map((instance: any, index: any) => { 
                instance['process'] = 'edit'; 
                return (
                <span key={index} >
                    <Row className="report-row" style={style}>
                        <Col className="clickable-list-item" sm={1} > 
                            { UserModel.checkUserAccess('follow_up_delete') && <DropdownButton title="" style={actions} id="bg-vertical-dropdown-3"> 
                                <MenuItem eventKey="3"
                                    onClick={() => this.onDeleteAttribute(instance)}
                                    className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem>
                            </DropdownButton> } <span className="serial-no l50">{index + 1}</span>
                        </Col>
                        <Col className="clickable-list-item"
                            onClick={() =>  this.followUpEditFormModal(instance)}
                            sm={3}
                        >
                            { (instance.action && substring(instance.action,35) )|| 'NA'}
                        </Col>
                        <Col className="clickable-list-item"
                            onClick={() =>  this.followUpEditFormModal(instance)}
                            sm={3}
                        >
                            {instance.assignedToName || 'NA'}
                        </Col>
                        <Col className="clickable-list-item"
                            onClick={() =>  this.followUpEditFormModal(instance)}
                            sm={3}
                        >
                            { format( new Date(instance.targetCompletion), 'MM/dd/yyyy')  || 'NA' }
                        </Col> 
                        <Col className="clickable-list-item closeCall" sm={2} onClick={() =>  this.followUpEditFormModal(instance)}
                        > 
                            { instance['status'] && instance['status']==='Open'?<Button className="close-call-followup-open" style={followUpOpen}>Open</Button>:instance['status']==='Closed'?<Button className="close-call-followup-closed" style={followUpClosed}>Closed</Button> : <Button className="close-call-followup-warning" style={followUpWarning}>Follow up</Button> } 
                        </Col> 
                    </Row>
                </span>
            );
        });
    }
    
    render() { 
        let instance = this.props.showModal;  
        if(!instance) return null; 
        return (
            <div>
                <Async
                    identifier="CloseCallFollowUpList"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<div></div>}
                    content={
                        <Modal dialogClassName="followup-list-modal model-50w"  
                            show={instance ? true : false} 
                            onHide={() => hideCloseCallFollowUpListModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title> { /* followUpTitle(instance['instance']['formType']) */ } Follow-up List</Modal.Title>
                                { UserModel.checkUserAccess('follow_up_add') && instance['instance']['followUpFor']['formId']!=='riskAssessmentForm' ? this.renderAddNewFormButton() : null }
                            </Modal.Header>
                            <Modal.Body>  
                                {this.renderFormList()}
                            </Modal.Body>
                        </Modal>
                    }
                />
            </div>
        );
    }
}
const followUpOpen: React.CSSProperties = {
    color:'white',
    borderColor: 'red',
    backgroundColor: 'red'
 };
 const followUpWarning: React.CSSProperties = {
    color:'white',
    borderColor: '#17a2b8',
    backgroundColor: '#17a2b8'
 };
 const followUpClosed: React.CSSProperties = {
    color:'white',
    borderColor: 'green',
    backgroundColor: 'green'
 };

export function mapStateToProps(state: any) {
    return {
        showModal: state.modal.get('showCloseCallFollowupListModal'),
        userId: state.login.get('userId'),
        userUID: state.login.get('userUID'), 
        followUpInstances: state.report.get('modalReportDataList'), 
        //followUpParentDetails: state.report.get('reportDetailData'),
        userInstance : UserModel.get(state.login.get('userId'))
    };
}

export const CloseCallFollowUpListModal = connect<ICloseCallFollowUpListModalProps, any, any>(mapStateToProps)(CloseCallFollowUpListModalImpl);
 