import * as React from 'react';
import { Col, Row, Container as Grid, Modal } from 'react-bootstrap';  
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { IHistory, IAttribute, IForms } from '../../interfaces';
import { hideChecklistProcessModal } from '../../actions/modalActions';  
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { Title } from '../reusableComponents/Title';  
import { EMPTY_FORM_MESSAGE} from '../../constants/general'; 
import {Async} from '../reusableComponents/Async';
import {addAttribute,  updateAttribute} from '../../services/attributeService'; 
import { dispatch, isFormEmpty, showAlert, isArrayEmptyAndAlert } from '../../utils/generalUtils'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader'; 
import { Alert } from '../Alert';

export interface IChecklistAttributeModalProps { 
    onSubmit?: (data: Object) => void;
    onCancel?: () => void; 
    id?: string; 
    showChecklistAttributeModal: boolean;
    modalID: string;
    instance: any;
    history?: IHistory; 
    onUpdate?: (attributeData: any) => void; 
    forms: IForms;
}

export interface IChecklistAttributeModalState {
    showChecklistAttributeModal: boolean;
    subAttribute: IAttribute[];
}

export class ChecklistAttributeModalImpl extends React.PureComponent<IChecklistAttributeModalProps, IChecklistAttributeModalState> {
    constructor(props: IChecklistAttributeModalProps | Readonly<IChecklistAttributeModalProps>) {
        super(props);
        //let subAttributeArray: Array<IAttribute> = [{name: '', count: 0}];
        this.state = { showChecklistAttributeModal: true, subAttribute: [] };  
    } 
    getChildContext() {
        return { formModel: 'forms.attributeModalForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    identifier = 'ChecklistAttributeForm';   
    componentWillReceiveProps() {
        const { instance } = this.props;
        let subAttributeArray: any= [];
        if(!this.state.subAttribute.length && this.props.showChecklistAttributeModal ) {
            if(instance && instance.instance.subAttributeList.length) {
                let length = instance.instance.subAttributeList.length; 
                for (let index = 0; index < length; index++) {
                    subAttributeArray.push({name: '', count: index});
                }
            } else {
                subAttributeArray = [{name: '', count: 0}];
            }
            this.setState({subAttribute: subAttributeArray});
        } else {
            subAttributeArray = [{name: '', count: 0}];
        } 
    }
    hideChecklistProcessModal() {
        this.setState({subAttribute: []});
        hideChecklistProcessModal();
    } 
    incrementRows = () => {
        const attributeCount = this.state.subAttribute.length;
        this.setState({
            subAttribute: [...this.state.subAttribute, { count: attributeCount, name: '' }]
        });  
    }
    decrementRow = (index: number) => {
        const { subAttribute } = this.state; 
        dispatch(actions.remove(`forms.attributeModalForm.name`, index));
        let newArray = subAttribute.filter(e => e !== subAttribute[index]); 
        this.setState({ subAttribute: newArray });
        return;
    }
    handleSubmit = async (submittedValues: { mainAttribute: string; name: { [x: string]: any; }; }) => {  
        const { instance} = this.props;
        let editInstance = instance && instance.instance;
        const validateValues = {mainAttribute:submittedValues.mainAttribute}
        if (isFormEmpty(validateValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        }
        if(isArrayEmptyAndAlert( {subAttribute: submittedValues.name || [] } )) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        } 
        let subAttribute: any[] = []; 
        Object.keys(submittedValues.name).forEach(function (index) { 
            subAttribute.push(submittedValues.name[index]  )
        });
         
        let attributeParam: any = { formType: '', mainAttr: '', subAttr: [], oldAttr:'' };
        attributeParam.formType = editInstance.checklistType;
        attributeParam.mainAttr = submittedValues.mainAttribute;
        attributeParam.oldAttr = (editInstance && editInstance.attribute) || '';
        attributeParam.subAttr = subAttribute;
        if ((editInstance && editInstance.attribute) ) {
            await updateAttribute(attributeParam); 
        } else {
            await addAttribute(attributeParam);
        }
        this.props.onUpdate && this.props.onUpdate(attributeParam);
        this.hideChecklistProcessModal();
    }
    showModal = () => {
        const {props: {showChecklistAttributeModal}} = this;  
        if (!showChecklistAttributeModal) {
            return <div></div>;
        }
        /* 
        if (!instance) {
            return <div></div>; 
        } */
        return (
            <Modal className="confirmation-modal checklist-attribute-form" backdrop="static" 
             show={this.props.showChecklistAttributeModal} onHide={() => hideChecklistProcessModal()} >
                <Modal.Header >
                    <Modal.Title style={{color: 'white'}}>Process Checklist Attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.processAttribute()}
                </Modal.Body>
            </Modal>
        );
    }
    
    renderSubAttributeContent = () => {
        const { subAttribute } = this.state;
        const { instance } = this.props; 
        return subAttribute.map((attribute, index) => {
            return <Row key={index} className="show-grid">
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={11} sm={11} className="input">
                    <RRFInput
                        type="text"
                        placeholder="Enter sub attribute"
                        model={`.name[${index}]`} 
                        defaultValue={instance && instance.instance.subAttributeList.length && instance.instance.subAttributeList.length>index && instance.instance.subAttributeList[index].name}
                    />
                </Col>
                <Col sm={1}>
                    { subAttribute.length>1 && <i className="fa fa-trash-o margin-top-delete" onClick={() => this.decrementRow(index)} aria-hidden="true"></i>}
                </Col>
            </Row>;
        });
    }
    processAttribute = () => {
        const { props: {instance } } = this;
        return (
            <>
                <div className="project-list-wrapper">
                    <Grid style={{ width: '100%' }} className="safety-walkthrough-addattribute">
                        <Title titleColor="yellow" text={`Add Checklist Attribute`} />
                        <Alert className="danger-alert" id="attribute-form" />
                        <Form loader={<SubmissionLoader />} onSubmit={this.handleSubmit} model="forms.attributeModalForm"> 
                            <Row className="show-grid">
                                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={11} sm={11} className="input" >
                                    <RRFInput
                                        type="text"
                                        placeholder="Enter attribute name"
                                        model={`.mainAttribute`}  
                                        defaultValue={instance && instance.instance.attribute}
                                    />
                                </Col>
                            </Row> 
                            {this.renderSubAttributeContent()}
                            <div className="checklist-attribute-modal-table-buttons">
                                <button
                                    className="add-row"
                                    type="button"
                                    onClick={this.incrementRows}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    &nbsp;Add More SubAttribute
                            </button>
                            </div>
                            <Row className="show-grid"> 
                                <div style={{ textAlign: 'center' }} className="edit-button form-button"> 
                                    <Alert className="danger-alert" id="attribute-form-failed" />
                                    <Button type="submit">Update</Button>
                                    <Button style={{ color: '#5479AF', border: '1px solid #5479AF', backgroundColor: 'white', }} onClick={async () => { this.hideChecklistProcessModal(); }} type="reset">Cancel</Button>
                                </div>
                            </Row>
                        </Form>
                    </Grid>
                </div>
            </>
        )
    }

    render() {
        return <Async
            identifier={this.identifier}
            promise={undefined}
            error={<div></div>}
            content={this.showModal()}
        />;
    } 
     
}

export function mapStateToProps(state: any) {   
    return {
        modalID: state.modal.get('modalID'),
        showChecklistAttributeModal: state.modal.get('showChecklistAttributeModal'), 
        instance: state.modal.get('instance'), forms: state.forms 
    };
}

export const ChecklistAttributeModal = withRouter(connect<IChecklistAttributeModalProps, any, any>(mapStateToProps)(ChecklistAttributeModalImpl));
