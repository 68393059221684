import * as React from 'react';

export interface IErrorPageProps {
}

export class ErrorPage extends React.PureComponent<IErrorPageProps, {}> {
    constructor(props: IErrorPageProps | Readonly<IErrorPageProps>) {
        super(props);
    }

    render() {
        return (
            <div style={errorPage} className="error-page">
                <i style={errorIcon} className="fa fa-4x fa-exclamation-triangle" aria-hidden="true"></i>
                <h3>Oops, Something went wrong...!!!</h3>
            </div>
        );
    }
}
const errorIcon: React.CSSProperties = {
    fontSize: '115px'
};

const errorPage: React.CSSProperties = {
    textAlign: 'center',
    padding: '138px'
};
