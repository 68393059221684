import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { UserModel } from '../../../model/UserModel';

export interface IProtectedRouteImplProps {
    isLoggedIn?: boolean;
    path: string;
    component: React.ComponentClass;
    exact?: boolean;
    pathName?: string;
    userAccess?: string;
}

class EnsureLoginImpl extends React.PureComponent<IProtectedRouteImplProps, {}> {

    render() {
        const { path, component, isLoggedIn, exact, pathName, userAccess } = this.props; 
        return (
            <div>
                {isLoggedIn ? ( ( userAccess==='' || UserModel.checkUserAccess(userAccess) ) ?<Route exact={exact || false} path={path} component={component} /> :  <Redirect to={`/not-authorized`} /> ) :
                    <Redirect to={`/?redirect=${pathName}`} />}
            </div>
        );
    } 
}
export function mapStateToProps(state: any, ownProps: any) {
    return {
        isLoggedIn: state.login.get('isLoggedIn'),
        pathName: ownProps.location ? ownProps.location.pathname : ''
    };
}

export const ProtectedRoute = connect<{}, {}, IProtectedRouteImplProps>(mapStateToProps)(EnsureLoginImpl);
