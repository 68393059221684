import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { saveFilterData } from '../../actions/miscellaneousActions';
import { IChartValue, IOfficeSafetyReportSummary, IPieChartObject, IForms } from '../../interfaces';
import { applyMonthlyFilterOfficeSafety, applyYearlyFilterForOfficeSafety, getOfficeSafetyLineChartData,  getOfficeSafetyFormSummary } from '../../services/officeSafetyFormService';
import { getLegendNamesFromObj, marshallPieChartData } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Card } from '../reusableComponents/Card';
import { ActivityChart } from '../reusableComponents/ChartComponents/ActivityChart';
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage'; 
import { Title } from '../reusableComponents/Title';
import countOfRisk from '../../images/countOfRisk.png'; 
import maxObservations from '../../images/maxObservations.png';
import observations from '../../images/observations.png';
import maxObservation from '../../images/maxObservation.png'; 
/* import { printLogos } from '../../utils/generalUtils'; */
import { PrintLogos } from '../reusableComponents/PrintLogos';  

export interface IOfficeSafetyReportsDataProps {
    reportsData: IOfficeSafetyReportSummary;
    getRef?: (value: any) => void;
    getFilterBy?: (value: any) => void;
    setFilterBy?: (value: any) => void;
    id: string;
    isPrint: boolean;
    filterBy: string;
    forms: IForms;
}

export interface IOfficeSafetyReportsDataState {
    view: boolean;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
    filterBy: string;
}

export class OfficeSafetyReportsData extends React.Component<IOfficeSafetyReportsDataProps,
    IOfficeSafetyReportsDataState> {
    constructor(props: IOfficeSafetyReportsDataProps) {
        super(props);
        this.state = {
            view: false,
            data: [],
            legends: [],
            totalFormsSubmitted: 0,
            pieChartData: {},
            showMonthlyFilter: false, showYearlyFilter: false,
            filterBy: 'monthly',
            isLoading: false,
        };
    }

    ref: any;

    renderActivityPopover = (obj: IPieChartObject) => {
        const { Observations, ObservationDetails } = this.props.reportsData;
        let data:Array<any> = [];
        getLegendNamesFromObj(Observations);
        for (const key in ObservationDetails) {
            if (ObservationDetails.hasOwnProperty(key) && (key === obj.name)) {
                for (const newKey in (ObservationDetails as any)[key]) {
                    data.push({
                        name: newKey,
                        'At Risk': (ObservationDetails as any)[key][newKey]['At Risk'],
                        Safe: (ObservationDetails as any)[key][newKey].Safe
                    });
                }
            }
        }
        this.setState({
            view: true,
            pieChartData: obj,
            data, legends: ['Safe', 'At Risk']
        });
    }
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getOfficeSafetyFormSummary(this.props.forms); 
        setSuccess('ReportsPage');
    }


    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return (
            <YearlyFilter onApply={(year) => {
                setLoading('ReportsPage');
                saveFilterData('yearly', year);
                applyYearlyFilterForOfficeSafety(year);
            }} />
        );
    }

    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        return <MonthlyFilter onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilterOfficeSafety(year, month);
        }} />;
    }

    handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({
                showYearlyFilter: true,
                showMonthlyFilter: false,
                filterBy: 'yearly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'monthly') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: true,
                filterBy: 'monthly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'cumulative') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: false,
                filterBy: 'monthly'
            });
            this.props.getFilterBy && this.props.getFilterBy('monthly');
        }
        //await getOfficeSafetyFormSummary('summary', 'na', 'na');
    }

    renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            <Alert style={{ height: '40px' }} id="oi-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' },
                        { value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        defaultValue="cumulative"
                        model=".filterType"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    }

    renderSummaryCards = () => {
        if (!this.props.reportsData) {
            return <span />;
        }
        return (
            <Grid style={{ width: '100%' }}>
                <Row className="show-grid ">
                    <Col sm={12} className="row">
                        <Col sm={6} > <h4 style={{ margin: '15px 0px 0px 0px', color: 'White' }}> Today's Details </h4>
                        </Col>
                        <Col sm={6} > <h4 style={{ margin: '15px 0px 0px 0px', color: 'White' }}> Max Observation </h4>
                        </Col>
                    </Col>
                    <Col sm={12}  className="row">
                        <Col sm={6} >
                            <Card className="office-safety-report-card">
                                <img src={countOfRisk} alt="SafeConnect"/>
                                <h4 style={{ margin: '15px 0px 0px 0px', color: 'Black' }}>Count Of Risk</h4>
                                <p style={{ paddingTop: '0px', color: 'Black' }} >
                                    {this.props.reportsData.CountofRisks || 0}</p>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className="office-safety-report-card">
                                <img src={maxObservation} alt="SafeConnect"/>
                                <h4 style={{ color: 'Black' }}> Date</h4>
                                <p style={{ color: 'Black' }}>{this.props.reportsData.MaxObservationsDate || 0}</p>
                            </Card>
                        </Col>
                    </Col>
                </Row>
                <Row className="show-grid ">
                    <Col sm={12} style={{marginTop: '-39px'}} className="row">
                        <Col sm={6}>
                            <Card className="office-safety-report-card">
                                <img src={observations} alt="SafeConnect" />
                                <h4 style={{ color: 'Black' }}> Observations Percentage</h4>
                                <p style={{ color: 'Black' }}>{this.props.reportsData.ObservationsPercentage || 0}</p>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className="office-safety-report-card">
                                <img src={maxObservations} alt="SafeConnect" />
                                <h4 style={{ color: 'Black' }}> Observations</h4>
                                <p style={{ color: 'Black' }}>{this.props.reportsData.MaxObservations || 0}</p>
                            </Card>
                        </Col>
                    </Col>
                </Row>
            </Grid >
        );
    }

    renderPieChart = () => {
        if(!this.props.reportsData)
            return;
        const { Observations } = this.props.reportsData; 
        const legendsName = getLegendNamesFromObj(Observations);
        const observations = marshallPieChartData(Observations);
        return (
            <div id="pie-chart"
                style={{
                    height: '100%', width: '100%', display: 'inline-block',
                    margin: '20px auto', backgroundColor: 'white'
                }}>
                <Card width="100%" className="chart observation-count">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Observations Counts
                    </h4>
                    <Row className="show-grid" >
                        <Col sm={7} id="oi-pie-chart" className="pie circle" style={{ height: '253px' }}>
                            <ActivityChart
                                chartType="line"
                                visibility={this.state.view}
                                data={this.state.data}
                                legends={this.state.legends}
                                totalFormsSubmitted={this.state.totalFormsSubmitted}
                                obj={this.state.pieChartData} />
                            <PieChart
                                data={observations}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1}
                                customTootip={true}
                                onClick={this.renderActivityPopover}
                                isAnimationActive={true}
                            />
                        </Col>
                        <PieChartLegends
                            sm={5}
                            legendNames={legendsName}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                        />
                    </Row>
                </Card>
            </div>
        );
    }

    renderLineChart = () => {
        if (!this.props.reportsData) {
            return '';
        }
        const { officeData } = this.props.reportsData;
        const data = getOfficeSafetyLineChartData(this.props.filterBy || this.state.filterBy, officeData);
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Forms Submitted
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={data} legends={['Counts']}
                                strokeColors={['B0D9FF', 'FFBECE']}
                                dataKey={['Counts']} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data" > 
            <PrintLogos/>
            <Title key="title" text="Office Safety Report Summary"></Title>
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''}
                    {this.renderSummaryCards()}
                    {/*  {this.renderFilters()} */}
                    <CommonFilter onApply={this.applyCommonFilter} forms={this.props.forms}/>
                    {this.renderLineChart()}
                    {this.renderPieChart()}
                </div>
            </div>
        </div>;
    }

    render() {
        return [
            <Async
                key="content"
                identifier="ReportsPage"
                promise={() => setSuccess('ReportsPage')}
                loader={this.renderContent(true)}
                content={this.renderContent(false)}
                error={<ErrorPage />}
            />
        ];
    }
}
