import { BaseModel } from './BaseModel';

export interface ITeamModelProps {
    id: string;
    team: string; 
}

export class TeamModel extends BaseModel<ITeamModelProps> {
    constructor(props: ITeamModelProps) {
        super(props, TeamModel.resource);
    }
    static resource = 'team'; 
}

export interface ILocationModelProps {
    id: string;
    key: string;
    region: string; 
    locationName: string;
    address: string;
    company?: string;
}

export class LocationModel extends BaseModel<ILocationModelProps> {
    constructor(props: ILocationModelProps) {
        super(props, LocationModel.resource);
    }
    static resource = 'location'; 
}

export interface IDesignationModelProps {
    id: string;
    key: string;
    designation: string;  
    priority?: any;
    company?: string;
    status: any;

}

export class DesignationModel extends BaseModel<IDesignationModelProps> {
    constructor(props: IDesignationModelProps) {
        super(props, DesignationModel.resource);
    }
    static resource = 'designation'; 
}