import * as React from 'react';
import { IPersonalSafetyModelProps, PersonalSafetyModel } from '../../../model/PersonalSafetyModel';
import { IHistory } from '../../../interfaces';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress'; 
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { Cell } from '../../reusableComponents/ChartComponents/Cell';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { UserModel } from '../../../model/UserModel';
import { showDelConfirmModal } from '../../../actions/modalActions';

const MenuItem = Dropdown.Item;

export interface IListItemProps {
    instance?: PersonalSafetyModel;
    index?: number;
    currentUserInstance: any;
    style?: React.CSSProperties;
    history?: IHistory;
}

export class ListItemImpl extends React.Component<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    redirectUser = () => {
        const { currentUserInstance, instance } = this.props;
        if(instance) {
            if (currentUserInstance.toJS().userUID === instance.props.userUniqueId) {
                this.props.history?.push(`/personal-safety/${instance.props.id}`);
                return;
            }
            if (currentUserInstance.toJS().accessLevel !== 'L1') {
                this.props.history?.push(`/personal-safety/${instance.props.id}`);
                return;
            }
            if (instance && instance.props.followUp === 0) {
                this.props.history?.push(`/personal-safety/${instance.props.id}`);
                return;
            }
            this.props.history?.push(`/personal-safety/addComments/${instance.props.id}`);
        }
    }
    onDeleteAttribute() {
        const { instance } = this.props; 
        instance && showDelConfirmModal('delete-confirmation', 'Personal Safty form', instance.props);  
    }
    onEditAttribute(instance: IPersonalSafetyModelProps & { id?: string | undefined; }) {  
        this.props.history?.push(`/personal-safety/edit/${instance.id}`);
    }
    renderListItem = () => {
        const { currentUserInstance, instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        const { props: { fullName, dateCreated, projectName, groupName, supervisorName, followUp } } = instance; 
        return (
            <Row className="report-row" style={style}
            >
                <Col sm={1}>
                { ( UserModel.checkUserAccess('personal_safty_edit') || UserModel.checkUserAccess('personal_safty_view') || UserModel.checkUserAccess('personal_safty_delete') || (( currentUserInstance['userUID'] !== instance.props.userUniqueId ) && ( currentUserInstance['accessLevel'] === 'L1' || currentUserInstance['accessLevel'] === 'L2' ) && (instance && instance.props.followUp === 1)) ) &&
                    <DropdownButton title="" id="bg-vertical-dropdown-3">
                        {
                            ( currentUserInstance['userUID'] !== instance.props.userUniqueId ) && ( currentUserInstance['accessLevel']=== 'L1' || currentUserInstance['accessLevel'] === 'L2' ) && (instance && instance.props.followUp === 1) &&  <MenuItem eventKey="1" onClick={() => this.props.history?.push(`/personal-safety/addComments/${instance.props.id}`)} 
                            className="item"><i className="fa fa-refresh" aria-hidden="true"></i> Add Comments</MenuItem>
                        }
                        { UserModel.checkUserAccess('personal_safty_view') && <MenuItem eventKey="1" onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} 
                            className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                        { UserModel.checkUserAccess('personal_safty_edit') && instance.props.followUp === 1 && <MenuItem eventKey="2"
                            onClick={() => this.onEditAttribute(instance.props)}
                            className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                        { UserModel.checkUserAccess('personal_safty_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute()}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                    </DropdownButton> }
                    <span className="serial-no">{index || 0 + 1}</span> 
                </Col>
                <Cell sm={1} onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} >{dateCreated || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} /* onClick={this.redirectUser} */>{fullName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} >{projectName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} >{groupName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.props.history?.push(`/personal-safety/${instance.props.id}`)} >{supervisorName || 'NA'}</Cell>
                {followUp === 1 ?
                    <Col className="clickable-list-item" sm={2}><i
                        style={{ fontSize: '20px', color: '#888888' }}
                        className="fa  fa-clock-o"
                    ></i>
                        &nbsp;  Pending
                    </Col> :
                    <Col className="clickable-list-item" sm={2}>
                        <i
                            style={{ fontSize: '20px', color: '#26a65b' }}
                            className="fa fa-check-circle">
                        </i>
                        &nbsp; Completed
                    </Col>}
            </Row >
        );
    }

    render() {
        return this.renderListItem();
    }
}

export function mapStateToProps(state: any) {
    const currentUserInstance = state.login.get('currentUserInfo');
    return {
        currentUserInstance
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
