import { BaseModel } from './BaseModel';

export interface IRiskAssessmentModelProps {
    id: string;
    key?: string; 
    dateOfIncident: string;
    timeOfIncident: string;
    location: string;
    locationName: string;
    typeOfWork: string;
    groupName: string;
    projectName: string;
    followUp?: number;
    companyName: string;
    date: string;
    dateOfAssessment?: string;
    workToPerform: string;
    eventDescription: string[];
    eventScore: string[];
    eventCategory: string[];
    probabilityCategory: string[]; 
    averageEventCategory: string;
    averageProbabilityCategory: string;

    eventValues: IEventAssessmentStep[]; 
    editId?: string;
    totalFollowUp?: number; 
    totalClosedFollowUp?: number;
    username?: string;
    SSRI?: string;
    averageEventScore: string;
    eventResult: string;
    averageProbabilityScore: string;
    probabilityResult: string;
    reassessmentOption?: string;
    associatedForms?: string[];
    associatedChildForms?: IAssociatedChildForms[]; 
    createdById?: string;
    createdBy?: string;
    isApproved?: string; 
    approvedById?: string;
    approvedBy?: string;
    isAttested?: string; 
    attestedById?: string;
    attestedBy?: string;
    finalAuthorityComments?: string;
    finalResult?: string; 
    attachements: any[];
    attachmentUrls: any[];
    deletedFiles: any[];
} 

export interface IEventAssessmentStep { 
    eventCategory?: number;
    probabilityCategory?: number;
    eventDescription?: string;
} 
export interface IAssociatedChildForms {
    key: string;
    ssri: string;
    date: string;
    totalFollowUp: number; 
    totalOpenFollowUp: number;
}

export class RiskAssessmentModel extends BaseModel<IRiskAssessmentModelProps> {
    constructor(props: IRiskAssessmentModelProps) {
        super(props, RiskAssessmentModel.resource);
    }
    static resource = 'riskAssessment-from';
}
