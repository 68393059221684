import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces'; 
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
//import './swFormList.scss'; 
import { closeCallListProps, MechModel } from '../../model/MechModel';
import { TransModel } from '../../model/TransModel';
import { EnggModel } from '../../model/EnggModel';
import { getAllCloseCallForms, getCloseCallReportDetail, getAllFollowUpForms, getCloseCallEditDetail } from '../../services/closeCallService'; 
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';  
import { setLoading } from '../../actions/loadingActions'; 
import { FollowUpParentDetails } from '../../model/MechModel'; 
import { dispatch } from '../../utils/generalUtils'; 
import { getFollowUpDetails } from '../../actions/reportActions';  
import { showDelConfirmModal } from '../../actions/modalActions';  
import { UserModel } from '../../model/UserModel';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import { ListItem } from './ListItem';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { Pagination } from '../reusableComponents/Pagination';
declare var require: any;
const queryString = require('query-string');

export interface ICloseCallListPageProps {
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    closeCallInstances: closeCallListProps[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
    totalPageCount?: number;
}

export interface ICloseCallListPageState {
    showDetails: boolean;
    formId: string;
    closeCallInstances: closeCallListProps[];
}

export class CloseCallListPageImpl extends React.Component<ICloseCallListPageProps, ICloseCallListPageState> {
    constructor(props: ICloseCallListPageProps | Readonly<ICloseCallListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '', closeCallInstances: [] };
    }

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {
        //    if (this.props.closeCallInstances==null && this.props.closeCallInstances.length <= 0) {
        //         return getAllCheckListForms();
        //     }
        //     return null;
        await getAllCloseCallForms(pageNumber).then(() => {
            this.setState({ closeCallInstances: this.props.closeCallInstances });
        });
    }

    componentDidMount() { 
       
    }
    
    getCloseCallReportDetail = (item: any) => { 
        if(!UserModel.checkUserAccess('closecall_view'))
            return;
        getCloseCallReportDetail(item).then(() => {
            this.props.history?.push(`/close-call-report-detail/${item.uniqueId}/${item.formId}`);
        }); 
    }
 
    onDeleteAttribute(instance: { id: any; uniqueId: any; }) { 
        instance.id = instance.uniqueId; 
        showDelConfirmModal('delete-confirmation', 'Close Call Form', instance);  
    }
    onEditAttribute(instance: { formId: any; uniqueId: any; }) { 
        getCloseCallEditDetail(instance).then(() => {
            switch(instance.formId) {
                case 'Mechanical Form': 
                    this.props.history?.push(`/mech-reports/edit/${instance.uniqueId}`);  
                    break;
                case 'Transportation Form':
                    this.props.history?.push(`/trans-reports/edit/${instance.uniqueId}`); 
                    break;
                case 'Engineering Form':
                    this.props.history?.push(`/engineer-reports/edit/${instance.uniqueId}`); 
                    break; 
            }
        }); 
        
    }
    /* renderListItems = () => {
        const { closeCallInstances } = this.props;  
        //let closeCallInstances = this.state.closeCallInstances;  
        if (!closeCallInstances || closeCallInstances.length <= 0) {
            return (
                <div style={{ textAlign: 'center', margin: '26px', color: 'white' }}>No records found.</div>
            );
        }
        let filteredcloseCallInstances = closeCallInstances; 
        if (filteredcloseCallInstances.length <= 0) {
            return (
                <div style={{ textAlign: 'center', padding: '26px', color: 'grey' }}>No records found.</div>
            );
        }
        let style: React.CSSProperties = {
            borderBottom: 'none'
        };
        const last = filteredcloseCallInstances.length - 1;
       
        return filteredcloseCallInstances.map((instance, index) => {  
            instance['formType'] = 'closeCall'; 
            return (
            <span key={index} >
                <Row className="report-row" style={style}>
                    <Col className="clickable-list-item" sm={1} > 
                        { ( UserModel.checkUserAccess('closecall_view') || UserModel.checkUserAccess('closecall_edit') || UserModel.checkUserAccess('closecall_delete') ) && <DropdownButton title="&#8942;" id="bg-vertical-dropdown-3">
                            { UserModel.checkUserAccess('closecall_view') && <MenuItem eventKey="1"
                                onClick={() => this.props.history.push(`/close-call-report-detail/${instance.uniqueId}/${instance.formId}`)}
                                className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                            { UserModel.checkUserAccess('closecall_edit') && <MenuItem eventKey="2"
                                onClick={() => this.onEditAttribute(instance)}
                                className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                            { UserModel.checkUserAccess('closecall_delete') && <MenuItem eventKey="3"
                                onClick={() => this.onDeleteAttribute(instance)}
                                className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                        </DropdownButton> }
                        {index + 1}
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={3}
                    >
                        {instance.createdDate || 'NA'}
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={3}
                    >
                        {instance.userName || 'NA'}
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={2}
                    >
                        {instance.formId || 'NA'}
                    </Col>
                    <Col className="clickable-list-item" sm={3} onClick={() => this.getFollowUpList(instance)} > 
                        <Button className="closecall-followup-button" > {instance.totalClosedFollowUp || '0'}/{instance.totalFollowUp || '0'}</Button> 
                    </Col> 
                </Row>
            </span>
            );
        });
    } */
    getFollowUpList(instance: { [x: string]: any; }) { 
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
        let reportDetail: FollowUpParentDetails = {
            closeCallId: instance['uniqueId'],
            process: '',
            requestFrom: 'closeCallReport', 
        };
        dispatch(getFollowUpDetails(reportDetail));
        instance['closeCallId'] = instance['uniqueId'];
        showCloseCallFollowUpListModal(instance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['uniqueId']);
    }
    /* renderFormList = () => {
        const { closeCallInstances } = this.props;
        const { props: { style, titleStyle } } = this;
        return (
            <div className="office-safety-wrapper">
                <div style={style} className="list-page">
                    <Title style={titleStyle || {}} text="Close Call Reports"></Title>
                    <div className="reports-table">
                        <div style={{ /* margin: '10px 100px 0px 110px' */ /*}}>
                            <Row className="heading report-row">
                                <Col sm={1}>#</Col>
                                <Col sm={3}>Date Submitted</Col>
                                <Col sm={3}>User Name</Col>
                                <Col sm={2}>Form Type</Col>
                                <Col sm={3}>Follow_up Status</Col>
                            </Row >
                        </div>
                        <div
                            style={{
                                textAlign: 'center', fontSize: '15px',
                               /*  margin: '10px 100px 0px 110px', */ /*color: 'White'
                            }}>
                            {this.renderListItems()}
                        </div>
                    </div>
                </div > 
            </div>
        );
    } */
    renderFormList = () => {
        const { closeCallInstances } = this.props;
        return <div className="oi-list-wrapper" >
            <ListPage
                style={{ minHeight: '50vh', padding: '20px 0px 0px 0px' }}
                titleStyle={{ padding: '0px 70px' }}
                pageHeading="Close Call Reports"
                instances={closeCallInstances}
                rowHeadings={['Date Submitted', 'User Name', 'Form Type','Follow-up Status']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={['formId', 'userName', 'createdDate']}
                searchPlaceHolder="Enter form type, username or date created to search..."
                emptyInstancesMessage="No closecall reports found."
                sm={[2, 3, 3, 3]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
            />
            { /* UserModel.checkUserAccess('o&i_add') && this.renderAddNewFormButton() 
              UserModel.checkUserAccess('o&i_report_summary') && this.renderSummaryPageButton() */}
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={MechModel as any || TransModel as any || EnggModel as any} 
                identifier="AllListPage"
            />
        </div>;
    }
 
    render() {
        return ( 
           /*  <BasePage className="min-wide-list-page" >  
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                {this.renderFormList()}
            </BasePage > */
            <BasePage className="wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="OIFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderFormList()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) { 
    return {
        closeCallInstances: state.report.get('reportDataList'), 
        totalPageCount: state.miscellaneous.get('listCount'),
    };
}

export const CloseCallListPage = withRouter(connect< ICloseCallListPageProps, any, any>(mapStateToProps)(CloseCallListPageImpl));
