import * as React from 'react';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { printDetails } from '../../utils/generalUtils';
import { Img } from './ImageComponents/Img';
import { getUserInfo } from '../../services/loginService';
import { PrintLogos } from '../reusableComponents/PrintLogos';

export interface IDetailsPageWrapperProps {
    className: string;
    pdfFileName?: string;
    customButton?: JSX.Element;
    isLong?: boolean;
}

export class DetailsPageWrapper extends React.PureComponent<IDetailsPageWrapperProps, {}> {
    constructor(props: IDetailsPageWrapperProps | Readonly<IDetailsPageWrapperProps>) {
        super(props);
    }

    ref: any;

    renderPrintPreviewModal = () => {
        //showPrintPreviewModal('details-page');
        let ref: any = document.getElementById('detail-page');
        if(ref) {  
			window.print();
        }
    }

    render() {
        const { className, children, customButton, pdfFileName, isLong } = this.props;
        let companyLogo = getUserInfo().companyLogo; 
        return (
            <div>
                <PrintPreviewModal
                    className={className}
                    id="details-page"
                    isDetailsPage={true}
                    fileName={pdfFileName}
                    onClickYes={() => printDetails(this.ref, pdfFileName || '', (isLong ? isLong : false))}
                >
                    <div id="print" className={className} ref={value => this.ref = value}>
                        <div className="print-logo row" style={{marginBottom:'10px'}}>
                            <div className="col-sm-6">
                                {companyLogo && <Img 
                                    key={'companyLogo'}
                                    style={{ padding: '5px' }}
                                    width="150px"
                                    height="75px"
                                    /* src={`https://safeconnect-beta.s3.us-west-2.amazonaws.com/static-files/DART.png`} */
                                    src={companyLogo}
                                /> } 
                            </div>
                            <div className="col-sm-6" style={{textAlign: 'right'}}> 
                                <Img 
                                    key={'companyLogo'}
                                    style={{ padding: '5px' }}
                                    width="150px"
                                    height="75px"
                                    src="https://safeconnect-beta.s3.us-west-2.amazonaws.com/static-files/safeConnectTransparent.png"
                                /> 
                            </div>
                        </div> 
                        {children}
                    </div>
                </PrintPreviewModal>
                <div className={className} id="detail-page">
                    <PrintLogos/>
                    {children}
                </div>
                <div
                    style={{ padding: '10px 10px 20px 10px', textAlign: 'center' }}
                    className="export-button-container form-button"
                >
                    {customButton}
                    <button className="button" onClick={this.renderPrintPreviewModal}>Print</button>
                </div>
            </div >
        );
    }
}
