import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';

import { showPrintPreviewModal } from '../../actions/modalActions';
import { IFormMonths, IHistory, IForms } from '../../interfaces'; 
import { printDetails } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { RiskReportsData } from './RiskReportsData';
import { withRouter } from 'react-router-dom'; 
import { getRiskAssessmentData } from '../../services/riskAssessmentService';

export interface IReportSummaryPageProps {
    environment?: number;
    nearMiss?: number;
    other?: number;
    safety?: number;
    total?: number;
    userId?: string;
    observations?: {
        'Positive Observation': number;
        'Negative Observation': number;
    };
    combinedOthers?: boolean;
    health?: number;
    formMonths?: IFormMonths;
    security?: number; 
    concernTypes?: any;
    activityConcernTypes?: Object;
    formSummaryData: any;
    filterData: {
        year: string;
        month: string;
        filterType: string;
    };
    history?: IHistory;
    forms?: IForms;
} 

export interface IReportSummaryPageState {
    showMonthlyFilter: boolean;
    showYearlyFilter: boolean;
    hideElement: boolean;
}

export class ReportSummaryPageImpl extends React.PureComponent<IReportSummaryPageProps, IReportSummaryPageState> {
    constructor(props: IReportSummaryPageProps | Readonly<IReportSummaryPageProps>) {
        super(props);
        this.state = { showMonthlyFilter: false, showYearlyFilter: false, hideElement: false };
    }

    ref: any;

    getChildContext() {
        return { formModel: 'forms.reportFilterForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    promise = async () => { 
        await getRiskAssessmentData(); 
        return null;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'RiskAssessmentReports');
    }

    renderPrintPreviewModal = () => {
        showPrintPreviewModal('risk-assessment-summary'); 
        return;
    }

    renderContent = (showCheckBox: boolean, isAnimationActive: boolean, id?: string) => {
        if(!this.props.formSummaryData) {
            return <div></div>; 
        }
        const { total, formMonths, finalResult1, finalResult2, finalResult3, finalResult4, finalResult5, finalResult6,  activityConcernTypes, combinedOthers } = this.props.formSummaryData;
        const { filterData } = this.props.formSummaryData;
        const data: {
            name?: string;
            value?: number;
        }[] = []; 
        let finalCount = finalResult1+ finalResult2+ finalResult3+ finalResult4;
        let preliminaryCount = total - finalCount;
        const summaryCardsData = { total, finalCount, preliminaryCount }; 
        const lineChartData = { formMonths }; 

        data.push({ name: 'Risk-free (Preliminary) ', value: finalResult5 });
        data.push({ name: 'Risk-free (Manager Approved) ', value: finalResult1 });
        data.push({ name: 'Risk (Preliminary) ', value: finalResult6 }); 
        data.push({ name: 'Risk-mitigated (Manager Approved) ', value: finalResult2 });
        data.push({ name: 'Risk (Final Authority Approved) ', value: finalResult3 + finalResult4 });
        return <div> 
            <RiskReportsData
                id={id}
                filterData={filterData}
                activityData={activityConcernTypes}
                lineChartData={lineChartData} 
                combinedOthers={combinedOthers}
                detailedPie={data}
                summaryCardsData={summaryCardsData}
                showCheckBox={false}
                isAnimationActive={!showCheckBox}
                getRef={value => this.ref = value}
                history={this.props.history}
                forms={this.props.forms}
            />
            <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
            </div>
        </div>;
    }

    render() {
        return (
            <BasePage className="reports-page">
                <PrintPreviewModal
                    id="risk-assessment-summary"
                    ref="printPreview"
                    className="oi-report-data"
                    fileName="Risk Assessment Summary"
                    onClickYes={this.exportCharts}
                    style={{ boxShadow: 'none' }}
                >
                    {this.renderContent(true, false, 'print')}
                </PrintPreviewModal>
                <Async
                    identifier="OIFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent(false, true)}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) { 
    const formSummaryData = state.formSummary.get('riskAssessmentSummary'); 
    return { 
        filterData: state.miscellaneous.get('filterOIReports'),
        formSummaryData, forms: state.forms,
    };
}

export const ReportSummaryPage =
    withRouter(connect<IReportSummaryPageProps, any, any>(mapStateToProps)(ReportSummaryPageImpl));
