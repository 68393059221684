import { IHistory } from '../../../interfaces';
/* import { clearTimeout } from 'timers'; */
import * as React from 'react';
import { Alert } from '../../Alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearSetTimeout } from '../../../utils/generalUtils';
import { Modal } from 'react-bootstrap';

export interface IAlertModalProps {
    id?: string;
    history?: IHistory;
    path?: string;
    match?: {
        params: {
            id: string;
        }
    };
    style?: React.CSSProperties;
}

export class AlertModalImpl extends React.Component<IAlertModalProps, {}> {
    constructor(props: IAlertModalProps | Readonly<IAlertModalProps>) {
        super(props);
    }
    render() {
        const { path, history, id } = this.props;
        if(!id)
            return;
        return (
            <Alert id={id}
                view={(text: any, alertType: any) => {
                    return <Modal /* bsSize="sm" */ show={true}
                        onHide={(() => {
                            path ? clearSetTimeout(id, () => history && history.push(path)) :
                                clearSetTimeout(id, () => history && history.push('/home'));
                        })}>
                        <Modal.Header style={alertType==='success'?modalHeaderSuccess:modalHeaderDanger} closeButton>
                            <Modal.Title style={{ margin: '0 auto'}}>
                                <span style={{textAlign: 'center'}}>
                                    <i className={`fa fa-3x ${alertType==='success'?'fa-check-circle-o':'fa-check-circle-o'}`} aria-hidden="true" ></i>
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={alertType==='success'?modalHeaderSuccess:modalHeaderDanger}>
                            <p style={this.props.style || {} } >
                                { text}
                            </p>
                        </Modal.Body>
                    </Modal >;
                }}
            ></Alert>
        );
    }
}

const modalHeaderSuccess: React.CSSProperties = {
    borderBottom: 'none',
    color: 'green',
    textAlign: 'center',
    padding: '10px',
    fontSize: '18px'
};
const modalHeaderDanger: React.CSSProperties = {
    borderBottom: 'none',
    color: '#fd4444',
    textAlign: 'center',
    padding: '10px',
    fontSize: '18px'
};
export function mapStateToProps(state: any) {
    return {
        
    };
}

//export const AlertModal = withRouter(connect<{}, {}, IAlertModalProps>(mapStateToProps)(AlertModalImpl));
//export const AlertModal = connect<{}, {}, IAlertModalProps>(mapStateToProps)(AlertModalImpl);
export const AlertModal = withRouter(connect<IAlertModalProps, any, any>(mapStateToProps)(AlertModalImpl));
