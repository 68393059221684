import * as React from 'react';
import { connect } from 'react-redux';
import { FollowUpModel } from '../../model/FollowUpModel';
import { Container as Grid, Row, Col } from 'react-bootstrap';
import { ListPage } from '../reusableComponents/ListPage';
import { FollowUpListItem } from './FollowUpListItem';
import { BasePage } from '../reusableComponents/BasePage';
import { Async } from '../reusableComponents/Async';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { getAllFollowUpForm } from '../../services/incidentReportService';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import PropTypes from 'prop-types';
import { IForms } from '../../interfaces';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { getAllIncidentReportForms } from '../../services/incidentReportService';
import { INCIDENTINVESTIGATE_PREFIX } from '../../constants/general';

export interface IFollowUpListPageProps {
    instances: FollowUpModel[];
    userUID: string;
    forms: IForms;
    investigativeId: string;
    investigativeInstance: IncidentReportModel;
}

export interface IFollowUpListPageState {
    instanceList: FollowUpModel[];
}

export class FollowUpListPageImpl extends React.PureComponent<IFollowUpListPageProps, IFollowUpListPageState> {
    constructor(props: IFollowUpListPageProps | Readonly<IFollowUpListPageProps>) {
        super(props);
        this.state = { instanceList: [] };  
    }

    promise = async () => {
        if (IncidentReportModel.list().length <= 0) {
            await getAllIncidentReportForms();
        } 
        await getAllFollowUpForm(this.props.userUID, this.props.investigativeId);
        const defaultSearch = this.props.forms.followUpForm.searchInput || 'pending';
        this.setListItems(defaultSearch);
    }

    setListItems = async (value: string) => {
        const { instances } = this.props; 
        let newList: any;
        if (value === 'all') { 
            newList = instances;
            /* this.setState({
                instanceList: instances
            }); */
        } else if (value === 'complete') {
            newList = await instances.filter(instance => instance.props.Complete === 1 );
            /* this.setState({
                instanceList: newList
            }); */
        } else if (value === 'pending') {
            newList = await instances.filter(instance => instance.props.Complete === 0 );
            /* this.setState({
                instanceList: newList
            }); */
        }
        if(this.props.investigativeId ) {
            newList = await newList.filter( (instance: any) => ( this.props.investigativeId && instance.props.FormId === this.props.investigativeId ));
        } 
        this.setState({
            instanceList: newList
        });
    }

    handleDropdownChange = (value: any) => {
        this.setListItems(value);
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    getChildContext() {
        return { formModel: 'forms.followUpForm' };
    }

    renderList = () => {
        const { props: { forms, instances, investigativeInstance }, state: { instanceList } } = this;
        const defaultSearch = forms.followUpForm.searchInput || 'pending';
        if(this.props.investigativeId && !investigativeInstance)
            return; 
        return <Grid
            className="oi-report-list"
            style={{
                width: '100%',
                padding: '20px'
            }}>
            <ListPage
                style={{
                    minHeight: '50vh',
                    padding: '20px 0px 0px 0px'
                }}
                pageHeading={`${this.props.investigativeId?`${INCIDENTINVESTIGATE_PREFIX}-${investigativeInstance.props.formSerialNo}`:'Overall'} Investigative Follow-up Actions`}
                instances={instanceList || instances}
                rowHeadings={['Created By', 'Status', 'Target Date']}
                listItemComponent={FollowUpListItem}
                isSearchable={true}
                searchableBy={['CreatedBy', 'TargetDate']}
                searchPlaceHolder="Enter creator name or date created..."
                customSearchComponent={<Row style={{
                    margin: '0',
                    padding: '15px 38px',
                }} >
                    <Col sm={{span: 4, offset: 4}} xs={4} >
                        <RRFInput
                            model=".searchInput"
                            type="dropdown"
                            placeholder="Select to filter..."
                            menuItems={[{ label: 'All', value: 'all' },
                            { label: 'Complete', value: 'complete' },
                            { label: 'Pending', value: 'pending' }]}
                            defaultValue={defaultSearch}
                            onSelect={this.handleDropdownChange}
                        />
                    </Col>
                </Row>}
                sm={[4, 4, 3]}
                emptyInstancesMessage={`No forms found.`} 
                inputParam1={this.props.investigativeId || ''} />
        </Grid>;
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="FollowUpList"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderList()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const userUID = state.login.get('userUID');
    const instances = FollowUpModel.getAllFollowUps();
    const investigativeId = ownProps.match.params.investigativeId || '';
    const investigativeInstance = investigativeId && IncidentReportModel.get(investigativeId); 
    return {
        instances, userUID, forms: state.forms, investigativeId, investigativeInstance
    };
}

export const FollowUpListPage = connect<{}, {}, IFollowUpListPageProps>(mapStateToProps)(FollowUpListPageImpl);
