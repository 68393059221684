import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { IForms } from '../../interfaces';
import { SafetyWalkthroughReportChartData } from '../../model/SafetyWalkthroughModel';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { getSafetyWalkthroughReportData } from '../../services/attributeService';
import { printDetails  } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { SafetyWalkthroughReportData } from './SafetyWalkthroughReportData';

export interface IOfficeSafetyWalkthroughReportSummaryProps {
    reportsData: SafetyWalkthroughReportChartData;
    forms?: IForms;
}

export interface IOfficeSafetyReportSummaryPageState {
    mountPrintPreviewModal: boolean;
    reportsDataValue: SafetyWalkthroughReportChartData;
    filterBy: string;
}

export class SafetyWalkthroughReportSummaryPageImpl extends React.Component<IOfficeSafetyWalkthroughReportSummaryProps,
    IOfficeSafetyReportSummaryPageState> {
    constructor(props: IOfficeSafetyWalkthroughReportSummaryProps) {
        super(props);
        let reportData: SafetyWalkthroughReportChartData = { reportCountData: {}, reportSumData: {} };
        this.state = { mountPrintPreviewModal: false, filterBy: '', reportsDataValue: reportData };
    }

    /* promise = async () => {
        const currentMonth = new Date().getMonth().toString();
        const year = new Date().getFullYear().toString();
        await getOfficeSafetyFormSummary('summary', 'na', 'na');
    } */
    async componentDidMount() {
        /* getSafetyWalkthroughReportData('yearly', '0', '0').then(() => {
            // this.setState({reportsDataValue:this.props.reportsData});
        }); */ 
        await getSafetyWalkthroughReportData(); 
    }

    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any;

    renderContent = (id: string) => {
        return <div id="summary-reports">
            <SafetyWalkthroughReportData
                reportsData={this.props.reportsData}
                getRef={value => this.ref = value}
                id={id}
                isPrint={id ? true : false}
                getFilterBy={filterBy => { this.setState({ filterBy }); }}
                filterBy={this.state.filterBy} 
                forms={this.props.forms}
            />
            <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
            </div>
        </div>;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'SafetyWalkthroughReports');
    }

    renderPrintPreviewModal = () => {
        this.setState({
            mountPrintPreviewModal: true
        });
        showPrintPreviewModal('office-safety-report'); 
        return;
    }

    render() {
        return <BasePage className="reports-page office-safety-summary" >
            {this.state.mountPrintPreviewModal ? <PrintPreviewModal
                id="office-safety-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="SafetyWalkthroughReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent('print')}
            </PrintPreviewModal> : ''}
            {this.renderContent('')} 
        </BasePage>;
    }
}

export function mapStateToProps(state: any) {
    return { reportsData: state.report.get('reportChartData'), forms: state.forms, };
}

export const SafetyWalkthroughReportSummaryPage =
    connect<IOfficeSafetyWalkthroughReportSummaryProps, any, any>(mapStateToProps)(SafetyWalkthroughReportSummaryPageImpl);
