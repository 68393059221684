import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';  
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { GroupInput } from '../../reusableComponents/FormComponents/GroupInput';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { ProjectInput } from '../../reusableComponents/FormComponents/ProjectInput';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../../reusableComponents/Loader';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Title } from '../../reusableComponents/Title';
import { UserModel } from '../../../model/UserModel'; 
import { getAllUsers } from '../../../services/userService';
import { IForms, IHistory } from '../../../interfaces';
import { LocationInput } from '../../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../../reusableComponents/FormComponents/MapInputModal';
import '../index.scss';
import { dispatch, isFormEmpty, showAlert, convertToDateFormat } from '../../../utils/generalUtils';
import { actions } from 'react-redux-form';

import { IMocTemporaryModelProps, MocTemporaryModel } from '../../../model/MOCModel';
import { ManualSignature } from '../../reusableComponents/ManuallyGenerateSignature/ManualSignature';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { AttachementInput } from '../../reusableComponents/FormComponents/AttachementInput';
import { submitMOCForms, getFormData } from '../../../services/MocService';
import { VoiceRecognition } from '../../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IMOCTemporaryDeviationFormProps { 
    instance?: any;
    userInstance: UserModel;
    history?: IHistory;
    forms: IForms;
    company: string;
    editId?: string;
    userUID?: string;
}

export interface IMOCTemporaryDeviationState {  
    currentDate: string;
    currentTime: string;  
    isDisableGeneralEdit: boolean; 

    safetyManagerUsers: any[]; operationsManagerUsers: any[]; productionManagerUsers: any[]; generalManagerMembers: any[]; globalOperationsManagersMembers: any[]; regionalVicePresidentMembers: any[];

    originatorSignatureTray: boolean; originatorSignature: string; originatorSignatureURL: string; 
    safetyManagerSignatureTray: boolean; safetyManagerSignature: string; safetyManagerSignatureURL: string;  
    operationsManagerSignatureTray: boolean; operationsManagerSignature: string; operationsManagerSignatureURL: string;
    productionManagerSignatureTray: boolean; productionManagerSignature: string; productionManagerSignatureURL: string;
    generalManagerSignatureTray: boolean; generalManagerSignature: string; generalManagerSignatureURL: string;
    regionalVicePresidentSignatureTray: boolean; regionalVicePresidentSignature: string; regionalVicePresidentSignatureURL: string;
    globalOperationsManagerSignatureTray: boolean; globalOperationsManagerSignature: string; globalOperationsManagerSignatureURL: string; 
} 
 
export class MOCTemporaryDeviationImpl extends React.PureComponent<IMOCTemporaryDeviationFormProps, IMOCTemporaryDeviationState> {
 
    constructor(props: IMOCTemporaryDeviationFormProps | Readonly<IMOCTemporaryDeviationFormProps>) {
        super(props); 
        this.state = { currentDate: format(new Date(), 'yyyy-MM-dd'), currentTime: format(new Date(), 'HH:mm'), isDisableGeneralEdit: false, 
        safetyManagerUsers: [], operationsManagerUsers: [], productionManagerUsers: [], generalManagerMembers: [], globalOperationsManagersMembers: [], regionalVicePresidentMembers: [], 
        originatorSignatureTray: true, originatorSignature: '', originatorSignatureURL: '',   safetyManagerSignatureTray: true, safetyManagerSignature: '', safetyManagerSignatureURL: '', operationsManagerSignatureTray:true, operationsManagerSignature: '',  operationsManagerSignatureURL: '', productionManagerSignatureTray: true, productionManagerSignature: '', productionManagerSignatureURL: '', generalManagerSignatureTray: true, generalManagerSignature: '', generalManagerSignatureURL: '',
        regionalVicePresidentSignatureTray: true, regionalVicePresidentSignature: '', regionalVicePresidentSignatureURL: '',  
        globalOperationsManagerSignatureTray: true, globalOperationsManagerSignature: '', globalOperationsManagerSignatureURL: '', }
    }

    promise = async () => { 
        const { editId, userUID } = this.props;
        let instance = this.props.instance;
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if(!editId) {
            this.setState({  safetyManagerSignatureTray: false, operationsManagerSignatureTray: false, productionManagerSignatureTray: false, generalManagerSignatureTray: false, regionalVicePresidentSignatureTray: false, globalOperationsManagerSignatureTray: false, })
        } 
        if(editId && !instance) {  
            await getFormData('mocTemporaryDeviation', editId);
            instance = MocTemporaryModel.get(editId); 
        } 
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers; 
            if(instance.props.userId!==userUID || answer.safetyManagerApprovedDate) {
                this.setState({isDisableGeneralEdit: true})
            }  
            this.setState({ safetyManagerSignatureTray: false, operationsManagerSignatureTray: false, productionManagerSignatureTray: false, generalManagerSignatureTray: false, regionalVicePresidentSignatureTray: false, globalOperationsManagerSignatureTray: false, })
            

            this.getUsersOptions(answer.groupName);
            if(answer.originatorSignatureURL) {
                this.setState({originatorSignatureURL: answer.originatorSignatureURL, originatorSignatureTray: false })
            } 
            if(answer.safetyManagerSignatureURL) {
                this.setState({safetyManagerSignatureURL: answer.safetyManagerSignatureURL })
            }
            if(answer.operationsManagerSignatureURL) {
                this.setState({operationsManagerSignatureURL: answer.operationsManagerSignatureURL })
            }
            if(answer.productionManagerSignatureURL) {
                this.setState({productionManagerSignatureURL: answer.productionManagerSignatureURL })
            }
            if(answer.generalManagerSignatureURL) {
                this.setState({generalManagerSignatureURL: answer.generalManagerSignatureURL })
            }
            if(answer.regionalVicePresidentSignatureURL) {
                this.setState({regionalVicePresidentSignatureURL: answer.regionalVicePresidentSignatureURL })
            }
            if(answer.globalOperationsManagerSignatureURL) {
                this.setState({globalOperationsManagerSignatureURL: answer.globalOperationsManagerSignatureURL })
            }
        }
    }
    componentWillMount() {  
    }

    getChildContext() {
        return { formModel: 'forms.MOCTemporaryDeviationForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 

    getUsersOptions = (group?: string) => {  
        const users = UserModel.list();
        this.setState({ safetyManagerUsers: [], operationsManagerUsers: [], productionManagerUsers: [], generalManagerMembers: [],globalOperationsManagersMembers: [], regionalVicePresidentMembers: [], });
        let safetyManagerUsers: any = []; 
        /* let operationsManagerUsers: any = []; 
        let productionManagerUsers: any = []; 
        let generalManagerMembers: any = [];
        let regionalVicePresidentMembers: any = [];
        let globalOperationsManagersMembers: any = []; 
        let firstNames: any[] = [];*/
        (users || []).forEach( (user:any) => { 
            if(user.props.groups.includes(group)) { 
                let firstName = user.props.firstName + ' ' + user.props.middleName + ' ' + user.props.lastName;
                safetyManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                /* switch(user.props.jobTitle) { 
                    case 'Safety Manager':
                        safetyManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Operations Manager':
                        operationsManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Production Manager':
                        productionManagerUsers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'General Manager':
                        generalManagerMembers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Global Operations Manager':
                        globalOperationsManagersMembers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    case 'Regional Vice President':
                        regionalVicePresidentMembers.push({ label: firstName, value: user.props.userUID.toString() });
                        break;
                    default: break;
                } */
            }
        }); 
        /* this.setState({ safetyManagerUsers: safetyManagerUsers, operationsManagerUsers: operationsManagerUsers, productionManagerUsers: productionManagerUsers, generalManagerMembers: generalManagerMembers, globalOperationsManagersMembers: globalOperationsManagersMembers, regionalVicePresidentMembers: regionalVicePresidentMembers }); */
        this.setState({ safetyManagerUsers: safetyManagerUsers, operationsManagerUsers: safetyManagerUsers, productionManagerUsers: safetyManagerUsers, generalManagerMembers: safetyManagerUsers, globalOperationsManagersMembers: safetyManagerUsers, regionalVicePresidentMembers: safetyManagerUsers });
    }  

    handleFormSubmit = async (values: IMocTemporaryModelProps) => {  
        const { props: { instance, editId, userUID } } = this; 
        let answer: any = {}; 
        if(instance)
            answer = instance.props.answers;
        let validateValues = { dateOfIncident: values.dateOfIncident, timeOfIncident: values.timeOfIncident, projectName: values.projectName, groupName: values.groupName, locationName: values.locationName, originator: values.originator, originatorSignature: values.originatorSignature || (answer && answer.originatorSignatureURL),changeRequestDuration: values.changeRequestDuration,changeRequestSection: values.changeRequestSection, changeRequestDetails: values.changeRequestDetails, reasonForChange: values.reasonForChange, neededPrecautions: values.neededPrecautions, safetyManager: values.safetyManager, operationsManager: values.operationsManager, regionalVicePresident: values.regionalVicePresident, globalOperationsManager: values.globalOperationsManager,} 
        
        let submitValues = JSON.parse(JSON.stringify(values));
        submitValues['changeRequestDetails'] = values.changeRequestDetails;
        if(!editId || (instance && instance.props.userId === userUID && !values.safetyManagerApprovedDate) ) {
            submitValues['mailTo'] = {'UUID': values.safetyManager, 'designation': 'safetyManager'}; 
        } else if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;  
            if(userUID===answer.safetyManager) {
                let validateNew = {safetyManagerApprovedDate: values.safetyManagerApprovedDate, safetyManagerSignature: values.safetyManagerSignature || answer.safetyManagerSignatureURL, safetySupervisorComments: values.safetyManagerComments };
                validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.productionManager, 'designation': 'operationsManager'}; 
            }
            if(userUID===answer.operationsManager) {
                let validateNew = {operationsManagerApprovedDate: values.operationsManagerApprovedDate, operationsManagerSignature: values.operationsManagerSignature || answer.operationsManagerSignatureURL, operationsManagerComments: values.operationsManagerComments };
                if(answer.safetyManagerApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                if(answer.productionManager) {
                    submitValues['mailTo'] = {'UUID': answer.productionManager, 'designation': 'productionManager'}; 
                } else {
                    submitValues['mailTo'] = {'UUID': answer.regionalVicePresident, 'designation': 'regionalVicePresident'}; 
                }
            }   
            if(userUID===answer.productionManager) {
                let validateNew = {productionManagerApprovedDate: values.productionManagerApprovedDate, productionManagerSignature: values.productionManagerSignature || answer.productionManagerSignatureURL, productionManagerComments: values.productionManagerComments };
                if(answer.productionManager && answer.operationsManagerApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.generalManager, 'designation': 'generalManager'}; 
            }
            if(userUID===answer.generalManager) {
                let validateNew = {generalManagerApprovedDate: values.generalManagerApprovedDate, generalManagerSignature: values.generalManagerSignature || answer.generalManagerSignatureURL };
                if(answer.generalManager && ( (answer.productionManager && answer.productionManagerApprovedDate) || (answer.operationsManagerApprovedDate) ) )
                    validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.regionalVicePresident, 'designation': 'regionalVicePresident'}; 
            }
            if(userUID===answer.regionalVicePresident) {
                let validateNew = {regionalVicePresidentApprovedDate: values.regionalVicePresidentApprovedDate, regionalVicePresidentSignature: values.regionalVicePresidentSignature || answer.regionalVicePresidentSignatureURL };
                if( (answer.generalManager && answer.generalManagerApprovedDate) || (answer.productionManager && answer.productionManagerApprovedDate) || (answer.operationsManager && answer.operationsManagerApprovedDate) )
                    validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': answer.globalOperationsManager, 'designation': 'globalOperationsManager'};
            }
            if(userUID===answer.globalOperationsManager) {
                let validateNew = {globalOperationsManagerApprovedDate: values.globalOperationsManagerApprovedDate, globalOperationsManagerSignature: values.globalOperationsManagerSignature || answer.globalOperationsManagerSignatureURL };
                if(answer.regionalVicePresidentApprovedDate)
                    validateValues = {...validateValues, ...validateNew}; 
                submitValues['mailTo'] = {'UUID': instance.props.userId, 'designation': 'createdUser'};
            }
        }
        if (isFormEmpty(validateValues)) { 
            showAlert(EMPTY_FORM_MESSAGE, 'mocTemporaryDeviation-failed', 'danger');
            return;
        } 
        await submitMOCForms(submitValues, 'mocTemporaryDeviation', this.props.history, this.props.forms, this.props.editId, answer);
    }
    renderSignatureField = (SignatureURL: any ='', SignatureTray = '', isDisabled = false) => {
        return <><Row className="show-grid" style={{ paddingBottom: '20px' }} > 
                <Col sm={12} style={{ color: 'green' }} className="signature">
                    { SignatureURL ?<img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ SignatureURL } alt="SafeConnect"/> : 'No signature found'}
                    {!isDisabled && <div>
                        <button
                            className="button"
                            style={newSign}
                            onClick={() => {  
                                var obj  = {};
                                obj[SignatureTray] = true;
                                this.setState(obj)
                                } }>
                            Sign New
                        </button>
                    </div> }
                </Col>
            </ Row ></>;
    }
    saveSignature = (file: any, acceptorSignature: any, model: any) => { 
        switch(model) {
            case 'originatorSignature':
                this.setState({
                    originatorSignatureTray: false, originatorSignature: file, originatorSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.originatorSignature`, acceptorSignature));
                break; 
            case 'safetyManagerSignature':
                this.setState({
                    safetyManagerSignatureTray: false,  safetyManagerSignature: file,  safetyManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.safetyManagerSignature`, acceptorSignature));
                break;
            case 'operationsManagerSignature':
                this.setState({
                    operationsManagerSignatureTray: false, operationsManagerSignature: file,  operationsManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.operationsManagerSignature`, acceptorSignature));
                break;
            case 'productionManagerSignature':
                this.setState({
                    productionManagerSignatureTray: false, productionManagerSignature: file,  productionManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.productionManagerSignature`, acceptorSignature));
                break;
            case 'generalManagerSignature':
                this.setState({
                    generalManagerSignatureTray: false, generalManagerSignature: file,  generalManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.generalManagerSignature`, acceptorSignature));
                break; 
            case 'regionalVicePresidentSignature':
                this.setState({
                    regionalVicePresidentSignatureTray: false, regionalVicePresidentSignature: file,  regionalVicePresidentSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.regionalVicePresidentSignature`, acceptorSignature));
                break; 
            case 'globalOperationsManagerSignature':
                this.setState({
                    globalOperationsManagerSignatureTray: false, globalOperationsManagerSignature: file,  globalOperationsManagerSignatureURL: acceptorSignature, 
                });  
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.globalOperationsManagerSignature`, acceptorSignature));
                break; 
        }
    } 
    saveGeneratedSignature = (file: any, base64: any, model: any) => { 
        switch(model) {
            case 'originatorSignature':
                this.setState({
                    originatorSignatureTray: false, originatorSignature: file, originatorSignatureURL: base64,
                }); 
                dispatch(actions.change(`forms.MOCTemporaryDeviationForm.originatorSignature`, base64));
                break; 
                case 'safetyManagerSignature':
                    this.setState({
                        safetyManagerSignatureTray: false,  safetyManagerSignature: file,  safetyManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.safetyManagerSignature`, base64));
                    break;
                case 'operationsManagerSignature':
                    this.setState({
                        operationsManagerSignatureTray: false, operationsManagerSignature: file,  operationsManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.operationsManagerSignature`, base64));
                    break;
                case 'productionManagerSignature':
                    this.setState({
                        productionManagerSignatureTray: false, productionManagerSignature: file,  productionManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.productionManagerSignature`, base64));
                    break;
                case 'generalManagerSignature':
                    this.setState({
                        generalManagerSignatureTray: false, generalManagerSignature: file,  generalManagerSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.generalManagerSignature`, base64));
                    break; 
                case 'regionalVicePresidentSignature':
                    this.setState({
                        regionalVicePresidentSignatureTray: false, regionalVicePresidentSignature: file,  regionalVicePresidentSignatureURL: base64, 
                    });  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.regionalVicePresidentSignature`, base64));
                    break; 
                case 'globalOperationsManagerSignature':
                    this.setState({
                        globalOperationsManagerSignatureTray: false, globalOperationsManagerSignature: file, globalOperationsManagerSignatureURL: base64});  
                    dispatch(actions.change(`forms.MOCTemporaryDeviationForm.globalOperationsManagerSignature`, base64));
                    break; 
        }
    }
    renderUserName(userId: string) {
        let user: any = UserModel.getUserByUID(userId);
        if(user) {
            return `${user.props.firstName} ${user.props.lastName}` || '';
        }
        return ''; 
    }
    renderContent() {
        const { handleFormSubmit, state: { currentDate, currentTime }, props: { instance, forms, userUID } } = this; 
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        } 
        return (
            <Grid className="moc-form" style={{ width: '100%' }}>  
                <MapInputModal id="tool-box-meeting-form" />
                <Form loader={<SubmissionLoader />} model="forms.MOCTemporaryDeviationForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="Temporary Deviation - Change Request Form" noNeedBorder={true}/>
                    <AlertModal id="mocTemporaryDeviation-form-modal" />   
                    <fieldset> 
                        <Row className="show-grid" > 
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date"
                                        defaultValue={instance? convertToDateFormat(answer.dateOfIncident, 'yyyy-MM-dd'): currentDate}  
                                        model=".dateOfIncident"
                                        maxdate={ currentDate} 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label required sm={12} htmlFor="currentTime">Time:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentTime"
                                        defaultValue={instance ? answer.timeOfIncident: currentTime}
                                        type="time"
                                        model=".timeOfIncident" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={answer ? answer.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} isDisabled={this.state.isDisableGeneralEdit} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={answer && answer.groupName} onGroupChange={this.getUsersOptions} isDisabled={this.state.isDisableGeneralEdit} />
                            </Col> 
                            <LocationInput  
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="tsm-form"
                                navigator={navigator} 
                                defaultValue={answer && answer.locationName}
                                isDisabled={this.state.isDisableGeneralEdit} 
                            />
                            <Col sm={{span: 3, offset: 1 }} xs={12}>
                                <Label required sm={12}>Originator:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="originator"
                                        type="text"
                                        defaultValue={(answer && answer.originator) || (this.props.userInstance && this.props.userInstance.props.firstName) || ''}
                                        model=".originator" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.originatorSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={`${this.props.userInstance && this.props.userInstance.props.firstName } ${this.props.userInstance && this.props.userInstance.props.lastName}`}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="originatorSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.originatorSignatureURL, 'originatorSignatureTray', this.state.isDisableGeneralEdit)}
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required sm={12}>Duration of Change Request:</Label>
                                <Col sm={12} className="input">  
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.changeRequestDuration} model={`forms.MOCTemporaryDeviationForm.changeRequestDuration`} /> 
                                    <RRFInput
                                        id="changeRequestDuration"
                                        type="text"
                                        defaultValue={answer && answer.changeRequestDuration}
                                        model=".changeRequestDuration" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={6} xs={12}>
                                <Label required sm={12}>Section & Page No to be edited/amended:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.changeRequestSection} model={`forms.MOCTemporaryDeviationForm.changeRequestSection`} /> 
                                    <RRFInput
                                        id="changeRequestSection"
                                        type="text"
                                        defaultValue={answer && answer.changeRequestSection}
                                        model=".changeRequestSection" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Details of Change requested:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.changeRequestDetails} model={`forms.MOCTemporaryDeviationForm.changeRequestDetails`} /> 
                                    <RRFInput
                                        id="changeRequestDetails"
                                        type="textarea"
                                        defaultValue={answer && answer.changeRequestDetails}
                                        model=".changeRequestDetails" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>  
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Reason for Change (explain rationale for change or improvement opportunity):</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.reasonForChange} model={`forms.MOCTemporaryDeviationForm.reasonForChange`} /> 
                                    <RRFInput
                                        id="reasonForChange"
                                        type="textarea"
                                        defaultValue={answer && answer.reasonForChange}
                                        model=".reasonForChange" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Precautions taken to maintain safety as per original rule :</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.neededPrecautions} model={`forms.MOCTemporaryDeviationForm.neededPrecautions`} /> 
                                    <RRFInput
                                        id="neededPrecautions"
                                        type="textarea"
                                        defaultValue={answer && answer.neededPrecautions}
                                        model=".neededPrecautions" 
                                        isDisabled={this.state.isDisableGeneralEdit} 
                                    />
                                </Col>
                            </Col> 
                            <Col sm={12} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input"> 
                                    <AttachementInput
                                        model=".attachments"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={instance && instance.props.attachmentUrls}
                                        noNeedAttachButton={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col> 
                        </Row> 

                        <Row className="show-grid" > 
                            <Col sm={12} xs={12}><legend className="legend-header" >Management Review & Approvals</legend></Col> 
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Safety Manager</label></Col>
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.safetyManager`}
                                        type="dropdown"
                                        menuItems={this.state.safetyManagerUsers}  
                                        model={`.safetyManager`} 
                                        defaultValue={answer && answer.safetyManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="safetyManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="safetyManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.safetyManagerApprovedDate? convertToDateFormat(answer.safetyManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".safetyManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && (userUID===answer.safetyManager && !answer.operationsManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.safetyManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.safetyManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="safetyManagerSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.safetyManagerSignatureURL, 'safetyManagerSignatureTray', answer && (userUID===answer.safetyManager && !answer.operationsManagerApprovedDate)? false: true)}
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && (userUID===answer.safetyManager && !answer.operationsManagerApprovedDate ) && <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.safetyManagerComments} model={`forms.MOCTemporaryDeviationForm.safetyManagerComments`} /> }
                                    <RRFInput
                                        id="safetyManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.safetyManagerComments}
                                        model=".safetyManagerComments" 
                                        isDisabled={answer && (userUID===answer.safetyManager && !answer.operationsManagerApprovedDate )? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Operations Manager</label></Col>
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="operationsManager">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.operationsManager`}
                                        type="dropdown"
                                        menuItems={this.state.operationsManagerUsers}  
                                        model={`.operationsManager`} 
                                        defaultValue={answer && answer.operationsManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="operationsManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="operationsManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.operationsManagerApprovedDate? convertToDateFormat(answer.operationsManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".operationsManagerApprovedDate"
                                        maxdate={currentDate}
                                        isDisabled={answer && ( userUID===answer.operationsManager && answer.safetyManagerApprovedDate && !answer.productionManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.operationsManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.operationsManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="operationsManagerSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.operationsManagerSignatureURL, 'operationsManagerSignatureTray', answer && ( userUID===answer.operationsManager && answer.safetyManagerApprovedDate && !answer.productionManagerApprovedDate)? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label required sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.operationsManager && answer.safetyManagerApprovedDate && !answer.productionManagerApprovedDate) && <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.operationsManagerComments} model={`forms.MOCTemporaryDeviationForm.operationsManagerComments`} /> }
                                    <RRFInput
                                        id="operationsManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.operationsManagerComments}
                                        model=".operationsManagerComments" 
                                        isDisabled={answer && ( userUID===answer.operationsManager && answer.safetyManagerApprovedDate && !answer.productionManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Production Manager</label></Col>
                            <Col sm={4} className="input">
                                <Label sm={12} htmlFor="productionManager">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.productionManager`}
                                        type="dropdown"
                                        menuItems={this.state.productionManagerUsers}  
                                        model={`.productionManager`} 
                                        defaultValue={answer && answer.productionManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="productionManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="productionManagerApprovedDate"
                                        type="date"
                                        defaultValue={instance && answer.productionManagerApprovedDate? convertToDateFormat(answer.productionManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".productionManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApprovedDate && !answer.generalManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label sm={12}>Sign:</Label> 
                                {this.state.productionManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.productionManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="productionManagerSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.productionManagerSignatureURL, 'productionManagerSignatureTray', answer && ( userUID===answer.productionManager && answer.operationsManagerApprovedDate && !answer.generalManagerApprovedDate)? false: true)}
                            </Col>
                            
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Comments:</Label>
                                <Col sm={12} className="input">
                                    {answer && ( userUID===answer.productionManager && answer.operationsManagerApprovedDate && !answer.generalManagerApprovedDate) && <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.productionManagerComments} model={`forms.MOCTemporaryDeviationForm.productionManagerComments`} /> }
                                    <RRFInput
                                        id="productionManagerComments"
                                        type="textarea"
                                        defaultValue={answer && answer.productionManagerComments}
                                        model=".productionManagerComments"
                                        isDisabled={answer && ( userUID===answer.productionManager && answer.operationsManagerApprovedDate && !answer.generalManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12}><legend className="legend-header" >Exception Reviews & Approvals</legend></Col> 
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">General Manager</label></Col>
                            <Col sm={4} className="input">
                                <Label sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.generalManager`}
                                        type="dropdown"
                                        menuItems={this.state.generalManagerMembers}  
                                        model={`.generalManager`} 
                                        defaultValue={answer && answer.generalManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="generalManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="generalManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.generalManagerApprovedDate? convertToDateFormat(answer.generalManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".generalManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.generalManager && answer.productionManagerApprovedDate && !answer.regionalVicePresidentApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label sm={12}>Sign:</Label> 
                                {this.state.generalManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12}
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.generalManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="generalManagerSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.generalManagerSignatureURL, 'generalManagerSignatureTray', answer && ( userUID===answer.generalManager && answer.productionManagerApprovedDate && !answer.regionalVicePresidentApprovedDate)? false: true)}
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Regional Vice President</label></Col>
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.regionalVicePresident`}
                                        type="dropdown"
                                        menuItems={this.state.regionalVicePresidentMembers}  
                                        model={`.regionalVicePresident`} 
                                        defaultValue={answer && answer.regionalVicePresident}  
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="regionalVicePresidentApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="regionalVicePresidentApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.regionalVicePresidentApprovedDate? convertToDateFormat(answer.regionalVicePresidentApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".regionalVicePresidentApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.regionalVicePresident && ( (!answer.generalManager && answer.operationsManagerApprovedDate) || answer.generalManagerApprovedDate) && !answer.globalOperationsManagerApprovedDate)? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.regionalVicePresidentSignatureTray ? 
                                    <ManualSignature manualSignsm={12} 
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.regionalVicePresident)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="regionalVicePresidentSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> : this.renderSignatureField(this.state.regionalVicePresidentSignatureURL, 'regionalVicePresidentSignatureTray', answer && ( userUID===answer.regionalVicePresident && ( (!answer.generalManager && answer.operationsManagerApprovedDate) || answer.generalManagerApprovedDate) && !answer.globalOperationsManagerApprovedDate)? false: true)}
                            </Col>
                        </Row>
                        <Row className="show-grid" > 
                            <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Global Operations Manager</label></Col>
                            <Col sm={4} className="input">
                                <Label required sm={12} htmlFor="project">Name:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id={`.globalOperationsManager`}
                                        type="dropdown"
                                        menuItems={this.state.globalOperationsManagersMembers}  
                                        model={`.globalOperationsManager`} 
                                        defaultValue={answer && answer.globalOperationsManager}
                                        isDisabled={this.state.isDisableGeneralEdit}
                                    />
                                </Col>
                            </Col>  
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="globalOperationsManagerApprovedDate">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="globalOperationsManagerApprovedDate"
                                        type="date"
                                        defaultValue={answer && answer.globalOperationsManagerApprovedDate? convertToDateFormat(answer.globalOperationsManagerApprovedDate, 'yyyy-MM-dd'): null}  
                                        model=".globalOperationsManagerApprovedDate"
                                        maxdate={currentDate} 
                                        isDisabled={answer && ( userUID===answer.globalOperationsManager && answer.regionalVicePresidentApprovedDate )? false: true}
                                    />
                                </Col>
                            </Col>
                             
                            <Col sm={{span: 4 }} xs={12} className="e-signature moc-form">
                                <Label required sm={12}>Sign:</Label> 
                                {this.state.globalOperationsManagerSignatureTray ? 
                                    <ManualSignature manualSignsm={12} 
                                        autoSignTitle={this.renderUserName(forms.MOCTemporaryDeviationForm.globalOperationsManager)}
                                        saveSignature={this.saveSignature}
                                        saveGeneratedSignature={this.saveGeneratedSignature}
                                        className={'signature'}
                                        model="globalOperationsManagerSignature"
                                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                                    /> :  this.renderSignatureField(this.state.globalOperationsManagerSignatureURL, 'globalOperationsManagerSignatureTray', answer && ( userUID===answer.globalOperationsManager && answer.regionalVicePresidentApprovedDate )? false: true)}
                            </Col>
                            <Col sm={12} xs={12}>
                                <Label sm={12}>Overall Comments:</Label>
                                <Col sm={12} className="input">
                                    <VoiceRecognition labelText="" defaultValue={forms.MOCTemporaryDeviationForm.overallComments} model={`forms.MOCTemporaryDeviationForm.overallComments`} /> 
                                    <RRFInput
                                        id="overallComments"
                                        type="textarea"
                                        defaultValue={answer && answer.overallComments}
                                        model=".overallComments"  
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="mocTemporaryDeviation-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ instance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { instance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid> ); 
    }
    
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="MOCTemporaryDeviation"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    } 
}

export function mapStateToProps(state: any, ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId); 
    const editId = ownProps.match.params.id;
    let editInstance = '';
    if(editId) {
        editInstance = MocTemporaryModel.get(editId)
    }
    return { instance: editInstance , userInstance, forms: state.forms, company, editId: editId, userUID };
} 
export const MOCTemporaryDeviationForm = withRouter(connect<IMOCTemporaryDeviationFormProps, any, any>(mapStateToProps)(MOCTemporaryDeviationImpl));
const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};