import { IHistory } from '../../interfaces';
import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { showDelConfirmModal } from '../../actions/modalActions'; 
import { UserModel } from '../../model/UserModel';
import { connect } from 'react-redux';
import { setLoading } from '../../actions/loadingActions';   
import { closeCallListProps } from '../../model/MechModel';
import { getCloseCallReportDetail, getAllFollowUpForms, getCloseCallEditDetail } from '../../services/closeCallService'; 
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';  
import { getPageItemNumber } from '../../utils/generalUtils';
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: {
        push: IHistory['push']
    };
    index?: number;
    instance?: closeCallListProps;
    style?: React.CSSProperties; 
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    onDeleteAttribute(instance: closeCallListProps) { 
        instance.id = instance.uniqueId; 
        showDelConfirmModal('delete-confirmation', 'Close Call Form', instance);  
    }
    onEditAttribute(instance: closeCallListProps) { 
        getCloseCallEditDetail(instance).then(() => {
            switch(instance.formId) {
                case 'Mechanical Form': 
                    this.props.history?.push(`/mech-reports/edit/${instance.uniqueId}`);  
                    break;
                case 'Transportation Form':
                    this.props.history?.push(`/trans-reports/edit/${instance.uniqueId}`); 
                    break;
                case 'Engineering Form':
                    this.props.history?.push(`/engineer-reports/edit/${instance.uniqueId}`); 
                    break; 
            }
        }); 
        
    }
    
    getCloseCallReportDetail = (item: any) => { 
        if(!UserModel.checkUserAccess('closecall_view'))
            return;
        getCloseCallReportDetail(item).then(() => {
            this.props.history?.push(`/close-call-report-detail/${item.uniqueId}/${item.formId}`);
        }); 
    }
    renderFormList = () => {
        const { index, style } = this.props;
        if (!this.props.instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        let instance = this.props.instance;
        return (
            <Row className="report-row" style={style}
            >
                <Col className="clickable-list-item" sm={1} > 
                        { ( UserModel.checkUserAccess('closecall_view') || UserModel.checkUserAccess('closecall_edit') || UserModel.checkUserAccess('closecall_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                            { UserModel.checkUserAccess('closecall_view') && <MenuItem eventKey="1"
                                onClick={() => this.getCloseCallReportDetail(instance)}
                                className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                            { UserModel.checkUserAccess('closecall_edit') && <MenuItem eventKey="2"
                                onClick={() => this.onEditAttribute(instance)}
                                className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                            { UserModel.checkUserAccess('closecall_delete') && <MenuItem eventKey="3"
                                onClick={() => this.onDeleteAttribute(instance)}
                                className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                        </DropdownButton> }
                        <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage) }</span> 
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={2}
                    >
                        {instance.createdDate || 'NA'}
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={3}
                    >
                        {instance.userName || 'NA'}
                    </Col>
                    <Col className="clickable-list-item"
                        onClick={() => this.getCloseCallReportDetail(instance)}
                        sm={3}
                    >
                        {instance.formId || 'NA'}
                    </Col>
                    <Col className="clickable-list-item" sm={3} onClick={() => this.getFollowUpList(instance)} > 
                        <Button className="closecall-followup-button" > {instance.totalClosedFollowUp || '0'}/{instance.totalFollowUp || '0'}</Button> 
                    </Col>  
            </Row >
        );
    }
    getFollowUpList(instance: closeCallListProps) { 
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['uniqueId'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance); 
        /* let reportDetail: FollowUpParentDetails = {
            closeCallId: instance['uniqueId'],
            process: '',
            requestFrom: 'o&iReport',
        }; 
        dispatch(getFollowUpDetails(reportDetail));
        instance['closeCallId'] = instance['uniqueId'];
        showCloseCallFollowUpListModal(instance); */
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['uniqueId']);
    }
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {  
    return { 
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
