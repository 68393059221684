import { Async } from '../reusableComponents/Async';
import * as React from 'react';
import { Title } from '../reusableComponents/Title';
import { BasePage } from '../reusableComponents/BasePage';
import '../HomePage/homePage.scss';
import { UserModel } from '../../model/UserModel';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { Container as Grid, Row, Col} from 'react-bootstrap';
import oiReportsIcon from '../../images/observationIcon.png';
import officeSafetyIcon from '../../images/officeSafetyLogo.png';
import pspReportsIcon from '../../images/pspIcon.png';
import managerSafetyIcon from '../../images/ManagerSafetyWlk.png'; 

export interface ICloseCallProps {
    history: IHistory;
}

export class CloseCallImpl extends React.PureComponent<ICloseCallProps, {}> {
    constructor(props: ICloseCallProps | Readonly<ICloseCallProps>) {
        super(props);
    }

    promise = async () => {
        return null;
    }

    renderContent = () => {
            return (
            <Grid style={{ width: '100%' }} className="homepage">
                <Title /* titleColor="white" */ text="Close Call"></Title>
                <div className="links-container">
                    <Row className="show-grid">
                        <Col sm={6} xs={12}
                            className="top-row-left"
                            title="Click to fill out Mechanical Form"
                            onClick={() => this.props.history.push('/mech-reports/create')}
                        >
                            <img className="icon icon-1" src={oiReportsIcon} alt="SafeConnect"/>
                            <span className="text">Mechanical Form</span>
                        </Col>
                        <Col sm={6} xs={12}
                            className="top-row-right"
                            title="Click to fill out Transportation Form"
                            onClick={() => this.props.history.push('/trans-reports/create')}
                        >
                            <img className="icon" src={officeSafetyIcon} alt="SafeConnect"/>
                            <span className="text">Transportation Form</span>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={6} xs={12}
                            className="bottom-row-left"
                            title="Click to fill out Engineering Form"
                            onClick={() => this.props.history.push('/engineer-reports/create')} >
                            <img className="icon" src={pspReportsIcon} alt="SafeConnect"/>
                            <span className="text">Engineering Form</span>
                        </Col>
                        <Col sm={6} xs={12}
                            className="bottom-row-right"
                            title="CheckList"
                            onClick={() => this.props.history.push('/close-call-checklist')} >
                            <img className="icon" src={managerSafetyIcon} alt="SafeConnect"/>
                            <span className="text">Checklist</span>
                        </Col>
                    </Row> 
                </div>
            </Grid >
        );
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="CloseCall"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);

    return {
        userInstance,
        userId
    };
}

export const CloseCall = withRouter(connect<{}, {}, ICloseCallProps>(mapStateToProps)(CloseCallImpl));
