import { IChartValue, IPieChartObject } from '../../../interfaces';
import * as React from 'react';
import { ResponsiveContainer, PieChart as RPieChart, Pie, Cell, Tooltip } from 'recharts';
import { checkIfAllZero } from '../../../utils/generalUtils';

export interface IPieChartCardProps {
    data: IChartValue[] | undefined;
    innerRadius: number;
    colorArray: string[];
    paddingAngle: number;
    legends?: string[];
    isAnimationActive?: boolean;
    emptyDataMsg?: string;
    customTootip?: boolean;
    onClick?: (obj: IPieChartObject) => void;
    tooltipHint?: string;
}

export interface IPieChartCardState {
    className: string;
}

export class PieChart extends React.PureComponent<IPieChartCardProps, IPieChartCardState> {
    constructor(props: IPieChartCardProps | Readonly<IPieChartCardProps>) {
        super(props);
        this.state = { className: 'inactive' };
    }

    static defaultProps = {
        emptyDataMsg: 'No Records Found',
        customTootip: false
    };

    className = 'inactive';

    isMobileDevice = () => {
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            return true;
        }
        return false;
    }

    intervalTime: any;

    isTouched = (ms: number) => {
        return ms++;
    }

    renderPieChart = () => {
        const { isMobileDevice,
            props: { data, customTootip, innerRadius, onClick, isAnimationActive, colorArray, tooltipHint } } = this;
        return (
            <ResponsiveContainer>
                <RPieChart>
                    <Pie
                        data={data}
                        innerRadius={innerRadius || 60}
                        dataKey="value"
                        paddingAngle={1}
                        onTouchStart={(obj, e) => {
                            this.intervalTime = setTimeout(() => {
                                return onClick ? onClick(obj) : null;
                            }, 1000);
                        }}
                        onTouchEnd={() => {
                            clearInterval(this.intervalTime);
                            this.intervalTime = null;
                        }}
                        onClick={obj => onClick && !isMobileDevice() ? onClick(obj) : null}
                        isAnimationActive={isAnimationActive}
                    >
                        {
                            (data || []).map((data, index) => {
                                return <Cell key={index} fill={`#${colorArray[index]}`} />;
                            })
                        }
                    </Pie>
                    {customTootip ? <Tooltip
                        content={({ payload }) =>
                            <ToolTip payload={payload} isMobile={this.isMobileDevice()} tooltipHint={tooltipHint}/>
                        } /> : <Tooltip />}
                </RPieChart>
            </ResponsiveContainer>
        );
    }

    render() {
        const { props: { data, emptyDataMsg } } = this;
        if (data && !checkIfAllZero(data)) {
            return <p className={this.className} style={noObservationStyle}>{emptyDataMsg}</p>;
        }
        return this.renderPieChart();
    }
}
const ToolTip = ({ payload, isMobile, tooltipHint }: any) => {
    return <div style={container}>
        <h3 style={text}>
            {payload.length ? payload[0].value : ''}
        </h3>
        {isMobile ?
            <span>Tap &amp; Hold to view <br /> Activities</span> :
            <span>{tooltipHint ? tooltipHint: 'Click to view Activities'}</span>
        }
    </div >;
};

const noObservationStyle: React.CSSProperties = {
    height: '92%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const container: React.CSSProperties = {
    width: 'auto',
    height: 'auto',
    background: 'white',
    fontSize: '10px',
    textAlign: 'center',
    display: 'inline - block',
    border: '2px solid #cecece',
    padding: '6px 6px',
};

const text: React.CSSProperties = {
    margin: 0,
    lineHeight: '26px',
    fontSize: '30px',
    marginBottom: '10px',
};
