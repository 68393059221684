import * as React from 'react';
import { Col, Row } from 'react-bootstrap'; 
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { saveFilterData } from '../../actions/miscellaneousActions';
import { IChartValue } from '../../interfaces';  
import { applyMonthlyFilter, applyDateRangeFilter, applyYearlyFilter, getCovidShortSummary } from '../../services/covid19Service';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { Card } from '../reusableComponents/Card'; 
import { StackedAreaChart } from '../reusableComponents/ChartComponents/StackedAreaChart';
import { StackedBarChart } from '../reusableComponents/ChartComponents/StackedBarChart';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter'; 
import { DateRangeFilter } from '../reusableComponents/FilterComponents/DateRangeFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title'; 
import './Covid19Summary.scss';
import { format } from 'date-fns';
import { Form } from '../reusableComponents/FormComponents/Form'; 

export interface ICovid19SummaryProps {
    reportsData: any;
    pandemicShortSummary: any
    getRef?: (value: any) => void;
    getFilterBy?: (value: any) => void;
    setFilterBy?: (value: any) => void;
    id: string;
    isPrint: boolean;
    filterBy: string; 
    isAnimationActive?: boolean;
    totalUsers: number; 
}
export interface ICovid19SummaryState {
    view: boolean; 
    showMonthlyFilter: boolean;
    showDateRangeFilter: boolean; 
    showYearlyFilter: boolean; 
    data: IChartValue[]; 
    isLoading: boolean;
    filterBy: string;
    currentDate: string;
    chartType: boolean;
}

export class Covid19SummaryData extends React.Component<ICovid19SummaryProps,
    ICovid19SummaryState> {
    constructor(props: ICovid19SummaryProps) {
        super(props);
        this.state = {
            view: false,
            data: [],   
            showMonthlyFilter: true, showDateRangeFilter: false, showYearlyFilter: false, 
            filterBy: 'monthly',
            isLoading: false, currentDate: format(new Date(), 'yyyy-MM-dd'), chartType: false,
        };
    } 
    ref: any;   
    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        } 
        const defaultYear = new Date().getFullYear().toString();  
        const defaultMonth = ("0" + (new Date().getMonth() + 1)).slice(-2).toString(); 
        saveFilterData('monthly', defaultYear, defaultMonth); 
        return <MonthlyFilter defaultValue={[defaultYear,defaultMonth]} onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilter(year, month);
        }} />;
    } 

    renderDateRangeFilter = () => {
        const { showDateRangeFilter } = this.state;
        if (!showDateRangeFilter) {
            return; 
        }
        return <DateRangeFilter onApply={(startDate, endDate) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', startDate, endDate);
            applyDateRangeFilter(startDate, endDate);
        }} />;
    }
    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return; 
        }
        const defaultYear = new Date().getFullYear();  
        return <YearlyFilter defaultValue={defaultYear} onApply={(year) => {
            setLoading('ReportsPage');
            saveFilterData('yearly', year);
            applyYearlyFilter(year);
        }} />;
    }
    handleFilterSelect = async (value: string) => {
        if (value === 'monthly') {
            this.setState({ showMonthlyFilter: true,showDateRangeFilter: false, showYearlyFilter: false, filterBy: 'monthly' });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'dateRange') {
            this.setState({ showMonthlyFilter: false,showDateRangeFilter: true, showYearlyFilter: false, filterBy: 'dateRange' });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'yearly') {
            this.setState({ showMonthlyFilter: false,showDateRangeFilter: false, showYearlyFilter: true, filterBy: 'yearly' });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } 
       
    }

    renderFilters = () => { 
        return <div className="filter-charts" style={{ /* padding: '20px' */ }}>
            <Alert style={{ height: '40px' }} id="covid19-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[ 
                        { value: 'monthly', label: 'Monthly' },{ value: 'dateRange', label: 'Date Range' }, { value: 'yearly', label: 'Yearly' }]}
                        model=".filterType"
                        defaultValue="monthly"
                    />
                </Col>
            </Row>
            { this.renderMonthlyFilter() } 
            { this.renderDateRangeFilter() }
            { this.renderYearlyFilter() } 
        </div>;
    } 
    handleChartType = async (value: boolean) => { 
        this.setState({chartType: value})
    }
    renderChart = () => { 
        //let strokeColors = ['82ca9d', 'ffc658']; 
        let strokeColors = ['008000', 'FF8C00'];
        //let strokeColors = ['228B22', 'ff4c00'];

        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <div  style={{ display: 'flex'}}>
                        <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px', flex: '1 0 auto', marginLeft: '20%' }}>
                            Total Count of <span style={{ color: `#${strokeColors[0]}` }}>
                                Safe</span> / <span style={{ color: `#${strokeColors[1]}`}}>Risk
                            </span> COVID-19 Assessments 
                        </h4>   
                        <span style={{ float:'right', /* marginRight: '4%', */ marginTop: '10px'}}>
                            <Form model="forms.reportFilterForm">
                                <label className="checkbox-label" htmlFor="chartType">Bar Chart</label>
                                <RRFInput
                                    id="chartType"
                                    model=".chartType"
                                    type="checkbox" 
                                    defaultValue={false} 
                                    onChange={this.handleChartType} className="chartType"
                                />
                                <label htmlFor="nearMiss"></label>
                                <label className="checkbox-label" htmlFor="chartType">Area Chart</label>
                            </Form>
                        </span>
                    </div> 
                    <Row className="show-grid line-chart closeCallSummary">
                        <Col sm={12} style={{ height: '300px' }}> 
                            {this.state.chartType? <StackedAreaChart data={this.props.reportsData.summary} dataKey={['Safe', 'Risk']} strokeColors={strokeColors}/> : <StackedBarChart data={this.props.reportsData.summary} dataKey={['Safe', 'Risk']} strokeColors={strokeColors}/>  } 
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
    getCovidShortSummary = async (date: any) => {
        setLoading('ReportsPage');
        await getCovidShortSummary(date);
        setSuccess('ReportsPage');
    }
    renderShortSummary = () => {
        const { currentDate } = this.state;
        const { pandemicShortSummary } = this.props;
        return <div className="shortSummary">
                <Row className="show-grid"> 
                    <Col sm={12} md={6} lg={4} xs={12} className="card-container"> 
                        <Row className="clickable-report-card card1" sm={12}>  
                            <Col sm={4} className="title date"> 
                                <h6>Date</h6>
                            </Col>
                            <Col sm={8} className="dateFilter"> 
                                <RRFInput
                                    id="currentDate"
                                    type="date" 
                                    model=".dateOfIncident"
                                    defaultValue={ currentDate } 
                                    maxdate={currentDate}
                                    onChange={this.getCovidShortSummary}
                                /> 
                            </Col>  
                            <Col sm={5} className="title users  " > 
                                <h6>Total Users</h6> 
                            </Col>
                            <Col sm={7} className="data  "> 
                                <h4>{ pandemicShortSummary.userCount || 0}</h4>
                            </Col>
                             
                        </Row> 
                    </Col> 
                    <Col sm={12} md={6} lg={2} xs={12} className="card-container" > 
                        <Row className="clickable-report-card">  
                            <Col sm={12} className="title assessed"> 
                                <h6>Total Assessed</h6> 
                            </Col>
                            <Col sm={12} className="border-top data"> 
                                <h4>{ pandemicShortSummary.total || 0}</h4> 
                            </Col>
                        </Row>  
                    </Col>
                    <Col sm={12} md={6} lg={3} xs={12} className="card-container"> 
                        <Row className="clickable-report-card"> 
                            <Col sm={12} className="title safe"> 
                                <h6>Total Safe</h6> 
                            </Col> 
                            <Col sm={6} className="data" > 
                                <h4>{pandemicShortSummary.negative || 0}</h4>
                            </Col>
                            <Col sm={6} className="data border-left" > 
                                <h4>{ pandemicShortSummary.negativePercentage || 0}%</h4>
                            </Col> 
                        </Row> 
                    </Col>
                    <Col sm={12} md={6} lg={3} xs={12} className="card-container"> 
                        <Row className="clickable-report-card"> 
                            <Col sm={12} className="title risk"> 
                                <h6>Total Risk</h6> 
                            </Col> 
                            <Col sm={6} className="data"> 
                                <h4>{ pandemicShortSummary.positive || 0}</h4>
                            </Col>
                            <Col sm={6} className="data border-left"> 
                                <h4>{ pandemicShortSummary.positivePercentage || 0}%</h4>
                            </Col>  
                        </Row> 
                    </Col> 
                </Row>
            
        </div>;
    } 


    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data" >
            <Title key="title" text="Covid-19 Report Summary"></Title>
            {this.renderShortSummary() }
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''}
                    {this.renderFilters()}
                    {this.renderChart()}  
                </div>
            </div>
        </div>;
    }

    render() {
        return [
            <Async
                key="content"
                identifier="ReportsPage"
                promise={() => setSuccess('ReportsPage')}
                loader={this.renderContent(true)}
                content={this.renderContent(false)}
                error={<ErrorPage />}
            />
        ];
    }
}
