import * as React from 'react';
import {connect} from 'react-redux';
import Select from "react-select";
import {Col, Row} from "react-bootstrap";
import {format} from "date-fns";
import {IHistory} from '../../../interfaces';
import {UserModel} from '../../../model/UserModel';
import {Async} from '../../reusableComponents/Async';
import {BasePage} from '../../reusableComponents/BasePage';
import {DetailsPageWrapper} from '../../reusableComponents/DetailsPageWrapper';
import {ErrorPage} from '../../reusableComponents/ErrorPage';
import {Gallery} from '../../reusableComponents/ImageComponents/Gallery';
import {Loader} from '../../reusableComponents/Loader';
import {Title} from '../../reusableComponents/Title';
import {Button} from '../../reusableComponents/FormComponents/Button';
import {getFormData} from "../../../services/texas811Service";
import {PreExcavationChecklistV2Model} from "../../../model/PreExcavationChecklistModel";
import * as displayText from "./language.json";
import {PlantLocationMap} from "../../reusableComponents/GoogleMapsWithDrawing/GoogleMapsWithDrawing";
import { AttachementInput } from '../../reusableComponents/FormComponents/AttachementInput';


export interface IPreExcavationDetailsPageProps {
    id: string;
    instance: PreExcavationChecklistV2Model;
    history: IHistory;
}

export interface IPreExcavationDetailsPageState {
    displayText: any;
    language: string;
}

export class PreExcavationDetailsPageImpl extends React.PureComponent<IPreExcavationDetailsPageProps, IPreExcavationDetailsPageState> {
    constructor(props: IPreExcavationDetailsPageProps | Readonly<IPreExcavationDetailsPageProps>) {
        super(props);
        this.state = {
            displayText: !props.instance ? displayText.eng : props.instance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.instance ? 'eng' : props.instance.props.language === 'spa' ? 'spa' : 'eng'
        }
    }

    promise = async () => {
        if (!this.props.instance) {
            await getFormData('preExcavationChecklist', this.props.id);
            setTimeout(() => {
                const {instance}: any = this.props;
                this.setState({
                    displayText: !instance ? displayText.eng : instance.props.language === 'spa' ? displayText.spa : displayText.eng,
                    language: !instance ? 'eng' : instance.props.language === 'spa' ? 'spa' : 'eng'
                });
            }, 100);
        }
    }

    getObserverName = (userID: string) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    }

    renderContent = () => {
        const {instance} = this.props;
        if (!instance) {
            return <Loader/>;
        }

        const {displayText, language} = this.state;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }
        const {props: {outfallsList, shapesList, coordinatesPointerList, coords}} = instance;
        return <div>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle2}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value = {{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <Row className={'show-grid'}>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.date} &nbsp;&nbsp;&nbsp;
                            <span>{answer.excavationDate ? format(new Date(answer.excavationDate), 'MM/dd/yyyy') : ''}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.jobNo}&nbsp;&nbsp;&nbsp;
                            <span>{answer.jobNo}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.foreman}&nbsp;&nbsp;&nbsp;
                            <span>{answer.crewForeman}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.streetAddress}&nbsp;&nbsp;&nbsp;
                            <span>{answer.streetAddress}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.city}&nbsp;&nbsp;&nbsp;
                            <span>{answer.city}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.state}&nbsp;&nbsp;&nbsp;
                            <span>{answer.state}</span>
                        </label>
                    </Col>
                </Col>
                
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.oneCallTicketNo} &nbsp;&nbsp;&nbsp;
                            <span>{answer.oneCallTicketNo}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.updateTicketNo} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.updatTicket && answer.updatTicket === 'true') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
            </Row>
            <Row className={'show-grid top-info'}>
                <p>{displayText.doNotProceedLabel}</p> 
            </Row>
            <Row className={'show-grid'}>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest1Part1}<a href="https://www.texas811.org" target="_blank" rel="noreferrer" >www.texas811.org</a>{displayText.quest1Part2}</label> 
                </Col> 
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label">{displayText.quest1Ans1}</label> 
                        <label className="checkbox-label">{displayText.quest1Ans2}</label> 
                    </Col>
                </Col> 
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest2}</label> 
                </Col> 
                
                <Col sm={12} xs={12}>
                    <label className="checkbox-label">{displayText.quest2Hint}</label> 
                </Col>

                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.gas}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.naturalGasContactNo || '')}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.pipeline}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.pipelineContactNo || '')}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.electric}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.electricContactNo || '')}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.telephone}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.telephoneContactNo || '') }</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.water}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.waterContactNo || '') }</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.other}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isOtherUtilityMarked && answer.isOtherUtilityMarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                {(answer.isOtherUtilityMarked && answer.isOtherUtilityMarked === '1') && <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.specifyOtherUtility}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isOtherUtilityMarked && answer.otherUtility) || ''}</span>
                        </label>
                    </Col>
                </Col> }

                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.speicalInstructions} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.speicalInstructions  || '')}</span>
                        </label>
                    </Col>
                </Col> 
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest3}</label> 
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.quest3Ans1}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isScopeOfWork && answer.isScopeOfWork === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.quest3Ans2}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isWorkToBegin && answer.isWorkToBegin === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest4}</label> 
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.gasMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isGasUnmarked && answer.isGasUnmarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.electricMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isElectricUnmarked && answer.isElectricUnmarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.telephoneMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isTelephoneUnMarked && answer.isTelephoneUnMarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.cableTvMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isCableTVUnmarked && answer.isCableTVUnmarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.waterMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isWaterUnmarked && answer.isWaterUnmarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.sewerMarked}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isSewerUnmarked && answer.isSewerUnmarked === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} className="input" style={{marginTop: '15px', marginLeft: '20px'}}>
                    <label className="checkbox-label" >{displayText.quest4Hint1}</label>
                    <label className="checkbox-label" >{displayText.quest4Hint2}</label>
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest5}</label> 
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.quest5Ans1}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isSitePhotographed && answer.isSitePhotographed === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Row className={'show-grid attachement'}>
                    <AttachementInput
                        id={'excavationUploads'}
                        noNeedAttachButton={true}
                        model={'.attachments'}
                        uploadedImages={instance && instance.props.attachmentUrls}
                        multiple={true}
                    />
                </Row>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest6}</label> 
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.quest6Ans1}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isExposedMarkedUtilities && answer.isExposedMarkedUtilities === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.quest6Ans2}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isMechanizedEquipmentUsed && answer.isMechanizedEquipmentUsed === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest7}</label> 
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest8}</label> 
                </Col>
                <Col sm={12} xs={12}>
                    <label className="checkbox-label key-data">{displayText.quest9}</label> 
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.gasRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isGasDamaged && answer.isGasDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.electricRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isElectricDamaged && answer.isElectricDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.telephoneRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isTelephoneDamaged && answer.isTelephoneDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.cableTvRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isCableTvDamaged && answer.isCableTvDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.waterRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isWaterDamaged && answer.isWaterDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.sewerRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isSewerDamaged && answer.isSewerDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.unknownPipeRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isUnknownPipeDamaged && answer.isUnknownPipeDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={3} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.unknownCableRaw}: &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isUnknownCableDamaged && answer.isUnknownCableDamaged === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.digupTicketNo}: &nbsp;&nbsp;&nbsp;
                            <span>{answer.digupTicketNo || ''}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data" >{displayText.facilityOwner}: &nbsp;&nbsp;&nbsp;
                            <span>{answer.facilityOwner || ''}</span>
                        </label>
                    </Col>
                </Col>
            </Row>
            <div className="pagebreak"> </div> 
            <legend><span className="yellow-color-title">{displayText.mapDiagram}</span></legend>
            <Row className={'show-grid'}>
                <PlantLocationMap
                    locationAddress={answer.streetAddress}
                    locationCoords={coords} shapesList={shapesList}
                    outfallsList={outfallsList} coordinatesPointerList={coordinatesPointerList}
                    type={'nonEdit'}
                />
            </Row> 
            
        </div>
    }

    renderFormDetails = () => {
        return <Async
            identifier="PreExcavationFormDetail"
            promise={this.promise}
            error={<ErrorPage/>}
            loader={<Loader/>}
            content={this.renderContent()}
        />;
    }

    render() {
        let fileName = '';
        const {instance} = this.props;
        if (instance) {
            const {instance: {props: {excavationDate}}} = this.props;
            fileName = `PreExcavation checklist for ${excavationDate}`;
        }
        return (
            <BasePage>
                <Gallery images={instance ? instance.props.attachments : []}/>
                <DetailsPageWrapper pdfFileName={fileName} className="office-safety-report report-details preExcavationChecklist"
                                    customButton={<Button className="closeButton" redirectTo="goBack" type="button"
                                                          onClick={() => this.props.history?.goBack()}>
                                        <i className="fa fa-ban" aria-hidden="true"/>&nbsp;
                                        Close
                                    </Button>
                                    }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const id = ownProps.match.params.id;
    const instance = PreExcavationChecklistV2Model.get(id);
    return {
        id,
        instance
    };
}

export const PreExcavationDetailsPage = connect<{}, {}, IPreExcavationDetailsPageProps>(mapStateToProps)(PreExcavationDetailsPageImpl);
