import {IHistory} from '../../interfaces';
import * as React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {hideModal} from '../../actions/modalActions';
import {Button} from '../reusableComponents/FormComponents/Button';
import {withRouter} from 'react-router-dom';
import {showAlert} from '../../utils/generalUtils';
import {delCustom} from '../../utils/HTTP';
import {DELETE_FAILED, DELETE_SUCCESS} from '../../constants/general';
import {OIModel} from '../../model/OIModel';
import {OfficeSafetyModel} from '../../model/OfficeSafetyModel';
import {JSAModel} from '../../model/JSAModel';
import {IncidentNotificationModel} from '../../model/IncidentNotificationModel';
import {IncidentReportModel} from '../../model/IncidentReportModel';
import {PersonalSafetyModel} from '../../model/PersonalSafetyModel';
import {CloseCallCheckListModel} from '../../model/MechModel';
import {Async} from '../reusableComponents/Async';
import {getAllCheckListForms} from '../../services/attributeService';
import {getAllCloseCallForms, getAllFollowUpForms} from '../../services/closeCallService';
import {UserModel} from '../../model/UserModel';
import {RiskAssessmentModel} from '../../model/RiskAssessmentModel';
import {followUpListProps} from '../../model/MechModel';
import {AuditModel} from '../../model/AuditModel';
import {PreExcavationChecklistModel, PreExcavationChecklistV2Model} from "../../model/PreExcavationChecklistModel";
import {UtilityDamageReportModel} from "../../model/UtilityDamageReportModel";
import {ToolBoxMeetingModel} from '../../model/ToolBoxMeetingModel';
import {MocPermanentModel, MocTemporaryModel} from '../../model/MOCModel';
import {PermitToWorkModel} from "../../model/PermitToWorkModel"; 
import {HPModel} from '../../model/HPModel';
import { DesignationModel, LocationModel } from '../../model/MiscellaneousModel';

export interface IDeleteConfirmationModalProps {
    id?: string;
    componentType: string;
    showModal: boolean;
    modalID: string;
    instance: any;
    history?: IHistory;
    onConfirm?: (attributeData: any) => void;
    followUpInstances: followUpListProps[];
}

export interface IDeleteConfirmationModalState {
    showModal: boolean;
}

export class DeleteConfirmationModalImpl extends React.PureComponent<IDeleteConfirmationModalProps, IDeleteConfirmationModalState> {
    constructor(props: IDeleteConfirmationModalProps | Readonly<IDeleteConfirmationModalProps>) {
        super(props);
        this.state = {showModal: true};
    }

    identifier = 'DeleteForm';

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    showModal = () => {
        const {props: {id, modalID, history, instance, componentType}} = this;
        if (modalID !== id) {
            return <div></div>;
        }
        return (
            <Modal className="confirmation-modal" show={this.state.showModal} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: 'white'}}>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {componentType} ?
                    <div className="modal-buttons row">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                                onClick={() => history && this.deleteRow(instance, componentType, history)}
                        >
                            Yes
                        </Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                                onClick={() => hideModal()}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    } 
    async deleteRow(data: any, componentType: string, history: IHistory) {
        //setLoading(this.identifier); 
        // this.hideModal();
        try {
            switch (componentType) {
                case 'O&I form':
                    new OIModel(data).$delete();
                    break;
                case 'Office Safty Form':
                    new OfficeSafetyModel(data).$delete();
                    break;
                case 'JSA Form':
                    new JSAModel(data).$delete();
                    break;
                case 'Risk Assessment form':
                    new RiskAssessmentModel(data).$delete();
                    break;
                case 'Incident Notification Form':
                    new IncidentNotificationModel(data).$delete();
                    break;
                case 'Incident Investigative Form':
                    new IncidentReportModel(data).$delete();
                    break;
                case 'Personal Safty form':
                    new PersonalSafetyModel(data).$delete();
                    break;
                case 'pre excavation checklist report':
                    new PreExcavationChecklistModel(data).$delete();
                    break;
                case 'Pre Excavation Checklist(v2) Report':
                    new PreExcavationChecklistV2Model(data).$delete();
                    break;
                case 'utilityDamageReport':
                    new UtilityDamageReportModel(data).$delete();
                    break;
                case 'Closecall Checklist Form':
                    new CloseCallCheckListModel(data).$delete();
                    break;
                case 'Closecall Checklist Question':
                case 'Manager Checklist Question':
                case 'Supervisor Checklist Question':
                case 'Checklist Question':
                    hideModal();
                    this.props.onConfirm && this.props.onConfirm(data);
                    return;
                case 'Audit/Inspection Form':
                    new AuditModel(data).$delete();
                    break;
                case 'TSM Form': 
                    new ToolBoxMeetingModel(data).$delete();
                    break;
                case 'MOC-Permanent Deviation Report':
                    new MocPermanentModel(data).$delete();
                    break;
                case 'MOC-Temporary Deviation Report':
                    new MocTemporaryModel(data).$delete();
                    break;
                case 'PTW Report':
                    new PermitToWorkModel(data).$delete();
                    break;
                case 'HP Form':
                    new HPModel(data).$delete();
                    break;
                case 'Location Form':
                    new LocationModel(data).$delete(); 
                    data['delFormType'] = 'locationData'
                    /* await delCustom(`/Location`, data); 
                    showAlert(DELETE_SUCCESS, 'delete-success', 'success', () => { });
                    return; */
                    break;
                case 'Designation Form':
                    new DesignationModel(data).$delete(); 
                    data['delFormType'] = 'designationData'; 
                    break;
            }
            data['updatedBy'] = UserModel.getUserUID();
            await delCustom(`/EditForm`, data);
            switch (componentType) {
                case 'Safety Walk Form':
                    getAllCheckListForms('SafetyChecklist');
                    break;
                case 'Close Call Form':
                    getAllCloseCallForms();
                    break;
                case 'Follow_up Form':
                    if (data['followUpType'] && data['followUpType'] === 'temp') {
                        await getAllFollowUpForms(data['closeCallId'], data['followUpType']);
                    } else {
                        await getAllFollowUpForms(data['closeCallId']);
                        let followUpInstance = this.props.followUpInstances;
                        let open = 0;
                        let closed = 0;
                        followUpInstance.forEach((res) => {
                            if (res.status === 'Open') {
                                open++;
                            } else {
                                closed++;
                            }
                        });

                        let parantId = data['followUpFor']['key'];
                        switch (data['followUpFor']['formId']) {
                            case 'riskAssessmentForm':
                                let riskInstance = RiskAssessmentModel.get(parantId).props;
                                riskInstance['totalClosedFollowUp'] = closed;
                                riskInstance['totalFollowUp'] = followUpInstance.length;
                                riskInstance['totalOpenFollowUp'] = open;
                                new RiskAssessmentModel(riskInstance).$save();
                                break;
                        }
                    }

                    break;
                case 'Checklist Form':
                    this.props.onConfirm && this.props.onConfirm(data);
                    break;
            }
            //setSuccess(this.identifier);
            showAlert(DELETE_SUCCESS, 'delete-success', 'success', () => { });
            //return response;
            return;
        } catch (error) {
            showAlert(DELETE_FAILED, 'delete-success', 'danger');
            return error;
        }
    }
    render() {
        return <Async
            identifier={this.identifier}
            promise={undefined}
            error={<div></div>}
            content={this.showModal()}
        />;
    }
}

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('modalID'),
        showModal: state.modal.get('showModal'),
        componentType: state.modal.get('componentType'),
        instance: state.modal.get('instance'),
        followUpInstances: state.report.get('modalReportDataList'),
    };
}

export const DeleteConfirmationModal = withRouter(connect<IDeleteConfirmationModalProps, any, any>(mapStateToProps)(DeleteConfirmationModalImpl));
