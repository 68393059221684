import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces'; 
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './ListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { getAllAuditForms } from '../../services/auditService';
import { AuditModel } from '../../model/AuditModel';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
declare var require: any;
const queryString = require('query-string'); 

export interface IAuditFormListPageProps {
    auditInstances: AuditModel[];
    history?: IHistory;
    totalPageCount: number;
    forms: IForms;
    axiosPreviousToken: any;
}

export class AuditFormListPageImpl extends React.PureComponent<IAuditFormListPageProps, {}> {
    constructor(props: IAuditFormListPageProps | Readonly<IAuditFormListPageProps>) {
        super(props);
    }
    sortListing = { 0: 'date', 1: 'username', 2:'groupName', 3: 'referenceNo', 4: 'overallPercent' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }
    getReportData = async (sortIndex='false') => {
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {};  
        urlParams.sortIndexField = this.sortListing[sortIndex];
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllAuditForms(urlParams, this.props.forms );
        setSuccess('AllListPage'); 
    }

    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report"
                onClick={() => { history?.push('/audit'); }}
            >
                +
            </button>
        );
    } 

    renderContent = () => {
        const { auditInstances } = this.props; 
        return <div className="office-safety-wrapper"> 
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
            {<ExcelExport formType="auditForms" fileName="Audit Report" />}
            <><ListPage
                    pageHeading="Audit Report List"
                    instances={auditInstances}
                    rowHeadings={['Date','User', 'Group Name',`Reference No`,'Audit Result (Score/%)', 'Follow-Up Status' ]}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={[ 'date', 'username', 'projectName', 'groupName', 'jsano']}
                    searchPlaceHolder="Enter project, group, reference no to search..."
                    emptyInstancesMessage="No Office Safety report found."
                    sm={[2, 2, 2, 2, 2, 1]}
                    currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                { UserModel.checkUserAccess('office_safety_add') && this.renderAddNewFormButton()} 
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={AuditModel as any}
                    identifier="OfficeSafetyFormReports"
                />
            </>
        </div>;
    }

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="OfficeSafetyFormReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const auditInstances = AuditModel.list(); 
    return { auditInstances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),};
}

export const AuditFormListPage = withRouter(connect<IAuditFormListPageProps, any, any>
    (mapStateToProps)(AuditFormListPageImpl)); 
