import * as React from 'react';
import { BasePage } from '../../reusableComponents/BasePage';
import { Row, Col, Container as Grid } from 'react-bootstrap';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { Title } from '../../reusableComponents/Title';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { Alert } from '../../Alert';
import { Button } from '../../reusableComponents/FormComponents/Button';
import './personalSafetyForm.scss';
import { constructAnswers, dispatch, isFormEmpty, showAlert, sortBy } from '../../../utils/generalUtils';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import PropTypes from 'prop-types';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { connect } from 'react-redux';
import { UserModel } from '../../../model/UserModel';
import { getAllUsers } from '../../../services/userService';
import { submitPersonalSafetyForm, updatePersonalSafetyForm } from '../../../services/personalSafetyFormService';
import { Async } from '../../reusableComponents/Async';
import { Loader } from '../../reusableComponents/Loader';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { format } from 'date-fns';
import { FileInput } from '../../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../../reusableComponents/FormComponents/VideoInput';
import { ProjectInput } from '../../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../../reusableComponents/FormComponents/GroupInput';
import { PersonalSafetyModel } from '../../../model/PersonalSafetyModel';
import { actions } from 'react-redux-form';
import { getPersonalSafetyFormDetails } from '../../../services/personalSafetyFormService';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../../interfaces';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { VoiceRecognition } from '../../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IPersonalSafetyFormProps {
    userUID: string;
    userId: string;
    userInstance: UserModel;
    history?: IHistory;
    companyName: string;
    id: string; 
    editInstance?: PersonalSafetyModel;
    forms?: IForms;
    editId: string;
} 

export interface IPersonalSafetyFormState {
    isDisabled: boolean;
    firstLast?: string;
    company?: string;
    credibilityDate: string;
    actionDate: string;
    resilienceDate: string;
    engagementDate: string;
    considerationsDate: string;
    project?: string;
    group?: string;
    minCredibilityDate: string;
    minActionDate: string;
    minResilienceDate: string;
    minEngagementDate: string;
    minConsiderationsDate: string;
}

export class PersonalSafetyFormImpl extends React.PureComponent<IPersonalSafetyFormProps, IPersonalSafetyFormState> {
    constructor(props: IPersonalSafetyFormProps | Readonly<IPersonalSafetyFormProps>) {
        super(props);
        this.state = {
            firstLast: '', isDisabled: false,
            credibilityDate: format(new Date(), 'yyyy-MM-dd'),
            actionDate: format(new Date(), 'yyyy-MM-dd'),
            resilienceDate: format(new Date(), 'yyyy-MM-dd'),
            engagementDate: format(new Date(), 'yyyy-MM-dd'),
            considerationsDate: format(new Date(), 'yyyy-MM-dd'),
            minCredibilityDate: format(new Date(), 'yyyy-MM-dd'),
            minActionDate: format(new Date(), 'yyyy-MM-dd'),
            minResilienceDate: format(new Date(), 'yyyy-MM-dd'), 
            minEngagementDate: format(new Date(), 'yyyy-MM-dd'),
            minConsiderationsDate: format(new Date(), 'yyyy-MM-dd'),
        };
    }

    componentDidUpdate() {
        const checkURL = window.location.href.toString().split('/');
        if (checkURL[checkURL.length - 1] === 'create') {
            this.promise();
        }
    } 
    formModel = 'forms.personalSafetyForm';

    getChildContext() {
        return { formModel: this.formModel };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleSubmit = ({ name, company, credebilityHelp, credibilityDate, credibilityComment, actionHelp, actionDate, actionComment, resilienceHelp, resilienceDate, resilienceComment, engagementHelp, engagementDate, engagementComment, considerationsHelp, considerationsDate, considerationsComment, personalSafetyImages, groupName, projectName, supervisor, personalSafetyVideos }: any) => { 
        const { id, userInstance, history, editId, forms } = this.props; 
        let answers: Array<any> = [];
        if(editId) {
            let formInstance = PersonalSafetyModel.get(editId); 
            let followUpStatus = formInstance.props.followUp===0?true:false;
            const submittedValues: any = {
                credebilityHelp,
                credibilityDate,
                credibilityComment: followUpStatus? credibilityComment:'',
                actionHelp,
                actionDate,
                actionComment: followUpStatus? actionComment:'',
                resilienceHelp,
                resilienceDate,
                resilienceComment: followUpStatus? resilienceComment:'',
                engagementHelp,
                engagementDate,
                engagementComment: followUpStatus? engagementComment:'',
                considerationsHelp,
                considerationsDate,
                considerationsComment: followUpStatus? considerationsComment:'',
                
                supervisor,
            };
            if(followUpStatus) {  
                if (isFormEmpty({ supervisor, projectName, groupName, credebilityHelp, credibilityDate, actionHelp, actionDate, resilienceHelp, considerationsHelp, resilienceDate, engagementHelp, engagementDate, considerationsDate, credibilityComment, actionComment, resilienceComment, engagementComment, considerationsComment,  })) {
                    showAlert(EMPTY_FORM_MESSAGE, 'personal-form', 'danger');
                    return;
                }
            } else { 
                if (isFormEmpty({ supervisor, projectName, groupName, credebilityHelp, credibilityDate, actionHelp, actionDate, resilienceHelp, resilienceDate, engagementHelp, engagementDate, considerationsHelp, considerationsDate
                })) {
                    showAlert(EMPTY_FORM_MESSAGE, 'personal-form', 'danger');
                    return;
                } 
            } 
            answers = constructAnswers(submittedValues); 
            return submitPersonalSafetyForm(userInstance, groupName, projectName, answers, personalSafetyImages, personalSafetyVideos, history, forms, editId,formInstance );
            //return updatePersonalSafetyForm(userInstance, groupName, projectName, answers, formInstance, history); 
        } else if (id ) {
            let formInstance = PersonalSafetyModel.get(id);
            const submittedValues: any = {
                credebilityHelp: formInstance.props.credebilityHelp,
                credibilityDate: formInstance.props.credibilityDate,
                credibilityComment,
                actionHelp: formInstance.props.actionHelp,
                actionDate: formInstance.props.actionDate,
                actionComment,
                resilienceHelp: formInstance.props.resilienceHelp,
                resilienceDate: formInstance.props.resilienceDate,
                resilienceComment,
                engagementHelp: formInstance.props.engagementHelp,
                engagementDate: formInstance.props.engagementDate,
                engagementComment,
                considerationsHelp: formInstance.props.considerationsHelp,
                considerationsDate: formInstance.props.considerationsDate,
                considerationsComment,
            };
            if (isFormEmpty({ credibilityComment, actionComment, resilienceComment, engagementComment, considerationsComment })) {
                showAlert(EMPTY_FORM_MESSAGE, 'personal-form', 'danger');
                return;
            }
            answers = constructAnswers(submittedValues);
            return updatePersonalSafetyForm(userInstance, groupName, projectName, answers, formInstance, history);
        } else {
            const submittedValues = {
                credebilityHelp, credibilityDate, credibilityComment: '', actionHelp,
                actionDate, actionComment: '', resilienceHelp, resilienceDate, resilienceComment: '',
                engagementHelp, engagementDate, engagementComment: '', considerationsHelp, considerationsDate, considerationsComment: '', supervisor
            };
            if (isFormEmpty({ supervisor, projectName, groupName, credebilityHelp, credibilityDate, actionHelp, actionDate, resilienceHelp, resilienceDate, engagementHelp, engagementDate, considerationsHelp, considerationsDate
            })) {
                showAlert(EMPTY_FORM_MESSAGE, 'personal-form', 'danger');
                return;
            } 
            answers = constructAnswers(submittedValues); 
            return submitPersonalSafetyForm(userInstance, groupName, projectName, answers, personalSafetyImages, personalSafetyVideos, history);
        }
    }
    
    supervisor: any = [];
    promise = async () => {
        const { id, userInstance, editId } = this.props;
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        const users = UserModel.list();  
        function containsAny(source: any[],target: string | any[]) {
            var result = source.filter(function(item){ return target.indexOf(item) > -1});   
            return (result.length > 0);  
        }    
        if( userInstance && this.supervisor.length<1 ) { 
            users.forEach(async (user: any) => {  
                let name; 
                if( ( (userInstance.props.accessLevel==='L1' || userInstance.props.accessLevel==='L2') && userInstance.props.userUID===user.props.userUID) || ( /* userInstance.props.accessLevel=='L2' && */ (userInstance.props.userUID!==user.props.userUID) && userInstance.props.projects &&  containsAny(userInstance.props.projects,user.props.projects) ) || id ) {
                    name = user.props.firstName + ' ' + user.props.middleName + ' ' + user.props.lastName;
                    await this.supervisor.push({ label: name, value: user.props.userUID.toString() });
                } 
                dispatch(actions.change(`${this.formModel}.supervisor`, userInstance.props.userUID));
            }); 
            this.supervisor = await sortBy(this.supervisor,'alpha');

            /* if(userInstance.props.accessLevel=='L1' || userInstance.props.accessLevel=='L2') { 
                dispatch(actions.change(`${this.formModel}.supervisor`, userInstance.props.userUID));
            } */
        }

        if (id || editId) {
            await this.populateValues();
        } else { 
            this.setState({ project: userInstance && userInstance.props.projects && userInstance.props.projects[0]})
        }
        return;
    }
    personalInstance: any;
    populateValues = async () => {
        const { id, editId } = this.props;
        const personalFormInstance = await getPersonalSafetyFormDetails(id || editId);  
        this.personalInstance = personalFormInstance;
        new PersonalSafetyModel(personalFormInstance).$save(); 
        if(id) {  
            this.setState({
                isDisabled: true
            });
        }
        const userInstance = UserModel.getUserByUID(personalFormInstance.userId);
        if (userInstance) {
            this.setState({
                firstLast: `${userInstance.props.firstName} ${userInstance.props.lastName}`,
                company: `${userInstance.props.company}`
            });
        }
        this.setState({
            credibilityDate: personalFormInstance.credibilityDate,
            actionDate: personalFormInstance.actionDate,
            resilienceDate: personalFormInstance.resilienceDate,
            engagementDate: personalFormInstance.engagementDate,
            considerationsDate: personalFormInstance.considerationsDate,
            minCredibilityDate: format(new Date(personalFormInstance.date), 'yyyy-MM-dd'),
            minActionDate: format(new Date(personalFormInstance.date), 'yyyy-MM-dd'),
            minResilienceDate: format(new Date(personalFormInstance.date), 'yyyy-MM-dd'),
            minEngagementDate: format(new Date(personalFormInstance.date), 'yyyy-MM-dd'), 
            minConsiderationsDate: format(new Date(personalFormInstance.date), 'yyyy-MM-dd'), 
        });  
        dispatch(actions.change(`${this.formModel}.projectName`, personalFormInstance.projectName));
        dispatch(actions.change(`${this.formModel}.groupName`, personalFormInstance.groupName));
        if(personalFormInstance.supervisor!==undefined)
            dispatch(actions.change(`${this.formModel}.supervisor`, personalFormInstance.supervisor));
        dispatch(actions.change(`${this.formModel}.credebilityHelp`, personalFormInstance.credebilityHelp));
        dispatch(actions.change(`${this.formModel}.credibilityDate`, personalFormInstance.credibilityDate));
        dispatch(actions.change(`${this.formModel}.actionHelp`, personalFormInstance.actionHelp));
        dispatch(actions.change(`${this.formModel}.actionDate`, personalFormInstance.actionDate));
        dispatch(actions.change(`${this.formModel}.resilienceHelp`, personalFormInstance.resilienceHelp));
        dispatch(actions.change(`${this.formModel}.resilienceDate`, personalFormInstance.resilienceDate));
        dispatch(actions.change(`${this.formModel}.engagementHelp`, personalFormInstance.engagementHelp));
        dispatch(actions.change(`${this.formModel}.engagementDate`, personalFormInstance.engagementDate));
        dispatch(actions.change(`${this.formModel}.considerationsDate`, personalFormInstance.considerationsDate));
        if(editId && personalFormInstance.followUp===0) { 
            dispatch(actions.change(`${this.formModel}.credibilityComment`, personalFormInstance.credebilityComment));
            dispatch(actions.change(`${this.formModel}.actionComment`, personalFormInstance.actionComment));
            dispatch(actions.change(`${this.formModel}.resilienceComment`, personalFormInstance.resilienceComment));
            dispatch(actions.change(`${this.formModel}.engagementComment`, personalFormInstance.engagementComment));
        }
    }

    renderContent = () => {
        const { userInstance, id, editId, forms } = this.props; 
        let firstLast = 'NA';
        let userUID: any = '';
        let userLevel: any = '';
        //let editInstance: PersonalSafetyModel = this.props.editInstance || [];
        if (userInstance && !id) { 
            firstLast = `${userInstance.props.firstName} ${userInstance.props.lastName}`;
            userUID = userInstance.props.userUID; 
            userLevel = userInstance.props.accessLevel;
        }
        /* let personalSafetyInstance: PersonalSafetyModel;
        if (id) {
            personalSafetyInstance = PersonalSafetyModel.get(id);
        } */
        if(editId ) {
            //editInstance = PersonalSafetyModel.get(editId); 
          /*   history.push('/personal-safety/all'); */
        }   
        let editInstance = this.props.editInstance; 
        return (
            <Grid style={{ width: '100%' }} className="personal-safety-form">
                <Form
                    loader={<SubmissionLoader />}
                    model={this.formModel}
                    onSubmit={this.handleSubmit}
                >
                    <Title titleColor="yellow" text="Personal Safety Form" noNeedBorder={true}/>
                    <AlertModal id="personal-form-modal" />
                    <fieldset>
                        <legend><span style={{ color: 'yellow' }}>Personal Safety</span></legend>
                        <Row className="show-grid">
                            <Col sm={6} xs={12}>
                                <Label sm={12} htmlFor="name">Name:</Label>
                                <Col sm={12} className="input">
                                    <div style={{ padding: '5px 0px' }}>
                                        <strong>
                                            {this.personalInstance ? this.personalInstance.username:( userInstance ? `${userInstance.props.firstName} ${userInstance.props.lastName}` : '' )}
                                        </strong>
                                    </div>
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label sm={12} htmlFor="company">Company</Label>
                                <Col sm={12} className="input">
                                    <div style={{ padding: '5px 0px' }}>
                                        <strong>
                                            {userInstance ? userInstance.props.company : ''}
                                        </strong>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="supervisor">Supervisor</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="supervisor"
                                        type="dropdown"
                                        menuItems={this.supervisor}
                                        isDisabled={this.state.isDisabled} 
                                        model=".supervisor"
                                        defaultValue={this.personalInstance && this.personalInstance.supervisor}
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={this.personalInstance && this.personalInstance.projectName} isDisabled={this.state.isDisabled}/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={this.personalInstance && this.personalInstance.groupName} isDisabled={this.state.isDisabled}/>
                            </Col> 
                        </Row>
                        <div className="action-label" style={{ marginTop: '20px' }}>
                            LEADERSHIP BEHAVIOR: CREDIBILITY
                    </div>
                        <Row className="show-grid">
                            <Col sm={12}>
                                1. Maintain <i><b>safety as first priority with clients/contractors/partners</b></i>;
                            follow through on commitments to build
                            trust and help them succeed.
                        </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={9} xs={12}>
                                <Label sm={12} >My Safety Commitment:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms?.personalSafetyForm.credebilityHelp} model="forms.personalSafetyForm.credebilityHelp" />
                                <Col sm={12}>
                                    <RRFInput
                                        type="textarea"
                                        id="credebilityHelp"
                                        placeholder="Enter your commitment..."
                                        model=".credebilityHelp"
                                        isDisabled={this.state.isDisabled}
                                        defaultValue={this.personalInstance && this.personalInstance.credebilityHelp}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} >Target Date</Label>
                                <Col sm={12}>
                                    <RRFInput
                                        id="credibilityDate"
                                        type="date"
                                        model=".credibilityDate"
                                        defaultValue={this.state.credibilityDate}
                                        isDisabled={this.state.isDisabled}
                                        mindate={this.state.minCredibilityDate} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                        {id || (editInstance && editInstance.props.followUp===0)? <CommentInput id="credibilityComment" model=".credibilityComment" /> : ''}
                        <div className="action-label" style={{ marginTop: '20px' }}>
                            LEADERSHIP BEHAVIOR: ACTION
                        </div>
                        <Row className="show-grid">
                            <Col sm={12}>
                                1. <i><b>Always intervene in potentially unsafe acts </b></i>
                                or conditions in a positive way to make others fix
                            the problem and feel good about doing it.< br />
                                2. Promote the use of SafeConnect to share and report
                                observations on a regular basis (2 per week).
                                <br />
                                &emsp; &emsp; a. Lead by example
                        </Col>
                        </Row>

                        <Row className="show-grid">
                            <Col sm={9} xs={12}> 
                                <Label sm={12} >My Safety Commitment:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms?.personalSafetyForm.actionHelp} model="forms.personalSafetyForm.actionHelp" />
                                <Col sm={12}>
                                    <RRFInput
                                        isDisabled={this.state.isDisabled}
                                        id="actionHelp"
                                        type="textarea"
                                        placeholder="Enter your commitment..."
                                        model=".actionHelp"
                                        defaultValue={this.personalInstance && this.personalInstance.actionHelp}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Target Date</Label>
                                <Col sm={12}>
                                    <RRFInput
                                        isDisabled={this.state.isDisabled}
                                        id="actionDate"
                                        type="date"
                                        defaultValue={this.state.actionDate}
                                        model=".actionDate"
                                        mindate={this.state.minActionDate} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">

                        </Row>
                        {id || (editInstance && editInstance.props.followUp===0)? <CommentInput id="actionComment" model=".actionComment" /> : ''}
                        <div className="action-label" style={{ marginTop: '20px' }}>
                            LEADERSHIP BEHAVIOR: RESILIENCE
                    </div >
                        <Row className="show-grid">
                            <Col sm={12}>
                                1. React positively to bad incidents by recognizing the
                            only real good is to capture learning from
                            what happened to <b><i>improve future performance.</i></b>
                                <br />2. Encourage all of us to behave this way.
                        </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={9} xs={12}> 
                                <Label sm={12} >My Safety Commitment:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms?.personalSafetyForm.resilienceHelp} model="forms.personalSafetyForm.resilienceHelp" />
                                <Col sm={12}>
                                    <RRFInput
                                        id="resilienceHelp"
                                        type="textarea"
                                        isDisabled={this.state.isDisabled}
                                        placeholder="Enter your commitment..."
                                        model=".resilienceHelp"
                                        defaultValue={this.personalInstance && this.personalInstance.resilienceHelp}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Target Date</Label>
                                <Col sm={12}>
                                    <RRFInput
                                        id="resilienceDate"
                                        type="date"
                                        model=".resilienceDate"
                                        defaultValue={this.state.resilienceDate}
                                        isDisabled={this.state.isDisabled}
                                        mindate={this.state.minResilienceDate} 
                                    />
                                </Col>
                            </Col>
                        </Row>

                        {id || (editInstance && editInstance.props.followUp===0)? <CommentInput id="resilienceComment" model=".resilienceComment" /> : ''}
                        <div className="action-label" style={{ marginTop: '20px' }}>
                            LEADERSHIP BEHAVIOR: ENGAGEMENT </div >
                        <Row className="show-grid">
                            <Col sm={12}>
                                1. Improve oral and written communication skills to
                            <b><i> increase my effectiveness.</i></b> <br />
                                2. Stop work when appropriate, do management walkarounds, etc. to
                            <b><i> demonstrate consistency and commitment.</i></b><br />
                                3. <b><i>Promote the use of the consistent approach to Safety Leadership </i></b> by using SafeConnect to capture best practices.
                                {/* by acting as both a team participant
                            and Management Sponsor of the new behavior &quot;model&quot;
                            using SafeConnect and capturing best practices to share and incorporate in all we do. */}
                        </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={9} xs={12}> 
                                <Label sm={12} >My Safety Commitment:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms?.personalSafetyForm.engagementHelp} model="forms.personalSafetyForm.engagementHelp" />
                                <Col sm={12}>
                                    <RRFInput
                                        isDisabled={this.state.isDisabled}
                                        id="engagementHelp"
                                        type="textarea"
                                        placeholder="Enter your commitment..."
                                        model=".engagementHelp"
                                        defaultValue={this.personalInstance && this.personalInstance.engagementHelp}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Target Date</Label>
                                <Col sm={12}>
                                    <RRFInput
                                        id="engagementDate"
                                        isDisabled={this.state.isDisabled}
                                        type="date"
                                        defaultValue={this.state.engagementDate}
                                        model=".engagementDate"
                                        mindate={this.state.minEngagementDate} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                        {id || (editInstance && editInstance.props.followUp===0) ? <CommentInput id="engagementComment" model=".engagementComment" /> : ''}
                        {id ? '' : <Row style={{ paddingTop: '20px' }} className="show-grid">
                        </Row>} 
                        
                        <div className="action-label" style={{ marginTop: '20px' }}>
                            HUMAN PERFORMANCE CONSIDERATIONS</div >
                        <Row className="show-grid">
                            <Col sm={12}>
                                1. Human Performance are actions taken to ensure safe work activity. <br />
                                2. Key concepts are avoiding errors, pre-planning, and embedding work knowledge. 
                        </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={9} xs={12}> 
                                <Label sm={12} >My Safety Commitment:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms?.personalSafetyForm.considerationsHelp} model="forms.personalSafetyForm.considerationsHelp" />
                                <Col sm={12}>
                                    <RRFInput
                                        isDisabled={this.state.isDisabled}
                                        id="considerationsHelp"
                                        type="textarea"
                                        placeholder="Enter your commitment..."
                                        model=".considerationsHelp"
                                        defaultValue={this.personalInstance && this.personalInstance.considerationsHelp}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Target Date</Label>
                                <Col sm={12}>
                                    <RRFInput
                                        id="considerationsDate"
                                        isDisabled={this.state.isDisabled}
                                        type="date"
                                        defaultValue={this.state.considerationsDate}
                                        model=".considerationsDate"
                                        mindate={this.state.minConsiderationsDate} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                        {id || (editInstance && editInstance.props.followUp===0) ? <CommentInput id="considerationsComment" model=".considerationsComment" /> : ''}
                        {id ? '' : <Row style={{ paddingTop: '20px' }} className="show-grid">
                        </Row>}
                        

                        <Row className="show-grid">
                            <Col /* smOffset={4} */ sm={6} xs={12}>
                                <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                                <Col sm={12} className="input">
                                    <FileInput
                                        model=".personalSafetyImages"
                                        multiple={true}
                                        id="upload-images"
                                        defaultValue={this.personalInstance && this.personalInstance.imageUrls}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                                <Col sm={12} className="input">
                                    <VideoInput
                                        model=".personalSafetyVideos"
                                        multiple={true}
                                        id="upload-videos" 
                                        defaultValue={this.personalInstance && this.personalInstance.videoUrls}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                    </fieldset>
                    <Row className="show-grid">
                        <div className="form-button" style={{ marginTop: '10px', textAlign: 'center' }} > 
                            <Alert className="danger-alert" id="personal-form" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                redirectTo="goBack" type="button"
                                onClick={() => this.props.history?.goBack() }
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                Cancel
                        </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >   <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                                { editInstance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form >
            </Grid >
        );
    }

    render() {
        return (
            <BasePage className="safeconnect-form psp-form" >
                <Async
                    identifier="PersonalSafetyForm"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

const CommentInput = ({ model, id }: any) => {
    return <Row style={{ paddingBottom: '20px' }} className="show grid">
        <Label sm={3}>
            Comments:
        </Label>
        <Col sm={9}>
            <RRFInput
                id={id}
                type="textarea"
                placeholder="Enter comments..."
                model={model}
            />
        </Col>
    </Row>;
};
 
export function mapStateToProps(state: any, ownProps: any) {
    const userId = state.login.get('userId');
    const userUID = state.login.get('userUID');
    const companyName = state.login.get('company');
    const userInstance = UserModel.get(userId);
    const editInstance = PersonalSafetyModel.get(ownProps.match.params.editId); 
    const editId = ownProps.match.params.editid; 
    return {
        userUID, userInstance, userId, companyName,
        id: ownProps.match.params.id,
        forms: state.forms, editInstance, editId
    };
}

export const PersonalSafetyForm = withRouter(connect<IPersonalSafetyFormProps, any, any>
    (mapStateToProps)(PersonalSafetyFormImpl));
