import { saveAllInstances, saveInstance, removeInstance, saveFilteredInstance, updateInstance } from '../actions/modelActions';
import { store } from '../store';
import { contains, isEmpty } from '../utils/generalUtils';

export class BaseModel<P> {
    static resource: string;
    resource: string | undefined;
    static constraint: Object;
    static defaultProps: any;

    constructor(public props: P & {
        id?: string
    }, public resourceProps?: string) { 
        //this.resource = this.constructor.resource; 
        this.resource = resourceProps; 
        this.props = props;
    }

    /* private assignDefault() {
        //let defaultProps = this.constructor.defaultProps;
        let defaultProps = '';
        if (!defaultProps) {
            return;
        }
        keys(defaultProps, (key: string | number) => {
            if (isEmpty(this.props[key])) {
                this.props[key] = defaultProps[key];
            }
        });
    } */

    getStoreKey(): string { return `${this.resource}${this.props.id}`; }

    $save(identifier: string = ''): BaseModel<P> {
        if (!this.validate()) {
            throw Error;
        } 
        //saveInstance(this, this.getStoreKey(), identifier);
        saveInstance(this, this.getStoreKey(), identifier);
        return this;
    }

    $update(key: string = ''): BaseModel<P> {
        updateInstance(`${key
            ? `${this.resource}${key}`
            : this.getStoreKey()}`, this);
        return this;
    }

    $delete(casecade: boolean = true): void {
        removeInstance(this.getStoreKey());
    }

    $saveFiltered(key: string): BaseModel<P> {
        if (!this.validate()) {
            throw Error;
        }
        saveFilteredInstance(this, `${key}${this.props.id}`, key);
        return this;
    }

    private validate() {
        //let constraints = this.constructor.constraints;
        let constraints = '';
        if (isEmpty(constraints)) {
            return true;
        }
        validateObject(this.props, constraints);
    }

    static get(id: string, state = store.getState()) {
        let modelState = state.models;
        if (!modelState) {
            return;
        }
        let storeKey: string = `${this.resource}${id}`;
        /* return modelState.toJS()
            ? modelState.get(storeKey)
            : modelState[storeKey]; */
        return modelState.get(storeKey);
    }

    static getAll(ids: string[]) {
        const instances = this.list();
        return instances.filter((instance: { props: { id: string; }; }) => {
            return contains(ids, instance.props.id);
        });
    }

    static getAllFormIds(): string[] {
        const instances = this.list();
        let ids: any[] = [];
        instances.forEach((instance: { props: { id: any; }; }) => {
            ids.push(instance.props.id);
        });
        return ids;
    }

    static getBy(reference: string, value: string) {
        const instances = this.list();
        return instances.find((userInstance: { props: { [x: string]: string; }; }) => {
            if (userInstance.props[reference] === value) {
                return userInstance;
            }
        });
    }

    static getFiltered(filterBy: string, state = store.getState()) {
        return state
            .models
            .filter((instance: { props: { filterBy: string; }; }) => instance.props.filterBy === filterBy)
            .toArray();
    }

    static list(state = store.getState()) {  
        let data = state.models.filter((instance: { resource: string; }) => instance.resource === this.resource).toArray();
        let resultSet: any = [];
        data.map((instance: any, key: any) => {
            resultSet.push(instance[1])
        });
        return resultSet;
        /* return state
        .models
        .filter((instance: { resource: string; }) => instance.resource === this.resource)
        .toArray(); */
    }

    static saveAll<T extends BaseModel<{}>>(instances: T[]): void {
        for (let instance of instances) {
            if (!validateObject(instance, this.constraint)) {
                throw Error;
            }
        }

        instances.map((instance, value) => {
            saveInstance(instance, instance.getStoreKey());
        });
        saveAllInstances(instances);
    }

    static deleteAll<T extends BaseModel<{}>>(instances: T[] = this.list()): void {
        instances.map(instance => removeInstance(instance.getStoreKey()));
    }

    static deleteAllFiltered<T extends BaseModel<{}>>(instances: T[], filterBy: string): void {
        instances.map(instance => removeInstance(`${filterBy}${instance.props.id}`));
    }
}

/* function generateInstanceMap(instances: BaseModel<{}>[]) {
    if (isEmpty(instances)) {
        return;
    }
    let instanceMap: any = {};

    instances.forEach(instance => {
        instanceMap[instance.getStoreKey()] = instance;
    });
    return instanceMap;
} */

function validateObject(obj: any, rules: any): boolean {
    for (let prop in rules) {
        if (rules.hasOwnProperty(prop)) {
            let constraint = rules[prop];
            if (!constraint(obj[prop])) {
                return false;
            }
        }
    }
    return true;
}
