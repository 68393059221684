import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { SafetyWalkthroughReportDetail } from '../../model/SafetyWalkthroughModel';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import './swFormList.scss';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { Img } from '../reusableComponents/ImageComponents/Img'; 
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { KeyValue } from '../../utils/generalUtils';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery'; 
import { Loader } from '../reusableComponents/Loader'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Async } from '../reusableComponents/Async';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay'; 
import { format } from 'date-fns'; 
import { Button } from '../reusableComponents/FormComponents/Button';
import noImage from '../../images/noImage.png';
import { getSWReportDetail } from '../../services/attributeService';

export interface ISWReportDetailProps {
    swReportDetails: SafetyWalkthroughReportDetail;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    history?: IHistory;
    formId: string;
    formType: string;
    match?: {
        params: {
            id: string;
        }
    };
}

export interface ISWReportDetailState {
    swReportDetails: SafetyWalkthroughReportDetail;
    showDetails: boolean;
    formId: string;
}

export class SWReportDetailImpl extends React.PureComponent<ISWReportDetailProps, ISWReportDetailState> {
    constructor(props: ISWReportDetailProps | Readonly<ISWReportDetailProps>) {
        super(props);
        let reportDetail: SafetyWalkthroughReportDetail = {
            company: '', subAttributeList: [],
            formType: '', date: '', overAllComment: '', totalScore: '', userName: ''
        };
        this.state = { showDetails: false, formId: '', swReportDetails: reportDetail };
    }

    promise = async () => { 
        const { formId, formType } = this.props;
        getSWReportDetail(formId, formType); 
    }

    componentDidMount() {
        /* const { formId, formType } = this.props;
        getSWReportDetail(formId, formType); */
    }

    renderAnswerList = () => {
        const { swReportDetails } = this.props;
        if (swReportDetails.subAttributeList) { 
            let attrList: any = []; 
            swReportDetails.subAttributeList.map((item, parentIndex) => { 
                if(item['attributeName'].toLowerCase() === 'general' ) {
                    attrList.push(item);   
                    swReportDetails && swReportDetails.subAttributeList && delete swReportDetails.subAttributeList[parentIndex];  
                } 
            }); 
            attrList = attrList.concat(swReportDetails.subAttributeList); 
            attrList = attrList.filter(Boolean);
            return attrList.map((item: { attributeName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; answerList: any; }, index: React.Key | null | undefined) => {
                return (
                    <div key={index}>
                        <div className="sub-heading" >{item.attributeName}</div>
                        <div>{this.renderAnswerInnerList(item.answerList)}</div>
                        <div className="table-rows">
                        </div>
                    </div>
                );
            });
        } else {
            return (<span></span>);
        }
    }
    renderAnswerInnerList = (list: any[]) => {
        return list.map((item, index) => {
            return (
                <span key={index} >
                    <KeyValue label={item.key}><span>{item.value}</span></KeyValue>
                </span>
            );
        });
    }

    renderImages = () => {
        const { swReportDetails } = this.props;
        if ((swReportDetails.imageUrls || []).length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        }
        const { imageUrls } = swReportDetails;

        return (imageUrls || []).map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    }

    renderDetailList = () => {
        const { swReportDetails } = this.props;
        const { props: { titleStyle } } = this;  
        if (!swReportDetails) {
            return <Loader />;
        }  
        return (
            <div>
                <Title style={titleStyle || {}} text={`${swReportDetails.formType} Report Details`}></Title>
                <KeyValue label="Date"><span>{swReportDetails.dateOfIncident || (swReportDetails.date && format(new Date(swReportDetails.dateOfIncident || swReportDetails.date), 'MM/dd/yyyy')) }</span></KeyValue> 
                {swReportDetails.formType!=='Manager Checklist' && swReportDetails.formType!=='Supervisor Checklist'  && swReportDetails.formType!=='Closecall Checklist' &&
                <><KeyValue label="Serial No.">{swReportDetails.serialNo}</KeyValue>
                <KeyValue label="Checklist Title">{swReportDetails.checklistTitle}</KeyValue></> }
                <KeyValue label="Form Type">{swReportDetails.formType}</KeyValue>
                <KeyValue label="Team Name">{swReportDetails.projectName}</KeyValue>
                <KeyValue label="Group Name">{swReportDetails.groupName}</KeyValue>
                {this.renderAnswerList()}
                <span className="border"></span>
                <KeyValue label="Total Score:">{swReportDetails.totalScore}</KeyValue>
                <KeyValue label="Overall Comments:">{swReportDetails.overAllComment}</KeyValue >
                <KeyValue label="Images">{this.renderImages()}</KeyValue>
                <KeyValue label="Videos"><VideoPlay videoUrls={swReportDetails.videoUrls}/></KeyValue>
            </div>
        );
    }
    renderFormDetails = () => { 
        return (
            <><Gallery images={this.props.swReportDetails ? this.props.swReportDetails.imageUrls : []} />
            <DetailsPageWrapper pdfFileName={'SafetyWalk'} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                Close </Button> }
            >
                {this.renderDetailList()}
            </DetailsPageWrapper></>
        );
    }

    render() {
        return (
            <BasePage className="jsa-report" >
                <Async
                    identifier="OIDetailsPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderDetailList()}
                /> 
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        formId: ownProps.match.params.formId,
        formType: ownProps.match.params.formType,
        swReportDetails: state.report.get('reportDetailData')
    };
}

export const SWReportDetail = withRouter(connect<ISWReportDetailProps, any, any>(mapStateToProps)(SWReportDetailImpl));
