import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { getAllUsers } from '../../services/userService';
import { GroupModel, IGroupModelProps } from '../../model/GroupModel';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Alert } from '../Alert';
import { GroupForm } from './GroupForm'; 
import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { Title } from '../reusableComponents/Title';
import { getAllGroups, updateGroupForm } from '../../services/groupService';
import { BasePage } from '../reusableComponents/BasePage';
import { connect } from 'react-redux';
import './addGroupForm.scss'; 
import { IHistory, IForms } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { isFormEmpty, showAlert } from '../../utils/generalUtils';
import { UserModel } from '../../model/UserModel';
import { format } from 'date-fns';

export interface IUpdateFormProps {
    instance: GroupModel;
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    forms: IForms;
}

export class UpdateFormImpl extends React.PureComponent<IUpdateFormProps, {}> {
    constructor(props: IUpdateFormProps | Readonly<IUpdateFormProps>) {
        super(props);
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if (GroupModel.list().length <= 0) {
            return getAllGroups();
        }
        return null;
    }

    handleSubmit = (submittedValues: IGroupModelProps) => {
        const { instance } = this.props;
        submittedValues.groupId = instance.props.groupId;
        submittedValues.dateUpdated = format(new Date(), 'yyyy-MM-dd');
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'update-group', 'danger');
            return;
        }
        return this.props.history && updateGroupForm(submittedValues, this.props.history);
    }

    renderGroupForm = () => {
        const { instance } = this.props;
        if (!instance) {
            return <div></div>;
        }
        const { props: { groupForms, groupName, groupDesc, members } } = instance;
        const groupMembersName: any = [];
        const memberIds: Array<any> = []; 
        (members || []).forEach((memberId, index) => {
            const userInstance = UserModel.get(memberId);
            if (userInstance) {
                const { userId } = userInstance.props; 
                groupMembersName.push(userInstance.props.userUID);
                memberIds.push(userId);
            }
        });
        return (
            <GroupForm
                onSubmit={this.handleSubmit}
                onCancel={() => this.props.history?.push('/groups')}
                groupName={groupName}
                instance={instance}
                groupDesc={groupDesc}
                groupForms={groupForms}
                groupMembers={groupMembersName.toString()}
                type="update" />
        );
    }

    render() {
        return (
            <BasePage>
                <Grid style={{ width: '100%' }} className="update-group">
                    <Title text="Update Group" />
                    <AlertModal id="update-group-modal" />
                    <Alert className="danger-alert" id="update-group" />
                    <Async
                        identifier="GroupUpdateForm"
                        promise={this.promise}
                        error={<ErrorPage />}
                        loader={<Loader />}
                        content={this.renderGroupForm()}
                    />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const instance = GroupModel.get(ownProps.match.params.id, state);
    return {
        instance,
        forms: state.forms
    };
}
export const UpdateForm = connect<{}, {}, IUpdateFormProps>(mapStateToProps)(UpdateFormImpl);
