
import * as React from 'react'; 
import { ActivityChart } from '../reusableComponents/ChartComponents/ActivityChart';
import { Card } from '../reusableComponents/Card';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends';
import { BarChart } from '../reusableComponents/ChartComponents/BarChart';
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { Title } from '../reusableComponents/Title';
import { getOIFormSummaryData, } from '../../services/oiformService'; 
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Col, Row, Container as Grid } from 'react-bootstrap'; 
import { IChartValue, IFormMonths, IPieChartObject, IHistory, IForms } from '../../interfaces'; 
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Async } from '../reusableComponents/Async';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import totalReportsAvatar from '../../images/totalReports.png';
import positiveObservationAvatar from '../../images/positiveObservations.png';
import negativeObservationAvatar from '../../images/negativeObservation.png';
import nearMissAvatar from '../../images/nearMiss.png';  
import { PrintLogos } from '../reusableComponents/PrintLogos'; 

export interface IOIReportsDataProps {
    concernTypes?: {
        name?: string;
        value?: number;
    }[];
    activityData: any;
    combinedOthers?: boolean;
    lineChartData: { formMonths?: IFormMonths };
    barChartData: {
        positiveObservations: number;
        negativeObservations: number;
        nearMiss: number;
    };
    summaryCardsData: {
        positiveObservations: number;
        negativeObservations: number;
        total: number;
        nearMiss: number;
    };
    getRef?: (value: any) => void;
    showCheckBox?: boolean;
    id?: string;
    isAnimationActive?: boolean;
    filterData: {
        year: string;
        filterType: string;
        month: string;
    };
    history?: IHistory;
    forms?: IForms;
}

export interface IOIReportsDataState {
    showPieChart: string;
    showLineChart: string;
    showBarChart: string;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    view: boolean;
    activityName: string;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
}

export class OIReportsData extends React.PureComponent<IOIReportsDataProps, IOIReportsDataState> {
    constructor(props: IOIReportsDataProps | Readonly<IOIReportsDataProps>) {
        super(props);
        this.state = {
            showMonthlyFilter: false, showYearlyFilter: false,
            showPieChart: 'show', showLineChart: 'show',
            showBarChart: 'show', view: false, activityName: '', legends: [],
            data: [], totalFormsSubmitted: 0, isLoading: false
        };
    }

    componentWillMount() {
        setSuccess('ReportsPage');
    }

    ref: any;

    /* handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({
                showYearlyFilter: true,
                showMonthlyFilter: false
            });
            return;
        }
        if (value === 'monthly') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: true
            });
            return;
        }
        this.setState({
            showYearlyFilter: false,
            showMonthlyFilter: false
        }); 
        getOIFormSummaryData();
    }

    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return (
            <YearlyFilter onApply={(year) => {
                setLoading('ReportsPage');
                saveFilterData('yearly', year);
                applyYearlyFilter(year);
            }} />
        );
    }

    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        return <MonthlyFilter onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilter(month, year);
        }} />;
    } */
    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getOIFormSummaryData(this.props.forms); 
        setSuccess('ReportsPage');
    }
    renderPrintPreviewModal = () => {
        showPrintPreviewModal('oi-report');
        return;
    }

    renderActivityPopover = (obj: IPieChartObject) => {
        const { activityData, concernTypes } = this.props;
        let legends: Array<any> = [];
        let data: Array<any> = [];

        let totalFormsSubmitted: any = '';
        (concernTypes || []).forEach(concernData => {
            if (concernData.name === obj.name) {
                totalFormsSubmitted = concernData.value;
            }
        });
        const activities = obj.name !== undefined ? activityData[obj.name]:'';
        for (let key in activities) {
            legends.push(key);
        }
        for (let key in activities) {
            data.push({
                name: key, value: activities[key]
            });
        }
        this.setState({
            view: true,
            pieChartData: obj,
            legends,
            data,
            totalFormsSubmitted
        });
    }

    renderPieChart = () => {
        const { concernTypes, combinedOthers, isAnimationActive } = this.props;
        const legendsName: any = [];
        (concernTypes || []).forEach(concern => {
            legendsName.push(concern.name);
        });
        return (
            <div id="pie-chart"
                style={{ /* height: '100%', */ width: '100%', display: 'inline-block', margin: '20px auto', backgroundColor: 'white' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Types Of Observations
                    </h4>
                    <Row className="show-grid" >
                        <Col sm={7} id="oi-pie-chart" className="pie circle" style={{ height: '253px' }}>
                            <ActivityChart
                                visibility={this.state.view}
                                data={this.state.data}
                                legends={this.state.legends}
                                totalFormsSubmitted={this.state.totalFormsSubmitted}
                                obj={this.state.pieChartData}
                                chartType="pie"
                            />
                            <PieChart
                                data={concernTypes}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1}
                                onClick={this.renderActivityPopover}
                                customTootip={true}
                                isAnimationActive={isAnimationActive}
                            />
                        </Col>
                        <PieChartLegends
                            sm={5}
                            legendNames={legendsName}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA',
                            'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                        />
                    </Row>
                    {combinedOthers ? <p style={{ textAlign: 'center' }}>
                        Others Concern Type can contain inactive concern types</p> : null}
                </Card>
            </div>
        );
    }

    renderBarChart = () => {
        const { barChartData: { positiveObservations, negativeObservations, nearMiss }, isAnimationActive } = this.props;
        const data = [{ name: '+ve Obs', value: positiveObservations },
        { name: '-ve Obs', value: negativeObservations },  { name: 'Near Miss', value: nearMiss }];
        return (
            <div style={{
                height: '100%', width: '100%', display: 'inline-block',
                margin: '20px auto'
            }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Observations Influence
                    </h4>
                    <Row className="show-grid ">
                        <Col sm={12} className="bar-chart" style={{ height: '253px' }}>
                            <BarChart
                                isAnimationActive={isAnimationActive}
                                data={data}
                                barColors={['A9DEDC', 'FFBECE','F2BD91']}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    renderLineChart = () => {
        const { lineChartData: { formMonths } } = this.props;
        if (!formMonths) {
            return;
        }
        const { January, February, March, April, May, June, July, August,
            September, October, November, December } = formMonths;
        const data = [
            { name: 'Jan', actual: January, expected: 9 }, { name: 'Feb', actual: February, expected: 9 },
            { name: 'Mar', actual: March, expected: 9 }, { name: 'Apr', actual: April, expected: 9 },
            { name: 'May', actual: May, expected: 9 }, { name: 'Jun', actual: June, expected: 9 },
            { name: 'July', actual: July, expected: 9 }, { name: 'Aug', actual: August, expected: 9 },
            { name: 'Sep', actual: September, expected: 9 }, { name: 'Oct', actual: October, expected: 9 },
            { name: 'Nov', actual: November, expected: 9 }, { name: 'Dec', actual: December, expected: 9 },
        ];
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }} >
                        Types Of Observations
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={data} legends={['Expected', 'Actual']}
                                strokeColors={['B0D9FF', 'FFBECE']}
                                dataKey={['expected', 'actual']} />
                            {/* <LineChart data={data} legends={['Actual']}
                                strokeColors={['B0D9FF', 'FFBECE']}
                                dataKey={['actual']} /> */}
                        </Col>
                    </Row>  
                </Card>
            </div>
        );
    }

    /* renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            <Alert style={{ height: '40px' }} id="oi-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' },
                        { value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        defaultValue="cumulative"
                        model=".filterType"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    } */

    renderSummaryCards = () => {
        const { nearMiss, total, positiveObservations, negativeObservations } = this.props.summaryCardsData; 
        return (
            <Grid style={{ width: '100%' }}>
                <Row className="show-grid " sm={12}> 
                    <Col sm={6} /* onClick={() => this.props.history?.push('/oi-reports')} */ >
                        <Card className="oi-report-card clickable-report-card">
                            <img src={totalReportsAvatar} alt="SafeConnect"/>
                            <h4 style={{ margin: '15px 0px 0px 0px' }}>Total Reports</h4>
                            <p style={{ paddingTop: '0px' }} >{total || 0}</p>
                        </Card>
                    </Col>
                    <Col sm={6}
                        /* onClick={() => this.props.history?.push( 
                            `/oi-reports/filter/${filterData ? filterData.filterType : 'summary'}/${filterData ? filterData.year : 'na'}/${filterData ? filterData.month : 'na'}/nearmiss` 
                        )} */
                    >
                        <Card className="oi-report-card clickable-report-card">
                            <img src={nearMissAvatar} alt="SafeConnect"/>
                            <h4 style={{ margin: '15px 0px 0px 0px' }}>Near Miss</h4>
                            <p style={{ paddingTop: '0px' }}>{nearMiss || 0}</p>
                        </Card>
                    </Col> 
                </Row>
                <Row className="show-grid " sm={12}> 
                    <Col sm={6}
                        /* onClick={() => this.props.history?.push( 
                            `/oi-reports/filter/${filterData ? filterData.filterType : 'summary'}/${filterData ? filterData.year : 'na'}/${filterData ? filterData.month : 'na'}/positive` 
                        )} */
                    >
                        <Card className="oi-report-card clickable-report-card">
                            <img src={positiveObservationAvatar} alt="SafeConnect" />
                            <h4>Positive Observations</h4>
                            <p>{positiveObservations}</p>
                        </Card>
                    </Col>
                    <Col sm={6}
                        /* onClick={() => this.props.history?.push( 
                            `/oi-reports/filter/${filterData ? filterData.filterType : 'summary'}/${filterData ? filterData.year : 'na'}/${filterData ? filterData.month : 'na'}/negative` 
                        )} */
                    >
                        <Card className="oi-report-card clickable-report-card">
                            <img src={negativeObservationAvatar} alt="SafeConnect" />
                            <h4>Negative Observations</h4>
                            <p>{negativeObservations}</p>
                        </Card>
                    </Col> 
                </Row>
            </Grid >
        );
    }

    handleLineChart = (value: any) => {
        this.setState({
            showLineChart: value
        });
    }

    handlePieChart = (value: any) => {
        this.setState({
            showPieChart: value
        });
    }

    handleBarChart = (value: any) => {
        this.setState({
            showBarChart: value
        });
    }

    renderCheckBox = () => {
        const { } = this.state;
        return <div className="print-filter-wrapper">
            <div className="filter-title">Please select charts to export:</div>
            <div className="print-data-filter">
                <div className="chart-filter">
                    <label
                        className="checkbox-label"
                        htmlFor="oiLineChart"
                    >
                        &nbsp;Types Of Observations(Line Chart)
                    </label>
                    <RRFInput
                        type="checkbox"
                        model=".oiLineChart"
                        id="oiLineChart"
                        onChange={this.handleLineChart}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <label
                        className="checkbox-label"
                        htmlFor="oiPieChart"
                    >
                        &nbsp;Types Of Observations(Pie Chart)
                    </label>
                    <RRFInput
                        type="checkbox"
                        id="oiPieChart"
                        model=".oiPieChart"
                        onChange={this.handlePieChart}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <label className="checkbox-label" htmlFor="oiBarChart">&nbsp;Observation Influence</label>
                    <RRFInput
                        type="checkbox"
                        id="oiBarChart"
                        model=".oiBarChart"
                        onChange={this.handleBarChart}
                        defaultValue="checked"
                    />
                </div>
            </div>
        </div >;
    } 

    renderContent = (isLoading: boolean) => {
        const { props: { showCheckBox, getRef, forms },
            state: { showBarChart, showLineChart, showPieChart } } = this;
        return (
            <div id="oi-reports" className="oi-report-summary">
                {showCheckBox ? this.renderCheckBox() : null}
                {isLoading ? <SubmissionLoader /> : ''}
                <div
                    className="summary-reports"
                    id={this.props.id} ref={value => { getRef ? getRef(value) : this.ref = value; }}>
                    <PrintLogos/>
                    <Title text="O&amp;I Cumulative Report Summary" />
                    {this.renderSummaryCards()}
                    {/* {this.renderFilters()} */}
                    <CommonFilter onApply={this.applyCommonFilter} forms={forms}/>
                    {showLineChart ? this.renderLineChart() : null}
                    <Row>
                        <Col sm={6} xs={12} className="report-chart" style={{ padding: '0 20px' }}>
                            {showPieChart ? this.renderPieChart() : null}
                        </Col>
                        <Col sm={6} xs={12} className="report-chart" style={{ padding: '0 20px' }}>
                            {showBarChart ? this.renderBarChart() : null}
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }
    render() {
        return <Async
            identifier="ReportsPage" 
            error={<div></div>}
            loader={this.renderContent(true)}
            content={this.renderContent(false)}
        />;
    }
}
