import { IHistory } from '../../interfaces';
import { showModal } from '../../actions/modalActions';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { UserModel } from '../../model/UserModel';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { ProjectModel } from '../../model/ProjectModel';
import { connect } from 'react-redux';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
const MenuItem = Dropdown.Item;

export interface IListItemImplProps {
    history?: IHistory;
    index?: number;
    instance?: ProjectModel;
    userId: string;
    style?: React.CSSProperties;
}

export interface IListItemState {
    displayOptions: boolean;
}

export class ListItemImpl extends React.PureComponent<IListItemImplProps, IListItemState> {

    constructor(props: IListItemImplProps | Readonly<IListItemImplProps>) {
        super(props);
        this.state = { displayOptions: false };
    }

    static defaultProps: IListItemImplProps;

    renderUserOptions = (e: any) => {
        this.setState({
            displayOptions: !this.state.displayOptions
        });
    }

    handleDelete = (id: string) => {
        const projectInstance = ProjectModel.get(id);
        new ProjectModel(projectInstance).$delete();
        this.setState({
            displayOptions: false
        });
        showModal('delete-project', projectInstance.props.projectName, projectInstance);
    }

    renderDropdown = () => {
        const { instance } = this.props;  
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">
                <MenuItem eventKey="1"
                    onClick={() => instance && this.props.history?.push(`/teams/${instance.props.id}/edit`)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</MenuItem>
                {
                    UserModel.isDemoUser() ? ''
                        : <MenuItem eventKey="2"
                            onClick={() => instance && this.handleDelete(instance.props.id)} className="item">
                            <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                </MenuItem>

                }
            </DropdownButton>
        );
    }

    renderFormList = () => {
        const { instance, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        /* const userInstance = UserModel.getUserByUID(instance.props.projectCreator);
        let projectCreator = 'NA';
        if (userInstance) {
            projectCreator = userInstance.props.firstName;
        } */
        return (
            <Row className="report-row" style={style}>
                <Col sm={1}>
                    {( UserModel.checkUserAccess('project_edit') ||  UserModel.checkUserAccess('project_delete') ) &&this.renderDropdown()}
                    <div className="serial-no">{ (this.props.index || 0) + 1}</div>
                </Col>
                <Cell sm={4} onClick={() => this.props.history?.push(`/teams/${instance.props.id}`)}>
                    {instance.props.creatorName || 'NA'}</Cell>
                <Cell sm={4} onClick={() => this.props.history?.push(`/teams/${instance.props.id}`)}>
                    {instance.props.projectName || 'NA'}</Cell>
                <Cell sm={3} onClick={() => this.props.history?.push(`/teams/${instance.props.id}`)}>
                    {instance.props.dateCreated || 'NA'}</Cell>
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
        userId: state.login.get('userId')
    };
}

export const ListItem = withRouter(connect<IListItemImplProps, any, any>(mapStateToProps)(ListItemImpl));
