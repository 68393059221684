import { IHistory } from '../../interfaces';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { GroupModel } from '../../model/GroupModel';
import { IProjectModelProps, ProjectModel } from '../../model/ProjectModel';
import { getAllGroups } from '../../services/groupService';
import { dispatch } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { Button } from '../reusableComponents/FormComponents/Button';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Label } from '../reusableComponents/FormComponents/Label';
import { Loader } from '../reusableComponents/Loader';
import PropTypes from 'prop-types';
import { Form } from '../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';

export interface IProjectFormProps {
    instance?: ProjectModel;
    groupNames?: string[];
    projectName?: string;
    groups?: string;
    projectDesc?: string;
    userUID?: string;
    onSubmit?: (data: Object) => void;
    history?: IHistory;
    onCancel?: () => void;
}

export interface IProjectFormState {
    groupList?: Object[];
}
export class ProjectFormImpl extends React.PureComponent<IProjectFormProps, IProjectFormState> {
    constructor(props: IProjectFormProps | Readonly<IProjectFormProps>) {
        super(props);
        this.state = { groupList: [] };
    }

    buttonLabel = 'Add Team';
    groupMembers: any = [];

    static childContextTypes = {
        formModel: PropTypes.string
    };

    getChildContext() {
        return { formModel: 'forms.projectForm' };
    }

    componentWillMount() {
        const { instance } = this.props;
        if (instance) {
            this.buttonLabel = 'Update Team';
        }
    }

    listGroupNames = () => {
        const groupInstance = GroupModel.list();
        groupInstance.forEach((group: { props: { groupName: any; groupId: any; }; }) => {
            this.groupMembers.push({ label: group.props.groupName, value: group.props.groupId });
        });
    }

    promise = async () => {
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        this.listGroupNames();
    }

    handleGroupSelect = (value: string) => {
        const arrayValue = value.split(',');
        this.setState({
            groupList: arrayValue
        });
        dispatch(actions.change('forms.projectForm.groups', value));
    }

    handleSubmit = (submittedValues: IProjectModelProps) => {
        const { props: { userUID } } = this;
        const groupArray = submittedValues.groups;
        const submittedValue = {
            projectCreator: userUID,
            projectName: submittedValues.projectName,
            projectDesc: submittedValues.projectDesc,
            groups: groupArray.toString().split(','),
        };
        return this.props.onSubmit && this.props.onSubmit(submittedValue);
    }

    renderFormContent = () => {
        const { instance, projectDesc, projectName, groups } = this.props;
        return (
            <Form loader={<SubmissionLoader />} model="forms.projectForm" onSubmit={this.handleSubmit}>
                <Row className="show-grid">
                    <Label required htmlFor="projectName">Team Name:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="projectName"
                            type="text"
                            placeholder="Enter team name..."
                            model=".projectName"
                            defaultValue={projectName}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Label required htmlFor="projectDesc">Team Description:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="projectDesc"
                            type="text"
                            placeholder="Enter team description..."
                            model=".projectDesc"
                            defaultValue={projectDesc}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Label required={false} htmlFor="groups">Groups:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            multi={true}
                            id="groups"
                            type="dropdown"
                            placeholder="Select groups..."
                            menuItems={this.groupMembers}
                            defaultValue={groups}
                            model=".groups"
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <div className="form-button" style={{ textAlign: 'center', marginTop: '50px' }} >
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            
                            onClick={() => this.props.history?.goBack() }
                            type="reset"
                        >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button>
                        <Button style={{
                            color: '#FFFFFF',
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}>
                            {instance ? <i className="fa fa-refresh" aria-hidden="true"></i> :
                                <i className="fa fa-plus" aria-hidden="true"></i>}
                            &nbsp;
                            {this.buttonLabel}
                        </Button>
                    </div>
                </Row>
            </Form>
        );
    }

    render() {
        return (
            <Async
                identifier="ProjectForm"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderFormContent()}
            />
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        userUID: state.login.get('userUID')
    };
}
export const ProjectForm = withRouter(connect<IProjectFormProps, any, any>(mapStateToProps)(ProjectFormImpl));
