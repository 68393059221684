import axios from 'axios';
import { SERVER_URL } from '../constants/urls';
import { UserModel } from '../model/UserModel';
import { APPVERSION } from '../constants/general';
import { saveAxiosPreviousToken } from '../actions/miscellaneousActions';
axios.defaults.baseURL = SERVER_URL;

async function getUpgradeStatus(currVersion) {
    let result = APPVERSION && parseInt(APPVERSION) < parseInt(currVersion) ? true : false;
    if(result) {
        if('caches' in window){
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
        }
        window.location.reload()
        return;
    }
    return true;
}
export async function get(url: string, params: Object = {}) {
    const company = UserModel.getCompanyName();
    const response = await axios.get(url + `/${company}`, params); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (JSON.stringify(response.data).toLowerCase().indexOf('error') > -1 || response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function getCustom(url: string, data: Object = {}) { 
    const response = await axios.get(url, data); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (response.data.status === 'invalid') {
        throw new Error(response.data);
    }
    return response;
}

export async function post(url: string, data: Object = {}, axiosPreviousToken?: any) {
    const company = UserModel.getCompanyName();
    let newData = Object.assign(data, { company }); 
    const CancelToken = axios.CancelToken.source(); 
    if(url==='/GetForms' || url==='/GetAllCheckListForms' || url==='/GetPersonalSafetyPlans') { 
        if(axiosPreviousToken)
            axiosPreviousToken.cancel('Operation canceled by the user.');  
        saveAxiosPreviousToken(CancelToken) 
    }
    const response = await axios.post(url, newData,  {
        cancelToken: CancelToken.token
      }); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function postCustom(url: string, data: Object = {}) {
    const response = await axios.post(url, data); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function putCustom(url: string, data: Object = {}) {
    const response = await axios.put(url, data); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (response.data.status === 'invalid') {
        throw new Error(response.data);
    }
    return response;
}

export async function put(url: string, data: Object = {}) {
    const company = UserModel.getCompanyName();
    const newData = Object.assign(data, { company });
    const response = await axios.put(url, newData); 
    response.headers.expires && await getUpgradeStatus(response.headers.expires)   
    if (response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function del(url: string, data: Object = {}) {
    const company = UserModel.getCompanyName();
    const response = await axios.delete(url + `/${company}`, data);
    if (response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function delCustom(url: string, data: Object = {}) {
    const response = await axios.delete(url, { data });
    if (response.data.status === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}

export async function putupdateUserPassword(url: string) {
    const response = await axios.put(url);
    if (response.data === 'invalid') {
        throw new Error(response.data.message);
    }
    return response;
}
