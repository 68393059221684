import * as React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';

export interface ILoaderProps {
    style?: React.CSSProperties;
    size?: number;
}

export class Loader extends React.PureComponent<ILoaderProps, {}> {
    constructor(props: ILoaderProps | Readonly<ILoaderProps>) {
        super(props);
    }

    render() {
        const { size, style } = this.props;
        return (
            <MuiThemeProvider>
                <div style={Object.assign(loaderStyle, style)}>
                    <CircularProgress style={{
                        top: '50%',
                        transform: 'translateY(-75%)'
                    }} size={size || 100} color="#88A4CE" />
                </div>
            </MuiThemeProvider>
        );
    }
}
const loaderStyle: React.CSSProperties = {
    textAlign: 'center',
    height: '400px',
    width: '100%',
};
