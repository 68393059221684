import * as React from 'react';
import { connect } from 'react-redux';
/* import SignaturePad from 'react-signature-pad'; */  
import { Col } from 'react-bootstrap';
import { AutoGenerateSignature } from '../../reusableComponents/AutoGenerateSignature';
import { base64ToFile } from '../../../utils/generalUtils';
import './manualsignature.scss';
var SignaturePad = require('react-signature-pad');

export interface IManualSignatureProps {
    onClick?: Function;
    saveSignature: (file: any, acceptorSignature: any, model?: string) => void;
    saveGeneratedSignature: (file: any, base64: any, model?: string) => void;
    autoSignTitle: string;
    className?: string;
    autoGenerateProps: {
        className: string;
    };
    model?: string;
    manualSignsm?: number;
}

export interface IManualSignatureState {
    isAutoGenerate: boolean;
    isManualGenerate: boolean;

}
export class ManualSignatureImpl extends React.Component<IManualSignatureProps, IManualSignatureState> {

    constructor(props: IManualSignatureProps) {
        super(props);
        this.state = {
            isAutoGenerate: false, isManualGenerate: true, 
        };
    }
    refs!: {
        acceptorSignature: typeof SignaturePad;
    };

    saveGeneratedSign = async (base64: any) => { 
        const file = await base64ToFile(base64, 'user-signature.png').then(function (file) {  
            return file;
        });
        this.props.saveGeneratedSignature(file, base64, this.props.model);
    }

    saveSign = async () => {
        const acceptorSignature = this
            .refs
            .acceptorSignature
            .toDataURL();
        const file = await base64ToFile(acceptorSignature, 'user-signature.png').then(function (file) {
            return file;
        });
    this.props.saveSignature(file, acceptorSignature, this.props.model);
    }

    renderAutoGenerateSignature = () => {
            return <AutoGenerateSignature
                name={this.props.autoSignTitle}
                toDataURL={this.saveGeneratedSign}
                className={
                    this.props.autoGenerateProps.className
                }
            />;
    }

    renderSignatureInput = () => {
        return  <>{/* <div className={this.props.className} style={{ paddingBottom: '20px' }} >   */}
            {/* <Label required={false} htmlFor="files">Signature:</Label> */}
            <Col sm={this.props.manualSignsm || 8} className="m-signature" style={{ textAlign: 'center' }} >
                {this.state.isAutoGenerate ?
                    this.renderAutoGenerateSignature()
                    : [<SignaturePad 
                    key="pad" width="100%" ref="acceptorSignature" clearButton={true} />,
                    <span key="padding" style={{ padding: '20px 0px' }} ></span>,
                    <a
                        key="button"
                        style={newSignButton}
                        className="btn btn-default button"
                        onClick={this.saveSign}
                    >
                        Save
            </a>]}
            </Col>
            <div>
                {this.state.isAutoGenerate ?
                    <Col sm={{span:12/* , offset: this.props.className !== 'signature' ? 4 : 0  */}} style={{ textAlign: 'center' }} className='manual-e-signature-button' >
                        <a
                            style={autoSignButton}
                            className="btn btn-default button"
                            onClick={() => this.setState({ isAutoGenerate: false, isManualGenerate: true })}
                        >
                            Sign Manually
                    </a>
                    </Col>
                    : null 
                }
                {this.state.isManualGenerate && 
                    <Col sm={{span:12 }} style={{ textAlign: 'center' }} className='auto-e-signature-button'>
                        <a
                            style={autoSignButton}
                            className="btn btn-default button"
                            onClick={() => this.setState({ isAutoGenerate: true, isManualGenerate: false  })}
                        >
                            Auto Generate Signature
                        </a>
                    </Col>
                }
             </div>  
        {/* </div> */} </>;
    }   
    
    render() {
        return (
            this.renderSignatureInput()
        );
    }
}  
export function forEachStateToProps(state: any, ownProps: any) {
    //const userId = ownProps.match.params.id;
    //const userInstance = UserModel.get(userId, state);
    return {
        //userInstance,
    };
}
export const ManualSignature =  connect<{}, {}, IManualSignatureProps>(forEachStateToProps)(ManualSignatureImpl);

const newSignButton: React.CSSProperties = {
    color: 'white',
    border: 'none',
    backgroundColor: '#26a65b',
    width: '75px',
    borderRadius: '5px',
    padding: '5px 0'
};

const autoSignButton: React.CSSProperties = {
    color: 'white',
    border: 'none',
    backgroundColor: '#26a65b',
    borderRadius: '5px',
    padding: '5px 15px',
    marginTop: '10px'
};
