import * as React from 'react';
import { PersonalSafetyModel } from '../../../model/PersonalSafetyModel';
import { Async } from '../../reusableComponents/Async';
import { Loader } from '../../reusableComponents/Loader';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Title } from '../../reusableComponents/Title';
import { Row, Col } from 'react-bootstrap';
import { getPersonalSafetyFormDetails } from '../../../services/personalSafetyFormService';
import { connect } from 'react-redux';
import { BasePage } from '../../reusableComponents/BasePage';
import { UserModel } from '../../../model/UserModel';
import { getAllUsers } from '../../../services/userService';
import { format } from 'date-fns';
import { DetailsPageWrapper } from '../../reusableComponents/DetailsPageWrapper';
import { KeyValue } from '../../../utils/generalUtils';
import './personalSafetyForm.scss';
import { VideoPlay } from '../../reusableComponents/FormComponents/VideoPlay'; 
import noImage from '../../../images/noImage.png'; 
import { shoCarousel } from '../../../actions/miscellaneousActions';
import { Img } from '../../reusableComponents/ImageComponents/Img';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { IHistory } from '../../../interfaces';

export interface IDetailsPageProps {
    userUID: string;
    formID: string;
    instance: PersonalSafetyModel;
    companyName: string;
    history?: IHistory;
}

export class DetailsPageImpl extends React.Component<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = async () => {
        const { formID } = this.props;
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        await getPersonalSafetyFormDetails(formID);
        return;
    }

    renderContent = () => {
        const { instance } = this.props;
        if (!instance) {
            return <div></div>;
        } 
        const userInstance = UserModel.getUserByUID(instance.props.userId);
        const isMyForm = true; //  UserModel.getUserUID() === instance.props.userId && !UserModel.isAdmin();
        let supervisorName = 'NA'; 
        if (UserModel.list().length >= 1 && instance.props.supervisor) {
            UserModel.list().forEach((ele: { props: { userUID: string; firstName: string; lastName: string; }; }) => {  
                if(ele.props.userUID === instance.props.supervisor) { 
                    supervisorName = ele.props.firstName + ' ' + ele.props.lastName; 
               } 
               
            });
        } 
        return <React.Fragment>
            <Title text="Personal Safety Form Details"></Title>
            <KeyValue label="Name">{userInstance ? `${userInstance.props.firstName} 
            ${userInstance.props.lastName}` : 'NA'}</KeyValue>
             <KeyValue label="Supervisor Name">{supervisorName}</KeyValue>
            <div className="action-label" style={{ marginTop: '20px' }}>
                LEADERSHIP BEHAVIOR: CREDIBILITY
                    </div>
            <Row className="show-grid">
                <Col sm={12} className="que-detail" >
                    1. Maintain <i><b>safety as first priority with clients/contractors/partners</b></i>;
                follow through on commitments to build
                trust and help them succeed.
                        </Col>
            </Row>
            <KeyValue label="Commitment">{instance.props.credebilityHelp}</KeyValue>
            <KeyValue label="Date">{instance.props.credibilityDate && format(new Date(instance.props.credibilityDate), 'MM/dd/yyyy')}</KeyValue>
            {isMyForm && <KeyValue label="Comments">{instance.props.credebilityComment}</KeyValue>}
            <div className="action-label" style={{ marginTop: '20px' }}>
                LEADERSHIP BEHAVIOR: ACTION
                        </div>
            <Row className="show-grid">
                <Col sm={12} className="que-detail" >
                    1. <i><b>Always intervene in potentially unsafe acts </b></i>
                    or conditions in a positive way to make others fix
                            the problem and feel good about doing it.< br />
                    &emsp; 2. Promote the use of SafeConnect to share and report
                    observations on a regular basis (2 per week).
                                <br />
                    &emsp; &emsp; a. Lead by example
                        </Col>
            </Row>
            <KeyValue label="Commitment">{instance.props.actionHelp}</KeyValue>
            <KeyValue label="Date">{instance.props.actionDate && format(new Date(instance.props.actionDate), 'MM/dd/yyyy')}</KeyValue>
            {isMyForm && <KeyValue label="Comments">{instance.props.actionComment}</KeyValue>}
            <div className="action-label" style={{ marginTop: '20px' }}>
                LEADERSHIP BEHAVIOR: RESILIENCE
                    </div >
            <Row className="show-grid">
                <Col sm={12} className="que-detail" >
                    1. React positively to bad incidents by recognizing the
                only real good is to capture learning from
                            what happened to <b><i>improve future performance.</i></b>
                    <br />2. Encourage all of us to behave this way.
                        </Col>
            </Row>
            <KeyValue label="Commitment">{instance.props.resilienceHelp}</KeyValue>
            <KeyValue label="Date">{instance.props.resilienceDate && format(new Date(instance.props.resilienceDate), 'MM/dd/yyyy')}</KeyValue>
            {isMyForm && <KeyValue label="Comments">{instance.props.resilienceComment}</KeyValue>}
            <div className="action-label" style={{ marginTop: '20px' }}>
                LEADERSHIP BEHAVIOR: ENGAGEMENT
                    </div >
            <Row className="show-grid">
                <Col sm={12} className="que-detail" >
                    1. Improve oral and written communication skills to
                            <b><i> increase my effectiveness.</i></b> <br />
                    2. Stop work when appropriate, do management walkarounds, etc. to
                            <b><i> demonstrate consistency and commitment.</i></b><br />
                    3. <b><i>Promote the use of the consistent approach to Safety Leadership </i></b>
                    by acting as both a team participant
                and Management Sponsor of the new behavior &quot;model&quot;
                using SafeConnect and capturing best practices to share and incorporate in all we do.
                        </Col>
            </Row>
            <KeyValue label="Commitment">{instance.props.engagementHelp}</KeyValue>
            <KeyValue label="Date">{instance.props.engagementDate && format(new Date(instance.props.engagementDate), 'MM/dd/yyyy')}</KeyValue>
            {isMyForm && <KeyValue label="Comments">{instance.props.engagementComment}</KeyValue>}

            <div className="action-label" style={{ marginTop: '20px' }}> HUMAN PERFORMANCE CONSIDERATIONS  </div >
            <Row className="show-grid">
                <Col sm={12} className="que-detail" >
                    1. Human Performance are actions taken to accomplish work <br />
                    2. Key concepts are avoiding error, planning, work knowledge, learning from experience etc.
                </Col>
            </Row>
            <KeyValue label="Commitment">{instance.props.considerationsHelp}</KeyValue>
            <KeyValue label="Date">{instance.props.considerationsDate && format(new Date(instance.props.considerationsDate), 'MM/dd/yyyy')}</KeyValue>
            {isMyForm && <KeyValue label="Comments">{instance.props.considerationsComment}</KeyValue>}

            <Row className="clearfix"></Row>
                <Row className="show-grid">
                    <Col xs={6}
                        className="details-label"
                        style={{ textAlign: 'right' }} sm={6}>Images:</Col>
                    <Col className="details-value closecall-images"
                        sm={12} xs={12} id="detailsChild" >{this.renderImages()}</Col>
                </Row > 

                <Row className="show-grid"> 
                    <Col xs={6}
                        className="details-label"
                        style={{ textAlign: 'right' }} sm={6}>Videos:</Col>
                    <Col className="details-value closecall-videos"
                        sm={12} xs={12} id="detailsChild" >{<VideoPlay videoUrls={instance.props.videoUrls}/>}</Col>
                </Row > 
        </React.Fragment>;
    }
    renderImages = () => {
        const { instance } = this.props; 
        if ((instance.props.imageUrls || []).length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        } 
        return instance.props.imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData} 
            />;
        });
    } 
    render() {
        return (
            <BasePage>
                <Async
                    identifier="PersonalSafetyDetailsPage"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={<DetailsPageWrapper
                        pdfFileName={'PersonalSafetyForm'}
                        className="oi-report report-details psp-reports" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                        <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                       Close
                    </Button>
                    }>
                        {this.renderContent()}
                    </DetailsPageWrapper>}
                />
            </BasePage>
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const userUID = state.login.get('userUID');
    const formID = ownProps.match.params.id;
    const companyName = state.login.get('companyName');
    const instance = PersonalSafetyModel.get(formID);
    return {
        instance, userUID, companyName, formID
    };
}

export const DetailsPage = connect<{}, {}, IDetailsPageProps>(mapStateToProps)(DetailsPageImpl);
