import {
    DELETE_INSTANCE,
    REMOVE_INSTANCE,
    SAVE_FILTERED_INSTANCE,
    SAVE_INSTANCE,
    UPDATE_INSTANCE,
} from '../constants/actions';
import { BaseModel } from '../model/BaseModel';
import { dispatch } from '../utils/generalUtils';

//export function saveInstance(instance: BaseModel<{}>, key: string, identifier?: string) {
export function saveInstance(instance: any, key: string, identifier?: string) {
    return dispatch({
        type: identifier ? `${SAVE_INSTANCE}/${identifier}` : SAVE_INSTANCE,
        instance,
        key
    });
}

//export function saveFilteredInstance(instance: BaseModel<{}>, key: string, filterBy: string, identifier?: string) {
export function saveFilteredInstance(instance: any, key: string, filterBy: string, identifier?: string) {
    return dispatch({
        type: identifier ? `${SAVE_FILTERED_INSTANCE}/${identifier}` : SAVE_FILTERED_INSTANCE,
        instance,
        key,
        filterBy
    });
}

//export function deleteInstance(instance: BaseModel<{}>, key: string) {
export function deleteInstance(instance: any, key: string) {
    return dispatch({
        type: DELETE_INSTANCE,
        instance,
        key
    });
}

export function removeInstance(key: string) {
    return dispatch({
        type: REMOVE_INSTANCE,
        key
    });
}
export function saveAllInstances<T extends BaseModel<{}>>(instance: T[]) {
}

export function deleteAllInstances<T>() {
    return dispatch({
        type: DELETE_INSTANCE,
        key: 1 
    });
}

export function updateInstance(key: string, instance: any) {
    return dispatch({
        type: UPDATE_INSTANCE,
        key,
        instance
    });
}
