import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { ToolBoxMeetingModel } from '../../model/ToolBoxMeetingModel';
import { UserModel } from '../../model/UserModel'; 
import { convertTime } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { getFormData } from '../../services/tsmService';
import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap'; 
import { Label } from '../reusableComponents/FormComponents/Label';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import './index.scss';

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    instance: ToolBoxMeetingModel;
}
export interface IDetailsPageState {
    imageURLs: string[];
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''] };
    }

    promise = async () => {  
        if(this.props.match?.params.id)
            await getFormData(this.props.match?.params.id); 
        return null;
    }
 

    getObserverName = (userID: any) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    renderContent = () => {
        const { instance } = this.props;
        if (!instance) {
            return <Loader />;
        } 
        return (
            <div>
                <Title text={`TSM Report for ${format(new Date(instance.props.dateOfIncident),'MM/dd/yyyy')}`}></Title> 
                <fieldset> 
                    <Row className="show-grid" >
                        <Col sm={12} xs={12}><legend className="legend-header" >Section 1: General Information </legend></Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data" >Date: </Label> 
                            <Label sm={12} className="key-value ">{instance.props.dateOfIncident ? format(new Date(instance.props.dateOfIncident), 'MM/dd/yyyy') : ''}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Time: </Label> 
                            <Label sm={12} className="key-value ">{instance.props.timeOfIncident ?convertTime(instance.props.timeOfIncident) : ''}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Location: </Label>
                            <Label sm={12} className="key-value ">{instance.props.locationName || ''}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Task: </Label> 
                            <Label sm={12} className="key-value ">{instance.props.taskToPerform || ''}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Project Name: </Label> 
                            <Label sm={12} className="key-value ">{instance.props.projectName || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Group Name: </Label> 
                            <Label sm={12} className="key-value ">{instance.props.groupName || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">TSM Coordinater:</Label>
                            <Label sm={12} className="key-value ">{instance.props.tsmCoordinater || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Primary Assembly Point:  </Label> 
                            <Label sm={12} className="key-value ">{instance.props.primaryAssemblyPoint || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Secondary Assembly Point: </Label>
                            <Label sm={12} className="key-value ">{instance.props.secondaryAssemblyPoint || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Nearest Medical Facility: </Label>
                            <Label sm={12} className="key-value ">{instance.props.nearestMedicalFacility || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Nearest Fire Extinguisher: </Label>
                            <Label sm={12} className="key-value ">{instance.props.nearestFireExtinguisher || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Nearest First Aid Kit: </Label>
                            <Label sm={12} className="key-value ">{instance.props.nearestFirstAidKit || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Nearest Eye Wash: </Label>
                            <Label sm={12} className="key-value ">{instance.props.nearestEyeWash || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Cell Phone Usage: </Label>
                            <Label sm={12} className="key-value ">{instance.props.cellPhoneUsage==='1'?'Yes':'NA'}</Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Are there other personnel not required for this Task present during execution?  <span className="key-value ">{instance.props.isExternalPersonNeed==='1'?'Yes':'NA'}</span> </Label> 
                        </Col>
                        {instance.props.isExternalPersonNeed==='1' && 
                            <Col sm={12} xs={12}>
                                <Label sm={12} className="checkbox-label key-data"><span className="color-red">if External personnel needed</span>, Will those personnel be affected by this Task <span className="key-value ">{instance.props.isExternalPersonAffected==='1'?'Yes':(instance.props.isExternalPersonAffected==='0'?'No':'NA')}</span> </Label> 
                            </Col> }
                        {instance.props.isExternalPersonAffected==='1' && 
                            <Col sm={12} xs={12}>
                                <Label sm={12} className="checkbox-label key-data"><span className="color-red">if External personnel affected</span>, Those personnel are briefed about the Task and present in TSM  <span className="key-value ">{instance.props.isExternalPersonBriefed==='1'?'Yes':(instance.props.isExternalPersonBriefed==='0'?'No':'NA')}</span> </Label> 
                            </Col> }
                    </Row>
                    <Row className="show-grid" >
                        <Col sm={12} xs={12}><legend className="legend-header" >Section 2: Work Details </legend></Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Description of Tasks to be performed </Label> 
                            {this.renderWorkDetails()}
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Are employees working within hearing distance from each other?  <span className="key-value ">{instance.props.employeesWithinDistance==='1'?'Yes':'No'}</span> </Label> 
                        </Col>
                        {instance.props.employeesWithinDistance==='0' && 
                            <Col sm={12} xs={12}>
                                <Label sm={12} className="checkbox-label key-data"><span className="color-red">if no,</span> Communication is through?  <span className="key-value ">{`${instance.props.throughCellPhone==='1'?'Cell Phone, ':''} ${instance.props.throughCellPhone==='1'?'Land Line, ':''} ${instance.props.through2WayWalkie==='1'?'2 Way Walkie Talkie, ':''} ${instance.props.throughOtherCommunication==='1'?instance.props.specifyOtherCommunication:''}` }</span> </Label> 
                            </Col> }
                    </Row>
                    <Row className="show-grid" >
                        <Col sm={12} xs={12}><legend className="legend-header" >Section 3: Hazard Identification & Control </legend></Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Type of work:  <span className="key-value ">{ `
                                ${instance.props.hotWork==='1'?'Hot Work, ':''} ${instance.props.lockoutTagout==='1'?'Lockout/Tagout, ':''} ${instance.props.excavation==='1'?'Excavation, ':''} ${instance.props.confinedSpace==='1'?'Confined Space, ':''} ${instance.props.otherTypeOfWork==='1'?'Others':''}
                            `}</span> </Label> 
                        </Col>
                        { instance.props.otherTypeOfWork==='1' && <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Confirm availability of additional permit/forms: </Label>
                            <Label sm={12} className="key-value ">{instance.props.additionalPermitAvailablity==='1'?'Yes':'No'}</Label> 
                        </Col>}
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Hazards for this Task: <span className="key-value ">{ `
                                ${instance.props.hazardousAtmosphere==='1'?'Hazardous Atmosphere, ':''} ${instance.props.overheadHazards==='1'?'Overhead Hazards, ':''} ${instance.props.temperatureExtremesHazard==='1'?'Temperature extremes (hot/cold), ':''} ${instance.props.chemicalExposureHazard==='1'?'Chemical Exposure, ':''} ${instance.props.safetySystemsHazard==='1'?'Safety Systems Bypasses/disabled, ':''} ${instance.props.slipsHazard==='1'?'Slips/ Trips/ Falls/ Alternate Route, ':''} ${instance.props.dustyHazard==='1'?'Dusty environment, ':''} ${instance.props.weatherHazard==='1'?'Weather Hazards, ':''} ${instance.props.roadwayHazard==='1'?'Roadway Work (traffic control), ':''} ${instance.props.fallFromHeightHazard==='1'?'Fall From Heights, ':''} ${instance.props.heavyLoadsHazard==='1'?'Heavy Loads, ':''} ${instance.props.excavationCollapseHazard==='1'?'Excavation Collapse, ':''} ${instance.props.movingMachinaryHazard==='1'?'Moving Machinary, ':''} ${instance.props.noiseHazard==='1'?'Noise, ':''} ${instance.props.adjacentOperationHazard==='1'?'Adjacent Operations, ':''} ${instance.props.wildLifeHazard==='1'?'Wild Life (reptiles, bears, etc), ':''} ${instance.props.suspendedLoadsHazard==='1'?'Suspended Loads/Rigging, ':''} ${instance.props.electricalHazard==='1'?'Electrical, ':''} ${instance.props.mobileEquipmentsHazard==='1'?'Mobile Equipments, ':''} ${instance.props.insectsHazard==='1'?'Insects (bees, ticks etc), ':''} ${instance.props.ignitionSouresHazard==='1'?'Ignition Sources, ':''} ${instance.props.pinchPointsHazard==='1'?'Pinch Points, ':''} ${instance.props.overExertionHazard==='1'?'Over Exertion, ':''} ${instance.props.poisonHazard==='1'?'Poison Oak, Ivy, Summac etc, ':''} ${instance.props.loneWorkerHazard==='1'?'Lone Worker, ':''} ${instance.props.otherHazard==='1'?instance.props.specifyOtherHazard:''}
                            `}</span> </Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Indicate driving hazards inside (weel heads, barriers, low hanging tree branches, tanks etc): </Label>
                            <Label sm={12} className="key-value ">{instance.props.drivingHazard || 'NA'}</Label> 
                        </Col>
                    </Row>
                    <Row className="show-grid" >
                        <Col sm={12} xs={12}><legend className="legend-header" >Section 4: Additional Personal Protection </legend></Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">General PPE:  <span className="key-value ">{ `
                                ${instance.props.faceShield==='1'?'Face Shield, ':''} ${instance.props.frClothing==='1'?'FR Clothing, ':''} ${instance.props.hearingProtection==='1'?'Hearing Protection, ':''} ${instance.props.chainsawChaps==='1'?'Chainsaw Chaps, ':''} ${instance.props.otherGeneralPPE==='1'?instance.props.specifyOtherGeneralPPE:''}
                            `}</span> </Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Gloves:  <span className="key-value ">{ `
                                ${instance.props.generalPurposeGloves==='1'?'General Purpose, ':''} ${instance.props.chemicalResistant==='1'?'Chemical Resistant, ':''} ${instance.props.heatResistant==='1'?'Heat Resistant, ':''} ${instance.props.otherGloves==='1'?instance.props.specifyOtherGlovesPPE:''}
                            `}</span> </Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Fall Arrrest:  <span className="key-value ">{ `
                                ${instance.props.harness==='1'?'Harness, ':''} ${instance.props.lanyard==='1'?'Lanyard, ':''} ${instance.props.retrievalLine==='1'?'Retrieval Line, ':''} ${instance.props.otherFallArrestPPE==='1'?instance.props.specifyOtherFallArrestPPE:''}
                            `}</span> </Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Personal Monitors:  <span className="key-value ">{ `
                                ${instance.props.fourGasMonitor==='1'?'4-Gas monitor, ':''} ${instance.props.h2s==='1'?'H2S, ':''} ${instance.props.o2==='1'?'O2, ':''} ${instance.props.lel==='1'?'LEL':''}
                            `}</span> </Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Respirator Type:  <span className="key-value ">{ `
                                ${instance.props.dustMask==='1'?'Dust mask, ':''} ${instance.props.halfMaskAPR==='1'?'1/2 mask APR, ':''} ${instance.props.scba==='1'?'SCBA, ':''} ${instance.props.otherRespiratorType==='1'?instance.props.specifyOtherRespiratorType:''}
                            `}</span> </Label> 
                        </Col>
                    </Row>
                    <Row className="show-grid" >
                        <Col sm={12} xs={12}><legend className="legend-header" >Section 5: TSM Completion </legend></Col>
                        <Label sm={4} className="checkbox-label key-data">TSM Leader</Label>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Name: </Label>
                            <Label sm={12} className="key-value ">{instance.props.TSMLeaderName || 'NA'}</Label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Signature: </Label>
                            <Label sm={12} className="key-value ">
                                { instance.props.TSMLeaderSignatureURL ? <img height="56px" width="160px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={instance.props.TSMLeaderSignatureURL} alt="SafeConnect" />:'NA'}</Label> 
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data"> Company employees conducting the task:</Label>
                            <Label sm={12} className="key-value ">{this.renderEmployeeDetails()} </Label>
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data"> Contract employees conducting the task:</Label>
                            <Label sm={12} className="key-value ">{this.renderContractEmployeeDetails()} </Label>
                        </Col>
                        <Col sm={12} xs={12}>
                            <Label sm={12} className="checkbox-label key-data">Overall Comments: </Label>
                            <Label sm={12} className="key-value ">{instance.props.overallComments || 'NA'}</Label> 
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} xs={12}>
                            {/*  <div style={{fontSize: '18px', textAlign: 'center', marginTop:'30px' }}  className="color-gray">Attachments</div> */}
                            <Label sm={12} className="checkbox-label key-data">Attachments </Label> 
                            <AttachementInput
                                model=".attachements"
                                multiple={true}
                                id="upload-images" 
                                noNeedAttachButton={true}
                                uploadedImages={instance.props.attachmentUrls || []}
                            />
                        </Col>
                    </Row>
                </fieldset>
            </div >
        );
    }

    renderWorkDetails = () => {
        const { instance } = this.props; 
       return (instance.props.worksDetail || []).map((data: any) => {
            return <Label sm={12} className="key-value " key={data}>{data || 'NA'}</Label>
        });
    }
    renderEmployeeDetails = () => {
        const { instance } = this.props;
        let employees = '';
        if(instance.props.emplyeesNameList.length>0) {
            instance.props.emplyeesNameList.forEach((data)=> {
                employees += data + ', ';
            }); 
        }
        return employees || 'NA';
    }
    renderContractEmployeeDetails = () => {
        const { instance } = this.props;
        let contractEmployees = '';
        if(instance.props.contractEmplyeesName.length>0) {
            instance.props.contractEmplyeesName.forEach((data)=> {
                contractEmployees += data + ', ';
            }); 
        }
        return contractEmployees || 'NA';
    }
    renderFormDetails = () => { 
        return (
            <Async
                identifier="OIDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        let fileName = '';
        if (this.props.instance) {
            const { instance: { props: { dateOfIncident } } } = this.props;
            fileName = `TSM Report for ${format(new Date(dateOfIncident),'MM/dd/yyyy')}`;
        }
        return (
            <BasePage className="min-wide-list-page__"> 
                <DetailsPageWrapper pdfFileName={fileName} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const instance = ToolBoxMeetingModel.get(ownProps.match.params.id);
    return { instance
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
