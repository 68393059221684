import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../FormComponents/Button';
import { Form, actions } from 'react-redux-form';
import PropTypes from 'prop-types';
import { RRFInput } from '../FormComponents/RRFInput';
import { dispatch } from '../../../utils/generalUtils';
import './DateRangeFilter.scss';
import { format, addDays, subDays, differenceInCalendarDays, addMonths, subMonths} from 'date-fns'; 

export interface IDateRangeFilterProps {
    onApply: (startDate: any, endDate: any) => void;
}
export interface IDateRangeFilterState {
    endDateMaxDate: any;
    endDateMinDate: any;
    endDateDefault: any;
    startDateMinDate: any;
    startDateMaxDate: any;
}
export class DateRangeFilter extends React.PureComponent<IDateRangeFilterProps,IDateRangeFilterState > {
    constructor(props: IDateRangeFilterProps | Readonly<IDateRangeFilterProps>) {
        super(props);
        this.state = { endDateMaxDate: format(new Date(), 'yyyy-MM-dd'), endDateMinDate:null,endDateDefault: null, startDateMinDate: null, startDateMaxDate: format(new Date(), 'yyyy-MM-dd') }
    }

    componentWillMount() {
        dispatch(actions.reset('forms.dateRangeFilter'));
    }

    getChildContext() {
        return { formModel: 'forms.dateRangeFilter' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    applyFilter = ({ startDate, endDate }: any) => {
        const { onApply } = this.props;
        if (onApply) {
            return onApply(startDate, endDate);
        }
    }
    onStartDateChange = (date: any) => {  
        if(date==='1970-01-01' || date==='1969-12-31') {
            this.setState({ endDateMinDate:null, endDateMaxDate: format(new Date(), 'yyyy-MM-dd') }) 
            return
        }
        date = format(new Date(date), 'yyyy-MM-dd') 
        /* let maxDate = subDays(format(addMonths(date, 1), 'yyyy-MM-dd'),1) 
        let diff = differenceInCalendarDays(format(new Date(), 'yyyy-MM-dd'), maxDate)  */
        let maxDate = subDays( (addMonths(new Date(date), 1) ),1) 
        let diff = differenceInCalendarDays( (new Date() ), maxDate) 
        this.setState({ endDateMinDate: date}) 
        if(diff>0)
            this.setState({ endDateMaxDate: format(maxDate, 'yyyy-MM-dd'), endDateDefault: format(maxDate, 'yyyy-MM-dd')}) 
    }
    onEndDateChange = (date: any) => {  
        if(date==='1970-01-01' || date==='1969-12-31') {
            this.setState({ startDateMinDate: null, startDateMaxDate: format(new Date(), 'yyyy-MM-dd')}) 
            return
        }
        //date = format(date, 'yyyy-MM-dd')  
       /*  let minDate = addDays(format(subMonths(date, 1), 'yyyy-MM-dd'),1);  */
        let minDate = addDays( (subMonths(date, 1) ),1); 
        // this.setState({ startDateMinDate: format(minDate, 'yyyy-MM-dd'), startDateMaxDate: date}) 
        this.setState({ startDateMinDate: minDate, startDateMaxDate: date}) 
    }
    renderDateRangeFilter = () => { 
        return (
            <Form model="forms.dateRangeFilter" onSubmit={this.applyFilter}>
                <Row className="show-grid filter" style={{ padding: '10px 0px' }}>
                    <Col sm={5} className="input month-select">
                        <RRFInput
                            id="startDate"
                            type="date" 
                            model=".startDate"
                            onChange={this.onStartDateChange}
                            mindate={this.state.startDateMinDate}
                            maxdate={this.state.startDateMaxDate}
                        />
                    </Col>
                    <Col sm={5} className="input year-select">
                        <RRFInput
                            id="endDate"
                            type="date" 
                            model=".endDate"
                            onChange={this.onEndDateChange}
                            mindate={this.state.endDateMinDate}
                            maxdate={this.state.endDateMaxDate}
                            defaultValue={this.state.endDateDefault} 
                        />  
                    </Col>
                    <Col sm={2} className="input done">
                        <Button
                            type="submit"
                            style={{
                                width: '100%',
                                color: '#5479AF',
                                border: '1px solid #5479AF', backgroundColor: 'white',
                                marginLeft: '20px', marginRight: '20px'
                            }}
                        >
                            Done
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
    render() {
        return this.renderDateRangeFilter();
    }
}
