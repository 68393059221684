import * as React from 'react';
import { Col, Row, Container as Grid } from 'react-bootstrap'; 
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IAttribute, IHistory, IForms  } from '../../interfaces';  

import { Alert } from '../Alert';
import { BasePage } from '../reusableComponents/BasePage'; 
import { Async } from '../reusableComponents/Async'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader'; 
import { Title } from '../reusableComponents/Title'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general';  
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader'; 
import { dispatch, isFormEmpty, showAlert, isArrayEmptyAndAlert } from '../../utils/generalUtils'; 
import { actions } from 'react-redux-form';
import '../SafetyReport/SafetyWalkAssesment/safetyWalkAssesment.scss'; 
import { addAttribute, updateAttribute } from '../../services/attributeService';
import { editAttribute } from '../../actions/attributeAction'; 

export interface IChecklistAttributeFormProps { 
    onSubmit?: (data: Object) => void;
    history?: IHistory;
    onCancel?: () => void; 
    userUID: string;
    checklistId: string;
    editingAttribute: any;
    forms: IForms;
}

export interface IChecklistAttributeFormState { 
    subAttribute: IAttribute[];
}

export class ChecklistAttributeFormImpl extends React.PureComponent<IChecklistAttributeFormProps, IChecklistAttributeFormState> { 
    constructor(props: IChecklistAttributeFormProps | Readonly<IChecklistAttributeFormProps>) {
        super(props);
        let subAttributeArray: Array<IAttribute> = [{name: '', count: 0}];
        this.state = { subAttribute: subAttributeArray };
    }
    promise = async () => {
        return;
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    getChildContext() {
        return { formModel: 'forms.attributeFrom' };
    }  
    componentDidMount() {
        if (this.props.editingAttribute && this.props.editingAttribute.subAttributeList) {
            dispatch(actions.change('forms.attributeFrom.attribute',
                this.props.editingAttribute.attribute));
            let subAttributeArray: Array<IAttribute> = [];
            this.props.editingAttribute.subAttributeList.forEach((item: { name: any; }, index: any) => {
                let newSubAttribute: IAttribute = { name: item.name, count: 0 };
                subAttributeArray.push(newSubAttribute);
                dispatch(actions.change(`forms.attributeFrom.name[${index}]`, item.name));
            });
            this.setState({ subAttribute: subAttributeArray });
        }
        // check if it is edit page or add
        // define one props as editing or adding then bind that in UI
        // dispatch(actions.reset('forms.attributeFrom'));
    }
    navigateBack = () => { 
        let editedData: any = [];
        dispatch(editAttribute(true, editedData));
        this.props.history?.push(`/checklist-form/${this.props.checklistId}`);
    }
    handleSubmit = async (submittedValues: { mainAttribute: string; name: { [x: string]: any; }; }) => {  
        const { editingAttribute } = this.props;  
        const validateValues = {mainAttribute:submittedValues.mainAttribute}
        if (isFormEmpty(validateValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        }
        if(isArrayEmptyAndAlert( {subAttribute: submittedValues.name || [] } )) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        } 
        let subAttribute: any[] = []; 
        Object.keys(submittedValues.name).forEach(function (index) { 
            subAttribute.push(submittedValues.name[index]  )
        });
         
        let attributeParam: any = { formType: '', mainAttr: '', subAttr: [], oldAttr:'' };
        attributeParam.formType = this.props.checklistId;
        attributeParam.mainAttr = submittedValues.mainAttribute;
        attributeParam.oldAttr = (editingAttribute && editingAttribute.attribute) || '';
        attributeParam.subAttr = subAttribute;
        if ((editingAttribute && editingAttribute.attribute) || (editingAttribute && editingAttribute.size !== 0)) {
            await updateAttribute(attributeParam);
        } else {
            await addAttribute(attributeParam);
        }
        let editedData: any = [];
        dispatch(editAttribute(true, editedData));
        this.props.history?.push(`/checklist-form/${this.props.checklistId}`);
    }
    incrementRows = () => {
        const attributeCount = this.state.subAttribute.length;
        this.setState({
            subAttribute: [...this.state.subAttribute, { count: attributeCount, name: '' }]
        }); 
    }
    decrementRow = (index: number) => {
        const { subAttribute } = this.state; 
        dispatch(actions.remove(`forms.attributeFrom.name`, index));
        let newArray = subAttribute.filter(e => e !== subAttribute[index]); 
        this.setState({
            subAttribute: newArray
        });
    }
    renderSubAttributeContent = () => {
        const { subAttribute } = this.state;
        return subAttribute.map((attribute, index) => {
            return <Row key={index} className="show-grid">
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={11} sm={11} className="input">
                    <RRFInput
                        type="text"
                        placeholder="Enter sub attribute"
                        model={`.name[${index}]`} 
                        defaultValue={attribute.name}
                    />
                </Col>
                <Col sm={1}>
                    { subAttribute.length>1 && <i className="fa fa-trash-o margin-top-delete" onClick={() => this.decrementRow(index)} aria-hidden="true"></i>}
                </Col>
            </Row>;
        });
    }
    renderAttributeContent() {
        const { editingAttribute } = this.props; 
        return (
            <div className="project-list-wrapper">
                <Grid style={{ width: '100%' }} className="safety-walkthrough-addattribute">
                    <Title titleColor="yellow" text={`${editingAttribute && editingAttribute.attribute?'Edit':'Add'} Checklist Attribute`} />
                    <Alert className="danger-alert" id="attribute-form" />
                    <Form loader={<SubmissionLoader />} onSubmit={this.handleSubmit} model="forms.attributeFrom">

                        <Row className="show-grid">
                            <Col
                                style={{ padding: '10px 15px', display: 'inline-block' }}
                                xs={11}
                                sm={11}
                                className="input"
                            >
                                <RRFInput
                                    type="text"
                                    placeholder="Enter attribute name"
                                    model={`.mainAttribute`} 
                                    defaultValue={editingAttribute&& editingAttribute.attribute}
                                />
                            </Col>
                        </Row> 
                        
                        {this.renderSubAttributeContent()}
                        <div className="table-buttons">
                            <button
                                className="add-row"
                                type="button"
                                onClick={this.incrementRows}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add More SubAttribute
                        </button>
                        </div>
                        <div style={{ textAlign: 'center' }} className="edit-button form-button">
                            
                            <Alert className="danger-alert" id="attribute-form-failed" />
                            <Button type="submit">Update</Button>
                            <Button style={{
                                color: '#5479AF',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }} onClick={async () => {
                                this.navigateBack();
                            }} type="reset">Cancel</Button>
                        </div>
                    </Form>
                </Grid>
            </div>
        );
    }
    render() {
        return (
            <BasePage className="safety-addattribute_form" >
                <Async
                    identifier="SafetyWalkthroughAddAttribute"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderAttributeContent()}
                />
            </BasePage >
        );
    }
}
 
export function mapStateToProps( state: any,ownProps: any ) {
    const userUID = state.login.get('userUID'); 
    const checklistId = ownProps.match.params.checklistId; 
    const editingAttribute = state.attribute.get('editingAttribute');
    return {
        userUID, checklistId, editingAttribute, forms: state.forms 
    };
}
export const ChecklistAttributeForm = withRouter(connect< IChecklistAttributeFormProps, any, any>(mapStateToProps)(ChecklistAttributeFormImpl)); 