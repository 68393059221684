import { BaseModel } from './BaseModel'; 
 
export interface IToolBoxMeetingModelProps {
    id: string;
    key?: string; 
    date: string;
    dateOfIncident: string;
    timeOfIncident: string;
    location?: string;
    locationName: string ;
    specifiedLocation: string;
    groupName: string;
    projectName: string; 
    company: string; 
    taskToPerform: string;
    tsmCoordinater: string;
    primaryAssemblyPoint: string;
    secondaryAssemblyPoint: string;
    nearestMedicalFacility: string;
    nearestFireExtinguisher: string;
    nearestFirstAidKit: string;
    nearestEyeWash: string;
    cellPhoneUsage: any;
    isExternalPersonNeed: any;
    isExternalPersonAffected: any;
    isExternalPersonBriefed: any;
    worksDetail: any[];
    employeesWithinDistance: any;
    throughCellPhone: any;
    throughLandLine: any;
    through2WayWalkie: any;
    throughOtherCommunication: any;
    specifyOtherCommunication: any;
    hotWork: any;
    lockoutTagout: any;
    excavation: any; 
    confinedSpace: any;
    otherTypeOfWork: any;
    additionalPermitAvailablity: any; 
    hazardousAtmosphere: any;
    overheadHazards: any;
    temperatureExtremesHazard: any;
    chemicalExposureHazard: any;
    safetySystemsHazard: any;
    slipsHazard: any;
    dustyHazard: any;
    weatherHazard: any;
    roadwayHazard: any;
    fallFromHeightHazard: any;
    heavyLoadsHazard: any;
    excavationCollapseHazard: any;
    movingMachinaryHazard: any;
    noiseHazard: any;
    adjacentOperationHazard: any;
    wildLifeHazard: any;
    suspendedLoadsHazard: any;
    electricalHazard: any;
    mobileEquipmentsHazard: any; 
    insectsHazard: any;
    ignitionSouresHazard: any;
    pinchPointsHazard: any;
    overExertionHazard: any;
    poisonHazard: any;
    loneWorkerHazard: any;
    otherHazard: any;
    specifyOtherHazard: any;
    drivingHazard: any;
    faceShield: any;
    frClothing: any;
    hearingProtection: any;
    chainsawChaps: any;
    otherGeneralPPE: any;
    specifyOtherGeneralPPE: any;
    generalPurposeGloves: any;
    chemicalResistant: any;
    heatResistant: any;
    otherGloves: any;
    specifyOtherGlovesPPE: any;
    harness: any; 
    lanyard: any;
    retrievalLine: any;
    otherFallArrestPPE: any;
    specifyOtherFallArrestPPE: any;
    fourGasMonitor: any;
    h2s: any;
    o2: any; 
    lel: any;
    dustMask: any;
    halfMaskAPR: any;
    scba: any; 
    otherRespiratorType: any;
    specifyOtherRespiratorType: any;

    TSMLeaderName: any[];
    TSMLeaderSignature: any[];
    TSMLeaderSignatureURL: any;

    emplyeesName: any[];
    emplyeesNameList: any[];
    emplyeesSignature: any[];

    contractEmplyeesName: any[];
    contractEmplyeesSignature: any[];

    overallComments: any;

    attachements: any[];
    attachmentUrls: any[];
    deletedFiles: any[];  
} 
export class ToolBoxMeetingModel extends BaseModel<IToolBoxMeetingModelProps> {
    constructor(props: IToolBoxMeetingModelProps) {
        super(props, ToolBoxMeetingModel.resource);
    }
    static resource = 'tool-box-meeting-form';
}

export interface IWorkDetails { 
    worksDetail: string;
}

export interface IEmployeesDetails { 
    name: string; 
}

export interface IContractEmployeesDetails { 
    name: string; 
}
