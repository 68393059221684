import { SAVE_FILTERED_GROUP_LIST, SAVE_ALL_GROUPS } from '../constants/actions';
import { dispatch } from '../utils/generalUtils';

export function saveFilteredGroups(groupList: string[]) {
    return dispatch({
        type: SAVE_FILTERED_GROUP_LIST,
        groupList
    });
}

export function saveAllGroups(groupNames: string[]) {
    return dispatch({
        type: SAVE_ALL_GROUPS,
        groupNames
    });
}
