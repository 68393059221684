import { BaseModel } from './BaseModel';

export interface IFeedbackModel {
    id?: string;
    recreate?: string;
    date?: string;
    uniqueId?: string;
    device?: string;
    os?: string;
    uniqueUserId?: string;
    problem?: string;
}
export class FeedbackModel extends BaseModel<IFeedbackModel> {
    constructor(props: IFeedbackModel) {
        super(props, FeedbackModel.resource);
    }
 
    static resource = 'feedback';

}
