import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as React from 'react';
import { Col, Row, DropdownButton, Dropdown, Button } from 'react-bootstrap'; 
import { withRouter } from 'react-router-dom'; 
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { connect } from 'react-redux';
import { IHistory } from '../../interfaces';
import { showDelConfirmModal } from '../../actions/modalActions';
import { UserModel } from '../../model/UserModel'; 
import { INCIDENTINVESTIGATE_PREFIX } from '../../constants/general';
import { checkInvestigationEditAccess } from '../../services/incidentReportService';
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: IncidentReportModel;
    style?: React.CSSProperties;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderFormList = () => {
        const { instance, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        } 
        return (
            <Row className="report-row"  style={style}>
                <Col className={`clickable-list-item ${instance.props.finalReport!==1?( instance.props.isFinalSubmit==='1'?'font-success text-18':'font-danger'):'' }`} sm={1} >
                { ( UserModel.checkUserAccess('incident_investigative_view') || UserModel.checkUserAccess('incident_investigative_edit') || UserModel.checkUserAccess('incident_investigative_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                    { UserModel.checkUserAccess('incident_investigative_view') && <MenuItem eventKey="1"
                        onClick={() => this.props.history?.push(`/incident-investigative-reports/view/${instance.props.id}`)}
                        className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                    { UserModel.checkUserAccess('incident_investigative_edit') && (instance.props.finalReport===0 || checkInvestigationEditAccess(instance.props.userAccessLevel || 'L3') ) && <MenuItem eventKey="2"
                        onClick={() => this.onEditAttribute(instance.props)}
                        className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                    { UserModel.checkUserAccess('incident_investigative_delete') && <MenuItem eventKey="3"
                        onClick={() => this.onDeleteAttribute()}
                        className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton> }
                <span className="serial-no"> {`${INCIDENTINVESTIGATE_PREFIX}-${instance.props.formSerialNo}`} </span></Col>
                <Col className="clickable-list-item" sm={2} onClick={() => this.onViewAttribute(instance.props.id) } >{instance.props.date || 'NA'}</Col> 
                <Col className="clickable-list-item" sm={2} onClick={() => this.onViewAttribute(instance.props.id) } >{instance.props.location || 'NA'}</Col>
                <Col className="clickable-list-item" sm={2} onClick={() => this.onViewAttribute(instance.props.id) } >{instance.props.actualSeverity || 'NA'}</Col>
                <Col className="clickable-list-item" sm={3} onClick={() => this.onViewAttribute(instance.props.id) } >{instance.props.potentialSeverity || 'NA'}</Col>
                <Col className="clickable-list-item" sm={2} onClick={() => this.getFollowUpList(instance.props)} > 
                    <Button className="closecall-followup-button" > {instance.props.totalClosedFollowUp || '0'}/{instance.props.totalFollowUp || '0'}</Button> 
                </Col> 
            </Row >
        );
    }
    getFollowUpList(instance: any) { 
        this.props.history?.push(`/incident-investigative-reports/follow-up/${instance.key}`);  
    }
    onDeleteAttribute() {
        const { instance } = this.props; 
        if(instance) {
            instance.props['company'] = UserModel.getCompanyName(); 
        }
        instance && showDelConfirmModal('delete-confirmation', 'Incident Investigative Form', instance.props);  
    }
    onEditAttribute(instance: any) { 
        this.props.history?.push(`/incident-investigative-reports/edit/${instance.key}`);
    }
    onViewAttribute(id: any) { 
        if(!UserModel.checkUserAccess('incident_investigative_view') )
            return;
            this.props.history?.push(`/incident-investigative-reports/view/${id}`)
    }
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return { 
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
