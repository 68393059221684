import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { Cell } from '../../reusableComponents/ChartComponents/Cell';
import { Col, Row, Button, DropdownButton, Dropdown } from 'react-bootstrap'; 
import { IHistory } from '../../../interfaces';  
import { CloseCallCheckListModel, closeCallCheckListProps } from '../../../model/MechModel';   
import { showCloseCallFollowUpListModal } from '../../../actions/modalActions';  
import { setLoading } from '../../../actions/loadingActions';  
import { getAllFollowUpForms } from '../../../services/closeCallService';  
import { getSWReportDetail } from '../../../services/attributeService';
import { getPageItemNumber } from '../../../utils/generalUtils'; 
import { UserModel } from '../../../model/UserModel';
import { showDelConfirmModal } from '../../../actions/modalActions'; 
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: CloseCallCheckListModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderRow = () => {
        const { history, index, instance, style } = this.props;
        if (!instance) {
            return <div></div>;
        }
        const { props: { UserName, FormType, Date, id, totalCloseCallCount, totalCloseCallOpenCount }  } = instance;
        (instance as any)['formType'] = 'closeCallCheckList';
        return <Row className="report-row" style={style} >
            <Col sm={1}>
                {( UserModel.checkUserAccess('closecall_checklist_view') || UserModel.checkUserAccess('closecall_checklist_edit') || UserModel.checkUserAccess('closecall_checklist_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                    { UserModel.checkUserAccess('closecall_checklist_view') && <MenuItem eventKey="1" onClick={() => history?.push(`/closecall-checklist-detail/${id}/${FormType}`)}
                        className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                    { UserModel.checkUserAccess('closecall_checklist_edit') && <MenuItem eventKey="2"
                        onClick={() => this.onEditAttribute(instance.props)}
                        className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                    { UserModel.checkUserAccess('closecall_checklist_delete') && <MenuItem eventKey="3"
                        onClick={() => this.onDeleteAttribute()}
                        className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton> }
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
            </Col>
            <Cell sm={2} onClick={() => this.onViewAttribute(id, FormType) }>{Date || 'NA'}</Cell>
            <Cell sm={3} onClick={() => this.onViewAttribute(id, FormType) }>{UserName || 'NA'}</Cell>
            <Cell sm={3} onClick={() => this.onViewAttribute(id, FormType)}>{FormType || 'NA'}</Cell>
            <Cell sm={3} rawText={ <Button className="closecall-followup-button" onClick={() => this.getFollowUpList(instance['props'])} > { ( (totalCloseCallCount || 0) - (totalCloseCallOpenCount || 0)) || '0'}/{totalCloseCallCount || '0'}</Button> }> </Cell>
        </Row>;
    }
    onDeleteAttribute() {
        const { instance } = this.props; 
        instance && showDelConfirmModal('delete-confirmation', 'Closecall Checklist Form', instance.props);  
    }
    onEditAttribute(instance: closeCallCheckListProps & { id?: string | undefined; }) { 
        getSWReportDetail(instance.UniqueId, instance.FormType).then(() => {  
            this.props.history?.push(`/closecall-checklist/edit/${instance.id}`); 
        });
    }
    onViewAttribute(id: any, formType: any) { 
        if(!UserModel.checkUserAccess('closecall_checklist_view') )
            return;
        this.props.history?.push(`/closecall-checklist-detail/${id}/${formType}`)
    }
    getFollowUpList(instance: closeCallCheckListProps & { id?: string | undefined; }) { 
        if(!UserModel.checkUserAccess('follow_up_list'))
            return;
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['UniqueId'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance); 
        /* let reportDetail: FollowUpParentDetails = {
            closeCallId: instance['UniqueId'],
            process: '',
            requestFrom: 'closeCallChecklist', 
        };
        dispatch(getFollowUpDetails(reportDetail));
        instance['closeCallId'] = instance['UniqueId'];
        showCloseCallFollowUpListModal(instance); */
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['UniqueId']);
    }

    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) {
    return { 
    };
}
export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
