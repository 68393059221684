import { IHistory } from '../../../interfaces';
import * as React from 'react'; 
import { connect } from 'react-redux';

export interface IButtonProps {
    type?: "submit" | "button" | "reset" | undefined;
    label?: string;
    style?: React.CSSProperties;
    onClick?: () => void; 
    history?: IHistory;
    children?: any;
    redirectTo?: string;
    className?: any;
    id?: any;
}

export class ButtonImpl extends React.Component<IButtonProps, {}> {
    constructor(props: IButtonProps | Readonly<IButtonProps>) {
        super(props);
    }

    handleButtonClick = () => { 
        const { type, onClick } = this.props;
        if (onClick) {
            return onClick();
        }
        if (type === 'reset') {
            this.props.history && this.props.history.push('/home');
        }
        if(this.props.redirectTo==='goBack') { 
            this.props.history?.goBack();
        }
    }

    render() {
        const { type, style, className, id } = this.props;
        let typeText = className==='risk-assessment-toggle'? 'button': (type ? type : 'submit')
        return <button
            type={typeText}
            className={`button ${className}`} id={id}
            style={style}
            onClick={this.handleButtonClick}
        >
            {this.props.children}
        </button>;
    }
}

export function mapStateToProps() {
    return {
    };
} 
export const Button = connect<{}, {}, IButtonProps>(mapStateToProps)(ButtonImpl);