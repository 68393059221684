import * as React from 'react';
import { IHistory } from '../../../interfaces';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'; 
import { Cell } from '../../reusableComponents/ChartComponents/Cell';
import { connect } from 'react-redux';  
import { UserModel } from '../../../model/UserModel';
import { getPageItemNumber, substring } from '../../../utils/generalUtils';  
import { showDelConfirmModal } from '../../../actions/modalActions';
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: any;
    style?: React.CSSProperties; 
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> { 
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderDropdown = () => {
        const { instance } = this.props;
        if (!instance) {
            return;
        }
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">  
                { UserModel.checkUserAccess('user_edit') && <MenuItem eventKey="2"
                    onClick={() => {this.editInstance(instance.props) } }
                    className="item"><i className={`fa fa-pencil }`} aria-hidden="true"></i>{'Edit'}</MenuItem> } 
                { UserModel.checkUserAccess('user_delete') && <MenuItem eventKey="3"
                                onClick={() => this.onDeleteAttribute()}
                                className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
            </DropdownButton>
        );
    }  
    onDeleteAttribute() {
        const { instance } = this.props;  
        instance && showDelConfirmModal('delete-confirmation', 'Location Form', instance.props);  
    }
    editInstance = (instance: any) => { 
        this.props.history?.push(`/location/edit/${instance.key}`);
    }

    renderFormList = () => {
        const {  index, style } = this.props; 
        let instance = this.props.instance.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}> 
                <Col sm={1}>
                    {( UserModel.checkUserAccess('jsa_view') || UserModel.checkUserAccess('jsa_edit') || UserModel.checkUserAccess('jsa_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{getPageItemNumber(index || 0, 1)}</span>
                </Col>
                <Cell sm={3} onClick={() => {this.editInstance(instance) } }>
                    {instance.region || 'NA'}
                </Cell>
                <Cell sm={4} onClick={() => {this.editInstance(instance) } }>
                    {instance.locationName || 'NA'}
                </Cell>  
                <Cell sm={4} onClick={() => {this.editInstance(instance) } }>
                    { (instance.address && substring(instance.address,35) )|| 'NA'}
                </Cell>  
                
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return { };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
 