import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom'; 
import { showPrintPreviewModal } from '../../actions/modalActions';
import { IHistory, IForms } from '../../interfaces'; 
import { geHPFormSummaryData } from '../../services/hpFormService';
import { printDetails } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { HPReportsData } from './HPReportsData';

export interface IHPReportSummaryPageProps { 
    userId?: string; 
    formSummaryData: any; 
    history?: IHistory;
    forms?: IForms;
} 

export interface IHPReportSummaryPageState { 
}

export class HPReportSummaryPageImpl extends React.PureComponent<IHPReportSummaryPageProps, IHPReportSummaryPageState> {
    constructor(props: IHPReportSummaryPageProps | Readonly<IHPReportSummaryPageProps>) {
        super(props); 
    }

    ref: any;

    getChildContext() {
        return { formModel: 'forms.hpReports' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    promise = async () => { 
        await geHPFormSummaryData(); 
        return null;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'HPReports');
    }

    renderPrintPreviewModal = () => { 
        showPrintPreviewModal('hp-report'); 
        return;
    } 
    renderContent = (id?: any) => {
        if(this.props.formSummaryData) { 
            return <div>
                <HPReportsData
                    reportsData={this.props.formSummaryData}
                    getRef={value => this.ref = value}
                    id={id} 
                    history={this.props.history}
                    forms={this.props.forms}
                />  
            </div>;
        } else {
            return <div></div>
        }
    } 
    render() {
        return (
            <BasePage className="reports-page">
                <PrintPreviewModal
                    id="oi-report"
                    ref="printPreview"
                    className="oi-report-data"
                    fileName="HPReports"
                    onClickYes={this.exportCharts}
                    style={{ boxShadow: 'none' }}
                >
                    {this.renderContent(true)}
                </PrintPreviewModal>
                <Async
                    identifier="HPFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent(false)}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: { miscellaneous?: any; formSummary?: any; login?: any; forms?: any }) {
    const { formSummary } = state;  
    const formSummaryData = formSummary.get('hpFormSummary'); 
    return { 
        formSummaryData, forms: state.forms,
    };
}

export const HPReportSummaryPage = withRouter(connect<IHPReportSummaryPageProps, any, any>(mapStateToProps)(HPReportSummaryPageImpl));
