import { IHistory, IForms } from '../../interfaces';
import * as React from 'react';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { showDelConfirmModal } from '../../actions/modalActions'; 
import { IHPModelProps, HPModel } from '../../model/HPModel';
import { UserModel } from '../../model/UserModel';
import { connect } from 'react-redux'; 
import { getPageItemNumber } from '../../utils/generalUtils'; 
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';   
import { setLoading } from '../../actions/loadingActions'; 
import { getAllFollowUpForms } from '../../services/closeCallService';   
import './hpForm.scss';

const MenuItem = Dropdown.Item;
export interface IListItemProps {
    history?: {
        push: IHistory['push']
    };
    index?: number;
    instance?: HPModel;
    style?: React.CSSProperties; 
    currentPage?: number; 
    forms: IForms;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    onDeleteAttribute() {
        const { instance } = this.props;  
        instance &&  showDelConfirmModal('delete-confirmation', 'HP Form', instance.props);  
    }
    onEditAttribute(instance: IHPModelProps & { key?: string | undefined; }) { 
        this.props.history?.push(`/human-performance/edit/${instance.key}`);
    }
    onViewAttribute(instance: HPModel) { 
        if(!UserModel.checkUserAccess('o&i_view') )
            return;
        this.props.history?.push(`/human-performance/view/${instance.props.id}`);
    }
    renderFormList = () => {
        const { instance, index, style } = this.props; 
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}
            >
                <Col sm={1}>
                        { ( UserModel.checkUserAccess('o&i_view') || UserModel.checkUserAccess('o&i_edit') || UserModel.checkUserAccess('o&i_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                            { UserModel.checkUserAccess('o&i_view') && <MenuItem eventKey="1"
                                onClick={() => this.onViewAttribute(instance) }
                                className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                            { UserModel.checkUserAccess('o&i_edit') && <MenuItem eventKey="2"
                                onClick={() => this.onEditAttribute(instance.props)}
                                className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                            { UserModel.checkUserAccess('o&i_delete') && <MenuItem eventKey="3"
                                onClick={() => this.onDeleteAttribute()}
                                className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                        </DropdownButton> }
                        <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
                </Col>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.date || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.username || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.projectName || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance) }>{instance.props.groupName || 'NA'}</Cell> 
                <Col className="clickable-list-item" sm={3} onClick={() => this.onViewAttribute(instance) }> <>{instance.props.answers.totalScore===0?'NILL': (instance.props.answers.totalScore>5? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}</> </Col> 
            </Row >
        );
    }
    getFollowUpList(instance: IHPModelProps & { id?: string | undefined; }) { 
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
         
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance; 
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {  
    return { 
        forms: state.forms,
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
