import { login } from '../actions/loginActions';
import { FORM_SUBMISSION_ERROR,
    DUPLICATE_USER, UPDATE_USER_PASSWORD_FAILED } from '../constants/general';
import { IHistory } from '../interfaces';
import { UserModel } from '../model/UserModel';
import { showAlert } from '../utils/generalUtils';
import { post, putCustom, getCustom, delCustom, putupdateUserPassword } from '../utils/HTTP';
import { getUserInfo, saveUserInfo, removeUserInfo } from './loginService';
import { format } from 'date-fns';
import { OIModel } from '../model/OIModel';
import { JSAModel } from '../model/JSAModel';
import { OfficeSafetyModel } from '../model/OfficeSafetyModel';
import { IncidentNotificationModel } from '../model/IncidentNotificationModel';
import { IncidentReportModel } from '../model/IncidentReportModel';
import { PersonalSafetyModel } from '../model/PersonalSafetyModel'; 
import { setSuccess } from '../actions/loadingActions';  
import { logout } from '../actions/loginActions'; 

export async function addBulkUser(data: any) {  
    try { 
        const det = { data: data, company: UserModel.getCompanyName(), responseName: UserModel.getLoggedUserInstance()['firstName'], responseEmail: UserModel.getLoggedUserInstance()['email']};
        const response = await post('/CreateBulkUsers', det);
      /*   if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'add-user', 'danger');
            return;
        } */
        setSuccess('UserListPage');
        let retRes = response.data.response;
        let message = 'Bulk user(s) uploaded. Total success : ' + retRes.totalSuccess + (retRes.emailIdExist!==0?', Total email exist : ' + retRes.emailIdExist:'')+ (retRes.emailIdNone!==0?', Total email invalid : ' + retRes.emailIdNone:'') + (retRes.userIdExist!==0?', Total user id exist : ' + retRes.userIdExist:'') + (retRes.userIdNone!==0?', Total user id invalid : ' + retRes.userIdNone:'') + (retRes.nameNone!==0?', Total invalid name : ' + retRes.nameNone:'') + (retRes.accessLevelNone!==0?', Total invalid access level : ' + retRes.accessLevelNone:'');
        //showAlert(message, 'delete-user-success', 'success' );
        return message;
    } catch (error) {
        setSuccess('UserListPage');
        showAlert('User upload failed', 'bulk-upload-red-alert', 'danger'); 
       /*  if (error.toString().indexOf('already exists') > -1) {
            showAlert(DUPLICATE_USER, 'add-user', 'danger');
            throw error;
        }
        showAlert(FORM_SUBMISSION_ERROR, 'add-user', 'danger'); */
        throw error;
    }
    

}
export async function addUser(submittedValues: any, history: IHistory) {
    const groupNames = submittedValues.groups ? submittedValues.groups.split(',') : [];
    const data = {
        email: submittedValues.email,
        firstName: submittedValues.firstName,
        password: submittedValues.password,
        groups: (groupNames.length > 0 ? groupNames : []),
        jobDesc: submittedValues.jobDesc,
        jobTitle: submittedValues.jobTitle,
        lastName: submittedValues.lastName,
        middleName: submittedValues.middleName,
        userId: submittedValues.userId,
        accessLevel: submittedValues.accessLevel,
        phoneNumber: submittedValues.phoneNumber || null,
        originalCompany: '',
        dateCreated: format(new Date(), 'yyyy-MM-dd'),
        //isSPIUser: submittedValues.email.toLowerCase().indexOf('spius.net') > -1
    };
    try {
        const response = await post('/CreateUser', data);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'add-user', 'danger');
            return;
        }
        showAlert(response.data.message, 'user-modal', 'success',
            () => { history.push('/home'); });
        await getAllUsers();
        return response;
    } catch (error: any) {
        if (error.toString().indexOf('already exists') > -1) {
            showAlert(DUPLICATE_USER, 'add-user', 'danger');
            throw error;
        }
        showAlert(FORM_SUBMISSION_ERROR, 'add-user', 'danger');
        throw error;
    }
}

export async function updateUser(submittedValues: any, history?: IHistory) {
    const groupNames = submittedValues.groups ? submittedValues.groups.split(',') : [];
    const data = {
        email: submittedValues.email,
        phoneNumber: submittedValues.phoneNumber || null,
        firstName: submittedValues.firstName,
        groups: groupNames.length > 0 ? groupNames : [],
        jobDesc: submittedValues.jobDesc,
        jobTitle: submittedValues.jobTitle,
        lastName: submittedValues.lastName,
        middleName: submittedValues.middleName,
        userId: submittedValues.userUID,
        accessLevel: submittedValues.accessLevel,
        originalCompany: '',
        company: UserModel.getCompanyName(),
        originalEmail: submittedValues.originalEmail || null,
    };
    try {
        const response = await putCustom('/CreateUser', data); 
        if (JSON.stringify(response.data.message).indexOf('already exists') > -1) {
            showAlert(DUPLICATE_USER, 'update-user', 'danger');
            return;
        }
        if (JSON.stringify(response.data).indexOf('error') > 0 ) {
            showAlert(FORM_SUBMISSION_ERROR, 'update-user', 'danger');
            return;
        }

        showAlert(response.data.message, 'update-user-modal', 'success',
            async () => {
                history?.push('/home');await getAllUsers(); });
        return response;
    } catch (error) { 
        showAlert(FORM_SUBMISSION_ERROR, 'update-user', 'danger');
        throw error;
    }
}

export async function updateUserData(userID: string) {
    try {
        const company = UserModel.getCompanyName();
        let { data } = await getCustom(`/CreateUser/${company}/${userID}`);
        const { userInfo } = data;
        const userInstance = marshallUserInstance(userInfo); 
        return new UserModel(userInstance).$save();
    } catch (error) {
        throw error;
    }
}

export async function deleteUser(submittedValues: UserModel, history: IHistory) {
    try { 
        const company = UserModel.getCompanyName();
        let response = await delCustom(`/CreateUser/${submittedValues.props.email}/${company}/${submittedValues.props.userUID}`, submittedValues);
        new UserModel(submittedValues.props).$delete();
        history.push('/users');
        showAlert(response.data.message, 'delete-user-success', 'success',
            () => { history.push('/home'); });
         return response;
    } catch (error) {
        showAlert(UPDATE_USER_PASSWORD_FAILED, 'user-details', 'danger');
        return error;
    }
}

export async function updateUserPassword(id: string, history?: IHistory) {
    try {
        await putupdateUserPassword(`/UpdateUserPassword/${id}`);
        history?.push('/users');
        showAlert('Password Updated Successfully with the Default Password and emailed to User', 
        'password-update-user-success', 'success');
            // () => { history.push('/home'); });
    } catch (error) {
        showAlert(UPDATE_USER_PASSWORD_FAILED, 'user-details', 'danger');
        return ;
    }
}
export async function getUser(this: any, userUID: string) {
    
    const company = UserModel.getCompanyName();
    if(company===undefined || userUID===undefined || company==='undefined' || userUID==='undefined' || !company || !userUID) { 
        await logout(); 
        await removeUserInfo();  
        //UserModel.deleteAll(); 
        this.props.history.push('/');
        return;
    }
    let { data } = await getCustom(`/CreateUser/${company}/${userUID}`); 
    const { userInfo } = data; 
    const userInstance = marshallUserInstance(userInfo); 
    const checkLocalStorage = getUserInfo();
    login(userInfo);
    if (!checkLocalStorage) {
        saveUserInfo(userInfo.firstName, userUID, userInfo.accessLevel, userInfo.company);
    }
    return new UserModel(userInstance).$save();
}

export function marshallUserInstanceForRequest(instance: { id: any; userId: any; projects: any; groups: any; }) {
    instance.id = instance.userId;
    instance.projects = instance.projects.split(',');
    instance.groups = instance.groups.split(',');
    return instance;
}

export async function getAllUsers(allowedType: string = '') { 
    try {   
        const company = UserModel.getCompanyName();
        const { data: { userInfo } } = await getCustom(`/GetAllUsers/${company}/${UserModel.getAccessLevel() || 'L2'}`);   
        UserModel.deleteAll();
        (userInfo || []).forEach((userData: { userUID: any; }) => { 
            if (allowedType==='includeSelf' || (userData.userUID !== UserModel.getUserUID()) ) {
                const userInstance = marshallUserInstance(userData); 
                new UserModel(userInstance).$save(); 
            }
        });
    } catch (error) {
        throw error;
    }
}

export function marshallUserInstance(instance: any) {
    instance.id = instance.userId;
    return instance;
}

export async function saveUserData(userData: { userInfo: any; elmsToken: any }) {
    const { userInfo, elmsToken } = userData; 
    const userInstance = await marshallUserInstance(userInfo);
    const { userId, email } = userInfo;
    await getUserInfo();
    await login(userInfo);
    //if (!checkLocalStorage) {
        await saveUserInfo(userInfo.firstName, userId, userInfo.accessLevel, userInfo.userUID, userInfo.company, userInfo.companyLogo,userInfo.userAccess, email, userInfo.elmsId, userInfo.elmsFlag, elmsToken  );
    //}
    return new UserModel(userInstance).$save();
}

export function getProjectsOfUser(userId: string, userInstanceParam?: any) {
    const userInstance = UserModel.get(userId) || UserModel.getUserByUID(userId);  
    const projects: { value: any; label: any; }[] = [];
    if (!userInstance) { 
        let userSessionProject = UserModel.getLoggedUserInstance();  
        (userSessionProject.projects || []).forEach((project: any) => {
            projects.push({ value: project, label: project });
        }); 
        return projects;
    } 
    userInstance.props.projects.forEach((project: any) => {
        projects.push({ value: project, label: project });
    }); 
    return projects;
}

export function getGroupsOfUser(userId: string, projectName?: string ) {
    const userInstance = UserModel.get(userId) || UserModel.getUserByUID(userId);
    const groups: { value: any; label: any; }[] = [];
    let groupsByProject = [];  
    if (!userInstance) { 
        let userSessionGroup = UserModel.getLoggedUserInstance();  
        if(projectName) {
            if( projectName.indexOf(',') !== -1 ) {
                let projects = projectName.split(',');
                (projects).forEach((project: any) => {
                    groupsByProject = groupsByProject.concat(userSessionGroup.groupsByProject[project]); 
                }); 
            } else {
                groupsByProject = userSessionGroup.groupsByProject && userSessionGroup.groupsByProject[projectName];
            }
        } else {
            groupsByProject = userSessionGroup.groups;
        }  
        (groupsByProject ? groupsByProject : []).forEach((group: any) => {
            groups.push({ value: group, label: group });
        }); 
        return groups;
    }
    if(projectName) {
        if( projectName.indexOf(',') !== -1 ) {
            let projects = projectName.split(',');
            (projects).forEach((project: any) => {
                groupsByProject = groupsByProject.concat(userInstance.props.groupsByProject[project]);  
            });  
        } else {
            groupsByProject = userInstance.props.groupsByProject && userInstance.props.groupsByProject[projectName];
        }
    } else {
        groupsByProject = userInstance.props.groups;
    }  
    (groupsByProject ? groupsByProject : []).forEach((group: any) => {
        groups.push({ value: group, label: group });
    });
    return groups;
}

export async function getUsersByGroup(group: string, allowedType: string = '', errorId: string = 'closecall-followup-failed') {
    const company = UserModel.getCompanyName();
    const accessLevel = UserModel.getAccessLevel();
    try {
        const { data: { userInfo } } = await getCustom(`/GetAllUsersByGroup/${company}/${group || 'all'}`); 
        let usersList: { value: any; label: string; }[] = []; 
        (userInfo || []).forEach((userData: { userUID: any; accessLevel: string; firstName: string; lastName: string; }) => { 
            if ( (allowedType==='includeSelf' || (userData.userUID !== UserModel.getUserUID()) ) && ( (accessLevel!=='L1' && userData.accessLevel!=='L1') || accessLevel==='L1' ) ) {
                usersList.push({ value: userData.userUID, label: userData.firstName + ' ' + userData.lastName });
            }
        }); 
        return usersList; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, errorId, 'danger');
        throw error;
    } 
}

export async function getFormsOfAllUser(userUID: string) {
    const oldInstance = UserModel.getUserByUID(userUID);
    let formsSubmitted: any = [];
    try {
        const company = UserModel.getCompanyName();
        const { data } = await getCustom(`/GetUserForms/${company}/${userUID}`);
        (data.data ? data.data.ListofForms : []).forEach((formId: string) => {
            formsSubmitted && formsSubmitted.push(getFormData(formId));
        }); 
        oldInstance.props.formsSubmitted = formsSubmitted;
        new UserModel(oldInstance.props).$update(userUID);
    } catch (error) {
        throw JSON.stringify(error);
    }
}

export function getFormData(formId: string) {
    const oiFormIds = OIModel.getAllFormIds();
    const jsaFormIds = JSAModel.getAllFormIds();
    const officeSafetyFormIds = OfficeSafetyModel.getAllFormIds();
    const incidentNotificationFormIds = IncidentNotificationModel.getAllFormIds();
    const incidentReportFormIds = IncidentReportModel.getAllFormIds();
    const personalSafetyFormIds = PersonalSafetyModel.getAllFormIds();

    if (oiFormIds.indexOf(formId) > -1) {
        const oiFormInstance = OIModel.get(formId);
        return {
            formName: 'O&I Form',
            date: oiFormInstance.props.date,
            projectName: oiFormInstance.props.projectName,
            formURL: `/oi-reports/${formId}`
        };
    } else if (jsaFormIds.indexOf(formId) > -1) {
        const jsaInstance = JSAModel.get(formId);
        return {
            formName: 'JSA Form',
            date: jsaInstance.props.date,
            projectName: jsaInstance.props.projectName,
            formURL: `/jsa-reports/${formId}`
        };
    } else if (incidentNotificationFormIds.indexOf(formId) > -1) {
        const incidentNotificationInstance = IncidentNotificationModel.get(formId);
        return {
            formName: 'Incident Notification Form',
            date: incidentNotificationInstance.props.date,
            projectName: incidentNotificationInstance.props.projectName,
            formURL: `/incident-reports/${formId}`
        };
    } else if (incidentReportFormIds.indexOf(formId) > -1) {
        const incidentReportInstance = IncidentReportModel.get(formId);
        return {
            formName: 'Incident Investigative Form',
            date: incidentReportInstance.props.date,
            projectName: incidentReportInstance.props.projectName,
            formURL: `/incident-investigative-reports/${formId}`
        };
    } else if (officeSafetyFormIds.indexOf(formId) > -1) {
        const officeSafetyInstance = OfficeSafetyModel.get(formId);
        return {
            formName: 'Office Safety Form',
            date: officeSafetyInstance.props.date,
            projectName: officeSafetyInstance.props.projectName,
            formURL: `/office-safety-reports/${formId}`
        };
    } else if (personalSafetyFormIds.indexOf(formId) > -1) {
        const personalSafetyInstance = PersonalSafetyModel.get(formId);
        return {
            formName: 'Personal Safety Form',
            date: personalSafetyInstance.props.date,
            projectName: personalSafetyInstance.props.projectName,
            formURL: `/personal-safety/${formId}`
        };
    }
}
