import { setSuccess } from '../actions/loadingActions';
import { LOCAL_STORAGE_KEY, LOGIN_FAILED } from '../constants/general';
import { IHistory, ISelectOptions } from '../interfaces';
import { UserModel } from '../model/UserModel';
import { showAlert } from '../utils/generalUtils';
import { postCustom, getCustom } from '../utils/HTTP';
import { saveUserData } from './userService';
import { format } from 'date-fns';

export interface IUserInfo {
    firstName: string;
    userId: number;
}

export async function loginDataFetch(userId: string, password: string, company: string, history: IHistory,
    pathName: string) {
    try {
        const response = await postCustom('/AuthenticateUser', { userId, password, company });
        setSuccess('login');
        const userInstance = await saveUserData(response.data);
        if (userInstance instanceof UserModel) {
            //history.push(pathName);
            window.location.reload();
        }
    } catch (error) {
        setSuccess('login');
        showAlert(LOGIN_FAILED, 'login', 'danger');
        throw error;
    }
}

export async function getCompaniesByEmail(email?: string) {
    try {
        let companies: ISelectOptions[] = [];
        const response = await getCustom(`/GetCompaniesByEmail/${email}`);
        response.data.data.forEach((companyName: any) => {
            companies.push({ label: companyName, value: companyName });
        });  
        return companies;
    } catch (error) {
        throw error;
    }
}

export function saveUserInfo(firstName: string, userId: string, accessLevel: string,
    userUID: string, company?: string, companyLogo?: string, userAccess?: undefined, email?: string, elmsId?: string, elmsFlag?: any, elmsToken?: any, ) {  
    const isAdmin = accessLevel === 'L1';
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        firstName, company, userId,
        isAdmin, isLoggedIn: true, userUID, userAccess, accessLevel, companyLogo, language: 'eng', email, elmsId, elmsFlag, elmsToken
    })); 
}

export function removeUserInfo() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
}

export function getUserInfo() {
    let parse = localStorage.getItem(LOCAL_STORAGE_KEY);
    return parse ? JSON.parse(parse) : [];
}
export function setAdditionalStorage(key: string | number, value: any) { 
    let currSess = getUserInfo();
    currSess[key] = value; 
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currSess) );  
} 
export async function submitSignUpForm(submittedValues: any, history: IHistory) {
    const requestBody = {
        email: submittedValues.email,
        firstName: submittedValues.firstName,
        password: submittedValues.password,
        jobDesc: submittedValues.jobDesc,
        jobTitle: submittedValues.jobTitle,
        lastName: submittedValues.lastName,
        middleName: submittedValues.middleName,
        userId: submittedValues.userId,
        phoneNumber: submittedValues.phoneNumber || null,
        dateCreated: format(new Date(), 'yyyy-MM-dd'),
        originalCompany: submittedValues.originalCompany,
        accessLevel: 'L1',
        projects: [],
        // groups: ['a84a02c9-28f5-11e8-9ef5-0a84f65e4a72'],
        groups: ['d0d39600-2868-11e8-9ef5-0a84f65e4a72'],
        company: 'Demo'
    };
    try {
        const response = await postCustom('/CreateUser', requestBody);
        setSuccess('sing-up');
        await loginDataFetch(requestBody.userId, requestBody.password, requestBody.company, history, '/home');
        return response;

    } catch (error: any) {
        setSuccess('sing-up');
        const errorMsg = error.toString().split(':')[1];
        showAlert(errorMsg.toString(), 'sign-up', 'danger');
        throw error;
    }
}
export async function submitForgotPassword(data: any, history: IHistory) {  
    try {  
        const response = await postCustom('/ForgetPassword', data); 
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ isLoggedIn: false, userUID: response.data.userUID, company: data.company }));
        showAlert('Password updated. Temporary password sent to your registered mail.', 'login-form-modal', 'success', () => { history.push('/forgot-password'); }); 
        return true;
    } catch (error) {
        setSuccess('forgot-password');
        showAlert(LOGIN_FAILED, 'forgot-password', 'danger');
        throw error;
    }
}
export async function resetPassword(data: any, history: IHistory) {
    try {
        await postCustom('UpdatePassword', data); 
        removeUserInfo(); 
        setSuccess('forgot-password');
        showAlert('Password updated.', 'forgot-password-form-modal', 'success', () => { history.push('/'); });
    } catch (error) {
        setSuccess('forgot-password');
        showAlert('Incorrect old Password', 'forgot-password-error', 'danger');
    }

}

export async function loginUsingAAD(user: any, company: string) {
    user['company'] = company;
    const response = await postCustom('/AADLogin', { user }); 
    const userInstance = await saveUserData(response.data.loggedData); 
    if (userInstance instanceof UserModel) { 
        window.location.reload();
    }
}