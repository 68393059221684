import { fromJS } from 'immutable';
import { SAVE_NOTIFICATIONS, SAVE_ACTIVITIES_MENU_ITEMS, SAVE_OFFICE_SAFETY_SUMMARY } from '../constants/actions'; 
import {
    DELETE_CONCERN_TYPE,
    SAVE_CONCERN_TYPES,
    SAVE_INCIDENT_NOTIFICATION_SUMMARY,
    SAVE_OI_FORM_SUMMARY,
    SAVE_INCIDENT_INVESTIGATIVE_SUMMARY, SAVE_PANDEMIC_SUMMARY, SAVE_PANDEMIC_SHORT_SUMMARY, SAVE_RISK_ASSESSMENT_SUMMARY, SAVE_HP_FORM_SUMMARY
} from '../constants/actions';

export function summaryReducer(state: any = fromJS({}), action: { type: any; summaryData: any; concern: string | number; concernTypes: any; activityOptions: any; notifications: any; totalFollowupOpen: any; }) {
    const concernTypes = state.get('concernTypes');
    switch (action.type) {
        case SAVE_OI_FORM_SUMMARY:
            return state.set('oiFormSummary', action.summaryData);
        case SAVE_OFFICE_SAFETY_SUMMARY:
            return state.set('officeSafetyFormSummary', action.summaryData);
        case SAVE_INCIDENT_INVESTIGATIVE_SUMMARY:
            return state.set('incidentInvestigativeSummary', action.summaryData);
        case SAVE_INCIDENT_NOTIFICATION_SUMMARY:
            return state.set('incidentFormSummary', action.summaryData);
        case DELETE_CONCERN_TYPE:
            delete concernTypes[action.concern];
            return state.set('concernTypes', concernTypes);
        case SAVE_CONCERN_TYPES:
            return state.set('concernTypes', action.concernTypes);
        case SAVE_ACTIVITIES_MENU_ITEMS:
            return state.set('activityMenuItems', action.activityOptions);
        case SAVE_NOTIFICATIONS:
            return state.set('formNotifications', action.notifications).set('totalFollowupOpen', action.totalFollowupOpen);
        case SAVE_PANDEMIC_SUMMARY:
            return state.set('pandemicSummary', action.summaryData);
        case SAVE_PANDEMIC_SHORT_SUMMARY: 
            return state.set('pandemicShortSummary', action.summaryData);
        case SAVE_RISK_ASSESSMENT_SUMMARY:
            return state.set('riskAssessmentSummary', action.summaryData);
        case SAVE_HP_FORM_SUMMARY:
            return state.set('hpFormSummary', action.summaryData);
        default:
            return state;
    }
}
