import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SafetyWalkthroughReportChartData } from '../../model/SafetyWalkthroughModel';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { saveFilterData } from '../../actions/miscellaneousActions';
import { IChartValue, IForms } from '../../interfaces';
import { applyMonthlyFilterSafetyWalkthrough, applyYearlyFilterForSafetyWalkthrough, getSafetyWalkthroughReportData } from '../../services/attributeService';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { Card } from '../reusableComponents/Card';
import { BarChart } from '../reusableComponents/ChartComponents/BarChart';
import { LineChart } from '../reusableComponents/ChartComponents/LineChart';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';  
/* import { printLogos } from '../../utils/generalUtils';  */
import { PrintLogos } from '../reusableComponents/PrintLogos';  

export interface IOfficeSafetyReportsDataProps {
    reportsData: SafetyWalkthroughReportChartData;
    getRef?: (value: any) => void;
    getFilterBy?: (value: any) => void;
    setFilterBy?: (value: any) => void;
    id: string;
    isPrint: boolean;
    filterBy: string;
    barChartData?: {
        positiveObservations: number;
        negativeObservations: number;
    };
    isAnimationActive?: boolean;
    forms?: IForms;
}
export interface IOfficeSafetyReportsDataState {
    view: boolean;
    showYearlyFilter: boolean;
    showMonthlyFilter: boolean;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    totalFormsSubmitted: number;
    isLoading: boolean;
    filterBy: string;
}

export class SafetyWalkthroughReportData extends React.Component<IOfficeSafetyReportsDataProps,
    IOfficeSafetyReportsDataState> {
    constructor(props: IOfficeSafetyReportsDataProps) {
        super(props);
        this.state = {
            view: false,
            data: [],
            legends: [],
            totalFormsSubmitted: 0,
            pieChartData: {},
            showMonthlyFilter: false, showYearlyFilter: false,
            filterBy: 'monthly',
            isLoading: false,
        };
    }

    ref: any; 

    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return (
            <YearlyFilter onApply={(year) => {
                setLoading('ReportsPage');
                saveFilterData('yearly', year);
                applyYearlyFilterForSafetyWalkthrough(year);
            }} />
        );
    }

    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        return <MonthlyFilter onApply={(month, year) => {
            setLoading('ReportsPage');
            saveFilterData('monthly', year, month);
            applyMonthlyFilterSafetyWalkthrough(year, month);
        }} />;
    }

    handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({
                showYearlyFilter: true,
                showMonthlyFilter: false,
                filterBy: 'yearly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'monthly') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: true,
                filterBy: 'monthly'
            });
            this.props.getFilterBy && this.props.getFilterBy(value);
        } else if (value === 'cumulative') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: false,
                filterBy: 'monthly'
            });
            //this.props.getFilterBy('monthly'); 
           // getSafetyWalkthroughReportData('yearly', '0', '0');
            this.props.getFilterBy && this.props.getFilterBy(value);
        }
        //getSafetyWalkthroughReportData('yearly', '2019', '0');
    }
    

    renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            <Alert style={{ height: '40px' }} id="oi-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' }, { value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        model=".filterType"
                        defaultValue="cumulative"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    } 

    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getSafetyWalkthroughReportData(this.props.forms); 
        setSuccess('ReportsPage');
    }

    renderCountLineChart = () => {
        const { reportCountData } = this.props.reportsData; 
        // const data = getOfficeSafetyLineChartData(this.props.filterBy || this.state.filterBy, officeData);
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Total Count of <span style={{ color: '#82CAFA' }}>
                            Manager </span> / <span style={{ color: '#FFB6C1' }}>Supervisor
                         </span> Forms
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={reportCountData} legends={['ManagerCheckList', 'SupervisorCheckList']}
                                strokeColors={['B0D9FF', 'FFBECE']} isAnimationActive={false}
                                dataKey={['Manager', 'Supervisor']} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
    renderSumLineChart = () => {
        const { reportSumData } = this.props.reportsData;
        // const data = getOfficeSafetyLineChartData(this.props.filterBy || this.state.filterBy, officeData);
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto', padding: '20px' }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Average Score - <span style={{ color: '#82CAFA' }}>
                            Manager </span> / <span style={{ color: '#FFB6C1' }}>Supervisor
                            </span>
                    </h4>
                    <Row className="show-grid line-chart ">
                        <Col sm={12} style={{ height: '218px' }}>
                            <LineChart data={reportSumData} legends={['ManagerCheckList', 'SupervisorCheckList']}
                                strokeColors={['B0D9FF', 'FFBECE']} isAnimationActive={false}
                                dataKey={['Manager', 'Supervisor']} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    renderAverageLineChart = () => {
        const { managerCheckListPercentage, supervisorCheckListPercentage } = this.props.reportsData;
        const data = [{ name: 'Manager', value: managerCheckListPercentage },
        { name: 'Supervisor', value: supervisorCheckListPercentage }];
        return (
            <div style={{
                height: '100%', width: '100%',
                margin: '20px auto', paddingTop: '20px', paddingLeft: '20%', paddingRight: '20%'
            }}>
                <Card width="100%" className="chart">
                    <h4 style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Average Monthly / Yearly Score
                      </h4>
                    <Row className="show-grid">
                        <Col sm={12} className="bar-chart" style={{ height: '253px' }}>
                            <BarChart data={data}
                                barColors={['B0D9FF', 'FFBECE']} />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    renderContent = (isLoading: boolean) => {
        return <div id={this.props.id} className="reports-data" >
            {/* {printLogos()} */} 
            <PrintLogos/>
            <Title key="title" text="Safety Walkthrough Report Summary"></Title>
            <div key="data" >
                <div id="oi-reports" className="oi-report-summary">
                    {isLoading ? <SubmissionLoader /> : ''}
                    {/* {this.renderFilters()} */} 
                    <CommonFilter onApply={this.applyCommonFilter} forms={this.props.forms}/>
                    {this.renderCountLineChart()}
                    {this.renderSumLineChart()}
                    {this.renderAverageLineChart()}
                </div>
            </div>
        </div>;
    }

    render() {
        return [
            <Async
                key="content"
                identifier="ReportsPage"
                promise={() => setSuccess('ReportsPage')}
                loader={this.renderContent(true)}
                content={this.renderContent(false)}
                error={<ErrorPage />}
            />
        ];
    }
}
