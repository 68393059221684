import { format } from 'date-fns'; 
import { saveListCount } from '../actions/miscellaneousActions';
import { setSuccess } from '../actions/loadingActions';
import { saveOfficeSafetyFormSummary } from '../actions/summaryActions';
import { getOfficeSafetyComment } from '../actions/summaryActions';
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { IAnswer, IForms, IHistory } from '../interfaces';
import { OfficeSafetyModel } from '../model/OfficeSafetyModel';
import { UserModel } from '../model/UserModel';
import { Answers } from '../utils/Answers';
import { showAlert } from '../utils/generalUtils';
import { post, putCustom } from '../utils/HTTP';  

export async function submitOfficeSafetyForm(answers: IAnswer[], forms: IForms,
    history: IHistory, userId: string, groupName: string, projectName: string, date: string, editId?: string) {
    const submittedValues: any = {
        groupName,
        formId: 'oiOfficeForm',
        projectName,
        userId,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        answers,
        associatedFormId: '',
        followUp: 0, 
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: forms.officeSafetyForm.officeSafetyImages || [],
        videoData: forms.officeSafetyForm.officeSafetyVideos || []
    }; 
    try { 
        submittedValues.deletedImages = forms.officeSafetyForm.deletedImages || [];
        submittedValues.deletedVideos = forms.officeSafetyForm.deletedVideos || [];
        submittedValues.editId = editId; 
        const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues); 
        if (JSON.stringify(response.data).toLowerCase().indexOf('error') > 0) {
            return;
        }
        showAlert(response.data.message, 'office-safety-modal', 'success', async () => {
            history.push('/home'); 
            await getAllOfficeSafetyForm();
        }); 
        return response; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'office-safety-form', 'danger');
        throw error;
    }
}

export async function getAllOfficeSafetyForm(urlData?: any, forms?: IForms) { 
    try {
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        let data = { ...forms?.commonFilterForm, formType: 'oiOfficeForm', pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken);  
        if (response.data.status === 'valid') {
            OfficeSafetyModel.deleteAll();
            response.data.data.forEach((data: any) => {
                const officeSafetyInstance = marshallOfficeSafetyInstances(data);
                new OfficeSafetyModel(officeSafetyInstance).$save();
            });
            saveListCount(response.data.TotalPages);
        }
    } catch (error) {
        throw error;
    }
}

export function marshallOfficeSafetyInstances(instance: any ) {
    instance.id = instance.key;
    instance.totalClosedFollowUp = instance.totalFollowUp - instance.totalOpenFollowUp; 
    let answerData = new Answers(instance.answers); 
    const officeFormInstance = {
        dateOfIncident: format(new Date(instance.date), 'MM/dd/yyyy'),
        dateReported: format(new Date(answerData.get(1)), 'MM/dd/yyyy'),
        timeOfIncident: answerData.get(2),
        location: answerData.get(3),
        locationName: answerData.get(4),
        eyesOnPath: answerData.get(5),
        ascendingDescending: answerData.get(6),
        onTheMoveComments: answerData.get(7),
        toolOrEquipment: answerData.get(8),
        conditionUse: answerData.get(9),
        toolSelectionComments: answerData.get(10),
        liftingPoweringTwisting: answerData.get(11),
        pushingPulling: answerData.get(12),
        manualHandlingComments: answerData.get(13),
        ergonomicsWorkstation: answerData.get(14),
        ergonomicsComments: answerData.get(15),
        lineOfFire: answerData.get(16),
        pinchPoint: answerData.get(17),
        eyesOnTask: answerData.get(18),
        bodyPositionComments: answerData.get(19),
        foodSafety: answerData.get(20),
        cleanliness: answerData.get(21),
        healthHygieneComments: answerData.get(22),
        tailgating: answerData.get(23),
        personalProperty: answerData.get(24),
        barricading: answerData.get(25),
        securityComments: answerData.get(26),
        hskAccessEgress: answerData.get(27),
        hskStorageHousekeeping: answerData.get(28),
        housekeepingComments: answerData.get(29),
        doubleSidePrinting: answerData.get(30),
        recycling: answerData.get(31),
        computerShutdown: answerData.get(32),
        envRecyclingComments: answerData.get(33),

    };
    Object.assign(instance, officeFormInstance);
    delete instance.answers;
    return instance;
}
 
export async function getOfficeSafetyFormSummary(forms?: IForms ) { 
    try { 
        const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '', month: forms?.commonFilterForm.month || '', }; 
        const response = await post('/GetOfficeOiReportSummary', data); 
        saveOfficeSafetyFormSummary(marshallReportSummaryIdentifiers(response.data));
    } catch (error) {
        throw error;
    }
}
export interface OfficeSafetyCommentModel {
    date?: string;
    comments?: string;
}

export async function GetOfficeComments(startDate: any, endDate: any, location?: string, category?: string, RiskFact?: string, pageNumber: number = 1) {
    const company = UserModel.getCompanyName();
    let requestBody = {
        StartDate: startDate,
        EndDate: endDate,
        Company: company, 
        Location: location || '',
        Category: category || '', RiskFact: RiskFact || '', pageNumber: pageNumber
    };
    try {
        const response = await putCustom(`/GetOfficeComments`, requestBody);
        let CommentList = Array<OfficeSafetyCommentModel>();
        if (response.data && response.data.data) {
            response.data.data.map((item: { FormType: any; Section: any; Date: number | Date; Comment: any; Location: any; SubmittedBy: any; }) => {
                let OfficeComment = {
                    formType: item.FormType,
                    section: item.Section,
                    date: format(new Date(item.Date), 'MM/dd/yyyy'),
                    comment: item.Comment,
                    location: item.Location,
                    name: item.SubmittedBy
                };
                CommentList.push(OfficeComment);
            });
            getOfficeSafetyComment(CommentList); 
        }
        saveListCount(response.data.TotalPages);
        return CommentList;
    } catch (error) {
        throw error;
    }
}

function marshallReportSummaryIdentifiers(data: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }) {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            let newKey = key.replace('\\s+', '');
            data[newKey] = data[key];
        }
    }
    return data;
}

export async function applyMonthlyFilterOfficeSafety(year: string, month: string) {
    if (!month) {
        setSuccess('ReportsPage');
        showAlert('Please select month.', 'oi-report-summary', 'danger');
        return;
    } else if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'oi-report-summary', 'danger');
        return;
    }
    try {
        //await getOfficeSafetyFormSummary('monthly', year, month);
        setSuccess('ReportsPage');
    } catch (error) {
        throw error;
    }
}

export async function applyYearlyFilterForOfficeSafety(year: string) {
    if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'oi-report-summary', 'danger');
        return;
    }
    try {
        //await getOfficeSafetyFormSummary('yearly', year, 'na');
        setSuccess('ReportsPage');
    } catch (error) {
        throw error;
    }
}

export function getOfficeSafetyLineChartData(filterType: any, officeData: any) {
    let data:Array<any> = [];
    if (filterType === 'yearly') {
        data = [
            { name: 'Jan', Counts: officeData['01'] }, { name: 'Feb', Counts: officeData['02'] },
            { name: 'Mar', Counts: officeData['03'] }, { name: 'Apr', Counts: officeData['04'] },
            { name: 'May', Counts: officeData['05'] }, { name: 'Jun', Counts: officeData['06'] },
            { name: 'July', Counts: officeData['07'] }, { name: 'Aug', Counts: officeData['08'] },
            { name: 'Sep', Counts: officeData['09'] }, { name: 'Oct', Counts: officeData['10'] },
            { name: 'Nov', Counts: officeData['11'] }, { name: 'Dec', Counts: officeData['12'] },

        ];
    } else if (filterType === 'monthly') {
        for (const key in officeData) {
            data.push({ name: key, Counts: officeData[key] });
        }
    }
    return data;
}
