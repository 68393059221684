export class Answers {
    answers;
    constructor(props: any) {
        this.answers = props;
    }

    get noOfAnswers() {
        return this.answers.length || 0;
    }

    get = (quesNum: number) => {
        if (this.noOfAnswers < quesNum) {
            return;
        }
        return this.answers.find(((answerData: { quesNum: number; }) => answerData.quesNum === quesNum) || {}).answer;
    }
}
