import {Async} from '../reusableComponents/Async';
import * as React from 'react';
import {Title} from '../reusableComponents/Title';
import {BasePage} from '../reusableComponents/BasePage';
import '../HomePage/homePage.scss';
import {UserModel} from '../../model/UserModel';
import {Loader} from '../reusableComponents/Loader';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {Container as Grid, Row, Col} from 'react-bootstrap'; 
import officeSafetyIcon from '../../images/officeSafetyLogo.png';
import pspReportsIcon from '../../images/pspIcon.png';
import jsaReportsIcon from '../../images/jsaIcon.png';

export interface ITexas811FormsProps {
    history: IHistory;
}

export class Texas811FormsImpl extends React.PureComponent<ITexas811FormsProps, {}> {
    constructor(props: ITexas811FormsProps | Readonly<ITexas811FormsProps>) {
        super(props);
    }

    promise = async () => {
        return null;
    }

    renderContent = () => {
        let smGrid = UserModel.checkUserAccess('jsa_texas_add')?4:6
        return (
            <Grid style={{width: '100%'}} className="homepage texas-home-page">
                <Title /* titleColor="white" */ text="Utility Forms"/>
                <div className="links-container">
                    <Row className="show-grid">
                        { UserModel.checkUserAccess('jsa_texas_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-left1 menu"
                                title="Click to fill out Risk Assessment Form"
                                onClick={() => this.props.history?.push('/jsa-reports/create')} >
                                <img className="icon icon-5" src={jsaReportsIcon} alt="SafeConnect" />
                                <span className="text">Job Safety<br></br>Analysis</span>
                            </Col> }
                        {/* <Col sm={3} xs={12}
                             className="top-row-left"
                             title="Click to fill out Pre Excavation Checklist(v1)"
                             onClick={() => this.props.history.push('/preExcavationChecklist/create')}
                        >
                            <img className="icon icon-1" src={oiReportsIcon} alt="SafeConnect" />
                            <span className="text">Pre Excavation <br></br>Checklist(v1)</span>
                        </Col>  */}
                        <Col sm={smGrid} xs={12}
                             className="top-row-left"
                             title="Click to fill out Pre Excavation Checklist"
                             onClick={() => this.props.history.push('/preExcavationChecklist-v2/create')}
                        >
                            <img className="icon" src={pspReportsIcon} alt="SafeConnect" />
                            <span className="text">Pre Excavation<br></br>Checklist</span>
                        </Col>
                        <Col sm={smGrid} xs={12}
                             className="top-row-right"
                             title="Click to fill out Utility Damage Report"
                             onClick={() => this.props.history.push('/utilityDamageReport/create')}
                        >
                            <img className="icon" src={officeSafetyIcon} alt="SafeConnect" />
                            <span className="text">Utility Damage<br></br>Report</span>
                        </Col>
                       
                    </Row>
                </div>
            </Grid>
        );
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="texas811"
                    promise={this.promise}
                    loader={<Loader/>}
                    error={<ErrorPage/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);

    return {
        userInstance,
        userId
    };
}

export const Texas811Forms = withRouter(connect<{}, {}, ITexas811FormsProps>(mapStateToProps)(Texas811FormsImpl));
