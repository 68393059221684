import * as React from 'react';
import {connect} from 'react-redux'; 
import {IHistory} from '../../interfaces'; 
import {UserModel} from '../../model/UserModel'; 
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {DetailsPageWrapper} from '../reusableComponents/DetailsPageWrapper';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Gallery} from '../reusableComponents/ImageComponents/Gallery'; 
import {Loader} from '../reusableComponents/Loader';
import {Title} from '../reusableComponents/Title'; 
import {Button} from '../reusableComponents/FormComponents/Button'; 
import {getFormData} from "../../services/texas811Service";
import {PreExcavationChecklistModel} from "../../model/PreExcavationChecklistModel"; 
import Select from "react-select";
import {Col, Row} from "react-bootstrap"; 
import {format} from "date-fns";
import * as displayText from "./PreExcavationCheckListForm/language.json"; 

export interface IPreExcavationDetailsPageProps {
    id: string;
    instance: PreExcavationChecklistModel;
    history: IHistory;
}

export interface IPreExcavationDetailsPageState {
    displayText: any;
    language: string;
}

export class PreExcavationDetailsPageImpl extends React.PureComponent<IPreExcavationDetailsPageProps, IPreExcavationDetailsPageState> {
    constructor(props: IPreExcavationDetailsPageProps | Readonly<IPreExcavationDetailsPageProps>) {
        super(props);
        this.state = {
            displayText: !props.instance ? displayText.eng : props.instance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.instance ? 'eng' : props.instance.props.language === 'spa' ? 'spa' : 'eng'
        }
    }

    promise = async () => {
        if (!this.props.instance) {
            await getFormData('preExcavationChecklist', this.props.id);
            setTimeout(() => {
                const {instance}: any = this.props;
                this.setState({
                    displayText: !instance ? displayText.eng : instance.props.language === 'spa' ? displayText.spa : displayText.eng,
                    language: !instance ? 'eng' : instance.props.language === 'spa' ? 'spa' : 'eng'
                });
            }, 100);
        }
    }

    getObserverName = (userID: string) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    }

    renderContent = () => {
        const {instance} = this.props;
        if (!instance) {
            return <Loader/>;
        }

        const {displayText, language} = this.state;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }
        return <div>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value = {{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <Row className={'show-grid'}>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.cfName}&nbsp;&nbsp;&nbsp;
                            <span>{answer.crewForeman}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.oneCallTicketNo} &nbsp;&nbsp;&nbsp;
                            <span>{answer.oneCallTicketNo}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.contactNo} &nbsp;&nbsp;&nbsp;
                            <span>{answer.contactNo}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={6} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label key-data">{displayText.date} &nbsp;&nbsp;&nbsp;
                            <span>{answer.excavationDate ? format(new Date(answer.excavationDate), 'MM/dd/yyyy') : ''}</span>
                        </label>
                    </Col>
                </Col>
            </Row>
            <Row className={'show-grid top-info'}>
                <p>{displayText.boxedInfoPara1}</p>
                <p className={'centered-text'}>{displayText.boxedInfoPara2}</p>
            </Row>
            <Row className={'show-grid'}>
                <p className="key-data">{displayText.quest1OneCallTicketCovers}</p>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label"
                               htmlFor="isVerifiedWorkScope">{displayText.sowVerified} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isVerifiedWorkScope && answer.isVerifiedWorkScope === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <Col sm={12} xs={12}>
                    <Col sm={12} className="input">
                        <label className="checkbox-label"
                               htmlFor="isVerifiedWorkScope">{displayText.wtbVerified} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isVerifiedWorkBeginDay && answer.isVerifiedWorkBeginDay === '1') ? 'Yes' : 'No'}</span>
                        </label>
                    </Col>
                </Col>
                <p className="key-data">{displayText.quest2UtilitiesObserved}</p>
                <Col sm={4} className="input">
                    <label className="checkbox-label" htmlFor="isGasMarked">{displayText.gas} &nbsp;&nbsp;&nbsp;
                        <span> {(answer.isGasMarked && answer.isGasMarked === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} className="input">
                    <label className="checkbox-label" htmlFor="isElectricMarked">{displayText.electric} &nbsp;&nbsp;&nbsp;
                        <span> {(answer.isElectricMarked && answer.isElectricMarked === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} className="input">
                    <label className="checkbox-label"
                           htmlFor="isTelephoneMarked">{displayText.telephone} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isTelephoneMarked && answer.isTelephoneMarked === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} className="input">
                    <label className="checkbox-label" htmlFor="isCableTVMarked">{displayText.ctv} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isCableTVMarked && answer.isCableTVMarked === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
                <Col sm={4} className="input">
                    <label className="checkbox-label" htmlFor="isWaterMarked">{displayText.water} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isWaterMarked && answer.isWaterMarked === '1') ? 'Yes' : 'No'}</span></label>
                </Col>
                <Col sm={4} className="input">
                    <label className="checkbox-label" htmlFor="isSewerMarked">{displayText.sewer} &nbsp;&nbsp;&nbsp;
                        <span>{(answer.isSewerMarked && answer.isSewerMarked === '1') ? 'Yes' : 'No'}</span></label>
                </Col>
            </Row>
            <Row className={'show-grid'}>
                <Col sm={12} className="input">
                    <label className={'yellow-color-title key-data'}
                           htmlFor="isUnmarkedUtilities">{displayText.quest3UnmarkedUtilities}&nbsp;&nbsp;&nbsp;
                        <span>{(answer.isUnmarkedUtilities && answer.isUnmarkedUtilities === '1') ? 'Yes' : 'No'}</span>
                    </label>
                </Col>
            </Row>
            {answer.isUnmarkedUtilities === '1' && [<p key={3.1}
                                                       className={'key-data'}>{displayText.questPleaseIdentify}</p>,
                <Row key={3.2} className={'show-grid'}>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isGasUnmarked">{displayText.gas} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isGasUnmarked && answer.isGasUnmarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label"
                               htmlFor="isElectricUnmarked">{displayText.electric} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isElectricUnmarked && answer.isElectricUnmarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label"
                               htmlFor="isTelephoneUnMarked">{displayText.telephone} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isTelephoneUnMarked && answer.isTelephoneUnMarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isCableTVUnmarked">{displayText.ctv} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isCableTVUnmarked && answer.isCableTVUnmarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isWaterUnmarked">{displayText.water} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isWaterUnmarked && answer.isWaterUnmarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                    <Col sm={4} className="input">
                        <label className="checkbox-label" htmlFor="isSewerUnmarked">{displayText.sewer} &nbsp;&nbsp;&nbsp;
                            <span>{(answer.isSewerUnmarked && answer.isSewerUnmarked === '1') ? 'Yes' : 'No'}</span></label>
                    </Col>
                </Row>,
                <Row key={3.3} className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <span className={'checkbox-label'}>{displayText.questNotifiedUnmarkedUtility}&nbsp;&nbsp;&nbsp;
                            <span>{(answer.isOneCallNotifiedOnUnmarked && answer.isOneCallNotifiedOnUnmarked === '1') ? 'Yes' : 'No'}</span></span>
                    </Col>
                </Row>]}
            <p className="key-data">{displayText.quest4IsPhotographed}</p>
            <Row className={'show-grid'}>
                <Col sm={12} xs={12}>
                        <span className={'checkbox-label'}>{displayText.questPhotoGraphedPriorExcavation}&nbsp;&nbsp;&nbsp;
                            <span>{(answer.isSitePhotographed && answer.isSitePhotographed === '1') ? 'Yes' : 'No'}</span></span>
                </Col>
                <Col sm={12} xs={12}>
                        <span className={'checkbox-label'}>{displayText.questPhotoGraphedMarkings}&nbsp;&nbsp;&nbsp;
                            <span>{(answer.isMarkingsPhotographed && answer.isMarkingsPhotographed === '1') ? 'Yes' : 'No'}</span></span>
                </Col>
            </Row>
            <p className="key-data">{displayText.quest5CrewMemberAdvised}</p>
            <h6 style={{color: "red", textAlign: 'center'}}>{displayText.respectTheMarks}</h6>
            <Row className={'show-grid'}>
                <Col sm={12} xs={12}>
                        <span className={'checkbox-label'}>{displayText.crewAdvised}&nbsp;&nbsp;&nbsp;
                            <span>{(answer.isAdvised18InchHandDigRule && answer.isAdvised18InchHandDigRule === '1') ? 'Yes' : 'No'}</span></span>
                </Col>
            </Row>
            <p className="key-data">{displayText.quest6TestHolesEvery80T060Feet}</p>
            <Row className={'show-grid'}>
                <Col sm={12} xs={12}>
                        <span className={'checkbox-label'}>{displayText.quest6Option}&nbsp;&nbsp;&nbsp;
                            <span>{(answer.isAdvisedToTestDugHoles && answer.isAdvisedToTestDugHoles === '1') ? 'Yes' : 'No'}</span></span>
                </Col>
            </Row>
            <hr/>
            <span className={'advise-title'}>
                      <h5 style={{textAlign: 'center'}}>{displayText.advise1Title}</h5>
                    <ul>
                        <li>{displayText.advise11}</li>
                        <li>{displayText.advise12}</li>
                        <li>{displayText.advise13}</li>
                    </ul>
                </span>
            <span className={'advise-title'}>
                      <h5 style={{textAlign: 'center'}}>{displayText.advise2Title}</h5>
                    <ul>
                        <li>{displayText.advise21}</li>
                        <li>{displayText.advise22}</li>
                        <li>{displayText.advise23}</li>
                        <li>{displayText.advise24}</li>
                        <li>{displayText.advise25}</li>
                        <li>{displayText.advise26}</li>
                        {displayText.advise27 && <li>{displayText.advise27}</li>}
                        {displayText.advise28 && <li>{displayText.advise28}</li>}
                    </ul>
                </span>
        </div>
    }

    renderFormDetails = () => {
        return <Async
            identifier="PreExcavationFormDetail"
            promise={this.promise}
            error={<ErrorPage/>}
            loader={<Loader/>}
            content={this.renderContent()}
        />;
    }

    render() {
        let fileName = '';
        const {instance} = this.props;
        if (instance) {
            const {instance: {props: {excavationDate}}} = this.props;
            fileName = `PreExcavation checklist for ${excavationDate}`;
        }
        return (
            <BasePage>
                <Gallery images={instance ? instance.props.attachments : []}/>
                <DetailsPageWrapper pdfFileName={fileName} className="office-safety-report report-details preExcavationChecklist"
                                    customButton={<Button className="closeButton" redirectTo="goBack" type="button"
                                                          onClick={() => this.props.history?.goBack()}>
                                        <i className="fa fa-ban" aria-hidden="true"/>&nbsp;
                                        Close
                                    </Button>
                                    }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const id = ownProps.match.params.id;
    const instance = PreExcavationChecklistModel.get(id);
    return {
        id,
        instance
    };
}

export const PreExcavationDetailsPage = connect<{}, {}, IPreExcavationDetailsPageProps>(mapStateToProps)(PreExcavationDetailsPageImpl);
