import * as React from 'react';
import { IHistory } from '../../../interfaces';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'; 
import { Cell } from '../../reusableComponents/ChartComponents/Cell';
import { connect } from 'react-redux'; 
import { showCreateCloseCallFollowUpModal } from '../../../actions/modalActions'; 
import { UserModel } from '../../../model/UserModel';
import { getPageItemNumber, substring } from '../../../utils/generalUtils'; 
import { Button } from '../../reusableComponents/FormComponents/Button';
import { format } from 'date-fns';  
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: any;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> { 
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderDropdown = () => {
        const { instance } = this.props;
        if (!instance) {
            return;
        }
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3">  
                { UserModel.checkUserAccess('jsa_edit') && <MenuItem eventKey="2"
                    onClick={() => this.followUpEditFormModal(instance.props)}
                    className="item"><i className={`fa fa-${instance.props.status==='Closed'?'eye':'pencil'} }`} aria-hidden="true"></i>{instance.props.status==='Closed'?'View':'Edit'}</MenuItem> } 
            </DropdownButton>
        );
    } 
    followUpEditFormModal(instance: any) {    
        instance['reqFrom'] = 'ownFollowUps';
        showCreateCloseCallFollowUpModal(instance);
    }  
    renderFormList = () => {
        const {  index, style } = this.props; 
        let instance = this.props.instance.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}> 
                <Col sm={1}>
                    {( UserModel.checkUserAccess('jsa_view') || UserModel.checkUserAccess('jsa_edit') || UserModel.checkUserAccess('jsa_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
                </Col>
                <Cell onClick={() => this.followUpEditFormModal(instance) } sm={4} >
                    { (instance.action && substring(instance.action,35) )|| 'NA'}
                </Cell>
                <Cell onClick={() => this.followUpEditFormModal(instance) } sm={2} >
                    {instance.assignedByName || 'NA'}
                </Cell>  
                <Cell onClick={() => this.followUpEditFormModal(instance) } sm={2} >
                    { format( new Date(instance.targetCompletion), 'MM/dd/yyyy')  || 'NA' }
                </Cell>  
                <Col className="clickable-list-item" sm={3} onClick={() => this.followUpEditFormModal(instance) }> 
                    { instance['status'] && instance['status']==='Open'?<Button className="close-call-followup-open" style={followUpOpen}>Open</Button>:instance['status']==='Closed'?<Button className="close-call-followup-closed" style={followUpClosed}>Closed</Button> : <Button className="close-call-followup-warning" style={followUpWarning}>Follow up</Button> } 
                </Col> 
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
        
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));

const followUpOpen: React.CSSProperties = {
    color:'white',
    borderColor: 'red',
    backgroundColor: 'red'
 };
 const followUpWarning: React.CSSProperties = {
    color:'white',
    borderColor: '#17a2b8',
    backgroundColor: '#17a2b8'
 };
 const followUpClosed: React.CSSProperties = {
    color:'white',
    borderColor: 'green',
    backgroundColor: 'green'
 };