import * as React from 'react';
import { connect } from 'react-redux';

import { showPrintPreviewModal } from '../../actions/modalActions';
import { IIncidentInvestigativeSummary, IHistory, IForms } from '../../interfaces';
import { getIncidentInvestigativeSummaryData } from '../../services/incidentReportService';
import { printDetails } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { IncidentInvestigativeSummaryData } from './IncidentInvestigativeSummaryData';
import { withRouter } from 'react-router';

export interface IIncidentReportSummaryPageProps {
    summaryData: IIncidentInvestigativeSummary;
    userUID: string;
    followUpsCount?: number;
    history?: IHistory;
    forms?: IForms;
}

export class IncidentReportSummaryPageImpl extends React.PureComponent<IIncidentReportSummaryPageProps, {}> {

    ref: any;

    promise = async () => { 
        //if (!summaryData) {
            //await getIncidentInvestigativeSummaryData(userUID);
            await getIncidentInvestigativeSummaryData();
            /* await getOIFormSummaryData(); */
            //await getAllFollowUpForm(userUID);
        //}
        //return null;
    }

    renderPrintPreviewModal = () => {
        showPrintPreviewModal('incident-investigative-report');
       /*  let ref: any = document.getElementById('summary-reports'); 
        if(ref) {  
			window.print();
        } */
        return;
    }

    renderContent = (isAnimationActive: boolean, id?: string) => {
        const { summaryData } = this.props;
        if (!summaryData || !this.props.history) {
            return <Loader />;
        } 
        return <div>
            <IncidentInvestigativeSummaryData
                id={id || ''}
                followUpsCount={this.props.summaryData.followUpsCount}
                history={this.props.history}
                potentialSeverity={this.props.summaryData.potentialSeverity}
                actualSeverity={this.props.summaryData.actualSeverity}
                getRef={refVal => this.ref = refVal}
                locationChartData={this.props.summaryData.locations}
                isAnimationActive={isAnimationActive}
                bodyPartChartData={this.props.summaryData.bodyParts}
                forms={this.props.forms}
            />
            <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
            </div>
        </div>;
    }

    exportCharts = (ref: any) => {
        printDetails(this.ref, 'IncidentInvestigativeReports');
    }

    render() {
        return (
            <BasePage className="reports-page">
                <PrintPreviewModal
                    id="incident-investigative-report"
                    ref="printPreview"
                    className="incident-report-summary"
                    onClickYes={this.exportCharts}
                    style={{ boxShadow: 'none' }}
                    fileName="IncidentInvestigativeReports"
                >
                    {this.renderContent(false, 'print')}
                </PrintPreviewModal>
                <div className="incident-report-summary" id="summary-reports">
                    <Async
                        identifier="IncidentInvestigativeReport"
                        promise={this.promise}
                        error={<ErrorPage />}
                        loader={<Loader />}
                        content={this.renderContent(true)}
                    />
                </div>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any) {
    const summaryData = state.formSummary.get('incidentInvestigativeSummary');
    const userUID = state.login.get('userUID'); 
    //const followUpsCount = state.formSummary.get('incidentInvestigativeSummary').followUpsCount;
    return { summaryData, userUID, forms: state.forms, };
}

export const IncidentReportSummaryPage = withRouter(connect<
    IIncidentReportSummaryPageProps, any, any>(mapStateToProps)(IncidentReportSummaryPageImpl)
);
