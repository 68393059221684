import {IForms, IHistory} from "../interfaces";
import {UserModel} from "../model/UserModel";
import {format} from "date-fns";
import {constructAnswers, removeByValue, showAlert, getSignatureData} from "../utils/generalUtils"; 
import {FORM_SUBMISSION_ERROR} from "../constants/general";
import {getCustom, post} from "../utils/HTTP";
import {PermitToWorkModel} from '../model/PermitToWorkModel'; 
import {UtilityDamageReportModel} from "../model/UtilityDamageReportModel";

let generalFields = ['pshDate', 'isPSH', 'pshPI', 'pshOI', 'riskMitigationHazard', 'riskMitigationControl', 'attributeYes', 'attributeNA', 'checklistComments' ];
let CSEClassLimit = ['AT1CSEDate', 'AT1CSETime', 'AT1CSEO2', 'AT1CSELELGW', 'AT1CSELELF', 'AT1CSECO', 'AT1CSEBenzene', 'AT1CSESO2', 'AT1CSEAGTInitials' ];  
let isolationDetail = ['isolationDetailEquipmentID', 'isolationDetailDescription', 'isolationDetailIsolationType', 'isolationDetailIsolationMethod', 'isolationDetailComment', 'isolationDetailPointPositionNormal', 'isolationDetailPointPositionIsolatedType', 'isolationDetailInstalledIAAssigned', 'isolationDetailInstalledIIP', 'isolationDetailRemovedIAAssigned', 'isolationDetailRemovedIAR' ];
let isolationBlindRegisterDetail = ['isolationBlindRegisterDescription', 'isolationBlindRegisterRating', 'isolationBlindRegisterSize', 'isolationBlindRegisterType', 'isolationBlindRegisterDate', 'isolationBlindRegisterIAInitial', 'isolationBlindRegisterVerifierInitial', 'isolationBlindRegisterRemovedDate', 'isolationBlindRegisterRemovedIAInitial', 'isolationBlindRegisterRemovedVerifierInitial' ];
let workDecleration = ['signOffName', 'signOffDate', 'signOffInTime', 'signOffOutTime'];
let attachementURLS = [ 'generalPermitTemporaryDefeatAttachmentsURL','generalPermitLiftingPlanAttachmentsURL', 'generalPermitMSDSAttachmentsURL', 'generalPermitPIDAttachmentsURL', 'generalPermitProcedureAttachmentsURL', 'generalPermitRescuePlanAttachmentsURL', 'generalPermitSIMOPAttachmentsURL',  'generalPermitWorkAidAttachmentsURL', 'otherJSAAttachmentsURL'];
let detecterUsed = ['detecterUsed', 'colibirationDate'];

export async function submitPermitToWorkForms(formValues: any, formName: string, history: IHistory | undefined, forms: IForms, checklistComments: any, editId?: string, editInstance?: any) {
    const company = UserModel.getCompanyName();
    const submittedDateTime = new Date();
    let deletedFiles: Array<any> = [];  
    let removedFileds = ['editId', 'deletedFiles', 'formStage', 'isolationType', 'jsaNo' ]; 
    let signatureFields = [ 'AASignature', 'AIASignature', 'AOClosedSignature', 'AtmospericAGTSignature', 'ITDCOISignature', 'ITDCPISignature', 'OIReviewerSignature', 'PCOOIClosedSignature', 'PCOPISignature', 'PHSignature', 'PICSignature', 'PIReviewerSignature', 'deisolationPIIsolationVerificationSignature',  'isolationDedeatedBySignature', 'isolationOIIsolationVerificationSignature', 'isolationPIIsolationVerificationSignature', 'isolationPIPlanVerificationSignature', 'isolationTemporaryDefeatPISignature', 'isolationLongTermDefeatRequestedBySignature', 'longTermDefeatsRequestedBySignature', 'returnToServiceBySignature', 'isolationTemporaryDefeatDefeatedBySignature', 'isolationTemporaryDefeatServiceReturnBySignature', 'isolationLongTermDefeatAuthorizationPISignature', 'isolationLongTermDefeatAuthorizationOISignature', 'permitCloseOutOISignature', 'permitCloseOutPISignature'];
    let attachementFields = ['generalPermitTemporaryDefeatAttachments','generalPermitLiftingPlanAttachments', 'generalPermitMSDSAttachments', 'generalPermitPIDAttachments', 'generalPermitProcedureAttachments', 'generalPermitRescuePlanAttachments', 'generalPermitSIMOPAttachments',  'generalPermitWorkAidAttachments', 'otherJSAAttachments'];
    let signatureFieldsURL: any = [];
    if (signatureFields && signatureFields.length > 0) {
        for (let i = 0; i < signatureFields.length; i++) {
            let signatureFieldURL = signatureFields[i]+'URL';
            signatureFieldsURL.push(signatureFieldURL);
        }
    }
    removedFileds = removedFileds.concat(generalFields, signatureFields, signatureFieldsURL,attachementFields, attachementURLS, CSEClassLimit, isolationDetail, isolationBlindRegisterDetail, workDecleration, detecterUsed );
    formValues = removeByValue(formValues, removedFileds);
    let answers = constructAnswers(formValues);
    deletedFiles = forms.permitToWorkForm.deletedFiles || []; 
    let signatureData: any = [];
    if (signatureFields && signatureFields.length > 0) {
        for (let i = 0; i < signatureFields.length; i++) {
            let signatureField = signatureFields[i];
            let signatureFieldURL = `${signatureField}URL`;
            if(forms.permitToWorkForm[signatureField]) {  
                let signature = getSignatureData(forms.permitToWorkForm[signatureField], signatureFieldURL )
                signatureData.push(signature);
            } else if(editInstance[signatureFieldURL]) { 
                answers.push({'answer': editInstance[signatureFieldURL] , 'quesNum': 0, 'quesType': signatureFieldURL }) 
            }
        }
    } 
    let attachments: any = [];
    if (attachementFields && attachementFields.length > 0) {
        for (let i = 0; i < attachementFields.length; i++) {
            let files = forms.permitToWorkForm[attachementFields[i]];
            let attachementFieldURL = `${attachementFields[i]}URL`;
            let quesNum = 0;
            if(files && files.length>0) {
                for (let j = 0; j < files.length; j++) {
                    let fileData = files[j];
                    fileData = Object.assign({quesType: attachementFieldURL, quesNum: quesNum++ }, fileData); 
                    attachments.push(fileData);
                }
            }
            let oldUploadedURL = forms.permitToWorkForm[attachementFieldURL] || [];
            if(oldUploadedURL.length>0) {
                for (let k = 0; k < oldUploadedURL.length; k++) {
                    answers.push({'answer': oldUploadedURL[k], 'quesNum': quesNum++, 'quesType': attachementFieldURL });
                }
            }
        }
    } 
    if(forms.permitToWorkForm.isPSH && forms.permitToWorkForm.pshDate && forms.permitToWorkForm.pshPI && forms.permitToWorkForm.pshOI ) {
        answers.push({'answer': forms.permitToWorkForm.isPSH.length.toString(), 'quesNum': answers.length+1, 'quesType': 'pshCount' });
        for (let i = 0; i < forms.permitToWorkForm.isPSH.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.pshDate[i], 'quesNum': answers.length+1, 'quesType': 'pshDate' });
        }
        for (let i = 0; i < forms.permitToWorkForm.isPSH.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.isPSH[i], 'quesNum': answers.length+1, 'quesType': 'isPSH' });
        }
        for (let i = 0; i < forms.permitToWorkForm.pshPI.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.pshPI[i], 'quesNum': answers.length+1, 'quesType': 'pshPI' });
        }
        for (let i = 0; i < forms.permitToWorkForm.pshOI.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.pshOI[i], 'quesNum': answers.length+1, 'quesType': 'pshOI' });
        }
    }
    if(forms.permitToWorkForm.AT1CSEDate ) {
        answers.push({'answer': forms.permitToWorkForm.AT1CSEDate.length.toString(), 'quesNum': answers.length+1, 'quesType': 'AT1CSECount' });
    } 
    for (let i = 0; i < CSEClassLimit.length; i++) {
        if(forms.permitToWorkForm[CSEClassLimit[i]].length>0 ) {
            for (let j= 0; j < forms.permitToWorkForm[CSEClassLimit[i]].length; j++) { 
                answers.push({'answer': forms.permitToWorkForm[CSEClassLimit[i]][j], 'quesNum': answers.length+1, 'quesType': CSEClassLimit[i] });
            }
        }
    }
    if(forms.permitToWorkForm.detecterUsed) {
        answers.push({'answer': forms.permitToWorkForm.detecterUsed.length.toString(), 'quesNum': answers.length+1, 'quesType': 'riskMitigationHazardCount' });
        for (let i = 0; i < forms.permitToWorkForm.detecterUsed.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.detecterUsed[i], 'quesNum': answers.length+1, 'quesType': 'detecterUsed' });
        } 
    }
    if(forms.permitToWorkForm.colibirationDate) { 
        for (let i = 0; i < forms.permitToWorkForm.colibirationDate.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.colibirationDate[i], 'quesNum': answers.length+1, 'quesType': 'colibirationDate' });
        } 
    } 

    if(forms.permitToWorkForm.riskMitigationHazard) {
        answers.push({'answer': forms.permitToWorkForm.riskMitigationHazard.length.toString(), 'quesNum': answers.length+1, 'quesType': 'riskMitigationHazardCount' });
        for (let i = 0; i < forms.permitToWorkForm.riskMitigationHazard.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.riskMitigationHazard[i], 'quesNum': answers.length+1, 'quesType': 'riskMitigationHazard' });
        } 
    }
    if(forms.permitToWorkForm.riskMitigationControl) { 
        for (let i = 0; i < forms.permitToWorkForm.riskMitigationControl.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.riskMitigationControl[i], 'quesNum': answers.length+1, 'quesType': 'riskMitigationControl' });
        } 
    } 
    /* if(forms.permitToWorkForm.isolationDetailEquipmentID ) {
        answers.push({'answer': forms.permitToWorkForm.isolationDetailEquipmentID.length.toString(), 'quesNum': answers.length+1, 'quesType': 'isolationDetailCount' });
    } */
    /* for (let i = 0; i < isolationDetail.length; i++) {
        if(forms.permitToWorkForm[isolationDetail[i]].length>0 ) {
            for (let j= 0; j < forms.permitToWorkForm[isolationDetail[i]].length; j++) { 
                answers.push({'answer': forms.permitToWorkForm[isolationDetail[i]][j], 'quesNum': answers.length+1, 'quesType': isolationDetail[i] });
            }
        }
    } */
    /* if(forms.permitToWorkForm.isolationBlindRegisterDescription ) {
        answers.push({'answer': forms.permitToWorkForm.isolationBlindRegisterDescription.length.toString(), 'quesNum': answers.length+1, 'quesType': 'isolationBlindRegisterDetailCount' });
    } */
    /* for (let i = 0; i < isolationBlindRegisterDetail.length; i++) {
        if(forms.permitToWorkForm[isolationBlindRegisterDetail[i]].length>0 ) {
            for (let j= 0; j < forms.permitToWorkForm[isolationBlindRegisterDetail[i]].length; j++) { 
                answers.push({'answer': forms.permitToWorkForm[isolationBlindRegisterDetail[i]][j], 'quesNum': answers.length+1, 'quesType': isolationBlindRegisterDetail[i] });
            }
        }
    } */
    if(forms.permitToWorkForm.signOffName ) {
        answers.push({'answer': forms.permitToWorkForm.signOffName.length.toString(), 'quesNum': answers.length+1, 'quesType': 'workDeclerationCount' }); 
    }
    for (let i = 0; i < workDecleration.length; i++) {
        if(forms.permitToWorkForm[workDecleration[i]].length>0 ) {
            for (let j= 0; j < forms.permitToWorkForm[workDecleration[i]].length; j++) { 
                answers.push({'answer': forms.permitToWorkForm[workDecleration[i]][j], 'quesNum': answers.length+1, 'quesType': workDecleration[i] });
            }
        }
    }
    if(forms.permitToWorkForm.attributeYes){
        for (let i = 0; i < forms.permitToWorkForm.attributeYes.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.attributeYes[i], 'quesNum': answers.length+1, 'quesType': 'attributeYes' });
        }
    }
    if(forms.permitToWorkForm.attributeNA){
        for (let i = 0; i < forms.permitToWorkForm.attributeNA.length; i++) { 
            answers.push({'answer': forms.permitToWorkForm.attributeNA[i], 'quesNum': answers.length+1, 'quesType': 'attributeNA' });
        }
    } 
    /* if(forms.permitToWorkForm.checklistComments ) {
        for (var key in forms.permitToWorkForm.checklistComments ) { 
            answers.push({'answer': key+ '/~/'+ forms.permitToWorkForm.checklistComments[key].toString(), 'quesNum': answers.length+1, 'quesType': 'checklistComments' });
        } 
    }*/ 
    if(checklistComments.length>0 ) { 
        for (var key in checklistComments ) { 
            answers.push({'answer': key+ '/~/'+ checklistComments[key].toString(), 'quesNum': answers.length+1, 'quesType': 'checklistComments' });
        } 
    }
     
    let formStage = forms.permitToWorkForm.closePermitCloseOut?3:(forms.permitToWorkForm.closeWorkDecleration?2:1);
    answers.push({'answer': formStage.toString(), 'quesNum': answers.length+1, 'quesType': 'formStage' }); 
    //return answers;
    const dataToSend = { groupName: '', userId: UserModel.getUserUID(), formId: formName, date: format(submittedDateTime, 'yyyy-MM-dd'), time: format(submittedDateTime, 'HH:mm'), projectName: '', associatedFormId: '', followUp: "0", editId: editId || '', PTWAttachments: attachments, deletedFiles, deletedImages: [], deletedVideos: [], company, answers, signatureData };
    try { 
        const response = editId ? await post('/EditForm', dataToSend) : await post('/SubmitForm', dataToSend);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, `PTW-form-failed`, 'danger');
            return;
        } 
        return response;
    } catch (e) {
        showAlert(FORM_SUBMISSION_ERROR, `PTW-form-failed`, 'danger');
        throw e;
    }
}

export async function getAllPTWForms( urlData?: any, forms?: IForms, formStage = 1) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
       const data = { ...forms?.commonFilterForm,  formType: 'permitToWork', pageNumber, sortIndexField, sortingOrder, filterParam1: formStage, searchItem }; 
       const response = await post('/GetForms', data, axiosPreviousToken); 
       PermitToWorkModel.deleteAll();
        if (response.data.status === 'valid' && response.data.data.length) { 
            let ptwFormData: any = {}; 
            response.data.data.forEach((data: any) => {
                ptwFormData = marshallFormData(data); 
                new PermitToWorkModel(ptwFormData).$save();
            }); 
        }
    } catch (e) {
        throw e;
    }
}  
function formattingAnswers(answers: Array<{ quesType: string, quesNum: number, answer: any }>) {
    const formattedAnswer: any = {}; 
    formattedAnswer['checklistComments'] = [];
    answers.forEach((answer: { quesType: string, quesNum: number, answer: any }) => { 
        if(answer.quesType==='checklistComments') {
            let checklistComments = answer.answer.split('/~/'); 
            formattedAnswer['checklistComments'][checklistComments[0]] = checklistComments[1]; 
        } else if( generalFields.indexOf(answer.quesType) !== -1  || CSEClassLimit.indexOf(answer.quesType) !== -1 || isolationDetail.indexOf(answer.quesType) !== -1 || isolationBlindRegisterDetail.indexOf(answer.quesType) !== -1 || workDecleration.indexOf(answer.quesType) !== -1 || detecterUsed.indexOf(answer.quesType) !== -1 || attachementURLS.indexOf(answer.quesType) !== -1) { 
            if(formattedAnswer[answer.quesType]) { 
                formattedAnswer[answer.quesType].push(answer.answer)
            } else { 
                formattedAnswer[answer.quesType] = [answer.answer]
            }
        } else { 
            formattedAnswer[answer.quesType] = answer.answer;
        }
    }); 
    return formattedAnswer;
} 
function marshallFormData(data: any) {
    const formattedData: any = {
        id: data.key,
        answers: formattingAnswers(data.answers)
    } 
    delete data['answers'];
    Object.assign(formattedData, data);
    return formattedData
} 
export async function getFormData(formId: string) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`GetForms/permitToWork/${company}/${formId}`);
        UtilityDamageReportModel.deleteAll(); 
        if (response.data.status === 'valid' && response.data.data.length) {
            PermitToWorkModel.deleteAll(); 
            let ptwFormData: any = {}; 
            response.data.data.forEach((data: any) => {
                ptwFormData = marshallFormData(data)
                new PermitToWorkModel(ptwFormData).$save();
            }); 
        }
    } catch (e) {
        throw e
    } 
}
