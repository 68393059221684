import './referencePage.scss';

import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Row, Col} from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '../../reusableComponents/FormComponents/Button';
import { IHistory } from '../../../interfaces';
import { BasePage } from '../../reusableComponents/BasePage';
import { Title } from '../../reusableComponents/Title';
import { FileDisplayCard } from './FileDetailsCard';
import { Alert } from '../../Alert';
import { showAlert } from '../../../utils/generalUtils';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { saveReferences } from '../../../services/miscellaniousServices';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal'; 
import { yellowA200 } from 'material-ui/styles/colors';
import { FileInput } from '../../reusableComponents/FormComponents/FileInput';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { IForms } from '../../../interfaces';

export interface IReferenceDropZoneProps {
    history: IHistory;
    forms: IForms;
}

export interface IReferenceDropZoneState {
    //files: IFileType[];
    files: any[];
    URL: string;
    inputValue: string;
    URLTitle: string;
    isSubmitting: boolean; 
}

export class ReferenceDropZoneImpl extends React.PureComponent<IReferenceDropZoneProps, IReferenceDropZoneState> {
    constructor(props: IReferenceDropZoneProps) {
        super(props);
        this.state = { inputValue: '', files: [], URL: '', URLTitle: '', isSubmitting: false };
    }
    reffileName: any = '';

    handleFileDrop = (acceptedFiles: any) => {
        const newFiles = this.state.files.concat(acceptedFiles); 
        const fileReader = new FileReader();
        const name = new Date().getTime().toString();
        let filename = newFiles[0]['name'].split('.');
        this.reffileName = `${name}.${filename[filename.length-1].toLowerCase()}`;
        fileReader.onload = this.readFile; 
        fileReader.readAsDataURL(newFiles[0]);  
        this.setState({ files: newFiles });
    } 
    refFileData: any = '';
    readFile = (e: any) => { 
        this.refFileData = {file: e.target.result, name: this.reffileName};
    } 
    handleDeleteFile = (index: number) => {
        const files = this.state.files;
        const remainingFiles = files.filter((file, fileIndex) => fileIndex !== index); 
        this.setState({
            files: remainingFiles
        });
    } 
    handleUploadFiles = async () => {
        let forms = this.props.forms;  
        let bannerImage = (forms as any)[`referenceForm`][`bannerImage`];
        const { files, URL, URLTitle } = this.state;
        if(bannerImage.length > 1){
            showAlert('Sorry multiple banner imagae upload is not allowed.', 'reference', 'danger');
            return;
        }
        if (files.length > 1) {
            showAlert('Sorry multiple file upload is not allowed.', 'reference', 'danger');
            return;
        }
        if (files[0] && !files[0].title) {
            showAlert('Please add title to the file.', 'reference', 'danger');
            return;
        }
        this.setState({
            isSubmitting: true
        });
        if (URLTitle.length) {
            await saveReferences(URLTitle, URL, /* files */this.refFileData, bannerImage, this.props.history);
        } else if (files[0].title) {
            await saveReferences(files[0].title, URL, /* files */this.refFileData, bannerImage, this.props.history);
        }
        this.setState({
            isSubmitting: false
        });
    }

    handleFileTitleChange = (value: string, index: number) => {
        const files = this.state.files;
        files[index].title = value;
        this.setState({
            files
        });
    }

    getChildContext() {
        return { formModel: 'forms.referenceForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    render() {
        return <BasePage className="reference-doc-upload" >
            {this.state.isSubmitting ? <SubmissionLoader /> : ''}
            <div className="reference-upload" >
                <Title text="Add File/URL" />
                <Alert id="reference" className="danger-alert" />
                <AlertModal id="reference-upload-success" className="success-alert" />
                <Form loader={<SubmissionLoader />} model="forms.referenceForm"> 
                    <Row className="show-grid text-center">
                        <Col sm={12} xs={12}>
                            <Label required={false} sm={12} style={{textAlign: 'center',}}  htmlFor="upload-images">Banner Image:</Label>
                            <Col sm={12} className="input">
                                <FileInput
                                    multiple={false}
                                    model=".bannerImage" 
                                    id="upload-images" 
                                    style={{left: 'unset',}} 
                                /> 
                            </Col>
                        </Col> 
                    </Row> 
                    {this.state.files.length > 0 || this.state.URL.length > 0 ? '' :
                        [
                        <div key="file" className="dropzone-container">
                            <Dropzone 
                            onDrop={acceptedFiles => this.handleFileDrop(acceptedFiles)}
                        >
                             {({getRootProps, getInputProps}) => (
                                <section>
                                <div className="dropzone" style={{position: 'relative'}} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                    <p>Try dropping a file here, or click to select a file to upload.</p>
                                </div>
                                </section>
                            )}
                            
                        </Dropzone>
                        </div>,
                        <div key="or" style={{
                            textAlign: 'center',
                            fontSize: '22px',
                            color: yellowA200,
                            paddingBottom: '20px'
                        }} >
                            OR
                        </div>]
                    }
                </Form>
                {this.state.URL.length <= 0 ?
                    (this.state.files.length <= 0 ?
                        <div className="add-link-container">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Paste your link here..."
                                onChange={e => this.setState({ inputValue: e.target.value })}
                            />
                            <i
                                title="Click to save URL"
                                onClick={() => this.setState({ URL: this.state.inputValue })}
                                className="fa fa-check"
                                aria-hidden="true"
                            ></i>
                        </div> : '') :
                    (this.state.files.length <= 0 ?
                        <div className="url-display" style={{ padding: '20px', textAlign: 'center' }} >
                            <div style={{ padding: '11px', fontSize: '18px' }} >
                                <span style={{ color: 'white' }} >URL: &nbsp; </span>
                                <a href={this.state.URL} target="_blank" rel="noreferrer">
                                    {this.state.URL}
                                </a>
                                <i onClick={() => this.setState({
                                    URLTitle: '',
                                    URL: '',
                                    files: []
                                })}

                                    title="Click to delete this URL"
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter your Reference Title..."
                                    onChange={e => this.setState({ URLTitle: e.target.value })}
                                />
                            </div>
                        </div> : ''
                    )}
                <div className="files-display" >
                    {this.state.files.map((file, index) => {
                        return <FileDisplayCard
                            file={file}
                            key={index}
                            index={index}
                            onDelete={this.handleDeleteFile}
                            onTitleChange={this.handleFileTitleChange}
                        />;
                    })}
                </div>
            {
                
                <Row className="show-grid " style={{ textAlign: 'center' }}> 
                    <div className="form-button">
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white', width: '160px', margin:'0px 10px', 
                        }}
                            
                            onClick={() => this.props.history.goBack() }
                            type="reset"
                        >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button> 
                        {this.state.files.length > 0 || this.state.URLTitle.length > 0 ?
                            <button onClick={this.handleUploadFiles} >Upload Reference</button>
                        : ''} 
                    </div>
                </Row>
            }
            </div>
        </BasePage >;
    }
}
export function mapStateToProps(state: any) {
    return { 
        forms: state.forms
    }; 
} 
export const ReferenceDropZone = connect<{}, {}, IReferenceDropZoneProps>(mapStateToProps)(ReferenceDropZoneImpl);
