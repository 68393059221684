import * as React from 'react';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import './pagination.scss';
import { BaseModel } from '../../../model/BaseModel';
import { setFilters } from '../../../utils/generalUtils';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { IHistory, IForms } from '../../../interfaces';
declare var require: any;
const queryString = require('query-string'); 

export interface IPaginationProps {
    totalCount: number | undefined;
    Model?: typeof BaseModel;
    promise: (pageNumber: any) => void;
    history?: IHistory;
    identifier: string;
    forms?: IForms;
}

export class Pagination extends React.Component<IPaginationProps, { pageNumber?: number, activeBar: any, showLoader?: boolean }> {
 
    handlePageNumberClick = async (pageNumber: number, activeBar: any) => {
        const { Model, promise, forms } = this.props;
        if (Model) {
            Model.deleteAll();
        }
        this.setState({ pageNumber, /* showLoader: true */ });
        
        let urlParams = queryString.parse(this.props.history?.location.search);
        let param: any = { pageNumber, activeBar, sortingIndex: urlParams.sortingIndex || '', sortingOrder: urlParams.sortingOrder || '', searchItem: urlParams.searchItem || '', ...forms?.commonFilterForm };
        //this.props.history && setFilters({ pageNumber, activeBar }, this.props.history );
        this.props.history && setFilters(param, this.props.history );
        setLoading(this.props.identifier); 
        if (promise) {
            await promise(pageNumber);
        }
        //this.setState({ showLoader: false });
        setSuccess(this.props.identifier);
    }
    handleState() {
        this.state = {
            activeBar: queryString.parse(this.props.history?.location.search).activeBar || 1,
            pageNumber: queryString.parse(this.props.history?.location.search).pageNumber || 1,
            showLoader: false,
        };
    
    }
    activeStyle: React.CSSProperties = {
        fontWeight: 'bold',
        pointerEvents: 'none',
        backgroundColor: '#6070ce',
        color: 'white',
        cursor: 'pointer'
    };

    renderPageNumbers = (activeBar: any) => {
        const { totalCount } = this.props; 
        let pageNumberContainer: JSX.Element[] = [];
        if ((totalCount || 0) > 21) {
            for (let i = activeBar; i < Number(activeBar) + 21; i++) {
                pageNumberContainer.push(<div key={i}
                    className={`page-number`}
                    style={Number(this.state.pageNumber) === (i + 1) ? this.activeStyle : {}}
                    onClick={() => { this.handlePageNumberClick(i, this.state.activeBar); }}
                >
                    {i}
                </div>);
            }
            return pageNumberContainer;
        }
        for (let i = 0; i < Number(totalCount || 0); i++) {
            pageNumberContainer.push(<div key={i}
                className={`page-number`}
                style={Number(this.state.pageNumber) === (i + 1) ? this.activeStyle : {}}
                onClick={() => { this.handlePageNumberClick(i + 1, this.state.activeBar); }}
            >
                {i + 1}
            </div>);
        }
        return pageNumberContainer;
    }

    render() {
        this.handleState();
        const totalPages = Math.ceil(this.props.totalCount || 0 / 20);
        return <div className={`pagination-container`}>
            {this.state.showLoader && <SubmissionLoader /> }
            <div className="bar-container" >
                {totalPages > 10 &&
                    <div
                        className="start"
                        onClick={() =>
                            this.setState({ activeBar: this.state.activeBar === 1 ? 1 : this.state.activeBar - 10 })}
                    >
                        <i className="left" />1
                </div>}
                {this.renderPageNumbers(this.state.activeBar)}
                {totalPages > 10 &&
                    < div
                        className="end"
                        onClick={() =>
                            this.setState({ activeBar: Number(this.state.activeBar) + 10 })}
                    >
                        <i className="right" />{totalPages}</div>
                }
            </div>
        </div >;
    }
}
