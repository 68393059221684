import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { IncidentReportForm } from './IncidentReportForm'; 
/* import { InvestigationForm } from './InvestigationForm';  */

export interface ICreateFormProps {
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, {}> {
    constructor(props: ICreateFormProps | Readonly<ICreateFormProps>) {
        super(props);
    }

    render() {
        return (
            <BasePage className="safeconnect-form" >
                <Grid className="incident-report-form" style={{ width: '100%' }}>
                    <Title titleColor="yellow" text="Incident Investigative Form" noNeedBorder={true} />
                    <Alert className="danger-alert" id="incident-report" />
                    <AlertModal id="incident-report-modal" />
                    <IncidentReportForm />
                    {/* {<InvestigationForm/>} */}
                    <Alert className="danger-alert" id="incident-report" />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps() {
    return {
    };
}

export const CreateForm = connect<{}, {}, ICreateFormProps>(mapStateToProps)(CreateFormImpl);
