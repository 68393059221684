
import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';  
import { UserModel } from '../../model/UserModel';
import { IHistory, IForms } from '../../interfaces';
import { ListPage } from '../reusableComponents/ListPage'; 
import { ToolBoxMeetingModel } from '../../model/ToolBoxMeetingModel';
import { getAllTSMForms } from '../../services/tsmService';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ListItem } from './ListItem';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import { Pagination } from '../reusableComponents/Pagination';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess} from '../../actions/loadingActions'; 

declare var require: any;
const queryString = require('query-string');

export interface ITSMFormListPageProps {
    instances: ToolBoxMeetingModel[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
    totalPageCount?: number;
    forms: IForms;
    axiosPreviousToken: any;
}

export interface ITSMFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class TSMFormListPageImpl extends React.PureComponent<ITSMFormListPageProps, ITSMFormListPageState> {
    constructor(props: ITSMFormListPageProps | Readonly<ITSMFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'locationName', 2:'projectName', 3: 'groupName', 4: 'taskToPerform' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];
        return this.getReportData(urlParams.sortingIndex);
    }
    
    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        urlParams.sortIndexField = this.sortListing[sortIndex];
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllTSMForms( urlParams, this.props.forms );
        setSuccess('AllListPage'); 
    }

    renderFormList = () => {
        const { instances } = this.props; 
        return ( 
            <div className="jsa-list-wrapper">  
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
                {<ExcelExport formType="tsmForm" fileName="TSM Report" />}
                <> <ListPage
                        pageHeading={'TSM Reports'}
                        instances={instances}
                        rowHeadings={['Date Submitted','Location', 'Team', 'Group', 'Task']}
                        listItemComponent={ListItem}
                        emptyInstancesMessage="No TSM reports found."
                        isSearchable={true}
                        searchableBy={['taskToPerform', 'username', 'date', 'groupName', 'projectName','locationName']}
                        searchPlaceHolder="Enter team, group, task, task coordinator to search..."
                        sm={[2, 2, 2, 2, 3]}
                        currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                        rowSortings={this.sortListing} 
                        promise={(sortIndex) => this.getReportData(sortIndex)}
                        forms={this.props.forms}
                        history={this.props.history }
                    />
                    { UserModel.checkUserAccess('tsm_add') && this.renderAddNewFormButton() }
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.promise(pageNumber)}
                        totalCount={this.props.totalPageCount}
                        Model={ToolBoxMeetingModel as any} 
                        identifier="AllListPage"
                    />
                </>
            </div>
                 
        );
    }

    renderAddNewFormButton = () => { 
        return (<button className="add-new-report"
            onClick={() => { this.props.history?.push('/tool-box-meeting/create'); }}
        >
            +
        </button>
        );
    }

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="TSMListPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderFormList()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const instances = ToolBoxMeetingModel.list(state); 
    return { instances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const TSMFormListPage = withRouter(connect< ITSMFormListPageProps, any, any>(mapStateToProps)(TSMFormListPageImpl));
