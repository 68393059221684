import * as React from 'react'; 
import { connect } from 'react-redux';
import { showMicrophoneModal } from '../../../actions/modalActions'; 
import { Label } from '../FormComponents/Label';

export interface IVoiceRecognitionProps { 
    id?: any;
    model?: any;
    defaultValue?: any;
    labelText?: any;
    required?: boolean;
}

export class VoiceRecognitionImpl extends React.Component<IVoiceRecognitionProps, {}> {
    constructor(props: IVoiceRecognitionProps | Readonly<IVoiceRecognitionProps>) {
        super(props);
    } 

    render() { 
        const { defaultValue, model, labelText, required } = this.props; 
        let fieldName = this.props.model?.split('.')[2];
        return (
            <div className={`form-title-container ${(!labelText && 'no-padding') || ''}`}>
                {labelText && <Label sm={12} htmlFor={fieldName} className='form-title-label' required={required}>{labelText}: </Label> }
                <div className='form-title-mic' onClick={() => showMicrophoneModal(model, (defaultValue || '') ) }>
                    <i className="fa fa-microphone" ></i>
                </div>
            </div>
        );
    }
}

export function mapStateToProps() {
    return {
    };
} 
export const VoiceRecognition = connect<{}, {}, IVoiceRecognitionProps>(mapStateToProps)(VoiceRecognitionImpl);