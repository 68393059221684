import { BaseModel } from './BaseModel'; 

export interface IMechModelProps {
    id?: string;
    key?: string; 
    mechImages?: { name: string; image: string; }[];
    imageUrls?: string[];
    mechVideos?: { name: string; file: string;}[];
    videoUrls?: string[];
    eventtype?: string;
    eventlocation?: string;
    facility?: string;
    involvedWorkers?: string;
    milepost?: string;
    nearstation?: string;
    phone?: string;
    hours?: string;
    carrier?: string;
    state?: string;
    Name?: string;
    date?: string;
    time?: string;
    address?: string;
    city?: string;
    stateString?: string;
    zip?: string;
    reporter?: string;
    reporterOther?: string;
    certification?: string;
    activity?: string;
    activityOther?: string;
    railroadyears?: string;
    yearscraft?: string;
    groupsize?: string;
    shiftEvent?: string;
    otherShiftEvent?: string;
    reporterLocation?: string;
    otherReporterLocation?: string;
    weather?: string;
    outdoors?: string;
    workarea?: string;
    reducedVisibility?: string;
    reducedVisibilityDetail?: string;
    totalhead?: string;
    locomotive?: string;
    remoteControl?: string;
    distributedPower?: string;
    positionInTrain?: string;
    noofcars?: string;
    noinservice?: string;
    carCabControlling?: string;
    loads?: string;
    empties?: string;
    tons?: string;
    length?: string;
    recordsComplete?: string;
    serviceReleased?: string;
    requiredDocuments?: string;
    movingForRepair?: string;
    maintenceDeferred?: string;
    type?: string;
    otherType?: string;
    involvedcar?: string;
    location?: string;
    operatingRules?: string;
    operatingRulesOthers?: string;
    descriveEventSituation?: string; 
    deletedImages?: string[];
    deletedVideos?: string[];
    editId?: string; 
    createdDate?: any;
} 
export interface closeCallListProps {
    id?: string | undefined;
    /* CloseCall Lising */
    company?: string;
    formType?: string;
    userName?: string;
    date?: string;
    createdDate?: string;
    time?: string;
    userId?: string;
    formId?: string;
    uniqueId?: string;  
    subAttributeList?: Array<any>;   
    imageUrls?: string[];  
    videoUrls?: string[];  
    followUpStatus?: string;
    totalFollowUp?: number;
    totalOpenFollowUp?: number;
    totalClosedFollowUp?: number;
    projectName?: string;
    groupName?: string;
} 
export class closeCallListModel extends BaseModel<closeCallListProps> {
    constructor(props: closeCallListProps) {
        super(props, closeCallListModel.resource);
    }
    static resource = 'close-call-checklist-from';
}
export interface closeCallDetailProps {
    /* CloseCall Details */
    company?: string;
    formType?: string;
    userName?: string;
    date?: string;
    time?: string;
    userId?: string;
    formId?: string;
    uniqueId?: string;  
    imageUrls?: string[]; 
    videoUrls?: string[]; 
    subAttributeList?: Array<any>;
    answers?: string;
    projectName?: string;
    groupName?: string;
    overAllComment?: string;
}
export interface closeCallSummaryProps {
    yearlyCount?: any;
    yearlySum?: any;
    monthlyCount?: any;
    monthlySum?: any;
    managerCheckListPercentage?: any;
    supervisorCheckListPercentage?: any;
    reportCountData?: any;
    reportSumData?: any;
    averageReportData?: any;
}
export class MechModel extends BaseModel<IMechModelProps> {
    constructor(props: IMechModelProps) {
        super(props);
    }
    static resource = 'mech-from';
}

export interface closeCallCheckListProps {
    Company?: any; 
    Date?: any; 
    FormType?: any; 
    Time?: any; 
    UniqueId?: any; 
    UserId?: any; 
    UserName?: any; 
    id?: any; 
    totalCloseCallCount?: number;
    totalCloseCallOpenCount?: number;
}
export class CloseCallCheckListModel extends BaseModel<closeCallCheckListProps> {
    constructor(props: closeCallCheckListProps) {
        super(props, CloseCallCheckListModel.resource);
    }
    static resource = 'close-call-checklist-from';
}

export class followUpListProps {
    key?: any;
    id?: any;
    description?: any;
    action?: any;
    company?: any;
    targetCompletion?: any;
    actualCompletion?: any;
    assignedTo?: any;
    assignedToName?: any;
    comments?: any;
    status?: any; 
    imageUrls?: string[]; 
    closeCallId?: any;
    followUpId?: any;
    createdDate?: any;
    createdBy?: any;
    assignedByName?: any;
}
 


export interface FollowUpParentDetails {
    closeCallId?: any;
    process?: any; 
    requestFrom?: any; 
}

export class FollowUpModel extends BaseModel<followUpListProps> {
    constructor(props: followUpListProps) {
        super(props, FollowUpModel.resource);
    }
    static resource = 'follow-up-from';
}