import '../../forms.scss'; 
import './hpForm.scss';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';

import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IForms, IHistory } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { IHPModelProps, HPModel } from '../../model/HPModel';
import { ProjectModel } from '../../model/ProjectModel';
import { UserModel } from '../../model/UserModel';
import { getAllGroups } from '../../services/groupService'; 
import { getAllProjects } from '../../services/projectService'; 
import { submitHPForm } from '../../services/hpFormService';
import { constructAnswers, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';  
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IHPFormProps {
    userInstance: UserModel;
    userUID: string;
    userId: string; 
    userName: string;
    company: string;
    history?: IHistory; 
    forms: IForms; 
    editInstance?:HPModel;
    editId?: string;
}

export interface IHPFormState {
    currentDate: string;
    currentTime: string;
    observer?: string;
    isDisabled: boolean;
    isChecked: boolean; 
    overallScore: number;
}

export class HPFormImpl extends React.PureComponent<IHPFormProps, IHPFormState> { 
    constructor(props: IHPFormProps | Readonly<IHPFormProps>) {
        super(props);
        this.state = { currentDate: format(new Date(), 'yyyy-MM-dd'), isDisabled: true, isChecked: false, currentTime: format(new Date(), 'HH:mm'), overallScore: 0 };
    }

    promise = async () => {
        const { editInstance } = this.props;
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } 
        if(editInstance && editInstance.props.answers.totalScore) {
            this.setState({overallScore: editInstance.props.answers.totalScore})
        }
        return;
    } 

    getChildContext() {
        return { formModel: 'forms.hpForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    handleFormSubmit = ({ groupName, mobileLocation, locationName, projectName, dateOfIncident, comments, fitandable, planandexecute, teamcapable, noexternaldistractions }: IHPModelProps) => {  
        const { props: { userUID, forms, history, editId } } = this;  
        const answerData = { dateOfIncident,  locationString: mobileLocation, specifiedLocation: locationName, fitandable, planandexecute, teamcapable, noexternaldistractions, comments, totalScore: this.state.overallScore, overallResult: this.state.overallScore>5?'safe': 'risk' };
        const submittedValues = { dateOfIncident, locationName, projectName, groupName,  }; 
        
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'hp-form-failed', 'danger');
            return;
        }
        if (isFormEmpty({fitandable, planandexecute, teamcapable, noexternaldistractions})) {
            showAlert('Please answer for all checklist', 'hp-form-failed', 'danger');
            return;
        }
        let answers = constructAnswers(answerData); 
        return history && submitHPForm(userUID, dateOfIncident || '', answers, history, groupName || '', projectName || '', forms, answerData['overallResult'], editId); 
    } 
    renderContent = () => {
        const { editInstance, editId, history, forms } = this.props;
        const { handleFormSubmit, 
            state: { currentDate, }, } = this;   
        if(editId && editInstance===undefined ) {
            history?.push('/hp-reports');
        } 
        return (
            <Grid className="hp-form" style={{ width: '100%' }}>
                <MapInputModal id="hp-form" />
                <Form loader={<SubmissionLoader />} model="forms.hpForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="HP Form" noNeedBorder={true}/>
                    <AlertModal id="hp-modal" /> 
                    <fieldset>
                        <legend>Human Performance Checklist</legend>
                        <Row className="show-grid" >
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="name">Name:</Label>
                                <Col sm={12} className="input">
                                <Label sm={12} htmlFor="name" style={{color: 'white'}}>{(editInstance && editInstance.props.username) || (this.props.userName)} </Label>
                                    {/* <RRFInput
                                        id="name"
                                        type="text"
                                        defaultValue={(editInstance && editInstance.props.username) || () }
                                        model=".name" 
                                        disabled={true}
                                    /> */}
                                </Col>
                            </Col> 
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="dateOfIncident">Date:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="dateOfIncident"
                                        type="date"
                                        defaultValue={editInstance && editInstance.props.dateOfIncident? format(new Date(editInstance.props.dateOfIncident), 'yyyy-MM-dd'): currentDate}
                                        model=".dateOfIncident"
                                        maxdate={editInstance? format(new Date(editInstance.props.date), 'yyyy-MM-dd'):currentDate} 
                                    />
                                </Col>
                            </Col> 
                            <LocationInput  
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="hp-form"
                                navigator={navigator}
                                defaultValue={editInstance && editInstance.props.answers?.specifiedLocation}
                            />
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={editInstance ? editInstance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} />
                            </Col>
                            <Col sm={4} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={editInstance ? editInstance.props.groupName : this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]} />
                            </Col>  
                        </Row> 
                        <Row className="show-grid hp-checklist">
                            <Row className="show-grid heading">
                                <Col sm={1} xs={12}>S.No</Col>
                                <Col sm={8} xs={12}>Checklist</Col>
                                <Col sm={3} xs={12}> Options </Col>
                            </Row>
                            <Row className="show-grid checklists">
                                <Col sm={1} xs={12} className="sno">1</Col>
                                <Col sm={8} xs={12}>I feel fit and able for today’s work</Col>
                                <Col sm={3} xs={12} className="options"><RadioButton labelSm={6} radioSm={6} id="fitandable" defaultValue={editInstance && editInstance.props.answers?.fitandable} onChange={this.onChangeScore}> </RadioButton></Col>
                            </Row>
                            <Row className="show-grid checklists">
                                <Col sm={1} xs={12} className="sno">2</Col>
                                <Col sm={8} xs={12}>I know my job and confident to safely plan & execute</Col>
                                <Col sm={3} xs={12} className="options"><RadioButton labelSm={6} radioSm={6} id="planandexecute" defaultValue={editInstance && editInstance.props.answers?.planandexecute} onChange={this.onChangeScore}> </RadioButton></Col>
                            </Row>
                            <Row className="show-grid checklists">
                                <Col sm={1} xs={12} className="sno">3</Col>
                                <Col sm={8} xs={12}>I know my team and they are capable for the job</Col>
                                <Col sm={3} xs={12} className="options"><RadioButton labelSm={6} radioSm={6} id="teamcapable" defaultValue={editInstance && editInstance.props.answers?.teamcapable} onChange={this.onChangeScore}> </RadioButton></Col>
                            </Row>
                            <Row className="show-grid checklists">
                                <Col sm={1} xs={12} className="sno">4</Col>
                                <Col sm={8} xs={12}>I have no external distractions and am able to work</Col>
                                <Col sm={3} xs={12} className="options"><RadioButton labelSm={6} radioSm={6} id="noexternaldistractions" defaultValue={editInstance && editInstance.props.answers?.noexternaldistractions} onChange={this.onChangeScore}> </RadioButton></Col>
                            </Row>
                            <Row className="show-grid checklists">
                                <Col sm={9} xs={12} className="overallscorelabel">Overall Score</Col>
                                <Col sm={3} xs={12} className="overallscore">{this.state.overallScore===0?'NILL': (this.state.overallScore>5? <label htmlFor={`2`} className="checklist-result green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>:<label htmlFor={`1`} className="checklist-result red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label> )}</Col> 
                            </Row>
                        </Row>
                        <Row className="show-grid" >
                            <Col sm={12} xs={12}>
                                <Label sm={12} htmlFor="name">Comments:</Label> 
                                <VoiceRecognition labelText="" defaultValue={forms.hpForm.comments} model="forms.hpForm.comments" />
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="comments"
                                        type="textarea"
                                        defaultValue={editInstance && editInstance.props.answers.comments }
                                        model=".comments" 
                                    />
                                </Col>
                            </Col> 
                        </Row>

                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="hp-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { editInstance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    } 
    onChangeScore = () => {
        const { forms } = this.props;
        let totalScore = (parseInt(forms.hpForm.teamcapable ) || 0) + (parseInt(forms.hpForm.fitandable) || 0)+ (parseInt(forms.hpForm.noexternaldistractions ) || 0) + (parseInt(forms.hpForm.planandexecute ) || 0); 
        this.setState({overallScore: totalScore});
    }
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="HPForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
} 
const RadioButton = ({ labelSm, radioSm, id, defaultValue, children, onChange }: any ) => { 
    return <div> 
        <div className="work-related radio-options">
            <div style={radioContainer} > 
                <RRFInput
                    id={`${id}2`}
                    type="radio"
                    model={`.${id}`}
                    radioValue="2"
                    radioName={`${id}`} 
                    defaultValue={defaultValue && defaultValue==='2' ? defaultValue : null } 
                    onChange={onChange}
                />
                <label htmlFor={`${id}2`} className="radio-option green"> <i className="fa fa-smile-o" aria-hidden="true"></i> </label>
                <label style={radioButton} htmlFor={`${id}2`}></label>
            </div>
            <div style={radioContainer} >
                <RRFInput
                    id={`${id}1`}
                    type="radio"
                    model={`.${id}`}
                    radioValue="1"
                    radioName={`${id}`} 
                    defaultValue={defaultValue && defaultValue==='1' ? defaultValue : null } 
                    onChange={onChange}
                /> 
                <label htmlFor={`${id}1`} className="radio-option red"> <i className="fa fa-frown-o" aria-hidden="true"></i> </label>
                <label style={radioButton} htmlFor={`${id}1`}></label>
            </div>
        </div>
    </div>;
}; 

const radioButton: React.CSSProperties = {
    verticalAlign: 'middle',
    margin: '0',
    marginLeft: '10px'
};

const radioContainer: React.CSSProperties = {
    display: 'inline-block',
    padding: '0 10px'
};

export function mapStateToProps(state: any,ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    const userInstance = UserModel.get(userId);
    const editId = ownProps.match.params.id;
    const editInstance = HPModel.get(editId); 
    const userName = userInstance && (userInstance.props.firstName + ' ' +  userInstance.props.lastName)
    return { userId, userInstance, userUID, userName, company, forms: state.forms, editInstance, editId };
} 
export const HPForm = withRouter(connect<IHPFormProps, any, any>(mapStateToProps)(HPFormImpl));
