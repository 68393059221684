import {getCustom, post, put} from '../utils/HTTP';
import {IPersonalSafetyModelProps, PersonalSafetyModel} from '../model/PersonalSafetyModel';
import {UserModel} from '../model/UserModel';
import {IAnswer, IForms, IHistory} from '../interfaces';
import {format} from 'date-fns';
import {Answers} from '../utils/Answers';
import {showAlert} from '../utils/generalUtils';
import {FORM_SUBMISSION_ERROR} from '../constants/general'; 
import {saveListCount} from '../actions/miscellaneousActions';

export async function getAllPersonalSafetyForms(urlData?: any, forms?: IForms, formType: string = 'othersPersonalSafety') {
    try {
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let userUID = UserModel.getUserUID(); 
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        let data = { ...forms?.commonFilterForm, pageNumber, sortIndexField, sortingOrder, uuid: userUID, formType, formStage: 'all', searchItem }; 
        const response = await post('/GetPersonalSafetyPlans', data, axiosPreviousToken);  
        if (response.data.status === 'valid') { 
            PersonalSafetyModel.deleteAll(); 
            (response.data.plans || []).forEach((formInstance: IPersonalSafetyModelProps) => {
                formInstance.id = formInstance.uniqueId; 
                new PersonalSafetyModel(formInstance).$save();
            });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

/* export async function getPersonalSafetyFormByUserUID(userUID: string, companyName: string, pageNumber: number = 1) {
    try { 
        PersonalSafetyModel.deleteAll();
        const response = await getCustom(`/GetPersonalSafetyPlans/${userUID}/${companyName}/all/na/${pageNumber}`);
        response
            .data
            .plans
            .forEach((formInstance: IPersonalSafetyModelProps) => {
                formInstance.id = formInstance.uniqueId;
                new PersonalSafetyModel(formInstance).$save();
            });
        saveListCount(response.data.TotalPages);
    } catch (error) {
        throw error;
    }
} */

export async function submitPersonalSafetyForm(userInstance: UserModel, groupName: string, projectName: string, answers: IAnswer[], personalSafetyImages: { name: string, image: string }[], personalSafetyVideos: { name: string, file: string }[], history?: IHistory, forms?: IForms, editId?: string, formInstance ?: PersonalSafetyModel) {
    const body: any = {
        userId: userInstance.props.userUID,
        company: userInstance.props.company,
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        groupName,
        projectName,
        formId: 'personalSafety',
        actionType: 'pending',
        associatedFormId: '',
        followUp: editId && formInstance && formInstance.props.followUp === 0 ? 0 : 1,
        /* imageUrls: [],
        videoUrls: [], */
        answers,
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: personalSafetyImages || [],
        videoData: personalSafetyVideos || []
    };  
    
    try { 
        body.deletedImages = (forms && forms.personalSafetyForm.deletedImages) || [];
        body.deletedVideos = (forms && forms.personalSafetyForm.deletedVideos) || [];
        body.editId = editId;  
        const response = body.editId ? await post('/EditForm', body) : await post('/SubmitForm', body);
        //const response = await post('/SubmitForm', body);
        if (JSON.stringify(response.data).toString().toLowerCase().indexOf('error') > -1) {
            showAlert(FORM_SUBMISSION_ERROR, 'personal-form', 'danger');
            return;
        }
        //await userInstance && userInstance.props.company && getAllPersonalSafetyForms(userInstance.props.company);
        showAlert(response.data.message, 'personal-form-modal', 'success', () => {
            history?.push('/home');
        });
        return response;

    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'personal-form', 'danger');
        throw error;
    }
}

export async function updatePersonalSafetyForm(userInstance: UserModel, groupName: string,
                                               projectName: string, answers: IAnswer[], formInstance: PersonalSafetyModel, history?: IHistory) {
    const body = {
        company: userInstance.props.company,
        uuid: formInstance.props.id,
        formId: 'personalSafety',
        answers
    };
    try {
        const response = await put('/SubmitForm', body);
        if (JSON.stringify(response.data).toString().toLowerCase().indexOf('error') > -1) {
            showAlert(FORM_SUBMISSION_ERROR, 'personal-form', 'danger');
            return;
        }
        //await userInstance && userInstance.props.company && getAllPersonalSafetyForms(userInstance.props.company);
        showAlert(response.data.message, 'personal-form-modal', 'success', () => {
            history?.push('/home');
        });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'personal-form', 'danger');
        throw error;
    }
}

export async function getPersonalSafetyFormDetails(formId: string) {
    try {
        const company = UserModel.getCompanyName();
        //const response = await getCustom(`/GetForm/${company}/${uniqueId}`); 
        //const response = await getCustom(`/GetForm/${uniqueId}`); 
        const response = await getCustom(`GetForms/personalSafety/${company}/${formId}`); 
        if(response.data!=='No Data Found') {
            const formData = marshalInstance(response.data.data[0]);
            formData.id = formData.key;
            new PersonalSafetyModel(formData).$save();
            return formData;
        } else return [];
    } catch (error) {
        throw error;
    }
}

export function marshalInstance(data: any) { 
    const ans = new Answers(data.answers);  
    const obj = {
        credebilityHelp: ans.get(1),
        credibilityDate: ans.get(2),
        credebilityComment: ans.get(3),
        actionHelp: ans.get(4),
        actionDate: ans.get(5),
        actionComment: ans.get(6),
        resilienceHelp: ans.get(7),
        resilienceDate: ans.get(8),
        resilienceComment: ans.get(9),
        engagementHelp: ans.get(10),
        engagementDate: ans.get(11),
        engagementComment: ans.get(12),
        considerationsHelp: ans.get(13),
        considerationsDate: ans.get(14),
        considerationsComment: ans.get(15),
        supervisor: ans.get(16)
    };
    delete data.answers;
    Object.assign(data, obj);
    return data;
} 

export async function checkFormSubmitted(userUID: string, companyName: string) {
    try {
        const response = await getCustom(`/GetPersonalSafetyPlans/${userUID}/${companyName}/all`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
