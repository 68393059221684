import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { getAllUsers } from '../../services/userService';
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';
import { UserModel } from '../../model/UserModel';
import { Async } from '../reusableComponents/Async';

export interface ISecurityFormProps {
}

export interface ISecurityFormState {
    isAgencyNotified: boolean;
    isCostIncurred: boolean;
}

export class SecurityForm extends React.PureComponent<ISecurityFormProps, ISecurityFormState> {
    constructor(props: ISecurityFormProps | Readonly<ISecurityFormProps>) {
        super(props);
        this.state = { isCostIncurred: false, isAgencyNotified: false };
    }

    reportedToAgencyOptions: any = [];

    promise = async () => {
        let users = UserModel.list();
        if (users.length <= 1) {
            await getAllUsers();
            users = UserModel.list();
        }
        users.forEach((user: any) => {
            this.reportedToAgencyOptions.push({ label: user.props.firstName, value: user.props.userId.toString() });
        });
    }

    handleAgencyNotifiedChange = (value: boolean) => {
        this.setState({
            isAgencyNotified: value
        });
    }

    handleCostIncurredChange = (value: boolean) => {
        this.setState({
            isCostIncurred: value
        });
    }

    renderAgencyNotificationDetails = () => {
        return <Row className="show-grid">
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="agencyName">Agency Name:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="agencyName"
                        type="text"
                        model=".agencyName"
                        placeholder="Enter agency name..."
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="dateReportedToAgency">Date Reported to Agency:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="dateReportedToAgency"
                        type="date"
                        model=".dateReportedToAgency"
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="reportedToAgencyBy">Reported to Agency By:</Label>
                <Col sm={12} className="input">
                    <Async
                        identifier="SecurityForm"
                        promise={this.promise}
                        loader={<RRFInput
                            id="reportedToAgencyBy"
                            type="dropdown"
                            placeholder="Loading..."
                            searchable={true}
                            model=".reportedToAgencyBy"
                        />
                        }
                        error={<RRFInput
                            id="reportedToAgencyBy"
                            type="dropdown"
                            placeholder="Loading..."
                            searchable={true}
                            model=".reportedToAgencyBy"
                        />}
                        content={
                            <RRFInput
                                id="reportedToAgencyBy"
                                type="dropdown"
                                menuItems={this.reportedToAgencyOptions}
                                placeholder="Enter reported to agency by..."
                                searchable={true}
                                model=".reportedToAgencyBy"
                            />
                        }
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="notifiedAtAgency">Name of Person Notified at Agency:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="notifiedAtAgency"
                        type="text"
                        model=".notifiedAtAgency"
                        placeholder="Enter name of person notified at agency..."
                    />
                </Col>
            </Col>
        </Row>;
    }

    renderCostIncurredDetails = () => {
        return <Row className="show-grid">
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="totalCost">Total Cost US $:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="totalCost"
                        type="number"
                        model=".totalCost"
                        placeholder="Enter total cost in USD..."
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="emOwnershipPercentage">Ownership Percentage:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="emOwnershipPercentage"
                        type="number"
                        model=".emOwnershipPercentage"
                        placeholder="Enter ownership percentage..."
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="costType">Cost Type:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="costType"
                        type="text"
                        model=".costType"
                        placeholder="Enter cost type.."
                    />
                </Col>
            </Col>
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="costCategory">Cost Category:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        id="costCategory"
                        type="text"
                        model=".costCategory"
                        placeholder="Enter cost category.."
                    />
                </Col>
            </Col>
        </Row>;
    }

    render() {
        const { isAgencyNotified, isCostIncurred } = this.state;
        return (
            <div className="">
                <fieldset className="">
                    <legend>Security Form</legend>
                    <Row className="show-grid">
                        <Col sm={6} xs={12}>
                            <Label sm={12} htmlFor="securityCategory">Security Category:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="securityCategory"
                                    type="dropdown"
                                    menuItems={[
                                        { label: 'Reportable', value: 'reportable' },
                                        { label: 'Non-Reportable', value: 'nonReportable' },
                                    ]}
                                    model=".securityCategory"
                                    placeholder="Select security category..."
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label sm={12} htmlFor="methodOfBreach">Method of Breach:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="methodOfBreach"
                                    type="text"
                                    model=".methodOfBreach"
                                    placeholder="Enter method of breach..."
                                />
                            </Col>
                        </Col>
                    </Row>
                    {/* <label htmlFor="agencyNotified">Yes</label>
                    <RRFInput
                        id="agencyNotifiedYes"
                        type="radio"
                        model=".agencyNotified"
                        radioValue="yes"
                        radioName="agencyNotified"
                    />
                    <label htmlFor="agencyNotifiedYes"></label>
                    <label htmlFor="agencyNotified">No</label>
                    <RRFInput
                        id="agencyNotifiedNo"
                        type="radio"
                        model=".agencyNotified"
                        radioValue="no"
                        radioName="agencyNotified"
                    />
                    <label htmlFor="agencyNotifiedNo"></label> */}
                    <Row className="show-grid checkbox-row">
                        <Col sm={4}>
                            <label className="checkbox-label" htmlFor="agencyNotified">Agency Notified:</label>
                            <RRFInput
                                type="checkbox"
                                id="agencyNotified"
                                model=".agencyNotified"
                                onChange={this.handleAgencyNotifiedChange}
                            />
                        </Col>
                        <Col sm={4} className="checkbox-center">
                            <label className="checkbox-label" htmlFor="costIncurred">Cost Incurred?:</label>
                            <RRFInput
                                type="checkbox"
                                id="costIncurred"
                                model=".costIncurred"
                                onChange={this.handleCostIncurredChange}
                            />
                        </Col>
                        <Col sm={4}>
                            <label className="checkbox-label" htmlFor="8010Required">Mgt. Report Required?:</label>
                            <RRFInput
                                id="8010Required"
                                type="checkbox"
                                model=".8010Required"
                            />
                        </Col>
                    </Row>
                    {isAgencyNotified ? this.renderAgencyNotificationDetails() : null}
                    <Row className="show-grid">

                        {/* <Col sm={6} className="work-related">
                        <label htmlFor="constIncurred">Yes</label>
                        <RRFInput
                            id="constIncurredYes"
                            type="radio"
                            model=".constIncurred"
                            radioValue="yes"
                            radioName="constIncurred"
                        /><label htmlFor="constIncurredYes"></label>
                        <label htmlFor="constIncurred">No</label>
                        <RRFInput
                            radioValue="no"
                            id="constIncurredNo"
                            type="radio"
                            model=".constIncurred"
                            radioName="constIncurred"
                        />
                        <label htmlFor="constIncurredNo"></label>
                    </Col> */}
                    </Row>
                    {isCostIncurred ? this.renderCostIncurredDetails() : null}
                    <Row className="show-grid">

                        {/* <Col sm={6} className="work-related">
                        <label htmlFor="8010Required">Yes</label>
                        <RRFInput
                            id="8010RequiredYes"
                            type="radio"
                            model=".8010Required"
                            radioValue="yes"
                            radioName="8010Required"
                        />
                        <label htmlFor="8010RequiredYes"></label>
                        <label htmlFor="8010Required">No</label>
                        <RRFInput
                            radioValue="no"
                            id="8010RequiredNo"
                            type="radio"
                            model=".8010Required"
                            radioName="8010Required"
                        />
                        <label htmlFor="8010RequiredNo"></label>
                    </Col> */}
                    </Row>
                </fieldset>
            </div >
        );
    }
}
