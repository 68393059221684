import React, {Component} from "react";
import {BasePage} from "../../reusableComponents/BasePage";
import {Async} from "../../reusableComponents/Async";
import {ErrorPage} from "../../reusableComponents/ErrorPage";
import {Loader} from "../../reusableComponents/Loader";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Form} from "../../reusableComponents/FormComponents/Form";
import {SubmissionLoader} from "../../reusableComponents/SubmissionLoader";
import {Title} from "../../reusableComponents/Title";
import {Col, Row} from "react-bootstrap";
import {Label} from "../../reusableComponents/FormComponents/Label";
import {RRFInput} from "../../reusableComponents/FormComponents/RRFInput";
import '../PreExcavationCheckListForm/preExcavationChecklist.scss';
import {AttachementInput} from "../../reusableComponents/FormComponents/AttachementInput";
import Select from 'react-select';
import * as displayText from './language.json'
import PropTypes from "prop-types";
import {Button} from "../../reusableComponents/FormComponents/Button";
import {Alert} from "../../Alert";
import {IForms, IHistory} from "../../../interfaces";
import {IUtilityDamageReportModelProps, UtilityDamageReportModel} from "../../../model/UtilityDamageReportModel";
import {dispatch, isFormEmpty, showAlert} from "../../../utils/generalUtils";
import {EMPTY_FORM_MESSAGE} from "../../../constants/general";
import {actions} from "react-redux-form";
import {getFormData, submitTexas11Forms} from "../../../services/texas811Service";
import {AlertModal} from "../../reusableComponents/ModalComponents/AlertModal";
import {format} from "date-fns";
import {PlantLocationMap} from "../../reusableComponents/GoogleMapsWithDrawing/GoogleMapsWithDrawing";
import { VoiceRecognition } from '../../reusableComponents/VoiceRecognition/VoiceRecognition';

interface IUtilityDamageReportProps {
    editId: string;
    history?: IHistory;
    forms: IForms;
    editInstance: UtilityDamageReportModel
}

interface IUtilityDamageReportState {
    displayText: Record<any, any>;
    isLocationAddressChanged: boolean;
    language: string;
}

export class UtilityDamageReportImpl extends Component<IUtilityDamageReportProps, IUtilityDamageReportState> {

    shapesList: Array<any> | undefined = [];
    outfallsList: Array<any> | undefined = [];
    coordinatesPointerList: Array<any> | undefined = [];
    coords: { lat: number, lang: number } | null = null

    constructor(props: IUtilityDamageReportProps) {
        super(props);
        this.state = {
            displayText: !props.editInstance ? displayText.eng : props.editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
            language: !props.editInstance ? 'eng' : props.editInstance.props.language === 'spa' ? 'spa' : 'eng',
            isLocationAddressChanged: false,
        }
    }

    getChildContext() {
        return {formModel: 'forms.utilityDamageReport'};
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    applyDefaultValues = async () => {
        await setTimeout(() => {
            const {editInstance}: any = this.props; 
            if (editInstance) {
                this.shapesList = this.props.editInstance.props.shapesList;
                this.outfallsList = this.props.editInstance.props.outfallsList;
                this.coordinatesPointerList = this.props.editInstance.props.coordinatesPointerList;
                this.coords = this.props.editInstance.props.coords;
                this.setState({
                    displayText: !editInstance ? displayText.eng : editInstance.props.language === 'spa' ? displayText.spa : displayText.eng,
                    language: !editInstance ? 'eng' : editInstance.props.language === 'spa' ? 'spa' : 'eng'
                })
            }
        }, 100);
    }

    promise = async () => {
        if (this.props.editId && !this.props.editInstance) {
            await getFormData('utilityDamageReport', this.props.editId).then(() => {
                this.applyDefaultValues();
            });
        } else {
            await this.applyDefaultValues();
        }
    }

    onChangeLanguage = (lang: any) => {
        this.setState({
            displayText: lang.value === 'spa' ? displayText.spa : displayText.eng,
            language: lang.value
        })
    }

    onOneCallCenterNotifiedChange = () => {
        dispatch(actions.change(`forms.utilityDamageReport.locateTicketNumber`, ''));
    }

    onOtherWorkTypeSelected = () => {
        dispatch(actions.change(`forms.utilityDamageReport.otherWorkType`, ''));
    }

    onFormSubmit = async (values: IUtilityDamageReportModelProps) => {
        const ignoreList = ['attachments', 'id', 'editId', 'deletedFiles']
        if (!values.isOtherWork) {
            ignoreList.push('otherWorkType')
        }

        if (values.isOneCallCenterNotified !== 'true') {
            ignoreList.push('locateTicketNumber')
        }
        if (isFormEmpty(values, ignoreList)) {
            showAlert(EMPTY_FORM_MESSAGE, 'utility-damage-report-form', 'danger');
            return;
        }

        const valuesToSave = JSON.parse(JSON.stringify(values))

        delete valuesToSave['attachments'];
        delete valuesToSave['deletedFiles'];

        const mapObjs = {
            'coords': JSON.stringify(this.coords),
            'shapesList': JSON.stringify(this.shapesList),
            'outfallsList': JSON.stringify(this.outfallsList), 
            'coordinatesPointerList': JSON.stringify(this.coordinatesPointerList),
        }

        await submitTexas11Forms(valuesToSave, 'utilityDamageReport', this.state.language, this.props.history, this.props.forms, this.props.editId, mapObjs);
    }

    setMapCoordsData = (coords) => {
        this.coords = coords;
    }

    updateOutfallsList = (outfallsList) => {
        this.outfallsList = outfallsList;
    }

    updateShapesList = (shapesList) => {
        this.shapesList = shapesList;
    }

    updateCoordinatesPointerList = (coordinatesPointerList) => {
        this.coordinatesPointerList = coordinatesPointerList;
    }


    updateAddressChangeStatus = (status) => {
        if (status !== this.state.isLocationAddressChanged)
            this.setState({
                isLocationAddressChanged: status
            });
    }

    renderContent = () => {
        const {displayText, language} = this.state;
        const {editInstance, history, forms} = this.props;
        let answer: any = {};
        if (editInstance && editInstance.props && editInstance.props.answers) {
            answer = editInstance.props.answers;
        }
        return <Form loader={<SubmissionLoader/>} model="forms.utilityDamageReport" className="utilityDamageReport" onSubmit={this.onFormSubmit}>
            <AlertModal id="utilityDamageReport-form-modal"/>
            <Alert className="danger-alert" id="utilityDamageReport-form-failed"/>
            <div className={'header-container'}><Title titleColor="yellow" text={displayText.headerTitle}
                                                       noNeedBorder={true}/>
                <Select
                    options={[{label: 'English', value: 'eng'}, {label: 'Español', value: 'spa'}]}
                    defaultValue={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    value={{
                        label: language === 'spa' ? 'Español' : 'English',
                        value: language === 'spa' ? 'spa' : 'eng'
                    }}
                    className="lang-select"
                    onChange={this.onChangeLanguage}/></div>
            <fieldset>
                <legend><span className="yellow-color-title">{displayText.partATitle}</span></legend>
                <Row className="show-grid">
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="date">{displayText.dod}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="date"
                                type="date"
                                placeholder="HH:MM"
                                model=".dateOfDamage"
                                defaultValue={answer.dateOfDamage ? format(new Date(answer.dateOfDamage), 'yyyy-MM-dd') : ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} htmlFor="streetAddress">{displayText.streetAddr}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.streetAddress} model="forms.utilityDamageReport.streetAddress" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="streetAddress"
                                type="text"
                                placeholder=""
                                model=".streetAddress"
                                onBlur={() => {
                                    this.updateAddressChangeStatus(true)
                                }}
                                defaultValue={answer.streetAddress || null}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="city">{displayText.city}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.city} model="forms.utilityDamageReport.city" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="city"
                                type="text"
                                placeholder=""
                                model=".city"
                                onBlur={() => {
                                    this.updateAddressChangeStatus(true)
                                }}
                                defaultValue={answer.city || null}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="state">{displayText.state}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.state} model="forms.utilityDamageReport.state" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="state"
                                type="text"
                                placeholder=""
                                model=".state"
                                onBlur={() => {
                                    this.updateAddressChangeStatus(true)
                                }}
                                defaultValue={answer.state || null}
                            />
                        </Col>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Label required sm={12} htmlFor="nearestIntersection">{displayText.nearestIntersection}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.nearestIntersection} model="forms.utilityDamageReport.nearestIntersection" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="nearestIntersection"
                                type="text"
                                placeholder=""
                                model=".nearestIntersection"
                                defaultValue={answer.nearestIntersection || null}
                            />
                        </Col>
                    </Col>
                </Row>
                <hr/>
                <legend><span className="yellow-color-title">{displayText.partBTitle}</span></legend>
                <Row className={'show-grid'}>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isElectricAffected">{displayText.electric}</label>
                            <RRFInput
                                id="isElectricAffected"
                                type="checkbox"
                                model=".isElectricAffected"
                                defaultValue={(answer.isElectricAffected && answer.isElectricAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isNGasAffected">{displayText.nGas}</label>
                            <RRFInput
                                id="isNGasAffected"
                                type="checkbox"
                                model=".isNGasAffected"
                                defaultValue={(answer.isNGasAffected && answer.isNGasAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isSewerAffected">{displayText.sewer}</label>
                            <RRFInput
                                id="isSewerAffected"
                                type="checkbox"
                                model=".isSewerAffected"
                                defaultValue={(answer.isSewerAffected && answer.isSewerAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isWaterAffected">{displayText.water}</label>
                            <RRFInput
                                id="isWaterAffected"
                                type="checkbox"
                                model=".isWaterAffected"
                                defaultValue={(answer.isWaterAffected && answer.isWaterAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isTelephoneAffected">{displayText.telephone}</label>
                            <RRFInput
                                id="isTelePhoneAffected"
                                type="checkbox"
                                model=".isTelePhoneAffected"
                                defaultValue={(answer.isTelePhoneAffected && answer.isTelePhoneAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isCableTVAffected">{displayText.cableTV}</label>
                            <RRFInput
                                id="isCableTVAffected"
                                type="checkbox"
                                model=".isCableTVAffected"
                                defaultValue={(answer.isCableTVAffected && answer.isCableTVAffected === '1') || false}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>  
                    <Label required sm={12} className="text-left">{displayText.typeOfService} </Label>   
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isServiceOrDrop">{displayText.serviceDrop}</label>
                            <RRFInput
                                id="isServiceOrDrop"
                                type="checkbox"
                                model=".isServiceOrDrop"
                                defaultValue={(answer.isServiceOrDrop && answer.isServiceOrDrop === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={2} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isMain">{displayText.main}</label>
                            <RRFInput
                                id="isMain"
                                type="checkbox"
                                model=".isMain"
                                defaultValue={(answer.isMain && answer.isMain === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isFiberOptic">{displayText.fo}</label>
                            <RRFInput
                                id="isFiberOptic"
                                type="checkbox"
                                model=".isFiberOptic"
                                defaultValue={(answer.isFiberOptic && answer.isFiberOptic === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="damageDepth">{displayText.dodf}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.damageDepth} model="forms.utilityDamageReport.damageDepth" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="damageDepth"
                                type="text"
                                placeholder=""
                                model=".damageDepth"
                                defaultValue={answer.damageDepth || ''}
                            />
                        </Col>
                    </Col>
                </Row>
                <hr/>
                <legend><span className="yellow-color-title">{displayText.partCTitle}</span></legend>
                <Row className={'show-grid'}>
                    <Col sm={6} xs={12}>
                        {/* <p className="yellow-color-title">{displayText.oneCallCenterNotified}</p> */}
                        <Label required sm={12} className="text-left">{displayText.oneCallCenterNotified} </Label>
                        <Label sm={12} className="radioInput reporterLocationContent">
                            <RRFInput
                                id="reporterLocationCFYes"
                                type="radio"
                                model=".isOneCallCenterNotified"
                                radioValue={'true'}
                                radioName="oneCallCenterNotified"
                                onChange={this.onOneCallCenterNotifiedChange}
                                defaultValue={answer.isOneCallCenterNotified || ''}
                            /> <label htmlFor="reporterLocationCFYes">{displayText.yes}</label>
                            <RRFInput
                                id="reporterLocationCFNo"
                                type="radio"
                                model=".isOneCallCenterNotified"
                                radioValue={'false'}
                                radioName="oneCallCenterNotified"
                                onChange={this.onOneCallCenterNotifiedChange}
                                defaultValue={answer.isOneCallCenterNotified || ''}
                            /> <label htmlFor="reporterLocationCFNo">{displayText.no}</label>
                        </Label>
                    </Col>
                    {(forms.utilityDamageReport.isOneCallCenterNotified === 'true') && <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="locateTicketNumber">{displayText.provideTheTicketNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="locateTicketNumber"
                                type="text"
                                placeholder=""
                                model=".locateTicketNumber"
                                defaultValue={answer.locateTicketNumber || ''}
                            />
                        </Col>
                    </Col>}
                    <Col sm={6} xs={12}> 
                        <Label required sm={12} className="text-left">{displayText.questFacilityMarksVisible} </Label> 
                        <Label sm={12} className="radioInput facilityMarkVisible">
                            <RRFInput
                                id="facilityMarkVisibleYes"
                                type="radio"
                                model=".isFacilityMarkVisible"
                                radioValue={'true'}
                                radioName="facilityMarkVisible"
                                defaultValue={answer.isFacilityMarkVisible || ''}
                            /> <label htmlFor="facilityMarkVisibleYes">{displayText.yes}</label>
                            <RRFInput
                                id="facilityMarkVisibleNo"
                                type="radio"
                                model=".isFacilityMarkVisible"
                                radioValue={'false'}
                                radioName="facilityMarkVisible"
                                defaultValue={answer.isFacilityMarkVisible || ''}
                            /> <label htmlFor="facilityMarkVisibleNo">{displayText.no}</label>
                        </Label>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} className="text-left">{displayText.questFacilityMarkAccurate} </Label>  
                        <Label sm={12} className="radioInput facilityMarkAccurate">
                            <RRFInput
                                id="facilityMarkAccurateYes"
                                type="radio"
                                model=".isFacilityMarkAccurate"
                                radioValue={'true'}
                                radioName="facilityMarkAccurate"
                                defaultValue={answer.isFacilityMarkAccurate || ''}
                            /> <label htmlFor="facilityMarkAccurateYes">{displayText.yes}</label>
                            <RRFInput
                                id="facilityMarkAccurateNo"
                                type="radio"
                                model=".isFacilityMarkAccurate"
                                radioValue={'false'}
                                radioName="facilityMarkAccurate"
                                defaultValue={answer.isFacilityMarkAccurate || ''}
                            /> <label htmlFor="facilityMarkAccurateNo">{displayText.no}</label>
                        </Label>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} className="text-left">{displayText.questFacilityMarkerType} </Label> 
                        <Label sm={12} className="radioInput facilityMarkerType">
                            <RRFInput
                                id="facilityMarkerTypePaint"
                                type="radio"
                                model=".facilityMarkerType"
                                radioValue="paint"
                                radioName="facilityMarkerType"
                                defaultValue={answer.facilityMarkerType || ''}
                            /> <label htmlFor="facilityMarkerTypePaint">{displayText.paint}</label>
                            <RRFInput
                                id="facilityMarkerTypeFlag"
                                type="radio"
                                model=".facilityMarkerType"
                                radioValue="flags"
                                radioName="facilityMarkerType"
                                defaultValue={answer.facilityMarkerType || ''}
                            /> <label htmlFor="facilityMarkerTypeFlag">{displayText.flag}</label>
                            <RRFInput
                                id="facilityMarkerTypePF"
                                type="radio"
                                model=".facilityMarkerType"
                                radioValue="paintAndFlag"
                                radioName="facilityMarkerType"
                                defaultValue={answer.facilityMarkerType || ''}
                            /> <label htmlFor="facilityMarkerTypePF">{displayText.paintAndFlag}</label>
                        </Label>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} className="text-left">{displayText.QuestIsPhotosTaken} </Label> 
                        <Label sm={12} className="radioInput photosAvailable">
                            <RRFInput
                                id="photosAvailableYes"
                                type="radio"
                                model=".isPhotosTaken"
                                radioValue={'true'}
                                radioName="photosAvailable"
                                defaultValue={answer.isPhotosTaken || ''}
                            /> <label htmlFor="photosAvailableYes">{displayText.yes}</label>
                            <RRFInput
                                id="photosAvailableNo"
                                type="radio"
                                model=".isPhotosTaken"
                                radioValue={'false'}
                                radioName="photosAvailable"
                                defaultValue={answer.isPhotosTaken || ''}
                            /> <label htmlFor="photosAvailableNo">{displayText.no}</label>
                        </Label>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Col sm={12} xs={12}>
                        <Label required sm={12} className="text-left">{displayText.questTypeOfPaintLocateMarksPresent} </Label>  
                        <Label sm={12} className="radioInput typeOfPaintedLocateMarks">
                            <RRFInput
                                id="typeOfPaintedLocateMarksDB"
                                type="radio"
                                model=".typeOfPaintedLocateMarks"
                                radioValue="ductBank"
                                radioName="typeOfPaintedLocateMarks"
                                defaultValue={answer.typeOfPaintedLocateMarks || 'false'}
                            /> <label htmlFor="typeOfPaintedLocateMarksDB">{displayText.dbdp}</label>
                            <RRFInput
                                id="typeOfPaintedLocateMarksSLB"
                                type="radio"
                                model=".typeOfPaintedLocateMarks"
                                radioValue="singleLineBuffer"
                                radioName="typeOfPaintedLocateMarks"
                                defaultValue={answer.typeOfPaintedLocateMarks || 'false'}
                            /> <label htmlFor="typeOfPaintedLocateMarksSLB">{displayText.slWithBuffer}</label>
                            <RRFInput
                                id="typeOfPaintedLocateMarksSL"
                                type="radio"
                                model=".typeOfPaintedLocateMarks"
                                radioValue="singleLineNoBuffer"
                                radioName="typeOfPaintedLocateMarks"
                                defaultValue={answer.typeOfPaintedLocateMarks || 'false'}
                            /> <label htmlFor="typeOfPaintedLocateMarksSL">{displayText.slWithoutBuffer}</label>
                        </Label>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} htmlFor="locateMarkDistance">{displayText.QuestMarksDistance}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.locateMarksDistance} model="forms.utilityDamageReport.locateMarksDistance" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="locateMarkDistance"
                                type="text"
                                placeholder=""
                                model=".locateMarksDistance"
                                defaultValue={answer.locateMarksDistance || ''}
                            />
                        </Col>
                    </Col>
                </Row>
                <hr/>
                <legend><span className="yellow-color-title">{displayText.partDTitle}</span>
                </legend>
                <Row className={'show-grid'}>
                    <Label required sm={12} className="text-left">{displayText.questTypeofExcavationEquipment} </Label> 
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isTrackhoe">{displayText.backhoe}</label>
                            <RRFInput
                                id="isTrackhoe"
                                type="checkbox"
                                model=".isTrackHoe"
                                defaultValue={(answer.isTrackHoe && answer.isTrackHoe === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isBoring">{displayText.boring}</label>
                            <RRFInput
                                id="isBoring"
                                type="checkbox"
                                model=".isBoring"
                                defaultValue={(answer.isBoring && answer.isBoring === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isAuger">{displayText.auger}</label>
                            <RRFInput
                                id="isAuger"
                                type="checkbox"
                                model=".isAuger"
                                defaultValue={(answer.isAuger && answer.isAuger === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isTrencher">{displayText.trencher}</label>
                            <RRFInput
                                id="isTrencher"
                                type="checkbox"
                                model=".isTrencher"
                                defaultValue={(answer.isTrencher && answer.isTrencher === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isDirectionDrill">{displayText.directionDrill}</label>
                            <RRFInput
                                id="isDirectionDrill"
                                type="checkbox"
                                model=".isDirectionDrill"
                                defaultValue={(answer.isDirectionDrill && answer.isDirectionDrill === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isDrilling">{displayText.drilling}</label>
                            <RRFInput
                                id="isDrilling"
                                type="checkbox"
                                model=".isDrilling"
                                defaultValue={(answer.isDrilling && answer.isDrilling === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isHandTools">{displayText.handTools}</label>
                            <RRFInput
                                id="isHandTools"
                                type="checkbox"
                                model=".isHandTools"
                                defaultValue={(answer.isHandTools && answer.isHandTools === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isProbingDevice">{displayText.probingDevice}</label>
                            <RRFInput
                                id="isProbingDevice"
                                type="checkbox"
                                model=".isProbingDevice"
                                defaultValue={(answer.isProbingDevice && answer.isProbingDevice === '1') || false}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className={'show-grid'}>
                    <Label required sm={12} className="text-left">{displayText.questTypeOfWorkPerformed} </Label> 
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isServiceOrDrop">{displayText.installingGP}</label>
                            <RRFInput
                                id="isGasPipelineInstalled"
                                type="checkbox"
                                model=".isGasPipelineInstalled"
                                defaultValue={(answer.isGasPipelineInstalled && answer.isGasPipelineInstalled === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isMain">{displayText.installingEC}</label>
                            <RRFInput
                                id="isElectricCable"
                                type="checkbox"
                                model=".isElectricCable"
                                defaultValue={(answer.isElectricCable && answer.isElectricCable === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isJointTrench">{displayText.jointTrench}</label>
                            <RRFInput
                                id="isJointTrench"
                                type="checkbox"
                                model=".isJointTrench"
                                defaultValue={(answer.isJointTrench && answer.isJointTrench === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isTelephoneInstallation">{displayText.installingTele}</label>
                            <RRFInput
                                id="isTelephoneInstallation"
                                type="checkbox"
                                model=".isTelephoneInstallation"
                                defaultValue={(answer.isTelephoneInstallation && answer.isTelephoneInstallation === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isCableTVInstallation">{displayText.installingCTV}</label>
                            <RRFInput
                                id="isCableTVInstallation"
                                type="checkbox"
                                model=".isCableTVInstallation"
                                defaultValue={(answer.isCableTVInstallation && answer.isCableTVInstallation === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isPolesInstallation">{displayText.installingPoles}</label>
                            <RRFInput
                                id="isPolesInstallation"
                                type="checkbox"
                                model=".isPolesInstallation"
                                defaultValue={(answer.isPolesInstallation && answer.isPolesInstallation === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isAnchorsInstallation">{displayText.installingAnchors}</label>
                            <RRFInput
                                id="isAnchorsInstallation"
                                type="checkbox"
                                model=".isAnchorsInstallation"
                                defaultValue={(answer.isAnchorsInstallation && answer.isAnchorsInstallation === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={2} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="Others">{displayText.otherWork}</label>
                            <RRFInput
                                id="Others"
                                type="checkbox"
                                onChange={this.onOtherWorkTypeSelected}
                                model=".isOtherWork"
                                defaultValue={(answer.isOtherWork && answer.isOtherWork === '1') || false}
                            />
                        </Col>
                    </Col>
                    {(forms.utilityDamageReport.isOtherWork === '1' || forms.utilityDamageReport.isOtherWork) &&
                        <Col sm={4} xs={12}>
                            <Label required sm={12} htmlFor="otherWorkType">{displayText.otherWorkType}</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="otherWorkType"
                                    type="text"
                                    placeholder=""
                                    model=".otherWorkType"
                                    defaultValue={answer.otherWorkType || ''}
                                />
                            </Col>
                        </Col>}
                </Row>
                <Row className={'show-grid'}> 
                    <Label required sm={12} className="text-left">{displayText.questLocationOfDigSite} </Label>  
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isLocationPrivateProp">{displayText.privateProp}</label>
                            <RRFInput
                                id="Others"
                                type="checkbox"
                                model=".isLocationPrivateProp"
                                defaultValue={(answer.isLocationPrivateProp && answer.isLocationPrivateProp === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label"
                                   htmlFor="isLocationUtilityEasement">{displayText.utilityEasement}</label>
                            <RRFInput
                                id="Others"
                                type="checkbox"
                                model=".isLocationUtilityEasement"
                                defaultValue={(answer.isLocationUtilityEasement && answer.isLocationUtilityEasement === '1') || false}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col sm={12} className="input">
                            <label className="checkbox-label" htmlFor="isLocationRoad">{displayText.roadRow}</label>
                            <RRFInput
                                id="Others"
                                type="checkbox"
                                model=".isLocationRoad"
                                defaultValue={(answer.isLocationRoad && answer.isLocationRoad === '1') || false}
                            />
                        </Col>
                    </Col>
                </Row>
                <hr/>
                <legend><span
                    className="yellow-color-title">{displayText.partETitle}</span>
                </legend>
                <Row className={'show-grid'}>
                    <Col className="row-cell-height " sm={12} xs={12}>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.incidentDesc} model="forms.utilityDamageReport.incidentDesc" /> 
                        <div className="width-height-100-textcenter">
                            <RRFInput
                                id="incidentDesc"
                                type="textarea"
                                placeholder=""
                                model={`.incidentDesc`}
                                textAreaRows="5"
                                defaultValue={answer.incidentDesc || ''}
                            />
                        </div>
                    </Col>
                </Row>
                <hr/>
                <legend><span className="yellow-color-title">{displayText.partFTitle}</span></legend>
                <Row className={'show-grid'}>
                    <PlantLocationMap
                        locationAddress={forms.utilityDamageReport.streetAddress + ', ' + forms.utilityDamageReport.city + ', ' + forms.utilityDamageReport.state}
                        locationCoords={this.coords} shapesList={this.shapesList}
                        outfallsList={this.outfallsList} coordinatesPointerList={ (this.props.editInstance && this.props.editInstance.props.coordinatesPointerList) || []} 
                        type={'edit'}
                        isLocationAddressChanged={this.state.isLocationAddressChanged}
                        updateCoords={this.setMapCoordsData}
                        updateShapesList={this.updateShapesList}
                        updateOutfallsList={this.updateOutfallsList}
                        updateCoordinatesPointerList={this.updateCoordinatesPointerList}
                        updateAddressChangeStatus={this.updateAddressChangeStatus}
                    />
                </Row>
                <Row className={'show-grid'}>
                    <AttachementInput
                        id={'excavationUploads'}
                        model={'.attachments'}
                        uploadedImages={editInstance && editInstance.props.attachmentUrls}
                        multiple={true}
                    />
                </Row>
                <hr/>
                <Row className={'show-grid'}>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="generalForemanName">{displayText.GFN}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.generalForemanName} model="forms.utilityDamageReport.generalForemanName" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="generalForemanName"
                                type="text"
                                placeholder=""
                                model=".generalForemanName"
                                defaultValue={answer.generalForemanName || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="generalForemanPhone">{displayText.GFPNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="generalForemanPhone"
                                type="text"
                                placeholder=""
                                model=".generalForemanPhone"
                                defaultValue={answer.generalForemanPhone || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="crewForemanName">{displayText.CFN}</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.utilityDamageReport.crewForemanName} model="forms.utilityDamageReport.crewForemanName" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="crewForemanName"
                                type="text"
                                placeholder=""
                                model=".crewForemanName"
                                defaultValue={answer.crewForemanName || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="crewForemanPhone">{displayText.CFPNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="crewForemanPhone"
                                type="text"
                                placeholder=""
                                model=".crewForemanPhone"
                                defaultValue={answer.crewForemanPhone || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="jobNo">{displayText.jobNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="jobNo"
                                type="text"
                                placeholder=""
                                model=".jobNo"
                                defaultValue={answer.jobNo || ''}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="crewNo">{displayText.crewNo}</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="crewNo"
                                type="text"
                                placeholder=""
                                model=".crewNo"
                                defaultValue={answer.crewNo || ''}
                            />
                        </Col>
                    </Col>
                </Row>
            </fieldset>
            <Row className="show-grid">
                <div className="form-button" style={{marginTop: '50px', textAlign: 'center'}}>
                    <Button style={{color: 'rgb(84, 121, 175)', border: '1px solid #5479AF', backgroundColor: 'white',}}
                            redirectTo="goBack" type="button" onClick={() => history?.goBack()}>
                        <i className="fa fa-ban" aria-hidden="true"/>  &nbsp; Cancel
                    </Button>
                    <Button style={{color: '#FFFFFF', border: 'none', backgroundColor: '#26A65B'}}>
                        <i className={editInstance ? "fa fa-refresh" : "fa fa-plus"} aria-hidden="true"/>
                        &nbsp;
                        {editInstance ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Row>
            <Alert id="utility-damage-report-form" className="danger-alert"/>
        </Form>
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
                <Async
                    identifier="preExcavationChecklist"
                    promise={this.promise}
                    error={<ErrorPage/>}
                    loader={<Loader/>}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }

}

function mapStateToProps(state: any, ownProps: any) {
    const editId = ownProps.match.params.id;
    return {
        editId,
        forms: state.forms,
        editInstance: UtilityDamageReportModel.get(ownProps.match.params.id)
    }
}

export const UtilityDamageReportForm = withRouter(connect<IUtilityDamageReportProps, any, any>(mapStateToProps)(UtilityDamageReportImpl));
