import { fromJS } from 'immutable';
import { SAVE_PROJECT_LIST } from '../constants/actions';
export function projectReducer(state: any = fromJS({}), action: { type: any; projectNames: any; }) {
    switch (action.type) {
        case SAVE_PROJECT_LIST:
            return state.set('projectNames', action.projectNames);
        default:
            return state;
    }

}
