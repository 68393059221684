import {IForms, IHistory} from "../interfaces";
import {UserModel} from "../model/UserModel";
import {format} from "date-fns";
import {constructAnswers, removeByValue, showAlert} from "../utils/generalUtils"; 
import {FORM_SUBMISSION_ERROR} from "../constants/general";
import {getCustom, post} from "../utils/HTTP";
import {PreExcavationChecklistModel, PreExcavationChecklistV2Model} from "../model/PreExcavationChecklistModel";
import {UtilityDamageReportModel} from "../model/UtilityDamageReportModel"; 

function formattingAnswers(answers: Array<{ quesType: string, quesNum: number, answer: any }>) {
    const formattedAnswer: any = {};  
    answers.forEach((answer: { quesType: string, quesNum: number, answer: any }) => {
        formattedAnswer[answer.quesType] = answer.answer;
    }) 
    return formattedAnswer;
}

function marshallTexas811FormData(data: any) {
    const formattedData: any = {
        id: data.key,
        answers: formattingAnswers(data.answers)
    }
    formattedData.language = formattedData.answers['language']
    data.shapesList = JSON.parse(data['shapesList'] || '[]')
    data.outfallsList = JSON.parse(data['outfallsList'] || '[]')
    data.coordinatesPointerList = JSON.parse(data['coordinatesPointerList'] || '[]')
    data.coords = JSON.parse(data['coords'] || '{}')
    delete data['answers'];
    Object.assign(formattedData, data);
    return formattedData
}

export async function submitTexas11Forms(formValues: any, formName: string, language: string, history: IHistory | undefined, forms: IForms, editId?: string, mapObj?: any) {
    const company = UserModel.getCompanyName();
    const submittedDateTime = new Date()
    let deletedFiles: Array<any> = [];
    let files: Array<any> = [];
    if (formName === 'utilityDamageReport') {
        deletedFiles = forms.utilityDamageReport.deletedFiles || []
        files = forms.utilityDamageReport.attachments;
    } else if (formName === 'preExcavationChecklistV2') {
        deletedFiles = forms.preExcavationChecklistV2.deletedFiles || []
        files = forms.preExcavationChecklistV2.attachments;
        formValues = removeByValue(formValues, ['attachments', 'id', 'editId', 'deletedFiles' ]);
    } else {
        deletedFiles = forms.preExcavationChecklist.deletedFiles || []
        files = forms.preExcavationChecklist.attachments;
    }
    formValues.language = language;
    let answers = constructAnswers(formValues);
    const dataToSend = {
        groupName: '',
        userId: UserModel.getUserUID(),
        formId: formName,
        date: format(submittedDateTime, 'yyyy-MM-dd'),
        time: format(submittedDateTime, 'HH:mm'),
        projectName: '',
        associatedFormId: '',
        followUp: "0",
        editId: editId,
        /* imageUrls: [],
        videoUrls: [],
        attachments: [], */
        attachments: files,
        deletedFiles,
        deletedImages: [],
        deletedVideos: [],
        company,
        answers,
        ...(mapObj && mapObj)
    }; 
    try {
        dataToSend.editId = editId || '';
        const response = editId ? await post('/EditForm', dataToSend) : await post('/SubmitForm', dataToSend);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, `'${formName}-form-failed`, 'danger');
            return;
        }
        showAlert(response.data.message, `${formName}-form-modal`, 'success', async () => {
            history?.push(editId ? `/${formName}/reports` : (UserModel.checkUserAccess('texas811_forms')?`/texas811`:`/utility-forms`) );
        });
        return response;
    } catch (e) {
        showAlert(FORM_SUBMISSION_ERROR, `${formName}-form-failed`, 'danger');
        throw e;
    }

}

export async function getAllTexas811Forms( urlData: any, forms: IForms, formType: string) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType, pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        UtilityDamageReportModel.deleteAll();
        PreExcavationChecklistModel.deleteAll();
        PreExcavationChecklistV2Model.deleteAll();
        if (response.data.status === 'valid' && response.data.data.length) {
            let texas811FormData: any = {};
            if (formType === 'utilityDamageReport') {
                response.data.data.forEach((data: any) => {
                    texas811FormData = marshallTexas811FormData(data)
                    new UtilityDamageReportModel(texas811FormData).$save();
                });
            } else if(formType === 'preExcavationChecklistV2') {
                response.data.data.forEach((data: any) => {
                    texas811FormData = marshallTexas811FormData(data)
                    new PreExcavationChecklistV2Model(texas811FormData).$save();
                });
            } else {
                response.data.data.forEach((data: any) => {
                    texas811FormData = marshallTexas811FormData(data)
                    new PreExcavationChecklistModel(texas811FormData).$save();
                });
            }
        }
    } catch (e) {
        throw e;
    }
}

export async function getFormData(formType: string, formId: string) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`GetForms/${formType}/${company}/${formId}`);
        UtilityDamageReportModel.deleteAll();
        PreExcavationChecklistModel.deleteAll();
        if (response.data.status === 'valid' && response.data.data.length) {
            const texas811FormData = marshallTexas811FormData(response.data.data[0]);
            if (formType === 'utilityDamageReport') {
                new UtilityDamageReportModel(texas811FormData).$save();
            } else {
                new PreExcavationChecklistModel(texas811FormData).$save();
            }
        }
    } catch (e) {
        throw e
    }

}
