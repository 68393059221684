import * as React from 'react';
import { connect } from 'react-redux';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { IHistory } from '../../interfaces';
import { OfficeSafetyModel } from '../../model/OfficeSafetyModel';
import { UserModel } from '../../model/UserModel';
import { getAllOfficeSafetyForm } from '../../services/officeSafetyFormService';
import { getAllUsers } from '../../services/userService';
import { convertTime, KeyValue } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import { Img } from '../reusableComponents/ImageComponents/Img';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay';
import { Button } from '../reusableComponents/FormComponents/Button';
import noImage from '../../images/noImage.png'; 

export interface IDetailsPageProps {
    match: {
        params: {
            id: string;
        }
    };
    history: IHistory;
    officeSafetyInstance: OfficeSafetyModel;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = async () => {
        if (OfficeSafetyModel.list().length <= 0) {
            await getAllOfficeSafetyForm();
        }
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return null;
    }

    getObserverName = (userID: string) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    renderImages = () => {
        const { officeSafetyInstance } = this.props;
        if (officeSafetyInstance && officeSafetyInstance.props.imageUrls && officeSafetyInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        }
        const { props: { imageUrls } } = officeSafetyInstance;
        return (imageUrls || []).map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    }

    renderContent = () => {
        const { officeSafetyInstance } = this.props;
        if (!officeSafetyInstance) {
            return <Loader />;
        }
        const { timeOfIncident, locationName, location, eyesOnPath,
            ascendingDescending, onTheMoveComments, toolOrEquipment, conditionUse,
            toolSelectionComments, manualHandlingComments, liftingPoweringTwisting,
            pushingPulling, ergonomicsComments, ergonomicsWorkstation, userId,
            lineOfFire, pinchPoint, eyesOnTask, bodyPositionComments, foodSafety,
            cleanliness, healthHygieneComments, hskAccessEgress, hskStorageHousekeeping,
            housekeepingComments, tailgating, personalProperty, barricading, securityComments,
            envRecyclingComments, doubleSidePrinting, dateReported,
            recycling, computerShutdown } = officeSafetyInstance.props;
        return <div>
            <Title text={`Office Safety Report for ${dateReported}`} />
            <KeyValue label="Time">{timeOfIncident ? convertTime(timeOfIncident) : 'NA'}</KeyValue>
            <KeyValue label="Location">{location || 'NA'}</KeyValue>
            <KeyValue label="Location Name">{locationName || 'NA'}</KeyValue>
            <KeyValue label="Observer">{this.getObserverName(userId || '') || 'NA'}</KeyValue>
            <div className="action-label">On The Move</div>
            <KeyValue label="Eyes On Path">{eyesOnPath}</KeyValue>
            <KeyValue label="Ascending/Descending">{ascendingDescending}</KeyValue>
            <KeyValue label="Comments">{onTheMoveComments || 'NA'}</KeyValue>
            <div className="action-label">Facility/Equipment/Tool</div>
            <KeyValue label="Tool or Equipment Selection">{toolOrEquipment}</KeyValue>
            <KeyValue label="Condition/Use">{conditionUse}</KeyValue>
            <KeyValue label="Comments">{toolSelectionComments || 'NA'}</KeyValue>
            <div className="action-label">Manual Handling</div>
            <KeyValue label="Lifting/Powering/Twisting">{liftingPoweringTwisting}</KeyValue>
            <KeyValue label="Pushing/Pulling">{pushingPulling}</KeyValue>
            <KeyValue label="Comments">{manualHandlingComments || 'NA'}</KeyValue>
            <div className="action-label">Ergonomics</div>
            <KeyValue label="Lifting/Powering/Pushing" > {ergonomicsWorkstation} </KeyValue>
            <KeyValue label="Comments">{ergonomicsComments || 'NA'}</KeyValue>
            <div className="action-label">Body Position</div>
            <KeyValue label="Line of Fire">{lineOfFire}</KeyValue>
            <KeyValue label="Pinch Points">{pinchPoint}</KeyValue>
            <KeyValue label="Eyes on Task">{eyesOnTask}</KeyValue>
            <KeyValue label="Comments">{bodyPositionComments}</KeyValue>
            <div className="action-label">Health &amp; Hygiene</div>
            <KeyValue label="Food Safety">{foodSafety}</KeyValue>
            <KeyValue label="Cleanliness and Spills">{cleanliness}</KeyValue>
            <KeyValue label="Comments">{healthHygieneComments || 'NA'}</KeyValue>
            <div className="action-label">Security</div>
            <KeyValue label="Tailgating">{tailgating}</KeyValue>
            <KeyValue label="Personal Property">{personalProperty}</KeyValue>
            <KeyValue label="Barricading">{barricading}</KeyValue>
            <KeyValue label="Comments">{securityComments}</KeyValue>
            <div className="action-label">HouseKeeping</div>
            <KeyValue label="Access / Egress">{hskAccessEgress}</KeyValue>
            <KeyValue label="Storage/Housekeeping">{hskStorageHousekeeping}</KeyValue>
            <KeyValue label="Comments">{housekeepingComments}</KeyValue>
            <div className="action-label">Environment &amp; Recycling</div>
            <KeyValue label="Double Sided Printing">{doubleSidePrinting}</KeyValue>
            <KeyValue label="Recycling">{recycling}</KeyValue>
            <KeyValue label="Computer Shutdown">{computerShutdown}</KeyValue>
            <KeyValue label="Comments">{envRecyclingComments}</KeyValue>
            <KeyValue label="Images">{this.renderImages()}</KeyValue>
            <KeyValue label="Videos"><VideoPlay videoUrls={officeSafetyInstance.props.videoUrls}/></KeyValue>
        </div>;
    }

    renderFormDetails = () => { 
        return <Async
            identifier="OfficeSafetyFormDetails"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={this.renderContent()}
        />;
    }

    render() {
        let fileName = '';
        const { officeSafetyInstance } = this.props;
        if (officeSafetyInstance) {
            const { officeSafetyInstance: { props: { dateOfIncident } } } = this.props;
            fileName = `Office Safety Report for ${dateOfIncident}`;
        }
        return (
            <BasePage>
                <Gallery images={officeSafetyInstance ? officeSafetyInstance.props.imageUrls : []} />
                <DetailsPageWrapper pdfFileName={fileName} className="office-safety-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const officeSafetyInstance = OfficeSafetyModel.get(ownProps.match.params.id);
    return {
        officeSafetyInstance
    };
}

export const DetailsPage = connect<{}, {}, IDetailsPageProps>(mapStateToProps)(DetailsPageImpl);
