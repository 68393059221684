import * as  React from 'react';
import { connect } from 'react-redux';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { ListPage } from '../../reusableComponents/ListPage';
import { getAllFeedbacks } from '../../../services/miscellaniousServices';
import { ListItem } from './ListItem';
import { UserModel } from '../../../model/UserModel';
import { getAllUsers } from '../../../services/userService';
import { FeedbackModel } from '../../../model/FeedbackModel';

export interface IFeedbackListPageProps {
    allFeedbacks: FeedbackModel[];
}

export interface IFeedbackListPageState {

}

export class FeedbackListPageImpl extends React.Component<IFeedbackListPageProps, IFeedbackListPageState> {
    constructor(props: IFeedbackListPageProps) {
        super(props);
    }

    promise = async () => {
        if (this.props.allFeedbacks.length <= 0) {
            await getAllFeedbacks();
        }
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return;
    }

    renderFeedbackList = () => {
        const { allFeedbacks } = this.props;
        return <Async
            identifier="FeedbackListPage"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={
                <div className="basepage">
                    <ListPage
                        pageHeading={'Submitted Feedbacks'}
                        instances={allFeedbacks}
                        rowHeadings={['OS', 'Device', 'Date Created']}
                        listItemComponent={ListItem}
                        emptyInstancesMessage="No JSA reports found."
                        sm={[4, 4, 3]}
                    />
                </div>
            }
        />;
    }

    render() {
        return (
            <div style={{ paddingTop: '50px' }} className="background">{this.renderFeedbackList()}</div>
        );
    }
}

export function mapStateToProps(state: any) {
    const allFeedbacks = FeedbackModel.list();
    return {
        allFeedbacks
    };
}

export const FeedbackListPage = connect<{}, {}, IFeedbackListPageProps>(mapStateToProps)(FeedbackListPageImpl);
