import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { IncidentReportModel } from '../../model/IncidentReportModel'; 

export interface IInjurySeverityProps {
    editInstance?: IncidentReportModel;
}

export class InjurySeverityForm extends React.PureComponent<IInjurySeverityProps, {}> {
    constructor(props: IInjurySeverityProps | Readonly<IInjurySeverityProps>) {
        super(props);
    }

    InjurySeverityLevel = [
        { value: 'No Treat (NT)', label: 'No Treat (NT)' },
        { value: 'Near Miss (NM)', label: 'Near Miss (NM)' },
        { value: 'Serious Near Miss (SNM)', label: 'Serious Near Miss (SNM)' },
        { value: 'First Aid (FA)', label: 'First Aid (FA)' },
        { value: 'Medical Treatment (MTI)', label: 'Medical Treatment (MTI)' },
        { value: 'Lost Time (LTI)', label: 'Lost Time (LTI)' },
        { value: 'Fatality (FAT)', label: 'Fatality (FAT)' }
    ];

    render() {
        let editInstance = this.props.editInstance;  
        return (
            <div className="severity-form form-bottom">
                <fieldset className="form-bottom-fieldset">
                    <legend><span style={{color: 'yellow'}}>
                    Injury Severity Scale</span></legend>
                    <Row className="show-grid">
                        <Col sm={6} xs={12}>
                            <Label required sm={12} htmlFor="actualSeverity">Actual Severity:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="actualSeverity"
                                    type="dropdown"
                                    menuItems={this.InjurySeverityLevel}
                                    model=".actualSeverity"
                                    defaultValue={editInstance && editInstance.props.actualSeverity}
                                />
                            </Col>
                        </Col>
                        <Col sm={6} xs={12}>
                            <Label required sm={12} htmlFor="potentialSeverity">Potential Severity:</Label>
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="potentialSeverity"
                                    type="dropdown"
                                    menuItems={this.InjurySeverityLevel}
                                    model=".potentialSeverity"
                                    defaultValue={editInstance && editInstance.props.potentialSeverity}
                                />
                            </Col>
                        </Col>
                    </Row>
                </fieldset>
            </div>
        );
    }
}
