import * as React from 'react';

export interface IValues {
    value?: string;
    label?: string;
    date?: string;
}

export interface IValueComponentProps {
    placeholder?: string;
    value: IValues;
}

export class ValueComponent extends React.PureComponent<IValueComponentProps, {}> {
    constructor(props: IValueComponentProps | Readonly<IValueComponentProps>) {
        super(props);
    }
    render() {
        return (
            <div className="Select-value" style={{ paddingRight: '30px' }}>
                <span className="Select-value-label">
                    <div
                        style={{ textAlign: 'left', width: '50%', display: 'inline-block' }}
                    >
                        {this.props.children || 'NA'}
                    </div>
                    <div
                        style={{ textAlign: 'right', width: '50%', display: 'inline-block' }}
                    >
                        {this.props.value.date}
                    </div>
                </span>
            </div>
        );
    }
}
