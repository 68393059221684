import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../../interfaces';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { hideModal } from '../../../actions/modalActions';
import { deleteReferences } from '../../../services/miscellaniousServices';

export interface IConfirmationModalProps {
    id?: string;
    modalHeading: string;
    showModal: boolean;
    modalID: string;
    referenceIDs: any;
    history?: IHistory;
}  

export interface IConfirmationModalState {
    showModal: boolean;
}

export class ConfirmationModalImpl extends React.PureComponent<IConfirmationModalProps, IConfirmationModalState> {
    constructor(props: IConfirmationModalProps | Readonly<IConfirmationModalProps>) {
        super(props);
        this.state = { showModal: true };
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    showModal = () => {
        const { props: { id, modalID } } = this;
        if (modalID !== id) {
            return <div></div>;
        }
        return (
            <Modal className="confirmation-modal" show={this.state.showModal} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }} >Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this File(s)?
                    <div className="modal-buttons row">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={() => this.props.referenceIDs && deleteReferences(this.props.referenceIDs)}
                        >
                            Yes
                        </Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => hideModal()}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return this.showModal();
    }
} 

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('modalID'),
        showModal: state.modal.get('showModal'),
        modalHeading: state.modal.get('modalHeading'),
        referenceIDs: state.modal.get('instance')
    };
}

export const ConfirmationModal = withRouter(connect< IConfirmationModalProps, any, any>(mapStateToProps)
    (ConfirmationModalImpl));
