import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux'; 
import { showPrintPreviewModal } from '../../actions/modalActions'; 
import { IFormMonths, IForms } from '../../interfaces';
import { getIncidentNotificationFormSummaryData } from '../../services/incidentNotificationFormService';
import { printDetails } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { IncidentSummaryData } from './IncidentSummaryData';

export interface IIncidentSummaryPageProps {
    formMonths: IFormMonths;
    total: number;
    incidentSeverity: any;
    formSummaryData: any;
    concernTypes: any;
    activityConcernTypes: any;
    lastRecorded: number;
    lastSeverityRecorded: number;
    bodyParts: {
        Head: number;
        Hand: number;
        Foot: number;
        Chest: number;
        Other: number;
        Limb: number;
    };
    forms?: IForms;
}

export interface IIncidentSummaryPageState {
    showMonthlyFilter: boolean;
    showYearlyFilter: boolean;
}

export class IncidentSummaryPageImpl extends React.PureComponent<IIncidentSummaryPageProps, IIncidentSummaryPageState> {
    constructor(props: IIncidentSummaryPageProps | Readonly<IIncidentSummaryPageProps>) {
        super(props);
        this.state = { showMonthlyFilter: false, showYearlyFilter: false };
    }

    promise = async () => { 
        await getIncidentNotificationFormSummaryData(); 
        return null;
    }

    getChildContext() {
        return { formModel: 'forms.incidentReports' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any;

    exportChart = (ref: any) => {
        printDetails(this.ref, 'IncidentDetails');
    }
    renderPrintPreviewModal = () => { 
        showPrintPreviewModal('incident-notification-report');
        /* let ref: any = document.getElementById('summary-reports'); 
        if(ref) {  
			window.print();
        } */
        return;
    }

    renderContent = (showCheckBox: boolean, isAnimationActive: boolean, id: string='') => {
        if (!this.props.formSummaryData) {
            return <div></div>;
        }
        const { concernTypes, formMonths, total, bodyParts, activityConcernTypes, incidentSeverity, LastRecorded, LastSeverityRecorded } = this.props.formSummaryData; 
        let data: Array<any> = [];
        for (let key in concernTypes) {
            data.push({ name: key, value: concernTypes[key] });
        }
        const barChartData = {
            medicalTreatment: incidentSeverity['Medical Treatment (MTI)'],
            fatality: incidentSeverity['Fatality (FAT)'],
            firstAid: incidentSeverity['First Aid (FA)'],
            noTreat: incidentSeverity['No Treat (NT)'],
            seriousNearMiss: incidentSeverity['Serious Near Miss (SNM)'],
            nearMiss: incidentSeverity['Near Miss (NM)'],
            lostTime: incidentSeverity['Lost Time (LTI)'],
        };
        const coneData = {
            formMonths, total,
            medicalTreatment: incidentSeverity['Medical Treatment (MTI)'],
            fatality: incidentSeverity['Fatality (FAT)'],
            firstAid: incidentSeverity['First Aid (FA)'],
            noTreat: incidentSeverity['No Treat (NT)'],
            seriousNearMiss: incidentSeverity['Serious Near Miss (SNM)'],
            nearMiss: incidentSeverity['Near Miss (NM)'],
            lostTime: incidentSeverity['Lost Time (LTI)'],
        };
        return (<div>
            <IncidentSummaryData
                id={id}
                concernTypes={data}
                lastRecorded={ LastRecorded}
                lastSeverityRecorded={ LastSeverityRecorded}
                activityData={activityConcernTypes}
                total={coneData.total}
                barChartData={barChartData}
                coneData={coneData}
                pieChartData={bodyParts}
                showCheckBox={showCheckBox}
                isAnimationActive={isAnimationActive}
                getRef={value => this.ref = value}
                forms={this.props.forms}
            />
            <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={() => this.renderPrintPreviewModal()}>
                    Export as PDF
                </button>
            </div>
        </div>
        );
    }

    render() {
        return (
            <BasePage className="reports-page">
                <PrintPreviewModal
                    id="incident-notification-report"
                    onClickYes={this.exportChart}
                    style={{ boxShadow: 'none' }}
                    fileName="IncidentDetails"
                    className="incident-report-data"
                >
                    {this.renderContent(true, false, 'print')}
                </PrintPreviewModal>
                <Async
                    identifier="IncidentReportSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent(false, true)}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) { 
    const formSummaryData = state.formSummary.get('incidentFormSummary');
    return {
        /* formMonths: state.formSummary.get('incidentFormSummary').formMonths,
        total: state.formSummary.get('incidentFormSummary').total,
        bodyParts: state.formSummary.get('incidentFormSummary').bodyParts,
        activityConcernTypes: state.formSummary.get('incidentFormSummary').activityConcernTypes,
        concernTypes: state.formSummary.get('incidentFormSummary').concernTypes,
        incidentSeverity: state.formSummary.get('incidentFormSummary').incidentSeverity,
        lastRecorded: state.formSummary.get('incidentFormSummary').LastRecorded,
        lastSeverityRecorded: state.formSummary.get('incidentFormSummary').LastSeverityRecorded, */
        formSummaryData, forms: state.forms,
    };
}

export const IncidentSummaryPage = connect<{}, {}, IIncidentSummaryPageProps>(mapStateToProps)(IncidentSummaryPageImpl);
