import * as React from 'react';
import { FollowUpModel } from '../../model/FollowUpModel';
import { connect } from 'react-redux';
import { Async } from '../reusableComponents/Async';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { getAllFollowUpForm, updateFollowUp, updateFollowUpAdditionalDet } from '../../services/incidentReportService';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { Row, Col, Modal } from 'react-bootstrap';
import { Form } from '../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Button } from '../reusableComponents/FormComponents/Button';
import { showAlert, isFormEmpty } from '../../utils/generalUtils';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IHistory, IForms } from '../../interfaces';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { showFollowUpFormModal, hideFollowUpFormModal } from '../../actions/modalActions';
import { FATick } from '../reusableComponents/FATIck';
import { UserModel } from '../../model/UserModel';
import { getUsersByGroup } from '../../services/userService'; 
import { S3_URL } from '../../constants/urls';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'; 
import { format } from 'date-fns';
import noImage from '../../images/noImage.png';
import textThumb from '../../images/text-thumb.png';
import videoThumb from '../../images/video-thumb.png';
import excelThumb from '../../images/excel-thumb.jpg';
import wordThumb from '../../images/word-thumb.png';
import pdfThumb from '../../images/pdf-thumb.png'; 
import { ManualSignature } from '../reusableComponents/ManuallyGenerateSignature/ManualSignature';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
/* import SignaturePad from 'react-signature-pad'; */
var SignaturePad = require('react-signature-pad');

export interface IFollowUpPageProps {
    instance: FollowUpModel;
    formID: string;
    userUID: string;
    history?: IHistory;
    showFormModal: boolean;
    userInstance: UserModel; 
    investigativeId: string; 
    forms: IForms;
}

export interface IFollowUpPageState {
    uploadedFile?: File | string | null;
    isSignatureUploaded: boolean;
    isSignatureUpdated: boolean;
    showModal: boolean;
    signBase64: string;
    showFormModal: boolean;
    useDefault: boolean;
    isAutoGenerate: boolean;

    uploadedFiles: File[]; 
    uploadedFileNames: string[];
    oldFileNames: string[];
    deletedFileNames: string[];
    editFileNameIndex?: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[];   
    isActualCompletionDisabled: boolean; 
    actualCompletionDate: string;

    defaultUserSignatureUrl?: string | null;
    showSubmitForAssigned: boolean;
    showAssignedLoader: boolean;
    assignedToUser?: string;
    usersList?: any;
}

export class FollowUpPageImpl extends React.PureComponent<IFollowUpPageProps,
    IFollowUpPageState> {
    constructor(props: IFollowUpPageProps) {
        super(props);
        this.state = {
            showModal: false,
            uploadedFile: (props.userInstance ? props.userInstance.props.signatureUrl : null),
            isSignatureUploaded: false,
            isSignatureUpdated: true,
            signBase64: '',
            showFormModal: false,
            useDefault: true,
            isAutoGenerate: false, uploadedFiles: [], uploadedFileNames: [], oldFileNames: [], deletedFileNames: [], isActualCompletionDisabled: true, actualCompletionDate: 'null',defaultUserSignatureUrl : (props.userInstance ? props.userInstance.props.signatureUrl : null), showSubmitForAssigned: false, showAssignedLoader: false, usersList: []
        };
    }

    componentWillReceiveProps(nextProps: IFollowUpPageProps) {
        this.setState({
            uploadedFile: (nextProps.userInstance ? nextProps.userInstance.props.signatureUrl : null),defaultUserSignatureUrl : (nextProps.userInstance ? nextProps.userInstance.props.signatureUrl : null),
        });
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    invalidFile: number = 0;
    usersList: any ;
    getChildContext() {
        return { formModel: 'forms.followUpForm' };
    }

    promise = async () => {
        const { userUID, formID, instance } = this.props;
        if (!FollowUpModel.getFollowUp(formID)) {
            await getAllFollowUpForm(userUID);
        }
        let usersList =  instance && instance.props.GroupName && await getUsersByGroup(instance.props.GroupName, 'includeSelf', 'follow-up-failed');
        this.setState({usersList: usersList});
        this.usersList = usersList; 
        //if (UserModel.list().length <= 1) {
            //await getAllUsers('includeSelf');
        //}  
        /* if(this.usersList.length < 1) {
            UserModel.list().forEach(user => { 
                if(user.props.projects.indexOf(this.props.instance.props.AssignedToProject.toString())>-1 ) 
                    this.usersList.push({ value: user.props.userUID, label: user.props.firstName + ' ' + user.props.lastName });
            });
            this.usersList = await sortBy(this.usersList,'alpha');
        }  */
    }

    handleFileUpload = ({ target: { files } }: any) => {
        const { uploadedFiles, uploadedFileNames, oldFileNames, deletedFileNames } = this.state;
        let filesData: File[] = [];
        let fileNames: Array<any> = [];
        if (oldFileNames.length > 0) { 
            for (let i = 0; i < oldFileNames.length; i++) { 
                let file = oldFileNames[i].split('/'); 
                if(deletedFileNames.indexOf(file[file.length-1])===-1 ) { 
                    fileNames.push(file[file.length-1]);
                }
            }
        }
        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) { 
                filesData.push(uploadedFiles[i]); 
                const fileSplit = uploadedFiles[i].name.split('.'); 
                const fileExtension = fileSplit[fileSplit.length - 1];
                //const fileExtension = uploadedFiles[i].name.split('.')[1];
                //fileNames.push(uploadedFileNames[i]);
                fileNames.push(uploadedFiles[i].name.split('.')[0] + '~~' + uploadedFiles[i].lastModified + '.' + fileExtension);
            }
        }
        for (let i = 0; i < files.length; i++) {
            if (uploadedFileNames.indexOf(files[i].name) < 0) {  
                const fileSplit = files[i].name.split('.'); 
                const fileExtension = fileSplit[fileSplit.length - 1];
                //const fileExtension = files[i].name.split('.')[1];
                filesData.push(files[i]);
                //fileNames.push(files[i].name);
                fileNames.push(files[i].name.split('.')[0] + '~~' + files[i].lastModified + '.' + fileExtension);
            }
        }
        this.setState({
            uploadedFiles: filesData,
            uploadedFileNames: fileNames
        });
    } 
    removeItem = (index: any) => {
        const allFileNames = this.state.uploadedFileNames; 
        const oldFileNames = this.state.oldFileNames; 
        let deletedFileNames = this.state.deletedFileNames; 
        if(oldFileNames.indexOf(allFileNames[index])!==-1) {
            deletedFileNames.push(allFileNames[index]);
            allFileNames.splice(index, 1);
            this.setState({
                uploadedFileNames: allFileNames,
                deletedFileNames: deletedFileNames
            });
            return;
        }
        const allFiles: File[] = [];  
        let start = (oldFileNames.length-deletedFileNames.length); 
        let filesRemove = (index - start);  
        for (let i = 0; i < this.state.uploadedFiles.length; i++) { 
            if (i !== filesRemove) {  
                allFiles.push(this.state.uploadedFiles[i]);
            }
        }  
        allFileNames.splice(index, 1);  
        this.setState({
            uploadedFileNames: allFileNames,
            uploadedFiles: allFiles,
            deletedFileNames: deletedFileNames
        });
    } 
    setFileNames = () => {
        let { uploadedFileNames, editFileName, editFileNameIndex } = this.state;
        if(editFileNameIndex) {
            let newFileNames = uploadedFileNames;
            const fileExtension = this.state.uploadedFiles[editFileNameIndex].name.split('.')[1];
            uploadedFileNames.forEach((fileName, index) => {
                if (index === editFileNameIndex && editFileName) {
                    newFileNames.splice(editFileNameIndex, 1, `${editFileName}.${fileExtension}`);
                    this.setState({
                        uploadedFileNames: newFileNames,
                        editFileNameIndex: -1
                    });
                } else {
                    this.setState({
                        editFileNameIndex: -1
                    });
                }
            });
        }
    } 
    renderListItem = (index: number, fileName: string) => {  
        const { oldFileNames, uploadedFiles, deletedFileNames } = this.state; 
        const fileSplit = fileName.split('.'); 
        let imageThumb = '';
        let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1]; 
        switch(fileSplit[fileSplit.length-1].toLowerCase()) {
            case 'pdf': imageThumb = pdfThumb; break;
            case 'doc': case 'docx': imageThumb = wordThumb; break; 
            case 'xlx': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
            case 'txt': imageThumb = textThumb; break;
            case 'mp4': imageThumb = videoThumb; break;
            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ 
                if(oldFileNames.indexOf(fileName)!==-1) {
                    imageThumb = S3_URL +'/follow_ups/' + fileName;  
                } else {
                    let totalIndex = (oldFileNames.length-deletedFileNames.length);  
                    let currentImageIndex = index - totalIndex; 
                    if(uploadedFiles[currentImageIndex]) {
                        imageThumb = URL.createObjectURL(uploadedFiles[currentImageIndex]);
                    }
                }
                break;
            default: imageThumb = ''; this.invalidFile++; break; 
        } 
       /*  if(this.invalidFile>0) {
            showAlert(`Sorry, Some file(s) CANNOT be upload.`, 'file-danger-alert-invalidexe', 'danger',null,true,4500);   
            return;
        } */
        return imageThumb && <span className="file-upload"><span className="images"><div key={index} style={displayImage} className="image-wrapper"> 
                <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.removeItem(index) }
                /> 
        <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={tooltip}> <strong>{tooltip}</strong> </Tooltip> } >
            <img
                height="75px"
                width="90px"
                style={{ padding: '5px' }}
                className="thumbnail-image" 
                src={imageThumb} alt="SafeConnect"
            />
        </OverlayTrigger>
        </div></span></span>;   
    } 
    handleSubmit = ({ printedName, actualCompletionDate, comments }: any) => {  
        const { state: { uploadedFiles, uploadedFileNames }, props: { instance, userUID, investigativeId, forms } } = this; 
        const submittedValues = { printedName, actualCompletionDate,  }; 
        const answerValues = {printedName, actualDate:actualCompletionDate, comments: comments||'' };
        /* if (!printedName) {
            showAlert(EMPTY_FORM_MESSAGE, 'follow-up-failed', 'danger');
            return;
        } */
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'closecall-followup-failed', 'danger');
            return;
        }
        if (!this.state.signBase64) {
            showAlert('Please select signature.', 'follow-up-failed', 'danger');
            return;
        }
        return this.props.history && updateFollowUp(answerValues, this.state.signBase64, userUID, instance.props.UUID, this.props.history, uploadedFiles, uploadedFileNames, investigativeId, forms);
    }

    handleFileDelete = () => {
        this.setState({ uploadedFile: null, isSignatureUploaded: false });
    }

    renderSignaturePad = () => {
        this.setState({ showModal: true });
    }

    refs!: {
        acceptorSignature: typeof SignaturePad;
    };
    saveSignature = (file: any, acceptorSignature: any) => { 
        /* this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: acceptorSignature,
            signNew: false
        }); */
        this.setState({
            showModal: false,
            isSignatureUploaded: true,
            signBase64: acceptorSignature, 
            uploadedFile: file
        });
    }
    saveGeneratedSignature = (file: any, base64: any) => { 
        /* this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: base64,
            signNew: false
        }); */
        this.setState({
            showModal: false,
            isSignatureUploaded: true,
            signBase64: base64,
            uploadedFile: file
        });
    }
    /* saveSignature = async () => {
        const acceptorSignature = this
            .refs
            .acceptorSignature
            .toDataURL();
        const file = await base64ToFile(acceptorSignature, 'user-signature.png').then(function (file) {
            return file;
        });
        this.setState({
            showModal: false,
            isSignatureUploaded: true,
            signBase64: acceptorSignature
        });
        if (this.state.useDefault) {
            this.setState({
                uploadedFile: this.props.userInstance.props.signatureUrl,
            });
            showAlert('Signature Updated Successfully.', 'signature-update', 'success-alert');
        } else {
            this.setState({
                uploadedFile: file,
            });
            showAlert('Signature Updated Successfully.', 'signature-update', 'success-alert');
        }
    }

    saveGeneratedSignature = async (base64: any) => {
        const file = await base64ToFile(base64, 'user-signature.png').then(function (file) {
            return file;
        });
        this.setState({
            showModal: false,
            isSignatureUploaded: true,
            signBase64: base64,
            uploadedFile: file
        });
        showAlert('Signature Updated Successfully.', 'signature-update', 'success-alert');
    } */
    clearState = () => {
        this.setState({
            uploadedFiles: [],
            uploadedFileNames: [],
            editFileNameIndex: -1,
            editFileName: '',
            editedFileNames: [],
            deletedFileNames: [],
            oldFileNames: []
        });
    }
    closeModal = async () => {
        await this.clearState();
        this.setState({ showFormModal: false, isSignatureUploaded: false });
    }

    /* renderAutoGenerateSignature = () => {
        if (this.props.userInstance) {
            return <AutoGenerateSignature
                name={`${this.props.userInstance.props.firstName} ${this.props.userInstance.props.lastName}`}
                toDataURL={this.saveGeneratedSignature}
            />;
        }
        return '';
    } */

    renderSignatureInput = () => {
        const { userInstance } = this.props; 
        if(!this.state.defaultUserSignatureUrl && this.state.useDefault  ) {
            return <Col sm={6} style={{ color: 'green' }}>
            {/* <img height="100%" width="100%" style={{ border: '1px solid rgb(210, 210, 210)', backgroundColor: 'white', width: '100%', borderRadius: '10px', height: '56px', }}
                src={this.state.defaultUserSignatureUrl || ''} />  */}
            <div style={{ textAlign: 'right', paddingTop: '5px' }} >
                <button
                    type="button"
                    onClick={() => this.setState({ useDefault: false, uploadedFile:'', signBase64: '', isSignatureUploaded: false  })}
                    style={newSignButton} >
                    Sign New
                </button>
            </div>
        </Col>;
        }
        //if (userInstance && userInstance.props.signatureUrl && this.state.useDefault) { 
        if (this.state.defaultUserSignatureUrl && this.state.useDefault) {
            return <Col sm={6} style={{ color: 'green' }}>
                <img height="100%" width="100%" style={{ border: '1px solid rgb(210, 210, 210)', backgroundColor: 'white', width: '100%', borderRadius: '10px', height: '56px', }} alt="SafeConnect"
                    src={this.state.defaultUserSignatureUrl} />
                {/* <span style={savedSignature} >Saved Signature</span> */}
                <div style={{ textAlign: 'right', paddingTop: '5px' }} >
                    <button
                        type="button"
                        onClick={() => this.setState({ useDefault: false, signBase64: '', isSignatureUploaded: false })}
                        style={newSignButton} >
                        Sign New
                    </button>
                </div>
            </Col>;
        } 
        if (!this.state.useDefault && !this.state.isSignatureUploaded) {
            return <Col sm={8} className="input e-signature investigation-follow-up">
                {  userInstance &&
                    <ManualSignature manualSignsm={12}
                        autoSignTitle={`${this.props.userInstance && this.props.userInstance.props.firstName} ${this.props.userInstance && this.props.userInstance.props.lastName}`}
                        saveSignature={this.saveSignature}
                        saveGeneratedSignature={this.saveGeneratedSignature}
                        className={'signature'}
                        autoGenerateProps={ { className: 'jsa-signature-generator' } }
                    />  }
            </Col>
        }
        /* if (!this.state.useDefault && !this.state.isSignatureUploaded) {
            return [<Col sm={6} className="m-signature e-signature investigation-follow-up" >
                {this.state.isAutoGenerate ?
                    this.renderAutoGenerateSignature()
                    :
                    [<SignaturePad width="100%" ref="acceptorSignature" clearButton={true} />,
                    <span style={{ padding: '0 10px' }} />,
                    <a
                        style={Object.assign({ padding: '5px 0' }, newSignButton)}
                        className="btn btn-default"
                        onClick={this.saveSignature}
                    >
                        Save
              </a>]}
            </Col >,
            this.state.isAutoGenerate ?
                <Col sm={{span: 6, offset: 4}} style={{ textAlign: 'center' }} >
                    <a
                        style={autoSignButton}
                        className="btn btn-default"
                        onClick={() => this.setState({ isAutoGenerate: false })}
                    >
                        Sign Manually
                    </a>
                </Col> 
                : null,

                <Col  sm={{span: 6, offset: 4}} style={{ textAlign: 'center' }} >
                    <a
                        style={autoSignButton}
                        className="btn btn-default"
                        onClick={() => this.setState({ isAutoGenerate: true })}
                    >
                        Auto Generate Signature
                    </a>
                </Col>];
        } */
        if (this.state.signBase64 && this.state.isSignatureUploaded) {
            return <Col sm={6} style={{ color: 'green' }}>
                <img height="100%" width="100%" style={{ border: '1px solid rgb(210, 210, 210)', backgroundColor: 'white', width: '100%', borderRadius: '10px', height: '56px', }} alt="SafeConnect"
                    src={this.state.signBase64} />
                <div style={{ textAlign: 'right', paddingTop: '5px'}} >
                    <button
                        type="button"
                        onClick={() => this.setState({ useDefault: false, uploadedFile:'', signBase64: '', isSignatureUploaded: false })}
                        style={newSignButton} >
                        Sign New
                    </button>
                   {/*  <button
                        type="button"
                        onClick={() => {
                            showAlert('Using Default Signature.', 'signature-update', 'success-alert');
                            this.setState({ useDefault: true });
                        }}
                        style={newSignButton} >
                        Default
                    </button> */}
                </div>
            </Col>;
        }
    }

    showFormModal = () => { 
        return (
            <Modal size="lg"
                className="confirmation-modal"
                show={this.props.showFormModal}
                onHide={() => hideFollowUpFormModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#88A4CE' }} >Investigative Follow Up Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert id="follow-up-failed" className="danger-alert" />
                    <Alert id="signature-update" className="success-alert" />
                    <Form
                        loader={< SubmissionLoader />}
                        onSubmit={this.handleSubmit}
                        model="forms.followUpForm">
                        <Row className="show-grid" style={{ paddingBottom: '20px' }} >
                            <Label style={{ textAlign: 'right' }} htmlFor="printedName">Printed Name:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    id="printedName"
                                    type="text"
                                    placeholder="Enter printed name..."
                                    model=".printedName" />
                            </Col>
                        </Row>
                        <Row className="show-grid" style={{ paddingBottom: '20px' }} >
                            <Label style={{ textAlign: 'right' }} htmlFor="files">Signature:</Label>
                            {this.renderSignatureInput()}
                        </Row>
                        <Row className="show-grid" style={{ paddingBottom: '20px' }} >
                            <Label style={{ textAlign: 'right' }} htmlFor="printedName">Actual Completion:</Label> 
                            <CustomCol style={{ marginLeft: '-20px' }}> 
                                <RRFInput
                                    id="actualCompletionDate"
                                    type="date"
                                    placeholder="Choose actual completion..."
                                    model=".actualCompletionDate" 
                                    defaultValue={this.state.actualCompletionDate} 
                                    //mindate={ instance.props.TargetDate && instance.props.TargetDate!='None'? format(instance.props.TargetDate, 'yyyy-MM-dd') : null }
                                    mindate={format(new Date(), 'yyyy-MM-dd')}
                                    maxdate={ format(new Date(), 'yyyy-MM-dd') }   
                                />
                            </CustomCol>
                        </Row> 
                        <Row className="show-grid" style={{ paddingBottom: '20px' }}>
                            <Label style={{ textAlign: 'right' }} htmlFor="printedName">Comments:</Label> 
                            <CustomCol style={{ marginLeft: '-20px' }}>
                                <RRFInput
                                    id="comments"
                                    type="textarea"
                                    placeholder="Enter comments..."
                                    model=".comments" 
                                />
                            </CustomCol>
                        </Row>
                        <Row className="show-grid" style={{ paddingBottom: '20px' }}>
                            <Label style={{ textAlign: 'right' }} htmlFor="title">Files:</Label> 
                            <CustomCol style={{ marginLeft: '-20px' }}>
                                <AttachementInput
                                    model=".attachments"
                                    multiple={false}
                                    id="upload-images" 
                                    uploadedImages={[]}
                                />
                            </CustomCol> 
                        </Row>
                        {/* <Row className="show-grid" style={{ paddingBottom: '20px' }} >
                            <Label style={{ textAlign: 'right' }} htmlFor="printedName">Files:</Label> 
                            <CustomCol>
                                <div className="file-upload" style={{ display: 'inline-block' }} >
                                    <input
                                        id="files"
                                        type="file"
                                        multiple
                                        style={fileUpload}
                                        onChange={this.handleFileUpload} 
                                        accept={FILESALLOWEDEXE.join(', ')} 
                                    />
                                    <label
                                        htmlFor="files"
                                        style={{ padding: '2px 15px', marginLeft: '15px', }}
                                        className="upload-image">
                                        Select File(s)
                                    </label>
                                </div>
                            </CustomCol>
                        </Row> */}
                        
                        {/* {this.state.uploadedFileNames.length > 0 ? <Row className="show-grid" style={{ paddingBottom: '20px' }}>  
                            <Col sm={12} xs={12} className="input">
                                {this.state.uploadedFileNames.length > 0 ? <div style={{ border: '1px solid #ddd',borderRadius: '15px' }}>
                                    <PerfectScrollbar>
                                        <ListGroup style={this.state.uploadedFileNames.length > 0 ? listStyle : {}}>
                                            {this.state.uploadedFileNames.map((fileName, index) => { 
                                                return this.renderListItem(index, fileName); 
                                            })}
                                        </ListGroup>
                                    </PerfectScrollbar>
                                </div> : ''}
                            </Col>
                        </Row> : ''} */}
                        <div
                            className="form-button"
                           /*  style={{
                                marginTop: '50px'
                            }} */>
                            <Button
                                style={{
                                    color: '#5479AF',
                                    border: '1px solid #5479AF',
                                    backgroundColor: 'white'
                                }}
                                onClick={() =>  hideFollowUpFormModal()}
                                type="button">
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp; Cancel
                          </Button>
                            <Button
                                style={{
                                    color: '#FFFFFF',
                                    border: 'none',
                                    backgroundColor: '#26a65b'
                                }}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp; Submit
                          </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
   /*  renderImages = () => {
        const { instance } = this.props;
        if ((instance.props.imageUrls || []).length <= 0) {
            return <img className="no-image" src={noImage} />;
        } 

        return instance.props.imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    } */
    renderFiles() { 
        const { instance } = this.props;
        if(!instance) return null;  
        let imageData = instance.props.imageUrls  || []; 
        if(imageData.length > 0) {
            return imageData.map((imageObj, index) => {
                let imageThumb = imageObj;  
                const fileSplit = imageObj.split('/')[imageObj.split('/').length - 1]; 
                switch( fileSplit.split('.')[fileSplit.split('.').length - 1] ) { 
                    case 'pdf': 
                        imageThumb = pdfThumb;
                        break;
                    case 'doc': case 'docx': 
                        imageThumb = wordThumb;
                        break; 
                    case 'xlx': case 'csv': case 'xlsx': 
                        imageThumb = excelThumb;
                        break; 
                    case 'txt': 
                        imageThumb = textThumb;
                        break;
                    case 'mp4': 
                        imageThumb = videoThumb;
                        break; 
                } 
                let file = imageObj.split('/')[imageObj.split('/').length-1].split('~~')[0];
                let fileExtension = imageObj.split('.')[imageObj.split('.').length-1]; 
                return <span className="file-upload linker" onClick={() => this.openFile(imageObj)}><span className="images"><div key={index} style={displayImage} className="image-wrapper"> 
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={file + '.' + fileExtension}> <strong>{file + '.' + fileExtension}</strong> </Tooltip> } >
                    <img
                        height="75px"
                        width="90px"
                        style={{ padding: '5px' }}
                        className="thumbnail-image" 
                        src={imageThumb} alt="SafeConnect"
                    />
                </OverlayTrigger>
                </div></span></span>; 
            });
        } else {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;  
        }
    }
    openFile = (imageObj: any) => {
        window.open(imageObj, "_blank");
    } 
    onSelectAssigned = (value: any) =>{
        this.setState({assignedToUser: value, showSubmitForAssigned: true})
    }
    submitAssigned = async () => {
        this.setState({showAssignedLoader: true});
        const { instance } = this.props; 
        const answerValues = {assignedTo: this.state.assignedToUser, followUpId: instance.props.UUID, assignedBy: UserModel.getUserUID(), action: instance.props.Action, targetDate:instance.props.TargetDate, company: UserModel.getCompanyName() }
        await updateFollowUpAdditionalDet(answerValues);
        this.setState({showAssignedLoader: false, showSubmitForAssigned: false, assignedToUser: ''});
    }
    renderContent = () => {
        if (!this.props.instance) {
            return <div></div>;
        }
        const { instance } = this.props; 
        if (instance.props.Complete === 1) { 
            //let AssignedTo = instance.props.AssignedTo!='None'?instance.props.AssignedTo:'';
            //let assignedToName = ( UserModel.list().length && AssignedTo && UserModel.get(AssignedTo).props.firstName ) || 'NA';
            //let assignedToName = instance.props.AssignedTo!=='None' ? instance.props.AssignedTo : '';
            let assignedToName = 'NA';
            return <div className="incident-investigative-report report-details">
                <Title text="Investigative Follow Up Details" />
                <Details label="Created By:">{instance.props.CreatedBy || 'NA'}</Details>
                <Details label="Groups Assigned To:">{(instance.props.AssignedToProject || 'NA').toString()}</Details>
                <Details label="Assigned To:">{assignedToName}</Details>
                <Details label="Target Date:">{instance.props.TargetDate || 'NA'}</Details>
                <Details label="Action:">{instance.props.Action || 'NA'}</Details>
                <Details label="Printed Name:">{instance.props.PrintedName || 'NA'}</Details>
                <Details label="Actual Completed Date:">{instance.props.ActualCompletedDate || 'NA'}</Details>
                <Details label="Comments:">{instance.props.Comments || 'NA'}</Details>
                <Details label="Status:"><div title="Completed" style={{ display: 'flex' }} >
                    Completed &nbsp;
                    <FATick />
                </div></Details>
                <Details label="Signature:">
                    <img
                        style={{ border: '1px solid #e2e2e2', backgroundColor: 'white' }}
                        height="100px"
                        width="100px"
                        src={instance.props.SignatureUrl} alt="SafeConnect"
                    />
                </Details>
                <Details label="Files:">{this.renderFiles()}
                </Details>
                <Row className="show-grid">
                    <Col
                        xs={12}
                        style={{
                            textAlign: 'center',
                            color: '#969696'
                        }}
                        sm={6}>Completed By:</Col>
                    <Col
                        xs={12}
                        style={{
                            textAlign: 'center',
                            color: '#969696'
                        }}
                        sm={6}>Date Submitted:</Col>
                </Row >
                <Row className="show-grid">
                    <Col style={{ textAlign: 'center' }} sm={6} xs={6}>{instance.props.CompletedBy || 'NA'}</Col>
                    <Col style={{ textAlign: 'center' }} sm={6} xs={6}>{instance.props.DateSubmitted || 'NA'}</Col>
                </Row >
            </div>;
        } else {
            return (
                <div className="incident-investigative-report report-details">
                    <Title text="Investigative Follow Up Form" />
                    <AlertModal id="follow-up-success" />   
                    <Alert id="follow-up-failed" className="danger-alert" />
                    {this.state.showAssignedLoader && < SubmissionLoader />}
                    {this.showFormModal()}
                    <Details label="Created By:">{instance.props.CreatedBy || 'NA'}</Details>
                    <Details label="Action:">{instance.props.Action || 'NA'}</Details>
                    <Details label="Groups Assigned To:">
                        {(instance.props.AssignedToProject || 'NA').toString()}
                    </Details>
                    <Details label="Assigned To:" >  
                    <Col xs={10} sm={10} style={{marginLeft:'-20px', marginTop:'-5px'}}>
                        <RRFInput
                            id="assignedTo"
                            type="dropdown" 
                            menuItems={this.state.usersList}
                            placeholder="Select assigned to..."
                            model=".assignedTo" 
                            onSelect={this.onSelectAssigned}
                            defaultValue={instance && instance.props.AssignedTo}
                        /></Col>

                        <Col xs={2} sm={2} >   
                            {this.state.showSubmitForAssigned &&  <> <OverlayTrigger
                                   placement="top"
                                   delay={{ show: 250, hide: 400 }}
                                    overlay={ <Tooltip id="button-tooltip ">
                                    Click here to submit
                                </Tooltip>}
                                >
                                    <span style={{fontSize:'27px', color:'green', cursor:'pointer'}} onClick={this.submitAssigned}><i className="fa fa-cloud-upload" aria-hidden="true"></i> </span>
                            </OverlayTrigger>   </>}
                        </Col>

                        {/* {this.state.showSubmitForAssigned && <Col xs={2} sm={2} title="Click here to submit"><span style={{fontSize:'27px', color:'green', cursor:'pointer'}} onClick={this.submitAssigned}><i className="fa fa-check" aria-hidden="true"></i> </span></Col> } */}

                        {/* <RRFInput
                            id="assignedTo"
                            type="text"
                            defaultValue={this.props.userInstance && this.props.userInstance.props.firstName}
                            model=".assignedTo" 
                        />   */}
                    </Details>
                    <Details label="Target Completion:">{instance.props.TargetDate || 'NA'}</Details> 
                    <Row className="show-grid" style={{ display: 'flex', alignItems: 'center' }} >
                        <Col
                            xs={6}
                            style={{
                                textAlign: 'right',
                                color: '#969696'
                            }}
                            sm={6}>Complete:</Col>
                        <Col sm={6}>
                            <button
                                onClick={() => showFollowUpFormModal()}
                                style={{
                                    textAlign: 'center',
                                    color: 'white',
                                    backgroundColor: '#26a65b',
                                    border: 'none',
                                    borderRadius: '10px',
                                    height: '35px',
                                    width: '160px',
                                }}
                            >Mark as Complete</button> </Col>
                    </Row >
                </div >
            );
        }
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="FollowUpPage"
                    promise={this.promise}
                    loader={< Loader />}
                    error={< ErrorPage />}
                    content={this.renderContent()} />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const instance = FollowUpModel.getFollowUp(ownProps.match.params.id); 
    const userUID = state.login.get('userUID');
    const userInstance = UserModel.getUserByUID(userUID); 
    return {
        instance,
        formID: ownProps.match.params.id,
        showFormModal: state.modal.get('followUpModal'),
        userInstance, 
        userUID, investigativeId: ownProps.match.params.investigativeId || '', forms: state.forms,
    };
}

export const FollowUpPage = withRouter(connect<IFollowUpPageProps, any, any>(mapStateToProps)(FollowUpPageImpl));

const Details = ({ label, children, sm = 6 }: any) => {
    return <Row className="show-grid">
        <Col
            xs={6}
            style={{
                textAlign: 'right',
                color: '#969696'
            }}
            sm={sm}>{label}</Col>
        <Col sm={sm} xs={6} className="row">{children || 'NA'}</Col>
    </Row >;
};
  
const newSignButton: React.CSSProperties = {
    color: 'white',
    border: 'none',
    backgroundColor: '#26a65b',
    width: '90px',
    borderRadius: '5px',
    padding: '5px 0',
    marginLeft: '10px' 
};

/* const tickIcon: React.CSSProperties = {
    width: '25px',
    height: '25px',
    backgroundColor: '#26a65b',
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    color: 'white',
}; */

/* const fileUpload: React.CSSProperties = {
    height: '38px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer'
}; */

/* const fileDisplay: React.CSSProperties = {
    border: '1px solid #d2d2d2',
    lineHeight: '30px',
    color: 'grey',
    display: 'flex',
    margin: '0 20px',
    alignContent: 'center',
    padding: 0
};

const fileName: React.CSSProperties = {
    display: 'inline-block',
    width: '95%',
    fontSize: '10px',
    padding: '0 10px',
    borderRight: '1px solid #d2d2d2'
};

const autoSignButton: React.CSSProperties = {
    color: 'white',
    border: 'none',
    backgroundColor: '#26a65b',
    borderRadius: '5px',
    padding: '5px 15px',
    marginTop: '10px'
};

const CustomLabel = ({ children, htmlFor, required }: any) => {
    return <Label className="custom-notification-label" htmlFor={htmlFor} required={required}>{children}</Label>;
}; */

const CustomCol = ({ children, style }: any) => {
    return <Col sm={6} xs={6} /* style={(style)} */ className="input custom-notification-input">{children}</Col>;
};
 
/* const renameInput: React.CSSProperties = {
    border: 'none',
    fontSize: '12px',
    padding: 0,
    boxShadow: 'none',
    height: 'auto',
    width: '70%',
    display: 'inline-block',
    marginRight: '5px',
}; */

/* const listStyle: React.CSSProperties = {
    maxHeight: '143px',
    color: 'grey',
    fontSize: '12px',
    marginBottom: 0,
}; */

/* const listItemStyle: React.CSSProperties = {
    outline: '1px solid #ddd',
    border: 'none', margin: '17px'
};  */
const displayImage: React.CSSProperties = {
    width: '90px',
    height: '75px',
    margin: '0 10px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)'
};
