import { UserModel } from '../../model/UserModel';
import { IHistory, IForms } from '../../interfaces';
import { ListPage } from '../reusableComponents/ListPage'; 
import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { JSAModel } from '../../model/JSAModel'; 
import { getAllJSAForm } from '../../services/jsaFormService';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ListItem } from './ListItem';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import { Pagination } from '../reusableComponents/Pagination';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess } from '../../actions/loadingActions';
declare var require: any;
const queryString = require('query-string');

export interface IJSAFormListPageProps {
    jsaInstances: JSAModel[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    };
    totalPageCount?: number;
    forms: IForms;
    axiosPreviousToken: any;
}

export interface IJSAFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class JSAFormListPageImpl extends React.PureComponent<IJSAFormListPageProps, IJSAFormListPageState> {
    constructor(props: IJSAFormListPageProps | Readonly<IJSAFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'jsaNum', 2:'workToPerform', 3: 'projectName'  };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
    }

    getReportData = async (sortIndex='false') => {
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {};  
        if(sortIndex!=='false') {
            urlParams.sortIndexField = this.sortListing[sortIndex];
        }
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllJSAForm(urlParams, this.props.forms);
        setSuccess('AllListPage'); 
    }
    renderFormList = () => {
        const { jsaInstances } = this.props; 
        return (
            <> 
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
                <div className="jsa-list-wrapper">
                    {<ExcelExport formType="jsa" fileName="JSA Report" style={{paddingTop: '17px', paddingRight: '20px'}}/>} 
                    <ListPage
                        pageHeading={'JSA Reports'}
                        instances={jsaInstances}
                        rowHeadings={['Date Submitted','JSA N°', 'Title', 'Project', 'Participants Acknowledged/Total']}
                        listItemComponent={ListItem}
                        emptyInstancesMessage="No JSA reports found."
                        isSearchable={true}
                        searchableBy={['workToPerform', 'typeOfWork' , 'username', 'date', 'groupName', 'projectName','locationName']}
                        searchPlaceHolder="Enter project, group, location, title, work to perform to search..."
                        sm={[2, 2, 3, 2, 2]}
                        currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                        rowSortings={this.sortListing} 
                        promise={(sortIndex) => this.getReportData(sortIndex)}
                        forms={this.props.forms}
                        history={this.props.history }
                    />
                    { UserModel.checkUserAccess('jsa_add') && this.renderAddNewFormButton() }
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.promise(pageNumber)}
                        totalCount={this.props.totalPageCount}
                        Model={JSAModel as any} 
                        identifier="AllListPage"
                    />
                </div> 
            </>
        );
    }

    renderAddNewFormButton = () => { 
        return (<button className="add-new-report"
            onClick={() => { this.props.history?.push('/jsa-reports/create'); }}
        >
            +
        </button>
        );
    }

    render() {
        return (
            <BasePage className="extra-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="IncidentReportListPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={ this.renderFormList()}
                />; 
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const jsaInstances = JSAModel.list(state);
    return { jsaInstances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const JSAFormListPage = withRouter(connect< IJSAFormListPageProps, any, any>(mapStateToProps)(JSAFormListPageImpl));
