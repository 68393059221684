import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../../interfaces';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { ListPage } from '../../reusableComponents/ListPage';
import { Loader } from '../../reusableComponents/Loader';
import { ListItem } from './MOCPermanentDeviationListItem';
import { Pagination } from '../../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import {getAllMOCForms} from "../../../services/MocService";
import {MocPermanentModel} from "../../../model/MOCModel";
import { ExcelExport } from '../../reusableComponents/ExcelExport';
import { CommonFilter } from '../../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess} from '../../../actions/loadingActions'; 
declare var require: any;
const queryString = require('query-string');

export interface IMOCPermanentDeviationListPageProps {
    instances: MocPermanentModel[];
    history?: IHistory;
    totalPageCount: number;
    forms: IForms; 
    axiosPreviousToken: any;
}

export class MOCPermanentDeviationListImpl extends React.PureComponent<IMOCPermanentDeviationListPageProps, {}> {
    constructor(props: IMOCPermanentDeviationListPageProps | Readonly<IMOCPermanentDeviationListPageProps>) {
        super(props);
    }
    sortListing = { 0: 'date', 1: 'username', 2:'groupName', 3: 'completedMOCStage', 4: 'MOCApprovalStatus' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }
    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {};
        urlParams.sortIndexField = this.sortListing[sortIndex];  
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllMOCForms( urlParams, this.props.forms, 'mocPermanentDeviation' );
        setSuccess('AllListPage'); 
    }
    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report"
                    onClick={() => { history?.push('/moc-permanent-deviation/create'); }}
            >
                +
            </button>
        );
    } 

    renderContent = () => {
        const { instances } = this.props;
        return <div className="office-safety-wrapper">  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
            {<ExcelExport formType="mocPermanentDeviation" fileName="MOC - Permanent Deviation Report" />}
            <> <ListPage
                    pageHeading="MOC - Permanent Deviation List"
                    instances={instances}
                    rowHeadings={['Date Submitted', 'Originator', 'Group', 'Response', 'Approval' ]}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={[ 'date', 'username']}
                    searchPlaceHolder="Enter project, group, details of change requested,reason for change to search..."
                    emptyInstancesMessage="No MOC - Permanent Deviation report found."
                    sm={[2, 2, 2, 2, 2]}
                    currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                {this.renderAddNewFormButton()} 
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={MocPermanentModel as any}
                    identifier="PreExcavationChecklistReports"
                />
            </>
        </div>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="MOCPermanentDeviationReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const instances = MocPermanentModel.list();
    return { instances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const MOCPermanentDeviationList = withRouter(connect<IMOCPermanentDeviationListPageProps, any, any>
(mapStateToProps)(MOCPermanentDeviationListImpl));
