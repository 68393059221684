import { Action, CombinedState, combineReducers } from 'redux';
import { alertReducer } from './alertReducer';
import { modelReducer } from './modelReducer';
import { loginReducer } from './loginReducer';
import { loadingReducer } from './loadingReducer';
import { projectReducer } from './projectReducer';
import { groupReducer } from './groupReducer';
import { forms } from './formReducer';
import { modalReducer } from './modalReducer';
import { summaryReducer } from './summaryReducer';
import { miscellaneousReducer } from './miscellaneousReducer';
import { attributeReducers } from './attributeReducers';
import { reportReducers } from './reportReducer';
import { OrderedMap } from 'immutable';

let reducer = combineReducers({
    alert: alertReducer,
    modal: modalReducer,
    models: modelReducer,
    loading: loadingReducer,
    login: loginReducer,
    projects: projectReducer,
    attribute: attributeReducers,
    report: reportReducers,
    groups: groupReducer,
    formSummary: summaryReducer,
    miscellaneous: miscellaneousReducer,
    forms
});

export const rootReducer = (state: CombinedState<{ alert: any; modal: any; models: OrderedMap<string, unknown>; loading: any; login: any; projects: any; attribute: any; report: any; groups: any; formSummary: any; miscellaneous: any; forms: unknown; }> | undefined, action: Action<any>) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return reducer(state, action);
};   
