import * as React from 'react'; 
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import PropTypes from 'prop-types';
import { dispatch, showAlert } from '../../../utils/generalUtils';
import { actions } from 'react-redux-form';
import { Modal } from 'react-bootstrap';
import recordingURL from '../../images/recording.gif'; 
import { connect } from 'react-redux';
import { hideMicrophoneModal } from '../../../actions/modalActions';
import { RRFInput } from '../FormComponents/RRFInput';
import { Form } from './../FormComponents/Form';
import { IForms } from '../../../interfaces';

export interface IVoiceRecognitionProps { 
    id?: string;
    model?: any; 
    showModal?: Object;
    defaultValue?: any
    forms?: IForms;
}
export interface IVoiceRecognitionState { 
    isRecording: any;
    model: any;
    transcriptData: any
}
const Dictaphone = ({ onTranscriptChange, model }) => { 
    const { transcript, resetTranscript  } = useSpeechRecognition(); 
    // When `transcript` changes, invoke a function that will act as a callback to the parent (SearchBar)
    // Note of caution: this code may not work perfectly as-is. Invoking `onTranscriptChange` would cause the parent's state to change and therefore Dictaphone would re-render, potentially causing infinite re-renders. You'll need to understand the hook's behavior to mitigate appropriately.
    let transData = transcript; 
    React.useEffect(() => {
      onTranscriptChange(transData); 
    }, [transData]);  
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        showAlert('Your browser is not supporting speech recognition. Please contact administrator.', 'microphone-alert-modal', 'success');
    }  
    return null;
}
 
export class VoiceRecognitionModalImpl extends React.PureComponent<IVoiceRecognitionProps, IVoiceRecognitionState> {
    constructor(props: IVoiceRecognitionProps | Readonly<IVoiceRecognitionProps>) {
        super(props); 
        this.onTranscriptChange = this.onTranscriptChange.bind(this);
        this.state = { isRecording: false, model: '', transcriptData: '' }; 
    }
    getChildContext() {
        return { formModel: 'forms.commonForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 

    onTranscriptChange(transcript){  
        if(this.props.model) { 
            this.setState({ transcriptData: this.props.defaultValue + transcript });  
        }
    } 

    componentDidUpdate() { 
        this.startReg(this.props.model);
    } 
    startReg(model) { 
        this.setState({  isRecording: true, model: model })
        SpeechRecognition.startListening({ continuous: true }); 
    }
    stopReg(model) {
        SpeechRecognition.stopListening();
        this.setState({ isRecording: false, model: '' });    
     }
    duplicateMicrophone() {
        showAlert('Microphone already in use. Please stop the existing process to continue.', 'microphone-alert-modal', 'success');
    } 
    
    handleConfirm = () => {
        SpeechRecognition.stopListening();  
        dispatch(actions.change(this.props.model, this.props.forms?.commonForm.microphoneText));  
        hideMicrophoneModal();
    }
    hideVoiceRecognitionModal = () => { 
        SpeechRecognition.stopListening();  
        hideMicrophoneModal();
    }
    render() { 
        return <>
        <Modal
            show={this.props.showModal ? true : false}
            onHide={() => this.hideVoiceRecognitionModal()} 
        >
            <Modal.Header   closeButton>
                <Modal.Title id="contained-modal-title">
                    Listening... 
          </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '160px', padding: 0 }} > 
                <Form model="forms.commonForm" > 
                    <div style={{padding: '10px',}}> 
                        <Dictaphone onTranscriptChange={this.onTranscriptChange} model={this.props.model} />
                        <RRFInput
                            id="microphoneText"
                            textAreaRows="5"
                            placeholder="Please speak.."
                            type="textarea"
                            model=".microphoneText"  
                            defaultValue={ this.state.transcriptData } 
                        />
                    </div> 
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }} className="form-button">
                <button style={confirmButton} onClick={this.handleConfirm} >
                    Submit
                </button> 
            </Modal.Footer>
        </Modal ></>;
    }
} 

export function mapStateToProps(state: any) { 
    if (!state.modal.get('microphoneModal')) {
        return {};
    } 
    return {
        model: state.modal.get('microphoneModal').model, 
        defaultValue: state.modal.get('microphoneModal').defaultValue, 
        showModal: state.modal.get('microphoneModal'), 
        forms: state.forms,
    };
}

export const VoiceRecognitionModal = (connect< IVoiceRecognitionProps, any, any>(mapStateToProps)(VoiceRecognitionModalImpl));

const confirmButton: React.CSSProperties = {
    border: 'none',
    height: '30px',
    color: 'white',
    background: '#26a65b',
    borderRadius: '5px',
}; 
