import '../../commons.scss'; 
import * as React from 'react';
//import EasyTransition from 'react-easy-transition'; 
import { NavBar } from './NavBar';
/* import { showWeatherModal } from '../../actions/miscellaneousActions'; */
import { connect } from 'react-redux';
import { IWeatherDetails } from '../../interfaces'; 
import { ConfirmationModal } from '../../components/reusableComponents/ModalComponents/ConfirmationModal'; 
/* import { COVID19AssessmentModal } from '../Covid19/Covid19AssessmentModal'; */
import { CustomNotificationFormModal } from './CustomNotificationForm'; 
import { CloseCallFollowUpListModal } from '../CloseCall/CloseCallFollowUpListModal';
import { CloseCallFollowUpFormModal } from '../CloseCall/CloseCallFollowUpFormModal';
/* import { WeatherDetails } from './ModalComponents/WeatherDetailsModal'; */ 
import { VoiceRecognitionModal } from '../reusableComponents/VoiceRecognition/VoiceRecognitionModal';

export interface IBasePageProps {
    style?: React.CSSProperties;
    className?: string;
    weatherDetails?: IWeatherDetails;
    children: any
}

export interface IBasePageState {
    showModal: boolean;
}

export class BasePageImpl extends React.Component<IBasePageProps,
    IBasePageState> {
    constructor(props: IBasePageProps | Readonly<IBasePageProps>) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    toggleModalVisibility = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    render() {
        return (
            <div className="background"> 
                <NavBar />
                <ConfirmationModal/> 
                {/* <COVID19AssessmentModal /> */}
                <CustomNotificationFormModal />
                <CloseCallFollowUpListModal />
                <CloseCallFollowUpFormModal />
                
                <VoiceRecognitionModal />
                {/* <WeatherDetails /> */}
                <div
                    style={this.props.style}
                    id="basepage"
                    className={`basepage ${this.props.className}`}>
                        {this.props.children}
                    {/* <EasyTransition
                        path={location.pathname}
                        initialStyle={{
                            opacity: 0
                        }}
                        transition="opacity 0.3s ease-in, color 0.5s ease-in"
                        finalStyle={{
                            opacity: 1
                        }}>
                        {this.props.children}
                    </EasyTransition> */}
                </div>
                {/* <div
                    title="Click to view weather conditions in your area"
                    className="weather-button-container"
                    onClick={() => showWeatherModal()}>
                    <img
                        src={`${this.props.weatherDetails
                            // tslint:disable-next-line
                            ? `https://s3-us-west-2.amazonaws.com/safeconnect-beta/sta
                            tic-files/weather-icons/${this.props.weatherDetails.icon}.png`
                            : 'https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif'}`} />
                    <div className="info">
                        {this.props.weatherDetails
                            ? `${this.props.weatherDetails.temp}`
                            : 'Loading...'}
                        &#8451;
                    </div>
                </div> */}
            </div >
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        weatherDetails: state
            .miscellaneous
            .get('weatherDetails'),
        showWeatherModal: state
            .miscellaneous
            .get('showWeatherModal')
    };
}

export const BasePage = connect<{}, {},
    IBasePageProps>(mapStateToProps)(BasePageImpl);
