import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideConfirmationModal } from '../../../actions/modalActions'; 
import { Button } from '../../reusableComponents/FormComponents/Button';
import {IHistory } from '../../../interfaces'; 
import { withRouter } from 'react-router-dom';
import { convertText } from '../../../utils/generalUtils'; 

export interface IConfirmationModalProps {
    id?: string; 
    history?: IHistory;
    showConfirmationModal: boolean; 
    instance: any;
    onConfirm?: (attributeData: any) => void;
}

export interface IConfirmationModalState {
    showModal: boolean;
}

export class ConfirmationModalImpl extends React.PureComponent<IConfirmationModalProps, IConfirmationModalState> {
    constructor(props: IConfirmationModalProps | Readonly<IConfirmationModalProps>) {
        super(props); 
        this.state = { showModal: props.showConfirmationModal || false };
    }

    hideModal(ins?: any){
        hideConfirmationModal(ins)
    }

    showModal = () => {
        const { props: { instance, showConfirmationModal } } = this;  
        if(!showConfirmationModal) {
            return (<div></div>);
        }   
        return (
            <Modal backdrop="static" keyboard={false} className="confirmation-modal" show={this.props.showConfirmationModal} onHide={() => hideConfirmationModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }}>{instance.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    {instance.question}
                    <div className="modal-buttons row">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={() => this.processModal() }
                        >{instance.requestFrom==='covidSelfAssessmentConfirmation' ? convertText('yes'): 'Yes'}</Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => hideConfirmationModal()}
                        >{instance.requestFrom==='covidSelfAssessmentConfirmation' ? convertText('no'): 'No'}</Button>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }

    processModal() {
        const { props: { instance } } = this;
        switch(instance.requestFrom) {
            case 'covidSelfAssessmentConfirmation':
                hideConfirmationModal({isConfirmed: true})
                break;
            default: break;
        }
    }

    render() {
        return this.showModal();
    }
}

export function mapStateToProps(state: any) {
    return { 
        showConfirmationModal: state.modal.get('showConfirmationModal'), 
        instance: state.modal.get('confirmationInstance')
    };
}

export const ConfirmationModal = withRouter(connect<IConfirmationModalProps, any, any>(mapStateToProps)(ConfirmationModalImpl));
