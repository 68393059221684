import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from 'react-redux-form';
import { IHistory } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage'; 
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { Pagination } from '../reusableComponents/Pagination'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';  
import { ListItem } from './ListItem'; 
import { getCovid19Report } from '../../services/covid19Service';
import { Covid19Model } from '../../model/Covid19Model'; 
import PropTypes from 'prop-types';
import { Alert } from '../Alert';
import { Col, Row } from 'react-bootstrap'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { format, addDays, subDays, differenceInCalendarDays, addMonths, subMonths} from 'date-fns'; 
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { sortBy, dispatch, showAlert, checkValidDate } from '../../utils/generalUtils';  
import { setLoading, setSuccess } from '../../actions/loadingActions'; 
declare var require: any;
const queryString = require('query-string');

export interface ICovid19ListPageProps {
    covid19FormInstances: Covid19Model[];
    totalPageCount?: number;
    history?: IHistory; 
    forms: any;
}

export interface ICovid19ListPageState {
    showDetails: boolean;
    formId: string;
    currentDate: string;
    defaultDate: string;
    isFilterClicked: boolean;

    endDateMaxDate: any;
    endDateMinDate: any;
    endDateDefault: any;
    startDateMinDate: any;
    startDateMaxDate: any;
    startDateDefault: any;
}

export class Covid19ListPageImpl extends React.PureComponent<ICovid19ListPageProps, ICovid19ListPageState> {
    constructor(props: ICovid19ListPageProps | Readonly<ICovid19ListPageProps>) {
        super(props); 
        /* let today =  format(new Date(), 'yyyy-MM-dd') 
        let minDate = addDays(format(subMonths(today, 1), 'yyyy-MM-dd'),1);  */
        let today =  format(new Date(), 'yyyy-MM-dd');
        let minDate = addDays(subMonths(new Date(), 1),1); 
      
        let reportFilterForm = props.forms.reportFilterForm;
        let startDateDefault = (reportFilterForm.fromDate && format(new Date(reportFilterForm.fromDate), 'yyyy-MM-dd') ) || format(new Date(), 'yyyy-MM-dd');
        let endDateDefault = ( reportFilterForm.fromDate && format(new Date(reportFilterForm.toDate), 'yyyy-MM-dd') ) || format(new Date(), 'yyyy-MM-dd') ;
        let isFilterClicked = false;
        if(reportFilterForm.fromDate !== today || reportFilterForm.toDate !== today || reportFilterForm.userName !=='all' || reportFilterForm.resultType !== 'all') {
            isFilterClicked = true;
        }
        this.state = { showDetails: false, formId: '', currentDate: format(new Date(), 'yyyy-MM-dd'), defaultDate: format(new Date(), 'yyyy-MM-dd'), isFilterClicked: isFilterClicked, endDateMaxDate: format(new Date(), 'yyyy-MM-dd'), endDateMinDate: format(new Date(startDateDefault), 'yyyy-MM-dd'),endDateDefault: endDateDefault, startDateMinDate: format(minDate, 'yyyy-MM-dd'), startDateMaxDate: format(new Date(), 'yyyy-MM-dd'), startDateDefault: startDateDefault };
    }
    usersList: any = [];
    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {  
        await getCovid19Report(pageNumber, this.props.forms);
        return null;
    }
    async componentWillMount() { 
        if (UserModel.list().length <= 1) {
            await getAllUsers('includeSelf');
        } 
        //if(this.usersList.length < 1) {
            UserModel.list().forEach((user: { props: { userUID: any; firstName: string; lastName: string; }; }) => {
                this.usersList.push({ value: user.props.userUID, label: user.props.firstName + ' ' + user.props.lastName });
            });
            this.usersList = await sortBy(this.usersList,'alpha');
        //}  
        let allOption = [{value: 'all', label: 'All'}]
        this.usersList = allOption.concat(this.usersList); 
    } 
    async onFilter(process: string) {
        if(process==='filter') {
            if (!checkValidDate(this.props.forms.reportFilterForm.fromDate) ) { 
                showAlert('Please select start date.', 'report-alert', 'danger');
                return;
            } else if (!checkValidDate(this.props.forms.reportFilterForm.toDate)) { 
                showAlert('Please select end date.', 'report-alert', 'danger');
                return;
            }
        }
        this.props.history?.push('/covid19-reports');
        setLoading('AllListPage'); 
        if(process==='filter') {
            await getCovid19Report(1, this.props.forms);
            this.setState({isFilterClicked: true});
        } else {  
            let today = format(new Date(), 'yyyy-MM-dd') 
            //let minDate = addDays(format(subMonths(today, 1), 'yyyy-MM-dd'),1); 
            let minDate = addDays(subMonths(new Date(), 1),1); 
    
            this.setState({endDateMaxDate: today, endDateMinDate: format(minDate, 'yyyy-MM-dd'),endDateDefault: today, startDateDefault: today,startDateMinDate: format(minDate, 'yyyy-MM-dd'), startDateMaxDate: today})

            this.setState({defaultDate: this.state.defaultDate, isFilterClicked: false });
            await dispatch(actions.change('forms.reportFilterForm.fromDate', this.state.defaultDate));
            await dispatch(actions.change('forms.reportFilterForm.toDate', this.state.defaultDate ));
            await dispatch(actions.change('forms.reportFilterForm.userName', 'all'));
            await dispatch(actions.change('forms.reportFilterForm.resultType', 'all'));
            
            await getCovid19Report(1, this.props.forms); 
        } 
        setSuccess('AllListPage');
    }
    getChildContext() {
        return { formModel: 'forms.reportFilterForm' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any; 
    onStartDateChange = (date: any) => {  
        //date = format(date, 'yyyy-MM-dd')  
        if(date==='1970-01-01' || date==='1969-12-31') {
            this.setState({ endDateMinDate: date, endDateMaxDate: format(new Date(), 'yyyy-MM-dd'), /* startDateDefault: '' */ }) 
            dispatch(actions.change('forms.reportFilterForm.fromDate', '' ));
            return;
        }
        this.setState({ startDateDefault: date}) ;
        //dispatch(actions.change('forms.reportFilterForm.fromDate', format(date, 'yyyy-MM-dd') ));
        dispatch(actions.change('forms.reportFilterForm.fromDate', date ));
        if(this.props.forms.reportFilterForm.userName!=='all') {
            this.setState({ endDateMinDate: date}) 
            return;
        }
        /* let maxDate = subDays(format(addMonths(date, 1), 'yyyy-MM-dd'),1) 
        let diff = differenceInCalendarDays(format(new Date(), 'yyyy-MM-dd'), maxDate)  */
        let maxDate = subDays( (addMonths(date, 1) ),1); 
        let diff = differenceInCalendarDays( new Date() , maxDate); 
        this.setState({ endDateMinDate: date}) 
        if(diff>0)
            this.setState({ endDateMaxDate: format(maxDate, 'yyyy-MM-dd'), endDateDefault: format(maxDate, 'yyyy-MM-dd')}) 
    }
    onEndDateChange = (date: any) => {  
        //date = format(date, 'yyyy-MM-dd')  
        if(date==='1970-01-01' || date==='1969-12-31') {
            this.setState({ startDateMinDate: null, startDateMaxDate: format(new Date(), 'yyyy-MM-dd'), /* endDateDefault: '' */})  
            dispatch(actions.change('forms.reportFilterForm.toDate', '' ));
            return;
        } 
        this.setState({ endDateDefault: date}) ;
        //dispatch(actions.change('forms.reportFilterForm.toDate', format(date, 'yyyy-MM-dd') ));
        dispatch(actions.change('forms.reportFilterForm.toDate', date ));
        if(this.props.forms.reportFilterForm.userName!=='all') {
            this.setState({ startDateMaxDate: date}) 
            return;
        }
        //let minDate = addDays(format(subMonths(date, 1), 'yyyy-MM-dd'),1); 
        let minDate = addDays( (subMonths(date, 1) ),1); 
        this.setState({ startDateMinDate: format(minDate, 'yyyy-MM-dd'), startDateMaxDate: date}) 
    } 
    onUserChange = (user: string) => { 
        if(user!=='all') {
            this.setState({ startDateMinDate: null, startDateMaxDate: format(new Date(), 'yyyy-MM-dd')}) 
            this.setState({ endDateMinDate: null, endDateMaxDate: format(new Date(), 'yyyy-MM-dd')})  
        } else {
            let today = format(new Date(), 'yyyy-MM-dd') 
            //let minDate = addDays(format(subMonths(today, 1), 'yyyy-MM-dd'),1);  
            let minDate = addDays( (subMonths(new Date(), 1) ),1);  
            this.setState({endDateMaxDate: today, endDateMinDate: format(minDate, 'yyyy-MM-dd'),endDateDefault: today, startDateMinDate: format(minDate, 'yyyy-MM-dd'), startDateMaxDate: today})
        }
    }
    renderFilters = () => {
        return <div className="report-filter-container" >
            <Row className="show-grid"> 
                <Col sm={3} className="input" >Start Date &nbsp; 
                    {/* <input className="form-control" min={this.state.startDateMinDate} max={this.state.startDateMaxDate} value={this.state.startDateDefault} onChange={e => this.onStartDateChange(new Date(e.target.value))  } type="date" />  */}
                    <RRFInput
                        id="fromDate"
                        type="date" 
                        model=".fromDate" 
                        onChange={this.onStartDateChange}
                        mindate={this.state.startDateMinDate}
                        maxdate={this.state.startDateMaxDate}
                        defaultValue={this.state.startDateDefault}
                    />
                </Col>
                <Col sm={3} className="input" > End Date &nbsp;  
                    {/* <input className="form-control" min={this.state.endDateMinDate} max={this.state.endDateMaxDate} value={this.state.endDateDefault} onChange={e => this.onEndDateChange(new Date(e.target.value))  } type="date" />  */}
                    <RRFInput
                        id="toDate"
                        type="date" 
                        model=".toDate"  
                        onChange={this.onEndDateChange}
                        mindate={this.state.endDateMinDate}
                        maxdate={this.state.endDateMaxDate}
                        defaultValue={this.state.endDateDefault} 
                    />
                </Col>
                <Col sm={3} className="input" >User &nbsp;  
                    <RRFInput
                        id="userName"
                        type="dropdown"
                        menuItems={this.usersList}
                        placeholder="Select assessed by"
                        model=".userName" 
                        defaultValue="all" 
                        onSelect={this.onUserChange}
                    />
                </Col>
                <Col sm={2} className="input" >Safe/Risk &nbsp;  
                    <RRFInput 
                        type="dropdown"
                        menuItems={[ { value: 'all', label: 'All' },{ value: '1', label: 'Safe' },{ value: '2', label: 'Risk' }]}
                        model=".resultType" 
                        defaultValue="all" 
                    />
                </Col> 
                <Col sm={1} className="input">
                    <Row className="show-grid">
                        <i title="Filter" style={{ cursor:'pointer', color: 'rgb(38, 166, 91) ', fontSize: '27px', textAlign: 'center', marginTop: '25px' }} className="fa fa-check" aria-hidden="true" onClick={() => this.onFilter('filter')} ></i>
                        { this.state.isFilterClicked && <i title="Clear Filter" style={{ cursor:'pointer', fontSize: '25px', color: 'rgb(255, 122, 122)',paddingLeft: '7px', textAlign: 'center'}} className="fa fa-refresh" aria-hidden="true" onClick={() => this.onFilter('reset')}></i> }
                    </Row>
                </Col>
            </Row> 
            
            <Alert style={{ height: '30px' }} id="report-alert" className="danger-alert" />
        </div>;
    }

    renderFormList = () => {
        const { covid19FormInstances } = this.props;
        return <div className="office-safety-wrapper" >
             
            {this.renderFilters()}
            <ListPage
                style={{ minHeight: '50vh', padding: '20px 0px 0px 0px' }}
                titleStyle={{ textAlign:'center', padding: '0px 70px' }}
                pageHeading="COVID-19 Self Assessment Report List"
                instances={covid19FormInstances}
                rowHeadings={['Date Submitted', 'User Name', 'Assessment Result']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={[ 'userName', 'date', 'formResult' ]}
                searchPlaceHolder="Enter user or date created to search..."
                emptyInstancesMessage="No self assessment reports found."
                sm={[4,4,3 ]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
            /> 
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={Covid19Model as any} 
                identifier="AllListPage"
            />
        </div>;
    }
 
    render() {
        return (
            <BasePage className="min-wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="OIFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderFormList()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const covid19FormInstances = Covid19Model.list(state); 
    const userId = state.login.get('userId'); 
    return { 
        forms: state.forms,
        totalPageCount: state.miscellaneous.get('listCount'),
        covid19FormInstances, 
        userId
    };
}

export const Covid19ListPage = withRouter(connect< ICovid19ListPageProps, any, any>(mapStateToProps)(Covid19ListPageImpl));
