import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { Covid19Model } from '../../model/Covid19Model';
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { convertTime, KeyValue, renderCovid19Result, renderCovid19QuestionResult } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { Button } from '../reusableComponents/FormComponents/Button'; 

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    covid19Instance: Covid19Model;
}
export interface IDetailsPageState {
    imageURLs: string[];
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''] };
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        } 
        return null;
    } 

    renderContent = () => {
        const { covid19Instance } = this.props;
        if (!covid19Instance) {
            return <Loader />;
        } 
        const { covid19Instance: { props: { userName, time, date, formResult, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13 } } } = this.props;
        let title = `COVID-19 Self Assessment Detail`;
        return (
            <div>
                <Title text={`${title}`}></Title>
                <KeyValue label="User Name">{userName}</KeyValue>
                <KeyValue label="Date">{date}</KeyValue>
                <KeyValue label="Time">{time ? convertTime(time) : 'NA'}</KeyValue>
                <KeyValue label="Result">{ renderCovid19Result(formResult, 'detail') }</KeyValue>
                <div className="action-label">1. Do you currently have any of the following COVID-19 symptoms? (Select all that apply)</div>
                <KeyValue label="Fever of 100.4 F or over">{renderCovid19QuestionResult(q1)}</KeyValue>
                <KeyValue label="New or worsening cough">{renderCovid19QuestionResult(q2)}</KeyValue>
                <KeyValue label="Shortness of breath or difficulty breathing">{renderCovid19QuestionResult(q3)}</KeyValue>
                <KeyValue label="New loss of taste or smell">{renderCovid19QuestionResult(q4)}</KeyValue>
                <KeyValue label="Sore throat">{renderCovid19QuestionResult(q5)}</KeyValue>
                <KeyValue label="Constant pain in chest">{renderCovid19QuestionResult(q6)}</KeyValue>
                <KeyValue label="None of the above">{renderCovid19QuestionResult(q7,'safe')}</KeyValue>
                <div className="action-label">2. In the last 2 weeks, have you had contact with anyone diagnosed with COVID-19?</div>
                <KeyValue label="Yes">{renderCovid19QuestionResult(q8)}</KeyValue>
                <KeyValue label="No">{renderCovid19QuestionResult(q9,'safe') }</KeyValue> 
                <div className="action-label">3.  In the last 2 weeks, have you had any of the following exposures? (Select all that apply)</div>
                <KeyValue label="International travel">{renderCovid19QuestionResult(q10)}</KeyValue>
                <KeyValue label="Visited an area where COVID-19 was prevalent">{renderCovid19QuestionResult(q11)}</KeyValue> 
                <KeyValue label="Public gathering for prolonged amount of time with no PPE">{renderCovid19QuestionResult(q12)}</KeyValue> 
                <KeyValue label="None of the above">{renderCovid19QuestionResult(q13,'safe')}</KeyValue> 
                </div >
        );
    }

    renderFormDetails = () => { 
        return (
            <Async
                identifier="OIDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() { 
        let fileName = '';
        if (this.props.covid19Instance) {
            const { covid19Instance: { props: { userName, date  } } } = this.props;
            fileName = `COVID-19 Self Assessment by ${userName} on ${date}`;
        } else {
            this.props.history?.push('/covid19-reports');
        }
        return (
            <BasePage> 
                <DetailsPageWrapper pdfFileName={fileName} className="office-safety-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close </Button> }
                >
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const covid19Instance = Covid19Model.get(ownProps.match.params.id); 
    return {
        covid19Instance
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
