import * as React from 'react';
import { Title } from '../../reusableComponents/Title';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { FeedbackModel } from '../../../model/FeedbackModel';
import { getAllFeedbacks } from '../../../services/miscellaniousServices';

export interface IFeedbackDetailsPageProps {
    feedbackInstance: FeedbackModel;
}

export class FeedbackDetailsPageImpl extends React.Component<IFeedbackDetailsPageProps, {}> {
    constructor(props: IFeedbackDetailsPageProps | Readonly<IFeedbackDetailsPageProps>) {
        super(props);
    }

    promsie = async () => {
        const { feedbackInstance } = this.props;
        if (!feedbackInstance) {
            await getAllFeedbacks();
        }
        return;
    }

    renderFormContent = () => {
        const { feedbackInstance } = this.props;
        return (
            <div className="basepage">
                <Title
                    style={{ padding: '10px 0px' }}
                    text={`Feedback Details for ${feedbackInstance ? feedbackInstance.props.date : ''}`}></Title>
                <KeyValue label="Devce">{feedbackInstance ? feedbackInstance.props.device : ''}</KeyValue>
                <KeyValue label="Device OS">{feedbackInstance ? feedbackInstance.props.os : ''}</KeyValue>
                <KeyValue label="Description">{feedbackInstance ? feedbackInstance.props.problem : ''}</KeyValue>
                <KeyValue label="Recreate">{feedbackInstance ? feedbackInstance.props.recreate : ''}</KeyValue>
            </div >
        );
    }

    render() {
        return (
            <Async
                identifier="FeedbackDetailsPage"
                promise={this.promsie}
                error={<ErrorPage />}
                loader={<Loader />}
                content={<div style={{ paddingTop: '50px' }} className="background">
                    {this.renderFormContent()}
                </div>}
            />
        );
    }
}
const KeyValue = ({ label, children }: any) => {
    return <Row style={{ paddingBottom: '10px' }} className="show-grid">
        <Col xs={6} style={{ textAlign: 'right', color: '#969696' }} sm={6}>{label}:</Col>
        <Col sm={6} xs={6}>{children || 'NA'}</Col>
    </Row >;
};

export function mapStateToProps(state: any, ownProps: any) {
    const id = ownProps.match.params.id;
    const feedbackInstance = FeedbackModel.get(id);
    return {
        feedbackInstance
    };
}

export const FeedbackDetailsPage = connect<{}, {}, IFeedbackDetailsPageProps>(mapStateToProps)(FeedbackDetailsPageImpl);
