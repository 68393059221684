import { BaseModel } from './BaseModel';
import { saveInstance, removeInstance } from '../actions/modelActions';

export interface IFollowUpModelProps {
    AssignedToProject: string;
    TargetDateMet: string;
    Complete: number;
    CreatedBy: string;
    FormId: string;
    Action: string;
    UUID: string;
    PrintedName: string;
    TargetDate: string;
    SignatureUrl: string;
    DateSubmitted: string;
    CompletedBy: string;
    ActualCompletedDate: string;
    Comments: string;
    imageUrls?: string[]; 
    GroupName?: string;

    actionItems: string;
    partyResponsible: string;
    targetDate: string;
    FormSerialNo: string;
    AssignedTo: string;
    target?: string;
}

export class FollowUpModel extends BaseModel<IFollowUpModelProps> {
    constructor(props: IFollowUpModelProps) {
        super(props, FollowUpModel.resource);
    }

    static resource = 'follow-up-model';

    $saveInCompleteFollowUp(identifier: string) { 
        saveInstance(this, `${identifier}-incomplete-${this.props.id}`);
        return this;
    }

    $saveCompleteFollowUp(identifier: string) {
        saveInstance(this, `${identifier}-complete-${this.props.id}`);
        return this;
    } 

    static getFollowUp(id: string) {
        const allFollowUps = FollowUpModel.getAllFollowUps();
        return (allFollowUps || []).filter(instance => instance.props.id === id)[0];
    }

    static deleteFollowUp(UUID: string) {
        removeInstance(UUID);
    } 
    static getAllFollowUps(): FollowUpModel[] {
        return FollowUpModel.list();
    }
}
