import { IHistory } from '../../interfaces';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { IUserModelProps, UserModel } from '../../model/UserModel';
import { addUser } from '../../services/userService';
import { dispatch, isFormEmpty, showAlert, isPasswordValid } from '../../utils/generalUtils';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { UserForm } from './UserForm';
import './addUserForm.scss';

export interface ICreateFormProps {
    userId: string;
    groups: string[];
    teamState: any;
    userInstance: UserModel;
    history?: IHistory;
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, {}> {

    constructor(props: ICreateFormProps | Readonly<ICreateFormProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.userForm'));
    }

    /**
     * Fired when the user submits the form.
     * @param event The object containing the form events. Used here to prevent the reloading of the page after
     * submitting the form.
     */
    handleSubmit = (submittedValues: IUserModelProps) => {
        const { history } = this.props;
        const values: any = {
            userId: submittedValues.userId,
            password: submittedValues.password,
            firstName: submittedValues.firstName,
            lastName: submittedValues.lastName,
            email: submittedValues.email,
            accessLevel: submittedValues.accessLevel,
            jobTitle: submittedValues.jobTitle,
            jobDesc: submittedValues.jobDesc,
        };
        if (isFormEmpty(values)) {
            showAlert(EMPTY_FORM_MESSAGE, 'add-user', 'danger');
            return;
        }
        if (!isPasswordValid(submittedValues.password || '')) {
            showAlert('Password Not meeting the criteria', 'add-user', 'danger');
            return;
        }
        return history && addUser(submittedValues, history);
    }

    renderForm = () => {
        const { userInstance } = this.props;
        if (!userInstance) {
            return <Loader />;
        }
        return <UserForm
            handleFormSubmit={this.handleSubmit}
            hideFields={[]}
            onClick={() => this.props.history?.push('/home')}
            buttonName="Add User"
        />;
    }

    render() {
        return (
            <BasePage>
                <Grid className="add-user" style={{ width: '100%' }}>
                    <Title text="Add User"/>
                    <AlertModal id="user-modal" />
                    {this.renderForm()}
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId);
    return {
        userInstance,
    };
}
export const CreateForm = withRouter(connect(mapStateToProps)(CreateFormImpl));
