import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setLoading, setSuccess } from '../../../actions/loadingActions';
import { IHistory, ISelectOptions } from '../../../interfaces';
import { loginDataFetch, submitSignUpForm, getCompaniesByEmail, submitForgotPassword, loginUsingAAD } from '../../../services/loginService';
import { dispatch, isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import './loginForm.scss';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { actions, Form } from 'react-redux-form';
import PropTypes from 'prop-types';
import { Loader } from '../../reusableComponents/Loader'; 
import { EMPTY_FORM_MESSAGE } from '../../../constants/general'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal'; 
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader'; 
import logoURL from'../../../images/SafeLogo.png';  
import AzureAuthenticationContext from "./auth/azure-authentication-context";
import { AccountInfo } from "@azure/msal-browser";
import { AADPWD } from '../../../constants/general';

export interface ILoginFormImplProps {
    history: IHistory;
    isLoggedIn: boolean;
    alertType: string;
    pathName: string;
    company?: string;
}

export interface ILoginFormImplState {
    isSignUpPage: boolean;
    errorMessage: string;
    email: string;
    company: string;
    isValidUser: boolean;
    menuItems: ISelectOptions[];
    forgotPasswordClicked: boolean;
    isL1User: boolean;
}

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;
//const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();

export class LoginFormImpl extends React.PureComponent<ILoginFormImplProps, ILoginFormImplState> {

    loginIdentifier: string = 'login';
    signUpIdentifier: string = 'sing-up';

    constructor(props: ILoginFormImplProps | Readonly<ILoginFormImplProps>) {
        super(props);
        this.state = { email: '',company: '', isValidUser: false, errorMessage: '', isSignUpPage: false, forgotPasswordClicked: false, isL1User: false, menuItems: []  };
    }

    promise = async () => {
        return null;
    }

    /**
     * To set the initial state of the loader in the login button.
     */
    componentWillMount() {
        setSuccess(this.loginIdentifier);
        setSuccess(this.signUpIdentifier);
        if (!this.props.isLoggedIn) {
            dispatch(actions.reset('forms.loginForm'));
            return;
        }
        this.props.history.push('/home');
    }

    getChildContext() {
        if (this.state.isSignUpPage) {
            return { formModel: 'forms.signUpForm' };
        }
        return { formModel: 'forms.loginForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    getCompanies = async () => {
        try {
            const companyNames = await getCompaniesByEmail(this.state.email);
            this.setState({
                menuItems: companyNames
            });
            /* this.setState({
                isValidUser: true
            }); */
            setSuccess('login'); 
            let isL1User = this.state.email.toLowerCase().indexOf('spius.net') > -1;
            if(this.state.menuItems.length===1) {
                dispatch(actions.change(`forms.loginForm.company`, this.state.menuItems[0].label)); 
                this.setState({ company: this.state.menuItems[0].value || '' });
            } else {
                if(!isL1User || (isL1User && this.state.menuItems.length===1) ) {
                    dispatch(actions.change(`forms.loginForm.company`, this.state.menuItems[0].label)); 
                    this.setState({ company: this.state.menuItems[0].value || '' });
                } 
            }
        } catch (error) {
            setSuccess('login');
            showAlert('Not a valid user', 'login', 'danger');
            throw error;
        }
    }

    handleEmailChange = (e: { target: { value: any; }; }) => {
        this.setState({
            email: e.target.value
        });
        /* this.setState({ isL1User: false, isValidUser: false }); */
    } 
    handleCompanyChange = (e: any) => { 
        this.setState({ company: e });
    } 
    /**
     * Submits the form to the sever. Collects the data from the username field and password field.
     */
    handleLogin = async ({ username, password, company }: any) => {
        setLoading(this.loginIdentifier); 
        let esubmittedValues: any; 
        if(this.state.isValidUser) {
            esubmittedValues = { username, password, company }; 
        } else {
            esubmittedValues = { username }; 
        } 
        if (isFormEmpty(esubmittedValues)) {
            setSuccess('login');
            showAlert(EMPTY_FORM_MESSAGE, 'login', 'danger');
            return;
        } 
        if (isFormEmpty(esubmittedValues)) {
            setSuccess('login');
            showAlert(EMPTY_FORM_MESSAGE, 'login', 'danger');
            return;
        } 
        /* if (!this.state.isValidUser) {
            this.getCompanies();
            return;
        } */

        if (!this.state.isL1User && username.toLowerCase().indexOf('spius.net') > -1) { 
            await this.getCompanies();
            await this.setState({ isL1User: true, isValidUser: true });
            return;
        } else if(!this.state.isValidUser) {
            await this.getCompanies();
            await this.setState({ isValidUser: true });
            return;
        }
        const { props: { history, pathName } } = this;
        // username = '12'; // password='pass'; 
        await loginDataFetch(username, password, company, history, pathName);
    } 
    forgotPassword = async () => { 
        const requestData = {
            email: this.state.email, 
            company: this.state.company 
        };
        const esubmittedValues = { email:requestData.email }
        if (isFormEmpty(esubmittedValues)) {
            showAlert('Please fill username', 'login', 'danger');
            return;
        } 
        const csubmittedValues = { company: requestData.company }
        if (isFormEmpty(csubmittedValues)) {
            showAlert('Please select company', 'login', 'danger');
            return;
        } 
        const { props: { history } } = this;
        this.setState({ forgotPasswordClicked: true });
        await submitForgotPassword(requestData, history); 
        this.setState({ forgotPasswordClicked: false });
    }
    renderLoginForm = () => {
        const { props: { alertType }, handleLogin } = this;
        return (
            <Form model="forms.loginForm" className="login" onSubmit={handleLogin}> 
                <div className="title">
                    <img src={logoURL} style={{ width: '45%', height: '100%' }} alt="SafeConnect" />
                </div>
                <Alert className={`${alertType}-alert`} id="login" />
                <AlertModal id="login-form-modal" />
                <div className="input-field">
                    <InputField className="username" placeholder="Username" onChange={this.handleEmailChange} type="text" model=".username"
                    />
                    {this.state.isValidUser &&
                        <React.Fragment>
                            <InputField className="password" placeholder="Password" type="password" model=".password" />
                            
                        </React.Fragment>
                    }
                    {this.state.isL1User && 
                        <RRFInput
                            className="password"
                            placeholder="Select company..."
                            type="dropdown"
                            menuItems={this.state.menuItems}
                            model=".company"
                            onSelect={this.handleCompanyChange}
                        /> 
                    }
                </div>
                <Async
                    promise={this.promise}
                    loader={<span className="button-loading">
                        <MuiThemeProvider>
                            <CircularProgress color="#FFFFFF" size={30} />
                        </MuiThemeProvider>
                    </span>}
                    identifier={this.loginIdentifier}
                    content={<button style={
                        !this.state.isValidUser ? {
                            width: '150px',
                            height: '40px',
                        } : {}
                    } className="button">{this.state.isValidUser ? 'Login' : 'Next'}</button>}
                />
                { /*<div className="sign-up-option" >
                    Don't have an account? <br />
                    <span onClick={this.handleFormChange} className="sign-up-text"> SignUp for Demo</span>
                 </div> */
                }
                { this.state.isValidUser && !this.state.forgotPasswordClicked && <div className="sign-up-option forgot-password " onClick={this.forgotPassword}>
                        Forgot password?
                    </div> 
                }
                { this.state.forgotPasswordClicked && <SubmissionLoader /> }

                { this.props.company && this.props.company.toLocaleLowerCase()==='spi' && <div style={{marginTop: '20px', textAlign: 'center'}}> <div style={{color: '#fff', marginBottom:'10px', fontSize: '12px', display: 'none'}}>(Or) Social Authentications:</div>
                    <button type="button" className="sso" onClick={() => this.AADLogIn("loginPopup")}>Single Sign On (SSO) {/* <img src={adLogo} className="App-loago" alt="logo" /> */} </button> 
                </div> }
                
            </Form>
        );
    }
    AADLogIn(type: any) {
        if(!this.props.company) {
            return;
        }
        let clientId = AADPWD[this.props.company.toLowerCase()];
        if(!clientId) {
            showAlert('Invalid login', 'login', 'danger');
            return;
        }
        const typeName = "loginPopup";
        const logInType = isIE ? "loginRedirect" : typeName; 
        // Azure Login
        
        const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext(clientId);
        authenticationModule.login(logInType, this.returnedAccountInfo);
    }
    returnedAccountInfo  = async (user: AccountInfo) => { 
        if(user && this.props.company) {
            this.setState({forgotPasswordClicked: true});
            await loginUsingAAD(user, this.props.company); 
        }
    }
    
    handleFormChange = () => {
        this.setState({ isSignUpPage: !this.state.isSignUpPage });
    } 
    handleSignUp = (submittedValues: { userId: any; password: any; firstName: any; lastName: any; email: any; jobDesc: any; jobTitle: any; }) => {
        setLoading(this.signUpIdentifier);
        const valuesToValidate = {
            userId: submittedValues.userId,
            password: submittedValues.password,
            firstName: submittedValues.firstName,
            lastName: submittedValues.lastName,
            email: submittedValues.email,
            jobDesc: submittedValues.jobDesc,
            jobTitle: submittedValues.jobTitle,
        };
        if (isFormEmpty(valuesToValidate)) {
            setSuccess(this.signUpIdentifier);
            showAlert(EMPTY_FORM_MESSAGE, 'sign-up', 'danger');
            return;
        }
        return submitSignUpForm(submittedValues, this.props.history);
    } 
    renderSignUpForm = () => {
        return (
            <Form model="forms.signUpForm" className="sign-up" onSubmit={this.handleSignUp}>
                <div className="title">
                    <img src={logoURL} style={{ width: '45%', height: '100%' }} alt="SafeConnect"/>
                </div>
                <Alert className={`${this.props.alertType}-alert`} id="sign-up" />
                <div className="input-field">
                    <InputField className="username" placeholder="User Id (Numbers only)" type="number" model=".userId" />
                    <InputField className="password" placeholder="Password" type="password" model=".password" />
                    <InputField className="username" placeholder="First Name" type="text" model=".firstName" />
                    <InputField className="username" placeholder="Last Name" type="text" model=".lastName" />
                    <InputField className="username" placeholder="Email" type="email" model=".email" />
                    <InputField className="username" placeholder="Phone Number" type="number" model=".phoneNumber" />
                    <InputField className="username" placeholder="Job Description" type="text" model=".jobDesc" />
                    <InputField className="username" placeholder="Job Title" type="text" model=".jobTitle" />
                    <InputField className="username" placeholder="Company Name" type="text" model=".originalCompany" />
                </div>
                <Async
                    promise={this.promise}
                    loader={<span className="button-loading">
                        <MuiThemeProvider>
                            <CircularProgress color="#FFFFFF" size={30} />
                        </MuiThemeProvider>
                    </span>}
                    identifier={this.signUpIdentifier}
                    content={<button className="button">Sign Up</button>}
                />
                <div className="sign-up-option" >
                    Continue to
                    <span onClick={this.handleFormChange} className="sign-up-text"> Login</span>
                </div>
            </Form>
        );
    } 
    renderContent = () => {
        return (
            <div className={`login-form ${this.state.isSignUpPage ? 'sign-up-form' : ''}`}>
                {
                    this.state.isSignUpPage ?
                        this.renderSignUpForm() :
                        this.renderLoginForm()
                }
            </div>
        );
    } 
    render() { 
        return <div>
            <Async
                identifier="LoginForm"
                promise={this.promise}
                error={<div></div>}
                loader={<Loader size={50} />}
                content={this.renderContent()}
            />
        </div>;
    }
}

/**
 * To be used for fetching the login state of the user.
 * @param login A Map of the immutable Object.
 */
export function mapStateToProps(state: any, ownProps: any) {
    let pathName = '/home';
    if (ownProps.location.search) {
        pathName = ownProps.location.search.split('=')[1];
    }
    const company = ownProps.match.params.company;
    return {
        isLoggedIn: state.login.get('isLoggedIn'),
        alertType: state.alert.get('type'),
        pathName, company, 
    };
}
export const LoginForm = withRouter(connect(mapStateToProps)(LoginFormImpl)); 
export const error: React.CSSProperties = {
    position: 'relative',
    top: '39%',
    width: '100%',
    textAlign: 'center',
    color: 'red'
}; 
const InputField = ({ className, placeholder, type, model, ...props }: any) => {
    return <RRFInput
        className={className}
        placeholder={placeholder}
        onChange={props.onChange}
        type={type}
        model={model}
    />;
};
