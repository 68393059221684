import { getIncidentForm } from '../../services/incidentNotificationFormService';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { getAllUsers } from '../../services/userService';
import { Async } from '../reusableComponents/Async';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { getInvestigativeForm } from '../../services/incidentReportService';
import { convertTime, KeyValue, capatalize } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { IHistory } from '../../interfaces';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { Img } from '../reusableComponents/ImageComponents/Img';
import { UserModel } from '../../model/UserModel';
import { withRouter } from 'react-router-dom';
import { Button } from '../reusableComponents/FormComponents/Button';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay'; 
import { format } from 'date-fns';
import noImage from '../../images/noImage.png'; 

export interface IDetailsPageProps {
    incidentInvestigativeId: string; 
    incidentInstance: IncidentReportModel;
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    }

    promise = async () => {
        /* if (IncidentReportModel.list().length <= 0) {
            await getAllIncidentReportForms();
        }
        if (IncidentNotificationModel.list().length <= 0) {
            await getAllIncidentNotificationForms();
        } */ 
        this.loadData()
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return null;
    }
    async componentDidUpdate(prevProps: any) {   
        if (prevProps.incidentInvestigativeId !== this.props.incidentInvestigativeId) {   
            this.loadData();
        }
    } 
    loadData = async () => {
        const { incidentInvestigativeId, incidentInstance } = this.props;
        await getInvestigativeForm(incidentInvestigativeId); 
        if(incidentInstance && incidentInstance.props.associatedForms && incidentInstance.props.associatedForms.length>0) {
            await getIncidentForm(incidentInstance.props.associatedForms[0])
        }
    }

    renderProjectOIMSSystem = (projectOIMSSystemData: any, index: any) => {
        return (
            <div key={index} className="steps">
                <Row className="step-row">
                    <Col sm={8} style={{ color: '#969696' }}>
                        Project OIMS System:
                    </Col>
                    <Col sm={8}>
                        {projectOIMSSystemData.projectOIMSSystem}
                    </Col>
                </Row >
                <Row className="step-row">
                    <Col sm={8} style={{ color: '#969696' }}>
                        OIMS System Date:
                    </Col>
                    <Col sm={8}>
                        {projectOIMSSystemData.OIMSSystemDate}
                    </Col>
                </Row >
            </div>
        );
    }

    renderActionItems = (incidentInvestigationData: any, index: any) => {
        return (
            <div key={index} className="steps">
                <Row className="step-row">
                    <Col sm={8} style={{ color: '#969696' }}>
                        Action Item:
                    </Col>
                    <Col sm={8}>
                        {incidentInvestigationData.actionItem}
                    </Col>
                </Row >
                <Row className="step-row">
                    <Col sm={8} style={{ color: '#969696' }}>
                        Party Responsible:
                    </Col>
                    <Col sm={8}>
                        {incidentInvestigationData.partyResponsible}
                    </Col>
                </Row >
                <Row className="step-row">
                    <Col sm={8} style={{ color: '#969696' }}>
                        Target Date:
                    </Col>
                    <Col sm={8}>
                        {incidentInvestigationData.partyResponsible}
                    </Col>
                </Row >
            </div>
        );
    }

    renderImages = () => {
        const { incidentInstance } = this.props;
        if (incidentInstance.props.imageUrls.length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        }
        return incidentInstance.props.imageUrls.map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData} 
            />;
        });

    }

    handleRedirectToAssociatedForm = () => {
        const { incidentInstance } = this.props;
        if (!incidentInstance.props.associatedForms) {
            return;
        }
        this.props.history?.push(`/incident-reports/${incidentInstance.props.associatedForms[0]}`);
    }

    renderContent = () => {
        const { incidentInstance } = this.props;
        if (!incidentInstance) {
            return <Loader />;
        }
        const { props: { date, dateOfIncident, dateReported, timeReported,
            dateReturnedToWork, dateReportedToAgency, photosIncluded, finalReport, generalContractor, subContractor, workRelated, offShore, onShore, incidentSummary, incidentDetailedDesc, immediateCorrectiveAction, jobTask,
            equipInvolved, weather, lighting, wind, seas, properPPE, selfSupervised, incidentWitnessed,
            name, employee, contractor, drugTestConducted, company, jobPosition, timeOnJob,
            natureOfInjury, bodyPart, medicalResponseTaken, statusUpdate, eventLeadingToInjury, actualDaysLost, firstAidTreatment, estimatedDaysLost, actualSeverity, potentialSeverity, securityCategory, methodOfBreach, agencyNotified, agencyName, reportedToAgencyBy, notifiedAtAgency, costIncurred, totalCost, emOwnershipPercentage, costType, costCategory, directCause, rootCause, mngmtReportRequired, associatedForms, incidentActivity, incidentConditions, workDirection, category, location }
        } = incidentInstance;
        const incidentNotificationInstance = associatedForms && IncidentNotificationModel.get(associatedForms[0]);
        let notificationFormDate = 'NA';
        let notificationFormBodyPart = 'NA';
        if (incidentNotificationInstance) {
            const { props: { date, injuryBodyPart } } = incidentNotificationInstance;
            notificationFormDate = date;
            notificationFormBodyPart = injuryBodyPart;
        } 
        const userInstance = UserModel.get(reportedToAgencyBy);
        let userName;
        if (userInstance) {
            const { props: { firstName, middleName, lastName } } = userInstance;
            userName = `${firstName} ${middleName} ${lastName}`;
        }
        return (
            <div>
                <Title text={`Detailed Incident Report for ${date}`} />
                <KeyValue label="Date of Incident">{dateOfIncident}</KeyValue>
                <KeyValue label="Time of Incident">{convertTime(timeReported)}</KeyValue>
                <KeyValue label="Photos Included">{photosIncluded > 0 ? 'Yes' : 'No'}</KeyValue>
                <KeyValue label="Report Type">{finalReport===1?'Final Report': 'Preliminary Report'}</KeyValue>
                <KeyValue label="Date Reported">{format(new Date(dateReported),'MM/dd/yyyy')}</KeyValue>
                <KeyValue label="Time Reported">{timeReported ? convertTime(timeReported) : 'NA'}</KeyValue>
                <KeyValue label="General Contractor">{generalContractor}</KeyValue>
                <KeyValue label="Sub Contractor">{subContractor}</KeyValue>
                <KeyValue label="Category">{category}</KeyValue>
                <KeyValue label="Work Related">{capatalize(workRelated)}</KeyValue>
                <KeyValue label="OnShore">{onShore > 0 ? 'Yes' : 'No' || 'NA'}</KeyValue>
                <KeyValue label="OffShore">{offShore > 0 ? 'Yes' : 'No' || 'NA'}</KeyValue>
                <KeyValue label="Incident Summary">{incidentSummary}</KeyValue>
                <KeyValue label="Detailed Incident Description">{incidentDetailedDesc}</KeyValue>
                <KeyValue label="Immediate Corrective Action">{immediateCorrectiveAction}</KeyValue>
                <KeyValue label="Job Task">{jobTask}</KeyValue>
                <KeyValue label="Location">{location}</KeyValue>
                <KeyValue label="Equipment Involved">{equipInvolved}</KeyValue>
                <KeyValue label="Weather">{weather}</KeyValue>
                <KeyValue label="Lighting">{lighting}</KeyValue>
                <KeyValue label="Wind">{wind}</KeyValue>
                <KeyValue label="Seas">{seas}</KeyValue>
                <KeyValue label="Proper PPE Used">{capatalize(properPPE)}</KeyValue>
                <KeyValue label="Self Supervised Activity">{capatalize(selfSupervised)}</KeyValue>
                <KeyValue label="Was Incident Witnessed">{capatalize(incidentWitnessed)}</KeyValue>
                <KeyValue label="Name">{capatalize(name)}</KeyValue>
                <KeyValue label="Employee">{employee > 0 ? 'Yes' : 'No'}</KeyValue>
                <KeyValue label="Contractor">{contractor > 0 ? 'Yes' : 'No'}</KeyValue>
                <KeyValue label="Drug Test Conducted">{capatalize(drugTestConducted) || 'NA'}</KeyValue>
                <KeyValue label="Company">{company || 'NA'}</KeyValue>
                <KeyValue label="Job Position">{jobPosition || 'NA'}</KeyValue>
                <KeyValue label="Time On Job">{timeOnJob || 'NA'}</KeyValue>
                <KeyValue label="Nature of Injury or Illness">{natureOfInjury}</KeyValue>
                <KeyValue label="Body Part">{bodyPart || 'NA'}</KeyValue>
                <KeyValue label="Medical Response Taken">{medicalResponseTaken}</KeyValue>
                <KeyValue label="Status Update">{statusUpdate}</KeyValue>
                <KeyValue label="Date Returned To Work">{format(new Date(dateReturnedToWork),'MM/dd/yyyy')}</KeyValue>
                <KeyValue label="Event Leading to Injury">{eventLeadingToInjury}</KeyValue>
                <KeyValue label="Actual Days Lost / Restricted">{actualDaysLost}</KeyValue>
                <KeyValue label="First Aid Treatment Type">{firstAidTreatment}</KeyValue>
                <KeyValue label="Estimated Days Lost / Restricted">{estimatedDaysLost}</KeyValue>
                <KeyValue label="Actual Severity">{actualSeverity}</KeyValue>
                <KeyValue label="Potential Severity">{potentialSeverity}</KeyValue>
                <KeyValue label="Security Category">{securityCategory}</KeyValue>
                <KeyValue label="Method Of Breach">{methodOfBreach}</KeyValue>
                <KeyValue label="Agency Notified?">{agencyNotified > 0 ? 'Yes' : 'No'}</KeyValue>
                <KeyValue label="Agency Name">{agencyName}</KeyValue>
                <KeyValue label="Date Reported To Agency">{format(new Date(dateReportedToAgency),'MM/dd/yyyy')}</KeyValue>
                <KeyValue label="Reported To Agency By">{userName}</KeyValue>
                <KeyValue label="Name of Person Notified at Agency">{notifiedAtAgency}</KeyValue>
                <KeyValue label="Cost Incurred">{capatalize(costIncurred)}</KeyValue>
                <KeyValue label="Total Cost in USD">{totalCost + '$'}</KeyValue>
                <KeyValue label="Ownership Percentage">{emOwnershipPercentage + '%'}</KeyValue>
                <KeyValue label="Cost Type">{costType}</KeyValue>
                <KeyValue label="Cost Category">{costCategory}</KeyValue>
                <KeyValue label="Mgt. Report Required?">{capatalize(mngmtReportRequired)}</KeyValue>
                <KeyValue label="Direct Cause">{directCause}</KeyValue>
                <KeyValue label="Primary &amp; Other Significant Contributing Casual Factor (Root Cause)"> {rootCause} </KeyValue>
                <KeyValue label="Identify activities contributing to the incident">{incidentActivity}</KeyValue>
                <KeyValue label="Identify conditions contributing to the incident">{incidentConditions}</KeyValue>
                <KeyValue label="How did work direction influencing the incident?">{workDirection}</KeyValue>
                <KeyValue label="Images">{this.renderImages()}</KeyValue> 
                <KeyValue label="Videos"><VideoPlay videoUrls={incidentInstance.props.videoUrls}/></KeyValue>
                {/* <Row className="show-grid">
                    <Col xs={6} style={{ textAlign: 'right', color: '#969696' }} sm={6}>
                        Project OIMS Systems:
        </Col>
                    <Col sm={6} xs={6}>{projectOIMSDetails || 'NA'}</Col>
                </Row >
                    <Col sm={6} xs={6}>{incidentInvestigation || 'NA'}</Col>
                </Row > */}
                {/* {projectOIMSDetails.map((projectOIMSSystem, index) => {
                    return this.renderProjectOIMSSystem(projectOIMSSystem, index);
                })}
                 */}
                <Row className="show-grid associated-forms">
                    <Col xs={6} style={{ textAlign: 'right', color: 'white' }} className="" sm={6}>
                        Associated Incident Notification form:
                    </Col>
                    <Col sm={6} xs={6} className="form-button" style={{ /* textAlign: 'left', float: 'initial'  */}}>
                        {
                           associatedForms &&  (associatedForms.length <= 0 || associatedForms[0] === 'No Report')  ? 'No forms associated' :
                                <Button style={{ margin: '0px' }} onClick={this.handleRedirectToAssociatedForm}>
                                    {`${notificationFormBodyPart} ${notificationFormDate!=='NA' && format(new Date(notificationFormDate),'MM/dd/yyyy')}`}
                                </Button>
                        }
                    </Col>
                </Row>
            </div >
        );
    }

    renderIncidentDetail = () => {
        return (
            <Async
                identifier="IncidentReportDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        const { incidentInstance } = this.props;
        let fileName = '';
        if (incidentInstance) {
            const { incidentInstance: { props: { date } } } = this.props;
            fileName = `Detailed Incident Report for ${date}`;
        }
        return (
            <BasePage>
                <Gallery images={incidentInstance ? incidentInstance.props.imageUrls : []} />
                <DetailsPageWrapper
                    isLong={true}
                    pdfFileName={fileName}
                    className="incident-investigative-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close </Button> }
                >
                    {this.renderIncidentDetail()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const incidentInvestigativeId = ownProps.match.params.incidentId
    const incidentInstance = IncidentReportModel.get(incidentInvestigativeId);
    const userInstances = UserModel.list();
    return { incidentInvestigativeId, incidentInstance, userInstances };
}

export const DetailsPage = withRouter(connect< IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
