import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { IHistory } from '../../../interfaces';
import { MocPermanentModel } from '../../../model/MOCModel';
import { UserModel } from '../../../model/UserModel'; 
import { getAllUsers } from '../../../services/userService';
import { convertTime, convertToDateFormat } from '../../../utils/generalUtils';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../../reusableComponents/ErrorPage'; 
import { Loader } from '../../reusableComponents/Loader';
import { Title } from '../../reusableComponents/Title'; 
import { Button } from '../../reusableComponents/FormComponents/Button';  
import format from 'date-fns/format';
import {Col, Row} from "react-bootstrap";
import {getFormData} from '../../../services/MocService';
import { AttachementInput } from '../../reusableComponents/FormComponents/AttachementInput';

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    instance: MocPermanentModel;
    viewId: string;
}
export interface IDetailsPageState {
    imageURLs: string[];
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, IDetailsPageState> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
        this.state = { imageURLs: [''] };
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        const { instance, viewId } = this.props;
        if( !instance) {
            await getFormData('mocPermanentDeviation', viewId);
        }
         
        return null;
    } 

    getObserverName = (userID: any) => {
        const userInstance = UserModel.getUserByUID(userID);
        if (!userInstance) {
            return;
        }
        return userInstance.props.firstName;
    }

    renderContent = () => {
        const { instance } = this.props;
        if (!instance) {
            return <Loader />;
        } 
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        } 
        return (
            <div> 
                <Title text={`MOC Report for ${format(new Date(instance.props.date), 'MM/dd/yyyy') }`}></Title>
                <Row className={'show-grid'}>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Time:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{instance.props.time ? convertTime(instance.props.time) : 'NA'}</Col> 
                    </Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Team:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.projectName || 'NA'}</Col> 
                    </Col> 
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Group:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.groupName || 'NA'}</Col> 
                    </Col>  
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Location:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.locationName || 'NA'}</Col> 
                    </Col>  
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Originator:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.originator || 'NA'}</Col> 
                    </Col>  
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Originator Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.originatorSignatureURL } alt="SafeConnect" />}</Col>
                    </Col> 
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Details of Change Requested:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.changeRequestDetails || 'NA'}</Col> 
                    </Col> 
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Reason for Change (explain rationale for change or improvement opportunity):</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.reasonForChange || 'NA'}</Col> 
                    </Col>  
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Target date for change (if approved):</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{(answer.targetDateForChange && convertToDateFormat(answer.targetDateForChange) ) || '' }</Col> 
                    </Col> 
                </Row>
                <Row>
                    <div style={{fontSize: '18px', textAlign: 'center', marginTop:'30px' }}  className="color-gray">Attachments</div> 
                    <AttachementInput
                        model=".attachements"
                        multiple={true}
                        id="upload-images" 
                        noNeedAttachButton={true}
                        uploadedImages={instance.props.attachmentUrls || []}
                    />
                </Row>
                <Row className="show-grid" > 
                    <Col sm={12} xs={12}><legend className="legend-header" >Approvals</legend></Col> 
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Safety Supervisor</label></Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Name:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{this.getObserverName(answer.safetySupervisor) || 'NA'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Date:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.safetySupervisorApprovedDate && convertToDateFormat(answer.safetySupervisorApprovedDate) ) || 'Nill'}</Col> 
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.safetySupervisorSignatureURL && <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.safetySupervisorSignatureURL } alt="SafeConnect" />) || 'Nill'}</Col>  
                    </Col> 
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Comments:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.safetySupervisorComments || 'Nill'}</Col>  
                    </Col> 
                </Row>
                <Row className="show-grid" >  
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Production Supervisor</label></Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Name:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{this.getObserverName(answer.productionSupervisor) || 'NA'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Date:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.productionSupervisorApprovedDate && convertToDateFormat(answer.productionSupervisorApprovedDate) ) || 'Nill'}</Col> 
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.productionSupervisorSignatureURL && <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.productionSupervisorSignatureURL } alt="SafeConnect" />) || 'Nill'}</Col>  
                    </Col> 
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Comments:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.productionSupervisorComments || 'Nill'}</Col>  
                    </Col> 
                </Row>
                <Row className="show-grid" >  
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Safety Manager</label></Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Name:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{this.getObserverName(answer.safetyManager) || 'NA'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Date:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.safetyManagerApprovedDate && convertToDateFormat(answer.safetyManagerApprovedDate) ) || 'Nill'}</Col> 
                    </Col> 
                    <Col sm={2} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Is Approved:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.safetyManagerApproval && (answer.safetyManagerApproval ==='1'?'Yes': 'No') ) || 'Nill'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.safetyManagerSignatureURL && <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.safetyManagerSignatureURL } alt="SafeConnect"/> ) || 'Nill'}</Col>  
                    </Col>
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Comments:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.safetyManagerComments || 'Nill'}</Col>  
                    </Col>
                </Row>
                <Row className="show-grid" >  
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Operations Manager</label></Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Name:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{this.getObserverName(answer.operationsManager) || 'NA'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Date:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.operationsManagerApprovedDate && convertToDateFormat(answer.operationsManagerApprovedDate) ) || 'Nill'}</Col> 
                    </Col> 
                    <Col sm={2} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Is Approved:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.operationsManagerApproval && (answer.operationsManagerApproval ==='1'?'Yes': 'No') ) || 'Nill'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.operationsManagerSignatureURL && <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.operationsManagerSignatureURL } alt="SafeConnect" />) || 'Nill'}</Col>  
                    </Col>
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Comments:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.operationsManagerComments || 'Nill'}</Col>  
                    </Col> 
                </Row>
                <Row className="show-grid" >  
                    <Col sm={12} xs={12} style={{marginTop: '10px'}}><label className="sub-legend-header">Production Manager</label></Col>
                    <Col sm={4} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Name:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{this.getObserverName(answer.productionManager) || 'NA'}</Col>   
                    </Col>  
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Date:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.productionManagerApprovedDate && convertToDateFormat(answer.productionManagerApprovedDate) ) || 'Nill'}</Col> 
                    </Col>  
                    <Col sm={2} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Is Approved:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.productionManagerApproval && (answer.productionManagerApproval ==='1'?'Yes': 'No')) || 'Nill'}</Col>   
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Sign:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{ (answer.productionManagerSignatureURL && <img height="56px" width="100%" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white' }} src={ answer.productionManagerSignatureURL } alt="SafeConnect"/>) || 'Nill'}</Col>  
                    </Col>
                    <Col sm={12} xs={12}>
                        <Col xs={12} className="details-label" sm={12}>Comments:</Col> 
                        <Col className="details-value" sm={12} xs={12} id="detailsChild">{answer.productionManagerComments || 'Nill'}</Col>  
                    </Col>  
                </Row>
            </div >
        );
    }

    renderFormDetails = () => { 
        return (
            <Async
                identifier="OIDetailsPage"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={this.renderContent()}
            />
        );
    }

    render() {
        let fileName = '';
        if (this.props.instance) {
            const { instance: { props: { date } } } = this.props;
            fileName = `MOC Report for ${format(new Date(date), 'MM/dd/yyyy')}`;
        }
        return (
            <BasePage> 
                <DetailsPageWrapper pdfFileName={fileName} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close
                </Button>
                }>
                    {this.renderFormDetails()}
                </DetailsPageWrapper>
            </BasePage >
        );
    }

}

export function mapStateToProps(state: any, ownProps: any) {
    const viewId = ownProps.match.params.id;
    const instance = MocPermanentModel.get(viewId); 
    return {
        instance, viewId
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(mapStateToProps)(DetailsPageImpl));
