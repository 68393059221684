import * as React from 'react';
import { Col } from 'react-bootstrap';

export interface ICellProps {
    style?: React.CSSProperties;
    sm?: number;
    xs?: number;
    onClick?: Function;
    rawText?: any;
    children: any;
}

export class Cell extends React.PureComponent<ICellProps, {}> {
    constructor(props: ICellProps | Readonly<ICellProps>) {
        super(props);
    }

    sliceText = () => {
        const { children } = this.props;
        if (children.toString().length > 25) {
            return children.toString().slice(0, 25) + '...';
        }
        return children.toString();
    }

    render() {
        const { props: { onClick, style, sm, xs, children, rawText }, sliceText } = this;
        return (
            <Col
                className="clickable-list-item"
                onClick={onClick ? () => onClick() : undefined}
                sm={sm}
                xs={xs}
                style={style}
                title={children.toString()}
            >
                {rawText?rawText:sliceText() || 'NA'}
            </Col>
        );
    }
}
