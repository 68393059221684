import { deleteGroup } from '../../services/groupService';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modalActions';
import { GroupModel } from '../../model/GroupModel';
import { Button } from '../reusableComponents/FormComponents/Button';
import { IHistory } from '../../interfaces';
import { withRouter } from 'react-router-dom';

export interface IConfirmationModalProps {
    id?: string;
    modalHeading: string;
    history?: IHistory;
    showModal: boolean;
    modalID: string;
    instance: GroupModel; 
}

export interface IConfirmationModalState {
    showModal: boolean;
}

export class ConfirmationModalImpl extends React.PureComponent<IConfirmationModalProps, IConfirmationModalState> {
    constructor(props: IConfirmationModalProps | Readonly<IConfirmationModalProps>) {
        super(props);
        this.state = { showModal: true };
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    showModal = () => {
        const { props: { history, id, modalID, instance } } = this;
        if (modalID !== id) {
            return <div></div>;
        }
        return (
            <Modal className="confirmation-modal" show={true} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }}>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete group {this.props.modalHeading} ?
                <div className="modal-buttons">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={() => history && deleteGroup(instance, history)}
                        >Yes</Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => hideModal()}
                        >No</Button>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }

    render() {
        return this.showModal();
    }
}

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('modalID'),
        showModal: state.modal.get('showModal'),
        modalHeading: state.modal.get('modalHeading'),
        instance: state.modal.get('instance')
    };
}

export const ConfirmationModal = withRouter(connect<IConfirmationModalProps, any, any>(mapStateToProps)
    (ConfirmationModalImpl));
