import * as React from 'react';
import { connect } from 'react-redux'; 
import './fileInput.scss'; 
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import noVideo from '../../../images/noVideo.jpg'; 
import { Row } from 'react-bootstrap';

export interface IVideoPlayProps { 
    videoUrls?: string[];
}

export interface IVideoPlayState {
    /* imagesArray: string[]; */
}

export class VideoPlayImpl extends React.PureComponent<IVideoPlayProps, IVideoPlayState> {
    constructor(props: IVideoPlayProps | Readonly<IVideoPlayProps>) {
        super(props);
        this.state = { imagesArray: [] };
    }

    componentWillMount() { 
    } 

    render() {
        const { videoUrls } = this.props;
        if(!videoUrls || videoUrls.length<1) {
            return <img className="no-video" src={noVideo} alt="Saf" />; 
        }
        return videoUrls.map((fileObj, index) => {  
            let fileName = fileObj.split('/')[fileObj.split('/').length-1];
            fileName = fileName.split('~~')[0] + '.' + fileName.split('~~')[1].split('.')[1];
            return <Row><div key={index}/*  style={displayImage}  */className="video-wrapper"> 
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={fileName}> <strong>{fileName}</strong> </Tooltip> } > 
                   {/*  <img onClick={() => this.openFile(fileObj)} 
                        height="100px"
                        width="125px" 
                        style={{ padding: '5px' }}
                        className="thumbnail-image"
                        src={S3_URL + '/video-thumb.png'}
                    /> */}
                    <video ref="vidRef" height="125px" width="150px" style={{ padding: '5px' }} controls className="thumbnail-image video-container video-container-overlay"  >
                        <source src={ fileObj } type="video/mp4" />
                    </video>
                   
                </OverlayTrigger> 
                {/* <span className="video-name">{fileName}</span> */} 
            </div></Row>;
        });
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms
    };
}

export const VideoPlay = connect<{}, {}, IVideoPlayProps>(mapStateToProps)(VideoPlayImpl);
 