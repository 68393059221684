import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './PTWListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal'; 
import { PermitToWorkModel } from "../../model/PermitToWorkModel"; 
import { getAllPTWForms } from '../../services/permitToWorkService';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess} from '../../actions/loadingActions'; 
declare var require: any;
const queryString = require('query-string');

export interface IPTWListPageProps {
    instances: PermitToWorkModel[];
    history?: IHistory;
    totalPageCount: number;
    formStage: any;
    forms: IForms;
    axiosPreviousToken: any;
}

export interface IPTWListPageState {
    loadSubmission: boolean;
}

export class PTWListImpl extends React.PureComponent<IPTWListPageProps, IPTWListPageState>  { 
    constructor(props: IPTWListPageProps | Readonly<IPTWListPageProps>) {
        super(props);
        this.state = {loadSubmission: false}
    }
    
    sortListing = { 0: 'date', 1: 'permitNo', 2: 'locationName', 3: 'taskDescription', 4: 'PIReviewerName', 5: 'OIReviewerName' };
    async componentWillReceiveProps(nextProps: any) {
      if(nextProps.formStage!==this.props.formStage) {  
        this.setState({loadSubmission: true});
        PermitToWorkModel.deleteAll();
        let urlParams = queryString.parse(this.props.history?.location['search']) || {};  
        urlParams.pageNumber = 1;
        await getAllPTWForms( urlParams, this.props.forms, nextProps.formStage );
        this.setState({loadSubmission: false});
      }
    }

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => {
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }
    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage');  
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        urlParams.sortIndexField = this.sortListing[sortIndex];
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllPTWForms( urlParams, this.props.forms, this.props.formStage );
        setSuccess('AllListPage'); 
    }

    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report" onClick={() => { history?.push('/moc-permanent-deviation/create'); }} > + </button>
        );
    } 

    renderContent = () => {
        const { instances, formStage } = this.props;
        return <div className="office-safety-wrapper">  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history}/>
            {<ExcelExport formType="permitToWork" fileName={`${formStage==='preliminary-reports'? 'Preliminary': (formStage==='temporary-reports'?'Final':'Close Out') } PTW Report`} filterParams={ {formStage} } />}
            <> <ListPage
                    pageHeading={formStage==='preliminary-reports'? "Preliminary PTW Report":(formStage==='temporary-reports'?'Final PTW Report':'Close Out PTW Report')}
                    instances={instances}
                    rowHeadings={['Date Submitted', 'Permit No', 'Location', ' Task Description', 'Facility Incharge','Overall Incharge' ]}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={[ 'date', 'username']}
                    searchPlaceHolder="Enter project, group, task description, facility incharge, overall incharge to search..."
                    emptyInstancesMessage={this.state.loadSubmission?'Loading...':(formStage==='preliminary-reports'? "No Preliminary PTW Report":(formStage==='temporary-reports'?'No Final PTW Report':'No Close Out PTW Report'))}
                    sm={[2, 1, 2, 2, 2, 2]}
                    currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                {/* {this.renderAddNewFormButton()} */}
                {/* { UserModel.checkUserAccess('office_safety_report_summary') && this.renderSummaryPageButton()}*/}
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={PermitToWorkModel as any}
                    identifier="PermitToWorkReports"
                />
            </>
        </div>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" > 
                {this.state.loadSubmission ? <SubmissionLoader /> : ''} 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>
                    <Async
                        identifier="MOCPermanentDeviationReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any,ownProps: any) {
    const instances = PermitToWorkModel.list(); 
    const formStage = ownProps.match.params.formStage;
    return { instances, totalPageCount: state.miscellaneous.get('listCount'), formStage, forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const PTWList = withRouter(connect<IPTWListPageProps, any, any>(mapStateToProps)(PTWListImpl));
