import * as React from 'react';
import { connect } from 'react-redux';
import { getCustomNotificationDetails } from '../../services/notificationService';
import { ICustomNotification } from '../../interfaces';
import { Title } from './Title';
import { Row, Col } from 'react-bootstrap';
import { Async } from './Async';
import { Loader } from './Loader';
import { ErrorPage } from './ErrorPage';
import { BasePage } from './BasePage';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { white } from 'material-ui/styles/colors';

export interface ICustomNotificationDetailsProps {
    notificationId: string;
    notificationData: ICustomNotification;
    userUID: string;
}

export interface ICustomNotificationDetailsState {
    id: string;
}

export class CustomNotificationDetailsImpl extends React.PureComponent<ICustomNotificationDetailsProps,
    ICustomNotificationDetailsState> {

    constructor(props: ICustomNotificationDetailsProps | Readonly<ICustomNotificationDetailsProps>) {
        super(props);
        this.state = { id: '' };
    }

    async componentWillReceiveProps(nextProps: { notificationId: any; }) {
        const { notificationId } = nextProps;
        if (notificationId !== this.state.id) {
            setLoading('CustomNotificationDetails');
            await getCustomNotificationDetails(notificationId, this.props.userUID);
            this.setState({
                id: notificationId
            });
            setSuccess('CustomNotificationDetails');
        }
    }

    promise = async () => {
        const { notificationId, userUID } = this.props;
        return await getCustomNotificationDetails(notificationId, userUID);
    }

    renderContent = () => {
        const { notificationData } = this.props;
        if (!notificationData) {
            return <div></div>;
        }
        return <div style={{ padding: '20px' }}>
            <Title text="Notification Information" />
            <Row style={{ padding: '20px 0px' }} className="show-grid">
                <Col style={{ textAlign: 'right', color: 'Yellow' }} xs={6} sm={6} className="key">Title:</Col>
                <Col style={{ textAlign: 'left', color: 'white' }} 
                xs={1} sm={3} className="value">{notificationData.title}</Col>
            </Row >
            <Row style={{ paddingBottom: '20px' }} className="show-grid">
                <Col style={{ textAlign: 'right', color: 'Yellow' }} xs={6} sm={6} className="key">Message:</Col>
                <Col style={{ textAlign: 'left', color: 'white' }} 
                xs={1} sm={3} className="value">{notificationData.message}</Col>
            </Row >
            <Row className="show-grid">
                <Col style={{ textAlign: 'right', color: 'Yellow' }} xs={12} sm={6} className="key">Files:</Col>
                {notificationData.fileInfo && notificationData.fileInfo.length ? notificationData.fileInfo.map((fileData, index) => {
                    return <Col
                        key={index} 
                        style={index > 0 ? { paddingTop: '10px' } : {}} xs={12}
                        sm={{span: 6, offset: index > 0 ? 6 : 0}}
                        className="value"
                    >
                      <span style={{color: white}}>  <i  className="fa fa-external-link" />&nbsp;</span>
                        <a target="_blank" rel="noreferrer" href={fileData.fileLink}>{fileData.title}</a></Col>;
                }): <Col style={{ textAlign: 'left', color: 'white' }} 
                xs={1} sm={3} className="value">No files uploaded</Col>}
            </Row >
        </div>;
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="CustomNotificationDetails"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

/* const KeyValue = ({ label, children }: any) => {
    return <Row style={{ paddingBottom: '20px' }} className="show-grid">
        <Col style={{ textAlign: 'right', color: '#969696' }} xs={6} sm={6} className="key">{label}</Col>
        <Col xs={6} sm={6} className="value">{children}</Col>
    </Row >; 
}; */
export function mapStateToProps(state: any, ownProps: any) {
    return {
        notificationData: state.miscellaneous.get('customNotitification'),
        notificationId: ownProps.match.params.id,
        userUID: state.login.get('userUID')
    };
}

export const CustomNotificationDetailsPage = connect<{}, {}, ICustomNotificationDetailsProps>
    (mapStateToProps)(CustomNotificationDetailsImpl);
