import * as React from 'react';
import { Label } from '../reusableComponents/FormComponents/Label'; 
import { Row, Col } from 'react-bootstrap';
import { actions } from 'react-redux-form';
import { dispatch, showAlert } from '../../utils/generalUtils';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { bodyParts } from '../../constants/general';
import { IncidentReportModel } from '../../model/IncidentReportModel'; 
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel'; 
import { Alert } from '../Alert';
import { removeAlert } from '../../../src/actions/alertActions';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IInjuryFormProps {
    editInstance?: IncidentReportModel;
    incidentNotificationInstance?:IncidentNotificationModel;
    forms?: any;
}

export interface IInjuryFormState {
    isNatureOfInjury: boolean;
}

export class InjuryForm extends React.PureComponent<IInjuryFormProps, IInjuryFormState> {
    constructor(props: IInjuryFormProps | Readonly<IInjuryFormProps>) {
        super(props);
        
        let editInstance = this.props.editInstance; 
        this.state = { isNatureOfInjury: editInstance && editInstance.props.natureOfInjury === '1' ? true : false };
    }

    medicalResponseTaken = '';
    statusUpdate = '';

    handleNatureOfInjury = (isYes: any) => {
        if (isYes) {
            this.setState({
                isNatureOfInjury: isYes
            });
        } else {
            this.setState({
                isNatureOfInjury: isYes
            });
            dispatch(actions.change('forms.incidentDetails.medicalResponseTaken', ''));
            dispatch(actions.change('forms.incidentDetails.statusUpdate', ''));
            dispatch(actions.change('forms.incidentDetails.dateReturnedToWork', ''));
            dispatch(actions.change('forms.incidentDetails.actualDaysLost', ''));
            dispatch(actions.change('forms.incidentDetails.firstAidTreatment', ''));
            dispatch(actions.change('forms.incidentDetails.estimatedDaysLost', ''));
        }
    }

    employeeTypeChange = (checked: boolean) => {
        dispatch(actions.change('forms.incidentDetails.contractor', !checked)); 
    }
    contractorTypeChange = (checked: boolean) => {
        dispatch(actions.change('forms.incidentDetails.employee', !checked));
    }

    /* actualDaysLostChange(value) { 
        if(parseInt(value)<0) { 
            dispatch(actions.change('forms.incidentDetails.actualDaysLost', ''));
        }
    } */
    actualDaysLostChange = (e: any) => { e.preventDefault(); 
        if(parseInt(e.target.value)<0) {  
            showAlert('Invalid actual days', 'actual-days-lost-alert', 'danger');
            dispatch(actions.change('forms.incidentDetails.actualDaysLost', ''));
            return false; 

        } else {
            removeAlert('actual-days-lost-alert');
            return;
        } 
    }
    estimatedDaysLostChange = (e: any) => {
        e.preventDefault();   
        if(parseInt(e.target.value)<0) {  
            showAlert('Invalid estimated days', 'estimated-days-lost-alert', 'danger');
            dispatch(actions.change('forms.incidentDetails.estimatedDaysLost', ''));
            return false; 

        } else {
            removeAlert('estimated-days-lost-alert');
            return;
        } 
    }


    renderMedicalInformationFillUp = () => { 
        let editInstance = this.props.editInstance;  
        const { forms } = this.props; 
        return (<div>
            <Row className="show-grid">
                <Col sm={4} xs={12}>
                    <Label sm={12} htmlFor="medicalResponseTaken">Medical Response Taken:</Label>
                    <VoiceRecognition labelText="" defaultValue={forms.medicalResponseTaken} model="forms.incidentDetails.medicalResponseTaken" />  
                    <Col sm={12} className="input">
                        <RRFInput
                            type="textarea"
                            placeholder="Enter medical response taken..."
                            model=".medicalResponseTaken"
                            textAreaRows={3} 
                            defaultValue={editInstance && editInstance.props.medicalResponseTaken}
                        />
                    </Col>

                </Col>
                <Col sm={4} xs={12}>
                    <Label sm={12} htmlFor="statusUpdate">Status Update:</Label>
                    <VoiceRecognition labelText="" defaultValue={forms.statusUpdate} model="forms.incidentDetails.statusUpdate" />  
                    <Col sm={12} className="input">
                        <RRFInput
                            type="textarea"
                            placeholder="Enter status updates..."
                            model=".statusUpdate"
                            textAreaRows={3}
                            defaultValue={editInstance && editInstance.props.statusUpdate}
                        />
                    </Col>
                </Col>
                <Col sm={4} xs={12}>
                    <Label sm={12} htmlFor="dateReturnedToWork">Date Returned To Work?:</Label>
                    <Col sm={12} className="input">
                        <RRFInput
                            id="dateReturnedToWork"
                            type="date"
                            model=".dateReturnedToWork"
                            defaultValue={ editInstance ? editInstance.props.dateReturnedToWork: forms.dateReturnedToWork || (forms && forms.dateOfIncident)}
                            //mindate={incidentNotificationInstance && format(incidentNotificationInstance.props.dateOfIncident,'yyyy-MM-dd')}
                            mindate={forms && forms.dateOfIncident }
                            //maxdate={format(new Date, 'yyyy-MM-dd')}
                        />
                    </Col>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col sm={4} xs={12} >
                    <Label sm={12} htmlFor="actualDaysLost">Actual Days Lost / Restricted:</Label> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="actualDaysLost"
                            type="number"
                            model=".actualDaysLost"
                            placeholder="Enter actual days lost..."
                            defaultValue={editInstance && editInstance.props.actualDaysLost} 
                            //onChange={e => this.actualDaysLostChange(e.target.value)}
                            onChange={this.actualDaysLostChange}
                        /> 
                        <Alert className="danger-alert" id="actual-days-lost-alert" />
                    </Col>
                </Col>
                <Col sm={4} xs={12} >
                    <Label sm={12} htmlFor="firstAidTreatment">First Aid Treatment Type:</Label>
                    <VoiceRecognition labelText="" defaultValue={forms.firstAidTreatment} model="forms.incidentDetails.firstAidTreatment" />  
                    <Col sm={12} className="input">
                        <RRFInput
                            id="firstAidTreatment"
                            type="text"
                            model=".firstAidTreatment"
                            placeholder="Enter first aid treatment type..."
                            defaultValue={editInstance && editInstance.props.firstAidTreatment}
                        />
                    </Col>

                </Col>
                <Col sm={4} xs={12} >
                    <Label sm={12} htmlFor="estimatedDaysLost">Estimated Days Lost / Restricted:</Label> 
                    <Col sm={12} className="input">
                        <RRFInput
                            id="estimatedDaysLost"
                            type="number"
                            model=".estimatedDaysLost"
                            placeholder="Enter estimated days lost..."
                            defaultValue={editInstance && editInstance.props.estimatedDaysLost}
                            onChange={this.estimatedDaysLostChange}
                        /> 
                        <Alert className="danger-alert" id="estimated-days-lost-alert" />
                    </Col>
                </Col>
            </Row>
        </div>
        );
    }

    render() {
        const { isNatureOfInjury } = this.state;
        let editInstance = this.props.editInstance;  
        const { forms } = this.props; 
        return (
            <div className="injury-illness">
                <div>
                    <fieldset className="form-top-fieldset">
                        <legend> <span style={{color: 'yellow'}}>
                        Injury or Illness</span></legend>
                        <Row className="show-grid">
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="sufferEmployeeName">Name:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.sufferEmployeeName} model="forms.incidentDetails.sufferEmployeeName" />  
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="sufferEmployeeName"
                                        placeholder="Enter name..."
                                        type="text"
                                        model=".sufferEmployeeName"
                                        defaultValue={editInstance && editInstance.props.sufferEmployeeName}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="company">Company:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.company} model="forms.incidentDetails.company" />  
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="company"
                                        placeholder="Enter company..."
                                        type="text"
                                        model=".company"
                                        defaultValue={editInstance && editInstance.props.company}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="jobPosition">Job Position:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.jobPosition} model="forms.incidentDetails.jobPosition" />  
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="jobPosition"
                                        placeholder="Enter job position..."
                                        type="text"
                                        model=".jobPosition"
                                        defaultValue={editInstance && editInstance.props.jobPosition}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="timeOnJob">Time On Job:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.timeOnJob} model="forms.incidentDetails.timeOnJob" />  
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="timeOnJob"
                                        placeholder="Enter time spent on job..."
                                        type="text"
                                        model=".timeOnJob"
                                        defaultValue={editInstance && editInstance.props.timeOnJob}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid injury-radio-buttons checkbox-row">
                            <Col sm={6}>
                                <label
                                    className="checkbox-label-one col-sm-12"
                                    htmlFor="drugTestConducted"
                                >
                                    Post Incident Drug Test Conducted:
                        </label>
                                <Col sm={12} className="work-related text-center">
                                    <label htmlFor="drugTestConducted">Yes</label>
                                    <RRFInput
                                        id="drugTestConductedYes"
                                        type="radio"
                                        radioValue="yes"
                                        model=".drugTestConducted"
                                        radioName="drugTestConducted"
                                        defaultValue={editInstance && editInstance.props.drugTestConducted}
                                    /><label htmlFor="drugTestConductedYes"></label>
                                    <label htmlFor="drugTestConducted">No</label>
                                    <RRFInput
                                        id="drugTestConductedNo"
                                        radioValue="no"
                                        type="radio"
                                        model=".drugTestConducted"
                                        radioName="drugTestConducted"
                                        defaultValue={editInstance && editInstance.props.drugTestConducted}
                                    /> <label htmlFor="drugTestConductedNo"></label>
                                </Col>
                            </Col>
                            <Col sm={3} className="checkbox-center">
                                <label className="checkbox-label" htmlFor="employee">Employee:</label>
                                <RRFInput
                                    model=".employee"
                                    type="checkbox"
                                    id="employee"
                                    onChange={this.employeeTypeChange}
                                    defaultValue={!editInstance || (editInstance && editInstance.props.employee===1)?true:false}
                                />
                                <label className="checkbox-field" htmlFor="employee"></label>
                            </Col>
                            <Col sm={3} className="checkbox-right">
                                <label className="checkbox-label" htmlFor="contractor">Contractor:</label>
                                <RRFInput
                                    model=".contractor"
                                    type="checkbox"
                                    id="contractor"
                                    onChange={this.contractorTypeChange}
                                    defaultValue={editInstance && editInstance.props.contractor===1?true:false}
                                />
                                <label className="checkbox-field" htmlFor="contractor"></label>
                            </Col>
                        </Row>
                    </fieldset>
                </div>

                <div className="form-middle" >
                    <fieldset>
                        <legend><span style={{color: 'yellow'}}>
                        Nature Of Injury or Illness</span></legend>
                        <Row className="show-grid"> 
                            <Col sm={6} xs={12}>
                                <Label sm={12} htmlFor="natureOfInjury">Injury or Illness?:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="natureOfInjury"
                                        placeholder="Enter nature of injury or illness..."
                                        type="dropdown"
                                        menuItems={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false }
                                        ]}
                                        model=".natureOfInjury"
                                        onSelect={this.handleNatureOfInjury}
                                        defaultValue={isNatureOfInjury || (editInstance ?(editInstance.props.natureOfInjury==='0'?false:true): '')}
                                    />
                                </Col>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required sm={12} htmlFor="bodyPart">Body Part:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="bodyPart"
                                        placeholder="Enter body part..."
                                        type="dropdown"
                                        model=".bodyPart"
                                        menuItems={[
                                            { value: bodyParts[0], label: bodyParts[0] },
                                            { value: bodyParts[1], label: bodyParts[1] },
                                            { value: bodyParts[2], label: bodyParts[2] },
                                            { value: bodyParts[3], label: bodyParts[3] },
                                            { value: bodyParts[4], label: bodyParts[4] },
                                            { value: bodyParts[5], label: bodyParts[5] }]}
                                        defaultValue={editInstance && editInstance.props.bodyPart}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        {isNatureOfInjury || forms.natureOfInjury ? this.renderMedicalInformationFillUp() : null}
                    </fieldset>
                </div>

            </div >
        );
    }
}
