import { saveActivitiesOptions } from '../../../actions/summaryActions';
import { getConcernTypes } from '../../../services/oiformService';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { RRFInput } from '../FormComponents/RRFInput';
import { Label } from '../FormComponents/Label';
import { Async } from '../Async';
import { IForms, IConcernType } from '../../../interfaces';  
import { dispatch } from '../../../utils/generalUtils';
import { actions } from 'react-redux-form';

export interface IConcernDropdownProps {
    model: string;
    id: string;
    defaultValue?: string;
    forms?: IForms;
    concernTypes?: IConcernType[];
    onSelect?: (val: string) => void;
}

export class ConcernDropdownImpl extends React.PureComponent<IConcernDropdownProps> {
    constructor(props: IConcernDropdownProps | Readonly<IConcernDropdownProps>) {
        super(props);
    }

    promise = async () => {
        /*const { concernTypes } = this.props;
        if (!concernTypes) { */
            return await getConcernTypes();
        /* }
        return null; */
    }

    static contextTypes = {
        formModel: PropTypes.string
    };
    
    getConcernTypesOptions = () => {
        const { concernTypes } = this.props;
        if (!concernTypes) {
            return;
        }
        let options: any = [];
        concernTypes.map(concernData => {
            if (concernData.active === 1) {
                options.push({
                    value: concernData.concern,
                    label: concernData.concern
                });
            }
        });
        return options;
    }

    handleActivityOptions = (concern: string, isDefault?:boolean) => { 
        const { concernTypes, forms } = this.props;  
        const { context: { formModel } } = this; 
        const menuItems: any = []; 
        if(!concernTypes) return; 
        const formName = (formModel || '').split('.');
       /*  const modelName = model.split('.'); */
        const changedConcernType = (forms as any)[`${formName[1]}`]['changedConcernType'];  
        //let currentConcern =  forms.oiForm.changedConcernType || concern ;  
        let currentConcern =  changedConcernType || concern ;  
        concernTypes.forEach(concernData => {
            if (concernData.concern === currentConcern && concernData.activities) { 
                concernData.activities.sort(function(a,b){
                    return a.activity.localeCompare(b.activity);
                })
                concernData.activities.forEach(activityData => {
                    if(activityData.activity !== 'Other') {
                        menuItems.push({
                            value: activityData.activity, label: activityData.activity
                        });
                    } 
                });
                menuItems.push({ value: 'Other', label: 'Other' });
            }
        });
        if (this.props.onSelect) {
            this.props.onSelect(concern);
        } 
        saveActivitiesOptions(menuItems);
    }

    renderConcernDropdown = (isDisabled: boolean, placeholder?: string) => {
        const { id, model, defaultValue } = this.props; 
        if(defaultValue) {
            this.handleActivityOptions(defaultValue);
        }
        return <div>
            <Label required sm={12} htmlFor="concernType">Concern Type:</Label>
            <Col sm={12} className="input">
                <RRFInput
                    id={id}
                    model={model}
                    isDisabled={isDisabled}
                    type="dropdown"
                    placeholder={placeholder}
                    // optionComponent={DeleteOptionsComponent}
                    menuItems={this.getConcernTypesOptions()}
                    onSelect={( this.onChangeEvent )}
                    defaultValue={ defaultValue}
                />
            </Col>
        </div>;
    }
    onChangeEvent = async (concern: string) => {  
        const { context: { formModel } } = this; 
        await dispatch(actions.change(`${formModel}.changedConcernType`, concern));  
        if(concern!=='Other') { 
            await dispatch(actions.change(`${formModel}.activity`, '')); 
            this.handleActivityOptions(concern)
        } else { 
            await dispatch(actions.change(`${formModel}.activity`, '')); 
        } 
    }
    render() { 
        return (
            <Async
                identifier="ConcernType"
                promise={this.promise}
                loader={this.renderConcernDropdown(true, 'Loading...')}
                error={this.renderConcernDropdown(true, 'Cannot get concern types ')}
                content={this.renderConcernDropdown(false)}
            />
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        concernTypes: state.formSummary.get('concernTypes'),
        forms: state.forms
    }; 
}

export const ConcernDropdown = connect<{}, {}, IConcernDropdownProps>(mapStateToProps)(ConcernDropdownImpl);
