import { getReportChartData, getReportDataList, getReportDetailData } from '../actions/reportActions';
import { SafetyWalkthroughCommentListModel, SafetyWalkthroughCommentModel, SafetyWalkthroughModel, SafetyWalkthroughReportChartData, SafetyWalkthroughReportDetail } from '../model/SafetyWalkthroughModel';
import { closeCallSummaryProps } from '../model/MechModel';
import { dispatch } from '../utils/generalUtils';
import { getCommentList, refreshAttributeList } from '../actions/attributeAction';
import { UserModel } from '../model/UserModel';
import { delCustom, getCustom, post, postCustom, putCustom } from '../utils/HTTP';
import { showAlert } from '../utils/generalUtils';
import { IHistory, ISubAttributeData, IForms } from '../interfaces'; 
import { format } from 'date-fns'; 
import { actions } from 'react-redux-form';
import { setSuccess } from '../actions/loadingActions'; 
import { IGoogleMaps, IMapRegion} from '../interfaces';
import { saveListCount } from '../actions/miscellaneousActions'; 
import { IAttributeDataState } from '../components/Micellaneous/SafetyWalkAssesment/AddAttribute';
import { ChecklistAttributeModel } from '../model/ChecklistModel';

export async function getAllAttribute(formType: string) {
  const company = UserModel.getCompanyName();
  try {
    const response = await getCustom(`/GetQuestions/${formType}/${company}`); 
    let responseList:Array<any> = [];
    if (response.data && response.data.data && response.data.data!=='No data found') {
      for (let item in response.data.data) {
        let responseData = { attribute: '', name: [] };
        responseData.attribute = item;
        responseData.name = response.data.data[item];
        responseList.push(responseData);
      }
      dispatch(refreshAttributeList(responseList));
    }
    return responseList;
  } catch (error) {
    throw error;
  }
}
export async function getAllComments(startDate: number | Date, endDate: number | Date) {
  const company = UserModel.getCompanyName();
  let requestBody = {
    StartDate: format(startDate, 'MM-DD-YYYY'),
    EndDate: format(endDate, 'MM-DD-YYYY'),
    Company: company
  };
  try {
    const response = await putCustom(`/GetSafetyComments`, requestBody);
    if (response.data && response.data.data) {
      let supervisorCommentList = Array<SafetyWalkthroughCommentModel>();
      let managrCommentList = Array<SafetyWalkthroughCommentModel>();
      let closecallCommentList = Array<SafetyWalkthroughCommentModel>();
      response.data.data.map((item: { FormType: string; Date: any; Comment: any; SubmittedBy: any; }) => {

        if (item.FormType === 'ManagerCheckList') {
          let supervisorComment = {
            formType: item.FormType,
            date: item.Date,
            comment: item.Comment,
            name: item.SubmittedBy
          };
          managrCommentList.push(supervisorComment);
        }
        if (item.FormType === 'SupervisorCheckList') {
          let supervisorComment = {
            formType: item.FormType,
            date: item.Date,
            comment: item.Comment,
            name: item.SubmittedBy
          };
          supervisorCommentList.push(supervisorComment);
        }
        if (item.FormType === 'CloseCallCheckList') {
          let closecallComment = {
            formType: item.FormType,
            date: item.Date,
            comment: item.Comment,
            name: item.SubmittedBy
          };
          closecallCommentList.push(closecallComment);
        }
      });
      let commentList: SafetyWalkthroughCommentListModel = {
        swManagerComment: managrCommentList, swSupervisorComment: supervisorCommentList, swCloseCallComment: closecallCommentList
      };
      dispatch(getCommentList(commentList));
    }
  } catch (error) {
    throw error;
  }
}
 
export async function getAllCheckListForms(urlData: any) { 
  try { 
    urlData.pageNumber =  urlData.pageNumber || 1;
    urlData.sortIndexField = urlData.sortIndexField || '';
    urlData.sortingOrder = urlData.sortingOrder || '';
    let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
    const response = await post(`/GetAllCheckListForms`, urlData, axiosPreviousToken);
    if (response.data.data != null) {
      let reportDataList = new Array<SafetyWalkthroughModel>();
      response.data.data.map((item: { FormType: string; UserName: string | undefined; UserId: string | undefined; Company: string | undefined; Date: number | Date; Time: string | undefined; UniqueId: string | undefined; totalCloseCallCount: number | undefined; totalCloseCallOpenCount: number | undefined; checklistTitle: string | undefined; serialNo: string | undefined; }) => {
        let formType = 'NA'; 
        switch(item.FormType) {
          case 'SupervisorCheckList': formType = 'Supervisor Checklist'; break;
          case 'ManagerCheckList': formType = 'Manager Checklist'; break; 
          case 'CloseCallCheckList': formType = 'Close Call Checklist'; break;
          default: formType = item.FormType; break;
        }
        let reportItem: SafetyWalkthroughModel = {};
        reportItem.userName = item.UserName;
        reportItem.userId = item.UserId;
        reportItem.company = item.Company;
        reportItem.date = format(new Date(item.Date),'MM/dd/yyyy');
        reportItem.time = item.Time;
        reportItem.formType = formType;
        reportItem.formId = item.UniqueId;
        reportItem.totalCloseCallCount = item.totalCloseCallCount;
        reportItem.totalCloseCallOpenCount = item.totalCloseCallOpenCount;
        reportItem.checklistTitle = item.checklistTitle;
        reportItem.serialNo = item.serialNo;
        reportDataList.push(reportItem);
      });
      dispatch(getReportDataList(reportDataList));
      saveListCount(response.data.TotalPages);
    }
  } catch (error) {
    throw error;
  }
}

export async function getAllCheckListSerialDetails(params: any) {
  const company = UserModel.getCompanyName();
  try { 
    params['company'] = company;
    const response = await postCustom(`/getAllCheckListSerialDetails`, params);
    if (response.data.data != null) {
      let reportDataList = new Array<SafetyWalkthroughReportDetail>();
      response.data.data.map((item: any) => {
        let reportItem: any = {}; 
        reportItem.key = item.key;
        reportItem.id = item.key;
        reportItem.serialNo = item.serialNo;
        reportItem.checklistTitle = item.checklistTitle;
        reportItem.Comment = item.Comment;
        reportDataList.push(reportItem);
      }); 
      dispatch(getReportDataList(reportDataList));
    }
  } catch (error) {
    throw error;
  }
}

function dynamicSort(property: any) {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a: { [x: string]: string; }, b: { [x: string]: any; }) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

export async function getSWReportDetail(formId: any, formType: string) { 
  const company = UserModel.getCompanyName();
  try {
    const response = await getCustom(`/GetCheckListDetails/${company}/${formId}`);
    //const response = await getCustom(`/GetCheckListDetails/${formId}`);
    if (response.data != null) {
      let reportDetail: SafetyWalkthroughReportDetail = {
        company: response.data.data.Company,
        projectName: response.data.data.ProjectName,
        groupName: response.data.data.Groupname,
        date: response.data.data.Date,
        overAllComment: response.data.data.Comment,
        formType: formType,
        subAttributeList: [],
        totalScore: response.data.data.TotalScore,
        checklistTitle: response.data.data.checklistTitle,
        serialNo: response.data.data.serialNo,
        userName: response.data.data.UserName,
        imageUrls: response.data.data.imageUrls,
        videoUrls: response.data.data.videoUrls,
        dateOfIncident: response.data.data.dateOfIncident || response.data.data.Date,
      };  
      for (let item in response.data.data.Answers) {
        let answer: any = {
          attributeName: item,
          answerList: [],
        };
        let subAttributeList:Array<any> = [];
        for (let innerItem in response.data.data.Answers[item]) { 
          subAttributeList.push({
            key: innerItem,
            value: response.data.data.Answers[item][innerItem]
          });
        }
        answer.answerList = subAttributeList.sort(dynamicSort('key'));
        reportDetail.subAttributeList && reportDetail.subAttributeList.push(answer);
      }
      reportDetail.subAttributeList && reportDetail.subAttributeList.sort(dynamicSort('attributeName')); 
      dispatch(getReportDetailData(reportDetail));
    }
  } catch (error) {
    throw error;
  }
}
export async function applyMonthlyFilterSafetyWalkthrough(year: string, month: any) {
  if (!month) {
    setSuccess('ReportsPage');
    showAlert('Please select month.', 'oi-report-summary', 'danger');
    return;
  } else if (!year) {
      setSuccess('ReportsPage');
      showAlert('Please select year.', 'oi-report-summary', 'danger');
      return;
  }
  try {
    //await getSafetyWalkthroughReportData('monthly', year, month); 
    setSuccess('ReportsPage');
  } catch (error) { 
    setSuccess('ReportsPage');
    throw error;
  }
}
export async function applyYearlyFilterForSafetyWalkthrough(year: string) {
  if (!year) {
    setSuccess('ReportsPage');
    showAlert('Please select year.', 'oi-report-summary', 'danger');
    return;
  }
  try {
    //await getSafetyWalkthroughReportData('yearly', year, 'na'); 
    setSuccess('ReportsPage');
  } catch (error) { 
    setSuccess('ReportsPage');
    throw error;
  }
}
//export async function getSafetyWalkthroughReportData(fetchType: any, yearDetails: string, monthlyDetail: any) {
export async function getSafetyWalkthroughReportData(forms?: IForms ) {  
  try {
    //const response = await getCustom(`/SafetyWalkSummary/${company}/${fetchType}/${yearDetails}/${monthlyDetail}`);
    const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'yearly', year: forms?.commonFilterForm.year || '0', month: forms?.commonFilterForm.month || '0', }; 
    const response = await post('/SafetyWalkSummary', data);  
    if (response.data != null) {
      let reportChartData: SafetyWalkthroughReportChartData = {};
      reportChartData.managerCheckListPercentage = response.data.ManagerCheckListPercentage;
      reportChartData.supervisorCheckListPercentage = response.data.SupervisorCheckListPercentage;
      reportChartData.monthlyCount = response.data.MonthlyCount;
      reportChartData.monthlySum = response.data.MonthlySum;
      // reportChartData.supervisorCheckListPercentage = response.data.SupervisorCheckListPercentage;
      reportChartData.yearlyCount = response.data.YearlyCount;
      reportChartData.yearlySum = response.data.YearlySum;
      let chartData = getSafetyWalkthroughLineChartData(data.fetchType, reportChartData);

      reportChartData.reportCountData = chartData.yearlyCountData;
      reportChartData.reportSumData = chartData.yearlySumData;
      reportChartData.averageReportData = chartData.averageReportData;
      dispatch(getReportChartData(reportChartData));
    }
  } catch (error) {
    throw error;
  }
}
/* tslint:disable*/
export function getSafetyWalkthroughLineChartData(filterType: string, safetyData: SafetyWalkthroughReportChartData) {
  let reportData: any = { averageReportData: {}, yearlyCountData: [], yearlySumData: [], monthlyCountData: [], monthlySumData: [] };

  reportData.averageReportData = [{ name: 'Manager', value: safetyData.managerCheckListPercentage },
  { name: 'Supervisor', value: safetyData.supervisorCheckListPercentage }];
  if (filterType === 'yearly' || filterType === 'summary') {
    reportData.yearlyCountData = [
      { name: 'Jan', Manager: safetyData.yearlyCount.ManagerCheckList['January'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['January'] },
      { name: 'Feb', Manager: safetyData.yearlyCount.ManagerCheckList['February'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['February'] },
      { name: 'Mar', Manager: safetyData.yearlyCount.ManagerCheckList['March'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['March'] },
      { name: 'Apr', Manager: safetyData.yearlyCount.ManagerCheckList['April'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['April'] },
      { name: 'May', Manager: safetyData.yearlyCount.ManagerCheckList['May'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['May'] },
      { name: 'Jun', Manager: safetyData.yearlyCount.ManagerCheckList['June'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['June'] },
      { name: 'July', Manager: safetyData.yearlyCount.ManagerCheckList['July'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['July'] },
      { name: 'Aug', Manager: safetyData.yearlyCount.ManagerCheckList['August'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['August'] },
      { name: 'Sep', Manager: safetyData.yearlyCount.ManagerCheckList['September'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['September'] },
      { name: 'Oct', Manager: safetyData.yearlyCount.ManagerCheckList['October'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['October'] },
      { name: 'Nov', Manager: safetyData.yearlyCount.ManagerCheckList['November'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['November'] },
      { name: 'Dec', Manager: safetyData.yearlyCount.ManagerCheckList['December'], Supervisor: safetyData.yearlyCount.SupervisorCheckList['December'] },
    ];
    reportData.yearlySumData = [
      { name: 'Jan', Manager: safetyData.yearlySum.ManagerCheckList['January'], Supervisor: safetyData.yearlySum.SupervisorCheckList['January'] },
      { name: 'Feb', Manager: safetyData.yearlySum.ManagerCheckList['February'], Supervisor: safetyData.yearlySum.SupervisorCheckList['February'] },
      { name: 'Mar', Manager: safetyData.yearlySum.ManagerCheckList['March'], Supervisor: safetyData.yearlySum.SupervisorCheckList['March'] },
      { name: 'Apr', Manager: safetyData.yearlySum.ManagerCheckList['April'], Supervisor: safetyData.yearlySum.SupervisorCheckList['April'] },
      { name: 'May', Manager: safetyData.yearlySum.ManagerCheckList['May'], Supervisor: safetyData.yearlySum.SupervisorCheckList['May'] },
      { name: 'Jun', Manager: safetyData.yearlySum.ManagerCheckList['June'], Supervisor: safetyData.yearlySum.SupervisorCheckList['June'] },
      { name: 'July', Manager: safetyData.yearlySum.ManagerCheckList['July'], Supervisor: safetyData.yearlySum.SupervisorCheckList['July'] },
      { name: 'Aug', Manager: safetyData.yearlySum.ManagerCheckList['August'], Supervisor: safetyData.yearlySum.SupervisorCheckList['August'] },
      { name: 'Sep', Manager: safetyData.yearlySum.ManagerCheckList['September'], Supervisor: safetyData.yearlySum.SupervisorCheckList['September'] },
      { name: 'Oct', Manager: safetyData.yearlySum.ManagerCheckList['October'], Supervisor: safetyData.yearlySum.SupervisorCheckList['October'] },
      { name: 'Nov', Manager: safetyData.yearlySum.ManagerCheckList['November'], Supervisor: safetyData.yearlySum.SupervisorCheckList['November'] },
      { name: 'Dec', Manager: safetyData.yearlySum.ManagerCheckList['December'], Supervisor: safetyData.yearlySum.SupervisorCheckList['December'] },
    ];
  } else if (filterType === 'monthly') {
    for (const key in safetyData.monthlyCount.ManagerCheckList) {
      reportData.yearlyCountData.push({ name: key, Manager: safetyData.monthlyCount.ManagerCheckList[key], Supervisor: safetyData.monthlyCount.SupervisorCheckList[key] });
    }
    for (const key in safetyData.monthlySum.ManagerCheckList) {
      reportData.yearlySumData.push({ name: key, Manager: safetyData.monthlySum.ManagerCheckList[key], Supervisor: safetyData.monthlySum.SupervisorCheckList[key] });
    }
  }
  return reportData;
}
/* tslint:enable*/
export async function applyMonthlyFilterCloseCallSummary(year: string, month: any) {
  if (!month) {
    setSuccess('ReportsPage');
    showAlert('Please select month.', 'oi-report-summary', 'danger');
    return;
  } else if (!year) {
      setSuccess('ReportsPage');
      showAlert('Please select year.', 'oi-report-summary', 'danger');
      return;
  }
  try { 
    year = year?year:new Date().getFullYear().toString(); 
    dispatch(actions.change(`forms.monthlyFilter.year`, year));
    //await getCloseCallReportSummaryData('monthly', year, month);
    setSuccess('ReportsPage');
  } catch (error) {
    throw error;
  }
}
export async function applyYearlyFilterCloseCallSummary(year: string) {
  if (!year) {
    setSuccess('ReportsPage');
    showAlert('Please select year.', 'oi-report-summary', 'danger');
    return;
  }
  try {
    //await getCloseCallReportSummaryData('yearly', year, 'na');
    setSuccess('ReportsPage');
  } catch (error) {
    throw error;
  }
}
//export async function getCloseCallReportSummaryData(fetchType: any, yearDetails: string, monthlyDetail: any) {
export async function getCloseCallReportSummaryData(forms?: IForms) { 
  try {
    //const response = await getCustom(`/CloseCallSummary/${company}/${fetchType}/${yearDetails}/${monthlyDetail}`); 
    const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '0', month: forms?.commonFilterForm.month || '0', }; 
        const response = await post('/CloseCallSummary', data); 
    if (response != null) { 
      let reportChartData: closeCallSummaryProps = {};
      reportChartData.yearlyCount = response.data.YearlyCount;
      reportChartData.monthlyCount = response.data.MonthlyCount;  
      let chartData = getCloseCallLineChartData(data.fetchType, reportChartData); 
      reportChartData.reportCountData = chartData.yearlyCountData; 
      dispatch(getReportChartData(reportChartData));
    } 
  } catch (error) {
    throw error;
  }
}
export function getCloseCallLineChartData(filterType: string, closeCallData: closeCallSummaryProps) { 
  let reportData: any = { yearlyCountData: [], monthlyCountData: []};  
  if (filterType === 'yearly' || filterType === 'summary') {
    reportData.yearlyCountData = [
      { name: 'Jan', CheckList: closeCallData.yearlyCount.CloseCallChecklist['January'], Mechanical: closeCallData.yearlyCount.mechanicalForm['January'], Transportation: closeCallData.yearlyCount.transportationForm['January'], Enginnering: closeCallData.yearlyCount.engineeringForm['January'] },
      { name: 'Feb', CheckList: closeCallData.yearlyCount.CloseCallChecklist['February'], Mechanical: closeCallData.yearlyCount.mechanicalForm['February'], Transportation: closeCallData.yearlyCount.transportationForm['February'], Enginnering: closeCallData.yearlyCount.engineeringForm['February'] },
      { name: 'Mar', CheckList: closeCallData.yearlyCount.CloseCallChecklist['March'], Mechanical: closeCallData.yearlyCount.mechanicalForm['March'], Transportation: closeCallData.yearlyCount.transportationForm['March'], Enginnering: closeCallData.yearlyCount.engineeringForm['March'] },
      { name: 'Apr', CheckList: closeCallData.yearlyCount.CloseCallChecklist['April'], Mechanical: closeCallData.yearlyCount.mechanicalForm['April'], Transportation: closeCallData.yearlyCount.transportationForm['April'], Enginnering: closeCallData.yearlyCount.engineeringForm['April'] },
      { name: 'May', CheckList: closeCallData.yearlyCount.CloseCallChecklist['May'], Mechanical: closeCallData.yearlyCount.mechanicalForm['May'], Transportation: closeCallData.yearlyCount.transportationForm['May'], Enginnering: closeCallData.yearlyCount.engineeringForm['May'] },
      { name: 'Jun', CheckList: closeCallData.yearlyCount.CloseCallChecklist['June'], Mechanical: closeCallData.yearlyCount.mechanicalForm['June'], Transportation: closeCallData.yearlyCount.transportationForm['June'], Enginnering: closeCallData.yearlyCount.engineeringForm['June'] },
      { name: 'July', CheckList: closeCallData.yearlyCount.CloseCallChecklist['July'], Mechanical: closeCallData.yearlyCount.mechanicalForm['July'], Transportation: closeCallData.yearlyCount.transportationForm['July'], Enginnering: closeCallData.yearlyCount.engineeringForm['July'] },
      { name: 'Aug', CheckList: closeCallData.yearlyCount.CloseCallChecklist['August'], Mechanical: closeCallData.yearlyCount.mechanicalForm['August'], Transportation: closeCallData.yearlyCount.transportationForm['August'], Enginnering: closeCallData.yearlyCount.engineeringForm['August'] },
      { name: 'Sep', CheckList: closeCallData.yearlyCount.CloseCallChecklist['September'], Mechanical: closeCallData.yearlyCount.mechanicalForm['September'], Transportation: closeCallData.yearlyCount.transportationForm['September'] , Enginnering: closeCallData.yearlyCount.engineeringForm['September']},
      { name: 'Oct', CheckList: closeCallData.yearlyCount.CloseCallChecklist['October'], Mechanical: closeCallData.yearlyCount.mechanicalForm['October'], Transportation: closeCallData.yearlyCount.transportationForm['October'], Enginnering: closeCallData.yearlyCount.engineeringForm['October'] },
      { name: 'Nov', CheckList: closeCallData.yearlyCount.CloseCallChecklist['November'], Mechanical: closeCallData.yearlyCount.mechanicalForm['November'], Transportation: closeCallData.yearlyCount.transportationForm['November'], Enginnering: closeCallData.yearlyCount.engineeringForm['November'] },
      { name: 'Dec', CheckList: closeCallData.yearlyCount.CloseCallChecklist['December'], Mechanical: closeCallData.yearlyCount.mechanicalForm['December'], Transportation: closeCallData.yearlyCount.transportationForm['December'], Enginnering: closeCallData.yearlyCount.engineeringForm['December'] },
    ];  
  } else if (filterType === 'monthly') {
    for (const key in closeCallData.monthlyCount.CloseCallChecklist) {
      reportData.yearlyCountData.push({ name: key, CheckList: closeCallData.monthlyCount.CloseCallChecklist[key], Mechanical: closeCallData.monthlyCount.mechanicalForm[key], Transportation: closeCallData.monthlyCount.transportationForm[key], Enginnering: closeCallData.monthlyCount.engineeringForm[key] });
    } 
  }
  return reportData;
}


export async function addAttribute(attributeParam: any) {
  const company = UserModel.getCompanyName();
  try {
    await postCustom(`/AddQuestions/${company}`, attributeParam);
    //await getAllAttribute(attributeParam.formType); 
  } catch (error) {
    throw error;
  }
}
export async function updateAttribute(attributeParam: any) {
  const company = UserModel.getCompanyName();
  try {
    await putCustom(`/AddQuestions/${company}`, attributeParam);
    // await getAllAttribute(attributeParam.formType);
  } catch (error) {
    throw error;
  }
}

export async function addForm(formDetails: any) {
  try {
    await postCustom(`/SubmitCheckList`, formDetails); 
  } catch (error) {
    throw error;
  }
}

export async function editAttribute(isEditAttribute: boolean) {
  const company = UserModel.getCompanyName();
  try {
    await putCustom(`/AddQuestions/${company}`, isEditAttribute);
  } catch (error) {
    throw error;
  }
}

export async function deleteAttribute(attribute: any) {
  const company = UserModel.getCompanyName();
  try {
    await delCustom(`/AddQuestions/${company}`, attribute);
  } catch (error) {
    throw error;
  }
}
export async function submitCheckList(param: any, history: IHistory | undefined, forms: any, imagesModelName: string, videoModelName?: string, editId?: string) { 
  /* let count = 0;
  const imageURLs:Array<any> = [];
  let videoCount = 0;
  const videoURLs: Array<any> = []; 
  if(forms.assessmentForm[imagesModelName].length>0) {
    for (let i = 0; i < forms.assessmentForm[imagesModelName].length; i++) {
      const file = await base64ToFile(forms.assessmentForm[imagesModelName][i].image,
        forms.assessmentForm[imagesModelName][i].name)
        .then(file => {
          return file;
        });
      try {
        const fileUpload = await uploadFile(file, forms.assessmentForm[imagesModelName][i].name.split('.')[0], 'fotos');
        if (fileUpload) {
          imageURLs.push(`${S3_URL}/fotos/${forms.assessmentForm[imagesModelName][i].name}`);
          count++;
        }
      } catch (error) {
        throw Error;
      }
    }
  } 
  if(videoModelName && forms.assessmentForm[videoModelName].length>0) {
    for (let i = 0; i < forms.assessmentForm[videoModelName].length; i++) {
      const file = await base64ToFile(forms.assessmentForm[videoModelName][i].file, forms.assessmentForm[videoModelName][i].name)
          .then(file => {
              return file;
          });
      try {
          const fileUpload = await uploadFile(file, forms.assessmentForm[videoModelName][i].name.split('.')[0], 'vdos'); 
          if (fileUpload) {
              videoURLs.push(`${S3_URL}/vdos/${forms.assessmentForm[videoModelName][i].name}`);
              videoCount++;
          }
      } catch (error) {
          throw Error;
      }
    } 
  }  */
  try {
    /* if (count === forms.assessmentForm[imagesModelName].length && videoCount === forms.assessmentForm[videoModelName].length) {
      param.imageUrls = imageURLs;
      param.videoUrls = videoURLs; */  
      param.imageData = (imagesModelName && forms.assessmentForm[imagesModelName] ) || [];
      param.videoData = (videoModelName && forms.assessmentForm[videoModelName]) || [];
      param.deletedImages = forms.assessmentForm.deletedImages || []; 
      param.deletedVideos = forms.assessmentForm.deletedVideos || []; 
      param.editId = editId || ''; 
      param.projectName = forms.assessmentForm.projectName || ''; 
      param.groupName = forms.assessmentForm.groupName || ''; 
      param.checklistTitle = forms.assessmentForm.checklistTitle || ''; 
      param.serialNo = forms.assessmentForm.serialNo || ''; 
      const response = await postCustom(`/SubmitCheckList`, param); 
      showAlert(editId?'Form updated successfuly':'Form added successfully', 'check-list-form', 'success',
        () => { history?.push('/home'); /* param.formType=='CloseCallCheckList'?history.push('/close-call-checklist-reports'):param.formType=='SupervisorCheckList'?history.push('/checklist-reports/safety-walkthrough'):history.push('/checklist-reports/other-checklists'); */ });
      return response;
    //}
  } catch (error) {
    throw error; 
  }
}

export async function getLocations(companyName: string) { 
  try { 
      const response = await getCustom(`/Locations/${companyName}`);  
      let placesArray: IGoogleMaps[] = [];
      let regionsArray: IMapRegion[] = []; 
      let regLength = 0;
      for (let item in response.data.data) { 
          regLength++;
          regionsArray.push({'loc':item});
          if( regLength===1 ) {
              for (let item1 in response.data.data[item]) {  
              placesArray.push({'lat': response.data.data[item][item1]['latitude'],'lng': response.data.data[item][item1]['longitude'],'name':item1}); 
              }
          }
      } 
     /*  this.setState({ allLocations:response.data.data,
          locations: placesArray, regions: regionsArray, regLength:regLength
      }); */
    return response.data.data;
  } catch (error) {
      throw error;
  }
}  
export async function formatChecklistQuestions(checklistId:string, editInstance:any = '' ) {
  let attributeArray: IAttributeDataState[] = [];
  await getAllAttribute(checklistId).then((responseList) => { 
    if (responseList) {
        //responseList.sort( dynamicSort('attribute')); 
        let attrList: any = []; 
        responseList.map((item: any, parentIndex) => {
            if(item['attribute'].toLowerCase() === 'general' ) {
                attrList.push(item);   
                delete responseList[parentIndex];  
            } 
        }); 
        attrList = attrList.concat(responseList); 
        attrList = attrList.filter(Boolean); 
        attrList.map((item: { attribute: any; name: { length: number; map: (arg0: (subattributeName: any, index: string) => void) => void; }; subAttributeList: { length: number; map: (arg0: (subattributeItem: { name: any; }, index: string) => void) => void; }; }, parentIndex: any) => { 
            let editIAttr: any = [];
            if(editInstance) { 
                editIAttr = editInstance.subAttributeList.filter((p: { attributeName: any; }) => p.attributeName === item.attribute)
            } 
            let subAttributeArray: ISubAttributeData[] = [];
            if (item.name && item.name.length > 0) {
                item.name.map((subattributeName: any, index: string) => {
                    let editISubAttr: any = [];
                    if(editIAttr.length) {
                        editISubAttr = editIAttr[0].answerList.filter((p: { key: any; }) => p.key === subattributeName)
                    } 
                    let subAttributeItem: ISubAttributeData = {
                        subAttributeId: index + '' + parentIndex,
                        isAttributeNA: (editISubAttr.length && editISubAttr[0].value==='NA' && true) || false,
                        isAttributeNo: (editISubAttr.length && editISubAttr[0].value==='No' && true) || false,
                        isAttributeYes: (editISubAttr.length && editISubAttr[0].value==='Yes' && true) || false,
                        name: subattributeName
                    };
                    subAttributeArray.push(subAttributeItem);
                });
            } else if (item.subAttributeList && item.subAttributeList.length > 0) {
                item.subAttributeList.map((subattributeItem: { name: any; }, index: string) => {
                    let subAttributeItem: ISubAttributeData = {
                        subAttributeId: index + '' + parentIndex,
                        isAttributeNA: false,
                        isAttributeNo: false,
                        isAttributeYes: false,
                        name: subattributeItem.name
                    };
                    subAttributeArray.push(subAttributeItem);
                });
            }
            let attrId: any =  (parseInt(parentIndex) + 1).toString()
            let attributeItem: IAttributeDataState = {
                attributeId: attrId,
                attribute: item.attribute,
                editAttribute: false,
                inputValue: '',
                isSubmitting: false,
                name: [],
                newAttributes: [],
                subAttributes: [],
                subAttributeList: subAttributeArray,
                formType: '',
                key: `${checklistId}-${item.attribute}`, id: `${checklistId}-${item.attribute}`, checklistId: checklistId
            };
            new ChecklistAttributeModel(attributeItem).$save();
            attributeArray.push(attributeItem);
        });
        //this.setState({ attributeList: attributeArray });
    }
  });
  return attributeArray;
}