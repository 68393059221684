import { InfoWindow, Map, Marker } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';

import { setSuccess } from '../../../actions/loadingActions';
import { hideMapInputModal } from '../../../actions/modalActions';
import { IGoogleMaps } from '../../../interfaces';
import { dispatch } from '../../../utils/generalUtils';
import { Async } from '../Async'; 
import { resetEmptyField } from '../../../actions/miscellaneousActions'; 
import mapIcon from '../../../images/map-icon.png'; 

export interface IMapInputModalProps {
    id?: string;
    modelName?: string;
    markers?: IGoogleMaps[];
    showModal?: Object;
    google?: any;
    centerLocation?: {
        centerLat: number;
        centerLong: number;
        zoom: number;
    };
}

export interface IMapInputState {
    selectedPlaceName: string;
    activeMarker: any;
    showInfoWindow: boolean;
    activeLatLong: any;
}

export class MapInputModalImpl extends React.PureComponent<IMapInputModalProps, IMapInputState> {
    constructor(props: IMapInputModalProps | Readonly<IMapInputModalProps>) {
        super(props);
        this.state = { selectedPlaceName: '', activeMarker: null, showInfoWindow: false, activeLatLong: null };
    }

    handleLocationSelect = (obj: { name: any; position: { lat: any; lng: any; }; }, marker: any) => {  
        // const { props: { modelName }, state: { activeMarker, selectedPlaceName, showInfoWindow } } = this; 
        const { props: { modelName } } = this;  
        if(!modelName){ 
            hideMapInputModal();
            return;
        }
        const fullModel = modelName.split('.');
        fullModel.pop();
        const locationInput = fullModel.join('.');
        this.setState({
            activeMarker: marker,
            selectedPlaceName: obj.name,
            showInfoWindow: true,
            activeLatLong: obj.position
        }); 
        if (obj.position) { 
            dispatch(actions.change(`${locationInput}.locationName`, obj.name)); 
            dispatch(actions.change(modelName, `${obj.position.lat},${obj.position.lng}`));
            /* hideMapInputModal();
            return; */
        }
        resetEmptyField();
        hideMapInputModal();
        return;
    }

    handleLocationConfirm = () => {
        const { props: { modelName }, state: { selectedPlaceName, activeLatLong } } = this; 
        if(!modelName){ 
            hideMapInputModal();
            return;
        }
        const fullModel = modelName.split('.');
        fullModel.pop();
        const locationInput = fullModel.join('.');
        if (activeLatLong) {
            dispatch(actions.change(`${locationInput}.locationName`, selectedPlaceName));
            dispatch(actions.change(modelName, `${activeLatLong.lat},${activeLatLong.lng}`));
            /* hideMapInputModal();
            return; */
        }
        hideMapInputModal();
        return;
    }

    handleOtherLocationSelect = () => {
        const { props: { modelName } } = this; 
        if(!modelName){ 
            hideMapInputModal();
            return;
        }
        const fullModel = modelName.split('.');
        fullModel.pop();
        const locationInput = fullModel.join('.');
        dispatch(actions.change(`${locationInput}.locationName`, 'Other'));
        dispatch(actions.change(modelName, `Other`)); 
        resetEmptyField();
        hideMapInputModal();
    }

    renderModal__ = (isLoading: boolean) => {
        const { centerLocation, markers } = this.props;
        if (!centerLocation || !markers) {
            return <div></div>;
        }  
        return <Modal
            show={this.props.showModal ? true : false}
            onHide={() => hideMapInputModal()}
        >
            <Modal.Header style={headerStyle} closeButton>
                <Modal.Title id="contained-modal-title">
                    Select Location
          </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '500px', padding: 0 }} >
                <Map
                    initialCenter={{
                        lat: centerLocation.centerLat,
                        lng: centerLocation.centerLong
                    }}
                    google={this.props.google} /* zoom={centerLocation.zoom} */>
                    {markers && markers.map((data, index) => {  
                        return <Marker
                            onMouseover={() => this.setState({ showInfoWindow: true })}
                            /* onMouseout={() => this.setState({ showInfoWindow: false })} */
                            key={index}
                            mapCenter={{lat: data.lat, lng: data.lng }}
                            /* position={{ lat: data.lat, lng: data.lng }} */
                            onClick={() => this.handleLocationSelect}
                            /* name={data.name} */ />;
                    })}
                    <InfoWindow
                        visible={this.state.showInfoWindow}
                        marker={this.state.activeMarker}
                        google={this.props.google}
                        map={this.props.google}
                        /* onClose={() => { }} */><div>{this.state.selectedPlaceName}</div></InfoWindow>
                </Map>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }} className="form-button">
                {/* <button style={confirmButton} onClick={this.handleLocationConfirm} >
                    Confirm Location
                </button> */}
                 
                 
                <button style={otherLocation} onClick={this.handleOtherLocationSelect}>
                    Location Not Listed
                </button> 
            </Modal.Footer>
        </Modal >;
    } 
    renderModal = (isLoading: boolean) => {
        const { centerLocation, markers } = this.props;
        if (!centerLocation || !markers) {
            return <div></div>;
        }  
        const AnyReactComponent = (data: any) => <div><span style={{color: 'black',}}>{data.name}</span><img src={mapIcon} className="" style={{maxWidth: '30px', cursor: 'pointer'}} onClick={() => this.handleLocationSelect({name: data.name, position: { lat: data.lat, lng: data.lng }}, '' ) }  alt="SafeConnect"/>
        </div>;
        return <Modal
            show={this.props.showModal ? true : false}
            onHide={() => hideMapInputModal()}
        >
            <Modal.Header style={headerStyle} closeButton>
                <Modal.Title id="contained-modal-title">
                    Select Location
          </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '90%', padding: 0 }} >
            <div style={{ height: '70vh', width: '100%' }}>
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAf2IZLhSyMcpxc6ryUlzACo5JwF9j5G34' }}
                    defaultCenter={{
                        lat: centerLocation.centerLat,
                        lng: centerLocation.centerLong
                    }}
                    defaultZoom={centerLocation.zoom}  
                    /* options={{ 
                        styles: [{ stylers: [{
                            "featureType": "all",
                            "elementType": "labels.text",
                            "stylers": [
                              {
                                "visibility": "off"
                              }
                            ]
                          },
                          {
                            "featureType": "poi",
                            "elementType": "labels.icon",
                            "stylers": [
                              {
                                "visibility": "off"
                              }
                            ]
                          },{
                            "elementType": "labels",
                            "stylers": [
                              {
                                "visibility": "off"
                              }
                             ]
                         },] }]
                      }
                    } */
                    options={{
                        styles: [
                            {
                              "featureType": "poi.business",
                              "stylers": [{ "visibility": "off" }],
                            },
                            {
                              "featureType": "transit",
                              "elementType": "labels.icon",
                              "stylers": [{ "visibility": "off" }],
                            },
                            {
                                "featureType": "all",
                                "elementType": "labels.text",
                                "stylers": [
                                  {
                                    "visibility": "off"
                                  }
                                ]
                              },
                              {
                                "featureType": "poi",
                                "elementType": "labels.icon",
                                "stylers": [
                                  {
                                    "visibility": "off"
                                  }
                                ]
                              }
                        
                        ]
                            
                    }}
                    > 
                    {
                        markers && markers.map((data: any) => { 
                            return(
                                <AnyReactComponent lat={data.lat}
                                lng={data.lng} name={ data.name}
                                />
                
                              )
                        })
                    }
                    </GoogleMapReact>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }} className="form-button">  
                <button style={otherLocation} onClick={this.handleOtherLocationSelect}>
                    Location Not Listed
                </button> 
            </Modal.Footer>
        </Modal >;
    }

    render() {
        return (
            <Async
                identifier="GoogleMapsInput"
                error={<div></div>}
                promise={() => setSuccess('GoogleMapsInput')}
                loader={this.renderModal(true)}
                content={this.renderModal(false)}
            />
        );
    }
}

const headerStyle: React.CSSProperties = {
    backgroundColor: '#88A4CE',
    color: 'white',
    border: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
};

const otherLocation: React.CSSProperties = {
    border: '1px solid #26a65b',
    height: '30px',
    color: '#26a65b',
    background: 'white',
    borderRadius: '5px',
    textAlign: 'center',
};

/* const confirmButton: React.CSSProperties = {
    border: 'none',
    height: '30px',
    color: 'white',
    background: '#26a65b',
    borderRadius: '5px',
}; */

export function mapStateToProps(state: any) { 
    if (!state.modal.get('mapInput')) {
        return {};
    }
    return {
        modalID: state.modal.get('mapInput').id,
        modelName: state.modal.get('mapInput').fullModelName,
        centerLocation: state.modal.get('mapInput').center,
        markers: state.modal.get('mapInput').markers,
        showModal: state.modal.get('mapInput')
    };
}

export const MapInputModal = /* GoogleApiWrapper({
    apiKey: 'AIzaSyAf2IZLhSyMcpxc6ryUlzACo5JwF9j5G34',
    version: '3.31'
}) */(connect< IMapInputModalProps, any, any>(mapStateToProps)(MapInputModalImpl));
