import { format } from 'date-fns'; 
import { saveRiskAssessmentSummary } from '../actions/summaryActions';
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { IAnswer, IForms, IHistory } from '../interfaces'; 
import { RiskAssessmentModel } from '../model/RiskAssessmentModel';
import { UserModel } from '../model/UserModel';
import { Answers } from '../utils/Answers';
import { showAlert, convertToDateFormat} from '../utils/generalUtils';
import { getCustom, post } from '../utils/HTTP';  
import { saveListCount } from '../actions/miscellaneousActions';
 
export async function getAllRiskAssessmentForms(urlData?: any, forms?:IForms, assessmentType: string = 'primary') { 
    try {
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        const data = { ...forms?.commonFilterForm, formType: 'riskAssessmentForm', pageNumber, sortIndexField, sortingOrder, filterParam1: assessmentType, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        if (response.data.status === 'valid') {
            RiskAssessmentModel.deleteAll();
            response
                .data
                .data
                .forEach((FormData: any) => {
                    const FormInstance: any = marshallFormInstance(FormData); 
                    new RiskAssessmentModel(FormInstance).$save();
                });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export function marshallFormInstance(data: { id: any; key: any; totalClosedFollowUp: number; totalFollowUp: number; totalOpenFollowUp: number; answers: any; date: number | Date; time: any; userId: any; }) {
    data.id = data.key; 
    data.totalClosedFollowUp = data.totalFollowUp - data.totalOpenFollowUp; 
    const answerData = new Answers(data.answers); 
    let obj: any = {
        dateReported: format(new Date(data.date), 'MM/dd/yyyy'),
        timeReported: data.time,
        dateOfIncident: convertToDateFormat(answerData.get(1) ),
        timeOfIncident: answerData.get(2),
        location: answerData.get(3),
        locationName: answerData.get(4), 
        typeOfWork: answerData.get(5), 
        workToPerform: answerData.get(6), 
        SSRI: answerData.get(7), 
        eventSteps: answerData.get(8), 
        averageEventCategory: answerData.get(9), 
        averageProbabilityCategory: answerData.get(10),   
    };
    let eventDescription: any = [];
    let eventCategory: any = [];
    let probabilityCategory: any = [];
    let eventIndex: number = 11;
    for (let index = 0; index < answerData.get(8); index++) {
        eventDescription.push(answerData.get(eventIndex++))
        eventCategory.push(answerData.get(eventIndex++))
        probabilityCategory.push(answerData.get(eventIndex++))
    } 
    obj.eventDescription = eventDescription;
    obj.eventCategory = eventCategory;
    obj.probabilityCategory = probabilityCategory; 

    obj.reassessmentOption = answerData.get(eventIndex); 
    obj.createdById = answerData.get(++eventIndex);  
    obj.isApproved = answerData.get(++eventIndex)==='0'?false:true; 
    obj.approvedById = answerData.get(++eventIndex);  
    obj.isAttested = answerData.get(++eventIndex)==='0'?false:true; 
    obj.attestedById = answerData.get(++eventIndex);  
    obj.finalAuthorityComments = answerData.get(++eventIndex);  
    obj.finalResult = answerData.get(++eventIndex);  

    Object.assign(data, obj);
    delete data.answers;
    return data; 

} 
export async function getRiskAssessmentForm(uuid: any) { 
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`/GetForms/riskAssessmentForm/${company}/${uuid}`); 
        if (response.data.status === 'valid' && response.data.data !== 'No Data Found') {  
            RiskAssessmentModel.deleteAll();
            response.data.data.forEach(async (FormData: any) => {
                const FormInstance: any = marshallFormInstance(FormData); 
                new RiskAssessmentModel(FormInstance).$save();
            });  
        }
        return response;
    } catch (error) {
        throw error;
    }
}
export async function submitRiskAssessmentForm(formData: any, answers: IAnswer[], history: IHistory, forms: IForms, editId?: string) { 
    const submittedValues: any = {
        groupName: formData.groupName,
        userId: formData.userUID,
        formId: 'riskAssessmentForm',
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        answers,
        projectName: formData.projectName,
        associatedFormId: formData.associatedFormId || '',
        followUp: '0', 
        deletedImages: [],
        deletedVideos: [],
        editId: '',
        followUpTempUUID: formData.followUpTempUUID,
        mailTo: formData.mailTo,
        dateOfAssessment: formData.dateOfAssessment,
        workToPerform: formData.workToPerform,
        finalSSRIResult: formData.finalSSRIResult,
        deletedFiles: forms.riskAssessmentForm.deletedFiles || [], 
        attachments: forms.riskAssessmentForm.attachements || []
    };  
    try { 
        submittedValues.editId = editId;  
        const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'risk-assessment-form-failed', 'danger');
            return;
        } 
        showAlert(response.data.message, 'risk-assessment-modal', 'success', async () => {
            /* formData.associatedFormId? history.push(`/risk-assessment/edit/${formData.associatedFormId}`): */ history.push(`/home`);  
        });
        return response; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'risk-assessment-form-failed', 'danger');
        throw error;
    } 
} 
//export async function getRiskAssessmentData(freq: string = 'summary', year: string = 'na', month: string = 'na') {
export async function getRiskAssessmentData(forms?: IForms) { 
    try {
        const defaultYear = new Date().getFullYear().toString();  
        const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || defaultYear, month: forms?.commonFilterForm.month || '', }; 
        const response = await post('/GetRiskAssessmentSummary', data); 
        //const response = await getCustom(`/GetRiskAssessmentSummary/${company}/${freq}/${year}/${month}`);  
        saveRiskAssessmentSummary(response.data.data);
    } catch (error) {
        throw error;
    }
} 
