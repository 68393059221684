import * as React from 'react';
import { Col, DropdownButton, Container as Grid, Dropdown, Row, Modal, Table} from 'react-bootstrap'; 
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IHistory, IForms } from '../../interfaces'; 
import { Button } from '../reusableComponents/FormComponents/Button'; 
import { Label } from '../reusableComponents/FormComponents/Label'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';  
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { EMPTY_FORM_MESSAGE } from '../../constants/general'; 
import { isFormEmpty, showAlert, dispatch, constructAnswers, getChecklistReferenceDocument } from '../../utils/generalUtils';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Form } from '../reusableComponents/FormComponents/Form';  
import '../OIForm/oiForm.scss';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { format } from 'date-fns';
import { UserModel } from '../../model/UserModel'; 
import { Async } from '../reusableComponents/Async'; 
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';  
import { getAllAttribute, getAllCheckListSerialDetails } from '../../services/attributeService';
import { ChecklistNameModel } from '../../model/ChecklistModel';
import { getAllCheckListNames } from '../../services/checklistService'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { editAttribute, navigateAddAttribute } from '../../actions/attributeAction';
import { showDelConfirmModal, showAuditEffectiveInputModal, hideAuditEffectiveInputModal } from '../../actions/modalActions';
import { actions } from 'react-redux-form';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { submitAuditForm, deleteChecklistAttr } from '../../services/auditService';
import { IAttributeDataState } from '../Micellaneous/SafetyWalkAssesment/AddAttribute';
import { AuditModel } from '../../model/AuditModel';
import { GetAllJSAForms } from '../../services/jsaFormService';
import { JSAModel } from '../../model/JSAModel';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';
const MenuItem = Dropdown.Item;

export interface IAuditFormProps {
    history?: IHistory;
    attributeList: IAttributeDataState[];
    firstName: string;
    company?: string;
    forms: IForms;
    userId: string;
    editId: string;
    editInstance: any;
    userInstance: UserModel;
    checklistId: string;
    checklistNameInstance: ChecklistNameModel;
    auditInputModal?: Object; 
    auditInputModelName?: string;
    checklistInstances: any;
}
 
export interface IAuditFormState {
    userUID: string;
    userId: string;
    managerName: string;
    id: string;
    currentDate: string;
    attributeList: IAttributeDataState[];
    hasError: boolean;
    submitDate: Date;
    isSubmit: boolean;

    auditAttributeList: any; 
    totalScore: number; 
    overallPercent: number;
    isJsaNoVerified: boolean;
    verifiedJsaNo: string;
    jsaData: any; 
    verificationNoDropdown: any;
    verifiedReferenceData: any;
}

export class AuditFormImpl extends React.Component<IAuditFormProps, IAuditFormState> {
    constructor(props: IAuditFormProps | Readonly<IAuditFormProps>) {
        super(props); 
        let date = new Date();
        let currentDate = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
        this.state = { userUID: '', userId: '', managerName: '', id: '', currentDate: currentDate,
            attributeList: [], totalScore: (props.editInstance && props.editInstance.totalScore) || 0, submitDate: new Date(), hasError: false, isSubmit: false, auditAttributeList: [],overallPercent: 0, isJsaNoVerified: false, verifiedJsaNo: '', jsaData: '', verificationNoDropdown: [], verifiedReferenceData: '',
        };  
    }
    getChildContext() {
        return { formModel: 'forms.auditForm' };
    } 
    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    promise = async () => { 
        const { editInstance } = this.props;
        await getAllCheckListNames('auditChecklistForm');  
        await this.renderEditInstance();
        if(editInstance) {  
            dispatch(actions.change('forms.auditForm.referenceNo', this.props.editInstance.props.referenceNo));
            await this.changeVerificationNo(editInstance.props.referenceNo || '');
            await this.processScore(); 
        }
        return;
    }  
    async componentDidMount() { 
        this.getCheckListData(); 
    } 
    renderEditInstance = async () => { 
        await this.formVerificationDropdown();
        this.setState({overallPercent: 0, totalScore: 0 });
        return true;
    }
    formVerificationDropdown = async () => {
        const { checklistNameInstance } = this.props;
        if(checklistNameInstance) { 
            let referenceForm = checklistNameInstance.props.referenceForm;
            if(referenceForm==='JSA') { 
                await GetAllJSAForms();
                this.state.verificationNoDropdown.push({'label':  'External JSA' , 'value': 'Other JSA', 'title': 'External JSA', 'serialNo': 'External Checklist',  }); 
                if(JSAModel.list().length>0) {
                    (JSAModel.list() ).forEach(instance => {
                        this.state.verificationNoDropdown.push({'label': instance.props.jsaNum + ' ('+instance.props.title+')' , 'serialNo': instance.props.jsaNum, 'value': instance.props.id, 'title': instance.props.title, formType: 'JSA'  }); 
                    });
                } 
            } else if(referenceForm!=='OI' && referenceForm!=='Office OI' ) { //For get all checklist serial nos
                let params = {checklistType: checklistNameInstance.props.id}
                await getAllCheckListSerialDetails(params); 
                this.state.verificationNoDropdown.push({'label':  'External Checklist' , 'value': 'External~Data', 'title': 'External Checklist', 'serialNo': 'External Checklist',  }); 
                if(this.props.checklistInstances.length>0) {
                    (this.props.checklistInstances ).forEach(instance => {
                        if(instance.serialNo)
                            this.state.verificationNoDropdown.push({'label': instance.serialNo + ' ('+instance.checklistTitle+')' , 'value': instance.id, 'title': instance.checklistTitle, 'serialNo': instance.serialNo, formType: 'checklist'  }); 
                    });
                } 
            }  
        }
    } 
    processScore = () => { 
        const { editInstance, forms } = this.props;
        let effects = (forms.auditForm.effect.length>0 && forms.auditForm.effect) || ( editInstance && editInstance.props.effect);  
        let totalEffect = 0; let overallPercent = 0;
        for(let i = 0; i<effects.length;i++) { 
            let effect = parseInt(effects[i]) || 0;  
            totalEffect += effect; 
        }
        overallPercent = (100 * totalEffect)/(this.state.auditAttributeList.length * 3); 
        this.setState({totalScore: totalEffect, overallPercent: Math.round(overallPercent) })
    }
    changeVerificationNo = (value: string) => {
        const ref = this.state.verificationNoDropdown.find(item => item.value === value);   
        if(ref) { 
            this.setState({verifiedReferenceData: ref});
        }  
        return;
    }
    getCheckListData() {
        const { checklistId } = this.props; 
        let auditAttributeList: any = [];
        getAllAttribute(checklistId).then((responseList) => {  
            if (responseList) { 
                responseList.sort(this.dynamicSort('attribute'));  
                responseList.forEach((item:any) => {
                    if (item.name && item.name.length > 0) {
                        item.name.forEach((subattributeName: any, index: string) => {
                            auditAttributeList.push(subattributeName)
                        });
                    }
                });
            }
            this.setState({auditAttributeList: auditAttributeList})
        });
    }
    dynamicSort(property: string) {
        let sortOrder = 1; 
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        } 
        return function (a: any, b: any) { 
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        };
    }
    handleFormSubmit = ({ dateOfIncident, projectName, groupName, jsano, effect, comments, overallComments, referenceNo }: any) => {
        const { totalScore, overallPercent } = this.state;
        const { firstName, editId, forms, history, checklistId} = this.props; 
        const submittedValues = {dateOfIncident, projectName, groupName, referenceNo};
        if (isFormEmpty(submittedValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'checklist-form-failed', 'danger');
            return;
        }
        let time = new Date().toLocaleTimeString();
        let userUID = UserModel.getUserUID();
        let formData: any = { dateOfIncident, timeOfIncident: time, projectName, groupName, userUID, checklistId };
        let auditAttributeList = this.state.auditAttributeList;
        let answerData: any = { reportDate: dateOfIncident, reportTime: time, createdUserName: firstName, serialNo: this.state.verifiedReferenceData.serialNo, referenceNo: this.state.verifiedReferenceData.value, overallComments: overallComments, }; 
        let answers = constructAnswers(answerData); 
        if(editId) {
            formData.editId = editId;
        }
        answers.push({
            'quesType': 'totalScore',
            'quesNum': answers.length + 1,
            'answer': totalScore.toString(),
        });
        answers.push({
            'quesType': 'overallPercent',
            'quesNum': answers.length + 1,
            'answer': overallPercent.toString(),
        });
        answers.push({
            'quesType': 'totalChecklist',
            'quesNum': answers.length + 1,
            'answer': auditAttributeList.length,
        }); 
        auditAttributeList.forEach((item: any, index: any) => { 
            answers.push({
                'quesType': 'effect~'+item,
                'quesNum': answers.length + 1,
                'answer': effect[index] || '',
            }); 
            answers.push({
                'quesType': 'comments',
                'quesNum': answers.length + 1,
                'answer': comments[index] || '',
            }); 
        }); 
        return history && submitAuditForm(formData, answers, history, forms, editId);  
    }
    onAddNewAttribute = () => {  
        dispatch(actions.change('forms.attributeFrom.mainAttribute', this.props.checklistId));
        this.props.history?.push(`/audit-checklist-attribute/${this.props.checklistId}`);  
    } 
    onDeleteAttribute(index: number, attributeItem: IAttributeDataState) { 
        let attributeData = { index, attributeItem}
        showDelConfirmModal('delete-confirmation', 'Checklist Question', attributeData);  
    } 
    onConfirmDeleteAttribute = async (attributeData: any) => {
        /* 3600 62352 */ 
        let index = attributeData.index;  
        let newAttributeArray = this.state.auditAttributeList; 
        let param = {company: UserModel.getCompanyName(), formType: this.props.checklistId, attribute: newAttributeArray[index] };
        this.setState({isSubmit: true});
        await deleteChecklistAttr(param); 
        let changeIndex = parseInt(index) ; 

        dispatch(actions.remove(`forms.auditForm.effect`, changeIndex));
        dispatch(actions.remove(`forms.auditForm.comments`, changeIndex)); 
        newAttributeArray.splice(index, 1); 
        await this.setState({ auditAttributeList: newAttributeArray  });  
        this.setState({isSubmit: false});
        showAlert('Checklist updated successfully', 'checklist-form-modal', 'success' ); 
        this.processScore();
    } 
    onEditAttribute = (item: any, index: number) => { 
        let attributeParam: any = { attribute: this.props.checklistId, attributeId: index, editAttribute: false, formType: this.props.checklistId, inputValue:'',isSubmitting: false, subAttributeList: [{name: item}], req_from: 'audit', auditAttributeList: this.state.auditAttributeList  }; 
        dispatch(navigateAddAttribute(this.props.checklistId)); 
        dispatch(editAttribute(true, attributeParam));
        this.props.history?.push(`/audit-checklist-attribute/${this.props.checklistId}`);
    }
    renderChecklist = () => {
        const { auditAttributeList } = this.state;
        const { editInstance, forms } = this.props; 
        return auditAttributeList.map((item: any, index: any) => { 
            return <div key={`attributeRowParent${index}`}>
                <Row key={`subAttributeRow${index}`}
                    className="customized-grid row-cell-height border-bottom 
                    border-left border-right margin-left-right-0 padding-bottom-0">
                        <Col className="row-cell-height border-right" sm={1} xs={12}> 
                            { ( UserModel.checkUserAccess('safety_walkthrough_question_add') || UserModel.checkUserAccess('safety_walkthrough_question_delete') ) && 
                                <DropdownButton title="" id="bg-vertical-dropdown-3">
                                    { UserModel.checkUserAccess('safety_walkthrough_question_delete') && <MenuItem eventKey="2"
                                        onClick={() => this.onEditAttribute(item, index+1)}
                                        className="item">&nbsp;Edit</MenuItem> }
                                    { UserModel.checkUserAccess('safety_walkthrough_question_add') && <MenuItem eventKey="1"
                                        onClick={() => this.onDeleteAttribute(index, item)} 
                                        className="item">&nbsp;Delete</MenuItem> } 
                                </DropdownButton> 
                            }
                            <div className="sequence-number-position">
                                {`${index+1}.`}
                            </div>
                        </Col>
                        <Col className="row-cell-height border-right auditQuestion" sm={6} xs={12} >
                            <Label sm={12} style={{color: 'white' }}>{item}</Label>
                        </Col>
                        <Col className="row-cell-height border-right color-white" sm={2} xs={12} onClick={() => this.selectEffective(`effect[${index}]`)}>
                            <div className="width-height-100-textcenter" >
                                <RRFInput
                                    id="effect"
                                    className="effect-input"
                                    placeholder="Enter effectiveness"
                                    type="text" 
                                    model={`.effect[${index}]`} 
                                    readOnly={true}
                                    defaultValue={ (editInstance && editInstance.props.effect[index]) }
                                /> 
                            </div>
                        </Col> 
                        <Col className="row-cell-height " sm={3} xs={12}>
                            <div className="width-height-100-textcenter">
                                <VoiceRecognition labelText="" defaultValue={forms.auditForm.comments[index]} model={`forms.auditForm.comments[${index}]`} />  
                                <RRFInput
                                    id="comments"
                                    type="textarea"
                                    placeholder="Enter comments"
                                    model={`.comments[${index}]`}
                                    textAreaRows="3"
                                    defaultValue={ (editInstance && editInstance.props.comments[index]) }
                                />
                            </div>
                        </Col>
                    </Row>  
                </div> 
        })
    }
    renderContent() { 
        const { firstName, editInstance, checklistNameInstance, forms } = this.props; 
        if(!checklistNameInstance) return <div></div>;
        let referenceForm = checklistNameInstance.props.referenceForm; 
        return (
            <div className="project-list-wrapper">
                {this.renderAuditInputModal(false)}
                <Grid style={{ width: '100%' }} className="safety-walkthrough-assesment">
                    <Title titleColor="yellow" text={`${checklistNameInstance ? checklistNameInstance.props.checklistName:'Checklist'}`} noNeedBorder={true}/>
                    <Alert className="danger-alert" id="check-list-form-alert" />
                    <fieldset> 
                    {this.state.isSubmit && <SubmissionLoader />}
                    <Form loader={<SubmissionLoader />} model="forms.auditForm" onSubmit={this.handleFormSubmit}>
                       <Row className="show-grid margin-left-right-0"> 
                            <Col sm={4} xs={12} className="input">
                                <Label sm={12} htmlFor="currentDate">User:</Label>
                                <RRFInput
                                    id="user"
                                    type="text" 
                                    model=".user" 
                                    defaultValue={firstName}
                                    isDisabled={true}
                                />
                                {/* <Col sm={12}  >{firstName}</Col> */}
                            </Col>
                            <Col sm={4} xs={12} className="input">
                                <Label required sm={12} htmlFor="currentDate">Date:</Label>
                                <RRFInput
                                    id="currentDate"
                                    type="date"
                                    defaultValue={editInstance?format(new Date(editInstance.props.dateOfIncident), 'yyyy-MM-dd'): format(this.state.submitDate, 'yyyy-MM-dd')}
                                    model=".dateOfIncident"
                                    maxdate={format(new Date(), 'yyyy-MM-dd')}  
                                /> 
                            </Col>
                            <Col sm={4} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={ editInstance && editInstance.props.projectName } />
                            </Col>
                            <Col sm={4} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={ editInstance && editInstance.props.groupName} />
                            </Col>
                            {referenceForm!=='OI' && referenceForm!=='Office OI' &&
                                <Col sm={4} xs={12}>
                                    <Label required sm={12} >Reference No:</Label>
                                    <Col sm={12} className="input">
                                        <RRFInput
                                            id="referenceNo" 
                                            model=".referenceNo"
                                            type="dropdown" 
                                            searchable={true}
                                            menuItems={this.state.verificationNoDropdown } 
                                            onSelect={this.changeVerificationNo}
                                            defaultValue={editInstance && editInstance.props.referenceNo}
                                        /> 
                                    </Col>
                                </Col>
                            }  
                            <Alert className="danger-alert" id="jsa-check-form-failed" />
                        </Row>
                        <Row>
                        {this.state.jsaData && <Col sm={12} style={{textAlign: 'center', color: 'white', fontSize: '20px', marginBottom:'20px'}}> <span style={{color: 'yellow',}}> Job desc </span>: {this.state.jsaData.answers[6]['answer']} </Col>}
                        </Row>
                        <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0">
                            <Col className="border-bottom border-right" sm={1} xs={12}>
                                <Label sm={12}>No.</Label>
                            </Col>
                            <Col className="border-bottom" sm={6} xs={12}>
                                <Label sm={12}>{`${checklistNameInstance ? checklistNameInstance.props.checklistName:'Checklist'}`} Quality Elements</Label>
                            </Col>
                            <Col className="border-bottom border-left" sm={2} xs={12}>
                                <Label sm={12}>Effectiveness</Label>
                            </Col> 
                            <Col className="border-bottom border-left" sm={3} xs={12}>
                                <Label sm={12}>Comments</Label>
                            </Col> 
                        </Row> 
                        {this.renderChecklist()} 
                        <Row key={`totalScore`}
                        className="customized-grid row-cell-height border-bottom 
                        border-left border-right margin-left-right-0 padding-bottom-0">
                            <Col className="row-cell-height " sm={1} xs={12}> 
                            </Col>
                            <Col className="row-cell-height border-right" sm={6} xs={12} >
                                <Label sm={12} style={{ }}> Total Score</Label>
                            </Col>
                            <Col className="row-cell-height border-__ color-white" sm={2} xs={12}>
                                <div className="width-height-100-textcenter"  >
                                   <label className="totalScore">{this.state.totalScore}</label>
                                </div>
                            </Col> 
                        </Row> 
                        <Row key={`overallPercent`}
                        className="customized-grid row-cell-height border-bottom 
                        border-left border-right margin-left-right-0 padding-bottom-0">
                            <Col className="row-cell-height " sm={1} xs={12}> 
                            </Col>
                            <Col className="row-cell-height border-right" sm={6} xs={12} >
                                <Label sm={12} style={{ }}> Overall %</Label>
                            </Col>
                            <Col className="row-cell-height border-right__ color-white" sm={2} xs={12}>
                                <div className="width-height-100-textcenter"  >
                                   <label className="scorePersent">{this.state.overallPercent}%</label>
                                </div>
                            </Col> 
                        </Row> 

                        <Row className="show-grid">
                            <Col className="row-cell-height " sm={12} xs={12}>
                                <Label required sm={12} htmlFor="overallComments">Overall Comments:</Label>
                                <div className="width-height-100-textcenter">
                                    <VoiceRecognition labelText="" defaultValue={forms.auditForm.overallComments} model="forms.auditForm.overallComments" />  
                                    <RRFInput
                                        id="overallComments"
                                        type="textarea"
                                        placeholder="Enter comments"
                                        model={`.overallComments`}
                                        textAreaRows="3"
                                        defaultValue={editInstance && editInstance.props.overallComments}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Attachments:</Label>
                                <Col sm={12} className="input">
                                    { this.renderReferenceDocument()} 
                                    <AttachementInput
                                        model=".attachements"
                                        multiple={true}
                                        id="upload-images" 
                                        uploadedImages={editInstance && editInstance.props.attachmentUrls}
                                    />
                                </Col>
                            </Col> 
                        </Row>
                        <Row className="row-addrow-section">
                            <Alert className="danger-alert" id="checklist-form-failed" />
                            <Col>
                                <div className="table-buttons form-button"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '0'
                                    }}>
                                    <Button className="closeButton" redirectTo="goBack" type="button" style={{width: '20%', height:' 34px'}} onClick={() => this.props.history?.goBack() }>
                                        <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                                    Cancel </Button>   
                                    { !editInstance ? <button
                                        className="add-row"
                                        type="button"
                                        onClick={this.onAddNewAttribute}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        &nbsp;Add Attribute
                                    </button> : null }
                                    
                                    <Button type="submit" style={{
                                        color: '#FFFFFF',
                                        border: 'none',
                                        backgroundColor: '#26A65B',
                                        fontSize: '18px',
                                        height: '34px',
                                        borderRadius: '17px',
                                        outline: 'none',
                                        width: '190px',
                                        marginRight: '5px'
                                        }}
                                        >  <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                                        &nbsp;
                                        { editInstance ? 'Update' : 'Submit' }
                                    </Button> 
                                </div>
                            </Col>
                        </Row>
                       </Form>
                    </fieldset>
                </Grid>
            </div>
        );
    }
    renderReferenceDocument() {
        const { forms, checklistNameInstance } = this.props; 
        let formType = this.state.verifiedReferenceData.formType;
        if(forms.auditForm.referenceNo && this.state.verifiedReferenceData && formType ) {
            let docLink = ''; 
            switch(formType) {
                case 'JSA':
                    docLink = `${this.state.verifiedReferenceData.value}`; break;
                case 'checklist':
                    docLink = `${this.state.verifiedReferenceData.value}/${checklistNameInstance.props.checklistName}`; break;
            }
            return getChecklistReferenceDocument(formType, this.state.verifiedReferenceData.title, `${docLink}`);
        }
        return <></>
    } 
    selectEffective = (model: string) => {
        showAuditEffectiveInputModal( `forms.auditForm.${model}`);
    }
    renderAuditInputModal = (isLoading: boolean) => {
        const { auditInputModelName } = this.props;
        if (!auditInputModelName ) {
            return <div></div>;
        } 
        return <Modal
            show={auditInputModelName ? true : false}
            onHide={() => hideAuditEffectiveInputModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title id="modal-title">
                    Select Effectiveness
          </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Table responsive bordered hover size="sm" className="effectiveness-score-categorization">
                    <thead>
                        <tr style={{textAlign:'center'}}>
                            <th>Score</th>
                            <th>Effectiveness</th> 
                            <th>Action</th> 
                        </tr>
                    </thead>
                    <tbody className="table-buttons form-button">
                        <tr> <td style={{textAlign:'center'}}>3</td> <td>Meets expectations</td><td> <Button className="closeButton" type="button" style={{width: '65%', height:'25px', fontSize:'16px'}} onClick={() => this.processEffectiveSelections(3) }> Select </Button> </td> </tr>
                        <tr> <td style={{textAlign:'center'}}>2</td> <td>Partially meets expectations and should be improved</td><td><Button className="closeButton" type="button" style={{width: '65%', height:'25px', fontSize:'16px'}} onClick={() => this.processEffectiveSelections(2) }> Select </Button></td> </tr>
                        <tr> <td style={{textAlign:'center'}}>1</td> <td>Does not meet expectations</td><td><Button className="closeButton" type="button" style={{width: '65%', height:'25px', fontSize:'16px'}} onClick={() => this.processEffectiveSelections(1) }> Select </Button></td> </tr>
                    </tbody>
                </Table> 
            </Modal.Body>
            <Modal.Footer style={{ textAlign: 'center' }} className="form-button">  
            </Modal.Footer>
        </Modal >;
    } 
    processEffectiveSelections = async (effect: number) => {
        const { auditInputModelName } = this.props; 
        if(effect) {
            await dispatch(actions.change(`${auditInputModelName}`, effect));
            this.processScore();
        }
        hideAuditEffectiveInputModal();
    }

    render() { 
        return (
            <BasePage className="safeconnect-form" >
                <AlertModal id="checklist-form-modal" /> 
                <DeleteConfirmationModal id="delete-confirmation" onConfirm={this.onConfirmDeleteAttribute}/>
                <Async
                    identifier="CheklistForm"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
} 
 
export function mapStateToProps(state: any, ownProps: any) { 
    const { login } = state;
    const checklistId = ownProps.match.params.checklistId;
    const checklistNameInstance = ChecklistNameModel.get(checklistId); 
    const editId = ownProps.match.params.editId; 
    let firstName = login.get('firstName');
    let userId = login.get('userUID'); 
    const editInstance = editId && AuditModel.get(editId); 
    const userInstance = UserModel.get(userId);  
    return {
        firstName, userId, editInstance, userInstance, checklistId, checklistNameInstance, forms: state.forms, editId,  attributeList: state.attribute.get('attributeList'), auditInputModal: state.modal.get('auditInput'), auditInputModelName: state.modal.get('auditInput') ? state.modal.get('auditInput').fullModelName:'', checklistInstances: state.report.get('reportDataList'), 
    };
}
export const AuditForm = withRouter(connect<IAuditFormProps, any, any>(mapStateToProps)(AuditFormImpl)); 
 