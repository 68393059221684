import { BaseModel } from './BaseModel'; 
import { IAttributeDataState } from '../components/Micellaneous/SafetyWalkAssesment/AddAttribute';

export interface IChecklistNameProps {
    id: string;
    checklistName?: string; 
    referenceForm?: string;
}
export interface ChecklistSummaryDataProps {
    reportCountData?: any; 
    checklistNames?: any; 
}

export class ChecklistNameModel extends BaseModel<IChecklistNameProps> {
    constructor(props: IChecklistNameProps) {
        super(props, ChecklistNameModel.resource);
    }
    static resource = 'checklistName-form';
}

export class ChecklistAttributeModel extends BaseModel<IAttributeDataState> {
    constructor(props: IAttributeDataState) {
        super(props, ChecklistAttributeModel.resource);
    }
    static resource = 'checklist-attribute-form';
}
