import * as React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'; 
import { IIncidentNotificationModelProps, IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { connect } from 'react-redux';
import { IHistory } from '../../interfaces'; 
import { showDelConfirmModal } from '../../actions/modalActions';
import { UserModel } from '../../model/UserModel';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { checkInvestigationEditAccess } from '../../services/incidentReportService';
import { getPageItemNumber } from '../../utils/generalUtils'; 
const MenuItem = Dropdown.Item;
export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: IncidentNotificationModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderFormList = () => {
        const { instance, index, style } = this.props;
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div> 
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}
            >
                <Col sm={1}>
                    { ( UserModel.checkUserAccess('incident_notification_view') || UserModel.checkUserAccess('incident_notification_edit') || UserModel.checkUserAccess('incident_notification_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                        { this.investigationProcess() } 
                        { UserModel.checkUserAccess('incident_notification_view') && <MenuItem eventKey="1"
                            onClick={() => this.props.history?.push(`/incident-reports/${instance.props.id}`)}
                            className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                        { UserModel.checkUserAccess('incident_notification_edit') && <MenuItem eventKey="2"
                            onClick={() => this.onEditAttribute(instance.props)}
                            className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                        { UserModel.checkUserAccess('incident_notification_delete') &&     <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute()}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                    </DropdownButton>}
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
                </Col> 
                <Cell sm={2} onClick={() => this.onViewAttribute(instance.props.id) }>{instance.props.date || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance.props.id) }>{instance.props.dateOfIncident || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance.props.id) }>{instance.props.concernType || 'NA'}</Cell>
                <Cell sm={3} onClick={() => this.onViewAttribute(instance.props.id) }>{instance.props.injuryClassification || 'NA'}</Cell>
                <Cell sm={2} onClick={() => this.onViewAttribute(instance.props.id) }>{instance.props.injuryBodyPart || 'NA'}</Cell>
            </Row >
        );
    }
    investigationProcess() {
        const { instance } = this.props;
        if(!instance || !instance.props.associatedFormsCompleted)
            return;
        if(instance.props.associatedFormsCompleted.length<1) {

            return (UserModel.checkUserAccess('incident_investigative_add') && <MenuItem eventKey="1"
            onClick={() =>  this.props.history?.push(`/incident-investigative-reports/create/${instance.props.id}`)}
            className="item"><i className="fa fa-plus-square-o" aria-hidden="true"></i> Add Investigative Form 
            </MenuItem>);
        } else {
            
            let investigativeInstance = IncidentReportModel.get(instance.props.associatedFormsCompleted[0]); 
            return ( UserModel.checkUserAccess('incident_investigative_edit') && (investigativeInstance && ( investigativeInstance.props.finalReport===0 || (investigativeInstance.props.finalReport && checkInvestigationEditAccess(investigativeInstance.props.userAccessLevel)) ) ) && <MenuItem eventKey="1"
            onClick={() => instance.props.associatedFormsCompleted && this.props.history?.push(`/incident-investigative-reports/edit/${instance.props.associatedFormsCompleted[0]}`)}
            className="item"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Investigative Form 
            </MenuItem>);
        }

    }
    onDeleteAttribute() {
        const { instance } = this.props;  
        instance && instance.props && showDelConfirmModal('delete-confirmation', 'Incident Notification Form', instance.props); 
    } 
    onEditAttribute(instance: IIncidentNotificationModelProps & { id?: string | undefined; }) { 
        this.props.history?.push(`/incident-reports/edit/${instance.key}`); 
    }
    onViewAttribute(id: any) { 
        if(!UserModel.checkUserAccess('incident_notification_view') )
            return;
            this.props.history?.push(`/incident-reports/${id}`)
    }
    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
         
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
