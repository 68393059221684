import { BaseModel } from './BaseModel';

export interface IGroupModelProps {
    id?: string;
    dateCreated?: string;
    groupCreator?: string;
    creatorName?: string;
    dateUpdated?: string;
    groupId?: string;
    groupName?: string;
    groupDesc?: string;
    groupMembers?: string;
    groupForms?: string;
    groups?: string;
    projectName?: string;
    groupImages?: string[];
    imageUrls?: string[];
    members?: string[];
}
export class GroupModel extends BaseModel<IGroupModelProps> {
    constructor(props: IGroupModelProps) {
        super(props, GroupModel.resource);
    }
    static resource = 'group';

    static getIdByName(groupName: string) {
        const groupList = GroupModel.list();
        let groupId = '';
        if (groupList.length <= 0) {
            return;
        }
        groupList.forEach((groupInstance: { props: { groupName: string; groupId: string; }; }) => {
            if (groupInstance.props.groupName === groupName) {
                groupId = groupInstance.props.groupId;
            }
        });
        return groupId;
    }
}
