import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { shoCarousel } from '../../actions/miscellaneousActions';
import { Img } from '../reusableComponents/ImageComponents/Img';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { KeyValue } from '../../utils/generalUtils';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery';
import noImage from '../../images/noImage.png'; 
import './CloseCallList.scss';
import { closeCallDetailProps } from '../../model/MechModel';
import { VideoPlay } from '../reusableComponents/FormComponents/VideoPlay'; 
import { Button } from '../reusableComponents/FormComponents/Button';

export interface IcloseCallReportDetailProps {
    closeCallReportDetails: closeCallDetailProps;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    history?: IHistory;
    formId: string;
    formType: string;
    match?: {
        params: {
            id: string;
        }
    };
} 
export interface IcloseCallReportDetailstate {
    closeCallReportDetails: closeCallDetailProps;
    showDetails: boolean;
    formId: string;
} 
export class closeCallReportDetailImpl extends React.PureComponent<IcloseCallReportDetailProps, IcloseCallReportDetailstate> {
    constructor(props: IcloseCallReportDetailProps | Readonly<IcloseCallReportDetailProps>) {
        super(props);
       /*  let reportDetail: closeCallDetailProps = {
            company: '', subAttributeList: [], formType: '', date: '', userName: ''
        }; */
        this.state = { showDetails: false, formId: '', closeCallReportDetails: props };
    } 
    promise = async () => { 
    }  
    render() {
        return (
            <BasePage className="closeCall-report" >
                <Gallery images={this.props.closeCallReportDetails ? this.props.closeCallReportDetails.imageUrls : []} />
                <DetailsPageWrapper pdfFileName={'Close Call'} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                   Close </Button> }
                >
                    {this.renderDetailList()}
                </DetailsPageWrapper> 
            </BasePage >
        );
    } 
    renderDetailList = () => {
        const { closeCallReportDetails } = this.props; 
        if(!closeCallReportDetails.company)
            this.props.history?.push(`/close-call-reports`); 
        const { props: { titleStyle } } = this; 
        return (
            <div>
                <Title style={titleStyle || {}} text="Close Call Report Details"></Title>
                <KeyValue label="Date"><span>{closeCallReportDetails.date}</span></KeyValue>
                <KeyValue label="Company Name">{closeCallReportDetails.company}</KeyValue>
                <KeyValue label="Form Type">{closeCallReportDetails.formType}</KeyValue>
                <div className="closeCallAnswers">{this.renderAnswerList()}</div>
                <KeyValue label="Images">{this.renderImages()}</KeyValue>
                {/* <KeyValue label="Videos"><VideoPlay videoUrls={closeCallReportDetails.videoUrls}/></KeyValue> */}
                <Row className=" col-sm-6"> 
                    <Col xs={6}
                        className="details-label"
                        style={{ textAlign: 'right' }} sm={6}>Videos:</Col>
                    <Col className="details-value closecall-videos"
                        sm={6} xs={6} id="detailsChild" >{<VideoPlay videoUrls={closeCallReportDetails.videoUrls}/>}</Col>
                </Row >  
            </div>
        );
    } 
    renderAnswerList = () => {
        const { closeCallReportDetails } = this.props;
        if (closeCallReportDetails.subAttributeList) {  
            return (closeCallReportDetails.subAttributeList || []).map((item:any, index: any) => {
                return ( 
                    <KeyValue label={closeCallReportDetails.subAttributeList && closeCallReportDetails.subAttributeList[index].quesType}><span>{ (closeCallReportDetails.subAttributeList && closeCallReportDetails.subAttributeList[index].answer) || 'NA'}</span></KeyValue> 
                );
            }); 
        } else {
            return (<span></span>);
        }
    }
    
    renderImages = () => {
        const { closeCallReportDetails } = this.props;
        if ((closeCallReportDetails.imageUrls || []).length <= 0) {
            return <img className="no-image" src={noImage} alt="SafeConnect" />;
        }
        const { imageUrls } = closeCallReportDetails; 
        return (imageUrls || []).map((imageData, index) => {
            return <Img
                onClick={() => shoCarousel(imageData)}
                key={index}
                style={{ padding: '5px' }}
                width="90px"
                height="75px"
                src={imageData}
            />;
        });
    } 
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        formId: ownProps.match.params.formId,
        formType: ownProps.match.params.formType,
        closeCallReportDetails: state.report.get('reportDetailData')
    };
}

export const CloseCallListDetail = withRouter(connect<IcloseCallReportDetailProps, any, any>(mapStateToProps)(closeCallReportDetailImpl));
