import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { Alert } from '../../Alert';
import { Async } from '../../reusableComponents/Async';
import { BasePage } from '../../reusableComponents/BasePage';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../../reusableComponents/Loader';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Title } from '../../reusableComponents/Title';
import { IAttribute, IHistory, ISubAttributeData } from '../../../interfaces';
import { emptyAttributeList, saveAttribute } from '../../../actions/attributeAction';
import { addAttribute, updateAttribute } from '../../../services/attributeService';
import { dispatch, isFormEmpty, showAlert, isArrayEmptyAndAlert } from '../../../utils/generalUtils'; 
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import './safetyWalkAssesment.scss';
export interface Action {
    type: any;
}
 
export interface AnyAction extends Action {
    // Allows any extra properties to be defined in an action.
    [attribute: string]: any;
} 
export interface IAttributeHistoryProps {
    history?: IHistory;
    attribute?: string;
    name?: string[];
    saveAttribute?: (attribute: IAttributeDataState) => void;
    isEditAttribute: boolean;
    editingAttribute: any;
    formType: string;
}

export interface IAttributeDataState {
    formType: string;
    attributeId: string;
    subAttributes: IAttribute[];
    newAttributes: IAttribute[];
    editAttribute: boolean;
    attribute: string;
    inputValue: string;
    name: string[];
    subAttributeList: ISubAttributeData[];
    isSubmitting: boolean;
}

export class AttributePageImpl extends React.Component<IAttributeHistoryProps, IAttributeDataState> {
    constructor(props: IAttributeHistoryProps | Readonly<IAttributeHistoryProps>) {
        super(props);
        let subAttribute: IAttribute = { name: '', count: 1 };
        this.state = {
            attributeId: '', inputValue: '', formType: '',
            subAttributes: [subAttribute], newAttributes: [],
            attribute: '', isSubmitting: false, editAttribute: true, name: [], subAttributeList: []
        };
    }

    componentDidMount() {
        if (this.props.editingAttribute && this.props.editingAttribute.subAttributeList) {
            dispatch(actions.change('forms.attributeFrom.attribute',
                this.props.editingAttribute.attribute));
            let subAttributeArray: Array<IAttribute> = [];
            this.props.editingAttribute.subAttributeList.map((item: { name: any; }, index: any) => {
                let newSubAttribute: IAttribute = { name: item.name, count: 0 };
                subAttributeArray.push(newSubAttribute);
                dispatch(actions.change(`forms.attributeFrom.name[${index}]`, item.name));
            });
            this.setState({ subAttributes: subAttributeArray });
        }
        // check if it is edit page or add
        // define one props as editing or adding then bind that in UI
        // dispatch(actions.reset('forms.attributeFrom'));
    }
    getChildContext() {
        return { formModel: 'forms.attributeFrom' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    promise = async () => {
        return;
    }

    incrementRows = () => {
        const attributeCount = this.state.subAttributes.length;
        this.setState({
            subAttributes: [...this.state.subAttributes, { count: attributeCount, name: '' }]
        });
    }

    navigateBack = () => { 
        //  dispatch(actions.reset('forms.attributeFrom'));
        // dispatch(actions.reset('attributeFrom'));
        //dispatch(editAttribute(false, null));

        dispatch(emptyAttributeList(''));
        if (this.props.formType === 'ManagerCheckList') {
            this.props.history?.push('/safety-walkthroug/all');
        } else if(this.props.formType === 'SupervisorCheckList'){
            this.props.history?.push('/supervisor-safetywalkthrough/all');
        }
        else {
            this.props.history?.push('/close-call-checklist');
        }
        // history.push(`/safety-walkthroug/all`);
    }

    decrementRow = (index: number) => {
        const { subAttributes } = this.state;
        dispatch(actions.remove(`forms.attributeFrom.name`, index));
        let newArray = subAttributes.filter(e => e !== subAttributes[index]);
        this.setState({
            subAttributes: newArray
        });
        // dispatch(actions.change('forms.attributeFrom.attribute',this.state.attribute));
    }

    handleSubmit = async (submittedValues: IAttributeDataState) => {
        const { editingAttribute } = this.props; 
        // const { response, updatedRules } = await submitGoldenRules(goldenRules.rule, history);
        // let attributeList = {attribute:this.state.attribute,name:this.state.subAttributes}
        // this.props.saveAttribute(submittedValue)); 
        const validateValues = {attribute:submittedValues.attribute}
        if (isFormEmpty(validateValues)) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        }
        if(isArrayEmptyAndAlert( {name: submittedValues.name } )) {
            showAlert(EMPTY_FORM_MESSAGE, 'attribute-form-failed', 'danger');
            return;
        } 
        let attributeParam = { formType: '', mainAttr: '', subAttr: [] as any, oldAttr:'' };
        attributeParam.formType = this.props.formType;
        attributeParam.mainAttr = submittedValues.attribute;
        attributeParam.oldAttr = editingAttribute && editingAttribute.attribute;
        attributeParam.subAttr = submittedValues.name;
        // dispatch(saveAttribute(submittedValues));
          
        if ((editingAttribute && editingAttribute.attribute) || (editingAttribute && editingAttribute.size !== 0)) {
            await updateAttribute(attributeParam);
        } else {
            await addAttribute(attributeParam);
        }
        //dispatch(editAttribute(false, null));
        
        dispatch(emptyAttributeList(''));
        // dispatch(actions.reset('forms.attributeFrom'));
        if (this.props.formType === 'ManagerCheckList') {
            this.props.history?.push('/safety-walkthroug/all');
        }else if(this.props.formType === 'SupervisorCheckList'){
            this.props.history?.push('/supervisor-safetywalkthrough/all');
        }
        else {
            this.props.history?.push('/close-call-checklist');
        }
        // window.location.reload();
    }

    renderSubAttributeDynamic() {
        const { subAttributes } = this.state;
        return subAttributes.map((attribute, index) => {
            return <Row key={index} className="show-grid">
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={11} sm={11} className="input">
                    <RRFInput
                        type="text"
                        placeholder="Enter sub attribute"
                        model={`.name[${index}]`}
                        defaultValue={attribute.name}
                    />
                </Col>
                { subAttributes.length>1 && <i className="fa fa-trash-o margin-top-delete"
                    onClick={() => this.decrementRow(index)} aria-hidden="true"></i>}
            </Row>;
        });
    }

    renderAttributeContent() {
        return (
            <div className="project-list-wrapper">
                <Grid style={{ width: '100%' }} className="safety-walkthrough-addattribute">
                    <Title titleColor="yellow" text="Add Attributes" />
                    <Alert className="danger-alert" id="attribute-form" />
                    <Form loader={<SubmissionLoader />} onSubmit={this.handleSubmit} model="forms.attributeFrom">

                        <Row className="show-grid">
                            <Col
                                style={{ padding: '10px 15px', display: 'inline-block' }}
                                xs={11}
                                sm={11}
                                className="input"
                            >
                                <RRFInput
                                    type="text"
                                    placeholder="Enter attribute name"
                                    model={`.attribute`}
                                    defaultValue={this.props.editingAttribute && this.props.editingAttribute.attribute}
                                />
                            </Col>

                        </Row>
                        {this.renderSubAttributeDynamic()}
                        <div className="table-buttons">
                            <button
                                className="add-row"
                                type="button"
                                onClick={this.incrementRows}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add More SubAttribute
                        </button>
                        </div>
                        <div style={{ textAlign: 'center' }} className="edit-button form-button">
                            
                            <Alert className="danger-alert" id="attribute-form-failed" />
                            <Button type="submit">Update</Button>
                            <Button style={{
                                color: '#5479AF',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }} onClick={async () => {
                                this.navigateBack();
                            }} type="reset">Cancel</Button>
                        </div>
                    </Form>
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <BasePage className="safety-addattribute_form" >
                <Async
                    identifier="SafetyWalkthroughAddAttribute"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderAttributeContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        editingAttribute: state.attribute.get('editingAttribute'),
        formType: state.attribute.get('formType'),
        isEditAttribute: state.attribute.get('isEditAttribute'),
    };
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => { 
    return {
        saveAttribte: () => {
            dispatch(saveAttribute(ownProps.attribute));
        }
    };
};

export const AddAttributePage = withRouter(connect<IAttributeHistoryProps, any, any>(mapStateToProps, mapDispatchToProps)(AttributePageImpl));
