import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { IProjectModelProps, ProjectModel } from '../../model/ProjectModel';
import { submitProjectForm } from '../../services/projectService';
import { dispatch, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title';
import { ProjectForm } from './ProjectForm';
import './projects.scss';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { format } from 'date-fns';

export interface ICreateFormProps {
    history: IHistory;
}

export interface ICreateFormState {
}

export class CreateFormImpl extends React.PureComponent<ICreateFormProps, ICreateFormState> {
    constructor(props: ICreateFormProps | Readonly<ICreateFormProps>) {
        super(props);
    }

    componentWillMount() {
        dispatch(actions.reset('forms.projectForm'));
    }

    handleSubmit = (submittedValues: IProjectModelProps) => {
        const { history } = this.props;
        submittedValues.dateCreated = format(new Date(), 'yyyy-MM-dd');
        if (isFormEmpty({
            projectName: submittedValues.projectName,
            projectDesc: submittedValues.projectDesc    
        })) {
            showAlert(EMPTY_FORM_MESSAGE, 'create-project', 'danger');
            return;
        }
        return submitProjectForm(submittedValues, history);
    }

    render() {
        return (
            <BasePage>
                <Grid className="project-form" style={{ width: '100%' }}>
                    <Title text="Add Teams Form" />
                    <AlertModal id="create-project-modal" />
                    <Alert id="create-project" className="danger-alert" />
                    <ProjectForm onSubmit={this.handleSubmit} />
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userId = state.login.get('userId');
    const projectInstance = ProjectModel.get(userId);
    return {
        projectInstance,
    };
}

export const CreateForm = withRouter(connect<{}, {}, ICreateFormProps>(mapStateToProps)(CreateFormImpl));
