import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { IOfficeSafetyCommentSummary, ISelectOptions } from '../../interfaces';
import { GetOfficeComments } from '../../services/officeSafetyFormService';
import { printDetails, getFormattedDate } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { getOfficeSafetyCategoryTitle } from '../../constants/general';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { format } from 'date-fns';
import { OFFICESAFETYTITLES } from '../../constants/general';
import { getLocations } from '../../services/attributeService';
import { UserModel } from '../../model/UserModel';
import { IHistory } from '../../interfaces';
import { Pagination } from '../reusableComponents/Pagination';
import { getPageItemNumber, setFilters } from '../../utils/generalUtils';
declare var require: any;
const queryString = require('query-string');

export interface IOfficeSafetyCommentsProps {
    commentsData: IOfficeSafetyCommentSummary;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    history: IHistory;
    totalPageCount: number;
}

export interface IOfficeSafetyCommentsState {
    mountPrintPreviewModal: boolean;
    filterBy: string;
    CommentList: {
        name?: string;
        date?: string;
        comments?: string;
    }[];
    startDate: string;
    isStartSelected: boolean;
    endDate: string;
    minDate: string;
    maxDate: string;
    isLoading: boolean;
    category?: string;
    location?: string;
    riskFact?: string;
    categoryOptions?: ISelectOptions[];
    locationOptions?: ISelectOptions[];
}

export class OfficeSafetyCommentsImpl extends React.Component<IOfficeSafetyCommentsProps,
    IOfficeSafetyCommentsState> {
    constructor(props: IOfficeSafetyCommentsProps) {
        super(props);
        let dt = new Date(); 
        this.state = { CommentList: [], mountPrintPreviewModal: false, filterBy: '', isStartSelected: false,
        startDate: format(new Date(dt.getFullYear(), dt.getMonth(), 1), 'yyyy-MM-dd') , endDate: getFormattedDate(), minDate: '', maxDate: getFormattedDate(), isLoading: true }; 
    }

    promise = async () => { 
        const { startDate, endDate }  = this.state;
        const CommentList = await GetOfficeComments(startDate, endDate);
        this.setState({
            CommentList,
            isLoading: false
        });  
    }
    async componentWillMount() {
        let categoryOptions: Array<any> = [];
        for (let key of Object.keys(OFFICESAFETYTITLES)) {
            categoryOptions.push({value:key ,label:OFFICESAFETYTITLES[key]}); 
        }
        categoryOptions.sort((a, b) => a.label.localeCompare(b.label));
        await this.setState({categoryOptions: categoryOptions});
        const company = UserModel.getCompanyName(); 
        let locations = await getLocations(company); 
        let locationOptions: Array<any> = [];
        for (let item in locations) { 
            for (let location in locations[item]) {
                locationOptions.push({value:location ,label:location});
            }
        }
        locationOptions.push({value:'Other' ,label:'Other'});
        //categoryOptions.sort((a, b) => a.label.localeCompare(b.label));
        await this.setState({locationOptions: locationOptions});
    }
    
    filterComments = async (process: string) => {
        this.setState({isLoading: true});
        if(process==='reset') {
            let pageNumber = 1, activeBar = 1;
            await setFilters({ pageNumber, activeBar }, this.props.history);
            let dt = new Date(); 
            await this.setState({ location: '', category: '', startDate: format(new Date(dt.getFullYear(), dt.getMonth(), 1), 'yyyy-MM-dd') , endDate: getFormattedDate(), riskFact: '' }); 
        }
        if(process==='filter') {
            let pageNumber = 1, activeBar = 1;
            await setFilters({ pageNumber, activeBar }, this.props.history);
        }
        const { startDate, endDate, location, category, riskFact }  = this.state;
        let pageNumber = queryString.parse(this.props.history.location['search']).pageNumber || 1;
        const CommentList = await GetOfficeComments(startDate, endDate, location, category, riskFact, pageNumber );
        this.setState({
            CommentList,
            isLoading: false
        });
    } 
    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any; 

    renderListItems = () => {
        const { CommentList } = this.state; 
        const { props: { style } } = this;  
        if (!CommentList || CommentList.length <= 0) {
            return (
                <div style={{ textAlign: 'center', margin: '26px', color: 'white' }}>No records found.</div>
            );
        }
        /* let filteredcloseCallInstances = CommentList; 
        if (filteredcloseCallInstances.length <= 0) { return ( <div style={{ textAlign: 'center', padding: '26px', color: 'grey' }}>No records found.</div> ); } 
        const last = filteredcloseCallInstances.length - 1;  */
        let pageNumber = queryString.parse(this.props.history.location['search']).pageNumber || 1;
        return (CommentList || []).map((instance: any, index) => { 
            let category = getOfficeSafetyCategoryTitle(instance.section);
            return (
            <span key={index} >
                <Row className="report-row" style={style}>
                    <Col className="clickable-list-item" sm={1} >  
                        {getPageItemNumber(index, pageNumber)}
                    </Col>
                    <Col className="clickable-list-item" 
                        sm={2}
                    >
                        {instance.date || 'N/A'}
                    </Col>
                    <Col className="clickable-list-item" 
                        sm={2}
                    >
                        {instance.location || 'N/A'}
                    </Col>
                    <Col className="clickable-list-item" 
                        sm={2}
                    >
                         {category.length>15? <OverlayTrigger placement="top" overlay={ <Tooltip id={`Tooltip - Category - ${index}`}> <span>{category}</span> </Tooltip>}>
                                    { <span style={{ opacity: 1 }} >{category.substring(0, 14) + ".."}</span> }
                                </OverlayTrigger>:category || 'N/A'}  
                    </Col> 
                    <Col className="clickable-list-item" 
                        sm={5}
                    >
                       {instance.comment.length>50? <OverlayTrigger placement="top" overlay={ <Tooltip id={`Tooltip - Comment - ${index}`}> <span>{instance.comment}</span> </Tooltip>}>
                                    { <span style={{ opacity: 1 }} >{instance.comment /* instance.comment.substring(0, 49) + ".." */}</span> }
                                </OverlayTrigger>:instance.comment || 'N/A'} 
                    </Col>
                </Row>
            </span>
            );
        });
    }
    handleCategory = (value: any) => { 
        this.setState({
            category: value,
        }); 
    }
    handleLocation = (value: any) => { 
        this.setState({
            location: value,
        }); 
    }
    handleRiskFact = (value: any) => { 
        this.setState({
            riskFact: value,
        }); 
    }
    renderContent = (id?: any) => {
        const { props: { style }, state:{categoryOptions, locationOptions} } = this;  
        return  <DetailsPageWrapper pdfFileName={'Office Safety Comments'} className="oi-report report-details">
            <div>
            {this.state.isLoading && <SubmissionLoader/>}
            <Grid style={{ width: '100%' }} className="office-safety-comments">
                <Title titleColor="yellow" style={{ marginBottom: '30px' }} text="Office Safety Comments" />
                <div className="filter-container p0" >
                    <Row>
                        <Col sm={4} md={2} className="input">Category: &nbsp;  <RRFInput
                            id="category" 
                            model=".category"
                            type="dropdown" 
                            onSelect={this.handleCategory}
                            defaultValue={this.state.category} 
                            menuItems={categoryOptions} 
                        /></Col>
                        <Col sm={4} md={2} className="input">Location: &nbsp;  <RRFInput
                            id="location" 
                            model=".location"
                            type="dropdown" 
                            defaultValue={this.state.location}
                            onSelect={ this.handleLocation}
                            menuItems={locationOptions} 
                        /></Col>
                        <Col sm={4} md={2} className="input">Risk/Safe: &nbsp;  <RRFInput
                            id="riskFact" 
                            model=".riskFact"
                            type="dropdown" 
                            defaultValue={this.state.riskFact}  
                            onSelect={ this.handleRiskFact}
                            menuItems={[{ label: 'Safe', value: 'safe' },
                            { label: 'Risk', value: 'risk' }]} 
                        /></Col>
                        <Col sm={4} md={2} className="input">Start Date: &nbsp; 
                    {/*  <RRFInput id="startDate" type="date" model=".startDate" defaultValue={this.state.startDate }
                            mindate={this.state.minDate} maxdate={this.state.maxDate}
                            onChange={e => this.setState({ isStartSelected: true, minDate: getFormattedDate(new Date(e.target.value)), startDate: getFormattedDate(new Date(e.target.value)) })} 
                        /> */}
                            <input className="form-control" max={this.state.endDate} value={this.state.startDate}
                            onChange={e => this.setState({ isStartSelected: true, startDate: getFormattedDate(new Date(e.target.value)) })} type="date" />
                        </Col>
                        <Col sm={4} md={2} className="input">End Date: &nbsp; 
                    {/*  <RRFInput
                            id="endDate" type="date" model=".endDate" defaultValue={this.state.endDate } mindate={this.state.minDate} 
                            disabled={this.state.isStartSelected} maxdate={this.state.maxDate}
                            onChange={e => this.setState({ maxDate: getFormattedDate(new Date(e.target.value)), endDate: getFormattedDate(new Date(e.target.value)) })} 
                        /> */} <input 
                        min={this.state.startDate}
                        max={getFormattedDate()}
                        disabled={!this.state.isStartSelected} value={this.state.endDate}
                        className="form-control" 
                        onChange={e => this.setState({ endDate: getFormattedDate(new Date(e.target.value)) })} 
                        type="date"  /></Col>
                        <Col sm={2} md={1} className="input"><button className="filter-button" onClick={() => this.filterComments('filter')}  >Filter</button> </Col>
                        <Col sm={2} md={1} className="input"><button className="filter-reset-button"  onClick={() => this.filterComments('reset')} >Reset</button> </Col>
                    </Row>
                </div> 
                <div className="oi-list-wrapper">
                    <div style={style} className="list-page"> 
                        <div className="reports-table">
                            <div style={{ /* margin: '10px 100px 0px 110px' */ }}>
                                <Row className="heading report-row">
                                    <Col sm={1}>#</Col>
                                    <Col sm={2}>Date Submitted</Col>
                                    <Col sm={2}>Location</Col>
                                    <Col sm={2}>Category</Col>
                                    <Col sm={5}>Comment</Col>
                                </Row >
                            </div>
                            <div
                                style={{
                                    textAlign: 'center', fontSize: '15px', color: 'White'
                                }}>
                                {this.renderListItems()}
                            </div>
                        </div>
                    </div > 
                    <Pagination
                        history={this.props.history}
                        promise={(pageNumber) => this.filterComments('paginate')}
                        totalCount={this.props.totalPageCount}
                        Model={'' as any}
                        //identifier="OIFormSummary"
                        identifier="AllListPage"
                    />
                </div> 
            </Grid>
        </div>
        </DetailsPageWrapper>;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'OfficeSafetyReports');
    }

    renderPrintPreviewModal = () => {
        this.setState({
            mountPrintPreviewModal: true
        });
        showPrintPreviewModal('office-safety-report');
        return;
    }

    render() {
        return <BasePage className=" extra-wide-list-page safeconnect-form___" >
            <Async
                identifier="OfficeReportsPage"
                promise={this.promise}
                loader={<Loader />}
                content={this.renderContent()}
                error={<ErrorPage />}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state: any) {
    return {
        reportsData: state.formSummary.get('officeSafetyFormSummary'), totalPageCount: state.miscellaneous.get('listCount'),
    };
}

export const OfficeSafetyComments =
    connect<{}, {}, IOfficeSafetyCommentsProps>(mapStateToProps)(OfficeSafetyCommentsImpl);
