import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Title } from '../reusableComponents/Title';  
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper';
import { KeyValue, getChecklistReferenceDocument } from '../../utils/generalUtils';
import { Gallery } from '../reusableComponents/ImageComponents/Gallery'; 
import './Auditlist.scss'; 
import { Button } from '../reusableComponents/FormComponents/Button';
import { AuditModel } from '../../model/AuditModel';
import { Loader } from '../reusableComponents/Loader';
import { format } from 'date-fns';
import { Label } from '../reusableComponents/FormComponents/Label';
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';
import { getAllCheckListNames } from '../../services/checklistService';
import { ChecklistNameModel } from '../../model/ChecklistModel'; 
import { Async } from '../reusableComponents/Async';

export interface IAuditFormDetailProps {
    checklistDetails: AuditModel;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    history?: IHistory;
    formId: string;
    viewId: string;
    match?: {
        params: {
            id: string;
        }
    }; 
    checklistNameInstance: ChecklistNameModel;
} 
export interface IAuditFormDetailstate {  
    showDetails: boolean;
    formId: string;
} 
export class AuditFormDetailImpl extends React.PureComponent<IAuditFormDetailProps, IAuditFormDetailstate> {
    constructor(props: IAuditFormDetailProps | Readonly<IAuditFormDetailProps>) {
        super(props); 
        this.state = { showDetails: false, formId: ''};
    } 
    promise = async () => { 
        //if(ChecklistNameModel.list().length<1)
        await getAllCheckListNames('auditChecklistForm')
    } 
    async componentDidMount() { 
        //const { formId, viewId } = this.props;
        //getSWReportDetail(formId, formType); 
    }
    renderDetailList = () => {
        const { checklistDetails, checklistNameInstance } = this.props;  
        if (!checklistDetails) {
            return <Loader />;
        } 
        return (
            <div>
                <Title text={` ${checklistNameInstance && checklistNameInstance.props.checklistName} Quality Checklist Details`}></Title>
                <KeyValue label="Date"><span>{checklistDetails.props.dateOfIncident && format(new Date(checklistDetails.props.dateOfIncident), 'MM/dd/yyyy')}</span></KeyValue>
                <KeyValue label="Company Name">{checklistDetails.props.company}</KeyValue>
                <KeyValue label="Team Name">{checklistDetails.props.projectName}</KeyValue>
                <KeyValue label="Group Name">{checklistDetails.props.groupName}</KeyValue> 
                <KeyValue label="Reference No">{checklistDetails.props.serialNo}</KeyValue> 
                <Title style={{fontSize: '16px'}} text={`Checklists`}></Title>
                <Row className="border-left border-right border-top margin-left-right-0 padding-bottom-0 ">
                    <Col className="border-bottom border-right" sm={1} xs={12}>
                        <Label className="checklist-title" sm={12}>No.</Label>
                    </Col>
                    <Col className="border-bottom" sm={6} xs={12}>
                        <Label className="checklist-title"  sm={12}>{`${checklistNameInstance && checklistNameInstance.props.checklistName}`} Quality Elements</Label>
                    </Col>
                    <Col className="border-bottom border-left" sm={2} xs={12}> 
                        <Label className="checklist-title effect-splice" sm={12}>Effectiveness</Label>
                    </Col> 
                    <Col className="border-bottom border-left" sm={3} xs={12}>
                        <Label className="checklist-title" sm={12}>Comments</Label>
                    </Col> 
                </Row> 
                {this.renderAnswerList()}
                {/* <span className="border"></span> */} 
                <KeyValue label="Total Score">{checklistDetails.props.totalScore}</KeyValue>
                <KeyValue label=" Overall %">{checklistDetails.props.overallPercent}%</KeyValue>
                <KeyValue label="Overall Comments">{checklistDetails.props.overallComments}</KeyValue > 
                {this.renderFiles()}
            </div>
        );
    }
    renderAnswerList = () => {
        const { comments, effect, checklistQuestions } = this.props.checklistDetails.props; 
        return checklistQuestions.map((item: string, index: any) => { 
            return (<div key={`attributeRowParent${index}`}>
                <Row key={`subAttributeRow${index}`}
                    className="customized-grid row-cell-height border-bottom border-left border-right margin-left-right-0 padding-bottom-0">
                        <Col className="row-cell-height border-right" sm={1} xs={12}> 
                            <div className="sequence-number-position">
                                {`${index+1}.`}
                            </div>
                        </Col>
                        <Col className="row-cell-height border-right auditQuestion" sm={6} xs={12} >
                            <Label sm={12} style={{color: 'white' }}>{item}</Label>
                        </Col>
                        <Col className="row-cell-height border-right color-white effectiveness" sm={2} xs={12}>
                            <div className="width-height-100-textcenter"> {effect[index] || 'NILL'}
                            </div>
                        </Col> 
                        <Col className="row-cell-height comments" sm={3} xs={12}>
                            <div className="width-height-100-textcenter">  
                                {comments[index] || 'NILL'}
                            </div>
                        </Col>
                    </Row>  
                </div> );
        });
    }
    renderFiles = () => {
        const { checklistDetails, checklistNameInstance } = this.props; 
        const { attachmentUrls } = checklistDetails.props;  
        return (
            <Col sm={12} xs={12} style={{textAlign: 'center'}}>
                <Label required={false} sm={12} htmlFor="upload-images" className="details-label" style={{textAlign: 'center'}}>Attachments:</Label>
                <Col sm={12} className="input"> 
                    { checklistDetails && checklistNameInstance && checklistDetails.props.referenceNo!=='External~Data' && getChecklistReferenceDocument('checklist', '', `${checklistDetails.props.referenceNo}/${checklistNameInstance.props.checklistName}`) } 
                    <AttachementInput
                        model=".attachements"
                        multiple={true}
                        id="upload-images" 
                        noNeedAttachButton={true}
                        emptyMessage="No external attachments found.."
                        uploadedImages={attachmentUrls || []}
                    />
                </Col>
            </Col> 
        );
    } 

    renderContent() {
        return (
                <><Gallery images={this.props.checklistDetails ? this.props.checklistDetails.props.fileUrls : []} />
                <DetailsPageWrapper pdfFileName={'Audit-Inspection Report'} className="oi-report report-details" customButton={<Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() }>
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                Close </Button> } >
                    {this.renderDetailList()}
                </DetailsPageWrapper></> 
        );
    }
    render() {
        return (
            <BasePage className="jsa-report">
                <Async
                    identifier="checklist"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    } 
}

export function mapStateToProps(state: any, ownProps: any) {  
    const checklistDetails = AuditModel.get(ownProps.match.params.viewId)
    const checklistNameInstance = ChecklistNameModel.get(checklistDetails.props.formId); 
    return {
        formId: ownProps.match.params.checklistId,
        viewId: ownProps.match.params.viewId, 
        checklistDetails, checklistNameInstance
    };
}

export const AuditFormDetail = withRouter(connect<IAuditFormDetailProps, any, any>(mapStateToProps)(AuditFormDetailImpl)); 
