import * as React from 'react';
import './password-verification.scss';

export interface IErrorMessageProps {
    isActive: boolean;
    errorMessage: string;
}

export class ErrorMessageLabel extends React.PureComponent<IErrorMessageProps, {}> {
    constructor(props: IErrorMessageProps) {
        super(props);
    } 
    render() {
        const { isActive, errorMessage } = this.props; 
        return (
            <React.Fragment>
                {isActive && <h6 className="password-verification">{errorMessage}</h6> }
            </React.Fragment>
        );
    }   
    
}
