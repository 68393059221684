import * as React from 'react'; 
import { connect } from 'react-redux';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom'; 
import { format } from 'date-fns';
import Geocode from 'react-geocode';
import PropTypes from 'prop-types';
import { IForms, IHistory } from '../../../interfaces'; 
import { constructAnswers, dispatch, isFormEmpty, showAlert } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage'; 
import './location.scss';
import { EMPTY_FORM_MESSAGE } from '../../../constants/general';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { Title } from '../../reusableComponents/Title';
import { Label } from '../../reusableComponents/FormComponents/Label';
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { ILocationModelProps, LocationModel } from '../../../model/MiscellaneousModel';
import { submitLocationForm } from '../../../services/miscellaniousServices';
import { GOOGLEAPIKEY } from '../../../constants/general';

export interface ILocationFormProps { 
    history?: IHistory;
    forms: IForms;
    editId?: string;
    instance?: LocationModel;
}

export interface ILocationFormState {
}

export class LocationFormImpl extends React.PureComponent<ILocationFormProps, ILocationFormState> {

    constructor(props: ILocationFormProps | Readonly<ILocationFormProps>) {
        super(props); 
        Geocode.setApiKey(GOOGLEAPIKEY);
    } 
    
    promise = async () => {

    }
    getChildContext() {
        return { formModel: 'forms.locationForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleFormSubmit = async ({ region, locationName, address }: ILocationModelProps) => { 
        const { props: { forms, history, editId } } = this; 
        const answerData: any = { region, locationName, address }; 
        if (isFormEmpty(answerData)) {
            showAlert(EMPTY_FORM_MESSAGE, 'location-form-failed', 'danger');
            return;
        } 
        let coordinates = {lat: '', lng: ''}
        await Geocode.fromAddress(address).then(
            (response) => { 
                if(response && response.status==='OK') {
                    const { lat, lng } = response.results[0].geometry.location;
                    coordinates.lat = lat.toString();
                    coordinates.lng = lng.toString(); 
                } else { 
                    showAlert('Coordinates not found. Please try with correct address.', 'location-form-failed', 'danger');
                    return;
                }
            },
            (error) => { 
                showAlert('Coordinates not found. Please try with correct address.', 'location-form-failed', 'danger');
                return;
            }
        );
        answerData.coordinates = coordinates; 
        answerData.editId = editId || '';  
        return history && submitLocationForm(answerData, history, forms); 
    } 

    renderContent = () => {
        const { editId, instance } = this.props;
        return (
            <Grid className="location-form" style={{ width: '100%' }}> 
                <Form loader={<SubmissionLoader />} model="forms.locationForm" onSubmit={this.handleFormSubmit}>
                    <Title titleColor="yellow" text="Location Form" noNeedBorder={true}/>
                    <AlertModal id="location-modal" /> 
                    <fieldset> 
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" required htmlFor="region">Region:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".region"
                                    id="region" 
                                    defaultValue={instance?instance.props.region:'General'}
                                /> 
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" required htmlFor="locationName">Location Name:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="text"
                                    model=".locationName"
                                    id="locationName" 
                                    defaultValue={instance && instance.props.locationName }
                                />
                            </Col>
                        </Row>
                        <Row style={rowStyle} className="show-grid">
                            <Label className="formLabel" required htmlFor="address">Address:</Label>
                            <Col sm={6} className="input">
                                <RRFInput
                                    type="textarea"
                                    model=".address"
                                    id="address" 
                                    defaultValue={instance && instance.props.address }
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="location-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ !instance? "fa fa-plus": 'fa fa-refresh' }  aria-hidden="true"></i>
                            &nbsp;
                            { !instance? 'Submit': 'Update' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    }
 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="LocationForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}


const rowStyle: React.CSSProperties = {
    paddingBottom: '20px'
};

export function mapStateToProps(state: any, ownProps: any) { 
    const editId = ownProps.match.params.id;
    const instance = LocationModel.get(editId);
    return { editId, instance, forms: state.forms, };
}
export const LocationForm = withRouter(connect<ILocationFormProps, any, any>(mapStateToProps)(LocationFormImpl));
