import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { ICovid19ShortSummaryProps } from '../../model/Covid19Model';
import { SafetyWalkthroughReportChartData } from '../../model/SafetyWalkthroughModel';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { printDetails } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { Covid19SummaryData } from './Covid19SummaryData'; 
import { getCovid19SummaryData, getCovidShortSummary } from '../../services/covid19Service'; 
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';  
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { format } from 'date-fns';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
 
export interface ICovid19SummaryProps { 
    pandemicSummary: any;
    pandemicShortSummary: ICovid19ShortSummaryProps;
    totalUsers: number;
}

export interface ICovid19SummaryPageState {
    mountPrintPreviewModal: boolean;
    reportsDataValue: SafetyWalkthroughReportChartData;
    filterBy: string;
}

export class Covid19SummaryPageImpl extends React.Component<ICovid19SummaryProps,
ICovid19SummaryPageState> {
    constructor(props: ICovid19SummaryProps) {
        super(props);
        let reportData: SafetyWalkthroughReportChartData = { reportCountData: {}, reportSumData: {} };
        this.state = { mountPrintPreviewModal: false, filterBy: '', reportsDataValue: reportData };
    }

    promise = async () => {
        const year = new Date().getFullYear().toString(); 
        const month = (new Date().getMonth() + 1).toString();
        await getCovid19SummaryData('monthly', year, month); 
        if(!this.props.totalUsers)
            await getAllUsers('includeSelf'); 
        await getCovidShortSummary(format(new Date(), 'yyyy-MM-dd')); 
        return null;
    }
    async componentDidMount() {
        const year = new Date().getFullYear().toString(); 
        const month = (new Date().getMonth() + 1).toString();
        await getCovid19SummaryData('monthly', year, month);
    }

    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any; 
    renderContent = (id?: any) => {
        if(this.props.pandemicSummary) { 
            return <div>
                <Covid19SummaryData
                    reportsData={this.props.pandemicSummary}
                    getRef={value => this.ref = value}
                    id={id}
                    isPrint={id ? true : false}
                    getFilterBy={filterBy => { this.setState({ filterBy }); }}
                    filterBy={this.state.filterBy}
                    totalUsers={this.props.totalUsers}
                    pandemicShortSummary={this.props.pandemicShortSummary} 
                /> 
               {/*  <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                    <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
                </div> */}
            </div>;
        } else {
            <div></div>
        }
    } 
    exportCharts = (val: any) => {
        printDetails(this.ref, 'SafetyWalkthroughReports');
    } 
    renderPrintPreviewModal = () => {
        this.setState({
            mountPrintPreviewModal: true
        });
        showPrintPreviewModal('covid19-report');
        return;
    } 
    render() { 
        return <BasePage className="reports-page office-safety-summary" > 
                <PrintPreviewModal
                    id="covid19-report"
                    ref="printPreview"
                    className="oi-report-data"
                    fileName="OIReports"
                    onClickYes={this.exportCharts}
                    style={{ boxShadow: 'none' }}
                >
                    {this.renderContent('print')}
                </PrintPreviewModal>
            <Async
                identifier="covidSummary"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderContent(true ) }
            /> 
        </BasePage>;
    }
    render_1() {
        /* return <BasePage className="reports-page office-safety-summary" >
            {this.state.mountPrintPreviewModal ? <PrintPreviewModal
                id="office-safety-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="OfficeSafetyReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent('print')}
            </PrintPreviewModal> : ''}
            {this.renderContent()} 
        </BasePage>; */
        return <BasePage className="reports-page office-safety-summary" >   {this.renderContent(true )}
            {/* <Async
                identifier="OIFormSummary"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderContent(true ) }
            />  */}
        </BasePage>;
    }
    render_Temp() {
        return <BasePage className="reports-page office-safety-summary" > 
            <Async
                identifier="OIFormSummary"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderContent(true ) }
            /> 
        </BasePage>;
    }
} 
export function mapStateToProps(state: any) { 
    return { 
        pandemicSummary: state.formSummary.get('pandemicSummary'),
        totalUsers: UserModel.list().length,
        pandemicShortSummary: state.formSummary.get('pandemicShortSummary'),
    };
}  
export const Covid19SummaryPage = connect<{}, {}, ICovid19SummaryProps>(mapStateToProps)(Covid19SummaryPageImpl);
