import { isEmpty } from '../../utils/generalUtils';
import * as React from 'react';
import { connect } from 'react-redux';
import { executePromiseAction } from '../../actions/loadingActions';

export interface IAsyncProps extends IAsyncStoreProps {
    promise?: (...args: any[]) => any;
    identifier: string;
    content: JSX.Element | JSX.Element[] | undefined;
    loader?: JSX.Element | JSX.Element[];
    error?: JSX.Element | JSX.Element[];
    initialState?: IAsyncState | any;
    className?: string;
}

export interface IAsyncStoreProps {
    executePromise?: () => void;
    loadingState?: IAsyncState | any;
}

export interface IAsyncState {
    isLoading?: boolean;
    hasError?: boolean;
}

export class AsyncImpl extends React.Component<IAsyncProps, {}> {
    constructor(props: IAsyncProps | Readonly<IAsyncProps>) {
        super(props);
    }

    executePromise = () => {
        const { identifier, promise } = this.props;
        if (promise instanceof Function) {
            executePromiseAction(
                promise(),
                identifier,
            );
        }
    }

    componentDidMount() {
        this.executePromise();
    }

    renderChildren = () => {
        let { props: { content, loader, error, loadingState } } = this; 
        let { isLoading, hasError } = loadingState; 

        if ((isLoading || isEmpty(loadingState)) && loader) {
            return React.Children.only(loader);
        }

        if (!isLoading && !hasError) {
            return React.Children.only(content);
        }

        if (hasError && Error()) {
            return React.Children.only(error);
        }
    }

    render() {
        return (this.renderChildren());
    }
}

export function mapStateToProps(state: any, { identifier, initialState }: any) {
    return {
        loadingState: state.loading.get(identifier) || initialState || {}
    };
}
export const Async = connect<{}, {}, IAsyncProps>(mapStateToProps)(AsyncImpl);
