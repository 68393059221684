import * as React from 'react';
import { connect } from 'react-redux';
import { BasePage } from '../reusableComponents/BasePage';
import { OfficeSafetyReportsData } from './OfficeSafetyReportsData';
import { Async } from '../reusableComponents/Async';
import { getOfficeSafetyFormSummary } from '../../services/officeSafetyFormService';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { IOfficeSafetyReportSummary, IForms } from '../../interfaces';
import PropTypes from 'prop-types';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { printDetails } from '../../utils/generalUtils';
import { showPrintPreviewModal } from '../../actions/modalActions';

export interface IOfficeSafetyReportSummaryPageProps {
    reportsData: IOfficeSafetyReportSummary;
    forms: IForms;
}

export interface IOfficeSafetyReportSummaryPageState {
    mountPrintPreviewModal: boolean;
    filterBy: string;
}

export class OfficeSafetyReportSummaryPageImpl extends React.Component<IOfficeSafetyReportSummaryPageProps,
    IOfficeSafetyReportSummaryPageState> {
    constructor(props: IOfficeSafetyReportSummaryPageProps) {
        super(props);
        this.state = { mountPrintPreviewModal: false, filterBy: '' };
    }

    promise = async () => { 
        //await getOfficeSafetyFormSummary('summary', 'na', 'na');
        await getOfficeSafetyFormSummary();
    }

    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any;

    renderContent = (id?: any) => {
        return <div>
            <OfficeSafetyReportsData
                reportsData={this.props.reportsData}
                getRef={value => this.ref = value}
                id={id}
                isPrint={id ? true : false}
                getFilterBy={filterBy => { this.setState({ filterBy }); }}
                filterBy={this.state.filterBy}
                forms={this.props.forms}
            />
            <div style={{ padding: '10px', textAlign: 'center' }} className="export-button-container">
                <button className="button" onClick={this.renderPrintPreviewModal}>Export as PDF</button>
            </div>
        </div>;
    }

    exportCharts = (val: any) => {
        printDetails(this.ref, 'OfficeSafetyReports');
    }

    renderPrintPreviewModal = () => {
        this.setState({
            mountPrintPreviewModal: true
        });
        showPrintPreviewModal('office-safety-report'); 
        return;
    }

    render() {
        return <BasePage className="reports-page office-safety-summary" >
            {this.state.mountPrintPreviewModal ? <PrintPreviewModal
                id="office-safety-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="OfficeSafetyReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent('print')}
            </PrintPreviewModal> : ''}
            <Async
                identifier="OfficeReportsPage"
                promise={this.promise}
                loader={<Loader />}
                content={this.renderContent()}
                error={<ErrorPage />}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state: any) {
    return {
        reportsData: state.formSummary.get('officeSafetyFormSummary'), forms: state.forms,
    };
}

export const OfficeSafetyReportSummaryPage =
    connect<{}, {}, IOfficeSafetyReportSummaryPageProps>(mapStateToProps)(OfficeSafetyReportSummaryPageImpl);
