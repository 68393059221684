import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Label } from '../reusableComponents/FormComponents/Label';
import { actions } from 'react-redux-form';
import { dispatch } from '../../utils/generalUtils';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { connect } from 'react-redux';
import { IForms } from '../../interfaces'; 
import { LocationOptionComponent } from '../reusableComponents/ReactSelectComponents/LocationOptionComponent';
import { LocationValueComponent } from '../reusableComponents/ReactSelectComponents/LocationValueComponent';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal'; 
import { IncidentReportModel } from '../../model/IncidentReportModel'; 
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IIncidentDetailsFormProps {
    forms: IForms;
    company: string; 
    editInstance?: IncidentReportModel;
}

export interface IIncidentDetailsFormState {
    locationInputIsDropdown: boolean;
}

export class IncidentDetailsFormImpl extends React.PureComponent<IIncidentDetailsFormProps, IIncidentDetailsFormState> {
    constructor(props: IIncidentDetailsFormProps | Readonly<IIncidentDetailsFormProps>) {
        super(props);
        this.state = { locationInputIsDropdown: true };
    }

    componentWillMount() {
        const { forms } = this.props;
        if (!forms) {
            return;
        }
        const otherLocation = forms.incidentDetails.location;
        if (otherLocation) {
            this.setState({ locationInputIsDropdown: false });
        }
        dispatch(actions.change('forms.incidentDetails.location', otherLocation)); 
    }

    renderLocationInputRow = () => {
        const { locationInputIsDropdown: isEdit } = this.state;
        return (
            <Col sm={3} xs={12}>
                <Label sm={12} htmlFor="location">Other Location:</Label>
                <Col sm={12} className="input">
                    <RRFInput
                        isDisabled={isEdit}
                        id="location"
                        className="form-control"
                        placeholder={isEdit ? 'Select Other in location to edit' : 'Enter other location...'}
                        type="text"
                        model={isEdit ? '.otherLocation' : '.location'}
                    />
                </Col>
            </Col>
        );
    }

    handleLocationInput = (value: string) => {
        if (value === 'Other') {
            this.setState({
                locationInputIsDropdown: false
            });
            dispatch(actions.change('forms.incidentDetails.location', ''));
        } else {
            this.setState({
                locationInputIsDropdown: true
            });
        }
    }

    isCustom = () => {
        if (this.props.company === 'Demo-Exxon' || 'UP') {
            return true;
        } else {
            return false;
        }
    }

    incidentDetailedSummary = '';

    getProps = () => {
        if (this.props.company === 'Demo-Exxon' || 'UP') {
            return {
                id: 'location',
                type: 'dropdown', 
                onSelect: (value: string) => this.handleLocationInput(value),
                optionComponent: LocationOptionComponent,
                valueComponent: LocationValueComponent,
                model: '.locationName',
            };
        }
        return {
            id: 'location',
            type: 'dropdown', 
            onSelect: (value: string) => this.handleLocationInput(value),
            model: '.locationName',

        };
    }

    render() {
        const { props: { company, forms } } = this;
            
        const { editInstance } = this.props;
        return (
            <div className="incident-details form-bottom">
                <MapInputModal id="incident-investigative-form" />
                <fieldset className="form-bottom-fieldset">
                    <legend><span style={{ color: 'yellow' }}>Incident Details Form</span></legend>
                    <Row className="show-grid">
                        <Col sm={3} xs={12}>  
                            <Label sm={12} htmlFor="eventLeadingToInjury">Event Leading To Injury:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.eventLeadingToInjury} model="forms.incidentDetails.eventLeadingToInjury" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    id="eventLeadingToInjury"
                                    type="text"
                                    placeholder="Enter event leading to injury..."
                                    model=".eventLeadingToInjury"
                                    defaultValue={editInstance && editInstance.props.eventLeadingToInjury}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="incidentSummary">Incident Summary:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.incidentSummary} model="forms.incidentDetails.incidentSummary" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="incidentSummary"
                                    className="form-control"
                                    placeholder="Enter incident summary..."
                                    type="text"
                                    model=".incidentSummary" 
                                    defaultValue={editInstance && editInstance.props.incidentSummary}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="incidentDetailedDesc">Incident Detailed Description:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.incidentDetailedDesc} model="forms.incidentDetails.incidentDetailedDesc" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    placeholder="Enter incident details..."
                                    model=".incidentDetailedDesc"
                                    textAreaRows={3}
                                    defaultValue={editInstance && editInstance.props.incidentDetailedDesc}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="immediateCorrectiveAction">Immediate Corrective Action:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.immediateCorrectiveAction} model="forms.incidentDetails.immediateCorrectiveAction" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="immediateCorrectiveAction"
                                    className="form-control"
                                    placeholder="Enter immediate corrective action..."
                                    type="text"
                                    model=".immediateCorrectiveAction"
                                    defaultValue={editInstance && editInstance.props.immediateCorrectiveAction}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={4} xs={12}> 
                            <Label sm={12} htmlFor="jobTask">Job Task:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.jobTask} model="forms.incidentDetails.jobTask" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="jobTask"
                                    className="form-control"
                                    placeholder="Enter job task..."
                                    type="text"
                                    model=".jobTask"
                                    defaultValue={editInstance && editInstance.props.jobTask}
                                />
                            </Col>
                        </Col>
                        <LocationInput
                            id="incident-investigative-form" 
                            companyName={company}
                            navigator={navigator}
                            model=".specifiedLocation"
                            defaultValue={editInstance && editInstance.props.location}
                        />
                        <Col sm={4} xs={12}> 
                            <Label sm={12} htmlFor="equipInvolved">Equip. Involved:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.equipInvolved} model="forms.incidentDetails.equipInvolved" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    id="equipInvolved"
                                    placeholder="Enter equip involved..."
                                    type="text"
                                    model=".equipInvolved"
                                    defaultValue={editInstance && editInstance.props.equipInvolved}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="weather">Weather:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.weather} model="forms.incidentDetails.weather" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    id="weather"
                                    className="form-control"
                                    placeholder="Enter weather condition..."
                                    type="text"
                                    model=".weather"
                                    defaultValue={editInstance && editInstance.props.weather}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="lighting">Lighting:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.lighting} model="forms.incidentDetails.lighting" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    id="lighting"
                                    placeholder="Enter lighting..."
                                    type="text"
                                    model=".lighting"
                                    defaultValue={editInstance && editInstance.props.lighting}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="wind">Wind:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.wind} model="forms.incidentDetails.wind" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    id="wind"
                                    placeholder="Enter wind..."
                                    type="text"
                                    model=".wind"
                                    defaultValue={editInstance && editInstance.props.wind}
                                />
                            </Col>
                        </Col>
                        <Col sm={3} xs={12}> 
                            <Label sm={12} htmlFor="seas">Seas:</Label>
                            <VoiceRecognition labelText="" defaultValue={forms.incidentDetails.seas} model="forms.incidentDetails.seas" />  
                            <Col sm={12} className="input">
                                <RRFInput
                                    className="form-control"
                                    id="seas"
                                    placeholder="Enter seas..."
                                    type="text"
                                    model=".seas"
                                    defaultValue={editInstance && editInstance.props.seas}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className="checkbox-row incident-radio-buttons show-grid">
                        <Col sm={4}>
                            <label
                                className="checkbox-label"
                                htmlFor="properPPE"
                            >
                                Proper PPE Used:
                            </label>
                            <div className="radio-buttons">
                                <label htmlFor="properPPE">Yes</label>
                                <RRFInput
                                    className="radio-input"
                                    id="properPPEYes"
                                    type="radio"
                                    model=".properPPE"
                                    radioValue="yes"
                                    radioName="properPPE"
                                    defaultValue={editInstance && editInstance.props.properPPE}
                                />
                                <label htmlFor="properPPEYes"></label>
                                <label htmlFor="properPPE">No</label>
                                <RRFInput
                                    className="radio-input"
                                    radioValue="no"
                                    id="properPPEno"
                                    type="radio"
                                    model=".properPPE"
                                    radioName="properPPE"
                                    defaultValue={editInstance && editInstance.props.properPPE}
                                />
                                <label htmlFor="properPPEno"></label>
                            </div>
                        </Col>
                        <Col sm={4} className="checkbox-center">
                            <label
                                className="checkbox-label"
                                htmlFor="selfSupervised"
                            >
                                Self-Supervised Activity:
                            </label>
                            <div className="radio-buttons">
                                <label htmlFor="selfSupervised">Yes</label>
                                <RRFInput
                                    className="radio-input"
                                    id="selfSupervisedYes"
                                    type="radio"
                                    model=".selfSupervised"
                                    radioValue="yes"
                                    radioName="selfSupervised"
                                    defaultValue={editInstance && editInstance.props.selfSupervised}
                                />
                                <label htmlFor="selfSupervisedYes"></label>
                                <label htmlFor="selfSupervised">No</label>
                                <RRFInput
                                    className="radio-input"
                                    radioValue="no"
                                    id="selfSupervisedNo"
                                    type="radio"
                                    model=".selfSupervised"
                                    radioName="selfSupervised"
                                    defaultValue={editInstance && editInstance.props.selfSupervised}
                                />
                                <label htmlFor="selfSupervisedNo"></label>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <label
                                className="checkbox-label"
                                htmlFor="incidentWitnessed"
                            >
                                Was Incident Witnessed:
                                </label>
                            <div className="radio-buttons">
                                <label htmlFor="incidentWitnessed">Yes</label>
                                <RRFInput
                                    className="radio-input"
                                    id="incidentWitnessedYes"
                                    type="radio"
                                    model=".incidentWitnessed"
                                    radioValue="yes"
                                    radioName="incidentWitnessed"
                                    defaultValue={editInstance && editInstance.props.incidentWitnessed}
                                />
                                <label htmlFor="incidentWitnessedYes"></label>
                                <label htmlFor="incidentWitnessed">No</label>
                                <RRFInput
                                    className="radio-input"
                                    radioValue="no"
                                    id="incidentWitnessedNo"
                                    type="radio"
                                    model=".incidentWitnessed"
                                    radioName="incidentWitnessed"
                                    defaultValue={editInstance && editInstance.props.incidentWitnessed}
                                />
                                <label htmlFor="incidentWitnessedNo"></label>
                            </div>
                        </Col>
                    </Row>
                </fieldset>
            </div >
        );
    }
}

export function mapStateToProps(state: any) {
    return {
        forms: state.forms,
        company: state.login.get('company')
    };
}

export const IncidentDetailsForm = connect<{}, {}, IIncidentDetailsFormProps>(mapStateToProps)(IncidentDetailsFormImpl);
