import * as React from 'react';
import { format } from 'date-fns';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IAnswer, IForms, IHistory } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { JSAModel } from '../../model/JSAModel';
import { ProjectModel } from '../../model/ProjectModel';
import { getAllGroups } from '../../services/groupService';
import { getFormData, submitJSAForm } from '../../services/jsaFormService';
import { getAllProjects } from '../../services/projectService';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { Title } from '../reusableComponents/Title';
import { JSAForm } from './JSAForm';
import './jsaForm.scss';

export interface IUpdateFormProps {
    userId: string;
    history?: IHistory;
    jsaInstance: JSAModel;
    match?: {
        params: {
            id: string;
        }
    };
    jsaId: string;
    forms: IForms;
}

export interface IUpdateFormState {
    currentDate: string;
    noOfRows: number[];
}

export class UpdateFormImpl extends React.PureComponent<IUpdateFormProps, IUpdateFormState> {
    constructor(props: IUpdateFormProps | Readonly<IUpdateFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'),
            noOfRows: [1]
        };
    }
    promise = async () => {
        /* if (JSAModel.list().length <= 1) {
            await getAllJSAForm();
        } */
        if (this.props.jsaId) {  
            await getFormData('jsa', this.props.jsaId);
        } 
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        }
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        return null;
    }

    renderJSAForm = () => {
        return (
            <Async
                identifier="GroupUpdateForm"
                promise={this.promise}
                error={<ErrorPage />}
                loader={<Loader />}
                content={
                    <JSAForm
                        onSubmit={this.handleSubmit}
                        instance={this.props.jsaInstance}
                    />
                }
            />
        );
    }

    handleSubmit = (userId: string, date: string, answerData: IAnswer[],
        group: string, project: string) => {
        const { history, forms } = this.props;
        return history && submitJSAForm(userId, date, answerData, history, group, project, forms);
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
                <Grid style={{ width: '100%' }} className="jsa-form" >
                    <Title text="JSA Form" />
                    <AlertModal id="jsa-form-modal" />
                    {this.renderJSAForm()}
                </Grid>
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const jsaId = ownProps.match.params.templateId;
    const jsaInstance = JSAModel.get(jsaId, state);
    return {
        userId: state.login.get('userId'), jsaId,
        jsaInstance,
        forms: state.forms
    };
}

export const TemplateForm = withRouter(connect<IUpdateFormProps, any, any>(mapStateToProps)(UpdateFormImpl));
