import { IHistory, IForms } from '../../../interfaces';
import { ListPage } from '../../reusableComponents/ListPage'; 
import * as React from 'react'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';  
import { BasePage } from '../../reusableComponents/BasePage'; 
import { ListItem } from './ListItem';
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader'; 
import { DeleteConfirmationModal } from '../../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal'; 
import { getAllLocations } from '../../../services/miscellaniousServices'; 
import { setLoading, setSuccess } from '../../../actions/loadingActions'; 
import { ILocationModelProps, LocationModel } from '../../../model/MiscellaneousModel';
import { UserModel } from '../../../model/UserModel';
declare var require: any;
const queryString = require('query-string');

export interface ILocationListPageProps {
    instance: ILocationModelProps[];
    history?: IHistory;
    match?: {
        params: {
            id: string;
        }
    }; 
    forms: IForms;
    userUID: string;
}

export interface ILocationListPageState {
    showDetails: boolean;
    formId: string;
}

export class LocationListPageImpl extends React.PureComponent<ILocationListPageProps, ILocationListPageState> {
    constructor(props: ILocationListPageProps | Readonly<ILocationListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }

    promise = async () => { 
        await this.getReportData();
    }

    getReportData = async () => {
        setLoading('AllListPage');   
        await getAllLocations();
        setSuccess('AllListPage'); 
    }
    renderFormList = () => {
        const { instance } = this.props; 
        return (
            <>  
                <div className="jsa-list-wrapper"> 
                    <ListPage
                        pageHeading={'Locations'}
                        instances={instance}
                        rowHeadings={['Region','Location Name', 'Address']}
                        listItemComponent={ListItem}
                        emptyInstancesMessage="No location found."
                        isSearchable={false}
                        searchableBy={['region', 'locationName' , 'address' ]}
                        searchPlaceHolder="Enter region, location name, address to search..."
                        sm={[3, 4, 4]}
                        currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    />  
                    { UserModel.checkUserAccess('group_add') && this.renderAddGroupButton() }
                </div> 
            </>
        );
    } 
    renderAddGroupButton = () => {  
        return (<button className="add-new-report"
            onClick={() => { this.props.history?.push('/location/create'); }}
        >
            +
        </button>);
    }

    render() {
        return (
            <BasePage className="min-wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="LocationListPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={ this.renderFormList()}
                />; 
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {  
    const instance =  LocationModel.list(); 
    let userUID = state.login.get('userUID');
    return { instance, forms: state.forms, userUID, };
}

export const LocationListPage = withRouter(connect< ILocationListPageProps, any, any>(mapStateToProps)(LocationListPageImpl));
