import { BasePage } from './BasePage';
import * as React from 'react';

export interface INotFoundPageProps {
}

export class NotFoundPage extends React.PureComponent<INotFoundPageProps, {}> {
    constructor(props: INotFoundPageProps | Readonly<INotFoundPageProps>) {
        super(props);
    }

    render() {
        return (
            <BasePage>
                <div style={errorPage} className="error-page">
                    <i style={errorIcon} className="fa fa-4x fa-exclamation-triangle" aria-hidden="true"></i>
                    <h3>404 Page Not Found...!!!</h3>
                </div>
            </BasePage>
        );
    }
}
const errorIcon: React.CSSProperties = {
    fontSize: '115px'
};

const errorPage: React.CSSProperties = {
    textAlign: 'center',
    padding: '138px'
};
