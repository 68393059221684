import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { IForms } from '../../interfaces';
import { SafetyWalkthroughReportChartData } from '../../model/SafetyWalkthroughModel';
import { showPrintPreviewModal } from '../../actions/modalActions';
import { getCloseCallReportSummaryData } from '../../services/attributeService';
import { printDetails } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { Loader } from '../reusableComponents/Loader';
import { PrintPreviewModal } from '../reusableComponents/ModalComponents/PrintPreviewModal';
import { CloseCallReportData } from './CloseCallReportData';

export interface ICloseCallSummaryProps {
    reportsData: SafetyWalkthroughReportChartData;
    forms?: IForms;
}

export interface ICloseCallSummaryPageState {
    mountPrintPreviewModal: boolean;
    reportsDataValue: SafetyWalkthroughReportChartData;
    filterBy: string;
}

export class CloseCallSummaryPageImpl extends React.Component<ICloseCallSummaryProps,
ICloseCallSummaryPageState> {
    constructor(props: ICloseCallSummaryProps) {
        super(props);
        let reportData: SafetyWalkthroughReportChartData = { reportCountData: {}, reportSumData: {} };
        this.state = { mountPrintPreviewModal: false, filterBy: '', reportsDataValue: reportData };
    }

    /* promise = async () => {
        const currentMonth = new Date().getMonth().toString();
        const year = new Date().getFullYear().toString();
        await getOfficeSafetyFormSummary('summary', 'na', 'na');
    } */
    async componentDidMount() { 
        /* await getCloseCallReportSummaryData('yearly', '0', '0').then(() => {
            // this.setState({reportsDataValue:this.props.reportsData});
        }); */
        await getCloseCallReportSummaryData();
    }

    getChildContext() {
        return { formModel: 'forms.oiReports' };
    }
    static childContextTypes = {
        formModel: PropTypes.string
    };
    ref: any; 
    renderContent = (id?: any) => {
        if(!this.props.reportsData) {
            return <Loader></Loader> 
        }
        return <div>
            <CloseCallReportData
                reportsData={this.props.reportsData}
                getRef={value => this.ref = value}
                id={id}
                isPrint={id ? true : false}
                getFilterBy={filterBy => { this.setState({ filterBy }); }}
                filterBy={this.state.filterBy}
                forms={this.props.forms}
            /> 
        </div>;
    } 
    exportCharts = (val: any) => {
        printDetails(this.ref, 'SafetyWalkthroughReports');
    } 
    renderPrintPreviewModal = () => {
        this.setState({
            mountPrintPreviewModal: true
        });
        showPrintPreviewModal('office-safety-report');
        return;
    } 
    render() {
        return <BasePage className="reports-page office-safety-summary" >
            {this.state.mountPrintPreviewModal ? <PrintPreviewModal
                id="office-safety-report"
                ref="printPreview"
                className="oi-report-data"
                fileName="OfficeSafetyReports"
                onClickYes={this.exportCharts}
                style={{ boxShadow: 'none' }}
            >
                {this.renderContent('print')}
            </PrintPreviewModal> : ''}
            {this.renderContent()} 
        </BasePage>;
    }
} 
export function mapStateToProps(state: any) {
    return {
        reportsData: state.report.get('reportChartData'), forms: state.forms,
    };
}  
export const CloseCallSummaryPage = connect<{}, {}, ICloseCallSummaryProps>(mapStateToProps)(CloseCallSummaryPageImpl);
