import { dispatch } from '../utils/generalUtils';
import {
    SAVE_ATTRIBUTE_DATA,
    DELETE_ATTRIBUTE_DATA,
    INITALIZE_ATTRIBUTE_DATA,
    EDIT_ATTRIBUTE_DATA,
    NAVIGATE_ADD_ATTRIBUTE,
    GET_SAFETY_COMMENT_LIST,
    EMPTY_ATTRIBUTE_LIST 
} from '../constants/actions';
import { IAction } from '../interfaces';
import { IAttributeDataState } from '../components/Micellaneous/SafetyWalkAssesment/AddAttribute';
import { deleteAttribute } from '../services/attributeService';
import { SafetyWalkthroughCommentListModel } from '../model/SafetyWalkthroughModel'; 

interface IAttributeAction extends IAction {
    attribute: IAttributeDataState;
}

interface IAttributDeleteAction extends IAction {
    attributeList: any[];
}

interface IAttributeEditAction extends IAction {
    isEditAttribute: boolean;
    attribute: IAttributeDataState;
}
interface IAddAttributeNavigationAction extends IAction {
    formType: string;
}
/* interface ISafetyCommentAction extends IAction {
    commentList: SafetyWalkthroughCommentListModel;
} */

export function getCommentList(commentList: SafetyWalkthroughCommentListModel) {
    return ({
        type: GET_SAFETY_COMMENT_LIST,
        commentList
    });
}

export function navigateAddAttribute(formType: string): IAddAttributeNavigationAction {
    return ({
        type: NAVIGATE_ADD_ATTRIBUTE,
        formType
    });
}

export function saveAttribute(attribute: any): IAttributeAction {
    return ({
        type: SAVE_ATTRIBUTE_DATA,
        attribute
    });
} 
export function deleteAttributeAction(attributeList: any[], attribute: any, formType: string): IAttributDeleteAction {
    let deletingAttribute = { formType: formType, mainAttr: attribute.attribute };
    deleteAttribute(deletingAttribute);
    return ({
        type: DELETE_ATTRIBUTE_DATA,
        attributeList
    });
}

export function refreshAttributeList(attributeList: any[]): IAttributDeleteAction {
    return ({
        type: INITALIZE_ATTRIBUTE_DATA,
        attributeList
    });
}

export function editAttribute(isEditAttribute: boolean, attribute: IAttributeDataState): IAttributeEditAction {
    return ({
        type: EDIT_ATTRIBUTE_DATA,
        isEditAttribute,
        attribute
    });
}

export function emptyAttributeList(attributeName: any) {
    return dispatch({
        type: EMPTY_ATTRIBUTE_LIST,
    });
}
