import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { IForms } from '../../../interfaces';
import { dispatch, uuid } from '../../../utils/generalUtils';
import './fileInput.scss';
import { FILESALLOWEDEXE } from '../../../constants/general'; 
import { OverlayTrigger, Tooltip } from 'react-bootstrap';   
import { S3_URL } from '../../../constants/urls'; 
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';

export interface IAttachementInputProps {
    id: string;
    multiple?: boolean;
    style?: React.CSSProperties;
    accept?: string;
    model: string;
    onChange?: (event: EventTarget) => void;
    imagesArray?: string[];
    defaultValue?: any;
    forms?: IForms | undefined;
    displayUploadedFiles?: boolean;
    restrictMultipleImage?: boolean;
    uploadedImages?: string[];
    noNeedAttachButton?: boolean | false;
    emptyMessage?: string;
}

export interface IAttachementInputState {
    /* imagesArray: string[]; 
    oldUploads: string[];
    uploadedImage?: any; */ 
    uploadedFiles: File[];
    uploadedFileNames: any[];
    oldFileNames: string[];
    deletedFileNames: string[];
    editFileNameIndex?: number;
    editFileName?: string;
    editedFileNames?: {
        name: string;
        index: number;
    }[];   
}

export class AttachementInputImpl extends React.Component<IAttachementInputProps, IAttachementInputState> {
    constructor(props: IAttachementInputProps | Readonly<IAttachementInputProps>) {
        super(props );
        const { uploadedImages } = this.props; 
        let fileInfo = uploadedImages;
        let fileNames: Array<any> = [];
        if (fileInfo && fileInfo.length > 0) {
            for (let i = 0; i < fileInfo.length; i++) {  
                let file = fileInfo[i].split('/'); 
                fileNames.push(file[file.length-1]);
            }
        }  
        this.state = { uploadedFiles: [], uploadedFileNames: fileNames, oldFileNames: fileNames, deletedFileNames: [], };
        return; 
    } 
    invalidFile: number = 0;

    static contextTypes = {
        formModel: PropTypes.string
    };
    fileName: string = '';
    handleFileUpload = ({ target: { files } }: any) => {
        const { uploadedFiles, uploadedFileNames, oldFileNames, deletedFileNames } = this.state; 
        const { context: { formModel }, props: { model } } = this;
        this.invalidFile = 0;
        let filesData: File[] = [];
        let fileNames:Array<any> = [];
        if (oldFileNames.length > 0) { 
            for (let i = 0; i < oldFileNames.length; i++) { 
                let file = oldFileNames[i].split('/'); 
                if(deletedFileNames.indexOf(file[file.length-1])===-1 ) { 
                    fileNames.push(file[file.length-1]);
                }
            }
        }
        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) { 
                filesData.push(uploadedFiles[i]);
                let fileDet = uploadedFiles[i].name.split('.');  
                const fileExtension = fileDet[fileDet.length - 1];
                //fileNames.push(uploadedFileNames[i]);
                fileNames.push(uploadedFiles[i].name.split('.')[0] + '~~' + uploadedFiles[i].lastModified + '.' + fileExtension);
            }
        }
        for (let i = 0; i < files.length; i++) {
            if (uploadedFileNames.indexOf(files[i].name) < 0) {
                filesData.push(files[i]);  
                const name = uuid();  
                let filenameDet = files[i]['name'].split('.');
                let fileName = `${name}.${filenameDet[filenameDet.length-1].toLowerCase()}`;  
                fileNames.push(fileName); 
                const fileReader = new FileReader();
                //fileReader.onload = this.readFile;  
                fileReader.onload = function (e: any) {
                    dispatch(actions.push(`${formModel}${model}`, { name: fileName, file: e.target.result })); 
                }
                fileReader.readAsDataURL(files[i]); 
            }
        } 
        this.setState({
            uploadedFiles: filesData,
            uploadedFileNames: fileNames
        });
    } 
    readFile = (e: any) => {   
        const { context: { formModel }, props: { model } } = this; 
        dispatch(actions.push(`${formModel}${model}`, { name: this.fileName, file: e.target.result })); 
    }
    removeItem = (index: number) => { 
        const { context: { formModel } } = this;
        const allFileNames = this.state.uploadedFileNames; 
        const oldFileNames = this.state.oldFileNames; 
        let deletedFileNames: any = this.state.deletedFileNames; 
        let deletedURL: any = []; 
        if(oldFileNames.indexOf(allFileNames[index])!==-1) {
            deletedFileNames = Object.assign([], deletedFileNames);
            deletedFileNames.push(allFileNames[index]);
            //deletedFileNames.push(allFileNames[index]);
            allFileNames.splice(index, 1);
            this.setState({
                uploadedFileNames: allFileNames,
                deletedFileNames: deletedFileNames
            }); 
            deletedFileNames.map((item: any) => {
                deletedURL.push(S3_URL +'/attachments/' + item );
            })
            dispatch(actions.change(`${formModel}.deletedFiles`, deletedURL)); 
            return;
        }
        const allFiles: File[] = [];  
        let start = (oldFileNames.length-deletedFileNames.length); 
        let filesRemove = (index - start);  
        for (let i = 0; i < this.state.uploadedFiles.length; i++) { 
            if (i !== filesRemove) {  
                allFiles.push(this.state.uploadedFiles[i]);
            }
        }  
        allFileNames.splice(index, 1);  
        this.setState({
            uploadedFileNames: allFileNames,
            uploadedFiles: allFiles,
            deletedFileNames: deletedFileNames
        }); 
        
        deletedFileNames.map((item: any) => {
            deletedURL.push(S3_URL +'/attachments/' + item );
        })
        dispatch(actions.change(`${formModel}.deletedFiles`, deletedURL)); 
    } 
    renderListFiles = (index: number, fileName: string) => {  
        const { oldFileNames, uploadedFiles, deletedFileNames } = this.state; 
        const { props: {noNeedAttachButton } } = this;
        const fileSplit = fileName.split('.'); 
        let imageThumb = '';
        let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
        switch(fileSplit[fileSplit.length-1].toLowerCase()) {
            case 'pdf': imageThumb = pdfThumb; break;
            case 'doc': case 'docx': imageThumb = wordThumb; break; 
            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
            case 'txt': imageThumb = textThumb; break;
            case 'mp4': imageThumb = videoThumb; break; 
            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ 
                if(oldFileNames.indexOf(fileName)!==-1) {
                    imageThumb = S3_URL +'/attachments/' + fileName;  
                } else {
                    let totalIndex = (oldFileNames.length-deletedFileNames.length);  
                    let currentImageIndex = index - totalIndex; 
                    if(uploadedFiles[currentImageIndex]) {
                        imageThumb = URL.createObjectURL(uploadedFiles[currentImageIndex]);
                    }
                }
                /* if(!imageThumb && (formModel=='forms.auditForm' || formModel=='forms.riskAssessmentForm' ) ) {
                    imageThumb = imageThumb = fileName;  
                } */
                break;
            default: imageThumb = ''; this.invalidFile++; break; 
        } 
       /*  if(this.invalidFile>0) {
            showAlert(`Sorry, Some file(s) CANNOT be upload.`, 'file-danger-alert-invalidexe', 'danger',null,true,4500);   
            return;
        } */ 

        return <div key={index} style={displayImage} className="image-wrapper">
                {!noNeedAttachButton && <input
                    type="button"
                    value="&times;"
                    className="delete-image"
                    onClick={() => this.removeItem(index) }
                /> }
                <OverlayTrigger key="top" placement="top" overlay={ <Tooltip id={tooltip}> <strong>{tooltip}</strong> </Tooltip> } >
                    <img
                        height="75px"
                        width="90px"
                        style={{ padding: '5px' }}
                        className="thumbnail-image" 
                        src={imageThumb} alt="SafeConnect"
                        onClick={() => this.openFile(S3_URL + '/attachments/' + fileName)}
                    />
                </OverlayTrigger>
            </div> ;  
    }  
    openFile = (imageObj: string ) => {
        window.open(imageObj, "_blank");
    } 
    render() {
        const { emptyMessage, noNeedAttachButton } = this.props ;  
        return ( 
            <> <div className="file-upload col-sm-12" style={{ display: 'inline-block' }} >
                {!noNeedAttachButton && 
                    <><input
                        id="files"
                        type="file"
                        multiple
                        style={fileUpload}
                        onChange={this.handleFileUpload} 
                        accept={FILESALLOWEDEXE.join(', ')} 
                    />
                    <label
                        htmlFor="files"
                        style={{ padding: '2px 15px', marginLeft: '15px', }}
                        className="upload-image">
                        Select File(s)
                    </label></>
                } 
            { (this.state.uploadedFileNames.length > 0 || (this.props.uploadedImages || []).length>0 ) && 
                <div className="images"> 
                    { this.state.uploadedFileNames.map((fileName, index) => { 
                        return this.renderListFiles(index, fileName); 
                        })
                    } 
                </div>
            }
            
            </div> 
            {noNeedAttachButton && this.state.uploadedFileNames.length<1 && 
                <div style={{textAlign: 'center', color:'white'}}>
                    {emptyMessage || 'No attachments found..'}
                </div>
            }</>
        );
    }
}

export function mapStateToProps( state: any ) { 
    return {
        forms: state.forms, 
    }; 
}

export const AttachementInput = connect<{}, {}, IAttachementInputProps>(mapStateToProps)(AttachementInputImpl);

const fileUpload: React.CSSProperties = {
    height: '38px',
    width: '136px',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    /* left: '35%', */
};

const displayImage: React.CSSProperties = {
    width: '90px',
    height: '75px',
    margin: '0 5px',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.1)',cursor: 'pointer',
}; 
