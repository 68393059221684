import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends'; 
import { IIncidentSummary, IFormMonths, IChartValue, IForms } from '../../interfaces';
import { Title } from '../reusableComponents/Title'; 
import { applyMonthlyFilter, applyYearlyFilter, getIncidentNotificationFormSummaryData, } from '../../services/incidentNotificationFormService';
import * as React from 'react';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Row, Col, Container as Grid } from 'react-bootstrap';
import { Alert } from '../Alert/index';
import { Card } from '../reusableComponents/Card';
import { MonthlyFilter } from '../reusableComponents/FilterComponents/MonthlyFilter';
import { YearlyFilter } from '../reusableComponents/FilterComponents/YearlyFilter';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { Cone } from '../reusableComponents/ChartComponents/Cone';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar, Cell } from 'recharts';
import { Label } from '../reusableComponents/FormComponents/Label';
import { ActivityChart } from '../reusableComponents/ChartComponents/ActivityChart';
import { Async } from '../reusableComponents/Async';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import totalReportsAvatar from '../../images/totalReports.png';
import lastIncidentIcon from '../../images/lastIncident.png';
import lastRecordedIcon from '../../images/lastRecorded.png'; 
/* import { printLogos } from '../../utils/generalUtils'; */
import { PrintLogos } from '../reusableComponents/PrintLogos';  

export interface IIncidentSummaryDataProps {
    barChartData: {
        fatality: number;
        firstAid: number;
        medicalTreatment: number;
        noTreat: number;
        seriousNearMiss: number;
        nearMiss: number;
        lostTime: number;
    };
    pieChartData: {
        Head: number;
        Hand: number;
        Foot: number;
        Chest: number;
        Other: number;
        Limb: number;
    };
    coneData: {
        formMonths: IFormMonths;
        total: number;
        fatality: number;
        firstAid: number;
        medicalTreatment: number;
        noTreat: number;
        seriousNearMiss: number;
        nearMiss: number;
        lostTime: number;
    };
    total: number;
    showCheckBox: boolean;
    getRef?: (value: any) => void;
    isAnimationActive: boolean;
    id: string;
    concernTypes: {
        name: string;
        value: number;
        Safety?: number,
        Health?: number,
        Environmental?: number,
        Security?: number,
        Other?: number
    }[];
    activityData: any;
    lastRecorded: number;
    lastSeverityRecorded: number;
    forms?: IForms;
}

export interface IIncidentSummaryDataState {
    showMonthlyFilter: boolean;
    showYearlyFilter: boolean;
    showPieChart: boolean;
    visibility: boolean;
    activityName: string;
    pieChartData?: Object;
    legends: string[];
    data: IChartValue[];
    showCone: boolean;
    showBarChart: boolean;
    totalFormsSubmitted: number;
}

export class IncidentSummaryData extends React.PureComponent<IIncidentSummaryDataProps, IIncidentSummaryDataState> {
    constructor(props: IIncidentSummaryDataProps | Readonly<IIncidentSummaryDataProps>) {
        super(props);
        this.state = {
            showMonthlyFilter: false, showYearlyFilter: false,
            showBarChart: true, showCone: true, showPieChart: true,
            visibility: false, activityName: '', legends: [], data: [],
            totalFormsSubmitted: 0
        };
    }

    ref: any;
     
    componentWillMount() {
        setSuccess('ReportsPage');
    }

    promise() {
        return;
    }
    
    handleFilterSelect = async (value: string) => {
        if (value === 'yearly') {
            this.setState({
                showYearlyFilter: true,
                showMonthlyFilter: false
            });
            return;
        }
        if (value === 'monthly') {
            this.setState({
                showYearlyFilter: false,
                showMonthlyFilter: true
            });
            return;
        }
        this.setState({
            showYearlyFilter: false,
            showMonthlyFilter: false
        }); 
        getIncidentNotificationFormSummaryData();
    }

    renderYearlyFilter = () => {
        const { showYearlyFilter } = this.state;
        if (!showYearlyFilter) {
            return;
        }
        return <YearlyFilter onApply={(year) => {
            setLoading('ReportsPage');
            applyYearlyFilter(year);
        }} />;
    }

    renderMonthlyFilter = () => {
        const { showMonthlyFilter } = this.state;
        if (!showMonthlyFilter) {
            return;
        }
        return <MonthlyFilter onApply={(month, year) => {
            setLoading('ReportsPage');
            applyMonthlyFilter(month, year);
        }
        } />;
    }

    applyCommonFilter = async () => {
        setLoading('ReportsPage'); 
        await getIncidentNotificationFormSummaryData(this.props.forms); 
        setSuccess('ReportsPage');
    }

    renderActivityPopover = (obj: any) => {
        const { concernTypes, activityData } = this.props;
        let legends: Array<any> = [];
        let data: Array<any> = [];
        const activities = activityData[obj.name];
        for (let key in activities) {
            legends.push(key);
        }
        for (let key in activities) {
            data.push({
                name: key, value: activities[key]
            });
        }
        let totalFormsSubmitted = 0;
        concernTypes.forEach(concernData => {
            if (concernData && concernData.name === obj.name) {
                totalFormsSubmitted = concernData.value;
            }
        });
        this.setState({
            visibility: true,
            pieChartData: obj,
            legends,
            data,
            totalFormsSubmitted
        });
    }

    renderConcernChart = () => {
        const { concernTypes, isAnimationActive } = this.props;
        const legendsName: (string)[] = [];
        concernTypes.forEach(concern => {
            legendsName.push(concern.name);
        });
        return (
            <div id="pie-chart"
                style={{ height: '100%', width: '100%', margin: '20px auto', backgroundColor: 'white' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Types Of Observations
                    </h4>
                    <Row className="show-grid" >
                        <Col sm={7} xs={12} id="oi-pie-chart" className="pie circle" style={{ height: '253px' }}>
                            <ActivityChart
                                visibility={this.state.visibility}
                                data={this.state.data}
                                legends={this.state.legends}
                                obj={this.state.pieChartData || []}
                                chartType="pie"
                                totalFormsSubmitted={this.state.totalFormsSubmitted}
                            />
                            <PieChart
                                data={concernTypes || []}
                                innerRadius={60}
                                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                    'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                                paddingAngle={1}
                                customTootip={true}
                                onClick={this.renderActivityPopover}
                                isAnimationActive={isAnimationActive}
                            />
                        </Col>
                        <PieChartLegends sm={5} xs={12}
                            legendNames={legendsName || []}
                            legendColors={['FFBECE', 'B0D9FF', 'FFF6BE ', 'A9DEDC', 'F2BD91', 'C0F5FA',
                                'C9FF9C', 'B3D9AD', 'AFA9FC', 'EDA28E']}
                        />
                    </Row>
                </Card>
            </div>
        );
    }

    renderPieChart = () => {
        const { pieChartData: { Head, Hand, Foot, Chest, Other, Limb, }, isAnimationActive } = this.props;
        const data = [{ name: 'Hand', value: Hand },
        { name: 'Head', value: Head }, { name: 'Limb', value: Limb },
        { name: 'Foot', value: Foot }, { name: 'Chest', value: Chest }, { name: 'Other', value: Other }];
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Injured Body Parts
                </h4>
                    <Row className="show-grid ">
                        <Col sm={7} className="pie circle" style={{ height: '253px' }}>
                            <PieChart
                                isAnimationActive={isAnimationActive}
                                data={data}
                                innerRadius={60}
                                colorArray={['FFBECE', 'A9DEDC', 'B0D9FF', 'FFF6BE', 'E7BFFF', 'FFDDBE']}
                                paddingAngle={1}
                            />
                        </Col>
                        <PieChartLegends sm={5} xs={12}
                            legendNames={['Hand', 'Head', 'Limb', 'Foot', 'Chest', 'Other']}
                            legendColors={['FFBECE', 'A9DEDC', 'B0D9FF',
                                'FFF6BE', 'E7BFFF', 'FFDDBE']}
                        />
                    </Row>
                </Card>
            </div>
        );
    }

    renderBarChart = () => {
        const { barChartData: { fatality, firstAid, lostTime,
            medicalTreatment, nearMiss, noTreat, seriousNearMiss } } = this.props;
        const data = [
            { name: 'FAT', value: fatality },
            { name: 'LTI', value: lostTime },
            { name: 'MTI', value: medicalTreatment },
            { name: 'SNM', value: seriousNearMiss },
            { name: 'FA', value: firstAid },
            { name: 'NT', value: noTreat },
            { name: 'NM', value: nearMiss }];
        const colorArray = ['FFBECE', 'A9DEDC', 'B0D9FF', 'FFF6BE', 'E7BFFF', 'FFDDBE', 'B6E6A6'];
        return (
            <div style={{ height: '100%', width: '100%', margin: '20px auto' }}>
                <Card width="100%" className="chart">
                    <h4
                        style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                    >
                        Incident Types
                </h4>
                    <Row className="show-grid ">
                        <Col sm={12} className="bar-chart" style={{ height: '370px' }}>
                            <ResponsiveContainer>
                                <BarChart
                                    width={600}
                                    height={300}
                                    data={data}
                                    /* isAnimationActive={isAnimationActive} */
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    layout="vertical"
                                >
                                    <XAxis type="number" allowDecimals={false}/>
                                    <YAxis type="category" dataKey="name" allowDecimals={false}/>
                                    <CartesianGrid strokeDasharray="3" />
                                    <Tooltip />
                                    <Bar dataKey="value">
                                        {colorArray.map((colorName, index) => {
                                            return <Cell key={index} fill={`#${colorName}`} />;
                                        })}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

    renderCone = () => {
        const { coneData: { fatality, firstAid, lostTime,
            medicalTreatment, nearMiss, noTreat, seriousNearMiss } } = this.props;
        const data: IIncidentSummary = {
            fat: fatality,
            lti: lostTime,
            mti: medicalTreatment,
            snm: seriousNearMiss,
            fa: firstAid,
            nt: noTreat,
            nm: nearMiss
        };
        const colorArray = ['f35454', 'ff7979', 'ffeea8', 'd1e88d',
            '75c590', '57b7e0', '3596ff'];
        return <div id="cone" style={{ height: '410px', width: '100%', margin: '20px auto' }}>
            <Card width="100%" height="410px" className="cone-card">
                <h4
                    style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}
                >
                    Incident Types
            </h4>
                <Cone
                    layerData={data}
                    colorArray={colorArray}
                />
            </Card>
        </div>;
    }

    renderFilters = () => {
        return <div className="filter-charts" style={{ padding: '20px' }}>
            <Alert style={{ height: '40px' }} id="incident-report-summary" className="danger-alert" />
            <Row className="show-grid filter">
                <Col sm={12} className="input" style={{ padding: '0px' }}>
                    <RRFInput
                        onSelect={this.handleFilterSelect}
                        type="dropdown"
                        menuItems={[{ value: 'cumulative', label: 'Cumulative' },
                        { value: 'yearly', label: 'Yearly' },
                        { value: 'monthly', label: 'Monthly' }]}
                        defaultValue="cumulative"
                        model=".filterType"
                    />
                </Col>
            </Row>
            {this.renderMonthlyFilter()}
            {this.renderYearlyFilter()}
        </div>;
    }

    renderIncidentReportCard = () => {
        const { total, lastSeverityRecorded, lastRecorded } = this.props; 
        return <Grid style={{ width: '100%', padding: '10px' }}>
            <Row className="show-grid ">
                <Col sm={4} className="card-one" style={{ textAlign: 'center' }}>
                    <Card className="oi-report-card">
                        <img src={lastRecordedIcon} alt="SafeConnect"/>
                        <h4 style={{ margin: '8px 0px 0px', textAlign: 'center' }}>Days Since Last Recordable</h4>
                        <p style={{ textAlign: 'center' }} > {lastSeverityRecorded}</p>
                    </Card>
                </Col>
                <Col sm={4} className="card-one" style={{ textAlign: 'center' }}>
                    <Card className="oi-report-card">
                        <img src={lastIncidentIcon} alt="SafeConnect"/>
                        <h4 style={{ margin: '8px 0px 0px', textAlign: 'center' }}>Days Since Last Incident</h4>
                        <p style={{ textAlign: 'center' }} > {lastRecorded}</p>
                    </Card>
                </Col>
                <Col sm={4} className="total-reports" style={{ textAlign: 'center' }}>
                    <Card className="oi-report-card">
                        <img src={totalReportsAvatar} alt="SafeConnect" />
                        <h4 style={{ margin: '15px 0px 0px 0px' }}>Total Reports</h4>
                        <p>{total}</p>
                    </Card>
                </Col>
            </Row>
        </Grid >;
    }

    handleCone = (value: any) => {
        this.setState({
            showCone: value
        });
    }

    handlePieChart = (value: any) => {
        this.setState({
            showPieChart: value
        });
    }

    handleBarChart = (value: any) => {
        this.setState({
            showBarChart: value
        });
    }

    renderCheckBox = () => {
        const { } = this.state;
        return <div className="print-filter-wrapper">
            <div className="filter-title">Please select charts to export:</div>
            <div className="print-data-filter">
                <div className="chart-filter">
                    <Label sm={8} htmlFor="incidentTypes">&nbsp;Incident Types</Label>
                    <RRFInput
                        type="checkbox"
                        model=".incidentTypes"
                        id="incidentTypes"
                        onChange={this.handleCone}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <Label sm={8} htmlFor="observationInfluence">&nbsp;Observation Influence</Label>
                    <RRFInput
                        type="checkbox"
                        id="observationInfluence"
                        model=".observationInfluence"
                        onChange={this.handleBarChart}
                        defaultValue="checked"
                    />
                </div>
                <div className="chart-filter">
                    <Label sm={8} htmlFor="injuredBodyParts">&nbsp;Injured Body Parts</Label>
                    <RRFInput
                        type="checkbox"
                        id="injuredBodyParts"
                        model=".injuredBodyParts"
                        onChange={this.handlePieChart}
                        defaultValue="checked"
                    />
                </div>
            </div>
        </div >;
    }
    renderContent = (isLoading: boolean) => {
        const { state: { showPieChart, showCone, showBarChart }, props: { getRef, showCheckBox, forms } } = this;
        return (
            <div className="incident-report-summary" id="summary-reports">
                {showCheckBox ? this.renderCheckBox() : null}
                <div
                    id={this.props.id}
                    className="summary-reports"
                    ref={value => { getRef ? getRef(value) : this.ref = value; }}>
                    {isLoading ? <SubmissionLoader /> : ''} 
                    <PrintLogos/>
                    <Title text="Incident Cumulative Report Summary" />
                    {this.renderIncidentReportCard()}
                    {/* {this.renderFilters()} */} 
                    <CommonFilter onApply={this.applyCommonFilter} forms={forms}/>
                    <Row>
                        <Col sm={6} xs={12} className="report-chart" >
                            {showCone ? this.renderCone() : null}
                        </Col>
                        <Col sm={6} xs={12} className="report-chart">
                            {showBarChart ? this.renderBarChart() : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} ></Col>
                        <Col sm={6} xs={12} className="report-chart" >
                            {showPieChart ? this.renderPieChart() : null}
                        </Col>
                        {/* <Col sm={6} xs={12} className="report-chart">
                            {showPieChart ? this.renderConcernChart() : null}
                        </Col> */}
                        <Col sm={3} ></Col>
                    </Row>
                </div>
            </div >
        );
    }
    render() {
        return <Async
            identifier="ReportsPage"
            promise={this.promise}
            error={<div></div>}
            loader={this.renderContent(true)}
            content={this.renderContent(false)}
        />;
    }
}
