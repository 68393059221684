import { Button } from '../reusableComponents/FormComponents/Button';
import { format } from 'date-fns';
import { IHistory } from '../../interfaces';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { getEventCategory, getProbabilityCategory, KeyValue, ssriResultTable } from '../../utils/generalUtils';
import { BasePage } from '../reusableComponents/BasePage';
import { Title } from '../reusableComponents/Title'; 
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';  
import { DetailsPageWrapper } from '../reusableComponents/DetailsPageWrapper'; 
import { Label } from '../reusableComponents/FormComponents/Label';
import './riskAssessment.scss';
import { RiskAssessmentModel } from '../../model/RiskAssessmentModel';
import { getRiskAssessmentForm } from '../../services/riskAssessmentService';
import { getAllFollowUpForms } from '../../services/closeCallService'; 
import { followUpListProps } from '../../model/MechModel';   
import { AttachementInput } from '../reusableComponents/FormComponents/AttachementInput';

export interface IDetailsPageProps {
    match?: {
        params: {
            id: string;
        }
    };
    editId: string;
    history?: IHistory; 
    instance: RiskAssessmentModel;
    followUpInstances?: followUpListProps[]; 
}

export class DetailsPageImpl extends React.PureComponent<IDetailsPageProps, {}> {
    constructor(props: IDetailsPageProps | Readonly<IDetailsPageProps>) {
        super(props);
    } 

    promise = async () => { 
        const { editId, instance } = this.props;
        if(!instance) {
            await getRiskAssessmentForm(editId);
        }
        if(instance) {
            await getAllFollowUpForms(instance.props.id);
        } 
        return null;
    }
    async componentWillReceiveProps(nextProps: any) { 
        const { editId } = this.props;
        if(this.props.editId !== nextProps.editId) {
            await getAllFollowUpForms(editId);
        }
    }
    renderEventAssessment = () => {
        const { eventDescription, eventCategory, probabilityCategory } = this.props.instance.props;
        if(!eventDescription) {
            return;
        } 
        return (eventDescription || []).map((description, key) => { 
            return (
                <Row className="show-grid assessment-details"> 
                    <Col sm={2} style={{ textAlign: 'center'  }}> 
                        <span >{key+1}</span>
                    </Col>
                    <Col sm={6} > 
                        <span>{description}</span>
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center'  }}> 
                        <span>{eventCategory[key]}</span>
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center'  }}> 
                        <span>{getProbabilityCategory(probabilityCategory[key])[2]}</span>
                    </Col>
                </Row>  
            )
        });  
    }
     
 
    renderContent = () => {   
        const { instance } = this.props; 
        return <div>
            <Title text={`Risk Assessment Report for ${instance.props.typeOfWork}`}></Title>
            <KeyValue label="Date">{instance.props.dateOfIncident}</KeyValue> 
            <KeyValue label="Location">{instance.props.locationName}</KeyValue>
            <KeyValue label="Type of Work">{instance.props.typeOfWork}</KeyValue>
            <KeyValue label="Work To Perform">{instance.props.workToPerform}</KeyValue>
            <KeyValue label="Team Name">{instance.props.projectName}</KeyValue>
            <KeyValue label="Group Name">{instance.props.groupName}</KeyValue> 
            <div style={{fontSize: '18px', textAlign: 'center',  marginTop:'10px' }} className="color-gray">Risk Assessment</div>
            <span className="border"></span>
            <Row className="show-grid table">
                <Col className="form-title yellow-center" sm={2}>#</Col>
                <Col className="form-title yellow-center" sm={6}>Description</Col>
                <Col className="form-title yellow-center" sm={2}>Event<br/>Category</Col> 
                <Col className="form-title yellow-center" sm={2}>Probability<br/>Category</Col> 
            </Row > 
            {this.renderEventAssessment()} 
            <Row>
                <Col sm={8} xs={4} style={{textAlign: 'right',}}> 
                    <Label style={{textAlign:'right'}} className="color-gray">Average</Label>
                </Col>
                <Col sm={2} xs={4} style={{textAlign: 'center',}}> 
                    <span style={{ background: '#817f84', color: 'white', padding: '3px 25px 3px 25px', borderRadius: '7px', fontWeight: 600, }} className="avg-score">{getEventCategory(instance.props.averageEventCategory)[1]}</span>
                </Col>
                <Col sm={2} xs={4} style={{textAlign: 'center',}}> 
                    <span style={{ background: '#817f84', color: 'white', padding: '3px 25px 3px 25px', borderRadius: '7px', fontWeight: 600, }} className="avg-score">{getProbabilityCategory(instance.props.averageProbabilityCategory)[2]}</span>
                </Col>
            </Row> 
            <Row className="analysis-result" style={{margin: '20px', textAlign:'center'}}> 
                <Col sm={12}  > 
                    <label className="result-label color-gray" style={{color: 'yellow'}}>Safeguards & Security Risk Index</label>
                </Col>
                <Col sm={{  span:'12'}} style={{textAlign: 'center'}}>
                    <label className="result-label">Risk Matrix Result:</label> <label className={`result-no res${instance.props.SSRI}`}>{instance.props.SSRI}</label>
                
                    <label className="result-label" style={{marginLeft: '20px'}}>Risk Matrix Category:</label> <label className={`result-no res${instance.props.SSRI}`}>{getEventCategory(instance.props.averageEventCategory)[1]}{getProbabilityCategory(instance.props.averageProbabilityCategory)[2]}</label>
                </Col> 
            </Row>
            <Row className="analysis-result-content">
                { ssriResultTable(parseInt(instance.props.averageEventCategory), parseInt(instance.props.averageProbabilityCategory)) }
            </Row>
            
            <div style={{fontSize: '18px', textAlign: 'center', marginTop:'10px' }} className="color-gray">Follow-up ({instance.props.totalClosedFollowUp}/{instance.props.totalFollowUp})</div>
            <span className="border"></span>
            <Row className="show-grid table">
                <Col className="form-title yellow-center" sm={1}>#</Col>
                <Col className="form-title yellow-center" sm={4}>Action</Col>
                <Col className="form-title yellow-center" sm={3}>Assigned To</Col> 
                <Col className="form-title yellow-center" sm={2}>Target Date</Col> 
                <Col className="form-title yellow-center" sm={2}>Status</Col> 
            </Row >
            {this.renderFollowUpListItems()} 
            <div style={{fontSize: '18px', textAlign: 'center', marginTop:'30px' }}  className="color-gray">Follow-up Risk Assessment ({ (instance?.props.associatedChildForms && instance.props.associatedChildForms.length) || 0})</div>
            <span className="border"></span>
            <Row className="show-grid table" style={{marginTop: '10px'}}>
                <Col className="form-title yellow-center" sm={1}>#</Col>
                <Col className="form-title yellow-center" sm={3}>Date</Col>
                <Col className="form-title yellow-center" sm={3}>Risk Matrix No</Col> 
                <Col className="form-title yellow-center" sm={3}>Follow-up Status</Col>  
            </Row >
            {this.renderRiskAssessmentFollowUpListItems()} 
            <Row>
                <Col sm={12} className=" " style={{textAlign:'center', margin:'20px 0 20px 0'}}> 
                    <Label className="radio-label color-gray" required={false} sm={6} htmlFor={`reassessmentTitle`} style={{fontSize: '20px'}}> Reassessment detail: <label className="color-black" style={{color: 'white'}}>{instance.props.reassessmentOption==='reasssessment'?'Yes':'Continue to approval'}</label> </Label>  
                </Col>
            </Row>
            <Row>
            <div style={{fontSize: '18px', textAlign: 'center', marginTop:'30px' }}  className="color-gray">Attachments</div>
            <span className="border"></span>
                <AttachementInput
                    model=".attachements"
                    multiple={true}
                    id="upload-images" 
                    noNeedAttachButton={true}
                    uploadedImages={instance.props.attachmentUrls || []}
                />
            </Row>
            <div >
                <div style={{fontSize: '18px', textAlign: 'center', marginTop:'10px' }}  className="color-gray">Approvals </div>
                <span className="border"></span>
                <Row className="show-grid approval-signature">
                    <Col className="form-title yellow-center" sm={4} >
                        <Label sm={12} required={false} htmlFor="upload-images" style={{textAlign: 'center'}}  className="color-gray">Creater Name: </Label> 
                        <label style={{color: 'white'}} className="color-black">({instance.props.createdBy})</label>
                    </Col> 
                    {instance.props.isApproved &&  
                        <Col className="form-title yellow-center" sm={4}>
                            <Label sm={12} required={false} htmlFor="upload-images" style={{textAlign: 'center'}}  className="color-gray">Manager Name: </Label> 
                            <label style={{color: 'white'}} className="color-black">({instance.props.approvedBy})</label>
                        </Col>
                    }
                    {instance.props.isAttested &&  
                        <><Col className="form-title yellow-center" sm={4} >
                            <Label sm={12} required={false} htmlFor="upload-images" style={{textAlign: 'center'}}  className="color-gray">Final Authority Name: </Label> 
                            <label style={{color: 'white'}} className="color-black">({instance.props.attestedBy})</label>
                        </Col>
                        <Col sm={12} xs={12} style={{marginTop: '20px', textAlign: 'center'}}>
                            <Label sm={12} htmlFor="finalAuthorityComments" className="color-gray" style={{ textAlign: 'center'}}>Final Authority Comments:</Label>
                            <Col sm={12} className="input color-black">
                                <label>{instance.props.finalAuthorityComments || 'Nill'}</label> 
                            </Col>
                        </Col></>
                    }
                </Row> 
            </div>
        </div >;
    }
    renderFollowUpListItems = () => {
        const { followUpInstances } = this.props; 
        if(followUpInstances && followUpInstances.length>0 && followUpInstances[0]!==null ) { 
            return followUpInstances.map((data, index) => {  
                return (
                    <Row className="show-grid assessment-details">
                        <Col style={{ textAlign: 'center' }} sm={1} > 
                          <span>{ ++index}</span>
                        </Col>
                        <Col style={{ textAlign: 'center'  }}
                            sm={4}
                        >
                            { data['action'] || ''}
                        </Col>
                        <Col style={{ textAlign: 'center' }}
                            sm={3}
                        >
                            { data['assignedToName']}
                        </Col>
                        <Col style={{ textAlign: 'center' }}
                            sm={2}
                        >
                            { data['targetCompletion'] && format(new Date(data['targetCompletion']), 'MM/dd/yy') }
                        </Col> 
                        <Col style={{ textAlign: 'center' }} sm={2} 
                        > 
                            { data['status']==='Open'?<Button className="close-call-followup-open" type="button" style={followUpOpen}>Open</Button>: <Button className="close-call-followup-close"  type="button" style={followUpClosed}>Closed</Button>} 
                        </Col> 
                    </Row>
                ); 
            });
        } else {
            return (<div style={{textAlign:'center'}} className="color-black">No follow-up record found..</div>)
        } 
    }
    renderRiskAssessmentFollowUpListItems = () => {
        const { instance } = this.props;
        let associatedChildForms = instance?.props.associatedChildForms || [];
        if(associatedChildForms.length>0 ) { 
            return associatedChildForms.map((data, index) => {  
                return (<><div>
                    <Row className="show-grid assessment-details">
                        <Col style={{ textAlign: 'center' }} sm={1} > 
                            <span className="serial-no">{index+1}</span>
                        </Col>
                        <Col style={{ textAlign: 'center' }} sm={3}
                        >
                            { format(new Date(data.date), 'MM/dd/yyyy') }
                        </Col>
                        <Col style={{ textAlign: 'center' }} sm={3}
                        >
                            <span className={`result-no res${data.ssri}`}>{ data.ssri}</span>
                        </Col>
                        <Col style={{ textAlign: 'center' }}  sm={3}
                        >
                            {  <Button type="button" className="assessment-followup-button" style={{top: '0px'}}> {( data.totalFollowUp  - data.totalOpenFollowUp )}/{data.totalFollowUp || 0}</Button>  }
                        </Col> 
                        
                    </Row>
                </div>
            </>)
            })
        } else {
            return (<div style={{textAlign:'center'}} className="color-black">No risk reassessment data found..</div>)
        } 
    }

    renderFormDetails = () => { 
        let fileName = 'Risk Assessment Report'; 
        if(!this.props.instance) {
            this.props.history?.push('/risk-assessment-reports/preliminary');
            return;
        }
        
        return ( 
            <DetailsPageWrapper
                pdfFileName={fileName}
                className="jsa-report risk-assessment report-details"
                    customButton={<><Button className="closeButton" redirectTo="goBack" type="button" onClick={() => this.props.history?.goBack() } >
                    <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;
                Close
                </Button> </>
                }
            >
                {this.renderContent()}
            </DetailsPageWrapper>
        );
    }

    render() { 
        return (
            <BasePage className="min-wide-list-page__"> 
                <Async
                    identifier="RiskAssessmentDetailsPage"
                    promise={this.promise}
                    error={<ErrorPage />}
                    loader={<Loader />}
                    content={this.renderFormDetails()}
                />
            </BasePage >
        );
    }
}

export function forEachStateToProps(state: any, ownProps: any) { 
    const editId = ownProps.match.params.id;
    const instance = RiskAssessmentModel.get(editId);
    const followUpInstances = state.report.get('modalReportDataList');  
    return {
        editId, instance, followUpInstances
    };
}

export const DetailsPage = withRouter(connect<IDetailsPageProps, any, any>(forEachStateToProps)(DetailsPageImpl));
const followUpOpen: React.CSSProperties = {
    color:'white',
    borderColor: 'red',
    backgroundColor: 'red', top: '0px',
 }; 
 const followUpClosed: React.CSSProperties = {
    color:'white',
    borderColor: 'green',
    backgroundColor: 'green', top: '0px',
 };