import {BaseModel} from './BaseModel';
import {store} from '../store/index';
import {LOCAL_STORAGE_KEY} from '../constants/general';

export interface IUserModelProps {
    id?: string;
    userId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    accessLevel?: string;
    jobTitle?: string;
    jobDesc?: string;
    dateCreated?: string;
    dateUpdated?: string;
    groups?: string[];
    projects?: string[];
    company?: string;
    password?: string;
    userUID?: string;
    userImages?: string[];
    formsSubmitted?: ISubmittedFormData[];
    formUrl?: string;
    numberofForms?: string;
    imageUrls?: string[];
    phoneNumber: string;
    signatureUrl?: string;
    companyLogo?: string;
    originalEmail?: string;
}

export interface ISubmittedFormData {

    formName: string;
    date: string;
    projectName: string;
    formURL: string;
}

export class UserModel extends BaseModel<IUserModelProps> {

    constructor(properties: IUserModelProps) {
        super(properties, UserModel.resource);

    }

    static resource = 'user';

    static isAdmin = () => {
        const userInstance = store.getState().login.get('currentUserInfo');
        if (userInstance) {
            //const isAdmin = userInstance.toJS().accessLevel === 'L1';
            const isAdmin = userInstance.accessLevel === 'L1';
            return isAdmin;
        }
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data);
        return userInfo.isAdmin;
    }

    static getCompanyName = () => {
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data);
        return userInfo.company;
    }
    static getAccessLevel = () => {
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data);
        return userInfo.accessLevel;
    }
    static getUserUID = () => {
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data);
        return userInfo.userUID;
    }
    static isLogged = () => {
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        return data && data.length>0;
    }
    static localSession = () => {
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        return JSON.parse(data);
    }

    static getLoggedUserInstance = (): any => {
        const userInstance = store.getState().login.get('currentUserInfo');
        //return userInstance ? userInstance.toJS() : {};
        return userInstance || {};
    }

    static getUserByUID(userUID: string, state = store.getState()): UserModel {
        const userInstances = UserModel.list();
        let user: any;
        if (userInstances.length <= 0) {
            return user;
        }
        userInstances.forEach((userInstance: { props: { userUID: string; }; }) => {
            if (userInstance.props.userUID === userUID) {
                user = userInstance;
                return;
            }
        });
        return user;
    }

    static isDemoUser() {
        const userData = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (userData) {
            const isDemoUser = JSON.parse(userData).company === 'Demo';
            return isDemoUser;
        }
    }

    /* static getUserCompAccess() {
        let userAccess = [];
        const userData = localStorage.getItem(LOCAL_STORAGE_KEY); 
        if(userData) {
            switch(JSON.parse(userData).company) { 
                //case 'Demo-Exxon':
                case 'DART':
                    userAccess.push('closeCall', 'closeCallReport');
                    break;
                default: break;
            } 
        }
        return userAccess;
        
    }
    static getUserAccess(access) {
        let userAccess = this.getUserCompAccess(); 
        if(userAccess.indexOf(access) !== -1) return true;
        else return false; 
    } */
    static checkUserAccess(reqAccess: string = '') {
        if (!reqAccess)
            return false;
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data); 
        if (userInfo && userInfo.userAccess && userInfo.userAccess.indexOf(reqAccess) !== -1) return true;
        else return false;
    }

    static getUserListTotalPages(userInstances: string | any[]) {
        let count: number = 0;
        if (!UserModel.isAdmin()) {
            for (let i = 1; i <= userInstances.length; i++) {
                let instance = userInstances[i - 1];
                if (instance.props.accessLevel !== 'L1')
                    count++
            }
            return count;
        }
        return userInstances.length;
    }

    static getNextPage(pageNumber: number, userInstances: string | any[]) {
        const userArray: Array<any> = [];
        let users: Array<any> = [];
        let filteredInstance: Array<any> = [];
        if (!UserModel.isAdmin()) {
            for (let i = 1; i <= userInstances.length; i++) {
                let instance = userInstances[i - 1];
                if (instance.props.accessLevel !== 'L1')
                    filteredInstance.push(instance);
            }
            userInstances = filteredInstance;
        }
        if (userInstances.length <= 20) {
            return userInstances;
        }
        for (let i = 1; i <= userInstances.length; i++) {
            users.push(userInstances[i - 1]);
            if (i % 20 === 0) {
                userArray.push(users);
                users = [];
            }
            if (i === userInstances.length && users.length <= 20) {
                userArray.push(users);
            }
        }
        return userArray[pageNumber - 1];
    }
}
