import { IHistory } from '../../interfaces';
import { ListPage } from '../reusableComponents/ListPage';
import { getAllUsers, getFormsOfAllUser } from '../../services/userService';
import { UserModel } from '../../model/UserModel';
import * as React from 'react';
import { connect } from 'react-redux';
import { BasePage } from '../reusableComponents/BasePage';
import { withRouter } from 'react-router-dom';
import { ConfirmationModal } from './ConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';
import { OIModel } from '../../model/OIModel';
import { getAllOIForms } from '../../services/oiformService';
import { JSAModel } from '../../model/JSAModel';
import { OfficeSafetyModel } from '../../model/OfficeSafetyModel';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { getAllJSAForm } from '../../services/jsaFormService';
import { getAllOfficeSafetyForm } from '../../services/officeSafetyFormService';
import { getAllIncidentNotificationForms } from '../../services/incidentNotificationFormService';
import { getAllIncidentReportForms } from '../../services/incidentReportService';
import { PersonalSafetyModel } from '../../model/PersonalSafetyModel'; 
import { FormListItem } from './FormListItem';

export interface IListPageProps {
    userInstances: UserModel[];
    user: UserModel;
    history?: IHistory;
    userUID: string;
    company: string;
    id: string;
}

export class ListPageImpl extends React.PureComponent<IListPageProps, {}> {
    constructor(props: IListPageProps | Readonly<IListPageProps>) {
        super(props);
    }

    promise = async () => {
        const { id } = this.props;
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        if (OIModel.list().length <= 0) {
            await getAllOIForms();
        }
        if (JSAModel.list().length <= 0) {
            await getAllJSAForm();
        }
        if (OfficeSafetyModel.list().length <= 0) {
            await getAllOfficeSafetyForm();
        }
        if (IncidentNotificationModel.list().length <= 0) {
            await getAllIncidentNotificationForms();
        }
        if (IncidentReportModel.list().length <= 0) {
            await getAllIncidentReportForms();
        }
        if (PersonalSafetyModel.list().length <= 0) {
            //await getAllPersonalSafetyForms(company);
        }
        await getFormsOfAllUser(id);
        return null;
    }

    renderUserList = () => {
        const { user } = this.props;
        return <Async
            identifier="UserListPage"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={
                <div className="user-list-wrapper">
                    <ListPage
                        pageHeading={`Forms Submitted By ${user ? user.props.firstName : ''}`}
                        instances={user && user.props.formsSubmitted ? user.props.formsSubmitted : []}
                        rowHeadings={[`Form Name`, 'Project Name', 'Date Created']}
                        listItemComponent={FormListItem}
                        emptyInstancesMessage=
                        {`No forms has been submitted by user ${user ? user.props.firstName : ''}`}
                        sm={[4, 4, 3]}
                    />
                </div>
            }
        />;
    }

    render() {
        return (
            <BasePage>
                <div className="users-list">
                    <AlertModal path="/users" id="delete-user-success" />
                    <ConfirmationModal id="delete-user" />
                    {this.renderUserList()}
                </div>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const userInstances = UserModel.list(state);
    const user = UserModel.getUserByUID(ownProps.match.params.id);
    const userUID = state.login.get('userUID');
    const company = state.login.get('company');
    return {
        user,
        id: ownProps.match.params.id,
        userUID,
        userInstances, company
    };
}

export const FormListPage = withRouter(connect<IListPageProps, any, any>(mapStateToProps)(ListPageImpl));
